import React from 'react';
import { css } from '@emotion/css';
import ConditionInstanceModel from '../../../models/features/api/Conditions/ConditionInstanceModel';
import { color_colors_growth, color_colors_royalty, color_text_light, color_text_link, color_variants_royalty_light_20 } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import Button from '../../../components/general/Button';
import ConditionModel, { ConditionComparisonType } from '../../../models/features/api/Conditions/ConditionModel';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
const addIcon = require('../../../content/images/condition-icons/add.svg');
const equalsIcon = require('../../../content/images/condition-icons/qualifier-equals.svg');
const notEqualsIcon = require('../../../content/images/condition-icons/qualifier-not-equal.svg');
const containsIcon = require('../../../content/images/condition-icons/qualifier-contains.svg');
const notContainsIcon = require('../../../content/images/condition-icons/qualifier-not-contain.svg');
const editIcon = require('../../../content/images/condition-icons/edit.svg');
const deleteIcon = require('../../../content/images/condition-icons/delete.svg');

interface ConditionalsGroupProps {
    conditionInstances: ConditionInstanceModel[]
    conditions: ConditionModel[]
    deviceTypes: DeviceTargetModel[]
    canDelete: boolean
    onAddAndCondition: (parentCondition: ConditionInstanceModel) => void
    onRemoveCondition: (condition: ConditionInstanceModel) => void
    onEditCondition: (condition: ConditionInstanceModel) => void
    onAddConditionGroup: () => void
}
const ConditionsGroup: React.FC<ConditionalsGroupProps> = (props) => {
    const capabilityOptions = [{
        value: "HasAudio",
        label: "Supports Audio"
    }, {
        value: "SupportsVideo",
        label: "Supports Video"
    }, {
        value: "HasDisplay",
        label: "Supports Display"
    }];
    const getTitleForCondition = (conditionId: string) => {
        return props.conditions.find(c => c.id == conditionId)?.groupName;
    }
    const getQualifierIcon = (conditionId: string) => {
        const qualifier = props.conditions?.find(c => c.id == conditionId)?.comparisonType;
        switch (qualifier) {
            case "Contains": return containsIcon;
            case "DoesNotContain": return notContainsIcon;
            case "Equals": return equalsIcon;
            case "NotEquals": return notEqualsIcon;
        }
        return '';
    }
    const getQualifierLabel = (conditionId: string) => {
        const qualifier = props.conditions?.find(c => c.id == conditionId)?.comparisonType;
        switch (qualifier) {
            case "Contains": return "Includes";
            case "DoesNotContain": return "Excludes";
            case "Equals": return "Equals";
            case "NotEquals": return "Does not equal";
        }
        return '';
    }
    const getDisplayValue = (conditionInstance: ConditionInstanceModel) => {
        const condition = props.conditions.find(c => c.id == conditionInstance?.conditionId);
        if (condition?.groupName?.toLocaleLowerCase() == "device type") {
            return props.deviceTypes?.find(d => d.id == conditionInstance?.value)?.displayName;
        }
        if (condition?.groupName?.toLocaleLowerCase() == "device capability") {
            return capabilityOptions.find(d => d.value == conditionInstance?.value)?.label;
        }

        return conditionInstance?.value;
    }
    const addAndCondition = (parent: ConditionInstanceModel) => {
        const getLeaf = (instance: ConditionInstanceModel) => {
            if (!instance.andCondition) return instance;
            return getLeaf(instance.andCondition);
        }
        props.onAddAndCondition(getLeaf(parent));
    }

    const renderSingleCondition = (conditionInstance: ConditionInstanceModel, isFirst: boolean) => {
        return (
            <>
                <div className="condition-item">
                    <div className="condition-handle">{!isFirst && '&'}</div>
                    <div className="condition-content ac-condition-content">
                        <p>{getTitleForCondition(conditionInstance.conditionId)}</p>
                        <TooltipWrapper text={getQualifierLabel(conditionInstance.conditionId)} place="top">
                            <img src={getQualifierIcon(conditionInstance.conditionId)} />
                        </TooltipWrapper>
                        <p>{getDisplayValue(conditionInstance)}</p>
                        <div className="condition-buttons">
                            <Button icon={editIcon} type="button" themes={['icon']} onClick={() => props.onEditCondition(conditionInstance)} />
                            {props.canDelete && <Button icon={deleteIcon} type="button" themes={['icon']} onClick={() => props.onRemoveCondition(conditionInstance)} />}
                        </div>
                    </div>
                </div>
                {conditionInstance.andCondition && renderSingleCondition(conditionInstance.andCondition, false)}
            </>
        )
    }
    return (
        <div className={containerStyle}>
            <div className="condition-groups">
                {props.conditionInstances?.map((c, i) => (
                    <React.Fragment key={i}>
                        {(i > 0) && <div className="or-row-wrapper">
                            <div className="or-line" />
                            <div className="or-row">
                                <p className="or">Or</p>
                                <p className="or-detail">This may also be true</p>
                            </div>
                        </div>}
                        <div className="condition-group" key={c.id}>
                            <div className="condition-items">
                                {renderSingleCondition(c, true)}
                            </div>
                            <button type="button" className="new-condition-item" onClick={() => addAndCondition(c)}>
                                <span className="new-condition-handle"><img src={addIcon} /></span>
                                <span className="new-condition-label ac-new-condition-label">Add a condition refinement (AND)</span>
                            </button>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <Button className="add-condition-group ac-add-condition-group" type="button" themes={['secondary', 'fill']} text="Add a Condition (OR)" onClick={() => props.onAddConditionGroup()} />
        </div>
    );
}

const containerStyle = css`
.condition-groups {
    padding: 24px 0;
    border-bottom: ${style_border_default};
    font-family: Muli;
    .condition-group {
        width: 100%;
        .condition-item {
            display: flex;
            align-items: center;
            min-height: 32px;
            width: 100%;
            border-bottom: none;
            &:first-child {
                .condition-handle{
                    border-top-left-radius: 8px;
                }
            }
            &:first-child {
                .condition-content {
                    border-top-right-radius: 8px;
                }
            }
            &:last-child {
                .condition-content {
                    border-bottom-right-radius: 8px;
                    border-bottom: 1px solid ${color_colors_royalty};
                }
            }                   
            .condition-handle {
                min-height: 42px;
                background: ${color_colors_royalty};
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-weight: lighter;
            }
            .condition-content {
                min-height: 42px;
                flex: 1;
                padding: 0 16px;
                display: flex;
                align-items: center;
                background: ${color_variants_royalty_light_20};
                border-top: 1px solid ${color_colors_royalty};
                border-right: 1px solid ${color_colors_royalty};
                img {
                    margin: 0 8px;
                }
                >p {                
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .condition-buttons {
                display: flex;
                align-items: center;
                padding-left: 8px;
                border-left: 1px solid white;
                margin-right: 0;
                margin-left: auto;
                button {
                    margin: 0;
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    &:first-child {
                        margin-right: 8px;

                    }
                }
            }
        }
        .new-condition-item {
            display: flex;
            align-items: center;
            border: none;
            background: none;
            box-shadow: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            .new-condition-handle {
                background: ${color_colors_royalty};
                width: 24px;
                min-height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-weight: lighter;
                font-size: 32px;
                border-radius: 0 0 12px 12px;
                img {
                    width: 12px;
                    height: 12px;
                }
            }
            .new-condition-label {
                margin: 4px 16px 0 16px;
                color: ${color_text_link};
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .or-row-wrapper {
        position: relative;
        height: 40px;
        .or-line {
            height: 100%;
            width: 1px;
            background: ${color_colors_royalty};
            position: absolute;
            left: 12px;
            top: 0;
            bottom: 0;
        }
        .or-row {
            position: absolute;
            display: flex;
            align-items: center;
            left: 1px;
            top: 12px;
            background: white;
            .or {
                border: 1px solid ${color_colors_royalty};
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                width: 24px;
                height: 16px;
                box-sizing: border-box;
                border-radius: 4px;
            }
            .or-detail {
                font-style: italic;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                margin: 0 8px;
                color: ${color_text_light};
            }
        }
    }
}
.add-condition-group {
    margin-left: 0;
    margin-top: 24px;
}
`;

export default ConditionsGroup;