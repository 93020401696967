import React from 'react';
import { css } from '@emotion/css';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import MediaContainer from '../../state/containers/MediaContainer';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import UserVariationsBubble from './UserVariationsBubble';
import DeviceAudioVariationsBubble from './DeviceAudioVariationsBubble';
import SsmlContainer from '../../state/containers/SsmlContainer';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';
import DeviceTargetSelector from './DeviceTargetSelector';
import ConversationBranchSwitcher from '../structure/ConversationFlow/ConversationBranchSwitcher';
import _ from 'lodash';
import LanguageModel from '../../models/features/api/LanguageModel';

interface AudioConversationFlowViewerProps {
    applicationId: string
    mediaContainer: MediaContainer
    responseContainer: MediaResponseStateContainer
    ssmlContainer: SsmlContainer
    conversationItems: ConversationItem[]
    targets: DeviceTargetModel[]
    endSession: boolean
    emptyLabel?: string
    languages?: LanguageModel[]
}

interface AudioConversationFlowViewerState {
    deviceTarget: DeviceTargetModel
}

class AudioConversationFlowViewer extends React.Component<AudioConversationFlowViewerProps, AudioConversationFlowViewerState> {
    componentWillMount() {
        this.setState({
            deviceTarget: this.getDefaultDevice()
        })
    }
    getDefaultDevice() {
        if (this.props.targets) {
            const alexaMedium = this.props.targets.find(t => t.assistant == "Alexa");
            return alexaMedium ? alexaMedium : this.props.targets[0];
        }
        return null;
    }
    handleTargetChange(target: DeviceTargetModel) {
        this.setState({
            ...this.state,
            deviceTarget: target
        })
    }

    renderBlock(conversationItem: ConversationItem, index: number) {
        return (
            <div key={index}>
                <UserVariationsBubble title={conversationItem.requestTitle} variations={conversationItem.requests} />
                <DeviceAudioVariationsBubble
                    title={conversationItem.responseTitle}
                    variations={conversationItem.responses}
                    reprompt={conversationItem.reprompt}
                    followUp={conversationItem.followUp}
                    ssmlContainer={this.props.ssmlContainer}
                    deviceTargetId={this.state.deviceTarget.id}
                    assistant={this.state.deviceTarget ? this.state.deviceTarget.assistant : 'alexa'} 
                    languages={this.props.languages}/>
                {conversationItem.children && conversationItem.children.length > 0 ? this.renderBranch(conversationItem.children) : null}
            </div>
        )
    }
    renderBranch(conversationItems: ConversationItem[]) {
        if (conversationItems && conversationItems.length > 1) {
            return (
                <ConversationBranchSwitcher position={0}>
                    {conversationItems.map((c, i) => (this.renderBlock(c, i)))}
                </ConversationBranchSwitcher>
            )
        }
        return conversationItems.map((c, i) => (this.renderBlock(c, i)))
    }
    render() {
        return (
            <div className={containerStyle}>
                <DeviceTargetSelector targets={_.sortBy(this.props.targets, o => o.displayName)} selectedTarget={this.state.deviceTarget} onSelectionChange={this.handleTargetChange.bind(this)} />
                <div className="branch-container">
                    {this.renderBranch(this.props.conversationItems)}
                </div>
                {this.props.emptyLabel ? <p className="empty-label-container">{this.props.emptyLabel}</p> : null}
            </div>
        )
    }
}

const containerStyle = css`
    margin-right: 48px;
    width: 100%;
    height: 100%;
    .branch-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    }
`

export default AudioConversationFlowViewer;