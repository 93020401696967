import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaSmallWrapper from './AlexaSmallWrapper';
import DOMPurify from 'dompurify';

const screenStyle = css`
    color: white;
    .body-container {
        margin: 180px 120px 0 120px;
    }
`
class AlexaSmallBody3TemplatePreview extends ScreenPreviewComponent {
    render() {
        return (
            <AlexaSmallWrapper className={screenStyle}>
                {this.state.foregroundImageUrl ? <img className="background-image" src={this.state.foregroundImageUrl} /> : null}
                {this.state.foregroundImageUrl ? <div className="background-cover" /> : null}
                {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                <div className="body-container">
                    <p className="body-text">
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.displayText) }}></span>

                        {this.state.followUpText ?
                            <>
                                <br /><br />
                                <span className="followup-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.followUpText) }}></span>
                            </>
                            : null}
                    </p>
                </div>
            </AlexaSmallWrapper>
        )
    }
}

export default AlexaSmallBody3TemplatePreview;