import React from 'react';
import { Subscribe } from 'unstated';
import WelcomeMessageContainer from '../../state/containers/WelcomeMessageContainer';
import WelcomeMessageContentForm from './components/WelcomeMessageContentForm';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import WelcomeMessageModel from '../../models/features/api/Welcome/WelcomeMessageModel';
import IResult from '../../models/result/IResult';
import GenericCreateContentItemPage from '../formScenes/GenericCreateContentItemPage';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { WelcomeMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface CreateWelcomeMessageProps extends RouteComponentProps<ApplicationRouteProps> { }

class CreateWelcomeMessage extends React.Component<CreateWelcomeMessageProps> {
    render() {
        return (
            <Subscribe to={[WelcomeMessageContainer, ApplicationContainer, TitleStateContainer, RepromptContainer, FollowUpContainer, MediaResponseStateContainer, FeatureContainer]}>
                {(welcomeContainer: WelcomeMessageContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer) => {
                    return (
                        <GenericCreateContentItemPage stateContainer={welcomeContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="New Welcome Message"
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={WelcomeMessagesFeatureTypeId}
                            formRender={(application, assistantApplication, onPublish, onSubmit: (model: WelcomeMessageModel) => Promise<IResult<WelcomeMessageModel>>, onAccessTypeChange) => (
                                <WelcomeMessageContentForm
                                    location={this.props.location}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    stateContainer={welcomeContainer}
                                    assistantApplication={assistantApplication}
                                    appContainer={appContainer}
                                    followUpContainer={followUpContainer}
                                    repromptContainer={repromptContainer}
                                    responseStateContainer={responseContainer}
                                    featureContainer={featureContainer}
                                    history={this.props.history}
                                    onSubmit={onSubmit}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onShowPublishModal={onPublish}
                                    isSaving={welcomeContainer.state.isSaving}
                                    application={application} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default CreateWelcomeMessage;