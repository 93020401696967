import { withFormik } from "formik";
import * as yup from 'yup';
import SimpleChoiceForm, { SimpleChoiceFormData, SimpleChoiceFormProps } from '../../formScenes/SimpleChoiceForm';

// note: some info on handling two submit buttons: https://github.com/jaredpalmer/formik/issues/214
// save creates the item and save and publish saves it and toggles the is live call
const CreateSimpleChoiceForm = withFormik<SimpleChoiceFormProps, SimpleChoiceFormData>({
    mapPropsToValues: props => ({
        title: 'Untitled Yes/No',
        responses: [{ content: '' }],
        followUpSelection: 'end',
        repromptSelection: 'end',
        choiceType: "Yes",
        entryType: 'targeted',
        sensitiveConversationChange: "None",
        requiresParent: true,
        isLive: true,
        languages: props.application?.languages.find(l => l.id == props.application?.defaultLanguageId) ? [props.application?.languages.find(l => l.id == props.application?.defaultLanguageId)] : [],
        webhookChainingType: "NoChaining"
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel({
            applicationFeatureId: props.applicationFeatureId,
            applicationId: props.application?.id,
            responses: []
        }, values));
    }
})(SimpleChoiceForm);

export default CreateSimpleChoiceForm;