import React from 'react';
import MenuStateContainer from '../../state/containers/MenuStateContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { Subscribe } from 'unstated';
import ContentTreeStateContainer from '../../state/containers/ContentTreeStateContainer';
import AppliedApplicationTemplateContainer from '../../state/containers/AppliedApplicationTemplateContainer';
import WebhookContainer from '../../state/containers/WebhookContainer';
import FeatureWebhookContainer from '../../state/containers/FeatureWebhookContainer';
import QuestionAnswerContainer from '../../state/containers/QuestionAnswerContainer';
import CustomRequestContainer from '../../state/containers/CustomRequestContainer';
import ExitMessageContainer from '../../state/containers/ExitMessageContainer';
import FallbackMessageContainer from '../../state/containers/FallbackMessageContainer';
import HelpMessageContainer from '../../state/containers/HelpMessageContainer';
import LatestMessageContainer from '../../state/containers/LatestMessageContainer';
import NumberRangeContainer from '../../state/containers/NumberRangeContainer';
import SimpleChoiceContainer from '../../state/containers/SimpleChoiceContainer';
import WelcomeMessageContainer from '../../state/containers/WelcomeMessageContainer';
import WebhookInstanceFormParent from './components/WebhookInstanceFormParent';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';

export interface WebhookInstanceManagerProps {
    show: boolean
    applicationId: string,
    webhookContainerId: string
    featureTypeId?: string,
    webhookInstanceScope: WebhookInstanceScope,
    stagedUpdate?: BulkWebhookInstancesUpdateRequest
    shouldNotLoad?: boolean
    existingWebhookChainingType?: WebhookChainingType
    onClose: () => void
    onFinish?: (webhookInstances: WebhookInstanceModel[]) => void,
    manualSave?: (updateRequest: BulkWebhookInstancesUpdateRequest) => void,
}

export type WebhookInstanceScope = "Application" | "ApplicationFeature" | "Content";

class WebhookInstanceManager extends React.Component<WebhookInstanceManagerProps> {

    render() {
        return (
            <Subscribe
                to={[WebhookContainer,
                    FeatureWebhookContainer,
                    QuestionAnswerContainer,
                    CustomRequestContainer,
                    ExitMessageContainer,
                    FallbackMessageContainer,
                    HelpMessageContainer,
                    LatestMessageContainer,
                    NumberRangeContainer,
                    SimpleChoiceContainer,
                    OrganizationContainer,
                    WelcomeMessageContainer]}>
                {(webhookContainer: WebhookContainer,
                    featureWebhookContainer: FeatureWebhookContainer,
                    questionAnswerContainer: QuestionAnswerContainer,
                    customRequestContainer: CustomRequestContainer,
                    exitMessageContainer: ExitMessageContainer,
                    fallbackMessageContainer: FallbackMessageContainer,
                    helpMessageContainer: HelpMessageContainer,
                    latestMessageContainer: LatestMessageContainer,
                    numberRangeContainer: NumberRangeContainer,
                    simpleChoiceContainer: SimpleChoiceContainer,
                    organizationContainer: OrganizationContainer,
                    welcomeMessageContainer: WelcomeMessageContainer
                ) => {
                    return (
                        <WebhookInstanceFormParent
                            {...this.props}
                            webhookContainer={webhookContainer}
                            featureWebhookContainer={featureWebhookContainer}
                            questionAnswerContainer={questionAnswerContainer}
                            customRequestContainer={customRequestContainer}
                            exitMessageContainer={exitMessageContainer}
                            fallbackMessageContainer={fallbackMessageContainer}
                            helpMessageContainer={helpMessageContainer}
                            organizationContainer={organizationContainer}
                            latestMessageContainer={latestMessageContainer}
                            numberRangeContainer={numberRangeContainer}
                            simpleChoiceContainer={simpleChoiceContainer}
                            welcomeMessageContainer={welcomeMessageContainer}
                        />
                    );
                }}
            </Subscribe>
        )
    }
}

export default WebhookInstanceManager;