import React from 'react';
import CollapsedMenuPanel from '../../../components/structure/CollapsedMenuPanel';
import { TreeItem } from '../../../components/structure/NestedTree/NestedTree';
import WebhookContainer from '../../../state/containers/WebhookContainer';
import WebhookFolderContainer from '../../../state/containers/WebhookFolderContainer';
import ExpandedWebhookSidePanel from './ExpandedWebhookSidePanel';


interface WebhookSidePanelProps {
    history: any
    webhookFolderContainer: WebhookFolderContainer
    onExpand: () => void
    webhookContainer: WebhookContainer
    applicationId: string
}


const CollapsedWebhookSidePanel: React.SFC<WebhookSidePanelProps> = (props) => {
    return (
        <CollapsedMenuPanel onExpand={props.onExpand} expandedViewRender={() => (
            <ExpandedWebhookSidePanel
                onCollapse={props.onExpand}
                webhookFolderContainer={props.webhookFolderContainer}
                history={props.history}
                webhookContainer={props.webhookContainer}
                showCollapseButton={false}
                applicationId={props.applicationId} />
        )} />
    )
}

export default CollapsedWebhookSidePanel;