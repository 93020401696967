import React from 'react';
import { css } from '@emotion/css';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import { color_text_default, color_text_light, color_colors_royalty, color_text_link, color_shades_dark, color_colors_ocean, color_shades_lighter, color_variants_main_bg, color_variants_ocean_light_1 } from '../../../constants/colors';
import ApplicationAvatar from '../../../components/general/ApplicationAvatar';
import ContentCard from '../../../components/structure/ContentCard';
import TextField from '../../../components/forms/TextField';
import TextAreaField from '../../../components/forms/TextAreaField';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { getLanguageName, getFlagIconForLanguage, getRegionyNameForLanguage } from '../../../models/extensions/languages';
import _ from 'lodash';
import LanguageCollectionAvatar from '../../../components/general/LanguageCollectionAvatar';
import AddMarkdownButton from './AddMarkdownButton';
import MarkdownEditorModal from './MarkdownEditorModal';
import Dropzone from 'react-dropzone';
import MediaContainer from '../../../state/containers/MediaContainer';
import { TabPanel } from 'react-tabs';
import Button from '../../../components/general/Button';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import TabContainerPanel from '../../../components/structure/Panels/TabContainerPanel';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import MarkdownPreview from './MarkdownPreview';
import ModuleCard from '../../applicationModules/components/ModuleCard';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Loader from '../../../components/general/Loader';
import { style_border_default, style_shadow_default } from '../../../constants/stylesValues';
import RadioGroup from '../../../components/forms/RadioGroup';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import TemplateIndustryModel from '../../../models/templating/api/TemplateIndustryModel';
import TemplateCategoryModel from '../../../models/templating/api/TemplateCategoryModel';
import SelectField from '../../../components/forms/SelectField';
import TemplateTag from './TemplateTag';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
const linkIcon = require('../../../content/images/link_out.svg');
const fieldPreviewIcon = require('../../../content/images/tab-icons/field-preview.svg');
const fieldPreviewIconBlue = require('../../../content/images/tab-icons/field-preview-blue.svg');
const helpIconBlue = require('../../../content/images/tab-icons/help-info-blue.svg');
const helpIcon = require('../../../content/images/tab-icons/help-info.svg');
const addImageIcon = require('../../../content/images/templates-icons/add-image.svg');
const addCircle = require('../../../content/images/add-circle.svg');
const deleteIcon = require('../../../content/images/content-explorer/delete.svg');

interface TemplateOverviewProps {
    templateConfiguration: TemplateConfigurationModel
    templateConfigurationContainer: TemplateConfigurationContainer
    mediaContainer: MediaContainer
    isSaving: boolean
    organizationId: string
    history: any
    onChange: (templateConfiguration: TemplateConfigurationModel) => void
}
interface TemplateOverviewState {
    isMarkdownOpen: boolean
    imageUrl?: string
    currentTagId?: string
    isDeleteConfirmationOpen: boolean
}
class TemplateSetupEditor extends React.Component<TemplateOverviewProps, TemplateOverviewState> {
    dropZone: Dropzone;
    constructor(props) {
        super(props);
        this.state = {
            isMarkdownOpen: false,
            imageUrl: null,
            isDeleteConfirmationOpen: false
        }
    }
    componentDidMount() {
        // handle anchor link scrolling
        this.props.history?.listen(location => {
            const { hash } = location;
            if (hash !== '') {
                // Push onto callback queue so it runs after the DOM is updated,
                // this is required when navigating from a different page so that
                // the element is rendered on the page before trying to getElementById.
                setTimeout(
                    () => {
                        const id = hash.replace('#', '');
                        const element = document.getElementById(id);
                        if (element) {
                            const scrollable = element.closest(".scrollable-panel").children[0];
                            if (scrollable) {
                                scrollable.scrollTop = element.offsetTop - 30; // header offset values
                            }
                        }
                    },
                    0
                );
            }
        });
    }
    handleToggleMarkdown() {
        this.setState({
            ...this.state,
            isMarkdownOpen: !this.state.isMarkdownOpen
        })
    }
    handleToggleDeleteConfirmation() {
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: !this.state.isDeleteConfirmationOpen
        });
    }
    async handleDeleteConfirm() {
        await this.props.templateConfigurationContainer.disableTemplateConfiguration(this.props.templateConfiguration.id);
        await this.props.templateConfigurationContainer.getOrganizationTemplates(this.props.organizationId);
        this.props.history.push('/v/templates');
    }
    handleTitleChange = (e) => {
        const template = this.props.templateConfiguration;
        template.name = e.target.value;
        this.props.onChange(template);
    }
    handleFinalizeUrlChange = (e) => {
        const template = this.props.templateConfiguration;
        template.finalizeFormUrl = e.target.value;
        this.props.onChange(template);
    }
    handleDynamicUrlChange = (e) => {
        const template = this.props.templateConfiguration;
        template.dynamicFormUrl = e.target.value;
        this.props.onChange(template);
    }
    handleShortDescriptionChange = (e) => {
        const template = this.props.templateConfiguration;
        template.shortDescription = e.target.value;
        this.props.onChange(template);
    }
    handleMarkdownChange = (markdown: string) => {
        const template = this.props.templateConfiguration;
        template.templateForm.instructionsMarkdown = markdown;
        this.props.onChange(template);
        this.setState({
            ...this.state,
            isMarkdownOpen: false
        })
    }
    handleVersionNameChange = (e) => {
        const template = this.props.templateConfiguration;
        if (!template.currentVersion) {
            template.currentVersion = {
                versionName: e.target.value
            }
        } else {
            template.currentVersion.versionName = e.target.value
        }
        template.currentVersion.id = null;
        template.currentVersion.createdDate = null;
        this.props.onChange(template)
    }
    handleReleaseNotesChange = (e) => {
        const template = this.props.templateConfiguration;
        if (!template.currentVersion) {
            template.currentVersion = {
                versionName: "1.0",
                releaseNotes: e.target.value
            }
        } else {
            template.currentVersion.releaseNotes = e.target.value
        }
        template.currentVersion.id = null;
        template.currentVersion.createdDate = null;
        this.props.onChange(template)
    }
    handleTypeChange = (option) => {
        const template = this.props.templateConfiguration;
        template.templateType = option.value;
        this.props.onChange(template);
    }
    handleAddIndustry(industry: TemplateIndustryModel) {
        const template = this.props.templateConfiguration;
        template.industries.push(industry);
        this.props.onChange(template);
    }
    handleAddCategory(category: TemplateCategoryModel) {
        const template = this.props.templateConfiguration;
        template.categories.push(category);
        this.props.onChange(template);
    }
    handleRemoveIndustry(industry: TemplateIndustryModel) {
        const template = this.props.templateConfiguration;
        _.remove(template.industries, i => i.id == industry.id);
        this.props.onChange(template);
    }
    handleRemoveCategory(category: TemplateCategoryModel) {
        const template = this.props.templateConfiguration;
        _.remove(template.categories, i => i.id == category.id);
        this.props.onChange(template);
    }
    handleTagChange(e) {
        this.setState({
            ...this.state,
            currentTagId: e.value
        })
    }
    handleAddCurrentTag() {
        const id = this.state.currentTagId;
        if (!id) return;

        const category = this.props.templateConfigurationContainer.state.categories?.find(c => c.id == id);
        const industry = this.props.templateConfigurationContainer.state.industries?.find(i => i.id == id);
        if (category) {
            this.handleAddCategory(category);
        }
        else if (industry) {
            this.handleAddIndustry(industry);
        }
    }
    handleFilePicked(files: File[]) {
        if (files.length > 0) {
            const file = files[0];
            this.readDataUrl(file);
            this.props.mediaContainer.uploadOrganizationMedia(this.props.organizationId, file.name, file, '', () => { }).then(result => {
                if (result.resultType == "Ok") {
                    const template = this.props.templateConfiguration;
                    template.imageUrl = result.data;

                    this.props.onChange(template);
                }
            });
        }
        else {
            this.setState({
                ...this.state,
                imageUrl: undefined
            })
        }
    }
    readDataUrl(file: File) {
        const reader = new FileReader();
        reader.onloadend = e => {
            this.setState({
                ...this.state,
                imageUrl: reader.result.toString()
            })
        }
        reader.readAsDataURL(file);
    }
    getLanguageGroups = () => {
        var dict = _.groupBy(this.props.templateConfiguration?.templateApplication?.languages ?? [], l => l.languageGroupCode)
        var groups = Object.keys(dict).map(key => ({
            key: key,
            label: getLanguageName(key),
            subItems: dict[key].map(lang => ({
                data: lang,
                value: lang.id,
                icon: getFlagIconForLanguage(lang),
                label: `${getLanguageName(key)} - ${getRegionyNameForLanguage(lang)}`,
                groupKey: key
            }))
        }));
        groups = _.sortBy(groups, group => group.key);
        return groups;
    }
    getAvailableTags() {
        const tags = this.props.templateConfigurationContainer.state.categories?.map(c => ({ ...c, type: "Category" }))?.concat(this.props.templateConfigurationContainer.state.industries?.map(i => ({ ...i, type: "Industry" })) ?? []);
        return tags?.filter(t => !this.props.templateConfiguration.categories?.some(c => c.id == t.id) && !this.props.templateConfiguration.industries?.some(i => i.id == t.id));
    }

    renderRadioOption(label: string, detail: string) {
        return <RadioOption>
            <p className="radio-label">{label}</p>
            <p className="radio-detail">{detail}</p>
        </RadioOption>
    }

    render() {
        const props = this.props;
        if (!props.templateConfiguration)
            return <Loader />
        return (
            <PanelContainer className={containerStyle}>
                <div className={`${menuStyle} expanded`}>
                    <div className={"fixed-wrapper"}>
                        <PanelHeaderContainer className="template-section-menu-header">
                            <h4>Setup</h4>
                        </PanelHeaderContainer>
                        <ScrollablePanelBody>
                            <ul>
                                <ModuleMenuItem>
                                    <Link to="#basis">Module Basis</Link>
                                </ModuleMenuItem>
                                <ModuleMenuItem>
                                    <Link to="#info">Basic Info</Link>
                                </ModuleMenuItem>
                                <ModuleMenuItem>
                                    <Link to="#type">Module Type</Link>
                                </ModuleMenuItem>
                                <ModuleMenuItem>
                                    <Link to="#dynamicform">Dynamic Form Endpoint</Link>
                                </ModuleMenuItem>
                                <ModuleMenuItem>
                                    <Link to="#discoverability">Discoverability</Link>
                                </ModuleMenuItem>
                                <ModuleMenuItem>
                                    <Link to="#version">Version Info</Link>
                                </ModuleMenuItem>
                            </ul>
                        </ScrollablePanelBody>
                    </div>
                </div>
                <PanelContainer className={formBodyStyle}>
                    <div className="form-wrapper">
                        <PanelContainer>
                            <div className={middlePanelContainer}>
                                <PanelHeaderContainer className="panel-header">
                                    <Button type="submit" themes={['primary-small', 'start']} text="Save" className="save-button" disabled={props.isSaving} loading={props.isSaving} />
                                </PanelHeaderContainer> <ScrollablePanelBody className={panelBodyStyle}>
                                    <div className={mainBodyStyle}>
                                        <HorizontalContainer className="template-setup-section-header">
                                            <h4>Setup</h4>
                                            <img src={deleteIcon} onClick={this.handleToggleDeleteConfirmation.bind(this)}/>
                                        </HorizontalContainer>
                                        <p className="section-description">Here’s where you can set up your module and configure some of the settings that describe what it does.</p>
                                        <AddMarkdownButton onClick={this.handleToggleMarkdown.bind(this)} hasValue={props.templateConfiguration?.templateForm?.instructionsMarkdown != null} />

                                        <ContentCard title="Module Source" id="basis">
                                            {props.templateConfiguration?.templateApplication ?
                                                <>
                                                    <div className="app-container">
                                                        <ApplicationAvatar app={props.templateConfiguration?.templateApplication} size={32} />
                                                        <h6>⬥ {props.templateConfiguration?.templateApplication?.name}</h6>
                                                        <a href={`/v/apps/${props.templateConfiguration?.templateApplication?.id}`} target="_blank">Open <img src={linkIcon} /></a>
                                                    </div>
                                                    <HorizontalSeparator />
                                                    <div className="languages-container">
                                                        <h6>Languages / Regions Supported</h6>
                                                        {this.getLanguageGroups()?.map(group => (
                                                            <div key={group.key}>
                                                                <p className="language-group-title">{group.label}</p>
                                                                <ul>
                                                                    {group.subItems?.map(lang => (
                                                                        <li key={lang.value}>
                                                                            <LanguageCollectionAvatar languages={[lang.data]} limit={1} />
                                                                            <p className="local-title">{lang.label}</p>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                                : <div className="app-container">
                                                    <TextField name="templateConfiguration.finalizeFormUrl" value={props.templateConfiguration.finalizeFormUrl ?? ''} onChange={this.handleFinalizeUrlChange.bind(this)} placeholder="The URL of the final POST request" label="Endpoint URL" />
                                                </div>}
                                        </ContentCard>
                                        <ContentCard title="Module Info" id="info">
                                            <FormFieldsContainer>
                                                <TextField label="Module Name" placeholder="Name your Module" name="templateConfiguration.name" value={props.templateConfiguration?.name ?? ''} required onChange={this.handleTitleChange.bind(this)} />

                                                <Dropzone ref={(node) => { this.dropZone = node }}
                                                    onDrop={this.handleFilePicked.bind(this)}
                                                    accept={".png,.jpg,.jpeg"}
                                                    multiple={false}
                                                    style={dropzoneStyle}>
                                                    <div className="template-image-container">
                                                        <HorizontalSeparator />
                                                        <div className="image-container">
                                                            <img src={this.state.imageUrl ?? props.templateConfiguration?.imageUrl ?? addImageIcon} className={(this.state.imageUrl ?? props.templateConfiguration?.imageUrl) ? '' : 'placeholder'} />
                                                        </div>
                                                        <p className="edit-option"><span>Edit Image</span> <img src={addCircle} /></p>
                                                    </div>
                                                </Dropzone>
                                                <TextAreaField label="Short Description" placeholder="This is used in your listing" name="templateConfiguration.shortDescription" value={props.templateConfiguration?.shortDescription ?? ''} required onChange={this.handleShortDescriptionChange.bind(this)} />
                                            </FormFieldsContainer>
                                        </ContentCard>
                                        <ContentCard title="Module Type" id="type">
                                            <DetailLabel>What is the main focus of this module?</DetailLabel>
                                            <RadioGroup value={props.templateConfiguration.templateType ?? "ContentTemplate"} options={[{
                                                value: "ContentTemplate",
                                                detailOverride: () => this.renderRadioOption("Content Template", "This is a module that primarily focuses on generating and managing content within an app. It may optionally contain webhooks but they serve a supporting role to the content.")
                                            }, {
                                                value: "Integration",
                                                detailOverride: () => this.renderRadioOption("Integration", "This is an integration that generates and manages webhooks to facilitate connections to other web-based services.")
                                            }]} onChange={this.handleTypeChange.bind(this)} />
                                        </ContentCard>
                                        <ContentCard title="Dynamic Form Endpoint" id="dynamicform">
                                            <DetailLabel>If you are building a dynamic module form, supply the HTTP POST endpoint URL you want Voicify to send the data to.</DetailLabel>
                                            <TextField name="templateConfiguration.dynamicFormUrl" value={props.templateConfiguration.dynamicFormUrl ?? ''} onChange={this.handleDynamicUrlChange.bind(this)} placeholder="The URL of the intermediate POST requests" label="Dynamic Form Endpoint URL" />
                                        </ContentCard>
                                        <ContentCard title="Discoverability" id="discoverability">
                                            <DetailLabel>Add industry and category tags that identify your module and help others find it </DetailLabel>
                                            <NewTagContainer>
                                                <SelectField label="Tags" name="tags" onChange={this.handleTagChange.bind(this)} value={this.state.currentTagId} placeholder="- Type a tag to add -" options={this.getAvailableTags().map(t => ({
                                                    label: `${t.type}: ${t.name}`,
                                                    value: t.id
                                                }))} useFormTheme />
                                                <Button themes={['primary']} text="Add Tag" onClick={() => this.handleAddCurrentTag()} disabled={this.state.currentTagId == null} type="button" />
                                            </NewTagContainer>
                                            <TagContainer>
                                                {props.templateConfiguration.categories?.map(c => (
                                                    <TemplateTag id={c.id} name={c.name} type={"Category"} onRemove={() => this.handleRemoveCategory(c)} />
                                                ))}
                                                {props.templateConfiguration.industries?.map(c => (
                                                    <TemplateTag id={c.id} name={c.name} type={"Industry"} onRemove={() => this.handleRemoveIndustry(c)} />
                                                ))}
                                            </TagContainer>
                                        </ContentCard>
                                        <ContentCard title="Version Info" id="version">
                                            <DetailLabel>Give your version name and latest update notes to help consumers of this module understand what they get.</DetailLabel>
                                            <FormFieldsContainer>
                                                <TextField label="Version Name" placeholder="1.0" name="templateConfiguration.currentVersion.versionName" value={props.templateConfiguration?.currentVersion?.versionName ?? ''} required onChange={this.handleVersionNameChange.bind(this)} />
                                                <TextAreaField label="Release Notes" placeholder="Content updates" name="templateConfiguration.currentVersion.releaseNotes" value={props.templateConfiguration?.currentVersion?.releaseNotes ?? ''} onChange={this.handleReleaseNotesChange.bind(this)} />
                                            </FormFieldsContainer>
                                        </ContentCard>
                                        {this.state.isMarkdownOpen && <MarkdownEditorModal contextTitle="Module Overview" markdown={props.templateConfiguration?.templateForm?.instructionsMarkdown ?? ''} onSave={this.handleMarkdownChange.bind(this)} onClose={this.handleToggleMarkdown.bind(this)} />}
                                    </div>
                                </ScrollablePanelBody>

                            </div>
                            <TabContainerPanel tabs={[{
                                title: 'Preview',
                                icon: fieldPreviewIcon,
                                selectedIcon: fieldPreviewIconBlue
                            }, {
                                title: 'Helpful Info',
                                icon: helpIcon,
                                selectedIcon: helpIconBlue
                            }]}>
                                <TabPanel>
                                    <ScrollablePanelBody className={mainBodyStyle}>
                                        <h4>Preview</h4>
                                        <p className="section-description">Here’s a preview of how your module will look in the listing</p>
                                        {this.props.templateConfiguration && <ModuleCard applicationId={null} templateConfiguration={props.templateConfiguration} />}
                                    </ScrollablePanelBody>
                                </TabPanel>
                                <TabPanel>
                                    <ScrollablePanelBody className={mainBodyStyle}>
                                        <MarkdownPreview instructionsMarkdown={props.templateConfiguration?.templateForm?.instructionsMarkdown ?? ''} />
                                    </ScrollablePanelBody>
                                </TabPanel>
                            </TabContainerPanel>
                        </PanelContainer>
                    </div>
                    {this.state.isDeleteConfirmationOpen &&
                        <ConfirmationDialog title="Delete" deleteText="Yes, Delete"
                            isLoading={this.props.templateConfigurationContainer.state.isLoading}
                            onClose={this.handleToggleDeleteConfirmation.bind(this)}
                            onConfirm={this.handleDeleteConfirm.bind(this)}
                        >
                            <DeleteConfirmation
                                itemLabel={" this module? Content generated with it will not be deleted though will become unable to be updated using it."} />
                        </ConfirmationDialog>}
                </PanelContainer>
            </PanelContainer>
        )
    };
}

const NewTagContainer = styled.div(`
    display: flex;
    width: 100%;
    align-items: flex-end;
    >div {
        flex: 1;
        margin: 0;
    }
    button {
        margin: 0;
        margin-left: 16px;
        width: 128px;
    }
`)
const TagContainer = styled.div(`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 24px 0;
    > * {
        margin-top: 8px;
    }
`)
const RadioOption = styled.div(`
    margin: 16px 0;
    .radio-label {
        font-size: 16px;
        line-height: 24px;
    }
    .radio-detail {
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
    }
`)
const DetailLabel = styled.p(`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color_text_light};
    margin: 24px 0;
`)
const middlePanelContainer = css`
    flex: 1;
    border-right: 1px solid ${color_shades_dark};
    .panel-header {
        button {
            margin: 0;
        }
        .save-button {
            margin-left: 32px;
        }
        .next-button {
            margin-right: 32px;
        }
        .prev-button {
            margin-right: 16px;
            margin-left: auto;
        }
    }
`
const containerStyle = css`
    background: ${color_variants_main_bg};
    .form-wrapper {
        flex: 1;
        min-width: 600px;
    }
    .empty-indicator {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .page-indicator-container {
        background: ${color_shades_lighter};
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 4px;
        margin: 0 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
    }    
    .template-section-menu-header {
        h4 {
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin: 0 32px;
        }
        button {
            margin-right: 16px;
            margin-left: auto;
        }
    }
    .template-setup-section-header {
        display: flex;
        img {
            margin-left: auto;
            cursor: pointer;
            width: 24px;
            height: 24px;
            margin-top: 4px;
        }
        h4 {
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
        }
    }
    .overview-empty-label {
        padding: 32px;
        margin: 32px;
        border: 1px solid ${color_shades_dark};
        border-radius: 8px;
        text-align: center;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    .module-card {
        >div {
            margin-left: 0;
        }
    }
`

const formBodyStyle = css`
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    .scrollable-body {       
        padding-bottom: 56px;
    }
`

const panelBodyStyle = css`
    padding-top: 0;
    min-height: 100%;
`

const menuStyle = css`
z-index: 2;
bottom: 0;
position: relative;
ul {
    margin: 16px;
}
&.collapsed {
    width: 80px;
    .fixed-wrapper {
        width: 80px;
    }
}
&.expanded {
    width: 288px; 
    .fixed-wrapper {
        width: 288px;
    }
}

.fixed-wrapper {
    position: fixed;
    bottom: 0;
    height: calc(100vh - 80px);
    background: white;
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
}
`

const dropzoneStyle: React.CSSProperties = {
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    color: color_colors_ocean,
    margin: '32px -32px'
};

const ModuleMenuItem = styled.li(`
    padding-left: 16px;
    flex: 1;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    a {
        color: ${color_text_default};
    }
    img {
        margin-right: 12px;
        width: 20px;
    }
    &.selected {
        background: ${color_variants_ocean_light_1};
        border-radius: 4px;
        color: ${color_text_link};
    }
`);


const mainBodyStyle = css`
    padding: 24px 32px 64px 32px;
    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: ${color_text_default};
    }
    h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        margin-top: 32px;
        margin-bottom: 24px;
    }
    .section-description {
        font-size: 14px;
        line-height: 20px;
        color: ${color_text_light};
        margin-top: 16px;
    }
    .app-container {
        display: flex;
        align-items: center;
        padding: 24px 0;
        h6 {
            color: ${color_colors_royalty};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            margin: 0 12px;
        }
        a {
            display: flex;
            align-items: center;
            color: ${color_text_link};
            margin-right: 0;
            margin-left: auto;
            img {
                margin-left: 12px;
            }
        }
    }
    .template-image-container {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin: 32px 0;
        .image-container {
            position: absolute;
            width: 80px;
            height: 80px;
            background: white;
            border: ${style_border_default};
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 32px;
            margin-bottom: 16px;
            box-shadow: ${style_shadow_default};

            img {
                max-height: 100%;
                max-width: 100%;
                &.placeholder {
                    margin: 16px;
                }
            }
        }
        .edit-option {
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-left: auto;
            color: ${color_text_link};
            margin-bottom: 24px;
            position: absolute;
            right: 32px;
            img {
                margin-left: 8px;
            }
        }
    }
    .languages-container {
        margin: 32px 0;
        h6 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
        }
        .language-group-title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        ul {
            list-style: none;
            margin: 16px;
            li {
                display: flex;
                align-items: center;
                margin: 0 16px;
                margin-bottom: 8px;
                .local-title {
                    margin: 0 12px;       
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
`;

export default TemplateSetupEditor;