import React from 'react';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import ModuleListItem from './ModuleListItem';
import ModuleCard from './ModuleCard';
import styled from '@emotion/styled';
import { color_colors_ocean } from '../../../constants/colors';
import EmptyView from './EmptyView';
import SecondaryLoader from '../../../components/general/SecondaryLoader';

interface AppliedTemplatesProps {
    templateConfigurationContainer: TemplateConfigurationContainer
    applicationId: string
    appliedTemplates: AppliedApplicationTemplateModel[]
}

const AppliedTemplates: React.FC<AppliedTemplatesProps> = ({
    templateConfigurationContainer,
    applicationId,
    appliedTemplates
}) => {

    return (
        <InnerPageWrapper>
            <PageHeader>Applied Modules</PageHeader>
            {appliedTemplates.length > 0 &&
                <PageDetail>These are the modules that have been applied to this app</PageDetail>
            }
            {templateConfigurationContainer.state.isLoading &&
                <LoaderWrapper>
                    <SecondaryLoader />
                </LoaderWrapper>
            }
            {templateConfigurationContainer.state.defaultViewType == "table" ?
                <ListWrapper>
                    {appliedTemplates?.map(t => (
                        <li key={t.id}><ModuleListItem templateConfiguration={t.templateForm.templateConfiguration} appliedTemplate={t} key={t.id} applicationId={applicationId} /></li>
                    ))}
                </ListWrapper>
                : <CardWrapper>
                    {appliedTemplates?.map(t => (
                        <li key={t.id}><ModuleCard templateConfiguration={t.templateForm.templateConfiguration} appliedTemplate={t} key={t.id} applicationId={applicationId} /></li>
                    ))}
                </CardWrapper>
            }
            {appliedTemplates?.length < 1 && !templateConfigurationContainer.state.isLoading &&
                <EmptyView
                    title="It's quiet in here!"
                    description="No modules have been applied to this app yet."
                />
            }
        </InnerPageWrapper>)

};

const InnerPageWrapper = styled.div(`
    padding-bottom: 128px;
`);

const CardWrapper = styled.ul(`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
`);

const ListWrapper = styled.ul(`
    list-style-type: none;
`);

const LoaderWrapper = styled.div(`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
    .spinner > div {
        background-color: ${color_colors_ocean};
    }
`);

const PageHeader = styled.h3(`
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 32px 0 24px 32px;
`);

const PageDetail = styled.p(`
    margin: auto 32px;
`);

export default AppliedTemplates;