import React from 'react';
import styled from '@emotion/styled';
import TemplateConfigurationModel from '../../models/templating/api/TemplateConfigurationModel';
import { style_border_default } from '../../constants/stylesValues';
import LanguageCollectionAvatar from '../general/LanguageCollectionAvatar';
import _ from 'lodash';
import { getLanguageName, getFlagIconForLanguage, getRegionyNameForLanguage } from '../../models/extensions/languages';

interface OverviewProps {
    templateConfiguration: TemplateConfigurationModel
}

const TemplateOverviewCard: React.FC<OverviewProps> = ({
    templateConfiguration
}) => {

    var dict = _.groupBy(templateConfiguration?.templateApplication?.languages ?? [], l => l.languageGroupCode)
    var languageGroups = Object.keys(dict).map(key => ({
        key: key,
        label: getLanguageName(key),
        subItems: dict[key].map(lang => ({
            data: lang,
            value: lang.id,
            icon: getFlagIconForLanguage(lang),
            label: `${getLanguageName(key)} - ${getRegionyNameForLanguage(lang)}`,
            groupKey: key
        }))
    }));
    languageGroups = _.sortBy(languageGroups, group => group.key);
    return (
        <CardContainer>
            <h6>Creator:</h6>
            <p>{templateConfiguration?.ownerName}</p>
            <h6>Description</h6>
            <p>{templateConfiguration?.description}</p>
            <div className="languages-container">
                <h6>Languages / Regions Supported</h6>
                {languageGroups?.map(group => (
                    <div key={group.key}>
                        <p className="language-group-title">{group.label}</p>
                        <ul>
                            {group.subItems?.map(lang => (
                                <li key={lang.value}>
                                    <LanguageCollectionAvatar languages={[lang.data]} limit={1} />
                                    <p className="local-title">{lang.label}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            {(!languageGroups || languageGroups.length == 0) && <p>Unknown</p>}
        </CardContainer>
    );
}

const CardContainer = styled.div(`
    margin: 32px;
    padding: 24px 32px;
    background: white;
    border: ${style_border_default};
    border-radius: 8px;

    h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
    >p {
        margin-bottom: 32px;
        margin-top: 16px;
        font-size: 14px;
        line-height: 20px;
    }
    .languages-container {
        margin: 32px 0;
        h6 {
            margin-bottom: 24px;
        }
        .language-group-title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        ul {
            list-style: none;
            margin: 16px;
            li {
                display: flex;
                align-items: center;
                margin: 0 16px;
                .local-title {
                    margin: 0 12px;       
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
`);

export default TemplateOverviewCard;