import { css } from '@emotion/css';
import React, { useState } from 'react';
import Button from '../../../components/general/Button';
import ModalTitle from '../../../components/general/ModalTitle';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import FooterBar from '../../../components/structure/FooterBar';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import ModalLayout from '../../../components/structure/ModalLayout';
import PageContainer from '../../../components/structure/PageContainer';
import { color_colors_gold, color_colors_gold_background, color_colors_ocean, color_shades_dark, color_shades_darkest } from '../../../constants/colors';
import WebhookModel from '../../../models/webhooks/api/WebhookModel';
import FullScreenLayout from '../../fullScreenLayout';
import WebhookListItem from './WebhookListItem';
const closeIcon = require('../../../content/images/close_icon.svg');

interface OrphanedWebhooksModalProps {
    webhooks: WebhookModel [],
    handleClose: () => void,
    handleRemove: (webhook: WebhookModel) => any
    handleAddToApp: (ids: string[]) => any
    isLoading: boolean
}

const OrphanedWebhooksModal: React.FC<OrphanedWebhooksModalProps> = ({
    webhooks,
    handleClose,
    handleAddToApp,
    handleRemove,
    isLoading
}) => {

    const [selectedWebhookIds, setSelectedWebhookIds] = useState([] as string[]);

    const updateSelection = (webhook: WebhookModel, isSelected: boolean) => {
        if(!isSelected && selectedWebhookIds.some(id => id == webhook.id)) {
            const ids = selectedWebhookIds.filter(id => id != webhook.id);
            setSelectedWebhookIds(ids);
        }
        else if(isSelected && !selectedWebhookIds.some(id => id == webhook.id)) {
            let ids = [...selectedWebhookIds];
            ids.push(webhook.id);
            setSelectedWebhookIds(ids);
        }
    }
    return (
        <ModalLayout className={containerStyle} isVisible={true} onClose={handleClose.bind(this)}>        
            <PageContainer fill withFooter>
                <HorizontalContainer>
                    <ModalTitle padded>Orphaned Webhooks</ModalTitle>
                    <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={handleClose.bind(this)} />
                </HorizontalContainer>
                <div className="orphaned-webhooks-description">
                    <p>These webhooks exist within your org but are not currently associated with any specific apps. Select which webhooks you’d like to move to your current app. </p>
                    <p>Once they have been established within a particular app, webhooks can then be updated as needed.</p>
                </div>
                <HorizontalSeparator />
                <CustomScrollbars autoHeight autoHeightMin={608} autoHeightMax={608}>
                    {
                        webhooks.map((webhook) => 
                            <WebhookListItem
                                key={webhook.id}
                                webhook={webhook} 
                                isSelected={selectedWebhookIds.some(id => id == webhook.id)}
                                selectable
                                disabled={false}
                                onDelete={() => handleRemove(webhook)}
                                onClick={() => null}
                                onSelectionChange={updateSelection.bind(this)}/>
                        )
                    }
                </CustomScrollbars>
                <FooterBar className={"orphaned-webhooks-footer"}>
                    <Button type="button" themes={['primary']} text="Move to this app" onClick={() => handleAddToApp(selectedWebhookIds)} disabled={isLoading || selectedWebhookIds.length == 0} loading={isLoading} />
                    <div className="selected-count">{`${selectedWebhookIds.length} Selected`}</div>
                    <Button type="button" themes={['secondary']} text="Cancel" onClick={handleClose.bind(this)} disabled={isLoading} loading={isLoading} />
                </FooterBar>
            </PageContainer>
        </ModalLayout>
    );

};

const containerStyle = css`
    color: ${color_shades_darkest};
    height: 900px;
    .orphaned-webhooks-description {
        padding: 32px;
        border-top: 1px solid ${color_shades_dark};
    }
    .selected-count {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: auto;
    }
    .orphaned-webhooks-footer {
        padding: 32px 16px;
    }
`;


export default OrphanedWebhooksModal;