import React, { useState } from 'react';
import { FieldArray, FormikProps, withFormik } from "formik";
import * as yup from 'yup';
import WebhookModel from '../../../models/webhooks/api/WebhookModel';
import WebhookParameter from '../../../models/webhooks/api/WebhookParameter';
import ModalLayout from '../../../components/structure/ModalLayout';
import FullScreenLayout from '../../fullScreenLayout';
import { css } from '@emotion/css';
import PageContainer from '../../../components/structure/PageContainer';
import WebhookTypeModel from '../../../models/webhooks/api/WebhookTypeModel';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import ModalTitle from '../../../components/general/ModalTitle';
import Button from '../../../components/general/Button';
import TextField from '../../../components/forms/TextField';
import { color_colors_ocean, color_shades_dark, color_shades_darker, color_shades_darkest } from '../../../constants/colors';
import SelectField from '../../../components/forms/SelectField';
import FooterBar from '../../../components/structure/FooterBar';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import WebhookConfigurationParameter from '../../../models/webhooks/api/WebhookConfigurationParameter';
import Switch from '../../../components/forms/Switch';
import useWindowSize from '../../../hooks/UseWindowSize';
import TextOverflowMenu from '../../../components/general/overflowMenu/TextOverflowMenu';
import styled from '@emotion/styled';
import WebhookSensitiveDataModal from './WebhookSensitiveDataModal';
import WebhookSensitiveDataModel from '../../../models/webhooks/api/WebhookSensitiveDataModel';

const closeIcon = require('../../../content/images/close_icon.svg');
const descriptionIcon = require('../../../content/images/webhooks/webhook-description.svg');
const typeIcon = require('../../../content/images/webhooks/webhook-type.svg');
const linkIcon = require('../../../content/images/webhooks/webhook-link.svg');
const tokenIcon = require('../../../content/images/webhooks/webhook-token.svg');
const removeParameterIcon = require('../../../content/images/webhooks/remove-parameter.svg');
const deleteIcon = require('../../../content/images/content-item-icons/delete-blue.svg');

export interface WebhookFormData {
    title: string,
    description: string,
    url: string,
    webhookTypeId: string,
    accessToken: string,
    parameters: WebhookParameter[]
    configurationParameters: WebhookConfigurationParameter[],
    hasSensitiveDataKey: boolean
}

export interface WebhookFormProps {
    editorType: EditorType,
    currentFolderId?: string,
    webhookToUpdate: WebhookModel,
    isLoading: boolean,
    webhookTypes: WebhookTypeModel[],
    finishCreate: (model: WebhookModel) => void,
    finishUpdate: (model: WebhookModel) => void,
    onClose: () => void,
    onWebhookDelete: (webhook: WebhookModel) => void,
    removeSensitiveDataKey: Function,
    generateSensitiveDataKey: Function,
    webhookSensitiveData?: WebhookSensitiveDataModel
}

export type SensitiveDataStatus = "Enabled" | "Disabled";

export type SensitiveDataActions = "Disable" | "Generate New Key";

const SENSITIVE_DATA_STATUS_DISABLE = "Disable";

const SENSITIVE_DATA_STATUS_UPDATE = "Regenerate Key";

const SENSITIVE_DATA_STATUS_GENERATE = "Generate New Key"

export type EditorType = "update" | "create";

const WebhookFormComponent: React.FC<WebhookFormProps & FormikProps<WebhookFormData>> = (props) => {
    const [isGenerateEncryptionKeyOpen, setIsGenerateEncryptionKeyOpen] = useState<boolean>(false);

    const windowSize = useWindowSize();

    const handleTypeChange = (value) => {
        const finalValue = value === null ? null : value.value;
        props.setFieldValue("webhookTypeId", finalValue);
    };

    const handleTypeBlur = (blur) => {
        props.setFieldTouched("webhookTypeId", blur);
    };

    const openGenerateEncryptionKey = (webhookId: string) => {
        props.generateSensitiveDataKey(webhookId);
        setIsGenerateEncryptionKeyOpen(true);
    };

    const removeEncryptionKey = (webhookId: string) => {
        props.removeSensitiveDataKey(webhookId)
    }

    const toggleWebhookSensitiveDataModal = () => {
        setIsGenerateEncryptionKeyOpen(!isGenerateEncryptionKeyOpen);
    }

    const handleClose = () => {
        toggleWebhookSensitiveDataModal();
    };

    return (
        <ModalLayout
            className={modalStyle}
            isVisible={true}
            onClose={props.onClose}
            zIndex={1010}
        >
            {isGenerateEncryptionKeyOpen &&
                <WebhookSensitiveDataModal
                    handleClose={handleClose}
                    webhookToUpdate={props.webhookToUpdate}
                    sensitiveData={props.webhookSensitiveData}
                    isLoading={props.isLoading}
                />
            }
            <form onSubmit={props.handleSubmit}>
                <FullScreenLayout className={containerStyle}>
                    <PageContainer fill>
                        <HorizontalContainer className="header-wrapper">
                            <ModalTitle>{props.values.title ? props.values.title : (props.editorType == "create" ? "Create Webhook" : "Update Webhook")}</ModalTitle>
                            <Button className="close-icon" themes={['end-tight', 'icon']} icon={closeIcon} type="button" onClick={props.onClose} />
                        </HorizontalContainer>
                        <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - 188}>
                            {props.editorType == "update" && <div onClick={() => props.onWebhookDelete(props.webhookToUpdate)} className="delete-confirmation"><div>{"Delete Webhooks & all Instances"}</div><img src={deleteIcon} /></div>}
                            <TextField
                                className="title-field"
                                name={`title`}
                                value={props.values.title}
                                placeholder="Enter webhook name"
                                label={`Webhook Name`}
                                onChange={props.handleChange}
                                disabled={props.isLoading}
                                onBlur={props.handleBlur} />
                            {props.editorType === "update" &&                                
                                <SensitiveDataContainer>
                                    <TextOverflowMenu
                                        clickableText={props.values.hasSensitiveDataKey ? "Enabled" : "Disabled"}
                                        menuClassName={dropdownMenuStyle}
                                        label={"Accepts Secure Data:"}
                                        onOptionSelected={(option) => {
                                            props.setFieldValue("hasSensitiveDataKey", option !== SENSITIVE_DATA_STATUS_DISABLE)
                                            option === SENSITIVE_DATA_STATUS_DISABLE ? removeEncryptionKey(props.webhookToUpdate.id) : openGenerateEncryptionKey(props.webhookToUpdate.id)
                                        }}
                                        options={props.values.hasSensitiveDataKey ? [{ label: SENSITIVE_DATA_STATUS_DISABLE }, { label: SENSITIVE_DATA_STATUS_UPDATE }] : [{ label: SENSITIVE_DATA_STATUS_GENERATE }]}
                                    />
                                </SensitiveDataContainer>
                            }
                            <div className="main-form-container">
                                <HorizontalContainer>
                                    <TextField
                                        labelIcon={descriptionIcon}
                                        className="webhook-editor-text left"
                                        name={`description`}
                                        value={props.values.description}
                                        disabled={props.isLoading}
                                        placeholder="Enter description"
                                        label={`Description`}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur} />
                                    <SelectField
                                        labelIcon={typeIcon}
                                        className="webhook-editor-selector right"
                                        options={props.webhookTypes.map(o => ({ value: o.id, label: o.title }))}
                                        value={props.values.webhookTypeId}
                                        onBlur={handleTypeBlur.bind(this)}
                                        onChange={handleTypeChange.bind(this)}
                                        disabled={props.isLoading}
                                        label="Type"
                                        placeholder="- None -"
                                        clearValueText="- None -"
                                        clearable={true}
                                        name="webhookTypeId" />
                                </HorizontalContainer>
                                <HorizontalContainer >
                                    <TextField
                                        labelIcon={linkIcon}
                                        className="webhook-editor-text left"
                                        name={`url`}
                                        value={props.values.url}
                                        disabled={props.isLoading}
                                        placeholder="Enter webhook url"
                                        label={`URL`}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur} />
                                    <TextField
                                        labelIcon={tokenIcon}
                                        className="webhook-editor-text right"
                                        name={`accessToken`}
                                        disabled={props.isLoading}
                                        value={props.values.accessToken}
                                        placeholder="Enter token"
                                        label={`Token (Optional)`}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur} />
                                </HorizontalContainer>
                                <div className="sub-header">Configuration Parameters</div>
                                <div className="sub-header-description">Configuration Parameters will be carried over to any instances of this webhook</div>
                                <FieldArray
                                    validateOnChange={false}
                                    name="configurationParameters"
                                    render={arrayHelpers => (
                                        <div>
                                            {
                                                props.values.configurationParameters.map((c, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <HorizontalContainer className="remove-parameter">
                                                                <span className="left" />
                                                                <div>{c.key ? c.key : "Configuration Parameter"}</div>
                                                                <span className="right" />
                                                                <img src={removeParameterIcon} onClick={() => { arrayHelpers.remove(idx) }} />
                                                            </HorizontalContainer>
                                                            <HorizontalContainer>
                                                                <TextField
                                                                    className="webhook-editor-text left"
                                                                    name={`configurationParameters.${idx}.key`}
                                                                    value={c.key}
                                                                    disabled={props.isLoading}
                                                                    placeholder="Enter configuration key"
                                                                    label={`Key`}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur} />
                                                                <TextField
                                                                    className="webhook-editor-text right"
                                                                    name={`configurationParameters.${idx}.value`}
                                                                    disabled={props.isLoading}
                                                                    value={c.value}
                                                                    placeholder="Enter configuration value"
                                                                    label={`Value`}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur} />
                                                            </HorizontalContainer>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div
                                                onClick={() => {
                                                    arrayHelpers.push({
                                                        value: "",
                                                        key: ""
                                                    }
                                                    )
                                                }}
                                                className={`add-parameter-link ${props.values.configurationParameters.length > 0 ? 'less-top-padding' : ''}`}>
                                                + Add a Configuration Parameter
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className="sub-header">Instance Parameters</div>
                                <div className="sub-header-description">Instance Parameters can be set up when a webhook is attached as an instance</div>
                                <FieldArray
                                    validateOnChange={false}
                                    name="parameters"
                                    render={arrayHelpers => (
                                        <div>
                                            {
                                                props.values.parameters.map((p, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <HorizontalContainer className="remove-parameter">
                                                                <span className="left" />
                                                                <div>{p.name ? p.name : "Instance Parameter"}</div>
                                                                <span className="right" />
                                                                <img src={removeParameterIcon} onClick={() => { arrayHelpers.remove(idx) }} />
                                                            </HorizontalContainer>
                                                            <HorizontalContainer>
                                                                <TextField
                                                                    className="webhook-editor-text left"
                                                                    name={`parameters.${idx}.name`}
                                                                    value={p.name}
                                                                    disabled={props.isLoading}
                                                                    placeholder="Enter a parameter key"
                                                                    label={`Key`}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur} />
                                                                <TextField
                                                                    className="webhook-editor-text right"
                                                                    name={`parameters.${idx}.defaultValue`}
                                                                    disabled={props.isLoading}
                                                                    value={p.defaultValue}
                                                                    placeholder="Enter a default value"
                                                                    label={`Default Value (Optional)`}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur} />
                                                            </HorizontalContainer>
                                                            <Switch
                                                                className="switch-wrapper"
                                                                checked={p.isRequired}
                                                                onChange={(value) => props.setFieldValue(`parameters.${idx}.isRequired`, value)}
                                                                label="Required Parameter"
                                                                disabled={props.isLoading} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div
                                                onClick={() => {
                                                    arrayHelpers.push({
                                                        name: "",
                                                        defaultValue: "",
                                                        isRequired: false
                                                    }
                                                    )
                                                }}
                                                className={`add-parameter-link less-bottom-padding ${props.values.parameters.length > 0 ? 'less-top-padding' : ''}`}>
                                                + Add an Instance Parameter
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </CustomScrollbars>
                        <FooterBar>
                            <Button className="submit-button" loading={props.isLoading} disabled={props.isLoading} themes={["primary", "wide"]} text={props.editorType == "create" ? "Create" : "Save"} type="submit" />
                            <Button className="cancel-button" loading={props.isLoading} disabled={props.isLoading} themes={["white", "end"]} text="Cancel" type="button" onClick={props.onClose.bind(this)} />
                        </FooterBar>
                    </PageContainer>
                </FullScreenLayout>
            </form>
        </ModalLayout>
    );
}

const WebhookForm = withFormik<WebhookFormProps, WebhookFormData>({
    mapPropsToValues: props => {
        if (props.editorType == "update") {
            return {
                title: props.webhookToUpdate.title,
                description: props.webhookToUpdate.description,
                url: props.webhookToUpdate.url,
                webhookTypeId: props.webhookToUpdate.webhookType.id,
                accessToken: props.webhookToUpdate.accessToken,
                parameters: props.webhookToUpdate.parameters ?? [],
                configurationParameters: props.webhookToUpdate.configurationParameters ?? [],
                hasSensitiveDataKey: props.webhookToUpdate.hasSensitiveDataKey
            };
        }
        else {
            return {
                title: "",
                description: "",
                url: "",
                webhookTypeId: "",
                accessToken: "",
                parameters: [],
                configurationParameters: [],
                hasSensitiveDataKey: false
            };
        }
    },
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        url: yup.string().url().required("You must enter a webhook url"),
        webhookTypeId: yup.string().required("You must choose a webhook type"),
        parameters: yup.array().of(
            yup.object().shape({
                name: yup.string().required("You must enter an instance parameter key"),
                isRequired: yup.boolean().required("You must specify if the parameter is required"),
            })
        ),
        configurationParameters: yup.array().of(
            yup.object().shape({
                key: yup.string().required("You must enter a configuration parameter key"),
                value: yup.string().required("You must enter a configuration parameter value"),
            })
        ),
    }),
    handleSubmit: (values, { props }) => {
        const model: WebhookModel = {
            ...props.webhookToUpdate,
            webhookFolderId: props.currentFolderId,
            title: values.title,
            description: values.description,
            url: values.url,
            webhookType: props.webhookTypes.find(wt => wt.id == values.webhookTypeId),
            accessToken: values.accessToken,
            configurationParameters: values.configurationParameters,
            parameters: values.parameters
        };
        if (props.webhookToUpdate) {
            props.finishUpdate(model);
        }
        else {
            props.finishCreate(model);
        }
    }
})(WebhookFormComponent);

export default WebhookForm;

const dropdownMenuStyle = css`
    margin-left: 68px;
`;

const SensitiveDataContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;
    margin-top: 32px;
`;

const modalStyle = css`
    width: 75vw;
    height: 80vh;
`;

const containerStyle = css`
    .delete-confirmation {
        color: ${color_colors_ocean};
        position: absolute;
        right: 32px;
        top: 16px;
        cursor: pointer;
        display: flex;
        div {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin-right: 16px;
            padding-top: 2px;
        }
    }
    .header-wrapper {
        .close-icon {
            margin-right: 32px;
            margin-top: 28px;
        }
        h2 {
            margin-left: 32px;
        }
        border-bottom: solid 1px ${color_shades_dark};
    }
    .switch-wrapper {
        margin-bottom: 32px;
    }
    .title-field {
        margin: 32px 32px 0px 32px;
    }
    .main-form-container {
        border: solid 1px ${color_shades_dark};
        border-radius: 6px;
        padding: 32px;
        margin: 8px 32px 32px 32px;
    }
    .sub-header {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${color_shades_darkest};
    }
    .sub-header-description {            
        margin-top: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darker};
    }
    .webhook-editor-text {
        margin-left: 0px;
        &.left {
            margin-right: 8px;
        }
        &.right {
            margin-left: 8px;
        }
    }
    .webhook-editor-selector {
        width: 50%;
        margin-left: 0px;
        &.left {
            padding-right: 8px;
        }
        &.right {
            padding-left: 8px;
        }
        .Select-control {
            border-radius: 8px;
            height: 60px;
        }
        .Select-input {
            margin: 0px;
        }
    }
    .add-parameter-link {
        margin-top: 16px;
        margin-bottom: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_colors_ocean};
        display: flex;
        flex-direction: row;
        cursor: pointer;
        &.less-top-padding {
            margin-top: 0px;
        }
        &.less-bottom-padding {
            margin-bottom: 0px;
        }
    }
    .remove-parameter {
        margin: 16px 0px 16px 0px;
        display: flex;
        flex-direction: row;
        div {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: ${color_shades_darkest};
            display: flex;
            flex-direction: row;
        }
        img {
            cursor: pointer;
        }
        span {
            border-bottom: solid 1px ${color_shades_dark};
            margin-bottom: 10px;
            &.left {
                width: 10px;
                margin-right:8px;
            }
            &.right {
                margin-left: 8px;
                flex-grow: 1;
                margin-right: 8px;
            }
        }
    }
`;