import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import RepromptModel from '../../models/features/api/Reprompt/RepromptModel';
import NewRepromptRequest from '../../models/features/api/Reprompt/NewRepromptRequest';
import IResult from '../../models/result/IResult';
import UpdateRepromptRequest from '../../models/features/api/Reprompt/UpdateRepromptRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import IContentWebhookContainer from '../definitions/IContentWebhookContainer';
import _ from 'lodash';

type RepromptState = {
    reprompt: RepromptModel[]
    isLoading: boolean
    errors: string[]
    isSaving: boolean
}

export default class RepromptContainer extends Container<RepromptState> {
    // default state
    state = {
        reprompt: [] as RepromptModel[],
        isLoading: false,
        errors: [],
        isSaving: false,
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        });
    }

    getFromId(id: string) {
        var matched = this.state.reprompt.filter(wm => wm.id == id);
        if (matched.length > 0) {
            return matched[0];
        }
    }

    addReprompt(applicationId: string, model: NewRepromptRequest): Promise<IResult<RepromptModel>> {
        this.setSaving(true);
        const addPromise = voicifyApi.addReprompt(applicationId, model);
        addPromise.then(repromptResult => {
            if (repromptResult.resultType == "Ok") {
                // add the new message to state immutably;
                const updated = [...this.state.reprompt, repromptResult.data];
                this.setState({
                    ...this.state,
                    reprompt: updated,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: repromptResult.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            console.log(error);
        });

        return addPromise;
    }

    updateReprompt(repromptId: string, model: UpdateRepromptRequest): Promise<IResult<RepromptModel>> {
        this.setSaving(true);
        var updatePromise = voicifyApi.updateReprompt(model);
        updatePromise.then(repromptResult => {
            if (repromptResult.resultType == "Ok") {

                // get immutable copy of array
                const updated = [...this.state.reprompt];
                // now update the item
                updated[updated.findIndex(m => m.id == repromptId)] = repromptResult.data;
                this.setState({
                    ...this.state,
                    reprompt: updated,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: repromptResult.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error?.toString()],
                isSaving: false
            });
        });
        return updatePromise;
    }

    deleteReprompt(repromptId: string): Promise<IResult<RepromptModel>> {
        const deletePromise = voicifyApi.deleteReprompt(repromptId);
        deletePromise.then(repromptResult => {
            if (repromptResult.resultType == "Ok") {
                // remove the message from state immutably;
                const updated = this.state.reprompt.filter(w => w.id !== repromptId);
                this.setState({
                    ...this.state,
                    reprompt: updated,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: repromptResult.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            console.log(error);
        });
        return deletePromise;
    }

    getRepromptForApp(applicationId: string) {
        this.setLoading(true);
        var promise = voicifyApi.getRepromptsForApp(applicationId);
        promise.then(repromptResult => {
            if (repromptResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    reprompt: repromptResult.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: repromptResult.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            console.log(error);
        })

        return promise;
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }

    private setSaving(isSaving: boolean) {
        this.setState({
            ...this.state,
            isSaving: isSaving
        });
    }
}
