import React from 'react';
import { css } from '@emotion/css';
import MediaItemModel from '../../../models/media/MediaItemModel';
import { text_blue, cool_grey, dark_grey_blue, ocean_blue, silver_three, dark_grey_blue_10, denim_blue, color_variants_shadow_10 } from '../../../constants/colors';
import MultiFileAudioPlayer from '../../../components/messagePreviews/MultiFileAudioPlayer';
import TextField from '../../../components/forms/TextField';
import TextAreaField from '../../../components/forms/TextAreaField';
import SmallOverflowMenu from '../../../components/general/overflowMenu/SmallOverflowMenu';
import IResult from '../../../models/result/IResult';
import MediaItemLinkedContent from '../../../models/media/MediaItemLinkedContent';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import SimplePaginatedList from '../../../components/general/SimplePaginatedList';
import SimpleItemDisplayInfo from '../../../models/media/SimpleItemDisplayInfo';
import moment from 'moment';
const deleteIcon = require('../../../content/images/bin-blue.svg');
const deleteCircleIcon = require('../../../content/images/delete-circle.svg');
const yellowInfoIcon = require('../../../content/images/info-circle-yellow.svg');
const deleteRedIcon = require('../../../content/images/bin-red.svg');
const replaceIcon = require('../../../content/images/synchronize-arrows-square-blue.svg');
const attachmentIcon = require('../../../content/images/attachment.svg');
const restoreIcon = require('../../../content/images/undo.svg');
const downloadIcon = require('../../../content/images/download-bottom-blue.svg');

interface ExpandedMediaItemProps {
    mediaItem: MediaItemModel
    index: number
    column?: number
    onCollapse: (i: number) => void
    onUpdateMediaItem: (item: MediaItemModel, index: number) => void
    onAttachMediaItem: (item: MediaItemModel) => void
    onRestoreMediaItem: (item: MediaItemModel, index: number) => void
    onDeleteItem: (item: MediaItemModel, linkedContent: MediaItemLinkedContent) => void
    onReplaceMediaItem: (item: MediaItemModel, file: File, index: number) => Promise<IResult<MediaItemModel>>
    loadLinkedContent?: (mediaItemId: string) => Promise<IResult<MediaItemLinkedContent>>
    generateLinkedContentLinks?: (linkedContent?: MediaItemLinkedContent) =>  SimpleItemDisplayInfo[]
}

interface ExpandedMediaItemState {
    mediaURL: string,
    name: string,
    caption: string,
    potentialURL: string,
    potentialName: string,
    linkedContent: MediaItemLinkedContent
    showLinkedContentItems: boolean,
    showEditWarning: boolean
}

class ExpandedMediaItem extends React.Component<ExpandedMediaItemProps, ExpandedMediaItemState> {     
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);
    
        // Set the state directly. Use props if necessary.
        this.state = {
            mediaURL: "",
            name: "",
            caption: "",
            potentialURL: "",
            potentialName: "",
            showEditWarning: true,
            linkedContent: {
                contentItems: [],
                mediaItemId: ""
            },
            showLinkedContentItems: false
        }
    }   
    componentDidMount() {
        const item = this.props.mediaItem;
        this.props.loadLinkedContent(item.id).then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    showEditWarning: true,
                    linkedContent: result.data                  
                })
            }
        });
        this.setState({
            ...this.state,
            mediaURL: item.url,
            name: item.name,
            showEditWarning: true,
            caption: item.caption,
        });
    }
    toggleShowLinkedContentItems() {
        if(this.state.linkedContent != null && this.state.linkedContent.contentItems.length > 0)
        {
            this.setState({
                ...this.state,
                showLinkedContentItems: !this.state.showLinkedContentItems
            });
        }
    }
    disableEditWarning() {
        this.setState({
            ...this.state,
            showEditWarning: false
        })
    }
    handleUpdateTitle(changeEvent) {
        const item = this.props.mediaItem;
        item.name = changeEvent.target.value;
        this.props.onUpdateMediaItem(item, this.props.index);
    }
    handleUpdateCaption(changeEvent) {
        const item = this.props.mediaItem;
        item.caption = changeEvent.target.value;
        this.props.onUpdateMediaItem(item, this.props.index);
    }
    handleChangeCaption(changeEvent) {
        this.setState({
            ...this.state,
            caption: changeEvent.target.value
        });
    }
    handleChangeTitle(changeEvent) {
        this.setState({
            ...this.state,
            name: changeEvent.target.value
        });
    }
    handleOptionSelected(option) {
        switch(option) {
            case("Download"):
                this.downloadItem()
                break;
            case("Delete"):
                this.props.onDeleteItem(this.props.mediaItem, this.state.linkedContent);
                break;
            case("Replace with..."):
                this.replaceItem();
                break;
        }
    }
    forceDownload(blob, filename) {
        var a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
    downloadItem() {
        const item = this.props.mediaItem;
        fetch(item.url, {
            headers: new Headers({
                'Origin': location.origin
            }),
            mode: 'cors'
        })
        .then(response => response.blob())
        .then(blob => {
            let blobUrl = window.URL.createObjectURL(blob);
            this.forceDownload(blobUrl, item.name);
        })
        .catch(e => console.error(e));
    }
    replaceItem() {
        var input = document.createElement('input');
        input.type = 'file';
        switch(this.props.mediaItem.mediaType)
        {
            case("ImageFile"):
                input.accept = ".png, .jpg";
                break;            
            case("AudioFile"):
                input.accept = ".mp3";
                break; 
            case("VideoFile"):
                input.accept = ".mp4";
                break;                
        }
        input.click();
        input.onchange = e => { 
            this.handleReplaceFileSelection(e);
        }
    }
    handleReplaceFileSelection(event) {
        var newURL = URL.createObjectURL(event.target.files[0]);
        this.setState({
            ...this.state,
            potentialURL: newURL,
            potentialName: event.target.files[0].name
        });
        this.props.onReplaceMediaItem(this.props.mediaItem, event.target.files[0], this.props.index).then((result) => {
            this.setState({
                ...this.state,
                mediaURL: this.state.potentialURL,
                name: this.state.potentialName,
                potentialName: "",
                potentialURL: ""
            });
        }).catch((error) => {
        });
    
    }
    renderMedia() : JSX.Element {
        const item = this.props.mediaItem;
        switch(item.mediaType)
        {
            case("ImageFile"):
                return (
                <div className={mediaCSS}>
                    <div className={"col"}>
                        <img src={this.state.mediaURL}></img>
                    </div>
                </div>
            )
            case("AudioFile"):
                return (                    
                <div className={audioPreview}>
                    <MultiFileAudioPlayer
                        src={[this.state.mediaURL]}
                        isLoading={false}
                        hasFile={true}
                        onLoad={() => null}
                    />
                </div>
            )
            case("VideoFile"):
                return (
                <div className={videoPreview}>
                    <video controls>
                        <source src={this.state.mediaURL} type="video/mp4" />
                        Your browser does not support videos.
                    </video>
                </div>
            )
        }
    }
    render() {
        const item = this.props.mediaItem;             
        const linkedContentData = this.props.generateLinkedContentLinks(this.state.linkedContent);  
        const lastEditedDateString = moment(item.modifiedDate).format("MMM DD, YYYY");
        const modifiedUser = (item.modifiedByUser == null || item.modifiedByUser.lastName == null) ? {
            firstName: null,
            lastName: null,
            imageUrl: null
        } 
        : 
        item.modifiedByUser;
        return (
            <div className={`${expandedContainer} ${this.props.column == null ? "" : ("grid _" + this.props.column)}`}>
                {this.props.column != null ? <div className={`${triangleStyle} _${this.props.column}`}></div> : null}
                <div className={`${expandedInnerDiv} ${this.props.column == null ? 'table' : ""}`}>
                    <div className={topRow}>
                        <div className={"collapse text"} onClick={() => this.props.onCollapse(this.props.index)}>Collapse</div>  
                        <div className={"options"}>
                            <SmallOverflowMenu isActive={false} 
                                onOptionSelected={this.handleOptionSelected.bind(this)} 
                                options={[
                                { label: 'Delete', icon: deleteIcon, hoverIcon: deleteRedIcon, isDestructive: true },
                                { label: 'Replace with...', icon: replaceIcon, hoverIcon: replaceIcon },
                                { label: 'Download', icon: downloadIcon, hoverIcon: downloadIcon }]}
                                label={"Options"}>
                            </SmallOverflowMenu>
                        </div>
                    </div>
                    {
                        (linkedContentData.length > 1 && this.state.showEditWarning) &&
                        <div className={editWarning}>
                            <img className={`leftImage`} src={yellowInfoIcon} />
                            <div className={`warningText`}>Edits to this file will affect all instances of it</div>
                            <span />
                            <img className={`rightImage`} onClick={this.disableEditWarning.bind(this)} src={deleteCircleIcon}/>
                        </div> 
                    }
                    <div className={contentItemCount}>
                        <div onClick={this.toggleShowLinkedContentItems.bind(this)} className={`count ${linkedContentData.length > 0 ? ``:`disabled`}`}>
                            {linkedContentData.length > 0 ? (this.state.showLinkedContentItems
                                ? <ToggleIndicator direction="up" />
                                : <ToggleIndicator direction="down" />) : null}
                            {`Used by ${linkedContentData.length} response${linkedContentData.length === 1 ? `` : `s`}`}
                        </div>
                        <span className={`${fill} line`}></span>   
                    </div>
                    {this.state.showLinkedContentItems ? 
                        <div>
                            <div className={`new-tab ${unaccentedText}`}>Opens in a new tab</div>
                            <SimplePaginatedList pageSize={5} displayInfo={linkedContentData} />
                        </div>
                        :
                        null
                    }
                    { this.renderMedia() }
                    <div className={`${inputContainer} title`}>      
                        <TextField 
                            name="name" 
                            value={this.state.name} 
                            label="Title" 
                            placeholder="Enter Title" 
                            useForm={false}
                            onChange={this.handleChangeTitle.bind(this)}
                            disabled={(item.isDisabled != null && item.isDisabled)}
                            onBlur={this.handleUpdateTitle.bind(this)}
                            />                               
                    </div>
                    <div className={inputContainer}>                   
                        <TextAreaField 
                            name="name" 
                            value={this.state.caption}
                            label="Description - (Optional)" 
                            disabled={(item.isDisabled != null && item.isDisabled)}
                            placeholder="Enter Description" 
                            onChange={this.handleChangeCaption.bind(this)}
                            onBlur={this.handleUpdateCaption.bind(this)}
                            />
                    </div>                  
                    <div className={`${unaccentedText} ${lastEditedRow}`}>
                        Last edited on <span className={accentedText}>{lastEditedDateString}</span> {modifiedUser.firstName === null ? "" : " by"}                          
                        {modifiedUser.firstName !== null && 
                            <div className={avatarStyle}>
                                {modifiedUser.imageUrl != null ? 
                                    <img className={avatarImageStyle}
                                        src={modifiedUser.imageUrl}/>
                                    : 
                                    <div className={nameStyle}>
                                        <span>{`${modifiedUser.firstName[0]} ${modifiedUser.lastName[0]}`}</span>
                                    </div>
                                }
                            </div>}
                        {modifiedUser.firstName !== null && <div className={accentedText}>{`${modifiedUser.firstName} ${modifiedUser.lastName}`}</div>}
                    </div>                    
                    
                        <div className={bottomRow}>
                            <span className={fill}></span> 
                            {(item.isDisabled != null && item.isDisabled) ?
                                <div onClick={() =>this.props.onRestoreMediaItem(item, this.props.index)} className={"attach"}><span>Restore</span><img src={restoreIcon}/></div> :
                                <div onClick={() =>this.props.onAttachMediaItem(item)} className={"attach"}><span>Attach</span><img src={attachmentIcon}/></div>
                            }                   
                        </div>
                
                </div>                
            </div>
        )
    }
}


const nameStyle = css`
    border-radius: 50%;
    color: white;
    background: ${denim_blue};
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const avatarStyle = css`
    padding: 4px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -10;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: solid 1px ${silver_three};
    box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
`
const avatarImageStyle = css`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`

const contentItemCount = css`
    display: flex;
    flex-direction: row;
    .count{
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        padding-right: 5px;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        &.disabled {
            cursor: default;
            color: ${cool_grey};
        }
        color: ${text_blue};
        cursor: pointer;
        text-align:left;
        flex-direction: row;
        display:flex;
        padding-bottom: 15px;
        div {
            margin-right: 10px;
            margin-top: 6px;
        }
    }
`;

const topRow = css`
    flex-direction: row;
    display: flex;
    .options {
        margin-left: auto; 
        margin-right: 0;
    }
    .collapse {
        margin-left: 5px;
        padding-bottom: 10px;
        cursor: pointer;
        text-align:left
    }    
    .text {        
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        padding-right: 5px;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: ${text_blue};
    }
    .triangle {
        margin-right: 20px;
    }
`;

const lastEditedRow = css`
    display: flex;
    flex-direction: row;
`;
const expandedContainer = css`
    padding: 0px 25px 0px;        
    &.grid {        
        border-radius: 4px;
        border: solid 1px #cccccc;
        box-shadow: 0 2px 4px 0 ${color_variants_shadow_10};
    }
    
`;

const editWarning = css`
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 4px;
    border: solid 1px #f6a12f;
    background-color: rgb(246,161,47,.1);
    display: flex;
    margin-bottom: 15px;
    flex-direction: row;
    .leftImage {
        padding-left: 10px;
        padding-right: 10px;
    }
    .rightImage {
        padding-right: 10px;
        cursor: pointer;
    }
    .warningText {
        padding-top: 9px;
    }
    span {
        flex-grow: 1;
    }
`;


const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: white;
    margin-left: auto;
    &._0 {
        margin-right: 600px;
    }
    &._1 {
        margin-right: 30px;
    }
`;

const expandedInnerDiv = css`
    padding: 25px 0px 20px;
    &.table {
        border-top: 1px solid #cccccc;
    }
`;

const videoPreview = css`
    video {
        width: 100%;
        border-radius: 4px;
    }
`;

const audioPreview = css`
    div {
        border-radius: 4px;
        &.rc-slider-handle {
            border-radius: 50%;
        }
    }
`;

const inputContainer = css`
    &.title {
        margin-top: 16px;
    }
    width: 100%;
    .field-container {
        height: 45px;
    }
    label {
        font-size: 12px;
    }
    textarea {
        height: 95px !important;
        margin-right: 3px;
        resize: none;
        margin-bottom: 3px;
        margin-top: 3px;        
    }   
`;

const unaccentedText = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    letter-spacing: normal;
    padding-bottom: 20px;
    color: ${cool_grey};
    &.new-tab {
        padding-bottom: 10px;
    }
`;

const accentedText = css`
    color: ${dark_grey_blue};
    font-weight: bold;
    padding-left: 3px;
    padding-right: 5px;
`;

const bottomRow = css`
    display: flex;
    flex-direction: row;
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${ocean_blue};
    .attach {
        cursor: pointer;
        margin-right: -5px;
        span {
            position: relative;
            top: -5;
        }
    }    
    span {
        padding-left: 10px;
        padding-top: 3px;
    }
    div {
        span {
            padding-right: 10px;
        }
    }
`;

const fill = css`
    flex: 1;
    &.line {
        margin-left: 5px;
        border-bottom: solid 1px #cccccc;        
        margin-bottom: 24px;
    }
`;

const mediaCSS = css`
    width: 100%;
    height: 0px;
    background: black;
    padding-bottom: 75%;
    position: relative;
    .col {    
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            max-height: 100%;
            max-width: 100%;
            min-height: 100px;
            border-radius: 4px;
            object-fit: contain;
        }  
    }
`;

export default ExpandedMediaItem;