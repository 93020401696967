import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaSmallWrapper from './AlexaSmallWrapper';

const screenStyle = css`
.body-container {
    margin: auto 180px 180px 180px;
}

`
class AlexaSmallListTemplate2Preview extends ScreenPreviewComponent {

    render() {
        return (
            <AlexaSmallWrapper className={screenStyle}>
                {this.state.foregroundImageUrl ? <img className="background-image" src={this.state.foregroundImageUrl} /> : null}
                {this.state.foregroundImageUrl ? <div className="background-cover" /> : null}
                <div className="body-container">
                    <p className="body-text">
                        <span>{this.state.displayText}</span>
                    </p>
                </div>
            </AlexaSmallWrapper>
        )
    }
}

export default AlexaSmallListTemplate2Preview;