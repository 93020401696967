import React, { useEffect, useState } from "react";
import SlotCard from "./SlotCard";
import { color_shades_dark } from "../../../constants/colors";
import { css } from "@emotion/css";
import Loader from "../../../components/general/Loader";
import Button from "../../../components/general/Button";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import SlotItem from "./SlotItem";
import EditableSlot from "../../../models/interactionModel/EditableSlot";
import { SlotsEditorFormData, SlotsEditorFormProps } from "./SlotsEditorForm";
import PageError from "../../../components/general/PageError";
import FooterBar from "../../../components/structure/FooterBar";
import styled from "@emotion/styled";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import useWindowSize from "../../../hooks/UseWindowSize";
import ModalHeader from "../../../components/general/ModalHeader";

export const INTENT_FORM_PREFIX: string = "intent";
export const maxSlotNameDisplaySize: number = 25;

const SlotsEditor = (props: SlotsEditorFormProps & FormikProps<SlotsEditorFormData>) => {

    const windowSize = useWindowSize();

    const { isValid, dirty } = props;
    const [activeItemIndex, setActiveItemIndex] = useState(-1);
    const [errorMessages, setErrorMessages] = useState([] as string[]);
    const [slotGroups, setSlotGroups] = useState([] as string[]);

    const intent = props.values.intent;
    const intentName: string = props.intent?.displayName;
    const enableSave: boolean = dirty && isValid;

    useEffect(function activateFirstNonDeletedItem() {
        if (intent?.slots?.length > 0) {
            //sort slots by disallowed slots first, required second, required slot groups third, then alphabetically
            intent?.slots?.sort((a, b) => {
                if (a.disallowed && !b.disallowed) return -1;
                if (!a.disallowed && b.disallowed) return 1;
                if (a.required && !b.required) return -1;
                if (!a.required && b.required) return 1;
                if (a.requiredSlotGroupIds?.length && !b.requiredSlotGroupIds?.length) return -1;
                if (!a.requiredSlotGroupIds?.length && b.requiredSlotGroupIds?.length) return 1;
                if (a.requiredSlotGroupIds?.length || b.requiredSlotGroupIds?.length) {
                    return a.requiredSlotGroupIds[0].localeCompare(b.requiredSlotGroupIds[0]);
                } else {
                    return a.name.localeCompare(b.name);
                }
            });
            const index = intent?.slots?.findIndex(s => !s.isDeleted);
            if (index >= 0) {
                toggleItemActivationByIndex(index);
            }
        }
    }, []);

    useEffect(() => {
        const newSlotGroups = [];
        if (intent?.slots?.length > 0) {
            intent?.slots?.forEach(slot => {
                slot?.requiredSlotGroupIds?.forEach(groupId => {
                    if (groupId?.length && !newSlotGroups.includes(groupId)) {
                        newSlotGroups.push(groupId);
                    }
                });
            });
            // order new slot groups alphabetically 
            newSlotGroups.sort((a, b) => a.localeCompare(b));
            setSlotGroups(newSlotGroups);
        }
        else {
            setSlotGroups([]);
        }
    }, [intent, intent?.slots]);

    const toggleItemActivationByIndex = (index: number) => {
        setActiveItemIndex(index);
    };

    const onSubmitIntentSlotsUpdate = () => {
        props.validateForm();
        props.submitForm();
    };

    const addSlot = () => {

        let newEditableSlot: EditableSlot = {
            name: "New",
            required: false,
            isOriginal: false,
            isAdded: true,
            isDeleted: false,
            isModified: true,
            originalName: "",
            entityName: ""
        }

        const idx: number = intent?.slots?.length ?? 0;
        const fieldName = `${INTENT_FORM_PREFIX}.slots.${idx}`;
        props.setFieldValue(fieldName, newEditableSlot);

        toggleItemActivationByIndex(idx);
    };

    const slot: EditableSlot = intent?.slots?.[activeItemIndex];

    const renderSlotMenuItem = (slot: EditableSlot, index: number, arrayHelpers: ArrayHelpers) => {
        const isActive: boolean = index === activeItemIndex;
        // Do not display deleted slots
        return (
            !slot.isDeleted &&
            <SlotItem
                {...props}
                toggleItemActivationByIndex={toggleItemActivationByIndex}
                applicationId={props.applicationId}
                nlpEntities={props.values.nlpEntities}
                intent={intent}
                slot={slot}
                isActive={isActive}
                key={index}
                formPrefix={INTENT_FORM_PREFIX}
                formIndex={index}
            />
        )
    };

    const renderSlotItemsList = () => {
        if (!intent.slots?.length) {
            return <LeftEmptyView>
                Add a slot to extract entities for this intent
            </LeftEmptyView>
        }

        return (
            <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - 250}>
                <SlotItemsListWrapper>
                    {// Newly added slots on the top
                        //Sort slots by required slot group
                        intent?.slots?.some(v => v.isAdded) &&
                        <FieldArray validateOnChange={true}
                            name={`${INTENT_FORM_PREFIX}.slots`}
                            render={arrayHelpers => (
                                intent.slots
                                    ?.map((slot, index) => {
                                        if (!slot.isAdded) return null;
                                        return renderSlotMenuItem(slot, index, arrayHelpers);
                                    })

                            )}
                        />
                    }
                    <FieldArray validateOnChange={true}
                        name={`${INTENT_FORM_PREFIX}.slots`}
                        render={arrayHelpers => (
                            intent.slots
                                ?.map((slot, index) => {
                                    if (slot.isAdded) return null;
                                    return renderSlotMenuItem(slot, index, arrayHelpers);
                                })
                        )}
                    />
                </SlotItemsListWrapper>
            </CustomScrollbars>
        )
    };

    return (
        <SlotsEditorWrapper>
            <ModalHeader title={`Slots for ${intentName}`} onClose={() => props.handleCloseModal()} className={modalHeaderStyle} />
            <HorizontalSeparator />
            {props.isLoading ?
                <Loader className={customLoaderStyle} />
                :
                <>
                    <ModalContentWrapper>
                        <SlotListWrapper>
                            <SlotListHeader>
                                <div>Slots</div>
                                <Button
                                    themes={["primary-small", "start"]}
                                    disabled={props.isLoading}
                                    loading={props.isLoading}
                                    onClick={addSlot}
                                    text="Add a Slot"
                                />
                            </SlotListHeader>
                            <HorizontalSeparator />
                            {renderSlotItemsList()}
                        </SlotListWrapper>
                        <SlotEditWrapper>
                            {slot && !slot?.isDeleted ?
                                <SlotCard
                                    {...props}
                                    applicationId={props.applicationId}
                                    nlpEntities={props.values.nlpEntities}
                                    intent={intent}
                                    slot={slot}
                                    key={activeItemIndex}
                                    allSlotGroups={slotGroups}
                                    formPrefix={INTENT_FORM_PREFIX}
                                    formIndex={activeItemIndex}
                                />
                                :
                                <EmptyView>
                                    Select a Slot to see more details and manage values
                                </EmptyView>
                            }
                        </SlotEditWrapper>
                    </ModalContentWrapper>
                </>
            }
            <PageError
                errors={[...errorMessages ?? []]}
                onClear={() => {
                    setErrorMessages([]);
                }}
            />
            <CustomFooter usedInModal>
                <SubmitButton
                    type="button"
                    themes={['primary']}
                    text="Apply Changes"
                    onClick={() => onSubmitIntentSlotsUpdate()}
                    disabled={props.isLoading || !enableSave}
                    loading={props.isLoading} />
                <Button
                    type="button"
                    themes={['secondary']}
                    text="Cancel"
                    onClick={() => props.handleCloseModal()}
                    disabled={props.isLoading}
                    loading={props.isLoading} />
            </CustomFooter>
        </SlotsEditorWrapper>
    );
};

export default SlotsEditor;

const SlotItemsListWrapper = styled.div`
    margin: 32px 32px 0 32px;
`;

const ModalContentWrapper = styled.div`
    display: flex;
    width: 100%;
    // subtract the header and footer height
    height: calc(100vh - 160px);
`;

const modalHeaderStyle = css`
    width: 100%;
    border-bottom: none;
`;

const SlotsEditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SlotListWrapper = styled.div`
    flex: 1;
`;

const SlotListHeader = styled.div`
    button {
        margin: 0 16px 16px 0; 
    }
    display: flex;
    justify-content: space-between; 
    margin: 16px 32px 0 32px;
`;

const SlotEditWrapper = styled.div`
    flex: 1;
    border-left: 1px solid ${color_shades_dark};
`;

const customLoaderStyle = css`
    position: relative;
    top: auto;
    margin-top: 30px;
    margin-bottom: 30px;    
`;

const CustomFooter = styled(FooterBar)`
    z-index: auto;
`;

const SubmitButton = styled(Button)`
    margin-right: auto;
`;

const EmptyView = styled.div`
    background: white;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    color: ${color_shades_dark};
    padding: 24px;
    margin: 32px;
`;

const LeftEmptyView = styled(EmptyView)`
    margin: 32px;
`;

