import { Container, Provider } from "unstated";
import * as voicifyApi from "../../api";

import _ from "lodash";
import AmazonAuthUrlRequest from "../../models/deployments/api/AmazonAuthUrlRequest";
import GoogleAuthUrlRequest from "../../models/deployments/api/GoogleAuthUrlRequest";
import ApplicationLinksByPlatform from "../../models/applications/api/ApplicationLinksByPlatform";

type DeploymentAccountsState = {
    accountToApplicationLinks: ApplicationLinksByPlatform[],
    errors: string[]
}
export default class DeploymentAccountsContainer extends Container<DeploymentAccountsState>{
    state = {
        accountToApplicationLinks: [] as ApplicationLinksByPlatform[],
        errors: []
    }

    reset () {
        this.setState({ errors: [], accountToApplicationLinks: [] })
    }

    async updateDeploymentAccountLinksToApplications(organizationId: string) {
        try {
            this.reset();
            let linksByPlatform: ApplicationLinksByPlatform[] = [];
            
            const amazonLinksPromise = voicifyApi.getApplicationsLinkedToAmazon(organizationId);
            const dialogFlowLinksPromise = voicifyApi.getApplicationsLinkedToDialogflow(organizationId);
    
            const results = await Promise.all([amazonLinksPromise, dialogFlowLinksPromise]);
            results.forEach(r => {
                if(r.resultType == "Ok") {
                    r.data?.forEach(link => {
                        const existingLink = linksByPlatform.find(l => l.id == link.id);

                        if(existingLink) {
                            existingLink.applicationIds = [...new Set([...existingLink.applicationIds, ...link.applicationIds])];
                        } else {
                            linksByPlatform.push(link);
                        }
                    })
                }
            });
            
            if(linksByPlatform?.length > 0) {
                this.setState({ ...this.state, accountToApplicationLinks: linksByPlatform })
            }

        } catch (err) {
            this.setState({
                ...this.state,
                errors: [err.toString()]
            })
        }
    }

    getAmazonAuthUrl(request: AmazonAuthUrlRequest) {
        return voicifyApi.getAmazonAuthUrl(request)
            .then(results => {
                if (results.resultType != "Ok") {
                    this.setState({
                        ...this.state,
                        errors: results.errors
                    })
                }
                else {
                    return results.data
                }
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    errors: err
                })
            })
    }

    getGoogleAuthUrl(request: GoogleAuthUrlRequest) {
        return voicifyApi.getDialogflowAuthUrl(request)
            .then(results => {
                if (results.resultType != "Ok") {
                    this.setState({
                        ...this.state,
                        errors: results.errors
                    })
                }
                else {
                    return results.data
                }
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    errors: err
                })
            })
    }

    removeGoogleCredentials(tokenId: string) {
        if (!tokenId) return;

        const links = this.state.accountToApplicationLinks;
        _.remove(links, l => l.id === tokenId);

        // This is used for either Google Actions Builder or Dialogflow to remove Google account by tokenId
        return voicifyApi.removeGoogleCredentials(tokenId)
            .then(results => {
                if (results.resultType == "Ok") {
                    this.setState({ ...this.state, accountToApplicationLinks: links })
                    return results
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: results.errors
                    })
                }
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    errors: err
                })
            })
    }

    removeAmazonCredentials(tokenId: string) {
        if (!tokenId) return;

        const links = this.state.accountToApplicationLinks;
        _.remove(links, l => l.id === tokenId)
        this.setState({ ...this.state, accountToApplicationLinks: links })
        return voicifyApi.removeAmazonCredentials(tokenId)
            .then(results => {
                if (results.resultType == "Ok") {
                    this.setState({ ...this.state, accountToApplicationLinks: links })
                    return results
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: results.errors
                    })
                }
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    errors: err
                })
            })
    }

}