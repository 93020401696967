import React, { useEffect, useState } from 'react';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import UpdateApplicationForm from './UpdateApplicationForm';
import PageContainer from '../../../components/structure/PageContainer';
import LanguageContainer from '../../../hooks/LanguageContainer';
import ApplicationEnvironmentsContainer, { ApplicationEnvironmentsOption } from '../../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationEnvironmentModel from '../../../models/applications/api/environments/ApplicationEnvironmentModel';

interface ApplicationInfoSettingsProps {
    appContainer: ApplicationContainer;
    applicationId: string;
    environmentOption: ApplicationEnvironmentsOption;
    environmentsContainer: ApplicationEnvironmentsContainer;
};

const ApplicationInfoSettings: React.FC<ApplicationInfoSettingsProps> = (props) => {

    const [environment, setEnvironment] = useState(null as ApplicationEnvironmentModel);

    const languagesContainer = LanguageContainer.useContainer();

    useEffect(() => {
        getEnvironmentByOption();
    }, [props.applicationId, 
        props.environmentOption]);

    const getEnvironmentByOption = async (): Promise<ApplicationEnvironmentModel> => {
        await props.environmentsContainer.loadEnvironments(props.applicationId);
        if(!props.environmentOption) return;
        
        // If app has Live env then environmentOption: "live" passed from ApplicationSettingsTab 
        // All new apps get both envs and all migrated ones do as well.
        // If some strange app only has one env and it is a Draft env then "draft" option passed in
        const selectedEnvironment: ApplicationEnvironmentModel = props.environmentsContainer.findEnvironmentByOption(props.applicationId, props.environmentOption);
        setEnvironment(selectedEnvironment);
        return selectedEnvironment;
    };
    
    return (
        <PageContainer>
            <UpdateApplicationForm 
                languageIds={languagesContainer.currentSelectedLanguages.map(c => c.id)} 
                appContainer={props.appContainer}
                app={props.appContainer.state.currentApp}
                key={languagesContainer.currentSelectedLanguages.map(c => c.id).join('|')}
                environmentOption={props.environmentOption}
                environment={environment}
                environmentsContainer={props.environmentsContainer}
            />
        </PageContainer>
    );
}

export default ApplicationInfoSettings;