import React from 'react';
import { css } from '@emotion/css';
import _, { Dictionary } from 'lodash';
import GroupedMultiSelector, { ItemGroup, SelectorPosition } from './GroupedMultiSelector';
import LanguageModel from '../../models/features/api/LanguageModel';
import { getLanguageName, getFlagIconForLanguage, getRegionyNameForLanguage, getLanguageNameForLanguage } from '../../models/extensions/languages';
import LanguageCollectionAvatar from '../general/LanguageCollectionAvatar';
import { cool_grey, color_shades_dark, color_shades_light, color_shades_darkest } from '../../constants/colors';

interface LanguageMultiSelectorProps {
    languages: LanguageModel[]
    selectedLanguageIds: string[]
    onChange: (selectedLanguages: LanguageModel[]) => void
    label?: string
    position?: SelectorPosition
    isCollapsed?: boolean
    id?: string
    disabled?: boolean
}

class ContentLanguageMultiSelector extends React.Component<LanguageMultiSelectorProps> {
    handleChange(selectedLanguages: LanguageModel[]) {
        this.props.onChange(selectedLanguages);
    }

    getLanguageGroups(): ItemGroup<LanguageModel>[] {
        var dict = _.groupBy(this.props.languages, l => l.languageGroupCode)
        var groups = Object.keys(dict).map(key => ({
            key: key,
            label: `${key.toUpperCase()} - ${getLanguageName(key)}`,
            subItems: dict[key].map(lang => ({
                data: lang,
                value: lang.id,
                icon: getFlagIconForLanguage(lang),
                label: getRegionyNameForLanguage(lang),
                groupKey: key,
                isSelected: this.props.selectedLanguageIds == null ? false : this.props.selectedLanguageIds.some(id => id == lang.id)
            }))
        }));
        groups = _.sortBy(groups, group => group.key);
        return groups;
    }
    renderCollapsedSelection(selectedLangs: LanguageModel[]) {
        return <>
            {selectedLangs && selectedLangs.length > 0 ?
                <div className="avatar-container">
                    <LanguageCollectionAvatar languages={selectedLangs} limit={3} />
                    <p className="selected-text">{selectedLangs[0].languageGroupCode.toUpperCase()} </p>
                </div>
                : <p className="empty-text">Select a language</p>}
        </>
    }
    renderFullSelection(selectedLangs: LanguageModel[]) {
        return <div className="full-language-selector">
            <LanguageCollectionAvatar languages={selectedLangs} limit={3} />
            <p className={labelStyle}>{selectedLangs.length > 0 ? selectedLangs.map(l => `${getLanguageNameForLanguage(l)} (${getRegionyNameForLanguage(l)})`).join(', ') : 'Select a language'}</p>
        </div>

    }
    render() {
        const selectedLangs = this.props.languages.filter(l => this.props.selectedLanguageIds == null ? false : this.props.selectedLanguageIds.some(id => id == l.id));
        return (
            <div id={this.props.id} className={`language-grouped-selector ${containerStyle} ${this.props.isCollapsed ? 'minified' : 'full'} ${this.props.disabled ? 'disabled' : ''}`}>
                <GroupedMultiSelector
                    groupToggle={groupToggleStyle} 
                    label={this.props.label}
                    groups={this.getLanguageGroups()}
                    onChange={(ids) => this.handleChange(this.props.languages.filter(l => ids.some(id => id == l.id)))}
                    selectedValues={this.props.selectedLanguageIds}
                    withGroupSelection={true}
                    restrictGroups={true}
                    position={this.props.position ?? 'bottom'}
                    emptyText="You must select at least one language for your app first."
                    disabled={this.props.disabled}
                    innerSelectionRender={
                        () => this.props.isCollapsed ? this.renderCollapsedSelection(selectedLangs) : this.renderFullSelection(selectedLangs)
                    } />
            </div>

        )
    }
}

const groupToggleStyle = css`
    margin-left: auto;
    margin-right: 0;
    height: 8px;
    width: 8px;
`

const labelStyle = css`
    padding: 0 8px;
`
const containerStyle = css`
    margin-left: auto;
    margin-right: 0;
    flex: 1;
    &.full {
        .field-wrapper {
            display: block;
            label {
                margin-left: 16px;
                font-size: 14px;
                color: ${color_shades_darkest};
            }
            .selection-section {
                margin-top: 8px;
            }
        }
    }
    &.minified {
        .field {
            .selection {
                border: none;
                background: transparent;
            }
        }
        .field-wrapper {        
            max-width: 350px;
            margin-left: auto;
        }
    }
    &.bordered {
        border-right: 1px solid #ccc;
    }
    &.disabled {
        .field .selection{
            background: ${color_shades_light};
            cursor: not-allowed;
        }
    }
    .field {
        .selection {
            border-radius: 4px;
            .empty-text {
                text-align: right;
                margin-left: auto;
                margin-right: 12px;
                width: 100%;
            }
        }
    }

    .sub-item {
        img {
            border-radius: 50%;
            border: 1px solid ${cool_grey};            
        }
    }

    .avatar-container {
        margin-right: 12px;
        margin-left: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .selected-text {
            margin: 0 12px;
        }
        >div {
            justify-content: flex-end;
        }
    }

    .full-language-selector {
        display: flex;
        align-items: center;
    }
`;


export default ContentLanguageMultiSelector;