import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue, green_1, faded_red } from '../../../constants/colors';

const containerStyle = css`
    padding: 32px;

    .type-selector {
        margin: 16px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        .selector-label {
            font-family: Muli;
            font-size: 18px;
            color: ${dark_grey_blue};
            margin-right: 16px;
            flex: 0;
        }

        .selector {
            margin: 0;
            margin-left: auto;
            margin-right: 0;
            width: 100%;

        }
        .Select-value, .Select-option, .Select-control{
            height: 48px;
        }
    }
    h4 {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #304562;
        margin-bottom: 24px;
    }

    p {
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #304562;
    }

    .update-button {
        width: 130px;
        margin: 0;
    }

    .reset-button {
        padding: 0;
        border: none;
        margin-right: 0;
        margin-left: auto;
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 0;
        height: 32px;
        img {
            margin-right: 8px;
        }
    }

    .update-container {
        display: flex;
        align-items: center;
        p {
            margin-left: 16px;
        }
    }

    .success-label {
        font-size: 16px;
        font-weight: 600;
        color: ${green_1};
    }

    .error-label {
        font-size: 16px;
        font-weight: 600;
        color: ${faded_red};
    }
`
const ResponseBuilderContainer: React.SFC = (props) => (
    <div className={containerStyle}>
    {props.children}
    </div>
);

export default ResponseBuilderContainer;