import React from 'react';
import { css } from '@emotion/css';
import ResponseTemplateTypeModel from '../../../models/features/api/MediaResponses/ResponseTemplateTypeModel';
import { ocean_blue, cool_grey, pale_grey, pale_grey_two, silver_three, silver } from '../../../constants/colors';
const micIcon = require('../../../content/images/microphone-off.svg');

interface ResponseTemplateListItemProps {
    responseTemplate: ResponseTemplateTypeModel
    isSelected?: boolean
    onSelected: (template: ResponseTemplateTypeModel) => void
    viewOnly?: boolean
    top?: boolean
    bottom?: boolean
}
const containerStyle = css`
    background: white;
    display: flex;
    flex-direction: row;
    padding: 24px 32px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    flex: 1;
    align-items: stretch;

    &.selectable {
        border: 1px solid ${cool_grey};

        &:hover {
            background: ${silver};
        }
        &.top {
            border-radius: 10px 10px 0 0;
        }
        &.bottom {
            border-radius: 0 0 10px 10px;
        }
    }
    .template-icon {
        height: 114px;
        width: auto;

        margin-right: 24px;
    }
    &.selected {
        background-color: rgba(0, 125, 187, 0.1);
        border: 1px solid ${ocean_blue};
    }

    .template-title {
        font-size: 14px;
        font-weight: 600;
        color: #304562;
        margin-bottom: 6px;
    }

    .template-description-title {
        font-size: 11px;
        font-weight: normal;
        color: ${cool_grey};
    }

    .template-description {
        font-size: 12px;
        font-weight: 600;
        color: #304562;
    }

    .mic-indicator {
        display: flex;
        align-items: center;

        font-size: 12px;
        font-weight: 600;
        color: #faa21b;
        margin-top: 32px;
    }
`

export const ResponseTemplateListItem: React.SFC<ResponseTemplateListItemProps> = (props) => {
    if (props.responseTemplate) {
        return (
            <div className={`${props.viewOnly ? '' : 'selectable'} 
                     ${containerStyle} ${props.isSelected ? "selected" : ""}
                     ${props.top ? 'top' : ''} ${props.bottom ? 'bottom' : ''}
                    `} onClick={() => props.onSelected(props.responseTemplate)}>
                <img className="template-icon" src={props.responseTemplate.iconUrl} />
                <div>
                    <p className="template-title">{props.responseTemplate.name}</p>
                    <p className="template-description-title">COMPONENTS</p>
                    <p className="template-description">{props.responseTemplate.description}</p>
                    {props.responseTemplate.usesAudio || props.responseTemplate.usesVideo ?
                        <p className="mic-indicator">
                            <img src={micIcon} />
                            <span>Closes Mic</span>
                        </p>
                        : null}
                </div>
            </div>)
    }
    return null;
}

