import { VOICIFY_CHAT_HISTORY, VOICIFY_CHAT_STATE, VOICIFY_EFFECT_DATA, VOICIFY_USER_STATE } from '../keys/localStorageKeys';
import type VoicifyTextToSpeechProvider from '../models/textToSpeechModels';
import { getAssistantResponse } from '../apis/fetches/getAssistantResponse';
import { getUserData } from '../apis/fetches/getUserData';
import type { CustomAssistantRequest, CustomAssistantResponse, CustomAssistantUser, SessionEffect, MediaItemModel, VoicifyAssistantSettings, VoicifySessionData, VoicifyUserData, EffectModel } from "../models/customAssistantApiModels";
import type { VoicifySpeechToTextProvider } from "../models/speechToTextModels";
import { getLocalStorageAfterEffectData, getLocalStorageAfterResponseData, getLocalStorageEffectData, setLocalStorageAfterEffectData, setLocalStorageAfterResponseData, setLocalStorageEffectData } from './Shared';
import { handleNavigateEffect } from './effects/Navigate';
import { handleCloseAssistantEffect } from './effects/CloseAssistant';
import { handleClickEffect } from './effects/Click';
import { handleScrollToEffect } from './effects/ScrollTo';

type SpeakerType = "user" | "bot";

export interface ChatHistoryContent {
    text: string
    hints?: string[]
    image?: string
    audio?: string
    video?: string
};

export interface ChatHistoryItem {
    type: SpeakerType
    content: ChatHistoryContent
};

interface ChatStateItem {
    sessionId: string
    lastInteractionDate: string
};
interface UserState {
    userId: string
};

export interface RequestContext {
    request: CustomAssistantRequest,
    response: CustomAssistantResponse,
    assistantElementRootId: string,
    textToSpeechEnabled: boolean
}

export interface AfterResponseEffect {
    effect: string
    data: { [key: string]: any; },
    requestContext: RequestContext
}
export class VoicifyAssistant {
    textToSpeechProvider?: VoicifyTextToSpeechProvider
    speechToTextProvider?: VoicifySpeechToTextProvider
    timeout?: number
    settings: VoicifyAssistantSettings
    sessionId?: string
    userId?: string
    assistantElementRootId: string
    sessionAttributes?: { [key: string]: any; }
    userAttributes?: { [key: string]: any; }
    errorHandlers: ((e: string) => void)[] = []
    effectHandlers: { effect: string, callbacks: ((data: any, requestContext: RequestContext, afterResponseEffect: boolean) => boolean | Promise<boolean>)[] }[] = []
    requestStartedHandlers: ((req: CustomAssistantRequest) => void)[] = []
    responseHandlers: ((res: CustomAssistantResponse) => void)[] = []
    audioHandlers: ((media: MediaItemModel) => void)[] = []
    videoHandlers: ((media: MediaItemModel) => void)[] = []
    currentSessionInfo?: VoicifySessionData
    currentUserInfo?: VoicifyUserData
    shouldSendWelcome?: boolean

    constructor(settings: VoicifyAssistantSettings, ttsProvider?: VoicifyTextToSpeechProvider, sttProvider?: VoicifySpeechToTextProvider, rootId: string = "voicifyAssistantRoot") {
        this.speechToTextProvider = sttProvider;
        this.textToSpeechProvider = ttsProvider;
        this.settings = settings;
        this.shouldSendWelcome = settings.initializeWithWelcomeMessage;
        this.assistantElementRootId = rootId
        this.effectHandlers = [
            { effect: "navigateWeb", callbacks: [handleNavigateEffect] },
            { effect: "clickTap", callbacks: [handleClickEffect] },
            { effect: "scrollTo", callbacks: [handleScrollToEffect] },
            { effect: "closeAssistant", callbacks: [handleCloseAssistantEffect] }
        ];
        this.playAfterEffectResponses();
    };

    async playAfterEffectResponses() {
        const afterEffects = getLocalStorageAfterEffectData();
        setLocalStorageAfterEffectData([]);
        if (afterEffects?.length) {
            for (let i = 0; i < afterEffects.length; ++i) {
                const requestContext = afterEffects[i];
                await this.handleCustomAssistantResponse(requestContext.request, requestContext.response, false);
            }
        }
    }

    isExistingSession() {
        const localStorage = this.getLocalStorageChatState();
        if (localStorage) {
            const localStorageChatState = JSON.parse(localStorage);
            const lastInteractionDate = new Date(localStorageChatState.lastInteractionDate);
            const sessionTime = new Date(Date.now() - this.settings.sessionTimeout);
            if (lastInteractionDate.getTime() >= sessionTime.getTime()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        };
    };

    initializeExistingSession(handleSpeechTimeout: () => void = () => { }) {
        const chatState = this.getLocalStorageChatState();
        if (chatState) {
            this.shouldSendWelcome = false;
            this.initializeAndStart(handleSpeechTimeout);
            const sessionId = JSON.parse(chatState).sessionId;
            this.sessionId = sessionId;
        };
        const userState = this.getLocalStorageUserState();
        if (userState) {
            const userId = JSON.parse(userState).userId;
            this.userId = userId;
        }
    };

    getLocalStorageEffectData() {
        return getLocalStorageEffectData()
    };

    setLocalStorageEffectData(data: { [key: string]: any; }) {
        return setLocalStorageEffectData(data);
    };

    getLocalStorageAfterResponseData(): AfterResponseEffect[] {
        return getLocalStorageAfterResponseData();
    };

    setLocalStorageAfterResponseData(afterEffects: AfterResponseEffect[]) {
        setLocalStorageAfterResponseData(afterEffects);
    };

    getLocalStorageChatState() {
        return localStorage.getItem(VOICIFY_CHAT_STATE);
    };

    getLocalStorageUserState() {
        return localStorage.getItem(VOICIFY_USER_STATE);
    };

    setLocalStorageChatState(chatStateItem: ChatStateItem) {
        const chatState = JSON.stringify(chatStateItem);
        localStorage.setItem(VOICIFY_CHAT_STATE, chatState);
    };

    setLocalStorageUserState(userState: UserState) {
        const userStateStr = JSON.stringify(userState);
        localStorage.setItem(VOICIFY_USER_STATE, userStateStr);
    };

    recordChatHistoryItem(chatHistoryItem: ChatHistoryItem) {
        let chatHistory = this.getChatHistoryItems();
        chatHistory.push(chatHistoryItem);
        localStorage.setItem(VOICIFY_CHAT_HISTORY, JSON.stringify(chatHistory));
    };

    getChatHistoryItems(): ChatHistoryItem[] {
        const localStorageChatHistory = localStorage.getItem(VOICIFY_CHAT_HISTORY);
        if (localStorageChatHistory) {
            return JSON.parse(localStorageChatHistory);
        } else {
            return [];
        };
    };

    clearChatHistory() {
        localStorage.removeItem(VOICIFY_CHAT_HISTORY);
    }

    clearAllLocalStorage() {
        localStorage.removeItem(VOICIFY_CHAT_HISTORY);
        localStorage.removeItem(VOICIFY_CHAT_STATE);
        localStorage.removeItem(VOICIFY_EFFECT_DATA);
    }

    endSession() {
        localStorage.removeItem(VOICIFY_CHAT_HISTORY);
        localStorage.removeItem(VOICIFY_CHAT_STATE);
        localStorage.removeItem(VOICIFY_EFFECT_DATA);
        this.shouldSendWelcome = this.settings.initializeWithWelcomeMessage;
    }

    clearSpeechTimeout() {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }

    initializeAndStart(handleSpeechTimeout: () => void = () => { }) {
        this.speechToTextProvider?.clearHandlers();
        this.textToSpeechProvider?.clearHandlers();
        this.textToSpeechProvider?.initialize(this.settings.locale);
        this.speechToTextProvider?.initialize(this.settings.locale);
        this.speechToTextProvider?.addStartListener(() => {
            this.clearSpeechTimeout();
            const stopListening = () => {
                this.speechToTextProvider?.stopListening();
                if (this.settings.uiType === "minimal") {
                    handleSpeechTimeout();
                }
            }
            this.timeout = window?.setTimeout(stopListening, 5000);
        });
        this.speechToTextProvider?.addPartialListener((speech) => {
            if (speech) {
                this.clearSpeechTimeout();
                const stopListening = () => {
                    this.speechToTextProvider?.stopListening();
                    if (this.settings.uiType === "minimal") {
                        handleSpeechTimeout();
                    }
                }
                this.timeout = window?.setTimeout(stopListening, 5000);
            }
        });
        this.speechToTextProvider?.addFinishListener((speech) => {
            this.clearSpeechTimeout();
            this.speechToTextProvider?.stopListening();
            if (speech) {
                const result = this.makeTextRequest(speech);
                return result;
            }
            return "no input";
        });
        this.textToSpeechProvider?.addFinishListener(() => {
            if (this.settings.autoRunConversation && this.settings.useVoiceInput && this.settings.uiType !== "minimal") {
                this.speechToTextProvider?.startListening();
            }
        })
        this.textToSpeechProvider?.addFinishListener(async () => {
            await this.fireAfterResponseEffects();
        })
    };

    startNewSession(sessionId?: string, userId?: string, sessionAttributes?: { [key: string]: any; }, userAttributes?: { [key: string]: any; }) {
        this.sessionId = sessionId ?? uuidv4();
        if (!userId) {
            const storedUserState = this.getLocalStorageUserState();
            if (storedUserState) {
                const userId = JSON.parse(storedUserState).userId as string;
                this.userId = userId;
            } else {
                this.userId = uuidv4();
            }
        } else {
            this.userId = userId;
        }
        const lastInteraction: Date = new Date();
        let chatStateItem: ChatStateItem = {
            sessionId: this.sessionId,
            lastInteractionDate: lastInteraction.toString()
        }
        this.setLocalStorageChatState(chatStateItem);
        let userState: UserState = {
            userId: this.userId
        }
        this.setLocalStorageUserState(userState);
        this.sessionAttributes = sessionAttributes;
        this.userAttributes = userAttributes;
        this.currentSessionInfo = {};
        this.currentUserInfo = {};
        if (this.shouldSendWelcome) {
            this.makeWelcomeRequest();
        };
    };

    onEffect(effectName: string, callback: (data: any, requestContext: RequestContext) => boolean, overrideExistingEffect: boolean = true) {
        const existingHandler = this.effectHandlers.find(e => e.effect === effectName);
        if (existingHandler) {
            const idx = this.effectHandlers.findIndex(e => e.effect === existingHandler.effect);
            if (overrideExistingEffect) {
                this.effectHandlers[idx] = { effect: effectName, callbacks: [callback] };
            } else {
                this.effectHandlers[idx] = { effect: effectName, callbacks: [...this.effectHandlers[idx].callbacks, callback] };
            }
        } else {
            this.effectHandlers?.push({ effect: effectName, callbacks: [callback] });
        }
    };

    onError(callback: (error: string) => void) {
        this.errorHandlers?.push(callback);
    };

    onRequestStarted(callback: (request: CustomAssistantRequest) => void) {
        this.requestStartedHandlers?.push(callback);
    };

    onResponseReceived(callback: (response: CustomAssistantResponse) => void) {
        this.responseHandlers?.push(callback);
    };

    onPlayVideo(callback: (mediaItem: MediaItemModel) => void) {
        this.videoHandlers?.push(callback);
    };

    onPlayAudio(callback: (mediaItem: MediaItemModel) => void) {
        this.audioHandlers?.push(callback);
    };

    makeTextRequest(text: string, requestAttributes?: { [key: string]: any; }) {
        const request = this.generateTextRequest(text, requestAttributes);
        this.recordChatHistoryItem({
            type: 'user',
            content: { text }
        })
        return this.makeRequest(request);
    };

    makeWelcomeRequest(requestAttributes?: { [key: string]: any; }) {
        const request = this.generateWelcomeRequest(requestAttributes);
        return this.makeRequest(request);
    };

    addSessionAttribute(key: string, value: any) {
        if (!this.sessionAttributes) this.sessionAttributes = {};
        this.sessionAttributes[key] = value;
    };

    addUserAttribute(key: string, value: any) {
        if (!this.userAttributes) this.userAttributes = {};
        this.userAttributes[key] = value;
    };

    async fireAfterResponseEffects() {
        const afterResponseEffects = getLocalStorageAfterResponseData();
        const effectHandlers = this.effectHandlers;
        if (afterResponseEffects?.length) {
            for (let i = 0; i < afterResponseEffects.length; ++i) {
                const afterResponseEffect = afterResponseEffects[i];
                const handler = effectHandlers.find(e => e.effect == afterResponseEffect.effect);
                if (handler?.callbacks?.length) {
                    for (let j = 0; j < handler.callbacks.length; ++j) {
                        const callback = handler.callbacks[j]
                        await callback(afterResponseEffect.data, afterResponseEffect.requestContext, true);
                    }
                }
            }
        }
        setLocalStorageAfterResponseData([]);
    };

    async fireEffects(request: CustomAssistantRequest, response: CustomAssistantResponse): Promise<boolean> {
        const effectHandlers = this.effectHandlers;
        // fire effects
        let effects: EffectModel[] = [];
        if (response.effects?.length) {
            effects = response.effects;
        } else {
            var sessionEffects = (response.sessionAttributes?.["effects"] ?? []) as SessionEffect[];
            if (sessionEffects?.length)
                effects = sessionEffects?.filter(e => e.requestId == request.requestId)?.map(e => {
                    const em: EffectModel = {
                        name: e.effectName,
                        data: e.data
                    }
                    return em;
                });
        }
        var shouldContinueWithResponse = true;
        if (effects?.length) {
            for (let i = 0; i < effects.length; ++i) {
                const effect = effects[i];
                const handler = effectHandlers.find(e => e.effect == effect.name);
                if (handler?.callbacks?.length) {
                    for (let j = 0; j < handler.callbacks.length; ++j) {
                        const callback = handler.callbacks[j]
                        const effectResult = await callback(effect.data, {
                            request: request,
                            response: response,
                            assistantElementRootId: this.assistantElementRootId,
                            textToSpeechEnabled: !!(this.settings.useOutputSpeech && this.textToSpeechProvider)
                        }, false);
                        if (effectResult)
                            shouldContinueWithResponse = false;
                    }
                }
            }
        }
        return shouldContinueWithResponse;
    }

    async handleCustomAssistantResponse(request: CustomAssistantRequest, response: CustomAssistantResponse, shouldFireEffects: boolean) {
        let shouldPlay = true;
        const textToSpeechEnabled = this.settings.useOutputSpeech && this.textToSpeechProvider;
        if (shouldFireEffects) {
            shouldPlay = await this.fireEffects(request, response);
        }
        if (!shouldPlay && textToSpeechEnabled)
            return;

        let chatHistoryItem: ChatHistoryItem = {
            type: 'bot',
            content: {
                text: response.displayText
            }
        }
        if (response.hints) {
            chatHistoryItem.content.hints = response.hints;
        }
        if (response.foregroundImage?.url) {
            chatHistoryItem.content.image = response.foregroundImage.url;
        }
        if (response.videoFile?.url) {
            chatHistoryItem.content.video = response.videoFile.url;
        }
        if (response.audioFile?.url) {
            chatHistoryItem.content.audio = response.audioFile.url;
        }
        this.recordChatHistoryItem(chatHistoryItem)

        if (this.textToSpeechProvider && this.settings?.useOutputSpeech && response?.ssml && !response.audioFile) {
            this.textToSpeechProvider?.speakSsml(response.ssml);
        };

        if (response.sessionAttributes)
            this.currentSessionInfo = response.sessionAttributes;

        if (this.settings.loadUserProfile && this.userId) {
            const userRes = await getUserData(this.settings.serverRootUrl, this.userId, this.settings.appId, this.settings.appKey)
            if (userRes.status == 200) {
                const userData = await userRes?.json() as VoicifyUserData;
                if (userData) this.currentUserInfo = userData;
            }
        };

        this.responseHandlers?.forEach(handle => handle(response));
        if (response?.audioFile)
            this.audioHandlers?.forEach(handle => handle(response.audioFile!!));
        if (response?.videoFile)
            this.videoHandlers?.forEach(handle => handle(response.videoFile!!));
        if (this.settings.autoRunConversation && this.settings.useVoiceInput && !response.endSession && this.settings.uiType !== "minimal" && (!this.textToSpeechProvider || !this.settings.useOutputSpeech)) {
            this.speechToTextProvider?.startListening();
        }

        if (!textToSpeechEnabled) {
            await this.fireAfterResponseEffects();
        }

        return response;
    }

    async makeRequest(request: CustomAssistantRequest) {
        try {
            this.textToSpeechProvider?.stop();

            this.requestStartedHandlers?.forEach(handle => handle(request));

            const createEndpoint = () => {

                let endpoint: string = `${this.settings.serverRootUrl}/api/customAssistant/handleRequest?applicationId=${this.settings.appId}&applicationSecret=${this.settings.appKey}`;

                if (this.settings.environmentId) {
                    endpoint = endpoint.concat(`&environmentId=${this.settings.environmentId}`);
                }
                if (this.settings.noTracking) {
                    endpoint = endpoint.concat(`&noTracking=${this.settings.noTracking}`);
                }
                if (this.settings.useDraftContent) {
                    endpoint = endpoint.concat(`&useDraftContent=${this.settings.useDraftContent}`)
                }
                if (!this.settings.disableNlpInResponse) {
                    endpoint = endpoint.concat(`&includeNlp=true`)
                }
                if (!this.settings.disableSessionInResponse) {
                    endpoint = endpoint.concat(`&includeSessionAttributes=true`)
                }
                return endpoint;
            }

            const endpoint = createEndpoint();

            const result = await getAssistantResponse(request, endpoint);

            if (result.status == 200) {
                const response = await result.json() as CustomAssistantResponse;
                await this.handleCustomAssistantResponse(request, response, true);
            } else {
                const error = await result.text()
                this.errorHandlers?.forEach(handle => handle(error));
            }

            return null;

        } catch (e: any) {
            this.errorHandlers?.forEach(handle => handle(e.toString()));
            return null;
        }
    };

    generateTextRequest(text: string, requestAttributes?: { [key: string]: any; }): CustomAssistantRequest {
        return {
            requestId: uuidv4(),
            user: this.generateUser(),
            device: this.generateDevice(),
            context: {
                channel: this.settings.channel,
                locale: this.settings.locale,
                sessionId: this.sessionId as string,
                requestType: "IntentRequest",
                originalInput: text,
                requiresLanguageUnderstanding: true,
                additionalSessionAttributes: this.sessionAttributes,
                additionalRequestAttributes: requestAttributes
            }
        };
    };

    generateWelcomeRequest(requestAttributes?: { [key: string]: any; }): CustomAssistantRequest {
        return {
            requestId: uuidv4(),
            user: this.generateUser(),
            device: this.generateDevice(),
            context: {
                channel: this.settings.channel,
                locale: this.settings.locale,
                sessionId: this.sessionId as string,
                requestType: "IntentRequest",
                requestName: "VoicifyWelcome",
                originalInput: "[Automated]",
                requiresLanguageUnderstanding: false,
                additionalSessionAttributes: this.sessionAttributes,
                additionalRequestAttributes: requestAttributes
            }
        } as CustomAssistantRequest;
    };

    generateUser(): CustomAssistantUser {
        return {
            id: this.userId as string,
            name: this.userId,
            additionalUserAttributes: this.userAttributes
        };
    };

    generateDevice() {
        return {
            id: this.settings.device,
            name: this.settings.device,
            supportsVideo: true,
            supportsDisplayText: true,
            supportsTextInput: true,
            supportsSsml: this.settings.useOutputSpeech,
            supportsVoiceInput: this.settings.useVoiceInput
        };
    };
};

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};