import React from 'react';
import ContentCard from '../../components/structure/ContentCard';
import DetailLabel from '../../components/general/DetailLabel';
import PageContainer from '../../components/structure/PageContainer';
import FormFieldsContainer from '../../components/structure/FormFieldsContainer';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import TitleTextField from '../../components/forms/TitleTextField';
import SelectField from '../../components/forms/SelectField';
import FollowUpWithRepromptForm, { FollowUpWithRepromptFormData, FollowUpWithRepromptFormProps } from '../followUpForm';
import ConversationItemFormProps from '../../models/features/ConversationItemFormProps';
import ConversationFlow from '../conversationFlow';
import Loader from '../../components/general/Loader';
import NumberRangeContainer from '../../state/containers/NumberRangeContainer';
import NumberRangeModel from '../../models/features/api/NumberRanges/NumberRangeModel';
import TextField from '../../components/forms/TextField';
import ConversationRequest from '../../models/conversationFlow/ConversationRequest';
import { css } from '@emotion/css';
import { cool_grey } from '../../constants/colors';
import IResult from '../../models/result/IResult';
import { NumberRangeFeatureTypeId } from '../../constants/featureTypeIds';
import ContentLanguageMultiSelector from '../../components/forms/ContentLanguageMultiSelector';
import { TreeItem } from '../../components/structure/NestedTree/NestedTree';
import ContentPagePanel from '../contentPage';
import RelatedContentMenu from '../relatedContentMenu';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import PanelTitle from '../contentPage/components/PanelTitle';
import ContentCollapsingSection from '../../components/structure/ContentCollapsingSection';
import ContentSectionTitle from '../contentPage/components/ContentSectionTitle';
import { getFeatureTypeIcon } from '../../models/extensions';
import PageError from '../../components/general/PageError';
import ConversationItemActionButtonToolbar from './components/ConversationItemActionButtonToolbar';
import TemplateLockedBanner from '../../components/general/TemplateLockedBanner';
import ConversationItemFieldSet from '../../components/forms/ConversationItemFieldSet';
import ContentInfoOptions from '../../components/structure/ContentInfoOptions';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';
import NumberRangeResponseModel from '../../models/features/api/NumberRanges/NumberRangeResponseModel';
import { ContentItemPageState } from './BaseConversationItemForm';
import ResponseGroup from '../../models/features/ResponseGroup';
import ResponsesManager from '../responsesManager';
import { DirtyFormPrompt } from './components/DirtyFormPrompt';
import SensitiveDataOptions from '../contentPage/components/SensitiveDataOptions';
const userIcon = require('../../content/images/content-tree-icons/content-level/end-user-avatar.svg');
const contentInfoIcon = require('../../content/images/content-tree-icons/content-level/task-list-pin.svg');
const inputGroupIcon = require('../../content/images/content-tree-icons/content-level/input-stack.svg');
const inputIcon = require('../../content/images/content-tree-icons/content-level/input-single.svg');

export interface NumberRangeFormData extends FollowUpWithRepromptFormData {
    title?: string
    minimumValue?: number,
    maximumValue?: number,
    responses: NumberRangeResponseModel[]
    rangeType: RangeType
    webhookChainingType?: WebhookChainingType
}
export interface NumberRangeFormProps extends FollowUpWithRepromptFormProps<NumberRangeModel>, ConversationItemFormProps<NumberRangeModel> {
    stateContainer: NumberRangeContainer
    numberRange?: NumberRangeModel
}

const numberFieldStyle = css`
    max-width: 200px;
`
const horizontalFormStyle = css`
    align-items: center;
    p {
        margin: 20px 12px 0 12px;
        color: ${cool_grey};
    }
`
export type RangeType = "greater-than" | "less-than" | "between" | "equal"
class NumberRangeForm extends FollowUpWithRepromptForm<NumberRangeModel, NumberRangeFormProps, NumberRangeFormData, ContentItemPageState> {
    handleAsyncSubmit(onSuccess: (result: IResult<NumberRangeModel>) => void) {
        if (this.manualValidate())
            return this.props.onSubmit(this.props.stateContainer.mapFormDataToModel(this.props.numberRange, this.props.values), onSuccess);
    }
    handleDiscardChanges() {
        this.handleFinish();
    }
    handleDelete() {
        const confirmed = confirm("Are you sure you want to delete this?");
        if (confirmed) {
            this.props.stateContainer.delete(this.props.numberRange.id).then(() => {
                this.handleFinish();
            });
        }
    }
    handleCopy() {
        this.handleAsyncSubmit(() => {
            this.props.stateContainer.copy(this.props.numberRange.id).then(r2 => {
                if (r2.resultType == "Ok") {
                    this.props.history.push(`/v/${r2.data.applicationFeatureId}/numberranges/${r2.data.id}`);
                }
            });
        });
    }
  
    handleTypeChange(value) {
        const finalValue = value === null ? null : value.value;
        this.props.setFieldValue("rangeType", finalValue);
        this.props.setFieldValue("maximumValue", '');
        this.props.setFieldValue("minimumValue", '');
    }
    handleTypeBlur(blur) {
        this.props.setFieldTouched("rangeType", blur);
    }
    handleEqualsChange(e) {
        this.props.setFieldValue("maximumValue", e.target.value);
        this.props.setFieldValue("minimumValue", e.target.value);
    }
    handleEqualsBlur(blur) {
        this.props.setFieldTouched("maximumValue", blur);
        this.props.setFieldTouched("minimumValue", blur);
    }
    getUserUtterances(): ConversationRequest[] {
        switch (this.props.values.rangeType) {
            case "equal": return [{ id: "equal", content: this.getMinText(), highlights: [this.getMinText()] }];
            case "between": return [
                { id: "middle-random", content: `[Value greater than ${this.getMinText()} but less than ${this.getMaxText()}]`, highlights: [this.getMinText(), this.getMaxText()] },
                { id: "lower", content: this.getMinText(), highlights: [this.getMinText()] },
                { id: "upper", content: this.getMaxText(), highlights: [this.getMaxText()] },
            ];
            case "greater-than": return [
                { id: "middle-random", content: `[Value greater than ${this.getMinText()}]`, highlights: [this.getMinText()] },
                { id: "lower", content: '' + this.getMinText(), highlights: [this.getMinText()] },
            ];
            case "less-than": return [
                { id: "middle-random", content: `[Value less than ${this.getMaxText()}]`, highlights: [this.getMaxText()] },
                { id: "upper", content: '' + this.getMaxText(), highlights: [this.getMaxText()] },
            ];
        }

        return []
    }

    renderBetweenFields() {
        return (
            <HorizontalContainer className={horizontalFormStyle}>
                {this.renderGreaterThanFields()}
                <p>AND</p>
                {this.renderLessThanFields()}
            </HorizontalContainer>
        )

    }
    renderLessThanFields() {
        return (<TextField required className={numberFieldStyle} name="maximumValue" value={this.props.values.maximumValue} onChange={this.props.handleChange} onBlur={this.props.handleBlur} label="Maximum value" placeholder="max" type="number" />);
    }
    renderGreaterThanFields() {
        return (<TextField required className={numberFieldStyle} name="minimumValue" value={this.props.values.minimumValue} onChange={this.props.handleChange} onBlur={this.props.handleBlur} label="Minimum value" placeholder="min" type="number" />);
    }
    renderEqualsFields() {
        return (<TextField required className={numberFieldStyle} value={this.props.values.minimumValue} name="minimumValue" onChange={this.handleEqualsChange.bind(this)} onBlur={this.handleEqualsBlur.bind(this)} label="Exact value" placeholder="exact" type="number" />);
    }

    getMinText() {
        return this.props.values.minimumValue ? '' + this.props.values.minimumValue : '{minimum}';
    }
    getMaxText() {
        return this.props.values.maximumValue ? '' + this.props.values.maximumValue : '{maximum}';
    }
    getMenuTreeRoots() {
        var roots: TreeItem[] = [this.getContentInfoTreeItem(), {
            id: 'user-says',
            title: 'User-Says',
            isExpanded: this.isItemExpanded('user-says'),
            canExpand: true,
            icon: userIcon,
            linkTo: "#userSays",
            children: [{
                id: 'numberOptions',
                title: 'Number Options',
                canExpand: true,
                isExpanded: this.isItemExpanded('numberOptions'),
                icon: inputGroupIcon,
                linkTo: "#numberOptions",
                children: [{
                    id: 'numberRangeType',
                    title: 'Type',
                    canExpand: false,
                    icon: inputIcon,
                    linkTo: "#numberRangeType"
                }, {
                    id: 'values',
                    title: 'Values',
                    canExpand: false,
                    icon: inputIcon,
                    linkTo: "#values"
                }]
            }]
        }, 
        ...this.getResponsesTreeItems(this.props.values.responses)]

        return roots;
    }
    getContentItem() {
        return this.props.numberRange;
    }
    handleContentItemOptionSelected(option: string, event: any) {
        event.stopPropagation();
        if(option == "Manage Webhooks") {
            this.showWebhookInstanceManager();
        }
        else if(option == "Duplicate") {
            this.handleCopy();
        }
        else if(option == "Delete") {
            this.handleDelete();
        }
    }
    handleResponsesChange(responseGroups: ResponseGroup[]) {
        this.props.setFieldValue("responses", this.getResponsesFromGroups(responseGroups));
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.stateContainer.state.isSaving;
        const isDisabled = isLoading || this.props.isTemplateOwned;
        const responseGroups = this.getResponseGroups(this.props.values.responses);
        if (this.props.responseStateContainer.state.isLoading || !this.props.application) {
            return <Loader />
        } return (
            <>
                <form onSubmit={props.handleSubmit}>
                    <DirtyFormPrompt allowPrompt={props.status?.submitting != true}/>
                    <ContentPagePanel
                        relatedContentRender={() => (<RelatedContentMenu
                            parentContentId={this.props.previousContentId}
                            conversationItem={{ ...this.props.numberRange, featureTypeId: NumberRangeFeatureTypeId }}
                            history={this.props.history}
                            featureTypeId={NumberRangeFeatureTypeId}
                            followUp={responseGroups?.[0]?.firstResponse?.followUp}
                            contentId={this.props.numberRange?.id}
                            onClose={this.props.onToggleNavigation}
                            onSave={this.handleAsyncSubmit.bind(this)} />)
                        }
                        treeRender={this.renderTree.bind(this)}
                        formPanelRender={() => (
                            <PageContainer fill>
                                <ConversationItemActionButtonToolbar
                                    errors={Object.values(props.errors)}
                                    validateForm={props.validateForm}
                                    publishDisabled={this.props.assistantApplication == null}
                                    saveDisabled={ Object.keys(props.errors).length > 0}
                                    contentItem={props.numberRange}
                                    isLoading={isLoading}
                                    handleDiscardChanges={this.handleDiscardChanges.bind(this)}
                                    handlePublishing={this.handleSaveAndPublish.bind(this)}
                                    submitForm={props.submitForm}
                                />
                                {props.numberRange?.createdFromId && <TemplateLockedBanner isLoading={props.stateContainer.state.isTogglingSync} isLinked={props.isTemplateOwned} onRelink={this.handleToggleRelinkModal.bind(this)} onUnlink={() => this.props.onToggleSync(this.props.numberRange.id, true)} />}

                                <ConversationItemFieldSet disabled={isDisabled} withBanner={props.numberRange?.createdFromId != null}>
                                    <ScrollablePanelBody className="scrollable-body">
                                        <PanelTitle title="Content Editing"
                                            detail="Here’s where you can fill in the info about this content and determine the course of the conversation by filling in the relevant details.">
                                        </PanelTitle>
                                        <ContentCollapsingSection title="Content Info" id="contentInfo" icon={contentInfoIcon}titleRightRowComponent={                                                                               
                                            <ContentInfoOptions webhookCount={this.props.stateContainer.state.webhookUpdates?.webhookInstances?.length ?? this.props.stateContainer.state.attachedWebhooks?.length ?? 0} 
                                                options={this.getOptions(this.props.numberRange != null)} 
                                                optionSelected={this.handleContentItemOptionSelected.bind(this)} 
                                                manageWebhooks={this.showWebhookInstanceManager.bind(this)}
                                                showSensitiveConversation={this.state.showSensitiveConversation}
                                                toggleSensitiveConversation={() => { this.toggleSensitiveConversation(); }} />
                                        }>
                                            {this.state.showSensitiveConversation &&
                                                <SensitiveDataOptions
                                                    selectedOption={props.values.sensitiveConversationChange ?? "None"}
                                                    onChange={(value) => { props.setFieldValue("sensitiveConversationChange", value) }}
                                                />
                                            }
                                            <ContentSectionTitle title="Number Range Conversation" icon={getFeatureTypeIcon(NumberRangeFeatureTypeId, true)} />
                                            <TitleTextField id="contentTitle" disabled={isDisabled} name="title" placeholder="For display only" value={props.values.title} label="Title" onChange={props.handleChange} onBlur={props.handleBlur} />
                                            <ContentLanguageMultiSelector id="contentLanguage" disabled={isDisabled} label="Language / Region" languages={props.application.languages} selectedLanguageIds={props.values.languages.map(l => l.id)} onChange={this.handleLanguagesChange.bind(this)} />
                                            {this.renderOptions(true, isDisabled)}
                                        </ContentCollapsingSection>
                                        <ContentCollapsingSection title="User Says" icon={userIcon} id="userSays">
                                            <ContentCard title="Number Options" icon={inputGroupIcon} id="numberOptions">
                                                <FormFieldsContainer>
                                                    <DetailLabel text="Select the type of number or range" />
                                                    <SelectField
                                                        id="numberRangeType"
                                                        name="rangeType"
                                                        value={this.props.values.rangeType}
                                                        label="Type"
                                                        disabled={isDisabled}
                                                        options={[
                                                            { value: "equal", label: "Equal to" },
                                                            { value: "between", label: "Between" },
                                                            { value: "greater-than", label: "Greater than or equal to" },
                                                            { value: "less-than", label: "Less than or equal to" }
                                                        ]}
                                                        onChange={this.handleTypeChange.bind(this)}
                                                        onBlur={this.handleTypeBlur.bind(this)}
                                                    />
                                                    <div id="values" />
                                                    {this.props.values.rangeType == "equal" ? this.renderEqualsFields() : null}
                                                    {this.props.values.rangeType == "greater-than" ? this.renderGreaterThanFields() : null}
                                                    {this.props.values.rangeType == "less-than" ? this.renderLessThanFields() : null}
                                                    {this.props.values.rangeType == "between" ? this.renderBetweenFields() : null}
                                                </FormFieldsContainer>
                                            </ContentCard>

                                        </ContentCollapsingSection>
                                        <ResponsesManager applicationId={this.props.application.id}
                                            applicationFeatureId={this.props.applicationFeatureId}
                                            languages={this.props.values.languages}
                                            responseGroups={responseGroups}
                                            getConditionsKey={(c) => this.getConditionsKey(c)}
                                            isDisabled={isDisabled}
                                            onChange={this.handleResponsesChange.bind(this)}
                                            onBlur={this.props.handleBlur} />
                                    </ScrollablePanelBody>
                                </ConversationItemFieldSet>
                                <PageError errors={this.props?.stateContainer?.state?.errors ?? []} onClear={this.handleClearError.bind(this)} />
                            </PageContainer>
                        )}
                        flowPanelRender={() => (
                            <ConversationFlow
                                applicationId={this.props.application.id}
                                key={this.props.values.responseContainer ? this.props.values.responseContainer.id : ''}
                                endSession={!this.props.values.responses?.some(r => r.followUp != null)}
                                languages={this.props.values.languages}
                                conversationItems={[{
                                    requests: this.getUserUtterances(),                                 
                                    requestTitle: "User Question",
                                    children: this.getPreviewResponses(props.values.responses)
                                }]} />
                        )}>
                    </ContentPagePanel>
                </form>
                {this.renderRelinkSyncWarning()}
                {this.renderWebhookInstanceManager(this.props.application.id, this.props.numberRange?.id, NumberRangeFeatureTypeId, this.props.values?.webhookChainingType)}
            </>
        )
    }
}

export default NumberRangeForm;