import React from 'react';
import { css } from '@emotion/css';
import FullScreenLayout from '../../scenes/fullScreenLayout';
import Button from './Button';
import ModalTitle from './ModalTitle';
import PageContainer from '../structure/PageContainer';
import FooterBar from '../structure/FooterBar';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import HorizontalContainer from '../structure/HorizontalContainer';
import UserContainer from '../../state/containers/UserContainer';
import { color_shades_darkest, text_blue } from '../../constants/colors';
const expandIcon = require('../../content/images/expand-blue.svg');

interface NewTermsModalProps {
    userContainer: UserContainer
}

class NewTermsModal extends React.Component<NewTermsModalProps> {
    componentDidMount() {
        this.props.userContainer.getCurrentUserTermsAgreement();
    }
    openTermsInNewTab() {
        var win = window.open(this.props.userContainer.state.currentUserTermsAgreement.latestTerm.url, '_blank');
        win.focus();
    }
    acceptTerms() {
        this.props.userContainer.addUserTermAgreement(this.props.userContainer.state.currentUserTermsAgreement.latestTerm.id);
    }
    render() {
        const props = this.props;
        return (
            (this.props.userContainer.state.currentUserTermsAgreement.hasAgreedToLatest === false) &&
            <div className={containerStyle}>
                <div className={innerLayoutStyle}>
                    <div className={innerWrapperStyle}>
                        <FullScreenLayout>
                            <PageContainer fill>
                                <HorizontalContainer className={"modal-title"}>
                                    <ModalTitle padded>{"Terms & Conditions"}</ModalTitle>
                                </HorizontalContainer>
                                <HorizontalSeparator />
                                <div className={'text one row'}>
                                    {`We've updated our terms & conditions of use`}
                                </div>
                                <div className={'text row'}>
                                    {`In order to continue using the Voicify platform we require you to take a moment and review some changes we've made to our terms and conditions.`}
                                </div>
                                <div className={`text row`}>
                                    {`Please read them and accept by clicking the button below`}
                                </div>
                                <a target={"_blank"} href={props.userContainer.state.currentUserTermsAgreement.latestTerm.url} className={`link row`}>
                                    <span className="titleText">{`Terms & Conditions`}</span>
                                    <img src={expandIcon} />
                                </a>
                                <FooterBar>
                                    <Button onClick={this.acceptTerms.bind(this)} className={`left`} loading={props.userContainer.state.isLoading} disabled={props.userContainer.state.isLoading} themes={["primary"]} text={"Accept & Close"} type="button" />
                                    <Button onClick={this.openTermsInNewTab.bind(this)} disabled={props.userContainer.state.isLoading} themes={["white", "end"]} text="Learn More" type="button" />
                                </FooterBar>
                            </PageContainer>
                        </FullScreenLayout>
                    </div>
                </div>
                <div className={layoutStyle}>
                </div>
            </div>
        )
    }
}
const containerStyle = css`
    .text {
        color: ${color_shades_darkest}
        font: Muli;
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        &.one {
            font-size: 18px;
            padding-top: 48px;
        }
    }
    .link {
        display: flex;
        flex-direction: row;
        color: ${text_blue}
        font-size: 18px;
        font: Muli;
        padding-bottom: 48px;
        .titleText {
            padding-top: 4px;
        }
    }
    .row {
        padding-left: 48px;  
        padding-right: 48px;  
        padding-bottom: 25px;  
        img {
            padding-left: 5px;
        }  
    }

    .modal-title {        
        margin-left: 46px;
    }

    button.left {
        margin-left: 46px;
    }
    
`;

const layoutStyle = css`
    overflow-x: visible;
    background: rgba(44,61,82, 0.7);
    padding: 65px 115px;
    z-index: 101;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const innerLayoutStyle = css`
    border-radius: 20px;
    border: 12px solid rgba(44,61,82, 0.8);    
    display: block;
    width: 800px;
    height: 450px;
    max-height: calc(100vh - 210px);
    position: fixed;
    z-index: 102;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    overflow: hidden;
`;
const innerWrapperStyle = css`
    border-radius: 20px;
    button:disabled {
        background: linear-gradient(258deg, #c9c9c9, #a8a8a8 63%, #929292);
        cursor: not-allowed;
    }
`

export default NewTermsModal;