import React, { useState } from "react";
import { css } from '@emotion/css';
import EditableIntent from "../../../models/interactionModel/EditableIntent";
import { green_1 } from "../../../constants/colors";
import LanguageModel from "../../../models/features/api/LanguageModel";
import IntentItem from "./IntentItem";
import EditableInteractionModel from "../../../models/interactionModel/EditableInteractionModel";
import { FormikProps } from "formik";
import { findIntentsByName, InteractionModelFormData, sortEditableInteractionModel } from "./InteractionModelForm";
import ModalTitle from "../../../components/general/ModalTitle";
import Button from "../../../components/general/Button";
import ConfirmationDialog from "../../../components/structure/ConfirmationDialog";
import WarningConfirmation from "../../../components/general/WarningConfirmation";
import AddNewIntentModal from "./AddNewIntentModal";
import Loader from "../../../../shared/components/general/Loader";
import styled from "@emotion/styled";
import ManageIntentPromptModal from "./ManageIntentPromptModal";

type ApplicationIntentsEditorProps = {
    applicationId: string;
    languages?: LanguageModel[];
    isLoading: boolean;
    interactionModel: EditableInteractionModel;
}

const ApplicationIntentsEditor: React.FC<ApplicationIntentsEditorProps & FormikProps<InteractionModelFormData>> = (props) => {

    const [activeIntentKey, setActiveIntentKey] = useState("");
    const [isAddNewIntentModalOpen, setIsAddNewIntentModalOpen] = useState(false);
    const [isIntentMatchingPromptModalOpen, setIsIntentMatchingPromptModalOpen] = useState(false);
    const [isVerifySaveModalOpen, setIsVerifySaveModalOpen] = useState(false);

    const toggleActivationByKey = (intentKey: string) => {
        let newIntentKey = "";
        if (intentKey != activeIntentKey)
            newIntentKey = intentKey;

        setActiveIntentKey(newIntentKey);
    }

    const updateIntent = (intentName: string, updatedIntent: EditableIntent): string => {

        if (updatedIntent.displayName == null || !updatedIntent.displayName.trim()) {
            return "Invalid intent name";
        }

        let mergedInteractionModel = { ...props.interactionModel };

        let intent = mergedInteractionModel[updatedIntent.key];
        if(!intent) {
            return `Unable to find "${updatedIntent.key}" intent `;
        }
        const originalDisplayName = intent.originalDisplayName;
        const keys = Object.keys(mergedInteractionModel);

        //Check for duplicate intent display name
        const intentNameMatch = keys
            .filter((intentKey) => {
                const i = mergedInteractionModel[intentKey];
                return i.displayName?.toLowerCase().trim() === updatedIntent.displayName?.toLowerCase().trim()
                && i.originalDisplayName?.toLowerCase().trim() !== originalDisplayName?.toLowerCase().trim()
            });
        if (intentNameMatch?.length > 0) {
            return `Unable to change intent name to "${updatedIntent.displayName}". Intent with that name already exists. `;
        }

        const today = new Date();
        // For front end purpose only, backend will override it
        updatedIntent.modifiedDate = today.toUTCString();

        updatedIntent.isModified = true;

        delete mergedInteractionModel[updatedIntent.key];
        mergedInteractionModel[updatedIntent.key] = updatedIntent;
        mergedInteractionModel = sortEditableInteractionModel(mergedInteractionModel);
        props.setFieldValue("interactionModel", mergedInteractionModel);

        // No error
        return null;
    }

    const renderIntentMenuItem = (intentToRender: EditableIntent, idx: number) => {
        const isActive: boolean = intentToRender.key === activeIntentKey;

        const sameNameIntents = findIntentsByName(props.interactionModel, intentToRender.displayName);
        const showIntentLanguages = sameNameIntents?.length > 1;

        return (
            <IntentItem
                {...props}
                enhancedIntentMatchingEnabled={props.values.enableEnhancedIntentMatching ? true : props.values.disableEnhancedIntentMatching ? false : props.values.useEnhancedIntentMatching}
                displayType="List"
                nlpEntities={props.values.nlpEntities}
                isLoading={props.isLoading}
                updateIntent={updateIntent}
                toggleActivationByKey={toggleActivationByKey}
                applicationId={props.applicationId}
                languages={props.languages}
                intent={intentToRender}
                idx={idx}
                isActive={isActive}
                key={idx}
                displayLanguages={showIntentLanguages}
            />)
    }

    const addNewCustomIntent = (newDisplayName: string) => {
        let mergedInteractionModel = { ...props.values.interactionModel };

        const today = new Date();
        // For front end purpose only, backend will override it
        const modifiedDate = today.toUTCString();

        let intentNewKey = newDisplayName;
        props.languages?.forEach(l => {
            intentNewKey = intentNewKey + "|" + l.shortCode;
        })

        const newIntent: EditableIntent = {
            key: intentNewKey,
            originalDisplayName: newDisplayName,
            displayName: newDisplayName,
            utterances: [],
            slots: [],
            isDisabled: false,
            updatedDisabledState: false,
            isCustom: true,
            modifiedDate: modifiedDate,
            locales: props.languages.map(l => ({ shortCode: l.shortCode, intentId: newDisplayName })),
            isDeleted: false,
            isAdded: true,
            isModified: true,
            requiresExactUtteranceMatch: false,
        };

        mergedInteractionModel[intentNewKey] = newIntent;
        mergedInteractionModel = sortEditableInteractionModel(mergedInteractionModel);
        props.setFieldValue("interactionModel", mergedInteractionModel);
    }

    const addCustomIntent = (newIntentName: string) => {
        addNewCustomIntent(newIntentName);
        // Close Modal and scroll to newly added intent
        setIsAddNewIntentModalOpen(false);
        setActiveIntentKey(newIntentName);
    }

    const savePromptUpdate = (enabled: boolean, prompt: string) => {
        if (enabled != props.values.useEnhancedIntentMatching ) {
            if (enabled) {
                props.setFieldValue("enableEnhancedIntentMatching", true);
                props.setFieldValue("disableEnhancedIntentMatching", false);

            } else {
                props.setFieldValue("enableEnhancedIntentMatching", false);
                props.setFieldValue("disableEnhancedIntentMatching", true);

            } 
        } else {
            props.setFieldValue("enableEnhancedIntentMatching", false);
            props.setFieldValue("disableEnhancedIntentMatching", false);
        }
        if (prompt != props.values.enhancedIntentMatchingPrompt) {
            props.setFieldValue("updatedEnhancedIntentMatchingPrompt", prompt);
        } else {
            props.setFieldValue("updatedEnhancedIntentMatchingPrompt", null);
        
        }
        setIsIntentMatchingPromptModalOpen(false);
    }

    let intentNames = [];
    if (props.interactionModel) {
        intentNames = Object.keys(props.interactionModel)
    }

    if (props.isLoading) {
        return <LoaderContainer><Loader /></LoaderContainer>
    }
    return (
        <div className={containerStyle}>
            <div className={headerContainer}>
                <ModalTitle>Manage your Intents and Utterances</ModalTitle>
                <p className="detail-label">Here you can manage your base interaction model for all your deployed channels and add new phrases when necessary.</p>
            </div>

            {intentNames.length > 0 ?
                <div className={updatePanel}>
                    <Button
                        themes={["primary-small"]}
                        onClick={() => setIsAddNewIntentModalOpen(!isAddNewIntentModalOpen)}
                        loading={props.isLoading}
                        disabled={props.isLoading}
                        text="Add Intent" />
                    <Button
                        themes={["primary-small"]}
                        onClick={() => setIsIntentMatchingPromptModalOpen(!isIntentMatchingPromptModalOpen)}
                        loading={props.isLoading}
                        disabled={props.isLoading}
                        text="Manage LLM Intent Matching" />
                </div>
                :
                <div className={headerContainer}>
                    <p className="detail-label">Select a language to edit the interaction model for.</p>
                </div>
            }

            {(props.isLoading || !props.interactionModel) ?
                <></> :
                intentNames.map((name, idx) => {
                    return renderIntentMenuItem(props.interactionModel[name], idx);
                })
            }

            {isAddNewIntentModalOpen &&
                <AddNewIntentModal
                    interactionModel={props.interactionModel}
                    handleSubmit={addCustomIntent}
                    handleClose={() => setIsAddNewIntentModalOpen(false)}
                    languages={props.languages}
                    isLoading={props.isLoading}
                />
            }
            {isIntentMatchingPromptModalOpen &&
                <ManageIntentPromptModal
                    prompt={props.values.updatedEnhancedIntentMatchingPrompt ?? props.values.enhancedIntentMatchingPrompt}
                    enabled={props.values.enableEnhancedIntentMatching ? true : props.values.disableEnhancedIntentMatching ? false : props.values.useEnhancedIntentMatching}
                    handleSubmit={savePromptUpdate}
                    handleClose={() => setIsIntentMatchingPromptModalOpen(false)}
                    languages={props.languages}
                    isLoading={props.isLoading}
                />
            }
            {isVerifySaveModalOpen &&
                <ConfirmationDialog title={`Save Interaction Model Changes`}
                    deleteText="Save"
                    warning
                    isLoading={props.isLoading}
                    onClose={() => setIsVerifySaveModalOpen(false)}
                    onConfirm={() => props.submitForm()}>
                    <WarningConfirmation text={`Any Custom Intents and NLP Entities set for deletion will be permanentely removed from Interaction Model.`} />
                </ConfirmationDialog>
            }
        </div>
    )
}


export default ApplicationIntentsEditor;

const containerStyle = css`
    height: auto;
`;

const updatePanel = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    padding-left: 32px;
    .success-label {
        font-size: 16px;
        font-weight: 600;
        color: ${green_1};
    }
`;
const headerContainer = css`
    margin: 0 50px 16px 50px;
`
const LoaderContainer = styled.div`
    height: 100%;
`
