import React, { useEffect, useState } from "react";
import {css} from '@emotion/css';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import TemplateFieldType from '../../../models/templating/TemplateFieldType';
import TemplateFormFieldModel from '../../../models/templating/api/TemplateFormFieldModel';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import LargeOverflowMenu from '../../../components/general/LargeOverflowMenu';
import TextField from '../../../components/forms/TextField';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import Switch from '../../../components/forms/Switch';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
import { DraggableProvided } from 'react-beautiful-dnd';
import { color_shades_dark, color_text_light } from '../../../constants/colors';
import { getFieldTypeIcon } from '../../../models/extensions/fieldTypeIcons';
import { OverflowOption } from '../../../components/general/overflowMenu/OverflowMenu';
const menuIcon = require('../../../content/images/menu-item-icons/circle-menu.svg');
const deleteIcon = require('../../../content/images/menu-item-icons/bin.svg');
const upIcon = require('../../../content/images/menu-item-icons/navigation-up.svg');
const downIcon = require('../../../content/images/menu-item-icons/navigation-down.svg');
const moveIcon = require('../../../content/images/menu-item-icons/navigation-right-circle.svg');

interface FileUploadFieldEditorProps {
    field: TemplateFormFieldModel
    sections: TemplateFormSectionModel[]
    draggable: DraggableProvided
    onChange: (field: TemplateFormFieldModel) => void
    onDelete: (field: TemplateFormFieldModel) => void
    onMoveUp: (field: TemplateFormFieldModel) => void
    onMoveDown: (field: TemplateFormFieldModel) => void
    onMoveToSection: (field: TemplateFormFieldModel, sectionId: string) => void
}


const FieldUploadFieldEditor: React.FC<FileUploadFieldEditorProps> = (props) => {

    const [isCollapsed, setCollapsed] = useState(false);
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    function handleOptionSelected(optionName: string) {
        if (optionName == 'Move Up') {
            props.onMoveUp(props.field);
        }
        if (optionName == 'Move Down') {
            props.onMoveDown(props.field);
        }
        if (optionName == 'Delete') {
            setDeleteConfirmationOpen(true);
        }
    
        // find section by name and pass it off
        const section = props.sections.find(s => s.title == optionName);
        if(section) {
            props.onMoveToSection(props.field, section.id);
        }
    }

    function getOptions() {
        const options : OverflowOption[] = [
            { label: 'Delete', icon: deleteIcon, hoverIcon: deleteIcon, isDestructive: true },
            { label: 'Move Up', icon: upIcon, hoverIcon: upIcon },
            { label: 'Move Down', icon: downIcon, hoverIcon: downIcon },
            { label: 'Move to another page...', icon: moveIcon, hoverIcon: moveIcon, subOptions: props.sections.map(s => ({
                label: s.title
            }))}
        ];
        return options;
    }


    function handleFieldChange(fieldName: keyof TemplateFormFieldModel, value) {
        const field = props.field;
        (field[fieldName] as any) = value;
        props.onChange(field);
    }

    return (
        <div className={`${containerStyle}`}>
            <div className="field-title-wrapper" {...props.draggable.dragHandleProps} onClick = {() => setCollapsed(!isCollapsed)}>
                <ToggleIndicator direction={true ? 'right' : 'down'}  type="triangle" className="toggle-indicator" />
                <img src={getFieldTypeIcon(props.field.fieldType as TemplateFieldType)}/>
                <div className="title-text-container">
                    <p className="field-type-label">{props.field.fieldType}</p>
                    <p className="field-title">{props.field.title}</p>
                </div>
                <div className="field-button">
                        <LargeOverflowMenu isActive={false} onOptionSelected={handleOptionSelected.bind(this)} options={getOptions()} icon={menuIcon} />
                </div>
            </div>
            {!isCollapsed && 
            <div className="field-body-wrapper">
                <TextField name="field.associatedVariable" value={props.field.associatedVariable ?? ''} placeholder="{Variable}" label="Associated Variable" onChange={e => handleFieldChange("associatedVariable", e.target.value)} />
                <HorizontalSeparator />
                <br/>
                <TextField name="field.title" value={props.field.title ?? ''} placeholder="Upload your File" label="Question / Display Title" onChange={e => handleFieldChange("title", e.target.value)} />
                <TextField name="field.description" value={props.field.tip ?? ''} placeholder="Empty" label="Description (Optional)" onChange={e => handleFieldChange("tip", e.target.value)} />
                <TextField name="field.fileformats" value={props.field.fileFormats ?? ''} placeholder="Example .csv, .docx" label="File Formats Supported - Comma Separated (Optional)" onChange={e => handleFieldChange("fileFormats", e.target.value)} />
                <TextField name="field.filesuggestions" value={props.field.suggestions ?? ''} placeholder="Suggestions" label="File Suggestions (Optional)" onChange={e => handleFieldChange("suggestions", e.target.value)} />
                <HorizontalSeparator/>
                <h1 className={`${subHeader}`}>Settings:</h1>
                <Switch className="switch-container" checked={props.field.isRequired} onChange={(checked) => handleFieldChange("isRequired", checked)} label="Required Field" />
            </div>}
            {isDeleteConfirmationOpen &&
                <ConfirmationDialog title={`Delete ${props.field?.title ?? "Field"}`}
                    deleteText="Yes, Delete"
                    isLoading={false}
                    onClose={() => setDeleteConfirmationOpen(false)}
                    onConfirm={() => props.onDelete(props.field)}>
                    <DeleteConfirmation itemLabel={`${props.field?.title ?? "Field"}?`} />
                </ConfirmationDialog>}
        </div>
    )
}

const containerStyle = css`
    border: 1px solid ${color_shades_dark};
    background: white;
    border-radius: 8px;
    margin-bottom: 32px;
    .field-title-wrapper {
        display: flex;
        align-items: center;
        margin: 16px 0;
        cursor: pointer;
        >.toggle-indicator {
            margin: 16px;
        }
        >img {
            width: 24px;
            height: auto;
            margin-right: 12px;
        }
        .title-text-container {
            flex: 1;
        }
        .field-type-label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: ${color_text_light};
        }
        .field-title {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        .field-button {
            margin-right: 32px;
            margin-left: auto;
        }
    }
    .field-body-wrapper {
        margin: 0 32px;
        padding: 24px 0;
        border-top: 1px solid ${color_shades_dark};
        >.switch-container {
            margin: 32px 0;
        }
    }
`;

const subHeader = css`
    margin-top: 30px;
`

export default FieldUploadFieldEditor;