import { css } from "@emotion/css";
import React from "react";
import { useState } from "react";
import Button from "../../../components/general/Button";
import { color_shades_dark, color_colors_ocean, color_variants_ocean_light_2 } from "../../../constants/colors";
import styled from "@emotion/styled";
import SearchField from "../../../components/forms/SearchField";
import TooltipWrapper from "../../../components/general/TooltipWrapper";
import EditedMenuItemOptionGroupModel from "../../../models/nlp/menu/EditedMenuItemOptionGroupModel";
import EditedMenuItemSizeGroupModel from "../../../models/nlp/menu/EditedMenuItemSizeGroupModel";

const yellowUpdate = require('../../../content/images/yellow-update.svg');
const duplicateIcon = require('../../../content/images/response-duplicate.svg');
const pasteIcon = require('../../../content/images/paste.svg');

interface VoicifyMenuItemsProps {
    activeItemId: string;
    toggleItemActivation: (selectedGroup: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => void;
    getMenuItemsWithGroup: (group: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => string[];
    handleGroupPaste: (e: React.MouseEvent<HTMLElement>, groupToReplace: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => void;
    handleCopyGroup: (e: React.MouseEvent<HTMLElement>, groupToCopy: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => void;
    groupCopy: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel | null;
    groups: EditedMenuItemOptionGroupModel[] | EditedMenuItemSizeGroupModel[];
    showPasteIcon: boolean;
    groupType: string;
}

const VoicifyGroups: React.FC<VoicifyMenuItemsProps> = (props) => {

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    let filteredGroups = [];

    if (props.groupType === "optionGroup") {
        filteredGroups = props.groups?.filter(group => (group as EditedMenuItemOptionGroupModel).optionGroup.name?.toLowerCase().includes(searchTerm.toLowerCase()));
    } else {
        filteredGroups = props.groups?.filter(group => {
            const menuItems = props.getMenuItemsWithGroup(group as EditedMenuItemSizeGroupModel);
            return menuItems?.some(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
        });
    }

    return (
        <>
            {
                props.groups?.length > 0 &&
                <>
                    <SearchField
                        name={"searchField"}
                        placeholder={props.groupType === "optionGroup" ? "Find an option group" : "Find a size group"}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        boxType={"square"}
                        className={searchPadding}
                    />
                    {filteredGroups.map((group: EditedMenuItemOptionGroupModel) => {
                        return (
                            <OptionGroupWrapper
                                className={group.editId === props.activeItemId ? "active" : ""}
                                key={group.editId}
                                onClick={() => {
                                    props.toggleItemActivation(group)
                                }}
                            >
                                <TooltipWrapper
                                    text={(props.getMenuItemsWithGroup(group)?.length > 1) ? props.getMenuItemsWithGroup(group)[1] : props.getMenuItemsWithGroup(group)[0]}
                                    place="right"
                                    className={tooltipStyle}
                                >
                                    <ListItemLeftWrapper>
                                        <Button
                                            themes={["white-small"]}
                                            text={props.groupType === "optionGroup" ? group.optionGroup.name : props.getMenuItemsWithGroup(group)[0]}
                                            type="button"
                                        />
                                        <MenuItemsWrapper>{props.groupType === "optionGroup" ? props.getMenuItemsWithGroup(group)[0] : ""}</MenuItemsWrapper>
                                    </ListItemLeftWrapper>
                                </TooltipWrapper>
                                <MenuItemActionButtons>
                                    {
                                        (group.isModified) &&
                                        <Button
                                            themes={['icon', 'end-tight']}
                                            icon={group.isModified && yellowUpdate}
                                        />
                                    }
                                    {
                                        props.showPasteIcon &&
                                        <Button
                                            themes={['icon', 'end-tight']}
                                            icon={pasteIcon}
                                            type="button"
                                            onClick={(e) => props.handleGroupPaste(e, group)}
                                        />
                                    }
                                    <Button themes={['icon', 'end-tight']}
                                        icon={duplicateIcon}
                                        type="button"
                                        onClick={(e) => props.handleCopyGroup(e, group)}
                                    />
                                </MenuItemActionButtons>
                            </OptionGroupWrapper>
                        )
                    })}
                </>
            }
        </>
    );
};

export default VoicifyGroups;

const tooltipStyle = css`
    width: 30%;
`;

const ListItemLeftWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const MenuItemActionButtons = styled.div`
    display: flex;
`;

const MenuItemsWrapper = styled.div`
    font-size: 8px;  
    margin-left: 8px;
`;

const OptionGroupWrapper = styled.div`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
    }
`;

const searchPadding = css`
    padding-top: 16px;
`;

