import React from 'react';
import { InjectedFormikProps, withFormik, Form, FieldArray, ArrayHelpers } from "formik";
import * as yup from 'yup';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import FollowUpContainer from '../../../state/containers/FollowUpContainer';
import FollowUpModel from '../../../models/features/api/FollowUp/FollowUpModel';
import DetailLabel from '../../../components/general/DetailLabel';
import UpdateFollowUpHintRequest from '../../../models/features/api/FollowUp/UpdateFollowUpHintRequest';
import FormContainer from '../../../components/structure/FormContainer';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';

interface FollowUpFormProps {
    followUpContainer: FollowUpContainer
    followUp?: FollowUpModel
    applicationId: string
    onFinish?: (followUp: FollowUpModel) => void
    onClose?: () => void
    onChange?: () => void
}
interface FollowUpFormData {
    name?: string
    content?: string
    followUpHints?: UpdateFollowUpHintRequest[]
}
class InnerForm extends React.Component<InjectedFormikProps<FollowUpFormProps, FollowUpFormData>> {
    handleSsmlUpdate(value: string) {
        this.props.setFieldValue('content', value);
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.followUpContainer.state.isLoading || props.followUpContainer.state.isSaving;

        return (
            <>
                <FormContainer>
                    <DetailLabel text="Update your follow-up dialogue" />
                    <FormFieldsContainer>
                        <TextField className="tight" name="name" required value={props.values.name} label="Name:" placeholder="What do you want to call this message?" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                        <SsmlField applicationId={this.props.applicationId} className="tight" name="content" required value={props.values.content} label="Follow-up dialogue:" placeholder="What do you want the device to say?" onChange={this.handleSsmlUpdate.bind(this)} onBlur={props.handleBlur} disabled={isLoading} />
                        <FieldArray validateOnChange={false} name="followUpHints" render={arrayHelpers => (
                            <div>
                                {props.values.followUpHints.map((response, index) => (
                                    <div key={index}>
                                        <TextField disabled={isLoading}
                                            canDelete={index > 0}
                                            onDelete={() => arrayHelpers.remove(index)}
                                            deletePosition="right"
                                            name={`followUpHints.${index}.content`}
                                            placeholder="Something the user can say"
                                            label={`On-Screen Hint ${index > 0 ? "#" + (index + 1) : ""}`}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={response.content}
                                            tooltip={index == 0 ? `Hints are used on screen devices like the Echo Show and Google Home Hub. 
                                    On Alexa, this appears as a phrase at the bottom such as 'Try "Alexa, {hint}"', 
                                    and on Google Assistant they are tappable chips. Only hints with under 20 characters will appear on Google, 
                                    and Alexa can only show one hint at a time.` : undefined} />
                                    </div>
                                ))}
                                <Button type="button" themes={["hollow", "fill"]} text="Add an On-Screen Hint" onClick={() => arrayHelpers.push({
                                    content: '',
                                })} />
                            </div>
                        )} />
                    </FormFieldsContainer>
                </FormContainer>

                <FooterBar>
                    <Button loading={isLoading} disabled={isLoading} themes={["primary", "wide"]} text="Save" type="button" onClick={props.handleSubmit} />
                    <Button disabled={isLoading} themes={["white", "end"]} text="Cancel" type="button" onClick={this.props.onClose} />
                </FooterBar>
            </>
        )
    }
}

const FollowUpForm = withFormik<FollowUpFormProps, FollowUpFormData>({
    mapPropsToValues: props => ({
        name: props.followUp ? props.followUp.name : '',
        content: props.followUp ? props.followUp.content : '',
        followUpHints: props.followUp.followUpHints ? props.followUp.followUpHints.map(f => ({
            id: f.id,
            content: f.content
        })) : [{
            content: ''
        }]
    }),
    validationSchema: yup.object().shape({
        name: yup.string().required("You must enter a name"),
        content: yup.string().required("You must enter your message")
    }),
    handleSubmit: (values, { props }) => {
        if (props.followUp && props.followUp.id) {
            // updating an existing one
            props.followUpContainer.updateFollowUp(props.followUp.id, {
                id: props.followUp.id,
                name: values.name,
                content: values.content,
                followUpHints: values.followUpHints.filter(f => f.content && f.content.length > 0)
            }).then(result => {
                if (result.resultType == "Ok") {
                    props.onFinish(result.data);
                }
            })
        }
        else {
            // creating a new one
            props.followUpContainer.addFollowUp(props.applicationId, {
                name: values.name,
                content: values.content,
                followUpHints: values.followUpHints
            }).then(result => {
                if (result.resultType == "Ok") {
                    props.onFinish(result.data);
                }
            })
        }
    }
})(InnerForm);

export default FollowUpForm;
