import type { Styles } from "../models/styleModels";

export const addZIndex = (styleSettings?: Styles) => {
    const style = document.createElement("style");
    style.innerHTML = `
        #voicifyAssistantRoot {
            position: relative;
            z-index: ${styleSettings?.assistant?.zIndex ?? '1000'};
        }
    `
    document.body.append(style);
}

export const createStyles = (shadowRoot: ShadowRoot, styleSettings?: Styles, customStyles?: string) => {
    const style = document.createElement("style");
    style.innerHTML = `

        ::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            background: transparent;
        }

        .voicify-assistant-container {
            background: transparent;
            display: flex;
            flex-direction: column;
            width: ${styleSettings?.assistant?.width ?? '398px'};
            position: fixed;
            bottom: 32px;
            right: 32px;
        }

        .voicify-assistant-minimal-ui-container {
            background: transparent;
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: 0;
        }

        .voicify-assistant-minimal-ui-container-left {
            background: transparent;
            flex: 1;
            min-width: 0;
        }

        .voicify-assistant-minimal-ui-container-right {
            background: transparent;
            flex: 1;
            min-width: 0;
        }

        .voicify-assistant-container-slide-left {
            background: transparent;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            position: fixed;
            bottom: 0;
            right: 0;
        }

        .voicify-assistant-container-slide-up {
            width: ${styleSettings?.assistant?.width ?? '398px'};
            position: fixed;
            bottom: 0;
            right: 0;
            background: ${styleSettings?.assistant?.backgroundColor ?? 'transparent'};
            border-radius: ${styleSettings?.assistant?.borderRadius ?? '8px 8px 0 0'};
        }

        .voicify-assistant-container-minimal-show-history {
            background: transparent;
            display: flex;
            flex-direction: row;
            width: ${styleSettings?.assistant?.width ?? '100vw'};
            position: ${styleSettings?.assistant?.position ?? 'fixed'};
            top: ${styleSettings?.assistant?.top ?? 'initial'};
            bottom: ${styleSettings?.assistant?.bottom ?? '32px'};
            right: ${styleSettings?.assistant?.right ?? 'initial'};
            left: ${styleSettings?.assistant?.left ?? 'initial'};
        }

        .voicify-assistant-container-minimal {
            background: transparent;
            display: flex;
            flex-direction: row;
            width: ${styleSettings?.assistant?.width ?? '100vw'};
            position: ${styleSettings?.assistant?.position ?? 'fixed'};
            top: ${styleSettings?.assistant?.top ?? 'initial'};
            bottom: ${styleSettings?.assistant?.bottom ?? '32px'};
            right: ${styleSettings?.assistant?.right ?? 'initial'};
            left: ${styleSettings?.assistant?.left ?? 'initial'};
        }

        .voicify-assistant-chat-container {
            display: flex;
            flex-direction: column;
            width: ${styleSettings?.assistant?.width ?? '398px'};
            height: ${styleSettings?.assistant?.height ?? '75vh'};
            box-shadow: 0px 4px 20px rgba(0, 0, 0, .1);
            border-radius: ${styleSettings?.assistant?.borderRadius ?? '8px'};
            background: ${styleSettings?.assistant?.backgroundColor ?? 'transparent'};
        }

        .voicify-assistant-chat-container-slide-up {
            display: flex;
            flex-direction: column;
            width: ${styleSettings?.assistant?.width ?? '398px'};
            height: ${styleSettings?.assistant?.height ?? '75vh'};
            box-shadow: 0px 4px 20px rgba(0, 0, 0, .1);
            background: transparent;
        }

        .voicify-assistant-chat-container-quick {
            display: flex;
            flex-direction: column;
            width: ${styleSettings?.assistant?.width ?? '398px'};
            box-shadow: 0px 4px 20px rgba(0, 0, 0, .1);
            border-radius: ${styleSettings?.assistant?.borderRadius ?? '8px'};
            background: ${styleSettings?.assistant?.backgroundColor ?? 'transparent'};
        }

        .voicify-assistant-chat-container-minimal {
            display: flex;
            flex-direction: column;
            max-height: ${styleSettings?.assistant?.height ?? '25vh'};
            border-radius: ${styleSettings?.assistant?.borderRadius ?? '8px'};
            background: ${styleSettings?.assistant?.backgroundColor ?? '#FFFFFFBF'};
            min-width: 0;
        }

        .voicify-assistant-chat-container-minimal-show-history {
            display: flex;
            flex-direction: column;
            max-height: ${styleSettings?.assistant?.height ?? '25vh'};
            border-radius: ${styleSettings?.assistant?.borderRadius ?? '8px'};
            background: ${styleSettings?.assistant?.backgroundColor ?? '#FFFFFFBF'};
            min-width: 0;
        }

        .voicify-assistant-header {
            display: flex;
            justify-content: space-between;
            font-size: ${styleSettings?.header?.fontSize ?? '18px'};
            font-family: ${styleSettings?.header?.fontFamily ?? "Helvetica"};
            padding-left: ${styleSettings?.header?.paddingLeft ?? '16px'};
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 0;
            color: ${styleSettings?.header?.assistantNameTextColor ?? '#324661'};
            background: ${styleSettings?.header?.backgroundColor ?? 'white'};
            border: 1px solid #CBCCD2;
            font-weight: 600;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: ${styleSettings?.body?.borderTopColor ?? '#CBCCD2'};
        }

        .voicify-assistant-header-minimal {
            border-radius: 8px 8px 0 0;
            border: none;
            background: transparent;
            padding: 16px 16px 0 0;
            justify-content: right;
        }

        .voicify-assistant-header-bottom-right-button {
            border-radius: 8px 8px 0 0;
        }

        .voicify-assistant-header-slide-left {
            border-radius: 8px 0 0 0;
        }

        .voicify-assistant-header-slide-up {
            border-radius: 8px 8px 0 0;
        }

        .voicify-assistant-header-left {
            display: flex;
            align-items: center;
        }

        .voicify-assistant-logo {
            background-color: ${styleSettings?.header?.assistantImageBackgroundColor ?? 'none'};
            height: ${styleSettings?.header?.assistantImageHeight ?? '40px'};
            width: ${styleSettings?.header?.assistantImageWidth ?? '40px'};
            padding: ${styleSettings?.header?.assistantImagePadding ?? 'none'};
            margin-right: 12px;
            border-radius: ${styleSettings?.header?.assistantImageBorderRadius ?? '50%'};
            border-color: ${styleSettings?.header?.assistantImageBorderColor ?? 'none'};
            border-width: ${styleSettings?.header?.assistantImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.header?.assistantImageBorderStyle ?? 'none'};
        }

        .voicify-assistant-header-right {
            display: flex;
            align-items: center;
            margin-right: 16px;
        }

        .voicify-assistant-close-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            border-radius: ${styleSettings?.header?.closeAssistantButtonBorderRadius ?? '50%'};
            height: 24px;
            width: 24px;
            border: none;
            box-shadow: none;
            cursor: pointer;
        }

        .voicify-assistant-close-button-minimal {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            border-radius: ${styleSettings?.header?.closeAssistantButtonBorderRadius ?? '50%'};
            height: 24px;
            width: 24px;
            border: none;
            box-shadow: none;
            cursor: pointer;
            align-self: flex-end;
            margin: 16px 16px 0 0;
            position: absolute;
        }

        .voicify-assistant-minimize-button {
            width: ${styleSettings?.header?.minimizeButtonHeight ?? '24px'};
            height: ${styleSettings?.header?.minimizeButtonWidth ?? '24px'};
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            border-radius: ${styleSettings?.header?.minimizeButtonBorderRadius ?? 'none'};
            border: none;
            box-shadow: none;
            cursor: pointer;
            margin-right: 16px;
            filter: ${styleSettings?.header?.minimizeAssistantColor ?? 'invert(63%) sepia(8%) saturate(377%) hue-rotate(173deg) brightness(93%) contrast(90%)'};
        }

        .voicify-assistant-close-icon {
            background-color: ${styleSettings?.header?.closeAssistantButtonBackgroundColor ?? 'transparent'};
            border-radius: ${styleSettings?.header?.closeAssistantButtonBorderRadius ?? '50%'};
            border-color: ${styleSettings?.header?.closeAssistantButtonBorderColor ?? 'none'};
            border-width: ${styleSettings?.header?.closeAssistantButtonBorderWidth ?? 'none'};
            border-style: ${styleSettings?.header?.closeAssistantButtonBorderStyle ?? 'none'};
            height: 24px;
            width: 24px;
            filter: ${styleSettings?.header?.closeAssistantColor ?? 'invert(63%) sepia(8%) saturate(377%) hue-rotate(173deg) brightness(93%) contrast(90%)'};
        }

        .voicify-assistant-chat-history {
            display: block;
            flex-grow: 1;
            padding-left: ${styleSettings?.body?.paddingLeft ?? '24px'};
            padding-right: ${styleSettings?.body?.paddingRight ?? '24px'};
            padding-top: ${styleSettings?.body?.paddingTop ?? '24px'};
            padding-bottom: ${styleSettings?.body?.paddingBottom ?? '24px'};
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            border-top-color: transparent;
            border-bottom-color: ${styleSettings?.body?.borderBottomColor ?? '#CBCCD2'};
            border-right-color: #CBCCD2; 
            border-left-color: #CBCCD2;
            border-width: 1px;
            border-style: solid; 
            background: ${styleSettings?.body?.backgroundColor ?? '#F4F4F6'};
        }

        .voicify-assistant-chat-history-hidden {
            display: none;
        }

        .voicify-assistant-chat-history-minimal {
            display: flex;
            flex-grow: 1;
            padding-left: ${styleSettings?.body?.paddingLeft ?? '74px'};
            padding-right: ${styleSettings?.body?.paddingRight ?? '48px'};
            padding-top: ${styleSettings?.body?.paddingTop ?? '0'};
            padding-bottom: ${styleSettings?.body?.paddingBottom ?? '0'};
            overflow-y: auto;
            flex-direction: column;
            border-top-color: transparent;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            background: ${styleSettings?.body?.backgroundColor ?? 'transparent'};
            min-width: 0;
        }

        .voicify-assistant-chat-history-minimal-hidden {
            display: none;
        }

        .voicify-assistant-toolbar {
            background: ${styleSettings?.toolbar?.backgroundColor ?? 'white'};
            border: 1px solid #CBCCD2;
            border-top: none;
        }

        .voicify-assistant-toolbar-bottom-right-button {
            border-radius: 0 0 8px 8px;
            border-top: none;
        }

        .voicify-assistant-toolbar-minimal {
            background: ${styleSettings?.toolbar?.backgroundColor ?? 'transparent'};
            border: 1px solid #CBCCD2;
            border-radius: 8px;
            border-top: none;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, .1);
            display: flex;
            min-width: 0;
        }

        .voicify-assistant-toolbar-minimal-speaking {
            background: ${styleSettings?.toolbar?.backgroundColor ?? 'transparent'};
            border: 1px solid #CBCCD2;
            border-radius: 8px 8px 0 0;
            border-top: none;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, .1);
            display: flex;
            min-width: 0;
        }

        .voicify-assistant-toolbar-minimal-show-history {
            background: ${styleSettings?.toolbar?.backgroundColor ?? 'transparent'};
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top: none;
            display: flex;
            flex-direction: column;
            min-width: 0;
        }

        .voicify-assistant-toolbar-slide {
            border-radius: 0;
        }

        .voicify-assistant-toolbar-top-hidden {
            display: none;
        }

        .voicify-assistant-toolbar-bottom {
            display: flex;
            padding-left: ${styleSettings?.toolbar?.paddingLeft ?? '16px'};
            padding-right: ${styleSettings?.toolbar?.paddingRight ?? '16px'};
            padding-top: ${styleSettings?.toolbar?.paddingTop ?? '16px'};
            padding-bottom: ${styleSettings?.toolbar?.paddingBottom ?? '16px'};
        }

        .voicify-assistant-toolbar-bottom-minimal {
            display: flex;
            padding-left: ${styleSettings?.toolbar?.paddingLeft ?? '16px'};
            padding-right: ${styleSettings?.toolbar?.paddingRight ?? '48px'};
            padding-top: ${styleSettings?.toolbar?.paddingTop ?? '48px'};
            padding-bottom: ${styleSettings?.toolbar?.paddingBottom ?? '48px'};
            flex: 1;
            min-width: 0;
        }
        
        .voicify-assistant-toolbar-bottom-minimal-show-history {
            display: flex;
            padding-left: ${styleSettings?.toolbar?.paddingLeft ?? '16px'};
            padding-right: ${styleSettings?.toolbar?.paddingRight ?? '48px'};
            padding-top: ${styleSettings?.toolbar?.paddingTop ?? '0'};
            padding-bottom: ${styleSettings?.toolbar?.paddingBottom ?? '48px'};
            flex: 1;
            min-width: 0;
        }

        .voicify-assistant-toolbar-top {
            margin-top: auto;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            background: ${styleSettings?.toolbar?.backgroundColor ?? 'white'};
            border-bottom: none;
            padding-left: ${styleSettings?.toolbar?.paddingLeft ?? '16px'};
            padding-right: ${styleSettings?.toolbar?.paddingRight ?? '16px'};
            padding-top: ${styleSettings?.toolbar?.paddingTop ?? '16px'};
            padding-bottom: ${styleSettings?.toolbar?.paddingBottom ?? '0px'};
        }

        .voicify-assistant-toolbar-top-bottom-border {
            padding: 0 24px;
            margin-top: 8px;
            border-bottom: 1px dashed #CBCCD2;
        }

        .voicify-assistant-toolbar-help {
            font-family: ${styleSettings?.toolbar?.helpTextFontFamily ?? 'Helvetica'};
            font-size: ${styleSettings?.toolbar?.helpTextFontSize ?? '14px'};
            font-style: italic;
            color: ${styleSettings?.toolbar?.helpTextFontColor ?? '#8F97A1'};
            margin-bottom: 16px;
        }

        .voicify-assistant-minimal-speaking-indicator {
            background: ${styleSettings?.toolbar?.equalizerColor ?? 'radial-gradient(#182EE3 0%, #06BAC8 100%)'};
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            width: 100%;
            height: 20px;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-name: minimal-equalizer-color;
        }

        @keyframes minimal-equalizer-color {
            0% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 100%)}
            10% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 90%)}
            20% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 80%)}
            30% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 70%)}
            40% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 60%)}
            50% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 50%)}
            60% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 60%)}
            70% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 70%)}
            80% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 80%)}
            90% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 90%)}
            100% {background: radial-gradient(${styleSettings?.toolbar?.equalizerColorOne ?? `#182EE3`} 0%, ${styleSettings?.toolbar?.equalizerColorTwo ?? `#06BAC8`} 100%)}
        }

        .voicify-assistant-bar {
            border-radius: ${styleSettings?.toolbar?.equalizerBarBorderRadius ?? '10px'};
            height: 10px;
            width: ${styleSettings?.toolbar?.equalizerBarWidth ?? '10px'};
            background: ${styleSettings?.toolbar?.equalizerColor ?? 'linear-gradient(180deg, #83ACE4 0%, rgba(128, 199, 175, 0) 100%), #8AD1B8'};
            margin-right: ${styleSettings?.toolbar?.equalizerBarRightMargin ?? '2px'};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        .voicify-assistant-bar-1 {
            animation-name: one;
        }

        .voicify-assistant-bar-2 {
            animation-name: two;
        }

        .voicify-assistant-bar-3 {
            animation-name: three;
        }

        .voicify-assistant-bar-4 {
            animation-name: four;
        }

        .voicify-assistant-bar-5 {
            animation-name: five;
        }

        .voicify-assistant-bar-6 {
            animation-name: six;
        }

        .voicify-assistant-bar-7 {
            animation-name: seven;
        }

        .voicify-assistant-bar-8 {
            animation-name: eight;
        }

        .voicify-assistant-bar-9 {
            animation-name: nine;
        }

        .voicify-assistant-bar-10 {
            animation-name: ten;
        }

        .voicify-assistant-bar-11 {
            animation-name: eleven;
        }

        @keyframes one {
            0% {height: 10px}
            12.5% {height: 5.52px}
            25% {height: 10px}
            37.5% {height: 5.52px}
            50% {height: 10px}
            62.5% {height: 10px}
            75% {height: 16px}
            100% {height: 10px}
        }

        @keyframes two {
            0% {height: 10px}
            12.5% {height: 20.97px}
            25% {height: 38px}
            37.5% {height: 20.97px}
            50% {height: 10px}
            62.5% {height: 10px}
            75% {height: 16px}
            100% {height: 10px}
        }

        @keyframes three {
            0% {height: 10px}
            12.5% {height: 15.45px}
            25% {height: 28px}
            37.5% {height: 15.45px}
            50% {height: 10px}
            62.5% {height: 8.54px}
            75% {height: 14px}
            100% {height: 8.54px}
        }

        @keyframes four {
            0% {height: 10px}
            12.5% {height: 8.83px}
            25% {height: 16px}
            37.5% {height: 8.83px}
            50% {height: 10px}
            62.5% {height: 15.85px}
            75% {height: 26px}
            100% {height: 15.85px}
        }

        @keyframes five {
            0% {height: 10px}
            12.5% {height: 1.66px}
            25% {height: 3px}
            37.5% {height: 1.66px}
            50% {height: 10px}
            62.5% {height: 25px}
            75% {height: 41px}
            100% {height: 25px}
        }

        @keyframes six {
            0% {height: 10px}
            12.5% {height: 7.72px}
            25% {height: 14px}
            37.5% {height: 7.72px}
            50% {height: 10px}
            62.5% {height: 10px}
            75% {height: 16px}
            100% {height: 10px}
        }

        @keyframes seven {
            0% {height: 10px}
            12.5% {height: 32px}
            25% {height: 58px}
            37.5% {height: 32px}
            50% {height: 10px}
            62.5% {height: 12.2px}
            75% {height: 20px}
            100% {height: 12.2px}
        }

        @keyframes eight {
            0% {height: 10px}
            12.5% {height: 23.17px}
            25% {height: 42px}
            37.5% {height: 23.17px}
            50% {height: 10px}
            62.5% {height: 21.95px}
            75% {height: 36px}
            100% {height: 21.95px}
        }

        @keyframes nine {
            0% {height: 10px}
            12.5% {height: 25.38px}
            25% {height: 46px}
            37.5% {height: 25.38px}
            50% {height: 10px}
            62.5% {height: 15.85px}
            75% {height: 26px}
            100% {height: 15.85px}
        }

        @keyframes ten {
            0% {height: 10px}
            12.5% {height: 17.66px}
            25% {height: 32px}
            37.5% {height: 17.66px}
            50% {height: 10px}
            62.5% {height: 10.98px}
            75% {height: 18px}
            100% {height: 10.98px}
        }

        @keyframes eleven {
            0% {height: 10px}
            12.5% {height: 6.62px}
            25% {height: 12px}
            37.5% {height: 6.62px}
            50% {height: 10px}
            62.5% {height: 7.32px}
            75% {height: 12px}
            100% {height: 7.32px}
        }

        .voicify-assistant-toolbar-animation {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 64px;
        }

        .voicify-assistant-toolbar-state {
            font-family: ${styleSettings?.toolbar?.assistantStateFontFamily ?? 'Helvetica'};
            font-size: ${styleSettings?.toolbar?.assistantStateFontSize ?? '12px'};
            font-style: italic;
            color: ${styleSettings?.toolbar?.assistantStateFontColor ?? '#AAAAAA'};
        }

        .voicify-assistant-toolbar-transcription {
            display: flex;
            align-items: center;
            margin: 8px 0;
            padding: 4px 16px 4px 16px;
            border-radius: 8px;
            background-color: ${styleSettings?.toolbar?.speechResultBoxBackgroundColor ?? 'rgba(0, 0, 0, 0.5)'};
            color: ${styleSettings?.toolbar?.partialSpeechResultTextColor ?? 'white'};
            font-family: ${styleSettings?.toolbar?.partialSpeechResultFontFamily ?? 'Helvetica'};
            font-size: ${styleSettings?.toolbar?.partialSpeechResultFontSize ?? '16px'};
            height: ${styleSettings?.toolbar?.speechResultBoxHeight ?? '16px'};
            white-space: nowrap;
            overflow: hidden;
        }

        .voicify-assistant-toolbar-transcription-minimal {
            display: flex;
            flex: 1;
            align-items: center;
            padding: 18.5px 16px;
            border-radius: 8px;
            background-color: ${styleSettings?.toolbar?.speechResultBoxBackgroundColor ?? '#FFFFFF'};
            color: ${styleSettings?.toolbar?.partialSpeechResultTextColor ?? '#324661'};
            font-family: ${styleSettings?.toolbar?.partialSpeechResultFontFamily ?? 'Helvetica'};
            font-size: ${styleSettings?.toolbar?.partialSpeechResultFontSize ?? '16px'};
            height: ${styleSettings?.toolbar?.speechResultBoxHeight ?? '16px'};
            white-space: nowrap;
            overflow: hidden;
        }

        .voicify-assistant-toolbar-left {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .voicify-assistant-toolbar-left-minimal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .voicify-assistant-toolbar-left-no-mic {
            width: 0;
            margin-left: 8px;
        }

        .voicify-assistant-toolbar-right {
            margin-left: 16px;
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .voicify-assistant-toolbar-right-minimal {
            margin-left: 16px;
            display: flex;
            flex: 1;
            min-width: 0;
        }

        .voicify-assistant-send-button {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            outline: none;
            height: 36px;
            border: none;
            background: transparent;
            cursor: pointer;
        }

        .voicify-assistant-send-button-minimal {
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            height: 36px;
            border: none;
            background: transparent;
            cursor: pointer;
        }

        .voicify-assistant-send-icon {
            filter: ${styleSettings?.toolbar?.sendActiveColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            height: 24px;
            width: 24px;
        }

        .voicify-assistant-send-icon-minimal {
            filter: ${styleSettings?.toolbar?.sendActiveColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            height: 24px;
            width: 24px;
        }

        .voicify-assistant-send-icon-inactive {
            filter: ${styleSettings?.toolbar?.sendInactiveColor ?? 'invert(60%) sepia(7%) saturate(431%) hue-rotate(173deg) brightness(99%) contrast(90%)'};
            height: 24px;
            width: 24px;
        }

        .voicify-assistant-send-icon-inactive-minimal {
            filter: ${styleSettings?.toolbar?.sendInactiveColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            height: 24px;
            width: 24px;
        }

        .voicify-assistant-toolbar-listening-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .voicify-assistant-toolbar-mute-container {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .voicify-assistant-toolbar-mute-text {
            color: ${styleSettings?.toolbar?.muteFontColor ?? '#AAAAAA'};
            font-family: ${styleSettings?.toolbar?.muteFontFamily ?? "Helvetica"};
            font-size: ${styleSettings?.toolbar?.muteFontSize ?? '12px'};
            margin-right: 4px;
        }

        .voicify-assistant-toolbar-mute-icon {
            height: ${styleSettings?.toolbar?.muteImageHeight ?? 'auto'};
            width: ${styleSettings?.toolbar?.muteImageWidth ?? 'auto'};
            filter: ${styleSettings?.toolbar?.muteImageColor ?? 'invert(100%) sepia(0%) saturate(7488%) hue-rotate(146deg) brightness(92%) contrast(110%)'};
        }

        .voicify-assistant-toolbar-speak-active {
            font-size: ${styleSettings?.toolbar?.speakFontSize ?? '12px'};
            font-family: ${styleSettings?.toolbar?.speakFontFamily ?? "Helvetica"};
            color: ${styleSettings?.toolbar?.speakActiveTitleColor ?? '#444444'};
            margin-bottom: 4px;
        }

        .voicify-assistant-toolbar-speak-inactive {
            font-size: ${styleSettings?.toolbar?.speakFontSize ?? '12px'};
            font-family: ${styleSettings?.toolbar?.speakFontFamily ?? "Helvetica"};
            color: ${styleSettings?.toolbar?.speakInactiveTitleColor ?? '#8F97A1'};
            margin-bottom: 4px;
        }

        .voicify-assistant-toolbar-type-active {
            font-size: ${styleSettings?.toolbar?.typeFontSize ?? '12px'};
            font-family: ${styleSettings?.toolbar?.typeFontFamily ?? "Helvetica"};
            color: ${styleSettings?.toolbar?.typeActiveTitleColor ?? '#444444'};
            margin-bottom: 4px;
        }

        .voicify-assistant-toolbar-type-inactive {
            font-size: ${styleSettings?.toolbar?.typeFontSize ?? '12px'};
            font-family: ${styleSettings?.toolbar?.typeFontFamily ?? "Helvetica"};
            color: ${styleSettings?.toolbar?.typeInactiveTitleColor ?? '#8F97A1'};
            margin-bottom: 4px;
        }

        .voicify-assistant-microphone {
            display: flex;
            justify-content: center;
            align-items: start;
            border-radius: ${styleSettings?.toolbar?.micBorderRadius ?? '50%'};
            height: ${styleSettings?.toolbar?.micButtonHeight ?? '40px'};
            width: ${styleSettings?.toolbar?.micButtonWidth ?? '40px'};
            border: none;
            box-shadow: none;
            cursor: pointer;
            background: transparent;
        }

        .voicify-assistant-microphone-minimal {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: ${styleSettings?.toolbar?.micBorderRadius ?? '50%'};
            height: ${styleSettings?.toolbar?.micButtonHeight ?? '40px'};
            width: ${styleSettings?.toolbar?.micButtonWidth ?? '40px'};
            border: none;
            box-shadow: none;
            cursor: pointer;
            background: transparent;
        }

        .voicify-assistant-keyboard {
            display: flex;
            justify-content: center;
            align-items: center;
            filter: ${styleSettings?.toolbar?.keyboardColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            border: none;
            box-shadow: none;
            cursor: pointer;
            background: transparent;
        }

        .voicify-assistant-keyboard-inactive {
            display: flex;
            justify-content: center;
            align-items: center;
            filter: ${styleSettings?.toolbar?.keyboardColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            border: none;
            box-shadow: none;
            cursor: pointer;
            background: transparent;
        }

        .voicify-assistant-mic-active {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            filter: ${styleSettings?.toolbar?.micActiveColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            height: ${styleSettings?.toolbar?.micActiveImageHeight ?? '48px'};
            width: ${styleSettings?.toolbar?.micActiveImageWidth ?? '48px'};
            padding: ${styleSettings?.toolbar?.activeMicImagePadding ?? 'none'};
            border-width: ${styleSettings?.toolbar?.micImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.toolbar?.micImageBorderStyle ?? 'none'};
            border-color: ${styleSettings?.toolbar?.micImageBorderColor ?? 'none'};
            box-shadow: none;
            cursor: pointer;
            background: ${styleSettings?.toolbar?.micActiveHighlightColor ?? 'none'};
        }

        .voicify-assistant-mic-active-minimal {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            filter: ${styleSettings?.toolbar?.micActiveColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            height: ${styleSettings?.toolbar?.micActiveImageHeight ?? '48px'};
            width: ${styleSettings?.toolbar?.micActiveImageWidth ?? '48px'};
            padding: ${styleSettings?.toolbar?.activeMicImagePadding ?? 'none'};
            border-width: ${styleSettings?.toolbar?.micImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.toolbar?.micImageBorderStyle ?? 'none'};
            border-color: ${styleSettings?.toolbar?.micImageBorderColor ?? 'none'};
            box-shadow: none;
            cursor: pointer;
            background: ${styleSettings?.toolbar?.micActiveHighlightColor ?? 'none'};
        }

        .voicify-assistant-mic-inactive {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            filter: ${styleSettings?.toolbar?.micActiveColor ?? 'invert(60%) sepia(7%) saturate(431%) hue-rotate(173deg) brightness(99%) contrast(90%)'};
            height: ${styleSettings?.toolbar?.micImageHeight ?? '48px'};
            width: ${styleSettings?.toolbar?.micImageWidth ?? '48px'};
            padding: ${styleSettings?.toolbar?.micImagePadding ?? 'none'};
            border-width: ${styleSettings?.toolbar?.micImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.toolbar?.micImageBorderStyle ?? 'none'};
            border-color: ${styleSettings?.toolbar?.micImageBorderColor ?? 'none'};
            border: none;
            box-shadow: none;
            cursor: pointer;
            background: ${styleSettings?.toolbar?.micInactiveHighlightColor ?? 'none'};
        }

        .voicify-assistant-mic-inactive-minimal {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            filter: ${styleSettings?.toolbar?.micActiveColor ?? 'invert(53%) sepia(5%) saturate(0%) hue-rotate(157deg) brightness(94%) contrast(81%)'};
            height: ${styleSettings?.toolbar?.micImageHeight ?? '48px'};
            width: ${styleSettings?.toolbar?.micImageWidth ?? '48px'};
            padding: ${styleSettings?.toolbar?.micImagePadding ?? 'none'};
            border-width: ${styleSettings?.toolbar?.micImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.toolbar?.micImageBorderStyle ?? 'none'};
            border-color: ${styleSettings?.toolbar?.micImageBorderColor ?? 'none'};
            border: none;
            box-shadow: none;
            cursor: pointer;
            background: ${styleSettings?.toolbar?.micInactiveHighlightColor ?? 'none'};
        }

        .voicify-assistant-input-container-active {
            background: ${styleSettings?.toolbar?.textboxActiveHighlightColor ?? ' rgba(167, 167, 167, 0.2)'};
            border-radius: 8px;
            padding: 8px;
            display: flex;
        }

        .voicify-assistant-input-container-active-minimal {
            background: ${styleSettings?.toolbar?.textboxActiveHighlightColor ?? '#FFFFFF'};
            border-radius: 8px;
            padding: 8px;
            display: flex;
            border: 1px solid ${styleSettings?.toolbar?.textboxBorderColor ?? '#D9D9D9'};
            flex: 1;
        }

        .voicify-assistant-input-container-inactive {
            background: ${styleSettings?.toolbar?.textboxInactiveHighlightColor ?? 'transparent'};
            border-radius: 8px;
            padding: 8px;
            display: flex;
        }

        .voicify-assistant-input-container-inactive-minimal {
            background: ${styleSettings?.toolbar?.textboxInactiveHighlightColor ?? '#FFFFFF'};
            border-radius: 8px;
            padding: 8px;
            display: flex;
            border: 1px solid ${styleSettings?.toolbar?.textboxBorderColor ?? '#D9D9D9'};
            flex: 1;
        }

        .voicify-assistant-input-container-active input {
            font-size: ${styleSettings?.toolbar?.textboxFontSize ?? '16px'};
            font-family: ${styleSettings?.toolbar?.textboxFontFamily ?? "Helvetica"};
            color: ${styleSettings?.toolbar?.textInputTextColor ?? '#324661'};
        }

        .voicify-assistant-textbox {
            border: none;
            border-bottom: 1px solid ${styleSettings?.toolbar?.textInputLineColor ?? '#CCCCCC'};
            outline: none;
            background: transparent;
            flex-grow: 1;
            caret-color: ${styleSettings?.toolbar?.textInputCursorColor ?? '#A3A3A3'};
        }

        .voicify-assistant-textbox-minimal {
            border: none;
            outline: none;
            background: transparent;
            flex-grow: 1;
            caret-color: ${styleSettings?.toolbar?.textInputCursorColor ?? '#A3A3A3'};
            font-size: ${styleSettings?.toolbar?.textboxFontSize ?? '16px'};
        }

        .voicify-assistant-textbox-active {
            border: none;
            border-bottom: 1px solid ${styleSettings?.toolbar?.textInputActiveLineColor ?? '#CCCCCC'};
            outline: none;
            background: transparent;
            flex-grow: 1;
            caret-color: ${styleSettings?.toolbar?.textInputCursorColor ?? '#A3A3A3'};
        }

        .voicify-assistant-textbox-active-minimal {
            border: none;
            outline: none;
            background: transparent;
            flex-grow: 1;
            caret-color: ${styleSettings?.toolbar?.textInputCursorColor ?? '#A3A3A3'};
            font-size: ${styleSettings?.toolbar?.textboxFontSize ?? '16px'};
        }

        ::placeholder {
            color: ${styleSettings?.toolbar?.placeholderFontColor ?? '#8F97A1'};
            font-style: ${styleSettings?.toolbar?.placeholderFontStyle ?? 'normal'};
            font-size: ${styleSettings?.toolbar?.placeholderFontSize ?? '16px'};
            font-family: ${styleSettings?.toolbar?.placeholderFontFamily ?? "Helvetica"};
        }

        .voicify-assistant-chat-user-item {
            line-height: 1.4;
            background: ${styleSettings?.body?.messageSentBackgroundColor ?? 'rgba(0, 0, 0, 0.5)'};
            font-size: ${styleSettings?.body?.messageSentFontSize ?? '16px'};
            font-family: ${styleSettings?.body?.messageSentFontFamily ?? "Helvetica"};
            color: ${styleSettings?.body?.messageSentTextColor ?? 'white'};
            max-width: 265px;
            width: fit-contents;
            padding: 4px 16px;
            align-self: flex-end;
            border-width: ${styleSettings?.body?.messageSentBorderWidth ?? 'none'};
            border-style: ${styleSettings?.body?.messageSentBorderStyle ?? 'none'};
            border-color: ${styleSettings?.body?.messageSentBorderColor ?? 'none'};
            border-top-left-radius: ${styleSettings?.body?.messageSentBorderTopLeftRadius ?? '8px'};
            border-top-right-radius: ${styleSettings?.body?.messageSentBorderTopRightRadius ?? '0'};
            border-bottom-right-radius: ${styleSettings?.body?.messageSentBorderBottomRightRadius ?? '8px'};
            border-bottom-left-radius: ${styleSettings?.body?.messageSentBorderBottomLeftRadius ?? '8px'};
            display: inline-block;
            overflow-wrap: break-word;
        }

        .voicify-assistant-chat-user-item-minimal {
            max-width: 218px;
            line-height: 1.4;
            background: ${styleSettings?.body?.messageSentBackgroundColor ?? 'rgba(0, 0, 0, 0.5)'};
            font-size: ${styleSettings?.body?.messageSentFontSize ?? '16px'};
            font-family: ${styleSettings?.body?.messageSentFontFamily ?? 'Helvetica'};
            color: ${styleSettings?.body?.messageSentTextColor ?? 'white'};
            padding: 4px 16px;
            align-self: flex-end;
            border-width: ${styleSettings?.body?.messageSentBorderWidth ?? 'none'};
            border-style: ${styleSettings?.body?.messageSentBorderStyle ?? 'none'};
            border-color: ${styleSettings?.body?.messageSentBorderColor ?? 'none'};
            border-top-left-radius: ${styleSettings?.body?.messageSentBorderTopLeftRadius ?? '8px'};
            border-top-right-radius: ${styleSettings?.body?.messageSentBorderTopRightRadius ?? '0'};
            border-bottom-right-radius: ${styleSettings?.body?.messageSentBorderBottomRightRadius ?? '8px'};
            border-bottom-left-radius: ${styleSettings?.body?.messageSentBorderBottomLeftRadius ?? '8px'};
            overflow-wrap: break-word;
        }

        .voicify-assistant-chat-bot-container {
            display: flex;
            justify-self: start;
        }

        .voicify-assistant-chat-bot-container-left {
            display: flex;
            align-items: flex-start;
        }

        .voicify-assistant-chat-bot-container-right {
            display: flex;
            flex-direction: column;
        }

        .voicify-assistant-chat-bot-container-right-minimal {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        .voicify-assistant-chat-bot-avatar {
            border-color: ${styleSettings?.body?.assistantImageBorderColor ?? 'none'};
            border-width: ${styleSettings?.body?.assistantImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.body?.assistantImageBorderStyle ?? 'none'};
            border-radius: ${styleSettings?.body?.assistantImageBorderRadius ?? '50%'};
            height: 32px;
            width: 32px;
            margin-right: 8px;
            padding:  ${styleSettings?.body?.assistantImagePadding ?? 'none'};
        }

        .voicify-assistant-chat-bot-item {
            line-height: 1.4;
            background: ${styleSettings?.body?.messageReceivedBackgroundColor ?? 'rgba(0, 0, 0, 0.1)'};
            font-size: ${styleSettings?.body?.messageReceivedFontSize ?? '16px'};
            font-family: ${styleSettings?.body?.messageReceivedFontFamily ?? "Helvetica"};
            color: ${styleSettings?.body?.messageReceivedTextColor ?? '#324661'};
            max-width: 265px;
            width: fit-contents;
            padding: 4px 16px;
            border-top-left-radius: ${styleSettings?.body?.messageReceivedBorderTopLeftRadius ?? '8px'};
            border-top-right-radius: ${styleSettings?.body?.messageReceivedBorderTopRightRadius ?? '0'};
            border-bottom-right-radius: ${styleSettings?.body?.messageReceivedBorderBottomRightRadius ?? '8px'};
            border-bottom-left-radius: ${styleSettings?.body?.messageReceivedBorderBottomLeftRadius ?? '8px'};
            border-radius: 0px 8px 8px 8px;
            border-width: ${styleSettings?.body?.messageReceivedBorderWidth ?? '1px'};
            border-style: ${styleSettings?.body?.messageReceivedBorderStyle ?? 'solid'};
            border-color: ${styleSettings?.body?.messageReceivedBorderColor ?? '#CCCCCC'};
            border: 1px solid #CCCCCC;
            display: inline-block;
        }

        .voicify-assistant-chat-bot-item-minimal {
            display: flex;
            flex-direction: column;
            max-width: 218px;
            line-height: 1.4;
            background: ${styleSettings?.body?.messageReceivedBackgroundColor ?? '#FFFFFF'};
            font-size: ${styleSettings?.body?.messageReceivedFontSize ?? '16px'};
            font-family: ${styleSettings?.body?.messageReceivedFontFamily ?? 'Helvetica'};
            color: ${styleSettings?.body?.messageReceivedTextColor ?? '#324661'};
            padding: 4px 16px;
            border-top-left-radius: ${styleSettings?.body?.messageReceivedBorderTopLeftRadius ?? '8px'};
            border-top-right-radius: ${styleSettings?.body?.messageReceivedBorderTopRightRadius ?? '0'};
            border-bottom-right-radius: ${styleSettings?.body?.messageReceivedBorderBottomRightRadius ?? '8px'};
            border-bottom-left-radius: ${styleSettings?.body?.messageReceivedBorderBottomLeftRadius ?? '8px'};
            border-radius: 0px 8px 8px 8px;
            border-width: ${styleSettings?.body?.messageReceivedBorderWidth ?? 'none'};
            border-style: ${styleSettings?.body?.messageReceivedBorderStyle ?? 'none'};
            border-color: ${styleSettings?.body?.messageReceivedBorderColor ?? 'none'};
        }

        .voicify-assistant-chat-bot-item-minimal p  {
            margin: 0;
            overflow-wrap: break-word;
            flex: 1;
        }

        .voicify-assistant-chat-bot-item p  {
            margin: 0;
            overflow-wrap: break-word;
        }

        .voicify-assistant-hint-container {
            display: flex;
            flex-wrap: wrap;
        }

        .voicify-assistant-hint-button {
            border-width: ${styleSettings?.body?.hintsBorderWidth ?? '1px'};
            border-style: ${styleSettings?.body?.hintsBorderStyle ?? 'solid'};
            border-color: ${styleSettings?.body?.hintsBorderColor ?? '#CBCCD2'};
            color: ${styleSettings?.body?.hintsTextColor ?? '#8F97A1'};
            margin-right: 8px;
            margin-bottom: 8px;
            line-height: 24px;
            font-size: ${styleSettings?.body?.hintsFontSize ?? '16px'};
            font-family: ${styleSettings?.body?.hintsFontFamily ?? "Helvetica"};
            background: ${styleSettings?.body?.hintsBackgroundColor ?? 'white'};
            border-radius: ${styleSettings?.body?.hintsBorderRadius ?? '16px'};
            padding-top: ${styleSettings?.body?.hintsPaddingTop ?? '4px'};
            padding-bottom: ${styleSettings?.body?.hintsPaddingBottom ?? '4px'};
            padding-right: ${styleSettings?.body?.hintsPaddingRight ?? '16px'};
            padding-left: ${styleSettings?.body?.hintsPaddingLeft ?? '16px'};
            cursor: pointer;
        }

        .voicify-assistant-foreground-image {
            margin: 16px 0;
        }

        .voicify-assistant-audio-element {
            width: 100%;
            align-self: self-end;
        }

        .voicify-assistant-video-element {
            width: ${styleSettings?.body?.videoWidth ?? '100%'};
            height: ${styleSettings?.body?.videoHeight ?? 'auto'};
            align-self: self-end;
        }

        .voicify-assistant-start-button {
            width: ${styleSettings?.start?.assistantImageButtonWidth ?? '50px'};
            height: ${styleSettings?.start?.assistantImageButtonHeight ?? '50px'};
            background: ${styleSettings?.start?.assistantImageBackgroundColor ?? 'transparent'};
            border-width: ${styleSettings?.start?.assistantImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.start?.assistantImageBorderStyle ?? 'none'};
            border-color: ${styleSettings?.start?.assistantImageBorderColor ?? 'none'};
            box-shadow: none;
            border-radius: ${styleSettings?.start?.assistantImageBorderRadius ?? '50%'}; 
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-end;
            margin: 16px 0 0 16px;
            cursor: pointer;
        }

        .voicify-assistant-start-button-minimal {
            width: ${styleSettings?.start?.assistantImageButtonWidth ?? '50px'};
            height: ${styleSettings?.start?.assistantImageButtonHeight ?? '50px'};
            background: ${styleSettings?.start?.assistantImageBackgroundColor ?? 'transparent'};
            border-width: ${styleSettings?.start?.assistantImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.start?.assistantImageBorderStyle ?? 'none'};
            border-color: ${styleSettings?.start?.assistantImageBorderColor ?? 'none'};
            box-shadow: none;
            border-radius: ${styleSettings?.start?.assistantImageBorderRadius ?? '50%'}; 
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            margin: 16px 0 0 16px;
            cursor: pointer;
        }

        .voicify-assistant-start-button-slide-left {
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${styleSettings?.start?.buttonBackgroundColor ?? '#3E77A5'};
            width: ${styleSettings?.start?.buttonWidth ?? '122px'};
            height: ${styleSettings?.start?.buttonHeight ?? '28px'};
            padding: ${styleSettings?.start?.buttonPadding ?? '4px 16px'};
            border-radius: ${styleSettings?.start?.buttonBorderRadius ?? '8px 8px 0px 0px'};
            transform: rotate(-90deg);
            cursor: pointer;
            border: ${styleSettings?.start?.buttonBorder ?? 'none'};
            margin: ${styleSettings?.start?.buttonMargin ?? '0 -47px 122px 0'};
        }

        .voicify-assistant-start-button-slide-left-quick {
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${styleSettings?.start?.buttonBackgroundColor ?? '#3E77A5'};
            width: ${styleSettings?.start?.buttonWidth ?? '122px'};
            height: ${styleSettings?.start?.buttonHeight ?? '28px'};
            padding: ${styleSettings?.start?.buttonPadding ?? '4px 16px'};
            border-radius: ${styleSettings?.start?.buttonBorderRadius ?? '8px 8px 0px 0px'};
            transform: rotate(-90deg);
            cursor: pointer;
            border: ${styleSettings?.start?.buttonBorder ?? 'none'};
            margin: ${styleSettings?.start?.buttonMargin ?? '0 -47px 75px 0'};
        }

        .voicify-assistant-start-button-slide-up {
            width: ${styleSettings?.start?.buttonIconHeight ?? '24px'};
            height: ${styleSettings?.start?.buttonIconWidth ?? '24px'};
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            border-radius: ${styleSettings?.start?.buttonBorderRadius ?? '50%'};
            border: none;
            box-shadow: none;
            cursor: pointer;
        }

        .voicify-assistant-start-icon-slide-up {
            width: ${styleSettings?.start?.slideFromBottomStartIconHeight ?? null};
            height: ${styleSettings?.start?.slideFromBottomStartIconWidth ?? null};
            margin-right: 16px;
            filter: ${styleSettings?.header?.minimizeAssistantColor ?? 'invert(63%) sepia(8%) saturate(377%) hue-rotate(173deg) brightness(93%) contrast(90%)'};
        }

        .voicify-assistant-start-icon-slide-left {
            height: ${styleSettings?.start?.buttonIconHeight ?? 'null'};
            width: ${styleSettings?.start?.buttonIconWidth ?? 'null'};
            transform: rotate(90deg);
        }

        .voicify-assistant-start-icon-slide-left {
            margin-right: 4px;
        }

        .voicify-assistant-start-text-slide-left {
            font-family: ${styleSettings?.start?.buttonFontFamily ?? 'Helvetica'};
            color: ${styleSettings?.start?.buttonFontColor ?? '#FFFFFF'};
            font-size: ${styleSettings?.start?.buttonFontSize ?? '14px'};
        }

        .voicify-assistant-start-icon {
            width: ${styleSettings?.start?.assistantImageWidth ?? '64px'};
            height: ${styleSettings?.start?.assistantImageHeight ?? '64px'};
            border-with: ${styleSettings?.start?.assistantImageBorderWidth ?? 'none'};
            border-style: ${styleSettings?.start?.assistantImageBorderStyle ?? 'none'};
            border-color: ${styleSettings?.start?.assistantImageBorderColor ?? 'none'};
            height: auto;
            border-radius: ${styleSettings?.start?.assistantImageBorderRadius ?? '50%'};
        }
        
        ${customStyles ? customStyles : ''}

        @media (max-width: 767px) {
            .voicify-assistant-minimal-speaking-indicator {
                border-radius: 0;
            }
            .voicify-assistant-chat-container-minimal {
                border-radius: 0;
            }
            .voicify-assistant-chat-container-minimal-show-history {
                border-radius: 0;
            }
            .voicify-assistant-minimal-ui-container-left {
                flex: unset;
            }
            .voicify-assistant-minimal-ui-container-right {
                flex: unset;
            }
            .voicify-assistant-container-minimal-show-history {
                bottom: 16px;
            }
            .voicify-assistant-container-minimal {
                bottom: 16px;
            }
            .voicify-assistant-chat-history-minimal {
                border-radius: 0;
            }
            .voicify-assistant-toolbar-minimal-show-history {
                border-radius: 0;
            }
            .voicify-assistant-toolbar-minimal {
                border-radius: 0;
            }
            .voicify-assistant-container {
                bottom: 20px;
                right: 20px;
                display: flex;
                flex-grow: 1;
                max-width: 335px;
                position: fixed;
                margin: 20px 0 0 20px;
            }
            .voicify-assistant-chat-container {
                max-width: 335px;
            }
            .voicify-assistant-header {
                font-size: calc(${styleSettings?.header?.fontSize ?? '18px'} - 2px);
            }
            .voicify-assistant-header-minimal {
                border-radius: 0;
            }
            .voicify-assistant-container-slide-up {
                max-width: 335px;
            }
            .voicify-assistant-foreground-image {
                width: 75%;
                align-self: self-end;
            }
            .voicify-assistant-toolbar-bottom {
                padding-left: calc(${styleSettings?.toolbar?.paddingLeft ?? '16px'} - 8px);
                padding-right: calc(${styleSettings?.toolbar?.paddingRight ?? '16px'} - 8px);
                padding-top: calc(${styleSettings?.toolbar?.paddingTop ?? '16px'} - 8px);
                padding-bottom: calc(${styleSettings?.toolbar?.paddingBottom ?? '16px'} - 8px);
            }
            .voicify-assistant-toolbar-right {
                margin-left: 4px;
            }
            .voicify-assistant-logo {
                height: 36px;
                width: 36px;
                margin-right: 4px;
            }
            .voicify-assistant-microphone img {
                height: calc(${styleSettings?.toolbar?.micImageHeight ?? '48px'} - 12px);
                width: calc(${styleSettings?.toolbar?.micImageWidth ?? '48px'} - 12px);
            }
            .voicify-assistant-send-button {
                height: 24px;
            }
            .voicify-assistant-send-icon {
                height: 20px;
                width: 20px;
            }
            .voicify-assistant-textbox {
                width: 75px;
            }
        }

        @media (max-width: 360px) {
            .voicify-assistant-container {
                max-width: 240px;
            }
            .voicify-assistant-chat-container {
                max-width: 240px;
            }
            .voicify-assistant-container-slide-up {
                max-width: 240px;
            }
        }

    `;
    shadowRoot.append(style);
}

export default createStyles;
