import React from 'react';
import { css } from '@emotion/css';
import { silver_four, color_shades_darkest, color_shades_darker, color_shades_lighter, color_colors_royalty } from '../../../constants/colors';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { Link } from 'react-router-dom';
import ApplicationAvatar from '../../../components/general/ApplicationAvatar';
import ApplicationCountsModel from '../../../models/applications/api/ApplicationCountsModel';
import ApplicationWithMembers from '../../../models/applications/api/ApplicationWithMembers';
import IconCollectionAvatar from '../../../components/general/IconCollectionAvatar';
import IconCollectionItem from '../../../models/media/IconCollectionItem';
import SmallOverflowMenu from '../../../components/general/overflowMenu/SmallOverflowMenu';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import WebhookCountTag from '../../../components/general/WebhookCountTag';

const folderIcon = require('../../../content/images/grey-folder.svg');
const contentItemIcon = require('../../../content/images/grey-page.svg');
const deleteIcon = require('../../../content/images/menu-item-icons/bin.svg');
const webhookIcon = require('../../../content/images/webhooks/webhook-dark.svg');

interface ApplicationCardProps {
    app: ApplicationWithMembers
    counts?: ApplicationCountsModel
    onOptionSelect?: (id: string, option: string) => void
    conversationCount?: number
}

const ApplicationCard: React.SFC<ApplicationCardProps> = (props) => (
    <div className={`${cardStyle} ac-app-list-item`}>
        <Link to={`/v/apps/${props.app.id}`} ><ApplicationAvatar className="app-icon" app={props.app} size={56} /></Link>
        <Link to={`/v/apps/${props.app.id}`} className={`app-name ${props.app.allowsTemplating ? 'basis-app' : ''}`}>
            {`${props.app.allowsTemplating ? '⬥ ' : ''}${props.app.name}`}
        </Link>
        <HorizontalSeparator />
        <HorizontalContainer className="counts">
            <TooltipWrapper text={`${props.counts == null ? '-' : props.counts.applicationFeatureCount} Folders`}>
                <div className="count-container">
                    <img src={folderIcon} />
                    <div>{props.counts == null ? '-' : props.counts.applicationFeatureCount}</div>
                </div>
            </TooltipWrapper>
            <TooltipWrapper text={`${props.counts == null ? '-' : props.counts.conversationCount} Conversation Items`}>
                <div className="count-container">
                    <img src={contentItemIcon} />
                    <div>{props.counts == null ? '-' : props.counts.conversationCount}</div>
                </div>
            </TooltipWrapper>
        </HorizontalContainer>
        <IconCollectionAvatar
            className={userAvatars}
            items={props.app.members?.filter(a => a.organizationMember.isApiUser == false)?.map(a => {
                var collectionItem: IconCollectionItem;
                collectionItem = {
                    noImageText: `${a.organizationMember.firstName == null ? "N" : a.organizationMember.firstName[0]} ${a.organizationMember.lastName == null ? "A" : a.organizationMember.lastName[0]}`,
                    tooltipText: `${a.organizationMember.firstName == null ? "N" : a.organizationMember.firstName[0]} ${a.organizationMember.lastName == null ? "A" : a.organizationMember.lastName[0]}`,
                    imageUrl: a.organizationMember.imageUrl
                }
                return collectionItem;
            })}
            limit={4} />
        <SmallOverflowMenu
            className={`${overflowStyle} ac-app-list-item-overflow`}
            buttonClassName={overflowMenuButtonStyle}
            optionClassName={overflowOptionStyle}
            menuClassName={overflowMenuStyle}
            isActive={false}
            onOptionSelected={(option) => props.onOptionSelect(props.app.id, option)}
            options={[{ label: 'Webhooks', icon: webhookIcon, hoverIcon: webhookIcon }, { label: 'Delete', icon: deleteIcon, hoverIcon: deleteIcon, isDestructive: true }]} />

        {props.app?.webhookInstances?.length > 0 &&
            <WebhookCountTag onClick={() => props.onOptionSelect(props.app.id, "Webhooks")} className={webhookCountStyle} count={props.app.webhookInstances?.length ?? 0} />
        }
    </div>
);

const userAvatars = css`
    justify-content: center;
`;

const overflowMenuStyle = css`
    width: 130px;
    margin-left: 0px;
    right: -11px;
    z-index: 2;
`;

const overflowStyle = css`
    position: absolute;
    top: 8px;
    right: 8px;
`;

const webhookCountStyle = css`
    position: absolute;
    top: 8px;
    left: 8px;
`;


const overflowOptionStyle = css`
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 130px;
`;

const overflowMenuButtonStyle = css`    
    border-radius: 4px;
    width: 24px;
    height: 24px;
    border-color: ${color_shades_darker};
    .circle {
        width: 4px;
        height: 4px;
        border-color: ${color_shades_darker};
    }
`;

const cardStyle = css`
    border-radius: 8px;
    background-color: white;
    border: solid 1px ${silver_four};
    margin: 48px 0px 48px 32px;
    position: relative;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    .app-icon {
        top: -28px;
        left: calc(50% - 28px);
        position: absolute;
        box-shadow: none;
    }

    .app-name {
        font-style: normal;
        font-stretch: normal;
        line-height: 24px; 
        letter-spacing: normal;
        color: ${color_shades_darkest};
        font-family: Muli;
        justify-content: center;
        font-size: 18px;
        margin: 32px auto 8px auto;
        text-align: center;
        flex-grow: 1;        
        &.basis-app {
            color: ${color_colors_royalty};
        }
    }

    .counts {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${color_shades_darker};
        margin: 8px 0px;
        justify-content: center;
        img {
            margin-right: 4px;
            margin-bottom: 3px;
        }
        .count-container {
            display: flex;
            align-items: center;
        }
    }
`

export default ApplicationCard;