import React, { useEffect, useState } from "react";
import SettingsCard from "../../../../components/structure/SettingsCard";
import styled from '@emotion/styled';
import ApplicationFulfillmentEndpoints from '../../../../models/applications/api/ApplicationFulfillmentEndpoints';
import { CardContainer, CopyButton, EndpointsHeader, Logo, StyledLink } from '../SharedStyledComponents';
import HorizontalContainer from '../../../../components/structure/HorizontalContainer';
import TextField from '../../../../components/forms/TextField';
import { style_border_default, style_border_dotted } from '../../../../constants/stylesValues';
import Button from '../../../../components/general/Button';
import { color_shades_darkest } from "../../../../constants/colors";
import { css } from "@emotion/css";
import ApplicationContainer from "../../../../state/containers/ApplicationContainer";
import CustomAssistantContainer from "../../../../hooks/CustomAssistantContainer";
import MediaContainer from "../../../../state/containers/MediaContainer";
import { CustomAssistantConfiguration } from "../../../../models/customAssistant/CustomAssistantConfiguration";
import CustomAssistantConfigurationsForm from "./CustomAssistantConfigurationsForm";
import CustomAssistantConfigurationsContainer from "../../hooks/useCustomAssistantConfigurationSettings";
import Loader from "../../../../components/general/Loader";
import { getAssistantPreviewApiUrl } from "../../../../constants/Urls";

const customAssistantLogo = require("../../../../content/images/platforms/custom-assistant-logo.svg");
const defaultAvatar = require("../../../../content/images/custom-assistant-deployment/default-avatar.svg");
const copyAvatar = require("../../../../content/images/custom-assistant-deployment/common-file-double-2.svg");
const deleteAvatar = require("../../../../content/images/custom-assistant-deployment/delete.svg");
const editAvatar = require("../../../../content/images/custom-assistant-deployment/edit.svg");
const coreDefaultSettings: CustomAssistantConfiguration = require("../../../../constants/defaultCustomAssistantConfiguration.json");

interface CustomAssistantDeploymentProps {
    endpoints: ApplicationFulfillmentEndpoints
    appContainer: ApplicationContainer
    applicationId: string
    environmentId: string
    mediaContainer: MediaContainer
}

const CustomAssistantDeploymentCard = (props: CustomAssistantDeploymentProps) => {
    const buildDefaultSettings = (): CustomAssistantConfiguration => {

        coreDefaultSettings.serverRootUrl = getAssistantPreviewApiUrl();
        coreDefaultSettings.applicationId = props.appContainer.state.currentApp.id;
        coreDefaultSettings.applicationSecret = props.appContainer.state.currentApp.secret;
        coreDefaultSettings.name = props.appContainer.state.currentApp.name;
        coreDefaultSettings.locale = props.appContainer.state.currentApp.languages[0].shortCode;
        coreDefaultSettings.styles.header.assistantName = props.appContainer.state.currentApp.name;
        coreDefaultSettings.styles.header.assistantImage = props.appContainer.state.currentApp.imageUrl;
        coreDefaultSettings.styles.start.assistantImage = props.appContainer.state.currentApp.imageUrl;
        // javaScript
        coreDefaultSettings.platformConfigurations.javaScript.serverRootUrl = getAssistantPreviewApiUrl();
        coreDefaultSettings.platformConfigurations.javaScript.applicationId = props.appContainer.state.currentApp.id;
        coreDefaultSettings.platformConfigurations.javaScript.applicationSecret = props.appContainer.state.currentApp.secret;
        coreDefaultSettings.platformConfigurations.javaScript.name = props.appContainer.state.currentApp.name;
        coreDefaultSettings.platformConfigurations.javaScript.locale = props.appContainer.state.currentApp.languages[0].shortCode;
        coreDefaultSettings.platformConfigurations.javaScript.styles.header.assistantName = props.appContainer.state.currentApp.name;
        coreDefaultSettings.platformConfigurations.javaScript.styles.header.assistantImage = props.appContainer.state.currentApp.imageUrl;
        coreDefaultSettings.platformConfigurations.javaScript.styles.start.assistantImage = props.appContainer.state.currentApp.imageUrl;
        // swift
        coreDefaultSettings.platformConfigurations.swift.serverRootUrl = getAssistantPreviewApiUrl();
        coreDefaultSettings.platformConfigurations.swift.applicationId = props.appContainer.state.currentApp.id;
        coreDefaultSettings.platformConfigurations.swift.applicationSecret = props.appContainer.state.currentApp.secret;
        coreDefaultSettings.platformConfigurations.swift.name = props.appContainer.state.currentApp.name;
        coreDefaultSettings.platformConfigurations.swift.locale = props.appContainer.state.currentApp.languages[0].shortCode;
        coreDefaultSettings.platformConfigurations.swift.styles.header.assistantName = props.appContainer.state.currentApp.name;
        coreDefaultSettings.platformConfigurations.swift.styles.header.assistantImage = props.appContainer.state.currentApp.imageUrl;
        // kotlin
        coreDefaultSettings.platformConfigurations.kotlin.serverRootUrl = getAssistantPreviewApiUrl();
        coreDefaultSettings.platformConfigurations.kotlin.applicationId = props.appContainer.state.currentApp.id;
        coreDefaultSettings.platformConfigurations.kotlin.applicationSecret = props.appContainer.state.currentApp.secret;
        coreDefaultSettings.platformConfigurations.kotlin.name = props.appContainer.state.currentApp.name;
        coreDefaultSettings.platformConfigurations.kotlin.locale = props.appContainer.state.currentApp.languages[0].shortCode;
        coreDefaultSettings.platformConfigurations.kotlin.styles.header.assistantName = props.appContainer.state.currentApp.name;
        coreDefaultSettings.platformConfigurations.kotlin.styles.header.assistantImage = props.appContainer.state.currentApp.imageUrl;
        
        return coreDefaultSettings;
    }
    const [isCreateConfigurationsOpen, setIsCreateConfigurationsOpen] = useState<boolean>(false);
    const [isEditConfigurationsOpen, setIsEditConfigurationsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [appId, setAppId] = useState<string>('');
    const [appKey, setAppKey] = useState<string>('');
    const [customAssistantConfigurations, setCustomAssistantConfigurations] = useState<CustomAssistantConfiguration[]>([]);
    const [configuration, setConfiguration] = useState<CustomAssistantConfiguration>(buildDefaultSettings());
    const [configId, setConfigId] = useState<string>('');
    const [configName, setConfigName] = useState<string>('');
    const [newConfigurationId, setNewConfigurationId] = useState<string>("");

    const customAssistantContainer = CustomAssistantContainer.useContainer();
    const customAssistantConfigurationsContainer = CustomAssistantConfigurationsContainer.useContainer();

    useEffect(() => {
        loadCustomAssistantConfigurations();
        props.appContainer.getInitialState();
        setAppId(props.appContainer.state.currentApp.id);
        setAppKey(props.appContainer.state.currentApp.secret);
        setConfigName(props.appContainer.state.currentApp.name);
    }, []);

    useEffect(() => {
        setCustomAssistantConfigurations(customAssistantContainer.customAssistantConfigurations);
    }, [customAssistantContainer.customAssistantConfigurations]);


    const clearConfiguration = () => {
        setConfiguration({
            name: props.appContainer.state.currentApp.name,
            applicationId: props.appContainer.state.currentApp.id,
            applicationSecret: props.appContainer.state.currentApp.secret,
            platformConfigurations: {
                javaScript: { name: props.appContainer.state.currentApp.name, applicationId: props.appContainer.state.currentApp.id, applicationSecret: props.appContainer.state.currentApp.secret },
                swift: { name: props.appContainer.state.currentApp.name, applicationId: props.appContainer.state.currentApp.id, applicationSecret: props.appContainer.state.currentApp.secret },
                kotlin: { name: props.appContainer.state.currentApp.name, applicationId: props.appContainer.state.currentApp.id, applicationSecret: props.appContainer.state.currentApp.secret }
            }
        });
        setConfigId('');
        setConfigName('');
        setNewConfigurationId('');
    };

    const restartAssistant = () => {
        if (window.voicifyAssistant) {
            window.voicifyAssistant.clearAllLocalStorage();
            window.voicifyAssistant = null;
        }
    };

    const loadCustomAssistantConfigurations = async () => {
        setIsLoading(true);
        await customAssistantContainer.getCustomAssistantConfigurations(props.applicationId);
        setCustomAssistantConfigurations(customAssistantContainer.customAssistantConfigurations);
        setIsLoading(false);
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const deleteCustomConfig = async (configId: string) => {
        setIsLoading(true);
        await customAssistantContainer.deleteCustomAssistantConfiguration(configId);
        loadCustomAssistantConfigurations();
        setIsLoading(false);
    };

    const copyCustomConfig = async (configId: string) => {
        setIsLoading(true);
        await customAssistantContainer.copyCustomAssistantConfiguration(configId);
        loadCustomAssistantConfigurations();
        setIsLoading(false);
    };

    const openFormAndCreateConfig = async (config: CustomAssistantConfiguration) => {
        setIsLoading(true);
        restartAssistant();
        setIsCreateConfigurationsOpen(true);
        const newConfig = await customAssistantContainer.createCustomAssistantConfiguration(config);
        setConfiguration(newConfig.data);
        setNewConfigurationId(newConfig.data.id);
        setIsLoading(false);
    };

    const editCustomConfigForm = (configId: string, configName: string, config: CustomAssistantConfiguration) => {
        setConfiguration(config);
        setConfigId(configId);
        setConfigName(configName);
        setIsEditConfigurationsOpen(true);
    };

    const closeCustomConfigForm = () => {
        restartAssistant();
        clearConfiguration();
        setIsCreateConfigurationsOpen(false);
        setIsEditConfigurationsOpen(false);
        loadCustomAssistantConfigurations();
    };

    const submitCustomAssistantConfiguration = async (config: CustomAssistantConfiguration) => {
        setIsLoading(true);
        await customAssistantContainer.updateCustomAssistantConfiguration(config);
        loadCustomAssistantConfigurations();
        setIsLoading(false);
    }

    return (
        <CardContainer id="custom-assistant-card">
            {(isCreateConfigurationsOpen || isEditConfigurationsOpen) &&
                <CustomAssistantConfigurationsForm
                    browserAssistantSettings={customAssistantConfigurationsContainer.browserAssistantSettings}
                    kotlinAssistantSettings={customAssistantConfigurationsContainer.kotlinAssistantSettings}
                    swiftAssistantSettings={customAssistantConfigurationsContainer.swiftAssistantSettings}
                    closeCustomConfigForm={closeCustomConfigForm}
                    appContainer={props.appContainer}
                    applicationId={appId}
                    appKey={appKey}
                    handleSubmitForm={submitCustomAssistantConfiguration}
                    appName={props.appContainer.state?.currentApp?.name ?? ""}
                    configName={configName}
                    appAvatar={props.appContainer.state.currentApp.imageUrl}
                    customAssistantConfigurations={customAssistantConfigurations}
                    configuration={configuration}
                    configId={isEditConfigurationsOpen ? configId : newConfigurationId}
                    isCreateConfigurationsOpen={isCreateConfigurationsOpen}
                    isEditConfigurationsOpen={isEditConfigurationsOpen}
                    copyText={copyText}
                    mediaContainer={props.mediaContainer}
                    isLoading={isLoading}
                />
            }
            <SettingsCard>
                <Logo src={customAssistantLogo} />
                <DescriptionWrapper>
                    <p>Let's get you ready to deploy to Custom Assistant!</p>
                    <br />
                    <p>Select the "Add a Configuration" button below to create a low-code Custom Assisant that you can deploy on your website and in your Android and iOS mobile apps.
                    </p>
                    <p>As an alternative, you can create a completely customized assistant using our browser, React Native, Android or iOS SDKs.</p>
                    <StyledLink
                        target="_blank"
                        href="https://support.voicify.com/en/knowledge/how-do-i-deploy-my-voicify-app/assistant-to-my-website"
                    > Read More </StyledLink>
                </DescriptionWrapper>
                <EndpointContainer>
                    <EndpointsHeader>Copy your endpoint url</EndpointsHeader>
                    <HorizontalContainer>
                        <TextField
                            useForm={false}
                            onChange={() => null}
                            value={props.endpoints?.customAssistantEndpoint ?? ""}
                            label="Endpoint URL"
                            readOnly
                            className={endpointTextField}
                        />
                        <CopyButton themes={["secondary"]} text="Copy" type="button" onClick={() => copyText(props.endpoints?.customAssistantEndpoint)} />
                    </HorizontalContainer>
                </EndpointContainer>
                <CustomConfigurationsTitle>Custom Configurations</CustomConfigurationsTitle>
                <CustomConfigurationsListWrapper>
                    {
                        isLoading ? <Loader /> :
                            customAssistantContainer.customAssistantConfigurations.length > 0 ?
                                customAssistantContainer.customAssistantConfigurations.map((customAssistantConfig) => {
                                    return (
                                        <CustomConfigurationsListItem key={customAssistantConfig.id}>
                                            <CustomConfigurationsItemLeft onClick={() => editCustomConfigForm(customAssistantConfig.id, customAssistantConfig.name, customAssistantConfig)}>
                                                <Avatar src={customAssistantConfig.avatarUrl ? customAssistantConfig.avatarUrl : defaultAvatar} />
                                                {customAssistantConfig.name ? customAssistantConfig.name : <p>Custom Assistant</p>}
                                            </CustomConfigurationsItemLeft>
                                            <CustomConfigurationsItemRight>
                                                <ActionIcon src={editAvatar} onClick={() => editCustomConfigForm(customAssistantConfig.id, customAssistantConfig.name, customAssistantConfig)} />
                                                <ActionIcon src={copyAvatar} onClick={() => copyCustomConfig(customAssistantConfig.id)} />
                                                <ActionIcon src={deleteAvatar} onClick={() => deleteCustomConfig(customAssistantConfig.id)} />
                                            </CustomConfigurationsItemRight>
                                        </CustomConfigurationsListItem>
                                    )
                                })
                                :
                                <p>There are no Custom Assistant configurations associated with this app.</p>
                    }
                </CustomConfigurationsListWrapper>
                <ConfigurationsButtonWrapper>
                    <ConfigurationsButton
                        themes={["primary", "wide"]}
                        text="Add a Configuration"
                        type="button"
                        onClick={() => openFormAndCreateConfig(buildDefaultSettings())}
                    />
                </ConfigurationsButtonWrapper>
            </SettingsCard>
        </CardContainer>
    );
};

const endpointTextField = css`
    margin-left: 32px;
    margin-bottom: 0;
`;

const ActionIcon = styled.img`
    cursor: pointer;
    margin: 12px 24px 12px 12px;
`;

const Avatar = styled.img`
    height: 36px;
    width: 36px;
    margin: 12px 12px 12px 24px;
    padding: 2px;
    border: ${style_border_default};
    border-radius: 50%;
`;

const CustomConfigurationsTitle = styled.h3`
    font-size: 18px;
    color: ${color_shades_darkest};
    font-weight: 400;
    margin-top: 32px;
`;

const ConfigurationsButtonWrapper = styled.div``;

const ConfigurationsButton = styled(Button)`
    padding: 6px 24px;
    margin: 24px 0;
    display: flex;
    align-items: center;
    flex: 4;
`;

const DescriptionWrapper = styled.div`
    padding: 32px 0;
    border-bottom: ${style_border_default};
`;

const EndpointContainer = styled.div`
    padding: 32px 0;
    border-bottom: ${style_border_dotted};
`;

const CustomConfigurationsListWrapper = styled.div`
    padding-top: 32px;
    display: flex;
    flex-direction: column;
`;

const CustomConfigurationsListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${style_border_default};
    border-top: none;
    border-radius: 8px;
    height: 56px;
    &:first-of-type {
        border-top: ${style_border_default};
    }
`;

const CustomConfigurationsItemLeft = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const CustomConfigurationsItemRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export default CustomAssistantDeploymentCard;