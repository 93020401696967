import React from 'react';
import {css} from '@emotion/css';
import { color_colors_ocean } from '../../../constants/colors';
const helpIcon = require('../../../content/images/question-help-message.svg');
const includedIcon = require('../../../content/images/check-square.svg');

interface AddMarkdownButtonProps {
    onClick: () => void
    hasValue?: boolean
}

const AddMarkdownButton: React.SFC<AddMarkdownButtonProps> = (props) => (
    <button className={containerStyle} onClick={props.onClick} type="button">
        <div className="left-side">
            <img src={helpIcon}/>
            <p>Helpful Info </p>
            {props.hasValue && <>
                <p>&nbsp;- Included&nbsp;</p>
                <img src={includedIcon}/>
            </>}
        </div>
        <p className="right-side">{props.hasValue ? 'View/Edit' : 'Add Info +'}</p>
    </button>
);

const containerStyle = css`
    height: 48px;
    flex: 1;
    width: 100%;
    border: 1px solid ${color_colors_ocean};
    color: ${color_colors_ocean};
    background: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin: 24px 0;
    .left-side {
        display: flex;
        align-items: center;
        img {
            margin-right: 8px;
        }
    }
    .right-side {
        margin-right: 0;
        margin-left: auto;
    }
`;

export default AddMarkdownButton;