import React from 'react';
import { css } from '@emotion/css';
import MediaItemModel from '../../models/media/MediaItemModel';
import { dark_grey_blue, silver_two } from '../../constants/colors';
import Button from './Button';
import Tooltip from 'rc-tooltip';
const audioIcon = require('../../content/images/audio_icon.svg');
const videoIcon = require('../../content/images/video_icon.svg');
const imageIcon = require('../../content/images/picture_icon.svg');
const backgroundIcon = require('../../content/images/background_icon.svg');
const editIcon = require('../../content/images/pencil-light.svg');
const closeIcon = require('../../content/images/delete-circle.svg');
const videoDisabledIcon = require('../../content/images/video-file-warning.svg');
const audioDisabledIcon = require('../../content/images/audio-file-warning.svg');
const imageDisabledIcon = require('../../content/images/image-file-warning.svg');

interface MediaItemChipProps {
    mediaItem?: MediaItemModel
    fieldName: string
    canEdit?: boolean
    borderless?: boolean
    canDelete?: boolean
    deleteDisabled?: boolean
    deleteTooltip?: string
    onDelete?: (item?: MediaItemModel, fieldName?: string) => void
}

const wrapperStyle = css`
    height: 50px;
    border-radius: 10px;
    border: solid 1px ${silver_two};
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 12px 0 24px;
    > * + * {
        margin-left: 16px;
    }
    img {
        width: 28px;
        height: auto;
        fill: black;
    }
    .edit {
        height: 28px;
        width: auto;
        margin-right: 0;
        margin-left: auto;
        img {
            margin: 0;
        }
    }
    span {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        word-break: break-all;
        flex: 1;
    }

    button:disabled {
        cursor: not-allowed;
    }
  `

const borderless = css`
  border: none;
  padding: 0;
  `

const tooltipContentStyle = css`
      .rc-tooltip-inner{
          font-family: Muli;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          letter-spacing: 0.6px;
          z-index: 120;
          color: white;
          background-color: #304562;
          box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
          padding: 8px;
          min-height: 20;
      }
  `
const getIcon: (mediaItem: MediaItemModel, fieldName?: string) => string = (mediaItem, fieldName) => {
    if (fieldName == "backgroundImageItem") {
        return backgroundIcon;
    }
    switch (mediaItem.mediaType) {
        case "AudioFile": return ((mediaItem.isDisabled == null || mediaItem.isDisabled === false) ? audioIcon : audioDisabledIcon);
        case "VideoFile": return ((mediaItem.isDisabled == null || mediaItem.isDisabled === false) ? videoIcon : videoDisabledIcon);
        default: return ((mediaItem.isDisabled == null || mediaItem.isDisabled === false) ? imageIcon : imageDisabledIcon);
    }
}
const getMediaName: (mediaItem: MediaItemModel) => string = (mediaItem) => {
    const mediaName =  mediaItem != null ? mediaItem.name : '';
    if(mediaItem.isDisabled === true) {
        switch (mediaItem.mediaType) {
            case "AudioFile": return `Missing Audio File (${mediaName})`;
            case "VideoFile": return `Missing Video File (${mediaName})`;
            default: return `Missing Image File (${mediaName})`;
        }
    }
    else {
        return mediaName;
    }
}
const MediaItemChip: React.SFC<MediaItemChipProps> = (props) => (
    <div className={`${wrapperStyle} ${props.borderless ? borderless : ''}`}>
        <img src={props.mediaItem !== undefined ? getIcon(props.mediaItem, props.fieldName) : null} />
        <span>{getMediaName(props.mediaItem)}</span>
        {props.canEdit ?
            <Button themes={['end-tight', 'icon']} icon={editIcon} additionalClasses="edit" type="button" />
            : null
        }
        {props.canDelete && !props.deleteTooltip ?
            <Button themes={['end-tight', 'icon']} disabled={props.deleteDisabled} icon={closeIcon} onClick={() => { return props.onDelete(props.mediaItem, props.fieldName) }} additionalClasses="edit" type="button" />
            : null
        }
        {props.canDelete && props.deleteTooltip ?
            <Tooltip overlayClassName={tooltipContentStyle} trigger={['hover']} placement="left" overlay={<p>{props.deleteTooltip}</p>}>
            <div>
                <Button themes={['end-tight', 'icon']} disabled={props.deleteDisabled} icon={closeIcon} onClick={() => { return props.onDelete(props.mediaItem, props.fieldName) }} additionalClasses="edit" type="button" />
                </div>
            </Tooltip>
            : null
        }
    </div>
);

export default MediaItemChip;