import React, { useEffect, useState } from "react";
import { Option } from 'react-select';
import RadioGroup from "../../../components/forms/RadioGroup";
import Button from "../../../components/general/Button";
import PageError from "../../../components/general/PageError";
import TextField from "../../../components/forms/TextField";
import SecondaryLoader from "../../../components/general/SecondaryLoader";
import { CopyButton, DeploymentsCard, Endpoints, EndpointsHeader, HelperText, LogoEndpointsHeader, StyledLink, WorkflowStepContainer, TopTriangleStyle, Logo, StepHeader, StepNumber, DeploymentStep, CardContainer, StepHelperText, BorderSeparator, LabelAndButtonRow, Filler, LabeledText, AlternateButton, MainButton, DeploymentSelector, StepFormContainer, PublishStatusRow } from './SharedStyledComponents';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import ApplicationFulfillmentEndpoints from '../../../models/applications/api/ApplicationFulfillmentEndpoints';
import DeploymentWorkflowStep from './DeploymentWorkflowStep';
import MicrosoftDeploymentContainer, { LuisAppSelectionType } from "../../../hooks/MicrosoftDeploymentContainer";

const microsoftLogo = require("../../../content/images/platforms/azure-bot-service-logo.png");
const linkOutIcon = require("../../../content/images/link_out.svg");
const linkOutWhiteIcon = require("../../../content/images/link-out-white.svg");
const endpointButtonIconActive = require('../../../content/images/application-settings/deployments/endpoint-button-icon-active.svg')
const endpointButtonIconInactive = require('../../../content/images/application-settings/deployments/endpoint-button-icon-inactive.svg')
interface MicrosoftDeploymentProps {
    organizationId: string;
    applicationId: string;
    environmentId: string;
    endpoints: ApplicationFulfillmentEndpoints;
    applicationStatusIcon: string;
}

const MicrosoftDeploymentCard = (props: MicrosoftDeploymentProps) => {

    const deploymentContainer = MicrosoftDeploymentContainer.useContainer();

    const [showEndpoints, setShowEndpoints] = useState(false);
    const [workflowStep, setWorkflowStep] = useState(1);

    useEffect(() => {
        deploymentContainer.getEnvironmentCurrentLink(props.applicationId, props.environmentId);
    }, [props.applicationId, props.environmentId]);

    useEffect(() => {
        switch (deploymentContainer.currentStep) {
            case 1:
                setWorkflowStep(1);
                break;
            case 2:
                setWorkflowStep(2);
                break;
            case 3:
                setWorkflowStep(3);
                break;
            case 4:
            default:
                setWorkflowStep(4);
                break;
        }

    }, [deploymentContainer.currentStep]);

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const handleLinkOrCreateLuis = async () => {
        if (deploymentContainer.luisAppType === "new") {
            await deploymentContainer.createAndDeployEnvironmentNewLuisApp(props.applicationId, props.environmentId);
        } else {
            await deploymentContainer.linkEnvironmentToLuisApp(props.applicationId, props.environmentId);
        }
    };

    const handleCreateLiusResourses = async () => {
        await deploymentContainer.linkEnvironmentLuisResources(props.applicationId, props.environmentId);
    };

    const isMainButtonDisabled = (step) => {

        if(deploymentContainer.isLinkingApp || deploymentContainer.isLoadingApps || deploymentContainer.isCreatingApp) {
            return true;
        }

        if (step === 1 && 
            (!deploymentContainer.deploymentLink.botServiceName?.trim() ||
            !deploymentContainer.deploymentLink.microsoftAppId?.trim() ||
            !deploymentContainer.deploymentLink.microsoftAppPassword?.trim())) {
            return true;
        }

        if (step === 2 && 
            (!deploymentContainer.deploymentLink.luisAuthoringKey?.trim() || 
            !deploymentContainer.deploymentLink.luisPredictionKey?.trim() ||
            !deploymentContainer.deploymentLink.luisPredictionRegion?.trim() ||
            !deploymentContainer.deploymentLink.luisPredictionEndpoint?.trim())) {
            return true;
        }

        if (step === 3 && 
            !deploymentContainer.deploymentLink.luisAppId &&
            deploymentContainer.luisAppType === "existing") {
            return true;
        }

        return false;
    };
    
    const renderStep1 = () => {
        return (
            <>
                <DeploymentStep>
                    <StepHeader>
                        <StepNumber>1</StepNumber>
                        <div>Registration</div>
                    </StepHeader>
                    <EndpointsHeader>
                        <StepHelperText>Copy your endpoint url</StepHelperText>
                    </EndpointsHeader>
                    <HorizontalContainer>
                        <TextField
                            useForm={false}
                            value={props.endpoints?.botServiceEndpoint || ""}
                            onChange={null}
                            readOnly
                            label="Endpoint Url"
                        />
                        <CopyButton
                            themes={["secondary"]}
                            text="Copy"
                            type="button"
                            onClick={() => copyText(props.endpoints?.botServiceEndpoint)}
                        />
                    </HorizontalContainer>

                    <BorderSeparator />

                    <StepHelperText>
                        <p>
                            Register your account in the Azure Portal. View our documents for more information on what to do.
                            <StyledLink
                                target="_blank"
                                href="https://support.voicify.com/en/knowledge/microsoft-deployment"
                            > Read More </StyledLink>
                        </p>
                    </StepHelperText>
                    <StepHelperText>
                        <StyledLink target="_blank" href="https://portal.azure.com/"> Open Developer Portal <img src={linkOutIcon}></img>
                        </StyledLink>
                    </StepHelperText>
                    <StepHelperText>
                        <p>Once you’ve registered your bot in the Azure Portal, copy these fields into Voicify:</p>
                    </StepHelperText>
                    <StepFormContainer>
                        <TextField
                            required
                            useForm={false}
                            name="botname"
                            label="Bot Service Handle"
                            placeholder="Get this from your bot service in azure"
                            value={deploymentContainer.deploymentLink.botServiceName}
                            onChange={(e) => deploymentContainer.setBotName(e.target.value)}
                            onBlur={() => { }}
                        />
                        <TextField
                            required
                            useForm={false}
                            name="msappid"
                            label="Microsoft App ID"
                            placeholder="Get this from your bot service in azure"
                            value={deploymentContainer.deploymentLink.microsoftAppId}
                            onChange={(e) => deploymentContainer.setMsAppId(e.target.value)}
                            onBlur={() => { }}
                        />
                        <TextField
                            required
                            useForm={false}
                            name="msapppassword"
                            label="Microsoft App Secret Value"
                            placeholder="Get this from your bot service in azure"
                            value={deploymentContainer.deploymentLink.microsoftAppPassword}
                            onChange={(e) => deploymentContainer.setMsAppPassword(e.target.value)}
                            onBlur={() => { }}
                        />
                    </StepFormContainer>
                </DeploymentStep>
                <MainButton
                    themes={["primary", "wide"]}
                    text="Link"
                    type="button"
                    disabled={isMainButtonDisabled(workflowStep)}
                    onClick={ () => deploymentContainer.linkEnvironmentToBot(props.applicationId, props.environmentId)}
                />
            </>
        );
    };

    const renderStep1Complete = () => {
        return (
            <DeploymentStep>
                <StepHeader>
                    <StepNumber>1</StepNumber>
                    <div>Registration</div>
                </StepHeader>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Bot Service Handle</span>
                        <p>{deploymentContainer.deploymentLink.botServiceName}</p>
                    </LabeledText>
                    <Filler />
                </LabelAndButtonRow>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Microsoft App ID</span>
                        <p>{deploymentContainer.deploymentLink.microsoftAppId}</p>
                    </LabeledText>
                    <Filler />
                </LabelAndButtonRow>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Microsoft App Secret Value</span>
                        <p>
                            {deploymentContainer.deploymentLink.microsoftAppPassword?.substr(
                                0,
                                4
                            ) + "**************"}
                        </p>
                    </LabeledText>
                    <Filler />
                    <AlternateButton
                        themes={["secondary-small"]}
                        text="Disconnect"
                        onClick={() => deploymentContainer.unlinkEnvironmentMicrosoftProject(props.applicationId, props.environmentId)}
                    />
                </LabelAndButtonRow>
            </DeploymentStep>
        );
    };

    const renderStep2 = () => {
        return (
            <>
                <DeploymentStep>
                    <StepHeader>
                        <StepNumber>2</StepNumber>
                        <div>Luis Resources Setup</div>
                    </StepHeader>
                    <StepHelperText>
                        <p>To learn how to create your Microsoft Luis App and set up your Luis resources, see the Voicify knowledge base article.
                            <StyledLink
                                target="_blank"
                                href="https://support.voicify.com/en/knowledge/how-do-i-get-luis-resource-information"
                            > Read More </StyledLink>
                        </p>
                    </StepHelperText>
                    <StepHelperText>
                        <StyledLink target="_blank" href="https://portal.azure.com/"> Open Developer Portal <img src={linkOutIcon}></img>
                        </StyledLink>
                    </StepHelperText>
                    <StepHelperText>
                        <p>Create a Microsoft Luis App and paste required info here:</p>
                    </StepHelperText>
                    <StepFormContainer>
                        <TextField
                            required
                            useForm={false}
                            name="luiskey"
                            label="Azure Language Understanding / Luis Authoring Key"
                            placeholder="Get this from your Luis account settings"
                            value={deploymentContainer.deploymentLink.luisAuthoringKey}
                            onChange={(e) => deploymentContainer.setAuthoringKey(e.target.value)}
                        />

                        <TextField
                            required
                            useForm={false}
                            name="luisPredictionKey"
                            label="Azure Language Understanding / Luis Prediction Key"
                            placeholder="Get this from your Luis account settings"
                            value={deploymentContainer.deploymentLink.luisPredictionKey}
                            onChange={(e) => deploymentContainer.setPredictionKey(e.target.value)}
                        />
                        <TextField
                            required
                            useForm={false}
                            name="luisPredictionRegion"
                            label="Azure Language Understanding / Luis Prediction Region"
                            placeholder="Get this from your Luis account settings"
                            value={deploymentContainer.deploymentLink.luisPredictionRegion}
                            onChange={(e) => deploymentContainer.setPredictionRegion(e.target.value)}
                        />
                        <TextField
                            required
                            useForm={false}
                            name="luisPredictionEndpoint"
                            label="Azure Language Understanding / Luis Prediction Endpoint"
                            placeholder="Get this from your Luis account settings"
                            value={deploymentContainer.deploymentLink.luisPredictionEndpoint}
                            onChange={(e) => deploymentContainer.setPredictionEndpoint(e.target.value)}
                        />
                    </StepFormContainer>
                </DeploymentStep>
                <MainButton
                    themes={["primary", "wide"]}
                    text="Link"
                    type="button"
                    disabled={isMainButtonDisabled(workflowStep)}
                    onClick={handleCreateLiusResourses}
                />
            </>
        );
    };

    const renderStep2Complete = () => {
        return (
            <DeploymentStep>
                <StepHeader>
                    <StepNumber>2</StepNumber>
                    <div>Luis Resources Setup</div>
                </StepHeader>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Azure Language Understanding / Luis Authoring Key</span>
                        <p>
                            {deploymentContainer.deploymentLink.luisAuthoringKey?.substr(0, 4) +
                                "*****************************"}
                        </p>
                    </LabeledText>
                    <Filler />
                </LabelAndButtonRow>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Azure Language Understanding / Luis Prediction Key</span>
                        <p>{deploymentContainer.deploymentLink.luisPredictionKey?.substr(0, 4) +
                                "*****************************"}</p>
                    </LabeledText>
                    <Filler />
                </LabelAndButtonRow>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Azure Language Understanding / Luis Prediction Region</span>
                        <p>{deploymentContainer.deploymentLink.luisPredictionRegion}</p>
                    </LabeledText>
                    <Filler />
                </LabelAndButtonRow>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Azure Language Understanding / Luis Prediction Endpoint</span>
                        <p>{deploymentContainer.deploymentLink.luisPredictionEndpoint}</p>
                    </LabeledText>
                    <Filler />
                    <AlternateButton
                        themes={["secondary-small"]}
                        text="Disconnect"
                        onClick={() => deploymentContainer.unlinkEnvironmentLuisApp(props.applicationId, props.environmentId)}
                    />
                </LabelAndButtonRow>
            </DeploymentStep>
        );
    };

    
    const handleCreateNewSelectionChange = async (newValue) => {
        if (!newValue) return;
        await deploymentContainer.setLuisAppSelection(newValue.value as LuisAppSelectionType); 
    };

    const handleAppSelectionChange = async (option) => {
        const o = (option as Option<string>).value;
        await deploymentContainer.setLuisAppId(o); 
    }

    const renderStep3 = () => {
        // choose existing luis or create new
        return (
            <>
                <DeploymentStep>
                    <StepHeader>
                        <StepNumber>3</StepNumber>
                        <div>Link Luis App</div>
                    </StepHeader>
                    <StepHelperText>
                        <p>Link your Voicify app to a Luis application:</p>
                    </StepHelperText>

                    <RadioGroup options={[
                            { value: "new", label: "Create a new app" },
                            { value: "existing", label: "Connect to an existing app" },
                        ]}
                        onChange={handleCreateNewSelectionChange}
                        value={deploymentContainer.luisAppType || "new"} />
                    
                    <DeploymentSelector
                        useFormTheme={true}
                        useForm={false}
                        options={deploymentContainer.luisApps?.map((a) => ({
                            value: a.id,
                            label: a.name,
                        }))}
                        disabled={
                            deploymentContainer.luisAppType === "new" ||
                            deploymentContainer.isLoadingApps ||
                            deploymentContainer.isCreatingApp
                        }
                        value={deploymentContainer.deploymentLink.luisAppId}
                        onBlur={() => { }}
                        onChange={handleAppSelectionChange}
                        name="deployToApp"
                        label={"Luis Application"} />
                </DeploymentStep>
                <MainButton
                    themes={["primary", "wide"]}
                    text={deploymentContainer.luisAppType === "new" ? "Create" : "Link"}
                    type="button"
                    loading={deploymentContainer.isCreatingApp}
                    disabled={isMainButtonDisabled(workflowStep)}
                    onClick={handleLinkOrCreateLuis}
                />
            </>
        );
    };

    const renderStep3Complete = () => {
        const luisApp = deploymentContainer.luisApps.find(
            (a) => a.id == deploymentContainer.deploymentLink.luisAppId
        );
        return (
            <DeploymentStep>
                <StepHeader>
                    <StepNumber>3</StepNumber>
                    <div>Link Luis App</div>
                </StepHeader>
                {deploymentContainer.isLoadingApps ? (
                    <SecondaryLoader />
                ) : (
                    <>
                    <LabelAndButtonRow>
                        <LabeledText>
                            <span>Luis App Name</span>
                            <p>{deploymentContainer.luisAppName
                                    ? deploymentContainer.luisAppName
                                    : luisApp
                                        ? luisApp.name
                                        : "Unknown"}</p>
                        </LabeledText>
                        <Filler />
                    </LabelAndButtonRow>
                    <LabelAndButtonRow>
                        <LabeledText>
                            <span>Luis App ID</span>
                            <p>{deploymentContainer.deploymentLink.luisAppId}</p>
                        </LabeledText>  
                        <Filler />
                    </LabelAndButtonRow>
                    <LabelAndButtonRow>
                        <LabeledText>
                            <span>Luis Region</span>
                            <p>{deploymentContainer.deploymentLink.luisRegion}</p>
                        </LabeledText>
                        <Filler />
                        <AlternateButton
                            themes={["secondary-small"]}
                            text="Disconnect"
                            onClick={() => deploymentContainer.unlinkEnvironmentLuisApp(props.applicationId, props.environmentId)}
                        />
                    </LabelAndButtonRow>
                    </>
                )}
            </DeploymentStep>
        );
    };

    const renderStep4Complete = () => {
        return (
            <>
            <DeploymentStep>
                <StepHeader>
                    <StepNumber>4</StepNumber>
                    <div>Launch</div>
                </StepHeader>
                <LabelAndButtonRow>
                <LabeledText>
                    <span>Application Status</span>
                    <PublishStatusRow>
                        <a href="https://portal.azure.com/" target="_blank">
                            <img src={props.applicationStatusIcon} />
                        </a>
                        <p>{"Linked"}</p>
                    </PublishStatusRow>
                </LabeledText>
                <Filler />
                <StyledLink target="_blank" href="https://support.voicify.com/en/knowledge/microsoft-deployment">View our launch docs</StyledLink>
            </LabelAndButtonRow>
            <StepHelperText>
                <p>Your Voicify and Luis apps are linked.</p>
            </StepHelperText>
            </DeploymentStep>
            <a href="https://portal.azure.com/" target="_blank">
                <MainButton
                    themes={["primary", "wide"]}
                    text="Developer Portal"
                    type="button"
                    disabled={isMainButtonDisabled(workflowStep)}
                    rightIcon={linkOutWhiteIcon}
                    onClick={null} />
            </a>
        </>
        );
    };

    const renderStepContent = () => {
        const currentStep = deploymentContainer.currentStep;
        return (
            <>
                {currentStep == 1 ? renderStep1() : null}
                {currentStep > 1 ? renderStep1Complete() : null}
                {currentStep == 2 ? renderStep2() : null}
                {currentStep > 2 ? renderStep2Complete() : null}
                {currentStep == 3 ? renderStep3() : null}
                {currentStep > 3 ? renderStep3Complete() : null}
                {currentStep >= 4 ? renderStep4Complete() : null}
            </>
        );
    };

    return (
        <CardContainer>
            <DeploymentsCard id="bot-service-card">
                <LogoEndpointsHeader>
                    <Logo src={microsoftLogo} />
                    <Button
                        themes={["secondary-small"]}
                        disabled={props.endpoints?.botServiceEndpoint == null}
                        text={showEndpoints ? "Hide Endpoints" : "Show Endpoints"}
                        onClick={() => setShowEndpoints(!showEndpoints)}
                        type="button"
                        rightIcon={showEndpoints ? endpointButtonIconActive : endpointButtonIconInactive}
                        className={showEndpoints ? `button-active` : null}
                    />
                </LogoEndpointsHeader>
                {showEndpoints && (
                    <>
                        <TopTriangleStyle />
                        <Endpoints>
                            <EndpointsHeader>Fulfillment Endpoints</EndpointsHeader>
                            <HorizontalContainer>
                                <TextField
                                    useForm={false}
                                    value={props.endpoints?.botServiceEndpoint}
                                    onChange={null}
                                    readOnly
                                    label="Direct Fulfillment"
                                />
                                <CopyButton
                                    themes={["secondary"]}
                                    text="Copy"
                                    type="button"
                                    onClick={() => copyText(props.endpoints?.botServiceEndpoint)}
                                />
                            </HorizontalContainer>
                        </Endpoints>
                    </>
                )}
                <HelperText>
                    <p>Let’s get you ready to deploy to Azure Bot Service! <br /> <br /> </p>
                    <p>This process will get your account on Voicify synced with your Microsoft account and deploy your app to a specific LUIS app. </p>
                    <StyledLink
                        target="_blank"
                        href="https://support.voicify.com/en/knowledge/microsoft-deployment"
                    > Read More </StyledLink>
                </HelperText>
                <WorkflowStepContainer>
                    <DeploymentWorkflowStep
                        name="Registration"
                        currentStep={workflowStep}
                        step={1}
                    />
                    <DeploymentWorkflowStep
                        name="Luis Resources Setup"
                        currentStep={workflowStep}
                        step={2}
                    />
                    <DeploymentWorkflowStep
                        name="Link Luis App"
                        currentStep={workflowStep}
                        step={3}
                    />
                    <DeploymentWorkflowStep
                        name="Launch"
                        currentStep={workflowStep}
                        step={4}
                    />
                    <DeploymentWorkflowStep
                        last
                        icon={props.applicationStatusIcon}
                        currentStep={workflowStep}
                        step={5} 
                        href="https://portal.azure.com/" 
                    />
                </WorkflowStepContainer>

                {deploymentContainer.isLoadingLink ? (
                    <SecondaryLoader />
                ) : (
                    renderStepContent()
                )}
            </DeploymentsCard>
            <PageError 
                errors={deploymentContainer.errors} 
                onClear={deploymentContainer.clearErrors} />
        </CardContainer>
    );
};

export default MicrosoftDeploymentCard;
