import React from 'react';
import { css } from '@emotion/css';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
import { cool_grey } from '../../../constants/colors';
import ToggleableSection from '../../../components/structure/ToggleableSection';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import IconTooltip from '../../../components/general/IconTooltip';
import Tooltip from 'rc-tooltip';
import _ from 'lodash';
const puzzleIcon = require('../../../content/images/puzzle-orange.svg');
const allowIcon = require('../../../content/images/green-check-circle.svg');
const deniedIcon = require('../../../content/images/red-no.svg');

interface CompatabilityViewerProps {
    supportedTargets: DeviceTargetModel[]
    unsupportedTargets: DeviceTargetModel[]
    detailText: string
}


const containerStyle = css`
    .title-container {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        img {
            margin-right: 12px;
        }
    }
    .compat-title {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #faa21b;
    }

    .detail {
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${cool_grey};
    }

    .detail-grouping {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        height: 52px;
        .group-title {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #304562;
            margin-left: 8px;
        }
        .device-grouping {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-right: 0;
            margin-left: auto;

            img {
                height: 30px;
                margin-left: 6px;
                cursor: pointer;
                padding-left: 11px;
            }
        }
    }
`;

const tooltipContentStyle = css`
    .rc-tooltip-inner{
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0.6px;
        z-index: 120;
        color: white;
        background-color: #304562;
        box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
        padding: 8px;
        min-height: 0;
    }
`
const renderDevice = (target: DeviceTargetModel, index: number) => (
    <div key={target.id} className="device-target">
        <Tooltip mouseEnterDelay={.25} overlayClassName={tooltipContentStyle} mouseLeaveDelay={.5} trigger={['hover', 'click']} placement="top" overlay={<span>{target.displayName}</span>}>
            <img src={target.iconUrl}/>
        </Tooltip>
    </div>
)
const CompatabilityViewer: React.SFC<CompatabilityViewerProps> = (props) => (
    <div className={containerStyle}>
        <div className="title-container">
            <img src={puzzleIcon} />
            <p className="compat-title">Limited Compatability</p>
        </div>
        <p className="detail">{props.detailText}</p>
        <ToggleableSection showTitle="Show Compatability Details" hideTitle="Hide Compatability Details" isCollapsed={true}>
            <div className="detail-grouping">
                <img src={allowIcon} />
                <p className="group-title">Will Likely Render</p>
                <div className="device-grouping">
                    {_.sortBy(props.supportedTargets, d => d.displayName).map(renderDevice)}
                </div>
            </div>
            <HorizontalSeparator />
            <div className="detail-grouping">
                <img src={deniedIcon} />
                <p className="group-title">Not Compatible</p>
                <div className="device-grouping">
                    {_.sortBy(props.unsupportedTargets, d => d.displayName).map(renderDevice)}
                </div>
            </div>
        </ToggleableSection>
    </div>
)

export default CompatabilityViewer;