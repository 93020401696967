import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "./scenes/layout";
import { Provider } from 'unstated';
import ContainerProvider from "./ContainerProvider";
declare global {
    interface Window { analytics: any; }
};

const App = () => {
    return (
        <Provider>
            <ContainerProvider>
                <Router>
                    <Route path="/" component={Layout} />
                </Router>
            </ContainerProvider>
        </Provider>
    );
}

render(<App />, document.querySelector("#root"));

