import { withFormik } from "formik";
import * as yup from 'yup';
import ExitMessageForm, { EditExitMessageFormData, EditExitMessageFormProps } from '../../formScenes/ExitMessageForm';

const ExitMessageContentForm = withFormik<EditExitMessageFormProps, EditExitMessageFormData>({
    mapPropsToValues: (props) => ({
        responses: [{ content: '' }],
        title: 'Untitled Exit Message',
        isLive: true,
        entryType: 'open',
        requiresParent: false,
        sensitiveConversationChange: "None",
        languages: props.application?.languages.find(l => l.id == props.application?.defaultLanguageId) ? [props.application?.languages.find(l => l.id == props.application?.defaultLanguageId)] : [],
        webhookChainingType: "NoChaining"
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus, setFieldValue }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel({
            applicationFeatureId: props.applicationFeatureId,
            applicationId: props.application?.id,
            responses: []
        }, values));

    }
})(ExitMessageForm);

export default ExitMessageContentForm;