import React, { Ref } from 'react';
import CustomAssistantResponse from '../../../models/customAssistant/CustomAssistantResponse';
import SimulatorMessageBar from './SimulatorMessageBar';
import LanguageModel from '../../../models/features/api/LanguageModel';
import MediaResponseStateContainer from '../../../state/containers/MediaResponseContainer';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import DeviceAndEnvironmentPicker from './DeviceAndEnvironmentPicker';
import SimulatorConversationViewer from './SimulatorConversationViewer';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
import { color_shades_lighter, color_shades_dark } from '../../../constants/colors';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import styled from '@emotion/styled';
import { SimulatorEnvironment } from './Simulator';

interface SimulatorInteractionProps {
    applicationId: string
    responseContainer: MediaResponseStateContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    ssmlContainer: SsmlContainer
    selectedLanguage?: LanguageModel
    selectedDevice?: DeviceTargetModel
    requests: SimulatorMessage[]
    responses: CustomAssistantResponse[]
    isWaitingForResponse?: boolean
    simulatorMessageBarInputRef: Ref<HTMLInputElement>
    handleSendMessage: (text: string) => void
    handleDeviceSelected: (device: DeviceTargetModel) => void
    handleSendWelcome: () => void
    handleSetEnvironment: (env: SimulatorEnvironment) => void
    useDraftContent: boolean
}

export interface SimulatorMessage {
    text: string
}

const SimulatorInteraction: React.FC<SimulatorInteractionProps> = (props) => (
    <SimulatorInteractionContainer>
        <DeviceAndEnvironmentPicker
            availableDevices={props.responseContainer.state.deviceTargets}
            selectedTarget={props.selectedDevice}
            onSetEnvironment={props.handleSetEnvironment}
            useDraftContent={props.useDraftContent}
            onDeviceSelectionChange={props.handleDeviceSelected.bind(this)}
        />
        <SimulatorConversationViewer
            applicationId={props.applicationId}
            ssmlContainer={props.ssmlContainer}
            selectedDevice={props.selectedDevice}
            selectedLanguage={props.selectedLanguage}
            userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
            requests={props.requests}
            responses={props.responses}
            handleSendMessage={props.handleSendMessage}
        />
        <SimulatorMessageBar
            showSendWelcomeButton={props.requests.length == 0}
            simulatorMessageBarInputRef={props.simulatorMessageBarInputRef}
            onSubmit={props.handleSendMessage}
            handleSendWelcome={props.handleSendWelcome}
            selectedLanguage={props.selectedLanguage}
            isDisabled={(props.isWaitingForResponse || !props.selectedLanguage)} />
    </SimulatorInteractionContainer>
);

const SimulatorInteractionContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2;
    background: ${color_shades_lighter};
    border: 1px solid ${color_shades_dark};
    border-top: none;
    position: relative;
    >*{
        width: calc(100%-15px);
    }
`;

export default SimulatorInteraction;