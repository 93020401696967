import OrganizationContainer from "../../../state/containers/OrganizationContainer";
import React from "react";
import SettingsCard from "../../../components/structure/SettingsCard";
import { css } from '@emotion/css';
import { pale_grey } from "../../../constants/colors";
import { Subscribe } from "unstated";
import DeploymentAccountsContainer from "../../../state/containers/DeploymentAccountsContainer";
import DeploymentAccounts from "../../deploymentAccounts";

const amazonButton = require('../../../content/images/platforms/amazon-button.png');
const googleButton = require('../../../content/images/platforms/google-button-large.png');
const loginHeader = css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    > span {
        margin: 8px;
    }
`;

const amazonLogin = css`
    width: 170px;
    height: 40px;
    cursor: pointer;
`;

const googleLogin = css`
    width: 185px;
    height: 45px;
    cursor: pointer
`;


interface OrganizationDeploymentAccountsProps {
    organizationId: string,
    deploymentAccountsContainer: DeploymentAccountsContainer
}

class OrganizationDeploymentAccounts extends React.Component<OrganizationDeploymentAccountsProps>{

    loginWithAmazon() {
        this.props.deploymentAccountsContainer
            .getAmazonAuthUrl({ organizationId: this.props.organizationId })
            .then(url => {
                if (url)
                    window.open(url, "_self")
            })

    }

    loginWithGoogle() {
        this.props.deploymentAccountsContainer
            .getGoogleAuthUrl({ organizationId: this.props.organizationId })
            .then(url => {
                if (url)
                    window.open(url, "_self")
            });
    }

    render() {
        return (
            <SettingsCard title="Deployments">
                <div className={loginHeader}>
                    <span>Link/Update an account: </span>
                    <span><img src={amazonButton} className={amazonLogin} onClick={this.loginWithAmazon.bind(this)}></img></span>
                    <span><img src={googleButton} className={googleLogin} onClick={this.loginWithGoogle.bind(this)}></img></span>
                </div>
                <DeploymentAccounts organizationId={this.props.organizationId} />
            </SettingsCard>
        )
    }
}

export default OrganizationDeploymentAccounts;