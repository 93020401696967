import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, color_text_default } from '../../../constants/colors';

interface ContentSectionTitleProps {
    title: string
    icon: string
}

const ContentSectionTitle: React.FC<ContentSectionTitleProps> = (props) => (
    <div className={containerStyle}>
        <img src={props.icon} />
        <h3 className={styledTitle}>{props.title}</h3>
    </div>
)

const styledTitle = css `
    font-weight: 400;
    font-size: 18px;
    font-style: 'Muli';
`

const containerStyle = css`

    display: flex;
    align-items: center;
    border-bottom: 1px solid ${color_shades_dark};
    padding: 12px 0;
    
    h4 {

        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        color: ${color_text_default};
    }
    img{
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }

`;

export default ContentSectionTitle