import React, { useEffect, useState } from 'react';
import LanguageModel from '../../../models/features/api/LanguageModel';
import { DirtyFormPrompt } from '../../formScenes/components/DirtyFormPrompt';
import ContentLanguageMultiSelector from '../../../components/forms/ContentLanguageMultiSelector';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import styled from '@emotion/styled';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import EditableNlpEntity from '../../../models/nlpEntity/EditableNlpEntity';
import { css } from '@emotion/css';
import NlpEntityValueModel from '../../../models/nlpEntity/api/NlpEntityValueModel';
import NumberIncrementSelector from '../../../components/forms/NumberIncrementSelector';
import SearchField from '../../../components/forms/SearchField';
import _ from 'lodash';
import RoundedSelectionRow from '../../../components/general/RoundedSelectionRow';
import NlpEntityValuesListDisplay from './NlpEntityValuesListDisplay';
import NlpEntityValuesTextBoxDisplay from './NlpEntityValuesTextBoxDisplay';
import { LARGE_ENTITY_VALUES_COUNT } from '../../../hooks/ApplicationNlpEntityContainer';
import ImportFileDialog from '../../../components/general/ImportFileDialog';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import WarningConfirmation from '../../../components/general/WarningConfirmation';
import { color_colors_ocean, color_text_link, color_variants_ocean_light_opaque, color_shades_dark, color_shades_darkest, color_text_default, color_gradients_silver_shift } from '../../../constants/colors';
import { SmallTag, SmallTagWithMargin } from './SharedStyledComponents';
import { FormikErrors } from 'formik';
import { convertCSVtextToValues, convertValuesToCSVText, InteractionModelFormData } from './InteractionModelForm';
import TextOverflowMenu from '../../../components/general/overflowMenu/TextOverflowMenu';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import HorizontalOptionsSelector from '../../../components/forms/HorizontalOptionsSelector';
import { style_border_default } from '../../../constants/stylesValues';
import { CountButton } from '../../contentPage/components/CountButton';
import ContentCard from '../../../components/structure/ContentCard';

const closeIcon = require('../../../content/images/close_icon.svg');
const tableViewIconLight = require('../../../content/images/layout-list.svg');
const tableViewIconSelected = require('../../../content/images/layout-list-selected.svg');
const tableTextboxIcon = require('../../../content/images/layout-textbox.svg');
const tableTextboxIconSelected = require('../../../content/images/layout-textbox-selected.svg');
const importIcon = require("../../../content/images/content-explorer/import.svg");
const exportIcon = require("../../../content/images/content-explorer/export-blue.svg")
const questionIcon = require("../../../content/images/content-explorer/question.svg")
const selectedQuestionIcon = require("../../../content/images/content-explorer/selected-question.svg")
const searchQuestionIcon = require("../../../content/images/search-question-mark-icon.svg");
const nonEditableIcon = require("../../../content/images/nlp-icons/non-editable-entity.svg");
const sensitiveEntity = require('../../../content/images/data-sensitivity/computer-lock.svg');
const noTrackingEntity = require('../../../content/images/data-sensitivity/computer-notracking.svg');
const confidenceScoreIcon = require('../../../content/images/content-tree-icons/content-level/check-shield.svg')
const blueConfidenceScoreIcon = require('../../../content/images/content-tree-icons/content-level/blue-check-shield.svg')

const numberOfValuesPerPage: number = 10;
type entityValuesDisplayType = "ListDisplay" | "TextBoxDisplay"
const ENTITY_SENSITIVITY_NONE = "None";
const ENTITY_SENSITIVITY_SENSITIVE = "Sensitive";
const ENTITY_SENSITIVITY_NOTRACKING = "No Tracking";

export interface ApplicationNlpEntityCardProps {
    languages?: LanguageModel[];
    nlpEntities: EditableNlpEntity[];
    nlpEntity: EditableNlpEntity;
    formPrefix: string;
    formIndex: number;
    validateField;
    validateForm;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    setErrors: (errors: FormikErrors<InteractionModelFormData>) => void;
    handleSubmit;
    dirty: boolean;
    isValid: boolean;
    status?: any;
};

export interface NlpValueFormDisplayInfo {
    nlpEntityValue: NlpEntityValueModel;
    originalArrayIndex: number;
    displayIndex: number;
};

const ApplicationNlpEntityCard = (props: ApplicationNlpEntityCardProps) => {

    const [activeItemIndex, setActiveItemIndex] = useState(-1);
    const [valuesFormDisplayInfo, setValuesFormDisplayInfo] = useState([] as NlpValueFormDisplayInfo[]);
    const [filteredNumberOfValues, setFilteredNumberOfValues] = useState(0);
    const [valuesPageNumber, setValuesPageNumber] = useState(1);
    const [valuesSearchTerm, setValuesSearchTerm] = useState("");
    const [triggerNlpValuesSearch, setTriggerNlpValuesSearch] = useState("");
    const [viewDisplayType, setViewDisplayType] = useState("ListDisplay" as entityValuesDisplayType);
    const [isImportFileDialogModalOpen, setIsImportFileDialogModalOpen] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const [fileImportErrors, setFileImportErrors] = useState([]);
    const [importedValues, setImportedValues] = useState([] as NlpEntityValueModel[]);
    const [isConfirmImportValuesModalOpen, setIsConfirmImportValuesModalOpen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showConfidenceScore, setShowConfidenceScore] = useState(false);

    const cardPrefix = `${props.formPrefix}.${props.formIndex}`;
    const nameField: string = `${cardPrefix}.name`;
    const thresholdField: string = `${cardPrefix}.threshold`;
    const valuesField: string = `${cardPrefix}.values`;
    const isLargeEntityField: string = `${cardPrefix}.isLarge`;
    const isModifiedField = `${cardPrefix}.isModified`;
    const localesField = `${cardPrefix}.locales`;
    const sensitivityField = `${cardPrefix}.dataSensitivity`;

    const disableFields: boolean = !props.nlpEntity.isEditable || props.nlpEntity.isDeleted; //|| props.isSubmitting || props.isValidating 
    const selectedLanguageIds: string[] = props.nlpEntity.locales?.map(locale => props.languages?.find(lang => lang.shortCode == locale)?.id);

    const nlpEntityValues = props.nlpEntity.values;
    const valuesButtonText: string = nlpEntityValues ? nlpEntityValues.length.toLocaleString() : " None ";

    const isLastPage: boolean = numberOfValuesPerPage * valuesPageNumber >= filteredNumberOfValues;
    const largeEntityInfoText: string = "Large Entity";

    const viewDisplayValuesoptions = [
        { icon: tableViewIconLight, selectedIcon: tableViewIconSelected, name: "List" },
        { icon: tableTextboxIcon, selectedIcon: tableTextboxIconSelected, name: "Textbox" }
    ];

    useEffect(function resetNlpValuesDisplayOnEntitySelect() {
        clearSearchTerm();
        setViewDisplayType("ListDisplay");
        setValuesFormDisplayInfo([]);
        setImportedValues([]);

        recalculateValuesToRender();
    }, [props.formIndex]);

    useEffect(function resetNlpValuesDisplayOnSearchAddDeleteTypeChange() {
        recalculateValuesToRender();
    }, [triggerNlpValuesSearch, nlpEntityValues, viewDisplayType]);

    const recalculateValuesToRender = () => {
        setValuesFormDisplayInfo([]);

        // Do not display values for Large Entities
        if (props.nlpEntity.isLarge) return;

        const hasNewlyAdded: boolean = nlpEntityValues?.some(v => v.isAdded);
        let filtered: NlpValueFormDisplayInfo[] = [];

        nlpEntityValues?.map((value, index) => {

            const info: NlpValueFormDisplayInfo = {
                nlpEntityValue: value,
                // For values ArrayHelper to be able to delete a value need original form index
                originalArrayIndex: index,
                displayIndex: index // Will be set after sorting to new disply index if we have search 
            };

            if (valuesSearchTerm) {
                if (value?.name && value.name?.toLowerCase().includes(valuesSearchTerm?.toLowerCase())
                    || value?.pattern && value.pattern?.toLowerCase().includes(valuesSearchTerm?.toLowerCase())) {

                    if (value.isAdded) {
                        // Newly added values on top
                        filtered.unshift(info);
                    }
                    else {
                        filtered.push(info);
                    }
                }
            }
            else {
                if (value.isAdded) {
                    // Newly added values on top
                    filtered.unshift(info);
                }
                else {
                    filtered.push(info);
                }
            }
        })

        if (valuesSearchTerm || hasNewlyAdded) {
            filtered = filtered?.map((f: NlpValueFormDisplayInfo, idx) => {
                const d: NlpValueFormDisplayInfo = { ...f, displayIndex: idx };
                return d;
            });
        }

        setValuesFormDisplayInfo(filtered);
        setFilteredNumberOfValues(filtered?.length ?? 0)
    };

    const toggleConfidenceScore = () => {
        setShowConfidenceScore(!showConfidenceScore);
    };

    const confidenceScoreOptions = [
        { name: 'Very Fuzzy', value: .6 },
        { name: '', value: .7 },
        { name: 'Fuzzy', value: .8 },
        { name: '', value: .9 },
        { name: 'Exact', value: 1 },
    ];

    const decrementPage = () => {
        if (valuesPageNumber <= 1) {
            return;
        }
        setValuesPageNumber(valuesPageNumber - 1);
    };

    const incrementPage = () => {
        if (isLastPage) return;
        setValuesPageNumber(valuesPageNumber + 1);
    };

    const clearSearchTerm = () => {
        // Clear search
        setValuesSearchTerm("");
        setTriggerNlpValuesSearch("");
        setValuesPageNumber(1);
    };

    const handleSearch = (searchEvent) => {
        setValuesSearchTerm(searchEvent.target.value);
    };

    const handleSearchKeyDown = (event) => {

        if (event.key === "Escape") {
            // Clear search
            clearSearchTerm();
        }
        else if (event.key === "Enter") {
            setTriggerNlpValuesSearch(valuesSearchTerm);
            setValuesPageNumber(1);
        }
    };

    const toggleItemActivationByIndex = (index: number) => {
        setActiveItemIndex(index);
    };

    const addNewNlpValue = () => {

        // Clear search
        clearSearchTerm();

        const newIdx: number = nlpEntityValues?.length || 0;

        const nlpEntityValue: NlpEntityValueModel = { isAdded: true };
        props.setFieldValue(`${valuesField}.${newIdx}`, nlpEntityValue);
        props.setFieldValue(isModifiedField, true);

        setActiveItemIndex(newIdx);
        setValuesPageNumber(1);

    };

    const toggleViewDisplayType = () => {
        // TextBoxDisplay is Only applicable to List NLP Entity Type 
        setShowSearch(false);
        if (props.nlpEntity.type !== "List") return;

        if (viewDisplayType === "ListDisplay") {
            clearSearchTerm();
            setViewDisplayType("TextBoxDisplay");
        }
        else {
            setViewDisplayType("ListDisplay");
        }
        setValuesPageNumber(1);
    };

    const handleNlpEntityNameKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
        if (disableFields) return;

        if (event.key === "Escape") {

        }
        else if (event.key === "Enter") {
            props.validateForm();
        }
    };

    const handleDataSensitivityFieldChange = (sensitivity) => {
        props.setFieldValue(sensitivityField, sensitivity === "No Tracking" ? "NoTracking" : sensitivity);
        props.setFieldValue(isModifiedField, true);

    };

    const handleNlpEntityNameChange = (fieldName, event) => {
        props.setFieldValue(isModifiedField, true);

        props.handleChange(event);
    };

    const handleLanguagesChange = (langs: LanguageModel[]) => {
        const selectedLocales: string[] = langs?.map(lang => lang.shortCode);
        props.setFieldValue(localesField, selectedLocales);
        props.setFieldValue(isModifiedField, true);
    };

    const toggleConfirmImportValuesModal = () => {
        setIsConfirmImportValuesModalOpen(!isConfirmImportValuesModalOpen);
    };

    const toggleImportFileDialogModal = () => {
        setIsImportFileDialogModalOpen(!isImportFileDialogModalOpen);
    };

    const overwriteNlpValuesFromImport = () => {
        if (importedValues?.length > LARGE_ENTITY_VALUES_COUNT) {
            props.setFieldValue(isLargeEntityField, true);
        }
        else {
            props.setFieldValue(isLargeEntityField, false);
        }
        props.setFieldValue(valuesField, importedValues);
        props.setFieldValue(isModifiedField, true);

        setIsImporting(false);
        setImportedValues([]);
        toggleConfirmImportValuesModal();
    };

    const importNlpValues = (file: File) => {

        setFileImportErrors([]);

        if (!file) {
            setFileImportErrors(["Please select a file"]);
            return;
        }
        const reader = new FileReader();
        reader.onloadend = e => {
            const textBoxValues: string = reader.result.toString();
            if (textBoxValues) {
                const imported: NlpEntityValueModel[] = convertCSVtextToValues(textBoxValues);
                setImportedValues(imported);
                setIsImportFileDialogModalOpen(false);
                toggleConfirmImportValuesModal();
            }
        }

        reader.readAsText(file);
    };

    const exportNlpValues = () => {
        let fileName: string = props.nlpEntity?.name || "NlpEntity";
        let valuesData = nlpEntityValues;

        if (valuesData == null || valuesData?.length === 0) return;
        fileName = fileName + "_" + valuesData?.length.toString() + "_NlpValues";
        const valuesDataInCSV = convertValuesToCSVText(valuesData);
        handleDownload(fileName, valuesDataInCSV);
    };

    const handleDownload = (fileName: string, contentDataInCSV: string) => {
        const blob = new Blob([contentDataInCSV], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
    };

    const renderNlpValuesNavigation = () => {
        return (
            <>
                <div className={valuesNavigationContainer}>
                    <div className={valuesViewDisplayType} >
                        <div className={`label row`}>View</div>
                        <RoundedSelectionRow
                            key={"list-valyes-display-type-selector"}
                            options={viewDisplayValuesoptions}
                            className={roundedSelectStyle}
                            optionClassName={roundedOptionStyle}
                            onClick={toggleViewDisplayType}
                            selectedFilter={viewDisplayType === "ListDisplay" ? 0 : 1}
                        />
                    </div>
                    <div>
                        <NumberIncrementSelector
                            useShadow={true}
                            selectedNumber={`${valuesPageNumber}`}
                            updateValue={(b: boolean) => b ? incrementPage() : decrementPage()}
                            label={"Page"}
                            showNumber={true} />
                    </div>
                </div>
            </>
        )
    };

    const renderNlpValuesActions = () => {

        return (
            <>
                <div className={valuesActionsContainer}>
                    <AddAndSearchDiv>
                        {!props.nlpEntity.isLarge &&
                            <AddAValueButton
                                themes={["primary-small", 'end-tight']}
                                onClick={e => addNewNlpValue()}
                                disabled={disableFields || props.nlpEntity.isLarge || viewDisplayType === "TextBoxDisplay"}
                                text="Add a Value"
                            />
                        }
                        {props.nlpEntity.type === "List" && !props.nlpEntity.isLarge &&
                            <Button
                                className={showSearch ? SelectedQuestionButton : QuestionButton}
                                themes={["small-circle"]}
                                onClick={() => setShowSearch(true)}
                                icon={showSearch ? selectedQuestionIcon : questionIcon}
                            />
                        }
                    </AddAndSearchDiv>

                    {props.nlpEntity.type === "List" && !props.nlpEntity.isLarge && <Spacer />}
                    {props.nlpEntity.type === "List" &&
                        <ImportExport>
                            <ImportExportDiv onClick={toggleImportFileDialogModal}>
                                <ImportExportSpan>Import</ImportExportSpan>
                                <ImportExportImg src={importIcon} />
                            </ImportExportDiv>
                            <ImportExportDiv onClick={e => exportNlpValues()}>
                                <ImportExportSpan>Export</ImportExportSpan>
                                <ImportExportImg src={exportIcon} />
                            </ImportExportDiv>
                        </ImportExport>
                    }
                </div>
                {showSearch &&
                    <>
                        <HorizontalSeparator />
                        {showSearch &&
                            <div className={triangleStyle}></div>
                        }
                        <SearchField
                            id="searchField1"
                            name={"searchField"}
                            placeholder={"Find this"}
                            value={valuesSearchTerm}
                            disabled={props.nlpEntity.isLarge || viewDisplayType === "TextBoxDisplay"}
                            onChange={handleSearch}
                            onKeyDown={handleSearchKeyDown}
                            boxType={"square"}
                            className={searchPadding}
                        />
                    </>
                }
                {triggerNlpValuesSearch &&
                    <>
                        <div> Matching Results: </div>
                        {valuesFormDisplayInfo?.length === 0 &&
                            <SearchResultDiv key={"no-results"}>
                                <NoResultsDiv>
                                    <NoResultsImg src={searchQuestionIcon} />
                                    <p>No results found</p>
                                    <ClearButton
                                        themes={["white-small"]}
                                        onClick={e => clearSearchTerm()}
                                        text="Clear Search Terms"
                                        rightIcon={closeIcon} />
                                </NoResultsDiv>
                            </SearchResultDiv>
                        }
                    </>
                }
            </>
        )
    };

    const renderNlpValuesListDisplay = () => {

        return (
            <NlpEntityValuesListDisplay
                {...props}
                activeItemIndex={activeItemIndex}
                toggleItemActivationByIndex={toggleItemActivationByIndex}
                valuesFormDisplayInfo={valuesFormDisplayInfo}
                numberOfValuesPerPage={numberOfValuesPerPage}
                valuesPageNumber={valuesPageNumber}
                languages={props.languages}
                nlpEntities={props.nlpEntities}
                nlpEntity={props.nlpEntity}
                formPrefix={props.formPrefix}
                formIndex={props.formIndex} />
        )

    };

    const renderNlpEntityValuesTextBoxDisplay = () => {
        return (
            <NlpEntityValuesTextBoxDisplay
                {...props}
                setErrors={props.setErrors}
                languages={props.languages}
                nlpEntities={props.nlpEntities}
                nlpEntity={props.nlpEntity}
                formPrefix={props.formPrefix}
                formIndex={props.formIndex} />
        )

    };

    const renderNlpValuesItems = () => {
        return (
            <div className={menuItemsContainer}>
                <HorizontalSeparator />
                {props.nlpEntity.isEditable &&
                    <>
                        <ValuesHeading>
                            <div>
                                Values
                                <SmallTagWithMargin>{valuesButtonText}</SmallTagWithMargin>
                                {props.nlpEntity.isLarge && <SmallTagWithMargin>{largeEntityInfoText}</SmallTagWithMargin>}
                            </div>
                            {
                                props.nlpEntity.type === "List" &&
                                <div className={menuWrapperStyle}>
                                    <CustomCountButton
                                        isActive={showConfidenceScore}
                                        onClick={toggleConfidenceScore}
                                        activeIcon={blueConfidenceScoreIcon}
                                        inactiveIcon={confidenceScoreIcon}
                                        showCount={false}
                                        text="Confidence Score"
                                    />
                                </div>
                            }
                        </ValuesHeading>
                        {
                            showConfidenceScore &&
                            <ContentCard
                                title="Confidence Score"
                                icon={confidenceScoreIcon}
                                className={contentCardStyle}
                            >
                                <FormFieldsContainer>
                                    <Description>Designate a score between 0 and 1 to indicate the desired level of precision for extracting this entity, where 1 represents exact matching and no fuzziness. Be mindful that any value other than 1 will have performance impacts, especially for large entities.</Description>
                                    <HorizontalOptionsSelector
                                        name={thresholdField}
                                        options={confidenceScoreOptions}
                                        value={props.nlpEntity.threshold ?? 1}
                                        isCustom={false}
                                        onChange={(value) => {
                                            props.setFieldValue(isModifiedField, true);
                                            props.setFieldValue(thresholdField, value)
                                        }}
                                    />
                                </FormFieldsContainer>
                            </ContentCard>
                        }
                    </>
                }
                {!props.nlpEntity.isEditable ?
                    <NonEditableEntityDescription>
                        <img src={nonEditableIcon} />
                        This entity is a pre-configured type and cannot be edited
                    </NonEditableEntityDescription>
                    :
                    <>
                        {(!props.nlpEntity.isLarge && props.nlpEntity.type === "List") &&
                            renderNlpValuesNavigation()
                        }
                        {renderNlpValuesActions()}
                        {props.nlpEntity.isLarge ?
                            <NonEditableEntityDescription>
                                <img src={nonEditableIcon} />
                                <span>
                                    The number of values for this entity exceeds the threshold for display <br />
                                    To view/edit, please <ClickableText onClick={e => exportNlpValues()}>export</ClickableText> your values and <ClickableText onClick={toggleImportFileDialogModal}>re-import</ClickableText> them.
                                </span>
                            </NonEditableEntityDescription>
                            :
                            <>
                                {viewDisplayType === "ListDisplay" &&
                                    renderNlpValuesListDisplay()
                                }
                                {viewDisplayType === "TextBoxDisplay" &&
                                    renderNlpEntityValuesTextBoxDisplay()
                                }
                            </>
                        }
                    </>
                }
            </div>
        )
    };

    return (
        <CardContainer>
            <EntityHeader>
                <div>
                    Properties for:
                    <SmallTag>{props.nlpEntity.name}</SmallTag>
                </div>
                <EntitySensitivityDropdown
                    clickableText={props.nlpEntity.dataSensitivity === "NoTracking" ? "No Tracking" : props.nlpEntity.dataSensitivity ?? "None"}
                    menuClassName={dataSensitivityMenuStyle}
                    label="Data Security: "
                    onOptionSelected={(option) => handleDataSensitivityFieldChange(option)}
                    options={[
                        {
                            label: ENTITY_SENSITIVITY_NONE,
                            tooltip: "Entity does not represent sensitive data"
                        },
                        {
                            label: ENTITY_SENSITIVITY_NOTRACKING,
                            icon: noTrackingEntity,
                            hoverIcon: noTrackingEntity,
                            tooltip: "Entity information will not be tracked in this application's analytics"
                        },
                        {
                            label: ENTITY_SENSITIVITY_SENSITIVE,
                            icon: sensitiveEntity,
                            hoverIcon: sensitiveEntity,
                            tooltip: "Entity not tracked in analytics and will be encrypted for webhooks"
                        }
                    ]}
                />
            </EntityHeader>
            <DirtyFormPrompt
                promptOverride={(location: Location): string | boolean => {
                    if (location?.hash || location?.pathname?.includes("/nlp/"))
                        return true;
                    return "You have unsaved changes. Are you sure you want to leave?"
                }}
                allowPrompt={props.status?.submitting != true} />
            <HorizontalSeparator />
            <fieldset>
                <TextField
                    name={nameField}
                    className={textFieldStyle}
                    disabled={disableFields}
                    required
                    placeholder="NLP Entity Name"
                    value={props.nlpEntity.name}
                    label="Name"
                    onChange={e => handleNlpEntityNameChange(nameField, e)}
                    onBlur={props.handleBlur}
                    autoFocus={true}
                    onKeyPress={handleNlpEntityNameKeyUp}
                />
                <ContentLanguageMultiSelector disabled={disableFields}
                    id="contentLanguage"
                    label="Apply to Region"
                    languages={props.languages}
                    selectedLanguageIds={selectedLanguageIds}
                    onChange={handleLanguagesChange} />

                {renderNlpValuesItems()}
            </fieldset>

            {isImportFileDialogModalOpen &&
                <ImportFileDialog
                    onClose={toggleImportFileDialogModal}
                    completeImport={importNlpValues}
                    title={"Select NLP Values file to import"}
                    acceptFileTypes={".csv, .txt"}
                    acceptFileTypesDisplayText={"Supports .csv, .txt"}
                    isLoading={isImporting}
                    errors={fileImportErrors}
                />
            }
            {isConfirmImportValuesModalOpen &&
                <ConfirmationDialog title={`Confirm Overwrite`}
                    deleteText="Yes, Overwrite"
                    isLoading={isImporting}
                    onClose={toggleConfirmImportValuesModal}
                    onConfirm={() => { setIsImporting(true); overwriteNlpValuesFromImport(); }}>
                    <WarningConfirmation text={`Importing these values will replace any existing values for this entity. To preserve the existing values, export them and combine everything into a single file to be imported.`} />
                </ConfirmationDialog>
            }
        </CardContainer>
    );
};

export default ApplicationNlpEntityCard;

const contentCardStyle = css`
    margin-top: 16px;
`;

const CustomCountButton = styled(CountButton)`
    margin-right: 0;
`;

const menuWrapperStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .validation-button {
        height: 32px;
        padding: 0 24px;
        background: ${color_gradients_silver_shift};
        border: ${style_border_default};
        box-shadow: none;
        border-radius: 16px;
        box-sizing: border-box;
        color: ${color_text_default};
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Muli';
    }
    .toggled {
        background: ${color_variants_ocean_light_opaque};
        border: 1px solid ${color_colors_ocean};
        color: ${color_colors_ocean};
    }
`;

const ValuesHeading = styled.div`
    display: flex;
    justify-content: space-between;
`;

const textFieldStyle = css`
    margin-top: 16px;
`;

const Description = styled.p`
    margin-bottom: 32px;
`

const CardContainer = styled.div`
    .field-wrapper {
        label {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    .field-container {
        height: 44px;
    }
    label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

`;

const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: white;
    margin-left: 160px;
    margin-right: auto;
`

const EntityHeader = styled.div`
    button {
        margin: 0 0 16px 8px; 
    }
    margin: 0;
    display: flex;
    justify-content: space-between; 
`;

const EntitySensitivityDropdown = styled(TextOverflowMenu)`
    margin-top: 8px;
`;

const menuItemsContainer = css`
    position: relative;
    height: auto;
    padding-top: 16px;
`;

const valuesNavigationContainer = css`    
    display: flex;
    justify-content: space-between; 
    align-items: center;
    .label {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        padding: 0 0 4px 16px;
    }
`;

const valuesViewDisplayType = css`    
    text-align: center;
    min-width: 224px;

`;

const dataSensitivityMenuStyle = css`
    margin-left: unset;
    right: -20px;
`;

const roundedSelectStyle = css`
    height: 32px
`;

const roundedOptionStyle = css`
    height: 34px;
`;

const valuesActionsContainer = css` 
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    align-items: center;
    justify-content: space-between;
`;

const searchPadding = css`
    padding-top: 8px;
`;

const ImportExportSpan = styled.span`
    color: ${color_text_link};
`;

const ImportExportDiv = styled.span`
    padding-left: 24px;
`;

const ImportExport = styled.span`
    margin-left: auto;
    align-items: center;
    cursor: pointer;
`;

const AddAndSearchDiv = styled.div`
    text-align: left;
    min-width: 224px;
`;

const SearchResultDiv = styled.div`
    padding-top: 8px;
`;

const ImportExportImg = styled.img`
    height: 20px;
    width: 20px;
    margin-left: 8px;
    vertical-align: middle;
`;

const AddAValueButton = styled(Button)`
    margin-right: auto;
    margin-top: 0px;
`;

const QuestionButton = css`
    padding-left: 8px;
    margin-left: 16px;
    height: 32px;
    width: 32px;
    vertical-align: middle;
`;

const SelectedQuestionButton = css`
    padding-left: 8px;
    margin-left: 16px;
    height: 32px;
    width: 32px;
    vertical-align: middle;
    background-color: ${color_variants_ocean_light_opaque};
    border-color: ${color_colors_ocean};
`;

const ClearButton = styled(Button)`
    height: 32px;
    img {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
    margin-left: auto;
`;

const NoResultsDiv = styled.div`
    width: 100%;
    display: flex;
    background: white;
    border-radius: 8px;
    border: solid 1px ${color_shades_dark};
    height: 48px;
    vertical-align: middle;
    align-items: center;
`;

const NoResultsImg = styled.img`
    height: 25px;
    width: 25px;
    margin-right: 16px;
    margin-left: 16px;
`

const NonEditableEntityDescription = styled.div`
    background: white;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    color: ${color_shades_darkest};
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
    max-height: 144px;
    width: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-style: italic;
    margin-top: 16px;
    img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
    }
`;

const ClickableText = styled.span`
    color: ${color_colors_ocean};
    cursor: pointer;
`;

const Spacer = styled.div`
    height: 16px;
`;