import React from 'react';
import { css } from '@emotion/css';
import CheckIndicator from '../../../components/forms/CheckIndicator';
import { Link } from 'react-router-dom';
import { color_defaults_white, color_shades_light, color_shades_dark, color_shades_darker, color_shades_darkest, color_shades_lighter } from '../../../constants/colors';
import WebhookModel from '../../../models/webhooks/api/WebhookModel';
const webhookIcon = require("../../../content/images/webhooks/webhook-blue.svg");
const deleteIcon = require("../../../content/images/content-item-icons/delete-blue.svg");

interface WebhookListingItemProps {
    webhook: WebhookModel
    onSelectionChange: (webhook: WebhookModel, isSelected: boolean) => void
    onClick: (webhook: WebhookModel) => void
    isSelected: boolean
    selectable: boolean
    isSingleSelect?: boolean
    disabled?: boolean
    onDelete?: () => void
}

class WebhookListItem extends React.Component<WebhookListingItemProps> {
    render() {
        const webhook = this.props.webhook;
        return (
            <div className={`${containerStyle} ${this.props.disabled ? 'disabled' : ''}`}>
                <div className={`content-item`}>
                    <CheckIndicator checkType={this.props.isSingleSelect ? 'radio' : 'check'}
                        checked={this.props.isSelected}
                        onClick={this.props.disabled || !this.props.selectable ? () => { } : () => this.props.onSelectionChange(webhook, !this.props.isSelected)}
                        disabled={this.props.disabled || !this.props.selectable} />
                    <img className="content-item-icon" src={webhookIcon} />
                    <div onClick={() => this.props.onClick(this.props.webhook)} key={webhook.id} className="content-item-title-container">
                        <p className="section-title">Webhook</p>
                        <p className="section-detail">{webhook.title ?? "Untitled"}</p>
                    </div>
                    {this.props.onDelete && <img src={deleteIcon} className="delete-button" onClick={() => this.props.onDelete()}/>}
                </div>
            </div>

        )
    }
}

const containerStyle = css` 

    &.disabled {
        .content-item {
            background: ${color_shades_lighter};
            cursor: not-allowed;
        }
    }

    height: 80px;
    padding: 0 16px;
    background: ${color_shades_light};
    box-sizing: border-box;
    border-bottom: 1px solid ${color_shades_dark};
    &:first-child {
        border-top: 1px solid ${color_shades_dark};
    }  

    .content-item {
        background: ${color_defaults_white};
        box-sizing: border-box;
        border-radius: 4px;
        border-left: 1px solid ${color_shades_dark};
        border-right: 1px solid ${color_shades_dark};
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 100%;
    }

    .content-item-icon {
        width: 48px;
        height: 48px;
        margin-right: 16px;
    }

    .section-title {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${color_shades_darker};
    }

    .section-detail {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
    }

    .content-item-title-container {
        cursor: pointer;
        flex: 1;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25vw;
        }
    }

    .content-details-container {
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-left: auto;
        justify-content: flex-start;
        flex: 1;
    }

    .delete-button {
        cursor: pointer;
        margin-right: 16px;
    }

    .content-modified-container, .content-hits-container, .content-media-container {
        padding: 0 16px;
    }

    .content-hits-container {
        width: 96px;
    }
    .content-modified-container{
        width: 124px;
    }

    .content-item-status-container {
        margin-right: 0;
        margin-left: 0;
        width: 124px;
    }

    .content-item-languages-container {
        margin-right: 32px;
        margin-left: 0;
        width: 100px;

        >div {
            justify-content: flex-end;
        }
    }

`

export default WebhookListItem;