import { css } from '@emotion/css';
import _ from 'lodash';
import React from 'react';
import IconSelectField, { IconOption } from '../../../components/forms/IconSelectField';
import RadioGroup, { RadioOption } from '../../../components/forms/RadioGroup';
import TextAreaField from '../../../components/forms/TextAreaField';
import Button from '../../../components/general/Button';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import { dark_grey_blue, faded_red, green_1 } from '../../../constants/colors';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
import ResponseTemplateTypeModel from '../../../models/features/api/MediaResponses/ResponseTemplateTypeModel';
import UpdateBulkMediaResponseContainerRequest from '../../../models/features/api/MediaResponses/UpdateBulkMediaResponseContainerRequest';
import UpdateMediaResponseRequest from '../../../models/features/api/MediaResponses/UpdateMediaResponseRequest';
import MediaItemModel from '../../../models/media/MediaItemModel';
import MediaType from '../../../models/media/MediaTypes';
import CompatabilityViewer from './CompatabilityViewer';
import { ResponseFieldCard } from './ResponseFieldCard';
import ResponseTemplateSelector from './ResponseTemplateSelector';
import ResponseBuilderContainer from './ResponseBuilderContainer';
import DefaultMedia from '../../../models/features/api/DefaultMedia';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';
const readoutIcon = require('../../../content/images/media-icons/Readout-Dark.svg');
const audioIcon = require('../../../content/images/media-icons/Audio-dark.svg')
const textIcon = require('../../../content/images/media-icons/Text-dark.svg');
const videoIcon = require('../../../content/images/media-icons/Video-dark.svg');
const noVideoIcon = require('../../../content/images/media-icons/Video-None-dark.svg');
const imageIcon = require('../../../content/images/media-icons/Image-dark.svg');
const noImageIcon = require('../../../content/images/media-icons/Image-None-dark.svg');
const resetIcon = require('../../../content/images/reset-blue.svg');
const customIcon = require('../../../content/images/hammer-wench.svg');

interface ResponseBuilderProps {
    imageItem?: MediaItemModel
    backgroundImageItem?: MediaItemModel
    audioItem?: MediaItemModel
    videoItem?: MediaItemModel
    displayTitleOverride?: string
    displayTextOverride?: string
    targets: DeviceTargetModel[],
    onChange?: (container: UpdateBulkMediaResponseContainerRequest) => void
    onDefaultsChange?: (media: DefaultMedia) => void
    container: UpdateBulkMediaResponseContainerRequest
    deviceType: string
    mediaItems: MediaItemModel[]
    applicationId: string
}
interface ResponseBuilderState {
    deviceTarget: DeviceTargetModel
    customResponse?: UpdateMediaResponseRequest
    validationErrors?: string[]
    successMessage?: string
    hasCustomChanges: boolean
}

class ScreenResponseBuilder extends React.Component<ResponseBuilderProps, ResponseBuilderState> {
    componentWillMount() {
        this.setState({
            ...this.state,
            deviceTarget: this.props.targets[0],
            customResponse: this.getCurrentResponse(this.props.targets[0].id),
            validationErrors: null,
            successMessage: null,
            hasCustomChanges: false
        })
    }

    componentDidUpdate(prevProps: ResponseBuilderProps) {
        if (prevProps.deviceType != this.props.deviceType) {
            this.setState({
                ...this.state,
                deviceTarget: this.props.targets[0],
                customResponse: this.getCurrentResponse(this.props.targets[0].id)
            })
        }
        else if (this.props.imageItem != prevProps.imageItem
            || this.props.backgroundImageItem != prevProps.backgroundImageItem
            || this.props.audioItem != prevProps.audioItem
            || this.props.videoItem != prevProps.videoItem
            || this.props.container.responses.length != prevProps.container.responses.length) {
            this.setState({
                ...this.state,
                customResponse: this.getCurrentResponse(this.state.deviceTarget.id)
            })
        }
    }

    private getCurrentResponse(deviceTargetId?: string): UpdateMediaResponseRequest {
        if (!deviceTargetId) deviceTargetId = this.state.deviceTarget.id
        const existingResponse = this.props.container.responses.find(r => r.deviceTargetId == deviceTargetId);
        if (existingResponse) {
            return existingResponse;
        }
        else {
            return this.buildScreenResponseFromDefault(deviceTargetId);
        }
    }

    private buildScreenResponseFromDefault(deviceTargetId?: string): UpdateMediaResponseRequest {
        if (!deviceTargetId) deviceTargetId = this.state.deviceTarget.id
        const target = this.props.targets.find(t => t.id == deviceTargetId);
        if (!target) return null;
        // if video and target supports, set that template
        if (this.props.videoItem && target.supportsVideo) {
            const template = target.responseTemplates.find(t => t.usesVideo);
            if (template) {
                return {
                    videoItemId: this.props.videoItem.id,
                    responseTemplateTypeId: template.id,
                    applicationId: this.props.applicationId,
                    deviceTargetId: deviceTargetId
                }
            }
        }
        // if audio and target supports, set that template
        if (this.props.audioItem && target.supportsRichAudio) {
            const template = target.responseTemplates.find(t => t.usesAudio);
            if (template) {
                return {
                    audioItemId: this.props.audioItem.id,
                    foregroundImageItemId: this.props.imageItem ? this.props.imageItem.id : null,
                    backgroundImageItemId: this.props.backgroundImageItem ? this.props.backgroundImageItem.id : null,
                    displayText: this.props.displayTextOverride,
                    displayTitle: this.props.displayTitleOverride,
                    responseTemplateTypeId: template.id,
                    applicationId: this.props.applicationId,
                    deviceTargetId: deviceTargetId
                }
            }
        }
        // if images and target supports, set that template
        if (this.props.imageItem && target.supportsForegroundImages) {
            const template = target.responseTemplates.find(t => t.usesForegroundImage && !t.usesAudio && t.usesDisplayText);
            if (template) {
                return {
                    foregroundImageItemId: this.props.imageItem ? this.props.imageItem.id : null,
                    backgroundImageItemId: this.props.backgroundImageItem ? this.props.backgroundImageItem.id : null,
                    displayText: this.props.displayTextOverride,
                    displayTitle: this.props.displayTitleOverride,
                    responseTemplateTypeId: template.id,
                    applicationId: this.props.applicationId,
                    deviceTargetId: deviceTargetId
                }
            }
        }

        const template = target.responseTemplates.find(t => !t.usesForegroundImage && t.usesDisplayText && !t.usesAudio && !t.usesVideo && t.usesDisplayTitle);
        if (!template) return;
        return {
            backgroundImageItemId: this.props.backgroundImageItem ? this.props.backgroundImageItem.id : null,
            displayText: this.props.displayTextOverride,
            displayTitle: this.props.displayTitleOverride,
            responseTemplateTypeId: template.id,
            applicationId: this.props.applicationId,
            deviceTargetId: deviceTargetId
        }
    }

    handleTargetChange(selection) {
        const target = this.props.targets.find(t => t.id == selection.value);
        this.setState({
            ...this.state,
            deviceTarget: target,
            customResponse: this.getCurrentResponse(target.id),
            hasCustomChanges: false,
            validationErrors: null,
            successMessage: null
        });
    }

    handleDefaultDisplayTextChange(option: RadioOption) {
        const propValues = { ...this.props }
        propValues.displayTextOverride = option.value == "default" ? null : '';
        this.props.onDefaultsChange(propValues);
    }

    handleDefaultTextValueChange(e) {
        const propValues = { ...this.props }
        propValues.displayTextOverride = e.target.value
        this.props.onDefaultsChange(propValues);
    }

    handleDefaultTitleValueChange(e) {
        const propValues = { ...this.props }
        propValues.displayTitleOverride = e.target.value
        this.props.onDefaultsChange(propValues);
    }

    handleDefaultDisplayTitleChange(option: RadioOption) {
        const propValues = { ...this.props }
        propValues.displayTitleOverride = option.value == "default" ? null : '';
        this.props.onDefaultsChange(propValues);
    }

    handleDefaultVideoChange(option: RadioOption) {
        const propValues = { ...this.props }
        propValues.videoItem = option.value == "default" ? null : this.props.mediaItems.find(m => m.id == option.value);
        this.props.onDefaultsChange(propValues);
    }

    handleDefaultAudioChange(option: RadioOption) {
        const propValues = { ...this.props }
        propValues.audioItem = option.value == "default" ? null : this.props.mediaItems.find(m => m.id == option.value);
        this.props.onDefaultsChange(propValues);
    }

    handleDefaultForegroundImageChange(option: RadioOption) {
        const propValues = { ...this.props }
        propValues.imageItem = option.value == "default" ? null : this.props.mediaItems.find(m => m.id == option.value);
        this.props.onDefaultsChange(propValues);
    }

    handleDefaultBackgroundImageChange(option: RadioOption) {
        const propValues = { ...this.props }
        propValues.backgroundImageItem = option.value == "default" ? null : this.props.mediaItems.find(m => m.id == option.value);
        this.props.onDefaultsChange(propValues);
    }

    handleTemplateChange(template: ResponseTemplateTypeModel) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.responseTemplateTypeId = template.id;
            existingResponse.responseTemplateType = template;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true,
                validationErrors: null,
                successMessage: null
            })
        }
    }

    hasCustomResponse(deviceTargetId: string) {
        const isCustom = this.props.container.responses.some(r => r.deviceTargetId == deviceTargetId);
        return isCustom;
    }

    removeTargetResponse(deviceTargetId: string) {
        const container = this.props.container;
        _.remove(container.responses, r => r.deviceTargetId == deviceTargetId);

        this.props.onChange(container);
        // reset values
        this.setState({
            ...this.state,
            customResponse: this.getCurrentResponse(this.state.deviceTarget.id)
        })
    }

    handleAudioSourceChange(option: RadioOption) {
        if (option.value != 'default') {
            var existingResponse = this.state.customResponse;
            if (existingResponse) {
                existingResponse.audioItemId = option.value;
                this.setState({
                    ...this.state,
                    customResponse: existingResponse,
                    hasCustomChanges: true
                });
            }
        }
        else {
            // if readout, remove response for target
            this.removeTargetResponse(this.state.deviceTarget.id);
        }
    }

    handleScreenVideoChange(option: RadioOption) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.videoItemId = option.value == "default" ? undefined : option.value;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleScreenBackgroundImageChange(option: RadioOption) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.backgroundImageItemId = option.value == "default" ? undefined : option.value;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleScreenForegroundImageChange(option: RadioOption) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.foregroundImageItemId = option.value == "default" ? undefined : option.value;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleScreenDisplayTextOptionChange(option: RadioOption) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.displayText = option.value == "default" ? undefined : '';
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleScreenDisplayTitleOptionChange(option: RadioOption) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.displayTitle = option.value == "default" ? undefined : '';
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }


    handleReadoutChange(option: RadioOption) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.readoutOverride = option.value == "default" ? null : '';
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    getMediaItems(type: MediaType) {
        return this.props.mediaItems.filter(m => m.mediaType == type);
    }

    getDisplayText() {
        if (this.state.customResponse && this.state.customResponse.displayText)
            return this.state.customResponse.displayText

        return '';
    }

    getCustomMarkup() {
        if (this.state.customResponse && this.state.customResponse.customMarkup)
            return this.state.customResponse.customMarkup

        return '';
    }

    getDisplayTitle() {
        if (this.state.customResponse && this.state.customResponse.displayTitle)
            return this.state.customResponse.displayTitle

        return '';
    }

    getDefaultDisplayText() {
        if (this.props.displayTextOverride !== undefined && this.props.displayTextOverride !== null)
            return this.props.displayTextOverride
        return '';
    }

    getDefaultDisplayTitle() {
        if (this.props.displayTitleOverride !== undefined && this.props.displayTitleOverride !== null)
            return this.props.displayTitleOverride

        return '';
    }

    handleReadoutTextChange(ssml: string) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.readoutOverride = ssml;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleDisplayTextChange(e) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.displayText = e.target.value;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleCustomMarkupChange(e) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.customMarkup = e.target.value;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleDisplayTitleChange(e) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.displayTitle = e.target.value;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    private getCurrentTemplate() {
        const response = this.state.customResponse;
        if (response) {
            return this.state.deviceTarget.responseTemplates.find(r => r.id == response.responseTemplateTypeId)
        }

        // just return the first template for the target
        return this.state.deviceTarget.responseTemplates[0];
    }

    handleRemoveCustomization() {
        this.removeTargetResponse(this.state.deviceTarget.id);
    }

    handleUpdateResponse() {
        const validationErrors = this.verifyCustomResponse();
        if (validationErrors) {
            this.setState({
                ...this.state,
                validationErrors: [validationErrors]
            });
            return;
        }

        const container = this.props.container;
        const existingResponse = container.responses.find(r => r.deviceTargetId == this.state.deviceTarget.id);
        if (existingResponse) {
            const index = container.responses.indexOf(existingResponse);
            container.responses[index] = this.state.customResponse;
        }
        else {
            container.responses.push(this.state.customResponse);
        }

        this.props.onChange(container);
        this.setState({
            ...this.state,
            validationErrors: null,
            successMessage: "Saved",
            hasCustomChanges: false
        });

        // hide save message after delay
        setTimeout(() => {
            this.setState({
                ...this.state,
                successMessage: null
            })
        }, 3000);
    }

    private verifyCustomResponse(): string {
        const response = this.state.customResponse;
        if (!response) return "Invalid Response";
        const template = this.state.deviceTarget.responseTemplates.find(t => t.id == this.state.customResponse.responseTemplateTypeId);
        if (!template) return "Invalid template";

        if (template.requiresVideo && !response.videoItemId) return "This template requires a video";
        if (template.requiresAudio && !response.audioItemId) return "This template requires an audio file";
        if (template.requiresForegroundImage && !response.foregroundImageItemId) return "This template requires a foreground image";
        if (template.requiresBackgroundImage && !response.backgroundImageItemId) return "This template requires a background image";
        if (template.requiresCustomMarkup && !response.customMarkup) return "This template requires custom markup";
        return null;
    }

    render() {
        const currentTemplate = this.getCurrentTemplate();
        return (
            <ResponseBuilderContainer>
                <div>
                    <h4>Default Components</h4>
                    <p>This sets the underlying components used in a response. We will apply a best-fit solution for each device based on their respective capabilities. </p>

                    <div>
                        {this.props.mediaItems.some(m => m.mediaType == "VideoFile") ?

                            <ResponseFieldCard title="Video File">
                                <RadioGroup
                                    options={[...this.getMediaItems("VideoFile").map(item => ({ icon: videoIcon, value: item.id, label: item.name })), { icon: noVideoIcon, value: 'default', label: 'None' }]}
                                    onChange={this.handleDefaultVideoChange.bind(this)}
                                    value={this.props.videoItem ? this.props.videoItem.id : 'default'} />
                                <CompatabilityViewer detailText="Not all devices are capable of returning a response with a video." supportedTargets={this.props.targets.filter(t => t.supportsVideo)} unsupportedTargets={this.props.targets.filter(t => !t.supportsVideo)} />
                            </ResponseFieldCard>
                            : null}
                        {this.props.mediaItems.some(m => m.mediaType == "AudioFile") ?
                            <ResponseFieldCard title="Audio Source">
                                <RadioGroup
                                    options={[...this.props.mediaItems.filter(m => m.mediaType == "AudioFile").map(item => ({ icon: audioIcon, value: item.id, label: item.name })), { icon: readoutIcon, value: 'default', label: 'Device Readout' }]}
                                    onChange={this.handleDefaultAudioChange.bind(this)}
                                    value={this.props.audioItem ? this.props.audioItem.id : 'default'} />
                            </ResponseFieldCard>
                            : null}

                        {this.props.mediaItems.some(m => m.mediaType == "ImageFile") ?

                            <ResponseFieldCard title="Foreground Image">
                                <RadioGroup
                                    options={[...this.getMediaItems("ImageFile").map(item => ({ icon: imageIcon, value: item.id, label: item.name })), { icon: noImageIcon, value: 'default', label: 'None' }]}
                                    onChange={this.handleDefaultForegroundImageChange.bind(this)}
                                    value={this.props.imageItem ? this.props.imageItem.id : 'default'} />

                            </ResponseFieldCard>

                            : null}

                        {this.props.mediaItems.some(m => m.mediaType == "ImageFile") ?

                            <ResponseFieldCard title="Background Image">
                                <RadioGroup
                                    options={[...this.getMediaItems("ImageFile").map(item => ({ icon: imageIcon, value: item.id, label: item.name })), { icon: noImageIcon, value: 'default', label: 'None' }]}
                                    onChange={this.handleDefaultBackgroundImageChange.bind(this)}
                                    value={this.props.backgroundImageItem ? this.props.backgroundImageItem.id : 'default'} />
                                <CompatabilityViewer detailText="Not all devices are capable of returning a response with a background image." supportedTargets={this.props.targets.filter(t => t.supportsBackgroundImages)} unsupportedTargets={this.props.targets.filter(t => !t.supportsBackgroundImages)} />
                            </ResponseFieldCard>
                            : null}
                        <ResponseFieldCard title="Display Text">
                            <FormFieldsContainer>
                                <RadioGroup
                                    options={[{ icon: textIcon, value: 'default', label: 'Same as Readout' },
                                    { icon: textIcon, value: 'custom', label: 'Custom' }]}
                                    onChange={this.handleDefaultDisplayTextChange.bind(this)}
                                    value={this.props.displayTextOverride != null ? 'custom' : 'default'} />
                                {this.props.displayTextOverride != null ?
                                    <TextAreaField name="displayText" placeholder="New display text" value={this.getDefaultDisplayText()} onChange={this.handleDefaultTextValueChange.bind(this)} />
                                    : null}
                            </FormFieldsContainer>
                        </ResponseFieldCard>
                        <ResponseFieldCard title="Display Title">
                            <FormFieldsContainer>
                                <RadioGroup
                                    options={[{ icon: textIcon, value: 'default', label: 'Automatic' },
                                    { icon: textIcon, value: 'custom', label: 'Custom' }]}
                                    onChange={this.handleDefaultDisplayTitleChange.bind(this)}
                                    value={this.props.displayTitleOverride != null ? 'custom' : 'default'} />
                                {this.props.displayTitleOverride != null ?
                                    <TextAreaField name="displayTitle" placeholder="Display title" value={this.getDefaultDisplayTitle()} onChange={this.handleDefaultTitleValueChange.bind(this)} />
                                    : null}
                            </FormFieldsContainer>
                        </ResponseFieldCard>
                    </div>
                </div>
                <h4>Custom Overrides</h4>
                <p>Customizations made here will apply to the specific device-type and override the default settings</p>

                {this.props.targets.length > 1 ?
                    <div className="type-selector">
                        <IconSelectField name="deviceType"
                            clearable={false}
                            label="Device-Type:"
                            options={_.sortBy(this.props.targets.map(t => ({
                                value: t.id,
                                icon: t.iconUrl,
                                label: t.displayName,
                                rightIcon: this.hasCustomResponse(t.id) ? customIcon : null,
                                rightText: this.hasCustomResponse(t.id) ? "CUSTOM" : null
                            } as IconOption)), o => o.label)}
                            value={this.state.deviceTarget.id}
                            onBlur={() => { }}
                            onChange={this.handleTargetChange.bind(this)} />
                    </div>
                    : null}
                <div className="update-container">
                    <Button themes={['primary-small', 'start-tight']} disabled={!this.state.hasCustomChanges} type="button" onClick={this.handleUpdateResponse.bind(this)} additionalClasses="update-button" text="Update" />
                    <p className="error-label">{this.state.validationErrors ? this.state.validationErrors.join(',') : null}</p>
                    <p className="success-label">{this.state.successMessage}</p>
                    {this.hasCustomResponse(this.state.deviceTarget.id) ?
                        <Button themes={['hollow', 'end-tight']} type="button" onClick={this.handleRemoveCustomization.bind(this)} additionalClasses="reset-button" text="Reset to default" icon={resetIcon} />
                        : null}
                </div>
                <div>
                    <ResponseTemplateSelector
                        templates={this.state.deviceTarget.responseTemplates}
                        selectedTemplate={currentTemplate}
                        onSelectionChange={this.handleTemplateChange.bind(this)} />
                    <div>

                        {currentTemplate && currentTemplate.usesVideo ?
                            <ResponseFieldCard title="Video File">
                                <RadioGroup
                                    options={[...this.getMediaItems("VideoFile").map(item => ({ icon: videoIcon, value: item.id, label: item.name }))]}
                                    onChange={this.handleScreenVideoChange.bind(this)}
                                    value={this.state.customResponse.videoItemId ? this.state.customResponse.videoItemId : 'default'} />
                            </ResponseFieldCard>

                            : null}
                        {currentTemplate && currentTemplate.usesAudio ?
                            <ResponseFieldCard title="Audio File">
                                <RadioGroup
                                    options={[...this.getMediaItems("AudioFile").map(item => ({ icon: audioIcon, value: item.id, label: item.name }))]}
                                    onChange={this.handleAudioSourceChange.bind(this)}
                                    value={this.state.customResponse.audioItemId ? this.state.customResponse.audioItemId : 'default'} />
                            </ResponseFieldCard>

                            : null}
                        {currentTemplate && currentTemplate.usesReadout ?
                            <ResponseFieldCard title="Output Speech">
                                <RadioGroup
                                    options={[{ icon: readoutIcon, value: 'default', label: 'Default Readout' },
                                    { icon: readoutIcon, value: 'custom', label: 'Custom Readout' }]}
                                    onChange={this.handleReadoutChange.bind(this)}
                                    value={this.state.customResponse.readoutOverride != null ? 'custom' : 'default'} />
                                {this.state.customResponse && this.state.customResponse.readoutOverride != null ?
                                    <SsmlField name="readoutOverride" placeholder="What do you want the assistant to say?" value={this.state.customResponse.readoutOverride} onChange={this.handleReadoutTextChange.bind(this)} onBlur={() => { }} applicationId={this.props.applicationId} />
                                    : null}
                            </ResponseFieldCard>
                            : null}
                        {currentTemplate && currentTemplate.usesBackgroundImage ?
                            <ResponseFieldCard title="Background Image">
                                <RadioGroup
                                    options={[...this.getMediaItems("ImageFile").map(item => ({ icon: imageIcon, value: item.id, label: item.name })), { icon: noImageIcon, value: 'default', label: 'None' }]}
                                    onChange={this.handleScreenBackgroundImageChange.bind(this)}
                                    value={this.state.customResponse.backgroundImageItemId ? this.state.customResponse.backgroundImageItemId : 'default'} />
                            </ResponseFieldCard>

                            : null}
                        {currentTemplate && currentTemplate.usesForegroundImage ?
                            <ResponseFieldCard title="Foreground Image">
                                <RadioGroup
                                    options={[...this.getMediaItems("ImageFile").map(item => ({ icon: imageIcon, value: item.id, label: item.name })), { icon: noImageIcon, value: 'default', label: 'None' }]}
                                    onChange={this.handleScreenForegroundImageChange.bind(this)}
                                    value={this.state.customResponse.foregroundImageItemId ? this.state.customResponse.foregroundImageItemId : 'default'} />

                            </ResponseFieldCard>

                            : null}
                        {currentTemplate && currentTemplate.usesDisplayText ?
                            <ResponseFieldCard title="Display Text">
                                <FormFieldsContainer>
                                    <RadioGroup
                                        options={[{ icon: textIcon, value: 'default', label: 'Use Default' },
                                        { icon: textIcon, value: 'custom', label: 'Custom' }]}
                                        onChange={this.handleScreenDisplayTextOptionChange.bind(this)}
                                        value={this.state.customResponse && this.state.customResponse.displayText != null ? 'custom' : 'default'} />
                                    {this.state.customResponse && this.state.customResponse.displayText != null ?
                                        <TextAreaField name="displayText" placeholder="New display text" value={this.getDisplayText()} onChange={this.handleDisplayTextChange.bind(this)} />
                                        : null}
                                </FormFieldsContainer>
                            </ResponseFieldCard>

                            : null}
                        {currentTemplate && currentTemplate.usesDisplayTitle ?
                            <ResponseFieldCard title="Display Title">
                                <FormFieldsContainer>
                                    <RadioGroup
                                        options={[{ icon: textIcon, value: 'default', label: 'Use Default' },
                                        { icon: textIcon, value: 'custom', label: 'Custom' }]}
                                        onChange={this.handleScreenDisplayTitleOptionChange.bind(this)}
                                        value={this.state.customResponse && this.state.customResponse.displayTitle != null ? 'custom' : 'default'} />
                                    {this.state.customResponse && this.state.customResponse.displayTitle != null ?
                                        <TextAreaField name="displayText" placeholder="Display title" value={this.getDisplayTitle()} onChange={this.handleDisplayTitleChange.bind(this)} />
                                        : null}
                                </FormFieldsContainer>
                            </ResponseFieldCard>

                            : null}

                        {currentTemplate && currentTemplate.usesCustomMarkup ?
                            <ResponseFieldCard title="Custom Markup">
                                <FormFieldsContainer>
                                    <TextAreaField name="customMarkup" placeholder="Paste custom markup here" value={this.getCustomMarkup()} onChange={this.handleCustomMarkupChange.bind(this)} />
                                </FormFieldsContainer>
                            </ResponseFieldCard>

                            : null}
                    </div>
                    <div className="update-container">
                        <Button themes={['primary-small']} disabled={!this.state.hasCustomChanges} type="button" onClick={this.handleUpdateResponse.bind(this)} additionalClasses="update-button" text="Update" />
                        <p className="error-label">{this.state.validationErrors ? this.state.validationErrors.join(',') : null}</p>
                        <p className="success-label">{this.state.successMessage}</p>
                    </div>
                </div>
            </ResponseBuilderContainer>
        )
    }
}

export default ScreenResponseBuilder