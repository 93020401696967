import React from 'react';
import { css } from '@emotion/css';
import Logo from '../../scenes/dashboardHeader/components/Logo';
import ReactMarkdown from 'react-markdown';
import Button from './Button';
import { color_shades_dark, color_shades_darkest_transparent, color_text_link } from '../../constants/colors';
import CustomScrollbars from '../structure/CustomScrollbars';
import NotificationsContainer from '../../hooks/NotificationsContainer';

const linkRenderer = (props) => {
    return <a href={props.href} target="_blank">{props.children}</a>
}
const UrgentNotificationSplash: React.FC = (props) => {

    const notificationsHook = NotificationsContainer.useContainer();
    const notifications = notificationsHook.notifications;
    const notification = notifications.find(n => !n.isRead && n.priority === "Urgent");
    return (
        notification != null &&
        <div className={`${containerStyle} ${notification?.imageUrl == null ? "no-image" : ""}`}>
            <CustomScrollbars autoHide>
                <Logo isFull isInverse />
                {notification?.imageUrl != null &&
                    <div className="image-container">
                        <img src={notification.imageUrl} />
                    </div>
                }
                <div className={`content ${markdownStyle}`}>
                    <ReactMarkdown source={notification?.markdown} renderers={{ link: linkRenderer }} />
                    <div className="bottom-row">
                        <Button
                            text="Dismiss"
                            themes={["primary"]}
                            disabled={notificationsHook.isLoading}
                            loading={notificationsHook.isLoading}
                            onClick={() => notificationsHook.markNotificationAsRead(notification.id)} />
                    </div>
                </div>
            </CustomScrollbars>
        </div>
    )
};
const containerStyle = css`
    position: fixed;
    width: 100%;
    height: 100%;    
    top: 0;
    left: 0;
    z-index: 5;
    &.no-image {
        padding-top: 80px;
    }
    background-color: ${color_shades_darkest_transparent};
    .logo {
        position: fixed;
        left: 24px;
        top: 16px;
    }
    .image-container {
        text-align: center;
        width: 100%;
        img {
            margin-left: auto;
            margin-right: auto;
            top: 0px;
        }
    }
    .content {
        width: 75%;
        height: auto;
        border-radius: 16px;
        background: white;
        opacity: 1;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 56px;
        padding: 56px;
    }
    .bottom-row {
        width: 100%;
        padding-top: 8px;
        margin-top: 16px;
        border-top: 1px solid ${color_shades_dark};
    }
`

const markdownStyle = css`
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    font-size: 18px;
    width: 100%;
    line-height: 20px;
    word-wrap: break-word;
    img {
        margin: 32px;
        max-width: calc(100% - 64px);
    }
    p {        
        font-size: 18px;
        margin: 12px 0;
        line-height: 24px;
    }
    ul, ol {
        padding-left: 24px;
        margin: 12px 0;
        li {
            margin: 4px;
        }
    }
    h1 {
        margin-bottom: 24px;
        margin-top: 40px;  
        font-size: 28px;
        font-weight: bold;
    }
    h2 {
        margin-bottom: 24px;
        margin-top: 40px;  
        font-size: 24px;
        font-weight: bold;
    }
    h3 {
        margin-bottom: 24px;
        margin-top: 40px;        
        font-size: 22px;
        font-weight: bold;
    }
    h4 {
        margin-bottom: 16px;
        margin-top: 24px;  
        font-size: 20px;
        font-weight: bold;
    }
    h5 {
        margin-bottom: 16px;
        margin-top: 16px;  
        font-size: 18px;
        font-weight: bold italic;
    }
    h6 {
        margin-bottom: 16px;
        margin-top: 16px;  
        font-size: 18px;
        font-weight: bold;
    }
    a {
        color: ${color_text_link};
    }
    
`
export default UrgentNotificationSplash;