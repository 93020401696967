import React from 'react';
import {css} from '@emotion/css';

interface PageTitleProps {
    text?: string
    isSubtitle?: boolean
    tight?: boolean
}

const titleStyle = css`
    font-size: 28px;
    font-family: Muli, "sans-serif";
    margin: 0 0 16px 0;
    font-weight: normal;
`;

const subTitleStyle = css`
    font-size: 20px;
`

const tightStyle = css`
    margin: 0;
`
const PageTitle: React.SFC<PageTitleProps> = (props) => (
    <h2 className={`${titleStyle} ${props.isSubtitle ? subTitleStyle: ''} ${props.tight ? tightStyle : ''}`}>{props.text}</h2>
);

export default PageTitle;