import React, { useEffect, useState } from "react";
import { faded_red, color_text_light, color_shades_darkest, color_variants_decline_light_1, color_colors_decline } from "../../../constants/colors";
import { getRegionyNameForLanguage, getLanguageNameForLanguage, getFlagIconForLanguage } from '../../../models/extensions/languages';
import styled from "@emotion/styled";
import Button from "../../../components/general/Button";
import { css } from "@emotion/css";
import ModalHeader from "../../../components/general/ModalHeader";
import FooterBar from "../../../components/structure/FooterBar";
import SmallModalLayout from "../../../components/structure/SmallModalLayout";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import useWindowSize from "../../../hooks/UseWindowSize";
import LanguageModel from "../../../models/features/api/LanguageModel";
import { InteractionModelFormData } from "./InteractionModelForm";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import EditableInteractionModel from "../../../models/interactionModel/EditableInteractionModel";
import { FormikErrors } from "formik";
import ConditionalTooltipWrapper from "../../../components/general/ConditionalTooltipWrapper";
import EditedMenuItemModel from "../../../models/nlp/menu/EditedMenuItemModel";

const alertIcon = require('../../../content/images/alert-circle.svg');
const entityIcon = require('../../../content/images/nlp-icons/entity-icon.svg');
const intentIcon = require('../../../content/images/nlp-icons/intent-icon.svg');
const slotIcon = require('../../../content/images/nlp-icons/slot-icon.svg');
const utteranceIcon = require('../../../content/images/nlp-icons/utterance-icon.svg');
const menuItemIcon = require('../../../content/images/nlp-icons/menu-item-icon.svg')

interface ChangedDataModel {
    changeType: "Entity" | "Intent" | "Slot" | "Utterance" | "EnhancedIntentMatching";
    state: string;
    text: string;
};

export interface NlpSaveChangesModalProps {
    showSaveChangesModal: boolean;
    closeModal: () => void;
    values: InteractionModelFormData;
    submitChanges: () => void;
    languages: LanguageModel[];
    isLoading: boolean;
    errors: FormikErrors<InteractionModelFormData>
    updatedMenuItems: EditedMenuItemModel[];
};

const NlpSaveChangesModal: React.FC<NlpSaveChangesModalProps> = (props) => {
    const windowSize = useWindowSize();
    const [changedNlpData, setChangedNlpData] = useState([] as ChangedDataModel[]);

    useEffect(() => {
        if (!props.isLoading) {
            setChangedNlpData(getChangedData())
        }
    }, [props.values, props.showSaveChangesModal]);

    const saveChangesAndClose = () => {
        props.submitChanges();
        props.closeModal();
    }

    const getChangedData = () => {
        if (props.values == null)
            return []

        let changedData: ChangedDataModel[] = getChangedInteractionModelData(props.values.interactionModel, props.values);
        const entityData: ChangedDataModel[] = getChangedEntityData(props.values.nlpEntities);
        if (entityData?.length > 0)
            changedData = changedData.concat(entityData);

        return changedData;
    }

    const determineIcon = (changeType) => {
        if (changeType == "Entity")
            return entityIcon;
        else if (changeType == "Slot")
            return slotIcon;
        else if (changeType == "Intent")
            return intentIcon;
        else if (changeType == "Utterance")
            return utteranceIcon
        return intentIcon
    }

    const modifyDisplayStringBasedOnType = (changeType, text) => {
        if (changeType == "Utterance")
            return '"' + text + '"';
        else if (changeType == "Slot")
            return "{" + text + "}";
        else if (changeType == "Entity")
            return "<" + text + ">";
        else if (changeType == "Intent")
            return text;
        else if (changeType === "EnhancedIntentMatching")
            return text;
    };

    const changedMenuItems = props.updatedMenuItems?.filter((menuItem) => {
        return menuItem.isAdded || menuItem.isDeleted || menuItem.isModified;
    });

    const getChangedEntityData = (nlpEntities: EditableNlpEntity[]): ChangedDataModel[] => {
        let changedData = [];

        for (let counter = 0; counter < nlpEntities.length; counter++) {
            const nlpEntity = nlpEntities[counter];

            if (nlpEntity.isAdded && nlpEntity.isDeleted) {
                // No real changes, do not display a status
            }
            else if (nlpEntity.isDeleted) {
                changedData.push({
                    changeType: "Entity",
                    state: "Removed",
                    text: nlpEntity.name,
                })
            }
            else if (nlpEntity.isAdded) {
                changedData.push({
                    changeType: "Entity",
                    state: "Added",
                    text: nlpEntity.name,
                })
            }
            else if (nlpEntity.isModified) {
                changedData.push({
                    changeType: "Entity",
                    state: "Modified",
                    text: nlpEntity.name,
                })
            }
        }
        return changedData;
    }

    const getChangedInteractionModelData = (interactionModel: EditableInteractionModel, values: InteractionModelFormData): ChangedDataModel[] => {
        let changedData = [];
        if (props.values.disableEnhancedIntentMatching) {
            changedData.push({
                changeType: "EnhancedIntentMatching",
                state: "Removed",
                text: "Disabled",
            });
        }
        else if (props.values.enableEnhancedIntentMatching) {
            changedData.push({
                changeType: "EnhancedIntentMatching",
                state: "Added",
                text: "Enabled",
            });
        }
        if (props.values.updatedEnhancedIntentMatchingPrompt?.length) {
            changedData.push({
                changeType: "EnhancedIntentMatching",
                state: "Modified",
                text: "Updated Prompt",
            });
        }
        for (let key in interactionModel) {
            const intent = interactionModel[key];
            const utterances = intent.utterances;
            const slots = intent.slots;

            if (intent.isAdded && intent.isDeleted) {
                // No real changes, do not display a status
            }
            else if (intent.isDeleted) {
                changedData.push({
                    changeType: "Intent",
                    state: "Removed",
                    text: intent.displayName,
                });
            }
            else if (intent.isAdded) {
                changedData.push({
                    changeType: "Intent",
                    state: "Added",
                    text: intent.displayName,
                });
            }
            else if (intent.isModified) {
                changedData.push({
                    changeType: "Intent",
                    state: "Modified",
                    text: intent.displayName,
                });
            }

            if (slots?.length > 0) {
                for (let counter = 0; counter < slots.length; counter++) {
                    if (slots[counter].isModified) {
                        const slotObj = {
                            changeType: "Slot",
                            state: "Modified",
                            text: slots[counter].name,
                        }

                        changedData.push(slotObj);
                    }
                    else if (slots[counter].isDeleted) {
                        const slotObj = {
                            changeType: "Slot",
                            state: "Removed",
                            text: slots[counter].name,
                        }

                        changedData.push(slotObj);
                    }
                    else if (slots[counter].isAdded) {
                        const slotObj = {
                            changeType: "Slot",
                            state: "Added",
                            text: slots[counter].name,
                        }

                        changedData.push(slotObj);
                    }
                }
            }

            if (utterances?.length > 0) {
                for (let counter = 0; counter < utterances.length; counter++) {
                    if (utterances[counter].changeType == "Removed") {
                        const removedObj = {
                            changeType: "Utterance",
                            state: "Removed",
                            text: utterances[counter].phrase,
                        };

                        changedData.push(removedObj)
                    }
                    else if (utterances[counter].changeType == "Added") {
                        const removedObj = {
                            changeType: "Utterance",
                            state: "Added",
                            text: utterances[counter].phrase,
                        };

                        changedData.push(removedObj)
                    }
                }
            }
        }

        return changedData;
    }

    const renderMenuItemChange = (menuItem: EditedMenuItemModel, index: number) => {
        if (menuItem.isAdded) {
            return (
                <ChangedItemWrapper key={"Key " + index}>
                    <div className={addedOrModifiedItem}>Added </div>
                    <ChangedMenuItemText>
                        <Icon src={menuItemIcon} />
                        Menu item: {menuItem.menuItem.originalName ? menuItem.menuItem.originalName : menuItem.menuItem.colloquialName ? menuItem.menuItem.colloquialName : `unnamed item`}
                    </ChangedMenuItemText>
                </ChangedItemWrapper>
            )
        } else if (menuItem.isModified) {
            return (
                <ChangedItemWrapper key={"Key " + index}>
                    <div className={addedOrModifiedItem}>Modified </div>
                    <ChangedMenuItemText>
                        <Icon src={menuItemIcon} />
                        Menu item: {menuItem.menuItem.originalName ? menuItem.menuItem.originalName : menuItem.menuItem.colloquialName ? menuItem.menuItem.colloquialName : `unnamed item`}
                    </ChangedMenuItemText>
                </ChangedItemWrapper>
            )
        } else if (menuItem.isDeleted) {
            return (
                <ChangedItemWrapper key={"Key " + index}>
                    <div className={removedItem}>Removed </div>
                    <ChangedMenuItemText>
                        <Icon src={menuItemIcon} />
                        Menu item: {menuItem.menuItem.originalName ? menuItem.menuItem.originalName : menuItem.menuItem.colloquialName ? menuItem.menuItem.colloquialName : `unnamed item`}
                    </ChangedMenuItemText>
                </ChangedItemWrapper>
            )
        }
    };

    const renderExplanationText = () => {
        if (changedNlpData?.length > 0 && changedMenuItems?.length > 0) {
            return (
                <ExplanationText>This will update your menu and interaction model and apply those changes to both the test and live environments.</ExplanationText>
            )
        } else if (changedNlpData?.length > 0 && changedMenuItems?.length == 0) {
            return (
                <ExplanationText>This will update your interaction model and apply those changes to both the test and live environments.</ExplanationText>
            )
        } else if (changedNlpData?.length == 0 && changedMenuItems?.length > 0) {
            return (
                <ExplanationText>This will update your menu with the below changes.</ExplanationText>
            )
        } else if (changedNlpData?.length == 0 && changedMenuItems?.length == 0) {
            return (
                <ExplanationText>There are no pending menu or interaction model changes to save.</ExplanationText>
            )
        }
    };

    let tooltip = "";
    if (!props.isLoading) {
        if (!!(Object.keys(props.errors)?.length)) {
            tooltip = "You have errors in your NLP model";
        } else if (changedNlpData?.length == 0)
            tooltip = "You don't have any changes to save";
    }

    return (
        <SmallModalLayout className={saveChangesModal} isVisible={props.showSaveChangesModal}>
            <ModalHeader title="Save Changes" onClose={() => props.closeModal()} />
            {!!(Object.keys(props.errors)?.length) && !props.isLoading &&
                <ErrorStyle>
                    <AlertIcon src={alertIcon} />
                    You have errors in your NLP model
                </ErrorStyle>
            }
            <SaveChangesWrapper>
                {changedNlpData?.length > 0 || changedMenuItems?.length > 0 && <Title>Save all Pending Changes?</Title>}
                {renderExplanationText()}
                {changedNlpData?.length > 0 || changedMenuItems?.length > 0 && <ChangedText>Here's what has changed:</ChangedText>}
                <ChangeSummary>
                    <CustomScrollbars autoHide autoHeight autoHeightMin={(windowSize.windowSize.innerHeight * .6) - 175} autoHeightMax={(windowSize.windowSize.innerHeight * .6) - 175}>
                        {changedNlpData?.length > 0 && props.languages.map((lang, index) =>
                            <ChangedItemWrapper key={"Key " + index}>
                                <Icon src={getFlagIconForLanguage(lang)} />
                                <p>{getLanguageNameForLanguage(lang) + " - " + getRegionyNameForLanguage(lang)}</p>
                            </ChangedItemWrapper>
                        )}
                        <div>
                            {changedNlpData?.map((data, index) =>
                                <ChangedItemWrapper key={"Key " + index}>
                                    <p className={data.state == "Removed" ? removedItem : addedOrModifiedItem}>{data.state}</p>
                                    <ChangedMenuItemText><Icon src={determineIcon(data.changeType)} />{data.changeType + ": " + modifyDisplayStringBasedOnType(data.changeType, data.text)}</ChangedMenuItemText>
                                </ChangedItemWrapper>
                            )}
                        </div>
                        {changedMenuItems.length > 0 &&
                            <ChangedMenuItemsWrapper>
                                {changedMenuItems?.map((menuItem, index) => {
                                    return renderMenuItemChange(menuItem, index);
                                })
                                }
                            </ChangedMenuItemsWrapper>
                        }
                    </CustomScrollbars>
                </ChangeSummary>
            </SaveChangesWrapper>
            <FooterBar>
                <ConditionalTooltipWrapper
                    place="top"
                    showTooltip={!!tooltip}
                    text={tooltip}
                >
                    <SaveButtonWrapper>
                        <Button
                            type="button"
                            themes={["primary"]}
                            text="Save"
                            loading={props.isLoading}
                            disabled={props.isLoading || changedNlpData?.length == 0 && changedMenuItems?.length == 0 || !!(Object.keys(props.errors)?.length)}
                            onClick={() => saveChangesAndClose()} />
                    </SaveButtonWrapper>
                </ConditionalTooltipWrapper>
                <Button
                    type="button"
                    themes={["secondary"]}
                    text="Cancel"
                    loading={props.isLoading}
                    onClick={() => props.closeModal()}
                />
            </FooterBar>
        </SmallModalLayout >
    )

}

export default NlpSaveChangesModal;

const ChangeSummary = styled.div`
    padding: 16px 0;
`;

const Title = styled.div`
    margin-bottom: 16px;
`;

const SaveChangesWrapper = styled.div`
    padding: 32px;
`;

const ChangedMenuItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChangedMenuItemText = styled.div`
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const SaveButtonWrapper = styled.div`
    margin-right: auto;
`;

const saveChangesModal = css`
    height: 80%;
    width: 30%;
    min-width: 450px;
`;

const ExplanationText = styled.p`
    font-size: 14px;
    margin-bottom: 16px;
`;

const ChangedText = styled.p`
    margin-top: 32px;
    margin-bottom: 0;
    font-weight: bold;
`;

const ChangedItemWrapper = styled.div`
    padding-top: 16px;
    display: flex;
    align-items: center;
    &:first-of-type {
        padding-top: 0;
    }
`;

const addedOrModifiedItem = css`
    color: ${color_text_light};
    font-style: italic;
`;

const removedItem = css`
    color: ${faded_red};
    font-style: italic;
`;

const Icon = styled.img`
    padding: 0 8px;
    width: 32px;
`;

const ErrorStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Muli, "sans-serif";
    font-weight: normal;
    color: ${color_shades_darkest};
    background-color: ${color_variants_decline_light_1};
    border: 1px solid ${color_colors_decline};
    border-radius: 8px;
    flex-grow: 1;
    padding: 8px 16px;
    margin: 8px 0;
    font-size: 14px;
    margin: 16px 32px 0px 32px;
`;

const AlertIcon = styled.img`
    margin-right: 8px;
`;