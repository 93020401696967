import React from "react";
import { css } from '@emotion/css';
import { color_colors_decline, color_colors_ocean, color_shades_dark } from "../../../constants/colors";
import EditableSlot from "../../../models/interactionModel/EditableSlot";
import ConditionalTooltipWrapper from "../../../components/general/ConditionalTooltipWrapper";
import styled from "@emotion/styled";

type SlotSelectorProps = {
    hidden?: boolean,
    availableSlots: EditableSlot[],
    usedSlots: string[],
    addSlot: () => void,
}

export default class SlotSelector extends React.Component<SlotSelectorProps>
{

    renderSlotItem(slot: EditableSlot, idx: number, first: boolean, last: boolean) {
        return <div
            key={idx}
            onClick={this.props.addSlot.bind(this, slot)}
            className={"availableSlot"} >
            <ConditionalTooltipWrapper showTooltip={ slot.required} text={"This slot is required in all utterances for this intent"} >
                <SlotRow className={`${first ? 'first': ''} ${last ? 'last': ''}`}>
                    <SlotName>{"{ } "}
                        {slot.name}
                    </SlotName>
                    {slot.required ? <RequiredIndicator>*</RequiredIndicator> : <></> }
                </SlotRow>
            </ConditionalTooltipWrapper>
        </div>
    }

    render() {
        if (this.props.hidden)
            return null;

        return (
            <div className={slotSelectorContainer}>
                {this.props.availableSlots ? this.props.availableSlots.map((s, idx) => this.renderSlotItem(s, idx, idx === 0, idx === this.props.availableSlots.length-1)) : <i>No slots available</i>}
            </div>
        )
    }
}

const SlotName = styled.div`
    margin-right: 4px;
`;


const SlotRow = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px ${color_shades_dark};
    padding: 8px 8px 8px 8px;
    &.first {
    }
    &.last {
        border-bottom: none;
    }
`;

const RequiredIndicator = styled.div`
    color: ${color_colors_decline};
    margin-right: 4px;
    margin-left: auto;
`;
const slotSelectorContainer = css`
    position: absolute;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: ${color_shades_dark};
    right: 0px;
    top: 30px;
    min-width: 100px;
    z-index: 3;
    .availableSlot {
        cursor: pointer;
    }
    .availableSlot:hover {
        color: ${color_colors_ocean};
    }
`;
