import React from 'react';
import {css} from '@emotion/css';
import { pale_grey } from '../../../constants/colors';


const containerStyle = css`
    border: solid 1px #c7cfd2;
    background: ${pale_grey};
    padding: 16px 8px;
    margin: 0 -32px;
    p {
        margin: 0;
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        color: #304562;
        margin-left: 32px;
    }
`
const ChildItemContainer: React.SFC = (props) => (
    <div className={containerStyle}>
        <p>These options will be available to the user.</p>
        {props.children}
    </div>
);

export default ChildItemContainer