import type { VoicifySpeechRecognition, VoicifySpeechToTextProvider } from "../models/speechToTextModels"

export class BrowserSpeechToTextProvider implements VoicifySpeechToTextProvider {
    locale: string
    partialSpeechResultHandlers: ((partialResult: string) => void)[] = []
    speechResultsHandlers: ((fullResult: string) => void)[] = []
    speechErrorHandlers: ((error: string) => void)[] = []
    speechStartHandlers: ((error?: boolean) => void)[] = []
    speechOnNoMatchHandlers: ((error: string) => void)[] = []
    speechEndHandlers: ((error?: boolean) => void)[] = []
    recognition: VoicifySpeechRecognition | undefined = undefined;

    constructor(locale: string) {
        this.locale = locale;
        if (this.recognition === undefined) {
            const SpeechRecognition = (window as any)?.SpeechRecognition || (window as any)?.webkitSpeechRecognition;
            this.recognition = SpeechRecognition ? new SpeechRecognition() : null;
        }
    }

    initialize() {
        const that = this;
        if (this.recognition) {
            this.recognition.lang = this.locale ?? "en-US";
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.onresult = (event: any) => {
                let partialTranscript = '';
                let finalTranscript = '';
                for (let i = 0; i < event.results.length; i++) {
                    if (event?.results[i]?.isFinal) {
                        finalTranscript += event?.results[i][0]?.transcript;
                    } else {
                        partialTranscript += event?.results[i][0]?.transcript;
                    }
                }
                that.speechResultsHandlers?.forEach(handle => event.results && finalTranscript && handle(finalTranscript));
                that.partialSpeechResultHandlers?.forEach(handle => event.results && handle(partialTranscript));
            }
            this.recognition.onerror = (event: any) => {
                that.speechErrorHandlers?.forEach(handle => handle(event?.error))
            }
            this.recognition.onspeechend = (event: any) => {
                that.speechEndHandlers?.forEach(handle => handle(event?.type));
            }
            this.recognition.onnomatch = (event: any) => {
                that.speechOnNoMatchHandlers?.forEach(handle => handle(event?.error));
            }
        };
    };

    isSpeechRecognitionAvailable() {
        if (this.recognition === undefined) {
            return false;
        } else {
            return true;
        }
    }

    abortListening() {
        if (this.recognition) {
            this.recognition.abort();
        }
    }

    startListening() {
        if (this.recognition) {
            this.recognition.start();
            this.speechStartHandlers?.forEach(handle => handle());
        }
    }

    stopListening() {
        if (this.recognition) {
            this.recognition.stop();
            this.speechEndHandlers.forEach(handle => handle());
        }
    }

    addPartialListener(callback: (partialResult: string) => void) {
        this.partialSpeechResultHandlers?.push(callback);
    }

    addNoMatchListener(callback: (error: string) => void) {
        this.speechOnNoMatchHandlers?.push(callback);
    }

    addFinishListener(callback: (result: string) => void) {
        this.speechResultsHandlers?.push(callback);
    }

    addErrorListener(callback: (error: string) => void) {
        this.speechErrorHandlers?.push(callback);
    }

    addStartListener(callback: (error?: boolean) => void) {
        this.speechStartHandlers.push(callback)
    }

    addEndListener(callback: (error?: boolean) => void) {
        this.speechEndHandlers?.push(callback)
    }

    clearHandlers() {
        this.partialSpeechResultHandlers = []
        this.speechOnNoMatchHandlers = []
        this.speechResultsHandlers = []
        this.speechErrorHandlers = []
        this.speechStartHandlers = []
        this.speechEndHandlers = []
    }
};