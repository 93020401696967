import React from 'react';
import { css } from '@emotion/css';
import { color_shades_lighter, color_shades_dark } from '../../../constants/colors';
import ReactMarkdown from 'react-markdown';

interface MarkdownPreviewProps {
    instructionsMarkdown: string
}

const linkRenderer = (props) => {
    return <a href={props.href} target="_blank">{props.children}</a>
}
const MarkdownPreview: React.SFC<MarkdownPreviewProps> = (props) => (
    <div className={containerStyle}>
        <ReactMarkdown className="instructions" source={props.instructionsMarkdown} renderers={{ link: linkRenderer }} />
    </div>
);

const containerStyle = css`
    flex: 1;
    .instructions {
        font-family: Muli;
        font-style: normal;
        font-stretch: normal;
        p {
            margin: 16px 0px;
        }
        ol {
            margin-left: 16px;
        }
        ul {
            margin-left: 16px;
        }
        h1 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 24px;
            font-weight: bold;
        }
        h2 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 20px;
            font-weight: bold;
        }
        h3 {
            margin-bottom: 16px;
            margin-top: 16px;        
            font-size: 18px;
            font-weight: bold;
        }
        h4 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 16px;
            font-weight: bold;
        }
        h5 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 14px;
            font-weight: bold italic;
        }
        h6 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 14px;
            font-weight: bold;
        }
    }
`;

export default MarkdownPreview;