import React from 'react';
import { Subscribe } from 'unstated';
import DeploymentAccountsContainer from '../../state/containers/DeploymentAccountsContainer';
import DeploymentAccountsListView from './components/DeploymentAccountsListView';
import ApplicationContainer from '../../state/containers/ApplicationContainer';

interface DeploymentAccountsProps {
    organizationId: string
}

class DeploymentAccounts extends React.Component<DeploymentAccountsProps>{ 
    render(){
        return (
            <Subscribe to={[DeploymentAccountsContainer, ApplicationContainer]}>
                {(deploymentAccounts : DeploymentAccountsContainer, applications: ApplicationContainer) => {
                    return (
                        <DeploymentAccountsListView 
                            organizationId={this.props.organizationId} 
                            deploymentAccountsContainer={deploymentAccounts} 
                            appContainer={applications}
                        />
                    );
                }}
            </Subscribe>
        );
    }
}

export default DeploymentAccounts;