import { hexToCSSFilter } from 'hex-to-css-filter';
import { Theme } from '../../models/customAssistant/CustomAssistantConfigurationsTheme';
import { color_defaults_white, color_shades_darkest, color_shades_lighter } from '../colors';
import { defaultBlue, defaultBlueHighlight, defaultDarkGray, defaultDarkModeTextColor, defaultGrayHighlight, defaultLightGray, defaultWhite, transparent } from './customAssistantConfigurationsColors';

export const defaultFont: string = 'sans-serif';

const sharedLightStyle1Props: Theme = {
    assistantNameTextColor: color_shades_darkest,
    messageSentBackgroundColor: defaultDarkGray,
    messageReceivedBackgroundColor: '#0000000d',
    messageReceivedTextColor: color_shades_darkest,
    speechResultBoxBackgroundColor: defaultDarkGray,
    buttonBackgroundColor: defaultDarkGray,
    textInputLineColor: defaultLightGray,
    textInputActiveLineColor: defaultLightGray,
    textboxActiveHighlightColor: defaultGrayHighlight,
    font: defaultFont,
    header: {
        backgroundColor: color_defaults_white
    },
    body: {
        backgroundColor: color_shades_lighter
    },
    toolbar: {
        backgroundColor: color_defaults_white
    }
}

const sharedLightStyle1MinimalProps: Theme = {
    assistantNameTextColor: color_shades_darkest,
    messageSentBackgroundColor: defaultDarkGray,
    messageReceivedBackgroundColor: '#0000000d',
    messageReceivedTextColor: color_shades_darkest,
    speechResultBoxBackgroundColor: defaultDarkGray,
    buttonBackgroundColor: defaultDarkGray,
    textInputLineColor: defaultLightGray,
    textInputActiveLineColor: defaultLightGray,
    textboxActiveHighlightColor: defaultGrayHighlight,
    font: defaultFont,
    header: {
        backgroundColor: "transparent"
    },
    body: {
        backgroundColor: "transparent"
    },
    toolbar: {
        backgroundColor: "transparent"
    }
}

const sharedLightStyle2Props: Theme = {
    assistantNameTextColor: color_shades_darkest,
    messageSentBackgroundColor: defaultBlue,
    messageReceivedBackgroundColor: '#0000000d',
    messageReceivedTextColor: color_shades_darkest,
    speechResultBoxBackgroundColor: defaultBlue,
    speakActiveTitleColor: defaultBlue,
    typeActiveTitleColor: defaultBlue,
    textboxActiveHighlightColor: defaultBlueHighlight,
    font: defaultFont,
    header: {
        backgroundColor: color_defaults_white
    },
    body: {
        backgroundColor: color_shades_lighter
    },
    toolbar: {
        backgroundColor: color_defaults_white
    }
}

const sharedLightStyle2MinimalProps: Theme = {
    assistantNameTextColor: color_shades_darkest,
    messageSentBackgroundColor: defaultBlue,
    messageReceivedBackgroundColor: '#0000000d',
    messageReceivedTextColor: color_shades_darkest,
    speechResultBoxBackgroundColor: defaultBlue,
    speakActiveTitleColor: defaultBlue,
    typeActiveTitleColor: defaultBlue,
    textboxActiveHighlightColor: defaultBlueHighlight,
    font: defaultFont,
    header: {
        backgroundColor: "transparent"
    },
    body: {
        backgroundColor: "transparent"
    },
    toolbar: {
        backgroundColor: "transparent"
    }
}

const sharedDarkStyle1Props: Theme = {
    assistantNameTextColor: defaultWhite,
    messageReceivedTextColor: defaultWhite,
    messageSentTextColor: defaultDarkModeTextColor,
    messageSentBackgroundColor: defaultWhite,
    speechResultBoxBackgroundColor: defaultWhite,
    speakInactiveTitleColor: defaultWhite,
    speakActiveTitleColor: defaultWhite,
    typeInactiveTitleColor: defaultWhite,
    typeActiveTitleColor: defaultWhite,
    textInputTextColor: defaultWhite,
    helpTextFontColor: defaultWhite,
    muteFontColor: defaultWhite,
    assistantStateFontColor: defaultWhite,
    fullSpeechResultTextColor: defaultDarkModeTextColor,
    partialSpeechResultTextColor: defaultDarkModeTextColor,
    font: defaultFont,
    hintsTextColor: defaultWhite,
    hintsBackgroundColor: transparent,
    header: {
        backgroundColor: transparent
    },
    body: {
        backgroundColor: transparent
    },
    toolbar: {
        backgroundColor: transparent
    }
}

const sharedDarkStyle1MinimalProps: Theme = {
    assistantNameTextColor: defaultWhite,
    messageReceivedTextColor: color_shades_darkest,
    messageSentTextColor: defaultDarkModeTextColor,
    messageSentBackgroundColor: defaultWhite,
    speechResultBoxBackgroundColor: defaultWhite,
    speakInactiveTitleColor: defaultWhite,
    speakActiveTitleColor: defaultWhite,
    typeInactiveTitleColor: defaultWhite,
    typeActiveTitleColor: defaultWhite,
    textInputTextColor: defaultWhite,
    helpTextFontColor: defaultWhite,
    muteFontColor: defaultWhite,
    assistantStateFontColor: defaultWhite,
    fullSpeechResultTextColor: defaultDarkModeTextColor,
    partialSpeechResultTextColor: defaultDarkModeTextColor,
    font: defaultFont,
    hintsTextColor: defaultWhite,
    hintsBackgroundColor: transparent,
    header: {
        backgroundColor: transparent
    },
    body: {
        backgroundColor: transparent
    },
    toolbar: {
        backgroundColor: transparent
    }
}

const sharedDarkStyle2Props: Theme = {
    assistantNameTextColor: defaultWhite,
    messageReceivedTextColor: defaultWhite,
    messageSentTextColor: defaultWhite,
    messageSentBackgroundColor: defaultBlue,
    speechResultBoxBackgroundColor: defaultBlue,
    fullSpeechResultTextColor: defaultWhite,
    partialSpeechResultTextColor: defaultWhite,
    speakInactiveTitleColor: defaultWhite,
    speakActiveTitleColor: defaultWhite,
    typeInactiveTitleColor: defaultWhite,
    typeActiveTitleColor: defaultWhite,
    textInputTextColor: defaultWhite,
    helpTextFontColor: defaultWhite,
    muteFontColor: defaultWhite,
    assistantStateFontColor: defaultWhite,
    font: defaultFont,
    hintsTextColor: defaultWhite,
    hintsBackgroundColor: transparent,
    header: {
        backgroundColor: transparent
    },
    body: {
        backgroundColor: transparent
    },
    toolbar: {
        backgroundColor: transparent
    }
}

const sharedDarkStyle2MinimalProps: Theme = {
    assistantNameTextColor: defaultWhite,
    messageReceivedTextColor: color_shades_darkest,
    messageSentTextColor: defaultWhite,
    messageSentBackgroundColor: defaultBlue,
    speechResultBoxBackgroundColor: defaultBlue,
    fullSpeechResultTextColor: defaultWhite,
    partialSpeechResultTextColor: defaultWhite,
    speakInactiveTitleColor: defaultWhite,
    speakActiveTitleColor: defaultWhite,
    typeInactiveTitleColor: defaultWhite,
    typeActiveTitleColor: defaultWhite,
    textInputTextColor: defaultWhite,
    helpTextFontColor: defaultWhite,
    muteFontColor: defaultWhite,
    assistantStateFontColor: defaultWhite,
    font: defaultFont,
    hintsTextColor: defaultWhite,
    hintsBackgroundColor: transparent,
    header: {
        backgroundColor: transparent
    },
    body: {
        backgroundColor: transparent
    },
    toolbar: {
        backgroundColor: transparent
    }
}

const sharedDefaultStyleProps: Theme = {
    assistantNameTextColor: color_shades_darkest,
    messageSentBackgroundColor: defaultDarkGray,
    messageReceivedBackgroundColor: '#0000000d',
    messageReceivedTextColor: color_shades_darkest,
    speechResultBoxBackgroundColor: defaultDarkGray,
    buttonBackgroundColor: defaultDarkGray,
    textInputLineColor: defaultLightGray,
    textInputActiveLineColor: defaultLightGray,
    textboxActiveHighlightColor: defaultGrayHighlight,
    font: defaultFont,
    header: {
        backgroundColor: color_defaults_white
    },
    body: {
        backgroundColor: color_shades_lighter
    },
    toolbar: {
        backgroundColor: color_defaults_white
    }
}

const sharedDefaultStyleMinimalProps: Theme = {
    assistantNameTextColor: color_shades_darkest,
    messageSentBackgroundColor: defaultDarkGray,
    messageReceivedBackgroundColor: '#0000000d',
    messageReceivedTextColor: color_shades_darkest,
    speechResultBoxBackgroundColor: defaultDarkGray,
    buttonBackgroundColor: defaultDarkGray,
    textInputLineColor: defaultLightGray,
    textInputActiveLineColor: defaultLightGray,
    textboxActiveHighlightColor: defaultGrayHighlight,
    font: defaultFont,
    header: {
        backgroundColor: "transparent"
    },
    body: {
        backgroundColor: "transparent"
    },
    toolbar: {
        backgroundColor: "transparent"
    }
}

export const browserLightStyle1: Theme = {
    ...sharedLightStyle1Props,
    closeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    muteImageColor: hexToCSSFilter(defaultLightGray).filter,
    micActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendInactiveColor: hexToCSSFilter(defaultDarkGray).filter,
    equalizerColor: `linear-gradient(180deg, ${defaultDarkGray} 0%, ${defaultLightGray} 100%)`,
};

export const browserLightStyle1Minimal: Theme = {
    ...sharedLightStyle1MinimalProps,
    closeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    muteImageColor: hexToCSSFilter(defaultLightGray).filter,
    micActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    keyboardColor: hexToCSSFilter(defaultDarkGray).filter,
    sendActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendInactiveColor: hexToCSSFilter(defaultDarkGray).filter,
    overallBackgroundColor: "#FFFFFFBF",
    partialSpeechResultTextColor: "#FFFFFF"
}

export const mobileLightStyle1: Theme = {
    ...sharedLightStyle1Props,
    closeAssistantColor: defaultLightGray,
    minimizeAssistantColor: defaultLightGray,
    muteImageColor: defaultLightGray,
    micActiveColor: defaultDarkGray,
    sendActiveColor: defaultDarkGray,
    sendInactiveColor: defaultDarkGray,
    equalizerColor: `${defaultDarkGray},${defaultLightGray}`,

};

export const mobileLightStyle1Minimal: Theme = {
    ...sharedLightStyle1MinimalProps,
    closeAssistantColor: defaultLightGray,
    minimizeAssistantColor: defaultLightGray,
    muteImageColor: defaultLightGray,
    micActiveColor: defaultDarkGray,
    keyboardColor: defaultDarkGray,
    sendActiveColor: defaultDarkGray,
    sendInactiveColor: defaultDarkGray,
    equalizerColor: `${defaultDarkGray},${defaultLightGray}`,
    overallBackgroundColor: "#FFFFFFBF",
};

export const browserLightStyle2: Theme = {
    ...sharedLightStyle2Props,
    closeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    muteImageColor: hexToCSSFilter(defaultLightGray).filter,
    micActiveColor: hexToCSSFilter(defaultBlue).filter,
    sendActiveColor: hexToCSSFilter(defaultBlue).filter,
    sendInactiveColor: hexToCSSFilter(defaultBlue).filter,
    equalizerColor: 'linear-gradient(180deg, #83ACE4 0%, rgba(128, 199, 175, 0) 100%), #8AD1B8',
};

export const browserLightStyle2Minimal: Theme = {
    ...sharedLightStyle2MinimalProps,
    closeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    muteImageColor: hexToCSSFilter(defaultLightGray).filter,
    micActiveColor: hexToCSSFilter(defaultBlue).filter,
    keyboardColor: hexToCSSFilter(defaultBlue).filter,
    sendActiveColor: hexToCSSFilter(defaultBlue).filter,
    sendInactiveColor: hexToCSSFilter(defaultBlue).filter,
    overallBackgroundColor: "#FFFFFFBF",
    partialSpeechResultTextColor: "#FFFFFF"
};

export const mobileLightStyle2: Theme = {
    ...sharedLightStyle2Props,
    closeAssistantColor: defaultLightGray,
    minimizeAssistantColor: defaultLightGray,
    muteImageColor: defaultLightGray,
    micActiveColor: defaultBlue,
    sendActiveColor: defaultBlue,
    sendInactiveColor: defaultBlue,
    equalizerColor: "#83ACE4,#80c7af00,#8AD1B8",
};

export const mobileLightStyle2Minimal: Theme = {
    ...sharedLightStyle2MinimalProps,
    closeAssistantColor: defaultLightGray,
    minimizeAssistantColor: defaultLightGray,
    muteImageColor: defaultLightGray,
    micActiveColor: defaultBlue,
    keyboardColor: defaultBlue,
    sendActiveColor: defaultBlue,
    sendInactiveColor: defaultBlue,
    equalizerColor: "#83ACE4,#80c7af00,#8AD1B8",
    overallBackgroundColor: "#FFFFFFBF",
};

export const browserDarkStyle1: Theme = {
    ...sharedDarkStyle1Props,
    closeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    muteImageColor: hexToCSSFilter(defaultWhite).filter,
    micActiveColor: hexToCSSFilter(defaultWhite).filter,
    sendActiveColor: hexToCSSFilter(defaultWhite).filter,
    sendInactiveColor: hexToCSSFilter(defaultWhite).filter,
    overallBackgroundColor: 'linear-gradient(180deg, #444A50 0%, #8F9CA7 100%)',
    equalizerColor: 'linear-gradient(215.1deg, rgba(255, 255, 255, 0.7) 20.69%, rgba(255, 255, 255, 0.7) 95.73%)',
};

export const browserDarkStyle1Minimal: Theme = {
    ...sharedDarkStyle1MinimalProps,
    closeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    muteImageColor: hexToCSSFilter(defaultWhite).filter,
    micActiveColor: hexToCSSFilter(defaultWhite).filter,
    keyboardColor: 'brightness(0) invert(1);',
    sendActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendInactiveColor: hexToCSSFilter(defaultDarkGray).filter,
    overallBackgroundColor: '#00000080',
};

export const mobileDarkStyle1: Theme = {
    ...sharedDarkStyle1Props,
    closeAssistantColor: defaultWhite,
    minimizeAssistantColor: defaultWhite,
    muteImageColor: defaultWhite,
    micActiveColor: defaultWhite,
    keyboardColor: defaultWhite,
    sendActiveColor: defaultWhite,
    sendInactiveColor: defaultWhite,
    overallBackgroundColor: "#444A50,#8F9CA7",
    equalizerColor: "#ffffffb3,#ffffffb3"
};

export const mobileDarkStyle1Minimal: Theme = {
    ...sharedDarkStyle1MinimalProps,
    closeAssistantColor: defaultWhite,
    minimizeAssistantColor: defaultWhite,
    muteImageColor: defaultWhite,
    micActiveColor: defaultWhite,
    keyboardColor: defaultWhite,
    sendActiveColor: defaultDarkGray,
    sendInactiveColor: defaultDarkGray,
    overallBackgroundColor: "#444A50,#8F9CA7",
    equalizerColor: "#ffffffb3,#ffffffb3"
};

export const browserDarkStyle2: Theme = {
    ...sharedDarkStyle2Props,
    closeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    muteImageColor: hexToCSSFilter(defaultWhite).filter,
    micActiveColor: hexToCSSFilter(defaultWhite).filter,
    sendActiveColor: hexToCSSFilter(defaultWhite).filter,
    sendInactiveColor: hexToCSSFilter(defaultWhite).filter,
    overallBackgroundColor: 'linear-gradient(180deg, #202C36 0%, #3E77A5 100%)', 
    equalizerColor: 'linear-gradient(215.1deg, rgba(255, 255, 255, 0.7) 20.69%, rgba(255, 255, 255, 0.7) 95.73%)',
}

export const browserDarkStyle2Minimal: Theme = {
    ...sharedDarkStyle2MinimalProps,
    closeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultWhite).filter,
    muteImageColor: hexToCSSFilter(defaultWhite).filter,
    micActiveColor: hexToCSSFilter(defaultWhite).filter,
    keyboardColor: 'brightness(0) invert(1);',
    sendActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendInactiveColor: hexToCSSFilter(defaultDarkGray).filter,
    overallBackgroundColor: '#00000080', 
}

export const mobileDarkStyle2: Theme = {
    ...sharedDarkStyle2Props,
    closeAssistantColor: defaultWhite,
    minimizeAssistantColor: defaultWhite,
    muteImageColor: defaultWhite,
    micActiveColor: defaultWhite,
    keyboardColor: defaultWhite,
    sendActiveColor: defaultWhite,
    sendInactiveColor: defaultWhite,
    overallBackgroundColor: "#202C36,#3E77A5",
    equalizerColor: "#ffffffb3,#ffffffb3"
};

export const mobileDarkStyle2Minimal: Theme = {
    ...sharedDarkStyle2MinimalProps,
    closeAssistantColor: defaultWhite,
    minimizeAssistantColor: defaultWhite,
    muteImageColor: defaultWhite,
    micActiveColor: defaultWhite,
    keyboardColor: defaultWhite,
    sendActiveColor: defaultDarkGray,
    sendInactiveColor: defaultDarkGray,
    overallBackgroundColor: "#202C36,#3E77A5",
    equalizerColor: "#ffffffb3,#ffffffb3"
};

export const browserDefaultStyle: Theme = {
    ...sharedDefaultStyleProps,
    closeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    muteImageColor: hexToCSSFilter(defaultLightGray).filter,
    micActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendInactiveColor: hexToCSSFilter(defaultDarkGray).filter,
    equalizerColor: `linear-gradient(180deg, ${defaultDarkGray} 0%, ${defaultLightGray} 100%)`,
}

export const browserDefaultStyleMinimal: Theme = {
    ...sharedDefaultStyleMinimalProps,
    closeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    minimizeAssistantColor: hexToCSSFilter(defaultLightGray).filter,
    muteImageColor: hexToCSSFilter(defaultLightGray).filter,
    micActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    keyboardColor: hexToCSSFilter(defaultDarkGray).filter,
    sendActiveColor: hexToCSSFilter(defaultDarkGray).filter,
    sendInactiveColor: hexToCSSFilter(defaultDarkGray).filter,
    overallBackgroundColor: "#FFFFFFBF",
    partialSpeechResultTextColor: "#FFFFFF"
}

export const mobileDefaultStyle: Theme = {
    ...sharedDefaultStyleProps,
    closeAssistantColor: defaultLightGray,
    minimizeAssistantColor: defaultLightGray,
    muteImageColor: defaultLightGray,
    micActiveColor: defaultDarkGray,
    keyboardColor: defaultDarkGray,
    sendActiveColor: defaultDarkGray,
    sendInactiveColor: defaultDarkGray,
    equalizerColor: `${defaultDarkGray},${defaultLightGray}`
};