import React, { useEffect, useRef } from 'react';
import { TabPanel } from 'react-tabs';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import TabbedLayout from '../../../components/structure/TabbedLayout';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import OrganizationOrderingAnalyticsReport from './OrganizationOrderingAnalyticsReport';
import OrgAnalyticsContainer from '../../../hooks/OrgAnalyticsContainer';
import { isEqual } from 'lodash';

interface OrganizationAnalyticsPageProps {
    filter: AnalyticsRequestFilter
    history?: any
}
const OrganizationAnalyticsPage: React.FC<OrganizationAnalyticsPageProps> = (props) => {
    const orgAnalyticsContainer = OrgAnalyticsContainer.useContainer();
    const filterRef = useRef(null)
    useEffect(() => {
        const currentOrgId = JSON.parse(localStorage.getItem("CURRENT_ORG"))?.id;
        if (!currentOrgId) return;
        if (!isEqual(filterRef.current, props.filter)) {
            orgAnalyticsContainer.loadOrgAnalytics(currentOrgId, props.filter);
            filterRef.current = props.filter
        }

    }, [props.filter]);
    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = "/v/orgAnalytics/";

        if (index == 0) {
            props.history.push(urlStart)
        }
        if (index == 1) {
            props.history.push(urlStart + "ordering")
        }
    }

    const getSelectedIndex = () => {
        if (location.href.indexOf("ordering") > -1) {
            return 1;
        }

        return 0;
    }
    return (
        <ScrollablePanelBody>
            <TabbedLayout tabs={["General Performance", "Ordering Performance"]} top
                selectedIndex={getSelectedIndex()}
                onTabSelected={(idx, last) => handleTabSelection(idx, last)}>
                <TabPanel>
                    <OrganizationOrderingAnalyticsReport scope="general" filter={props.filter} />
                </TabPanel>
                <TabPanel>
                    <OrganizationOrderingAnalyticsReport scope="ordering" filter={props.filter} />
                </TabPanel>
            </TabbedLayout>
        </ScrollablePanelBody>
    )

}

export default OrganizationAnalyticsPage;