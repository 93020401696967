import TabContainerPanel from "../../../components/structure/Panels/TabContainerPanel";
import React from "react";
import { TabPanel } from "react-tabs";
import { css } from '@emotion/css';
import SimulatorConversationHistory from "./SimulatorConversationHistory";
import ConversationHistory from "../../../models/sessionAttributes/ConversationHistory";
import ScrollablePanelBody from "../../../components/structure/ScrollablePanelBody";
import SimulatorHistoryContentItem from "../../../models/features/api/SimulatorHistoryContentItem";
const historyIcon = require('../../../content/images/simulator/simulator-history.svg');
const historyIconBlue = require('../../../content/images/simulator/simulator-history-blue.svg');

interface SimulatorOutputProps {
    conversationHistory: ConversationHistory
    contentItems: SimulatorHistoryContentItem[]
    viewOnly?: boolean
};

const SimulatorOutputPanel: React.FC<SimulatorOutputProps> = ({ conversationHistory, contentItems, viewOnly }) => (
    <TabContainerPanel 
        tabs={[
            {
                title: "History",
                icon: historyIcon,
                selectedIcon: historyIconBlue,
                className: "ac-simulator-history"
            }
        ]} 
        className={containerStyle}>
        <TabPanel>
            <ScrollablePanelBody>
                <SimulatorConversationHistory conversationHistory={conversationHistory} contentItems={contentItems} />
            </ScrollablePanelBody>
        </TabPanel>
    </TabContainerPanel>
);

const containerStyle = css`
    width: 432px;
    max-width: 432px;
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
    flex: 1;
`;

export default SimulatorOutputPanel;