import { Container } from "unstated";
import ConditionModel from "../../models/features/api/Conditions/ConditionModel";
import * as voicifyApi from '../../api';

interface ConditionalsState {
    conditions: ConditionModel[]
    isLoading: boolean
    errors: string[]
}
export default class ConditionsContainer extends Container<ConditionalsState> {
    state = {
        conditions: [] as ConditionModel[],
        isLoading: false,
        errors: []
    }

    async getConditions() {
        if (this.state.conditions.length > 0)
            return {
                conditions: this.state.conditions,
                resultType: "Ok",
                errors: []
            }

        try {
            await this.setState({
                ...this.state,
                isLoading: true
            })
            const result = await voicifyApi.getConditions();
            await this.setState({
                ...this.state,
                conditions: result.data,
                errors: result.errors,
                isLoading: false
            })
            return result;
        } catch {
            await this.setState({
                ...this.state,
                errors: ["Unable to get conditions"],
                isLoading: false
            })
        }
    }
}