import React from 'react';
import { css } from '@emotion/css';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { text_blue, color_shades_dark, color_shades_darker } from '../../../constants/colors';
import { getFeatureTypeIcon, getDefaultFeatureTypeName } from '../../../models/extensions';
const appsIcon = require('../../../content/images/content-tree-icons/app-level/all-apps.svg');
interface CurrentContentSelectorProps {
    featureTypeId: string
    onToggle: () => void
    isMinified: boolean
    isOpen: boolean
}


const CurrentContentSelector: React.SFC<CurrentContentSelectorProps> = (props) => (
    <div className={`${containerStyle} ${props.isOpen ? 'open' : ''} ${props.isMinified ? 'minified' : ''}`} onClick={props.onToggle}>
        <div className="feature-type-icon">
            <img src={getFeatureTypeIcon(props.featureTypeId)} />
        </div>
        {!props.isMinified &&
            <div className="app-selector-title">
                <p className="selection-detail">Select Content</p>
                <p className="selection-title">{getDefaultFeatureTypeName(props.featureTypeId)}</p>
            </div>
        }
        <ToggleIndicator direction={props.isOpen ? "up" : "down"} className="toggle-indicator" />
    </div>
);

const containerStyle = css`
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 48px;
    padding: 0 16px;
    border-left: 1px solid ${color_shades_darker};
    border-right: 1px solid ${color_shades_darker};
    .feature-type-icon {
        background: white;
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 8px;
        padding: 8px;
        width: 48px;
        height: 48px;
        img {
            width: 32px;
            height: 32px;
        }
    }
    &.minified {
        .toggle-indicator {
            border-color: white;
            margin-left: 8px;
        }
    }    
    &.open {
        background: ${text_blue};
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
    }
    .app-selector-title {
        margin: 0 12px;
        p {
            color: white;
        }

        .selection-detail {
            font-family: Muli;
            font-style: italic;
            font-weight: normal;
            font-size: 10px;
            line-height: 16px;
        }
        .selection-title {
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .toggle-indicator {
        border-color: white;
        margin-left: auto;
        margin-right: 0;
    }
`


export default CurrentContentSelector;