import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaSmallWrapper from './AlexaSmallWrapper';

const screenStyle = css`
    align-items: center;
`
class AlexaSmallBody7TemplatePreview extends ScreenPreviewComponent {
    render() {
        return (
            <AlexaSmallWrapper className={screenStyle}>
                {this.state.backgroundImageUrl ? <img className="background-image" src={this.state.backgroundImageUrl} /> : null}
                {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                <div className="body-container">
                    {this.state.foregroundImageUrl
                        ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                        : null}
                </div>
            </AlexaSmallWrapper>
        )
    }
}

export default AlexaSmallBody7TemplatePreview;