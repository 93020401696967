import React from "react";
import { Provider, Subscribe } from 'unstated';
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import TemplateConfigurationContainer from "../../state/containers/TemplateConfigurationContainer";
import OrganizationAnalyticsBody from "./components/OrganizationAnalyticsBody";

interface OrganizationAnalyticsProps {
    history: any
}
const OrganizationAnalytics: React.FC<OrganizationAnalyticsProps> = (props) => (
    <Subscribe to={[TemplateConfigurationContainer, OrganizationContainer]}>
        {() => {
            return (
                <OrganizationAnalyticsBody history={props.history}/>
            );
        }}
    </Subscribe>
);


export default OrganizationAnalytics;
