import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import StatisticsModel from '../../models/analytics/api/StatisticsModel';
import ContentStatisticsModel from '../../models/analytics/ContentStatisticsModel';
import GenericAnalyticsContainer, { AnalyticsState } from './GenericAnalyticsContainer';
import AnalyticsRequestFilter from '../../models/analytics/AnalyticsRequestFilter';

interface ContentAnalyticsState extends AnalyticsState {
    isLoading: boolean
    isLoadingTotals: boolean
    contentStats: ContentStatisticsModel[]
    errors: string[]
}

export default class ContentStatisticsContainer extends GenericAnalyticsContainer<ContentAnalyticsState> {
    constructor() {
        super();
        this.state = {
            contentStats: [],
            isLoading: false,
            isLoadingTotals: false,
            currentDisplayOption: '',
            currentDeltaPeriod: 'Previous Period',
            currentDeltaType: 'Value',
            errors: []
        }
    }
    displayOptions = []

    async loadStats(applicationId: string, contentId: string, filter: AnalyticsRequestFilter): Promise<IResult<StatisticsModel>> {
        await this.setLoading(true);

        const previousPeriod = this.getPreviousDates(filter.startDate, filter.endDate);

        const existingStats = this.state.contentStats?.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(applicationId, filter));
        const existingPrevious = this.state.contentStats?.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(applicationId, {
            ...filter,
            startDate: previousPeriod.startDate,
            endDate: previousPeriod.endDate
        }));

        if (!existingPrevious)
            await this.reloadStats(applicationId, contentId, {
                ...filter,
                startDate: previousPeriod.startDate,
                endDate: previousPeriod.endDate
            });

        if (existingStats) {
            this.setLoading(false);
            return new Promise<IResult<StatisticsModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: existingStats.data,
            }))
        }

        // load primary and previous period at the same time
        var primaryPromise = this.reloadStats(applicationId, contentId, filter);
        return primaryPromise
    }
    async reloadStats(applicationId: string, contentId: string, filter: AnalyticsRequestFilter): Promise<IResult<StatisticsModel>> {
        this.setLoadingTotals(true);

        const existingStats = this.state.contentStats.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(applicationId, filter));

        if (existingStats !== undefined && existingStats !== null) {
            this.setLoadingTotals(false);
            return new Promise<IResult<StatisticsModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: existingStats.data
            }))
        }
        const promise = voicifyApi.getContentItemStats(applicationId, contentId, filter);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var usage = this.state.contentStats;
                usage.push({
                    id: contentId,
                    filter,
                    data: result.data
                });
                this.setState({
                    ...this.state,
                    isLoadingTotals: false,
                    errors: [],
                    contentStats: usage
                })
            }
            else {
                this.setLoadingTotals(false);
            }
        })
            .catch(() => {
                this.setLoadingTotals(false);
            });

        return promise;
    }


    private setLoadingTotals(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoadingTotals: isLoading
        })
    }
}