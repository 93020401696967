import styled from '@emotion/styled';
import React, { useState } from 'react';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import ModalTitle from '../../../components/general/ModalTitle';
import PageError from '../../../components/general/PageError';
import FooterBar from '../../../components/structure/FooterBar';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import ModalLayout from '../../../components/structure/ModalLayout';
import PageContainer from '../../../components/structure/PageContainer';
import { color_text_default } from '../../../constants/colors';
import LanguageModel from '../../../models/features/api/LanguageModel';
import EditableInteractionModel from '../../../models/interactionModel/EditableInteractionModel';

const closeIcon = require('../../../content/images/close_icon.svg');

interface AddNewIntentModalProps {
    handleClose: () => void;
    handleSubmit: (name: string) => any;
    isLoading: boolean;
    languages?: LanguageModel[];
    interactionModel: EditableInteractionModel;
}

const AddNewIntentModal: React.FC<AddNewIntentModalProps> = (props) => {

    const [newIntentName, setNewIntentName] = useState("" as string);
    const [isAddDisabled, setIsAddDisabled] = useState(true);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        if (props.isLoading || isAddDisabled) return;

        if (e.key === "Enter") {
            props.handleSubmit(newIntentName);
        }
    };

    const handleChange = value => {
        setIsAddDisabled(true);
        setErrorMessages([]);
        setNewIntentName(value);

        //Validate that intent display name is unique
        const iMatch = Object.keys(props.interactionModel)
            ?.filter(key => props.interactionModel[key]?.displayName.toLowerCase().trim() === value.toLowerCase().trim());
        if (iMatch?.length > 0) {
            setErrorMessages([`Intent with name "${value}" already exists`]);
            return;
        }

        if (value?.trim().length == 0) {
            setIsAddDisabled(true);
            setErrorMessages([`Please add intent name`]);
            return;
        }

        setIsAddDisabled(false);
    };
    
    return (
        <NewIntentModal 
            isVisible={true} 
            onClose={() => props.handleClose()} >        
            <PageContainer fill withFooter>
                <HorizontalContainer>
                    <ModalTitle padded>New Intent</ModalTitle>
                    <Button themes={['end-tight', 'icon']} 
                        icon={closeIcon} 
                        onClick={() => props.handleClose()} />
                </HorizontalContainer>
                <HorizontalSeparator />
                <HorizontalContainer >
                    <div className="main-body" 
                        onKeyUp={(e) => handleKeyUp(e)} >
                        <TextField
                            autoFocus
                            useForm={false}
                            required={true}
                            name={`displayName`}
                            value={newIntentName}
                            disabled={props.isLoading}
                            placeholder="Name your intent"
                            label={`Name`}
                            onChange={(e) => handleChange(e.target.value)}
                            onBlur={() => { }} />
                    </div>
                    <PageError errors={[
                        ...errorMessages ?? []
                        ]} onClear={() => {
                            setErrorMessages([]);
                        }} />
                </HorizontalContainer>
                <CustomFooter>
                    <SubmitButton
                        type="button" 
                        themes={['primary']} 
                        text="Add" 
                        onClick={() => props.handleSubmit(newIntentName)} 
                        disabled={props.isLoading || newIntentName.length == 0 || isAddDisabled} 
                        loading={props.isLoading} />
                    <Button
                        type="button"
                        themes={['secondary']}
                        text="Cancel"
                        onClick={() => props.handleClose()}
                        disabled={props.isLoading}
                        loading={props.isLoading} />
                </CustomFooter>
            </PageContainer>
        </NewIntentModal>
    );

};

export default AddNewIntentModal;

const NewIntentModal = styled(ModalLayout)`
    color: ${color_text_default};
    width: 40%;
    height: 30%;
    min-height: 500px;
    min-width: 600px;

    .main-body {
        width: 100%;
        padding: 32px;
    }
`;

const CustomFooter = styled(FooterBar)`
    z-index: auto;
`;

const SubmitButton = styled(Button)`
    margin-right: auto;
`;
