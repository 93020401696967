import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../../constants/colors';

const bombIcon = require('../../../content/images/atomic-bomb.svg');


const AppliedTemplateDeleteConfirmation = (props) => (
    <div className={containerStyle}>
        <img src={bombIcon}/>
        <p>Removing this template will delete all synced content</p>
    </div>
)
const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 64px;
    img {
        width: 82px;
        height: 82px;
        margin-bottom: 32px;
    }

    p {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${dark_grey_blue};
    }
`
export default AppliedTemplateDeleteConfirmation;