import { css } from '@emotion/css';
import React, { useEffect, useState } from "react";
import RadioGroup, { RadioOption } from "../../../components/forms/RadioGroup";
import Button from "../../../components/general/Button";
import { color_text_link } from "../../../constants/colors";
import PageError from "../../../components/general/PageError";
import styled from '@emotion/styled';
import { AlternateButton, CopyButton, DeploymentsCard, DeploymentSelector, DeploymentStep, Endpoints, EndpointsHeader, Filler, HelperText, LabelAndButtonRow, LabeledText, LinkedAccount, LoginImage, LogoEndpointsHeader, MainButton, PublishStatusRow, StepHeader, StyledLink, WorkflowStepContainer, StepNumber, TopTriangleStyle, Logo, CardContainer } from './SharedStyledComponents';
import ApplicationFulfillmentEndpoints from '../../../models/applications/api/ApplicationFulfillmentEndpoints';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import TextField from '../../../components/forms/TextField';
import DeploymentWorkflowStep from './DeploymentWorkflowStep';
import Loader from '../../../components/general/Loader';
import { LinkedApplicationStatus } from './DeploymentsTab';
import GoogleAuthUrlRequest from '../../../models/deployments/api/GoogleAuthUrlRequest';
import DialogflowDeploymentContainer, { DeploymentSelectionType } from "../../../hooks/DialogflowDeploymentContainer";
import DeploymentsContainer from '../../../hooks/DeploymentsContainer';

const dialogflowLogo = require("../../../content/images/platforms/dialogflow-logo.svg");
const googleButton = require('../../../content/images/platforms/google-button-large.png');
const linkOutWhiteIcon = require("../../../content/images/link-out-white.svg");
const linkOutIcon = require("../../../content/images/link_out.svg");
const plusDarkIcon = require("../../../content/images/plus-dark-wide.svg");
const endpointButtonIconActive = require('../../../content/images/application-settings/deployments/endpoint-button-icon-active.svg')
const endpointButtonIconInactive = require('../../../content/images/application-settings/deployments/endpoint-button-icon-inactive.svg')

interface DialogflowDeploymentProps {
    organizationId: string;
    applicationId: string;
    environmentId: string;
    endpoints: ApplicationFulfillmentEndpoints;
    applicationStatusIcon: string;
    applicationStatus: LinkedApplicationStatus;
    launchMessage: string;
}

const DialogflowDeploymentCard = (props: DialogflowDeploymentProps) => {

    const deploymentContainer = DialogflowDeploymentContainer.useContainer();
    const deploymentsContainer = DeploymentsContainer.useContainer();

    const [showEndpoints, setShowEndpoints] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
    const [workflowStep, setWorkflowStep] = useState(1);

    useEffect(() => {
        const loadAsync = async () => {
            setLoaded(false);
            // load Dialogflow deployment stuff per environment
            await deploymentContainer.loadEnvironmentDeploymentInformation(props.applicationId, props.environmentId, props.organizationId);
            setLoaded(true);
        }

        loadAsync();

        const cleanUp = () => {
            deploymentContainer.reset();
        }
        return cleanUp;
    }, [props.applicationId, props.environmentId, props.organizationId]);

    useEffect(() => {
        switch (deploymentContainer.status) {
            case ("initial"):
                setWorkflowStep(1);
                break;
            case ("linkingApp"):
                setWorkflowStep(2);
                break;
            case ("finished"):
                setWorkflowStep(3);
                break;
        }
    }, [deploymentContainer.status]);

    const handleSelectionChange = (option: RadioOption) => {
        deploymentContainer.setSelection(option.value as DeploymentSelectionType)
    }

    const handleDeployToAppSelectionChange = (option: RadioOption) => {
        option && deploymentContainer.setDeployToApp(option.value as string);
    }

    const handleLinkButton = async () => {
        await setPrimaryButtonLoading(true);
        switch (deploymentContainer.status) {
            case "initial": await handleAccountLink(); break;
            case "linkingApp": await handleAppLink(); break;
        }
        await setPrimaryButtonLoading(false);
    }

    const handleAccountLink = async () => {
        switch (deploymentContainer.accountSelection) {
            case "new": await linkNewAccount(); break;
            case "existing": await linkExistingAccount(); break;
        }
    }

    const handleAppLink = async () => {
        if (!deploymentContainer.linkedProjectId) return;

        await setPrimaryButtonLoading(true);
        await deploymentContainer.linkEnvironment(props.applicationId, props.environmentId, deploymentContainer.linkedProjectId);
        await deploymentsContainer.waitForEnvironmentDeployment(props.applicationId, props.environmentId, false, true, false);
        await setPrimaryButtonLoading(false);
    }

    const handleAccountSelectionChange = async (newValue) => {
        if (!newValue) return
        await deploymentContainer.setSelection("existing")
        await deploymentContainer.setAccountToLink(newValue.value, newValue.label);

    }

    const linkNewAccount = async () => {
        //openAuthWindow
        const request: GoogleAuthUrlRequest = { 
            applicationId: props.applicationId, 
            environmentId: props.environmentId };
        const urlResult = await deploymentContainer.getAuthUrl(request);
        if (urlResult) window.open(urlResult.data, "_self")
    }

    const linkExistingAccount = async () => {
        if (!deploymentContainer.tokenId) return;

        await setPrimaryButtonLoading(true);
        // Clear linkedProjectId
        await deploymentContainer.setDeployToApp("");

        const linkTokenResult = await deploymentContainer.linkTokenToEnvironment(props.applicationId, props.environmentId, deploymentContainer.tokenId);
        
        if (linkTokenResult && linkTokenResult.data) {
            await deploymentContainer.getAgents(deploymentContainer.tokenId);
            await deploymentContainer.setLinkingStatus("linkingApp");
        }
        await setPrimaryButtonLoading(false);
    }

    const handleUnlinkApp = async () => {
        await setLoaded(false);
        await deploymentContainer.unlinkEnvironmentAgent(props.applicationId, props.environmentId);
        await deploymentContainer.setLinkingStatus("linkingApp");
        await setLoaded(true);
    }

    const handleDisconnectAccount = async () => {
        await setLoaded(false);
        await deploymentContainer.unlinkEnvironmentUserAccount(props.applicationId, props.environmentId);
        await deploymentContainer.setLinkingStatus("initial");
        await setLoaded(true);
    }

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    var appName = deploymentContainer.availableApps.length ?
        deploymentContainer.availableApps
            .filter(a => a.id == deploymentContainer.linkedProjectId).map(a => a.name)[0]
        : null
        
    var appNameOrPlaceholderText = appName && appName.length ?
        appName
        : (deploymentContainer.loadingAvailableApps ? "Loading..." : "[deleted]");

    const renderStep1 = () => {
        if (deploymentContainer.status == "initial") {
            return <DeploymentStep>
                <StepHeader>
                    <StepNumber>1</StepNumber>
                    <div>Connect Account</div>
                </StepHeader>
                <RadioGroup options={[
                    { value: "new", label: "Connect a new account" },
                    { value: "existing", label: "Connect an existing account" }]}
                    onChange={handleSelectionChange}
                    value={deploymentContainer.accountSelection || (deploymentContainer.userName ? "existing" : "new")} />
                <DeploymentSelector
                    useFormTheme={true}
                    useForm={false}
                    options={deploymentContainer.availableCredentials.map(c => ({ label: c.userName, value: c.id }))}
                    disabled={deploymentContainer.accountSelection === "new"}
                    value={deploymentContainer.tokenId} onBlur={() => { }}
                    onChange={handleAccountSelectionChange.bind(this)}
                    label={"Account"}
                    name="existingDeploymentAccount" />
            </DeploymentStep>

        } else {
            return <LinkedAccount>
                <StepHeader>
                    <StepNumber>1</StepNumber>
                    <div>Connect Account</div>
                </StepHeader>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Account</span>
                        <p>{deploymentContainer.userName}</p>
                    </LabeledText>
                    <Filler />
                    <AlternateButton themes={["secondary-small"]} text="Disconnect" onClick={handleDisconnectAccount} />
                </LabelAndButtonRow>
            </LinkedAccount>
        }
    }

    const renderStep2 = () => {
        if (deploymentContainer.status == "linkingApp") {
            return <DeploymentStep>
                <StepHeader>
                    <StepNumber>2</StepNumber>
                    <div>Link Agent</div>
                </StepHeader>
                <AdditionalStepSection>
                    <img src={plusDarkIcon} alt="plus icon"/>
                    <p>To create a new agent, visit the <a target="_blank" href="https://console.dialogflow.com">Dialogflow console <img src={linkOutIcon}></img></a></p>
                </AdditionalStepSection>
                <StepSectionLabel>Connect an existing agent</StepSectionLabel>
                <DeploymentSelector
                    useFormTheme={true}
                    useForm={false}
                    options={deploymentContainer.availableApps.map(a => ({ value: a.id, label: a.name }))}
                    value={deploymentContainer.linkedProjectId} onBlur={() => { }}
                    onChange={handleDeployToAppSelectionChange.bind(this)}
                    name="deployToApp"
                    label="Agent"
                />
            </DeploymentStep>
        } else {
            return <DeploymentStep>
                <StepHeader>
                    <StepNumber>2</StepNumber>
                    <div>Link Agent</div>
                </StepHeader>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Agent</span>
                        <p>{appNameOrPlaceholderText}</p>
                    </LabeledText>
                    <Filler />
                    <AlternateButton themes={["secondary-small"]} text="Disconnect" onClick={handleUnlinkApp} />
                </LabelAndButtonRow>
            </DeploymentStep>
        }
    }
    const getApplicationStatusText = () => {
        switch (props.applicationStatus) {
            case ("WAITING"):
                return "Please wait...";
            case ("LINKED"):
                return "Agent linked"
            case ("DEPLOYMENT_ISSUE"):
                return "Deployment issues"
            case ("UNKNOWN"):
            default:
                return "Unknown";
        }
    }

    const getAdditionalStatusText = () => {
        switch (props.applicationStatus) {
            case ("WAITING"):
                return "Your Voicify application is syncing with your Dialogflow agent";
            case ("LINKED"):
                return "Your Voicify application is synced with your dialogflow agent. Go to the dialogflow console to deploy it.";
            case ("UNKNOWN"):
                return "";
            default:
                return "";
        }
    }

    const renderStep3 = () => {
        const additionalStatusText = getAdditionalStatusText();
        return <DeploymentStep>
            <StepHeader>
                <StepNumber>3</StepNumber>
                <div>Launch</div>
            </StepHeader>
            <LabelAndButtonRow>
                <LabeledText>
                    <span>Application Status</span>
                    <PublishStatusRow>
                        <a href="https://console.dialogflow.com/" target="_blank">
                            <img src={props.applicationStatusIcon} />
                        </a>
                        <p>{getApplicationStatusText()}</p>
                    </PublishStatusRow>
                </LabeledText>
                <Filler />
                <StyledLink target="_blank" href="https://support.voicify.com/knowledge/getting-started-with-voicify-part-4-deploying-to-alexa-and-google">View our launch docs</StyledLink>
            </LabelAndButtonRow>
            {(props.launchMessage || additionalStatusText) &&
                <LabeledText>
                    <p>{props.launchMessage ?? additionalStatusText}</p>
                </LabeledText>
            }
        </DeploymentStep>
    }

    const isMainButtonDisabled = () => {
        if (workflowStep == 1 && !deploymentContainer.tokenId)
            return true;
        if (workflowStep == 2 && !deploymentContainer.linkedProjectId)
            return true;
        return false;
    }

    const renderMainButton = () => {
        if (deploymentContainer.status == "finished") {
            return <a href="https://console.dialogflow.com/" target="_blank">
                <MainButton
                    themes={["primary", "wide"]}
                    text="Developer Console"
                    loading={primaryButtonLoading}
                    disabled={primaryButtonLoading}
                    type="button"
                    rightIcon={linkOutWhiteIcon}
                    onClick={null} />
            </a>
        }
        if (deploymentContainer.accountSelection != "new") {
            return <MainButton themes={["primary", "wide"]}
                text="Link"
                type="button"
                loading={primaryButtonLoading}
                disabled={primaryButtonLoading || isMainButtonDisabled()}
                onClick={handleLinkButton.bind(this)} />
        } else {
            return <LoginImage src={googleButton} onClick={linkNewAccount} />
        }
    }

    return (
        <CardContainer>
            <DeploymentsCard id="dialogflow-card">
                <LogoEndpointsHeader>
                    <Logo src={dialogflowLogo} />
                    <Filler />
                    <Button
                        themes={['secondary-small']}
                        disabled={props.endpoints?.dialogFlowEndpoint == null}
                        text={showEndpoints ? "Hide Endpoints" : "Show Endpoints"}
                        onClick={() => setShowEndpoints(!showEndpoints)}
                        type="button"
                        rightIcon={showEndpoints ? endpointButtonIconActive : endpointButtonIconInactive}
                        className={showEndpoints ? `button-active` : null} />
                </LogoEndpointsHeader>
                {
                    showEndpoints &&
                    <>
                        <TopTriangleStyle />
                        <Endpoints>
                            <EndpointsHeader>Fulfillment Endpoints</EndpointsHeader>
                            <HorizontalContainer>
                                <TextField
                                    useForm={false}
                                    value={props.endpoints?.dialogFlowEndpoint}
                                    onChange={null}
                                    readOnly
                                    label="Direct Fulfillment"
                                />
                                <CopyButton themes={["secondary"]} text="Copy" type="button" onClick={() => copyText(props.endpoints?.dialogFlowEndpoint)} />
                            </HorizontalContainer>
                        </Endpoints>
                    </>
                }
                <HelperText>
                    <p>Let’s get you ready to deploy to Dialogflow! <br /> <br /></p>
                    <p>This process will get your account on Voicify synced with your Google account and deploy your app to a specific Dialogflow agent. </p>
                    <StyledLink target="_blank" href="https://support.voicify.com/en/knowledge/dialogflow-deployment">Read More </StyledLink>
                </HelperText>
                <WorkflowStepContainer>
                    <DeploymentWorkflowStep
                        name="Connect Account"
                        currentStep={workflowStep}
                        step={1} />
                    <DeploymentWorkflowStep
                        name="Link Agent"
                        currentStep={workflowStep}
                        step={2} />
                    <DeploymentWorkflowStep
                        name="Launch"
                        currentStep={workflowStep}
                        step={3} />
                    <DeploymentWorkflowStep
                        last
                        icon={props.applicationStatusIcon}
                        currentStep={workflowStep}
                        step={4} 
                        href="https://console.dialogflow.com/" 
                    />
                </WorkflowStepContainer>
                {!loaded && <Loader className={customLoaderStyle} />}
                {
                    loaded &&
                    <>
                        {(workflowStep >= 1 && renderStep1())}
                        {(workflowStep >= 2 && renderStep2())}
                        {(workflowStep >= 3 && renderStep3())}
                        {renderMainButton()}
                    </>
                }
            </DeploymentsCard>
            <PageError 
                errors={deploymentContainer.errors} 
                onClear={deploymentContainer.clearErrors} />
        </CardContainer>
    );
}

const customLoaderStyle = css`
    position: relative;
    top: auto;
    margin-top: 30px;
    margin-bottom: 30px;    
`;

const AdditionalStepSection =  styled.div`
    display: flex;
    align-items: center;
    margin: 24px 0;
    >img {
        margin-right: 12px;
    }
    a {
        color: ${color_text_link};
    }
`

const StepSectionLabel = styled.p`
    margin: 8px 0 16px 0;
`

export default DialogflowDeploymentCard;