import React from 'react';
import { css } from '@emotion/css';
import { ConversationTrackingTreeContentNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import { style_border_default } from '../../../constants/stylesValues';
import { color_colors_royalty, color_highlights_pink, color_shades_light, color_text_light, color_variants_royalty_light_10 } from '../../../constants/colors';
import SlimGenericContentItemModel from '../../../models/features/api/SlimGenericContentItemModel';
import TooltipWrapper from '../../general/TooltipWrapper';
import NodeSocket from './NodeSocket';
import { getDefaultFeatureTypeName, getFeatureTypeIcon } from '../../../models/extensions';
import { FallbackFeatureTypeId } from '../../../constants/featureTypeIds';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import ContentItemModel from '../../../models/features/api/ContentItemModel';

interface ContentNodeProps {
    id?: string
    nodeData: ConversationTrackingTreeContentNode
    conversationItem: ContentItemModel
    featureTypeId: string
    active: boolean
    selected: boolean
    childSelected: boolean
    isLoadingDetails: boolean
}

const FullContentNode: React.SFC<ContentNodeProps> = (props) => {
    const getTitle = () => {
        if (props.conversationItem?.title) return props.conversationItem.title;
        if (props.isLoadingDetails) return 'Loading';
        return 'Deleted'
    }
    return (
        <div className={`${containerStyle} ${props.active ? 'active' : 'inactive'} ${props.selected ? 'selected' : 'unselected'}`} id={props.id}>
            <div className="node-card">
                <div className="in-socket">
                    <NodeSocket direction="in" active={props.selected} />
                </div>
                <div className="card-body">
                    <div className="card-top">
                        <img src={getFeatureTypeIcon(props.featureTypeId ??  FallbackFeatureTypeId)} />
                        <p>{getDefaultFeatureTypeName(props.featureTypeId ?? FallbackFeatureTypeId)}</p>
                    </div>
                    <div className="card-bottom">
                        <TooltipWrapper place="top" text={props.conversationItem?.title}>
                            <p>{getTitle()}</p>
                        </TooltipWrapper>
                    </div>
                </div>
                <div className="out-socket">
                    <NodeSocket direction="out" active={props.childSelected} />
                </div>
            </div>
            <div className="circle"/>
        </div>
    );
}

const containerStyle = css`
    width: 192px;
    margin: 0 12px;
    height: 248px;
    position: relative;  
    .circle {
        width: 8px;
        height: 8px;
        position: absolute;
        border-radius: 50%;
        top: -84px;
        left: 92px;
        background: ${color_variants_royalty_light_10};
        border: 1px solid ${color_variants_royalty_light_10};
        box-sizing: content-box;
    }
    .percentage-container {
        display: flex;
        position: absolute;
        top: -16px;
        right: 0;
        >p {
            margin-right: 0;
            margin-left: auto;
            color: ${color_colors_royalty};
            text-align: right;   
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
        }
    }
    .node-card {
        cursor: pointer;
        width: 192px;
        height: 248px;
        border-radius: 4px;
        border: ${style_border_default};
        background: ${color_shades_light};
        padding: 32px 0;
        position: relative;
        .card-body {
            background: white;
            padding: 8px;
            position: absolute;
            left: 0;
            right: 0;
            top: 32px;
            bottom: 32px;
            .card-top {
                border-bottom: ${style_border_default};
                padding: 16px 12px;
                display: flex;
                align-items: flex-end;
                height: 50%;
                >p {
                    margin-left: 8px;
                    color: ${color_text_light};
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            .card-bottom {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50%;
                >p {
                    font-size: 14px;
                    line-height: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
       
        .out-socket {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 192px;
            position: absolute;
            bottom: -5px;
            z-index: 3;
        }
        .in-socket {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 192px;
            position: absolute;
            top: -5px;
            z-index: 3;
        }
    }

    &.inactive {
        opacity: 0.4;
    }
    &.selected {
        .circle {
            background :${color_colors_royalty};
            border: 2px solid white;
            z-index: 3;
            left: 90px;
            top: -85px;
        }
    }
`;

export default FullContentNode;