import React from 'react';
import {css} from '@emotion/css';

interface TableProps {
    itemRender: (obj : any, column: number) => JSX.Element 
    expandRender: (obj : any, index: number, column: number) => JSX.Element 
    data: any[]  
    enableExpand?: boolean
    reset?: boolean
    columnCount?: number
}

interface TableState {
    expandedIndex: number,
    expandedElement: JSX.Element,
    selectedItem: any,
    expandedColumn: number
}

const rowContainer = css`
    width: 100%;
    box-sizing: border-box;
    div {
        box-sizing: border-box;
    }
`;

const noItems = css`    
    color: rgba(0,0,0,0.5);
    padding-top: 30px;
`;

class ExpandableGrid extends React.Component<TableProps, TableState> {
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);
    
        // Set the state directly. Use props if necessary.
        this.state = {
            expandedIndex: -1,
            expandedElement: <span />,
            selectedItem: "",
            expandedColumn: 0,
        }
    }
    componentDidUpdate() {
        if(this.props.reset === true && this.state.expandedIndex !== -1) {
            this.setState({
                ...this.state,
                expandedIndex: -1,
                expandedElement: <span />,
                selectedItem: "",
                expandedColumn: 0
            });
        }
    }
    handleClick(item: any, columnIndex: number, index: number) {
        if(this.props.enableExpand)
        {
            this.setState({
                ...this.state,
                expandedIndex: index,
                expandedElement: <span />,
                selectedItem: item,
                expandedColumn: columnIndex
            }, this.renderExpand)
        }
    }
    renderExpand() {
        this.setState({
            ...this.state,
            expandedElement: this.props.expandRender(this.state.selectedItem, this.state.expandedIndex, this.state.expandedColumn)
        })
    }
    render() {
        const data = this.props.data;
        const rowArray = [];
        const columnCount = this.props.columnCount == null ? 2 : this.props.columnCount;     
        const rowStyle = css`
            display: flex;
            flex-direction: row;
            width: 100%;
            .col {
                width: ${100/columnCount}%;
            }    
        `;
        for(var i = 0; i < data.length; i+=columnCount)
        {
            var row = [];
            for(var j = i; (j < (i + (columnCount)) && j < data.length); j++)
            {
                row.push(data[j]);
            }
            rowArray.push(row);
        }
        return (
            <div>
                {(rowArray == null || rowArray.length < 1)
                    ?
                    <div className={noItems}>No Items in Library</div>
                    :
                    rowArray.map((itemRow, rowIndex) =>    
                        <div key={rowIndex} className={rowContainer}>               
                            <div className={`${rowStyle}`}>
                                {
                                    itemRow.map((item, columnIndex) => 
                                        <div 
                                            onClick={() =>  this.handleClick(item, columnIndex, rowIndex)}  
                                            className={"col"}
                                            key={columnIndex}
                                        >
                                            {this.props.itemRender(item, columnIndex)}
                                        </div>
                                    )
                                }
                            </div>
                            {
                                (this.state.expandedIndex === rowIndex && this.props.enableExpand) ?
                                    this.state.expandedElement
                                    :
                                    null                                
                            }
                        </div>  
                    )
                }
            </div>
        )
    }
}

export default ExpandableGrid;