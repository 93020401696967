import fetch from 'cross-fetch';
import type { CustomAssistantRequest } from "models/customAssistantApiModels";

export const getAssistantResponse = (requestBody: CustomAssistantRequest, url: string) => {
    return (
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        })
    )
};