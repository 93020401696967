import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import FeatureContainer from '../../state/containers/FeatureContainer';
import SimpleChoiceContainer from '../../state/containers/SimpleChoiceContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import CreateSimpleChoiceForm from './components/CreateSimpleChoiceForm';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import SimpleChoiceModel from '../../models/features/api/SimpleChoices/SimpleChoiceModel';
import IResult from '../../models/result/IResult';
import GenericCreateContentItemPage from '../formScenes/GenericCreateContentItemPage';
import { SimpleChoiceFeatureTypeId } from '../../constants/featureTypeIds';

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface CreateSimpleChoiceProps extends RouteComponentProps<ApplicationRouteProps> { }

class CreateSimpleChoice extends React.Component<CreateSimpleChoiceProps> {
    render() {
        return (
            <Subscribe to={[SimpleChoiceContainer, ApplicationContainer, TitleStateContainer, RepromptContainer, FollowUpContainer, MediaResponseStateContainer, FeatureContainer]}>
                {(simpleChoiceContainer: SimpleChoiceContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseStateContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer) => {
                    return (
                        <GenericCreateContentItemPage stateContainer={simpleChoiceContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="New Simple Choice"
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={SimpleChoiceFeatureTypeId}
                            formRender={(application, assistantApplication, onPublish, onSubmit: (model: SimpleChoiceModel) => Promise<IResult<SimpleChoiceModel>>,onAccessTypeChange) => (

                                <CreateSimpleChoiceForm
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    application={application}
                                    stateContainer={simpleChoiceContainer}
                                    assistantApplication={assistantApplication}
                                    appContainer={appContainer}
                                    location={this.props.location}
                                    repromptContainer={repromptContainer}
                                    followUpContainer={followUpContainer}
                                    responseStateContainer={responseStateContainer}
                                    featureContainer={featureContainer}
                                    isSaving={simpleChoiceContainer.state.isSaving}
                                    history={this.props.history}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onShowPublishModal={onPublish}
                                    onSubmit={onSubmit}
                                    onToggleNavigation={() => { }} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default CreateSimpleChoice;