import React from 'react';
import { css } from '@emotion/css';
import MediaResponseContainerModel from '../../../models/features/api/MediaResponses/MediaResponseContainerModel';
import MediaItemModel from '../../../models/media/MediaItemModel';
import MediaResponseStateContainer from '../../../state/containers/MediaResponseContainer';
import MediaContainer from '../../../state/containers/MediaContainer';
import { silver_two, dark_grey_blue, pale_grey, cool_grey } from '../../../constants/colors';
import ImageInnerTabs from '../../../components/structure/ImageInnerTabs';
import { TabPanel } from 'react-tabs';
import ConversationItem from '../../../models/conversationFlow/ConversationItem';
import UserVariationsBubble from '../../../components/messagePreviews/UserVariationsBubble';
import DeviceAudioVariationsBubble from '../../../components/messagePreviews/DeviceAudioVariationsBubble';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import AudioConversationFlowViewer from '../../../components/messagePreviews/AudioConversationFlowViewer';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import ChatConversationFlowViewer from '../../../components/messagePreviews/ChatConversationFlowViewer';
import ScreenConversationFlowViewer from '../../../components/messagePreviews/ScreenConversationFlowViewer';
import { SessionEndConversationFinish, FollowUpConversationFinish } from '../../../components/structure/ConversationFlow/ConversationFinish';
import LanguageModel from '../../../models/features/api/LanguageModel';
const outputIcon = require('../../../content/images/device.svg');
const speakerIcon = require('../../../content/images/speaker.svg');
const speakerSelectedIcon = require('../../../content/images/speaker-blue.svg');
const screenIcon = require('../../../content/images/screen.svg');
const screenSelectedIcon = require('../../../content/images/screen-blue.svg');
const chatIcon = require('../../../content/images/chat-icon.svg');
const chatSelectedIcon = require('../../../content/images/chat-icon-blue.svg');

interface ConversationFlowViewerProps {
    applicationId: string
    mediaContainer: MediaContainer
    responseContainer: MediaResponseStateContainer
    ssmlContainer: SsmlContainer
    conversationItems: ConversationItem[]
    chatOverrideItems?: ConversationItem[]
    audioOverrideItems?: ConversationItem[]
    screenOverrideItems?: ConversationItem[]
    endSession: boolean
    languages?: LanguageModel[]
}

interface ConversationFlowViewerState {
    containers: MediaResponseContainerModel[]
}

const containerStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-top: solid 1px ${silver_two};
    overflow: hidden;

    .tabs-header {
        font-family: Muli;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: ${cool_grey};
        margin: 16px 0 8px 0;
    }

    .spinner > div{
        background: ${cool_grey};
    }
`


const bodyStyle = css`
    background: ${pale_grey};
    flex-grow: 1;
`

class ConversationFlowViewer extends React.Component<ConversationFlowViewerProps> {
    componentWillMount() {
        this.props.responseContainer.getDeviceTargets();
    }
    render() {
        return (
            <div className={containerStyle}>
                <p className="tabs-header">SELECT AN INTERFACE</p>
                <div className={`${bodyStyle} ac-content-preview-tab-container`}>
                    <ImageInnerTabs defaultIndex={1} tabs={[
                        { title: 'Screen', icon: screenIcon, selectedIcon: screenSelectedIcon },
                        { title: 'Audio', icon: speakerIcon, selectedIcon: speakerSelectedIcon },
                        { title: 'Chat', icon: chatIcon, selectedIcon: chatSelectedIcon },]}>
                        <TabPanel>
                        {this.props.responseContainer.state.isLoading || this.props.responseContainer.state.deviceTargets.length == 0
                                ? <SecondaryLoader /> :
                                <ScreenConversationFlowViewer
                                    mediaContainer={this.props.mediaContainer}
                                    responseContainer={this.props.responseContainer}
                                    ssmlContainer={this.props.ssmlContainer}
                                    applicationId={this.props.applicationId}
                                    conversationItems={this.props.screenOverrideItems ? this.props.screenOverrideItems : this.props.conversationItems}
                                    endSession={this.props.endSession}
                                    targets={this.props.responseContainer.state.deviceTargets.filter(d => d.supportsForegroundImages)}
                                    languages={this.props.languages}/>
                                }
                        </TabPanel>
                        <TabPanel>
                            {this.props.responseContainer.state.isLoading || this.props.responseContainer.state.deviceTargets.length == 0
                                ? <SecondaryLoader /> :
                                <AudioConversationFlowViewer
                                    mediaContainer={this.props.mediaContainer}
                                    responseContainer={this.props.responseContainer}
                                    ssmlContainer={this.props.ssmlContainer}
                                    applicationId={this.props.applicationId}
                                    conversationItems={this.props.audioOverrideItems ? this.props.audioOverrideItems : this.props.conversationItems}
                                    endSession={this.props.endSession}
                                    targets={this.props.responseContainer.state.deviceTargets.filter(d => d.supportsSsml && !d.supportsDisplayText)} 
                                    languages={this.props.languages}/>
                            }
                        </TabPanel>
                        <TabPanel>
                            {this.props.responseContainer.state.isLoading || this.props.responseContainer.state.deviceTargets.length == 0
                                ? <SecondaryLoader /> :
                                <ChatConversationFlowViewer
                                    mediaContainer={this.props.mediaContainer}
                                    responseContainer={this.props.responseContainer}
                                    applicationId={this.props.applicationId}
                                    conversationItems={this.props.chatOverrideItems ? this.props.chatOverrideItems : this.props.conversationItems}
                                    endSession={this.props.endSession}
                                    targets={this.props.responseContainer.state.deviceTargets.filter(d => !d.supportsSsml && d.supportsDisplayText)}/>
                            }

                        </TabPanel>
                    </ImageInnerTabs>
                    {this.props.endSession ? <SessionEndConversationFinish /> : <FollowUpConversationFinish />}
                                    
                </div>
            </div>
        )
    }
}

export default ConversationFlowViewer;