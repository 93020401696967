import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import * as yup from 'yup';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import TemplateFormFieldValueModel from '../../../models/templating/api/TemplateFormFieldValueModel';
import { FormikProps, withFormik } from 'formik';
import Loader from '../../../components/general/Loader';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import Button from '../../../components/general/Button';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import AppliedApplicationTemplateSectionForm from '../../formScenes/AppliedApplicationTemplateSectionForm';
import { color_text_light } from '../../../constants/colors';
import TemplateFormFieldModel from '../../../models/templating/api/TemplateFormFieldModel';

interface AppliedAppTemplatesManagerProps {
    applicationId: string
    templateSection: TemplateFormSectionModel
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    appliedAppValues: TemplateFormFieldValueModel[]
    appliedAppTemplate: AppliedApplicationTemplateModel
    onNext: () => void
    onPrevious: () => void
    insertDynamicFormSection: (section: TemplateFormSectionModel) => void
    history: any
}

interface AppliedAppTemplatesManagerFormData {
    templateFormValues: TemplateFormFieldValueModel[]
}

const InnerForm: React.FC<AppliedAppTemplatesManagerProps & FormikProps<AppliedAppTemplatesManagerFormData>> = (props) => {
    const [recentlyAddedModuleFormFields, setRecentlyAddedModuleFormFields] = useState<TemplateFormFieldModel[]>();

    const requiredFields = props.templateSection?.templateFormFields?.filter(f => f.isRequired) ?? [];
    const combinedRequiredFields = [...requiredFields, ...(props.templateSection?.dynamicFormFields?.filter(f => f.isRequired) ?? [])];  //add required dynamic form fields to required template form fields array
    const completeFields = combinedRequiredFields.filter(f => props.values.templateFormValues?.some(v => (v.dynamicFormFieldName && v.dynamicFormFieldName == f.name && v.value) || (v.templateFormFieldId && v.templateFormFieldId == f.id && v.value)));

    useEffect(() => {
        const recentlyAddedFields = props.templateSection?.templateFormFields?.filter(f => f.recentlyAdded) ?? [];
        for (const templateFormSection of props.appliedAppTemplate?.templateForm?.templateFormSections ?? []) {
            for (const templateFormField of templateFormSection?.templateFormFields) {
                if (!props.appliedAppTemplate?.templateFormFieldValues.find(templateFormFieldValue => templateFormFieldValue.title === templateFormField.title)) {
                    if (templateFormField.defaultValue) {
                        recentlyAddedFields.push({ ...templateFormField, recentlyAdded: true });
                    }
                }
            }
        }
        setRecentlyAddedModuleFormFields(recentlyAddedFields);
    }, []);

    useEffect(() => {
        const updatedFieldValues = [...props.appliedAppValues];
        for (const templateFormSection of props.appliedAppTemplate?.templateForm?.templateFormSections ?? []) {
            for (const templateFormField of templateFormSection?.templateFormFields) {
                if (!props.appliedAppTemplate?.templateFormFieldValues.find(templateFormFieldValue => templateFormFieldValue.title === templateFormField.title)) {
                    updatedFieldValues.push({
                        value: templateFormField.defaultValue,
                        fieldType: templateFormField.fieldType,
                        tip: templateFormField.tip,
                        title: templateFormField.title,
                        label: templateFormField.label,
                        defaultValue: templateFormField.defaultValue,
                        associatedVariable: templateFormField.associatedVariable,
                        placeholder: templateFormField.placeholder,
                        templateFormFieldId: templateFormField.id
                    });
                }
            }
        }
        props.setFieldValue("templateFormValues", updatedFieldValues);
    }, [props.appliedAppValues]);

    const onDynamicFormValueChanged = (field: TemplateFormFieldModel, value: string) => {
        const templateValues = props.values.templateFormValues ?? [];
        // if we have the value, update it
        const existingField = templateValues.find(v => (field.name && v.dynamicFormFieldName == field.name) || (field.id && v.templateFormFieldId == field.id))
        if (existingField != null) {
            const index = templateValues.indexOf(existingField);
            existingField.value = value;
            templateValues[index] = existingField;
        }
        // if we don't, add it
        else {
            const fieldValue: TemplateFormFieldValueModel = {
                value: value,
                fieldType: field.fieldType,
                tip: field.tip,
                title: field.title,
                label: field.label,
                defaultValue: field.defaultValue,
                associatedVariable: field.associatedVariable,
                placeholder: field.placeholder
            };
            if (field.name)
                fieldValue.dynamicFormFieldName = field.name;
            else
                fieldValue.templateFormFieldId = field.id;
            templateValues.push(fieldValue);
        }
        props.setFieldValue("templateFormValues", templateValues);
    }

    return (
        <>
            {
                props.appliedAppTemplateContainer.state.isLoading ?
                    <Loader /> :
                    <form className={containerStyle} onSubmit={props.handleSubmit}>
                        <PanelHeaderContainer>
                            <p className="progress-label">{completeFields?.length ?? 0} of {requiredFields?.length ?? 0} required fields on this page are complete</p>
                            <div className={buttonContainerStyle}>
                                <Button disabled={props.appliedAppTemplateContainer.state.isSaving}
                                    themes={["white-small"]}
                                    text="Previous"
                                    type="button"
                                    onClick={props.onPrevious} />
                                <Button disabled={props.appliedAppTemplateContainer.state.isSaving || ((props.appliedAppTemplate?.templateForm?.templateConfiguration?.dynamicFormUrl != null) && (combinedRequiredFields.length != completeFields.length))}
                                    loading={props.appliedAppTemplateContainer.state.isSaving}
                                    themes={["primary-small"]}
                                    text={props.appliedAppTemplate?.templateForm?.templateConfiguration?.dynamicFormUrl ? "Next" : "Save & Next"}
                                    type="button"
                                    onClick={() => { props.submitForm() }} />
                            </div>
                        </PanelHeaderContainer>
                        {props.templateSection &&
                            <AppliedApplicationTemplateSectionForm
                                key={props.templateSection?.name ?? props.templateSection?.id}
                                section={props.templateSection}
                                values={props.values.templateFormValues}
                                applicationId={props.applicationId}
                                onFieldValueChange={onDynamicFormValueChanged}
                                recentlyAddedModuleFormFields={recentlyAddedModuleFormFields}
                            />
                        }
                    </form>
            }
        </>
    )
}

const containerStyle = css`
    .progress-label {
        color: ${color_text_light};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin: 0 32px;
    }
`

const buttonContainerStyle = css`
    margin-right: 16px;
    margin-left: auto;
    button {
        margin: 0 16px 0 0;
    }
`

const UpdateAppliedAppTemplateForm = withFormik<AppliedAppTemplatesManagerProps, AppliedAppTemplatesManagerFormData>({
    mapPropsToValues: props => {
        const updatedFieldValues = [...props.appliedAppTemplate?.templateFormFieldValues];
        for (const templateFormSection of props.appliedAppTemplate?.templateForm?.templateFormSections ?? []) {
            for (const templateFormField of templateFormSection?.templateFormFields) {
                if (!props.appliedAppTemplate?.templateFormFieldValues.find(templateFormFieldValue => templateFormFieldValue.title === templateFormField.title)) {
                    updatedFieldValues.push({
                        value: templateFormField.defaultValue,
                        fieldType: templateFormField.fieldType,
                        tip: templateFormField.tip,
                        title: templateFormField.title,
                        label: templateFormField.label,
                        defaultValue: templateFormField.defaultValue,
                        associatedVariable: templateFormField.associatedVariable,
                        placeholder: templateFormField.placeholder,
                        templateFormFieldId: templateFormField.id
                    });
                }
            }
        }
        return {
            templateFormValues: updatedFieldValues
        }
    },
    validationSchema: yup.object().shape({}),
    handleSubmit: async (values, { props, setFieldError }) => {
        if (props.appliedAppTemplate?.templateForm?.templateConfiguration?.dynamicFormUrl) {
            const result = await props.appliedAppTemplateContainer.finishFormSection(props.appliedAppTemplate?.id, {
                fieldValues: values.templateFormValues
            });
            if (result.resultType == "Ok") {
                const dynamicResponse = result.data;
                switch (dynamicResponse.responseType) {
                    case ("Invalid"):
                        if ((dynamicResponse.validation?.length ?? 0) > 0) {
                            //if there are errors then set the field errors and don't go nextsince we want to stay on this section.
                            dynamicResponse.validation.forEach(v => {
                                setFieldError(`fields.${v.dynamicFormFieldName ?? v.templateFormFieldId}`, v.message);
                            });
                        }
                        break;
                    case ("Custom"):
                        if (dynamicResponse.formSection != null) {
                            await props.insertDynamicFormSection(dynamicResponse.formSection);
                        }
                        break;
                    case ("Oauth"):
                        if (dynamicResponse.oauthUrl != null) {
                            // todo redirect to oauth flow during a 3.x release.
                            // for now just fall through to next page.
                        }
                    case ("Continue"):
                    default:
                        setTimeout(props.onNext, 1); // we want to elevate the transition to the main thread
                        break;
                }
            }
        }
        else {
            props.appliedAppTemplateContainer.updateAppliedTemplate(props.appliedAppTemplate?.id, {
                templateFormFieldValues: values.templateFormValues,
                name: props.appliedAppTemplate.name
            }).then(result => {
                if (result.resultType == "Ok")
                    setTimeout(props.onNext, 1); // we want to elevate the transition to the main thread
            })
        }
    }
})(InnerForm);


export default UpdateAppliedAppTemplateForm;