import React from 'react';
import WelcomeMessageContainer from "../../state/containers/WelcomeMessageContainer";
import PageContainer from '../../components/structure/PageContainer';
import WelcomeMessageModel from '../../models/features/api/Welcome/WelcomeMessageModel';
import TitleTextField from '../../components/forms/TitleTextField';
import FollowUpWithRepromptForm, { FollowUpWithRepromptFormData, FollowUpWithRepromptFormProps } from '../followUpForm';
import ConversationItemFormProps from '../../models/features/ConversationItemFormProps';
import ConversationFlow from '../conversationFlow';
import Loader from '../../components/general/Loader';
import { WelcomeMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import IResult from '../../models/result/IResult';
import ContentLanguageMultiSelector from '../../components/forms/ContentLanguageMultiSelector';
import ContentPagePanel from '../contentPage';
import RelatedContentMenu from '../relatedContentMenu';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import PanelTitle from '../contentPage/components/PanelTitle';
import ContentCollapsingSection from '../../components/structure/ContentCollapsingSection';
import ContentSectionTitle from '../contentPage/components/ContentSectionTitle';
import { getFeatureTypeIcon } from '../../models/extensions';
import PageError from '../../components/general/PageError';
import ConversationItemActionButtonToolbar from './components/ConversationItemActionButtonToolbar';
import TemplateLockedBanner from '../../components/general/TemplateLockedBanner';
import ConversationItemFieldSet from '../../components/forms/ConversationItemFieldSet';
import ContentInfoOptions from '../../components/structure/ContentInfoOptions';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';
import { ContentItemPageState } from './BaseConversationItemForm';
import WelcomeMessageResponseModel from '../../models/features/api/Welcome/WelcomeMessageResponseModel';
import ResponsesManager from '../responsesManager';
import ResponseGroup from '../../models/features/ResponseGroup';
import { DirtyFormPrompt } from './components/DirtyFormPrompt';
import SensitiveDataOptions from '../contentPage/components/SensitiveDataOptions';

const contentInfoIcon = require('../../content/images/content-tree-icons/content-level/task-list-pin.svg');
const continueIcon = require('../../content/images/await.svg');

export interface EditWelcomeMessageFormData extends FollowUpWithRepromptFormData {
    title?: string
    responses: WelcomeMessageResponseModel[]
    webhookChainingType?: WebhookChainingType
}
export interface EditWelcomeMessageFormProps extends FollowUpWithRepromptFormProps<WelcomeMessageModel>, ConversationItemFormProps<WelcomeMessageModel> {
    stateContainer: WelcomeMessageContainer
    welcomeMessage?: WelcomeMessageModel
}
class WelcomeMessageForm extends FollowUpWithRepromptForm<WelcomeMessageModel, EditWelcomeMessageFormProps, EditWelcomeMessageFormData, ContentItemPageState> {
    handleAsyncSubmit(onSuccess: (result: IResult<WelcomeMessageModel>) => void) {
        if (this.props.onSubmit && this.manualValidate()) {
            return this.props.onSubmit(this.props.stateContainer.mapFormDataToModel(this.props.welcomeMessage, this.props.values), onSuccess);
        }
    }
    handleDiscardChanges() {
        this.handleFinish();
    }
    handleDelete() {
        const confirmed = confirm("Are you sure you want to delete this?");
        if (confirmed) {
            this.props.stateContainer.delete(this.props.welcomeMessage.id).then(() => {
                this.handleFinish();
            });
        }
    }

    handleCopy() {
        this.props.onSubmit(this.props.stateContainer.mapFormDataToModel(this.props.welcomeMessage, this.props.values), () => {
            this.props.stateContainer.copy(this.props.welcomeMessage.id).then(r2 => {
                if (r2.resultType == "Ok") {
                    this.props.history.push(`/v/${r2.data.applicationFeatureId}/welcomemessages/${r2.data.id}`);
                }
            });
        });
    }

    getMenuTreeRoots() {
        return [this.getContentInfoTreeItem(),
        ...this.getResponsesTreeItems(this.props.values.responses)]
    }
    handleContentItemOptionSelected(option: string, event: any) {
        event.stopPropagation();
        if (option == "Manage Webhooks") {
            this.showWebhookInstanceManager();
        }
        else if (option == "Duplicate") {
            this.handleCopy();
        }
        else if (option == "Delete") {
            this.handleDelete();
        }
    }
    getContentItem() {
        return this.props.welcomeMessage;
    }
    handleResponsesChange(responseGroups: ResponseGroup[]) {
        this.props.setFieldValue("responses", this.getResponsesFromGroups(responseGroups));
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.stateContainer.state.isSaving;
        const isDisabled = isLoading || this.props.isTemplateOwned;
        const responseGroups = this.getResponseGroups(this.props.values.responses);
        if (this.props.responseStateContainer.state.isLoading || !this.props.application) {
            return <Loader />
        } return (
            <>
                <form onSubmit={props.handleSubmit}>
                    <DirtyFormPrompt allowPrompt={props.status?.submitting != true}/>
                    <ContentPagePanel
                        relatedContentRender={() => (<RelatedContentMenu
                            parentContentId={this.props.previousContentId}
                            conversationItem={{ ...this.props.welcomeMessage, featureTypeId: WelcomeMessagesFeatureTypeId }}
                            history={this.props.history}
                            featureTypeId={WelcomeMessagesFeatureTypeId}
                            followUp={responseGroups?.[0]?.firstResponse?.followUp}
                            contentId={this.props.welcomeMessage?.id}
                            onClose={this.props.onToggleNavigation}
                            onSave={this.handleAsyncSubmit.bind(this)} />)
                        }
                        treeRender={this.renderTree.bind(this)}
                        formPanelRender={() => (
                            <PageContainer fill>
                                <ConversationItemActionButtonToolbar
                                    errors={Object.values(props.errors)}
                                    validateForm={props.validateForm}
                                    publishDisabled={this.props.assistantApplication == null}
                                    saveDisabled={ Object.keys(props.errors).length > 0}
                                    contentItem={props.welcomeMessage}
                                    isLoading={isLoading}
                                    handleDiscardChanges={this.handleDiscardChanges.bind(this)}
                                    handlePublishing={this.handleSaveAndPublish.bind(this)}
                                    submitForm={props.submitForm}
                                />
                                {props.welcomeMessage?.createdFromId && <TemplateLockedBanner isLoading={props.stateContainer.state.isTogglingSync} isLinked={props.isTemplateOwned} onRelink={this.handleToggleRelinkModal.bind(this)} onUnlink={() => this.props.onToggleSync(this.props.welcomeMessage.id, true)} />}

                                <ConversationItemFieldSet disabled={isDisabled} withBanner={props.welcomeMessage?.createdFromId != null}>
                                    <ScrollablePanelBody className="scrollable-body">
                                        <PanelTitle title="Content Editing"
                                            detail="Here’s where you can fill in the info about this content and determine the course of the conversation by filling in the relevant details.">
                                        </PanelTitle>
                                        <ContentCollapsingSection title="Content Info" id="contentInfo" icon={contentInfoIcon} titleRightRowComponent={
                                            <ContentInfoOptions webhookCount={this.props.stateContainer.state.webhookUpdates?.webhookInstances?.length ?? this.props.stateContainer.state.attachedWebhooks?.length ?? 0}
                                                options={this.getOptions(this.props.welcomeMessage != null)}
                                                optionSelected={this.handleContentItemOptionSelected.bind(this)}
                                                manageWebhooks={this.showWebhookInstanceManager.bind(this)}
                                                toggleSensitiveConversation={this.toggleSensitiveConversation.bind(this)}
                                                showSensitiveConversation={this.state.showSensitiveConversation} />
                                        }>
                                            {this.state.showSensitiveConversation &&
                                                <SensitiveDataOptions
                                                    selectedOption={props.values.sensitiveConversationChange ?? "None"}
                                                    onChange={(value) => { props.setFieldValue("sensitiveConversationChange", value) }}
                                                />
                                            }
                                            <ContentSectionTitle title="Welcome Message Conversation" icon={getFeatureTypeIcon(WelcomeMessagesFeatureTypeId, true)} />
                                            <TitleTextField id="contentTitle" disabled={isDisabled} name="title" placeholder="For display only" value={props.values.title} label="Title" onChange={props.handleChange} onBlur={props.handleBlur} />
                                            <ContentLanguageMultiSelector id="contentLanguage" disabled={isDisabled} label="Language / Region" languages={props.application?.languages} selectedLanguageIds={props.values.languages.map(l => l.id)} onChange={this.handleLanguagesChange.bind(this)} />
                                            {this.renderOptions(false, isDisabled, true)}
                                        </ContentCollapsingSection>
                                        <ResponsesManager applicationId={this.props.application?.id}
                                            applicationFeatureId={this.props.applicationFeatureId}
                                            languages={this.props.values.languages}
                                            responseGroups={responseGroups}
                                            getConditionsKey={(c) => this.getConditionsKey(c)}
                                            isDisabled={isDisabled}
                                            onChange={this.handleResponsesChange.bind(this)}
                                            onBlur={this.props.handleBlur}
                                            emptyFollowUpOptionText={"Default - await further user input"}
                                            emptyFollowUpOptionIcon={continueIcon}
                                        />
                                    </ScrollablePanelBody>
                                </ConversationItemFieldSet>
                                <PageError errors={this.props?.stateContainer?.state?.errors ?? []} onClear={this.handleClearError.bind(this)} />
                            </PageContainer>
                        )}
                        flowPanelRender={() => (
                            <ConversationFlow
                                applicationId={this.props.application?.id}
                                key={props.values.responseContainer ? props.values.responseContainer?.id : ''}
                                endSession={false}
                                languages={this.props.values.languages}
                                conversationItems={[{
                                    requests: [{ content: `Hello ${this.props.application.invocationPhrase}`, highlights: [], id: "1" },
                                    { content: `Open ${this.props.application.invocationPhrase}`, highlights: [], id: "2" },
                                    { content: `Start ${this.props.application.invocationPhrase}`, highlights: [], id: "3" },
                                    { content: `Talk to ${this.props.application.invocationPhrase}`, highlights: [], id: "4" }],
                                    requestTitle: "User Question",
                                    children: this.getPreviewResponses(props.values.responses)
                                }]} />

                        )}>
                    </ContentPagePanel>
                </form>
                {this.renderRelinkSyncWarning()}
                {this.renderWebhookInstanceManager(this.props.application?.id, this.props.welcomeMessage?.id, WelcomeMessagesFeatureTypeId, this.props.values.webhookChainingType)}
            </>
        )
    }
}

export default WelcomeMessageForm;