import React from 'react';
import { css } from '@emotion/css';
import { silver_two, ocean_blue, color_variants_shadow_10 } from '../../constants/colors';
// component used in building forms with consistent components for look and function
interface RoundedSelectionOptionProps {
    value?: string;
    onClick: (i: number) => void;
    id?: string;
    selected: boolean;
    name: string;
    customSelectedStyle?: string;
    customDefaultStyle?: string;
    icon?: string;
    selectedIcon?: string;
    optionNumber: number;
    className?: string;
    textStyle?: string
}

class RoundedSelectionOption extends React.Component<RoundedSelectionOptionProps> {
    render() {
        const props = {
            ...this.props
        };
        const buttonClassName = `${optionStyle} ${props.selected ? (props.customSelectedStyle ?? selectedStyle) : (props.customDefaultStyle ?? defaultStyle)} ${props.className ?? ''}`;

        return (
            <button type={"button"}
                className={buttonClassName}
                onClick={() => this.props.onClick(this.props.optionNumber)}>
                <div className={`${textIconContainer} ${props.textStyle ?? ''}`}>
                    {props.icon !== undefined ? <img src={props.selected ? (props.selectedIcon ? props.selectedIcon : props.icon) : props.icon} className={optionIconStyle} /> : null}
                    <span className={`${textStyle} ${props.selected && selectedTextStyle}`}>{props.name}</span>
                </div>
            </button>
        );
    }
}

const optionStyle = css`
    display: inline-block;
    align-items: center;
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 50px;
    background: white;
    text-align: center;
    cursor: pointer;
`

const textStyle = css`    
    font-family: Muli;
    font-size: 14;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    box-sizing: border-box;
    color: var(--structure-color-inverted);
    position: relative;
    top: 2px;
`;

const selectedTextStyle = css`
    color: ${ocean_blue};
`;

const optionIconStyle = css`
    width: 20px;
    height: 20px;        
    margin-right: 8px;
    vertical-align: middle;
    box-sizing: border-box;
`;

const textIconContainer = css`
    display: inline-block;
    box-sizing: border-box;
`;

const selectedStyle = css`
    box-shadow: 0 4px 20px 0 ${color_variants_shadow_10};
    border: solid 1px ${silver_two};
`;

const defaultStyle = css`
    border: 0px;
    border-radius: 2px;
    border: solid 1px transparent;
    background: transparent;
`;

export default RoundedSelectionOption;
