import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React from "react";
import KeyModalLayout from "../../../components/structure/KeyModalLayout";
import ModalLayout from "../../../components/structure/ModalLayout";
import { blue_white, color_colors_ocean, color_shades_darkest, color_text_default, color_variants_main_bg } from "../../../constants/colors";
import { style_border_default } from "../../../constants/stylesValues";
import WebhookModel from "../../../models/webhooks/api/WebhookModel";
import WebhookSensitiveDataModel from "../../../models/webhooks/api/WebhookSensitiveDataModel";

interface WebhookSensitiveDataModalProps {
    handleClose: () => void
    webhookToUpdate: WebhookModel
    sensitiveData: WebhookSensitiveDataModel
    isLoading: boolean
}

const WebhookSensitiveDataModal = (props: WebhookSensitiveDataModalProps) => {    
    return (
        <WebhookSensitiveDataModalStyled
            zIndex={1010}
            isVisible={true}
            onClose={props.handleClose}
        >
            <KeyModalLayout
                modalTitle="Sensitive Data Encryption Key"
                handleClose={props.handleClose}
                token={props.sensitiveData.key}
                isLoading={props.isLoading}
            />
        </WebhookSensitiveDataModalStyled>
    )
}

const WebhookSensitiveDataModalStyled = styled(ModalLayout)`
    color: ${color_text_default};
    
    .main-body {
        width: 100%;
    }
`;

export default WebhookSensitiveDataModal;