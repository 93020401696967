import React, { } from "react";
import { Subscribe } from 'unstated';
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import ApplicationContainer from "../../state/containers/ApplicationContainer";
import ApplicationSettingsTab from "./components/ApplicationSettingsTab";
import MemberContainer from "../../state/containers/MemberContainer";
import ApplicationMembersContainer from "../../state/containers/ApplicationMembersContainer";
import UserContainer from "../../state/containers/UserContainer";
import ApplicationEnvironmentsContainer from "../../state/containers/ApplicationEnvironmentsContainer";
import MediaContainer from "../../state/containers/MediaContainer";
interface ApplicationSettingsProps {
    history: any
    applicationId: string,
    environmentId: string
}

function ApplicationSettings(props: ApplicationSettingsProps) {
    return (
        <Subscribe to={[
            MediaContainer,
            OrganizationContainer,
            ApplicationContainer,
            MemberContainer,
            ApplicationMembersContainer,
            UserContainer,
            ApplicationEnvironmentsContainer]}>
            {(mediaContainer: MediaContainer,
                orgContainer: OrganizationContainer,
                appContainer: ApplicationContainer,
                memberContainer: MemberContainer,
                applicationMembersContainer: ApplicationMembersContainer,
                userContainer: UserContainer,
                environmentsContainer: ApplicationEnvironmentsContainer) => {
                return (
                    <ApplicationSettingsTab
                        mediaContainer={mediaContainer}
                        orgContainer={orgContainer}
                        appContainer={appContainer}
                        memberContainer={memberContainer}
                        applicationMembersContainer={applicationMembersContainer}
                        userContainer={userContainer}
                        environmentsContainer={environmentsContainer}
                        {...props}
                    />
                );
            }}
        </Subscribe>
    );
}

export default ApplicationSettings;
