import { withFormik } from "formik";
import * as yup from 'yup';
import FallbackMessageForm, { EditFallbackMessageFormProps, EditFallbackMessageFormData } from '../../formScenes/FallbackMessageForm';

// note: some info on handling two submit buttons: https://github.com/jaredpalmer/formik/issues/214
// save creates the item and save and publish saves it and toggles the is live call
const CreateFallbackMessageForm = withFormik<EditFallbackMessageFormProps, EditFallbackMessageFormData>({
    mapPropsToValues: props => ({
        responses: [{ content: '' }],
        title: 'Untitled Fallback Message',
        followUpSelection: 'end',
        repromptSelection: 'end',
        entryType: 'open',
        sensitiveConversationChange: "None",
        requiresParent: false,
        isLive: true,
        languages: props.application?.languages.find(l => l.id == props.application?.defaultLanguageId) ? [props.application?.languages.find(l => l.id == props.application?.defaultLanguageId)] : [],
        webhookChainingType: "NoChaining"
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
        
        categoryId: yup.string().required("You must select a category")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus, setFieldValue }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel({
            applicationFeatureId: props.applicationFeatureId,
            applicationId: props.application?.id,
            responses: []
        }, values));
    }
})(FallbackMessageForm);

export default CreateFallbackMessageForm;
