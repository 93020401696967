import React from 'react';
import { css } from '@emotion/css';
import ParentContentContainer from '../../state/containers/ParentContentContainer';
import { Subscribe } from 'unstated';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import NavigationMenu from './components/NavigationMenu';
import IResult from '../../models/result/IResult';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';

interface RelatedContentNavigationProps {
    featureTypeId: string
    contentId: string
    parentContentId?: string
    followUp?: FollowUpModel
    onClose?: () => void
    history: any
    onSave?: () => Promise<IResult<any>>
    conversationItem?: GenericContentItemModel
}

class RelatedContentMenu extends React.Component<RelatedContentNavigationProps> {
    render() {
        return (
            <Subscribe to={[ParentContentContainer, FollowUpContainer]}>
                {(parentContentContainer: ParentContentContainer, followUpContainer: FollowUpContainer) => (
                    <NavigationMenu
                        featureTypeId={this.props.featureTypeId}
                        contentId={this.props.contentId}
                        followUp={this.props.followUp}
                        parentContentContainer={parentContentContainer}
                        followUpContainer={followUpContainer}
                        conversationItem={this.props.conversationItem}
                        onClose={this.props.onClose}
                        history={this.props.history}
                        onSave={this.props.onSave}
                        parentContentId={this.props.parentContentId} />
                )}
            </Subscribe>

        )
    }
}

export default RelatedContentMenu