import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import ConditionInstanceModel from '../../../models/features/api/Conditions/ConditionInstanceModel';
import ConditionModel, { ConditionComparisonType } from '../../../models/features/api/Conditions/ConditionModel';
import ModalLayout from '../../../components/structure/ModalLayout';
import SmallModalLayout from '../../../components/structure/SmallModalLayout';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import ModalTitle from '../../../components/general/ModalTitle';
import Button from '../../../components/general/Button';
import { style_border_default } from '../../../constants/stylesValues';
import { color_defaults_specbg, color_variants_main_bg, color_variants_royalty_light_20 } from '../../../constants/colors';
import FooterBar from '../../../components/structure/FooterBar';
import SelectField from '../../../components/forms/SelectField';
import TextField from '../../../components/forms/TextField';
import ScrollView from '../../../components/structure/ScrollView';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import generateGuid from '../../../services/guids';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
import ApplicationConversationFlagModel from '../../../models/features/api/Flags/ApplicationConversationFlagModel';
import _ from 'lodash';
const closeIcon = require('../../../content/images/close_icon.svg');
const decisionIcon = require('../../../content/images/content-item-icons/decision-medium.svg');
const equalsIcon = require('../../../content/images/condition-icons/qualifier-equals.svg');
const notEqualsIcon = require('../../../content/images/condition-icons/qualifier-not-equal.svg');
const containsIcon = require('../../../content/images/condition-icons/qualifier-contains.svg');
const notContainsIcon = require('../../../content/images/condition-icons/qualifier-not-contain.svg');
interface ConditionManagerProps {
    defaultConditionInstance?: ConditionInstanceModel
    conditions: ConditionModel[]
    deviceTypes: DeviceTargetModel[]
    flags: ApplicationConversationFlagModel[]
    onFinish: (conditionInstance: ConditionInstanceModel) => void
    onCancel: () => void
}
interface ConditionManagerState {
    groupName: string,
    qualifier: ConditionComparisonType,
    value: string
    canChangeValue: boolean
}

const BooleanConditionGroupNames = ["New User", "Account Linked", "Positive Sentiment", "Negative Sentiment"];

class ConditionManager extends React.Component<ConditionManagerProps, ConditionManagerState> {
    constructor(props: ConditionManagerProps) {
        super(props);
        const condition = props.defaultConditionInstance?.conditionId ? props.conditions.find(c => c.id == props.defaultConditionInstance.conditionId) : null
        this.state = {
            groupName: condition?.groupName ?? '',
            qualifier: condition?.comparisonType ?? 'Equals',
            value: props.defaultConditionInstance?.value ?? '',
            canChangeValue: condition != null
        }
    }
    capabilityOptions = [{
        value: "HasAudio",
        label: "Supports Audio"
    }, {
        value: "SupportsVideo",
        label: "Supports Video"
    }, {
        value: "HasDisplay",
        label: "Supports Display"
    }];
    getValueSuggestions() {
        if (this.state.groupName?.toLocaleLowerCase() == "platform") {
            return ["Alexa", "GoogleAssistant", "Dialogflow"]
        }
        if(this.state.groupName?.toLocaleLowerCase() == "user flags" || this.state.groupName?.toLocaleLowerCase() == "session flags") {
            return _.uniqBy(this.props.flags ?? [], f => f.name)?.map(f => f.name) ?? [];
        }
        return null;
    }

    isBooleanCondition(value: string) {

        return BooleanConditionGroupNames.includes(value);
    }

    handleVariableChange(e) {
        let newValue = "";
        if (this.isBooleanCondition(e.value))
            newValue = "true";
        this.setState({
            ...this.state,
            groupName: e.value,
            qualifier: this.props.conditions?.find(c => c.groupName == e.value && (c.comparisonType == "Equals" || c.comparisonType == "Contains"))?.comparisonType ?? "Equals",
            canChangeValue: !this.isBooleanCondition(e.value),
            value: newValue
        })
    }
    handleValueChange(e) {
        this.setState({
            ...this.state,
            value: e.target.value,
        })
    }
    hanldeSelectValueChange(e) {
        this.setState({
            ...this.state,
            value: e.value,
        })
    }
    getQualifierLabel(qualifier: ConditionComparisonType) {
        switch (qualifier) {
            case "Contains": return "Includes";
            case "DoesNotContain": return "Excludes";
            case "Equals": return "Equals";
            case "NotEquals": return "Does not equal";
        }
        return '';
    }
    getQualifierIcon(qualifier: ConditionComparisonType) {
        switch (qualifier) {
            case "Contains": return containsIcon;
            case "DoesNotContain": return notContainsIcon;
            case "Equals": return equalsIcon;
            case "NotEquals": return notEqualsIcon;
        }
        return '';
    }
    getQualifiers() {
        const conditions = this.props.conditions?.filter(c => c.groupName == this.state.groupName);
        return conditions?.map(c => ({
            label: this.getQualifierLabel(c.comparisonType),
            value: c.comparisonType
        }))
    }
    handleQualifierChange(e) {
        this.setState({
            ...this.state,
            qualifier: e.value,
            canChangeValue: this.state.groupName != "New User" && this.state.groupName != "Account Linked",
            value: (this.state.groupName != "New User" && this.state.groupName != "Account Linked") ? this.state.value : "true"
        })
    }
    getConditionGroups() {
        const conditionGroups = [];
        this.props.conditions?.forEach(c => {
            const group = conditionGroups.find(g => g.groupName == c.groupName);
            if (group) {
                group.conditions.push(c);
            } else {
                conditionGroups.push({
                    groupName: c.groupName,
                    conditions: [c]
                })
            }
        });
        return conditionGroups;
    }
    getInstanceFromState(): ConditionInstanceModel {
        const condition = this.props.conditions.find(c => c.groupName == this.state.groupName && c.comparisonType == this.state.qualifier);
        return {
            id: generateGuid(),
            andCondition: null,
            value: this.state.value,
            conditionId: condition.id,
            condition
        }
    }
    handleEnterPress(e) {
        if (e.charCode == 13) {
            this.props.onFinish(this.getInstanceFromState());
            e.stopPropogation();
        }
    }
    renderValueField() {
        // device type show dropdown
        if (this.state.groupName?.toLocaleLowerCase() == "device type") {
            return <SelectField useFormTheme clearable={false} className={valueSelectStyle} options={this.props.deviceTypes?.map(d => ({ value: d.id, label: d.displayName }))} placeholder="- Select a Value -" label="Value" value={this.state?.value ?? ''} onChange={this.hanldeSelectValueChange.bind(this)} disabled={!this.state.groupName || !this.state.canChangeValue} name="value" />
        }
        // capability show dropdown
        if (this.state.groupName?.toLocaleLowerCase() == "device capability") {
            return <SelectField useFormTheme clearable={false} className={valueSelectStyle} options={this.capabilityOptions} placeholder="- Select a Value -" label="Value" value={this.state?.value ?? ''} onChange={this.hanldeSelectValueChange.bind(this)} disabled={!this.state.groupName || !this.state.canChangeValue} name="value" />
        }

        // default text field
        return <TextField
            className={valueTextStyle}
            onKeyPress={this.handleEnterPress.bind(this)}
            suggestions={this.getValueSuggestions()}
            onSuggestionClick={this.handleSuggestionClick.bind(this)}
            placeholder="- Enter a Value -"
            label="Value"
            value={this.state?.value ?? ''}
            onChange={this.handleValueChange.bind(this)}
            disabled={!this.state.groupName || !this.state.canChangeValue}
            name="value" />
    }
    handleSuggestionClick(text: string) {
        this.setState({
            ...this.state,
            value: text
        })
    }

    getDisplayValue() {
        if (this.state.groupName?.toLocaleLowerCase() == "device type") {
            return this.props.deviceTypes?.find(d => d.id == this.state.value)?.displayName;
        }
        if (this.state.groupName?.toLocaleLowerCase() == "device capability") {
            return this.capabilityOptions.find(d => d.value == this.state.value)?.label;
        }

        return this.state.value;
    }

    render() {
        return (
            <SmallModalLayout className={overflowVisibleStyle} isVisible onClose={this.props.onCancel}>
                <div className={wrapperStyle}>
                    <HorizontalContainer className={titleContainer}>
                        <TitleWrapper>
                            <img src={decisionIcon} />
                            <ModalTitle>Add a Condition</ModalTitle>
                        </TitleWrapper>
                        <CloseButtonWrapper>
                            <Button className={closeButton} themes={['start-tight', 'icon']} icon={closeIcon} onClick={this.props.onCancel} />
                        </CloseButtonWrapper>
                        
                    </HorizontalContainer>
                    <div className={bodyContainer}>
                        <SelectField clearable={false} placeholder="- Select a Variable -" label="Variable" value={this.state.groupName} options={this.getConditionGroups().map(c => ({
                            label: c.groupName,
                            value: c.groupName
                        }))} onChange={this.handleVariableChange.bind(this)} useFormTheme name="variable" onBlur={() => { }} />
                        <SelectField
                            clearable={false}
                            placeholder="- Select a Qualifier -"
                            label="Qualifier"
                            value={this.state.qualifier}
                            options={this.getQualifiers()}
                            disabled={!this.state.groupName}
                            onChange={this.handleQualifierChange.bind(this)}
                            useFormTheme
                            name="qualifier"
                            onBlur={() => { }} />
                        {this.renderValueField()}
                    </div>

                    {this.state.groupName && <div className={conditionPreviewStyle}>
                        <p>{this.state.groupName}</p>
                        <img src={this.getQualifierIcon(this.state.qualifier)} />
                        <p>{this.getDisplayValue() ?? '...'}</p>
                    </div>}
                    <FooterBar className={footerStyle}>
                        <Button themes={["primary", "start-tight"]} text="Save" type="button" disabled={!this.state.groupName} onClick={() => this.props.onFinish(this.getInstanceFromState())} />
                        <Button themes={["white", "end-tight"]} text="Cancel" type="button" onClick={this.props.onCancel} />
                    </FooterBar>
                </div>
            </SmallModalLayout>
        )
    }
}
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`
const CloseButtonWrapper = styled.div`
    display: flex;
    padding-left: 28.5px;
    border-left: ${style_border_default};
`
const closeButton = css`
    margin-right: -8px;
    margin-left: 0;
`
const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 580px;
    overflow: visible;
`
const titleContainer = css`
    background: white;
    padding: 0 32px;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: ${style_border_default};
    img {
        margin-right: 16px;
    }
`
const bodyContainer = css`
    padding: 32px;
    background: ${color_variants_main_bg};
    flex: 1;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
`
const conditionPreviewStyle = css`
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    background: ${color_variants_royalty_light_20};
    height: 40px;
    border-top: ${style_border_default};
    padding: 0 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    img {
        margin: 0 8px;
    }
`
const footerStyle = css`
    position: fixed;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0 32px;
`;

const valueSelectStyle = css`    
    .Select-menu-outer {
        z-index: 111;
        overflow: hidden;
    }
`;

const valueTextStyle = css`
    .text-field-suggestions {
        z-index: 111;
    }
`;

const overflowVisibleStyle = css`
    overflow: visible;
`;

export default ConditionManager;