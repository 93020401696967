import React from 'react';
import { withFormik } from "formik";
import * as yup from 'yup';
import ExitMessageForm, { EditExitMessageFormProps, EditExitMessageFormData } from '../../formScenes/ExitMessageForm';

const EditExitMessageForm = withFormik<EditExitMessageFormProps, EditExitMessageFormData>({
    mapPropsToValues: props => {
        return {
            title: props.exitMessage.title,
            responses: props.exitMessage.responses.length > 0 ? props.exitMessage.responses : [{ content: '' }],
            languages: props.exitMessage.languages,
            webhookChainingType: props.exitMessage.webhookChainingType,
            isLive: props.exitMessage.isLive,
            requiresParent: props.exitMessage.requiresParent,
            sensitiveConversationChange: props.exitMessage.sensitiveConversationChange ?? "None",
        }
    },
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter an response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.exitMessage, values));
    }
})(ExitMessageForm);

export default EditExitMessageForm;