import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';

const containerStyle = css`
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: ${dark_grey_blue};
    }

`;

interface ImageUploadContainerProps {
    className?: string
}

const ImageUploadContainer: React.SFC<ImageUploadContainerProps> = (props) => (
    <div className={`${containerStyle} ${props.className ?? ''}`}>
    {props.children}
    </div>
);

export default ImageUploadContainer;