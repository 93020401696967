import React from 'react';
import { css } from '@emotion/css';
import { Subscribe } from "unstated";
import { color_shades_lighter, color_shades_dark } from '../../constants/colors';
import TemplateFormFieldValueModel from '../../models/templating/api/TemplateFormFieldValueModel';
import TemplateFormFieldModel from '../../models/templating/api/TemplateFormFieldModel';
import TemplateFormSectionModel from '../../models/templating/api/TemplateFormSectionModel';
import CheckboxField from '../../components/forms/CheckboxField';
import TextField from '../../components/forms/TextField';
import TextAreaField from '../../components/forms/TextAreaField';
import SelectField from '../../components/forms/SelectField';
import Switch from '../../components/forms/Switch';
import FormFieldCard from '../../components/forms/FormFieldCard';
import PanelContainer from '../../components/structure/Panels/PanelContainer';
import TemplateInstructionsPanel from '../applicationAppliedTemplateManager/components/TemplateInstructionsPanel';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import RadioGroup from '../../components/forms/RadioGroup';
import CheckboxGroup from '../../components/forms/CheckboxGroup';
import SsmlField from '../../components/forms/SsmlField/SsmlField';
import FileUploadField from '../../components/forms/FileUploadField';
import MediaContainer from '../../state/containers/MediaContainer';
import CustomScrollbars from '../../components/structure/CustomScrollbars';

interface AppliedApplicationTemplateFormProps {
    section: TemplateFormSectionModel
    onFieldValueChange: (field: TemplateFormFieldModel, value: string) => void
    applicationId: string
    values?: TemplateFormFieldValueModel[]
    fieldsOnly?: boolean
    preview?: boolean
    recentlyAddedModuleFormFields?: TemplateFormFieldModel[]
}

const AppliedApplicationTemplateSectionForm: React.FC<AppliedApplicationTemplateFormProps> = (props) => {

    const isRecentlyAddedModuleFormField = (field: TemplateFormFieldModel) => {
        return props.recentlyAddedModuleFormFields?.some(f => f.id == field.id);
    };

    const getValue = (field: TemplateFormFieldModel) => {
        const foundField = props.values?.find(v => (field?.name && v?.dynamicFormFieldName == field?.name) || (field?.id && v?.templateFormFieldId == field?.id));
        return foundField?.value;
    };

    const getFieldName = (field: TemplateFormFieldModel): string => {
        return field.name ?? field.id;
    };

    const handleChange = (field: TemplateFormFieldModel, value) => {
        props.onFieldValueChange(field, value);
    };

    const renderCheckbox = (field: TemplateFormFieldModel) => {
        return (
            <div>
                <div className={label}>{field.label}</div>
                <CheckboxField
                    key={field.id ?? field.name}
                    name={`fields.${getFieldName(field)}`}
                    checked={getValue(field) == 'true'}
                    onChange={(e) => handleChange(field, e.target.checked.toString())}
                    className={checkboxField}
                />
            </div>
        )
    };

    const renderSwitch = (field: TemplateFormFieldModel) => {
        return (
            <div>
                <div className={label}>{field.label}</div>
                <Switch
                    className={switchContainer}
                    key={field.id ?? field.name}
                    name={`fields.${getFieldName(field)}`}
                    onChange={(checked) => handleChange(field, checked.toString())}
                    checked={getValue(field) == 'true'}
                    label={field.label}
                />
            </div>
        )
    };

    const renderShortText = (field: TemplateFormFieldModel, type?: string) => {
        return (
            <TextField
                key={field.id ?? field.name}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field) ?? field.defaultValue ?? ''}
                type={type}
                onChange={(e) => handleChange(field, e.target.value)}
                onBlur={() => { }}
                label={field.label}
                placeholder={field.placeholder}
            />
        )
    };

    const renderLongText = (field: TemplateFormFieldModel) => {
        return (
            <TextAreaField
                key={field.id ?? field.name}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field) ?? field.defaultValue ?? ''}
                onChange={(e) => handleChange(field, e.target.value)}
                onBlur={() => { }}
                label={field.label}
                placeholder={field.placeholder}
            />
        )
    };

    const renderSsmlField = (field: TemplateFormFieldModel) => {
        return (
            <SsmlField
                key={field.id ?? field.name}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field) ?? field.defaultValue ?? ''}
                onChange={(value) => handleChange(field, value)}
                onBlur={() => { }}
                label={field.label}
                placeholder={field.placeholder}
                applicationId={props.applicationId} />
        )
    };

    const renderSelect = (field: TemplateFormFieldModel) => {
        return (
            <SelectField
                key={field.id ?? field.name}
                className={selectField}
                onChange={(e: any) => handleChange(field, e.value)}
                onBlur={() => { }}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field)}
                label={field.label}
                placeholder={field.placeholder}
                options={field.options.map(o => ({ value: o.value, label: o.label }))}
            />
        )
    };

    const renderCheckboxGroup = (field: TemplateFormFieldModel) => {
        // note: checkboxes use pipe delimited values from the core value
        return (
            <CheckboxGroup
                key={field.id ?? field.name}
                onChange={(e, c) => {
                    var val = getValue(field);
                    if (c) {
                        val += `|${e.value}`;
                    } else {
                        val = val.replace(e.value, '');
                    }
                    handleChange(field, val)
                }}
                values={getValue(field)?.split('|')}
                options={field.options.map(o => ({ value: o.value, label: o.label }))}
            />
        )
    };

    const renderRadioGroup = (field: TemplateFormFieldModel) => {
        return (
            <RadioGroup
                key={field.id ?? field.name}
                onChange={(e) => handleChange(field, e.value)}
                value={getValue(field)}
                options={field.options.map(o => ({ value: o.value, label: o.label }))}
            />
        )
    };

    const renderFileUpload = (field: TemplateFormFieldModel) => {
        return (
            <Subscribe to={[MediaContainer]}>
                {(mediaContainer: MediaContainer) => (
                    <FileUploadField
                        onChange={(e) => handleChange(field, e.url)}
                        disabled={false}
                        field={field}
                        mediaContainer={mediaContainer}
                        applicationId={props.applicationId}
                        value={getValue(field)}
                        preview={props.preview}
                    />
                )}
            </Subscribe>
        )
    };

    const renderSetupProperty = (field: TemplateFormFieldModel) => {
        switch (field.fieldType) {
            case "ShortTextField":
                return renderShortText(field);
            case "NumberTextField":
                return renderShortText(field, 'number');
            case "DateTextField":
                return renderShortText(field, 'date');
            case "LongTextField":
                return renderLongText(field);
            case "SwitchField":
                return renderSwitch(field);
            case "SelectField":
                return renderSelect(field);
            case "CheckboxGroupField":
                return renderCheckboxGroup(field);
            case "RadioGroupField":
                return renderRadioGroup(field);
            case "CheckboxField":
                return renderCheckbox(field);
            case "FileUploadField":
                return renderFileUpload(field);
            case "SSMLField":
            case "SSMLTextField":
                return renderSsmlField(field);
        }
    };

    const renderFields = () => {
        const fields = props.section?.templateFormFields ?? props.section?.dynamicFormFields ?? [];
        return (
            <div className={setupSectionContainer}>
                <h3>{props.section.title}</h3>
                {fields.map((p, subIdx) => {
                    return (
                        <div key={`setupSection_${subIdx}`} className={setupPropertyContainer}>
                            <FormFieldCard
                                title={p.title}
                                tip={p.tip}
                                required={p.isRequired}
                                isRecentlyAddedModuleFormField={isRecentlyAddedModuleFormField(p)}
                            >
                                {renderSetupProperty(p)}
                            </FormFieldCard>
                        </div>
                    )
                })}
            </div>
        )
    };

    return (
        <>
            {props.fieldsOnly && renderFields()}
            <PanelContainer className={panelContainerStyle}>
                <CustomScrollbars autoHide={true} autoHeight={true} autoHeightMin="calc(100vh - 136px)" autoHeightMax="calc(100vh - 136px)">
                    {renderFields()}
                </CustomScrollbars>
                {props.section.instructionsMarkdown &&
                    <ScrollablePanelBody className={previewStyle}>
                        <TemplateInstructionsPanel
                            title="Preview"
                            instructionsMarkdown={props.section.instructionsMarkdown}
                        />
                    </ScrollablePanelBody>
                }
            </PanelContainer>
        </>
    )
}

const panelContainerStyle = css`
`

const previewStyle = css`
    height: 100%;
`

const selectField = css`
    padding-top: 16px;
`;

const checkboxField = css`
    padding-left: 16px;
    .tooltip {
        margin-top: 3px;
    }
`;

const setupSectionContainer = css`
    padding: 32px;
    h3 {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
`;

const label = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    margin: 8px 16px;
`;
const switchContainer = css`
    margin-bottom: 32px;
`
const setupPropertyContainer = css`
    padding: 16px 0px;
    position: relative;
    .field-triangle {
        position: absolute;
        width: 24px;
        height: 24px;
        background: ${color_shades_lighter};
        right: -44px;
        top: 64px;
        transform: rotate(45deg);
        border-left: 1px solid ${color_shades_dark};
        border-bottom: 1px solid ${color_shades_dark};
    }
`;

export default AppliedApplicationTemplateSectionForm;