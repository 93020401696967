import {Container} from 'unstated';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import * as voicifyApi from '../../api';
import WebhookModel from '../../models/webhooks/api/WebhookModel';
import WebhookTypeModel from '../../models/webhooks/api/WebhookTypeModel';
import NewWebhookRequest from '../../models/webhooks/api/NewWebhookRequest';
import UpdateWebhookRequest from '../../models/webhooks/api/UpdateWebhookRequest';
import ApplicationWebhookModel from '../../models/webhooks/api/ApplicationWebhookModel';
import _ from 'lodash';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParameterValue from '../../models/webhooks/api/WebhookParameterValue';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import IResult from '../../models/result/IResult';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

type FeatureWebhookState = {
    isLoading: boolean
    isUpdating: boolean
    webhooks: WebhookModel[]
    webhookTypes: WebhookTypeModel[]
    applicationFeatureWebhooks: WebhookInstanceModel[]
    errors: string[]
}

export default class FeatureWebhookContainer extends Container<FeatureWebhookState> {
    state = {
        isLoading: false,
        isUpdating: false,
        webhooks: [] as WebhookModel[],
        webhookTypes: [] as WebhookTypeModel[],
        applicationFeatureWebhooks: [] as WebhookInstanceModel[],
        errors: []
    }

    getApplicationFeatureWebhooks(applicationFeatureId: string) {
        this.setLoading(true);
        const promise = voicifyApi.getApplicationFeatureWebhooks(applicationFeatureId);
        promise.then(result => {
            if(result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    applicationFeatureWebhooks: result.data
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })
        return promise;
    }

    addApplicationFeatureWebhook(id: string, webhook: WebhookModel, defaultParameters: { [id: string]: WebhookParameterValue }, userDefinedParameters: { [id: string]: WebhookParameterValue }) {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        let promise = webhook.instanceId == null ?
            voicifyApi.addApplicationFeatureWebhook(id, webhook.id, defaultParameters, userDefinedParameters)
            :
            voicifyApi.updateApplicationFeatureWebhook(id, webhook.instanceId, defaultParameters, userDefinedParameters);
            
        promise.then(result => {
            if (result.resultType == "Ok") {
                const webhooks = [ ...this.state.applicationFeatureWebhooks ];
                const model = result.data as WebhookInstanceModel;
                const existing = webhooks.find(w => w.id === model.id)
                if(existing == null)                
                    webhooks.push(model);
                else {
                    const idx = webhooks.indexOf(existing);
                    webhooks.splice(idx, 1, model);
                }
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [],
                    applicationFeatureWebhooks: webhooks
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }

    async bulkUpdateFeatureWebhookInstances(appFeatureId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> {
        try {     
            this.setState({
                ...this.state,
                isUpdating: true
            })
            var result = await voicifyApi.bulkUpdateFeatureWebhookInstances(appFeatureId, updateRequest);   
            if(result.resultType == "Ok") {                
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    applicationFeatureWebhooks: result.data,
                    errors: [],
                })
            }   
            else {      
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }   
            return result;
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    
    removeApplicationFeatureWebhook(applicationFeatureWebhookId: string) {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        const promise = voicifyApi.removeApplicationFeatureWebhook(applicationFeatureWebhookId);
        promise.then(result => {
            if(result.resultType == "Ok") {
                var webhooks = this.state.applicationFeatureWebhooks;
                _.remove(webhooks, w => w.id == applicationFeatureWebhookId);
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [],
                    applicationFeatureWebhooks: webhooks
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }

  
    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading
        })
    }
}