import React from 'react';
import {Tabs, Tab, TabPanel, TabList} from 'react-tabs';
import TabIndicator from './TabIndicator';
import {css} from '@emotion/css';
import { dark_sky_blue, ocean_blue, silver_two,  dark_grey_blue } from '../../constants/colors';


interface MediaLibraryTabbedLayoutProps {
    tabs: string[]
    selectedIndex?: number
    forceRenderTabPanel?: boolean
    onTabSelected?: (index: number, last: number) => void
}
const tabStripStyle = css`
    display: flex;
    flex-direction: row;
    line-height: 65px;
    list-style: none;
    border-bottom: solid 1px ${silver_two};
`;

const tabStyle = css`
display: flex;
flex-direction: column;
width: 50%;
color: black;
text-align: center;
cursor: pointer;
span {
    flex-grow: 1;
    line-height: 65px;
    font-family: Muli;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    color: ${dark_grey_blue};
}
div {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
}
`;

const activeTabStyle = css`
color: ${ocean_blue};
div {
    display: block;
    position: relative;
    top: 2;
}
`;

const tabLayoutStyle = css`
background: white;
`

const indicatorGradient = `linear-gradient(267deg, ${dark_sky_blue}, ${ocean_blue});`;

const MediaLibraryTabbedLayout: React.SFC<MediaLibraryTabbedLayoutProps> = (props) => {

    function renderTab(name: string) {
        return (
            <Tab className={tabStyle} selectedClassName={activeTabStyle}  key={name}>
                <span>{name}</span>
                <TabIndicator color={indicatorGradient}/>
            </Tab>
        );
    }
    return (
        <Tabs
            className={tabLayoutStyle} 
            defaultIndex={props.selectedIndex} 
            onSelect={props.onTabSelected}>
            <TabList className={tabStripStyle}>
                {props.tabs.map(renderTab)}
            </TabList>
            {props.children}
        </Tabs>
    );
}

export default MediaLibraryTabbedLayout;
