import React from 'react';
import {css} from '@emotion/css';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import TemplateFieldOption from './TemplateFieldOption';
import { color_text_light } from '../../../constants/colors';
interface TemplateFieldSelectorProps {
    templateConfiguration: TemplateConfigurationModel
    section: TemplateFormSectionModel
    onFieldSelected: (fieldType: string) => void
}

const TemplateFieldSelector: React.SFC<TemplateFieldSelectorProps> = (props) => {

    return (<div className={containerStyle}>
        <h3>Form Fields</h3>
        <p>Choose a form field to add to your module and configure them in the column to the left.</p>
        <TemplateFieldOption fieldType="ShortTextField" name="Short Text Field" description="Short text fields allow your users to input a short phrase to be inserted into your template." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType="LongTextField" name="Long Text Field" description="Long text fields allow your users to input a a larger portion of text that could stretch across multiple lines." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType="SSMLTextField" name="SSML Field" description="Allows users to enter ssml." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType="NumberTextField" name="Number Field" description="Allows users to pick a number." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType="DateTextField" name="Date Field" description="Allows users to pick a date." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType="SelectField" name="Dropdown" description="Dropdowns are meant for when your users have a larger selection of options and can choose only one." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType="RadioGroupField" name="Radio Buttons" description="Radio buttons are best suited for when your users have a small selection of options and can choose only one." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType="CheckboxGroupField" name="Checkboxes" description="For when you’d like to present an array of options and allow for multiple values to be selectable." onAdd={props.onFieldSelected}/>
        <TemplateFieldOption fieldType='FileUploadField' name='File' description='Allow users to upload a file' onAdd={props.onFieldSelected}/>
    </div>)
};

const containerStyle = css`
    padding: 32px;
    margin-bottom: 32px;
    >h3{ 
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;   
    }
    >p {
        color: ${color_text_light};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }
`;

export default TemplateFieldSelector;