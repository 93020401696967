import React from 'react';
import { css } from '@emotion/css';
import SelectableOrganizationMember from '../../../models/organizations/SelectableOrganizationMember';
import MemberCard from './MemberCard';

interface MemberSelectorProps {
    members: SelectableOrganizationMember[]
    onChange: (members: SelectableOrganizationMember[]) => void
}

interface MemberSelectorState {
    members: SelectableOrganizationMember[]
}

class MemberSelector extends React.Component<MemberSelectorProps, MemberSelectorState> {
    componentWillMount() {
        this.setState({
            members: this.props.members
        })
    }

    componentDidUpdate(previousProps: MemberSelectorProps) {
        if (JSON.stringify(previousProps.members) != JSON.stringify(this.props.members)) {
            this.setState({
                members: this.props.members
            })
        }
    }
    handleSelectionChange(member: SelectableOrganizationMember, index: number) {
        const members = this.state.members;
        members[index] = {member: member.member, isSelected: !member.isSelected};
        this.setState({
            ...this.state,
            members: members
        });

        this.props.onChange(members);
    }

    render() {
        return (
            <div>
                {this.state.members.map((m, i) => (
                    <MemberCard key={m.member.id}
                        id={m.member.id}
                        firstName={m.member.firstName}
                        lastName={m.member.lastName}
                        email={m.member.email}
                        selected={m.isSelected}
                        imageUrl={m.member.imageUrl}
                        onClick={this.handleSelectionChange.bind(this, m, i)} />))}
            </div>
        )
    }
}

export default MemberSelector;