import React from 'react';
import { css } from '@emotion/css';
import { DropResult, ResponderProvided, DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import WebhookListItem from './WebhookListItem';
import WebhookModel from '../../../models/webhooks/api/WebhookModel';
import WebhookFolderModel from '../../../models/webhooks/api/WebhookFolderModel';
import NoResultsView from '../../featureContent/components/NoResultsView';
import { color_colors_growth, color_shades_dark, color_shades_darkest, color_shades_light, color_variants_main_bg } from '../../../constants/colors';
import NewFeatureItem from '../../featureContent/components/NewFeatureItem';
import WebhookFolderContainer from '../../../state/containers/WebhookFolderContainer';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import WebhookFolderItem from './WebhookFolderItem';
import NewWebhookFolderItem from './NewWebhookFolderItem';
import EmptyView from '../../featureContent/components/EmptyView';

interface WebhookListProps {
    webhookFolderContainer: WebhookFolderContainer
    currentFolderId: string
    folders: WebhookFolderModel[]
    webhooks: WebhookModel[]
    withNesting: boolean
    applicationId: string
    selectedFolderIds: string[]
    selectedWebhookIds: string[]
    disabledFolderIds: string[]
    onFolderClick?: (folder: WebhookFolderModel) => void
    onWebhookClick?: (webhook: WebhookModel) => void
    onWebhookSelectionChange: (contentItem: WebhookModel, isSelected: boolean) => void
    onFolderSelectionChange: (folder: WebhookFolderModel, isSelected: boolean) => void
    handleCreateNewFolder:(text: string) => void
    reloadData: () => void
    showWebhookCount: boolean
    showContentItems: boolean
    selectableContentItems: boolean
    selectableFeatures: boolean
    isSingleSelect?: boolean
    isAddingNewFolder: boolean
    droppableId: string
}

class WebhookList extends React.Component<WebhookListProps> {
    handleDragEnd(result: DropResult, provider: ResponderProvided) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const features = _.orderBy(this.props.folders, af => af.priority, 'asc');
        const reorder = (list: any[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };
        this.props.webhookFolderContainer.setFolderOrder(this.props.applicationId, this.props.currentFolderId, reorder(features, result.source.index, result.destination.index).map(r => r.id))
    }

    render() {
        return (
            <div className={containerStyle}>
                <div className="child-container">
                    {this.props.withNesting && <div className="spacer">
                    </div>}
                    <div className="child-content-container">
                        {this.props.isAddingNewFolder && <NewWebhookFolderItem isLoading={false} onFinish={(text) => this.props.handleCreateNewFolder(text)} />}
                        {(this.props.webhookFolderContainer.state.isLoading && !(this.props.webhookFolderContainer.state.loadingFolderIds.some(s => s === this.props.currentFolderId))) ? <SecondaryLoader className="feature-loader" /> :
                            <DragDropContext onDragEnd={this.handleDragEnd.bind(this)}>
                                <Droppable droppableId={this.props.droppableId} type="WEBHOOKS_MAIN">
                                    {
                                        (provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                {_.orderBy(this.props.folders, f => f.priority, 'asc')?.map((folder, i) => (
                                                    <Draggable draggableId={folder.id} index={i} key={folder.id}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <WebhookFolderItem 
                                                                    reloadData={this.props.reloadData}
                                                                    folder={folder}
                                                                    webhookFolderContainer={this.props.webhookFolderContainer}
                                                                    applicationId={this.props.applicationId}
                                                                    onSelectionChange={this.props.onFolderSelectionChange}
                                                                    isSelected={this.props.selectedFolderIds?.some(id => id == folder.id)}
                                                                    selectable={!this.props.disabledFolderIds.some(id => id == folder.id)}
                                                                    disabled={this.props.disabledFolderIds.some(id => id == folder.id)}
                                                                    onClick={this.props.onFolderClick}
                                                                    isSingleSelect={this.props.isSingleSelect}
                                                                    showWebhookCount={this.props.showWebhookCount}
                                                                    index={i}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>
                            </DragDropContext>
                        }
                        {(this.props.webhookFolderContainer.state.loadingFolderIds.some(s => s === this.props.currentFolderId)) ?
                            <div className="content-loader">
                                <SecondaryLoader />
                            </div> :
                            <div className={`child-content-items-container ${this.props.folders == null || this.props.folders?.length === 0 ? "only-content" : ""}`}>
                                {this.props.showContentItems && _.orderBy(this.props.webhooks, webhook => webhook.createdDate, 'asc')?.map(webhook => (
                                    <WebhookListItem selectable={this.props.selectableContentItems}
                                        onClick={this.props.onWebhookClick}
                                        key={webhook.id}
                                        webhook={webhook}
                                        isSelected={this.props.selectedWebhookIds?.some(id => id == webhook.id)}
                                        onSelectionChange={this.props.onWebhookSelectionChange}
                                        disabled={false}
                                        isSingleSelect={this.props.isSingleSelect} />
                                ))}

                                {(this.props.webhooks.length == 0 && this.props.folders.length == 0 && !this.props.webhookFolderContainer.state.isLoading) &&
                                   <EmptyView />}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const containerStyle = css`
    background: ${color_variants_main_bg};
    height: 100%;
    .content-loader {
        margin: 32px;
        margin-top: 48px;
        .spinner > div {
            background: ${color_colors_growth};
        }
    }
    .feature-loader {
        margin-top: 16px;
    }
    .child-container {
        display: flex;
        align-items: stretch;
        .spacer {
            width: 32px;
            background: ${color_shades_light};
            border: 1px solid ${color_shades_dark};
            box-sizing: border-box;
        }
        .child-content-container {
            flex: 1;
            padding: 32px 0;
            .spinner > div {
                background: ${color_colors_growth};
            }
        }
        .child-content-items-container {
            padding: 32px 0;  
            margin-bottom: 64px;
            &.only-content {
                padding: 0px;
            }        
        }

    }
    .triangle-down {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid ${color_shades_darkest};
    }
    .triangle-right {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid ${color_shades_darkest};
    }

    .triangle-container {
        width: 3px;
        height: 3px;
        margin-right: 8px;
    }

    .check {
        margin-top: 4px;
    }
`

export default WebhookList;