import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import NewFollowUpRequest from '../../models/features/api/FollowUp/NewFollowUpRequest';
import IResult from '../../models/result/IResult';
import UpdateFollowUpRequest from '../../models/features/api/FollowUp/UpdateFollowUpRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import IContentWebhookContainer from '../definitions/IContentWebhookContainer';
import _ from 'lodash';
import { updateFollowUp } from '../../api';

type FollowUpState = {
    followUps: FollowUpModel[]
    isLoading: boolean
    isLoadingOne: boolean
    errors: string[]
    isSaving: boolean
}

export default class FollowUpContainer extends Container<FollowUpState> {
    // default state
    state = {
        followUps: [] as FollowUpModel[],
        isLoading: false,
        isLoadingOne: false,
        errors: [],
        isSaving: false,
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        });
    }

    getFromId(id: string) {
        const matched = this.state.followUps.filter(wm => wm.id == id);
        if (matched.length > 0) {
            return matched[0];
        }
    }

    findById(id: string): Promise<IResult<FollowUpModel>> {
      
        this.setState({
            ...this.state,
            isLoadingOne: true
        });
        const promise = voicifyApi.findFollowUpById(id);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const followUps = this.state.followUps;
                const existingFollowUp = followUps.find(f => f.id == id);
                if (existingFollowUp) {
                    followUps[followUps.indexOf(existingFollowUp)] = result.data;
                }
                else {
                    followUps.push(result.data);
                }
                this.setState({
                    ...this.state,
                    isLoadingOne: false,
                    errors: [],
                    followUps: followUps
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoadingOne: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoadingOne: false,
                errors: [error]
            })
        })
        return promise;
    }

    addFollowUp(applicationId: string, model: NewFollowUpRequest): Promise<IResult<FollowUpModel>> {
        this.setSaving(true);
        const addPromise = voicifyApi.addFollowUp(applicationId, model);
        addPromise.then(followUpResult => {
            if (followUpResult.resultType == "Ok") {
                // add the new message to state immutably;
                const updated = [...this.state.followUps, followUpResult.data];
                this.setState({
                    ...this.state,
                    followUps: updated,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: followUpResult.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            console.log(error);
        });

        return addPromise;
    }

    updateFollowUp(followUpId: string, model: UpdateFollowUpRequest): Promise<IResult<FollowUpModel>> {
        this.setSaving(true);
        var updatePromise = voicifyApi.updateFollowUp(model);
        updatePromise.then(followUpResult => {
            if (followUpResult.resultType == "Ok") {

                // get immutable copy of array
                const updated = [...this.state.followUps];
                // now update the item
                updated[updated.findIndex(m => m.id == followUpId)] = followUpResult.data;
                this.setState({
                    ...this.state,
                    followUps: updated,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: followUpResult.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error?.toString()],
                isSaving: false
            });
        });
        return updatePromise;
    }

    deleteFollowUp(followUpId: string): Promise<IResult<FollowUpModel>> {
        const deletePromise = voicifyApi.deleteFollowUp(followUpId);
        deletePromise.then(followUpResult => {
            if (followUpResult.resultType == "Ok") {
                // remove the message from state immutably;
                const updated = this.state.followUps.filter(w => w.id !== followUpId);
                this.setState({
                    ...this.state,
                    followUps: updated,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: followUpResult.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            console.log(error);
        });
        return deletePromise;
    }

    getFollowUpForApp(applicationId: string) {
        this.setLoading(true);
        var promise = voicifyApi.getFollowUpsForApp(applicationId);
        promise.then(followUpResult => {
            if (followUpResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    followUps: followUpResult.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: followUpResult.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            console.log(error);
        })
        return promise;
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }

    private setSaving(isSaving: boolean) {
        this.setState({
            ...this.state,
            isSaving: isSaving
        });
    }
}
