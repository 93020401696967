import { Platform } from "../../../models/deployments/api/ApplicationProviderAccountLink";
import React, { useEffect } from "react";
import DeploymentAccountsContainer from "../../../state/containers/DeploymentAccountsContainer";
import SortedTable from "../../../components/structure/SortedTable";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import TableCell from "../../../components/general/TableCell";
import TableHeader from "../../../components/general/TableHeader";
import ApplicationAvatar from "../../../components/general/ApplicationAvatar";
import { css } from '@emotion/css';
import ApplicationLinksByPlatform from "../../../models/applications/api/ApplicationLinksByPlatform";
import Button from "../../../components/general/Button";
import PageError from "../../../components/general/PageError";

const alexaIcon = require('../../../content/images/platforms/alexa-avatar.svg');
const googleIcon = require('../../../content/images/platforms/google-avatar.svg');

interface DeploymentAccountsListFilterOptions {
    applicationId?: string,
    platform?: Platform
}

const rowContent = css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    > * {
        margin-right: 12px;
    }
`;

const anchorRight = css`
    right: 10px;
    position: absolute;
`;

const platformIconMapping = (platform: Platform) => {
    switch (platform) {
        case "Alexa": return alexaIcon;
        case "Google": return googleIcon;
    };
}

interface DeploymentAccountsListViewProps {
    deploymentAccountsContainer: DeploymentAccountsContainer,
    appContainer: ApplicationContainer,
    organizationId: string,
    filter?: DeploymentAccountsListFilterOptions
}

const DeploymentAccountsListView = (props: DeploymentAccountsListViewProps) => {

    useEffect(() => {
        props.deploymentAccountsContainer.updateDeploymentAccountLinksToApplications(props.organizationId);
    }, [props.organizationId])

    const removeCredentials = (l) => {
        var rm = confirm("Are you sure you want to remove this account and any links to Voicify applications?")
        if (rm)
            switch (l.platform) {
                case "Alexa": props.deploymentAccountsContainer.removeAmazonCredentials(l.id); break;
                case "Google": props.deploymentAccountsContainer.removeGoogleCredentials(l.id); break;
            }
    }
    const { accountToApplicationLinks } = props.deploymentAccountsContainer.state;
    const columns = [
        {
            id: "account",
            Header: <TableHeader text="ACCOUNT" />,
            accessor: (l: ApplicationLinksByPlatform) => l.userName,
            Cell: row => (
                <TableCell>
                    <span className={rowContent}>
                        {row.value}
                    </span>
                </TableCell>
            )
        },
        {
            id: "platform",
            Header: <TableHeader text="PLATFORM" />,
            accessor: (l: ApplicationLinksByPlatform) => l.platform,
            Cell: row => (
                <TableCell>
                    <span className={rowContent}>
                        <img src={platformIconMapping(row.value)}></img>
                        {row.value}
                    </span>
                </TableCell>
            )
        },
        {
            id: "apps",
            Header: <TableHeader text="LINKED APPS" />,
            accessor: (l: ApplicationLinksByPlatform) => l.applicationIds,
            Cell: row => (
                <TableCell>
                    <div className={rowContent}>
                        <ApplicationAvatar size={32} 
                            app={{name: `${row.value.length}`,
                        }} 
                        key={"App"} />
                    </div>
                </TableCell>
            )
        },
        {
            id: "removeCredentials",
            accessor: (l: ApplicationLinksByPlatform) => l,
            Cell: row => (
                <TableCell>
                    <span className={anchorRight}>
                        <Button themes={["small-circle"]} text={"x"} onClick={removeCredentials.bind(this, row.value)} />
                    </span>
                </TableCell>
            )
        },
    ];

    return (
        <div>
            <SortedTable data={accountToApplicationLinks} columns={columns} />
            <PageError errors={props.deploymentAccountsContainer.state.errors} />
        </div>
    )
    
}

export default DeploymentAccountsListView;