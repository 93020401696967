import styled from "@emotion/styled";
import { FormikProps } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Loader from "../../../components/general/Loader";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import { color_shades_dark } from "../../../constants/colors";
import useWindowSize from "../../../hooks/UseWindowSize";
import { InteractionModelFormData } from "./InteractionModelForm";
import EmptyView from '../../featureContent/components/EmptyView';
import MenuItemModel from "../../../models/nlp/menu/MenuItemModel";
import EditedMenuItemModel from "../../../models/nlp/menu/EditedMenuItemModel";
import VoicifyGroups from "./VoicifyGroups";
import VoicifyMenuHeader from "./VoicifyMenuHeader";
import { MenuItemOptionGroup } from "../../../models/nlp/menu/MenuItemOptionGroup";
import VoicifyMenuEditor from "./VoicifyMenuEditor";
import TooltipWrapper from "../../../components/general/TooltipWrapper";
import { Matches } from "./InteractionModelEditorWrapper";
import EditedMenuItemOptionGroupModel from "../../../models/nlp/menu/EditedMenuItemOptionGroupModel";
import EditedMenuItemSizeGroupModel from "../../../models/nlp/menu/EditedMenuItemSizeGroupModel";
import { MenuItemSizeGroup } from "../../../models/nlp/menu/MenuItemSizeGroup";
import { last } from "lodash";

export type GroupType = "optionGroup" | "sizeGroup";

interface VoicifyGroupsEditorProps {
    isLoading: boolean;
    applicationId: string;
    menuItems: MenuItemModel[];
    originalMenuItems: EditedMenuItemModel[];
    setOriginalMenuItems: (originalMenuItem: EditedMenuItemModel[]) => void;
    editableMenuItems: EditedMenuItemModel[];
    setEditableMenuItems: (editableMenuItem: EditedMenuItemModel[]) => void;
    groupsList: EditedMenuItemOptionGroupModel[] | EditedMenuItemSizeGroupModel[];
    matches: Matches[];
    areGroups: boolean;
    selectedGroup: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel;
    setSelectedGroup: (selectedGroup: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => void;
    setGroupsList: Dispatch<SetStateAction<(EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel)[]>>
    performGroupChange: (changedGroup: MenuItemOptionGroup | MenuItemSizeGroup, groupToReplace?: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => void;
    setLatestChangedGroup: Dispatch<SetStateAction<MenuItemOptionGroup | MenuItemSizeGroup>>
    setLatestGroupToReplace: Dispatch<SetStateAction<EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel>>;
    latestChangedGroup: MenuItemOptionGroup | MenuItemSizeGroup;
    latestGroupToReplace: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel;
    groupType: GroupType;
};

const VoicifyGroupsEditor: React.FC<VoicifyGroupsEditorProps & FormikProps<InteractionModelFormData>> = (props) => {
    const [activeItemId, setActiveItemId] = useState<string>();
    const [groupCopy, setGroupCopy] = useState<EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel | null>(null);
    const [showPasteIcon, setShowPasteIcon] = useState<boolean>(false);
    const windowSize = useWindowSize();

    const areOptionGroups = props.groupType === "optionGroup";

    useEffect(() => {
        return () => {
            if (props.latestChangedGroup) {
                props.performGroupChange(props.latestChangedGroup, props.latestGroupToReplace);
            }
        };
    }, []);

    const handleCopyGroup = (e: React.MouseEvent<HTMLElement>, group: EditedMenuItemOptionGroupModel) => {
        e.stopPropagation();
        toggleItemActivation(group);
        setGroupCopy(group);
        setShowPasteIcon(true);
    };

    const handleGroupPaste = (e: React.MouseEvent<HTMLElement>, groupToReplace: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => {
        e.stopPropagation();
        toggleItemActivation(groupToReplace);
        if ('optionGroup' in groupCopy) {
            handleGroupChange(groupCopy.optionGroup, groupToReplace);
        } else if ('sizeGroup' in groupCopy) {
            handleGroupChange(groupCopy.sizeGroup, groupToReplace);
        }
    };

    const toggleItemActivation = (clickedGroup: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => {
        if (props.latestChangedGroup) {
            props.performGroupChange(props.latestChangedGroup, props.latestGroupToReplace);
        }
        setActiveItemId(clickedGroup?.editId);
        props.setSelectedGroup(clickedGroup);
    };

    const handleGroupChange = (changedGroup: MenuItemOptionGroup | MenuItemSizeGroup, groupToReplace?: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => {
        const groupListItemIndex = props.groupsList.findIndex((group) =>
            group.editId === (groupToReplace?.editId ?? props.selectedGroup?.editId))
        const groupListItem = props.groupsList[groupListItemIndex];
        let updatedGroupListItem: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel = {
            ...groupListItem,
            isModified: true,
        };
        let newArray: (EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel)[] = [...props.groupsList];
        if ('options' in changedGroup) {
            (updatedGroupListItem as EditedMenuItemOptionGroupModel).optionGroup = changedGroup as MenuItemOptionGroup;
        } else if ('sizes' in changedGroup) {
            (updatedGroupListItem as EditedMenuItemSizeGroupModel).sizeGroup = changedGroup as MenuItemSizeGroup;
        }
        newArray[groupListItemIndex] = updatedGroupListItem;
        props.setGroupsList(newArray);
        props.setLatestChangedGroup(changedGroup);
        props.setLatestGroupToReplace(groupToReplace);
    };

    const getMenuItemsWithGroup = (group: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel) => {
        const matchingMenuItems = props.matches.find((match) => match[group.editId]);
        if (!matchingMenuItems) {
            return [];
        }
        let matchingMenuItemNames = matchingMenuItems[group.editId]?.map((id) => {
            const matchingMenuItem = props.originalMenuItems?.find((menuItem) => menuItem.menuItem.id === id);
            return matchingMenuItem?.menuItem.originalName ?? null;
        });
        matchingMenuItemNames = [...new Set(matchingMenuItemNames)];
        let allMatchingMenuItemNames = matchingMenuItemNames.join(', ');
        if (matchingMenuItemNames?.length >= 3) {
            const [firstMenuItem, secondMenuItem, thirdMenuItem, ...rest] = matchingMenuItemNames;
            //account for long menu item names by restricting character count to 72
            const totalCharacterLength = firstMenuItem.length + secondMenuItem.length + thirdMenuItem.length;
            if (totalCharacterLength <= 72) {
                return [`${firstMenuItem}, ${secondMenuItem}, ${thirdMenuItem}${rest.length > 0 ? '...' : ''}`, allMatchingMenuItemNames];
            } else {
                return [`${firstMenuItem}${rest.length > 0 ? '...' : ''}`, allMatchingMenuItemNames];
            }
        } else if (matchingMenuItems[group.editId]?.length === 2) {
            const [firstMenuItem, secondMenuItem, ...rest] = matchingMenuItemNames;
            return [`${firstMenuItem}, ${secondMenuItem}${rest.length > 0 ? '...' : ''}`, allMatchingMenuItemNames];
        }
        return [allMatchingMenuItemNames ?? null];
    };

    return (
        <>
            {props.isLoading ?
                <CustomLoader />
                :
                <GroupsWrapper>
                    <CustomScrollbars autoHide autoHeight autoHeightMin={windowSize.windowSize.innerHeight - 210} autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                        <Panel>
                            <VoicifyMenuHeader
                                isLoading={props.isLoading}
                            />
                            {(!props.areGroups && !props.isLoading) &&
                                <EmptyViewPadding>
                                    <EmptyView subtext={`There are currently no menu items with ${areOptionGroups ? 'option groups.' : 'size groups.'}`} />
                                </EmptyViewPadding>
                            }
                            {(props.areGroups && !props.isLoading) &&
                                <VoicifyGroups
                                    getMenuItemsWithGroup={getMenuItemsWithGroup}
                                    activeItemId={activeItemId}
                                    toggleItemActivation={toggleItemActivation}
                                    groups={props.groupsList}
                                    handleCopyGroup={handleCopyGroup}
                                    handleGroupPaste={handleGroupPaste}
                                    groupCopy={groupCopy}
                                    showPasteIcon={showPasteIcon}
                                    groupType={areOptionGroups ? "optionGroup" : "sizeGroup"}
                                />
                            }
                        </Panel>
                    </CustomScrollbars>
                    <BorderLeftCustomScrollbars autoHide autoHeight autoHeightMin={windowSize.windowSize.innerHeight - 210} autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                        <Panel>
                            {
                                props.selectedGroup && (activeItemId != null) ?
                                    <GroupEditorHeader>Edit {areOptionGroups ? 'Option' : 'Size'} Groups for:
                                        {
                                            getMenuItemsWithGroup(props.selectedGroup)?.length > 1 ?
                                                <TooltipWrapper text={getMenuItemsWithGroup(props.selectedGroup)[1]}>
                                                    <ItemsWrapper>{getMenuItemsWithGroup(props.selectedGroup)[0]}</ItemsWrapper>
                                                </TooltipWrapper>
                                                :
                                                <ItemsWrapper>{getMenuItemsWithGroup(props.selectedGroup)[0]}</ItemsWrapper>
                                        }
                                    </GroupEditorHeader>
                                    :
                                    <GroupEditorHeader>Edit {areOptionGroups ? 'Option' : 'Size'} Group</GroupEditorHeader>
                            }
                            <HorizontalSeparator />
                            <GroupEditorWrapper>
                                {props.areGroups && (props.selectedGroup != null) ?
                                    <VoicifyMenuEditor
                                        group={areOptionGroups ?
                                            (props.selectedGroup as EditedMenuItemOptionGroupModel).optionGroup :
                                            (props.selectedGroup as EditedMenuItemSizeGroupModel).sizeGroup
                                        }
                                        handleChange={handleGroupChange}
                                    />
                                    :
                                    <EmptyViewText>
                                        Select {areOptionGroups ? 'an option' : 'a size'} group to see more details and manage values
                                    </EmptyViewText>
                                }
                            </GroupEditorWrapper>
                        </Panel>
                    </BorderLeftCustomScrollbars>
                </GroupsWrapper>
            }
        </>
    );
}

export default VoicifyGroupsEditor;

const ItemsWrapper = styled.div`
    font-size: 12px;  
    margin-left: 8px;
`;

const GroupsWrapper = styled.div`
    height: 100%;
    display: flex;
`;

const Panel = styled.div`
    padding: 16px 32px 16px 32px;
`;

const BorderLeftCustomScrollbars = styled(CustomScrollbars)`
    border-left: 1px solid ${color_shades_dark};
`;

const GroupEditorWrapper = styled.div``;

const GroupEditorHeader = styled.div`
    display: flex;
    align-items: center;
    height: 64px;
`;

const EmptyViewText = styled.div`
    background: white;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    color: ${color_shades_dark};
    padding: 24px;
    margin-top: 24px;
`;

const EmptyViewPadding = styled.div`
    margin-top: 24px;
    width: 100%;
`;

const CustomLoader = styled(Loader)`
    position: relative;
    top: auto;
    margin-top: 30px;
    margin-bottom: 30px;    
`;
