import React, { useState } from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import NestedTree, { TreeItem } from '../../../components/structure/NestedTree/NestedTree';
import ApplicationFeatureModel from '../../../models/features/api/ApplicationFeatureModel';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import { WelcomeMessagesFeatureTypeId, HelpMessagesFeatureTypeId, FallbackFeatureTypeId, ExitMessagesFeatureTypeId, QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, NumberRangeFeatureTypeId, SimpleChoiceFeatureTypeId, CustomRequestsFeatureTypeId } from '../../../constants/featureTypeIds';
import { getEditUrl } from '../../../models/extensions';
import ContentTreeStateContainer from '../../../state/containers/ContentTreeStateContainer';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { color_shades_dark, color_text_default } from '../../../constants/colors';
import _ from 'lodash';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import ApplicationHistoryToolbar from './ApplicationHistoryToolbar';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import PublishHistory from '../../applicationHistoryPanel/components/PublishHistory';
import SaveApplicationBackupModal from '../../applicationHistoryPanel/components/SaveApplicationBackupModal';
import PublishHistoryPanel from '../../applicationHistoryPanel';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
const folderIcon = require('../../../content/images/content-tree-icons/app-level/folder-closed.svg');
const folderOpenIcon = require('../../../content/images/content-tree-icons/app-level/folder-open.svg');
const customRequestIcon = require('../../../content/images/content-tree-icons/app-level/custom.svg');
const exitIcon = require('../../../content/images/content-tree-icons/app-level/exit.svg');
const fallbackIcon = require('../../../content/images/content-tree-icons/app-level/fallback.svg');
const helpIcon = require('../../../content/images/content-tree-icons/app-level/help-info.svg');
const latestMessageIcon = require('../../../content/images/content-tree-icons/app-level/latest-message.svg');
const numberRangeIcon = require('../../../content/images/content-tree-icons/app-level/number-range.svg');
const questionAnswerIcon = require('../../../content/images/content-tree-icons/app-level/question-answer.svg');
const welcomeIcon = require('../../../content/images/content-tree-icons/app-level/welcome.svg');
const simpleChoiceIcon = require('../../../content/images/content-tree-icons/app-level/yes-no.svg');


interface ApplicationContentMenuProps {
    application: ApplicationModel
    appContainer: ApplicationContainer
    featureContainer: FeatureContainer
    treeContainer: ContentTreeStateContainer
    backupsContainer: ApplicationBackupVersionsContainer
    history: any
}
const ApplicationContentTree: React.FC<ApplicationContentMenuProps> = (props) => {
    const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
    const [isCreatingBackup, setIsCreatingBackup] = useState(false);

    const getContentIcon = (featureTypeId: string) => {
        switch (featureTypeId) {
            case WelcomeMessagesFeatureTypeId: return welcomeIcon;
            case HelpMessagesFeatureTypeId: return helpIcon;
            case FallbackFeatureTypeId: return fallbackIcon;
            case ExitMessagesFeatureTypeId: return exitIcon;
            case QuestionAnswerFeatureTypeId: return questionAnswerIcon;
            case LatestMessagesFeatureTypeId: return latestMessageIcon;
             case NumberRangeFeatureTypeId: return numberRangeIcon;
            case SimpleChoiceFeatureTypeId: return simpleChoiceIcon;
            case CustomRequestsFeatureTypeId: return customRequestIcon;
        }

        return '';
    }
    const handleTreeItemClick = (item: TreeItem) => {
        if (item.type == "feature") {
            const expandedIds = props.treeContainer.state.expandedIds;
            if (expandedIds.some(id => id == item.id)) {
                expandedIds.splice(expandedIds.indexOf(item.id), 1);
            }
            else {
                expandedIds.push(item.id);
                props.featureContainer.getContentItemsForAppFeature(item.id);
            }
            props.treeContainer.setExpandedIds(expandedIds);
        }
    }
    const mapFeatureToTreeItem = (applicationFeature: ApplicationFeatureModel): TreeItem => {
        return {
            id: applicationFeature?.id,
            type: "feature",
            isHighlighted: props.treeContainer.state.selectedId == applicationFeature?.id,
            isExpanded: applicationFeature.parentId == null || props.treeContainer.state.expandedIds?.some(id => applicationFeature?.id == id),
            isLoadingChildren: props.featureContainer.state.loadingAppFeatureIds.some(s => s === applicationFeature?.id),
            icon: folderIcon,
            expandedIcon: folderOpenIcon,
            title: applicationFeature?.name ?? applicationFeature?.feature?.name ?? "Untitled",
            canExpand: true,
            linkTo: `/v/apps/${props.application.id}/content/${applicationFeature?.id}`,
            children: [...props.featureContainer.getFeaturesForParent(applicationFeature?.id)?.map(af => mapFeatureToTreeItem(af)),
            ..._.orderBy(props.featureContainer.getContentByFeatureId(applicationFeature?.id), item => {item.priority, item.createdDate}, 'asc')?.map(c => mapContentToTreeItem(c))]
        }
    }
    const mapContentToTreeItem = (contentItem: GenericContentItemModel): TreeItem => {
        return {
            id: contentItem.id,
            type: "content",
            title: contentItem.title ?? "Unknown",
            icon: getContentIcon(contentItem.featureTypeId),
            isExpanded: false,
            isLoadingChildren: false,
            canExpand: false,
            children: [],
            linkTo: getEditUrl(contentItem.id, contentItem.applicationFeatureId, contentItem.featureTypeId)
        }
    }
    // default select the top feature
    const rootFeature = props.featureContainer.getRootFeature(props.application?.id);
    if (!rootFeature) return null;
    return (
        <div className={containerStyle}>
            <ApplicationHistoryToolbar onSaveBackup={() => setIsCreatingBackup(true)}
                onToggle={() => setIsHistoryExpanded(!isHistoryExpanded)}
                isExpanded={isHistoryExpanded}
                isLoading={props.backupsContainer.state.isCreating} />
            {isHistoryExpanded ?
                <CustomScrollbars autoHide className="tree-scroll">
                    <div className="tree-container">
                        <PublishHistoryPanel applicationId={props.application.id} onClose={() => setIsHistoryExpanded(false)}/>
                    </div>
                </CustomScrollbars>
                :
                <CustomScrollbars autoHide className="tree-scroll">
                    <div className="tree-container">
                        {rootFeature != null &&
                            <NestedTree
                                isRootHidden={true}
                                key={rootFeature?.id ?? 'r'}
                                root={mapFeatureToTreeItem(rootFeature)}
                                onItemClick={handleTreeItemClick} />
                        }
                    </div>
                </CustomScrollbars>}

            {isCreatingBackup && <SaveApplicationBackupModal applicationId={props.application.id} onClose={() => setIsCreatingBackup(false)} backupsContainer={props.backupsContainer} />}
        </div>
    )
}

const containerStyle = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    .selector-section-header {
        align-items: center;
        padding: 0px 16px 0 24px;
        background: white;
        height: 56px;
        min-height: 56px;
        max-height: 56px;
        border-bottom: 1px solid ${color_shades_dark};
        p {
            font-size: 18px;
            line-height: 24px;
            padding-top: 16px;
            color: ${color_text_default};
        }
    }
    .tree-container {
        padding-bottom: 16px;
        max-height: 100%;
        flex: 1;
    } 
`

export default ApplicationContentTree;