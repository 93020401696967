import React from 'react';
import { css } from '@emotion/css';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import { Link } from 'react-router-dom';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import { color_shades_dark, color_shades_darker } from '../../../constants/colors';
interface TemplateListItemProps {
    templateConfiguration: TemplateConfigurationModel,
    applicationId: string,
    applied: boolean,
    appliedAppTemplate?: AppliedApplicationTemplateModel
    icon: string
    status: string
}
class TemplateListItem extends React.Component<TemplateListItemProps> {

    render() {
        const props = {...this.props};
        const link = props.applied ? `/v/apps/${this.props.applicationId}/templates/${props.appliedAppTemplate.id}` : `/v/apps/${props.applicationId}/templates/new/${props.templateConfiguration.id}`
        return (
          <Link to={link} className={containerStyle}>     
                <div className="image-container">
                    <img src={props.templateConfiguration.imageUrl} />
                </div>         
                <h3>{props.templateConfiguration.name}</h3>
                <p className={`${props.applied ? '' : 'italic'} right` }>{props.status}</p>
                <img className="end-icon" src={props.icon}/>
          </Link>
        )
    }
}

const containerStyle = css`
    margin: 16px 32px;
    border-radius: 8px;
    background: white;
    border: 1px solid ${color_shades_dark};
    display: flex;
    flex-direction: row;
    align-items: center;
    .image-container {    
        padding: 8px;    
        border-right: 1px solid ${color_shades_dark};
        width: 160px;
        position: relative;
        height: 88px;
        img {
            max-width: 144;
            max-height: 72px;
            margin: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    p {
        color: ${color_shades_darker};
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        &.italic {
            font-style: italic;
        }
        &.right {
            margin-left: auto;
            margin-right: 8px;
        }
    }
    .end-icon {
        margin-right: 32px;
        width:24px;
        height: 24px;
    }
`

export default TemplateListItem;