import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import { css } from '@emotion/css';
import SelectField from '../../../components/forms/SelectField';
import Button from '../../../components/general/Button';
import ModalTitle from '../../../components/general/ModalTitle';
import FooterBar from '../../../components/structure/FooterBar';
import { style_border_default } from '../../../constants/stylesValues';
import PageError from '../../../components/general/PageError';
import _ from 'lodash';
import EffectModel from '../../../models/features/api/Effects/EffectModel';
import TextField from '../../../components/forms/TextField';
import Switch from '../../../components/forms/Switch';
import ModalLayout from '../../../components/structure/ModalLayout';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import useWindowSize from '../../../hooks/UseWindowSize';
import { defaultProps } from '@nivo/pie';
const closeIcon = require('../../../content/images/close_icon.svg');
const effectIcon = require('../../../content/images/content-item-icons/effect.svg');

const effectTypeOptions = [{ label: 'Custom', value: 'custom' },
{ label: 'Navigate(Web)', value: 'navigateWeb' },
{ label: 'Navigate(Mobile)', value: 'navigateMobile' },
{ label: 'Click/Tap', value: 'clickTap' },
{ label: 'Scroll to', value: 'scrollTo' },
    { label: 'Close Assistant', value: 'closeAssistant' }];
export const getEffectLabel = (val: string) => {
    var option = effectTypeOptions.find(e => e.value === val);
    if (option)
        return option.label;
    return val;
}

interface EffectManagerProps {
    onFinish: (effect: EffectModel) => void
    onCancel: () => VoidFunction,
    editingEffect?: EffectModel
}
type EffectType = "custom" | "navigateWeb" | "navigateMobile" | "clickTap" | "scrollTo" | "closeAssistant";
const EffectManager: React.FC<EffectManagerProps> = ({
    onFinish,
    onCancel,
    editingEffect
}) => {
    const [name, setName] = useState("");
    const [effectType, setEffectType] = useState<EffectType>(null);
    const [error, setError] = useState(null as string);
    const [effectData, setEffectData] = useState<{[key: string]: string}[]>([])
    const [url, setUrl] = useState("");
    const [pageName, setPageName] = useState("");
    const [elementId, setElementId] = useState("");
    const [elementName, setElementName] = useState("");
    const [querySelector, setQuerySelector] = useState("");
    const [openInNewTab, setOpenInNewTab] = useState(false);
    const [fireBeforeTextToSpeech, setFireBeforeTextToSpeech] = useState(false);
    const [fireAfterMilliseconds, setFireAfterMilliseconds] = useState(0);

    const windowSize = useWindowSize();

    useEffect(() => {
        if (editingEffect) {
            setName(editingEffect.name);
            const data = editingEffect.data
            if (data) {
                if ("fireAfterMilliseconds" in data)
                    setFireAfterMilliseconds(data.fireAfterMilliseconds);
                if ("fireBeforeTextToSpeech" in data)
                    setFireBeforeTextToSpeech(data.fireBeforeTextToSpeech);
                switch (editingEffect.name) {
                    case ("navigateWeb"):
                        setEffectType("navigateWeb");
                        if ("url" in data)
                            setUrl(data.url);
                        if ("openInNewTab" in data)
                            setOpenInNewTab(data.openInNewTab);
                        break;

                    case ("navigateMobile"):
                        setEffectType("navigateMobile");
                        if ("url" in data)
                            setUrl(data.url);
                        if ("pageName" in data)
                            setPageName(data.pageName);
                        if ("openInNewTab" in data)
                            setOpenInNewTab(data.openInNewTab);
                        break;

                    case ("clickTap"):
                        setEffectType("clickTap");
                        if ("elementId" in data)
                            setElementId(data.elementId);
                        if ("elementName" in data)
                            setElementName(data.elementName);
                        if ("querySelector" in data)
                            setQuerySelector(data.querySelector);
                        break;
                    case ("scrollTo"):
                        setEffectType("scrollTo");
                        if ("elementId" in data)
                            setElementId(data.elementId);
                        if ("elementName" in data)
                            setElementName(data.elementName);
                        if ("querySelector" in data)
                            setQuerySelector(data.querySelector);
                        break;

                    case ("closeAssistant"):
                        setEffectType("scrollTo");
                        break;

                    default:
                        setEffectType("custom");
                        const keys = Object.keys(data);
                        const newData = [];
                        if (keys?.length) {
                            keys.forEach((k, i) => {
                                newData.push({ [k]: data[k] })
                            })
                        }
                        setEffectData(newData);
                }                
            }
        }
    }, []);
    const handleFinish = () => {
        let newEffectData: any = {};
        if (effectType === "custom") {
            effectData.forEach(d => {
                const keys = Object.keys(d);
                if (keys?.length) {
                    newEffectData[keys[0]] = d[keys[0]];
                }
            })            
        }
        else {
            newEffectData.fireAfterMilliseconds = fireAfterMilliseconds;
            newEffectData.fireBeforeTextToSpeech = fireBeforeTextToSpeech;
            switch (effectType) {
                case ("navigateWeb"):
                    newEffectData.url = url;
                    newEffectData.openInNewTab = openInNewTab;
                    break;

                case ("navigateMobile"):
                    newEffectData.url = url;
                    newEffectData.pageName = pageName;
                    newEffectData.openInNewTab = openInNewTab;
                    break;

                case ("clickTap"):
                case ("scrollTo"):
                    newEffectData.elementId = elementId;
                    newEffectData.elementName = elementName;
                    newEffectData.querySelector = querySelector;
                    break;
            } 
        }
        
        const effect: EffectModel = {
            id: editingEffect?.id,
            name: name,
            data: newEffectData,
            priority: editingEffect?.priority
        }
        onFinish(effect);
    }

    const clearFields = () => {
        setUrl("");
        setOpenInNewTab(false);
        setFireBeforeTextToSpeech(false);
        setPageName("");
        setElementId("");
        setElementName("");
        setQuerySelector("");
        setEffectData([{"": ""}]);
    }

    const handleEffectTypeChange = (effectTypeVal: EffectType) => {
        setEffectType(effectTypeVal);
        switch (effectTypeVal) {
            case ("custom"):
                setName("");
                break;
            default:
                setName(effectTypeVal)
        }
        clearFields();
    }
    const handleKey = (e: React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();

        if (e.key === "Enter") {
            handleFinish();
        }
        if (e.key === "Escape") {
            onCancel();
        }
    };
    const renderCustomFields = () => {
        return <>
            {effectData?.length && 
                effectData.map(
                    (o, i) => {
                        const key = Object.keys(o)[0];
                        const val = o[key];
                        return <CustomDataProperty key={`effectdatakey${i}`}>
                            <KeyField
                                name={`key${i}`}
                                placeholder="Enter key"
                                label="Key"
                                value={key}
                                onChange={e => updateEffectData(i, e.target.value, val)}
                            />
                            <ValueField
                                name={`value${i}`}
                                placeholder="Enter value"
                                label="Value"
                                value={val}
                                onChange={e => updateEffectData(i, key, e.target.value)}
                            />
                        </CustomDataProperty>
                    }
                )
            }
            <Button type="button" themes={["secondary-medium"]} text="Add a Data Property" onClick={() => addDataProperty(Object.entries(effectData)?.length ?? 1)} />
        </>
    }
    const updateEffectData = (index: number, key: string, val: string) => {
        const newEffectData = [ ...effectData ];
        newEffectData[index] = {[key]: val};
        setEffectData(newEffectData);
    }
    const addDataProperty = (i) => {
        const newEffectData = [...effectData];
        newEffectData.push({"": ""})
        setEffectData(newEffectData);
    }
    const renderUrl = () => {
        return <TextField
            name="url"
            placeholder="Enter URL"
            label="URL"
            value={url}
            onChange={e => setUrl(e.target.value)}
        />
    }
    const renderPageName = () => {
        return <TextField
            name="pageName"
            placeholder="Enter page name"
            label="Page Name"
            value={pageName}
            onChange={e => setPageName(e.target.value)}
        />
    }
    const renderElementId = () => {
        return <TextField
            name="elementId"
            placeholder="Specify element ID"
            label="Element ID"
            value={elementId}
            onChange={e => setElementId(e.target.value)}
        />
    }
    const renderElementName = () => {
        return <TextField
            name="elementName"
            placeholder="Specify element name"
            label="Element name"
            value={elementName}
            onChange={e => setElementName(e.target.value)}
        />
    }
    const renderQuerySelector = () => {
        return <TextField
            name="querySelector"
            placeholder="Specify query selector"
            label="Query selector"
            value={querySelector}
            onChange={e => setQuerySelector(e.target.value)}
        />
    }
    const renderOpenInNewTab = () => {
        return <StyledSwitch
            checked={openInNewTab}
            onChange={() => setOpenInNewTab(!openInNewTab)}
            label="Open in new tab" />
    }
    const renderFireBeforeTextToSpeech = () => {
        return <StyledSwitch
            checked={fireBeforeTextToSpeech}
            onChange={() => setFireBeforeTextToSpeech(!fireBeforeTextToSpeech)}
            label="Fire before text to speech" />
    }
    const renderFireAfterMilliseconds = () => {
        return <TextField
            name="fireAfterMilliseconds"
            placeholder="Enter number of milliseconds"
            label="Fire effect after specified milliseconds"
            value={fireAfterMilliseconds}
            type="number"
            onChange={e => setFireAfterMilliseconds(e.target.value)}
        />
    }
    const renderNavigateWebFields = () => {
        return <>
            {renderUrl()}
            {renderOpenInNewTab()}
            {renderFireBeforeTextToSpeech()}
            {renderFireAfterMilliseconds()}
        </>
    }
    const renderNavigateMobileFields = () => {
        return <>
            {renderUrl()}
            {renderPageName()}
            {renderOpenInNewTab()}
            {renderFireBeforeTextToSpeech()}
            {renderFireAfterMilliseconds()}
        </>
    }
    const renderClickTapFields = () => {
        return <>
            {renderElementId()}
            {renderElementName()}
            {renderQuerySelector()}
            {renderFireBeforeTextToSpeech()}
            {renderFireAfterMilliseconds()}
        </>
    }
    const renderScrollToFields = () => {
        return <>
            {renderElementId()}
            {renderElementName()}
            {renderQuerySelector()}
            {renderFireBeforeTextToSpeech()}
            {renderFireAfterMilliseconds()}
        </>
    }
    const renderCloseAssistantFields = () => {
        return <>
            {renderFireBeforeTextToSpeech()}
            {renderFireAfterMilliseconds()}
        </>
    }
    const renderEffectFields = () => {
        switch (effectType) {
            case ("navigateWeb"):
                return renderNavigateWebFields();
            case ("navigateMobile"):
                return renderNavigateMobileFields();
            case ("clickTap"):
                return renderClickTapFields();
            case ("scrollTo"):
                return renderScrollToFields();
            case ("closeAssistant"):
                return renderCloseAssistantFields();
            case ("custom"):
                return renderCustomFields();
            default:
                return <></>;
        }
    }
    return (
        <StyledModal className={overflowVisibleStyle} isVisible onClose={onCancel}>
            <EffectManagerBody onKeyPress={e => handleKey(e)} onKeyDown={e => handleKey(e)} onKeyUp={e => handleKey(e)} >
                <HorizontalContainer className={titleContainer}>
                    <img src={effectIcon} />
                    <ModalTitle>Effect</ModalTitle>
                    <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={onCancel} />
                </HorizontalContainer>
                <CustomScrollbars
                    autoHide
                    autoHeight
                    autoHeightMin={(windowSize.windowSize.innerHeight * .9) - 190}
                    autoHeightMax={(windowSize.windowSize.innerHeight * .9) - 290}>
                    <FormWrapper >
                        <SelectField name="flags"
                            label="Select an Effect"
                            value={effectType}
                            options={effectTypeOptions}
                            onChange={(e: any) => handleEffectTypeChange(e.value)}
                            onBlur={() => { }}
                            className={valueSelectStyle}
                        />
                        <TextField
                            name="effectName"
                            placeholder="Enter custom effect name"
                            disabled={effectType !== "custom"}
                            label="Effect Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        {renderEffectFields()}
                    </FormWrapper>
                </CustomScrollbars>                
                <FooterBar className={footerStyle}>
                    <Button themes={["primary", "start-tight"]} text="Save" type="button" onClick={() => handleFinish()} />
                    <Button themes={["white", "end-tight"]} text="Cancel" type="button" onClick={onCancel} />
                </FooterBar>
                <PageError errors={error ? [error] : []} onClear={() => setError(null)} />
            </EffectManagerBody>
        </StyledModal>
    )
};
const StyledModal = styled(ModalLayout)`
    min-width: 30%;
    min-height: 30%;

`;
const FormWrapper = styled.div`
    margin: 32px;
`;
const EffectManagerBody = styled.div(`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 580px;
    overflow: visible;
`)
const titleContainer = css`
    background: white;
    padding: 0 32px;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: ${style_border_default};
    img {
        margin-right: 16px;
    }
`
const footerStyle = css`
    position: fixed;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0 32px;
`;


const valueSelectStyle = css`    
    .Select-menu-outer {
        z-index: 111;
        overflow: hidden;
    }
`;
const StyledSwitch = styled(Switch)`
    margin: 16px;
`;

const overflowVisibleStyle = css`
    overflow: visible;
    min-height: 624px;
`;

const CustomDataProperty = styled.div`
    display: flex;
    flex-direction: row;
`;

const KeyField = styled(TextField)`
    margin-right: 8px;
`;

const ValueField = styled(TextField)`
    margin-left: 8px;
`;

export default EffectManager;