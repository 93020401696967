import React from 'react';
import { css } from '@emotion/css';
import MediaItemModel from '../../../models/media/MediaItemModel';
import { cool_grey, dark_grey_blue } from '../../../constants/colors';
import MediaItemChip from '../../../components/general/MediaItemChip';
import PageTitle from '../../../components/general/PageTitle';
const attachmentIcon = require('../../../content/images/attachment-black.svg');
interface AttachedMediaContainerProps {
    mediaItems: MediaItemModel[]
    customUsedItemIds: string[]
    onItemRemoved: (mediaItem: MediaItemModel) => void
}


const containerStyle = css`
    
    border-radius: 10px 10px 0 0;
    border: 1px solid ${cool_grey};
    border-bottom: 0;
    padding: 32px;

    .section-label {
        font-family: Muli;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 16px;
        display: flex;
        flex-align: center;

        img {
            margin-right: 4px;
        }
    }
`
export const AttachedMediaContainer: React.SFC<AttachedMediaContainerProps> = (props) => (
    <div className={containerStyle}>
        <p className="section-label">
            <img src={attachmentIcon} />
            <span>Attached Files</span>
        </p>
        {props.mediaItems.map((item, i) => (
            <MediaItemChip deleteDisabled={props.customUsedItemIds.some(i => i == item.id)} 
             deleteTooltip={props.customUsedItemIds.some(i => i==item.id) ? "This item cannot be removed because it is being used by a customized response." : null}
             fieldName="attachedItem" 
             mediaItem={item} 
             key={i} 
             canDelete
             onDelete={(item) => props.onItemRemoved(item)} />
        ))}
    </div>
);

