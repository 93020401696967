import React from 'react'
import { Prompt } from 'react-router-dom'
import { FormikConsumer } from 'formik'


export interface DirtyFormPromptProps {
    allowPrompt?: boolean
    promptOverride?: (location: Location) => string | boolean;
}
export const DirtyFormPrompt: React.FC<DirtyFormPromptProps> = (props) => {
    const prompt = (location: Location): string | boolean => {
        if (location?.hash)
            return true;
        return "You have unsaved changes. Are you sure you want to leave?"
    }
    return (
        <FormikConsumer>
          {formik => <Prompt when={formik.dirty && props.allowPrompt == true} message={(l) => props.promptOverride ? props.promptOverride(l) : prompt(l)} /> }
        </FormikConsumer>
    )
}