import React from 'react';
import { css } from '@emotion/css';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { color_shades_dark, color_text_default, color_text_link, dark_grey_blue_10 } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
const addIcon = require('../../../content/images/add-circle-dark.svg');
interface AddResponseSectionProps {
    titleRightRowComponent?: React.ReactNode
    onAddNew: () => void
    onCloneNew: () => void
}
interface AddResponseSectionState {
    isOptionsExpanded: boolean
}
class AddResponseSection extends React.Component<AddResponseSectionProps, AddResponseSectionState> {
    constructor(props) {
        super(props)
        this.state = {
            isOptionsExpanded: false
        }
    }
    documentCloser = this.handleDocumentClick.bind(this)
    componentDidMount() {
        document.addEventListener("click", this.documentCloser, true);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.documentCloser, true);
    }
    handleDocumentClick(e: any) {
        if (e.target.closest(".card-title-container") || e.target.closest(".add-options")) return;
        this.setState({
            ...this.state,
            isOptionsExpanded: false
        })
    }
    toggleOptions() {
        this.setState({
            ...this.state,
            isOptionsExpanded: !this.state.isOptionsExpanded
        })
    }
    render() {
        const { titleRightRowComponent } = this.props;
        return (
            <div className={`${cardStyle} ac-add-response-group`} id='addConditionalResponse'>
                <div className='card-title-container' onClick={this.toggleOptions.bind(this)}>
                    <img src={addIcon} />
                    <h4>Add a Conditional Response</h4>
                    <ToggleIndicator direction={this.state.isOptionsExpanded ? 'up' : 'down'} className={toggleIndicatorStyle} />
                    {
                        titleRightRowComponent
                    }
                </div>
                {this.state.isOptionsExpanded && <div className="add-options">
                    <button type="button" onClick={this.props.onCloneNew}>Duplicate the Default Response</button>
                    <button type="button" onClick={this.props.onAddNew}>Start a New Blank Response</button>
                    <div className="triangle" />
                </div>}
            </div>
        );
    }
}

const cardStyle = css`
    border-top: solid 1px ${color_shades_dark};
    flex-grow: 0;
    position: relative;

    .card-title-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: solid 1px ${color_shades_dark};
        padding: 24px 24px 24px 0;
        cursor: pointer;
        background: white;
        >img {
            width: 24px;
            height: 24px;
            margin-left: 32px;
        }
        >h4 {
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: ${color_text_default};
            margin: 0 10px;
        }

        .toggle-indicator {
            margin: 0 4px;
        }
    }
    .card-body {
        padding: 32px;
        &.collapsed {
            padding: 0;
            display: none;
        }
    }

    .add-options {
        position: absolute;
        margin-top: -172px;
        margin-left: 124px;
        border: ${style_border_default};
        border-radius: 8px;
        background: white;
        box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
        display: flex;
        flex-direction: column;
        button {
            border: none;
            background: none;
            box-shadow: none;
            color: ${color_text_link};
            height: 48px;
            padding: 0 16px;
            text-align: left;
            font-family: 'Muli';
            font-size: 14px;
            cursor: pointer;
            &:first-child {
                border-bottom: ${style_border_default};
            }
        }


        .triangle {
        position: absolute;
        bottom: -5px;
        right: 14px;
        width: 10px;
        height: 10px;
        border-bottom: ${style_border_default};
        border-right: ${style_border_default};
        transform: rotate(45deg);
        background-color: white;
    }

    }
`;

const toggleIndicatorStyle = css`
    height: 8px;
    width: 8px;
`


export default AddResponseSection;