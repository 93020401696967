import React from 'react';
import { ArrowRendererProps, MenuRendererProps } from 'react-select';
import styled from '@emotion/styled';
import ToggleIndicator from '../../../../components/general/ToggleIndicator';
import { color_colors_ocean, color_shades_dark, color_shades_darker, color_shades_darkest, color_variants_ocean_xlight } from '../../../../constants/colors';
import DropDownSelectField from '../../../../components/forms/DropDownSelectField';
import IconTooltip from '../../../../components/general/IconTooltip';
import { css } from '@emotion/css';
import ErrorLabel from '../../../../components/general/ErrorLabel';

const staticIcon = require('../../../../content/images/custom-assistant-deployment/landmarks-statue-flag.svg');
const dynamicIcon = require('../../../../content/images/custom-assistant-deployment/trekking-mountain.svg');
const staticIconSelected = require('../../../../content/images/custom-assistant-deployment/landmarks-statue-flag-active.svg');
const dynamicIconSelected = require('../../../../content/images/custom-assistant-deployment/trekking-mountain-active.svg');
const alertIcon = require('../../../../content/images/alert-circle.svg');

export type CodeExportType = "static" | "dynamic";

interface CustomAssistantExportTypeDropdownProps {
    exportType?: string
    handleExportTypeChange?: (optionValue: string, value: CodeExportType) => void
    hasPlatformConfigurations?: boolean
};

const options = [
    { value: "dynamic" as CodeExportType, label: "Dynamic", image: dynamicIcon, description: "Paste the snippet into your codebase once and your configuration will dynamically update as you publish changes in Voicify", selectedImage: dynamicIconSelected },
    { value: "static" as CodeExportType, label: "Static", image: staticIcon, description: "Paste the snippet into your codebase every time you update your configuration in Voicify", selectedImage: staticIconSelected }
];

const formatValueLabel = ({ label, image }) => (
    <CustomSelectChosenOption>
        <img src={image}></img>
        <p>{label}</p>
    </CustomSelectChosenOption>
);

const CustomAssistantExportTypeDropdown = (props: CustomAssistantExportTypeDropdownProps) => {

    return (
        <>
            <CustomAssistantExportTypeDropdownWrapper>
                {
                    props.hasPlatformConfigurations ?
                        <IconTooltipWrapper>
                            <DropdownTitle className="user-entry">Code Export Type</DropdownTitle>
                            <IconTooltip
                                text="- Static adds a layer of protection around updates </br> - Dynamic enables real-time updates"
                                place="right"
                                className={iconTooltipStyle}
                            />
                        </IconTooltipWrapper>
                        :
                        <DropdownTitleWrapper>
                            <IconTooltipWrapper>
                                <DropdownTitle className="user-entry">Code Export Type</DropdownTitle>
                                <IconTooltip
                                    text="- Static adds a layer of protection around updates </br> - Dynamic enables real-time updates"
                                    place="right"
                                    className={iconTooltipStyle}
                                />
                            </IconTooltipWrapper>
                            <ErrorLabel
                                image={alertIcon}
                                text={"Hit the 'Save & Close' button and reopen this configuration to access your dynamic code snippet."}
                                className={errorLabelStyle}
                            />
                        </DropdownTitleWrapper>
                }
                <CustomDropDownSelect
                    className="ac-access"
                    clearable={false}
                    searchable={false}
                    valueRenderer={formatValueLabel}
                    disabled={props.hasPlatformConfigurations ? false : true}
                    options={options}
                    onChange={(e: any) => {
                        props.handleExportTypeChange(props.exportType, e.value)
                    }}
                    value={props.exportType}
                    key="user-entry-selector"
                    arrowRenderer={(p: ArrowRendererProps) => <Arrow direction={p.isOpen ? 'up' : 'down'} />}
                    menuRenderer={(menuProps: MenuRendererProps) => {
                        return (<MenuOptionsWrapper>
                            <Triangle />
                            {options.map((option, index) =>
                                <SelectOption key={index} className="user-entry-option" onClick={() => menuProps.selectValue(option)}>
                                    <CustomSelectOptionLabel>
                                        {option.image ? <ExportTypeIcon src={menuProps.valueArray[0].value == option.value ? option.selectedImage : option.image}></ExportTypeIcon> : null}
                                        <div>
                                            <p className={menuProps.valueArray[0].value == option.value ? "option-selected" : ""}>{option.label}</p>
                                            <p className={menuProps.valueArray[0].value == option.value ? "option-description-selected" : "option-description"}>{option.description}</p>
                                        </div>
                                    </CustomSelectOptionLabel>
                                    {index != menuProps.options.length - 1 && <Divider />}
                                </SelectOption>
                            )}
                        </MenuOptionsWrapper>
                        )
                    }}
                />
            </CustomAssistantExportTypeDropdownWrapper>
        </>
    )

};

const errorLabelStyle = css`
    height: auto;  
`;

const DropdownTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const iconTooltipStyle = css`
    margin-left: 16px;
`;

const IconTooltipWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const DropdownTitle = styled.p`
`;

const Arrow = styled(ToggleIndicator)`
    height: 8px;
    width: 8px;
`;

const ExportTypeIcon = styled.img`
    height: 24px;
    width: 24px;
    margin-top: 4px;
`
const SelectOption = styled.div`
    &.user-entry-option:hover{
        background-color: ${color_variants_ocean_xlight};
        cursor: pointer;
    }
`;
const MenuOptionsWrapper = styled.div`
    margin-top: 8px;
`;

const CustomSelectChosenOption = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    p {
        color: ${color_shades_darkest};
        margin-left: 10px;   
    }
    img {
        height: 24px;
        width: 24px;
        margin-top: 4px;
    }
`;

const Divider = styled.div`
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${color_shades_dark};
    padding: 0;
`;

const CustomSelectOptionLabel = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    div{
        line-height: normal;
        margin-left:10px; 
        color: ${color_shades_darkest};
    }
    .option-selected{
        color: ${color_colors_ocean};
        font-size: 14px;
    }
    .option-description{
        font-size: 10px;
        color: ${color_shades_darker};
        margin-top: 4px; 
    }
    .option-description-selected{
        font-size: 10px;
        color: ${color_colors_ocean};
        margin-top: 4px;  
    }
`;

const Triangle = styled.div`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    border-top: solid 1px ${color_shades_dark};
    border-left: solid 1px ${color_shades_dark};
    background: white;
    margin-top: -7px;
    right: 15px;
    position: absolute;
`;

const CustomAssistantExportTypeDropdownWrapper = styled.div`
    margin-bottom: 16px;
    .user-entry{
        margin-left: 16px;
        font-size: 14px;
        color: ${color_shades_darkest};
    }
`
const CustomDropDownSelect = styled(DropDownSelectField)`
    margin-top: 4px;
    .Select-multi-value-wrapper{
        height: 48px;
    }
    .Select-menu-outer{
        border-top-color: ${color_shades_dark};
    }
    .Select-value{
        margin-top: 8px;
    }
    .Select-control {
        border-radius: 4px;
        font-size: 14px;
    }
    &.is-open  {
        .Select-control {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-color: ${color_shades_dark};
        }
    }
`
export default CustomAssistantExportTypeDropdown;