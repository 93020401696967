import moment from 'moment'
import ContentItemModel from '../features/api/ContentItemModel';
import LanguageModel from "../features/api/LanguageModel";
import ConversationAccessType from '../features/ConversationAccessType';

type TitledItem = {
    title: string
    isLive: boolean,
    languages?: LanguageModel[]
}

export const contentItemTitleSort = (a: TitledItem, b: TitledItem) => {
    return a.title.localeCompare(b.title) as 0 | 1 | -1;
}
export const contentItemLanguagesSort = (a: TitledItem, b: TitledItem) => {
    if (a.languages.length == 0 && b.languages.length == 0) return 0;
    if (a.languages.length == 0) return 1;
    if (b.languages.length == 0) return -1;
    var aLang = a.languages[0].languageGroupCode;
    var bLang = b.languages[0].languageGroupCode;

    return aLang.localeCompare(bLang) as 0 | 1 | -1;
}

export const contentItemLiveSort = (a: TitledItem, b: TitledItem) => {
    if (a.isLive == b.isLive) return 0;
    if (a.isLive) return 1;
    if (b.isLive) return -1;
}

export const contentItemDateSort = (a: string, b: string) => {
    var aDate = moment(a).format("YYYYMMDD");
    var bDate = moment(b).format("YYYYMMDD");

    return aDate.localeCompare(bDate) as 0 | 1 | -1;
}

export const getContentItemAccessType: (c) => ConversationAccessType = (contentItem: ContentItemModel) => {
    return getAccessType(contentItem.isLive, contentItem.requiresParent, false)
}

export const getAccessType: (isLive, requiresParent, alwaysOpen) => ConversationAccessType
    = (isLive: boolean, requiresParent: boolean, alwaysOpen: boolean) => {
        if (alwaysOpen) {
            return "alwaysOpen"
        }
        if (isLive && !requiresParent) {
            return "open"
        }
        else if (isLive && requiresParent) {
            return "targeted"
        }
        else {
            return "closed"
        }
    }