import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue_10, silver_three, color_text_default, silver_four } from '../../constants/colors';
import ApplicationModel from '../../models/applications/api/ApplicationModel';

interface ApplicationAvatarProps {
    app: ApplicationModel
    size: number
    className?: string
}

const iconStyle = css`
    border-radius: 50%;
    background: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`
const containerStyle = css`
    border-radius: 50%;    
    box-shadow: 0 2px 4px 0 ${dark_grey_blue_10};
    border: solid 1px ${silver_four};
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`
const nameStyle = css`
border-radius: 50%;
background: white;
font-family: Muli;
font-size: 18px;
font-weight: 600;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: -1px;
text-align: center;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
color: ${color_text_default};
`
const ApplicationAvatar: React.SFC<ApplicationAvatarProps> = (props) => (
    <div className={`${containerStyle}  ${props.className !== undefined ? props.className : ''}`} style={{width: props.size, height: props.size, minWidth: props.size, minHeight: props.size}}>
        {props.app?.imageUrl !== undefined ? 
            <img className={`${iconStyle}`} src={props.app?.imageUrl}/> :
            <div className={nameStyle}>
                <span>{props.app?.name[0]}</span>
            </div>
        }
    </div>
)

export default ApplicationAvatar;

