import React from 'react';
import HeaderBar from '../structure/HeaderBar';
import Logo from '../../scenes/dashboardHeader/components/Logo';
import Loader from './Loader';

const LoaderWithHeader: React.FC = (props) => (
    <>
        <HeaderBar>
            <Logo isFull isInverse />
        </HeaderBar>
        <Loader />
    </>
);


export default LoaderWithHeader;