import React from 'react';
import {css} from '@emotion/css';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import { color_variants_ocean_light_1, color_text_light, color_text_default } from '../../../constants/colors';
import { Link } from 'react-router-dom';
const templatesIcon = require('../../../content/images/templates-icons/templates-sync-dark.svg');

interface ActiveTemplateSelectorProps {
    appliedTemplates: AppliedApplicationTemplateModel[]
    activeAppliedTemplate: AppliedApplicationTemplateModel
}

interface ActiveTemplateSelectorState {
    isExpanded: boolean
}

class ActiveTemplateSelector extends React.Component<ActiveTemplateSelectorProps, ActiveTemplateSelectorState> {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }

    toggleExpand() {
        this.setState({
            ...this.state,
            isExpanded: !this.state.isExpanded
        })
    }
    render() {
        return (
            <div className={containerStyle}>
                <p className="selector-label">{(!this.props.appliedTemplates || this.props.appliedTemplates.length == 0) ? "No templates were used in this app" : "Enabled Templates"}</p>
                {this.props.appliedTemplates.map(t => (
                    <Link key={t.id} className={`template-item ${t.id == this.props.activeAppliedTemplate?.id ? 'active' : ''}`} to={`/v/apps/${t?.appliedApplicationId}/templates/${t?.id}`}>
                        <img src={templatesIcon}/>
                        <p>{t.templateForm.templateConfiguration.name}</p>
                    </Link>
                ))}
            </div>
        )
    }
}

const containerStyle = css`
    .selector-label {
        color: ${color_text_light};
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin: 24px 24px 16px 24px;
    }
    .template-item {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 24px;
        cursor: pointer;
        p {
            margin: 0 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${color_text_default};
        }
        img {
            width: 16px;
            height: 16px;
        }
        &.active {
            background: ${color_variants_ocean_light_1};
        }
    }
`

export default ActiveTemplateSelector;