import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue, cool_grey } from '../../constants/colors';

// component used for mid-page subheaders
const subheaderStyle = css`
    font-family: Muli;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: ${cool_grey};
    display:block;
    margin-bottom: 24px;
`
interface SubheaderProps {
    text?: string
}

const DetailLabel: React.SFC<SubheaderProps> = (props) => (
    <p className={subheaderStyle}>{props.text}</p>
);

export default DetailLabel;