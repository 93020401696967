import React from 'react';
import { withFormik } from "formik";
import * as yup from 'yup';
import HelpMessageForm, { EditHelpMessageFormData, EditHelpMessageFormProps } from '../../formScenes/HelpMessageForm';

const HelpMessageContentForm = withFormik<EditHelpMessageFormProps, EditHelpMessageFormData>({
    mapPropsToValues: props => ({
        responses: [{ content: '' }],
        title: 'Untitled Help Message',
        followUpSelection: 'end',
        repromptSelection: 'end',
        sensitiveConversationChange: "None",
        entryType: 'open',
        requiresParent: false,
        isLive: true,
        languages: props.application?.languages.find(l => l.id == props.application?.defaultLanguageId) ? [props.application?.languages.find(l => l.id == props.application?.defaultLanguageId)] : [],
        webhookChainingType: "NoChaining"
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
        
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus, setFieldValue }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel({
            applicationFeatureId: props.applicationFeatureId,
            applicationId: props.application?.id,
            responses: []
        }, values));
    }
})(HelpMessageForm);

export default HelpMessageContentForm;