import React from 'react';
import { css } from '@emotion/css';
import ContainerDimensions from 'react-container-dimensions';
const alexaBackground = require('../../../content/images/show-background.jpg');

export const alexaBodyStyle = css`
    position: relative;
    width: 100%;
    padding-top: 50%; /* 2:1 Aspect Ratio */
    margin: 16px 0;
    .screen-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        background: black;
        border-radius: 5px;
        width: calc(100% - 16px);
        height: 100%;
    }
    .screen {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-49%, -49%);
        transform-origin: center center;
        width: 1920px;
        height: 960px;
        background: url('${alexaBackground}');
        background-size:     cover; 
        background-repeat:   no-repeat;
        background-position: center center; 

        display: flex;
        flex-direction: column;

        .hint-container {
            margin-bottom: 0;
            margin-top: bottom;
        }
    }
`
interface AlexaWrapperProps {
    className?: string
}
const AlexaShortRectangleWrapper: React.SFC<AlexaWrapperProps> = (props) =>
    (
        <div className={alexaBodyStyle}>
            <div className='screen-wrapper'>
                <ContainerDimensions>
                    {({ width, height }) =>
                        <div className={`screen ${props.className}`} style={{ transform: `translate(-50%, -50%) scale(${Math.min(width / 1920, height / 960)})` }}>
                            {props.children}
                        </div>
                    }
                </ContainerDimensions>
            </div>
        </div >
    )

export default AlexaShortRectangleWrapper;