import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../../constants/colors';
import ApplicationFeatureModel from '../../../models/features/api/ApplicationFeatureModel';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';

const bombIcon = require('../../../content/images/atomic-bomb.svg');
const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 64px;
    img {
        width: 82px;
        height: 82px;
        margin-bottom: 32px;
    }

    p {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${dark_grey_blue};
    }
`

interface DeleteConfirmationProps {
    applicationFeatureCount?: number
    contentItemCount?: number
    itemLabel?: string
}

const getSelectionLabel = (applicationFeatureCount: number, contentItemCount: number) => {
    var label = "";
    if (applicationFeatureCount > 0) {
        label += ` ${applicationFeatureCount} Folder${applicationFeatureCount > 1 ? 's' : ''}`
    }
    if (contentItemCount > 0) {
        if (applicationFeatureCount > 0)
            label += ' and ';
        label += ` ${contentItemCount} Conversation${contentItemCount > 1 ? 's' : ''}`;
    }

    return `${label}?`;
}
const DeleteConfirmation: React.SFC<DeleteConfirmationProps> = (props) => (
    <div className={containerStyle}>
        <img src={bombIcon}/>
        <p>
            Are you sure you want to delete 
            <b>{props.itemLabel ? ` ${props.itemLabel}` : getSelectionLabel(props.applicationFeatureCount, props.contentItemCount)}</b>
        </p>
    </div>
)

export default DeleteConfirmation;