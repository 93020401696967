import React from 'react';
import { css } from '@emotion/css';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import { color_text_link, color_text_default, color_variants_ocean_light_1, color_text_light } from '../../../constants/colors';
import _ from 'lodash';
import { Droppable, DragDropContext, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { reorder } from '../../../models/extensions';

interface TemplateSectionSelectorProps {
    sections: TemplateFormSectionModel[]
    activeSection: TemplateFormSectionModel
    isStartSelected?: boolean
    onChange: (section: TemplateFormSectionModel) => void
    onSort: (sections: TemplateFormSectionModel[]) => void
    onStartSelect: () => void
}

const TemplateSectionSelector: React.SFC<TemplateSectionSelectorProps> = (props) => {
    const handleDragEnd = (result: DropResult, provider: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const sections = _.sortBy(props.sections ?? [], s => s.priority)
        props.onSort(reorder(sections, result.source.index, result.destination.index));
    }
    return (
        <div className={containerStyle}>
            <div className={`section-item ${props.isStartSelected ? 'active' : ''}`} onClick={props.onStartSelect}>
                <p>Overview</p>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={"sections"} type="TEMPLATE_SECTION">
                    {
                        (provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {_.sortBy(props.sections ?? [], s => s.priority)?.map((s, i) => (
                                    <Draggable draggableId={s?.id} index={i} key={s.id}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={`section-item ${props.activeSection == s ? 'active' : ''}`}
                                                onClick={() => props.onChange(s)}>
                                                <p>{s?.title}</p>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )
                    }
                </Droppable>
            </DragDropContext>

        </div>
    );
}

const containerStyle = css`

    padding: 16px;

    .section-item {
        display: flex;
        align-items: center;
        border-radius: 4px;
        height: 48px;
        padding: 0 16px;
        cursor: pointer;
        p {
            font-size: 14px;
            line-height: 20px;
            color: ${color_text_default};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img {
            margin-right: 0;
            margin-left: auto;
            width: 24px;
            height: 24px;
        }

        .selected-indicator {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: ${color_text_link};
            margin-right: 8px;
            margin-left: auto;
        }
        
        &.active {
            background: ${color_variants_ocean_light_1};
            p {
                color: ${color_text_link}
            }
        }

        &.disabled {
            img {
                display: none;
            }
            p {
                color: ${color_text_light};
            }
        }
    }

`

export default TemplateSectionSelector;