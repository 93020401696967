import React from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import TextField from "../../../../components/forms/TextField";
import { color_shades_darker, color_shades_darkest } from "../../../../constants/colors";
import { CustomAssistantConfigurationsFormData } from "../../../../models/customAssistant/CustomAssistantConfigurationsFormData";
import { FormikProps } from "formik";

const slideFromRightDesktop = require("../../../../content/images/custom-assistant-deployment/slide-from-right-desktop.svg");
const slideOutDesktop = require("../../../../content/images/custom-assistant-deployment/slide-out-desktop.svg");
const slideOutMobile = require("../../../../content/images/custom-assistant-deployment/slide-out-mobile.svg");
const overlayDesktop = require("../../../../content/images/custom-assistant-deployment/overlay-desktop.svg");
const overlayMobile = require("../../../../content/images/custom-assistant-deployment/overlay-mobile.svg");
const minimalDesktop = require("../../../../content/images/custom-assistant-deployment/minimal-desktop.svg");
const minimalMobile = require("../../../../content/images/custom-assistant-deployment/minimal-mobile.svg");

const CustomAssistantPreviewLayout: React.FC<FormikProps<CustomAssistantConfigurationsFormData>> = (props) => {
    switch (props.values.uiType) {
        case "minimal":
            return (
                <LayoutPreviewWrapper>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Minimal for Large Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={minimalDesktop} />
                        <LayoutPreviewDescription>Assistant will appear as a button in the bottom center of the page and will open vertically</LayoutPreviewDescription>
                    </LayoutPreview>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Minimal for Small Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={minimalMobile} />
                        <LayoutPreviewDescription>Assistant will appear as a button in the bottom center of the page and will open vertically</LayoutPreviewDescription>
                    </LayoutPreview>
                </LayoutPreviewWrapper>
            )
            break;
        case "bottomRightButton":
            return (
                <LayoutPreviewWrapper>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Overylay for Large Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={overlayDesktop} />
                        <LayoutPreviewDescription>Assistant will appear as a button in the bottom right corner of the page and will open vertically to cover the content</LayoutPreviewDescription>
                    </LayoutPreview>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Overylay for Small Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={overlayMobile} />
                        <LayoutPreviewDescription>Assistant will appear as a button in the bottom right corner of the page and will open vertically to cover the content</LayoutPreviewDescription>
                    </LayoutPreview>
                </LayoutPreviewWrapper>
            )
            break;
        case "slideFromBottom":
            return (
                <LayoutPreviewWrapper>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Slide-out for Large Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={slideOutDesktop} />
                        <LayoutPreviewDescription>Assistant will appear in a panel on the bottom right side of the screen and will open vertically to cover the content</LayoutPreviewDescription>
                    </LayoutPreview>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Slide-out for Small Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={slideOutMobile} />
                        <LayoutPreviewDescription>Assistant will appear in a panel on the bottom right side of the screen and will open vertically to cover the content</LayoutPreviewDescription>
                    </LayoutPreview>
                </LayoutPreviewWrapper>
            )
            break;
        case "slideFromRight":
            return (
                <>
                    <LayoutPreviewWrapper>
                        <LayoutPreview>
                            <LayoutPreviewHeading>Slide From Right for Large Screens</LayoutPreviewHeading>
                            <LayoutPreviewImage src={slideFromRightDesktop} />
                            <LayoutPreviewDescription>Assistant will appear as a verticle button on the right-hand side of the page and will open horizontally covering the content</LayoutPreviewDescription>
                        </LayoutPreview>
                    </LayoutPreviewWrapper>
                    <TextFieldWrapper>
                        <TextField
                            name="startButtonText"
                            disabled={false}
                            value={props.values.startButtonText}
                            label="Start Button Text"
                            placeholder="Start Button Text"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            fieldContainerStyle={fieldContainerStyle}
                        />
                        <TextField
                            name="startButtonFontSize"
                            disabled={false}
                            value={props.values.startButtonFontSize}
                            label="Start Button Text Size"
                            placeholder="Start Button Text Size"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            fieldContainerStyle={fieldContainerStyle}
                        />
                    </TextFieldWrapper>
                </>
            )
            break;
        default:
            return (
                <LayoutPreviewWrapper>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Minimal for Large Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={minimalDesktop} />
                        <LayoutPreviewDescription>Assistant will appear as a button in the bottom right corner of the page and will open vertically to cover the content</LayoutPreviewDescription>
                    </LayoutPreview>
                    <LayoutPreview>
                        <LayoutPreviewHeading>Minimal for Small Screens</LayoutPreviewHeading>
                        <LayoutPreviewImage src={minimalMobile} />
                        <LayoutPreviewDescription>Assistant will appear as a button in the bottom right corner of the page and will open vertically to cover the content</LayoutPreviewDescription>
                    </LayoutPreview>
                </LayoutPreviewWrapper>
            )
            break;
    };
};

const TextFieldWrapper = styled.div`
    margin-top: 16px;
`;

const fieldContainerStyle = css`
    height: 48px;
`;

const LayoutPreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LayoutPreview = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
`;

const LayoutPreviewHeading = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: ${color_shades_darkest};
    text-align: center;
`;

const LayoutPreviewImage = styled.img`
    margin: 8px 0;
`;

const LayoutPreviewDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darker};
    text-align: center;
`;

export default CustomAssistantPreviewLayout;
