import React from 'react';
import { css } from '@emotion/css';
import { color_shades_light, color_text_inverse } from '../../constants/colors';

const containerStyle = css`
    margin-right: 64px;
    .incomplete-indicator {
        margin-left: 60px;
    }
    padding-left: 24px;
    .status {
        label {
            color: ${color_shades_light};
        }
    }

    .incomplete-indicator {
        span {
            color: white;
        }
    }
`

const HeaderStatusContainer: React.SFC = (props) => (
    <div className={containerStyle}>
        {props.children}
    </div>
);

export default HeaderStatusContainer;