export const javascriptCodeExportDescription = "Copy the below JavaScript code snippet and paste it into the head section of your HTML page."
export const javascriptDocumentationInformation = "To get more information about the SDK, including the current version number, please visit our"
export const javascriptDocumentationLink = "https://support.voicify.com/en/knowledge/how-do-i-deploy-my-voicify-app/assistant-to-my-website"
export const javascriptDocumentationLinkName = "Browser SDK documentation."

export const swiftInstallationInstructions =
    `source 'https://github.com/voicifydevs/voicify-ios-sdk-pod-specs.git' 

target 'MyApp' do 

use_frameworks! :linkage => :static 

    pod 'voicify-ios-sdk', '~> {version}' 
    
end.
`
export const swiftPermissionsCode =
    `Key: Privacy - Speech Recognition Usage Description
Value: Your Usage Description Goes Here

Key: Privacy - Microphone Usage Description
Value: Your Usage Description Goes Here
`
export const swiftInstallationTitle = "1. Install the SDK with CocoaPods by specifing it to a target in your Podfile:"
export const swiftDocumentationInformation = "To get more information about the SDK, including the current version number, please visit our"
export const swiftDocumentationLink = "https://support.voicify.com/en/knowledge/how-do-i-deploy-my-voicify-assistant-to-my-ios-mobile-app"
export const swiftDocumentationLinkName = "iOS SDK documentation."
export const swiftPermissionsTitle = "2. Add permissions to your IOS target properties:"
export const swiftDescription = "3. Copy the below Swift code snippet and paste it into your view."

export const kotlinInstallationInstructions =
    `dependencies {
    implementation 'com.github.voicifydevs:voicify-android-sdk:{version}'
}
`
export const kotlinPermissionsCode =
    `<uses-permission "android:name=android.permission.RECORD_AUDIO" />
<uses-permission "android:name=android.permission.INTERNET" />

<queries>
    intent>
        <action android:name="android.speech.RecognitionService" />
    </intent>
</queries>`

export const kotlinInstallationTitle = "1. Install the SDK with Jitpack by specifying the dependency in your app level build.gradle file:"
export const kotlinDocumentationInformation = "To get more information about the SDK, including the current version number, please visit our"
export const kotlinDocumentationLink = "https://support.voicify.com/en/knowledge/how-do-i-deploy-my-voicify-assistant-to-my-android-app"
export const kotlinDocumentationLinkName = "Android SDK documentation."
export const kotlinPermissionsTitle = "2. Add permissions to your Android manifest:"
export const kotlinDescription = "3. Copy the below Kotlin code snippet and paste it into your activity."
