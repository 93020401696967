import React, { useState } from "react";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import { css } from '@emotion/css';
import TabContainerPanel from "../../../components/structure/Panels/TabContainerPanel";
import { TabPanel } from "react-tabs";
import SimulatorUserProfile from "./SimulatorUserProfile";
import LanguageModel from "../../../models/features/api/LanguageModel";
import SimulatorPinnedMessagesPanel from "./SimulatorPinnedMessagesPanel";
import UserApplicationPreferencesContainer from "../../../state/containers/UserApplicationPreferencesContainer";
import { SimulatorMessage } from "./SimulatorInteraction";
const userIcon = require('../../../content/images/simulator/user.svg');
const userSelectedIcon = require('../../../content/images/user-selected.svg');
const thumbtackIcon = require('../../../content/images/simulator/thumbtack.svg');
const thumbtackSelectedIcon = require('../../../content/images/simulator/thumbtack-selected.svg');

interface SimulatorInputProps {
    applicationId: string,
    appContainer: ApplicationContainer,
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer,
    languages: LanguageModel[],
    selectedLanguage: LanguageModel, 
    requests?: SimulatorMessage[],
    handleSendMessage: (text: string) => void
    handleSendWelcome: () => void
    handleLanguageSelected: (language: LanguageModel) => void
    handleStartNewSession: () => void
}

const SimulatorInputPanel: React.FC<SimulatorInputProps> = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const updateSelectedIndex = (index: number) => {
        setSelectedIndex(index);
    };

    return (
        <TabContainerPanel
            selectedIndex={selectedIndex}
            onTabSelected={(index) => { updateSelectedIndex(index) }}
            className={containerStyle}
            tabs={[
                {
                    title: "User Profile",
                    icon: userIcon,
                    selectedIcon: userSelectedIcon,
                    className: "ac-simulator-user-tab"
                },
                {
                    title: "Pinned",
                    icon: thumbtackIcon,
                    selectedIcon: thumbtackSelectedIcon,
                    className: "ac-simulator-pinned-tab"
                }
            ]}>
            <TabPanel>
                <SimulatorUserProfile
                    appContainer={props.appContainer}
                    applicationId={props.applicationId}
                    languages={props.languages}
                    selectedLanguage={props.selectedLanguage}
                    handleLanguageSelected={(language) => { props.handleLanguageSelected(language) }}
                />
            </TabPanel>
            <TabPanel>
                <SimulatorPinnedMessagesPanel
                    handleSendWelcome={props.handleSendWelcome}
                    applicationId={props.applicationId}
                    userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                    languages={props.languages}
                    selectedLanguage={props.selectedLanguage}
                    handleSendMessage={(text: string) => props.handleSendMessage(text)}
                    handleStartNewSession={props.handleStartNewSession}
                    requests = {props.requests}
                />
            </TabPanel>
        </TabContainerPanel>
    )
}

const containerStyle = css`
    width: 432px;
    max-width: 432px;
    flex: 1;
`;

export default SimulatorInputPanel;