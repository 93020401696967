import React from 'react';
import { css } from '@emotion/css';
import { InjectedFormikProps, withFormik } from "formik";
import * as yup from 'yup';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import Loader from '../../../components/general/Loader';
import SmallModalLayout from '../../../components/structure/SmallModalLayout';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import ModalHeader from '../../../components/general/ModalHeader';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import AppSelectField from '../../../components/forms/AppSelectField';
import RoundedSelectionRow from '../../../components/general/RoundedSelectionRow';
import TextField from '../../../components/forms/TextField';

interface CreateTemplateFormProps {
    appContainer: ApplicationContainer
    orgContainer: OrganizationContainer
    templatesContainer: TemplateConfigurationContainer
    history: any
}


interface CreateTemplateFormData {
    applicationId?: string
    dynamicUrl?: string
    sourceType: "Application" | "Dynamic"
}
class InnerForm extends React.Component<InjectedFormikProps<CreateTemplateFormProps, CreateTemplateFormData>> {
    handleClose() {
        this.props.history.push("/v/modules");
    }
    handleAppChange(e) {
        this.props.setFieldValue("applicationId", e.value);
    }
    handleChangeSource(sourceType: "Application" | "Dynamic") {
        this.props.setFieldValue("sourceType", sourceType);
        this.props.setFieldValue("applicationId", '');
        this.props.setFieldValue("dynamicUrl", '');
    }
    render() {
        const props = { ...this.props };
        const isSaving = this.props.templatesContainer.state.isSaving || this.props.appContainer.state.isSaving;
        const { applicationId, dynamicUrl, sourceType } = this.props.values;
        return (
            <SmallModalLayout isVisible onClose={this.handleClose.bind(this)}>
                <form onSubmit={props.handleSubmit} className={containerStyle}>
                    <CustomScrollbars className="modal-wrapper">
                        <ModalHeader title="Setup a new Module - Choose a Basis" onClose={this.handleClose.bind(this)} />
                        <div className="modal-body">
                            <h4>Select a basis for your module</h4>
                            <RoundedSelectionRow
                                options={[
                                    { name: "Template App" },
                                    { name: "Dynamic Integration Endpoint" },
                                ]}
                                onClick={(num) => this.handleChangeSource(num == 1 ? "Dynamic" : "Application")}
                                selectedFilter={sourceType == "Dynamic" ? 1 : 0} />
                            {sourceType == "Dynamic" ? <>
                                <p>Enter your API endpoint that you want Voicify to send the completed form details to. Your endpoint will get information about the filled out forms from the user and can then use custom export models to sync with the app in Voicify.</p>
                                <TextField label="Endpoint URL" value={dynamicUrl} name="dynamicUrl" onChange={props.handleChange} onBlur={props.handleBlur} placeholder="https://myintegration.voicify.com/api/myendpoint" />
                                <p>This endpoint must accept HTTP POST requests over https.</p>
                            </> : <>
                                <p>Select an existing app that will serve as a module for your template. The existing content, interaction model, and integrations will be copied over to any instances of the template.</p>

                                <AppSelectField name="applicationId" 
                                    label="Template app" 
                                    placeholder="Select an app" 
                                    value={applicationId} 
                                    onBlur={props.handleBlur} 
                                    onChange={this.handleAppChange.bind(this)} 
                                    appContainer={props.appContainer}
                                    organizationId={props.orgContainer.state.currentOrganization.id}
                                    />
                                <p>Dynamic assets such as variables can be included in your template and the values filled in will be inserted into the structure based on their placement within the source app.</p>

                            </>}
                        </div>
                    </CustomScrollbars>

                    <FooterBar>
                        <Button type="submit" themes={['primary', 'start']} text="Create" disabled={isSaving} loading={isSaving} />
                        <Button type="button" themes={['secondary', 'end']} text="Cancel" onClick={this.handleClose.bind(this)} disabled={isSaving} loading={isSaving} />
                    </FooterBar>
                </form>
            </SmallModalLayout>
        )
    }
}

const containerStyle = css`
    min-height: 580px;
    height: 580px;
    padding-bottom:64px;
    .modal-wrapper {
        min-height: 100%;
    }
    .modal-body{
        padding: 32px;
        margin-bottom: 180px;
        height: 100%;
        flex: 1;
        >h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        >p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin: 32px 0;
        }
    }
`

const CreateTemplateConfigurationForm = withFormik<CreateTemplateFormProps, CreateTemplateFormData>({
    mapPropsToValues: () => ({
        applicationId: '',
        dynamicUrl: '',
        sourceType: "Application"
    }),
    validationSchema: yup.object().shape({
        applicationId: yup.string().when('sourceType', {
            is: (sourceType) => sourceType == "Application",
            then: yup.string().required("You must select an application to use as the template")
        }),
        dynamicUrl: yup.string().when('sourceType', {
            is: (sourceType) => sourceType == "Dynamic",
            then: yup.string().url("You must enter a valid url").required("You must specify a dynamic integration endpoint url")
        })
    }),
    handleSubmit: (values, { props }) => {
        if (values.applicationId) {
            props.appContainer.toggleTemplating(values.applicationId, true).then(toggleResult => {
                if (toggleResult.resultType == "Ok") {
                    props.templatesContainer.createEmptyTemplateConfiguration(props.orgContainer.state.currentOrganization?.id, values.applicationId, values.dynamicUrl).then(result => {
                        if (result.resultType == "Ok") {
                            props.history.push(`/v/modules/edit/${result.data.id}`);
                            location.reload();
                        }
                    })
                }
            })
        } else {
            props.templatesContainer.createEmptyTemplateConfiguration(props.orgContainer.state.currentOrganization?.id, values.applicationId, values.dynamicUrl).then(result => {
                if (result.resultType == "Ok") {
                    props.history.push(`/v/modules/edit/${result.data.id}`);
                    location.reload();
                }
            })
        }
    }
})(InnerForm);


export default CreateTemplateConfigurationForm;
