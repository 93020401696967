import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import moment from "moment";
import OrganizationContainer from "../../../state/containers/OrganizationContainer";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import {
    color_defaults_white,
    color_shades_dark,
    color_shades_darker,
    color_shades_lighter,
    shade_2_lighter,
} from "../../../constants/colors";
import styled from "@emotion/styled";
import DeploymentStatus from "./DeploymentStatus";
import AmazonDeploymentCard from "./AmazonDeploymentCard";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import ApplicationFulfillmentEndpoints from "../../../models/applications/api/ApplicationFulfillmentEndpoints";
import SkillCertificationSummary from "../../../models/deployments/api/SkillCertificationSummary";
import DialogflowDeploymentCard from "./DialogflowDeploymentCard";
import MicrosoftDeploymentCard from "./MicrosoftDeploymentCard";
import _ from "lodash";
import MemberContainer from "../../../state/containers/MemberContainer";
import ApplicationMembersContainer from "../../../state/containers/ApplicationMembersContainer";
import UserContainer from "../../../state/containers/UserContainer";
import Loader from "../../../components/general/Loader";
import ApplicationEnvironmentsContainer, { ApplicationEnvironmentsOption } from "../../../state/containers/ApplicationEnvironmentsContainer";
import ApplicationEnvironmentModel from "../../../models/applications/api/environments/ApplicationEnvironmentModel";
import PageError from "../../../components/general/PageError";
import DialogflowDeploymentContainer from "../../../hooks/DialogflowDeploymentContainer";
import AmazonDeploymentContainer from "../../../hooks/AmazonDeploymentContainer";
import MicrosoftDeploymentContainer from "../../../hooks/MicrosoftDeploymentContainer";
import MediaContainer from "../../../state/containers/MediaContainer";
import CustomAssistantDeploymentCard from "./customAssistantConfigurations/CustomAssistantDeploymentCard";
import DeploymentsContainer from "../../../hooks/DeploymentsContainer";
import TelephonyDeploymentCard from "./telephonyDeployments/TelephonyDeploymentCard";

const alexaIcon = require("../../../content/images/platforms/alexa-icon.svg");
const dialogflowIcon = require("../../../content/images/platforms/dialogflow.svg");
const botServiceIcon = require("../../../content/images/platforms/azure-bot.svg");
const customChatIcon = require("../../../content/images/platforms/custom-chat.svg");
const telephonyIcon = require("../../../content/images/platforms/telephony-avatar.svg");
const bigLock = require("../../../content/images/application-settings/deployments/big-lock.svg");
const smallLock = require("../../../content/images/application-settings/deployments/small-lock.svg");

// linked Application status icons
const waitingIcon = require("../../../content/images/application-settings/deployments/waiting-to-deploy.svg");
const failedIcon = require("../../../content/images/application-settings/deployments/failed-review.svg");
const pendingReviewIcon = require("../../../content/images/application-settings/deployments/pending-review.svg");
const unknownReviewIcon = require("../../../content/images/application-settings/deployments/unknown-deploy-status.svg");
const liveReviewIcon = require("../../../content/images/application-settings/deployments/live.svg");
const livePendingReviewIcon = require("../../../content/images/application-settings/deployments/live-pending-review.svg");
const liveFailedReviewIcon = require("../../../content/images/application-settings/deployments/live-failed-review.svg");
const readyIcon = require("../../../content/images/application-settings/deployments/ready.svg");
const linkedIcon = require("../../../content/images/application-settings/deployments/linked.svg");

interface DeploymentsTabProps {
    applicationId: string;
    appContainer: ApplicationContainer;
    orgContainer: OrganizationContainer;
    memberContainer: MemberContainer;
    applicationMembersContainer: ApplicationMembersContainer;
    userContainer: UserContainer;
    environmentOption: ApplicationEnvironmentsOption;
    environmentsContainer: ApplicationEnvironmentsContainer;
    mediaContainer: MediaContainer;
}

export type LinkedApplicationStatus =
    | "WAITING"
    | "UNKNOWN"
    | "READY"
    | "FAILED_REVIEW"
    | "PENDING_REVIEW"
    | "LIVE"
    | "LIVE_PENDING_REVIEW"
    | "LIVE_FAILED_REVIEW"
    | "LINKED"
    | "DEPLOYMENT_ISSUE";

const DeploymentsTab = (props: DeploymentsTabProps) => {
    const dialogflowDeploymentContainer =
        DialogflowDeploymentContainer.useContainer();
    const amazonDeploymentContainer = AmazonDeploymentContainer.useContainer();
    const microsoftDeploymentContainer =
        MicrosoftDeploymentContainer.useContainer();
    const deploymentsContainer = DeploymentsContainer.useContainer();

    const [environment, setEnvironment] = useState(
        null as ApplicationEnvironmentModel
    );
    const [endpoints, setEndpoints] = useState(
        {} as ApplicationFulfillmentEndpoints
    );

    const [alexaLaunchMessage, setAlexaLaunchMessage] = useState("");
    const [loadingAlexaDeployment, setLoadingAlexaDeployment] = useState(false);
    const [alexaApplicationStatus, setAlexaApplicationStatus] = useState(
        "UNKNOWN" as LinkedApplicationStatus
    );
    const [relevantSkillCertification, setRelevantSkillCertification] = useState(
        {} as SkillCertificationSummary
    );

    const [dialogflowApplicationStatus, setDialogflowApplicationStatus] =
        useState("UNKNOWN" as LinkedApplicationStatus);
    const [loadingDialogflowDeployment, setLoadingDialogflowDeployment] =
        useState(false);
    const [dialogflowLaunchMessage, setDialogflowLaunchMessage] = useState("");

    const [botServiceApplicationStatus, setBotServiceApplicationStatus] =
        useState("UNKNOWN" as LinkedApplicationStatus);

    // Load current user permissions
    useEffect(() => {
        props.memberContainer.getMembers(
            props.orgContainer.state.currentOrganization.id
        );
        props.applicationMembersContainer.getMembersForApp(props.applicationId);
        props.orgContainer.getSystemRoles();
        if (props.userContainer.state.currentUser == null) {
            props.userContainer.getUser();
        }
    }, [props.applicationId]);

    useEffect(() => {
        if (props.applicationId && environment?.id)
            deploymentsContainer.waitForEnvironmentDeployment(
                props.applicationId,
                environment.id,
                true,
                true,
                true
            );
    }, [props.applicationId, environment]);

    useEffect(() => {
        loadEnvironmentDeployments();
    }, [props.applicationId, props.environmentOption]);

    useEffect(() => {
        loadAlexaDeploymentStatus();
    }, [
        amazonDeploymentContainer.currentSkillCertifications,
        amazonDeploymentContainer.availableSkills,
        amazonDeploymentContainer.linkedProjectId,
        deploymentsContainer?.status,
        amazonDeploymentContainer.status,
    ]);

    useEffect(() => {
        loadoadDialogflowDeploymentStatus();
    }, [
        dialogflowDeploymentContainer.linkedProjectId,
        deploymentsContainer?.status,
        dialogflowDeploymentContainer.status,
    ]);

    useEffect(() => {
        if (microsoftDeploymentContainer?.currentStep > 3) {
            setBotServiceApplicationStatus("LINKED");
        } else {
            setBotServiceApplicationStatus("UNKNOWN");
        }
    }, [microsoftDeploymentContainer.currentStep, props.environmentOption]);

    const loadEnvironmentDeployments = async () => {
        // Get application environment based on selected environment option: Live or Draft
        const selectedEnvironment: ApplicationEnvironmentModel =
            await getEnvironmentByOption();

        // Clear old environment polling so we do not get stale statuses while waiting for interaction model to load selected environment
        await deploymentsContainer.setPollingEnvironment(
            props.applicationId,
            selectedEnvironment?.id
        );

        // Load application end points, check if selected environment option is Draft
        await loadEndpointsByOption();
    };

    const getEnvironmentByOption =
        async (): Promise<ApplicationEnvironmentModel> => {
            await props.environmentsContainer.loadEnvironments(props.applicationId);
            if (!props.environmentOption) return;

            const selectedEnvironment: ApplicationEnvironmentModel =
                props.environmentsContainer.findEnvironmentByOption(
                    props.applicationId,
                    props.environmentOption
                );
            setEnvironment(selectedEnvironment);
            return selectedEnvironment;
        };

    const loadEndpointsByOption = async () => {
        let applicationEndpoints: ApplicationFulfillmentEndpoints;
        // load application endpoints
        if (
            props.appContainer.state.applicationEndpoints &&
            props.appContainer.state.applicationEndpoints.find(
                (a) => a.applicationId == props.applicationId
            )
        ) {
            // Not reference but a copy
            applicationEndpoints = {
                ...props.appContainer.state.applicationEndpoints.find(
                    (a) => a.applicationId == props.applicationId
                ),
            };
        } else {
            const endpointsResult = await props.appContainer.getEndpoints(
                props.applicationId
            );
            applicationEndpoints = endpointsResult.data;
        }

        if (props.environmentOption === "draft") {
            //Append &useDraftContent=true to all Application's Fulfillment Endpoints for Draft environment
            const append: string = "&useDraftContent=true";
            applicationEndpoints.alexaEndpoint =
                applicationEndpoints.alexaEndpoint + append;
            applicationEndpoints.alexaProxyEndpoint =
                applicationEndpoints.alexaProxyEndpoint + append;
            applicationEndpoints.dialogFlowEndpoint =
                applicationEndpoints.dialogFlowEndpoint + append;
            applicationEndpoints.botServiceEndpoint =
                applicationEndpoints.botServiceEndpoint + append;
            applicationEndpoints.customAssistantEndpoint =
                applicationEndpoints.customAssistantEndpoint + append;
        }

        setEndpoints(applicationEndpoints);
    };

    const loadAlexaDeploymentStatus = async () => {
        if (
            amazonDeploymentContainer.status != "launch" &&
            amazonDeploymentContainer.currentEnvironmentId == environment?.id
        ) {
            setAlexaApplicationStatus("UNKNOWN");
            return;
        }
        const status = deploymentsContainer?.status;
        const hasQueuedDeployments = status?.queuedForDeploymentToPlatforms?.some(
            (q) => q.platform == "alexa"
        );

        let deploymentResults = status?.latestDeploymentResults?.filter(
            (r) => r.platform == "alexa"
        );
        deploymentResults = _.orderBy(
            deploymentResults ?? [],
            (r) => new Date(r.modifiedDate),
            ["desc"]
        );
        const deploymentResult =
            deploymentResults?.length > 0 ? deploymentResults[0] : null;

        if (hasQueuedDeployments) {
            // if we're currently deploying this set waiting icon and show information about that deployment
            setLoadingAlexaDeployment(true);
            setAlexaApplicationStatus("WAITING");
            if (
                status?.queuedForDeploymentToPlatforms.some(
                    (q) => q.platform == "alexa" && q.packageId
                )
            ) {
                setAlexaLaunchMessage(
                    "Skill created, importing skill package to Alexa"
                );
            } else {
                setAlexaLaunchMessage(
                    "Your Voicify application is syncing with your Alexa skill"
                );
            }
        } else if (deploymentResult && deploymentResult.error) {
            // if there was an issue with a deployment we need to show the error.
            setAlexaLaunchMessage(deploymentResult.error);
            setAlexaApplicationStatus("DEPLOYMENT_ISSUE");
        } else if (loadingAlexaDeployment) {
            // if we were loading now we're done. Lets load info and clear the launch message.
            setAlexaLaunchMessage("");
            setLoadingAlexaDeployment(false);
            setAlexaApplicationStatus("READY");
            await amazonDeploymentContainer.loadEnvironmentDeploymentInformation(
                props.applicationId,
                environment?.id,
                props.orgContainer.state.currentOrganization.id
            );
        } else {
            // there's no current deployment or error lets check our current publication status
            let certifications = amazonDeploymentContainer.currentSkillCertifications;
            let skills = amazonDeploymentContainer.availableSkills;
            if (certifications.length > 0 && skills.length > 0) {
                // each version of a skill will show up as a different skill
                const currentSkills = skills.filter(
                    (s) => s.skillId == amazonDeploymentContainer.linkedProjectId
                );
                if (currentSkills != null && currentSkills.length > 0) {
                    certifications.sort((a, b) =>
                        moment(b.skillSubmissionTimestamp, "YYYY-MM-DDThh:mm").diff(
                            moment(a.skillSubmissionTimestamp, "YYYY-MM-DDThh:mm")
                        )
                    );
                    const currentCertification = certifications[0];
                    const isLive = currentSkills.some(
                        (s) => s.publicationStatus == "PUBLISHED"
                    );
                    setRelevantSkillCertification(currentCertification);
                    if (isLive) {
                        switch (currentCertification.status) {
                            case "CANCELLED":
                                setAlexaApplicationStatus("LIVE");
                                break;
                            case "IN_PROGRESS":
                                setAlexaApplicationStatus("LIVE_PENDING_REVIEW");
                                break;
                            case "FAILED":
                                setAlexaApplicationStatus("LIVE_FAILED_REVIEW");
                                break;
                            case "SUCCEEDED":
                                setAlexaApplicationStatus("LIVE");
                                break;
                        }
                    } else {
                        switch (currentCertification.status) {
                            case "CANCELLED":
                                setAlexaApplicationStatus("READY");
                                break;
                            case "IN_PROGRESS":
                                setAlexaApplicationStatus("PENDING_REVIEW");
                                break;
                            case "FAILED":
                                setAlexaApplicationStatus("FAILED_REVIEW");
                                break;
                            case "SUCCEEDED":
                                setAlexaApplicationStatus("READY");
                                break;
                        }
                    }
                }
            } else if (amazonDeploymentContainer.linkedProjectId) {
                setAlexaApplicationStatus("READY");
            } else {
                setAlexaApplicationStatus("UNKNOWN");
            }
        }
    };

    const loadoadDialogflowDeploymentStatus = async () => {
        const status = deploymentsContainer?.status;

        const hasQueuedDeployments = status?.queuedForDeploymentToPlatforms?.some(
            (q) => q.platform == "dialogflow"
        );
        let deploymentResults = status?.latestDeploymentResults?.filter(
            (r) => r.platform == "dialogflow"
        );
        deploymentResults = _.orderBy(
            deploymentResults ?? [],
            (r) => new Date(r.modifiedDate),
            ["desc"]
        );
        const deploymentResult =
            deploymentResults?.length > 0 ? deploymentResults[0] : null;

        if (hasQueuedDeployments) {
            setLoadingDialogflowDeployment(true);
            setDialogflowApplicationStatus("WAITING");
            setDialogflowLaunchMessage(null);
        } else if (deploymentResult && deploymentResult.error) {
            // if there was an issue with a deployment we need to show the error.
            setDialogflowLaunchMessage(deploymentResult.error);
            setDialogflowApplicationStatus("DEPLOYMENT_ISSUE");
            setLoadingDialogflowDeployment(false);
        } else {
            // we're not loading a deployment right now
            if (loadingDialogflowDeployment) {
                // if we were then load.
                setLoadingDialogflowDeployment(false);
                await dialogflowDeploymentContainer.loadEnvironmentDeploymentInformation(
                    props.applicationId,
                    environment?.id,
                    props.orgContainer.state.currentOrganization.id
                );
            }

            if (dialogflowDeploymentContainer.linkedProjectId) {
                if (dialogflowDeploymentContainer.status == "finished") {
                    setDialogflowApplicationStatus("LINKED");
                }
            } else {
                setDialogflowApplicationStatus("UNKNOWN");
            }

            setDialogflowLaunchMessage(null);
        }
    };

    const getApplicationStatusIcon = (status: LinkedApplicationStatus) => {
        switch (status) {
            case "WAITING":
                return waitingIcon;
            case "FAILED_REVIEW":
                return failedIcon;
            case "PENDING_REVIEW":
                return pendingReviewIcon;
            case "LIVE":
                return liveReviewIcon;
            case "LIVE_PENDING_REVIEW":
                return livePendingReviewIcon;
            case "LIVE_FAILED_REVIEW":
                return liveFailedReviewIcon;
            case "READY":
                return readyIcon;
            case "LINKED":
                return linkedIcon;
            case "DEPLOYMENT_ISSUE":
                return failedIcon;
            default:
                return unknownReviewIcon;
        }
    };

    const isCurrentUserAdmin = () => {
        const members = props.applicationMembersContainer.state.members.filter(
            (m) => m.applicationId == props.applicationId
        );
        const userId = props.userContainer.state.currentUser.id;
        var isAdmin = false;

        const member = members.find(
            (m) =>
                m.organizationMember.userId == userId &&
                m.applicationId == props.applicationId
        );

        if (member !== undefined) {
            isAdmin = member.isAdmin || member.organizationMember.isAdmin;
        } else {
            const organizationMember = props.memberContainer.state.members.find(
                (m) => m.userId == userId
            );
            if (organizationMember) {
                isAdmin = organizationMember.isAdmin;
            }
        }
        return isAdmin;
    };

    if (
        props.applicationMembersContainer.state.isLoadingMembers ||
        props.userContainer.state.currentUser == null ||
        props.environmentsContainer.state.isLoading ||
        // wait for environment to get set
        environment == null
    ) {
        return (
            <CenterDiv>
                <Loader />
            </CenterDiv>
        );
    } else if (!isCurrentUserAdmin()) {
        return (
            <StripedBackground>
                <img className="big-lock" src={bigLock}></img>
                <PermissionMessage>
                    <img src={smallLock}></img>
                    <p>You don't have permission to view this page</p>
                </PermissionMessage>
            </StripedBackground>
        );
    } else {
        return (
            <DeploymentsBody className={darkTabStyle}>
                <StatusPanel>
                    <DeploymentStatus
                        href="#custom-assistant-card"
                        platform="Custom Assistant"
                        platformIcon={customChatIcon}
                    />
                    <DeploymentStatus
                        href="#telephony-card"
                        platform="Telephony"
                        platformIcon={telephonyIcon}
                    />
                    <DeploymentStatus
                        href="#alexa-card"
                        platform="Amazon Alexa"
                        platformIcon={alexaIcon}
                        statusIcon={getApplicationStatusIcon(alexaApplicationStatus)}
                    />
                    <DeploymentStatus
                        href="#dialogflow-card"
                        platform="Dialogflow"
                        platformIcon={dialogflowIcon}
                        statusIcon={getApplicationStatusIcon(dialogflowApplicationStatus)}
                    />
                    <DeploymentStatus
                        href="#bot-service-card"
                        platform="Azure Bot Service"
                        platformIcon={botServiceIcon}
                        statusIcon={getApplicationStatusIcon(botServiceApplicationStatus)}
                    />
                </StatusPanel>
                <DeploymentsPageContainer>
                    <CustomScrollbars>
                        <CustomAssistantDeploymentCard
                            endpoints={endpoints}
                            appContainer={props.appContainer}
                            applicationId={props.applicationId}
                            environmentId={environment?.id}
                            mediaContainer={props.mediaContainer}
                        />
                        <TelephonyDeploymentCard
                            applicationId={props.applicationId}
                            environmentId={environment?.id}
                            appContainer={props.appContainer}
                        />
                        <AmazonDeploymentCard
                            appContainer={props.appContainer}
                            applicationId={props.applicationId}
                            endpoints={endpoints}
                            organizationId={props.orgContainer.state.currentOrganization.id}
                            applicationStatus={alexaApplicationStatus}
                            applicationStatusIcon={getApplicationStatusIcon(
                                alexaApplicationStatus
                            )}
                            certificationSummary={relevantSkillCertification}
                            launchMessage={alexaLaunchMessage}
                            environmentId={environment?.id}
                        />
                        <DialogflowDeploymentCard
                            applicationStatusIcon={getApplicationStatusIcon(
                                dialogflowApplicationStatus
                            )}
                            endpoints={endpoints}
                            organizationId={props.orgContainer.state.currentOrganization.id}
                            applicationId={props.applicationId}
                            applicationStatus={dialogflowApplicationStatus}
                            launchMessage={dialogflowLaunchMessage}
                            environmentId={environment?.id}
                        />
                        <MicrosoftDeploymentCard
                            organizationId={props.orgContainer.state.currentOrganization.id}
                            applicationId={props.applicationId}
                            applicationStatusIcon={getApplicationStatusIcon(
                                botServiceApplicationStatus
                            )}
                            endpoints={endpoints}
                            environmentId={environment?.id}
                        />
                    </CustomScrollbars>
                </DeploymentsPageContainer>
                <PageError
                    errors={[
                        ...(props.appContainer?.state?.errors ?? []),
                        ...(deploymentsContainer?.errors ?? []),
                    ]}
                    onClear={() => {
                        props.appContainer?.clearErrors();
                        deploymentsContainer?.clearErrors();
                    }}
                />
            </DeploymentsBody>
        );
    }
};

const StripedBackground = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: repeating-linear-gradient(
    -45deg,
    ${color_shades_lighter} -11px,
    ${color_shades_lighter} 28px,
    ${color_defaults_white} 10px,
    ${color_defaults_white} 56px
  );
  img {
    &.big-lock {
      margin-bottom: 110px;
    }
  }
`;
const CenterDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PermissionMessage = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  background-color: ${color_shades_darker};
  padding-left: 24px;
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    margin-left: 12px;
    color: ${color_defaults_white};
  }
`;
const darkTabStyle = css`
  background: ${shade_2_lighter};
  display: flex;
  flex-direction: row;
`;
const StatusPanel = styled.div`
  width: 288px;
  background: white;
  border-right: 1px solid ${color_shades_dark};
`;

const DeploymentsBody = styled.div`
  width: 100%;
`;

const DeploymentsPageContainer = styled.div`
  padding: 0px;
  flex: 1;
  height: calc(100vh - 136px);
`;

export default DeploymentsTab;
