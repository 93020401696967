import React from 'react';
import FeatureTypeModel from '../../../../models/features/FeatureTypeModel';
import FilterSelector from './FilterSelector';
import FilterOption from './FilterOption';
import { getFeatureTypeIcon } from '../../../../models/extensions';
import CustomScrollbars from '../../../../components/structure/CustomScrollbars';
const featureTypeIconLight = require('../../../../content/images/content-explorer/messages-bubble-check-light.svg');
const featureTypeIcon = require('../../../../content/images/content-explorer/messages-bubble-check.svg');
interface FeatureTypeFilterProps {
    featureTypeIds: string[]
    featureTypes: FeatureTypeModel[]
    onChange: (featureTypeIds: string[]) => void
}

class FeatureTypeFilter extends React.Component<FeatureTypeFilterProps> {
    handleToggleFeatureType(featureTypeId: string) {
        var featureTypeIds = this.props.featureTypeIds ?? []
        if (featureTypeIds.some(l => l == featureTypeId)) {
            featureTypeIds.splice(featureTypeIds.indexOf(featureTypeId), 1);
        }
        else {
            featureTypeIds.push(featureTypeId);
        }

        this.props.onChange(featureTypeIds);
    }
    handleClear() {
        this.props.onChange(null);
    }

    render() {
        const props = this.props;
        const selectedText = props.featureTypes.filter(f => props.featureTypeIds?.some(id => id == f.id))?.map(f => f.name)?.join(', ');
        return (
            <FilterSelector label="Interaction Type"
                icon={selectedText ? featureTypeIcon : featureTypeIconLight}
                placeholder={'All Interaction Types'}
                selectedText={selectedText}
                onClear={this.handleClear.bind(this)}>
                <CustomScrollbars autoHide autoHeight autoHeightMax={400}>
                    <FilterOption title="- Select All / None -"
                        onClick={() => props.onChange([])}
                        isSelected={!props.featureTypeIds}
                        isPartialSelected={true}
                        optionType="check" />
                    {props.featureTypes.map(f => (
                        <FilterOption key={f.id} title={f.name}
                            icon={getFeatureTypeIcon(f.id, true)}
                            selectedIcon={getFeatureTypeIcon(f.id)}
                            isSelected={props.featureTypeIds?.some(id => id == f.id)}
                            optionType="check"
                            onClick={() => this.handleToggleFeatureType(f.id)} />
                    ))}
                </CustomScrollbars>
            </FilterSelector>
        )
    }
}

export default FeatureTypeFilter;

