import { FormikErrors, withFormik } from "formik";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import * as yup from 'yup';
import EditableIntent from "../../../models/interactionModel/EditableIntent";
import SlotsEditor from "./SlotsEditor";
import LanguageModel from "../../../models/features/api/LanguageModel";
import _ from "lodash";
import EditableSlot from "../../../models/interactionModel/EditableSlot";

// Shape of form values in InteractionModelForm
export interface SlotsEditorFormData {
    nlpEntities: EditableNlpEntity[];
    intent: EditableIntent;
};

export interface SlotsEditorFormProps {
    isLoading: boolean;
    applicationId: string;
    languages: LanguageModel[];
    intent: EditableIntent;
    nlpEntities: EditableNlpEntity[];
    onSubmit: (formData: SlotsEditorFormData) => void;
    initialValues: SlotsEditorFormData;
    handleCloseModal: () => void;
};

const maxSlotNameSize: number = 50;

// Wrap our form with the withFormik HoC
const SlotsEditorForm = withFormik<SlotsEditorFormProps, SlotsEditorFormData>({
    displayName: "SlotsEditorForm",
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
        intent: yup.object().shape({

            slots: yup.array().of(
                yup.object().shape({
                    isDeleted: yup.boolean(),
                    name: yup.string().when("isDeleted", {
                        is: false,
                        then: yup.string().required("Validation: Slot name is required")
                            .max(maxSlotNameSize, `Validation: Slot name must not exceed ${maxSlotNameSize} characters`)
                    }),
                    entityName: yup.string().when("isDeleted", {
                        is: false,
                        then: yup.string().required("Validation: Slot NLP entity selection is required")
                    }),
                })
            ),
        })

    }),
    // Transform outer props into form values
    mapPropsToValues: props => {
        // Filter for selected locales 
        const formEntities = mapNlpEntities(props.nlpEntities, props.languages);

        // Order slots by name
        const slotsOrdered = _.orderBy(props.intent?.slots ?? [], (s) => s.name, "asc");
        const formIntent = { ...props.intent, slots: slotsOrdered };

        const values: SlotsEditorFormData = {
            nlpEntities: formEntities,
            intent: formIntent
        };

        return values;
    },
    // Add a custom validation function (this can be async)
    validate: (values: SlotsEditorFormData, props) => {
        const errors: FormikErrors<SlotsEditorFormData> = validateSlotsFormFields(values);
        // Returned errors object must be empty for validation to succeed
        return errors;
    },
    /** Form submit handler https://formik.org/docs/guides/form-submission */
    handleSubmit: async (values, { props, setSubmitting }) => {

        await props.onSubmit(values);
        setSubmitting(false);
    }
})(SlotsEditor);

const mapNlpEntities = (nlpEntities: EditableNlpEntity[], languages: LanguageModel[]): EditableNlpEntity[] => {
    // Filter for selected locales 
    const formEntities = nlpEntities?.filter(nlpEntity => {
        return languages?.map(lang => lang.shortCode)?.some(locale => nlpEntity.locales?.includes(locale));
    })?.map(e => {
        // We do not need to carry NLP Entity values for either slot validation or management display to pick an entity
        const formEntity: EditableNlpEntity = { ...e, values: [] };
        return formEntity;
    })

    return formEntities;
};

const validateSlotsFormFields = (values: SlotsEditorFormData): FormikErrors<SlotsEditorFormData> => {
    let errors: FormikErrors<SlotsEditorFormData> = {};
    let slotsErrorsArray: FormikErrors<EditableSlot>[] = [];

    const entities = values.nlpEntities?.filter(e => !e.isDeleted);

    for (let slotIdx = 0; slotIdx < values.intent?.slots?.length; slotIdx++) {
        const editableSlot: EditableSlot = values.intent?.slots[slotIdx];
        // Initialize null error for correct Form values array index
        slotsErrorsArray.push(null); // must be == slotIdx

        if (editableSlot.isDeleted) continue;

        // Check if non deleted slot has issue
        const entityName = editableSlot.entityName;
        const found = entities?.find(e => e.name == entityName);

        // Display error indicator on intent and slot items if slot is using deleted NLP Entity
        if (entityName && !found) {
            const nlpEntityError = `NLP entity "${entityName}" not found or not applicable to selected language(s) `;
            // Form's field's "entityName" error will show up in FieldError
            slotsErrorsArray[slotIdx] = { entityName: nlpEntityError };
            errors = { intent: { slots: slotsErrorsArray } };
        }

        // What about slot's entities existing in intent locales?
        const localeEntities = getNlpEntitiesInLocales(entities, values.intent?.locales?.map(l => l.shortCode));
        const slotEntityFoundInAllLocales = localeEntities?.find(e => e.name == entityName);
        if (entityName && !slotEntityFoundInAllLocales) {
            const nlpEntityError = `NLP entity "${entityName}" does not have intent language(s) selected `;
            // Form's field's "entityName" error will show up in FieldError
            slotsErrorsArray[slotIdx] = { entityName: nlpEntityError };
            errors = { intent: { slots: slotsErrorsArray } };
        }

        if (values.intent?.slots?.filter((slot, idx) =>
            idx != slotIdx
            && !slot.isDeleted
            && slot.name.trim().toLowerCase() == editableSlot.name?.trim().toLowerCase())?.length > 0) {

            const slotNameError = `Duplicate slot name "${editableSlot.name}" `;
            // Form's field's "name" error will show up in FieldError
            if (slotsErrorsArray[slotIdx]) {
                slotsErrorsArray[slotIdx] = { ...slotsErrorsArray[slotIdx], name: slotNameError };
            }
            else {
                slotsErrorsArray[slotIdx] = { name: slotNameError };
            }
            errors = { intent: { slots: slotsErrorsArray } };
        }
    }
    return errors;
};

const getNlpEntitiesInLocales = (nlpEntities: EditableNlpEntity[], locales: string[]): EditableNlpEntity[] => {
    // Filter for selected locales 
    const formEntities = nlpEntities?.filter(nlpEntity => locales.every(shortCode => nlpEntity.locales.includes(shortCode)));
    return formEntities;
};

export default SlotsEditorForm;


