import React, { useEffect, useState } from 'react';
import { dark_grey_blue, dark_grey_blue_10, cool_grey, color_shades_darkest, color_shades_darker, color_shades_dark } from '../../constants/colors';
import styled from '@emotion/styled';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import moment from 'moment';
import { DATE_FORMAT } from '../../models/analytics/AnalyticsDateFormat';
import SmallOverflowMenu from './overflowMenu/SmallOverflowMenu';
import OverflowMenu, { OverflowOption } from './overflowMenu/OverflowMenu';
import ToggleIndicator from './ToggleIndicator';
import { css } from '@emotion/css';
import { ContentItemEnvironmentStatus } from '../../models/applications/ContentItemEnvironmentStatus';
import { ContentItemStatusText } from '../../models/applications/ContentItemStatusText';
import TooltipWrapper from './TooltipWrapper';
const greenCheck = require('../../content/images/green-check.svg');
const yellowUpdate = require('../../content/images/yellow-update.svg');
const yellowDraft = require('../../content/images/yellow-draft.svg');
const greyLoading = require('../../content/images/grey-load-spin.svg');
const syncedPublished = require('../../content/images/synced-published.svg');
const syncedDraft = require('../../content/images/synced-draft.svg');
const syncedUpdated = require('../../content/images/synced-updated.svg');
const incomplete = require('../../content/images/incomplete-content.svg');
const PUBLISH = "Publish";
const PUBLISH_CHANGES = "Publish Changes";
const UNPUBLISH = "Unpublish";

interface ContentItemListStatusProps {
    lastPublish: GenericContentItemPublishModel
    contentItem: GenericContentItemModel
    className?: string
    loaded?: boolean
    optionsDisabled?: boolean
    dropDownDisabled?: boolean
    isListView: boolean
    handlePublish: () => void
    handleUnpublish: () => void
}
const ContentItemListStatus = (props: ContentItemListStatusProps) => {
    const [status, setStatus] = useState("unpublished" as ContentItemEnvironmentStatus);
    const [isOverflowExpanded, setIsOverflowExpanded] = useState(false);
    useEffect(() => {
        const synced = props.contentItem?.createdFromId && !props.contentItem?.shouldNotSync;
        if (!props.loaded) {
            setStatus("loading");
        }
        else if (!props.contentItem?.isComplete) {
            setStatus("incomplete")
        }
        else if (!props.lastPublish || !props.contentItem || props.lastPublish.publishEventType == UNPUBLISH) {
            if (synced) {

                setStatus("syncedUnpublished")
            }
            else {
                setStatus("unpublished");
            }
        } else {
            const modifiedDate = moment(props.contentItem.modifiedDate, DATE_FORMAT);
            const publishDate = moment(props.lastPublish?.applicationEnvironmentPublish?.createdDate, DATE_FORMAT);
            if (modifiedDate.isAfter(publishDate))
                if (synced) {

                    setStatus("syncedUpdated")
                }
                else {
                    setStatus("updated");
                }
            else {
                if (synced) {

                    setStatus("syncedPublished")
                }
                else {
                    setStatus("published");
                }
            }
        }
    }, [props.lastPublish, props.contentItem, props.loaded])
    const getStatusText = (): ContentItemStatusText => {
        if (props.isListView) {
            switch (status) {
                case "loading": return "Loading";
                case "unpublished": 
                case "syncedUnpublished": return "Draft";
                case "published": 
                case "syncedPublished": return "Published";
                case "updated":
                case "syncedUpdated": return "Updated";
                case "incomplete": return "Incomplete";
            }
        }
        else {
            switch (status) {
                case "loading": return "Loading";
                case "unpublished": return "Draft";
                case "published": return "Published";
                case "updated": return "Updated";
                case "incomplete": return "Incomplete";
                case "syncedPublished": return "Synced & Published";
                case "syncedUnpublished": return "Synced Draft";
                case "syncedUpdated": return "Synced & Updated";
            }
        }
    }
    const getStatusImage = () => {
        switch (status) {
            case "loading": return greyLoading;
            case "unpublished": return yellowDraft;
            case "published": return greenCheck;
            case "updated": return yellowUpdate;
            case "syncedPublished": return syncedPublished;
            case "syncedUnpublished": return syncedDraft;
            case "syncedUpdated": return syncedUpdated;
            case "incomplete": return incomplete;
        }
    }
    const getOptions = (): OverflowOption[] => {
            switch (status) {
                case "loading": return [];
                case "unpublished": return [{ label: PUBLISH, isDisabled: props.optionsDisabled }]
                case "published": return [{ label: UNPUBLISH, isDisabled: props.optionsDisabled }]
                case "updated": return [{ label: PUBLISH_CHANGES, isDisabled: props.optionsDisabled }, { label: UNPUBLISH, isDisabled: props.optionsDisabled }]
                case "syncedUnpublished": return [{ label: PUBLISH, isDisabled: props.optionsDisabled }]
                case "syncedPublished": return [{ label: UNPUBLISH, isDisabled: props.optionsDisabled }]
                case "syncedUpdated": return [{ label: PUBLISH_CHANGES, isDisabled: props.optionsDisabled }, { label: UNPUBLISH, isDisabled: props.optionsDisabled}]
            }
    }
    const handleOptionSelected = async (label: string, event: any) => {
        switch (label) {
            case (PUBLISH):
            case (PUBLISH_CHANGES):{
                props.handlePublish();
                break;
            }
            case (UNPUBLISH): {
                props.handleUnpublish();
                break;
            }
        }
    }
    const handleClick = () => {
        if (props.dropDownDisabled || status == 'loading' || status == "incomplete")
            return
        setIsOverflowExpanded(!isOverflowExpanded);
    }
    const renderListView = () => {
        return (
            <ListViewContainer className={status} onClick={handleClick}>
                {status.includes("synced") ?
                    <div className="text">
                        <p className="synced-text">SYNCED</p>
                        <p>{getStatusText()}</p>
                    </div>
                    :
                    <p>{getStatusText()}</p>}
                {status == "incomplete" ?
                    <div className="incomplete">
                        <div className="incomplete-left" />
                        <img className={status} src={getStatusImage()} />
                        <div className="incomplete-right" />
                    </div> :
                    <>
                        <img className={status} src={getStatusImage()} />
                        <ToggleIndicator disabled={props.dropDownDisabled} type="chevron" direction={isOverflowExpanded ? "up" : "down"} />
                    </>}
                {(isOverflowExpanded && status != "loading") &&
                    <ListViewCustomOverflow
                        className={listViewOverflowStyle}
                        optionClassName={overflowOptionStyle}
                        topOptionStyle={topOptionStyle}
                        bottomOptionStyle={bottomOptionStyle}
                        onClose={() => setIsOverflowExpanded(false)}
                        onOptionSelected={handleOptionSelected}
                        options={getOptions()} />
                }
            </ListViewContainer>
        )
    }
    const renderFormView = () => {
        return (
            <FormViewContainer>
                <div className="text">
                    <p>STATUS</p>
                    <p className="status-text">{getStatusText()}</p>
                </div>
                <div className="status" onClick={handleClick}>
                    <img className={status} src={getStatusImage()} />
                    <ToggleIndicator disabled={props.dropDownDisabled || status == "incomplete"} type="chevron" direction={isOverflowExpanded ? "up" : "down"} />
                    {(isOverflowExpanded && status != "loading") &&
                        <FormViewCustomOverflow
                            className={formViewOverflowStyle}
                            optionClassName={overflowOptionStyle}
                            topOptionStyle={topOptionStyle}
                            bottomOptionStyle={bottomOptionStyle}
                            onClose={() => setIsOverflowExpanded(false)}
                            onOptionSelected={handleOptionSelected}
                            options={getOptions()} />
                    }
                </div>
            </FormViewContainer>
        )
    }
    return (
        props.isListView ?
            props.dropDownDisabled ?
                <TooltipWrapper text={'Please run a full <br /> publish of your app <br /> before starting any <br /> partial publishes to <br /> avoid errors'} place="bottom">
                    {renderListView()}
                </TooltipWrapper>
                :
                renderListView()

            :
            props.dropDownDisabled ?
                <TooltipWrapper text={'Please run a full <br /> publish of your app <br /> before starting any <br /> partial publishes to <br /> avoid errors'} place="bottom">
                    {renderFormView()}
                </TooltipWrapper>
                :
                renderFormView()
    );

}

const listViewOverflowStyle = css`
    width: 132px;
    margin-left: -122px;
    border-radius: 4px;
`;

const formViewOverflowStyle = css`
    width: 204px;
    margin-left: -122px;
    border-radius: 4px;
`;

const topOptionStyle = css`
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
`;

const bottomOptionStyle = css`
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
`;
const overflowOptionStyle = css`
    height: 32px;
    p {
        font-size: 12px;
        line-height: 16px;
        margin: 0 11px;
    }
`

const ListViewCustomOverflow = styled(OverflowMenu)`
    position: absolute;
    top: 24px;
    right: -20
`;

const FormViewCustomOverflow = styled(OverflowMenu)`
    position: absolute;
    top: 28px;
    right: -13
`;

const ListViewContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    .incomplete{
        display: flex;
        flex-direction: row;
        .incomplete-left{
        border-left: 1px solid ${color_shades_dark};
        height: 18px;
        margin-right: 6px;
        }
        .incomplete-right{
        border-right: 1px solid ${color_shades_dark};
        height: 18px;
        margin-left: -2px;
        }
    }
    
    .text{
        .synced-text{
            color:${color_shades_darker};
            font-size: 10px;
        }
        text-align: right;
    }
    &.loading {
        cursor: default;
    }
    flex: 1;
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        align-items: flex-end;
        text-align: right;
        margin-right: 8px;
    }
    img {
        margin-right: 8px;
        height: 19px;
        width: 20px;
        &.loading {
            animation: rotation 2s infinite linear;
        }
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`
const FormViewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .text{
        font-size: 10px;
        .status-text{
            font-size: 14px;
        }
        text-align: right;
        margin-right: 16px
    }
    .status{
        width: 48px;
        height: 28px;
        display: flex;
        border-radius: 20px;
        background-color: white;
        align-items: center;
        margin-left: auto;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &.loading {
            cursor: default;
        }
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            display: flex;
            align-items: flex-end;
            text-align: right;
            margin-right: 8px;
        }
        img {
            margin-right: 8px;
            height: 19px;
            width: 20px;
            &.loading {
                animation: rotation 2s infinite linear;
            }
        }
        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
        }
    }   
`
export default ContentItemListStatus;