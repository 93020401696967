import React, { useState } from 'react';
import { css } from '@emotion/css';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import CurrentApplicationSelector from './components/CurrentApplicationSelector';
import ApplicationSelector from './components/ApplicationSelector';
import SlideInSlideOutComponent from '../../components/structure/SlideInSlideOutComponent';
import { text_blue } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';

interface ApplicationSelectorProps {
    application: ApplicationModel
    appContainer: ApplicationContainer
    organizationId: string
}

const ApplicationSelectorMenu: React.FC<ApplicationSelectorProps> = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggle = () => {
        setIsExpanded(!isExpanded)
    };

    return (
        <div className={`${applicationSelectorMenuWrapper} ${isExpanded ? 'expanded' : ''}`}>
            <CurrentApplicationSelector
                onToggle={() => toggle()}
                application={props.application}
                isMinified={true}
                isOpen={isExpanded}
            />
            <SlideInSlideOutComponent
                showChildren={isExpanded}
                styleName="applicationSelectorSlide"
                direction="down"
                slideType="size"
            >
                <ApplicationSelector
                    organizationId={props.organizationId}
                    appContainer={props.appContainer}
                    application={props.application}
                />
            </SlideInSlideOutComponent>
        </div>
    )
}

const applicationSelectorMenuWrapper = css`
    height: 80px;
    padding-top: 16px;
    &.expanded {        
        background: ${text_blue};
    }
    ${breakpoint_small} {
        display: none;
    }
`;

export default ApplicationSelectorMenu;