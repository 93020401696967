import React from 'react';
import { css } from '@emotion/css';
import _ from 'lodash';
import GroupedMultiSelector, { ItemGroup, OptionItem, SelectorPosition } from '../../../components/forms/GroupedMultiSelector';
import { cool_grey, color_shades_light, color_shades_darkest } from '../../../constants/colors';
import EditableInteractionModel from '../../../models/interactionModel/EditableInteractionModel';
import EditableIntent from '../../../models/interactionModel/EditableIntent';
import { IntentsDisplayNamesWithContentItems, IntentVoicifyNamesWithContentItems } from '../../../constants/Intents';
import { PLATFORM_VOICIFY } from '../../../constants/platforms';
const addIcon = require('../../../content/images/add-circle-dark.svg');
interface IntentSelectorProps {
    handleIntentChange: (values: string[]) => any
    selectedIntent: EditableIntent
    selectedDisplayText: string
    label?: string
    position?: SelectorPosition
    id?: string
    interactionModel: EditableInteractionModel
    disabled?: boolean
}

const IntentSelector: React.FC<IntentSelectorProps> = (props) => {
    const isIntentWithDifferentContentItem = (intent: EditableIntent): boolean => {
        return IntentsDisplayNamesWithContentItems.includes(intent.displayName) 
            || (intent?.name[PLATFORM_VOICIFY] && IntentVoicifyNamesWithContentItems.includes(intent?.name[PLATFORM_VOICIFY]));
    }
    const getGroups = (): ItemGroup<EditableIntent>[] => {
        const groups: ItemGroup<EditableIntent>[] = [];
        groups.push({
            key: "__newIntent",
            label: 'Add a new intent',
            subItems: [],
            image: addIcon
        });
        const customIntentGroup: ItemGroup<EditableIntent> = {
            key: '__customIntents',
            label: 'Custom Intent',
            subItems: [],
            hideRadio: true
        }

        const preBuiltIntentGroup: ItemGroup<EditableIntent> = {
            key: '__preBuiltIntents',
            label: 'Pre-built Intent',
            subItems: [],
            hideRadio: true
        }

        const intents = Object.entries(props.interactionModel);
        for (const [intentKey, intent] of intents) {
            let label = intent.displayName;
            // Check if custom intent display name is unique, if not use the key as label
            const intentNameMatch = intents?.filter(entry => entry[1]?.displayName?.toLowerCase().trim() === intent.displayName?.toLowerCase().trim());
            if (intentNameMatch?.length > 1) {
                label = intent.key?.replace(/\|/g, " ");
            }

            const optionItem: OptionItem<EditableIntent> = {
                label: label,
                value: intent.key,
                isSelected: false,
                data: intent,
                groupKey: '',
                hideCheckbox: true
            }
            if (intent.isCustom) {
                optionItem.groupKey = "customIntents";
                customIntentGroup.subItems.push(optionItem);
            } else if (!isIntentWithDifferentContentItem(intent)) {
                optionItem.groupKey = "preBuiltIntents";
                preBuiltIntentGroup.subItems.push(optionItem);
            }
        }
        if (customIntentGroup.subItems.length) {
            groups.push(customIntentGroup);
        }
        if (preBuiltIntentGroup.subItems.length) {
            groups.push(preBuiltIntentGroup);
        }
        groups.push({
            key: "__externalIntent",
            label: 'External Intent',
            subItems: []
        });
        return groups;
    }
    return (
        <div id={props.id} className={`language-grouped-selector ${containerStyle} ${false ? 'minified' : 'full'} ${props.disabled ? 'disabled' : ''}`}>
            <GroupedMultiSelector
                selectorWrapperClassName={selectorWrapperStyle}
                groupToggle={groupToggleStyle}
                label={props.label}
                groups={getGroups()}
                onChange={(values) => props.handleIntentChange(values)}
                selectedValues={[props.selectedIntent?.key]}
                withGroupSelection={false}
                treatGroupAsValue={true}
                subItemCheckType={"radio"}
                restrictGroups={true}
                position={props.position ?? 'bottom'}
                emptyText="Select an intent"
                disabled={props.disabled}
                innerSelectionRender={() =>
                    <div>{props.selectedDisplayText}</div>
                } />
        </div>

    )

}

const selectorWrapperStyle = css`
    z-index: 2;
`;

const groupToggleStyle = css`
    margin-left: auto;
    margin-right: 0;
    height: 8px;
    width: 8px;
`

const containerStyle = css`
    margin-left: auto;
    margin-top: 16px;
    margin-right: 0;
    flex: 1;
    &.full {
        .field-wrapper {
            display: block;
            label {
                margin-left: 16px;
                font-size: 14px;
                color: ${color_shades_darkest};
            }
            .selection-section {
                margin-top: 8px;
            }
        }
    }
    &.minified {
        .field {
            .selection {
                border: none;
                background: transparent;
            }
        }
        .field-wrapper {        
            max-width: 350px;
            margin-left: auto;
        }
    }
    &.bordered {
        border-right: 1px solid #ccc;
    }
    &.disabled {
        .field .selection{
            background: ${color_shades_light};
            cursor: not-allowed;
        }
    }
    .field {
        .selection {
            border-radius: 4px;
            .empty-text {
                text-align: right;
                margin-left: auto;
                margin-right: 12px;
                width: 100%;
            }
        }
    }

    .sub-item {
        img {
            border-radius: 50%;
            border: 1px solid ${cool_grey};            
        }
    }
`;


export default IntentSelector;