import React from 'react';
import styled from '@emotion/styled';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import { color_text_default } from '../../../constants/colors';
import ContentCard from '../../../components/structure/ContentCard';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import TextAreaField from '../../../components/forms/TextAreaField';
import DetailLabel from '../../../components/general/DetailLabel';

interface OverviewProps {
    templateConfiguration: TemplateConfigurationModel
    onChange: (templateConfiguration: TemplateConfigurationModel) => void
}

const TemplateOverviewEditor: React.FC<OverviewProps> = ({
    templateConfiguration,
    onChange
}) => {
    const handleOwnerChange = (e) => {
        templateConfiguration.ownerName = e.target.value;
        onChange(templateConfiguration);
    }
    const handleDescriptionChange = (e) => {
        templateConfiguration.description = e.target.value;
        onChange(templateConfiguration);
    }
    return (
        <OverviewContainer>
            <h4>Setup</h4>
            <ContentCard title="Identity">
                <FormFieldsContainer>
                    <DetailLabel>Add some more detail about this module.</DetailLabel>
                    <TextField label="Creator Name" placeholder="The owner and creator of the template" name="templateConfiguration.ownerName" value={templateConfiguration?.ownerName ?? ''} required onChange={handleOwnerChange} />
                    <TextAreaField label="Detailed Description" placeholder="This is used in your listing" name="templateConfiguration.description" value={templateConfiguration?.description ?? ''} required onChange={handleDescriptionChange} />
                </FormFieldsContainer>
            </ContentCard>
        </OverviewContainer>
    );
}

const OverviewContainer = styled.div(`
padding: 24px 32px 64px 32px;
h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${color_text_default};
    margin-bottom: 24px;
}
`);

export default TemplateOverviewEditor;