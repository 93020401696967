export const ADD_TEXT_TO_SPEECH = (baseUrl: string, ttsProvider: string) => {
    return `${baseUrl}/api/Ssml/toSpeech/${ttsProvider}`
};

export const GET_SESSION = (baseUrl: string, sessionId: string, appId: string, appKey: string) => {
    return `${baseUrl}/api/UserProfile/session/${sessionId}?applicationId=${appId}&applicationSecret=${appKey}`
};

export const GET_USER_PROFILE = (baseUrl: string, userId: string, appId: string, appKey: string) => {
    return `${baseUrl}/api/UserProfile/${userId}?applicationId=${appId}&applicationSecret=${appKey}`
};

export const GET_CONFIGURATION_URL = (baseUrl: string, configurationId: string, appId: string, appKey: string) => {
    return `${baseUrl}/api/CustomAssistantConfiguration/${configurationId}/JavaScript?applicationId=${appId}&applicationSecret=${appKey}`
};