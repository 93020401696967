import React from 'react';
import { css } from '@emotion/css';
import TabContainerPanel from '../../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import Loader from '../../../components/general/Loader';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import { color_shades_dark } from '../../../constants/colors';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import TemplateFieldSelector from './TemplateFieldSelector';
import TemplateSectionSelector from './TemplateSectionSelector';
import Button from '../../../components/general/Button';
import TemplateOverviewEditor from './TemplateOverviewEditor';
import TemplateSectionEditor from './TemplateSectionEditor';
import generateGuid from '../../../services/guids';
import _ from 'lodash';
import MediaContainer from '../../../state/containers/MediaContainer';
import MarkdownPreview from './MarkdownPreview';
import AppliedApplicationTemplateSectionForm from '../../formScenes/AppliedApplicationTemplateSectionForm';
import TemplateOverviewCard from '../../../components/templates/TemplateOverviewCard';
const formFieldsIcon = require('../../../content/images/tab-icons/form-fields.svg');
const addIcon = require('../../../content/images/add-circle-dark.svg');
const fieldPreviewIcon = require('../../../content/images/tab-icons/field-preview.svg');
const fieldPreviewIconBlue = require('../../../content/images/tab-icons/field-preview-blue.svg');
const helpIconBlue = require('../../../content/images/tab-icons/help-info-blue.svg');
const helpIcon = require('../../../content/images/tab-icons/help-info.svg');
interface TemplateConfigurationContentProps {
    templateConfiguration: TemplateConfigurationModel
    templateConfigurationContainer: TemplateConfigurationContainer
    mediaContainer: MediaContainer
    isSaving: boolean
    organizationId: string
    history: any
    onChange: (templateConfiguration: TemplateConfigurationModel) => void
}
interface TemplateConfigurationContentState {
    isFinishSelected: boolean
    isStartSelected: boolean
    currentSection: TemplateFormSectionModel
}

export default class TemplateDetailsEditor extends React.Component<TemplateConfigurationContentProps, TemplateConfigurationContentState> {

    constructor(props) {
        super(props);
        this.state = {
            isFinishSelected: false,
            isStartSelected: true,
            currentSection: null
        }
    }
    handleNextPage() {
        const sections = this.props.templateConfiguration?.templateForm?.templateFormSections;
        if (this.state.isStartSelected && sections && sections.length > 0) {
            this.setState({
                ...this.state,
                isStartSelected: false,
                currentSection: sections ? sections[0] : null
            });
        } else if (sections && sections.length > 0 && this.state.currentSection != sections[sections.length - 1]) {
            this.setState({
                ...this.state,
                isStartSelected: false,
                currentSection: sections[sections.indexOf(this.state.currentSection) + 1]
            })
        }
    }
    handlePrevPage() {
        const sections = this.props.templateConfiguration?.templateForm?.templateFormSections;
        if (!this.state.isStartSelected && this.state.currentSection != sections[0]) {
            this.setState({
                ...this.state,
                isStartSelected: false,
                currentSection: sections[sections.indexOf(this.state.currentSection) - 1]
            })
        }
        else if (this.state.currentSection == sections[0]) {
            this.setState({
                ...this.state,
                isStartSelected: true,
                currentSection: null
            })
        }

    }
    handleFinishSelected() {
        this.setState({
            ...this.state,
            isFinishSelected: true,
            isStartSelected: false,
            currentSection: null
        })
    }
    handleStartSelected() {
        this.setState({
            ...this.state,
            isFinishSelected: false,
            isStartSelected: true,
            currentSection: null
        })
    }
    handleSectionSelected(section: TemplateFormSectionModel) {
        this.setState({
            ...this.state,
            currentSection: section,
            isFinishSelected: false,
            isStartSelected: false
        })
    }

    handleClose() {
        this.props.history.push(`/v/templates`);
    }
    handleAddSection() {
        const templateConfig = this.props.templateConfiguration;
        if (!templateConfig.templateForm?.templateFormSections)
            templateConfig.templateForm.templateFormSections = [];

        templateConfig.templateForm.templateFormSections.push({
            id: generateGuid(),
            title: 'New Page',
            instructionsMarkdown: '',
            templateFormFields: [],
        });
        templateConfig.templateForm?.templateFormSections?.forEach((s, i) => {
            s.priority = i
        });
        this.handleTemplateUpdate(templateConfig);
        this.handleSectionSelected(templateConfig.templateForm.templateFormSections[templateConfig.templateForm.templateFormSections.length - 1]);
    }
    handleTemplateUpdate(template: TemplateConfigurationModel) {
        this.props.onChange(template);
    }
    handleAddField(fieldType: string) {
        const section = this.props.templateConfiguration.templateForm.templateFormSections.find(s => s == this.state.currentSection);

        if (section.templateFormFields == null)
            section.templateFormFields = [];

        section.templateFormFields.push({
            id: generateGuid(),
            associatedVariable: '',
            title: '',
            tip: '',
            placeholder: '',
            options: [],
            isRequired: false,
            templateFormSectionId: section.id,
            fieldType
        });

        section.templateFormFields.forEach((f, i) => {
            f.priority = i;
        });
        this.handleTemplateUpdate(this.props.templateConfiguration);
    }

    handleSortSections(sections: TemplateFormSectionModel[]) {
        sections.forEach((s, i) => {
            s.priority = i;
        });

        const templateConfig = this.props.templateConfiguration;
        templateConfig.templateForm.templateFormSections = sections;

        this.handleTemplateUpdate(templateConfig);
    }

    handleDeleteSection(section: TemplateFormSectionModel) {
        const templateConfig = this.props.templateConfiguration;
        _.remove(templateConfig.templateForm.templateFormSections, s => s.id == section.id);

        this.setState({
            ...this.state,
            isStartSelected: true,
            currentSection: null
        });
        this.handleTemplateUpdate(templateConfig);
    }

    renderMenu() {
        return (
            <div className={"fixed-wrapper"}>
                <PanelHeaderContainer className="template-section-menu-header">
                    <h4>Steps</h4>
                    <Button type="button" themes={['end', 'icon']} icon={addIcon} onClick={this.handleAddSection.bind(this)} />
                </PanelHeaderContainer>
                <ScrollablePanelBody className={panelBodyStyle}>
                    <TemplateSectionSelector
                        sections={this.props.templateConfiguration?.templateForm?.templateFormSections ?? []}
                        activeSection={this.state.currentSection}
                        isStartSelected={this.state.isStartSelected}
                        onStartSelect={this.handleStartSelected.bind(this)}
                        onChange={this.handleSectionSelected.bind(this)}
                        onSort={this.handleSortSections.bind(this)} />
                </ScrollablePanelBody>
            </div>);
    }
    getTabs() {
        // if we are on the start page, then don't add the fields tab
        const tabs = [{
            title: "Step Preview",
            icon: fieldPreviewIcon,
            selectedIcon: fieldPreviewIconBlue
        }, {
            title: 'Helpful Info',
            icon: helpIcon,
            selectedIcon: helpIconBlue
        }];
        if (!this.state.isStartSelected && !this.state.isFinishSelected) {
            tabs.unshift({
                title: 'Form Fields',
                icon: formFieldsIcon,
                selectedIcon: formFieldsIcon
            })
        }
        return tabs;
    }
    render() {
        const { isLoading, isSaving } = this.props.templateConfigurationContainer.state;
        if (isLoading)
            return <Loader />

        return (
            <PanelContainer>

                <div className={`${menuStyle} expanded`}>
                    {this.renderMenu()}
                </div>
                <div className={middlePanelContainer}>
                    <PanelHeaderContainer className="panel-header">
                        <Button type="submit" themes={['primary-small', 'start']} text="Save" className="save-button" disabled={isSaving} loading={isSaving} />
                        <Button type="button" themes={['secondary-small', 'end']} text="Prev Step" className="prev-button" disabled={this.state.isStartSelected} onClick={this.handlePrevPage.bind(this)} />
                        <Button type="button" themes={['secondary-small', 'end']} text="Next Step" className="next-button" onClick={this.handleNextPage.bind(this)} />
                    </PanelHeaderContainer>
                    <ScrollablePanelBody>
                        {this.state.isStartSelected
                            ? <TemplateOverviewEditor onChange={this.props.onChange} templateConfiguration={this.props.templateConfiguration} />
                            : <TemplateSectionEditor onChange={this.handleTemplateUpdate.bind(this)} section={this.state.currentSection} templateConfiguration={this.props.templateConfiguration} onDelete={this.handleDeleteSection.bind(this)} />}
                    </ScrollablePanelBody>
                </div>
                <TabContainerPanel tabs={this.getTabs()}>
                    {!this.state.isStartSelected && !this.state.isFinishSelected && <TabPanel>
                        <ScrollablePanelBody>
                            <TemplateFieldSelector templateConfiguration={this.props.templateConfiguration} section={this.state.currentSection} onFieldSelected={this.handleAddField.bind(this)} />
                        </ScrollablePanelBody>
                    </TabPanel>}
                    <TabPanel>
                        <ScrollablePanelBody>
                            {this.state.isStartSelected
                                ? <TemplateOverviewCard templateConfiguration={this.props.templateConfiguration}/>
                                : <AppliedApplicationTemplateSectionForm fieldsOnly values={[]} onFieldValueChange={() => { }} section={this.state.currentSection} applicationId={null} preview = {true}/>
                            }
                        </ScrollablePanelBody>
                    </TabPanel>
                    <TabPanel>
                        <ScrollablePanelBody className={mainBodyStyle}>
                            <MarkdownPreview instructionsMarkdown={this.state.isStartSelected ? (this.props.templateConfiguration?.templateForm?.instructionsMarkdown ?? '') : this.state.currentSection?.instructionsMarkdown ?? ''} />
                        </ScrollablePanelBody>
                    </TabPanel>
                </TabContainerPanel>
            </PanelContainer>)
    }
}

const middlePanelContainer = css`
    flex: 1;
    border-right: 1px solid ${color_shades_dark};
    .overview-empty-label {
        margin: 32px;
    }
    .panel-header {
        button {
            margin: 0;
        }
        .save-button {
            margin-left: 32px;
        }
        .next-button {
            margin-right: 32px;
        }
        .prev-button {
            margin-right: 16px;
            margin-left: auto;
        }
    }
`

const panelBodyStyle = css`
    padding-top: 0;
    min-height: 100%;
`

const mainBodyStyle = css`
    padding: 24px 32px 64px 32px;
`

const menuStyle = css`
z-index: 2;
bottom: 0;
position: relative;
.template-section-menu-header {    
    h4 {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 32px;
    }
    button {
        margin-right: 16px;
        margin-left: auto;
    }
}
&.collapsed {
    width: 80px;
    .fixed-wrapper {
        width: 80px;
    }
}
&.expanded {
    width: 288px; 
    .fixed-wrapper {
        width: 288px;
    }
}

.fixed-wrapper {
    position: fixed;
    bottom: 0;
    height: calc(100vh - 80px);
    background: white;
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
}

`
