import { css } from "@emotion/css";
import React from 'react';
import { color_colors_decline } from '../../constants/colors';

interface ErrorLabelProps {
    image: string;
    text?: string;
    className?: string;
}

const ErrorLabel = (props: ErrorLabelProps) => {

    return (
        <div className={`${errorLabelWrapper} ${props.className ?? ""}`}>
            <img src={props.image} />
            <div className={"error-text"}>{props.text}</div>
        </div>
    )

}

const errorLabelWrapper = css`
    color: ${color_colors_decline};
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid ${color_colors_decline};
    width: auto;
    height: 26px;
    margin: 16px;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    img {
        margin-right: 8px;
        height: 20px;
        width: auto;
    }
    .error-text {
        color: ${color_colors_decline};
        margin-right: 0px;
        padding: 0px;
    }
`;

export default ErrorLabel;
