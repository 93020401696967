import React from "react";
import { Provider, Subscribe } from 'unstated';
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import PageContainer from "../../components/structure/PageContainer";
import ApplicationContainer from "../../state/containers/ApplicationContainer";
import UserContainer from "../../state/containers/UserContainer";
import TemplateConfigurationContainer from "../../state/containers/TemplateConfigurationContainer";
import OrganizationTemplatesListView from "./components/OrganizationTemplatesListView";

interface OrganizationTemplatesProps {
    history: any
}
const OrganizationTemplates: React.FC<OrganizationTemplatesProps> = (props) => (
    <Subscribe to={[TemplateConfigurationContainer, OrganizationContainer]}>
        {(templatesContainer: TemplateConfigurationContainer, orgContainer: OrganizationContainer) => {
            return (
                <OrganizationTemplatesListView templatesContainer={templatesContainer} orgContainer={orgContainer} history={props.history} />
            );
        }}
    </Subscribe>
);


export default OrganizationTemplates;
