import React, { useEffect } from 'react';
import * as yup from 'yup';
import { TabPanel } from 'react-tabs';
import BackButton from '../../../components/general/BackButton';
import HeaderTitleView from '../../../components/structure/HeaderTitleView';
import { BreadcrumbItem } from '../../../components/general/Breadcrumb';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import PageError from '../../../components/general/PageError';
import FullPageTabbedPanel from '../../../components/structure/Panels/FullPageTabbedPanel';
import { FormikProps, withFormik } from 'formik';
import TemplateSetupEditor from './TemplateSetupEditor';
import _ from 'lodash';
import TemplateConfigurationMenuSelector from './TemplateConfigurationMenuSelector';
import MediaContainer from '../../../state/containers/MediaContainer';
import TemplateDetailsEditor from './TemplateDetailsEditor';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import UpdateAppliedApplicationTemplateFormRequest from '../../../models/templating/api/UpdateAppliedApplicationTemplateFormRequest';

const builderIcon = require('../../../content/images/tab-icons/template-builder.svg');
const setupIcon = require('../../../content/images/tab-icons/setup.svg');

interface TemplateConfigurationContentProps {
    templateConfigurationId?: string
    mediaContainer: MediaContainer
    orgContainer: OrganizationContainer
    templateConfigContainer: TemplateConfigurationContainer
    templateConfiguration: TemplateConfigurationModel
    history: any
    appliedApplicationTemplateContainer: AppliedApplicationTemplateContainer
}

interface TemplateConfigurationFormData {
    templateConfiguration: TemplateConfigurationModel
}

const TemplateConfigurationContent: React.FC<TemplateConfigurationContentProps & FormikProps<TemplateConfigurationFormData>> = (props) => {

    useEffect(() => {
        const loadTemplateConfigurations = async () => {
            await props.templateConfigContainer.getOrganizationTemplates(props.orgContainer.state.currentOrganization.id)
        }
        if (props.templateConfiguration == null) {
            loadTemplateConfigurations();
        }
        props.templateConfigContainer.loadCategories();
        props.templateConfigContainer.loadIndustries();
        props.setFieldValue("templateConfiguration", props.templateConfiguration);
    }, [props.templateConfiguration]);


    const handleClose = () => {
        props.history.push(`/v/templates`);
    }

    const getBreadCrumbItems = () => {
        var crumbs: BreadcrumbItem[] = [{
            id: 'org',
            title: props.orgContainer.state.currentOrganization?.name,
            link: '/'
        }]

        return crumbs;
    }

    const handleTemplateUpdate = (template: TemplateConfigurationModel) => {
        props.setFieldValue("templateConfiguration", template);
    }


    const { isSaving } = props.templateConfigContainer.state;
    return (
        <form onSubmit={props.handleSubmit}>
            <FullPageTabbedPanel tabs={[{
                title: "Setup",
                icon: setupIcon,
                selectedIcon: setupIcon
            }, {
                title: "Form Creator",
                icon: builderIcon,
                selectedIcon: builderIcon
            }
            ]}
                leftRender={() => <>
                    <BackButton onClick={handleClose.bind(this)} />
                    <TemplateConfigurationMenuSelector
                        templateConfiguration={props.values.templateConfiguration}
                        templateConfigContainer={props.templateConfigContainer}
                        currentTemplateId={props.values.templateConfiguration?.id}
                        history={props.history} />
                </>}
                titleRender={() => <HeaderTitleView text={props.templateConfiguration?.name} breadcrumb={getBreadCrumbItems()} />}
                rightRender={() => null}>
                <TabPanel>
                    <TemplateSetupEditor
                        history={props.history}
                        onChange={handleTemplateUpdate.bind(this)}
                        isSaving={isSaving} templateConfigurationContainer={props.templateConfigContainer}
                        templateConfiguration={props.values.templateConfiguration}
                        mediaContainer={props.mediaContainer}
                        organizationId={props.orgContainer.state.currentOrganization?.id} />
                </TabPanel>
                <TabPanel>
                    <TemplateDetailsEditor
                        history={props.history}
                        onChange={handleTemplateUpdate.bind(this)}
                        isSaving={isSaving}
                        templateConfigurationContainer={props.templateConfigContainer}
                        templateConfiguration={props.values.templateConfiguration}
                        mediaContainer={props.mediaContainer}
                        organizationId={props.orgContainer.state.currentOrganization?.id} />
                </TabPanel>
            </FullPageTabbedPanel>
            <PageError errors={props.templateConfigContainer.state.errors} onClear={() => props.templateConfigContainer.clearErrors()} />
        </form>
    );

}

const TemplateConfigurationForm = withFormik<TemplateConfigurationContentProps, TemplateConfigurationFormData>({
    mapPropsToValues: (props) => ({
        templateConfiguration: props.templateConfiguration
    }),
    validationSchema: yup.object().shape({
        templateConfiguration: yup.object().shape({

            // NOTE: if we want to do client-side validation on fields, we can use this - but it doesn't fit 
            // well since we have the ability to navigate between pages and it will hide field errors

            // name: yup.string().required("You must provide a name for your template"),
            // description: yup.string().required("You must provide a description for your template"),
            // templateForm: yup.object().shape({
            //     templateFormSections: yup.array().of(
            //         yup.object().shape({
            //             title: yup.string().required("You must enter a title for this section"),
            //             templateFormFields: yup.array().of(
            //                 yup.object().shape({
            //                     title: yup.string().required("You must enter a title for this field")
            //                 })
            //             )
            //         })
            //     ).required("You need at least one section")
            // }).required("You need a form for a template")
        }).required("You need to send a valid configuration")
    }),
    handleSubmit: async (values, { props }) => {
        if (!values.templateConfiguration.currentVersion) {
            values.templateConfiguration.currentVersion = {
                versionName: '1.0'
            }
        }
        await props.templateConfigContainer.updateTemplateConfiguration(values.templateConfiguration);
    }
})(TemplateConfigurationContent);

export default TemplateConfigurationForm;