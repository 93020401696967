import React, { Ref, RefObject } from "react";
import Button from "../../../components/general/Button";
import { css } from '@emotion/css';
import _ from "lodash";
import Slot from "../../../models/interactionModel/Slot";
import ToggleIndicator from "../../../components/general/ToggleIndicator";
import LanguageModel from "../../../models/features/api/LanguageModel";
import { color_shades_lighter, color_shades_dark, color_text_default, ocean_blue } from "../../../constants/colors";
import PhraseSlot from "../../../models/interactionModel/PhraseSlot";
import EditableIntent from "../../../models/interactionModel/EditableIntent";
import { SlotsEditorFormData } from "./SlotsEditorForm";
import EditableSlot from "../../../models/interactionModel/EditableSlot";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import SlotsEditorModal from "./SlotsEditorModal";
import SlotSelector from "./slotSelector";
import ApplicationModel from "../../../models/applications/api/ApplicationModel";


type InteractionModelInputProps = {
    applicationId: string;
    nlpEntities: EditableNlpEntity[]
    intentName: string;
    languages?: LanguageModel[];
    isLoading: boolean;
    intent: EditableIntent,
    validateUtterance: (utterance: string) => string[];
    setError: (errors: string[]) => any;
    addUtterance: (phrase: string, languages: LanguageModel[]) => any;
    extractSlots: (phrase: string) => PhraseSlot[];
    updateSlots: (updatedSlots: EditableSlot[]) => void;
    detectSlots: (phrase: string) => Promise<string>;
}

type InteractionModelInputState = {
    validationErrors: string[];
    inputText: string;
    slotsStyled: string[];
    insertingSlot: boolean;
    isSlotsEditorModalOpen: boolean;
    selectionNode: Node;
    anchorOffset: number;
    previousChildren: string[];
}

export default class InteractionModelInput extends React.Component<InteractionModelInputProps, InteractionModelInputState>{
    textInput = React.createRef<HTMLDivElement>();

    componentWillMount() {
        this.setState({
            validationErrors: [],
            slotsStyled: [],
            insertingSlot: false,
            isSlotsEditorModalOpen: false
        });
    }

    componentDidMount() {
        this.textInput?.current?.focus();
    }

    

    handleAddUtterance() {
        let text = this.textInput.current.innerText.replace(/\u200b/g, '');
        // replace any extra spaces with a single space
        text = text.replace(/\s\s+/g, ' ');
        const validationErrors = this.props.validateUtterance(text);
        if (validationErrors.length > 0) {
            this.detectSlots(true);
        } else {
            this.props.addUtterance(text, this.props.languages)
            this.textInput.current.innerHTML = "";
            this.props.setError([])
            this.setState({
                ...this.state,
                slotsStyled: [],
                insertingSlot: false
            })

        }
    }

    preventLineBreak(e: React.KeyboardEvent<HTMLInputElement>) {
        if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13))
            e.preventDefault();
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>) {
        var divElement = e.target;
        this.handleSlotChanged(divElement, e.keyCode == 8);
        if (e.key === "Enter") {
            this.handleEnter();
        }
    }

    async detectSlots(reValidate: boolean = false) {
        this.textInput.current.innerText = this.textInput.current.innerText.replace(/\s\s+/g, ' ');
        const result = await this.props.detectSlots(this.textInput.current.innerText);
        if (result?.length)
            this.textInput.current.innerText = result;
        if (reValidate) {
            const validationErrors = this.props.validateUtterance(result);
            if(validationErrors?.length)
                this.props.setError(validationErrors)
        }
        return result;
    }

    async handleEnter() {
        const currentText = `${this.textInput.current.innerText}`;
        // drop multiple spaces to one space
        let text = this.textInput.current.innerText.replace(/\s\s+/g, ' ');
        const result = await this.props.detectSlots(text);
        if (result?.length && currentText !== result) {
            this.textInput.current.innerText = result;
        }
        else {
            this.handleAddUtterance();
        }
    }

    handleSlotChanged(e: HTMLElement, backspacing = false, splitChildAtIndex?) {
        var slotsExtracted = this.props.extractSlots(e.innerText);
        var slots = slotsExtracted.map(s => s.fullText);
        var newSlot = _.difference(slots, this.state.slotsStyled);
        var deletedSlot = _.difference(this.state.slotsStyled, slots);
        var openBracketCount = this.textInput.current.textContent.match(/\{/g) ? this.textInput.current.textContent.match(/\{/g).length : 0;
        var closedBracketCount = this.textInput.current.textContent.match(/\}/g) ? this.textInput.current.textContent.match(/\}/g).length : 0;
        var inequalBracketCount = openBracketCount != closedBracketCount;

        if (!inequalBracketCount && (newSlot.length > 0 || deletedSlot.length > 0)) {
            var html = e.innerText;
            slots.map(s => { html = html.replace(`${s}`, `<span contenteditable=false style="background-color:${color_shades_lighter}">${s}</span>`) });
            e.innerHTML = html

            var childNodes = Array.from(this.textInput.current.childNodes);
            var sel = document.getSelection();
            if (splitChildAtIndex >= 0 && childNodes.length >= splitChildAtIndex + 2) {
                var targetNode = childNodes[splitChildAtIndex + 2];
                if (targetNode.nodeType != 3) {
                    sel.collapse(this.textInput.current, splitChildAtIndex + 2)
                }
                else {
                    sel.collapse(targetNode, 0)
                }
            }
            else {
                if (backspacing) {
                    var priorChildIndex;
                    var priorChildText;
                    if (deletedSlot.length > 0) {
                        this.state.previousChildren.forEach((n, i) => {
                            if (n == deletedSlot[0]) {
                                priorChildIndex = i - 1;
                                priorChildText = this.state.previousChildren[priorChildIndex];
                                if (priorChildIndex == -1) {
                                    sel.collapse(this.textInput.current, 0)
                                }
                                else if (i == this.state.previousChildren.length - 1) {
                                    if (priorChildText.indexOf('{') >= 0) {
                                        sel.collapse(this.textInput.current, childNodes.length);
                                    }
                                    else {
                                        var targetNode = childNodes[priorChildIndex];
                                        sel.collapse(targetNode, priorChildText.length);
                                    }
                                }
                                else {
                                    if (priorChildText.indexOf('{') >= 0) {
                                        var targetNode = childNodes[priorChildIndex + 1];
                                        targetNode.textContent.indexOf('{') >= 0 ? sel.collapse(this.textInput.current, priorChildIndex + 1) : sel.collapse(targetNode, 0);
                                    }
                                    else {
                                        var targetNode = childNodes[priorChildIndex];
                                        sel.collapse(targetNode, priorChildText.length);
                                    }
                                }
                            }
                        })
                    }
                }
                else {
                    var nextChildIndex;
                    var nextChildText; //Refactor to accept isBlockNode() function that checks text node vs styled node
                    if (newSlot.length >= 1) {
                        childNodes.forEach((n, i) => {
                            if (n.textContent == newSlot[newSlot.length - 1]) {
                                nextChildIndex = i + 1;
                                if (nextChildIndex >= childNodes.length) {
                                    sel.collapse(this.textInput.current, childNodes.length)
                                }
                                else {
                                    nextChildText = childNodes[i + 1].textContent
                                    if (nextChildText.indexOf('{') >= 0) {
                                        sel.collapse(this.textInput.current, i + 1)
                                    }
                                    else {
                                        sel.collapse(childNodes[i + 1], 0)
                                    }
                                }
                            }
                        })
                    }
                    else if (deletedSlot.length >= 1) {
                        this.state.previousChildren.forEach((n, i) => {
                            if (n == deletedSlot[deletedSlot.length - 1]) {
                                nextChildIndex = i
                                if (i == childNodes.length) {
                                    var targetNode = childNodes[i - 1];
                                    if (targetNode == null || targetNode.textContent.indexOf('{') >= 0)
                                        sel.collapse(this.textInput.current, childNodes.length)
                                    else {
                                        sel.collapse(targetNode, this.state.previousChildren[i - 1].length)
                                    }
                                }
                                else {
                                    nextChildText = childNodes[i].textContent
                                    if (nextChildText.indexOf('{') >= 0) {
                                        var targetNode = childNodes[i - 1];
                                        targetNode ? sel.collapse(targetNode, this.state.previousChildren[i - 1].length) : sel.collapse(this.textInput.current, i)
                                    }
                                    else {
                                        sel.collapse(childNodes[i], 0)
                                    }
                                }
                            }
                        })
                    }
                }
            }
            this.setState({
                ...this.state,
                slotsStyled: slots,
                previousChildren: Array.from(childNodes).map(n => n.textContent)
            })
        }
        else {
            this.setState({
                ...this.state,
                previousChildren: Array.from(this.textInput.current.childNodes).map(n => n.textContent)
            })
        }
    }

    toggleInsertSlot() {
        this.setState({
            ...this.state,
            insertingSlot: !this.state.insertingSlot
        })
    }

    toggleSlotsEditorModal() {
        this.setState({
            ...this.state,
            isSlotsEditorModalOpen: !this.state.isSlotsEditorModalOpen
        })
    }

    addSlot(slot: Slot) {

        const childNodes = this.textInput.current.childNodes;
        let indexOfCaret = 0;
        let foundSameNode = false;
        let splitChildAtIndex;
        const isTextNode = this.state.selectionNode.nodeType == 3;

        childNodes.forEach((node, key) => {
            if (!foundSameNode) {
                let isSameNode = isTextNode ? this.state.selectionNode.isSameNode(node) : key == this.state.anchorOffset;
                if (isSameNode) {
                    if (isTextNode) {
                        indexOfCaret += this.state.anchorOffset;
                    }
                    foundSameNode = true;
                    if (key != childNodes.length - 1) {
                        if (isTextNode) {
                            splitChildAtIndex = key;
                        } else {
                            splitChildAtIndex = key - 1;
                        }

                    }
                } else {
                    indexOfCaret += node.textContent.length
                }
            }
        })
        var start = this.textInput.current.innerText.slice(0, indexOfCaret);
        var end = this.textInput.current.innerText.slice(indexOfCaret);
        this.textInput.current.innerText = `${start}{${slot.name}}${end}`

        this.handleSlotChanged(this.textInput.current, false, splitChildAtIndex)
    }

    storeCursorPosition() {
        var sel = window.getSelection();
        this.setState({
            ...this.state,
            selectionNode: sel.anchorNode,
            anchorOffset: sel.anchorOffset
        })
    }

    handleIntentSlotsEdits(formData: SlotsEditorFormData) {
        const updatedSlots: EditableSlot[] = formData.intent.slots;
        this.props.updateSlots(updatedSlots);
        this.toggleSlotsEditorModal();
    }

    render() {
        const intent = this.props.intent;
        const availableSlots = intent?.slots?.filter(s => !s.isDeleted);

        return (
            <div className={interactionModelInput}>
                <div className={inputArea}>
                    <div className={labels}>
                        <div>Add a new phrase</div>
                        <div className="slots-actions-container">
                            {((!intent?.name || !intent.name.hasOwnProperty("voicify"))
                                || (intent.name["voicify"] !== "GeneralQuestion" && intent.name["voicify"] !== "WhatsNew")) &&
                                <div
                                    className="slot-menu-label right-border"
                                    onClick={this.toggleSlotsEditorModal.bind(this)}>Manage Slots</div>
                            }
                            <div
                                className="slot-menu-label"
                                onClick={this.toggleInsertSlot.bind(this)}>Insert a Slot</div>
                            <ToggleIndicator className="toggle-indicator" direction={this.state.insertingSlot ? 'up' : 'down'} />
                        </div>
                        <SlotSelector
                            addSlot={this.addSlot.bind(this)}
                            availableSlots={availableSlots}
                            usedSlots={this.state.slotsStyled}
                            hidden={!this.state.insertingSlot} />
                    </div>
                    <div contentEditable
                        onBlur={this.storeCursorPosition.bind(this)}
                        className={input}
                        ref={this.textInput}
                        onKeyPress={this.preventLineBreak.bind(this)}
                        onKeyUp={this.handleInputChange.bind(this)}>
                    </div>
                </div>
                <Button
                    className="add-phrase-button"
                    themes={["primary-small"]}
                    type="button"
                    loading={this.props.isLoading}
                    onClick={this.detectSlots.bind(this)}
                    text={"Detect Slots"} />
                
                <Button
                    className="add-phrase-button"
                    themes={["primary-small"]}
                    type="button"
                    loading={this.props.isLoading}
                    onClick={this.handleAddUtterance.bind(this)}
                    text={"Add Phrase"} />

                {this.state.isSlotsEditorModalOpen &&
                    <SlotsEditorModal
                        languages={this.props.languages}
                        isLoading={this.props.isLoading}
                        applicationId={this.props.applicationId}
                        handleClose={this.toggleSlotsEditorModal.bind(this)}
                        handleIntentSlotsEdits={this.handleIntentSlotsEdits.bind(this)}
                        intent={intent}
                        nlpEntities={this.props.nlpEntities} />
                }
            </div>
        )
    }
}


const interactionModelInput = css`
    height: 100px;
    padding: 30px;
    padding-bottom: 40px;
    background-color: white;
    font-size: 14px;
    color: ${color_text_default};
    border: solid 1px ${color_shades_dark};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 0px;
    margin: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 2;

    .add-phrase-button {
        margin-top: 40px;
    }

    .slots-actions-container {
        display: flex;
        align-items: center;
        .slot-menu-label {
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${ocean_blue};
            cursor: pointer;
            margin-right: 4px;
        
        }
        .right-border {
            padding-right: 4px;
            border-right: 1px solid gray;
        }
        .toggle-indicator {
            border-left: solid 1px ${ocean_blue};
            border-top: solid 1px ${ocean_blue};
        }
    }
`;

const inputArea = css`
    display: flex;
    flex-direction: column;
    flex: 1 500px;
    flex-basis: 500px;
    > input {
        border-radius: 5px;
        height: 45px;
    }
`;

const labels = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    margin-bottom: 6px;
`;

const input = css`
    background-color: white;
    width: 97%;
    border-radius: 5px;
    border: solid 1px #cccccc;
    padding: 12px;
    height: 40px;
    margin-top: 8px;
`;

