import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaSmallWrapper from './AlexaSmallWrapper';

const screenStyle = css`
    align-items: center;
    justify-content: center;
    video {
        width: 1080px;
    }
`
class AlexaSmallVideoPreview extends ScreenPreviewComponent {
    render() {
        return (
            <AlexaSmallWrapper className={screenStyle}>
                {this.state.videoUrl ?
                    <video controls>
                        <source src={this.state.videoUrl} type="video/mp4" />
                        Your browser does not support videos.
                    </video>
                : null}
            </AlexaSmallWrapper>
        )
    }
}

export default AlexaSmallVideoPreview;