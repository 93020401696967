import React from 'react';
import { Subscribe } from 'unstated';
import ExitMessageContainer from '../../state/containers/ExitMessageContainer';
import EditExitMessageForm from './components/EditExitMessageForm';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import ExitMessageModel from '../../models/features/api/Exit/ExitMessageModel';
import IResult from '../../models/result/IResult';
import GenericEditContentItemPage from '../formScenes/GenericEditContentItemPage';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { ExitMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import queryString from 'query-string';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';

interface ApplicationRouteProps {
    applicationFeatureId: string
    exitMessageId: string
}
interface EditExitMessageProps extends RouteComponentProps<ApplicationRouteProps> { }
class EditExitMessage extends React.Component<EditExitMessageProps> {
    render() {
        return (
            <Subscribe key={this.props.match.params.exitMessageId + this.props.match.params.applicationFeatureId}
                to={[ExitMessageContainer,
                    ApplicationContainer,
                    RepromptContainer,
                    FollowUpContainer,
                    TitleStateContainer,
                    MediaResponseStateContainer,
                    FeatureContainer,
                    ApplicationEnvironmentsContainer,
                    ApplicationBackupVersionsContainer]}>
                {(exitContainer: ExitMessageContainer,
                    appContainer: ApplicationContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    titleContainer: TitleStateContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer,
                    environmentsContainer: ApplicationEnvironmentsContainer,
                    backupsContainer: ApplicationBackupVersionsContainer) => {
                    return (
                        <GenericEditContentItemPage
                            stateContainer={exitContainer}
                            environmentsContainer={environmentsContainer}
                            backupsContainer={backupsContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="Edit Exit Message"
                            contentItemId={this.props.match.params.exitMessageId}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={ExitMessagesFeatureTypeId}
                            formRender={(contentItem: ExitMessageModel,
                                application,
                                isTemplateOwned,
                                assistantApplication,
                                onShowPublishModal,
                                onSubmit: (model: ExitMessageModel, onSuccess) => Promise<IResult<ExitMessageModel>>,
                                toggleSync: (id, shouldNotSync) => Promise<IResult<ExitMessageModel>>,
                                onAccessTypeChange) => (
                                <EditExitMessageForm
                                    appContainer={appContainer}
                                    followUpContainer={followUpContainer}
                                    repromptContainer={repromptContainer}
                                    location={this.props.location}
                                    application={application}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    exitMessage={contentItem}
                                    stateContainer={exitContainer}
                                    responseStateContainer={responseContainer}
                                    featureContainer={featureContainer}
                                    assistantApplication={assistantApplication}
                                    onShowPublishModal={onShowPublishModal}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onSubmit={onSubmit}
                                    onToggleSync={toggleSync}
                                    previousContentId={queryString?.parse(this.props.location.search)?.previousContentId as string}
                                    history={this.props.history}
                                    isSaving={exitContainer.state.isSaving}
                                    isTemplateOwned={isTemplateOwned} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default EditExitMessage;