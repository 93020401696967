import React from 'react';
import {css} from '@emotion/css';
import { color_shades_lighter, color_text_link, color_shades_dark } from '../../constants/colors';
import Button from './Button';
const icon = require('../../content/images/templates-icons/templates-sync-dark.svg');
interface TemplateBannerProps {
    isLinked: boolean
    isLoading: boolean
    onRelink: () => void
    onUnlink: () => void
}

const TemplateLockedBanner: React.SFC<TemplateBannerProps> = (props) => (
    <div className={`${containerStyle} ${props.isLinked ? 'linked': 'unlinked'}`}>
        <img src={icon}/>
        <p>{props.isLinked ? 'This content is managed via a module, and cannot be edited directly while linked' : 'This content was generated via a module, but has been unlinked from it'}</p>
        <Button type="button" themes={['hollow', 'end']} onClick={props.isLinked ? props.onUnlink : props.onRelink} text={props.isLinked ? 'Unlink' : 'Re-link'} disabled={props.isLoading} loading={props.isLoading}/>
    </div>
);

const containerStyle = css`
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 32px;
    img {
        min-width: 24px;
        min-height: 24px;
    }

    p {
        padding: 0 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        border-right: 1px solid ${color_shades_dark};
        flex: 1;
    }

    button {
        border: none;
        color: ${color_text_link};
        margin-right: 0;
        margin-left: 24px;
        
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    &.linked {
        background: rgba(246, 161, 47, 0.2);
    }
    &.unlinked {
        background: ${color_shades_lighter};
    }
`;

export default TemplateLockedBanner