import React, { FocusEvent } from 'react';
import {css} from '@emotion/css';
import { Subscribe } from 'unstated';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FeatureContainer from '../../state/containers/FeatureContainer';
import ConditionsContainer from '../../state/containers/ConditionsContainer';
import ResponseInstanceModel from '../../models/features/api/ResponseInstanceModel';
import Responses from './components/Responses';
import LanguageModel from '../../models/features/api/LanguageModel';
import ConditionInstanceModel from '../../models/features/api/Conditions/ConditionInstanceModel';
import ResponseGroup from '../../models/features/ResponseGroup';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ApplicationFlagsContainer from '../../state/containers/ApplicationFlagsContainer';

interface ResponsesManagerProps {
    applicationId: string
    applicationFeatureId: string
    emptyFollowUpOptionText?: string
    emptyFollowUpOptionIcon?: string
    hideFollowUp?: boolean
    languages: LanguageModel[]
    responseGroups: ResponseGroup[]
    getConditionsKey: (conditionInstances: ConditionInstanceModel[]) => string
    onChange: (responses: ResponseInstanceModel[]) => void
    isDisabled?: boolean
    onBlur?: (e: FocusEvent<any>) => void
}
const ResponsesManager: React.FC<ResponsesManagerProps> = (props) => (
    <Subscribe to={[FeatureContainer, FollowUpContainer, RepromptContainer, ConditionsContainer, MediaResponseStateContainer, ApplicationFlagsContainer]}>
        {(featureContainer: FeatureContainer,
         followUpContainer: FollowUpContainer,
         repromptContainer: RepromptContainer, 
         conditionsContainer: ConditionsContainer,
         mediaResponseContainer: MediaResponseStateContainer,
         appFlagsContainer: ApplicationFlagsContainer) => (
            <Responses
                featureContainer={featureContainer}
                followUpContainer={followUpContainer}
                repromptContainer={repromptContainer}
                conditionsContainer={conditionsContainer}
                mediaResponseContainer={mediaResponseContainer}
                appFlagsContainer={appFlagsContainer}
                {...props}/>
        )}
    </Subscribe>
);

export default ResponsesManager;