import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Subscribe } from 'unstated';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import ApplicationDashboardContent from './components/ApplicationDashboardContent';
import FeatureContainer from '../../state/containers/FeatureContainer';
import ContentTreeStateContainer from '../../state/containers/ContentTreeStateContainer';
import ImportExportContainer from '../../state/containers/ImportExportContainer';
import TemplateConfigurationContainer from '../../state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateContainer from '../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationAnalyticsFacetContainer from '../../state/containers/ApplicationAnalyticsFacetContainer';
import WebhookFolderContainer from '../../state/containers/WebhookFolderContainer';
import WebhookContainer from '../../state/containers/WebhookContainer';
import IntegrationsListingsContainer from '../../state/containers/IntegrationsListingsContainer';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import LoaderWithHeader from '../../components/general/LoaderWithHeader';
import UserContainer from '../../state/containers/UserContainer';
import ApplicationMembersContainer from '../../state/containers/ApplicationMembersContainer';
import MemberContainer from '../../state/containers/MemberContainer';

interface ApplicationRouteProps {
    applicationId?: string
    applicationFeatureId?: string
    webhookFolderId?: string
    contentItemId?: string,
    environmentId?: string
}
interface ApplicationFeatureProps extends RouteComponentProps<ApplicationRouteProps> { }
class ApplicationDashboard extends React.Component<ApplicationFeatureProps, {}> {
    render() {
        const { applicationId, applicationFeatureId, webhookFolderId, contentItemId, environmentId } = this.props.match.params;
        const webhookId = new URLSearchParams(this.props.location.search).get("webhookId");
        if(contentItemId) // if we're on a content item page lets show the header and a loader so loading directly there is smooth
            return <LoaderWithHeader />
        return (
            <Subscribe to={[TitleStateContainer,
                ApplicationContainer,
                OrganizationContainer,
                FeatureContainer,
                ContentTreeStateContainer,
                ImportExportContainer,
                TemplateConfigurationContainer,
                AppliedApplicationTemplateContainer,
                ApplicationAnalyticsFacetContainer,
                WebhookContainer,
                WebhookFolderContainer,
                IntegrationsListingsContainer,
                ApplicationEnvironmentsContainer,
                ApplicationBackupVersionsContainer,
                UserContainer,
                ApplicationMembersContainer,
                MemberContainer]}>
                {(titleContainer: TitleStateContainer,
                    appContainer: ApplicationContainer,
                    orgContainer: OrganizationContainer,
                    featureContainer: FeatureContainer,
                    treeContainer: ContentTreeStateContainer,
                    importExportContainer: ImportExportContainer,
                    templateConfigurationContainer: TemplateConfigurationContainer,
                    appliedAppTemplateContainer: AppliedApplicationTemplateContainer,
                    facetContainer: ApplicationAnalyticsFacetContainer,
                    webhookContainer: WebhookContainer,
                    webhookFolderContainer: WebhookFolderContainer,
                    integrationsContainer: IntegrationsListingsContainer,
                    environmentsContainer: ApplicationEnvironmentsContainer,
                    backupsContainer: ApplicationBackupVersionsContainer,
                    userContainer: UserContainer,
                    appMembershipContainer: ApplicationMembersContainer,
                    memberContainer: MemberContainer,
                    ) => {
                    return (
                        <ApplicationDashboardContent
                            importExportContainer={importExportContainer}
                            titleContainer={titleContainer}
                            appContainer={appContainer}
                            orgContainer={orgContainer}
                            featureContainer={featureContainer}
                            applicationId={applicationId ?? appContainer?.state?.currentApp?.id}
                            environmentId={environmentId}
                            history={this.props.history}
                            key={applicationId ?? applicationFeatureId}
                            treeContainer={treeContainer}
                            templateConfigurationContainer={templateConfigurationContainer}
                            appliedAppTemplateContainer={appliedAppTemplateContainer}
                            facetContainer={facetContainer}
                            currentFeatureId={applicationFeatureId}
                            currentWebhookFolderId={webhookFolderId}
                            currentWebhookId={webhookId}
                            webhookContainer={webhookContainer}
                            webhookFolderContainer={webhookFolderContainer}
                            integrationsContainer={integrationsContainer}
                            backupsContainer={backupsContainer}
                            environmentsContainer={environmentsContainer}
                            userContainer={userContainer}
                            applicationMembersContainer={appMembershipContainer}
                            orgMemberContainer={memberContainer} />
                    )
                }}
            </Subscribe>
        );
    }
}
export default ApplicationDashboard;