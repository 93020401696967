import React from 'react';
import { css } from '@emotion/css';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { color_colors_ocean } from '../../../constants/colors';
import AnalyticsSelectField from '../../../components/analytics/AnalyticsSelectField';
import ContentStatisticsContainer from '../../../state/containers/ContentStatisticsContainer';
import TotalCard from '../../../components/charts/TotalCard';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';

interface ContentStatisticsProps {
    stateContainer: ContentStatisticsContainer
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string
    contentId: string
}


class ContentStatistics extends React.Component<ContentStatisticsProps> {

    componentWillMount() {
        this.loadChartData();
    }

    componentDidUpdate(previousProps: ContentStatisticsProps) {
        if (previousProps.interval !== this.props.interval
            || JSON.stringify(previousProps.filter) != JSON.stringify(this.props.filter)) {

            this.loadChartData();
        }
    }

    loadChartData() {
        const { applicationId, contentId, filter } = this.props;
        this.props.stateContainer.loadStats(applicationId, contentId, filter);

    }
    async handleDeltaPeriodChange(option: string) {
        const { applicationId, contentId, filter, stateContainer } = this.props;
        await stateContainer.handleDeltaPeriodChange(option);
        this.props.stateContainer.loadStats(applicationId, contentId, filter);
    }
    render() {
        const { buildCacheKey } = this.props.stateContainer;
        const previousDates = this.props.stateContainer.getPreviousDates(this.props.filter.startDate, this.props.filter.endDate);
        const totals = this.props.stateContainer.state.contentStats.find(a => buildCacheKey(a.id, a.filter) == buildCacheKey(this.props.contentId, this.props.filter))?.data
        const previousTotals = this.props.stateContainer.state.contentStats.find(a => buildCacheKey(a.id, a.filter) == buildCacheKey(this.props.contentId, { ...this.props.filter, startDate: previousDates.startDate, endDate: previousDates.endDate }))?.data

        const deltaUsers = (totals?.totalUsers ?? 0) - (previousTotals?.totalUsers ?? 0);
        const deltaNewUsers = (totals?.totalNewUsers ?? 0) - (previousTotals?.totalNewUsers ?? 0);
        const deltaSessions = (totals?.totalSessions ?? 0) - (previousTotals?.totalSessions ?? 0);
        const deltaRequests = (totals?.totalRequests ?? 0) - (previousTotals?.totalRequests ?? 0);
        return (<div className={wrapperStyle}>
            <div className="options-row-container">
                <h5>Totals</h5>
                <div className="end-container">
                    <AnalyticsSelectField label="Compare to:" options={['Previous Period', 'Same Time Last Year'].map(s => ({ label: s, value: s }))} value={this.props.stateContainer.state.currentDeltaPeriod} onChange={(e) => this.handleDeltaPeriodChange(e.value)} />
                    <AnalyticsSelectField label="Change in:" options={['Value', 'Percentage', 'Value & Percentage'].map(s => ({ label: s, value: s }))} value={this.props.stateContainer.state.currentDeltaType} onChange={(e) => this.props.stateContainer.handleDeltaTypeChange(e.value)} />
                </div>
            </div>
            {this.props.stateContainer.state.isLoadingTotals || !totals ? <div className="loader-container"><SecondaryLoader /></div> :
                <div className="totals-container">
                    <TotalCard title="Users"
                        value={totals.totalUsers}
                        deltaValue={deltaUsers}
                        deltaPercentage={Math.round(deltaUsers / (previousTotals?.totalUsers > 0 ? previousTotals?.totalUsers : 1) * 100)}
                        showPercentage={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                        showValue={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1} />
                    <TotalCard title="New Users"
                        value={totals.totalNewUsers ?? 0}
                        deltaValue={deltaNewUsers}
                        deltaPercentage={Math.round(deltaNewUsers / (previousTotals?.totalNewUsers > 0 ? previousTotals?.totalNewUsers : 1) * 100)}
                        showPercentage={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                        showValue={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1} />
                    <TotalCard title="Sessions"
                        value={totals.totalSessions}
                        deltaValue={deltaSessions}
                        deltaPercentage={Math.round(deltaSessions / (previousTotals?.totalSessions > 0 ? previousTotals?.totalSessions : 1) * 100)}
                        showPercentage={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                        showValue={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1} />
                    <TotalCard title="Interactions"
                        value={totals.totalRequests}
                        deltaValue={deltaRequests}
                        deltaPercentage={Math.round(deltaRequests / (previousTotals?.totalRequests > 0 ? previousTotals?.totalRequests : 1) * 100)}
                        showPercentage={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                        showValue={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1} />
                </div>}
        </div>)
    }
}
const wrapperStyle = css`
   .options-row-container {
        display: flex;
        align-items: center;
        margin: 32px;
        h5 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
   }
   .end-container {
        display: flex;
        align-items: center;
       margin-right: 0;
       margin-left: auto;

       >div {
           margin-left: 24px;
       }
   }
   .loader-container {
       display: flex;
       flex: 1;
       align-items: center;
       justify-content: center;
       width: 100%;
       .spinner > div {
           background: ${color_colors_ocean};
       }
   }
   .totals-container{
       display: flex;
       flex: 1;
       margin: 0 16px;
   }
`


export default ContentStatistics;