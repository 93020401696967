import React from 'react';
import FilterSelector from './FilterSelector';
import FilterOption from './FilterOption';

const dateIconLight = require('../../../../content/images/content-explorer/calendar-light.svg');
const dateIcon = require('../../../../content/images/content-explorer/calendar.svg');

interface FeatureTypeFilterProps {
    days?: number
    onChange: (days?: number) => void
}

const ModifiedDateFilter: React.FC<FeatureTypeFilterProps> = (props) => (
    <FilterSelector label="Last Updated" 
        icon={props.days ? dateIcon : dateIconLight} 
        placeholder={'All Time'} 
        selectedText={props.days ? `Past ${props.days} days` : null}
        onClear={() => props.onChange(null)}>
        <FilterOption title="All Time" onClick={() => props.onChange(null)} isSelected={!props.days} optionType="check"/>
        <FilterOption title="Past 7 Days" onClick={() => props.onChange(7)} isSelected={props.days == 7} optionType="check"/>
        <FilterOption title="Past 30 Days" onClick={() => props.onChange(30)} isSelected={props.days == 30} optionType="check"/>
        <FilterOption title="Past 90 Days" onClick={() => props.onChange(90)} isSelected={props.days == 90} optionType="check"/>
        <FilterOption title="Past Year" onClick={() => props.onChange(365)} isSelected={props.days == 365} optionType="check"/>
        

    </FilterSelector>
)

export default ModifiedDateFilter;

