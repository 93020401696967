import React from 'react';
import { css } from '@emotion/css';
import ResponseTemplateTypeModel from '../../models/features/api/MediaResponses/ResponseTemplateTypeModel';
import { ocean_blue, cool_grey, pale_grey, pale_grey_two, silver_three, silver } from '../../constants/colors';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';
import { importToAppFeature } from '../../api';
const micIcon = require('../../content/images/microphone-off.svg');

interface DeviceTargetListItemProps {
    deviceTarget: DeviceTargetModel
    isSelected?: boolean
    onSelected: (target: DeviceTargetModel) => void
    viewOnly?: boolean
    top?: boolean
    bottom?: boolean
}

export const DeviceTargetListItem: React.SFC<DeviceTargetListItemProps> = (props) => {
    if (props.deviceTarget) {
        return (
            <div className={getContainerClass(props)} onClick={() => props.onSelected(props.deviceTarget)}>
                <img className="template-icon" src={props.deviceTarget.iconUrl} />
                <p className="template-title">{props.deviceTarget.displayName}</p>
            </div>)
    }
    return null;
}

const getContainerClass = (props: DeviceTargetListItemProps) => {
    var className = containerStyle;
    if (!props.viewOnly) className += ' selectable';
    if (props.isSelected) className += ' selected';
    if (props.top) className += ' top';
    if (props.bottom) className += ' bottom';
    return className
}

const containerStyle = css`
    background: white;
    display: flex;
    flex-direction: row;
    padding: 0 32px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    flex: 1;
    align-items: center;
    min-height: 80px;
    &.selectable {
        border: 1px solid ${cool_grey};

        &:hover {
            background: ${silver};
        }
        &.top {
            border-radius: 10px 10px 0 0;
        }
        &.bottom {
            border-radius: 0 0 10px 10px;
        }
    }
    .template-icon {
        margin-right: 24px;
    }
    &.selected {
        background-color: rgba(0, 125, 187, 0.1);
        border: 1px solid ${ocean_blue};
    }

    .template-title {
        font-size: 18px;
        color: #304562;
        margin-bottom: 2px;
    }
`