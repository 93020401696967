import React from 'react';
import { css } from '@emotion/css';
import { cool_grey, silver, dark_grey_blue, faded_blue_2 } from '../../../constants/colors';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import TextField from '../../../components/forms/TextField';
import TextAreaField from '../../../components/forms/TextAreaField';
import Button from '../../../components/general/Button';
import PageContainer from '../../../components/structure/PageContainer';
import StagedMediaItem from '../../../models/media/StagedMediaItem';
import HeaderProgressBar from '../../../components/general/HeaderProgressBar';
import { BytesToMegabytesMultiplier } from '../../../constants/UploadFileSize';

const removeIcon = require('../../../content/images/bin-blue.svg');
const attachIcon = require('../../../content/images/attachment.svg');
const audioIcon = require('../../../content/images/audio_icon.svg');
const videoIcon = require('../../../content/images/video_icon.svg');
const imageIcon = require('../../../content/images/picture_icon.svg');

interface FileStageProps {
    file: StagedMediaItem
    onRemove: (file: StagedMediaItem) => void
    onUpload: (file: StagedMediaItem) => void
    onChange: (file: StagedMediaItem, name: string, caption: string) => void
    isUploading: boolean
    progress?: number
    id?: string
}

class FileStageCard extends React.Component<FileStageProps> {
    getMediaTypeFromFile(fileName: string): string {
        if (!fileName) return null;

        var parts = fileName.split(".");
        var extension = parts[parts.length - 1];
        switch (extension) {
            case "mp3": return "Audio File";
            case "mp4": return "Video File";
            case "png":
            case "jpg":
            case "jpeg":
            default: return "Image File";
        }
    }
    getFileIcon(): string {
        switch (this.getMediaTypeFromFile(this.props.file.name)) {
            case "Audio File": return audioIcon;
            case "Video File": return videoIcon;
            case "Image File": return imageIcon;
        }

        return imageIcon;
    }
    onFileNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(this.props.file, e.target.value, this.props.file.caption)
    }
    onCaptionChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(this.props.file, this.props.file.name, e.target.value)
    }

    render() {
        return (
            <div className={containerStyle} id={this.props.id}>
                <div className="card-header">
                    {this.props.file.isUploading ?
                        <HeaderProgressBar percent={this.props.file.progress} />

                        : null}
                    <div className="header-container">
                        <img className="file-icon" src={this.getFileIcon()} />
                        <div>
                            <p className="file-type-label">{this.getMediaTypeFromFile(this.props.file.file.name)}</p>
                            <p className="file-size-label">{`${(this.props.file.file.size * BytesToMegabytesMultiplier).toFixed(2)}MB`}</p>
                        </div>
                        {this.props.file.isUploading ?
                            <div className="end">
                                <span className="progress-label">{`${this.props.file.progress}%`}</span>
                            </div> :
                            <div className="end">
                                <Button themes={["icon", "hollow", "flat"]} type="button" rightIcon={removeIcon} onClick={() => this.props.onRemove(this.props.file)} />
                                <div className="separator" />
                                <Button themes={["hollow", "flat"]} type="button" icon={attachIcon} text="Upload &amp; Attach" onClick={() => this.props.onUpload(this.props.file)} />
                            </div>}
                    </div>
                </div>
                <HorizontalSeparator />
                <PageContainer>
                    <TextField autoFocus name="name" label="Filename" placeholder="A name for your file" value={this.props.file.name} onChange={this.onFileNameChange.bind(this)} />
                    {this.getMediaTypeFromFile(this.props.file.name) == "Image File" ?
                        <TextAreaField  name="caption" label="Include a caption" placeholder="A caption for your file" value={this.props.file.caption} onChange={this.onCaptionChange.bind(this)} />
                        : null}
                </PageContainer>
            </div>
        )
    }
}

const containerStyle = css`

overflow: hidden;
margin: 16px 0;
border-radius: 10px;
border: 1px solid ${cool_grey};

.card-header {
    position: relative;
    height: 56px;
    overflow: hidden;
    display: flex;

    .header-container {
        display: flex;
        flex-direction: row;
        padding: 0 24px;
        align-items: center;
        height: 56px;
        position: absolute;
        flex: 1;
        right: 0;
        left: 0;

        .end {
            margin-right: 0;
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            
        }

        .separator {
            height: 24px;
            width: 1px;
            background: ${silver};
            margin-right: 16px;
        }

        .file-icon {
            margin-right: 16px;
        }

        .file-type-label {
            text-transform: uppercase;
            font-family: Muli;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: ${dark_grey_blue};
        }

        .file-size-label {font-family: Muli;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: ${cool_grey};
        }
    }

    .progress-label {
        font-size: 48px;
        color: ${faded_blue_2};
    }
}
`

export default FileStageCard