import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue_10, dark_grey_blue } from '../../../constants/colors';
import CheckboxField from '../../../components/forms/CheckboxField';
import UserAvatar from '../../../components/general/UserAvatar';
const checkImage = require('../../../content/images/gradient-check.svg');

interface MemberCardProps {
    id: string
    firstName: string
    lastName: string
    email: string
    imageUrl?: string
    selected: boolean
    onClick?: () => void
}

const cardStyle = css`
    height: 56px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 ${dark_grey_blue_10};
    background-color: #ffffff;
    border: solid 1px #c7cfd2;
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    margin-top: 5px;
    align-items: center;
    cursor: pointer;
    max-width: 400px;
    flex-wrap: wrap;

    p {
        display: block;
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
    }
`

const checkContainerStyle = css`
    margin-right: 24px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 16px;
        height: 12px;
    }
`

const avatarStyle = css`
    margin-right: 16px;
`;

const MemberCard: React.SFC<MemberCardProps> = (props) => (
    <div className={cardStyle} onClick={props.onClick}>
        <div className={checkContainerStyle}>
            {props.selected ? <img src={checkImage}/> : null}
        </div>
        <UserAvatar className={avatarStyle} src={props.imageUrl} firstName={props.firstName} lastName={props.lastName} size={28}/>
        <div>
            <p>{`${props.firstName} ${props.lastName}`}</p>
            <p>{props.email}</p>
        </div>
    </div>
)


export default MemberCard;