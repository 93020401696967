import React from 'react';
import { withFormik } from "formik";
import * as yup from 'yup';
import QuestionAnswerForm, { QuestionAnswerContentFormProps, QuestionAnswerContentFormData } from '../../formScenes/QuestionAnswerForm';

const QuestionAnswerContentForm = withFormik<QuestionAnswerContentFormProps, QuestionAnswerContentFormData>({
    mapPropsToValues: props => ({
        title: 'Untitled FAQ',
        questions: [
            {
                content: ''
            }
        ],
        responses: [
            {
                content: ''
            }
        ],
        isLive: true,
        sensitiveConversationChange: "None",
        followUpSelection: 'end',
        repromptSelection: 'end',
        entryType: 'open',
        requiresParent: false,
        languages: props.application?.languages.find(l => l.id == props.application?.defaultLanguageId) ? [props.application?.languages.find(l => l.id == props.application?.defaultLanguageId)] : [],
        webhookChainingType: "NoChaining",
        minimumConfidenceScore: null,
        requiredPhrases: []
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        questions: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a question variation")
            })
        ).required("You must enter at least 1 question variation").min(1, "You must enter at least 1 question variation"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 answer variation").min(1, "You must enter at least 1 answer variation"),
        minimumConfidenceScore: yup.number()
            .typeError('Please provide a number between 0 and 1.')
            .min(0, 'Please provide a number between 0 and 1.')
            .max(1, 'Please provide a number between 0 and 1.').nullable(true)
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus, setFieldValue }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel({
            applicationFeatureId: props.applicationFeatureId,
            applicationId: props.application.id,
            questionSet: [],
            responses: [],
        }, values));
    }
})(QuestionAnswerForm);

export default QuestionAnswerContentForm;