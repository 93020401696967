import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import ApplicationMemberModel from '../../models/applications/api/ApplicationMemberModel';
import NewBulkApplicationMemberRequest from '../../models/applications/api/NewBulkApplicationMemberRequest';

type ApplicationsMembersState = {
    isLoadingMembers: boolean
    isLoading: boolean
    members: ApplicationMemberModel[]
    errors: string[]
}

export default class ApplicationMembersContainer extends Container<ApplicationsMembersState> {
    // default state
    state = {
        isLoadingMembers: false,
        isLoading: false,
        members: [] as ApplicationMemberModel[],
        errors: [] as string[]
    }

    getMembersForApp(applicationId: string): Promise<IResult<ApplicationMemberModel[]>> {
        this.setLoading(true);
        const promise = voicifyApi.getApplicationMembers(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const members = this.state.members.filter(m => m.applicationId !== applicationId);
                members.push(...result.data);
                this.setState({
                    ...this.state,
                    errors: [],
                    isLoadingMembers: false,
                    members: members
                })
            } else {
                this.setState({
                    ...this.state,
                    isLoadingMembers: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoadingMembers: false,
                errors: [error]
            })
        })
        return promise;
    }

    isUserAppAdmin(userId: string, appId: string) : boolean {        
        const members = this.state.members.filter(m => m.applicationId == appId);
        var isAdmin = false;

        const member = members.find(m => m.organizationMember.userId == userId && m.applicationId == appId);
        if (member) {
            isAdmin = member.isAdmin || member.organizationMember.isAdmin;
        }
        else {
            const organizationMember = this.state.members.find(m => m.userId == userId);
            if (organizationMember) {
                isAdmin = organizationMember.isAdmin;
            }
        }
        return isAdmin;
    }

    addMembers(applicationId: string, model: NewBulkApplicationMemberRequest): Promise<IResult<ApplicationMemberModel[]>> {
        this.setState({
            ...this.state,
            isLoading: true
        });

        const promise = voicifyApi.addApplicationMember(applicationId, model);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(error => {

            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })
        return promise;
    }

    updateAppMemberRole(applicationMemberId: string, roleId: string): Promise<IResult<ApplicationMemberModel>> {
        const promise = voicifyApi.updateApplicationMemberRole(applicationMemberId, roleId);
        promise.then(result => {
            if (result.resultType != "Ok") {
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error]
            })
        })
        return promise;
    }

    removeMemberFromApp(applicationMemberId: string, ) {
        this.setState({
            ...this.state,
            isLoading: true
        })
        var promise = voicifyApi.removeApplicationMember(applicationMemberId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    members: this.state.members.filter(m => m.id != applicationMemberId)
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoadingMembers: isLoading
        });
    }
}