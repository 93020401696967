import React from 'react';
import { withFormik } from "formik";
import * as yup from 'yup';
import CustomRequestForm, { CustomRequestContentFormProps, CustomRequestContentFormData } from '../../formScenes/CustomRequestForm';

const CustomRequestContentForm = withFormik<CustomRequestContentFormProps, CustomRequestContentFormData>({
    mapPropsToValues: props => ({
        title: 'Untitled Custom Request',
        requestTypes: '',
        requestNames: '',
        platformFilter: '',
        responses: [{ content: '' }],
        followUpSelection: 'end',
        repromptSelection: 'end',
        entryType: 'open',
        requiresParent: false,
        sensitiveConversationChange: "None",
        isLive: true,
        languages: props.application?.languages.find(l => l.id == props.application?.defaultLanguageId) ? [props.application?.languages.find(l => l.id == props.application?.defaultLanguageId)] : [],
        webhookChainingType: "NoChaining",
        alwaysOpen: false
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
        requestTypes: yup.string(),
        requestNames: yup.string().when('requestTypes', {
            is: (val) => { return (!val) },
            then: yup.string().required("You must enter at least one request name or request type"),
            otherwise: yup.string()
        })
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus, setFieldValue}) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel({
            applicationFeatureId: props.applicationFeatureId,
            applicationId: props.application?.id,
            responses: []
        }, values));
    }
})(CustomRequestForm);

export default CustomRequestContentForm;