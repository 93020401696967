import React from 'react';
import styled from '@emotion/styled';
import { color_colors_growth, color_shades_lighter, color_text_default, color_text_light } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import IntegrationListing from '../../../models/integrations/IntegrationListing';

const defaultImage = require('../../../content/images/icon.svg');
const integrationIcon = require('../../../content/images/templates-icons/integration-icon-light.svg');
const notEnabledIcon = require('../../../content/images/templates-icons/not-enabled.svg');
const sunsetIcon = require('../../../content/images/templates-icons/sunset.svg');

interface LegacyIntegrationListItemProps {
    integration: IntegrationListing
    applicationId: string
    onSelect: (integration: IntegrationListing) => void
}

const LegacyIntegrationListItem: React.FC<LegacyIntegrationListItemProps> = ({
    integration,
    onSelect
}) => {

    return (
        <CardBody onClick={() => onSelect(integration)}>
            <CardHeader />
            <CardImageWrapper>
                <img src={integration?.iconUrl ?? defaultImage} />
            </CardImageWrapper>
            <InnerCardBody>
                <p className="template-title">{integration?.name}</p>
                <p className="template-byline">
                    <img src={integrationIcon} />
                    <span>{`Legacy Integration - by ${integration?.provider}`}</span>
                </p>
                <p className="template-description">{integration?.description}</p>
            </InnerCardBody>
            <LegacyHeader>
                <p>Legacy</p>
                <img src={sunsetIcon} />
            </LegacyHeader>
            <CardEnd>
                <p className="template-status">
                    <span>{"Not Enabled"}</span>
                    <img src={notEnabledIcon} />
                </p>
              
            </CardEnd>
        </CardBody>)
}


const CardEnd = styled.div(`
    height: calc(100% - 48px);
    width: 200px;
    border-left: ${style_border_default};
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .template-price {
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-left: auto;
        margin-bottom: 0;
        margin-top: auto;
        .price-label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: ${color_text_light};
            display: block;
            margin-right: 8px;
        }
        .price-value {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            display: block;
        }
    }
    .template-status {
        margin-right: 0;
        margin-left: auto;
        font-size: 14px;
        line-height: 20px;
        font-style: italic;
        color: ${color_text_light};
        display: flex;
        align-items: center;
        &.enabled {
            color: ${color_colors_growth};
        }
        img {
            width: 24px;
            margin-left: 8px;
        }
    }
`)
const LegacyHeader = styled.div(`
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: auto;
    margin-top: 32px;
    margin-bottom: auto;
    p {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 14px;
        line-height: 16px;
        margin-right: 8px;
    }
`)

const CardBody = styled.div(`
    border: ${style_border_default};
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    width: calc(100% - 64px);
    height: 160px;
    position: relative;
    margin-left: 32px;
    margin-top: 32px;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${color_text_default};
`);

const CardHeader = styled.div(`
    background: ${color_shades_lighter};
    width: 72px;
    height: 100%;
    border-right: ${style_border_default};
`);

const CardImageWrapper = styled.div(`
    position: absolute;
    width: 96px;
    height: 96px;
    margin-left: 24px;
    background: white;
    border: ${style_border_default};
    border-radius: 8px;
    overflow: hidden;

    img {
        max-height: 100%;
        max-width: 100%;
    }
`);

const InnerCardBody = styled.div(`
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    color: ${color_text_default};
    flex: 1;
    .template-title {
        margin-top: 16px;
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
    }
    .template-byline {
        display: flex;
        align-items: center;
        color: ${color_text_light};
        font-size: 14px;
        line-height: 20px;
        img {
            margin-right: 8px;
        }
        .version-label {
            padding-left: 8px;
        }
    }
    .template-description {
        margin-top: 24px;
    }
`)
export default LegacyIntegrationListItem