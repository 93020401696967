import React from 'react';
import moment from 'moment';
import { TabPanel } from 'react-tabs';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import { MeasurementType } from '../../../components/analytics/MeasurementTypePicker';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import TabbedLayout from '../../../components/structure/TabbedLayout';
import ContentStatisticsContainer from '../../../state/containers/ContentStatisticsContainer';
import ContentInteractionAnalyticsContainer from '../../../state/containers/ContentInteractionAnalyticsContainer';
import ContentUserAnalyticsContainer from '../../../state/containers/ContentUserAnalyticsContainer';
import { getEditUrl } from '../../../models/extensions';
import ContentUserCharts from './ContentUserCharts';
import ContentInteractionsCharts from './ContentInteractionsCharts';
import ContentStatistics from './ContentStatistics';
import { DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';

interface ContentMeasurementPageProps {
    applicationId: string
    contentId: string
    applicationFeatureId: string
    featureTypeId: string
    userAnalyticsContainer: ContentUserAnalyticsContainer
    interactionAnalyticsContainer: ContentInteractionAnalyticsContainer
    statisticsContainer: ContentStatisticsContainer
    interval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    history?: any
}
class ContentMeasurementPage extends React.Component<ContentMeasurementPageProps> {
    handleTabSelection(index: number, last: number) {
        if (index == last) return;
        const urlStart = getEditUrl(this.props.contentId, this.props.applicationFeatureId, this.props.featureTypeId) + "analytics/";

        if (index == 0) {
            this.props.history.push(urlStart + "users")
        }
        if (index == 1) {
            this.props.history.push(urlStart + "activity")
        }
        if(index == 2) {
            this.props.history.push(urlStart + "report")
        }
    }
    getSelectedIndex() {
        if (location.href.indexOf("activity") > -1) {
            return 1;
        }
        if (location.href.indexOf("report") > -1) {
            return 2;
        }

        return 0;
    }

    getInnerInterval(startDate: string, endDate: string): AnalyticsInterval {
        // rule of 3s.
        // if we are under 3 days, use hours
        // if we are under 3 months, use days
        // if we are under 3 years, use months
        // use years

        // get hours between dates
        const hoursBetween = moment(endDate, DATE_FORMAT).diff(moment(startDate, DATE_FORMAT), 'hours');
        if(hoursBetween <= (24 * 3)) {
            return 'hour';
        }
        if(hoursBetween <= (24 * 3 * 30)) {
            return "day"
        }
        if(hoursBetween <= (24 * 3 * 365)) {
            return "month"
        }
        return "year"
    }

    handleMeasurementTypeChange(type: MeasurementType) {
        this.setState({
            ...this.state,
            measurementType: type
        })
    }

    render() {
        const innerInterval = this.getInnerInterval(this.props.filter.startDate, this.props.filter.endDate); 

        return (
            <ScrollablePanelBody>
                <TabbedLayout tabs={["Users", "Activity", "Report"]} top
                            selectedIndex={this.getSelectedIndex()}
                            onTabSelected={this.handleTabSelection.bind(this)}>
                    <TabPanel>
                        <ContentUserCharts contentId={this.props.contentId} filter={this.props.filter} interval={this.props.interval} innerInterval={innerInterval} stateContainer={this.props.userAnalyticsContainer} applicationId={this.props.applicationId} />
                    </TabPanel>
                    <TabPanel>
                        <ContentInteractionsCharts contentId={this.props.contentId} filter={this.props.filter} interval={this.props.interval} innerInterval={innerInterval} stateContainer={this.props.interactionAnalyticsContainer} applicationId={this.props.applicationId} />
                    </TabPanel>                 
                    <TabPanel>
                        <ContentStatistics contentId={this.props.contentId} filter={this.props.filter} interval={this.props.interval} innerInterval={innerInterval} stateContainer={this.props.statisticsContainer} applicationId={this.props.applicationId}/>
                    </TabPanel>
                </TabbedLayout>
            </ScrollablePanelBody>
        )
    }
}

export default ContentMeasurementPage;