import { withFormik } from "formik";
import * as yup from 'yup';
import WelcomeMessageForm, { EditWelcomeMessageFormProps, EditWelcomeMessageFormData } from '../../formScenes/WelcomeMessageForm';

const EditWelcomeMessageForm = withFormik<EditWelcomeMessageFormProps, EditWelcomeMessageFormData>({
    mapPropsToValues: props => ({
        title: props.welcomeMessage.title,
        responses: props.welcomeMessage.responses.length > 0 ? props.welcomeMessage.responses : [{ content: '' }],
        languages: props.welcomeMessage.languages,
        isLive: props.welcomeMessage.isLive,
        webhookChainingType: props.welcomeMessage.webhookChainingType,
        showPublishModal: props?.location?.search?.includes("publishing=true") ?? false,
        sensitiveConversationChange: props.welcomeMessage.sensitiveConversationChange ?? "None",
        requiresParent: false
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter an response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.welcomeMessage, values));
    }
})(WelcomeMessageForm);

export default EditWelcomeMessageForm;