import React from 'react';
import { Subscribe } from 'unstated';
import TemplateConfigurationContainer from '../../state/containers/TemplateConfigurationContainer';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import ModalLayout from '../../components/structure/ModalLayout';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import CreateTemplateConfigurationForm from './components/CreateTemplateConfigurationForm';
import { RouteComponentProps } from 'react-router';

const CreateTemplateConfiguration: React.SFC<RouteComponentProps<{}>> = (props) => (
    <Subscribe to={[OrganizationContainer, ApplicationContainer, TemplateConfigurationContainer]}>
        {(orgContainer: OrganizationContainer, appContainer: ApplicationContainer, templatesContainer: TemplateConfigurationContainer) => (
            <CreateTemplateConfigurationForm orgContainer={orgContainer} appContainer={appContainer} templatesContainer={templatesContainer} history={props.history}/>
        )}
    </Subscribe>
)

export default CreateTemplateConfiguration;