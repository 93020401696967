import { Container } from 'unstated';
import BulkContentRequest from '../../models/features/api/BulkContentRequest';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import moment from 'moment';
import BulkWebhookRequest from '../../models/features/api/BulkWebhookRequest';
import NewArchiveRequest from '../../models/features/api/NewArchiveRequest';

type ImportExportState = {
    errors: string[]
    isLoading: boolean
}

export default class ImportExportContainer extends Container<ImportExportState> {
    // default state
    state = {
        errors: [],
        isLoading: false
    }
    async exportContentToFile(contentRequest: BulkContentRequest, currentApplication: ApplicationModel): Promise<IResult<Blob>> {
        try {

            this.setLoading(true);
            var request: NewArchiveRequest = {
                applicationId: currentApplication.id,
                bulkContentRequest: contentRequest,
                archiveType: "BulkContent",
            };
            const archiveResult = await voicifyApi.queueArchive(request);
            let result = null;
            if (archiveResult.resultType == "Ok") {
                await this.waitForExport(archiveResult.data.id);                
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: archiveResult.errors ?? result.errors
                })
            }
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }  
    }
    async exportWebhooksToFile(webhookRequest: BulkWebhookRequest, currentApplication: ApplicationModel): Promise<IResult<Blob>> {
        try {

            this.setLoading(true);
            var request: NewArchiveRequest = {
                applicationId: currentApplication.id,
                bulkWebhookRequest: webhookRequest,
                archiveType: "BulkWebhook",
            };
            const archiveResult = await voicifyApi.queueArchive(request);
            let result = null;
            if (archiveResult.resultType == "Ok") {
                await this.waitForExport(archiveResult.data.id);                            
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: archiveResult.errors ?? result.errors
                })
            }
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }  
    }
    async waitForExport(archiveId: string) : Promise<IResult<any>> {
        try {
            let checking = true;
            while (checking) {
                const result = await voicifyApi.getArchive(archiveId);
                if (result.resultType == "Ok") {
                    const archive = result.data;
                    switch (archive.stage) {
                        case ("Processing"):
                        case ("Queued"):
                            await new Promise(r => setTimeout(r, 1000));// wait 1000 ms and then we can try again. 
                            break;
                        case ("Complete"):
                            if (archive.error) {
                                await this.setState({
                                    ...this.state,
                                    isLoading: false,
                                    errors: [archive.error]
                                });
                                return {
                                    data: null,
                                    resultType: "Invalid",
                                    errors: [archive.error]
                                }
                            } else {
                                await this.setState({
                                    ...this.state,
                                    isLoading: false,
                                    errors: []
                                });
                                window.open(archive.fileUrl, '_blank').focus();
                                return result;
                            }
                            break;
                        default:
                            await this.setState({
                                ...this.state,
                                isLoading: false,
                                errors: ["Unknown issue with archive"]
                            });
                            return {
                                data: null,
                                resultType: "Invalid",
                                errors: ["Unknown issue with archive"]
                            }
                    }
                }
                else {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        errors: result.errors
                    })
                }
            }
        }
        catch (e) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            })
        }
    }

    importFileToAppFeature(applicationFeatureId: string, file: File): Promise<IResult<any>> {
        if (!file)
            return;

        this.setLoading(true);
        const promise = voicifyApi.importToAppFeature(applicationFeatureId, file);

        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [e.toString()]
            })
        });
        return promise;
    }

    async importFileToWebhookFolder(targetFolderId: string, applicationId: string, file: File): Promise<IResult<any>> {
        try {
            if (!file)
            return;

            if(!targetFolderId)
                targetFolderId = "";

            this.setLoading(true);
            const result = await voicifyApi.importToWebhookFolder(targetFolderId, applicationId, file);
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }        
    }

    private saveBlob(blob, fileName) {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.dispatchEvent(new MouseEvent('click'));
    }


    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}
