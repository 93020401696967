import React from 'react';
import ModalLayout from '../../../components/structure/ModalLayout';
import LanguageModel from '../../../models/features/api/LanguageModel';
import EditableIntent from '../../../models/interactionModel/EditableIntent';
import EditableNlpEntity from '../../../models/nlpEntity/EditableNlpEntity';
import SlotsEditorForm, { SlotsEditorFormData } from './SlotsEditorForm';

interface SlotsEditorModalProps {
    handleClose: () => void;
    handleIntentSlotsEdits: (formData: SlotsEditorFormData) => void;
    applicationId: string;
    isLoading: boolean;
    languages: LanguageModel[];
    intent: EditableIntent;
    nlpEntities: EditableNlpEntity[];
}

const SlotsEditorModal: React.FC<SlotsEditorModalProps> = (props) => {

    return (
        <ModalLayout
            zIndex={1010}
            isVisible={true}
            onClose={() => props.handleClose()} >
            <SlotsEditorForm
                isLoading={props.isLoading}
                applicationId={props.applicationId}
                languages={props.languages}
                nlpEntities={props.nlpEntities}
                intent={props.intent}
                onSubmit={props.handleIntentSlotsEdits}
                handleCloseModal={props.handleClose}
                initialValues={
                    {
                        nlpEntities: props.nlpEntities,
                        intent: props.intent
                    }
                }
            />

        </ModalLayout>
    );

};

export default SlotsEditorModal;
