import React from 'react';
import { css } from '@emotion/css';
import { InjectedFormikProps, withFormik } from "formik";
import * as yup from 'yup';
import PageContainer from '../../../components/structure/PageContainer';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import MemberContainer from '../../../state/containers/MemberContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import SelectableOrganizationMember from '../../../models/organizations/SelectableOrganizationMember';
import MemberSelector from './MemberSelector';
import RoleToggle from '../../../components/general/RoleToggle';
import PageError from '../../../components/general/PageError';
import ApplicationMembersContainer from '../../../state/containers/ApplicationMembersContainer';

interface AddApplicationMemberFormProps {
    memberContainer: MemberContainer
    organizationContainer: OrganizationContainer
    appMemberContainer: ApplicationMembersContainer
    applicationId: string
    onFinish?: () => void
    onClose?: () => void
    onChange?: () => void
}
interface AddApplicationMemberFormData {
    members: SelectableOrganizationMember[]
    applicationRoleId?: string
}

class InnerForm extends React.Component<InjectedFormikProps<AddApplicationMemberFormProps, AddApplicationMemberFormData>> {
    componentWillMount() {
        if (this.props.organizationContainer.state.systemRoles.length == 0) {
            this.props.organizationContainer.getSystemRoles();
        }
        this.props.appMemberContainer.getMembersForApp(this.props.applicationId);
        this.props.memberContainer.getMembers(this.props.organizationContainer.state.currentOrganization.id).then(result => {
            if (result.resultType == "Ok") {
                this.props.setFieldValue('members',
                    result.data.filter(m => !this.props.appMemberContainer.state.members.some(am => am.organizationMember.id == m.id))
                        .map(m => ({
                            isSelected: false,
                            member: m
                        })))
            }
        })
    }
    handleRoleChange(roleName: string) {
        const role = this.props.organizationContainer.state.systemRoles.find(r => r.name == roleName);
        this.props.setFieldValue("applicationRoleId", role.id);
        this.props.onChange();
    }
    handleRoleBlur(blur) {
        this.props.setFieldTouched("applicationRoleId", blur);
    }
    handleMemberChange(members: SelectableOrganizationMember[]) {
        this.props.setFieldValue('members', members);
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.appMemberContainer.state.isLoading;

        return (
            <PageContainer fill>
                <form className={formStyle} onSubmit={props.handleSubmit}>
                    <PageContainer>
                        <p>Select existing members from your organization</p>
                        <FormFieldsContainer>
                            <MemberSelector members={props.values.members} onChange={this.handleMemberChange.bind(this)} />
                        </FormFieldsContainer>
                    </PageContainer>
                    <FooterBar>
                        <RoleToggle positionTop includeError={true} name="applicationRoleId" label="Role:" onChange={this.handleRoleChange.bind(this)} currentRole='' roles={props.organizationContainer.state.systemRoles.map(r => r.name)} />
                        <div className={buttonContainer}>
                            <Button loading={isLoading} disabled={isLoading} themes={["primary"]} text="Send Invite" type="submit" />
                            <Button disabled={isLoading} themes={["white"]} text="Cancel" type="button" onClick={this.props.onClose} />
                        </div>
                    </FooterBar>
                </form>
                <PageError errors={this.props.appMemberContainer.state.errors} />
            </PageContainer>
        )
    }
}

const AddApplicationMemberForm = withFormik<AddApplicationMemberFormProps, AddApplicationMemberFormData>({
    mapPropsToValues: () => ({
        applicationRoleId: '',
        members: []
    }),
    validationSchema: yup.object().shape({
        applicationRoleId: yup.string().required('You must select a role for these members to have')
    }),
    handleSubmit: (values, { props }) => {
        props.appMemberContainer.addMembers(props.applicationId, {
            roleId: values.applicationRoleId,
            organizationMemberIds: values.members.filter(m => m.isSelected).map(m => m.member.id)
        }).then(result => {
            if (result.resultType == "Ok") {
                props.onFinish();
                props.appMemberContainer.getMembersForApp(props.applicationId);
            }
        })
    }
})(InnerForm);



const buttonContainer = css`
    margin-right: 50px;
    margin-left: auto;
    display: flex;
    > * + * {
        margin-left: 16px;
    }
`

const formStyle = css`
    padding: 32px;
`;

export default AddApplicationMemberForm;
