import React from "react";
import { Subscribe } from 'unstated';
import ApplicationContainer from "../../state/containers/ApplicationContainer";
import ApplicationsListView from "./components/ApplicationListView";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import SubscriptionContainer from "../../state/containers/SubscriptionContainer";
import WebhookContainer from "../../state/containers/WebhookContainer";
import MemberContainer from "../../state/containers/MemberContainer";
import UserContainer from "../../state/containers/UserContainer";

const OrganizationApplications: React.FC = () => (
    <Subscribe to={[ApplicationContainer, OrganizationContainer, SubscriptionContainer, WebhookContainer, MemberContainer, UserContainer]}>
        {
            (
                appContainer: ApplicationContainer,
                orgContainer: OrganizationContainer,
                subContainer: SubscriptionContainer,
                webhookContainer: WebhookContainer,
                memberContainer: MemberContainer,
                userContainer: UserContainer
            ) => {
                return (
                    <ApplicationsListView
                        appsContainer={appContainer}
                        orgContainer={orgContainer}
                        webhookContainer={webhookContainer}
                        subContainer={subContainer}
                        userContainer={userContainer}
                        memberContainer={memberContainer}
                        organizationId={orgContainer.state.currentOrganization?.id} />
                );
            }}
    </Subscribe>
);
export default OrganizationApplications;