import React, { useEffect } from 'react';
import { css } from '@emotion/css';
import AppliedApplicationTemplateContainer from '../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TabContainerPanel from '../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import { color_variants_main_bg } from '../../constants/colors';
import TemplateConfigurationContainer from '../../state/containers/TemplateConfigurationContainer';
import OwnedModules from './components/OwnedModules';
import DiscoverModules from './components/DiscoverModules';
import SearchField from '../../components/forms/SearchField';
import RoundedTwoOptionButton from '../../components/forms/RoundedTwoOptionButton';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import { CardTableOption } from '../../models/general/CardTableOption';
import { style_border_default } from '../../constants/stylesValues';
import IntegrationsListingsContainer from '../../state/containers/IntegrationsListingsContainer';
import OrganizationContainer from '../../state/containers/OrganizationContainer';

const ownedIcon = require('../../content/images/tab-icons/owned.svg');
const ownedBlueIcon = require('../../content/images/tab-icons/owned-blue.svg');
const discoverIcon = require('../../content/images/tab-icons/discover.svg');
const discoverBlueIcon = require('../../content/images/tab-icons/discover-blue.svg');
const completeIcon = require('../../content/images/templates-icons/complete.svg');
const readyIcon = require('../../content/images/templates-icons/generate-content.svg');
const incompleteIcon = require('../../content/images/templates-icons/incomplete.svg');

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

interface ApplicationModulesProps {
    applicationId: string
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    templateConfigurationContainer: TemplateConfigurationContainer
    orgContainer: OrganizationContainer
    integrationsContainer: IntegrationsListingsContainer
    history: any
}

const ApplicationModules: React.FC<ApplicationModulesProps> = (props) => {
    const appliedTemplates = props.appliedAppTemplateContainer.getLoadedTemplatesForApp(props.applicationId);
    const { availableTemplates, organizationTemplates, privateTemplates } = props.templateConfigurationContainer.state;
    const { integrationsListings } = props.integrationsContainer.state;
    let timer: NodeJS.Timer = null;

    useEffect(() => {
        props.templateConfigurationContainer.getAvailableTemplates(props.orgContainer.state.currentOrganization?.id);
        props.templateConfigurationContainer.getOrganizationTemplates(props.orgContainer.state.currentOrganization?.id);
        props.templateConfigurationContainer.getPrivateTemplates(props.orgContainer.state.currentOrganization?.id);
        props.integrationsContainer.getListings(props.orgContainer.state.currentOrganization?.id);
        props.templateConfigurationContainer.loadCategories();
        props.templateConfigurationContainer.loadIndustries();
    }, []);

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = `/v/apps/${props.applicationId}/modules/`;

        if (index == 0) {
            props.history.push(urlStart + window.location.search)
        }
        if (index == 1) {
            props.history.push(urlStart + "discover" + window.location.search)
        }
    };

    const getSelectedIndex = () => {
        if (location.href.indexOf("discover") > -1) {
            return 1;
        }
        return 0;
    };

    const getStatusIcon = (status) => {
        if (status == "incomplete") return incompleteIcon;
        if (status == "complete") return readyIcon;
        return completeIcon;
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case "synced": return "In Use";
            case "complete": return "Ready";
            case "incomplete": return "Incomplete";
        }
    };

    const toggleView = () => {
        const newView: CardTableOption = props.templateConfigurationContainer.state.defaultViewType == "card" ? "table" : "card";
        props.templateConfigurationContainer.setState({
            ...props.templateConfigurationContainer.state,
            defaultViewType: newView
        });
        localStorage.setItem("modulesViewType", newView)
    };

    const handleSearch = (searchEvent) => {
        clearTimeout(timer);
        props.templateConfigurationContainer.setState({
            ...props.templateConfigurationContainer.state,
            searchFilter: searchEvent.target.value
        });
        timer = setTimeout(reloadTemplates, WAIT_INTERVAL);
    };

    const handleSearchKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            reloadTemplates();
        }
    };

    const reloadTemplates = () => {
        props.templateConfigurationContainer.changeTemplateFilter({
            ...props.templateConfigurationContainer.state,
            currentDiscoverPage: 1
        });
    };

    return (
        <TabContainerPanel className={containerStyle}
            tabs={[{
                title: 'Owned',
                icon: ownedIcon,
                className: "ac-frameworks-owned-tab ac-modules-owned-tab",
                selectedIcon: ownedBlueIcon,
            }, {
                title: 'Discover',
                icon: discoverIcon,
                className: "ac-frameworks-discover-tab ac-modules-discover-tab",
                selectedIcon: discoverBlueIcon
            }]}
            selectedIndex={getSelectedIndex()}
            onTabSelected={handleTabSelection}
            additionalHeaderContent={() => <HorizontalContainer className={searchAndModify}>
                <SearchField
                    id="1"
                    name={"searchField"}
                    placeholder={"Search"}
                    disabled={false}
                    onChange={handleSearch}
                    onKeyDown={handleSearchKeyDown}
                    className={searchField} />
                <div className={"table-grid"}>
                    <RoundedTwoOptionButton
                        updateViewValue={toggleView}
                        selectedOption={props.templateConfigurationContainer.state.defaultViewType == "table"}
                    />
                </div>
            </HorizontalContainer>}
            key={location.href}>
            <TabPanel>
                <OwnedModules templateConfigurationContainer={props.templateConfigurationContainer}
                    appliedTemplatesContainer={props.appliedAppTemplateContainer}
                    applicationId={props.applicationId}
                    appliedTemplates={appliedTemplates}
                    organizationTemplates={organizationTemplates}
                    privateTemplates={privateTemplates?.filter(t => t.organizationId != props.appContainer.state.organizationId)} />
            </TabPanel>
            <TabPanel>
                <DiscoverModules templateConfigurationContainer={props.templateConfigurationContainer}
                    appliedTemplatesContainer={props.appliedAppTemplateContainer}
                    applicationId={props.applicationId}
                    organizationId={props.appContainer.state.organizationId}
                    appliedTemplates={appliedTemplates}
                    availableTemplateConfigurations={availableTemplates}
                    legacyIntegrations={integrationsListings} />
            </TabPanel>
        </TabContainerPanel>
    );
}

const containerStyle = css`
    width: 100%;
    background: ${color_variants_main_bg};
    .panel-tab-list {
        flex: 0;
    }
`;

const searchAndModify = css`
    margin: 12px 0 12px 96px;
    padding: 0 32px;
    border-left: ${style_border_default};
    flex: 1;
    div {        
        box-shadow: none;
    }
    .number-container {
        box-shadow: none;
    }
    .field-container {
        height: 32px;
        border-radius: 16px;
        margin-top: 0px;
    }
    .table-grid {
        margin-left: 16px;
    }   
`;

const searchField = css`
    margin-bottom: 0px;
`;

export default ApplicationModules;