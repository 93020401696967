import React from "react";
import { Subscribe } from "unstated";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import IntegrationsListingsContainer from "../../state/containers/IntegrationsListingsContainer";
import ModalLayout from "../../components/structure/ModalLayout";
import IntegrationListing from "../../models/integrations/IntegrationListing";
import IntegrationSetup from "./components/IntegrationSetup";
import WebhookContainer from "../../state/containers/WebhookContainer";

type AddIntegrationProps = {
    organizationId: string
    integration: IntegrationListing
    onClose: () => void
}

type AddIntegrationState = {
    selectedIntegration: IntegrationListing
}

class AddLegacyIntegration extends React.Component<AddIntegrationProps, AddIntegrationState> {
    state = {
        selectedIntegration: null
    } as AddIntegrationState

    handleClose(hasChanges: boolean) {
        if (hasChanges == true) {
            var confirmClose = confirm("You have unsaved changes. Are you sure you want to close?");
            if (!confirmClose)
                return;
        }

        this.props.onClose();
    }

    render() {
        return (
            <ModalLayout isVisible={true} onClose={this.handleClose.bind(this)}>
                <Subscribe to={[OrganizationContainer, IntegrationsListingsContainer, WebhookContainer]}>
                    {(orgContainer: OrganizationContainer, integrationContainer: IntegrationsListingsContainer, webhookContainer: WebhookContainer) => {
                        return (
                            <IntegrationSetup organizationId={orgContainer.state.currentOrganization.id}
                                integrationContainer={integrationContainer}
                                webhookContainer={webhookContainer}
                                handleClose={this.handleClose.bind(this)}
                                integration={this.props.integration} />
                        )
                    }
                    }
                </Subscribe>
            </ModalLayout>
        )
    }
}

export default AddLegacyIntegration;