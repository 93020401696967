import React from 'react';
import styled from '@emotion/styled';
import ResponseFlagRuleModel from '../../../models/features/api/Flags/ResponseFlagRuleModel';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import Button from '../../../components/general/Button';
import { css } from '@emotion/css';
import { color_text_light } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
const addFlagIcon = require("../../../content/images/content-item-icons/add-flag.svg");
const removeFlagIcon = require("../../../content/images/content-item-icons/remove-flag.svg");
const deleteIcon = require('../../../content/images/condition-icons/delete.svg');
const sessionFlagIcon = require('../../../content/images/content-item-icons/session-flag-light.svg');


interface FlagRulesProps {
    flagRules: ResponseFlagRuleModel[]
    onAddRule: () => void
    onRemoveRule: (flagRule: ResponseFlagRuleModel) => void
}

const FlagRulesGroup: React.FC<FlagRulesProps> = ({
    flagRules,
    onAddRule,
    onRemoveRule
}) => {
    return (
        <>
            <DetailLabel>Sometimes a particular response signifies a special circumstance that has occured. Here you may add or remove flags to help identify those cirucmstances.</DetailLabel>
            {
                flagRules?.length > 0
                    ? <FlagList>
                        {flagRules?.map(f => (<FlagListItem>
                            <img src={f.ruleType == "Remove" ? removeFlagIcon : addFlagIcon}/>
                            <p>{f.applicationConversationFlag?.name}</p>
                            {f.flagScope == "Session" && <img className="session-icon" src={sessionFlagIcon}/>}
                            <button className="remove-button" type="button" onClick={() => onRemoveRule(f)}>
                                <img src={deleteIcon}/>
                            </button>
                        </FlagListItem>))}
                    </FlagList>
                    : <EmptyFlagContainer><p>No changes to User Flags</p></EmptyFlagContainer>
            }
            <HorizontalSeparator />
            <Button themes={['secondary']} className={addButtonStyle} type="button" onClick={() => onAddRule()} text="Add / Remove a Flag" />
        </>
    )
}

const DetailLabel = styled.p(`
    margin: 16px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color_text_light};
`)
const FlagList = styled.ul(`
    list-type: none;
`)
const FlagListItem = styled.li(`
    height: 52px;
    border: ${style_border_default};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 8px;
    img {
        height: 24px;
        width: 24px;
        &.session-icon {
            width: 20px;
            height: 20px;
        }
    }
    p {
        margin: 0 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    .remove-button {
        border: none;
        background: none;
        box-shadow: none;
        padding: 0;
        margin-right: 0;
        margin-left: auto;
    cursor: pointer;
    }
`)
const EmptyFlagContainer = styled.div(`
    background: white;
    border: ${style_border_default};
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 24px;
    p {
        color: ${color_text_light};
        font-style: italic;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
`)
const addButtonStyle = css`
    margin: 24px 0;
    width: 100%;
    cursor: pointer;
`
export default FlagRulesGroup;