import { css } from "@emotion/css";
import React from "react";
interface CodeBlockProps {
    code: string
}
const CodeBlock = (props: CodeBlockProps) => {
    return (
        <pre className={preStyle}>
            <code className={codeStyle}>
                {props.code}
            </code>
        </pre>
    )
}
const preStyle = css`
    margin: 16px 0 32px 0;
    background: #eee;
    padding: 1rem;
    overflow: auto;
    border-radius: 3px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
`;

const codeStyle = css`
    text-align: left;
    white-space: pre-wrap;
    word-spacing: normal;
    word-break: normal;
    word-wrap: wrap;
    line-height: 1.5;
`;

export default CodeBlock