import React from 'react';
import { css } from '@emotion/css';
import MemberContainer from '../../../state/containers/MemberContainer';
import Loader from '../../../components/general/Loader';
import TableHeader from '../../../components/general/TableHeader';
import TableCell from '../../../components/general/TableCell';
import SortedTable from '../../../components/structure/SortedTable';
import RoleToggle from '../../../components/general/RoleToggle';
import Button from '../../../components/general/Button';
import UserAvatar from '../../../components/general/UserAvatar';
import MembershipModel from '../../../models/organizations/api/MembershipModel';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import UserRoleModel from '../../../models/user/UserRoleModel';
import PageError from '../../../components/general/PageError';
import UserContainer from '../../../state/containers/UserContainer';
import ApplicationMembersContainer from '../../../state/containers/ApplicationMembersContainer';
import ApplicationMemberModel from '../../../models/applications/api/ApplicationMemberModel';
import Tooltip from 'rc-tooltip';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import RemoveConfirmation from '../../../components/general/RemoveConfirmation';
const deleteIcon = require('../../../content/images/delete-2-dark.svg');
const deleteIconHover = require('../../../content/images/delete-2-red.svg');
const deleteIconInactive = require('../../../content/images/delete-2-xlight.svg');

interface ApplicationMemberListViewProps {
    memberContainer: MemberContainer
    appContainer: ApplicationContainer
    applicationMembersContainer: ApplicationMembersContainer
    organizationContainer: OrganizationContainer
    userContainer: UserContainer
    organizationId: string
    applicationId: string
    history: any
}
interface ApplicationMemberListViewState {
    isRemoveConfirmationVisible: boolean,
    selectedMember: ApplicationMemberModel
}
class ApplicationMemberListView extends React.Component<ApplicationMemberListViewProps, ApplicationMemberListViewState> {

    constructor(props) {
        super(props);
        this.state = {
            isRemoveConfirmationVisible: false,
            selectedMember: null
        }
    }
    componentWillMount() {
        this.props.memberContainer.getMembers(this.props.organizationId);
        this.props.applicationMembersContainer.getMembersForApp(this.props.applicationId);
        this.props.organizationContainer.getSystemRoles();
        if (this.props.userContainer.state.currentUser == null) {
            this.props.userContainer.getUser();
        }
    }

    handleChangeRole(memberId: string, roleDisplayName: string) {
        var role: UserRoleModel;
        if (roleDisplayName == "Administrator") {
            role = this.props.organizationContainer.state.systemRoles.find(r => r.isAdmin);
        }
        else {
            role = this.props.organizationContainer.state.systemRoles.find(r => !r.isAdmin && r.canEdit)
        }

        this.props.memberContainer.updateMemberRole(memberId, role.id);
    }


    handleChangeAppRole(memberId: string, roleDisplayName: string) {
        const role = this.props.organizationContainer.state.systemRoles.find(r => r.name == roleDisplayName);
        this.props.applicationMembersContainer.updateAppMemberRole(memberId, role.id);
    }

    handleInviteNew() {
        this.props.history.push(`/v/apps/${this.props.applicationId}/members/add`);
    }

    getAdminRoleName(): string {
        const role = this.props.organizationContainer.state.systemRoles.find(r => r.isAdmin);
        if (role != undefined) return role.name;

        return "Administrator";
    }

    getEditorRoleName(): string {
        const role = this.props.organizationContainer.state.systemRoles.find(r => !r.isAdmin && r.canEdit);
        if (role != undefined) return role.name;

        return "Contributor";
    }

    getViewerRoleName(): string {
        const role = this.props.organizationContainer.state.systemRoles.find(r => !r.isAdmin && !r.canEdit);
        if (role != undefined) return role.name;

        return "Viewer";
    }

    handleShowRemove(member: ApplicationMemberModel) {
        this.setState({
            ...this.state,
            isRemoveConfirmationVisible: true,
            selectedMember: member
        })
    }

    handleHideRemove() {
        this.setState({
            ...this.state,
            isRemoveConfirmationVisible: false,
        })
    }


    render() {
        const { isLoadingMembers } = this.props.applicationMembersContainer.state;
        if (isLoadingMembers || this.props.organizationContainer.state.isLoadingRoles || this.props.userContainer.state.currentUser == null) {
            return <Loader />
        }
        const members = this.props.applicationMembersContainer.state.members.filter(m => m.applicationId == this.props.applicationId);
        const userId = this.props.userContainer.state.currentUser.id;
        const isAdmin = this.props.applicationMembersContainer.isUserAppAdmin(userId, this.props.applicationId);

        const columns = [
            {
                id: "member",
                Header: <TableHeader text="MEMBER" />,
                accessor: (m: ApplicationMemberModel) => m,
                Cell: row => (
                    <TableCell>
                        <UserAvatar className={avatarStyle} noBackground lastName={row.value.organizationMember.lastName} firstName={row.value.organizationMember.firstName} src={row.value.organizationMember.imageUrl} size={32} />
                        <span>
                            {`${row.value.organizationMember.firstName} ${row.value.organizationMember.lastName}`}
                        </span>
                    </TableCell>
                )
            },
            {
                id: "email",
                Header: <TableHeader text='EMAIL' />,
                accessor: (m: ApplicationMemberModel) => m,
                Cell: row => (
                    <TableCell>
                        {row.value.organizationMember.email}
                    </TableCell>
                )
            },
            {
                id: "role",
                Header: <TableHeader text='ORGANIZATION ROLE' />,
                accessor: (m: ApplicationMemberModel) => m,
                Cell: row => (
                    <TableCell>
                        {isAdmin && row.value.organizationMember.userId != userId ?

                            <RoleToggle currentRole={row.value.organizationMember.isAdmin ? "Administrator" : "Contributor"}
                                roles={["Administrator", "Contributor"]}
                                onChange={(roleName) => this.handleChangeRole(row.value.organizationMember.id, roleName)} />

                            : <span>{row.value.organizationMember.isAdmin ? "Administrator" : "Contributor"}</span>}
                    </TableCell>
                )
            },
            {
                id: "appMemberRole",
                Header: <TableHeader text='APPLICATION ROLE' />,
                accessor: (m: ApplicationMemberModel) => m,
                Cell: row => (
                    <TableCell>
                        {isAdmin && row.value.organizationMember.userId != userId ?

                            <RoleToggle currentRole={row.value.isAdmin ? this.getAdminRoleName() : (row.value.canEdit ? this.getEditorRoleName() : this.getViewerRoleName())}
                                roles={this.props.organizationContainer.state.systemRoles.map(r => r.name)}
                                onChange={(roleName) => this.handleChangeAppRole(row.value.id, roleName)} />

                            : <span>{row.value.isAdmin ? this.getAdminRoleName() : (row.value.canEdit ? this.getEditorRoleName() : this.getViewerRoleName())}</span>}
                    </TableCell>
                )
            },
            {
                id: "removeMemberships",
                Header: <TableHeader text='' />,
                accessor: (m: MembershipModel) => m,
                width: 50,
                Cell: row => (
                    <TableCell>
                        <div className={deleteContainer}>
                            <Tooltip
                                mouseEnterDelay={.5}
                                overlayClassName={tooltipContentStyle}
                                trigger={['hover']}
                                placement="top"
                                overlay={isAdmin ?
                                    this.props.userContainer.state.currentUser.id == row.value.organizationMember.userId ?
                                        <div className={tooltipText}>Cannot remove<br />own account</div>
                                        :
                                        <span>Remove</span>
                                    :
                                    <div className={tooltipText}>Requires admin<br />permissions</div>}
                            >
                                <button onClick={() => isAdmin && this.props.userContainer.state.currentUser.id != row.value.organizationMember.userId && this.handleShowRemove(row.value)}>
                                    {isAdmin && this.props.userContainer.state.currentUser.id != row.value.organizationMember.userId ?
                                        <div>
                                            <img src={deleteIcon} className="static-icon" />
                                            <img src={deleteIconHover} className="active-icon" />
                                        </div>
                                        :
                                        <img src={deleteIconInactive} />
                                    }
                                </button>
                            </Tooltip>
                        </div>
                    </TableCell>
                )
            }
        ]
        return (
            <div>
                <Button text="Invite New" themes={["primary", "end-tight"]} onClick={this.handleInviteNew.bind(this)} disabled={!isAdmin} />

                <SortedTable columns={columns} data={members} />
                <PageError errors={this.props.applicationMembersContainer.state.errors} />
                {this.state.isRemoveConfirmationVisible ?
                    <ConfirmationDialog
                        title="Remove"
                        deleteText="Yes, Remove"
                        isLoading={this.props.applicationMembersContainer.state.isLoading}
                        onClose={this.handleHideRemove.bind(this)}
                        onConfirm={() => {
                            this.props.applicationMembersContainer.removeMemberFromApp(this.state.selectedMember?.id)
                            this.handleHideRemove()
                        }}>
                        <RemoveConfirmation
                            removalTarget={this.props.appContainer.state.currentApp?.name}
                            memberName={this.state.selectedMember?.organizationMember?.firstName + " " + this.state.selectedMember?.organizationMember?.lastName} />
                    </ConfirmationDialog> : null}
            </div>
        );
    }
}

const avatarStyle = css`
    margin-right: 16px;
`;

const deleteContainer = css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    button {
        border: none;
        box-shadow: none;
        background: none;
        color: transparent;
        padding: 0;
        cursor: pointer;
        &:hover {
            .active-icon {
                display: block;
            }
            .static-icon {
                display: none;
            }
        }       

        .active-icon {
            display: none;
        }
        
        img {
            height: 24px;
            width: 24px;
            margin: 2px;
        }
    }
`

const tooltipText = css`
    text-align: center
`

const tooltipContentStyle = css`
    .rc-tooltip-inner{
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0.6px;
        z-index: 120;
        color: white;
        background-color: #304562;
        box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
        padding: 8px;
        min-height: 0;
    }
`


export default ApplicationMemberListView;
