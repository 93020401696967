import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/css";
import RoundedSelectionRow from "../../../components/general/RoundedSelectionRow";
import Button from "../../../components/general/Button";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import InteractionModelUiStateContainer, { MenuEditorView } from "../../../hooks/InteractionModelUiStateContainer";

const exportIcon = require("../../../content/images/menu-item-icons/file-code-download.svg");
const importIcon = require("../../../content/images/menu-item-icons/file-code-upload.svg");

interface VoicifyMenuHeaderProps {
    isLoading: boolean;
    handleAddItemClick?: () => void;
    showAddItemButton?: boolean;
    handleImportMenuClick?: () => void;
    handleExportMenuClick?: () => void;
}

export const VoicifyMenuHeader = (props: VoicifyMenuHeaderProps) => {
    const uiStateContainer = InteractionModelUiStateContainer.useContainer();

    const options = [
        { name: "Menu Items" },
        { name: "Option Groups" },
        { name: "Size Groups" }
    ];

    const selectOption = (index: number) => {
        switch (index) {
            case 0:
                uiStateContainer.setMenuEditorView("Menu Items");
                break;
            case 1:
                uiStateContainer.setMenuEditorView("Option Groups");
                break;
            case 2:
                uiStateContainer.setMenuEditorView("Size Groups");
                break;
            default:
                break;
        }
    };

    const selectedFilter = (options: MenuEditorView) => {
        switch (options) {
            case "Menu Items":
                return 0;
            case "Option Groups":
                return 1;
            case "Size Groups":
                return 2;
            default:
                return 0;
        }
    };

    return (
        <>
            <MenuHeaderWrapper>
                <MenuHeaderTopRow>
                    <StyledRoundedSelectionRow
                        options={options}
                        className={roundedSelectStyle}
                        optionClassName={roundedOptionStyle}
                        optionTextClassName={optionTextStyle}
                        onClick={index => selectOption(index)}
                        selectedFilter={selectedFilter(uiStateContainer.menuEditorView)}
                    />
                    <ButtonWrapper>
                        {props.showAddItemButton &&
                            <>
                                <Button
                                    themes={["primary-small", "start"]}
                                    disabled={props.isLoading}
                                    loading={props.isLoading}
                                    onClick={() => props.handleAddItemClick()}
                                    className={addItemButton}
                                    text="Add an item"
                                />
                            </>
                        }
                    </ButtonWrapper>
                </MenuHeaderTopRow>
                {props.showAddItemButton &&
                    <MenuHeaderBottomRow>
                        <Button
                            disabled={props.isLoading}
                            loading={props.isLoading}
                            type="submit"
                            themes={['secondary-small', 'end-tight']}
                            rightIcon={importIcon}
                            className={importButton}
                            onClick={() => props.handleImportMenuClick()}
                            text="Import Full Menu"
                        />
                        <Button
                            disabled={props.isLoading}
                            loading={props.isLoading}
                            type="submit"
                            themes={['secondary-small', 'end-tight']}
                            rightIcon={exportIcon}
                            onClick={() => props.handleExportMenuClick()}
                            className={exportButton}
                            text="Export Full Menu"
                        />
                    </MenuHeaderBottomRow>
                }
            </MenuHeaderWrapper>
            <HorizontalSeparator />
        </>

    )
}

const addItemButton = css`
    margin-right: 0;
    margin-top: 4px;
`;

const MenuHeaderTopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const MenuHeaderBottomRow = styled.div`
    align-self: flex-end;
`;

const exportButton = css`
    margin: auto auto 16px auto;
`;

const importButton = css`
    margin: auto 8px auto auto;
`;

const ButtonWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

const StyledRoundedSelectionRow = styled(RoundedSelectionRow)`
    flex: 1;
    margin-bottom: 16px;
`;

const MenuHeaderWrapper = styled.div`
    flex-direction: column;
    display: flex;
`;

const roundedSelectStyle = css`
    height: 32px;
`;

const roundedOptionStyle = css`
    height: 35px;
`;

const optionTextStyle = css`
    margin-bottom: 4px;
`;

export default VoicifyMenuHeader;