import React from 'react';
import { FormikProps } from 'formik';
import ConversationItemFormProps from '../../models/features/ConversationItemFormProps';
import ConversationItemFormData from '../../models/features/ConversationItemFormData';
import LanguageModel from '../../models/features/api/LanguageModel';
import NestedTree, { TreeItem } from '../../components/structure/NestedTree/NestedTree';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import * as H from 'history';
import { getFeatureContentUrl } from '../../models/extensions';
import ConfirmationDialog from '../../components/structure/ConfirmationDialog';
import RelinkConfirmation from '../../components/forms/RelinkConfirmation';
import WebhookInstanceManager from '../webhookInstanceManager';
import { OverflowOption } from '../../components/general/overflowMenu/OverflowMenu';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';
import ResponseInstanceModel from '../../models/features/api/ResponseInstanceModel';
import ResponseGroup from '../../models/features/ResponseGroup';
import ConditionInstanceModel from '../../models/features/api/Conditions/ConditionInstanceModel';
import _ from 'lodash';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import ApplicationHistoryToolbar from '../applicationContentMenuPanel/components/ApplicationHistoryToolbar';
import PublishHistoryPanel from '../applicationHistoryPanel';
import styled from '@emotion/styled';
import { color_variants_ocean_light_opaque } from '../../constants/colors';
import { style_border_default } from '../../constants/stylesValues';

const contentInfoIcon = require('../../content/images/content-tree-icons/content-level/task-list-pin.svg');
const titleIcon = require('../../content/images/content-tree-icons/content-level/title.svg');
const langIcon = require('../../content/images/content-tree-icons/content-level/language-region.svg');
const webhookIcon = require('../../content/images/webhooks/webhook-dark.svg');
const deleteIcon = require('../../content/images/content-explorer/delete.svg');
const duplicateIcon = require('../../content/images/content-explorer/copy.svg');
const inputGroupIcon = require('../../content/images/content-tree-icons/content-level/input-stack.svg');
const inputIcon = require('../../content/images/content-tree-icons/content-level/input-single.svg');
const responseGroupIcon = require('../../content/images/content-tree-icons/content-level/response-stack.svg');
const mediaIcon = require('../../content/images/content-tree-icons/content-level/media-attachments.svg');
const outputIcon = require('../../content/images/content-tree-icons/content-level/device-avatar.svg');
const conditionalResponseIcon = require('../../content/images/content-tree-icons/content-level/conditional-response.svg');
const decisionIcon = require('../../content/images/decision.svg');
const guidanceIcon = require('../../content/images/compass.svg');
const hintsIcon = require('../../content/images/hints.svg');
const destinationIcon = require('../../content/images/pin.svg');
const helpIcon = require('../../content/images/contextual-help.svg');
const fallbackIcon = require('../../content/images/contextual-fallback.svg');
const repromptIcon = require('../../content/images/reprompt-icon.svg');
const followUpCardIcon = require('../../content/images/content-tree-icons/content-level/follow-up.svg');
const addIcon = require('../../content/images/add-circle-dark.svg');


export interface ContentItemPageState {
    expandedTreeNodeIds?: string[],
    isRelinkOpen?: boolean,
    isWebhookInstanceManagerOpen?: boolean,
    isHistoryExpanded?: boolean
    showSensitiveConversation?: boolean
}
abstract class BaseConversationItemForm<TContentItem extends ContentItemModel,
    TProps extends ConversationItemFormProps<TContentItem>,
    TFormData extends ConversationItemFormData, TState extends ContentItemPageState>
    extends React.Component<TProps & FormikProps<TFormData>, TState>  {

    componentDidMount() {
        // handle anchor link scrolling
        this.props.history?.listen(location => {
            const { hash } = location;
            if (hash !== '') {
                // Push onto callback queue so it runs after the DOM is updated,
                // this is required when navigating from a different page so that
                // the element is rendered on the page before trying to getElementById.
                setTimeout(
                    () => {
                        const id = hash.replace('#', '');
                        const element = document.getElementById(id);
                        if (element) {
                            const scrollable = element.closest(".scrollable-panel").children[0];
                            if (scrollable) {
                                scrollable.scrollTop = element.offsetTop - 30; // header offset values
                            }
                        }
                    },
                    0
                );
            }
        });
        this.handleTreeExpandChange(this.getAllExpandableIds(this.getMenuTreeRoots()));
    }

    toggleSensitiveConversation() {
        this.setState({
            ...this.state,
            showSensitiveConversation: !this.state.showSensitiveConversation
        })
    }

    closeSensitiveConversation() {
        this.setState({
            ...this.state,
            showSensitiveConversation: false
        })
    }

    handleSaveAndPublish() {
        this.props.onShowPublishModal();
    }

    expandContentNavigationTree() {
        this.handleTreeExpandChange(this.getAllExpandableIds(this.getMenuTreeRoots()));
    }

    getAllExpandableIds(items: TreeItem[]): string[] {
        const ids: string[] = [];
        items?.forEach((item) => {
            if (item.canExpand)
                ids.push(item.id);
            if (item.children != null && item.children.length > 0)
                this.getAllExpandableIds(item.children).forEach(id => ids.push(id));
        });

        return ids;
    }

    handleLanguagesChange(languages: LanguageModel[]) {
        this.props.setFieldValue("languages", languages);
    }

    handleTreeExpandChange(expandedIds: string[]) {
        this.setState({
            ...this.state,
            expandedTreeNodeIds: expandedIds
        })
    }

    handleTreeItemClick(item: TreeItem) {
        if (item.canExpand) {
            const expandedIds = this.state.expandedTreeNodeIds ?? [];
            if (expandedIds.some(id => id == item.id)) {
                expandedIds.splice(expandedIds.indexOf(item.id), 1);
            }
            else {
                expandedIds.push(item.id);
            }
            this.handleTreeExpandChange(expandedIds);
        }
    }

    handleFinish(url: string = null) {
        if (url == null) {
            if (this.props.history.location.key) { //https://github.com/ReactTraining/history/issues/582
                this.props.history.goBack();
            } else {
                const featureUrl = getFeatureContentUrl(this.props.application?.id, this.props.applicationFeatureId);
                this.props.history.push(featureUrl);
            }
        }
        else {
            this.props.history.push(url);
        }
    }

    getContentInfoTreeItem(): TreeItem {
        return {
            id: 'content-info',
            title: 'Content Info',
            isExpanded: this.isItemExpanded('content-info'),
            canExpand: true,
            linkTo: '#contentInfo',
            icon: contentInfoIcon,
            children: [{
                id: 'title',
                title: 'Title',
                linkTo: '#contentTitle',
                icon: titleIcon
            }, {
                id: 'language',
                title: 'Language-Region',
                linkTo: '#contentLanguage',
                icon: langIcon
            }]
        }
    };

    // Generate response's unique key based on all conditions ids recursively
    getConditionsKey(conditions: ConditionInstanceModel[]) {
        // builds the conditions key used for grouping responses
        const keys = []
        _.orderBy(conditions ?? [], ['conditionId'])?.forEach((c, i) => {
            keys.push(c.conditionId + c.value);
            if (c.andCondition)
                keys.push(this.getConditionsKey([c.andCondition]));
        });
        return keys.sort().join();
    };

    // Generate responses groups array based on all responses grouped by response conditions ordered by group's priority
    // Response Group is a helpful construct generated by UI code and does not exist in back end
    getResponseGroups(responses: ResponseInstanceModel[]) {
        const responseGroups = [] as ResponseGroup[];
        const minGroupPriority: number = Math.min.apply(Math, responses?.map(response => response.groupPriority ?? 0));

        responses?.forEach(response => {
            const key = this.getConditionsKey(response.conditions);
            const group = responseGroups.find(g => g.key == key);
            if (group) {
                group.responses.push(response);
            }
            else {
                // Calculate responses group priority; if response has no conditions the key will be empty
                let groupPriority: number = key ? responseGroups.length + 1 : 0;

                // If we already have responses group priority then use it
                groupPriority = response.groupPriority ?? groupPriority;

                // Default group should be first and have minimum group priority, let's double check
                if (!key && groupPriority != minGroupPriority) {
                    groupPriority = minGroupPriority - 1;
                }
                const newGroup: ResponseGroup = {
                    key,
                    conditions: response.conditions,
                    responses: [response],
                    firstResponse: response,
                    priority: groupPriority
                };
                responseGroups.push(newGroup);
            }
            
            for (const responseGroup of responseGroups) {
                if (responseGroup?.responses?.length && responseGroup.responses.length > 1) {
                    if (responseGroup?.responses[0]?.followUp) {
                        if (responseGroup?.responses?.some(r => !r?.followUp)) {
                            for (let i = 1; i < responseGroup?.responses.length; i++) {
                                if (!responseGroup?.responses[i]?.followUp) {
                                    responseGroup.responses[i].followUp = responseGroup?.responses[0]?.followUp;
                                }
                            }
                        }
                    }
                }
            }
        });

        const responseGroupsOrdered = _.orderBy(responseGroups, ['priority', 'key']);
        return responseGroupsOrdered;
    };

    getResponsesFromGroups(responseGroups: ResponseGroup[]) {
        const responseInstances = [] as ResponseInstanceModel[]
        responseGroups?.forEach(group => {
            responseInstances.push(...group?.responses?.map(response =>
                ({ ...response, conditions: group.conditions, groupPriority: group.priority } as ResponseInstanceModel)
            )
            );
        });

        return responseInstances;
    };

    /// builds the tree of responses based on the form data
    getResponsesTreeItems(responses: ResponseInstanceModel[]): TreeItem[] {
        // Response groups are ordered by group priority and key
        const responseGroups = this.getResponseGroups(responses);

        return [...responseGroups?.map((group, index) => ({
            id: `device-response-${index}`,
            title: group.key ? `Conditional Response ${index}` : "Default Response",
            isExpanded: this.isItemExpanded(`device-response-${index}`),
            canExpand: true,
            icon: group.key ? conditionalResponseIcon : outputIcon,
            linkTo: `#g${index}deviceResponse`,
            children: group.key ? [
                {
                    id: `g${index}conditions`,
                    title: 'Conditions',
                    canExpand: false,
                    icon: decisionIcon,
                    linkTo: `#g${index}deviceResponse`
                },
                {
                    id: `g${index}response-options`,
                    title: 'Response Variations',
                    canExpand: true,
                    isExpanded: this.isItemExpanded(`g${index}response-options`),
                    icon: responseGroupIcon,
                    linkTo: `#g${index}responseOptions`,
                    children: group.responses?.map((q, i) => ({
                        id: `g${index}r${i}`,
                        title: `Variation ${i + 1}`,
                        canExpand: false,
                        icon: inputIcon,
                        linkTo: `#g${index}response${i}`
                    }))
                },
                {
                    id: `g${index}media`,
                    title: 'Attached Media',
                    icon: mediaIcon,
                    linkTo: `#g${index}media`
                },
                this.getFollowUpTreeItem(index, group.firstResponse?.followUp)
            ]
            :
            [
                {
                    id: `g${index}response-options`,
                    title: 'Response Variations',
                    canExpand: true,
                    isExpanded: this.isItemExpanded(`g${index}response-options`),
                    icon: responseGroupIcon,
                    linkTo: `#g${index}responseOptions`,
                    children: group.responses?.map((q, i) => ({
                        id: `g${index}r${i}`,
                        title: `Variation ${i + 1}`,
                        canExpand: false,
                        icon: inputIcon,
                        linkTo: `#g${index}response${i}`
                    }))
                },
                {
                    id: `g${index}media`,
                    title: 'Attached Media',
                    icon: mediaIcon,
                    linkTo: `#g${index}media`
                },
                this.getFollowUpTreeItem(index, group.firstResponse?.followUp)
            ]
            })),
            {
                id: "add-conditional-response",
                title: "Add a Conditional Response",
                linkTo: "#addConditionalResponse",
                canExpand: false,
                icon: addIcon
            }
        ];
    }
    getFollowUpTreeItem(groupIndex: number, followUp: FollowUpModel): TreeItem {
        const children: TreeItem[] = [];
        if (followUp) {
            children.push({
                id: `g${groupIndex}guidance`,
                title: 'Guidance',
                icon: guidanceIcon,
                linkTo: `#g${groupIndex}guidance`,
                canExpand: false,
            })
            if (followUp?.childContentContainer) {
                children.push({
                    id: `g${groupIndex}destination`,
                    title: 'Destination',
                    icon: destinationIcon,
                    linkTo: `#g${groupIndex}destination`,
                    canExpand: false
                })
            }
            children.push({
                id: `g${groupIndex}on-screen-hint`,
                title: 'On-screen Hint',
                icon: hintsIcon,
                linkTo: `#g${groupIndex}on-screen-hint`,
                canExpand: false,
            })
            if (followUp.childContentContainer?.isLimitedToChildren) {
                children.push({
                    id: `g${groupIndex}contextualFallback`,
                    title: 'Contextual Fallback',
                    icon: fallbackIcon,
                    linkTo: `#g${groupIndex}contextualFallback`,
                    canExpand: false
                });
                children.push({
                    id: `g${groupIndex}contextualHelp`,
                    title: 'Contextual Help',
                    icon: helpIcon,
                    linkTo: `#g${groupIndex}contextualHelp`,
                    canExpand: false
                });
            }
            children.push({
                id: `g${groupIndex}reprompt`,
                title: 'Reprompt',
                icon: repromptIcon,
                linkTo: `#g${groupIndex}reprompt`,
                canExpand: false
            })
        }
        const treeItem: TreeItem = {
            id: `g${groupIndex}follow-up`,
            title: 'Follow-Up',
            icon: followUpCardIcon,
            canExpand: children.length > 0,
            isExpanded: this.isItemExpanded(`g${groupIndex}follow-up`),
            linkTo: `#g${groupIndex}followUp`,
            children: children
        }
        return treeItem;
    }
    isItemExpanded(id: string) {
        return this.state?.expandedTreeNodeIds?.some(i => i == id);
    }
    abstract getMenuTreeRoots(): TreeItem[]
    renderTree() {
        const contentId = this.getContentItem()?.id;
        if (!contentId) {
            return (
                this.getMenuTreeRoots()?.map((t, i) => <NestedTree key={i} root={t} onItemClick={this.handleTreeItemClick.bind(this)} />)
            )
        }
        return <>
            <ApplicationHistoryToolbar
                onToggle={() => this.setState({ ...this.state, isHistoryExpanded: !this.state?.isHistoryExpanded })}
                isExpanded={this.state?.isHistoryExpanded} />
            {this.state?.isHistoryExpanded ?
                <div className="tree-container">
                    <PublishHistoryPanel contentId={contentId} contentItemContainer={this.props.stateContainer} applicationId={this.props.application?.id} onClose={() => this.setState({ ...this.state, isHistoryExpanded: false })} />
                </div>
                : this.getMenuTreeRoots()?.map((t, i) => <NestedTree key={i} root={t} onItemClick={this.handleTreeItemClick.bind(this)} />)
            }
        </>
    }

    manualValidate() {
        var isValid = Object.keys(this.props.errors).length == 0; //In Formik 1.x isValid is not true by default, and so some forms validated against initialValues would have isValid=false. See https://github.com/ReactTraining/history/issues/582
        if (!isValid) {
            this.props.stateContainer?.setError("You have incomplete required fields.");
        }
        return isValid;
    }
    handleToggleRelinkModal() {
        this.setState({
            ...this.state,
            isRelinkOpen: !this.state?.isRelinkOpen
        })
    }
    handleRelink() {
        this.props.onToggleSync(this.getContentItem()?.id, false).then(_ => {
            this.setState({
                ...this.state,
                isRelinkOpen: false
            })
        })
    }
    renderRelinkSyncWarning() {
        if (this.state?.isRelinkOpen) {
            return (<ConfirmationDialog title={"Re-Link to Template"} deleteText="Re-Link"
                isLoading={this.props.stateContainer.state.isTogglingSync}
                onClose={this.handleToggleRelinkModal.bind(this)}
                warning
                onConfirm={this.handleRelink.bind(this)}>
                <RelinkConfirmation />
            </ConfirmationDialog>)
        }

        return null;
    }

    closeWebhookInstanceManager() {
        this.setState({
            ...this.state,
            isWebhookInstanceManagerOpen: false
        });
    }

    showWebhookInstanceManager() {
        this.setState({
            ...this.state,
            isWebhookInstanceManagerOpen: true
        });
    }

    stageWebhookUpdates(updateRequest: BulkWebhookInstancesUpdateRequest) {
        this.props.stateContainer.stageWebhookUpdates(updateRequest);
        this.props.setFieldValue("webhookChainingType", updateRequest.webhookChainingType);
    }

    getOptions(isEditing: boolean): OverflowOption[] {
        let overflowOptions: OverflowOption[] = [{ icon: webhookIcon, hoverIcon: webhookIcon, label: "Manage Webhooks" }];
        if (isEditing) {
            overflowOptions.push({
                label: "Duplicate",
                icon: duplicateIcon,
                hoverIcon: duplicateIcon
            });
            overflowOptions.push({
                label: "Delete",
                isDestructive: true,
                icon: deleteIcon,
                hoverIcon: deleteIcon
            })
        }
        return overflowOptions;
    }

    getPreviewResponses(responses: ResponseInstanceModel[]): ConversationItem[] {
        // Response groups are ordered by group priority and key
        const responseGroups = this.getResponseGroups(responses);

        return responseGroups.map((group, index) => ({
            responseTitle: group.key ? `Conditional Response ${index}` : "Default Response",
            followUp: group.firstResponse?.followUp,
            reprompt: group.firstResponse?.reprompt,
            responses: group.responses.map(response => ({
                content: response.content,
                highlights: [response.content],
                id: response.content,
                mediaResponseContainer: response.mediaResponseContainer,
                displayTextOverride: response.displayTextOverride,
                displayTitleOverride: response.displayTitleOverride,
                audioItem: response.audio,
                videoItem: response.video,
                imageItem: response.largeImage,
                backgroundImageItem: response.backgroundImage
            }))
        }))
    }

    renderWebhookInstanceManager(appId: string, contentItemId: string, featureTypeId: string, webhookChainingType: WebhookChainingType) {
        return (
            <WebhookInstanceManager show={this?.state?.isWebhookInstanceManagerOpen}
                webhookContainerId={contentItemId ?? ""}
                shouldNotLoad={contentItemId == null || this.props.stateContainer.state.hasWebhookUpdate}
                stagedUpdate={this.props.stateContainer.state.hasWebhookUpdate ? this.props.stateContainer.state.webhookUpdates : null}
                featureTypeId={featureTypeId}
                applicationId={appId}
                webhookInstanceScope="Content"
                existingWebhookChainingType={webhookChainingType}
                onClose={this.closeWebhookInstanceManager.bind(this)}
                manualSave={this.stageWebhookUpdates.bind(this)}
            />
        )
    }

    abstract getContentItem();

    handleClearError() {
        this.props.stateContainer?.clearErrors();
    }
}
export const HighlightSection = styled.div`
    padding: 32px 32px 0 32px;
    margin: -32px -32px 32px -32px;
    background: ${color_variants_ocean_light_opaque};
    border-bottom: ${style_border_default};
`
export default BaseConversationItemForm;


