
import React from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import TextField from "../../../../components/forms/TextField";
import HorizontalSeparator from "../../../../components/structure/HorizontalSeparator";
import StyledDropdown from "./StyledDropdown";
import { color_shades_darker, color_shades_darkest } from "../../../../constants/colors";
import { closeAssistantButtonImageOptions, micActiveImageOptions, minimizeIconOptions, sendActiveImageOptions, themeStyleOptions } from "../../../../models/customAssistant/CustomAssistantConfigurationFormOptions";
import { CustomAssistantConfigurationsFormData } from "../../../../models/customAssistant/CustomAssistantConfigurationsFormData";
import { FormikProps } from "formik";
import { CodeExportLanguage } from "../../../../models/customAssistant/CustomAssistantCodeExportTypes";
import IconTooltip from "../../../../components/general/IconTooltip";

interface AppearanceTabPanelProps {
    handleOnChange?: (optionValue: string, value: string | boolean) => void
    codeExportLanguage?: CodeExportLanguage
}

const AppearanceTabPanel: React.FC<AppearanceTabPanelProps & FormikProps<CustomAssistantConfigurationsFormData>> = (props) => {

    return (
        <ConfigurationTabContent>
            <SubHeader>Appearance</SubHeader>
            <Description>How your assistant will look to your users</Description>
            <SectionHeaderWrapper>
                <SectionHeaderText>Theme</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <Description>Use a pre-set theme to get a start on the style you want</Description>
            <Label>Theme Style</Label>
            <StyledDropdown
                value={props.values.themeStyle ?? ""}
                handleOnChange={props.handleOnChange}
                fieldName="themeStyle"
                options={themeStyleOptions}
                size="medium"
                className={dropDownStyleMedium}
            />
            <TextField
                name="primaryColor"
                tooltip="Must be a valid hex color code"
                toolTipPosition="right"
                disabled={false}
                value={props.values.primaryColor ?? ""}
                label="Primary Color"
                placeholder="Primary Color"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                fieldContainerStyle={fieldContainerStyle}
                className={textFieldStyle}
            />
            <SectionHeaderWrapper>
                <SectionHeaderText>Text</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <TextField
                name="font"
                tooltip="Ensure your website and/or mobile app has support for the font you choose"
                toolTipPosition="right"
                disabled={false}
                value={props.values.font ?? ""}
                label="Font"
                placeholder="Font"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                fieldContainerStyle={fieldContainerStyle}
                className={textFieldStyleTwo}
            />
            <SectionHeaderWrapper>
                <SectionHeaderText>Overall Assistant</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <IndentedTextFieldWrapper>
                <IndentedTextFieldLabelWrapper>
                    <IndentedTextFieldLabel>Background Color</IndentedTextFieldLabel>
                    <IconTooltip
                        text="Must be a valid hex color code"
                        place="right"
                    />
                </IndentedTextFieldLabelWrapper>
                <TextField
                    name="overallBackgroundColor"
                    disabled={false}
                    value={props.values.overallBackgroundColor ?? ""}
                    placeholder="Background Color"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    fieldContainerStyle={indentedTextFieldBoxStyle}
                    className={indentedTextFieldStyle}
                />
            </IndentedTextFieldWrapper>
            <>
                <SectionHeaderWrapper>
                    <SectionHeaderText>Header</SectionHeaderText>
                    <HorizontalSeparator />
                </SectionHeaderWrapper>
                {props.values.uiType !== "minimal" &&
                    <>
                        <IndentedTextFieldWrapper>
                            <IndentedTextFieldLabelWrapper>
                                <IndentedTextFieldLabel>Background Color</IndentedTextFieldLabel>
                                <IconTooltip
                                    text="Must be a valid hex color code"
                                    place="right"
                                />
                            </IndentedTextFieldLabelWrapper>
                            <TextField
                                name="headerBackgroundColor"
                                disabled={false}
                                value={props.values.headerBackgroundColor ?? ""}
                                placeholder=" Background Color"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={indentedTextFieldBoxStyle}
                                className={indentedTextFieldStyle}
                            />
                        </IndentedTextFieldWrapper>
                        <IndentedTextFieldWrapper>
                            <IndentedTextFieldLabelWrapper>
                                <IndentedTextFieldLabel>Font Color</IndentedTextFieldLabel>
                                <IconTooltip
                                    text="Must be a valid hex color code"
                                    place="right"
                                />
                            </IndentedTextFieldLabelWrapper>
                            <TextField
                                name="headerAssistantNameTextColor"
                                disabled={false}
                                value={props.values.headerAssistantNameTextColor ?? ""}
                                placeholder=" Font Color"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={indentedTextFieldBoxStyle}
                                className={indentedTextFieldStyle}
                            />
                        </IndentedTextFieldWrapper>
                    </>
                }
                <Label>Close Style</Label>
                <StyledDropdown
                    handleOnChange={props.handleOnChange}
                    fieldName="headerCloseAssistantButtonImage"
                    options={closeAssistantButtonImageOptions}
                    size="medium"
                    className={dropDownStyleMedium}
                    value={props.values.headerCloseAssistantButtonImage ?? ""}
                />
                {props.values.uiType !== "minimal" &&
                    <>
                        <Label>Minimize Style</Label>
                        <StyledDropdown
                            handleOnChange={props.handleOnChange}
                            fieldName="headerMinimizeIcon"
                            options={minimizeIconOptions}
                            size="medium"
                            className={dropDownStyleMedium}
                            value={props.values.headerMinimizeIcon ?? ""}
                        />
                    </>
                }
                <SectionHeaderWrapper>
                    <SectionHeaderText>Chat Section</SectionHeaderText>
                    <HorizontalSeparator />
                </SectionHeaderWrapper>
                <IndentedTextFieldWrapper>
                    <IndentedTextFieldLabelWrapper>
                        <IndentedTextFieldLabel>Chat Section Background Color</IndentedTextFieldLabel>
                        <IconTooltip
                            text="Must be a valid hex color code"
                            place="right"
                        />
                    </IndentedTextFieldLabelWrapper>
                    <TextField
                        name="bodyBackgroundColor"
                        disabled={false}
                        value={props.values.bodyBackgroundColor ?? ""}
                        placeholder="Background Color"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        fieldContainerStyle={indentedTextFieldBoxStyle}
                        className={indentedTextFieldStyle}
                    />
                </IndentedTextFieldWrapper>
            </>
            <SectionHeaderWrapper>
                <SectionHeaderText>Inputs</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            {props.values.uiType !== "minimal" &&
                <>
                    <IndentedTextFieldWrapper>
                        <IndentedTextFieldLabelWrapper>
                            <IndentedTextFieldLabel>Background Color</IndentedTextFieldLabel>
                            <IconTooltip
                                text="Must be a valid hex color code"
                                place="right"
                            />
                        </IndentedTextFieldLabelWrapper>
                        <TextField
                            name="toolbarBackgroundColor"
                            disabled={false}
                            value={props.values.toolbarBackgroundColor ?? ""}
                            placeholder="Background Color"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            fieldContainerStyle={indentedTextFieldBoxStyle}
                            className={indentedTextFieldStyle}
                        />
                    </IndentedTextFieldWrapper>
                    <IndentedTextFieldWrapper>
                        <IndentedTextFieldLabelWrapper>
                            <IndentedTextFieldLabel>Font Color</IndentedTextFieldLabel>
                            <IconTooltip
                                text="Must be a valid hex color code"
                                place="right"
                            />
                        </IndentedTextFieldLabelWrapper>
                        <TextField
                            name="toolbarFontColor"
                            disabled={false}
                            value={props.values.toolbarFontColor ?? ""}
                            placeholder="Font Color"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            fieldContainerStyle={indentedTextFieldBoxStyle}
                            className={indentedTextFieldStyle}
                        />
                    </IndentedTextFieldWrapper>
                </>
            }
            <TextField
                name="toolbarPlaceholder"
                disabled={false}
                value={props.values.toolbarPlaceholder ?? ""}
                label="Placeholder Text"
                placeholder="Placeholder Text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                fieldContainerStyle={fieldContainerStyle}
            />
            <Label>Speak Style</Label>
            <StyledDropdown
                handleOnChange={props.handleOnChange}
                fieldName="toolbarMicActiveImage"
                options={micActiveImageOptions}
                size="medium"
                className={dropDownStyleMedium}
                value={props.values.toolbarMicActiveImage ?? "https://voicify-prod-files.s3.amazonaws.com/sdk-images/micThreeActive.svg"}
            />
            <Label>Send Style</Label>
            <StyledDropdown
                handleOnChange={props.handleOnChange}
                fieldName="toolbarSendActiveImage"
                options={sendActiveImageOptions}
                size="medium"
                className={dropDownStyleMedium}
                value={props.values.toolbarSendActiveImage ?? "https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendTwoActive.svg"}
            />
        </ConfigurationTabContent>
    )

};

const IndentedTextFieldLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    flex-grow: 1;
`;

const IndentedTextFieldWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const IndentedTextFieldLabel = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin-right: 16px;
`;

const indentedTextFieldBoxStyle = css`
    height: 48px;
    flex-grow: 1;
`;

const indentedTextFieldStyle = css`
    margin-bottom: none;
`;

const textFieldStyle = css`
    margin-bottom: 32px; 
`;

const textFieldStyleTwo = css`
    margin-bottom: 32px; 
    margin-top: 32px;
`;

const SubHeader = styled.h2`
    font-size: 18px;
    color: ${color_shades_darkest};
    font-weight: 400;
`;

const fieldContainerStyle = css`
    height: 48px;
`;

const Description = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin: 16px 0 32px 0;
`;

const Label = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin: 32px 0 4px 16px;
`;

const SectionHeaderText = styled.h2`
    margin-right: 8px;
    font-size: 16px;
    color: ${color_shades_darkest};
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
`;

const ConfigurationTabContent = styled.div`
    padding: 24px 24px 0 24px;
`;

const SectionHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 32px 0;
`;

const dropDownStyleMedium = css`
    margin-bottom: 32px;
`;
export default AppearanceTabPanel;