import React from 'react';
import {css} from '@emotion/css';
import { cool_grey, dark_grey_blue } from '../../constants/colors';
const audioIcon = require('../../content/images/audio_icon_black.svg')

interface AudioPlayerFileIndicatorProps {
    title: string
}
const containerStyle = css`
    display: flex;
    align-items: center;
    border: 1px solid ${cool_grey};
    border-radius: 5px 5px 0 0;
    height: 50px;
    p {
        font-size: 14px;
        color: ${dark_grey_blue}
    }

    > * {
        margin-left: 22px;
    }
`
const AudioPlayerFileIndicator: React.SFC<AudioPlayerFileIndicatorProps> = (props) => (
    <div className={containerStyle}>
        <img src={audioIcon}/>
        <p>
            {props.title}
        </p>
    </div>
)

export default AudioPlayerFileIndicator;