import React, { useState } from 'react';
import styled from "@emotion/styled";
import Button from '../../../../components/general/Button';
import InteractionModelUiStateContainer from "../../../../hooks/InteractionModelUiStateContainer";
import { color_variants_gold_dark, color_variants_gold_light, text_blue } from '../../../../constants/colors';
const warningIcon = require('../../../../content/images/alert-circle-warning.svg');
const closeIcon = require('../../../../content/images/close_icon.svg');

interface LanguageWarningProps {
    isDirty: boolean
}

const LanguageWarning: React.FC<LanguageWarningProps> = (props) => {
    const [showWarning, setShowWarning] = useState(true);
    const InteractionModelStateContainer = InteractionModelUiStateContainer.useContainer();
    return (
        <>
            {showWarning && props.isDirty ?
                <WarningDiv>
                    <WarningIconImg src={warningIcon} />
                    <p>You have unsaved changes that may be lost if you change what langauge or region is displayed - </p>
                    <WarningSaveNow onClick={() => InteractionModelStateContainer.setTriggerSaveChanges(true)}>Save Now</WarningSaveNow>
                    <Button
                        themes={['end-tight', 'icon']}
                        icon={closeIcon}
                        onClick={() => setShowWarning(false)}
                    />
                </WarningDiv>
                :
                <></>
            }
        </>
    )
}

export default LanguageWarning;

const WarningDiv = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    border: 1px solid ${color_variants_gold_dark};
    background: ${color_variants_gold_light};
    border-radius: 8px 8px 0px 0px;   
`;

const WarningIconImg = styled.img`
    padding-right: 8px;
`;

const WarningSaveNow = styled.span`
    padding-left: 4px;
    color: ${text_blue};
`;