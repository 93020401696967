import React from 'react';
import { css } from '@emotion/css';
import ResponseTemplateTypeModel from '../../../models/features/api/MediaResponses/ResponseTemplateTypeModel';
import { ResponseTemplateListItem } from './ResponseTemplateListItem';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { cool_grey, dark_grey_blue_10 } from '../../../constants/colors';

interface ResponseTemplateSelectorProps {
    templates: ResponseTemplateTypeModel[]
    onSelectionChange: (template: ResponseTemplateTypeModel) => void
    selectedTemplate: ResponseTemplateTypeModel
}
interface ResponseTemplateSelectorState {
    isCollapsed: boolean
}

const containerStyle = css`
    margin-top: 16px;
    position: relative;
    .selection-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border: solid 1px ${cool_grey};
        border-radius: 10px;
        cursor: pointer;
        background: white;
        .selection-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            border-left: 1px solid ${cool_grey};
        }

        overflow: hidden;
    }

    .selector-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        background: white;
        margin-bottom: 120px;
        border: solid 1px #cccccc;
        margin-top: 12px;
        box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
        border-radius: 10px;
        z-index: 100;
        flex: 1;
        width: 100%;
    }
`

const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: transparent;
    margin-right: 16px;
    margin-left: auto;
`
class ResponseTemplateSelector extends React.Component<ResponseTemplateSelectorProps, ResponseTemplateSelectorState> {
    documentCloser = this.handleDocumentClick.bind(this)
    componentWillMount() {
        this.setState({
            isCollapsed: true
        })
        document.addEventListener("click", this.documentCloser, true);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.documentCloser, true);
    }
    handleDocumentClick(e: any) {
        if (e.target.closest(".selector-wrapper") || e.target.closest(".selection-container")) return;
        this.setState({
            ...this.state,
            isCollapsed: true
        })
    }
    handleToggle() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        })
    }
    handleSelectionChange(template: ResponseTemplateTypeModel) {
        this.handleToggle();
        this.props.onSelectionChange(template);
    }
    render() {
        return (
            <div className={containerStyle}>
                <div className="selection-container">
                    <ResponseTemplateListItem responseTemplate={this.props.selectedTemplate} viewOnly isSelected={false} onSelected={this.handleToggle.bind(this)} />
                    <div className="selection-toggle" onClick={this.handleToggle.bind(this)}>
                        <ToggleIndicator direction={this.state.isCollapsed ? "down" : "up"} />
                    </div>
                </div>
                {this.state.isCollapsed ? null :
                    <div className="selector-wrapper">
                        <div className={triangleStyle}></div>
                        {this.props.templates.map((template, i) => (
                            <ResponseTemplateListItem key={i} top={i == 0} bottom={i == this.props.templates.length - 1} responseTemplate={template} onSelected={this.handleSelectionChange.bind(this)} isSelected={template.id == this.props.selectedTemplate.id} />
                        ))}
                    </div>}
            </div>
        )
    }
}

export default ResponseTemplateSelector