import React, { useState } from 'react';
import { css } from '@emotion/css';
import * as yup from 'yup';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import { FormikProps, withFormik } from 'formik';
import Loader from '../../../components/general/Loader';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import Button from '../../../components/general/Button';
import { color_text_default, color_shades_dark } from '../../../constants/colors';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import TemplateInstructionsPanel from './TemplateInstructionsPanel';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import AppliedTemplateDeleteConfirmation from './AppliedTemplateDeleteConfirmation';
import TemplateFormModel from '../../../models/templating/api/TemplateFormModel';
import TextField from '../../../components/forms/TextField';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';

const deleteIcon = require('../../../content/images/content-item-icons/delete-blue.svg');

interface AppliedAppTemplateDetailsProps {
    applicationId: string
    appContainer: ApplicationContainer
    appliedAppTemplate: AppliedApplicationTemplateModel
    templateConfiguration: TemplateConfigurationModel
    templateForm?: TemplateFormModel
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    isNew: boolean
    onNext: () => void
    history: any
}

interface AppliedAppTemplateDetailsData {
    name: string
}
const InnerForm: React.FC<AppliedAppTemplateDetailsProps & FormikProps<AppliedAppTemplateDetailsData>> = (props) => {
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

    const handleDeleteConfirm = () => {
        props.appliedAppTemplateContainer.removeAppliedTemplate(props.applicationId, props.appliedAppTemplate?.id, result => {
            setIsDeleteConfirmationOpen(false);
            props.history.push(`/v/apps/${props.applicationId}`);
        })
    };

    const handleCloseModals = () => {
        setIsDeleteConfirmationOpen(false);
    };

    const handleOpenDelete = () => {
        setIsDeleteConfirmationOpen(true);
    };

    const templateConfiguration = props.templateConfiguration;

    if (props.appliedAppTemplateContainer.state.isLoading) return <Loader />

    return (
        <>
            { }
            <form onSubmit={props.handleSubmit}>
                <PanelHeaderContainer>
                    <div className={buttonContainerStyle}>
                        <Button disabled={props.appliedAppTemplateContainer.state.isSaving} loading={props.appliedAppTemplateContainer.state.isSaving}
                            themes={["primary-small"]}
                            text={props.isNew ? "Enable & Start" : "Next"}
                            type="button"
                            onClick={() => { props.submitForm() }} />
                    </div>
                </PanelHeaderContainer>

                <PanelContainer>
                    <CustomScrollbars className={previewStyle} autoHide={true} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                        <div className={containerStyle}>
                            <div className="section-header">
                                <h4>Overview</h4>
                                {props.appliedAppTemplate?.id &&
                                    <Button
                                        text="Remove"
                                        rightIcon={deleteIcon}
                                        themes={['flat', 'end']}
                                        type="button"
                                        onClick={handleOpenDelete.bind(this)}
                                    />
                                }
                            </div>
                            {
                                !props.isNew &&
                                <div className="template-card name-card">
                                    <div className="instance-name template-details">
                                        <h5>Instance Name</h5>
                                        <p>You can create an individual name for this instance of the module you’re using to help keep track of its purpose</p>
                                    </div>
                                    <TextField
                                        name="name"
                                        value={props.values.name}
                                        label="Name"
                                        className={"name-field"}
                                        placeholder="My Module Instance"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        disabled={props.appliedAppTemplateContainer.state.isSaving} />
                                </div>
                            }
                            <div className="template-card">
                                <div className="image-wrapper">
                                    <img src={templateConfiguration?.imageUrl} />
                                </div>
                                <div className="template-details">
                                    <h5>{templateConfiguration?.name}</h5>
                                    <h5>Description</h5>
                                    <p>{templateConfiguration?.description}</p>
                                </div>
                            </div>
                        </div>
                    </CustomScrollbars>
                    <ScrollablePanelBody className={previewStyle}>
                        <TemplateInstructionsPanel
                            title="Preview"
                            instructionsMarkdown={templateConfiguration?.templateForm?.instructionsMarkdown ?? props.templateForm?.instructionsMarkdown ?? null}
                        />
                    </ScrollablePanelBody>
                </PanelContainer>
            </form>
            {isDeleteConfirmationOpen &&
                <ConfirmationDialog title={`Delete all template content?`} deleteText="Remove & Delete"
                    isLoading={props.appliedAppTemplateContainer.state.isSaving}
                    onClose={handleCloseModals}
                    onConfirm={handleDeleteConfirm}>
                    <AppliedTemplateDeleteConfirmation />
                </ConfirmationDialog>}
        </>
    )
}

const buttonContainerStyle = css`
    margin-right: 16px;
    margin-left: auto;
    button {
        margin: 0 16px 0 0;
    }
`
const previewStyle = css`
    height: calc(100% - 120px);
`

const containerStyle = css`
    min-height: 100%;
    flex: 1;
    padding-bottom: 64px;
    .section-header {
        display: flex;
        align-items: center;
        button {
            margin-right: 0;
            margin-left: auto;
        }
    }
    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: ${color_text_default};
        margin: 32px;
    }
    .template-card {
        flex: 1;
        margin: 0 32px;
        box-sizing: border-box;
        border-radius: 8px;
        background: white;
        border: 1px solid ${color_shades_dark};
        overflow: hidden;
        &.name-card {
            margin-bottom: 32px;
        }

        .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid ${color_shades_dark};
            padding: 16px;
            img {
                height: 240px;
                width: auto;
            }
        }

        .template-details {
            padding: 0 32px 32px 32px;
            h5 {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                margin-top: 32px;
                margin-bottom: 16px;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .instance-name {
            h5 {
                border-bottom: 1px solid ${color_shades_dark};
                padding-bottom: 16px;
            }
        }
        .name-field {
            margin: 0px 32px 32px 32px;
        }
    }
`


const AppliedAppTemplateDetailsForm = withFormik<AppliedAppTemplateDetailsProps, AppliedAppTemplateDetailsData>({
    mapPropsToValues: props => {
        return {
            name: props.appliedAppTemplate?.name ?? ''
        };
    },
    validationSchema: yup.object().shape({
    }),
    handleSubmit: (values, { props }) => {
        if (props.isNew) {
            props.appliedAppTemplateContainer.addAppliedTemplateToApp(props.applicationId, props.templateConfiguration?.id).then(result => {
                if (result.resultType == "Ok") {
                    props.history.replace(`/v/apps/${props.applicationId}/templates/${result.data.id}`, 'urlhistory')
                }
            })
        } else {
            props.appliedAppTemplateContainer.updateAppliedTemplate(props.appliedAppTemplate?.id, {
                templateFormFieldValues: props.appliedAppTemplate?.templateFormFieldValues ?? [],
                name: values.name
            }).then(result => {
                if (result.resultType == "Ok")
                    setTimeout(props.onNext, 1); // we want to elevate the transition to the main thread
            })
        }
    }
})(InnerForm);


export default AppliedAppTemplateDetailsForm;