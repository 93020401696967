import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import QuestionAnswerContainer from '../../state/containers/QuestionAnswerContainer';
import QuestionAnswerContentForm from './components/QuestionAnswerContentForm';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import QuestionAnswerModel from '../../models/features/api/QuestionAnswers/QuestionAnswerModel';
import IResult from '../../models/result/IResult';
import GenericCreateContentItemPage from '../formScenes/GenericCreateContentItemPage';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { QuestionAnswerFeatureTypeId } from '../../constants/featureTypeIds';

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface CreateQuestionAnswerProps extends RouteComponentProps<ApplicationRouteProps> { }

class CreateQuestionAnswer extends NavigationBarFormPage<CreateQuestionAnswerProps, ConversationNavigationState> {
    render() {
        return (
            <Subscribe to={[QuestionAnswerContainer, ApplicationContainer, TitleStateContainer, RepromptContainer, FollowUpContainer, MediaResponseStateContainer, FeatureContainer]}>
                {(faqContainer: QuestionAnswerContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseStateContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer) => {
                    return (

                        <GenericCreateContentItemPage stateContainer={faqContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            featureContainer={featureContainer}
                            defaultTitle="New Question Answer"
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureTypeId={QuestionAnswerFeatureTypeId}
                            formRender={(application, assistantApplication, onPublish, onSubmit: (model: QuestionAnswerModel) => Promise<IResult<QuestionAnswerModel>>,onAccessTypeChange) => (
                                <QuestionAnswerContentForm
                                    location={this.props.location}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    application={application}
                                    stateContainer={faqContainer}
                                    assistantApplication={assistantApplication}
                                    appContainer={appContainer}
                                    repromptContainer={repromptContainer}
                                    followUpContainer={followUpContainer}
                                    responseStateContainer={responseStateContainer}
                                    featureContainer={featureContainer}
                                    history={this.props.history}
                                    isSaving={faqContainer.state.isSaving}
                                    onSubmit={onSubmit}
                                    onShowPublishModal={onPublish}
                                    onAccessTypeChange={onAccessTypeChange}
                                    isTemplateOwned={false}
                                    onToggleSync={null} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default CreateQuestionAnswer;