import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import CustomRequestContainer from '../../state/containers/CustomRequestContainer';
import CustomRequestContentForm from './components/CustomRequestContentForm';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import GenericCreateContentItemPage from '../formScenes/GenericCreateContentItemPage';
import IResult from '../../models/result/IResult';
import CustomRequestModel from '../../models/features/api/CustomRequests/CustomRequestModel';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { CustomRequestsFeatureTypeId } from '../../constants/featureTypeIds';

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface CreateCustomRequestProps extends RouteComponentProps<ApplicationRouteProps> { }

class CreateCustomRequest extends NavigationBarFormPage<CreateCustomRequestProps, ConversationNavigationState> {
    render() {
        return (
            <Subscribe to={[CustomRequestContainer, ApplicationContainer, TitleStateContainer, RepromptContainer, FollowUpContainer, MediaResponseStateContainer, FeatureContainer]}>
                {(customRequestContainer: CustomRequestContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseStateContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer) => {
                    return (
                        <GenericCreateContentItemPage stateContainer={customRequestContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="New Custom Request"
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={CustomRequestsFeatureTypeId}
                            formRender={(application, assistantApplication, onPublish, onSubmit: (model: CustomRequestModel) => Promise<IResult<CustomRequestModel>>, onAccessTypeChange) => (

                                <CustomRequestContentForm
                                    location={this.props.location}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    application={application}
                                    stateContainer={customRequestContainer}
                                    assistantApplication={assistantApplication}
                                    appContainer={appContainer}
                                    repromptContainer={repromptContainer}
                                    followUpContainer={followUpContainer}
                                    responseStateContainer={responseStateContainer}
                                    history={this.props.history}
                                    isSaving={customRequestContainer.state.isSaving}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onShowPublishModal={onPublish}
                                    featureContainer={featureContainer}
                                    onSubmit={onSubmit}
                                    isTemplateOwned={false}
                                    onToggleSync={null}  />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default CreateCustomRequest;