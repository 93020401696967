import LanguageModel from "../../../models/features/api/LanguageModel";
import { css } from '@emotion/css';
import React, { useState } from "react";
import ToggleIndicator from "../../../components/general/ToggleIndicator";
import { getFlagIconForLanguage } from "../../../models/extensions/languages";
import { color_shades_dark, color_variants_ocean_light_opaque_2, color_shades_light } from "../../../constants/colors";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";

interface SimulatorLanguageSelectorProps {
    languages: LanguageModel[];
    selectedLanguage?: LanguageModel;
    handleLanguageSelected: (selectedLanguage: LanguageModel) => void;
};

const SimulatorLanguageSelector: React.FC<SimulatorLanguageSelectorProps> = ({ handleLanguageSelected, languages, selectedLanguage }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const selectLanguage = (language) => {
        handleLanguageSelected(language);
        handleToggle();
    };

    const renderEmpty = () => {
        return (
            <div className="selected-summary">
                <p className="item-text">Select a Language</p>
                <ToggleIndicator direction={isExpanded ? 'up' : 'down'} />
            </div>
        );
    };

    const renderLanguageSummaryItem = (language: LanguageModel) => {
        return (
            <div className="selected-summary">
                <img src={getFlagIconForLanguage(language)} />
                <p className="item-text">{language?.shortCode}</p>
                <ToggleIndicator direction={isExpanded ? 'up' : 'down'} />
            </div>
        );
    };

    const renderNoLanguages = () => {
        return (
            <div className={containerStyle}>
                <div>
                    <p className="empty-value">You must add at least one language to your application first.</p>
                </div>
            </div>
        );
    };

    const renderLanguageList = () => {
        return (
            <>
                {languages.map((l, idx) => {
                    return (
                        <div key={l.id} className={`select-item ${idx === 0 ? " top" : ""}${selectedLanguage?.id === l.id ? "selected-language" : ""}`} onClick={() => { selectLanguage(l) }}>
                            <img src={getFlagIconForLanguage(l)} />
                            <p className="item-text">{l.shortCode}</p>
                        </div>
                    )
                })}
            </>
        );
    };

    if (languages.length === 0) {
        return (
            renderNoLanguages()
        );
    };

    return (
        <div className={containerStyle}>
            <div onClick={handleToggle} onBlur={handleToggle}>
                {selectedLanguage ? renderLanguageSummaryItem(languages.find(l => l.id === selectedLanguage?.id)) : renderEmpty()}
            </div>
            {isExpanded &&
                <div className={"expanded-view"}>
                    <div className={triangleStyle}></div>
                    <div className="select-list">
                        <CustomScrollbars autoHeight className="scrollbar-holder" autoHide >
                            {renderLanguageList()}
                        </CustomScrollbars>
                    </div>
                </div>}
        </div>
    );
};

const containerStyle = css`
    width: 100%;
    .expanded-view {
        margin-top: 10px;
    }
    .selected-summary {
        background: white;
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 4px;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;
    }
    .scrollbar-holder {
    }
    .select-list {
        background: white;
        border: 1px solid ${color_shades_dark};
        border-radius: 4px;
        max-height: 350px;
        min-height: 64px;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction:row;
        align-items: flex-start;
        cursor: pointer;
        flex-wrap:wrap;
    }
    .select-item {
        min-width: calc(100%-16px);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-left: 16px;
        border-top: 1px solid ${color_shades_dark};
        &:hover {
            background: ${color_shades_light};
        }
        &.selected-language {
            background: ${color_variants_ocean_light_opaque_2};
        }
        &.top {
            border-top: 0px;
        }
    }
    .item-text {
        padding-left: 8px;
    }
    .toggle-indicator {
        margin-right: 0;
        margin-left: auto;
    }
`;

const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: white;
    margin-right: 16px;
    margin-left: auto;
    z-index: 2;
`

export default SimulatorLanguageSelector;