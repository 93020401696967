import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import NewFollowUpRequest from '../../models/features/api/FollowUp/NewFollowUpRequest';
import IResult from '../../models/result/IResult';
import UpdateFollowUpRequest from '../../models/features/api/FollowUp/UpdateFollowUpRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import IContentWebhookContainer from '../definitions/IContentWebhookContainer';
import _ from 'lodash';
import { updateFollowUp } from '../../api';
import ParentContentCacheItem from '../../models/features/ParentContentCacheItem';
import ParentContentContainerModel from '../../models/features/api/Relationships/ParentContentContainerModel';

type ParentContentState = {
    parentContentItems: ParentContentCacheItem[]
    isLoading: boolean
    errors: string[]
}

export default class ParentContentContainer extends Container<ParentContentState> {
    // default state
    state = {
        parentContentItems: [] as ParentContentCacheItem[],
        isLoading: false,
        errors: []
    }

 
    getParentContentItems(featureTypeId: string, contentId: string) : Promise<IResult<ParentContentContainerModel>> {
        const cacheItem = this.state.parentContentItems.find(p => p.contentId == contentId && p.featureTypeId == featureTypeId);
        if(cacheItem) {
            return new Promise(resolve => resolve({
                resultType: 'Ok',
                errors: null,
                data: cacheItem.contentContainer
            }))
        };

        this.setLoading(true);
        const promise = voicifyApi.getParentContentItems(featureTypeId, contentId);
        promise.then(result => {
            if(result.resultType == 'Ok') {
                const parentContentItems = this.state.parentContentItems;
                parentContentItems.push({
                    contentContainer: result.data,
                    contentId,
                    featureTypeId
                })
                this.setState({
                    ...this.state,
                    errors: [],
                    isLoading: false,
                    parentContentItems
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })
        return promise;
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}
