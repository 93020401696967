import React from 'react';
import MenuStateContainer from '../../state/containers/MenuStateContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import { css } from '@emotion/css';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import FeatureContainer from '../../state/containers/FeatureContainer';
import ApplicationMenu from './components/ApplicationMenu';
import { Subscribe } from 'unstated';
import ContentTreeStateContainer from '../../state/containers/ContentTreeStateContainer';
import AppliedApplicationTemplateContainer from '../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationModel from '../../models/applications/api/ApplicationModel';

interface NavigationDrawerProps {
    history: any
    application: ApplicationModel
}
class ApplicationContentMenuPanel extends React.Component<NavigationDrawerProps> {

    render() {
        return (
            <Subscribe to={[MenuStateContainer, ApplicationContainer, OrganizationContainer, FeatureContainer, ContentTreeStateContainer, ApplicationBackupVersionsContainer]}>
                {(menuContainer: MenuStateContainer,
                    appContainer: ApplicationContainer,
                    orgContainer: OrganizationContainer,
                    featureContainer: FeatureContainer,
                    treeContainer: ContentTreeStateContainer,
                    backupsContainer: ApplicationBackupVersionsContainer) => {
                    return (
                        <ApplicationMenu appContainer={appContainer}
                            menuContainer={menuContainer}
                            orgContainer={orgContainer}
                            featureContainer={featureContainer}
                            application={this.props.application}
                            history={this.props.history}
                            treeContainer={treeContainer}
                            backupsContainer={backupsContainer} />
                    );
                }}
            </Subscribe>
        )
    }
}

export default ApplicationContentMenuPanel;