import React from 'react';
import { css } from '@emotion/css';
import { color_shades_darkest, color_text_link, color_shades_darker, color_defaults_white, color_shades_dark, color_shades_light } from '../../../constants/colors';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import IConversationItemContainer from '../../../state/definitions/IConversationItemContainer';
import { Subscribe, Container } from 'unstated';
import SubscribeToContentItem from '../../../components/structure/ContentItems/SubscribeToContentItem';
import { getFeatureTypeIcon, getDefaultFeatureTypeName } from '../../../models/extensions';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
const folderIcon = require('../../../content/images/content-explorer/folder.svg');

interface NewStubContentItemProps {
    onFinish: (contentItem: GenericContentItemModel) => void
    featureTypeId: string
    applicationFeatureId: string
    applicationId: string
    additionalProperties?: object
}
interface NewStubContentItemState {
    text: string
}

class NewStubContentItem extends React.Component<NewStubContentItemProps, NewStubContentItemState> {
    inputElement: HTMLInputElement
    constructor(props) {
        super(props);
        this.state = {
            text: "New Content"
        }
    }
    componentDidMount() {
        this.inputElement?.focus();
    }
    handleCreate(container: IConversationItemContainer) {
        container.addStub(this.props.applicationId,
            this.props.applicationFeatureId,
            this.state.text,
            this.props.additionalProperties).then(result => {
                if (result.resultType == "Ok")
                    this.props.onFinish(result.data);
            })
    }
    handleChange(e) {
        this.setState({
            ...this.state,
            text: e.target.value
        })
    }
    handleEnterBlur(e: React.KeyboardEvent<HTMLInputElement>, container: IConversationItemContainer & Container<any>) {
        if (e.charCode == 13) {
            e.preventDefault();
            this.handleCreate(container);
        }
    }
    render() {
        return (
            <SubscribeToContentItem featureTypeId={this.props.featureTypeId}>
                {(container: IConversationItemContainer & Container<any>) => (

                    <div className={containerStyle}>
                        <div className={`content-item`}>
                            {container.state.isSavingStub && <SecondaryLoader />}
                            <img className="content-item-icon" src={getFeatureTypeIcon(this.props.featureTypeId)} />
                            <div className="content-item-title-container">
                                <p className="section-title">{getDefaultFeatureTypeName(this.props.featureTypeId)}</p>
                                <input className="content-title"
                                    placeholder="New Content"
                                    value={this.state.text}
                                    disabled={container.state.isSavingStub}
                                    ref={r => this.inputElement = r}
                                    onChange={this.handleChange.bind(this)}
                                    onBlur={this.handleCreate.bind(this, container)}
                                    onFocus={(e) => e.target.select()}
                                    onKeyPress={(e) => this.handleEnterBlur(e, container)} />
                            </div>
                        </div>
                    </div>
                )}
            </SubscribeToContentItem>

        )
    }
}

const containerStyle = css` 
    height: 80px;
    padding: 0 16px;
    background: ${color_shades_light};
    box-sizing: border-box;
    border-bottom: 1px solid ${color_shades_dark};
    &:first-child {
        border-top: 1px solid ${color_shades_dark};
    }  

    .content-item {
        background: ${color_defaults_white};
        box-sizing: border-box;
        border-radius: 4px;
        border-left: 1px solid ${color_shades_dark};
        border-right: 1px solid ${color_shades_dark};
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 100%;
    }

    .content-item-icon {
        width: 48px;
        height: 48px;
        margin-right: 16px;
    }

    .section-title {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${color_shades_darker};
    }
    .section-detail {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
    }

    .content-item-title-container {
        flex: 1;
    }

    .spinner > div {
        background: ${color_text_link};
    } 
    .content-title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #324661;
    }
`
export default NewStubContentItem;