import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaRectangleWrapper from './AlexaRectangleWrapper';

const screenStyle = css`
    color: white;
    .body-text {
        font-family: 'Roboto', sans-serif;
        font-size: 100px;
        padding-left: 160px;
        color: white;    
        overflow-wrap: break-word;
        overflow-y: hidden;
        height: 160px;
        padding-top: 800px;
        flex: 1;
        margin-bottom: 0;
        margin-top: auto;
        z-index: 2;
        position: relative;
    }
    .body-container {
        display: flex;
        z-index: 2;
        position: relative;
    }
    .hint-text {
        font-family: 'Noto Serif', serif;
        font-size: 60px;
        color: white;
        font-style: italic;
        z-index: 2;
        padding-left: 160px;
        position: relative;
    }
    .foreground-image {
        width: 600px;
        z-index: 2;
        height: auto;
        padding-right: 160px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .background-image {
        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
    }
`
class AlexaBody6TemplatePreview extends ScreenPreviewComponent {
    getHintText() {
        if (this.state.hints && this.state.hints.length > 0) {
            const hint = this.state.hints[0];
            return `Try "Alexa, ${hint.content}"`;
        }
        return '';
    }
    render() {
        return (
            <AlexaRectangleWrapper className={screenStyle}>
                {this.state.backgroundImageUrl ? <img className="background-image" src={this.state.backgroundImageUrl} /> : null}
                <div className="body-container">
                    <p className="body-text">
                        <span>{this.state.displayText}</span>
                    </p>
                </div>
                {this.state.hints ? <p className="hint-text">{this.getHintText()}</p> : null}
            </AlexaRectangleWrapper>
        )
    }
}

export default AlexaBody6TemplatePreview;