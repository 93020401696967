import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import _ from 'lodash';
import ApiKeyModel from '../../models/auth/api/ApiKeyModel';
import NewApiKeyRequest from '../../models/auth/api/NewApiKeyRequest';
import ApiKeyResult from '../../models/auth/api/ApiKeyResult';

type ApiKeyState = {
    isLoading: boolean
    apiKeys: ApiKeyModel[]
    errors: string[]
}

export default class ApiKeyContainer extends Container<ApiKeyState> {
    // default state
    state = this.getInitialState()

    getInitialState(): ApiKeyState {
        return {
            isLoading: false,
            apiKeys: [] as ApiKeyModel[],
            errors: []
        }
    }

    public async loadOrganizationApiKeys(organizationId: string) : Promise<IResult<ApiKeyModel[]>> {
        try {
            await this.setLoading(true);
            var result = await voicifyApi.getOrganizationApiKeys(organizationId);            
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    apiKeys: result.data
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;
        }
        catch (error) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    public async createOrgAdminApiKey(organizationId:string, request: NewApiKeyRequest) : Promise<IResult<ApiKeyResult>> {
        try {
            await this.setLoading(true);
            var result = await voicifyApi.createOrganizationAdminApiKey(organizationId, request);            
            if (result.resultType == "Ok") {            
                await this.setLoading(false);
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;
        }
        catch (error) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    public async deleteApiKey(apiKeyId: string) : Promise<IResult<ApiKeyModel>> {
        try {
            await this.setLoading(true);
            var result = await voicifyApi.deleteApiKey(apiKeyId);            
            if (result.resultType == "Ok") {       
                let apiKeys = this.state.apiKeys;
                const existingIdx = apiKeys.findIndex(a => a.id == apiKeyId);
                if(existingIdx > -1){
                    apiKeys.splice(existingIdx, 1);
                    await this.setState({
                        ...this.state,
                        apiKeys: apiKeys,
                        isLoading: false
                    });
                }
                else {                          
                    await this.setLoading(false);
                }
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;

        }
        catch (error) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    private async setLoading(isLoading: boolean) {
        await this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}