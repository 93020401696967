import React, { useEffect, useState } from 'react';
import BackButton from '../../components/general/BackButton';
import { TabPanel } from 'react-tabs';
import PageContainer from '../../components/structure/PageContainer';
import HeaderTitleView from '../../components/structure/HeaderTitleView';
import DetailLabel from '../../components/general/DetailLabel';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import NavigationBarFormPage from './NavigationBarFormPage';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import Loader from '../../components/general/Loader';
import IGenericContentContainer from '../../state/definitions/IGenericContentContainer';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import IContentWebhookContainer from '../../state/definitions/IContentWebhookContainer';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import IResult from '../../models/result/IResult';
import HeaderSeparator from '../../components/structure/Panels/HeaderSeparator';
import FullPageTabbedPanel from '../../components/structure/Panels/FullPageTabbedPanel';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { BreadcrumbItem } from '../../components/general/Breadcrumb';
import ApplicationContentMenuSelector from '../applicationContentMenuSelector';
import PageError from '../../components/general/PageError';
import { css } from '@emotion/css';
import AssistantApplicationModel from '../../models/applications/api/AssistantApplicationModel';
import ConversationAccessType from '../../models/features/ConversationAccessType';
import { getEditUrl } from '../../models/extensions';
const contentEditIcon = require('../../content/images/tab-icons/content-edit.svg');
const analyticsIcon = require('../../content/images/tab-icons/analytics.svg');

interface ApplicationRouteProps {
    applicationFeatureId: string
}

interface GenericEditContentItemProps<TContentItem extends ContentItemModel> extends RouteComponentProps<ApplicationRouteProps> {
    stateContainer: IGenericContentContainer<TContentItem, any, any, any> & IContentWebhookContainer
    titleContainer: TitleStateContainer
    applicationContainer: ApplicationContainer
    featureContainer: FeatureContainer
    formRender: (application: ApplicationModel,
        assistantApplication: AssistantApplicationModel,
        onPublish: () => void,
        onSubmit: (model: TContentItem) => Promise<IResult<TContentItem>>,
        onAccessTypeChange: (accessType: ConversationAccessType) => void) => JSX.Element
    defaultTitle: string
    featureTypeId: string
}
const GenericCreateContentItemPage = <TContentItem extends ContentItemModel>(props: GenericEditContentItemProps<TContentItem>) => {
    const [accessType, setAccessType] = useState(null as ConversationAccessType);
    const [isPublishing, setIsPublishing] = useState(false);
    useEffect(() => {
        const asyncLoad = async () => {
            const featureResult = await props.featureContainer.getApplicationFeature(props.match.params.applicationFeatureId);
            props.applicationContainer.state.currentApp ?? await props.applicationContainer.loadCurrentAppById(featureResult.data.applicationId);
            props.stateContainer.setInitialCreateState();
        }
        asyncLoad();
    }, [])

    const getBreadCrumbItems = () => {
        var ancestors = props.featureContainer.getAllAncestors(props.match.params.applicationFeatureId);

        var crumbs: BreadcrumbItem[] = ancestors?.reverse()?.map(af => ({
            id: af?.id,
            title: af.parentId ? (af?.name ?? af.feature?.name ?? af?.feature?.featureType?.name ?? 'Untitled') : 'Home',
            link: `/v/apps/${props.applicationContainer.state.currentApp?.id}/content/${af?.id}`
        }))

        return crumbs;
    }
    const handleSubmit = async (model: TContentItem, onSuccess?: (result: IResult<TContentItem>) => void) => {
        const result = await props.stateContainer.createFromModel({
            ...model,
            applicationFeatureId: props.match.params.applicationFeatureId,
            applicationId: props.applicationContainer.state.currentApp?.id
        });
        if (result.resultType == "Ok") {
            if(onSuccess) onSuccess(result);
            // Full update was successful
            if (props.stateContainer.state.hasWebhookUpdate) {
                await props.stateContainer.bulkUpdateContentItemWebhookInstances(result.data.id, props.stateContainer.state.webhookUpdates);
            }
            // Update edited content state, redirect to edit content page             
            props.featureContainer?.addOrUpdateContentItem(result.data, result.data.responses[0], props.stateContainer.featureTypeId);
            let editUrl = getEditUrl(result.data.id, props.match.params.applicationFeatureId, props.stateContainer.featureTypeId);

            if (isPublishing) {
                // Redirect and open publish form
                editUrl = `${editUrl}?publishing=true`;
            }
            props.history.push(editUrl);
        }

        return result;
    }

    const handleClose = () => {
        props.history.push(`/v/apps/${props.applicationContainer.state.currentApp.id}/content/${props.match.params.applicationFeatureId}`);
    }
    const isLoading = props.stateContainer.state.isLoading
    if (!props.applicationContainer.state.currentApp) {
        return <Loader />
    }
    return (
        <>
            <FullPageTabbedPanel tabs={[{
                title: "Content Edit",
                icon: contentEditIcon,
                selectedIcon: contentEditIcon
            }, {
                title: "Analytics",
                icon: analyticsIcon,
                selectedIcon: analyticsIcon
            }]}
                leftRender={() => <>
                    <BackButton onClick={handleClose.bind(this)} />
                    <ApplicationContentMenuSelector application={props.applicationContainer.state.currentApp} featureTypeId={props.featureTypeId} history={props.history} />
                </>}
                titleRender={() =>
                    <HeaderTitleView text={props.defaultTitle} breadcrumb={getBreadCrumbItems()} app={props.applicationContainer.state.currentApp} />
                }
                rightRender={() => null}>

                <TabPanel>
                    {!isLoading ? props.formRender(props.applicationContainer.state.currentApp, props.applicationContainer.state.assistantApplication, () => setIsPublishing(true), handleSubmit, setAccessType) : <Loader />}
                </TabPanel>
                <TabPanel>
                    <ScrollablePanelBody className={paddedStyle}>
                        <PageContainer>
                            {!isLoading ?
                                <DetailLabel text="Here’s where you'll be able to see analytics for  item once it is published and used." />
                                : <Loader />}
                        </PageContainer>
                    </ScrollablePanelBody>
                </TabPanel>
            </FullPageTabbedPanel>
            <PageError errors={props.stateContainer?.state?.errors} onClear={() => props.stateContainer.clearErrors()} />
        </>);


}

const paddedStyle = css`
    padding: 32px;
`
export default GenericCreateContentItemPage;