import React from 'react';
import moment from 'moment';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import FeatureFilters from '../../../models/features/FeatureFilters';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import ApplicationFeatureModel from '../../../models/features/api/ApplicationFeatureModel';
import FeatureToolbar from '../../featureContent/components/FeatureToolbar';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import { css } from '@emotion/css';
import { ExploreType } from '..';
import FeatureModel from '../../../models/features/api/FeatureModel';
import FeatureContent from '../../featureContent';
import { BreadcrumbItem } from '../../../components/general/Breadcrumb';
import DisablingFilters from '../../../models/features/DisablingFilters';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';

interface FeatureContentManagerProps {
    withNesting: boolean
    applicationId: string
    appContainer: ApplicationContainer
    featureContainer: FeatureContainer
    exploreType: ExploreType
    isLoading: boolean
    initialFilter?: FeatureFilters
    disablingFilter?: DisablingFilters
    initialApplicationFeatureId?: string
    onSelect?: (contentItems: GenericContentItemModel[], featureIds: string[]) => void
}

interface FeatureContentManagerState {
    filters: FeatureFilters
    selectedFeatureIds: string[]
    selectedContentItems: GenericContentItemModel[]
    featureHistory: ApplicationFeatureModel[]
    currentFeatureId?: string
    isAddingNewFeature: boolean
    newStubFeatureTypeId?: string
    selectionText?: string
    isFilterExpanded: boolean
}

class InnerContentExplorer extends React.Component<FeatureContentManagerProps, FeatureContentManagerState> {
    constructor(props) {
        super(props);
        const rootId = this.props.featureContainer.getRootFeature(this.props.applicationId)?.id;
        this.state = {
            filters: this.props.initialFilter ?? {},
            currentFeatureId: props.initialApplicationFeatureId ?? rootId,
            newStubFeatureTypeId: null,
            isAddingNewFeature: false,
            selectedContentItems: [],
            selectedFeatureIds: this.props.exploreType == 'feature-select' ? [rootId] : [], // default select the root when selecting a folder
            featureHistory: [],
            selectionText: '',
            isFilterExpanded: false
        }
    }
    componentWillMount() {
        this.loadContent();
    }
    componentDidUpdate(prevProps: FeatureContentManagerProps, prevState: FeatureContentManagerState) {
        if (prevState.currentFeatureId != this.state.currentFeatureId) {
            this.loadContent();
        }
    }
    loadContent() {
        this.props.featureContainer.getContentItemsForAppFeature(this.state.currentFeatureId ?? this.props.featureContainer.state.currentTopicFeatureId);
    }
    handleStartAddNewFeature() {
        this.setState({
            ...this.state,
            isAddingNewFeature: true
        })
    }
    handleAddNewFeature(text: string, applicationFeatureId: string) {
        this.props.featureContainer.addApplicationFeature(this.props.applicationId, text, applicationFeatureId).then(result => {
            if (result.resultType == "Ok")
                this.setState({
                    ...this.state,
                    isAddingNewFeature: false
                })
        });
    }
    getBreadCrumbItems() {
        var ancestors = this.props.featureContainer.getAllAncestors(this.state.currentFeatureId);

        var crumbs: BreadcrumbItem[] = ancestors?.reverse()?.map(af => ({
            id: af?.id,
            title: af?.name ?? af.feature?.name ?? af?.feature?.featureType?.name ?? 'Untitled',
            link: '#',
            highlighted: this.state.selectedFeatureIds[0] == af?.id
        }))

        if (this.state.selectedFeatureIds[0] != this.state.currentFeatureId) {
            const af = this.props.featureContainer.findFeatureById(this.state.selectedFeatureIds[0]);
            if (af) {
                crumbs.push({
                    id: af.id,
                    title: af?.name ?? af.feature?.name ?? af?.feature?.featureType?.name ?? 'Untitled',
                    highlighted: true,
                    link: '#'
                })

            }
        }

        return crumbs;
    }
    handleFilterChange(filters: FeatureFilters) {
        this.setState({
            ...this.state,
            filters
        })
    }
    getFilteredFeatures(applicationFeatureId: string) {
        var features = this.props.featureContainer.getFeaturesForParent(applicationFeatureId);
        if (this.state.filters.textFilter) {
            features = features.filter(af => (af.name ?? af.feature.name)?.toLowerCase().indexOf(this.state.filters.textFilter.toLowerCase()) > -1)
        }
        return features;
    }
    getFilteredContent(applicationFeatureId: string) {
        var content = this.props.featureContainer.getContentByFeatureId(applicationFeatureId);
        if (this.state.filters.textFilter) {
            content = content.filter(c => (c.title ?? "untitled")?.toLowerCase().indexOf(this.state.filters.textFilter.toLowerCase()) > -1)
        }
        if (this.state.filters.featureTypeIdsFilter && this.state.filters.featureTypeIdsFilter.length > 0) {
            content = content.filter(c => this.state.filters.featureTypeIdsFilter.some(id => id == c.featureTypeId))
        }
        if (this.state.filters.languageIdsFilter) {
            content = content.filter(c => this.state.filters.languageIdsFilter?.some(id => c.languages?.some(l => l.id == id)))
        }
        if (this.state.filters.daysFilter) {
            content = content.filter(c => moment().diff(moment(c.modifiedDate), 'days') <= this.state.filters.daysFilter);
        }
        if (this.state.filters.propertiesFilter) {
            const { hasImage, hasAudio, hasVideo, isActive, isInactive, isIncomplete } = this.state.filters.propertiesFilter;
            if (hasImage)
                content = content.filter(c => c.hasForegroundImage || c.hasBackgroundImage);
            if (hasAudio)
                content = content.filter(c => c.hasAudio);
            if (hasVideo)
                content = content.filter(c => c.hasVideo);

            // these need to be grouped
            content = content.filter(c =>
                (!isActive && !isInactive && !isIncomplete)
                || (isActive && c.isLive)
                || (isInactive && !c.isLive)
                || (isIncomplete && !c.isComplete));
        }

        return content;
    }
    handleContentItemSelectionChange(contentItem: GenericContentItemModel, isSelected: boolean) {
        const selectedContentItems = this.props.exploreType == 'content-select' ? [] : this.state.selectedContentItems;

        if (isSelected) {
            selectedContentItems.push(contentItem);
        }
        else if (this.props.exploreType == 'content-multi-select') {
            selectedContentItems.splice(selectedContentItems.indexOf(contentItem), 1);
        }

        this.setState({
            ...this.state,
            selectedContentItems: selectedContentItems
        })
    }
    handleFeatureSelectionChange(applicationFeature: ApplicationFeatureModel, isSelected: boolean) {

        var selectedIds = this.state.selectedFeatureIds;

        if (isSelected) {
            if (this.props.exploreType == "feature-select")
                selectedIds = [applicationFeature?.id]
            else
                selectedIds.push(applicationFeature?.id);
        }
        else {
            selectedIds.splice(selectedIds.indexOf(applicationFeature.id), 1);
            if (this.props.exploreType == "feature-select") {
                selectedIds.push(this.state.currentFeatureId);
            }
        }

        this.setState({
            ...this.state,
            selectedFeatureIds: selectedIds
        })
    }
    handleClearSelections() {
        this.setState({
            ...this.state,
            selectedContentItems: [],
            selectedFeatureIds: []
        })
    }

    handleFeatureClick(applicationFeature: ApplicationFeatureModel) {
        const history = this.state.featureHistory;
        history.push(applicationFeature);
        this.setState({
            ...this.state,
            currentFeatureId: applicationFeature?.id,
            featureHistory: history,
            selectedFeatureIds: this.props.exploreType == 'feature-select' ? [applicationFeature?.id] : [] // select the folder we navigate to when using folder selection
        })
    }

    handleFinishSelection() {
        this.props.onSelect(this.state.selectedContentItems, this.state.selectedFeatureIds);
    }
    handleAddNewContent(featureTypeId: string) {
        this.setState({
            ...this.state,
            newStubFeatureTypeId: featureTypeId
        })
    }
    handleStubAdded(contentItem: GenericContentItemModel) {
        this.props.featureContainer.addContentItemToState(contentItem);
        this.setState({
            ...this.state,
            newStubFeatureTypeId: null
        });
    }
    handleBreadcrumbClick(item: BreadcrumbItem) {
        this.setState({
            ...this.state,
            currentFeatureId: item.id
        })
    }
    handleFilterToggle() {
        this.setState({
            ...this.state,
            isFilterExpanded: !this.state.isFilterExpanded
        })
    }

    render() {
        const feature = this.props.featureContainer.findFeatureById(this.state.currentFeatureId ?? this.props.featureContainer.state.currentTopicFeatureId);
        const application = this.props.appContainer.state.currentApp;
        const childContent = this.getFilteredContent(feature?.id);
        const childFeatures = this.getFilteredFeatures(feature?.id);
        const nonFilteredContent = this.props.featureContainer.getContentByFeatureId(feature?.id);
        const isContentFiltered : boolean = nonFilteredContent?.length > childContent?.length;

        return (
            <div className={containerStyle}>
                <FeatureToolbar applicationFeatureId={feature?.id}
                    application={application}
                    filters={this.state.filters}
                    isFilterExpanded={this.state.isFilterExpanded}
                    onToggleFilters={this.handleFilterToggle.bind(this)}
                    onAddNewFeature={this.handleStartAddNewFeature.bind(this)}
                    onAddNewContent={this.handleAddNewContent.bind(this)}
                    breadCrumbs={this.getBreadCrumbItems()}
                    onBreadCrumbItemClick={this.handleBreadcrumbClick.bind(this)}
                    featureContainer={this.props.featureContainer}
                    onImport={() => null}
                    groupSelectionState={'none'}
                    allowImport={false}
                    disableCreateContent={this.props.exploreType == "feature-select"}
                    highlightedBreadcrumbId={this.state.selectedFeatureIds[0]}
                    history={null} />
                <CustomScrollbars autoHide className="scrollable-container">
                    <FeatureContent
                        feature={feature}
                        showContentItemStatus={false}
                        handlePublish={null}
                        handleUnpublish={null}
                        webhookContainer={null}
                        application={application}
                        isAddingNewFeature={this.state.isAddingNewFeature}
                        featureContainer={this.props.featureContainer}
                        onFilterChange={this.handleFilterChange.bind(this)}
                        childFeatures={childFeatures}
                        contentItems={childContent}
                        handleAddNewFeature={this.handleAddNewFeature.bind(this)}
                        withNesting={this.props.withNesting}
                        applicationId={this.props.applicationId}
                        selectedContentItemIds={this.state.selectedContentItems?.map(c => c.id)}
                        selectedFeatureIds={this.state.selectedFeatureIds}
                        onFeatureClick={this.handleFeatureClick.bind(this)}
                        onContentItemSelectionChange={this.handleContentItemSelectionChange.bind(this)}
                        onFeatureSelectionChange={this.handleFeatureSelectionChange.bind(this)}
                        selectableFeatures={this.props.exploreType == 'feature-select'}
                        selectableContentItems={this.props.exploreType != 'feature-select'}
                        showContentItems={this.props.exploreType != 'feature-select'}
                        onStubAdded={this.handleStubAdded.bind(this)}
                        newStubFeatureTypeId={this.state.newStubFeatureTypeId}
                        navigatingContentItems={false}
                        disablingFilters={this.props.disablingFilter}
                        droppableId="features_modal"
                        isSingleSelect={this.props.exploreType != "content-multi-select"}
                        filters={this.state.filters}
                        isFilterExpanded={this.state.isFilterExpanded}
                        isContentFiltered={isContentFiltered} />
                </CustomScrollbars>
                <FooterBar small>
                    <Button themes={["primary"]} text="Select" type="button"
                        loading={this.props.isLoading}
                        disabled={this.props.isLoading || (this.state.selectedContentItems.length < 1 && this.state.selectedFeatureIds.length < 1)}
                        onClick={this.handleFinishSelection.bind(this)} />
                </FooterBar>
            </div>

        )
    }
}

const containerStyle = css`
    height: 100%;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;

    .scrollable-container {
        margin-bottom: 120px;
    }
`

export default InnerContentExplorer