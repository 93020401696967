import React from 'react';
import { css } from '@emotion/css';
import MediaResponseContainerModel from '../../models/features/api/MediaResponses/MediaResponseContainerModel';
import MediaItemModel from '../../models/media/MediaItemModel';
import MediaContainer from '../../state/containers/MediaContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import { Subscribe } from 'unstated';
import PageContainer from '../../components/structure/PageContainer';
import DetailLabel from '../../components/general/DetailLabel';
import Subheader from '../../components/general/Subheader';
import { dark_sky_blue, greeny_blue, pale_grey, dark_grey_blue, silver_two } from '../../constants/colors';
import ConversationFlowViewer from './components/ConversationFlowViewer';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import SsmlContainer from '../../state/containers/SsmlContainer';
import LanguageModel from '../../models/features/api/LanguageModel';

interface ConversationFlowProps {
    applicationId: string
    conversationItems: ConversationItem[]
    chatOverrideItems?: ConversationItem[]
    audioOverrideItems?: ConversationItem[]
    screenOverrideItems?: ConversationItem[]
    endSession: boolean
    languages?: LanguageModel[]
}

class ConversationFlow extends React.Component<ConversationFlowProps> {
    render() {
        return (
            <Subscribe to={[MediaContainer, MediaResponseStateContainer, SsmlContainer]}>
                {(mediaContainer: MediaContainer,
                    mediaResponseContainer: MediaResponseStateContainer,
                    ssmlContainer: SsmlContainer) => (
                        <PageContainer fill>
                            <ConversationFlowViewer
                                mediaContainer={mediaContainer}
                                responseContainer={mediaResponseContainer}
                                ssmlContainer={ssmlContainer}
                                applicationId={this.props.applicationId}
                                conversationItems={this.props.conversationItems}
                                chatOverrideItems={this.props.chatOverrideItems}
                                audioOverrideItems={this.props.audioOverrideItems}
                                screenOverrideItems={this.props.screenOverrideItems}
                                endSession={this.props.endSession}
                                languages={this.props.languages} />
                        </PageContainer>
                    )}
            </Subscribe>
        )
    }
}

export default ConversationFlow;