import React from 'react';
import { css } from '@emotion/css';
import TreePath from './TreePath';
import { ConversationTrackingTreeBaseNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import _ from 'lodash';

interface TreePathsProps {
    topIndex: number,
    topLength: number,
    topNodes: ConversationTrackingTreeBaseNode[]
    activeIndex?: number
    nodeOffset: number
    topNodeOffset: number
}

const NODE_WIDTH = 144; // the width of a node including margins

const UpTreePaths: React.SFC<TreePathsProps> = (props) => {
    const directionMultiplier = (length: number, index: number, prevLength: number, prevIndex: number) => {
        const currentPositionOffset = (length / 2) - index - props.nodeOffset;
        const topPositionOffset = (prevLength / 2) - prevIndex - props.topNodeOffset;

        return currentPositionOffset - topPositionOffset;
    }
    const totalWidth = NODE_WIDTH * (props.topLength > props.topNodes.length ? props.topLength : props.topNodes.length) * ((Math.abs(props.topNodeOffset) > Math.abs(props.nodeOffset) ? Math.abs(props.topNodeOffset) : Math.abs(props.nodeOffset) + 1))
    const totalSessions = props.topNodes.length == 0 ? 0 : props.topNodes.map(n => n.sessionIds?.length ?? 0).reduce((a, b) => (a + b));
    const containerStyle = css`
        position: relative;
        width: ${totalWidth}px;
        height: 170px;
    `;

    // each tree path is an SVG that is the width of the larger of the two rows it is being drawn between.
    // we calculate the offset from the center left or right of the top row and account for border-box (hence the -1)
    // we then draw the svg from the offset within a coordinate plane the size of the width of the container and the height
    // and go down, then over the direction width difference from the offset to the bottom row offset
    // and then draw the line down to the point.
    return (
        <div className={containerStyle}>
            {props.topNodes.map((n, i) => {
                const percentage = (n.sessionIds?.length ?? 0) / totalSessions;
                return (
                    <TreePath key={i}
                        direction={directionMultiplier(props.topNodes.length, i, 1, 0) < 0 ? 'up-right' : 'up-left'}
                        width={NODE_WIDTH * directionMultiplier(props.topNodes.length, i, 1, 0) * -1}
                        containerWidth={totalWidth}
                        offset={0}
                        height={170}
                        lineSize={Math.round(19 * percentage)}
                        active={props.activeIndex == i} />)
            }
            )}
        </div>
    );
};


export default UpTreePaths;