import styled from "@emotion/styled";
import React from "react";
import { color_shades_darker } from "../../../../constants/colors";
import CodeBlock from "./CodeBlock";
import CustomAssistantExportTypeDropdown, { CodeExportType } from "./CustomAssisantExportTypeDropdown";

interface CodeExportDescriptionProps {
    description: string,
    installationTitle?: string,
    installationCode?: string,
    permissionsTitle?: string,
    permissionsCode?: string,
    documentationInformation?: string,
    documentationLink?: string,
    documentationLinkName?: string,
    exportType?: CodeExportType,
    handleExportTypeChange?: (optionValue: string, value: CodeExportType) => void,
    hasPlatformConfigurations?: boolean
}

const CodeExportDescrption = (props: CodeExportDescriptionProps) => {
    return (
        <Container>
            {(props?.documentationInformation && props?.documentationLink) && <DocumentationInformation>{props.documentationInformation} <a target="_blank" href={props.documentationLink}>{props?.documentationLinkName}</a></DocumentationInformation>}
            <CustomAssistantExportTypeDropdown
                exportType={props.exportType}
                handleExportTypeChange={props.handleExportTypeChange}
                hasPlatformConfigurations={props.hasPlatformConfigurations}
            />
            {props?.installationTitle && <Description>{props.installationTitle}</Description>}
            {props?.installationCode && <CodeBlock code={props.installationCode} />}
            {props?.permissionsTitle && <Description>{props.permissionsTitle}</Description>}
            {props?.permissionsCode && <CodeBlock code={props.permissionsCode}></CodeBlock>}
            <Description>{props.description}</Description>
        </Container>
    )
}

const DocumentationInformation = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin: 32px 0;
`;

const Description = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin: 16px 0 0 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
export default CodeExportDescrption