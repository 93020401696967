import React from 'react';
import { css } from '@emotion/css';
import ConversationItemSlot from '../../../models/features/ConversationItemSlot';
import { cool_grey } from '../../../constants/colors';
import { getFeatureTypeIcon, getDefaultFeatureTypeName } from '../../../models/extensions';
import VerticalSeparator from '../../../components/structure/VerticalSeparator';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import IncompleteIndicator from '../../../components/general/IncompleteIndicator';

interface ConversationItemProps {
    slot: ConversationItemSlot
    onClick: () => void
}

const containerStyle = css`
    height: 64px;
    border-radius: 5px;
    border: solid 1px #007dbb;
    background: white;
    display: flex;
    align-items: center;
    padding: 12px;
    margin-top: 16px;
    cursor: pointer;
    .incomplete-indicator {
        margin: 0 12px;
    }
    .conversation-item-details {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 16px;

        >div {
            flex: 1;
        }
        img {
            height: 32px;
            width: 32px;
            margin-right: 12px;
        }
        .content-title {
            font-family: Muli;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.6px;
            color: #304562;
            margin-left: 0;
        }
        .feature-title {
            font-family: Muli;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${cool_grey};
            margin-left: 0;
        }
    }
    .empty-text {
        font-family: Muli;
        font-size: 14px;
        font-weight: 600;
        font-style: italic;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        color: ${cool_grey};
        flex: 1;
    }
    .select-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 12px;
        margin-top: 10px;
        .indicator {
            border-color: #007dbb;
            width: 8px;
            height: 8px;
            margin-right: 4px;
        }

        .select-text {
            font-family: Muli;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.7px;
            text-align: center;
            color: #007dbb;
            margin-top: 8px;
            margin-left: 0;
        }
`
const ConversationItemWrapper: React.SFC<ConversationItemProps> = (props) => (
    <div className={`${containerStyle} conversation-item-wrapper`} onClick={props.onClick}>

        {props.slot.conversationItem ?
            <div className="conversation-item-details">
                <img src={getFeatureTypeIcon(props.slot.conversationItem.featureTypeId)} />
                <div>
                    <p className="feature-title">{getDefaultFeatureTypeName(props.slot.conversationItem?.featureTypeId ?? props.slot.currentFeatureTypeId)}</p>
                    <p className="content-title"><i>{props.slot.conversationItem.titleAttribute}</i>{props.slot.conversationItem.title}</p>
                </div>
            </div>
            : <p className="empty-text">Select a response</p>
        }
        {props.slot.conversationItem && !props.slot.conversationItem.isComplete ?
            <IncompleteIndicator />
            : null}
        <VerticalSeparator />
        <div className="select-wrapper">
            <ToggleIndicator direction="right" className="indicator" />
            <p className="select-text">SELECT</p>
        </div>
    </div>
);

export default ConversationItemWrapper