import React from 'react';
import { css } from '@emotion/css';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import ContentUserAnalyticsContainer from '../../../state/containers/ContentUserAnalyticsContainer';
import ContentAnalyticsCharts from './ContentAnalyticsCharts';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';

interface ContentUserChartsProps {
    stateContainer: ContentUserAnalyticsContainer
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string
    contentId: string
}
class ContentUserCharts extends React.Component<ContentUserChartsProps> {
    componentDidMount() {
        this.loadChartData();
    }

    componentDidUpdate(previousProps: ContentUserChartsProps) {
        if (previousProps.interval !== this.props.interval
            || JSON.stringify(previousProps.filter) != JSON.stringify(this.props.filter)) {

            this.loadChartData();
        }
    }

    async loadChartData() {
        this.setState({
            ...this.state,
            isLoadingHistograms: true
        })
        this.props.stateContainer.loadPrimaryBreakdown(
            {applicationId: this.props.applicationId, contentId: this.props.contentId},
            this.props.filter,
            this.props.stateContainer.state.currentBreakdown)

        this.props.stateContainer.loadSecondaryBreakdown(
            {applicationId: this.props.applicationId, contentId: this.props.contentId},
            this.props.filter,
            this.props.stateContainer.state.currentBreakdown)

        await this.props.stateContainer.loadPrimaryHistogram(
            {applicationId: this.props.applicationId, contentId: this.props.contentId},
            this.props.innerInterval,
            this.props.filter
        )
        await this.props.stateContainer.loadSecondaryHistogram(
            {applicationId: this.props.applicationId, contentId: this.props.contentId},
            this.props.innerInterval,
            this.props.filter
        );
        await this.props.stateContainer.getAverages(
            {applicationId: this.props.applicationId, contentId: this.props.contentId},
            this.props.filter)
    }


    render() {

        return (
            <ContentAnalyticsCharts
                applicationId={this.props.applicationId}
                contentId={this.props.contentId}
                filter={this.props.filter}
                innerInterval={this.props.innerInterval}
                interval={this.props.interval}
                dataType='Users'
                stateContainer={this.props.stateContainer}
                showPrimary={this.props.stateContainer.state.currentDisplayOption?.toLocaleLowerCase()?.indexOf("total") > -1}
                showSecondary={this.props.stateContainer.state.currentDisplayOption?.toLowerCase()?.indexOf("new") > -1}
                secondaryDataName="New Users"
                primaryDataName="Total Users" />
        )
    }
}

export default ContentUserCharts;