import React from 'react';
import { css } from '@emotion/css';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import Button from '../../../components/general/Button';
import ContentItemModel from '../../../models/features/api/ContentItemModel';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import ConditionalTooltipWrapper from '../../../components/general/ConditionalTooltipWrapper';

interface ConversationitemActionButtonToolbarProps {
    contentItem: ContentItemModel,
    isLoading: boolean,
    publishDisabled?: boolean,
    saveDisabled?: boolean,
    handlePublishing: () => void,
    submitForm: () => void,
    handleDiscardChanges: () => void,
    validateForm: () => Promise<FormikErrors<any>>
    errors: any
}

interface Error {
    content: string;
}

const ConversationItemActionButtonToolbar: React.FC<ConversationitemActionButtonToolbarProps> = (props) => {

    const isTemplateOwned = props.contentItem?.createdFromId && !props.contentItem?.shouldNotSync;
    const handleSaveAndPublish = async () => {
        // create forms need to "show publish" before saving in order to use the query string before navigating
        // this could be reworked if all the page state and form state was moved up to a higher level
        const errors = await props.validateForm();
        if (isEmpty(errors)) {
            if (props.contentItem) {
                await props.submitForm();
                props.handlePublishing();
            } else {
                props.handlePublishing();
                await props.submitForm();
            }
        }
    }

    const errors = props.errors?.map((item: string | Error[]) => {
        if (typeof item === 'string') {
            return [item];
        } else {
            return item.map((error) => {
                return error?.content;
            })
        }
    });

    const tooltipText = () => {
        if(props.saveDisabled && props.publishDisabled) {
            return `The following fields are required: <br/>-You must perform a full app publish <br/>-${Array.prototype.concat.apply([], errors).join("<br/> -")}`
        } else {
            return "You must perform a full app publish"
        }
    };

    return (
        <>
            <PanelHeaderContainer>
                    <ConditionalTooltipWrapper showTooltip={props.saveDisabled} place="bottom" text={`The following fields are required: <br/> -${Array.prototype.concat.apply([], errors).join("<br/> -")}`}>
                    <div> {/*need to wrap this in a div for the tooltip to show*/}
                        <Button className={`${saveStyle} ac-content-save`} loading={props.isLoading}
                            disabled={props.isLoading || isTemplateOwned || props.saveDisabled} themes={["primary-small", "start"]}
                            text="Save" type="submit" />
                    </div>
                    </ConditionalTooltipWrapper>
                    <ConditionalTooltipWrapper showTooltip={props.publishDisabled || props.saveDisabled} place="bottom" text={tooltipText()}>
                        <div>
                        <Button className={`${saveAndActivateStyle} ac-content-save-activate`} loading={props.isLoading}
                            disabled={props.isLoading || isTemplateOwned || props.publishDisabled || props.saveDisabled } themes={["white-small"]}
                            text={"Save & Publish"} type="button" onClick={handleSaveAndPublish} />
                        </div>
                    </ConditionalTooltipWrapper>
                <Button className={`${discardStyle} ac-content-discard`} loading={props.isLoading}
                    disabled={props.isLoading || isTemplateOwned} themes={["white-small", "end"]}
                    text="Discard Changes" type="button" onClick={() => props.handleDiscardChanges()} />
            </PanelHeaderContainer>
        </>
    )
}

export const saveAndActivateStyle = css`
    margin-left: 8px;
    margin-right: auto;
`;

export const saveStyle = css`
    margin-left: 32px;
    margin-right: 8px;
`;

export const discardStyle = css`
    margin-right: 32px;
    margin-left: auto;
`;

export default ConversationItemActionButtonToolbar;