import React, { useEffect } from 'react';
import LanguageModel from '../../../models/features/api/LanguageModel';
import EditableNlpEntity from '../../../models/nlpEntity/EditableNlpEntity';
import { ArrayHelpers, FieldArray } from 'formik';
import NlpEntityValueItem from './NlpEntityValueItem';
import NlpEntityValueModel from '../../../models/nlpEntity/api/NlpEntityValueModel';
import { NlpValueFormDisplayInfo } from './ApplicationNlpEntityCard';

export interface NlpEntityValuesListDisplayProps {
    toggleItemActivationByIndex: (index: number) => void;
    activeItemIndex: number;
    valuesFormDisplayInfo: NlpValueFormDisplayInfo[];
    numberOfValuesPerPage: number;
    valuesPageNumber: number;
    languages?: LanguageModel[];
    nlpEntities: EditableNlpEntity[]; 
    nlpEntity: EditableNlpEntity; 
    formPrefix: string;
    formIndex: number;
    validateField;
    validateForm;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    handleSubmit;
    dirty: boolean;
    isValid: boolean;
};

const NlpEntityValuesListDisplay = (props: NlpEntityValuesListDisplayProps) => {

    const cardPrefix = `${props.formPrefix}.${props.formIndex}`;
    const valuesField: string = `${cardPrefix}.values`;
    const isModifiedField = `${cardPrefix}.isModified`;
    
    useEffect(function resetActiveItemIndexOnEntitySelection() {
        props.toggleItemActivationByIndex(-1);
    }, [props.formIndex]);

    const deleteNlpValueByIndex= (index: number, arrayHelpers: ArrayHelpers) => {
        props.toggleItemActivationByIndex(-1);
        arrayHelpers.remove(index);
        props.setFieldValue(isModifiedField, true);
    };

    const renderNlpEntityMenuItem = (nlpEntityValueToRender: NlpEntityValueModel, index: number, arrayHelpers: ArrayHelpers) => {
        const isActive: boolean = index === props.activeItemIndex;
                
        return <NlpEntityValueItem 
            {...props} 
            toggleItemActivationByIndex={props.toggleItemActivationByIndex}
            deleteNlpValueByIndex={(idx) => deleteNlpValueByIndex( idx, arrayHelpers)}
            languages={props.languages}
            nlpEntity={props.nlpEntity}
            isActive={isActive}
            key={index}
            formPrefix={props.formPrefix}
            formIndex={props.formIndex}
            nlpValue={nlpEntityValueToRender} 
            nlpValueIndex={index}        />
    };

    const renderNlpValuesList = () => {
        return (
            <>
                {props.valuesFormDisplayInfo ? 
                    <FieldArray validateOnChange={true} 
                        name={valuesField}
                        render={ arrayHelpers => (
                            props.valuesFormDisplayInfo?.map((item, index) => {

                                // Pagination - only display items that are in the range 
                                if(item.displayIndex >= props.numberOfValuesPerPage*(props.valuesPageNumber - 1) 
                                    && item.displayIndex < props.numberOfValuesPerPage*props.valuesPageNumber) {
                                    
                                    return renderNlpEntityMenuItem(item.nlpEntityValue, item.originalArrayIndex, arrayHelpers);
                                }
                                return null;
                            })
                        ) }
                    />
                    :
                    null
                }
            </>
        )

    };
    return (
        renderNlpValuesList() 
    );
};

export default NlpEntityValuesListDisplay;
