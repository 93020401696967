import React, { useState } from 'react';
import FilterSelector from './FilterSelector';
import FilterOption from './FilterOption';
import { PropertiesFilter } from '../../../../models/features/FeatureFilters';

const propertiesIconLight = require('../../../../content/images/content-explorer/messages-bubble-settings-light.svg');
const propertiesIcon = require('../../../../content/images/content-explorer/messages-bubble-settings.svg');

interface MiscFilterProps {
    propertiesFilter: PropertiesFilter
    onChange: (properties: PropertiesFilter) => void
}

const MiscellaneousFilter: React.FC<MiscFilterProps> = (props) => {
    const [isMediaExpanded, setIsMediaExpanded] = useState(true);
    const [isPublishedExpanded, setIsPublishedExpanded] = useState(true);

    const handleToggleMedia = () => {
        setIsMediaExpanded(!isMediaExpanded)
    }

    const handleTogglePublished = () => {
        setIsPublishedExpanded(!isPublishedExpanded)
    }

    const getSelectedText = (props) => {
        const propertiesFilter = props.propertiesFilter;
        let label = '';
        if (propertiesFilter?.hasAudio) label += 'Has Audio, ';
        if (propertiesFilter?.hasVideo) label += 'Has Video, ';
        if (propertiesFilter?.hasImage) label += 'Has Image, ';
        if (propertiesFilter?.isPublished) label += 'Publish Status: Published, ';
        if (propertiesFilter?.isDraft) label += 'Publish Status: Draft, ';
        if (propertiesFilter?.isUpdated) label += 'Publish Status: Updated, ';
        if (propertiesFilter?.isIncomplete) label += 'Publish Status: Incomplete, ';
        if (propertiesFilter?.isSyncedAndPublished) label += "Publish Status: Synced & Published"
        if (propertiesFilter?.isSyncedDraft) label += "Publish Status: Synced Draft"
        if (propertiesFilter?.isSyncedAndUpdated) label += "Publish Status: Synced & Updated"
        return label;
    }

    const getIsMediaPartiallySelected = (props) => {
        const propertiesFilter = props?.propertiesFilter
        return (propertiesFilter?.hasAudio || propertiesFilter?.hasVideo || propertiesFilter?.hasImage) && !getIsMediaSelected(props);
    }

    const getIsMediaSelected = (props) => {
        const propertiesFilter = props?.propertiesFilter
        return propertiesFilter?.hasAudio && propertiesFilter?.hasVideo && propertiesFilter?.hasImage;
    }

    const getIsStatusPartiallySelected = (props) => {
        const propertiesFilter = props?.propertiesFilter
        return (propertiesFilter?.isPublished || propertiesFilter?.isDraft || propertiesFilter?.isUpdated || propertiesFilter?.IsIncomplete) || propertiesFilter?.isSyncedAndPublished || propertiesFilter?.isSyncedDraft || propertiesFilter?.isSyncedAndUpdated && !getIsStatusSelected(props);
    }

    const getIsStatusSelected = (props) => {
        const propertiesFilter = props?.propertiesFilter
        return propertiesFilter?.isPublished && propertiesFilter?.isDraft && propertiesFilter?.isUpdated && propertiesFilter?.IsIncomplete && propertiesFilter?.isSyncedAndPublished && propertiesFilter?.isSyncedDraft && propertiesFilter?.isSyncedAndUpdated
    }

    const handleMediaGroupToggle = (props, getIsMediaSelected) => {
        if (getIsMediaSelected()) //all media options are selected so deselect everything
        {
            props.onChange({
                ...props.propertiesFilter,
                hasImage: false,
                hasAudio: false,
                hasVideo: false
            })
        }
        else //either some or none of the options are selected, select everything
        {
            props.onChange({
                ...props.propertiesFilter,
                hasImage: true,
                hasAudio: true,
                hasVideo: true
            })
        }
    }
    const handleStatusGroupToggle = (props, getIsStatusSelected) => {
        if (getIsStatusSelected()) //all options are selected so deselect everything
        {
            props.onChange({
                ...props.propertiesFilter,
                isPublished: false,
                isDraft: false,
                isUpdated: false,
                isIncomplete: false,
                isSyncedAndPublished: false,
                isSyncedDraft: false,
                isSyncedAndUpdated: false
            })
        }
        else //either some or none of the options are selected, select everything
        {
            props.onChange({
                ...props.propertiesFilter,
                isPublished: true,
                isDraft: true,
                isUpdated: true,
                isIncomplete: true,
                isSyncedAndPublished: true,
                isSyncedDraft: true,
                isSyncedAndUpdated: true
            })
        }
    }

    const handleClear = (props) => {
        props.onChange(null);
    }

    const selectedText = getSelectedText(props);

    return (
        <FilterSelector label="Properties"
            icon={selectedText ? propertiesIcon : propertiesIconLight}
            placeholder={'Any Properties'}
            selectedText={selectedText}
            onClear={handleClear.bind(this)}>
            <FilterOption
                isPartialSelected={getIsMediaPartiallySelected(props)}
                isSelected={getIsMediaSelected(props)}
                hasChildren
                isExpanded={isMediaExpanded}
                onClick={handleToggleMedia.bind(this)}
                onCheckClick={handleMediaGroupToggle.bind(this)}
                title='Has Media'
                optionType="check"
            />
            {isMediaExpanded && <>
                <FilterOption isSelected={props.propertiesFilter?.hasImage}
                    onClick={() => props.onChange({ ...props.propertiesFilter, hasImage: (!props.propertiesFilter?.hasImage ?? true) })}
                    title='Image'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.hasAudio}
                    onClick={() => props.onChange({ ...props.propertiesFilter, hasAudio: (!props.propertiesFilter?.hasAudio ?? true) })}
                    title='Audio'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.hasVideo}
                    onClick={() => props.onChange({ ...props.propertiesFilter, hasVideo: (!props.propertiesFilter?.hasVideo ?? true) })}
                    title='Video'
                    optionType="check"
                    isChild
                />
            </>}
            <FilterOption
                isPartialSelected={getIsStatusPartiallySelected(props)}
                isSelected={getIsStatusSelected(props)}
                hasChildren
                isExpanded={isPublishedExpanded}
                onClick={handleTogglePublished.bind(this)}
                onCheckClick={handleStatusGroupToggle.bind(this)}
                title='Publish Status'
                optionType="check"
            />
            {isPublishedExpanded && <>
                <FilterOption isSelected={props.propertiesFilter?.isPublished}
                    onClick={() => props.onChange({ ...props.propertiesFilter, isPublished: (!props.propertiesFilter?.isPublished ?? true) })}
                    title='Published'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.isDraft}
                    onClick={() => props.onChange({ ...props.propertiesFilter, isDraft: (!props.propertiesFilter?.isDraft ?? true) })}
                    title='Draft'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.isUpdated}
                    onClick={() => props.onChange({ ...props.propertiesFilter, isUpdated: (!props.propertiesFilter?.isUpdated ?? true) })}
                    title='Updated'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.isIncomplete}
                    onClick={() => props.onChange({ ...props.propertiesFilter, isIncomplete: (!props.propertiesFilter?.isIncomplete ?? true) })}
                    title='Incomplete'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.isSyncedAndPublished}
                    onClick={() => props.onChange({ ...props.propertiesFilter, isSyncedAndPublished: (!props.propertiesFilter?.isSyncedAndPublished ?? true) })}
                    title='Synced & Published'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.isSyncedDraft}
                    onClick={() => props.onChange({ ...props.propertiesFilter, isSyncedDraft: (!props.propertiesFilter?.isSyncedDraft ?? true) })}
                    title='Synced Draft'
                    optionType="check"
                    isChild
                />
                <FilterOption isSelected={props.propertiesFilter?.isSyncedAndUpdated}
                    onClick={() => props.onChange({ ...props.propertiesFilter, isSyncedAndUpdated: (!props.propertiesFilter?.isSyncedAndUpdated ?? true) })}
                    title='Synced & Updated'
                    optionType="check"
                    isChild
                />
            </>}
        </FilterSelector>
    )
}

export default MiscellaneousFilter;

