import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import { color_shades_dark, color_text_default, color_shades_lighter, color_shades_darkest } from '../../../constants/colors';
import { getFeatureTypeIcon, getDefaultFeatureTypeName } from '../../../models/extensions';
import ToggleIndicator from '../../../components/general/ToggleIndicator';

interface NavigationSelectionProps {
    count?: number
    icon: string
    title: string
    isOpen?: boolean
    onClick: () => void
}
const NavigationSelection: React.SFC<NavigationSelectionProps> = (props) => (
    <div className={containerStyle} onClick={props.onClick}>
        <div className="triangle-container">
            <div className={props.isOpen ? "triangle-down" : "triangle-right"} />
        </div>
        <img src={props.icon} />
        <p className="section-title">{props.title}</p>
        <div className="count-wrapper">
            <p className="count">{props.count ?? '0'}</p>
        </div>
    </div>
)
const containerStyle = css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${color_shades_dark};
    height: 48px;
    padding: 0 32px 0 24px;
    cursor: pointer;
    .triangle-container {
        width: 3px;
        height: 3px;
    }
    .triangle-down {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid ${color_shades_darkest};
    }
    .triangle-right {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid ${color_shades_darkest};
    }
    img {
        margin: 0 16px 0 12px;
    }

    .count-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: ${color_shades_lighter};
        width: 24px;
        height: 24px;

        margin-left: auto;
        margin-right: 0;
    }

    .p {
        color: ${color_text_default};
        font-size: 14px;
        line-height: 20px;
    }
`

export default NavigationSelection;