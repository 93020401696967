import React from 'react';
import { css } from '@emotion/css';
import _, { Dictionary } from 'lodash';
import { dark_grey_blue_10, primary_gradient, pale_grey } from '../../constants/colors';
import ToggleIndicator from '../general/ToggleIndicator';
import CheckIndicator, { CheckType } from './CheckIndicator';
import styled from '@emotion/styled';

interface GroupedMultiSelectorProps<T> {
    groups: ItemGroup<T>[]
    selectedValues: string[]
    onChange: (selectedValues: string[]) => void
    innerSelectionRender: () => JSX.Element
    label: string
    withGroupSelection: boolean
    restrictGroups: boolean
    subItemCheckType?: CheckType
    emptyText?: string
    position?: SelectorPosition
    autoGroupExpand?: boolean
    className?: string
    disabled?: boolean
    groupToggle?: string
    treatGroupAsValue?: boolean
    selectorWrapperClassName?: string
}
interface GroupedMultiSelectorState {
    isExpanded: boolean
    expandedGroupKeys: string[]
}

export interface ItemGroup<T> {
    key: string
    label: string
    subItems: OptionItem<T>[]
    hideRadio?: boolean
    image?: string
    customRender?: JSX.Element
}

export interface OptionItem<T> {
    value?: string
    icon?: string
    label: string
    isSelected: boolean
    data: T
    groupKey: string
    hideCheckbox?: boolean
}

export type SelectorPosition = "top" | "bottom"

class GroupedMultiSelector<T> extends React.Component<GroupedMultiSelectorProps<T>, GroupedMultiSelectorState> {
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: false,
            expandedGroupKeys: []
        }
    } documentCloser = this.handleDocumentClick.bind(this)
    componentWillMount() {
        document.addEventListener("click", this.documentCloser, true);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.documentCloser, true);
    }
    handleDocumentClick(e: any) {
        if (e.target.closest(".selector-wrapper") || e.target.closest(".field")) return;
        this.setState({
            ...this.state,
            isExpanded: false
        })
    }

    handleOpenToggle() {
        if (!this.props.disabled)
            this.setState({
                ...this.state,
                isExpanded: !this.state.isExpanded
            })
    }

    handleToggleGroup(itemGroup: ItemGroup<T>) {
        if (this.props.treatGroupAsValue && !(itemGroup.subItems?.length)) {
            this.props.onChange([itemGroup.key]);
            this.setState({
                ...this.state,
                isExpanded: false
            })
        } else {
            var expandedKeys = this.state.expandedGroupKeys == null ? [] : this.state.expandedGroupKeys;
            if (expandedKeys.some(l => l == itemGroup.key)) {
                expandedKeys.splice(expandedKeys.indexOf(itemGroup.key), 1);
            }
            else {
                expandedKeys.push(itemGroup.key);
            }
            this.setState({
                ...this.state,
                expandedGroupKeys: expandedKeys
            })
        }
    }

    handleSelectGroup(e: any, itemGroup: ItemGroup<T>) {
        e.stopPropagation();
        let isExpanded = true;
        if (this.props.treatGroupAsValue && !(itemGroup.subItems?.length)) {
            this.props.onChange([itemGroup.key]);
            isExpanded = false;
        } else {
            this.props.onChange(itemGroup.subItems.map(i => (i.value)));
        }
        if (itemGroup.subItems?.length) {
            var expandedKeys = this.state.expandedGroupKeys == null ? [] : this.state.expandedGroupKeys;
            if (!expandedKeys.some(l => l == itemGroup.key)) {
                expandedKeys.push(itemGroup.key);
            }            
        }
        this.setState({
            ...this.state,
            expandedGroupKeys: expandedKeys,
            isExpanded: isExpanded
        })
    }

    renderGroup(itemGroup: ItemGroup<T>, selectedOptions: OptionItem<T>[]) {
        if(itemGroup.customRender){
            return itemGroup.customRender;
        }
        selectedOptions = selectedOptions == null ? [] : selectedOptions;
        const expandedGroupKeys = this.state.expandedGroupKeys == null ? [] : this.state.expandedGroupKeys;
        const isExpanded = this.props.autoGroupExpand || this.state.expandedGroupKeys?.some(e => e == itemGroup?.key);
        const isSelected = selectedOptions?.some(l => l.groupKey == itemGroup.key);
        return (
            <div key={itemGroup.key}>
                <div className="item-group" onClick={this.handleToggleGroup.bind(this, itemGroup)}>
                    {itemGroup.image ? <GroupImage src={itemGroup.image} /> :
                        (this.props.withGroupSelection) ?
                            <div className="check-container" onClick={(e) => this.handleSelectGroup(e, itemGroup)}>
                                {isSelected ? <div className="selected-option" /> : null}
                            </div> : null}
                    <span onClick={(e) => this.handleSelectGroup(e, itemGroup)}>{itemGroup.label}</span>
                    {itemGroup.subItems.length > 0 && <ToggleIndicator className={this.props.groupToggle} direction={isExpanded ? 'up' : 'down'} />}

                </div>
                {isExpanded ?
                    <div className="sub-item-container">
                        {itemGroup.subItems.map(l => this.renderOption(l, selectedOptions.some(s => s.value == l.value)))}
                    </div>
                    : null}
            </div>
        )
    }

    handleToggleOption(option: OptionItem<T>, isSelected: boolean) {
        var selectedValues = this.props.selectedValues == null ? [] : this.props.selectedValues;
        if (this.props.subItemCheckType === "radio" && this.props.restrictGroups) {
            selectedValues = [option.value];
        }
        else if (isSelected) {
            // if we only allow selections within a group - remove the other ones
            if (this.props.restrictGroups) {
                const group = this.props.groups.find(g => g.subItems != null && g.subItems.some(i => i.value == option.value));
                selectedValues = selectedValues.filter((o) => group.subItems.some(i => i.value == o));
            }
            selectedValues.push(option.value);
        } else {
            selectedValues.splice(selectedValues.indexOf(option.value), 1);
        }

        this.props.onChange(selectedValues);

        if (option.hideCheckbox) {
            this.setState({
                ...this.state,
                isExpanded: false
            })
        }
    }

    renderOption(option: OptionItem<T>, isSelected: boolean) {
        return (
            <div className={"sub-item"} onClick={() => this.handleToggleOption(option, !isSelected)} key={option.value}>
                {option.hideCheckbox ? <></> : <CheckIndicator checkType={this.props.subItemCheckType ?? "check"} checked={isSelected} />}
                {option.icon ? <img src={option.icon} /> : null}
                <p>{option.label}</p>
            </div>
        );
    }

    getAllOptions() {
        if (this.props.groups && this.props.groups.length > 0) {
            return this.props.groups.reduce((prev, current) => ({ key: '', label: '', subItems: prev.subItems.concat(current.subItems) })).subItems;
        }
        return [];
    }

    render() {
        const selectedOptions = this.getAllOptions().filter(l => this.props.selectedValues != null && this.props.selectedValues.some(id => id == l.value));
        return (
            <div className={`${containerStyle} ${this.props.className ?? ''} `}>
                <div className="field-wrapper">
                    <label>{this.props.label}</label>
                    <div className="selection-section">
                        {this.state.isExpanded && this.props.position == "top" ?
                            <div className={`${selectorWrapperStyle} selector-wrapper top ${this.props.selectorWrapperClassName ?? ''}`}>
                                <div className="inner-wrapper">
                                    {this.props.groups && this.props.groups.length > 0
                                        ? this.props.groups.map(group => this.renderGroup(group, selectedOptions))
                                        : <div className="item-group"><p>{this.props.emptyText ? this.props.emptyText : "No options available"}</p></div>}
                                </div>
                                <div className={topTriangleStyle}></div>
                            </div>
                            : null}
                        <div className="field">
                            <div className="selection" onClick={this.handleOpenToggle.bind(this)}>
                                {this.props.innerSelectionRender()}
                                <ToggleIndicator className={this.props.groupToggle} direction={this.state.isExpanded ? 'up' : 'down'} />
                            </div>
                        </div>
                        {this.state.isExpanded && this.props.position == "bottom" ?
                            <div className={`${selectorWrapperStyle} selector-wrapper ${this.props.selectorWrapperClassName ?? ''}`}>
                                <div className={triangleStyle}></div>
                                <div className="inner-wrapper">
                                    {this.props.groups && this.props.groups.length > 0
                                        ? this.props.groups.map(group => this.renderGroup(group, selectedOptions))
                                        : <div className="item-group"><p>{this.props.emptyText ? this.props.emptyText : "No options available"}</p></div>}
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

const selectorWrapperStyle = css`
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    right: 0;
    left: 0;
    background: white;
    margin-bottom: 120px;
    margin-top: 12px;
    box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
    border-radius: 10px;
    flex: 1;
    border: solid 1px #cccccc;
    .inner-wrapper {
        overflow-y: auto;
        max-height: 600px;
    }

    &.top {
        bottom: 0;
        margin-bottom: 72px;
    }
`;

const containerStyle = css`
    position: relative;
    min-width: 350px;
    .field-wrapper {
        display: flex;
        align-items: center;
        label {
            font-family: Muli;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2c3d52;
            margin-right: 12px;
        }
        .selection-section {
            flex: 1;
            position:relative;
        }
    }
    .field {
        display: flex;
        align-items: center;
        .selection {
            height: 48px;
            border-radius: 22.5px;
            border: solid 1px #cccccc;
            background: white;
            display: flex;
            align-items: center;
            padding: 0 16px;
            cursor: pointer;
            flex: 1;

            span {
                margin: 0 12px;
                user-select: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }
    }
    .item-group {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 64px;
        padding: 0 16px;
        border-bottom: 1px solid #cccccc;   
        .check-container {
            position: relative;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ffffff;
            border: solid 1px #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
        }

        .selected-option {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-image: linear-gradient(225deg, #76c3b5, #3ea6ac 72%, #5296b3);
        }
    }

    .sub-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 64px;
        padding: 0 32px;
        border-bottom: 1px solid #cccccc;
        img {
            margin-right: 16px;
        }
        
        /* On mouse-over, add a grey background color */
        &:hover .checkmark {
            background-color: #EDF5FA;
        }
    
        &.disabled .checkmark {
            background: ${pale_grey};
        }
    }
    .sub-item-container {        
        border-bottom: 1px solid #cccccc;
    }
`
const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: white;
    margin-right: 16px;
    margin-left: auto;
`
const topTriangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-bottom: solid 1px #cccccc;
    border-right: solid 1px #cccccc;
    background: white;
    margin-left: 16px;
    margin-right: auto;
`
const GroupImage = styled.img`
    margin-right: 8px;
`;

export default GroupedMultiSelector;