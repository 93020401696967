import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import CortanaScreenWrapper from './CortanaScreenWrapper';

const screenStyle = css`
    .list-container {
        display: flex;
        flex-direction: row;      
        overflow-x: auto;
    }
  
`
class CortanaScreenListPreview extends ScreenPreviewComponent {
    renderAdditionalItems() {
        const items = [];
        for (var i = 0; i < this.props.response.additionalListItems; i++) {
            items.push(i);
        }
        return items.map((_, i) => (
            <div key={i} className="card">
                <div className="foreground-image empty"></div>
                <p className="body-text">
                    <span>{i + 2}. Additional Item</span>
                </p>
            </div>
        ))
    }
    render() {
        return (
            <CortanaScreenWrapper className={screenStyle}>
                <div className="body-container">
                    {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                    <div className="list-container">
                        <div className="card">
                            {this.state.foregroundImageUrl
                                ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                                : <div className="foreground-image empty"></div>}
                            <p className="body-text">
                                <span>1. {this.state.displayText}</span>
                            </p>
                        </div>
                        {this.props.response.additionalListItems ? this.renderAdditionalItems() : null}
                    </div>
                </div>
            </CortanaScreenWrapper>
        )
    }
}

export default CortanaScreenListPreview;