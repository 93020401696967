import React from 'react';
import {css} from '@emotion/css';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import ApplicationAvatar from '../../../components/general/ApplicationAvatar';
import { Link } from 'react-router-dom';
import { color_colors_sky } from '../../../constants/colors';

interface ApplicationListItemProps {
    application: ApplicationModel
    isSelected: boolean
    isMinified?: boolean
}

const ApplicationListItem: React.SFC<ApplicationListItemProps> = (props) => (
    <Link to={`/v/apps/${props.application.id}`} className={`${containerStyle} ${props.isSelected ? 'selected' : ''}`}>
        <ApplicationAvatar app={props.application} size={32}/>
        {!props.isMinified && <p>{props.application.name}</p> }
    </Link>
);

const containerStyle = css`
    display: flex;
    align-items: center;
    margin: 12px;
    height: 56px;
    padding: 0 12px;
    border-radius: 8px;
    &.selected {
        background: ${color_colors_sky};
    }

    p {
        color: white;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        margin: 0 12px;
    }
`

export default ApplicationListItem;