import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { FormikProps, withFormik } from "formik";
import moment from "moment";
import React from "react";
import * as yup from 'yup';
import DateTimeField from "../../../components/forms/DateTimeField";
import SelectField from "../../../components/forms/SelectField";
import TextField from "../../../components/forms/TextField";
import Button from "../../../components/general/Button";
import ModalTitle from "../../../components/general/ModalTitle";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import FooterBar from "../../../components/structure/FooterBar";
import HorizontalContainer from "../../../components/structure/HorizontalContainer";
import { blue_white, color_colors_ocean, color_shades_darkest } from "../../../constants/colors";
import { style_border_default } from '../../../constants/stylesValues';
import ApiKeyResult from "../../../models/auth/api/ApiKeyResult";
import NewApiKeyRequest from "../../../models/auth/api/NewApiKeyRequest";
import ApiKeyContainer from "../../../state/containers/ApiKeyContainer";
const closeIcon = require('../../../content/images/close_icon.svg');

interface NewApiKeyFormProps {
    onClose: () => void,
    onFinish: (result: ApiKeyResult) => void
    apiKeyContainer: ApiKeyContainer,
    organizationId: string
}
interface NewApiKeyFormData {
    name: string,
    expirationDate: string,
    expirationType: string
}

const NewApiKeyFormComponent: React.FC<NewApiKeyFormProps & FormikProps<NewApiKeyFormData>> = (props) => {

    const handleExpirationTypeChange = (value) => {
        const finalValue = value === null ? null : value.value;
        props.setFieldValue("expirationType", finalValue);
        if (value.value == "7") {
            const newExpirationDate = moment().add(7, "day").format("YYYY-MM-DD");
            props.setFieldValue("expirationDate", newExpirationDate);
        }
        else if (value.value == "30") {
            const newExpirationDate = moment().add(30, "day").format("YYYY-MM-DD");
            props.setFieldValue("expirationDate", newExpirationDate);
        }
        else if (value.value == "year") {
            const newExpirationDate = moment().add(1, "year").format("YYYY-MM-DD");
            props.setFieldValue("expirationDate", newExpirationDate);
        }
        else if (value.value == "none") {
            props.setFieldValue("expirationDate", "");
        }
    };

    const handleExpirationTypeBlur = (blur) => {
        props.setFieldTouched("expirationType", blur);
    };

    const handleExpirationDateChange = (e) => {
        props.setFieldValue("expirationType", "custom");
        props.setFieldValue("expirationDate", moment(e.target.value, 'YYYY-MM-DD').format("YYYY-MM-DD"));
    };
    const isLoading = props.apiKeyContainer.state.isLoading;

    return (
        <form onSubmit={props.handleSubmit}>
            <div className={containerStyle}>
                <HorizontalContainer className={modalTitleContainer}>
                    <TitleWrapper>
                        <ModalTitle>Generate API Key</ModalTitle>
                    </TitleWrapper>
                    <CloseButtonWrapper>
                        <Button className={closeButton} themes={['start-tight', 'icon']} icon={closeIcon} onClick={props.onClose} />
                    </CloseButtonWrapper>
                </HorizontalContainer>
                <CustomScrollbars className={"scrollable-area"} autoHide>
                    <div className="main-body">
                        <TextField
                            name={`name`}
                            value={props.values.name}
                            placeholder="Enter key"
                            label={`Name`}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur} />
                        <HorizontalContainer>
                            <SelectField
                                className="expiration-type"
                                options={[
                                    { value: 'none', label: 'None' },
                                    { value: '7', label: 'Today +7 days' },
                                    { value: '30', label: 'Today +30 days' },
                                    { value: 'year', label: 'Today +1 year' },
                                    { value: 'custom', label: 'Custom' },
                                ]}
                                onChange={handleExpirationTypeChange}
                                onBlur={handleExpirationTypeBlur}
                                label={"Expiration:"}
                                clearable={false}
                                disabled={isLoading}
                                useFormTheme={true}
                                value={props.values.expirationType}
                                name={"expirationType"} />
                            <DateTimeField
                                label={"Expires on:"}
                                disabled={isLoading}
                                value={props.values.expirationDate}
                                onChange={handleExpirationDateChange}
                                name={"expirationDate"}
                                onBlur={props.handleBlur} />
                        </HorizontalContainer>
                    </div>
                </CustomScrollbars>
                <FooterBar>
                    <Button loading={isLoading} disabled={isLoading} themes={["primary", "wide"]} text={"Generate Key"} type="submit" />
                    <Button disabled={isLoading} themes={["white", "end"]} text="Cancel" type="button" onClick={props.onClose.bind(this)} />
                </FooterBar>
            </div>
        </form>
    );
};

const NewApiKeyForm = withFormik<NewApiKeyFormProps, NewApiKeyFormData>({
    mapPropsToValues: props => {
        return {
            name: "",
            expirationDate: moment().add(30, "day").format("YYYY-MM-DD"),
            expirationType: "30"
        };
    },
    validationSchema: yup.object().shape({
        name: yup.string().required("You must specify a name.")
    }),
    handleSubmit: async (values, { props }) => {
        const request: NewApiKeyRequest = {
            name: values.name,
            utcExpirationDateTime: values.expirationDate
        }
        var result = await props.apiKeyContainer.createOrgAdminApiKey(props.organizationId, request);
        if (result.resultType == "Ok") {
            props.apiKeyContainer.loadOrganizationApiKeys(props.organizationId);
            props.onFinish(result.data);
        }
    }

})(NewApiKeyFormComponent);


const modalTitleContainer = css`
    background: white;
    padding: 0 32px;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: ${style_border_default};
    img {
        margin-right: 16px;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const CloseButtonWrapper = styled.div`
    display: flex;
    padding-left: 28.5px;
    border-left: ${style_border_default};
`;

const closeButton = css`
    margin-right: -8px;
    margin-left: 0;
`;

const containerStyle = css`
    .main-body {
        padding: 32px;
        background: ${blue_white};
        height: 100%;
        .Select-option {
            z-index: 10;
        }
    }
    .expiration-type {
        width: 50%;
    }
    .generated-warning {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
        margin-bottom: 32px;
    }
    .copy-key {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        color: ${color_colors_ocean};
        margin-top: 40px;
        img {
            margin-right: 8px;
        }
    }`;

export default NewApiKeyForm;