export default class PhraseSlot{
    fullText: string;

    constructor(text : string){
        this.fullText = text;
    }

    getSlotName() : string {
        if(!this.fullText || this.fullText == "") return null;
        return this.fullText.replace('{','').replace('}','').split('|')[0].trim()
    }

    getSlotExample() : string {
        if(!this.fullText || this.fullText == "") return null;
        return this.fullText.replace('{','').replace('}','').split('|')[1].trim()
    }
}
