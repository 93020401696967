import React from 'react';
import styled from '@emotion/styled';
import { color_shades_lighter } from '../../../constants/colors';
const closeIcon = require('../../../content/images/close_icon.svg');

interface TemplateTagProps {
    id: string
    name: string
    type: string
    onRemove: () => void
}

const TemplateTag: React.FC<TemplateTagProps> =({
    id,
    name,
    type,
    onRemove
}) => (
    <TemplatePill>
        <p>{type}: {name}</p>
        <img src={closeIcon} onClick={() => onRemove()}/>
    </TemplatePill>
);

const TemplatePill = styled.div(`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 16px;
    background: ${color_shades_lighter};
    border-radius: 12px;
    p {
        font-size: 14px;
        line-height: 20px;
        margin-right: 8px;
    }
    img {
        width: 10px;
        height: 10px;
        cursor: pointer;
    }
`);

export default TemplateTag;