import React, { useEffect, useState } from "react";
import SettingsCard from "../../../../components/structure/SettingsCard";
import styled from '@emotion/styled';
import { CardContainer, Logo } from '../SharedStyledComponents';
import { style_border_default } from '../../../../constants/stylesValues';
import Button from '../../../../components/general/Button';
import { color_shades_darkest } from "../../../../constants/colors";
import Loader from "../../../../components/general/Loader";
import TelephonyDeploymentContainer from "../../../../hooks/TelephonyDeploymentContainer";
import TelephonyDeploymentForm from "./TelephonyDeploymentForm";
import TelephonyDeploymentRequest from "../../../../models/telephonyDeployment/TelephonyDeploymentRequest";
import { TelephonyDeploymentResponse } from "../../../../models/telephonyDeployment/TelephonyDeploymentResponse";
import ApplicationContainer from "../../../../state/containers/ApplicationContainer";
import { css } from "@emotion/css";
import ConditionalTooltipWrapper from "../../../../components/general/ConditionalTooltipWrapper";
import PageError from "../../../../components/general/PageError";
import ConfirmationDialog from "../../../../components/structure/ConfirmationDialog";
import WarningConfirmation from "../../../../components/general/WarningConfirmation";
import { formatPhoneNumber } from "../../../../../clients/spark/scenes/basicOrganizationApplications/components/ApplicationItem";

const telephonyLogo = require("../../../../content/images/platforms/telephony-deployment.svg");
const telephonyAvatar = require("../../../../content/images/platforms/telephony-avatar.svg");
const deleteAvatar = require("../../../../content/images/custom-assistant-deployment/delete.svg");
const editAvatar = require("../../../../content/images/custom-assistant-deployment/edit.svg");

interface TelephonyDeploymentProps {
    applicationId: string;
    environmentId: string;
    appContainer: ApplicationContainer;
}

const TelephonyDeploymentCard = (props: TelephonyDeploymentProps) => {
    const [telephonyRequestFormIsOpen, setTelephonyRequestFormIsOpen] = useState(false);
    const [updateTelephonyRequestFormIsOpen, setUpdateTelephonyRequestFormIsOpen] = useState(false);
    const [selectedTelephonyDeployment, setSelectedTelephonyDeployment] = useState<TelephonyDeploymentResponse>();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");

    const telephonyDeploymentContainer = TelephonyDeploymentContainer.useContainer();

    useEffect(() => {
        loadTelephonyDeployments();
    }, []);

    const openTelephonyRequestForm = () => {
        setTelephonyRequestFormIsOpen(true);
    };

    const rewordedErrorMessage = () => {
        if (telephonyDeploymentContainer.errors[0] === "No numbers found") {
            return ["There are no numbers available with the digits you requested. Please try again."];
        } else {
            return telephonyDeploymentContainer.errors;
        }
    };

    const openTelephonyUpdateForm = (telephonyDeployment: TelephonyDeploymentResponse) => {
        setUpdateTelephonyRequestFormIsOpen(true);
        setSelectedTelephonyDeployment(telephonyDeployment);
    };

    const deletePhoneNumber = async (phoneNumber: string) => {
        setIsLoading(true);
        await telephonyDeploymentContainer.deleteTelephonyDeployment(props.applicationId, phoneNumber);
        loadTelephonyDeployments();
        setIsConfirmDeleteModalOpen(false);
    };

    const toggleConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
    };

    const closeDeleteModal = () => {
        toggleConfirmDeleteModal();
        setSelectedPhoneNumber("");
    };

    const selectPhoneNumberToDelete = (phoneNumber: string) => {
        setSelectedPhoneNumber(phoneNumber);
        toggleConfirmDeleteModal();
    };

    const closeTelephonyRequestForm = () => {
        if (telephonyRequestFormIsOpen) {
            setTelephonyRequestFormIsOpen(false);
        };
        if (updateTelephonyRequestFormIsOpen) {
            setUpdateTelephonyRequestFormIsOpen(false);
        };
        setSelectedTelephonyDeployment(undefined);
    };

    const loadTelephonyDeployments = async () => {
        setIsLoading(true);
        await telephonyDeploymentContainer.getTelephonyDeployments(props.applicationId);
        setIsLoading(false);
    };

    const submitTelephonyDeployment = async (request: TelephonyDeploymentRequest) => {
        if (telephonyRequestFormIsOpen) {
            await telephonyDeploymentContainer.createTelephonyDeployment(props.applicationId, request);
        }
        if (updateTelephonyRequestFormIsOpen) {
            await telephonyDeploymentContainer.updateTelephonyDeployment(props.applicationId, selectedTelephonyDeployment.phoneNumber, request);
        }
        loadTelephonyDeployments();
    };

    const disable = isLoading || telephonyDeploymentContainer.isLoading || telephonyDeploymentContainer.telephonyDeployments?.length >= 4;

    return (
        <CardContainer id="telephony-card">
            {(telephonyRequestFormIsOpen || updateTelephonyRequestFormIsOpen) &&
                <TelephonyDeploymentForm
                    closeTelephonyRequestForm={closeTelephonyRequestForm}
                    submitTelephonyDeployment={submitTelephonyDeployment}
                    environmentId={props.environmentId}
                    selectedTelephonyDeployment={selectedTelephonyDeployment}
                    appContainer={props.appContainer}
                />
            }
            <SettingsCard>
                <Logo src={telephonyLogo} />
                <DescriptionWrapper>
                    <p>Let's deploy your assistant to a phone number!</p>
                    <br />
                    <p>Select the "Add a Phone Number" button below to customize your settings.</p>
                </DescriptionWrapper>
                <CardTitle>Phone Numbers</CardTitle>
                <ListWrapper>
                    {

                        telephonyDeploymentContainer.telephonyDeployments?.length > 0 ?
                            telephonyDeploymentContainer.telephonyDeployments?.map((telephonyDeployment) => {
                                return (
                                    <div key={telephonyDeployment.id}>
                                        {isConfirmDeleteModalOpen &&
                                            <ConfirmationDialog
                                                isLoading={isLoading || telephonyDeploymentContainer.isLoading}
                                                title={`Confirm Delete`}
                                                deleteText="Yes, Delete"
                                                onClose={closeDeleteModal}
                                                onConfirm={() => deletePhoneNumber(selectedPhoneNumber)}>
                                                <WarningConfirmation
                                                    text={`Are you sure you want to delete ${formatPhoneNumber(selectedPhoneNumber)}? There's no guarantee you'll be able to request this specific number again.`}
                                                />
                                            </ConfirmationDialog>
                                        }
                                        {(isLoading || telephonyDeploymentContainer.isLoading) ? <Loader className={loaderStyle} /> :
                                            <ListItem key={telephonyDeployment.id}>
                                                <ListItemLeft onClick={() => openTelephonyUpdateForm(telephonyDeployment)}>
                                                    <Avatar src={telephonyAvatar} />
                                                    {telephonyDeployment?.name?.length ?
                                                        <>
                                                            <p>{telephonyDeployment.name}</p>
                                                            <NameSpacer>:</NameSpacer>
                                                        </> :
                                                        <></>
                                                    }
                                                    {telephonyDeployment.phoneNumber.length > 0 ? formatPhoneNumber(telephonyDeployment.phoneNumber, !!telephonyDeployment.virtualNumber?.length) : <p>Phone Number Unknown</p>}
                                                </ListItemLeft>
                                                <ListItemRight>
                                                    <ActionIcon src={editAvatar} onClick={() => openTelephonyUpdateForm(telephonyDeployment)} />
                                                    <ActionIcon src={deleteAvatar} onClick={() => selectPhoneNumberToDelete(telephonyDeployment.phoneNumber)} />
                                                </ListItemRight>
                                            </ListItem>
                                        }
                                    </div>
                                )
                            })
                            :
                            <p>Looks like you don't have any phone numbers set up for this assistant yet.</p>
                    }
                </ListWrapper>
                <ConditionalTooltipWrapper showTooltip={disable} place="top" text="You can have up to 4 phone numbers per assistant.">
                    <div>
                        <CustomButton
                            themes={["primary", "wide"]}
                            loading={isLoading || telephonyDeploymentContainer.isLoading}
                            disabled={disable}
                            text="Add a Phone Number"
                            type="button"
                            onClick={() => openTelephonyRequestForm()}
                        />
                    </div>
                </ConditionalTooltipWrapper>
            </SettingsCard>
            {telephonyDeploymentContainer.errors?.length > 0 &&
                <PageError
                    errors={rewordedErrorMessage()}
                    onClear={() => {
                        telephonyDeploymentContainer.clearErrors();
                    }}
                />
            }
        </CardContainer >
    );
};

const Empty = styled.div`
    width: 100px;
`;

const loaderStyle = css`
    position: fixed;
    top: 50%;
    left: 60%;
`;

const ActionIcon = styled.img`
    cursor: pointer;
    margin: 12px 24px 12px 12px;
`;

const Avatar = styled.img`
    margin: 12px 12px 12px 24px;
    padding: 2px;
    border-radius: 50%;
`;

const CardTitle = styled.h3`
    font-size: 18px;
    color: ${color_shades_darkest};
    font-weight: 400;
    margin-top: 32px;
`;

const CustomButton = styled(Button)`
    padding: 6px 24px;
    margin: 24px 0;
    display: flex;
    align-items: center;
    flex: 4;
`;

const DescriptionWrapper = styled.div`
    padding: 32px 0;
    border-bottom: ${style_border_default};
`;

const ListWrapper = styled.div`
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${style_border_default};
    border-top: none;
    border-radius: 8px;
    height: 56px;
    width: 100%;
    &:first-of-type {
        border-top: ${style_border_default};
    }
`;

const ListItemLeft = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const ListItemRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const NameSpacer = styled.p`
    margin-left: 0px;
    margin-right: 8px;
`;

export default TelephonyDeploymentCard;