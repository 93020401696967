import React from 'react';
import styled from '@emotion/styled';
import { color_shades_darkest, silver_four } from '../../constants/colors';
import Button from '../general/Button';
import Switch from './Switch';

const deleteIcon = require('../../content/images/bin-2.svg');
const duplicateIcon = require('../../content/images/response-duplicate.svg');

interface TextFieldProps {
    onDelete?: () => void
    onCopy?: () => void
    canCopy?: boolean
    canDelete?: boolean
    deletePosition?: IconPosition
    titleText?: string
}

const TextFieldSubtitle: React.FC<TextFieldProps> = ({ titleText, canDelete, canCopy, deletePosition, onDelete, onCopy } ) => {
    return (
        <>
            <TitleContainer>
                <TitleLineBefore/>
                <TitleText>{titleText}</TitleText>
                <TitleLineAfter/>
                <TitleIconContainer>
                    {canDelete && (deletePosition == undefined || deletePosition == "top") ?
                        <Button type="button" onClick={onDelete} themes={["medium-icon"]} icon={deleteIcon} />
                        :
                        null}
                    {canCopy && <Button type="button" onClick={onCopy} themes={["medium-icon"]} icon={duplicateIcon} />}
                </TitleIconContainer>
            </TitleContainer>
        </>
    );
};

type IconPosition = "top" | "right";

const TitleContainer = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    margin: 12px 0;
`
const TitleText = styled.h4`
    font-weight: 400;
    size: 16px;
    line-height: 24px;
    color: ${color_shades_darkest};
    padding: 0 8px;
`
const TitleLineBefore = styled.div`
    border-bottom: 1px solid ${silver_four};
    width: 8px;
`
const TitleLineAfter = styled.div`
    border-bottom: 1px solid ${silver_four};
    flex-grow: 1;
    margin-left: 8px;
`
const TitleIconContainer = styled.div`
    display: flex;
    align-items: center;
`

export default TextFieldSubtitle;