import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import * as yup from 'yup';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import TemplateFormFieldValueModel from '../../../models/templating/api/TemplateFormFieldValueModel';
import { FormikProps, withFormik } from 'formik';
import Loader from '../../../components/general/Loader';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import Button from '../../../components/general/Button';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import { color_text_default, color_colors_growth, color_colors_growth_background } from '../../../constants/colors';
import TemplateReviewSection from './TemplateReviewSection';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';

const completeIcon = require('../../../content/images/templates-icons/complete-thin.svg');
const incompleteIcon = require('../../../content/images/templates-icons/incomplete.svg');
const generateIcon = require('../../../content/images/templates-icons/generate-content.svg');
const assistantIcon = require('../../../content/images/assistant-blue.svg');
const syncedAssistantIcon = require('../../../content/images/assistant-green.svg');
const processingIcon = require('../../../content/images/templates-icons/processing-logo.svg');
const successfulSyncIcon = require('../../../content/images/templates-icons/successful-sync.svg');
const errorIcon = require('../../../content/images/alert-circle.svg');

interface AppliedAppTemplatesManagerProps {
    applicationId: string
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    incompleteSections: TemplateFormSectionModel[]
    onPrevious: () => void
    history: any
    appliedAppTemplateId?: string
    appliedAppTemplate?: AppliedApplicationTemplateModel
    appliedAppValues?: TemplateFormFieldValueModel[]
    appliedTemplates?: AppliedApplicationTemplateModel[]
    templateConfiguration?: TemplateConfigurationModel
    applicationEnvironmentsContainer: ApplicationEnvironmentsContainer
}

interface AppliedAppTemplatesManagerFormData {
    templateFormValues?: TemplateFormFieldValueModel[]
    appliedTemplates?: AppliedApplicationTemplateModel[]
    isSuccess: boolean
}

const InnerForm: React.FC<AppliedAppTemplatesManagerProps & FormikProps<AppliedAppTemplatesManagerFormData>> = (props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        props.applicationEnvironmentsContainer.loadEnvironments(props.applicationId);
    }, [props.applicationId])

    useEffect(() => {
        props.setFieldValue("templateFormValues", props.appliedAppValues);
        if (isFormSubmitted) {
            setIsFormSubmitted(false);
        }
    }, [props.appliedAppValues]);

    const submitForm = () => {
        setIsFormSubmitted(true);
        props.submitForm();
    };

    const goBack = () => {
        props.history.goBack();
    };

    const incompleteSections = props.incompleteSections;

    let submissionState: "none" | "templateConfigDisabled" | "waiting" | "error" | "success" = "none";

    if (props.templateConfiguration?.isDisabled) {
        submissionState = "templateConfigDisabled";
    } else if (isFormSubmitted) {
        if (props.appliedAppTemplateContainer.state.isWaitingForTemplateSync || props.applicationEnvironmentsContainer.state.isCreating)
            submissionState = "waiting";
        else if (props.appliedAppTemplateContainer.state.templateSyncErrors && props.appliedAppTemplateContainer.state.templateSyncErrors.length > 0)
            submissionState = "error";
        else
            submissionState = "success";
    };

    return (
        <>
            {props.appliedAppTemplateContainer.state.isLoading ?
                <Loader />
                :
                <form onSubmit={props.handleSubmit}>
                    <PanelHeaderContainer>
                        <div className={buttonContainerStyle}>
                            {submissionState != "waiting" &&
                                <Button disabled={props.appliedAppTemplateContainer.state.isSaving}
                                    themes={["white-small"]}
                                    text="Previous"
                                    type="button"
                                    onClick={props.onPrevious}
                                />
                            }
                            {submissionState != "success" ?
                                <Button
                                    disabled={props.appliedAppTemplateContainer.state.isSaving || submissionState == "waiting" || incompleteSections.length > 0}
                                    loading={props.appliedAppTemplateContainer.state.isSaving || submissionState == "waiting"}
                                    themes={["primary-small"]}
                                    text={"Finish"}
                                    type="button"
                                    onClick={submitForm}
                                />
                                :
                                <Button
                                    disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving}
                                    loading={props.appliedAppTemplateContainer.state.isSaving}
                                    themes={["primary-small"]}
                                    text={"Home"}
                                    type="button"
                                    onClick={goBack}
                                />
                            }
                        </div>
                    </PanelHeaderContainer>
                    <PanelContainer>
                        <CustomScrollbars>
                            <div className={containerStyle}>
                                <h4>Review</h4>
                                {submissionState == "success" &&
                                    <div className="successful-sync">
                                        <img src={successfulSyncIcon} />
                                        Success! Your content has been generated.
                                    </div>
                                }
                                {submissionState == "none" &&
                                    <>
                                        <TemplateReviewSection title="Status Check" icon={incompleteSections.length > 0 ? incompleteIcon : completeIcon} description={incompleteSections.length > 0 ? `You have incomplete sections: ${incompleteSections.map(s => s.title).join(', ')}` : "It looks like you've filled in all the required fields."} />
                                        <TemplateReviewSection
                                            title="Generate Content"
                                            icon={generateIcon}
                                            description={
                                                incompleteSections.length > 0 ?
                                                    "You need to complete all sections before you can generate content."
                                                    : "When you click “Finish” your content will be generated based on the values you entered."
                                            }
                                        />
                                        <div className="button-container">
                                            <Button
                                                disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving}
                                                loading={props.appliedAppTemplateContainer.state.isSaving}
                                                themes={["primary"]}
                                                text={"Finish"}
                                                type="button"
                                                onClick={submitForm} />
                                        </div>
                                    </>
                                }
                                {submissionState == "success" &&
                                    <div className="button-container">
                                        <Button
                                            disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving}
                                            loading={props.appliedAppTemplateContainer.state.isSaving}
                                            themes={["primary"]}
                                            text={"Home"}
                                            type="button"
                                            onClick={goBack}
                                        />
                                    </div>
                                }
                                {submissionState == "waiting" &&
                                    <TemplateReviewSection title="Processing..." icon={processingIcon} description="We’re busy writing out your content. Please wait until this process is complete before you try to initiate another update." />
                                }
                                {submissionState == "templateConfigDisabled" &&
                                    <TemplateReviewSection title="Disabled" icon={errorIcon} description="This module has been disabled and you are unable to use it further." />
                                }
                                {submissionState == "error" &&
                                    <>
                                        <TemplateReviewSection title="Errors" icon={errorIcon} description={`There was a problem syncing your template.\n ${props.appliedAppTemplateContainer.state.templateSyncErrors.toString()}`} />

                                        <div className="button-container">
                                            <Button disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving} loading={props.appliedAppTemplateContainer.state.isSaving}
                                                themes={["primary"]}
                                                text={"Retry"}
                                                type="button"
                                                onClick={submitForm} />
                                        </div>
                                    </>
                                }
                            </div>
                        </CustomScrollbars>
                    </PanelContainer>
                </form >
            }
        </>
    )
}

const buttonContainerStyle = css`
    margin-right: 16px;
    margin-left: auto;
    button {
        margin: 0 16px 0 0;
    }
`;

const containerStyle = css`
    padding: 32px;
    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: ${color_text_default};
    }
    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .successful-sync {
        margin-top: 16px;
        padding: 16px;
        display: flex;
        background-color: ${color_colors_growth_background};
        border: solid 1px ${color_colors_growth};
        border-radius: 8px;
        img {
            margin-right: 16px;
        }
    }
`;

const AppliedAppTemplateReviewForm = withFormik<AppliedAppTemplatesManagerProps, AppliedAppTemplatesManagerFormData>({
    mapPropsToValues: props => (
        {
            templateFormValues: [...props.appliedAppValues],
            isSuccess: false
        }
    ),
    validationSchema: yup.object().shape({
    }),
    handleSubmit: (values, { props }) => {
        const currentAppliedTemplateId = props.appliedAppTemplateId;
        {
            props.appliedAppTemplateContainer.finalizeForm(props.applicationId, currentAppliedTemplateId, {
                templateFormFieldValues: values.templateFormValues,
                name: props.appliedAppTemplate.name
            })
        }
    }
})(InnerForm);


export default AppliedAppTemplateReviewForm;