import React from 'react';
import { css } from '@emotion/css';
import ContainerDimensions from 'react-container-dimensions';

export const googleBodyStyle = css`
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    margin: 16px 0;
    .screen-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        background: gray;
        border-radius: 5px;
        width: calc(100% - 16px);
        height: 100%;
    }
    .screen {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-49%, -49%);
        transform-origin: center center;
        width: 1920px;
        height: 1080px;
        background: white;
    }
`
interface GoogleWrapperProps {
    className?: string
}
const GoogleScreenWrapper: React.SFC<GoogleWrapperProps> = (props) =>
    (
        <div className={googleBodyStyle}>
            <div className='screen-wrapper'>
                <ContainerDimensions>
                    {({ width, height }) =>
                        <div className={`screen ${props.className}`} style={{ transform: `translate(-50%, -50%) scale(${Math.min(width / 1920, height / 1080)})` }}>
                            {props.children}
                        </div>
                    }
                </ContainerDimensions>
            </div>
        </div >
    )

export default GoogleScreenWrapper;