import React from 'react';
import { css } from '@emotion/css';
import { color_shades_darkest, color_text_link, color_shades_dark, color_text_default } from '../../../constants/colors';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
const folderIcon = require('../../../content/images/content-explorer/folder.svg');

interface NewFeatureItemProps {
    onFinish: (text: string) => void
    isLoading: boolean
}
interface NewFeatureItemState {
    text: string
}

class NewFeatureItem extends React.Component<NewFeatureItemProps, NewFeatureItemState> {
    inputElement: HTMLInputElement
    constructor(props) {
        super(props);
        this.state = {
            text: "New Folder"
        }
    }
    componentDidMount() {
        this.inputElement?.focus();
    }
    handleBlur() {
        if (this.state.text) {
            this.props.onFinish(this.state.text);
        }
    }
    handleEnterBlur(e) {
        if (e.charCode == 13) {
            e.target.blur();
        }
    }
    handleChange(e) {
        this.setState({
            ...this.state,
            text: e.target.value
        })
    }
    render() {
        return (
            <div className={containerStyle}>
                {this.props.isLoading && <SecondaryLoader />}
                <div className="feature-link">
                    <img className="folder-icon" src={folderIcon} />
                    <input className="feature-title"
                        placeholder="New Folder"
                        value={this.state.text}
                        disabled={this.props.isLoading}
                        ref={r => this.inputElement = r}
                        onChange={this.handleChange.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        onFocus={(e) => e.target.select()}
                        onKeyPress={(e) => this.handleEnterBlur(e)} />
                </div>
            </div>
        )
    }
}

const containerStyle = css`
    height: 80px;
    background: #FFFFFF;
    border: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 32px;
    cursor: pointer;
    .feature-link {
        display: flex;
        align-items: center;
        color: ${color_shades_darkest};
    }
    .folder-icon {
        width: 56px;
        height: 56px;
    }
    .feature-title {
        background: white;
        margin-left: -32px;
        margin-top: 18px;            
        font-family: Muli;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 12px;
        border: none;
        color: ${color_text_default};
    }      
    .spinner > div {
        background: ${color_text_link};
    } 
`

export default NewFeatureItem;