import React from "react";
import ReactMarkdown from "react-markdown";
import IntegrationConfiguration, { FormFieldType } from "../../models/integrations/IntegrationFormField";
import IntegrationsListingsContainer from "../../state/containers/IntegrationsListingsContainer";
import PageContainer from "../../components/structure/PageContainer";
import FooterBar from "../../components/structure/FooterBar";
import Button from "../../components/general/Button";
import { css } from '@emotion/css';
import IntegrationListing from "../../models/integrations/IntegrationListing";
import TextField from "../../components/forms/TextField";
import { withFormik, FormikProps } from "formik";
import WebhookContainer from "../../state/containers/WebhookContainer";
import PageError from "../../components/general/PageError";
import IntegrationSetupSection from "../../models/integrations/setup/IntegrationSetupSection";
import IntegrationSetupProperty from "../../models/integrations/setup/IntegrationSetupProperty";
import { silver_four } from "../../constants/colors";
import TextAreaField from "../../components/forms/TextAreaField";
import CheckboxField from "../../components/forms/CheckboxField";
import Switch from "../../components/forms/Switch";
import SelectField from "../../components/forms/SelectField";
import Loader from "../../components/general/Loader";

type ExternalIntegrationFormProps = {
    integrationContainer: IntegrationsListingsContainer,
    webhookContainer: WebhookContainer,
    additionalPropertiesConfiguration: { [name: string]: FormFieldType },
    setupSections: IntegrationSetupSection[],
    integration: IntegrationListing,
    organizationId: string,
    handleClose: (hasChanges: boolean) => void
}

type ExternalIntegrationData = {
    stringParameters: { [id: string]: string }
    boolParameters: { [id: string]: boolean }
}

type ExternalIntegrationState = {
    errors: string[]
}

class InnerForm extends React.Component<ExternalIntegrationFormProps & FormikProps<ExternalIntegrationData>, ExternalIntegrationState>{
    renderSetupProperty(property: IntegrationSetupProperty) {
        switch (property.field.fieldType) {
            case "ShortTextField":
                return this.renderShortText(property);
            case "LongTextField":
                return this.renderLongText(property);
            case "SwitchField":
                return this.renderSwitch(property);
            case "SelectField":
                return this.renderSelect(property);
            case "CheckboxField":
                return this.renderCheckbox(property);
        }
    }
    renderCheckbox(property: IntegrationSetupProperty) {
        return (
            <div>
                <div className={label}>{property.label}</div>
                <CheckboxField
                    key={property.id}
                    name={`boolParameters.${property.id}`}
                    checked={this.props.values.boolParameters[property.id]}
                    onChange={this.props.handleChange}
                    required={property.required}
                    className={checkboxField}
                    tooltip={property.tooltip} />
            </div>

        )
    }
    renderSwitch(property: IntegrationSetupProperty) {
        return (
            <div>
                <div className={label}>{property.label}</div>
                <Switch
                    key={property.id}
                    name={`boolParameters.${property.id}`}
                    onChange={this.handleSwitchChange.bind(this, `boolParameters.${property.id}`, property.id)}
                    checked={this.props.values.boolParameters[property.id]}
                    label={property.field.rightOption}
                    leftOption={property.field.leftOption}
                    tooltip={property.tooltip}
                />
            </div>
        )
    }
    renderShortText(property: IntegrationSetupProperty) {
        return (
            <TextField
                key={property.id}
                name={`stringParameters.${property.id}`}
                value={this.props.values.stringParameters[property.id]}
                onChange={this.props.handleChange}
                required={property.required}
                tooltip={property.tooltip}
                label={property.label}
                placeholder={property.field.placeholder} />
        )
    }
    renderLongText(property: IntegrationSetupProperty) {
        return (
            <TextAreaField
                key={property.id}
                name={`stringParameters.${property.id}`}
                value={this.props.values.stringParameters[property.id]}
                onChange={this.props.handleChange}
                required={property.required}
                tooltip={property.tooltip}
                label={property.label}
                placeholder={property.field.placeholder} />

        )
    }
    renderSelect(property: IntegrationSetupProperty) {
        return (
            <SelectField
                key={property.id}
                className={selectField}
                onChange={this.handleChange.bind(this, `stringParameters.${property.id}`)}
                onBlur={() => { }}
                name={`stringParameters.${property.id}`}
                value={this.props.values.stringParameters[property.id]}
                label={property.label}
                placeholder={property.field.placeholder}
                options={property.field.options.map(o => ({ value: o.value, label: o.label }))}
            />
        )
    }
    renderSetupSection(section: IntegrationSetupSection, idx: number) {
        const properties = section.properties == null ? [] : section.properties;
        return (
            <div key={`section_${idx}`} className={setupSectionContainer}>
                <ReactMarkdown className="instructions" source={section.instructionsMarkdown} renderers={{ link: this.linkRenderer }} />
                {properties.map((p, subIdx) => {
                    return (
                        <div key={`setupSection_${subIdx}`} className={setupPropertyContainer}>
                            {this.renderSetupProperty(p)}
                        </div>
                    )
                })}
            </div>
        )
    }
    linkRenderer(props) {
        return <a href={props.href} target="_blank">{props.children}</a>
    }
    renderSetupSections() {
        return (
            <div className={setupSectionsContainer}>
                {this.props.setupSections.map((s, idx) => {
                    return this.renderSetupSection(s, idx);
                })}
            </div>
        )
    }
    handleChange(fieldName, value) {
        this.props.setFieldValue(fieldName, value.value);
    }
    handleSwitchChange(fieldName, id) {
        const newValue = !this.props.values.boolParameters[id];
        this.props.setFieldValue(fieldName, newValue);
    }
    render() {
        if (this.props.integrationContainer.state.isLoading)
            return <Loader />
        return (
            <PageContainer fill>
                <form onSubmit={this.props.handleSubmit}>
                    <PageContainer fill>
                        {this.props.setupSections != null ? this.renderSetupSections() : this.renderSimpleFields()}
                    </PageContainer>
                    <FooterBar>
                        <Button themes={["primary", "wide"]} loading={this.props.integrationContainer.state.isSaving} text="Create" type="submit" />
                        <Button themes={["white", "end"]} loading={this.props.integrationContainer.state.isSaving} text="Cancel" type="button" onClick={this.props.handleClose.bind(this, true)} />
                    </FooterBar>
                </form>
                <PageError errors={this.props.integrationContainer.state.errors} />
            </PageContainer>
        )
    }
    renderSimpleField(nameLeft: string, nameRight: string, idx: number) { // todo: remove in next release, deprecated.
        var type = this.props.additionalPropertiesConfiguration[nameLeft];
        switch (type) {
            default: return (
                <div key={`container${idx}`} className={formFieldsRow}>
                    <TextField key={`leftfield${idx}`} className={formFieldLeft} name={`stringParameters.${nameLeft}`} label={nameLeft} value={this.props.values.stringParameters[nameLeft]} onChange={this.props.handleChange} />
                    {nameRight == null ? null : <TextField className={formFieldRight} key={`rightfield${idx}`} name={`stringParameters.${nameRight}`} label={nameRight} value={this.props.values.stringParameters[nameRight]} onChange={this.props.handleChange} />}
                </div>
            )
        }
    }
    renderSimpleFields() { // todo: remove in next release, deprecated.
        const keys = Object.keys(this.props.additionalPropertiesConfiguration ?? {});
        return (
            <div className={dumbFormFieldsContainer}>
                {keys.map((n, idx) => {
                    return (idx % 2 === 0 || idx === 0) ? this.renderSimpleField(n, keys.length > (idx + 1) ? keys[idx + 1] : null, idx) : null;
                })}
            </div>
        )
    }
}

const ExternalIntegrationForm = withFormik<ExternalIntegrationFormProps, ExternalIntegrationData>({
    mapPropsToValues: (props) => {
        var stringParameters = {}
        var boolParameters = {}
        if (props.setupSections == null) {
            Object.keys(props.additionalPropertiesConfiguration ?? {}).map(f => {
                stringParameters[f] = ""
            })
        }
        else {
            props.setupSections.map(s => {
                const properties = s.properties == null ? [] : s.properties;
                properties.map(p => {
                    if (p.field.fieldType === "CheckboxField" || p.field.fieldType === "SwitchField")
                        boolParameters[p.id] = true;
                    else
                        stringParameters[p.id] = ""
                })
            })
        }
        return {
            stringParameters: stringParameters,
            boolParameters: boolParameters
        }
    },
    handleSubmit: (values, { props }) => {
        let paramsToSend = values.stringParameters;
        if (props.setupSections != null)
            for (let key in values.boolParameters)
                paramsToSend[key] = values.boolParameters[key] ? "true" : "false";

        props.integrationContainer.sendConfigurationInformation(props.organizationId, props.integration, paramsToSend)
            .then(() => {
                props.webhookContainer.getWebhooksForOrganization(props.organizationId).then(() => {
                    props.handleClose(false);
                })
            })
    }
})(InnerForm)

const setupSectionsContainer = css`
    padding-bottom: 150px;
`;

const selectField = css`
    padding-top: 16px;
`;

const checkboxField = css`
    padding-left: 16px;
    .tooltip {
        margin-top: 3px;
    }
`;

const setupSectionContainer = css`
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${silver_four};
    .instructions {
        font-family: Muli;
        font-style: normal;
        font-stretch: normal;
        padding-bottom: 20px;
        h1 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 24px;
            font-weight: bold;
        }
        h2 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 20px;
            font-weight: bold;
        }
        h3 {
            margin-bottom: 16px;
            margin-top: 16px;        
            font-size: 18px;
            font-weight: bold;
        }
        h4 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 16px;
            font-weight: bold;
        }
        h5 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 14px;
            font-weight: bold italic;
        }
        h6 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 14px;
            font-weight: bold;
        }
    }
`;

const label = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    margin: 24px 16px 8px 16px;
`;

const setupPropertyContainer = css`
    padding: 10px 0px;
    border-top: 1px solid ${silver_four};
`;

const dumbFormFieldsContainer = css`
    display:flex;
    flex-direction:column;
    margin: 30px;
    width: 90%;
    height: auto;
    font-size: 20px;
`;

const formFieldsRow = css`
    display:flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-left: 15px;
    margin-right: 15px;
`;

const formFieldLeft = css`
    width: 50%;
    margin-right: 15px;
`;

const formFieldRight = css`
    width: 50%;
    margin-left: 15px;
`;

export default ExternalIntegrationForm