import React, { useEffect, useState } from "react";
import LanguageModel from "../../../models/features/api/LanguageModel";
import TextField from "../../../components/forms/TextField";
import Button from "../../../components/general/Button";
import styled from "@emotion/styled";
import { color_shades_light } from "../../../constants/colors";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import { ArrayHelpers, FieldArray } from "formik";
import NlpEntityValueModel from "../../../models/nlpEntity/api/NlpEntityValueModel";
import SynonymsEditor from "./SynonymsEditor";

const closeIcon = require("../../../content/images/close_icon.svg");
const addIcon = require("../../../content/images/add-circle-dark.svg");

export interface NlpEntityValueCardProps {
    languages?: LanguageModel[];
    nlpEntity: EditableNlpEntity;
    nlpValue: NlpEntityValueModel;
    nlpValueIndex: number;
    formPrefix: string;
    formIndex: number;
    validateField;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    handleSubmit;
    dirty: boolean;
    isValid: boolean;
    status?: any;
}

const NlpEntityValueCard = (props: NlpEntityValueCardProps) => {

    const nlpEntityValue = props.nlpValue;
    const nlpEntityValueName: string = props.nlpValue?.name;
    const nlpEntityValueSynonyms = props.nlpValue?.synonyms;

    const disableFields: boolean =
        !props.nlpEntity.isEditable || props.nlpEntity.isDeleted; //|| props.isSubmitting || props.isValidating

    const isModifiedField = `${props.formPrefix}.${props.formIndex}.isModified`;

    const cardPrefix = `${props.formPrefix}.${props.formIndex}.values.${props.nlpValueIndex}`;
    const nameFieldName: string = `${cardPrefix}.name`;
    const patternFieldName: string = `${cardPrefix}.pattern`;
    const synonymsFieldName: string = `${cardPrefix}.synonyms`;

    useEffect(
        function resetNlpValueSynonymOnEntitySelect() {
        },
        [props.formIndex]
    );

    const handleDeleteSynonym = (
        index: number,
        arrayHelpers: ArrayHelpers
    ) => {
        arrayHelpers.remove(index);
        props.setFieldValue(isModifiedField, true);
    };

    const handleAddSynonym = (synonym: string) => {
        const error = validateNlpValueSynonym(synonym);
        if (error) {
            return;
        }
        const index: number = props.nlpValue?.synonyms?.length ?? 0;
        props.setFieldValue(`${synonymsFieldName}.${index}`, synonym);
        props.setFieldValue(isModifiedField, true);
    };

    const validateNlpValueSynonym = (value: string) => {
        let error;
        if (!value || value.trim() == "") {
            error = "List Value Synonym cannot be empty";
        }
        return error;
    };

    const handleChange = (fieldName, event) => {
        props.setFieldValue(isModifiedField, true);
        props.handleChange(event);
    };

    const renderListTypeEdits = () => {
        return (
            <>
                <TextField
                    name={nameFieldName}
                    disabled={disableFields}
                    required
                    placeholder="Name"
                    defaultValue={nlpEntityValueName}
                    label="Name"
                    onChange={(e) => handleChange(nameFieldName, e)}
                    onBlur={props.handleBlur}
                    autoFocus={true}
                    key={props.nlpValueIndex}
                />

                <SynonymsHeader> SYNONYMS </SynonymsHeader>
                <SynonymsEditor
                    synonyms={nlpEntityValueSynonyms}
                    synonymsFieldName={synonymsFieldName}
                    disableFields={disableFields}
                    handleDelete={handleDeleteSynonym}
                    handleAddSynonym={handleAddSynonym}
                    handleBlur={props.handleBlur}
                    autofocusNewSynonym={!nlpEntityValue.isAdded}
                />
            </>
        );
    };

    const renderRegexTypeEdits = () => {
        return (
            <>
                <div key={`${cardPrefix}.regex`}>
                    <TextField
                        name={patternFieldName}
                        disabled={disableFields}
                        required
                        placeholder="NLP Entity Regex Pattern"
                        defaultValue={nlpEntityValue.pattern}
                        label="Regex"
                        onChange={(e) => handleChange(patternFieldName, e)}
                        onBlur={props.handleBlur}
                        autoFocus={true}
                    />
                </div>
            </>
        );
    };

    const renderNativeTypeEdits = () => {
        return <></>;
    };

    return (
        <CardContainer>
            <HorizontalSeparator />
            <fieldset>
                {props.nlpEntity.type === "Regex" && renderRegexTypeEdits()}
                {props.nlpEntity.type === "Native" && renderNativeTypeEdits()}
                {props.nlpEntity.type === "List" && renderListTypeEdits()}
            </fieldset>
        </CardContainer>
    );
};

export default NlpEntityValueCard;
const CardContainer = styled.div`
    padding: 0 16px 0 16px;

    .field-wrapper {
        label {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    .field-container {
        height: 44px;
    }
    label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .new-field-container {
        display: flex;
    }
`;

const SynonymsHeader = styled.div`
    margin-bottom: 8px;
`;
