import React from 'react';
import { css } from '@emotion/css';
import _ from 'lodash';
import RadioCardGroup from '../../../components/forms/RadioCardGroup';
import { RadioOption } from '../../../components/forms/RadioGroup';
import SelectField from '../../../components/forms/SelectField';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';
import Switch from '../../../components/forms/Switch';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import DetailLabel from '../../../components/general/DetailLabel';
import PageError from '../../../components/general/PageError';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import ContentCard from '../../../components/structure/ContentCard';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { blue_white, color_defaults_black, color_shades_dark, cool_grey, dark_grey_blue } from '../../../constants/colors';
import { FallbackFeatureTypeId, HelpMessagesFeatureTypeId, SimpleChoiceFeatureTypeId, ExitMessagesFeatureTypeId, WelcomeMessagesFeatureTypeId, QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, NumberRangeFeatureTypeId, CustomRequestsFeatureTypeId } from '../../../constants/featureTypeIds';
import FollowUpModel from '../../../models/features/api/FollowUp/FollowUpModel';
import FollowUpHintModel from '../../../models/features/api/FollowUp/FollowUpHintModel';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import ChildContentContainerModel from '../../../models/features/api/Relationships/ChildContentContainerModel';
import ConversationItemSlot from '../../../models/features/ConversationItemSlot';
import { InnerPage } from '../../../state/containers/ConversationExplorerContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import FollowUpContainer from '../../../state/containers/FollowUpContainer';
import ContentExplorer from '../../contentExplorer';
import ChildItemContainer from './ChildItemContainer';
import ConversationItemCard from './ConversationItemCard';
import ConversationItemWrapper from './ConversationItemWrapper';
import { Droppable, DragDropContext, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import ManageImplicitFollowUpForm from './ManageImplicitFollowUpForm';
import HorizontalScrollView from '../../../components/structure/HorizontalScrollView';
import HintField from './HintField';
import { reorder } from '../../../models/extensions';
const endSessionIcon = require("../../../content/images/flags.svg");
const suggestIcon = require('../../../content/images/suggest.svg');
const targetIcon = require('../../../content/images/target-dark.svg');
const addIcon = require('../../../content/images/add-circle.svg');
const clearIcon = require('../../../content/images/clear-circle.svg');
const helpIcon = require('../../../content/images/contextual-help.svg');
const fallbackIcon = require('../../../content/images/contextual-fallback.svg');
const hintsIcon = require('../../../content/images/hints.svg');
const followUpIcon = require('../../../content/images/compass.svg');
const pinIcon = require('../../../content/images/pin.svg');
export const followUpCardIcon = require('../../../content/images/content-tree-icons/content-level/follow-up.svg');

interface ManageFollowUpFormProps {
    followUp?: FollowUpModel
    childContentContainer?: ChildContentContainerModel
    followUpContainer?: FollowUpContainer
    selection?: FollowUpSelectionType
    stateContainer?: FollowUpContainer
    featureContainer: FeatureContainer
    languageIds: string[]
    applicationId: string
    emptyOptionText?: string
    emptyOptionIcon?: string
    isLoading?: boolean
    onManage?: () => void
    onChange?: (followUp: FollowUpModel, childItemContainer: ChildContentContainerModel) => void
    applicationFeatureId: string
    disabled?: boolean
    groupIndex?: number
}
interface ManageFollowUpFormState {
    selection: FollowUpSelectionType
    isSelectingContent: boolean
    childSlots: ConversationItemSlot[]
    currentSlot: ConversationItemSlot
    currentFeatureTypeFilter?: string[]
    currentPropertyFilter?: any
    helpSlot?: ConversationItemSlot
    fallbackSlot?: ConversationItemSlot
    starterPage?: InnerPage
}
export type FollowUpSelectionType = "end" | "suggest" | "structured";
export const ValidFollowUpFeatureTypes = [QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, SimpleChoiceFeatureTypeId, NumberRangeFeatureTypeId, CustomRequestsFeatureTypeId];

class ManageFollowUpForm extends React.Component<ManageFollowUpFormProps, ManageFollowUpFormState> {
    constructor(props) {
        super(props);
        var selectionType: FollowUpSelectionType = "end";
        if (props.followUp) {
            selectionType = props.followUp.childContentContainer || props.childContentContainer ? "structured" : "suggest"
        }

        this.state = {
            selection: selectionType,
            isSelectingContent: false,
            currentSlot: null,
            childSlots: this.getInitialChildSlots(),
            helpSlot: this.state?.helpSlot ?? (props.followUp && props.followUp.helpMessageId ? {
                featureTypeIdFilter: HelpMessagesFeatureTypeId,
                title: "Contextual Help Message",
                currentFeatureTypeId: HelpMessagesFeatureTypeId,
                conversationItem: {
                    ...this.props.followUp.helpMessage,
                    featureTypeId: HelpMessagesFeatureTypeId
                },
                description: ''
            } : null),
            fallbackSlot: this.state?.fallbackSlot ?? (props.followUp && props.followUp.fallbackMessageId ? {
                featureTypeIdFilter: FallbackFeatureTypeId,
                title: "Contextual Fallback Message",
                currentFeatureTypeId: FallbackFeatureTypeId,
                conversationItem: {
                    ...props.followUp.fallbackMessage,
                    featureTypeId: FallbackFeatureTypeId
                },
                description: ''
            } : null)
        };
    }
    componentDidMount() {
        this.props.featureContainer.getSystemFeatures();
        this.props.featureContainer.getFeaturesForApp(this.props.applicationId);
    }
    componentDidUpdate(prevProps: ManageFollowUpFormProps) {
        if (prevProps.followUp != this.props.followUp || prevProps.childContentContainer != this.props.childContentContainer) {
            this.setSelection();
        }
    }
    setSelection() {
        var selectionType: FollowUpSelectionType = "end";
        if (this.props.followUp) {
            selectionType = this.props.followUp.childContentContainer || this.props.childContentContainer ? "structured" : "suggest"
        }
        this.setState({
            selection: selectionType,
            isSelectingContent: false,
            currentSlot: null,
            childSlots: this.getInitialChildSlots(),
            helpSlot: this.state?.helpSlot ?? (this.props.followUp && this.props.followUp.helpMessageId ? {
                featureTypeIdFilter: HelpMessagesFeatureTypeId,
                title: "Contextual Help Message",
                currentFeatureTypeId: HelpMessagesFeatureTypeId,
                conversationItem: {
                    ...this.props.followUp.helpMessage,
                    featureTypeId: HelpMessagesFeatureTypeId
                },
                description: ''
            } : null),
            fallbackSlot: this.state?.fallbackSlot ?? (this.props.followUp && this.props.followUp.fallbackMessageId ? {
                featureTypeIdFilter: FallbackFeatureTypeId,
                title: "Contextual Fallback Message",
                currentFeatureTypeId: FallbackFeatureTypeId,
                conversationItem: {
                    ...this.props.followUp.fallbackMessage,
                    featureTypeId: FallbackFeatureTypeId
                },
                description: ''
            } : null)
        })
    }
    getInitialChildSlots() {
        return this.props.childContentContainer && this.props.childContentContainer.contentItems && this.props.childContentContainer.contentItems.length > 0
            ? this.props.childContentContainer.contentItems.map((c, i) => ({
                featureTypeIdFilter: null,
                title: `Expected Response #${i + 1}`,
                description: 'Where will this response take the user?',
                conversationItem: c,
                currentFeatureType: this.props.featureContainer.getFeatureTypeById(c.featureTypeId)
            }))
            : [{
                featureTypeIdFilter: null,
                title: 'Expected Response #1',
                description: 'Where will this response take the user?',
                conversationItem: null,
            }]
    }
    handleSelectionChange(option: RadioOption) {
        const selection = option.value as FollowUpSelectionType
        var slots = [];
        if (selection == "end") {
            this.props.onChange(null, null);
        }
        else if (selection == "structured") {
            this.props.onChange(
                {
                    content: this.props.followUp?.content ?? '',
                    childContentContainer: {
                        id: null,
                        applicationId: this.props.applicationId,
                        isLimitedToChildren: false,
                    },
                    followUpHints: this.props.followUp?.followUpHints ?? []
                },
                {
                    applicationId: this.props.applicationId,
                    isLimitedToChildren: false,
                    contentItems: [],
                    id: null
                }
            );
            slots = [{
                featureTypeIdFilter: null,
                title: 'Expected Response #1',
                description: 'Where will this response take the user?',
                conversationItem: null,
            }]
        }
        else if (selection == "suggest") {
            this.props.onChange({
                content: this.props.followUp?.content ?? '',
                childContentContainer: null,
                followUpHints: this.props.followUp?.followUpHints ?? []
            },
                null)
        }

        this.setState({
            ...this.state,
            selection: selection,
            childSlots: slots
        });
    }
    handleFollowUpTextChange(ssml: string) {
        const followUp = this.props.followUp;
        followUp.content = ssml;
        this.props.onChange(followUp, this.props.childContentContainer);
    }
    handleHintChange(e, index: number) {
        const followUp = this.props.followUp;
        var hints = followUp ? followUp.followUpHints : null;
        if (hints) {
            hints[index].content = e.target.value;
        }
        followUp.followUpHints = hints;
        this.props.onChange(followUp, this.props.childContentContainer);
    }

    handleAddHint() {
        const followUp = this.props.followUp;
        var hints = followUp ? followUp.followUpHints : null;
       
        if (hints) {
            var newPriority = hints.length + 1;
            hints.push({
                id: null,
                followUpId: undefined,
                content: '',
                priority: newPriority
            })
        } else {
            hints = [{ id: null, followUpId: undefined, content: '', priority: 0 }]
        }
        followUp.followUpHints = hints;
        this.props.onChange(followUp, this.props.childContentContainer);

    }

    handleHintDelete(index: number) {
        const followUp = this.props.followUp;
        var hints = followUp ? followUp.followUpHints : null;
        if (hints) {
            hints.splice(index, 1);
            followUp.followUpHints = hints;
        }
        this.props.onChange(followUp, this.props.childContentContainer);
    }
    handleAddChild(slot?: ConversationItemSlot, initialPage?: InnerPage) {
        this.setState({
            ...this.state,
            isSelectingContent: true,
            currentSlot: slot,
            currentFeatureTypeFilter: slot?.featureTypeIdFilter ? [slot.featureTypeIdFilter] : null,
            currentPropertyFilter: slot?.itemFilter,
            starterPage: initialPage
        })
    }
    handleEnforceChildren(checked: boolean) {
        const childContainer = this.props.childContentContainer;
        childContainer.isLimitedToChildren = checked;
        this.props.onChange(this.props.followUp, childContainer);
    }
    handleLimitIntents(checked: boolean) {
        const childContainer = this.props.childContentContainer;
        childContainer.limitIntentsToChildren = checked;
        this.props.onChange(this.props.followUp, childContainer);
    }
    handleConversationItemSelected(contentItems: GenericContentItemModel[]) {
        const childContentContainer = this.props.childContentContainer;
        if (contentItems.length == 0) {
            this.setState({
                ...this.state,
                isSelectingContent: false
            });
            return;
        }
        const slots = this.state.childSlots ?? [];

        const helpSlot = this.state.helpSlot;
        const fallbackSlot = this.state.fallbackSlot;
        const followUp = this.props.followUp;
        if (this.state.currentSlot && this.state.currentSlot.featureTypeIdFilter == HelpMessagesFeatureTypeId) {
            helpSlot.conversationItem = contentItems[0];
            followUp.helpMessageId = contentItems[0]?.id;
        }
        else if (this.state.currentSlot && this.state.currentSlot.featureTypeIdFilter == FallbackFeatureTypeId) {
            fallbackSlot.conversationItem = contentItems[0];
            followUp.fallbackMessageId = contentItems[0].id;
        }
        else if (this.state.currentSlot && contentItems.length == 1) {
            slots[slots.indexOf(this.state.currentSlot)].conversationItem = contentItems[0];
            slots[slots.indexOf(this.state.currentSlot)].currentFeatureTypeId = contentItems[0]?.featureTypeId;
            childContentContainer.contentItems?.push(contentItems[0]);
        }
        else {
            // remove the placeholder
            _.remove(slots, s => !s.conversationItem);
            contentItems.forEach(c => {
                slots.push({
                    featureTypeIdFilter: null,
                    conversationItem: c,
                    title: `Expected Response #${slots.length + 1}`,
                    description: "Where will this response take the user?",
                    currentFeatureTypeId: c.featureTypeId
                })
                childContentContainer.contentItems?.push(c);
            })
        }
        this.setState({
            ...this.state,
            isSelectingContent: false,
            currentSlot: null,
            childSlots: slots,
            helpSlot: helpSlot,
            fallbackSlot: fallbackSlot,
            starterPage: null
        })
        this.props.onChange(followUp, childContentContainer);
    }
    handleCloseExplorer() {
        this.setState({
            ...this.state,
            isSelectingContent: false,
            currentSlot: null,
            starterPage: null
        })
    }
    handleRemoveSlot(slot: ConversationItemSlot) {
        if (slot.conversationItem) {
            const childContentContainer = this.props.childContentContainer;
            _.remove(childContentContainer.contentItems, c => c.id == slot.conversationItem.id);
            this.props.onChange(this.props.followUp, childContentContainer);
        }

        var slots = this.state.childSlots;
        _.remove(slots, s => s == slot);
        this.setState({
            ...this.state,
            childSlots: slots
        });
    }
    handleAddOrRemoveHelp() {
        if (this.state.helpSlot) {
            this.setState({
                ...this.state,
                helpSlot: null
            })
            const followUp = this.props.followUp;
            followUp.helpMessageId = null;
            this.props.onChange(followUp, this.props.childContentContainer);
        }
        else {
            this.setState({
                ...this.state,
                helpSlot: {
                    featureTypeIdFilter: HelpMessagesFeatureTypeId,
                    title: "Contextual Help Message",
                    currentFeatureTypeId: HelpMessagesFeatureTypeId,
                    conversationItem: null,
                    description: ''
                }
            })
        }
    }
    handleAddOrRemoveFallback() {
        if (this.state.fallbackSlot) {
            this.setState({
                ...this.state,
                fallbackSlot: null
            })
            const followUp = this.props.followUp;
            followUp.fallbackMessageId = null;
            this.props.onChange(followUp, this.props.childContentContainer);
        }
        else {
            this.setState({
                ...this.state,
                fallbackSlot: {
                    featureTypeIdFilter: FallbackFeatureTypeId,
                    title: "Contextual Fallback Message",
                    conversationItem: null,
                    currentFeatureTypeId: FallbackFeatureTypeId,
                    description: ''
                }
            })
        }
    }
    handleSelectHelpMessage() {
        const appFeature = this.props.featureContainer.findGeneralByFeatureType(HelpMessagesFeatureTypeId);
        this.handleAddChild(this.state.helpSlot, {
            pageType: 'conversation-items',
            applicationFeature: appFeature,
            name: 'Help Messages'
        });
    }
    handleSelectFallbackMessage() {
        const appFeature = this.props.featureContainer.findGeneralByFeatureType(FallbackFeatureTypeId);
        this.handleAddChild(this.state.fallbackSlot, {
            pageType: 'conversation-items',
            applicationFeature: appFeature,
            name: 'Fallback Messages'
        });
    }
    handleAddTargetedResponse(slot?: ConversationItemSlot) {
        const appFeature = this.props.featureContainer.findFeatureById(this.props.applicationFeatureId);
        this.handleAddChild(slot, {
            pageType: 'conversation-items',
            applicationFeature: appFeature,
            name: 'Conversation Items'
        });
    }
    handleAddOrClearHints() {
        const followUp = this.props.followUp;
        if (followUp && followUp.followUpHints && followUp.followUpHints.length > 0) {
            followUp.followUpHints = []; // clear them
        }
        else {
            followUp.followUpHints.push({
                content: '',
                priority: 0
            });
        }
        this.props.onChange(followUp, this.props.childContentContainer);
    }
    handleImplicitChange(followUp: FollowUpModel) {
        this.props.onChange(followUp, null);
    }
    handleDragEnd(result: DropResult, provider: ResponderProvided) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const hints: FollowUpHintModel[] = _.orderBy(this.props.followUp?.followUpHints, af => af.priority, 'asc');
        this.handleReorderHints(hints, result.source.index, result.destination.index);
    }
    handleMoveDown(index: number) {
        const hints: FollowUpHintModel[] = this.props.followUp?.followUpHints;

        if (index < hints.length - 1) {
            this.handleReorderHints(hints, index, index + 1);
        }
    }
    handleMoveUp(index: number) {
        const hints: FollowUpHintModel[] = this.props.followUp?.followUpHints;

        if (index > 0) {
            this.handleReorderHints(hints, index, index - 1);
        }
        
    }
    handleReorderHints(array: FollowUpHintModel[], sourceIndex: number, endIndex: number)
    {
        let orderedHints: FollowUpHintModel[] = reorder(array, sourceIndex, endIndex);
        orderedHints.forEach((h, idx) => h.priority = idx);
        this.props.onChange({
            ...this.props.followUp,
            followUpHints: orderedHints
        }, this.props.childContentContainer);

    }

    renderHints() {
        const hints = _.orderBy(this.props.followUp?.followUpHints ?? [], h => h.priority, 'asc');
        return (
            <div className={subsectionContainer}>
                <div className="inner-wrapper">
                    <img className="subsection-container-img" src={hintsIcon} />
                    <div>
                        <div className="title-section" id={`g${this.props.groupIndex}on-screen-hint`}>
                            <div className="hints-header">
                                <p className="header">On-screen Hint </p>
                                <p className="detail">A visible text snippet on screen devices that suggests something a user can say or navigate to</p>
                            </div>
                            {this.props.followUp?.name == null &&
                                <Button themes={['blue-pill', 'end', 'end-tight']} 
                                    icon={this.props.followUp && !this.props.followUp.followUpHints.length ? addIcon : clearIcon} text={this.props.followUp && !this.props.followUp.followUpHints.length ? 'ADD' : 'CLEAR'} 
                                    type="button" 
                                    onClick={this.handleAddOrClearHints.bind(this)} />
                            }
                        </div>

                        {hints && hints.length > 0 ?
                            <>
                                <DragDropContext onDragEnd={this.handleDragEnd.bind(this)}>
                                    <Droppable droppableId={"hints"} type="HINTS_SECTION">
                                        {
                                            (provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    {hints.map((hint, index) => (
                                                        <Draggable draggableId={index.toString()} index={index} key={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    key={index}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>
                                                                    <HintField
                                                                        index={index}
                                                                        isLast = {index == hints.length - 1}
                                                                        hint={hint}
                                                                        followUp={this.props.followUp}
                                                                        handleHintChange={this.handleHintChange.bind(this)}
                                                                        handleMoveDown={this.handleMoveDown.bind(this)}
                                                                        handleHintDelete={this.handleHintDelete.bind(this)}
                                                                        handleMoveUp={this.handleMoveUp.bind(this)} />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )
                                        }
                                    </Droppable>
                                </DragDropContext>
                                <Button type="button" themes={["hollow", "fill"]} text="Add an On-Screen Hint" onClick={() => this.handleAddHint()} />
                            </>
                            : null}
                    </div>
                </div>
            </div>)
    }
    render() {
        return (
            <ContentCard className="ac-response-followup-card" title="Conversation Follow-up" id={`g${this.props.groupIndex}followUp`} icon={followUpCardIcon}>
                {this.props.isLoading ? <div className={loaderContainer}><SecondaryLoader /></div>
                    :
                    <>
                        <FormFieldsContainer>
                            <DetailLabel text="Select what happens after the last response in this conversation" />
                            <RadioCardGroup options={[
                                { value: "end", label: this.props.emptyOptionText ? this.props.emptyOptionText : "End session", icon: this.props.emptyOptionIcon ? this.props.emptyOptionIcon : endSessionIcon },
                                { value: "suggest", label: "Suggest more content", icon: suggestIcon },
                                { value: "structured", label: "Targeted response", icon: targetIcon }]}
                                onChange={this.handleSelectionChange.bind(this)}
                                value={this.state.selection}
                                disabled={this.props.disabled} />
                        </FormFieldsContainer>

                        {this.state.selection == "structured" && <>
                            <HorizontalSeparator />
                            <div className={subsectionContainer} id={`g${this.props.groupIndex}guidance`}>
                                <div className="inner-wrapper">
                                    <img className="subsection-container-img" src={followUpIcon} />
                                    <div>
                                        <p className="header">Guidance</p>
                                        <p className="detail">Prompt the user with a question or statement and guide them to the next conversation</p>
                                        <SsmlField
                                            applicationId={this.props.applicationId}
                                            name="followUpContent"
                                            className="follow-up-field"
                                            label="Follow-up Language"
                                            value={this.props.followUp ? this.props.followUp.content : ''}
                                            onBlur={() => { }} onChange={this.handleFollowUpTextChange.bind(this)}
                                            placeholder="What do you want to ask the user?" />
                                    </div>
                                </div>
                            </div>
                            <div id={`g${this.props.groupIndex}destination`} className={`${subsectionContainer} tight`}>
                                <div className="inner-wrapper">
                                    <img className="subsection-container-img" src={pinIcon} />
                                    <div>
                                        <p className="header">Destination</p>
                                        <p className="detail">Where should the above guidance lead the user?</p>
                                    </div>
                                </div>
                            </div>
                            <ChildItemContainer>
                                {this.state.childSlots.map((slot, i) => (
                                    <ConversationItemCard key={i} slot={slot} canDelete={slot.featureTypeIdFilter == null} onDelete={() => this.handleRemoveSlot(slot)} onAddItem={() => this.handleAddTargetedResponse(slot)} />
                                ))}
                            </ChildItemContainer>

                            <Button type="button" themes={['hollow', 'fill']} text="Add an Expected Response" onClick={() => this.handleAddTargetedResponse(null)} />

                            <HorizontalSeparator />

                            <FormFieldsContainer>
                                <Switch className={switchContainer} checked={this.props.childContentContainer && this.props.childContentContainer.isLimitedToChildren} onChange={this.handleEnforceChildren.bind(this)} label="Enforce the above responses" detail="The user will be required to say one of these options to continue" />
                                <Switch className={switchContainer} checked={this.props.childContentContainer && this.props.childContentContainer.limitIntentsToChildren} onChange={this.handleLimitIntents.bind(this)} label="Limit intents to expected responses" detail="Intents will only be available if they are represented by one of the above options" />
                            </FormFieldsContainer>
                            <HorizontalSeparator />
                            {this.renderHints()}
                            {(this.props.childContentContainer && this.props.childContentContainer.isLimitedToChildren) &&
                                <>
                                    <HorizontalSeparator />
                                    <div className={subsectionContainer} id={`g${this.props.groupIndex}contextualFallback`}>
                                        <div className="inner-wrapper">
                                            <img className="subsection-container-img" src={fallbackIcon} />
                                            <div>
                                                <div className="title-section">
                                                    <div>
                                                        <p className="header">Contextual Fallback </p>
                                                        <p className="detail">If the user says something that isn’t recognized as one of the available responses</p>
                                                    </div>
                                                    <Button themes={['blue-pill', 'end', 'end-tight']} icon={this.state.fallbackSlot ? clearIcon : addIcon} text={this.state.fallbackSlot ? "CLEAR" : "ADD"} type="button" onClick={this.handleAddOrRemoveFallback.bind(this)} />

                                                </div>
                                                {this.state.fallbackSlot ?
                                                    <ConversationItemWrapper slot={this.state.fallbackSlot} onClick={this.handleSelectFallbackMessage.bind(this)} />
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    <HorizontalSeparator />
                                    <div className={subsectionContainer} id={`g${this.props.groupIndex}contextualHelp`}>
                                        <div className="inner-wrapper">
                                            <img className="subsection-container-img" src={helpIcon} />
                                            <div>
                                                <div className="title-section">
                                                    <div>
                                                        <p className="header">Contextual Help</p>
                                                        <p className="detail">If the user asks for help here</p>
                                                    </div>
                                                    <Button themes={['blue-pill', 'end', 'end-tight']} icon={this.state.helpSlot ? clearIcon : addIcon} text={this.state.helpSlot ? "CLEAR" : "ADD"} type="button" onClick={this.handleAddOrRemoveHelp.bind(this)} />

                                                </div>
                                                {this.state.helpSlot ?
                                                    <ConversationItemWrapper slot={this.state.helpSlot} onClick={this.handleSelectHelpMessage.bind(this)} />
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </>}
                        </>}
                        {this.state.selection == "suggest" &&
                            <ManageImplicitFollowUpForm followUp={this.props.followUp}
                                followUpContainer={this.props.followUpContainer}
                                applicationId={this.props.applicationId}
                                onManage={this.props.onManage}
                                onChange={this.handleImplicitChange.bind(this)}>
                                {this.renderHints()}
                            </ManageImplicitFollowUpForm>}
                        {this.state.isSelectingContent &&
                            <ContentExplorer
                                applicationId={this.props.applicationId}
                                exploreType={
                                    (this.state?.currentSlot?.featureTypeIdFilter == HelpMessagesFeatureTypeId
                                        || this.state?.currentSlot?.featureTypeIdFilter == FallbackFeatureTypeId)
                                        ? "content-select" : "content-multi-select"}
                                onClose={this.handleCloseExplorer.bind(this)}
                                title="Select Expected Conversation Items"
                                disabledFeatureIds={[]}
                                featureTypeIdFilter={this.state.currentFeatureTypeFilter ?? ValidFollowUpFeatureTypes}
                                propertyFilter={this.state.currentPropertyFilter}
                                languageIdFilter={this.props.languageIds}
                                onSelect={this.handleConversationItemSelected.bind(this)}
                                isLoading={this.props.featureContainer.state.isLoadingBulkOperation}
                                initialPage={this.state.starterPage}
                            />
                        }
                    </>}
                <PageError errors={this.props.followUpContainer.state.errors} />
            </ContentCard>
        )
    }
}

const loaderContainer = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;

    .spinner > div {
        background-color: ${dark_grey_blue};
    }
`
const switchContainer = css`
margin-bottom: 32px;
`
const subsectionContainer = css`
    padding: 24px 0;
    &.tight {
        padding-top: 0;
    }
    .inner-wrapper {
        display: flex;
        align-items: flex-start;
        >div {
            flex: 1;
        }
        .selector {
            margin-top: 24px;
        }
    }
    .title-section {
        display: flex;
        align-items: flex-start;
    }
    .subsection-container-img {
        margin-right: 16px;
    }
    .header {
        font-family: Muli;
        font-size: 18px;
        color: ${dark_grey_blue};
    }
    .detail {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: ${cool_grey};
        margin-right: 16px;
    }
    button {
        margin-top: 0;
        margin-bottom: 0;
    }
    .hints-header {
        margin-bottom: 16px;
    }
    .follow-up-field {
        margin-top: 16px;
    }
`

export default ManageFollowUpForm;