import React from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-tabs';
import TabIndicator from './TabIndicator';
import { css } from '@emotion/css';
import { dark_sky_blue, ocean_blue, silver_two, dark_grey_blue_10 } from '../../constants/colors';
import ImageTab from './ImageTab';


interface TabbedLayoutProps {
    tabs: ImageTabItem[]
    defaultIndex?: number
}
export interface ImageTabItem {
    title: string
    icon: string
    selectedIcon: string
    className?: string
}
const tabStripStyle = css`
    display: flex;
    flex-direction: row;
    height: 115px;
    list-style: none;
    border-bottom: solid 1px ${silver_two};
    background: white;
`;



const ImageInnerTabs: React.FC<TabbedLayoutProps> = (props) => {

   
    function renderTab(tab: ImageTabItem, index) {
        return (ImageTab({tab, index, className:index < props.tabs.length - 1 ? "bordered" : ""}));
    }
    return (
        <Tabs defaultIndex={props.defaultIndex}>
            <TabList className={tabStripStyle}>
                {props.tabs.map(renderTab)}
            </TabList>
            {props.children}
        </Tabs>
    );
}

export default ImageInnerTabs;
