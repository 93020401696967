import React from 'react';
import { css } from '@emotion/css';
import ModalLayout from '../../../components/structure/ModalLayout';
import ModalHeader from '../../../components/general/ModalHeader';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import 'react-markdown-editor-lite/lib/index.css';
import MdEditor from 'react-markdown-editor-lite'
import MarkdownPreview from './MarkdownPreview';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import { page_primary_background, color_shades_lighter, color_shades_dark } from '../../../constants/colors';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
const quoteIcon = require('../../../content/images/markdown-editor/close-quote.svg');
const codeIcon = require('../../../content/images/markdown-editor/code-brackets.svg');
const linkIcon = require('../../../content/images/markdown-editor/hyperlink-2.svg');
const imageIcon = require('../../../content/images/markdown-editor/image-file-landscape.svg');
const tableIcon = require('../../../content/images/markdown-editor/layout-2.svg');
const listIcon = require('../../../content/images/markdown-editor/list-bullets.svg');
const list2Icon = require('../../../content/images/markdown-editor/list-numbers.svg');
const textIcon = require('../../../content/images/markdown-editor/small-caps.svg');
const boldIcon = require('../../../content/images/markdown-editor/text-bold.svg');
const italicsIcon = require('../../../content/images/markdown-editor/text-italic.svg');
const strikethroughIcon = require('../../../content/images/markdown-editor/text-strike-through.svg');
interface MarkdownEditorModalProps {
    markdown: string
    contextTitle: string
    onSave: (markdown: string) => void
    onClose: () => void
}

interface MarkdownEditorModalState {
    markdown: string
}

class MarkdownEditorModal extends React.Component<MarkdownEditorModalProps, MarkdownEditorModalState> {
    constructor(props) {
        super(props);
        this.state = {
            markdown: props.markdown
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.markdown != this.props.markdown) {
            this.setState({
                ...this.state,
                markdown: this.props.markdown
            })
        }
    }
    handleChange(value: { text: string, html: string }) {
        this.setState({
            ...this.state,
            markdown: value.text
        })
    }

    render() {
        return (
            <ModalLayout isVisible={true} onClose={this.props.onClose}>
                <ModalHeader title="Helpful Information" onClose={this.props.onClose} />
                <PanelContainer className={containerStyle}>
                    <div className={`${sharedPanel} ${editorPanel}`}>
                        <h3>{this.props.contextTitle}</h3>
                        <p>Insert markdown here to better explain to users what will happen with the information they provide</p>
                        <div className="editor-wrapper">
                            <MdEditor
                                value={this.state.markdown}
                                style={{ flex: 1, height: "100%" }}
                                config={{
                                    view: {
                                        html: false,
                                        menu: true,
                                        md: true
                                    },
                                    canView: {
                                        html: false
                                    }
                                }}
                                renderHTML={(text) => null}
                                markdownClass={markdownContainer}
                                onChange={this.handleChange.bind(this)} />
                        </div>
                    </div>
                    <div className={`${sharedPanel} ${previewPanel}`}>
                        <h3>Preview</h3>
                        <p>Here’s what will be displayed</p>
                        <div className="editor-wrapper preview">
                            <CustomScrollbars>
                                <MarkdownPreview instructionsMarkdown={this.state.markdown} />
                            </CustomScrollbars>
                        </div>
                    </div>
                </PanelContainer>
                <FooterBar>
                    <Button themes={["primary"]} text="Save" type="button" onClick={() => this.props.onSave(this.state.markdown)} />
                    <Button themes={["secondary"]} text="Clear All" type="button" onClick={() => this.props.onSave('')} />
                    <Button themes={["secondary", "end"]} text="Cancel" type="button" onClick={this.props.onClose} />
                </FooterBar>
            </ModalLayout>
        )
    }
}
const containerStyle = css`
    margin-bottom: 160px;
    padding-bottom: 160px;
`
const sharedPanel = css`
    flex: 1;
    padding: 32px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 50%;
    h3 {                
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid ${color_shades_dark};
    }

    p {
        margin: 16px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    .editor-wrapper {
        border: 1px solid ${color_shades_dark};
        border-radius: 8px;
        background: white;
        flex: 1;
        overflow: hidden;

        .rc-md-navigation {
            height: 52px;
            .button-wrap {
                .button {
                    width: 20px;
                    height: 20px;
                    margin: 8px;
                }
            }
            .drop-wrap {
                top: 20px;
            }
            .button-type-header {
                i {
                    ::before {
                        content: url(${textIcon});
                    }
                }
            }
            .button-type-bold {
                i {
                    ::before {
                        content: url(${boldIcon});
                    }
                }
            }
            .button-type-italic {
                i {
                    ::before {
                        content: url(${italicsIcon});
                    }
                }
            }
            .button-type-underline {
                display: none;
                i {
                    ::before {
                        content: url(${textIcon});
                    }
                }
            }
            .button-type-strikethrough {
                i {
                    ::before {
                        content: url(${strikethroughIcon});
                    }
                }
            }
            .button-type-unordered {
                i {
                    ::before {
                        content: url(${listIcon});
                    }
                }
            }
            .button-type-ordered {
                i {
                    ::before {
                        content: url(${list2Icon});
                    }
                }
            }
            .button-type-quote {
                i {
                    ::before {
                        content: url(${quoteIcon});
                    }
                }
            }
            .button-type-hr {
                display: none;
                i {
                    ::before {
                        content: url(${textIcon});
                    }
                }
            }
            .button-type-inlinecode {
                display: none;
                i {
                    ::before {
                        content: url(${textIcon});
                    }
                }
            }
            .button-type-code {
                i {
                    ::before {
                        content: url(${codeIcon});
                    }
                }
            }
            .button-type-table {
                i {
                    ::before {
                        content: url(${tableIcon});
                    }
                }
            }
            .button-type-image {
                i {
                    ::before {
                        content: url(${imageIcon});
                    }
                }
            }
            .button-type-link {
                i {
                    ::before {
                        content: url(${linkIcon});
                    }
                }
            }
            .button-type-clear {
                display: none;
                i {
                    ::before {
                        content: url(${textIcon});
                    }
                }
            }
            .button-type-undo {
                display: none;
                i {
                    ::before {
                        content: url(${textIcon});
                    }
                }
            }
            .button-type-redo {
                display: none;
                i {
                    ::before {
                        content: url(${textIcon});
                    }
                }
            }
        }
    }
    .instructions {
        padding: 32px;
    }
    
`
const previewPanel = css`
    background: ${color_shades_lighter};
    border-left: 1px solid ${color_shades_dark};
`
const editorPanel = css`
    background: ${page_primary_background};
`
const markdownContainer = css`

`

export default MarkdownEditorModal;