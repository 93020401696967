import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import RadioGroup, { RadioOption } from '../../../components/forms/RadioGroup';
import SelectField from '../../../components/forms/SelectField';
import Button from '../../../components/general/Button';
import Loader from '../../../components/general/Loader';
import ModalTitle from '../../../components/general/ModalTitle';
import PageError from '../../../components/general/PageError';
import FooterBar from '../../../components/structure/FooterBar';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import ModalLayout from '../../../components/structure/ModalLayout';
import PageContainer from '../../../components/structure/PageContainer';
import { color_text_default } from '../../../constants/colors';
import ApplicationNlpEntityContainer from '../../../hooks/ApplicationNlpEntityContainer';
import { NlpEntityType } from '../../../models/nlpEntity/api/NlpEntityType';
import { NlpEntityCreateMethod } from './ApplicationNlpEntitiesEditor';

const closeIcon = require('../../../content/images/close_icon.svg');

interface AddNewNlpEntityModalProps {
    handleClose: () => void;
    handleSubmit: (source: NlpEntityCreateMethod, key: string) => any;
    isLoading: boolean;
};

const AddNewNlpEntityModal: React.FC<AddNewNlpEntityModalProps> = (props) => {

    const applicationNlpEntityContainer = ApplicationNlpEntityContainer.useContainer();

    const [isAddDisabled, setIsAddDisabled] = useState(true);
    const [errorMessages, setErrorMessages] = useState([]);
    const [nlpEntityOption, setNlpEntityOption] = useState("Custom" as NlpEntityCreateMethod);
    const [typeOptionValue, setTypeOptionValue] = useState(null as NlpEntityType);
    const [prebuiltOptionValue, setPrebuiltOptionValue] = useState(null as string);

    const types: NlpEntityType[] = ["List", "Regex"]; // We do not allow user to create "Native" entity
    const typeOptions = types.map(t => ({value: t, label: t}))
    const prebuiltEntitiesOptions = applicationNlpEntityContainer?.prebuiltNlpEntities?.
        map(e => ({value: e.id, label: (e.name + " (" + e.type + ")")}));

    const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        if (props.isLoading || isAddDisabled) return;

        if (e.key === "Enter") {
            handleAddNewNlpEntity();
        }
    };

    const handleOptionChange = (option: RadioOption) => {
        setIsAddDisabled(true);
        setNlpEntityOption(option.value as NlpEntityCreateMethod);
        setTypeOptionValue(null);
        setPrebuiltOptionValue(null);
    };

    const handleCustomSelectionChange = (e) => {
        setTypeOptionValue(e.value);
        setIsAddDisabled(false);
    };

    const handlePrebuiltSelectionChange = (e) => {
        setPrebuiltOptionValue(e.value);
        setIsAddDisabled(false);
    };

    const handleAddNewNlpEntity = () => {
        props.handleSubmit(nlpEntityOption, (prebuiltOptionValue || typeOptionValue));
    };
    
    return (
        <NewNlpEntityModal 
            isVisible={true} 
            onClose={() => props.handleClose()} >        
            <PageContainer fill withFooter>
                <HorizontalContainer>
                    <ModalTitle padded>New NLP Entity</ModalTitle>
                    <Button themes={['end-tight', 'icon']} 
                        icon={closeIcon} 
                        onClick={() => props.handleClose()} />
                </HorizontalContainer>
                <HorizontalSeparator />
                <HorizontalContainer >
                    <div className="main-body" 
                        onKeyUp={(e) => handleKeyUp(e)} > 

                        <RadioGroup onChange={handleOptionChange}
                            value={nlpEntityOption}
                            disabled={props.isLoading}
                            options={[
                                { value: "Custom", label: "Custom Entity" }
                            ]} />
                        {
                            typeOptionValue === "List" && 
                            <TypeDescription>
                                List entities represent lists of values each with optional synonyms. For example: an entity that represents toppings might have a value "Pepperoni" with a synonym of "roni".
                            </TypeDescription>                            
                        }
                        {
                            typeOptionValue === "Regex" && 
                            <TypeDescription>
                                    Regex entities represent regular expressions that represent different patterns of potential user inputs. For example:
                                    <br />
                                    ^[0-9]{5}(?:-[0-9]{4})?$
                                    <br />
                                    represents any 5 or 9 digit zip code. 
                            </TypeDescription>                             
                        }
                        <SelectField 
                            options={typeOptions}
                            value={typeOptionValue} 
                            disabled={props.isLoading || nlpEntityOption === "Prebuilt"}
                            placeholder={"Select..."}
                            onBlur={() => {}} 
                            onChange={handleCustomSelectionChange}
                            useFormTheme={true}
                            label="Type"
                            name="customNlpEntityType"/>

                        <RadioGroup onChange={handleOptionChange}
                            value={nlpEntityOption}
                            disabled={props.isLoading}
                            options={[
                                { value: "Prebuilt", label: "Pre-built Entity" }
                            ]} />

                        <SelectField 
                            options={prebuiltEntitiesOptions}
                            value={prebuiltOptionValue} 
                            disabled={props.isLoading || nlpEntityOption === "Custom"}
                            placeholder={"Select..."}
                            onBlur={() => {}} 
                            onChange={handlePrebuiltSelectionChange}
                            useFormTheme={true}
                            label="Select One"
                            name="prebuiltNlpEntities"/>
                        {props.isLoading &&
                            <Loader   />
                        }
                    </div>
                    
                    <PageError errors={[
                        ...errorMessages ?? []
                        ]} onClear={() => {
                            setErrorMessages([]);
                        }} />
                </HorizontalContainer>
                <CustomFooter className={"add-new-footer"}>
                    <SubmitButton 
                        type="button" 
                        themes={['primary']} 
                        text="Create" 
                        onClick={handleAddNewNlpEntity} 
                        disabled={props.isLoading || isAddDisabled} 
                        loading={props.isLoading} />
                    <div className="selected-count"></div>
                    <Button 
                        type="button" 
                        themes={['secondary']} 
                        text="Cancel" 
                        onClick={() => props.handleClose()} 
                        disabled={props.isLoading} 
                        loading={props.isLoading} />
                </CustomFooter>
            </PageContainer>
        </NewNlpEntityModal>
    );

};

export default AddNewNlpEntityModal;

const NewNlpEntityModal = styled(ModalLayout)`
    color: ${color_text_default};
    width: 40%;
    height: 30%;
    min-height: 730px;
    min-width: 700px;

    .main-body {
        width: 100%;
        padding: 32px;
    }
`;

const CustomFooter = styled(FooterBar)`
    z-index: auto;
`;

const SubmitButton = styled(Button)`
    margin-right: auto;
`;

const TypeDescription = styled.div`
    margin: 0px 16px 16px 16px;
`;
