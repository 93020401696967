import React from 'react';
import { Subscribe } from 'unstated';
import TemplateConfigurationContainer from '../../state/containers/TemplateConfigurationContainer';
import { RouteComponentProps } from 'react-router';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import TemplateConfigurationForm from './components/TemplateConfigurationForm';
import MediaContainer from '../../state/containers/MediaContainer';
import AppliedApplicationTemplateContainer from '../../state/containers/AppliedApplicationTemplateContainer';

interface EditTemplateProps {
    templateConfigurationId: string
}

const EditTemplateConfiguration: React.SFC<RouteComponentProps<EditTemplateProps>> = (props) => (
    <Subscribe
        to={[
            TemplateConfigurationContainer,
            MediaContainer,
            OrganizationContainer,
            AppliedApplicationTemplateContainer
        ]}
    >
        {(
            templatesContainer: TemplateConfigurationContainer,
            mediaContainer: MediaContainer,
            orgContainer: OrganizationContainer,
            appliedApplicationTemplateContainer: AppliedApplicationTemplateContainer
        ) => (
            <TemplateConfigurationForm
                templateConfigContainer={templatesContainer}
                mediaContainer={mediaContainer}
                orgContainer={orgContainer}
                templateConfigurationId={props.match.params.templateConfigurationId}
                templateConfiguration={templatesContainer.state.organizationTemplates.find(t => t.id == props.match.params.templateConfigurationId)}
                history={props.history}
                appliedApplicationTemplateContainer={appliedApplicationTemplateContainer}
            />
        )}
    </Subscribe>
);

export default EditTemplateConfiguration;