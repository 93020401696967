import React from 'react';
import { css } from '@emotion/css';
import Select, { Option, ValueComponentProps } from 'react-select';
import { render } from 'react-dom';
import { dark_grey_blue, dark_grey_blue_10 } from '../../constants/colors';
import FieldError from '../forms/FieldError';
const checkIcon = require('../../content/images/check.svg');
const inactiveIcon = require('../../content/images/x.svg');


interface RoleToggleProps {
    currentRole: string
    roles: string[]
    label?: string
    name?: string
    includeError?: boolean
    onChange: (role: string) => void
    positionTop?: boolean
}



interface RoleToggleState {
    currentRole: string
}
class RoleToggle extends React.Component<RoleToggleProps, RoleToggleState> {
    componentWillMount() {
        this.setState({
            ...this.state,
            currentRole: this.props.currentRole
        });
    }
    handleChange(value: any) {
        this.setState({
            ...this.state,
            currentRole: value.value
        });
        if(this.props.onChange) {
            this.props.onChange(value.value);
        }
    }
    render() {
        return (
            <div className={containerStyle}>
            {this.props.label !== undefined ? <label>{this.props.label}</label> : null}
            <Select
                name={this.props.name ? this.props.name : "role"}
                placeholder="Role"
                value={this.state.currentRole}
                onChange={this.handleChange.bind(this)}
                options={this.props.roles.map(r => ({value: r, label: r}))}
                className={`${selectStyle} ${this.props.positionTop ? topSelectStyle : ''}`}
                searchable={false}
                clearable={false} /> 
                {this.props.includeError ? <FieldError name={this.props.name ? this.props.name : "role"} /> : null}
                </div>
        );
    }
}
const topSelectStyle = css`
    &.is-open {
        .Select-control {
            border-radius: 16px;
        }
    }
    .Select-menu-outer {
        position: absolute;
        top: -100px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }
    .Select-option:first-child {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }
`
const selectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 160px;
    margin-right: 32px;
    margin-left: auto;
    .Select-control {
        border-radius: 16px;
        min-height: 32px;
        box-shadow: -1px 4px 10px 0 ${dark_grey_blue_10};
    }
    .Select-placeholder, .Select-value{
        padding: 0 26px 0 8px;
        font-family: Muli;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .Select-value-label {
        margin-left: 16px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        overflow-y: auto;
        z-index: 999;
    }
    .Select-option, Select-noresults {
        height: 32px;
        padding: 0 26px 0 8px;
        font-family: Muli;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
    }
`


const containerStyle = css`
    display: flex;
    align-items: center;
    
    > * + * {
        margin-left: 16px;
    }

    label {
        margin: 0 24px;
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: ${dark_grey_blue};
    }
`
export default RoleToggle;