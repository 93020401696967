import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import GoogleScreenWrapper from './GoogleScreenWrapper';
import DOMPurify from 'dompurify';

const screenStyle = css`
    color: white;
    .body-text, .title-text {
        font-family: 'Roboto', sans-serif;
        
        padding-top: 80px;
        padding-left: 80px;
        color: #111111;    
        overflow-wrap: break-word;
        z-index: 2;
        position: relative;
    }
    .title-text {
        font-size: 80px;
    }
    .foreground-image {
        width: 400px;
        z-index: 2;
        height: auto;
        padding-right: 160px;
        margin-top: 80px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .body-text {
        overflow-y: hidden;
        height: 660px;
        flex: 1;
        font-size: 42px;
    }
    .body-container {
        display: flex;
        z-index: 2;
        position: relative;
        align-items: start;
    }
    .chip-container {
        margin-left: 60px;
        margin-bottom: 20px;
    }
    .hint-chip {
        border: 1px solid gray;
        height: 80px;
        border-radius: 80px;
        margin-right: 40px;
        font-family: 'Roboto', sans-serif;
        font-size: 62px;
        color: #111111;    
        z-index: 2;
        padding: 32px;
    }
`
class GoogleStandardCardPreview extends ScreenPreviewComponent {

    render() {
        return (
            <GoogleScreenWrapper className={screenStyle}>
                {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                <div className="body-container">
                    <p className="body-text">
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.displayText) }}></span>

                        {this.state.followUpText ?
                            <>
                                <br /><br />
                                <span className="followup-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.followUpText) }}></span>
                            </>
                            : null}
                    </p>
                    {this.state.foregroundImageUrl
                        ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                        : null}
                </div>
                <div className="chip-container">
                    {this.state.hints ? this.state.hints
                        .filter(h => h.content.length <= 20)
                        .map((h, i) => <span key={i} className="hint-chip">{h.content}</span>)
                        : null}
                </div>
            </GoogleScreenWrapper>
        )
    }
}

export default GoogleStandardCardPreview;