import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { FormikErrors } from "formik";
import React, { useState } from "react";
import Button from "../../../components/general/Button";
import ErrorLabel from "../../../components/general/ErrorLabel";
import TooltipWrapper from "../../../components/general/TooltipWrapper";
import { color_shades_dark, color_text_default, color_colors_ocean, color_variants_ocean_light_2 } from "../../../constants/colors";
import LanguageModel from "../../../models/features/api/LanguageModel";
import NlpEntityValueModel from "../../../models/nlpEntity/api/NlpEntityValueModel";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import { CountButton } from "../../contentPage/components/CountButton";
import EntityMetadataModal from "./EntityMetadataModal";
import { InteractionModelFormData, maxNlpEntityNameSize } from "./InteractionModelForm";
import NlpEntityValueCard from "./NlpEntityValueCard";
import { SmallTagWithMargin } from "./SharedStyledComponents";

const deleteIcon = require('../../../content/images/trash.svg');
const alertIcon = require('../../../content/images/alert-circle.svg');
const metadataIcon = require("../../../content/images/entity-metadata.svg")

interface NlpEntityValueItemProps {
    toggleItemActivationByIndex: (index: number) => void;
    deleteNlpValueByIndex: (index: number) => void;
    languages?: LanguageModel[];
    nlpEntity: EditableNlpEntity;
    nlpValue: NlpEntityValueModel;
    nlpValueIndex: number;
    isActive: boolean;
    formPrefix: string;
    formIndex: number;
    validateField;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    handleSubmit;
    dirty: boolean;
    isValid: boolean;
    status?: any;
    errors?: FormikErrors<InteractionModelFormData>;
}

const NlpEntityValueItem: React.FC<NlpEntityValueItemProps> = (props) => {
    const [showMetadataModal, setShowMetadataModal] = useState(false);
    const isActive: boolean = props.isActive;
    const nlpEntityValue = props.nlpValue;
    const nlpEntityValueName: string = nlpEntityValue?.name;
    const nlpEntityValueSynonyms = nlpEntityValue?.synonyms;

    const nlpEntityValueTitle: string =
        (nlpEntityValueName ?
            (nlpEntityValueName?.trim().length > maxNlpEntityNameSize
                ? (nlpEntityValueName.trim().substring(0, maxNlpEntityNameSize) + "...")
                : nlpEntityValueName)
            : props.nlpEntity.type === "Regex" ?
                `Pattern ${props.nlpValueIndex + 1}`
                : `New ${props.nlpEntity.type} Value`);

    const disableFields: boolean = !props.nlpEntity.isEditable || props.nlpEntity.isDeleted; //|| props.isSubmitting || props.isValidating 
    const hasErrors = props.errors?.[props.formPrefix]?.[props.formIndex]?.["values"]?.[props.nlpValueIndex];

    const handleItemToggleActivation = (index: number) => {
        if (isActive) {
            //Close
            props.toggleItemActivationByIndex(-1);
        }
        else {
            //Open
            props.toggleItemActivationByIndex(index);
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
        if (disableFields) return;

        if (event.key === "Escape") {
            handleItemToggleActivation(-1);
        }
    };

    const handleDeleteItem = (event) => {
        event.stopPropagation();
        props.deleteNlpValueByIndex(props.nlpValueIndex);

    };

    const handleShowMetadataModal = (event) => {
        event.stopPropagation();
        setShowMetadataModal(true);
    };


    const renderItemName = () => {
        return (
            <div className={itemTitle} key={props.nlpValueIndex}>
                <SmallTagWithMargin>{nlpEntityValueTitle}</SmallTagWithMargin>
                {nlpEntityValueSynonyms?.length > 0 && <SmallTagWithMargin>{"+" + nlpEntityValueSynonyms?.length.toString()}</SmallTagWithMargin>}
            </div>
        )
    };

    const renderItemActions = () => {
        return (
            <div className={itemActions} >
                {hasErrors &&
                    <ErrorLabel image={alertIcon} text={"Errors Present"}/> 
                }
                {!disableFields &&
                    <MetadataButton>
                        <CountButton
                            isActive={false}
                            onClick={(event) => handleShowMetadataModal(event)}  
                            activeIcon={metadataIcon}
                            inactiveIcon={metadataIcon}
                            showCount={true}
                            count={Object.keys(nlpEntityValue.metadata ?? {}).length}
                            text="Metadata"
                        /> 
                    </MetadataButton>   
                }
                {!disableFields &&
                    <TooltipWrapper text="Delete">
                        <div>
                            <Button themes={['icon', 'end-tight']}
                                icon={deleteIcon}
                                type="button"
                                disabled={disableFields}
                                onClick={(event) => handleDeleteItem(event)} />    
                        </div>                
                    </TooltipWrapper>
                }
            </div>
        )
    };

    const renderNlpEntityDisplayItem = () => {

        return (
            <div className={`${itemContainer} ${isActive ? 'active' : ''}`}
                onKeyUp={handleKeyUp} >
                <div className={`${itemHeader} ${isActive ? 'active' : ''}`}
                    key={`menuItem-${props.nlpValueIndex}`}
                    onClick={() => handleItemToggleActivation(props.nlpValueIndex)}
                >
                    {renderItemName()}
                    {renderItemActions()}
                </div>
                {isActive &&
                    <NlpEntityValueCard
                        {...props}
                        languages={props.languages}
                        nlpEntity={props.nlpEntity}
                        key={props.nlpValueIndex}
                        formPrefix={props.formPrefix}
                        formIndex={props.formIndex}
                        nlpValue={props.nlpValue}
                        nlpValueIndex={props.nlpValueIndex}
                    />
                }
                {showMetadataModal && 
                    <EntityMetadataModal
                    metadata={props.nlpValue.metadata}
                    handleUpdate={(metadata) => {
                        if (metadata !== props.nlpValue.metadata) {
                            props.setFieldValue(`${props.formPrefix}.${props.formIndex}.isModified`, true)
                            props.setFieldValue(`${props.formPrefix}.${props.formIndex}.values.${props.nlpValueIndex}.metadata`, metadata)                            
                        }
                    }}
                    handleClose={() => { setShowMetadataModal(false) }} />
                }
            </div>
        )
    };

    return (
        renderNlpEntityDisplayItem()
    );

};

export default NlpEntityValueItem;

const itemContainer = css`

    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    padding: 0 16px 0 16px;

    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
        min-height: 56px;
        height: auto;
    }
    
`;

const itemHeader = css`
    height: 56px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const itemTitle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    color: ${color_text_default};
    position:relative;
`;

const itemActions = css`
    display: flex;
`;
const MetadataButton = styled.div`
    margin-right: 8px;
    margin-top: 12px;
`;
