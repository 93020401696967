import { Container } from "unstated";
import ApplicationBackupVersionModel from "../../models/applications/api/backups/ApplicationBackupVersionModel";
import * as voicifyApi from "../../api";
import CreateApplicationBackupVersionRequest from "../../models/applications/api/backups/CreateApplicationBackupVersionRequest";
import CreatePartialApplicationBackupVersionRequest from "../../models/applications/api/backups/CreatePartialApplicationBackupVersionRequest";
import UpdateApplicationBackupVersionRequest from "../../models/applications/api/backups/UpdateApplicationBackupVersionRequest";
import IResult from "../../models/result/IResult";
interface ApplicationBackupVersionsContainerState {
    backupVersions: ApplicationBackupVersionModel[];
    pollingIds: string[]
    errors: string[]
    isLoading: boolean
    isCreating: boolean
    isDeleting: boolean
}

export default class ApplicationBackupVersionsContainer extends Container<ApplicationBackupVersionsContainerState> {
    public state: ApplicationBackupVersionsContainerState = {
        backupVersions: [],
        errors: [],
        isLoading: false,
        isCreating: false,
        isDeleting: false,
        pollingIds: []
    };

    async loadBackupVersions(applicationId: string) {
        try {
            await this.setState({ ...this.state, isLoading: true });
            const result = await voicifyApi.getApplicationBackupVersions(applicationId);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    backupVersions: result.data,
                    isLoading: false,
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isLoading: false });
        }
    }

    async queueCreateFullBackup(applicationId: string, model: CreateApplicationBackupVersionRequest) {
        try {
            await this.setState({ ...this.state, isCreating: true });
            const result = await voicifyApi.queueCreateNewBackupVersion(applicationId, model);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    backupVersions: [result.data, ...this.state.backupVersions],
                    isCreating: false,
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isCreating: false
                });
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isCreating: false });
        }
    }

    async queueCreatePartialBackup(applicationId: string, model: CreatePartialApplicationBackupVersionRequest) {
        try {
            await this.setState({ ...this.state, isCreating: true });
            const result = await voicifyApi.queueCreateNewPartialBackupVersion(applicationId, model);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    backupVersions: [result.data, ...this.state.backupVersions],
                    isCreating: false,
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isCreating: false
                });
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isCreating: false });
        }
    }

    async updateApplicationBackupVersion(applicationVersionId: string, model: UpdateApplicationBackupVersionRequest) {
        await this.setState({
            ...this.state,
            backupVersions: this.state.backupVersions.map(b => b.id == applicationVersionId ? { ...b, name: model.name } : b),
        })
        const result = await voicifyApi.updateApplicationBackupVersion(applicationVersionId, model);
        if (result.resultType == "Ok") {
            await this.setState({
                ...this.state,
                backupVersions: this.state.backupVersions.map(b => b.id == applicationVersionId ? result.data : b),
                errors: []
            });
        } else {
            await this.setState({
                ...this.state,
                errors: result.errors
            });
        }
        return result;
    }

    async reloadBackupVersion(applicationVersionId: string) {
        try {
            const result = await voicifyApi.findBackupVersionById(applicationVersionId);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    backupVersions: this.state.backupVersions.map(b => b.id == applicationVersionId ? result.data : b),
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors
                });
            }
            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()] });
        }
    }

    async deleteBackupVersion(applicationVersionId: string) {
        await this.setState({ ...this.state, isDeleting: true });
        try {
            const result = await voicifyApi.disableApplicationBackupVersion(applicationVersionId);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    backupVersions: this.state.backupVersions.filter(b => b.id != applicationVersionId),
                    errors: [],
                    isDeleting: false
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isDeleting: false
                });
            }
            return result;
        } catch (error) {
            await this.setState({
                ...this.state, errors: [error.toString()],
                isDeleting: false
            });
        }
    }

    async downloadArchive(applicationArchiveId: string) {
        try {
            const result = await voicifyApi.getArchive(applicationArchiveId);
            if (result.resultType == "Ok") {
                window.open(result.data.fileUrl, '_blank').focus();;
            }
            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()] });
        }
    }

    async clearErrors() {
        await this.setState({ ...this.state, errors: [] });
    }    

    async addBackup(backup: ApplicationBackupVersionModel) {
        await this.setState({
            ...this.state,
            backupVersions: [backup, ...this.state.backupVersions]
        });
    }
    
    async waitForBackup(backupId: string) : Promise<IResult<any>> {
        try {
            let checking = true;
            let checkCount = 0;
            if(this.state.pollingIds.some(s => s == backupId))
                return;
            else {
                await this.setState({
                   ...this.state,
                   pollingIds: [...this.state.pollingIds, backupId] 
                });
            }
            // 450 loops, 2 second pause per loop = ~ 15 minutes of polling (which matches lambda timeout)
            while (checking && checkCount < 450) { 
                const result = await voicifyApi.findBackupVersionById(backupId);
                checkCount++;
                if (result.resultType == "Ok") {
                    const backup = result.data;
                    await this.setState({
                        ...this.state, 
                        backupVersions: this.state.backupVersions.map(b => b.id == backup.id ? backup : b)
                    })

                    switch (backup?.applicationArchive?.stage) {
                        case ("Processing"):
                        case ("Queued"):
                            await new Promise(r => setTimeout(r, 2000));
                            break;
                        case ("Complete"):
                            if (backup?.applicationArchive?.error) {
                                await this.setState({
                                    ...this.state,
                                    isLoading: false,
                                    pollingIds: this.state.pollingIds.filter(i => i != backupId),
                                    errors: [backup?.applicationArchive?.error],
                                });
                                return {
                                    data: null,
                                    resultType: "Invalid",
                                    errors: [backup?.applicationArchive?.stage],
                                }
                            } else {
                                await this.setState({
                                    ...this.state,
                                    isLoading: false,
                                    pollingIds: this.state.pollingIds.filter(i => i != backupId),
                                    errors: [],
                                });
                                return result;
                            }
                            break;
                        default:
                            await this.setState({
                                ...this.state,
                                isLoading: false,
                                pollingIds: this.state.pollingIds.filter(i => i != backupId),
                                errors: ["Unknown issue with backup"],
                            });
                            return {
                                data: null,
                                resultType: "Invalid",
                                errors: ["Unknown issue with backup"],
                            }
                    }
                }
                else {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        errors: result.errors
                    })
                }
            }
            // we've timed out.
            await this.setState({
                ...this.state,
                isLoading: false,
                pollingIds: this.state.pollingIds.filter(i => i != backupId),
                errors: ["Backup timed out"],
            });
        }
        catch (e) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            })
        }
    }    
}