import React from "react";
import { Provider, Subscribe } from 'unstated';
import MemberContainer from "../../state/containers/MemberContainer";
import ApplicationMemberListView from "./components/ApplicationMemberListView";
import { RouteComponentProps } from "react-router";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import PageContainer from "../../components/structure/PageContainer";
import TabPanelBody from "../../components/structure/TabPanelBody";
import ApplicationContainer from "../../state/containers/ApplicationContainer";
import UserContainer from "../../state/containers/UserContainer";
import ApplicationMembersContainer from "../../state/containers/ApplicationMembersContainer";

interface ApplicationMembersProps {
    history: any
    applicationId: string
}
class ApplicationMembers extends React.Component<ApplicationMembersProps> {
    render() {
        return (
            <Subscribe to={[MemberContainer, OrganizationContainer, ApplicationContainer, UserContainer, ApplicationMembersContainer]}>
                {(memberContainer : MemberContainer, 
                orgContainer : OrganizationContainer, 
                appContainer : ApplicationContainer, 
                userContainer: UserContainer,
                appMembersContainer: ApplicationMembersContainer) => {

                    return (
                        <PageContainer>
                            <ApplicationMemberListView history={this.props.history} 
                            memberContainer={memberContainer} 
                            organizationId={orgContainer.state.currentOrganization.id} 
                            applicationId={this.props.applicationId}
                            appContainer={appContainer} 
                            userContainer={userContainer}
                            applicationMembersContainer={appMembersContainer}
                            organizationContainer={orgContainer}/>
                        </PageContainer>
                    );
                }}
            </Subscribe>
        );
    }
};

export default ApplicationMembers;
