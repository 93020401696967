import React from 'react';
import { css } from '@emotion/css';
import LanguageModel from '../../../../models/features/api/LanguageModel';
import CheckIndicator from '../../../../components/forms/CheckIndicator';
import { getRegionyNameForLanguage, getLanguageNameForLanguage, getFlagIconForLanguage } from '../../../../models/extensions/languages';
import { silver_four, cool_grey, color_shades_darkest } from '../../../../constants/colors';
const alexaIcon = require('../../../../content/images/platforms/alexa-icon.svg');
const cortanaIcon = require('../../../../content/images/cortana-icon.svg');
const googleIcon = require('../../../../content/images/platforms/google-assistant-icon.svg');

interface LanguageCardProps {
    language: LanguageModel
    isSelected: boolean
    onChange: (language: LanguageModel, isSelected: boolean) => void
}

const renderPlatformIcons = (platforms: string) => {
    const icons = []
    if (platforms == '*' || platforms.toLowerCase().indexOf('alexa') > -1)
        icons.push(<img key="alexa" src={alexaIcon} />)
    if (platforms == '*' || platforms.toLowerCase().indexOf('googleassistant') > -1)
        icons.push(<img key="google" src={googleIcon} />)
    if (platforms == '*' || platforms.toLowerCase().indexOf('cortana') > -1)
        icons.push(<img key="cortana" src={cortanaIcon} />)
    return (<>
        {icons}
    </>)
}

const LanguageCard: React.SFC<LanguageCardProps> = (props) => (
    <div className={containerStyle} onClick={() => props.onChange(props.language, !props.isSelected)}>
        <div className="inner-container">
            <CheckIndicator checked={props.isSelected} />
            <img className="flag-icon" src={getFlagIconForLanguage(props.language)} />
            <p className="language">{`${getLanguageNameForLanguage(props.language)} (${getRegionyNameForLanguage(props.language)})`}</p>
            <div className="platform-support">
                {renderPlatformIcons(props.language.supportedPlatforms)}
            </div>
        </div>
        <p className="support-label">SUPPORT:</p>
    </div>
);

const containerStyle = css`
    height: 77px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(48, 69, 98, 0.1);
    border: 1px solid ${silver_four};
    background: white;
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    padding: 0 16px;
    margin: 16px;
    width: calc(50% - 66px);
    cursor: pointer;
    .support-label {
        top: 8px; 
        right: 16px;
        position: absolute;
        font-family: Muli;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8e97a1;
    }
    >.inner-container {
        display: flex;
        align-items: center;
        flex: 1;
        .flag-icon {            
            border: 1px solid ${cool_grey};
            border-radius: 50%;
        }
        >.language {
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #304562;
            margin: 0 16px;
        }

        >.platform-support {
            margin-right: 0;
            margin-left: auto;
            border-left: 1px solid ${silver_four};
            display: flex;
            align-items: center;
            padding-left: 8px;
            img {
                margin: 0 4px;
            }
        }
    }
`


export default LanguageCard;