import React from 'react';
import {css} from '@emotion/css';
import { color_shades_lightest, color_text_light, color_shades_dark } from '../../constants/colors';
const imageIcon = require('../../content/images/picture_icon.svg');
const backgroundIcon = require('../../content/images/background_icon.svg');
const audioIcon = require('../../content/images/audio_icon.svg');
const videoIcon = require('../../content/images/video_icon.svg');
const attachIcon = require('../../content/images/attachment-light.svg');

interface MediaIndicatorProps {
    hasAudio: boolean
    hasImage: boolean
    hasVideo: boolean
    hasBackground: boolean
}

const isEmpty = (props: MediaIndicatorProps) => {
    if(props.hasAudio) return false;
    if(props.hasVideo) return false;
    if(props.hasBackground) return false;
    if(props.hasImage) return false;
    return true;
}

const MediaIndicatorChip : React.SFC<MediaIndicatorProps> = (props) => (
    <div className={indicatorStyle}>
        <div className="group-section-header">
            <p>Media</p>
            <img src={attachIcon}/>
        </div>
        { props.hasAudio && <img src={audioIcon}/> }
        { props.hasImage && <img src={imageIcon}/> }
        { props.hasVideo && <img src={videoIcon}/> }
        { props.hasBackground && <img src={backgroundIcon}/> }
        { isEmpty(props) && <p className="empty-label">None</p>}
    </div>
);
const indicatorStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid ${color_shades_dark};
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 8px;

    .group-section-header {
        border-right: 1px solid ${color_shades_dark};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        padding-right: 8px;
        
        img {
            width: 16px;
            height: 16px;
        }
        p {
            color: ${color_text_light};
            font-size: 12px;
            line-height: 16px;
        }
    }
    >img {
        width: 22px;
        height: 22px;
        margin: 0 6px;
    }
    .empty-label {
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${color_text_light};
    }
`

export default MediaIndicatorChip;