import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { FormikProps, FieldArray, ArrayHelpers } from "formik";
import React, { useState } from "react";
import Button from "../../../components/general/Button";
import PageError from "../../../components/general/PageError";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import { color_shades_dark } from "../../../constants/colors";
import { LARGE_ENTITY_VALUES_COUNT } from "../../../hooks/ApplicationNlpEntityContainer";
import useWindowSize from "../../../hooks/UseWindowSize";
import LanguageModel from "../../../models/features/api/LanguageModel";
import NlpEntityModel from "../../../models/nlpEntity/api/NlpEntityModel";
import { NlpEntityType } from "../../../models/nlpEntity/api/NlpEntityType";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import AddNewNlpEntityModal from "./AddNewNlpEntityModal";
import ApplicationNlpEntityCard from "./ApplicationNlpEntityCard";
import ApplicationNlpEntityItem from "./ApplicationNlpEntityItem";
import { InteractionModelFormData } from "./InteractionModelForm";
import EmptyView from '../../featureContent/components/EmptyView';
import SearchField from "../../../components/forms/SearchField";

export const NLP_FORM_PREFIX: string = "nlpEntities";
export type NlpEntityCreateMethod = "Custom" | "Prebuilt";

interface ApplicationNlpEntitiesEditorProps {
    isLoading: boolean;
    applicationId: string;
    nlpEntities: EditableNlpEntity[];
    prebuiltNlpEntities: NlpEntityModel[];
    languages?: LanguageModel[];
    allAppLanguages?: LanguageModel[];
    status?: any;
};

const ApplicationNlpEntitiesEditor: React.FC<ApplicationNlpEntitiesEditorProps & FormikProps<InteractionModelFormData>> = (props) => {

    const [activeItemIndex, setActiveItemIndex] = useState(-1);
    const [isAddNewNlpEntityModalOpen, setIsAddNewNlpEntityModalOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState([] as string[]);
    const windowSize = useWindowSize();
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const toggleItemActivationByIndex = (index: number) => {
        setActiveItemIndex(index);
    };

    const toggleAddNewNlpEntityModal = () => {
        setIsAddNewNlpEntityModalOpen(!isAddNewNlpEntityModalOpen);
    };

    const copyNlpEntity = (copyFrom: EditableNlpEntity): EditableNlpEntity => {

        // For front end purpose only, backend will override it
        const today: string = new Date().toUTCString();

        const newNlpEntity: EditableNlpEntity = {
            ...copyFrom,
            id: "",
            applicationId: props.applicationId,
            name: "Copy - " + copyFrom.name,
            modifiedDate: today,
            isAdded: true,
            isModified: true,
            isDeleted: false
        };

        return newNlpEntity;
    };

    const handleCopyNlpEntityByIndex = (index: number, arrayHelpers: ArrayHelpers) => {

        const copyFrom: EditableNlpEntity = props.nlpEntities[index];

        if (copyFrom) {
            const newNlpEntity: EditableNlpEntity = copyNlpEntity(copyFrom);

            arrayHelpers.push(newNlpEntity);
            toggleItemActivationByIndex(props.nlpEntities.length);
        }

    };


    const getPrebuiltNlpEntityById = (entityId: string): NlpEntityModel => {
        const filtered: NlpEntityModel[] = props.prebuiltNlpEntities?.filter(e => e.id === entityId);
        if (filtered?.length == 1) {
            return filtered[0];
        }
        return null;
    };

    const copyNlpEntityFromPrebuilt = (prebuiltEntityId: string): EditableNlpEntity => {

        const prebuiltNlpEntity: NlpEntityModel = getPrebuiltNlpEntityById(prebuiltEntityId);
        if (!prebuiltNlpEntity) {
            setErrorMessages(["Unable to find Prebuilt NLP Entity to copy from"]);
            return null;
        }

        // If Prebuilt entity is not Copyable and we already have it - do not add it again
        // validation to not be able to add non-copyable prebuilt entiteis multiple times
        if (!prebuiltNlpEntity.isCopyable) {
            const found = props.nlpEntities?.find(e => e.prebuiltEntityId && e.prebuiltEntityId == prebuiltNlpEntity.id);

            if (found) {
                setErrorMessages([`Unable to add ${found.type} Prebuilt NLP Entity "${found?.name}", it was already added to your application. `]);
                return null;
            }
        }
        // For front end purpose only, backend will override it
        const today: string = new Date().toUTCString();

        const values = prebuiltNlpEntity.values ? [...prebuiltNlpEntity?.values] : [];
        const numberOfvalues: number = prebuiltNlpEntity.values?.length || 0;
        const isLarge: boolean = numberOfvalues > LARGE_ENTITY_VALUES_COUNT;

        const newNlpEntity: EditableNlpEntity = {
            ...prebuiltNlpEntity,
            id: "",
            applicationId: props.applicationId,
            values,
            modifiedDate: today,
            isAdded: true,
            isModified: true,
            isDeleted: false,
            isLarge,
            prebuiltEntityId: prebuiltNlpEntity.id
        };

        return newNlpEntity;
    };

    const addApplicationNlpEntity = (source: NlpEntityCreateMethod, key: string) => {
        // Close modal 
        setIsAddNewNlpEntityModalOpen(false);
        if (!source || !key) {
            return;
        }

        const defaultLocales = props.languages?.map(l => l.shortCode);
        // For front end purpose only, backend will override it
        const today: string = new Date().toUTCString();

        let customEntityType: NlpEntityType = "List";
        // Nlp Entity Type is required and is not user editable
        // For new Custom Nlp we have two choices either List or Regex
        if (source === "Custom" && key === "Regex") {
            customEntityType = "Regex";
        }

        // Default new Entity to Custom selection
        let newNlpEntity: EditableNlpEntity = {
            id: "",
            applicationId: props.applicationId,
            type: customEntityType,
            name: "New " + source + " " + key,
            values: [],
            locales: defaultLocales,
            modifiedDate: today,
            isAdded: true,
            isModified: true,
            isDeleted: false,
            isEditable: true,
            isCopyable: true,
            isEnabled: true,
            isLarge: false
        };

        if (source === "Prebuilt") {
            // Override new Entity with Prebuilt selection key=entityId
            newNlpEntity = copyNlpEntityFromPrebuilt(key);
            if (newNlpEntity == null) {
                return;
            }
        }

        const idx: number = props.nlpEntities?.length ?? 0;
        const fieldName = `${NLP_FORM_PREFIX}.${idx}`;
        props.setFieldValue(fieldName, newNlpEntity);

        toggleItemActivationByIndex(idx);
    };

    const renderActiveNlpEntityEdit = () => {
        const nlpEntity: EditableNlpEntity = props.nlpEntities[activeItemIndex];
        return (
            <div className={editActiveItem}>
                {nlpEntity ?
                    <ApplicationNlpEntityCard
                        {...props}
                        setErrors={props.setErrors}
                        languages={props.allAppLanguages}
                        nlpEntities={props.nlpEntities}
                        nlpEntity={nlpEntity}
                        formPrefix={NLP_FORM_PREFIX}
                        formIndex={activeItemIndex}
                    />
                    :
                    <EmptyViewText>
                        Select an entity to see more details and manage values
                    </EmptyViewText>
                }
            </div>

        )
    };

    const renderNlpEntity = (nlpEntity: EditableNlpEntity, index: number, arrayHelpers: ArrayHelpers) => {
        const isActive: boolean = index === activeItemIndex;
        const isInLanguages: boolean = props.languages?.map(lang => lang.shortCode)?.some(locale => nlpEntity.locales?.includes(locale));
        //Only render entities in selected languages
        return (isInLanguages &&
            <ApplicationNlpEntityItem
                {...props}
                toggleItemActivationByIndex={toggleItemActivationByIndex}
                handleCopyNlpEntity={(idx) => handleCopyNlpEntityByIndex(idx, arrayHelpers)}
                applicationId={props.applicationId}
                languages={props.languages}
                nlpEntity={nlpEntity}
                isActive={isActive}
                key={index}
                formPrefix={NLP_FORM_PREFIX}
                formIndex={index}
            />
        )
    };

    const renderNlpEntityItemsList = () => {
        if (!props.nlpEntities?.length && !props.isLoading) {
            return <EmptyViewPadding><EmptyView subtext="Try adding a new Entity" /></EmptyViewPadding>
        }
        return (
            <>
                <SearchField
                    id="searchField1"
                    name={"searchField"}
                    placeholder={"Find this"}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    boxType={"square"}
                    className={searchPadding}
                />
                {// Newly added entities on the top
                    props.nlpEntities?.some(v => v.isAdded) &&
                    <FieldArray validateOnChange={true}
                        name={NLP_FORM_PREFIX}
                        render={arrayHelpers => (
                            props.nlpEntities?.filter(entity => entity?.name?.toLowerCase().includes(searchTerm.toLowerCase()))?.map((entity, index) => {
                                if (!entity.isAdded) return null;
                                return renderNlpEntity(entity, index, arrayHelpers);
                            })

                        )}
                    />
                }
                <FieldArray validateOnChange={true}
                    name={NLP_FORM_PREFIX}
                    render={arrayHelpers => (
                        props.nlpEntities?.filter(entity => entity?.name?.toLowerCase().includes(searchTerm.toLowerCase()))?.map((entity, index) => {
                            if (entity.isAdded) return null;
                            return renderNlpEntity(entity, props.nlpEntities.indexOf(entity), arrayHelpers);
                        })
                    )}
                />
            </>
        )

    };

    const renderNlpEntitiesItemsPanel = () => {
        return (
            <div className={menuItemsContainer}>
                <div className={menuItemsContainerHeader}>
                    <div>NLP Entities </div>
                    <Button
                        themes={["primary-small", "start"]}
                        disabled={props.isLoading}
                        loading={props.isLoading}
                        onClick={toggleAddNewNlpEntityModal}
                        text="Add an entity" />
                </div>
                <HorizontalSeparator />
                {props.nlpEntities &&
                    renderNlpEntityItemsList()
                }
            </div >
        )
    };

    return (
        <div className={containerStyle}>
            <div className={twoSectionsContainer}>
                <CustomScrollbars autoHide autoHeight autoHeightMin={windowSize.windowSize.innerHeight - 210} autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                    {renderNlpEntitiesItemsPanel()}
                </CustomScrollbars>
                <BorderLeftCustomScrollbars autoHide autoHeight autoHeightMin={windowSize.windowSize.innerHeight - 210} autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                    {renderActiveNlpEntityEdit()}
                </BorderLeftCustomScrollbars>
            </div>
            {isAddNewNlpEntityModalOpen &&
                <AddNewNlpEntityModal
                    handleSubmit={addApplicationNlpEntity}
                    handleClose={toggleAddNewNlpEntityModal}
                    isLoading={props.isLoading}
                />
            }
            <PageError errors={[
                ...errorMessages ?? []
            ]} onClear={() => {
                setErrorMessages([]);
            }} />
        </div>
    );
};

export default ApplicationNlpEntitiesEditor;

const BorderLeftCustomScrollbars = styled(CustomScrollbars)`
    border-left: 1px solid ${color_shades_dark};
`;
const containerStyle = css`
    height: auto;
`;

const twoSectionsContainer = css`
    height: 100%;
    display: flex;
`;

const menuItemsContainer = css`
    position: relative;
    height: auto;
    padding: 0 32px 16px 32px;
`;

const menuItemsContainerHeader = css`
    display: flex;
    justify-content: space-between; 
    align-items: center;
`;

const editActiveItem = css`
    position: relative;
    height: auto;
    padding: 16px 32px 16px 32px;
    overflow: hidden;
`;

const customLoaderStyle = css`
    position: relative;
    top: auto;
    margin-top: 30px;
    margin-bottom: 30px;    
`;

const EmptyViewText = styled.div`
    background: white;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    color: ${color_shades_dark};
    padding: 24px;
`;

const EmptyViewPadding = styled.div`
    margin-top: 24px;
    width: 100%;
`;

const searchPadding = css`
    padding-top: 8px;
`;