import React from 'react';
import { css } from '@emotion/css';
import TabContainerPanel from '../../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import NestedTree, { TreeItem } from '../../../components/structure/NestedTree/NestedTree';
import WebhookFolderContainer from '../../../state/containers/WebhookFolderContainer';
import { color_shades_dark, color_text_default } from '../../../constants/colors';
import WebhookContainer from '../../../state/containers/WebhookContainer';
import MenuCollapseToggle from '../../../components/structure/MenuCollapseToggle';
const contentTreeIcon = require("../../../content/images/tab-icons/content-tree.svg");
const contentTreeBlueIcon = require("../../../content/images/tab-icons/content-tree-blue.svg");

interface ExpandedWebhookSidePanelProps {
    history: any
    webhookFolderContainer: WebhookFolderContainer
    onCollapse: () => void
    webhookContainer: WebhookContainer
    applicationId: string
    showCollapseButton: boolean
}
class ExpandedWebhookSidePanel extends React.Component<ExpandedWebhookSidePanelProps> {
    handleTreeItemClick(item: TreeItem) {
        if (item.type == "feature") {
            const expandedIds = this.props.webhookFolderContainer.state.expandedIds;
            if (expandedIds.some(id => id == item.id)) {
                expandedIds.splice(expandedIds.indexOf(item.id), 1);
            }
            else {
                expandedIds.push(item.id);
            }
            this.props.webhookFolderContainer.setExpandedIds(expandedIds);
            this.props.webhookFolderContainer.buildTree(this.props.applicationId, this.props.webhookContainer.state.webhooks)
        }
    }
    render() {
        return (
            <div className={containerStyle}>
                {this.props.showCollapseButton && <MenuCollapseToggle isMinified={false} onToggle={this.props.onCollapse} />}
                <TabContainerPanel tabs={[{
                    title: 'Explorer',
                    icon: contentTreeIcon,
                    selectedIcon: contentTreeBlueIcon
                }, 
                ]} selectedIndex={0}>
                    <TabPanel>
                        <div className="expanded-content-tree-container">                            
                            <div className={scrollContainer}>
                                <CustomScrollbars autoHide className="tree-scroll">
                                    <div className="tree-container">
                                        <NestedTree
                                            emptyText={"No Webhooks Found Here"}
                                            isRootHidden={true}
                                            key={this.props.webhookFolderContainer.state.tree?.id ?? 'r'}
                                            root={this.props.webhookFolderContainer.state.tree}
                                            onItemClick={this.handleTreeItemClick.bind(this)} />                                
                                    </div>
                                </CustomScrollbars>
                            </div>
                        </div>
                    </TabPanel>
                </TabContainerPanel>
            </div>
        )
    }
}

const containerStyle = css`
    .panel-header {
        max-width: 288px;
    }
    .header-container {
        position: relative;
        width: 288px;
        display: flex;
        align-items: center;
    }

`

const scrollContainer = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    .selector-section-header {
        align-items: center;
        padding: 0px 16px 0 24px;
        background: white;
        height: 56px;
        min-height: 56px;
        max-height: 56px;
        border-bottom: 1px solid ${color_shades_dark};
        p {
            font-size: 18px;
            line-height: 24px;
            padding-top: 16px;
            color: ${color_text_default};
        }
    }
    .tree-container {
        padding-bottom: 16px;
        max-height: 100%;
        flex: 1;
    } 
`

export default ExpandedWebhookSidePanel;



