import React from 'react';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
export default abstract class NavigationBarFormPage<TProps extends RouteComponentProps<any>, TState extends ConversationNavigationState> extends React.Component<TProps, TState> {
    handleToggleNavigation() {
        localStorage.setItem('isNavigationBarOpen', '' + !this.state.isNavigationBarOpen);
        this.setState({
            ...this.state,
            isNavigationBarOpen: !this.state.isNavigationBarOpen
        })
    }
    getPreviousContentId() : string{
        const params = queryString?.parse(this.props.location.search);
        if(!params || !params.previousContentId) return null;

        return params.previousContentId as string;
    }
    setSaving(isSaving: boolean) {
        this.setState({
            ...this.state,
            isSaving
        })
    }
}