import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import CheckIndicator from '../../../components/forms/CheckIndicator';
import { Link } from 'react-router-dom';
import { getEditUrl, getFeatureTypeIcon, getDefaultFeatureTypeName } from '../../../models/extensions';
import ApplicationFeatureModel from '../../../models/features/api/ApplicationFeatureModel';
import LanguageCollectionAvatar from '../../../components/general/LanguageCollectionAvatar';
import { color_defaults_white, color_shades_light, color_shades_dark, color_shades_darker, color_shades_darkest, color_shades_lighter } from '../../../constants/colors';
import MediaIndicatorChip from '../../../components/general/MediaIndicatorChip';
import moment from 'moment';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import WebhookCountTag from '../../../components/general/WebhookCountTag';
import ContentItemListStatus from '../../../components/general/ContentItemListStatus';
import GenericContentItemPublishModel from '../../../models/features/api/GenericContentItemPublishModel';
import styled from '@emotion/styled';

const noAccessImage = require('../../../content/images/conversation-access/no-access.svg');
const targetedAccessImage = require('../../../content/images/conversation-access/targeted-access.svg');
const onHoverIcon = require('../../../content/images/moveable-icon.svg');

interface ContentListItemProps {
    contentItem: GenericContentItemModel
    statusDropDownDisabled?: boolean
    applicationFeature: ApplicationFeatureModel
    featureContainer: FeatureContainer
    onSelectionChange: (contentItem: GenericContentItemModel, isSelected: boolean) => void
    onManageWebhooks?: (contentItem: GenericContentItemModel) => void
    handlePublish?: () => void
    handleUnpublish?: () => void
    isSelected: boolean
    selectable: boolean
    isSingleSelect?: boolean
    disabled?: boolean
    withLink: boolean
    webhookInstanceCount?: number
    showStatus?: boolean
    isPublishLoaded?: boolean
    lastPublish?: GenericContentItemPublishModel
    index?: number
    className?: string
}

const ContentListItem = (props: ContentListItemProps) => {
    const renderStatus = () => {
        return <ContentItemListStatus dropDownDisabled={props.statusDropDownDisabled} isListView={true} handleUnpublish={props.handleUnpublish} handlePublish={props.handlePublish} loaded={props.isPublishLoaded} contentItem={props.contentItem} lastPublish={props.lastPublish} />
    }

    const c = props.contentItem;
    const showWebhookCount = (props.onManageWebhooks && (props.webhookInstanceCount ?? 0) > 0);

    const renderTitle = () => {
        return (
            <ContentItemTitle>
                {(c.requiresParent && c.isLive) && <img className="ac-access-image" src={targetedAccessImage} />}
                {!c.isLive && <img className="ac-access-image" src={noAccessImage} />}
                <p className={`section-detail ac-content-list-item-title ${((c.requiresParent && c.isLive) || !c.isLive) ? "with-image" : ""}`}>{c.title ?? "Untitled"}</p>
            </ContentItemTitle>
        )
    }
    return (
        <div className={`${containerStyle} ${props.disabled ? 'disabled' : ''} ${props.className ?? '' } ${props.index == 0 ? 'top' : ''}`}>
            <div className={`content-item ${props.index == 0 ? 'top' : ''}`} >
                <img className='content-item-hover-icon' src={onHoverIcon} />
                <CheckIndicator checkType={props.isSingleSelect ? 'radio' : 'check'}
                    checked={props.isSelected}
                    onClick={props.disabled || !props.selectable ? () => { } : () => props.onSelectionChange(c, !props.isSelected)}
                    disabled={props.disabled || !props.selectable} />
                <img className="content-item-icon" src={getFeatureTypeIcon(c.featureTypeId)} />
                {props.withLink ?
                    <Link key={c.id} to={getEditUrl(c.id, props.applicationFeature.id, c.featureTypeId)} className="content-item-title-container">
                        <p className="section-title ac-content-list-item-type">{getDefaultFeatureTypeName(c.featureTypeId)}</p>
                        {renderTitle()}
                    </Link>
                    : <div key={c.id} className="content-item-title-container">
                        <p className="section-title">{getDefaultFeatureTypeName(c.featureTypeId)}</p>
                        {renderTitle()}
                    </div>}
                <div className="content-details-container">
                    <div className="content-modified-container">
                        <p className="section-title">Last Modified</p>
                        <p className="section-detail">{moment(c.modifiedDate).format("MMM DD, YYYY")}</p>
                    </div>
                    <div className="content-hits-container">
                        <p className="section-title">Interactions</p>
                        <p className="section-detail">{c.hits}</p>
                    </div>
                    <div className="content-media-container ac-content-list-item-media">
                        <MediaIndicatorChip hasAudio={c.hasAudio} hasImage={c.hasBackgroundImage || c.hasForegroundImage} hasVideo={c.hasVideo} hasBackground={false} />
                    </div>
                </div>
                {showWebhookCount ?
                    <WebhookCountTag className="webhook-count" onClick={() => props.onManageWebhooks(c)} count={props.webhookInstanceCount} />
                    :
                    <span className="webhook-count-filler" />
                }
                <div className="content-item-languages-container">
                    <LanguageCollectionAvatar languages={c.languages} limit={3} />
                </div>
                {props.showStatus &&
                    <div className="content-item-status-container">
                        {renderStatus()}
                    </div>
                }
            </div>
        </div>
    )
}

const ContentItemTitle = styled.div`
    display: flex;
    flex-direction: row;
    img {
        margin: 2px 8px 0px 0px;
    }
    .with-image {
        margin-top: 2px;
    }
`;

const containerStyle = css` 
    &.draggable:hover .content-item-hover-icon {
        visibility: visible;
    }

    &.dragging {
        border: 1px solid ${color_shades_dark};
        .content-item-hover-icon {
            visibility: visible;
        }
    }

    &.disabled {
        .content-item {
            background: ${color_shades_lighter};
            cursor: not-allowed;
        }
    }

    height: 80px;
    padding: 0 16px;
    background: ${color_shades_light};
    box-sizing: border-box;
    
    &.top {
        border-top: 1px solid ${color_shades_dark};
    }
    border-bottom: 1px solid ${color_shades_dark};
    
    .webhook-count-filler {
        width: 45px;
    }

    .content-item {
        background: ${color_defaults_white};
        box-sizing: border-box;
        border-radius: 4px;
        border-left: 1px solid ${color_shades_dark};
        border-right: 1px solid ${color_shades_dark};
        display: flex;
        align-items: center;
        
    /* 
    right padding should always be 16px
    content-item-hover-icon takes 12px, so left padding should be 16 - 12 */
        padding: 0 16px 0 4px;
        height: 100%;
    }

    .content-item-icon {
        width: 48px;
        height: 48px;
        margin-right: 16px;
    }

    .content-item-hover-icon {
        width: 8px;
        height: 24px;
        margin-right: 4px;
        visibility: hidden;
    }

    .section-title {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${color_shades_darker};
    }

    .section-detail {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
    }

    .content-item-title-container {
        flex: 1;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25vw;
        }
    }

    .content-details-container {
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-left: auto;
        justify-content: flex-start;
        flex: 1;
    }

    .content-modified-container, .content-hits-container, .content-media-container {
        padding: 0 16px;
    }

    .content-hits-container {
        width: 96px;
    }
    .content-modified-container{
        width: 124px;
    }

    .content-item-status-container {
        margin-right: 0;
        margin-left: 0;
        width: 124px;
    }

    .content-item-languages-container {
        margin-right: 32px;
        margin-left: 0;
        width: 100px;

        >div {
            justify-content: flex-end;
        }
    }

`

export default ContentListItem;