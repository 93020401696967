export const uITypeOptions = [
    { value: "minimal", label: "Minimal" },
    { value: "bottomRightButton", label: "Overlay" },
    { value: "slideFromBottom", label: "Slide-out" },
    { value: "slideFromRight", label: "Slide From Right" },
];

export const activeInputOptions = [
    { value: "textbox", label: "Textbox" },
    { value: "none", label: "None" },
];

export const textToSpeechProviderOptions = [
    { value: "Google", label: "Google" },
    { value: "Polly", label: "Polly" },
];

export const googleVoiceOptions = [
    { value: "female|af-ZA-Standard-A", label: "female|af-ZA-Standard-A" },
    { value: "female|ar-XA-Standard-A", label: "female|ar-XA-Standard-A" },
    { value: "male|ar-XA-Standard-B", label: "male|ar-XA-Standard-B" },
    { value: "male|ar-XA-Standard-C", label: "male|ar-XA-Standard-C" },
    { value: "female|ar-XA-Standard-D", label: "female|ar-XA-Standard-D" },
    { value: "female|ar-XA-Wavenet-A", label: "female|ar-XA-Wavenet-A" },
    { value: "male|ar-XA-Wavenet-B", label: "male|ar-XA-Wavenet-B" },
    { value: "male|ar-XA-Wavenet-C", label: "male|ar-XA-Wavenet-C" },
    { value: "female|ar-XA-Wavenet-D", label: "female|ar-XA-Wavenet-D" },
    { value: "female|bn-IN-Standard-A", label: "female|bn-IN-Standard-A" },
    { value: "male|bn-IN-Standard-B", label: "male|bn-IN-Standard-B" },
    { value: "female|bn-IN-Wavenet-A", label: "female|bn-IN-Wavenet-A" },
    { value: "male|bn-IN-Wavenet-B", label: "male|bn-IN-Wavenet-B" },
    { value: "female|bg-bg-Standard-A", label: "female|bg-bg-Standard-A" },
    { value: "female|ca-es-Standard-A", label: "female|ca-es-Standard-A" },
    { value: "female|yue-HK-Standard-A", label: "female|yue-HK-Standard-A" },
    { value: "male|yue-HK-Standard-B", label: "male|yue-HK-Standard-B" },
    { value: "female|yue-HK-Standard-C", label: "female|yue-HK-Standard-C" },
    { value: "male|yue-HK-Standard-D", label: "male|yue-HK-Standard-D" },
    { value: "female|cs-CZ-Standard-A", label: "female|cs-CZ-Standard-A" },
    { value: "female|cs-CZ-Wavenet-A", label: "female|cs-CZ-Wavenet-A" },
    { value: "female|da-DK-Standard-A", label: "female|da-DK-Standard-A" },
    { value: "male|da-DK-Standard-C", label: "male|da-DK-Standard-C" },
    { value: "female|da-DK-Standard-D", label: "female|da-DK-Standard-D" },
    { value: "female|da-DK-Standard-E", label: "female|da-DK-Standard-E" },
    { value: "female|da-DK-Wavenet-A", label: "female|da-DK-Wavenet-A" },
    { value: "male|da-DK-Wavenet-C", label: "male|da-DK-Wavenet-C" },
    { value: "female|da-DK-Wavenet-D", label: "female|da-DK-Wavenet-D" },
    { value: "female|da-DK-Wavenet-E", label: "female|da-DK-Wavenet-E" },
    { value: "female|nl-BE-Standard-A", label: "female|nl-BE-Standard-A" },
    { value: "male|nl-BE-Standard-B", label: "male|nl-BE-Standard-B" },
    { value: "female|nl-BE-Wavenet-A", label: "female|nl-BE-Wavenet-A" },
    { value: "male|nl-BE-Wavenet-B", label: "male|nl-BE-Wavenet-B" },
    { value: "female|nl-NL-Standard-A", label: "female|nl-NL-Standard-A" },
    { value: "male|nl-NL-Standard-B", label: "male|nl-NL-Standard-B" },
    { value: "male|nl-NL-Standard-C", label: "male|nl-NL-Standard-C" },
    { value: "female|nl-NL-Standard-D", label: "female|nl-NL-Standard-D" },
    { value: "female|nl-NL-Standard-E", label: "female|nl-NL-Standard-E" },
    { value: "female|nl-NL-Wavenet-A", label: "female|nl-NL-Wavenet-A" },
    { value: "male|nl-NL-Wavenet-B", label: "male|nl-NL-Wavenet-B" },
    { value: "male|nl-NL-Wavenet-C", label: "male|nl-NL-Wavenet-C" },
    { value: "female|nl-NL-Wavenet-D", label: "female|nl-NL-Wavenet-D" },
    { value: "female|nl-NL-Wavenet-E", label: "female|nl-NL-Wavenet-E" },
    { value: "female|en-AU-Neural2-A", label: "female|en-AU-Neural2-A" },
    { value: "female|en-AU-Standard-A", label: "female|en-AU-Standard-A" },
    { value: "male|en-AU-Standard-B", label: "male|en-AU-Standard-B" },
    { value: "female|en-AU-Standard-C", label: "female|en-AU-Standard-C" },
    { value: "male|en-AU-Standard-D", label: "male|en-AU-Standard-D" },
    { value: "female|en-AU-Wavenet-A", label: "female|en-AU-Wavenet-A" },
    { value: "male|en-AU-Wavenet-B", label: "male|en-AU-Wavenet-B" },
    { value: "female|en-AU-Wavenet-C", label: "female|en-AU-Wavenet-C" },
    { value: "male|en-AU-Wavenet-D", label: "male|en-AU-Wavenet-D" },
    { value: "female|en-IN-Standard-A", label: "female|en-IN-Standard-A" },
    { value: "male|en-IN-Standard-B", label: "male|en-IN-Standard-B" },
    { value: "male|en-IN-Standard-C", label: "male|en-IN-Standard-C" },
    { value: "female|en-IN-Standard-D", label: "female|en-IN-Standard-D" },
    { value: "female|en-IN-Wavenet-A", label: "female|en-IN-Wavenet-A" },
    { value: "male|en-IN-Wavenet-B", label: "male|en-IN-Wavenet-B" },
    { value: "male|en-IN-Wavenet-C", label: "male|en-IN-Wavenet-C" },
    { value: "female|en-IN-Wavenet-D", label: "female|en-IN-Wavenet-D" },
    { value: "female|en-GB-Neural2-A", label: "female|en-GB-Neural2-A" },
    { value: "female|en-GB-Standard-A", label: "female|en-GB-Standard-A" },
    { value: "male|en-GB-Standard-B", label: "male|en-GB-Standard-B" },
    { value: "female|en-GB-Standard-C", label: "female|en-GB-Standard-C" },
    { value: "male|en-GB-Standard-D", label: "male|en-GB-Standard-D" },
    { value: "female|en-GB-Standard-F", label: "female|en-GB-Standard-F" },
    { value: "female|en-GB-Wavenet-A", label: "female|en-GB-Wavenet-A" },
    { value: "male|en-GB-Wavenet-B", label: "male|en-GB-Wavenet-B" },
    { value: "female|en-GB-Wavenet-C", label: "female|en-GB-Wavenet-C" },
    { value: "male|en-GB-Wavenet-D", label: "male|en-GB-Wavenet-D" },
    { value: "female|en-GB-Wavenet-F", label: "female|en-GB-Wavenet-F" },
    { value: "female|en-US-Neural2-A", label: "female|en-US-Neural2-A" },
    { value: "male|en-US-Standard-A", label: "male|en-US-Standard-A" },
    { value: "male|en-US-Standard-B", label: "male|en-US-Standard-B" },
    { value: "female|en-US-Standard-C", label: "female|en-US-Standard-C" },
    { value: "male|en-US-Standard-D", label: "male|en-US-Standard-D" },
    { value: "female|en-US-Standard-E", label: "female|en-US-Standard-E" },
    { value: "female|en-US-Standard-F", label: "female|en-US-Standard-F" },
    { value: "female|en-US-Standard-G", label: "female|en-US-Standard-G" },
    { value: "female|en-US-Standard-H", label: "female|en-US-Standard-H" },
    { value: "male|en-US-Standard-I", label: "male|en-US-Standard-I" },
    { value: "male|en-US-Standard-J", label: "male|en-US-Standard-J" },
    { value: "male|en-US-Wavenet-A", label: "male|en-US-Wavenet-A" },
    { value: "male|en-US-Wavenet-B", label: "male|en-US-Wavenet-B" },
    { value: "female|en-US-Wavenet-C", label: "female|en-US-Wavenet-C" },
    { value: "male|en-US-Wavenet-D", label: "male|en-US-Wavenet-D" },
    { value: "female|en-US-Wavenet-E", label: "female|en-US-Wavenet-E" },
    { value: "female|en-US-Wavenet-F", label: "female|en-US-Wavenet-F" },
    { value: "female|en-US-Wavenet-G", label: "female|en-US-Wavenet-G" },
    { value: "female|en-US-Wavenet-H", label: "female|en-US-Wavenet-H" },
    { value: "male|en-US-Wavenet-I", label: "male|en-US-Wavenet-I" },
    { value: "male|en-US-Wavenet-J", label: "male|en-US-Wavenet-J" },
    { value: "female|fil-PH-Standard-A", label: "female|fil-PH-Standard-A" },
    { value: "female|fil-PH-Standard-B", label: "female|fil-PH-Standard-B" },
    { value: "male|fil-PH-Standard-C", label: "male|fil-PH-Standard-C" },
    { value: "male|fil-PH-Standard-D", label: "male|fil-PH-Standard-D" },
    { value: "female|fil-PH-Wavenet-A", label: "female|fil-PH-Wavenet-A" },
    { value: "female|fil-PH-Wavenet-B", label: "female|fil-PH-Wavenet-B" },
    { value: "male|fil-PH-Wavenet-C", label: "male|fil-PH-Wavenet-C" },
    { value: "male|fil-PH-Wavenet-D", label: "male|fil-PH-Wavenet-D" },
    { value: "female|fi-FI-Standard-A", label: "female|fi-FI-Standard-A" },
    { value: "female|fi-FI-Wavenet-A", label: "female|fi-FI-Wavenet-A" },
    { value: "female|fr-CA-Standard-A", label: "female|fr-CA-Standard-A" },
    { value: "male|fr-CA-Standard-B", label: "male|fr-CA-Standard-B" },
    { value: "female|fr-CA-Standard-C", label: "female|fr-CA-Standard-C" },
    { value: "male|fr-CA-Standard-D", label: "male|fr-CA-Standard-D" },
    { value: "female|fr-CA-Wavenet-A", label: "female|fr-CA-Wavenet-A" },
    { value: "male|fr-CA-Wavenet-B", label: "male|fr-CA-Wavenet-B" },
    { value: "female|fr-CA-Wavenet-C", label: "female|fr-CA-Wavenet-C" },
    { value: "male|fr-CA-Wavenet-D", label: "male|fr-CA-Wavenet-D" },
    { value: "female|fr-FR-Standard-A", label: "female|fr-FR-Standard-A" },
    { value: "male|fr-FR-Standard-B", label: "male|fr-FR-Standard-B" },
    { value: "female|fr-FR-Standard-C", label: "female|fr-FR-Standard-C" },
    { value: "male|fr-FR-Standard-D", label: "male|fr-FR-Standard-D" },
    { value: "female|fr-FR-Standard-E", label: "female|fr-FR-Standard-E" },
    { value: "female|fr-FR-Wavenet-A", label: "female|fr-FR-Wavenet-A" },
    { value: "male|fr-FR-Wavenet-B", label: "male|fr-FR-Wavenet-B" },
    { value: "female|fr-FR-Wavenet-C", label: "female|fr-FR-Wavenet-C" },
    { value: "male|fr-FR-Wavenet-D", label: "male|fr-FR-Wavenet-D" },
    { value: "female|fr-FR-Wavenet-E", label: "female|fr-FR-Wavenet-E" },
    { value: "female|de-DE-Standard-A", label: "female|de-DE-Standard-A" },
    { value: "male|de-DE-Standard-B", label: "male|de-DE-Standard-B" },
    { value: "female|de-DE-Standard-C", label: "female|de-DE-Standard-C" },
    { value: "male|de-DE-Standard-D", label: "male|de-DE-Standard-D" },
    { value: "male|de-DE-Standard-E", label: "male|de-DE-Standard-E" },
    { value: "female|de-DE-Standard-F", label: "female|de-DE-Standard-F" },
    { value: "female|de-DE-Wavenet-A", label: "female|de-DE-Wavenet-A" },
    { value: "male|de-DE-Wavenet-B", label: "male|de-DE-Wavenet-B" },
    { value: "female|de-DE-Wavenet-C", label: "female|de-DE-Wavenet-C" },
    { value: "male|de-DE-Wavenet-D", label: "male|de-DE-Wavenet-D" },
    { value: "male|de-DE-Wavenet-E", label: "male|de-DE-Wavenet-E" },
    { value: "female|de-DE-Wavenet-F", label: "female|de-DE-Wavenet-F" },
    { value: "female|el-GR-Standard-A", label: "female|el-GR-Standard-A" },
    { value: "female|el-GR-Wavenet-A", label: "female|el-GR-Wavenet-A" },
    { value: "female|gu-IN-Standard-A", label: "female|gu-IN-Standard-A" },
    { value: "male|gu-IN-Standard-B", label: "male|gu-IN-Standard-B" },
    { value: "female|gu-IN-Wavenet-A", label: "female|gu-IN-Wavenet-A" },
    { value: "male|gu-IN-Wavenet-B", label: "male|gu-IN-Wavenet-B" },
    { value: "female|hi-IN-Standard-A", label: "female|hi-IN-Standard-A" },
    { value: "male|hi-IN-Standard-B", label: "male|hi-IN-Standard-B" },
    { value: "male|hi-IN-Standard-C", label: "male|hi-IN-Standard-C" },
    { value: "female|hi-IN-Standard-D", label: "female|hi-IN-Standard-D" },
    { value: "female|hi-IN-Wavenet-A", label: "female|hi-IN-Wavenet-A" },
    { value: "male|hi-IN-Wavenet-B", label: "male|hi-IN-Wavenet-B" },
    { value: "male|hi-IN-Wavenet-C", label: "male|hi-IN-Wavenet-C" },
    { value: "female|hi-IN-Wavenet-D", label: "female|hi-IN-Wavenet-D" },
    { value: "female|hu-HU-Standard-A", label: "female|hu-HU-Standard-A" },
    { value: "female|hu-HU-Wavenet-A", label: "female|hu-HU-Wavenet-A" },
    { value: "female|is-is-Standard-A", label: "female|is-is-Standard-A" },
    { value: "female|id-ID-Standard-A", label: "female|id-ID-Standard-A" },
    { value: "male|id-ID-Standard-B", label: "male|id-ID-Standard-B" },
    { value: "male|id-ID-Standard-C", label: "male|id-ID-Standard-C" },
    { value: "female|id-ID-Standard-D", label: "female|id-ID-Standard-D" },
    { value: "female|id-ID-Wavenet-A", label: "female|id-ID-Wavenet-A" },
    { value: "male|id-ID-Wavenet-B", label: "male|id-ID-Wavenet-B" },
    { value: "male|id-ID-Wavenet-C", label: "male|id-ID-Wavenet-C" },
    { value: "female|id-ID-Wavenet-D", label: "female|id-ID-Wavenet-D" },
    { value: "female|it-IT-Standard-A", label: "female|it-IT-Standard-A" },
    { value: "female|it-IT-Standard-B", label: "female|it-IT-Standard-B" },
    { value: "male|it-IT-Standard-C", label: "male|it-IT-Standard-C" },
    { value: "male|it-IT-Standard-D", label: "male|it-IT-Standard-D" },
    { value: "female|it-IT-Wavenet-A", label: "female|it-IT-Wavenet-A" },
    { value: "female|it-IT-Wavenet-B", label: "female|it-IT-Wavenet-B" },
    { value: "male|it-IT-Wavenet-C", label: "male|it-IT-Wavenet-C" },
    { value: "male|it-IT-Wavenet-D", label: "male|it-IT-Wavenet-D" },
    { value: "female|ja-JP-Standard-A", label: "female|ja-JP-Standard-A" },
    { value: "female|ja-JP-Standard-B", label: "female|ja-JP-Standard-B" },
    { value: "male|ja-JP-Standard-C", label: "male|ja-JP-Standard-C" },
    { value: "male|ja-JP-Standard-D", label: "male|ja-JP-Standard-D" },
    { value: "female|ja-JP-Wavenet-A", label: "female|ja-JP-Wavenet-A" },
    { value: "female|ja-JP-Wavenet-B", label: "female|ja-JP-Wavenet-B" },
    { value: "male|ja-JP-Wavenet-C", label: "male|ja-JP-Wavenet-C" },
    { value: "male|ja-JP-Wavenet-D", label: "male|ja-JP-Wavenet-D" },
    { value: "female|kn-IN-Standard-A", label: "female|kn-IN-Standard-A" },
    { value: "male|kn-IN-Standard-B", label: "male|kn-IN-Standard-B" },
    { value: "female|kn-IN-Wavenet-A", label: "female|kn-IN-Wavenet-A" },
    { value: "male|kn-IN-Wavenet-B", label: "male|kn-IN-Wavenet-B" },
    { value: "female|ko-KR-Standard-A", label: "female|ko-KR-Standard-A" },
    { value: "female|ko-KR-Standard-B", label: "female|ko-KR-Standard-B" },
    { value: "male|ko-KR-Standard-C", label: "male|ko-KR-Standard-C" },
    { value: "male|ko-KR-Standard-D", label: "male|ko-KR-Standard-D" },
    { value: "female|ko-KR-Wavenet-A", label: "female|ko-KR-Wavenet-A" },
    { value: "female|ko-KR-Wavenet-B", label: "female|ko-KR-Wavenet-B" },
    { value: "male|ko-KR-Wavenet-C", label: "male|ko-KR-Wavenet-C" },
    { value: "male|ko-KR-Wavenet-D", label: "male|ko-KR-Wavenet-D" },
    { value: "male|lv-lv-Standard-A", label: "male|lv-lv-Standard-A" },
    { value: "female|ms-MY-Standard-A", label: "female|ms-MY-Standard-A" },
    { value: "male|ms-MY-Standard-B", label: "male|ms-MY-Standard-B" },
    { value: "female|ms-MY-Standard-C", label: "female|ms-MY-Standard-C" },
    { value: "male|ms-MY-Standard-D", label: "male|ms-MY-Standard-D" },
    { value: "female|ms-MY-Wavenet-A", label: "female|ms-MY-Wavenet-A" },
    { value: "male|ms-MY-Wavenet-B", label: "male|ms-MY-Wavenet-B" },
    { value: "female|ms-MY-Wavenet-C", label: "female|ms-MY-Wavenet-C" },
    { value: "male|ms-MY-Wavenet-D", label: "male|ms-MY-Wavenet-D" },
    { value: "female|ml-IN-Standard-A", label: "female|ml-IN-Standard-A" },
    { value: "male|ml-IN-Standard-B", label: "male|ml-IN-Standard-B" },
    { value: "female|ml-IN-Wavenet-A", label: "female|ml-IN-Wavenet-A" },
    { value: "male|ml-IN-Wavenet-B", label: "male|ml-IN-Wavenet-B" },
    { value: "female|cmn-CN-Standard-A", label: "female|cmn-CN-Standard-A" },
    { value: "male|cmn-CN-Standard-B", label: "male|cmn-CN-Standard-B" },
    { value: "male|cmn-CN-Standard-C", label: "male|cmn-CN-Standard-C" },
    { value: "female|cmn-CN-Standard-D", label: "female|cmn-CN-Standard-D" },
    { value: "female|cmn-CN-Wavenet-A", label: "female|cmn-CN-Wavenet-A" },
    { value: "male|cmn-CN-Wavenet-B", label: "male|cmn-CN-Wavenet-B" },
    { value: "male|cmn-CN-Wavenet-C", label: "male|cmn-CN-Wavenet-C" },
    { value: "female|cmn-CN-Wavenet-D", label: "female|cmn-CN-Wavenet-D" },
    { value: "female|cmn-TW-Standard-A", label: "female|cmn-TW-Standard-A" },
    { value: "male|cmn-TW-Standard-B", label: "male|cmn-TW-Standard-B" },
    { value: "male|cmn-TW-Standard-C", label: "male|cmn-TW-Standard-C" },
    { value: "female|cmn-TW-Wavenet-A", label: "female|cmn-TW-Wavenet-A" },
    { value: "male|cmn-TW-Wavenet-B", label: "male|cmn-TW-Wavenet-B" },
    { value: "male|cmn-TW-Wavenet-C", label: "male|cmn-TW-Wavenet-C" },
    { value: "female|nb-NO-Standard-A", label: "female|nb-NO-Standard-A" },
    { value: "male|nb-NO-Standard-B", label: "male|nb-NO-Standard-B" },
    { value: "female|nb-NO-Standard-C", label: "female|nb-NO-Standard-C" },
    { value: "male|nb-NO-Standard-D", label: "male|nb-NO-Standard-D" },
    { value: "female|nb-NO-Wavenet-A", label: "female|nb-NO-Wavenet-A" },
    { value: "male|nb-NO-Wavenet-B", label: "male|nb-NO-Wavenet-B" },
    { value: "female|nb-NO-Wavenet-C", label: "female|nb-NO-Wavenet-C" },
    { value: "male|nb-NO-Wavenet-D", label: "male|nb-NO-Wavenet-D" },
    { value: "female|nb-no-Standard-E", label: "female|nb-no-Standard-E" },
    { value: "female|nb-no-Wavenet-E", label: "female|nb-no-Wavenet-E" },
    { value: "female|pl-PL-Standard-A", label: "female|pl-PL-Standard-A" },
    { value: "male|pl-PL-Standard-B", label: "male|pl-PL-Standard-B" },
    { value: "male|pl-PL-Standard-C", label: "male|pl-PL-Standard-C" },
    { value: "female|pl-PL-Standard-D", label: "female|pl-PL-Standard-D" },
    { value: "female|pl-PL-Standard-E", label: "female|pl-PL-Standard-E" },
    { value: "female|pl-PL-Wavenet-A", label: "female|pl-PL-Wavenet-A" },
    { value: "male|pl-PL-Wavenet-B", label: "male|pl-PL-Wavenet-B" },
    { value: "male|pl-PL-Wavenet-C", label: "male|pl-PL-Wavenet-C" },
    { value: "female|pl-PL-Wavenet-D", label: "female|pl-PL-Wavenet-D" },
    { value: "female|pl-PL-Wavenet-E", label: "female|pl-PL-Wavenet-E" },
    { value: "female|pt-BR-Standard-A", label: "female|pt-BR-Standard-A" },
    { value: "male|pt-BR-Standard-B", label: "male|pt-BR-Standard-B" },
    { value: "female|pt-BR-Wavenet-A", label: "female|pt-BR-Wavenet-A" },
    { value: "male|pt-BR-Wavenet-B", label: "male|pt-BR-Wavenet-B" },
    { value: "female|pt-PT-Standard-A", label: "female|pt-PT-Standard-A" },
    { value: "male|pt-PT-Standard-B", label: "male|pt-PT-Standard-B" },
    { value: "male|pt-PT-Standard-C", label: "male|pt-PT-Standard-C" },
    { value: "female|pt-PT-Standard-D", label: "female|pt-PT-Standard-D" },
    { value: "female|pt-PT-Wavenet-A", label: "female|pt-PT-Wavenet-A" },
    { value: "male|pt-PT-Wavenet-B", label: "male|pt-PT-Wavenet-B" },
    { value: "male|pt-PT-Wavenet-C", label: "male|pt-PT-Wavenet-C" },
    { value: "female|pt-PT-Wavenet-D", label: "female|pt-PT-Wavenet-D" },
    { value: "female|pa-IN-Standard-A", label: "female|pa-IN-Standard-A" },
    { value: "male|pa-IN-Standard-B", label: "male|pa-IN-Standard-B" },
    { value: "female|pa-IN-Standard-C", label: "female|pa-IN-Standard-C" },
    { value: "male|pa-IN-Standard-D", label: "male|pa-IN-Standard-D" },
    { value: "female|pa-IN-Wavenet-A", label: "female|pa-IN-Wavenet-A" },
    { value: "male|pa-IN-Wavenet-B", label: "male|pa-IN-Wavenet-B" },
    { value: "female|pa-IN-Wavenet-C", label: "female|pa-IN-Wavenet-C" },
    { value: "male|pa-IN-Wavenet-D", label: "male|pa-IN-Wavenet-D" },
    { value: "female|ro-RO-Standard-A", label: "female|ro-RO-Standard-A" },
    { value: "female|ro-RO-Wavenet-A", label: "female|ro-RO-Wavenet-A" },
    { value: "female|ru-RU-Standard-A", label: "female|ru-RU-Standard-A" },
    { value: "male|ru-RU-Standard-B", label: "male|ru-RU-Standard-B" },
    { value: "female|ru-RU-Standard-C", label: "female|ru-RU-Standard-C" },
    { value: "male|ru-RU-Standard-D", label: "male|ru-RU-Standard-D" },
    { value: "female|ru-RU-Standard-E", label: "female|ru-RU-Standard-E" },
    { value: "female|ru-RU-Wavenet-A", label: "female|ru-RU-Wavenet-A" },
    { value: "male|ru-RU-Wavenet-B", label: "male|ru-RU-Wavenet-B" },
    { value: "female|ru-RU-Wavenet-C", label: "female|ru-RU-Wavenet-C" },
    { value: "male|ru-RU-Wavenet-D", label: "male|ru-RU-Wavenet-D" },
    { value: "female|ru-RU-Wavenet-E", label: "female|ru-RU-Wavenet-E" },
    { value: "female|sr-rs-Standard-A", label: "female|sr-rs-Standard-A" },
    { value: "female|sk-SK-Standard-A", label: "female|sk-SK-Standard-A" },
    { value: "female|sk-SK-Wavenet-A", label: "female|sk-SK-Wavenet-A" },
    { value: "female|es-ES-Standard-A", label: "female|es-ES-Standard-A" },
    { value: "male|es-ES-Standard-B", label: "male|es-ES-Standard-B" },
    { value: "female|es-ES-Standard-C", label: "female|es-ES-Standard-C" },
    { value: "female|es-ES-Standard-D", label: "female|es-ES-Standard-D" },
    { value: "male|es-ES-Wavenet-B", label: "male|es-ES-Wavenet-B" },
    { value: "female|es-ES-Wavenet-C", label: "female|es-ES-Wavenet-C" },
    { value: "female|es-ES-Wavenet-D", label: "female|es-ES-Wavenet-D" },
    { value: "female|es-US-Neural2-A", label: "female|es-US-Neural2-A" },
    { value: "female|es-US-Standard-A", label: "female|es-US-Standard-A" },
    { value: "male|es-US-Standard-B", label: "male|es-US-Standard-B" },
    { value: "male|es-US-Standard-C", label: "male|es-US-Standard-C" },
    { value: "female|es-US-Wavenet-A", label: "female|es-US-Wavenet-A" },
    { value: "male|es-US-Wavenet-B", label: "male|es-US-Wavenet-B" },
    { value: "male|es-US-Wavenet-C", label: "male|es-US-Wavenet-C" },
    { value: "female|sv-SE-Standard-A", label: "female|sv-SE-Standard-A" },
    { value: "female|sv-SE-Standard-B", label: "female|sv-SE-Standard-B" },
    { value: "female|sv-SE-Standard-C", label: "female|sv-SE-Standard-C" },
    { value: "male|sv-SE-Standard-D", label: "male|sv-SE-Standard-D" },
    { value: "male|sv-SE-Standard-E", label: "male|sv-SE-Standard-E" },
    { value: "female|sv-SE-Wavenet-A", label: "female|sv-SE-Wavenet-A" },
    { value: "female|sv-SE-Wavenet-B", label: "female|sv-SE-Wavenet-B" },
    { value: "male|sv-SE-Wavenet-C", label: "male|sv-SE-Wavenet-C" },
    { value: "female|sv-SE-Wavenet-D", label: "female|sv-SE-Wavenet-D" },
    { value: "male|sv-SE-Wavenet-E", label: "male|sv-SE-Wavenet-E" },
    { value: "female|ta-IN-Standard-A", label: "female|ta-IN-Standard-A" },
    { value: "male|ta-IN-Standard-B", label: "male|ta-IN-Standard-B" },
    { value: "female|ta-IN-Wavenet-A", label: "female|ta-IN-Wavenet-A" },
    { value: "male|ta-IN-Wavenet-B", label: "male|ta-IN-Wavenet-B" },
    { value: "female|te-IN-Standard-A", label: "female|te-IN-Standard-A" },
    { value: "male|te-IN-Standard-B", label: "male|te-IN-Standard-B" },
    { value: "female|th-TH-Standard-A", label: "female|th-TH-Standard-A" },
    { value: "female|tr-TR-Standard-A", label: "female|tr-TR-Standard-A" },
    { value: "male|tr-TR-Standard-B", label: "male|tr-TR-Standard-B" },
    { value: "female|tr-TR-Standard-C", label: "female|tr-TR-Standard-C" },
    { value: "female|tr-TR-Standard-D", label: "female|tr-TR-Standard-D" },
    { value: "male|tr-TR-Standard-E", label: "male|tr-TR-Standard-E" },
    { value: "female|tr-TR-Wavenet-A", label: "female|tr-TR-Wavenet-A" },
    { value: "male|tr-TR-Wavenet-B", label: "male|tr-TR-Wavenet-B" },
    { value: "female|tr-TR-Wavenet-C", label: "female|tr-TR-Wavenet-C" },
    { value: "female|tr-TR-Wavenet-D", label: "female|tr-TR-Wavenet-D" },
    { value: "male|tr-TR-Wavenet-E", label: "male|tr-TR-Wavenet-E" },
    { value: "female|uk-UA-Standard-A", label: "female|uk-UA-Standard-A" },
    { value: "female|uk-UA-Wavenet-A", label: "female|uk-UA-Wavenet-A" },
    { value: "female|vi-VN-Standard-A", label: "female|vi-VN-Standard-A" },
    { value: "male|vi-VN-Standard-B", label: "male|vi-VN-Standard-B" },
    { value: "female|vi-VN-Standard-C", label: "female|vi-VN-Standard-C" },
    { value: "male|vi-VN-Standard-D", label: "male|vi-VN-Standard-D" },
    { value: "female|vi-VN-Wavenet-A", label: "female|vi-VN-Wavenet-A" },
    { value: "male|vi-VN-Wavenet-B", label: "male|vi-VN-Wavenet-B" },
    { value: "female|vi-VN-Wavenet-C", label: "female|vi-VN-Wavenet-C" },
    { value: "male|vi-VN-Wavenet-D", label: "male|vi-VN-Wavenet-D" }
];

export const pollyVoiceOptions = [
    { value: "zeina", label: "Zeina" },
    { value: "arlet", label: "Arlet" },
    { value: "zhiyu", label: "Zhiyu" },
    { value: "naja", label: "Naja" },
    { value: "mads", label: "Mads" },
    { value: "lotte", label: "Lotte" },
    { value: "ruben", label: "Ruben" },
    { value: "nicole", label: "Nicole" },
    { value: "olivia", label: "Olivia" },
    { value: "russell", label: "Russell" },
    { value: "amy", label: "Amy" },
    { value: "emma", label: "Emma" },
    { value: "brian", label: "Brian" },
    { value: "arthur", label: "Arthur" },
    { value: "aditi", label: "Aditi" },
    { value: "raveena", label: "Raveena" },
    { value: "aria", label: "Aria" },
    { value: "ayanda", label: "Ayanda" },
    { value: "ivy", label: "Ivy" },
    { value: "joanna", label: "Joanna" },
    { value: "kendra", label: "Kendra" },
    { value: "kimberly", label: "Kimberly" },
    { value: "salli", label: "Salli" },
    { value: "joey", label: "Joey" },
    { value: "justin", label: "Justin" },
    { value: "kevin", label: "Kevin" },
    { value: "matthew", label: "Matthew" },
    { value: "geraint", label: "Geraint" },
    { value: "céline", label: "Céline" },
    { value: "celine", label: "Celine" },
    { value: "léa", label: "Léa" },
    { value: "mathieu", label: "Mathieu" },
    { value: "chantal", label: "Chantal" },
    { value: "gabrielle", label: "Gabrielle" },
    { value: "liam", label: "Liam" },
    { value: "marlene", label: "Marlene" },
    { value: "vicki", label: "Vicki" },
    { value: "hans", label: "Hans" },
    { value: "daniel", label: "Daniel" },
    { value: "hannah", label: "Hannah" },
    { value: "dóra", label: "Dóra" },
    { value: "dora", label: "Dora" },
    { value: "karl", label: "Karl" },
    { value: "carla", label: "Carla" },
    { value: "bianca", label: "Bianca" },
    { value: "giorgio", label: "Giorgio" },
    { value: "mizuki", label: "Mizuki" },
    { value: "takumi", label: "Takumi" },
    { value: "seoyeon", label: "Seoyeon" },
    { value: "liv", label: "Liv" },
    { value: "ewa", label: "Ewa" },
    { value: "maja", label: "Maja" },
    { value: "jacek", label: "Jacek" },
    { value: "jan", label: "Jan" },
    { value: "camila", label: "Camila" },
    { value: "vitória", label: "Vitória" },
    { value: "vitoria", label: "Vitoria" },
    { value: "ricardo", label: "Ricardo" },
    { value: "inês", label: "Inês" },
    { value: "ines", label: "Ines" },
    { value: "cristiano", label: "Cristiano" },
    { value: "carmen", label: "Carmen" },
    { value: "tatyana", label: "Tatyana" },
    { value: "maxim", label: "Maxim" },
    { value: "conchita", label: "Conchita" },
    { value: "lucia", label: "Lucia" },
    { value: "enrique", label: "Enrique" },
    { value: "mia", label: "Mia" },
    { value: "lupe", label: "Lupe" },
    { value: "penélope", label: "Penélope" },
    { value: "penelope", label: "Penelope" },
    { value: "miguel", label: "Miguel" },
    { value: "pedro", label: "Pedro" },
    { value: "astrid", label: "Astrid" },
    { value: "filiz", label: "Filiz" },
    { value: "gwyneth", label: "Gwyneth" }
];

export const autoRunConversationOptions = [
    { label: "Start listening on mic click", value: false },
    { label: "Always listen", value: true }
];

export const initializeWithWelcomeMessageOptions = [
    { label: "Initialize with welcome message", value: true },
    { label: "Initialize without welcome message", value: false }
];

export const useVoiceInputOptions = [
    { label: "Both speech and typing", value: true },
    { label: "Typing only", value: false }
];

export const useOutputSpeechOptions = [
    { label: "Readout of text & on-screen display", value: true },
    { label: "On-screen display only", value: false }
];

export const useDraftContentOptions = [
    { label: "Use published content only", value: false },
    { label: "Use draft content", value: true }
];

export const showConversationHistoryOptions = [
    { label: "Show conversation history", value: true },
    { label: "Don't show conversation history", value: false }
];

export const noTrackingOptions = [
    { label: "Track assistant usage", value: true },
    { label: "Don't track assistant usage", value: false }
];

export const themeStyleOptions = [
    { label: "Light Theme 1", value: "lightStyle1" },
    { label: "Light Theme 2", value: "lightStyle2" },
    { label: "Dark Theme 1", value: "darkStyle1" },
    { label: "Dark Theme 2", value: "darkStyle2" },
    { label: "Default Theme", value: "default" }
];

export const overallBackgroundStyleOptions = [
    { label: "Solid color or gradient", value: "solid or gradient" },
    { label: "Transparent", value: "transparent" }
];

export const headerBackgroundStyleOptions = [
    { label: "Solid color or gradient", value: "solid or gradient" },
    { label: "Transparent", value: "transparent" }
];

export const closeAssistantButtonImageOptions = [
    { label: "Style 1", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/close.svg' },
    { label: "Style 2", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/closeTwo.svg' }
];

export const minimizeIconOptions = [
    { label: "Style 1", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/minimize.svg' },
    { label: "Style 2", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/minimizeTwo.svg' }
];

export const micActiveImageOptions = [
    { label: "Style 1", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/micActive.svg' },
    { label: "Style 2", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/micTwoActive.svg' },
    { label: "Style 3", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/micThreeActive.svg' },
];

export const sendActiveImageOptions = [
    { label: "Style 1", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendActive.svg' },
    { label: "Style 2", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendTwoActive.svg' },
    { label: "Style 3", value: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendThreeActive.svg' }
];