import React from 'react';
import { Subscribe } from 'unstated';
import WelcomeMessageContainer from '../../state/containers/WelcomeMessageContainer';
import EditWelcomeMessageForm from './components/EditWelcomeMessageForm';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import GenericEditContentItemPage from '../formScenes/GenericEditContentItemPage';
import WelcomeMessageModel from '../../models/features/api/Welcome/WelcomeMessageModel';
import IResult from '../../models/result/IResult';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { WelcomeMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import queryString from 'query-string';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import ConversationAccessType from '../../models/features/ConversationAccessType';

interface ApplicationRouteProps {
    applicationFeatureId: string
    welcomeMessageId: string
}
interface EditWelcomeMessageProps extends RouteComponentProps<ApplicationRouteProps> { }
class EditWelcomeMessage extends React.Component<EditWelcomeMessageProps> {  
    render() {
        return (
            <Subscribe key={this.props.match.params.welcomeMessageId + this.props.match.params.applicationFeatureId} to={[WelcomeMessageContainer,
                ApplicationContainer,
                TitleStateContainer,
                RepromptContainer,
                FollowUpContainer,
                MediaResponseStateContainer,
                FeatureContainer,
                ApplicationEnvironmentsContainer,
                ApplicationBackupVersionsContainer]}>
                {(welcomeContainer: WelcomeMessageContainer,
                    applicationContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer,
                    environmentsContainer: ApplicationEnvironmentsContainer,
                    backupsContainer: ApplicationBackupVersionsContainer) => {

                    return (<GenericEditContentItemPage stateContainer={welcomeContainer}
                        environmentsContainer={environmentsContainer}
                        backupsContainer={backupsContainer}
                        titleContainer={titleContainer}
                        applicationContainer={applicationContainer}
                        defaultTitle="Edit Welcome Message"
                        contentItemId={this.props.match.params.welcomeMessageId}
                        history={this.props.history}
                        location={this.props.location}
                        match={this.props.match}
                        staticContext={this.props.staticContext}
                        featureContainer={featureContainer}
                        featureTypeId={WelcomeMessagesFeatureTypeId}
                        formRender={(contentItem: WelcomeMessageModel, 
                            application, 
                            isTemplateOwned, 
                            assistantApplication,
                            onShowPublishModal: () => void, 
                            onSubmit: (model: WelcomeMessageModel, onSuccess) => Promise<IResult<WelcomeMessageModel>>, 
                            toggleSync: (id, shouldNotSync) => Promise<IResult<WelcomeMessageModel>>,
                            onAccessTypeChange: (accessType: ConversationAccessType) => void) => (
                            <EditWelcomeMessageForm
                                location={this.props.location}
                                application={application}
                                applicationFeatureId={this.props.match.params.applicationFeatureId}
                                welcomeMessage={contentItem}
                                stateContainer={welcomeContainer}
                                appContainer={applicationContainer}
                                followUpContainer={followUpContainer}
                                repromptContainer={repromptContainer}
                                responseStateContainer={responseContainer}
                                assistantApplication={assistantApplication}
                                onShowPublishModal={onShowPublishModal}
                                onSubmit={onSubmit}
                                onToggleSync={toggleSync}
                                onToggleNavigation={null}
                                onAccessTypeChange={onAccessTypeChange}
                                previousContentId={queryString?.parse(this.props.location.search)?.previousContentId as string}
                                featureContainer={featureContainer}
                                history={this.props.history}
                                isSaving={welcomeContainer.state.isSaving}
                                isTemplateOwned={isTemplateOwned} />
                        )} />)

                }}
            </Subscribe>
        );
    }
}

export default EditWelcomeMessage;