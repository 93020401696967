import React, { useEffect, useState } from 'react';
import { color_shades_darkest, color_colors_ocean, color_shades_dark, color_shades_darker } from '../../../constants/colors';
import styled from '@emotion/styled';

const oneDark = require('../../../content/images/application-settings/deployments/one-dark.svg');
const oneBlue = require('../../../content/images/application-settings/deployments/one-blue.svg');
const oneGrey = require('../../../content/images/application-settings/deployments/one-grey.svg');
const twoDark = require('../../../content/images/application-settings/deployments/two-dark.svg');
const twoBlue = require('../../../content/images/application-settings/deployments/two-blue.svg');
const twoGrey = require('../../../content/images/application-settings/deployments/two-grey.svg');
const threeDark = require('../../../content/images/application-settings/deployments/three-dark.svg');
const threeBlue = require('../../../content/images/application-settings/deployments/three-blue.svg');
const threeGrey = require('../../../content/images/application-settings/deployments/three-grey.svg');

interface DeploymentWorkflowStepProps {
    name?: string
    icon?: string
    className?: string
    last?: boolean
    phase?: DeploymentPhase
    step: number
    currentStep: number
    href?: string
}

export type DeploymentPhase = "current" | "done" | "todo";

function DeploymentWorkflowStep(props: DeploymentWorkflowStepProps) {

    const [phase, setPhase] = useState("current" as DeploymentPhase);

    useEffect(() => {
        setPhase(props.currentStep > props.step ? "done" : props.currentStep < props.step ? "todo" : "current");
    }, [props.currentStep, props.step])
    return (
        <StyledTab
            key={props.step}
            className={`${props.last ? "last" : ''} ${props.className ? props.className : ''}`}>
            {
                props.last ?
                    props.href ?
                        <a href={props.href} target="_blank">
                            <StatusImg src={props.icon} />
                        </a>
                        :
                        <StatusImg src={props.icon} />
                    :
                    <NumberedTab className={`numbered-tab-${phase == "done" ? 'dark' : phase == "current" ? 'blue' : 'grey'}`}>
                        <p className={`step-number ${phase == "done" ? 'dark' : phase == "current" ? 'blue' : 'grey'}`}>{props.step}</p>
                        <p className={`step-name`}>{props.name}</p>
                        <SpacerLine className={`spacer-${phase == "done" ? 'dark' : phase == "current" ? 'blue' : 'grey'}`} />
                    </NumberedTab>
            }
        </StyledTab>
    )
}

const StyledTab = styled.div`
    width: 35%;
    &.last {
        width: auto;
    }
`;

const StatusImg = styled.img`
    margin-top: 4px;
`;
const NumberedTab = styled.div`
    display: flex;
    width: 100%;
    white-space: nowrap;
    height: 32px;
    p {
        font-size: 14px;
        &.step-name{
            margin-top: 6px;    
        }
        &.step-number{
            width: 24px;
            height: 24px;
            border: 1px solid;
            border-radius: 16px;
            text-align: center;
            margin-right: 8px;
            margin-top: 4px;
            line-height: 20px;
            &.dark{
                border: 1px solid ${color_shades_darkest};
            }
            &.blue{
                border: 1px solid ${color_colors_ocean};
            }
            &.grey{
                border: 1px solid ${color_shades_dark};
            }
        }
    }
    img {
        margin-right: 8px;
        margin-top: -1px;
    }
    &.numbered-tab-dark {
        color: ${color_shades_darkest};
    }
    &.numbered-tab-blue {        
        color: ${color_colors_ocean};
    }
    &.numbered-tab-grey {   
        color: ${color_shades_darker};     
    }
`;

const SpacerLine = styled.span`
    flex-grow: 1;
    margin: 0px 8px 15px 8px;
    &.spacer-dark {
        border-bottom: 1px solid ${color_shades_darkest};
    }
    &.spacer-blue {        
        border-bottom: 1px dashed ${color_colors_ocean};
    }
    &.spacer-grey {   
        border-bottom: 1px dashed ${color_shades_dark};     
    }
`;

export default DeploymentWorkflowStep;