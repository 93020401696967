import type { RequestContext } from "assistant/VoicifyAssistant";
import { addAfterEffectContext, addItemToAfterResponseData, extractDataValue, sleep } from "../Shared";


export const performNavigateEffect = async (data: any) => {
    var newTab = extractDataValue(data, "openInNewTab", true);
    var url = extractDataValue(data, "url", null);
    var fireAfterMilliseconds = extractDataValue(data, "fireAfterMilliseconds", 0);
    if (url) {
        await sleep(fireAfterMilliseconds);
        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        }
    }
}
export const handleNavigateEffect = async (data: { [key: string]: any }, requestContext: RequestContext, afterResponseEffect: boolean = false): Promise<boolean> => {
    var fireBefore = extractDataValue(data, "fireBeforeTextToSpeech", true);
    var newTab = extractDataValue(data, "openInNewTab", true);
    if (fireBefore && !afterResponseEffect && requestContext.textToSpeechEnabled) {
        addAfterEffectContext(requestContext);        
    }    
    if (fireBefore || afterResponseEffect) {
        await performNavigateEffect(data);
        return !newTab;
    } else {
        addItemToAfterResponseData("navigateWeb", requestContext, data);
        return false;
    }
}