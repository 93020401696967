import React from 'react';
import { css } from '@emotion/css';
import NumberIncrementSelector from '../forms/NumberIncrementSelector';
import { Link } from 'react-router-dom';
import SimpleItemDisplayInfo from '../../models/media/SimpleItemDisplayInfo';
import { ocean_blue } from '../../constants/colors';
const navigateIcon = require('../../content/images/navigation-right.svg');

// component used in building forms with consistent components for look and function
interface SimplePaginatedListProps {
    displayInfo: SimpleItemDisplayInfo[] 
    pageSize : number
}

interface SimplePaginatedListState {
    page: number,
    maxPage: number,
    shownInfo: SimpleItemDisplayInfo[],
    currentPageString: string
}

class SimplePaginatedList extends React.Component<SimplePaginatedListProps, SimplePaginatedListState> {  
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);
    
        // Set the state directly. Use props if necessary.
        this.state = {
            page: 1,
            maxPage: 1,
            shownInfo: [],
            currentPageString: ""
        }
    }   
    componentDidMount() {
        const newMaxPage = Math.ceil(this.props.displayInfo.length/this.props.pageSize);
        this.setState({
            ...this.state,
            maxPage: newMaxPage
        }, this.updateDisplay)
    }
    updatePage(up: boolean) {
        const change = up ? 1 : -1;
        let newPage = this.state.page + change;
        if(newPage > this.state.maxPage)
            newPage = this.state.maxPage;
        else if(newPage < 1)
            newPage = 1;
        this.setState({
            ...this.state,
            page: newPage
        }, this.updateDisplay)
    }
    updateDisplay() {
        const pageStart = (this.state.page-1)*this.props.pageSize;      
        const newShownInfo = this.props.displayInfo.slice(pageStart, pageStart + this.props.pageSize);
        const pageEnd = pageStart+this.props.pageSize;
        const pageString = `${pageStart + 1}-${pageEnd > this.props.displayInfo.length ? this.props.displayInfo.length : pageEnd} of ${this.props.displayInfo.length}`;
        this.setState({
            ...this.state,
            shownInfo: newShownInfo,
            currentPageString: pageString
        })
    }
    render() {        
        return (
            <div className={container}>
                {this.state.shownInfo.map((info, i) => 
                    <Link target={"_blank"} to={info.url} key={i} className={`item ${i === 0 ? `top` : ``} ${(i === this.state.shownInfo.length - 1) ? `bottom` : ``}`}>
                        <img className={`icon`} src={info.icon}/>
                        <div className={`linkText`}>{info.name}</div>
                        <span/>
                        <img className={`icon`} src={navigateIcon}/>
                    </Link>    
                )}
                <div className={`bottomRow`}>
                    <div className={`pageString`}>{this.state.currentPageString}</div>
                    <span />
                    <NumberIncrementSelector 
                        updateValue={this.updatePage.bind(this)}
                        showNumber={false}
                    />   
                </div>
            </div>
        );
    }
}

const container = css`
    font-family: Muli;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;  
    width: 100%;
    .item {
        border: 1px solid #cccccc;
        border-bottom: 0px;
        padding: 0px;
        box-sizing: border-box;
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: row;
        .icon {
            padding-left: 5px;
            padding-right: 5px;
            max-width: 30px;
        }
        .linkText {
            padding-top: 3px;
            padding-left: 5px;
            color: ${ocean_blue};
        }
        &.top {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        &.bottom {
            border-bottom: 1px solid #cccccc;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-bottom: 10px;
        }
    }
    span {
        flex-grow: 1;
    }
    .bottomRow {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        .pageString {
            padding-top: 5px;
        }
    }
    
`;
export default SimplePaginatedList;