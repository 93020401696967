import React from 'react';
import { css } from '@emotion/css';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import ApplicationAvatar from '../../../components/general/ApplicationAvatar';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { text_blue, color_shades_darker } from '../../../constants/colors';

const appsIcon = require('../../../content/images/content-tree-icons/app-level/all-apps.svg');

interface CurrentApplicationSelectorProps {
    application: ApplicationModel
    onToggle: () => void
    isMinified: boolean
    isOpen: boolean
}

const CurrentApplicationSelector: React.FC<CurrentApplicationSelectorProps> = (props) => (
    <div className={`${containerStyle} ${props.isOpen ? 'open' : ''} ${props.isMinified ? 'minified' : ''} ac-app-picker`} onClick={props.onToggle}>
        {props.application
            ? <ApplicationAvatar app={props.application} size={42} />
            : <img className="apps-icon" src={appsIcon} />
        }
        {!props.isMinified &&
            <div className="app-selector-title">
                <p className="selection-detail">Select an App</p>
                <p className="selection-title">{props.application.name}</p>
            </div>
        }
        <ToggleIndicator direction={props.isOpen ? "up" : "down"} className="toggle-indicator" />
    </div>
);

const containerStyle = css`
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    border-left: 1px solid ${color_shades_darker};
    border-right: 1px solid ${color_shades_darker};
    >img {
        width: 32px;
        height: 32px;
        padding: 5px;
    }
    .apps-icon {
        margin-left: 5px;
        margin-right: 5px;
    }
    &.minified {
        .toggle-indicator {
            border-color: white;
            margin-left: 8px;
        }
    }
    &.open {
        background: ${text_blue};
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
    }
    .app-selector-title {
        margin: 0 12px;
        p {
            color: white;
        }
        .selection-detail {
            font-family: Muli;
            font-style: italic;
            font-weight: normal;
            font-size: 10px;
            line-height: 16px;
        }
        .selection-title {
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .toggle-indicator {
        border-color: white;
        margin-left: auto;
        margin-right: 0;
    }
`;

export default CurrentApplicationSelector;