import React from 'react';
import { Subscribe } from 'unstated';
import OrganizationDashboardContent from './components/OrganizationDashboardContent';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import { RouteComponentProps } from 'react-router';
import ApplicationContainer from '../../state/containers/ApplicationContainer';


interface OrganizationDashboardProps {

}
class OrganizationDashboard extends React.Component<RouteComponentProps<OrganizationDashboardProps>> {
    render() {
        return (
            <Subscribe to={[OrganizationContainer, ApplicationContainer, TitleStateContainer]}>
            {(orgContainer: OrganizationContainer, appContainer: ApplicationContainer, titleContainer: TitleStateContainer) => (
                <OrganizationDashboardContent history={this.props.history} orgsContainer={orgContainer} titleContainer={titleContainer} appContainer={appContainer}/>
            )}
            </Subscribe>
        );
    }
}

export default OrganizationDashboard;