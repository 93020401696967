import React from 'react';
import { Subscribe } from 'unstated';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import ModalLayout from '../../components/structure/ModalLayout';
import ScrollView from '../../components/structure/ScrollView';
import FullScreenLayout from '../fullScreenLayout';
import PageContainer from '../../components/structure/PageContainer';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import ModalTitle from '../../components/general/ModalTitle';
import Button from '../../components/general/Button';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import TabPanelBody from '../../components/structure/TabPanelBody';
import FollowUpForm from './components/FollowUpForm';
import SmallModalLayout from '../../components/structure/SmallModalLayout';
const closeIcon = require('../../content/images/close_icon.svg');

interface FollowUpManagerProps {
    applicationId: string
    followUp?: FollowUpModel
    onClose?: () => void
    onFinish?: (followUp: FollowUpModel) => void
}

interface FollowUpManagerState {
    hasChanges: boolean
}

class FollowUpManager extends React.Component<FollowUpManagerProps, FollowUpManagerState> {
    componentWillMount() {
        this.setState({
            hasChanges: false
        });
    }
    handleClose() {
        if (this.state.hasChanges) {
            var confirmClose = confirm("You have unsaved changes. Are you sure you want to close?");
            if (!confirmClose)
                return;
        }

        if (this.props.onClose)
            this.props.onClose();
    }
    handleFinish(followUp: FollowUpModel) {
        this.setState({
            hasChanges: false
        });

        if (this.props.onFinish) {
            this.props.onFinish(followUp);
        }
    }
    handleChange() {
        this.setState({
            hasChanges: true
        });
    }
    render() {
        return (
            <SmallModalLayout onClose={this.handleClose.bind(this)} isVisible={true}>
                <Subscribe to={[FollowUpContainer]}>
                    {(followUpContainer: FollowUpContainer) => {
                        return (
                            <FullScreenLayout>
                                <ScrollView>
                                    <PageContainer>
                                        <HorizontalContainer>
                                            <ModalTitle>Add a Follow-up Message:</ModalTitle>
                                            <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={this.handleClose.bind(this)} />
                                        </HorizontalContainer>
                                        <HorizontalSeparator />
                                        <TabPanelBody>
                                            <FollowUpForm followUp={this.props.followUp}
                                                applicationId={this.props.applicationId}
                                                followUpContainer={followUpContainer}
                                                onFinish={this.handleFinish.bind(this)}
                                                onChange={this.handleChange.bind(this)}
                                                onClose={this.handleClose.bind(this)} />
                                        </TabPanelBody>
                                    </PageContainer>
                                </ScrollView>
                            </FullScreenLayout>
                        );
                    }}
                </Subscribe>
            </SmallModalLayout>
        )
    }
}

export default FollowUpManager;