import React from 'react';
import { css } from '@emotion/css';
import { color_shades_darkest, color_variants_ocean_light_1, color_variants_ocean_xlight, color_shades_darker, color_text_light } from '../../../constants/colors';
import { Link } from 'react-router-dom';
import ToggleIndicator from '../../general/ToggleIndicator';
const loadingIcon = require('../../../content/images/content-tree-icons/loading.svg');
const ghostIcon = require('../../../content/images/content-tree-icons/ghost.svg');

interface NestedTreeProps {
    root: TreeItem
    onItemClick: (item: TreeItem) => void
    isRootHidden?: boolean
    level?: number
    emptyText?: string
}

export interface TreeItem {
    id: string,
    type?: string,
    icon: string,
    expandedIcon?: string,
    title: string,
    canExpand?: boolean,
    isExpanded?: boolean,
    isLoadingChildren?: boolean,
    linkTo?: string
    children?: TreeItem[]
    isHighlighted?: boolean
}

const getImage = (root: TreeItem) => {
    if (root.isLoadingChildren)
        return loadingIcon;
    if (root.isExpanded)
        return root.expandedIcon ?? root.icon;
    return root.icon;
}

const getExpandedView = (props: NestedTreeProps, level: number): any => {
    if (props.root?.isExpanded) {
        if (props.root?.children?.length === 0) {
            if (props.root?.isLoadingChildren) { // node is expanded, but nothing is loaded yet and we're loading. Show nothing
                return null;
            }
            else { // node is expanded, nothing is loaded in and we're not loading. Show the no content item.
                return (
                    <div className={`${noContentStyle} ${getPaddingCssForLevel(level + 1, 22)}`}>
                        <img src={ghostIcon} /> {props.emptyText ?? "No Content Found Here"}
                    </div>
                )
            }
        }
        // we're expanded and we have content to render. 
        return (
            <div className="tree-item-container">
                {props.root?.children?.map(c => (
                    <NestedTree emptyText={props.emptyText} isRootHidden={false} level={level + 1} key={c.id} root={c} onItemClick={props.onItemClick} />
                ))}
            </div>
        );
    }
    // the node isn't expanded render nothing. 
    return null;
}

const getPaddingCssForLevel = (level: number, extra?: number): string => {
    const extraPadding = extra ?? 0;
    return css`
        padding-left: ${(level * 16) + extraPadding}px;
    `;
}

const NestedTree: React.SFC<NestedTreeProps> = (props) => {
    const level = props.isRootHidden === true ? -1 : (props.level ?? 0);
    return (<div className={`${containerStyle}${props.root.isHighlighted ? ' highlighted' : ''}${props.isRootHidden === true ? ' root-hidden' : ""}`}>
        {!props.isRootHidden &&
            <div className={`${getPaddingCssForLevel(level, props.root?.canExpand ? 8 : 14)} tree-item-title ${props.root?.canExpand ? "expandable" : ""}`} onClick={() => props.onItemClick(props.root)}>
                {props.root?.canExpand && <ToggleIndicator type='triangle' direction={props.root.isExpanded ? "down" : "right"} />}
                <img className={props.root.isLoadingChildren ? 'rotating' : undefined} src={getImage(props.root)} />
                {props.root.linkTo ? <Link to={props.root.linkTo}>{props.root?.title}</Link> : <p>{props.root?.title}</p>}
            </div>
        }
        {getExpandedView(props, level)}
    </div>)
};

const noContentStyle = css`
    display: flex;
    flex-direction: row;
    font-family: Muli;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color_text_light};
    padding-top: 8px;
    padding-bottom: 8px;
    img {
        margin-right: 8px;
    }
`;

const containerStyle = css` 
    .tree-item-title {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 32px;    
        img {
            margin: 0 8px;
            height: 16px;
            width: 16px;
            min-height: 16px;
            min-width: 16px;
        }

        p,a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rotating {
            @-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
            @-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
            @keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
            -webkit-animation:spin 1s linear infinite;
            -moz-animation:spin 1s linear infinite;
            animation:spin 1s linear infinite;
        }
    }

    a,p {
        color: ${color_shades_darkest};
    }

    &.highlighted {
        >.tree-item-title {
            background: ${color_variants_ocean_light_1};
        }

        >.tree-item-container {
            background: ${color_variants_ocean_xlight};
        }
    }
    &.root-hidden {
        padding-left: 0xp;        
        >.tree-item-container {
            padding-left: 0px;
        }
    }
`;

export default NestedTree;
