import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';
import ApplicationFeatureModel from '../../models/features/api/ApplicationFeatureModel';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';

const bombIcon = require('../../content/images/alert-triangle-red.svg');


const RelinkConfirmation = () => (
    <div className={containerStyle}>
        <img src={bombIcon}/>
        <p>
        Restoring the link to the template will remove any changes or customizations you have made. Making edits to this content will then be managed from the template.
        </p>
    </div>
)

const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 64px;
    img {
        width: 82px;
        height: 82px;
        margin-bottom: 32px;
    }

    p {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${dark_grey_blue};
    }
`
export default RelinkConfirmation;