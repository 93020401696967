import React, { useState } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import StaticMenuPanel from '../../../components/structure/Panels/StaticMenuPanel';
import BodyPanel from '../../../components/structure/Panels/BodyPanel';
import TemplateConfigurationModel, { TemplateType } from '../../../models/templating/api/TemplateConfigurationModel';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import ModuleCard from './ModuleCard';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import DiscoverModulesMenu from './DiscoverModulesMenu';
import { useHistory, useLocation } from 'react-router';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import FlatRoundedSelectField from '../../../components/forms/FlatRoundedSelectField';
import NumberIncrementSelector from '../../../components/forms/NumberIncrementSelector';
import { color_colors_ocean, color_text_default } from '../../../constants/colors';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import ModuleListItem from './ModuleListItem';
import IntegrationListing from '../../../models/integrations/IntegrationListing';
import LegacyIntegrationCard from './LegacyIntegrationCard';
import LegacyIntegrationListItem from './LegacyIntegrationListItem';
import AddLegacyIntegration from '../../addLegacyIntegration';
import ToggleableSection from '../../../components/structure/ToggleableSection';

interface DiscoverModuleProps {
    templateConfigurationContainer: TemplateConfigurationContainer
    appliedTemplatesContainer: AppliedApplicationTemplateContainer
    applicationId: string
    organizationId: string
    availableTemplateConfigurations: TemplateConfigurationModel[]
    appliedTemplates: AppliedApplicationTemplateModel[]
    legacyIntegrations: IntegrationListing[]
}


const DiscoverModules: React.FC<DiscoverModuleProps> = ({
    templateConfigurationContainer,
    appliedTemplatesContainer,
    applicationId,
    organizationId,
    availableTemplateConfigurations,
    appliedTemplates,
    legacyIntegrations
}) => {
    const location = useLocation();
    const history = useHistory();
    const [legacyIntegration, setLegacyIntegration] = useState(null as IntegrationListing);
    const filterValues = new URLSearchParams(location.search);

    const handleTypeChange = (type: TemplateType) => {
        if (!type)
            filterValues.delete("templateType")
        else
            filterValues.set("templateType", type);
        history.push(`${location.pathname}?${filterValues.toString()}`);
        templateConfigurationContainer.changeTemplateFilter({
            ...templateConfigurationContainer.state,
            templateTypeFilter: type,
            currentDiscoverPage: 1
        })
    }
    const handleCategoryChange = (categoryId: string) => {
        if (!categoryId)
            filterValues.delete("category");
        else
            filterValues.set("category", categoryId);
        history.push(`${location.pathname}?${filterValues.toString()}`);
        templateConfigurationContainer.changeTemplateFilter({
            ...templateConfigurationContainer.state,
            categoryIdFilter: categoryId,
            currentDiscoverPage: 1
        })
    }
    const handleIndustryChange = (industryId: string) => {
        if (!industryId)
            filterValues.delete("industry");
        else
            filterValues.set("industry", industryId);
        history.push(`${location.pathname}?${filterValues.toString()}`);
        templateConfigurationContainer.changeTemplateFilter({
            ...templateConfigurationContainer.state,
            industryIdFilter: industryId,
            currentDiscoverPage: 1
        })
    }
    const incrementPage = () => {
        if (templateConfigurationContainer.state.availableTemplates?.length == templateConfigurationContainer.state.defaultPageSize) {
            // only increment if we have a full page right now
            templateConfigurationContainer.changeTemplateFilter({
                ...templateConfigurationContainer.state,
                currentDiscoverPage: templateConfigurationContainer.state.currentDiscoverPage + 1
            })
        }
    }
    const decrementPage = () => {
        if (templateConfigurationContainer.state.currentDiscoverPage > 1) {
            templateConfigurationContainer.changeTemplateFilter({
                ...templateConfigurationContainer.state,
                currentDiscoverPage: templateConfigurationContainer.state.currentDiscoverPage - 1
            })
        }
    }
    const handleSortByChange = (e) => {
        templateConfigurationContainer.changeTemplateFilter({
            ...templateConfigurationContainer.state,
            defaultSortBy: e.value,
            currentDiscoverPage: 1
        })
    }

    return (<PanelContainer>
        <StaticMenuPanel>
            <DiscoverModulesMenu
                onCategoryChange={handleCategoryChange}
                onIndustryChange={handleIndustryChange}
                onTypeChange={handleTypeChange}
                selectedType={templateConfigurationContainer.state.templateTypeFilter}
                selectedCategoryId={templateConfigurationContainer.state.categoryIdFilter}
                selectedIndustryId={templateConfigurationContainer.state.industryIdFilter}
                categories={templateConfigurationContainer.state.categories}
                industries={templateConfigurationContainer.state.industries} />
        </StaticMenuPanel>
        <BodyPanel>
            <ScrollablePanelBody>
                <InnerPageContainer>
                    <PageHeader>All Modules</PageHeader>
                    <HorizontalContainer>
                        <FlatRoundedSelectField
                            options={[{ label: "Name - Alphabetical", value: "NameAscending" },
                            { label: "Name - Reverse Alphabetical", value: "NameDescending" },
                            { label: "Created Date - Oldest First", value: "CreatedDateAscending" },
                            { label: "Created Date - Latest First", value: "CreatedDateDescending" }]}
                            onChange={handleSortByChange}
                            value={templateConfigurationContainer.state.defaultSortBy}
                            label="Arrange by"
                            className={arrangeBy}
                        />

                        <div className={pageContainer}>
                            <div className={leftSideLabel}>Page</div>
                            <NumberIncrementSelector
                                useShadow={false}
                                selectedNumber={`${templateConfigurationContainer.state.currentDiscoverPage}`}
                                updateValue={(b: boolean) => b ? incrementPage() : decrementPage()}
                                showNumber={true} />
                        </div>
                    </HorizontalContainer>

                    {templateConfigurationContainer.state.isLoading
                        && <LoaderContainer>
                            <SecondaryLoader />
                        </LoaderContainer>
                    }
                    {templateConfigurationContainer.state.defaultViewType == "table"
                        ? <ListContainer>
                            {availableTemplateConfigurations?.map(t => (
                                <li key={t.id}><ModuleListItem templateConfiguration={t} key={t.id} applicationId={applicationId} /></li>
                            ))}
                        </ListContainer>
                        : <CardContainer>
                            {availableTemplateConfigurations?.map(t => (
                                <li key={t.id}><ModuleCard templateConfiguration={t} key={t.id} applicationId={applicationId} /></li>
                            ))}
                        </CardContainer>
                    }

                    <ToggleableSection className={legacySection} hideTitle="Legacy Integrations" showTitle="Legacy Integrations" toggleType="triangle" isCollapsed>
                        {templateConfigurationContainer.state.defaultViewType == "table"
                            ? <ListContainer>
                                {legacyIntegrations?.map(i => (
                                    <li key={i.id}><LegacyIntegrationListItem integration={i} key={i.id} applicationId={applicationId} onSelect={(i) => setLegacyIntegration(i)} /></li>
                                ))}
                            </ListContainer>
                            : <CardContainer>
                                {legacyIntegrations?.map(i => (
                                    <li key={i.id}><LegacyIntegrationCard integration={i} key={i.id} applicationId={applicationId} onSelect={(i) => setLegacyIntegration(i)} /></li>
                                ))}
                            </CardContainer>}
                    </ToggleableSection>
                </InnerPageContainer>
            </ScrollablePanelBody>
        </BodyPanel>
        {legacyIntegration && <AddLegacyIntegration integration={legacyIntegration} organizationId={organizationId} onClose={() => setLegacyIntegration(null)} />}
    </PanelContainer>)
};

const InnerPageContainer = styled.div(`
    padding-bottom: 128px;
`)
const CardContainer = styled.ul(`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
`);
const ListContainer = styled.ul(`
    list-style-type: none;
`);

const pageContainer = css`
    display: flex;
    flex-direction: row;
    margin: 12px 32px 0px 0px; 
`;

const LoaderContainer = styled.div(`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
    .spinner > div {
        background-color: ${color_colors_ocean};
    }
`)
const legacySection = css`
.title-section {
    margin-left: 32px;
    span {
        color: ${color_text_default};
        font-size: 18px;
        line-height: 24px;
    }
}
`
const leftSideLabel = css`    
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${color_text_default};
    margin-right: 16px;
    margin-top: 8px;
`;
const arrangeBy = css`    
    margin: 12px auto 12px 32px; 
    .Select {
        width: 240px
    }
`;

const PageHeader = styled.h3(`
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 32px 0 24px 32px;
`)

export default DiscoverModules