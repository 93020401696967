import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import LanguageModel from '../../../models/features/api/LanguageModel';
import EditableNlpEntity from '../../../models/nlpEntity/EditableNlpEntity';
import { css } from '@emotion/css';
import NlpEntityValueModel from '../../../models/nlpEntity/api/NlpEntityValueModel';
import TextAreaField from '../../../components/forms/TextAreaField';
import { convertValuesToCSVText, convertCSVtextToValues, InteractionModelFormData } from './InteractionModelForm';
import _ from 'lodash';
import { FormikErrors } from 'formik';

const closeIcon = require('../../../content/images/delete-circle.svg');

export interface NlpEntityValuesTextBoxDisplayProps {
    languages?: LanguageModel[];
    nlpEntities: EditableNlpEntity[]; 
    nlpEntity: EditableNlpEntity; 
    formPrefix: string;
    formIndex: number;
    validateField;
    validateForm;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    handleSubmit;
    setErrors: (errors: FormikErrors<InteractionModelFormData>) => void;
    dirty: boolean;
    isValid: boolean;
};


const NlpEntityValuesTextBoxDisplay = (props: NlpEntityValuesTextBoxDisplayProps) => {
    
    const [textBoxValues, setTextBoxValues] = useState("");
    
    const cardPrefix = `${props.formPrefix}.${props.formIndex}`;
    const valuesField: string = `${cardPrefix}.values`;
    const isModifiedField = `${cardPrefix}.isModified`;
    const disableFields: boolean = !props.nlpEntity?.isEditable || props.nlpEntity?.isDeleted; //|| props.isSubmitting || props.isValidating 

    const nlpEntityValues: NlpEntityValueModel[] = props.nlpEntity?.values;
    
    useEffect(function resetTextBoxDisplay() {
        const ordered: NlpEntityValueModel[] = _.sortBy(nlpEntityValues ?? [], v => v?.isAdded);
        const convertedToText = convertValuesToCSVText(ordered);
        setTextBoxValues(convertedToText);

    }, []);

    const handleChange = (event) => {
        props.setFieldValue(isModifiedField, true);
        setTextBoxValues(event.target.value);
        props.handleChange(event);
    };

    const handleBlur = (event) => {
        const newParsedValues = convertCSVtextToValues(textBoxValues);
        props.setFieldValue(valuesField, newParsedValues);
        props.handleBlur(event);
    };
    
    const renderNlpValuesTextBox = () => {
        return (
            <div className={inputContainer}>                   
                <TextAreaField 
                    name="textBoxValues"
                    value={textBoxValues}
                    disabled={disableFields}
                    placeholder="Comma separated name and synonyms Values" 
                    onChange={e => handleChange(e)}
                    onBlur={e => handleBlur(e)}
                    />
            </div>        
        )
    };

    return (
        renderNlpValuesTextBox()
    );
};

export default NlpEntityValuesTextBoxDisplay;

const inputContainer = css`
    &.title {
        margin-top: 16px;
    }
    width: 100%;
    .field-container {
        height: 45px;
    }
    label {
        font-size: 12px;
    }
    textarea {
        height: 344px !important;
        margin-right: 3px;
        resize: none;
        margin-bottom: 3px;
        margin-top: 3px;    
        
        overflow-x: auto;
        white-space: nowrap;    
    } 
    
`;
