import React, { useEffect, useState } from "react";
import LanguageContainer from '../../hooks/LanguageContainer';
import LanguageModel from "../../models/features/api/LanguageModel";
import InteractionModelForm from "./components/InteractionModelForm";
import PageError from "../../components/general/PageError";
import ApplicationNlpEntityContainer from "../../hooks/ApplicationNlpEntityContainer";
import InteractionModelContainer from "../../hooks/InteractionModelContainer";
import NlpModelTrainingContainer from "../../hooks/NlpModelTrainingContainer";
import InteractionModelUpdate from "../../models/interactionModel/api/InteractionModelUpdate";
import EditableNlpEntity from "../../models/nlpEntity/EditableNlpEntity";
import UpdateNlpEntityRequest from "../../models/nlpEntity/api/UpdateNlpEntityRequest";
import ApplicationContainer from "../../state/containers/ApplicationContainer";

interface InteractionModelProps {
    applicationId: string;
    allAppLanguages: LanguageModel[];
    appContainer: ApplicationContainer;
};

const InteractionModel: React.FC<InteractionModelProps> = ({ applicationId, allAppLanguages, appContainer }) => {

    const languagesContainer = LanguageContainer.useContainer();
    const applicationNlpEntityContainer = ApplicationNlpEntityContainer.useContainer();
    const interactionModelContainer = InteractionModelContainer.useContainer();
    const nlpTrainingContainer = NlpModelTrainingContainer.useContainer();

    const languages: LanguageModel[] = languagesContainer.currentSelectedLanguages;

    useEffect(function loadApplicationNlpEntitiesAsync() {
        loadApplicationNLP();
    }, [applicationId, languages]);

    const loadApplicationNLP = async () => {
        await applicationNlpEntityContainer.getApplicationNlpEntities(applicationId, languages);
        await interactionModelContainer.loadInteractionModels(applicationId, languages);
        await applicationNlpEntityContainer.loadPrebuiltNlpEntities(applicationId, languages);
    };

    const submitInteractionModelForm = async (updates: InteractionModelUpdate[], entities: EditableNlpEntity[]) => {
        // submit entities first
        let shouldPoll = false;
        if (entities.length) {
            shouldPoll = true;
            for (const entity of entities) {
                const request: UpdateNlpEntityRequest = {
                    name: entity?.name,
                    values: entity?.values,
                    locales: entity?.locales,
                    isEditable: entity?.isEditable,
                    isCopyable: entity?.isCopyable,
                    threshold: entity?.threshold
                };

                if (entity?.isDeleted) {
                    const deletedEntityResult = await applicationNlpEntityContainer.deleteApplicationNlpEntity(applicationId, entity.id);
                    if (deletedEntityResult?.resultType === "Ok") {
                        await applicationNlpEntityContainer.loadAllApplicationNlpEntities(applicationId);
                    }
                }
                else if (entity?.isAdded) {
                    const addedEntityResult = await applicationNlpEntityContainer.addApplicationNlpEntity(applicationId, entity);
                    if (addedEntityResult?.resultType === "Ok") {
                        await applicationNlpEntityContainer.loadAllApplicationNlpEntities(applicationId);
                    }
                }
                else if (entity?.isModified) {
                    const modifiedEntityResult = await applicationNlpEntityContainer.updateApplicationNlpEntity(applicationId, entity.id, request);
                    if (modifiedEntityResult?.resultType === "Ok") {
                        await applicationNlpEntityContainer.loadAllApplicationNlpEntities(applicationId);
                    }
                }
            }
        }
        // submit interaction model
        if (updates.length) {
            shouldPoll = true;
            const interactionModelResult = await interactionModelContainer.updateInteractionModel(applicationId, updates[0].locale, updates[0]);
            if (interactionModelResult?.resultType === "Ok") {
                await interactionModelContainer.loadInteractionModels(applicationId, languages);
            }            
        }
        if (shouldPoll) {
            await nlpTrainingContainer.queueTraining(applicationId);
            nlpTrainingContainer.startPollingForTraining();
        }
    };

    return (
        <>
            <InteractionModelForm
                appContainer={appContainer}
                languages={languages}
                allAppLanguages={allAppLanguages}
                applicationId={applicationId}
                applicationNlpEntities={applicationNlpEntityContainer?.applicationNlpEntities}
                prebuiltNlpEntities={applicationNlpEntityContainer?.prebuiltNlpEntities}
                interactionModels={interactionModelContainer?.interactionModels}
                onSubmit={submitInteractionModelForm} />

            <PageError errors={[
                ...applicationNlpEntityContainer?.errors ?? [],
                ...interactionModelContainer?.errors ?? []
            ]}
                onClear={() => {
                    applicationNlpEntityContainer.clearErrors();
                    interactionModelContainer.clearErrors();
                }} />
        </>
    );
};

export default InteractionModel;
