import React, { useState } from 'react';
import styled from '@emotion/styled'
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { color_variants_ocean_light_1 } from '../../../constants/colors';

interface ModuleMenuGroupProps {
    title: string
    allLabel: string
    onItemSelect: (id: string) => void
    selectedItemId?: string
    items: GroupItem[]
}

interface GroupItem {
    label: string
    id: string
}

const ModuleMenuGroup: React.FC<ModuleMenuGroupProps> = ({
    title,
    allLabel,
    selectedItemId,
    items,
    onItemSelect
}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (<GroupContainer>
        <TitleContainer onClick={() => setIsCollapsed(!isCollapsed)}>
            <ToggleIndicator direction={isCollapsed ? 'right' : 'down'} type="triangle" />
            <p>{title}</p>
        </TitleContainer>
        {!isCollapsed && <ul>
            <MenuItem className={`defualt ${!selectedItemId ? 'selected' : ''}`} onClick={() => onItemSelect(null)}>
                <p>{allLabel}</p>
            </MenuItem>
            {items?.map(i => <MenuItem key={i.id} className={i.id == selectedItemId ? 'selected' : ''} onClick={() => onItemSelect(i.id)}>
                <p>{i.label}</p>
            </MenuItem>)}
        </ul>}
    </GroupContainer>)
}

const TitleContainer = styled.div(`
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
        font-size: 16px;
        line-height: 24px;
        margin-left: 8px;
        margin-bottom: 4px;
    }
`)
const GroupContainer = styled.div(`
    margin: 12px 8px;
    width: calc(100% - 16px);
`);
const MenuItem = styled.li(`
    padding-left: 48px;
    flex: 1;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    &.default {
        font-style: italic;
    }
    &.selected {
        background: ${color_variants_ocean_light_1};
        border-radius: 4px;
    }
`);

export default ModuleMenuGroup;