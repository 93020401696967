import React from 'react';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import ContentTreeStateContainer from '../../../state/containers/ContentTreeStateContainer';
import CollapsedMenuPanel from '../../../components/structure/CollapsedMenuPanel';
import ApplicationContentMenuContent from './ApplicationContentMenuContent';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';

interface ApplicationContentMenuProps {
    application: ApplicationModel
    appContainer: ApplicationContainer
    featureContainer: FeatureContainer
    treeContainer: ContentTreeStateContainer
    backupsContainer: ApplicationBackupVersionsContainer
    onExpand: () => void
    history: any
}

const CollapsedApplicationContentMenu: React.FC<ApplicationContentMenuProps> = (props) => {
    return (
        <CollapsedMenuPanel onExpand={props.onExpand} expandedViewRender={() => (
            <ApplicationContentMenuContent backupsContainer={props.backupsContainer}
                appContainer={props.appContainer}
                application={props.application}
                featureContainer={props.featureContainer}
                treeContainer={props.treeContainer}
                history={props.history} />
        )} />
    )
}

export default CollapsedApplicationContentMenu;