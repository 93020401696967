import React from 'react';
import { css } from '@emotion/css';

interface ModalLayoutProps {
    isVisible?: boolean
    onClose?: () => void
    className?: string
    innerClassName?: string
}
const layoutStyle = css`
    width: 100%;
    height: 100%;
    overflow-x: visible;
    background: rgba(44,61,82, 0.7);
    padding: 65px 115px;
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
`;

const innerLayoutStyle = css`
    border-radius: 20px;
    border: 12px solid rgba(44,61,82, 0.8);    
    display: block;
    width: 80%;
    max-width: 900px;
    min-height: 580px;
    max-height: calc(100vh - 20px);
    position: fixed;
    z-index: 1002;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    overflow: hidden;
`;
const innerWrapperStyle = css`
border-radius: 20px;
height: 100%;
`
const hiddenStyle = css`
    display: none;
`

const SmallModalLayout: React.FC<ModalLayoutProps> = (props) => (
    <div className={props.isVisible ? '' : hiddenStyle}>
        <div className={`${innerLayoutStyle} ${props.className ?? ''}`}>
            <div className={`${innerWrapperStyle} ${props.innerClassName ?? ''}`}>
                {props.children}
            </div>
        </div>
        <div className={layoutStyle} onClick={props.onClose}>
        </div>
    </div>
)

export default SmallModalLayout;