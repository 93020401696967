import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import ModalHeader from '../../../components/general/ModalHeader';
import PageError from '../../../components/general/PageError';
import FooterBar from '../../../components/structure/FooterBar';
import SmallModalLayout from '../../../components/structure/SmallModalLayout';
import { color_text_default } from '../../../constants/colors';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';

interface FullApplicationPublishProps {
    backupsContainer: ApplicationBackupVersionsContainer
    environmentsContainer: ApplicationEnvironmentsContainer
    applicationId: string
    primaryEnvironmentId: string
    onClose: () => void
}
interface FullApplicationPublishFormData {
    name: string
}
const InnerForm: React.FC<FullApplicationPublishProps & FormikProps<FullApplicationPublishFormData>> = (props) => { 

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        if(props.isSubmitting) return;

        if (e.key === "Enter") {
            props.handleSubmit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
    <SmallModalLayout isVisible onClose={props.onClose} className={customModalInner}>
        <ModalForm tabIndex={0} onKeyDown={handleKeyDown}>
            <ModalHeader title="Full Publish" onClose={props.onClose} />
            <div className="modal-body">
                <PublishConfirmationHeader>Publish all Pending Updates?</PublishConfirmationHeader>
                <p>This will create a saved backup of all your content and push all changes to any channels where your app is currently deployed.</p>
                <TextField autoFocus name="name" placeholder="Enter name here" label="Name" 
                    onBlur={props.handleBlur} onChange={props.handleChange} disabled={props.isSubmitting} />
            </div>
            <FooterBar>
                    <Button type="button" themes={['primary', 'start']} text="Publish" onClick={() => props.handleSubmit()} disabled={props.isSubmitting} loading={props.isSubmitting} />
                <Button type="button" themes={['secondary', 'end']} text="Cancel" onClick={() => props.onClose()} disabled={props.isSubmitting} loading={props.isSubmitting} />
            </FooterBar>
            
            <PageError errors={[
                ...props.backupsContainer?.state?.errors ?? [], 
                ...props.environmentsContainer?.state?.errors ?? [] 
            ]} onClear={() =>{
                props.backupsContainer.clearErrors(); 
                props.environmentsContainer.clearErrors();
            }} />
        </ModalForm>
    </SmallModalLayout>
)};

const FullApplicationPublishModal = withFormik<FullApplicationPublishProps, FullApplicationPublishFormData>({
    mapPropsToValues: props => ({ name: '' }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        const backupAndPublishResult = await props.environmentsContainer.queueFullBackupAndPublish(props.applicationId, 
            props.primaryEnvironmentId, 
            { name: values.name ?? 'Published', description: '' }
        );
        if (backupAndPublishResult?.resultType == "Ok") { 
            await props.backupsContainer.addBackup(backupAndPublishResult.data.backup);
            await props.environmentsContainer.addPublish(backupAndPublishResult.data.publish);
            props.backupsContainer.waitForBackup(backupAndPublishResult.data.backup.id);
            props.environmentsContainer.waitForPublish(backupAndPublishResult.data.publish.id);
            setSubmitting(false);
            props.onClose();
            return;
        }
    }
})(InnerForm);

const PublishConfirmationHeader = styled.h5`
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${color_text_default};
    margin-top:24px;
`
const customModalInner = css`
    height: 416px;
    min-height: 416px;
`
const ModalForm = styled.form`
    min-height: 416px;
    height: 416px;
    padding-bottom:64px;
    .modal-body{
        padding: 0 32px;
        margin-bottom: 180px;
        height: 100%;
        flex: 1;
        >h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        >p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 24px 0;
        }
    }
`

export default FullApplicationPublishModal;