import React from 'react';
import { css } from '@emotion/css';
import { silver_four } from '../../../../constants/colors';
import CheckIndicator from '../../../../components/forms/CheckIndicator';
import ToggleIndicator from '../../../../components/general/ToggleIndicator';

interface FilterOptionProps {
    isSelected: boolean
    isPartialSelected?: boolean
    icon?: string
    selectedIcon?: string
    title: string
    hasChildren?: boolean
    isChild?: boolean
    onClick: () => void
    onCheckClick?: () => void
    optionType: FilterOptionType
    isExpanded?: boolean
}

type FilterOptionType = "radio" | "check"

const FilterOption: React.SFC<FilterOptionProps> = (props) => (
    <div className={`${containerStyle} ${props.isChild ? 'child' : ''} ${props.isSelected ? 'selected' : ''}`} onClick={props.onClick}>
        {props.optionType == 'check' && <CheckIndicator
            checked={props.isSelected}
            partial={props.isPartialSelected}
            onClick={(event) => {
                event.stopPropagation();
                if (props.onCheckClick == null)
                    props.onClick();
                else props.onCheckClick();
            }}
        />
        }
        {props.icon && <img className="option-icon" src={props.isSelected ? props.selectedIcon : props.icon} />}
        <p>{props.title}</p>
        {props.hasChildren && <ToggleIndicator direction={props.isExpanded ? 'up' : 'down'} />}
    </div>
)

const containerStyle = css`
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 48px;
    background: white;
    border-bottom: 1px solid ${silver_four};
    cursor: pointer;
    .option-icon {
        width: 24px;
        height: 24px;
        margin: 0 8px 0 0;
    }
    &.child {
        padding-left: 45px;
        border-left: 2px solid ${silver_four};
        background: #FCFDFE;
    }

    .toggle-indicator {
        margi-right: 0;
        margin-left: auto;
    }

    &.selected {
        p {
            color: #1E7EB9;
        }
    }
`;

export default FilterOption