import * as yup from 'yup';
import { withFormik } from "formik";
import { TelephonyDeploymentFormData } from './TelephonyDeploymentFormData';
import TelephonyDeployment from './TelephonyDeployment';
import TelephonyDeploymentRequest from '../../../../models/telephonyDeployment/TelephonyDeploymentRequest';
import { TelephonyDeploymentResponse } from '../../../../models/telephonyDeployment/TelephonyDeploymentResponse';
import ApplicationContainer from '../../../../state/containers/ApplicationContainer';
import { defaultTelephonyDeploymentSettings } from '../../../../constants/telephonyConstants.tsx/defaultTelephonyDeploymentSettings';
import { vi } from 'date-fns/locale';

export interface TelephonyDeploymentProps {
    closeTelephonyRequestForm: () => void;
    submitTelephonyDeployment: (request: TelephonyDeploymentRequest, phoneNumber?: string) => void;
    environmentId: string;
    selectedTelephonyDeployment: TelephonyDeploymentResponse;
    appContainer: ApplicationContainer;
}

const TelephonyDeploymentForm = withFormik<TelephonyDeploymentProps, TelephonyDeploymentFormData>({
    mapPropsToValues: (props) => ({
        noTracking: props.selectedTelephonyDeployment?.noTracking ?? defaultTelephonyDeploymentSettings.noTracking,
        phoneNumberStartsWith: props.selectedTelephonyDeployment?.phoneNumber ? props.selectedTelephonyDeployment?.phoneNumber : defaultTelephonyDeploymentSettings.phoneNumberStartsWith,
        ttsVoice: props.selectedTelephonyDeployment?.ttsVoice ?? defaultTelephonyDeploymentSettings.ttsVoice,
        locale: props.selectedTelephonyDeployment?.locale ? props.selectedTelephonyDeployment?.locale : props.appContainer?.state?.currentApp?.languages[0]?.shortCode ? props.appContainer?.state?.currentApp?.languages[0]?.shortCode : defaultTelephonyDeploymentSettings.locale,
        asrEndpointing: props.selectedTelephonyDeployment?.asrEndpointing ?? defaultTelephonyDeploymentSettings.asrEndpointing,
        asrModel: props.selectedTelephonyDeployment?.asrModel ?? defaultTelephonyDeploymentSettings.asrModel,
        asrTier: props.selectedTelephonyDeployment?.asrTier ?? defaultTelephonyDeploymentSettings.asrTier,
        firstMessageDelayMS: props.selectedTelephonyDeployment?.firstMessageDelayMS ?? defaultTelephonyDeploymentSettings.firstMessageDelayMS,
        mergeTurnsAfterPauses: props.selectedTelephonyDeployment?.mergeTurnsAfterPauses ?? defaultTelephonyDeploymentSettings.mergeTurnsAfterPauses,
        mergeTurnTimeoutMS: props.selectedTelephonyDeployment?.mergeTurnTimeoutMS ?? defaultTelephonyDeploymentSettings.mergeTurnTimeoutMS,
        disableBargeIn: props.selectedTelephonyDeployment?.disableBargeIn ?? defaultTelephonyDeploymentSettings.disableBargeIn,
        repromptAttempts: props.selectedTelephonyDeployment?.repromptAttempts ?? defaultTelephonyDeploymentSettings.repromptAttempts,
        repromptDelaySeconds: props.selectedTelephonyDeployment?.repromptDelaySeconds ?? defaultTelephonyDeploymentSettings.repromptDelaySeconds,
        name: props.selectedTelephonyDeployment?.name ?? defaultTelephonyDeploymentSettings.name,
        ttsProvider: props.selectedTelephonyDeployment?.ttsProvider ?? defaultTelephonyDeploymentSettings.ttsProvider,
        proactiveResponseDelayMS: props.selectedTelephonyDeployment?.proactiveResponseDelayMS ?? defaultTelephonyDeploymentSettings.proactiveResponseDelayMS,
        proactiveResponses: props.selectedTelephonyDeployment?.proactiveResponses ?? defaultTelephonyDeploymentSettings.proactiveResponses,
        useProactiveResponses: props.selectedTelephonyDeployment?.useProactiveResponses ?? defaultTelephonyDeploymentSettings.useProactiveResponses,
        disableWelcomeMessageBargeIn: props.selectedTelephonyDeployment?.disableWelcomeMessageBargeIn ?? defaultTelephonyDeploymentSettings.disableWelcomeMessageBargeIn,
        disableSpeechStarted: props.selectedTelephonyDeployment?.disableSpeechStarted ?? defaultTelephonyDeploymentSettings.disableSpeechStarted,
        speechStartedUnconfirmedBargeInLimit: props.selectedTelephonyDeployment?.speechStartedUnconfirmedBargeInLimit ?? defaultTelephonyDeploymentSettings.speechStartedUnconfirmedBargeInLimit,
        speechStartedPauseMS: props.selectedTelephonyDeployment?.speechStartedPauseMS ?? defaultTelephonyDeploymentSettings.speechStartedPauseMS,
        speechStartedRequiredBufferCount: props.selectedTelephonyDeployment?.speechStartedRequiredBufferCount ?? defaultTelephonyDeploymentSettings.speechStartedRequiredBufferCount,
        callRecordingEnabled: props.selectedTelephonyDeployment?.callRecordingEnabled ?? defaultTelephonyDeploymentSettings.callRecordingEnabled,
        speechStartedLevel: props.selectedTelephonyDeployment?.speechStartedLevel ?? defaultTelephonyDeploymentSettings.speechStartedLevel,
        attemptInitialTransfer: props.selectedTelephonyDeployment?.attemptInitialTransfer ?? defaultTelephonyDeploymentSettings.attemptInitialTransfer,
        initialTransferTimeoutSeconds: props.selectedTelephonyDeployment?.initialTransferTimeoutSeconds ?? defaultTelephonyDeploymentSettings.initialTransferTimeoutSeconds,
        initialTransferTarget: props.selectedTelephonyDeployment?.initialTransferTarget ?? defaultTelephonyDeploymentSettings.initialTransferTarget,
        initialTransferWithCallingNumber: props.selectedTelephonyDeployment?.initialTransferWithCallingNumber ?? defaultTelephonyDeploymentSettings.initialTransferWithCallingNumber,
        virtualNumber: props.selectedTelephonyDeployment?.virtualNumber ?? defaultTelephonyDeploymentSettings.virtualNumber,
        useVirtualNumber: !!props.selectedTelephonyDeployment ? !!props.selectedTelephonyDeployment?.virtualNumber?.length : defaultTelephonyDeploymentSettings.useVirtualNumber
    }),
    validationSchema: yup.object().shape({
        asrEndpointing: yup.number().min(0, "Must be a number more than 0"),
        firstMessageDelayMS: yup.number().min(0, "Must be a number more than 0"),
        mergeTurnTimeoutMS: yup.number().min(0, "Must be a number more than 0"),
        repromptAttempts: yup.number().min(0, "Must be a number more than 0"),
        repromptDelaySeconds: yup.number().min(0, "Must be a number more than 0")
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {

        const telephonyRequest: TelephonyDeploymentRequest = {
            ...props.selectedTelephonyDeployment,
            noTracking: values.noTracking,
            phoneNumberStartsWith: values.phoneNumberStartsWith,
            environmentId: props.environmentId,
            ttsProvider: values.ttsProvider ?? "Google",
            ttsVoice: values.ttsVoice,
            locale: values.locale,
            asrEndpointing: values.asrEndpointing,
            asrModel: values.asrModel,
            asrTier: values.asrTier,
            firstMessageDelayMS: values.firstMessageDelayMS,
            mergeTurnsAfterPauses: values.mergeTurnsAfterPauses,
            mergeTurnTimeoutMS: values.mergeTurnTimeoutMS,
            disableBargeIn: values.disableBargeIn,
            repromptAttempts: values.repromptAttempts,
            repromptDelaySeconds: values.repromptDelaySeconds,
            name: values.name,
            proactiveResponseDelayMS: values.proactiveResponseDelayMS,
            proactiveResponses: values.proactiveResponses,
            useProactiveResponses: values.useProactiveResponses,
            disableWelcomeMessageBargeIn: values.disableWelcomeMessageBargeIn,
            disableSpeechStarted: values.disableSpeechStarted,
            speechStartedUnconfirmedBargeInLimit: values.speechStartedUnconfirmedBargeInLimit,
            speechStartedPauseMS: values.speechStartedPauseMS,
            speechStartedRequiredBufferCount: values.speechStartedRequiredBufferCount,
            callRecordingEnabled: values.callRecordingEnabled,
            speechStartedLevel: values.speechStartedLevel,
            attemptInitialTransfer: values.attemptInitialTransfer,
            initialTransferTimeoutSeconds: values.initialTransferTimeoutSeconds,
            initialTransferTarget: values.initialTransferTarget,
            initialTransferWithCallingNumber: values.initialTransferWithCallingNumber,
            virtualNumber: values.virtualNumber,
            useVirtualNumber: values.useVirtualNumber
        };

        setSubmitting(true);
        props.submitTelephonyDeployment(telephonyRequest);
        setSubmitting(false)
        props.closeTelephonyRequestForm();
    }
})(TelephonyDeployment);

export default TelephonyDeploymentForm;