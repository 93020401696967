import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import QuestionAnswerContainer from '../../state/containers/QuestionAnswerContainer';
import EditQuestionAnswerContentForm from './components/EditQuestionAnswerContentForm';
import FeatureContainer from '../../state/containers/FeatureContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import { QuestionAnswerFeatureTypeId } from '../../constants/featureTypeIds';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import GenericEditContentItemPage from '../formScenes/GenericEditContentItemPage';
import QuestionAnswerModel from '../../models/features/api/QuestionAnswers/QuestionAnswerModel';
import IResult from '../../models/result/IResult';
import queryString from 'query-string';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';

interface ApplicationRouteProps {
    applicationFeatureId: string
    questionAnswerId: string
}
interface EditQuestionAnswerProps extends RouteComponentProps<ApplicationRouteProps> { }

class EditQuestionAnswer extends React.Component<EditQuestionAnswerProps> {
    render() {
        return (
            <Subscribe key={this.props.match.params.questionAnswerId + this.props.match.params.applicationFeatureId} to={[
                QuestionAnswerContainer,
                ApplicationContainer,
                TitleStateContainer,
                RepromptContainer,
                FollowUpContainer,
                MediaResponseStateContainer,
                FeatureContainer,
                ApplicationEnvironmentsContainer,
                ApplicationBackupVersionsContainer]}>
                {(faqContainer: QuestionAnswerContainer,
                    applicationContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer,
                    environmentsContainer: ApplicationEnvironmentsContainer,
                    backupsContainer: ApplicationBackupVersionsContainer) => (
                    <GenericEditContentItemPage stateContainer={faqContainer}
                        environmentsContainer={environmentsContainer}
                        backupsContainer={backupsContainer}
                        titleContainer={titleContainer}
                        applicationContainer={applicationContainer}
                        defaultTitle="Edit Question Answer"
                        contentItemId={this.props.match.params.questionAnswerId}
                        history={this.props.history}
                        location={this.props.location}
                        match={this.props.match}
                        staticContext={this.props.staticContext}
                        featureContainer={featureContainer}
                        featureTypeId={QuestionAnswerFeatureTypeId}
                        formRender={(contentItem : QuestionAnswerModel,
                            application,
                            isTemplateOwned,
                            assistantApplication,
                            onShowPublishModal,
                            onSubmit: (model: QuestionAnswerModel, onSuccess) => Promise<IResult<QuestionAnswerModel>>,
                            toggleSync: (id, shouldNotSync) => Promise<IResult<QuestionAnswerModel>>,
                            onAccessTypeChange) => (
                            <EditQuestionAnswerContentForm
                                location={this.props.location}
                                applicationFeatureId={this.props.match.params.applicationFeatureId}
                                application={application}
                                appContainer={applicationContainer}
                                stateContainer={faqContainer}
                                questionAnswer={contentItem as QuestionAnswerModel}
                                followUpContainer={followUpContainer}
                                repromptContainer={repromptContainer}
                                responseStateContainer={responseContainer}
                                featureContainer={featureContainer}
                                assistantApplication={assistantApplication}
                                onShowPublishModal={onShowPublishModal}
                                onAccessTypeChange={onAccessTypeChange}
                                onSubmit={onSubmit}
                                onToggleSync={toggleSync}
                                previousContentId={queryString?.parse(this.props.location.search)?.previousContentId as string}
                                history={this.props.history}
                                isSaving={faqContainer.state.isSaving}
                                isTemplateOwned={isTemplateOwned} />
                        )} />
                )

                }
            </Subscribe>
        );
    }
}

export default EditQuestionAnswer;