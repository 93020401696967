import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from "../../../../components/general/Button";
import ModalHeader from "../../../../components/general/ModalHeader";
import FooterBar from "../../../../components/structure/FooterBar";
import ModalLayout from "../../../../components/structure/ModalLayout";
import { style_border_default } from "../../../../constants/stylesValues";
import { color_colors_ocean, color_shades_darkest, color_variants_ocean_light_opaque } from "../../../../constants/colors";
import { css } from "@emotion/css";
import LanguageContainer from "../../../../hooks/LanguageContainer";
import { FormikProps } from "formik";
import { CustomAssistantConfigurationsFormData } from "../../../../models/customAssistant/CustomAssistantConfigurationsFormData";
import { Theme } from "../../../../models/customAssistant/CustomAssistantConfigurationsTheme";
import SetupTabPanel from "./SetupTabPanel";
import AppearanceTabPanel from "./AppearanceTabPanel";
import ExportTabPanel from "./ExportTabPanel";
import { browserDarkStyle1, browserDarkStyle2, browserDefaultStyle, browserDefaultStyleMinimal, browserLightStyle1, browserLightStyle1Minimal, browserLightStyle2, browserLightStyle2Minimal, mobileDefaultStyle, mobileDarkStyle1, mobileDarkStyle2, mobileLightStyle1, mobileLightStyle2, browserDarkStyle2Minimal, browserDarkStyle1Minimal, mobileDarkStyle1Minimal, mobileDarkStyle2Minimal, mobileLightStyle1Minimal, mobileLightStyle2Minimal } from "../../../../constants/customAssistantConfigurationStyles/customAssistantConfigurationsStyleThemes";
import useWindowSize from "../../../../hooks/UseWindowSize";
import CustomScrollbars from "../../../../components/structure/CustomScrollbars";
import { initializeVoicifyAssistant } from "@voicify/voicify-browser-assistant-sdk";
import { defaultLightGray } from "../../../../constants/customAssistantConfigurationStyles/customAssistantConfigurationsColors";
import useCustomAssistantConfigurationColors from "../../hooks/useCustomAssistantConfigurationColors";
import { CustomAssistantConfigurationsProps } from "./CustomAssistantConfigurationsForm";
import { CodeExportLanguage } from "../../../../models/customAssistant/CustomAssistantCodeExportTypes";
import { CodeExportType } from "./CustomAssisantExportTypeDropdown";
import CustomAssistantConfigurationsContainer from "../../hooks/useCustomAssistantConfigurationSettings";

const appearanceIcon = require("../../../../content/images/custom-assistant-deployment/color-brush.svg");
const exportIcon = require("../../../../content/images/custom-assistant-deployment/export.svg");
const previewIcon = require("../../../../content/images/custom-assistant-deployment/preview.svg");
const setupIcon = require("../../../../content/images/custom-assistant-deployment/wrench.svg");
const assistantPreviewBackground = require("../../../../content/images/custom-assistant-deployment/assistant-preview-background.svg");

const MODAL_SCROLLBAR_SUBTRACTION: number = 240;

const CustomAssistantConfigurations: React.FC<CustomAssistantConfigurationsProps & FormikProps<CustomAssistantConfigurationsFormData>> = (props) => {
    const [voicifyAssistantId, setVoicifyAssistantId] = useState<string>(uuidv4());
    const [rerenderAssistant, setRerenderAssistant] = useState<boolean>(false);
    const [updateAssistant, setUpdateAssistant] = useState<boolean>(true);
    const languagesContainer = LanguageContainer.useContainer();
    const windowSize = useWindowSize();
    const previewParent = useRef(null);
    const applicationSettingsHook = CustomAssistantConfigurationsContainer.useContainer();
    const customAssistantColors = useCustomAssistantConfigurationColors();
    const [codeExportLanguage, setCodeExportLanguage] = useState<CodeExportLanguage>("JavaScript");
    const [exportType, setExportType] = useState<CodeExportType>("static");

    const customAssistantSDKVersion: string = '1.3.9';

    const hasPlatformConfigurations = props.configuration?.platformConfigurations;

    useEffect(() => {
        if (applicationSettingsHook.browserAssistantSettings !== null && updateAssistant) {
            initializeVoicifyAssistant(
                applicationSettingsHook.browserAssistantSettings,
                voicifyAssistantId
            );
        }
    }, [voicifyAssistantId, applicationSettingsHook.browserAssistantSettings, updateAssistant])

    useEffect(() => {
        const getLanguages = async () => {
            await languagesContainer.loadLanguages();
        };
        if (languagesContainer.languages.length === 0) {
            getLanguages();
        };
        const configLanguage = languagesContainer.languages?.find(l => l.shortCode === props.configuration.locale);
        if (configLanguage) {
            const configLanguageId = configLanguage.id;
            if (configLanguageId) {
                props.setFieldValue("defaultLanguageId", configLanguageId);
            }
        }
        if (hasPlatformConfigurations) {
            setExportType("dynamic");
        };
    }, []);

    useEffect(() => {
        if (!props.values.primaryColor) {
            customAssistantColors.clearColorStates();
        };
        if (props.values.primaryColor && customAssistantColors.isValidHexCode(props.values.primaryColor)) {
            customAssistantColors.createIconFilter(props.values.primaryColor);
            customAssistantColors.createGradient(props.values.primaryColor);
            customAssistantColors.createColorShade(props.values.primaryColor, 80);
            customAssistantColors.createHighlight(props.values.primaryColor, 0.2);
        } else if (!props.values.primaryColor || !customAssistantColors.isValidHexCode(props.values.primaryColor)) {
            customAssistantColors.createIconFilter('');
        }
    }, [props.values.primaryColor, customAssistantColors.colorShade, customAssistantColors.iconFilter, customAssistantColors.highlight])

    useEffect(() => {
        remountAssistant();
    }, [props.values, rerenderAssistant, previewParent, customAssistantColors.gradient, customAssistantColors.colorShade, customAssistantColors.highlight, customAssistantColors.iconFilter]);

    useEffect(() => {
        applicationSettingsHook.updateAssistantSettings(props.values, customAssistantColors.gradient, props.applicationId, props.appKey, getBrowserTheme(), getMobileTheme(), customAssistantColors.iconFilter, props.appAvatar, props.appName, customAssistantColors.highlight);
    }, [props.values, customAssistantColors.gradient, props.applicationId, props.appKey, props.values.themeStyle, customAssistantColors.iconFilter, props.appAvatar, props.appName, customAssistantColors.highlight, updateAssistant])

    const remountAssistant = () => {
        if (!previewParent.current) {
            return
        }
        const assistantElement = document.getElementById(voicifyAssistantId);
        if (assistantElement) {
            assistantElement.remove();
        }
        const id = uuidv4();
        const element = document.createElement("div");
        element.id = id;
        previewParent.current.appendChild(element);
        setVoicifyAssistantId(id);
        applicationSettingsHook.generateAssistantSettingsExport(customAssistantSDKVersion, codeExportLanguage, exportType, props.configId, props.applicationId, props.appKey);
    };

    const getBrowserTheme = () => {
        let theme: Theme;
        switch (props.values.themeStyle) {
            case "lightStyle1":
                if (props.values.uiType === "minimal") {
                    theme = browserLightStyle1Minimal
                } else {
                    theme = browserLightStyle1
                }
                break;
            case "lightStyle2":
                if (props.values.uiType === "minimal") {
                    theme = browserLightStyle2Minimal
                } else {
                    theme = browserLightStyle2
                }
                break;
            case "darkStyle1":
                if (props.values.uiType === "minimal") {
                    theme = browserDarkStyle1Minimal
                } else {
                    theme = browserDarkStyle1
                }
                break;
            case "darkStyle2":
                if (props.values.uiType === "minimal") {
                    theme = browserDarkStyle2Minimal
                } else {
                    theme = browserDarkStyle2
                }
                break;
            default:
                if (props.values.uiType === "minimal") {
                    theme = browserDefaultStyleMinimal
                } else {
                    theme = browserDefaultStyle
                }
                break;
        };

        return theme;
    }

    const getMobileTheme = (): Theme => {
        let theme: Theme;
        switch (props.values.themeStyle) {
            case "lightStyle1":
                if (props.values.uiType === "minimal") {
                    theme = mobileLightStyle1Minimal
                } else {
                    theme = mobileLightStyle1
                }
                break;
            case "lightStyle2":
                if (props.values.uiType === "minimal") {
                    theme = mobileLightStyle2Minimal
                } else {
                    theme = mobileLightStyle2
                }
                break;
            case "darkStyle1":
                if (props.values.uiType === "minimal") {
                    theme = mobileDarkStyle1Minimal
                } else {
                    theme = mobileDarkStyle1
                }
                break;
            case "darkStyle2":
                if (props.values.uiType === "minimal") {
                    theme = mobileDarkStyle2Minimal
                } else {
                    theme = mobileDarkStyle2
                }
                break;
            default:
                theme = mobileDefaultStyle
                break;
        };

        return theme;
    }

    const handleOnChange = (optionValue: string, value: string | boolean) => {
        props.setFieldValue(optionValue, value);
    };

    const handleCodeExportLanguageChange = (optionValue: string, value: CodeExportLanguage) => {
        setCodeExportLanguage(value)
        applicationSettingsHook.generateAssistantSettingsExport(customAssistantSDKVersion, value, exportType, props.configId, props.applicationId, props.appKey);
    };

    const handleExportTypeChange = (optionValue: string, value: CodeExportType) => {
        setExportType(value)
        applicationSettingsHook.generateAssistantSettingsExport(customAssistantSDKVersion, codeExportLanguage, value, props.configId, props.applicationId, props.appKey);
    };

    return (
        <ModalLayout isVisible={true}>
            <ModalHeader title="Configure a Custom Deployment Channel" onClose={props.closeCustomConfigForm} />
            <form onSubmit={props.handleSubmit} >
                <ContentWrapper>
                    <Tabs className={tabWrapper}>
                        <TabList className={modalSubHeader}>
                            <Tab
                                className={tabItem}
                                disabledClassName={tabItemTitleDisabled}
                                selectedClassName={tabItemTitleSelected}>
                                <img className={tabItemIcon} src={setupIcon} />
                                <p>Setup</p>
                            </Tab>
                            <Tab
                                className={tabItem}
                                disabledClassName={tabItemTitleDisabled}
                                selectedClassName={tabItemTitleSelected}>
                                <img className={tabItemIcon} src={appearanceIcon} />
                                <p>Appearance</p>
                            </Tab>
                        </TabList>
                        <TabPanel className={setupTabPanel}>
                            <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                                <SetupTabPanel
                                    {...props}
                                    handleOnChange={handleOnChange}
                                />
                            </CustomScrollbars>
                        </TabPanel>
                        <TabPanel className={appearanceTabPanel}>
                            <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                                <AppearanceTabPanel
                                    {...props}
                                    handleOnChange={handleOnChange}
                                    codeExportLanguage={codeExportLanguage}
                                />
                            </CustomScrollbars>
                        </TabPanel>
                    </Tabs>
                    <Tabs onSelect={(index) => {
                        if (index === 0) {
                            setRerenderAssistant(!rerenderAssistant);
                            setUpdateAssistant(true);
                        }
                        else {
                            applicationSettingsHook.generateAssistantSettingsExport(customAssistantSDKVersion, codeExportLanguage, exportType, props.configId, props.applicationId, props.appKey);
                            //dont update assistant preview while on export tab
                            setUpdateAssistant(false);
                        }
                    }} className={tabWrapper}>
                        <TabList className={modalSubHeader}>
                            <Tab
                                className={tabItem}
                                disabledClassName={tabItemTitleDisabled}
                                selectedClassName={tabItemTitleSelected}>
                                <img className={tabItemIcon} src={previewIcon} />
                                <p>Preview</p>
                            </Tab>
                            <Tab
                                className={tabItem}
                                disabledClassName={tabItemTitleDisabled}
                                selectedClassName={tabItemTitleSelected}>
                                <img className={tabItemIcon} src={exportIcon} />
                                <p>Export</p>
                            </Tab>
                        </TabList>
                        <TabPanel className={previewTabPanel}>
                            <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                                <ConfigurationTabContent>
                                    <SubHeader>Scaled Preview</SubHeader>
                                    {props.values.uiType === "minimal" ?
                                        <AssistantPreviewWrapperMinimal>
                                            <VoicifyAssistantRootMinimal ref={previewParent} id="voicifyAssistantRoot" />
                                        </AssistantPreviewWrapperMinimal>
                                        :
                                        <AssistantPreviewWrapper>
                                            <VoicifyAssistantRoot ref={previewParent} id="voicifyAssistantRoot" />
                                        </AssistantPreviewWrapper>
                                    }
                                </ConfigurationTabContent>
                            </CustomScrollbars>
                        </TabPanel>
                        <TabPanel className={exportTabPanel}>
                            <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                                <ExportTabPanel
                                    copyText={props.copyText}
                                    assistantSettingsExport={applicationSettingsHook.assistantSettingsExport}
                                    codeExportLanguage={codeExportLanguage}
                                    handleCodeExportLanguageChange={handleCodeExportLanguageChange}
                                    isLoading={applicationSettingsHook.loading || props.isLoading}
                                    exportType={exportType}
                                    handleExportTypeChange={handleExportTypeChange}
                                    hasPlatformConfigurations={hasPlatformConfigurations}
                                />
                            </CustomScrollbars>
                        </TabPanel>
                    </Tabs>
                </ContentWrapper>
                <FooterBar>
                    <Button disabled={props.isSubmitting} loading={props.isSubmitting} type="submit" themes={['primary', 'start']} text="Save & Close" />
                    <Button disabled={props.isSubmitting} loading={props.isSubmitting} type="button" themes={['secondary', 'end']} text="Cancel" onClick={props.closeCustomConfigForm} />
                </FooterBar>
            </form>
        </ModalLayout>
    )
}

const SubHeader = styled.h2`
    font-size: 18px;
    color: ${color_shades_darkest};
    font-weight: 400;
    margin-bottom: 16px;
`;

const VoicifyAssistantRoot = styled.div`
    transform: scale(0.62);
`;

const VoicifyAssistantRootMinimal = styled.div`
    transform: scale(0.36);
`;

const AssistantPreviewWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-top: 4px solid ${defaultLightGray};
    border-right: 4px solid ${defaultLightGray};
    border-bottom: 4px solid ${defaultLightGray};
    padding-top: 16px;
    background: url(${assistantPreviewBackground});
    height: 78%;
`;

const AssistantPreviewWrapperMinimal = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-top: 4px solid ${defaultLightGray};
    border-right: 4px solid ${defaultLightGray};
    border-bottom: 4px solid ${defaultLightGray};
    padding-top: 16px;
    background: url(${assistantPreviewBackground});
    height: 78%;
`;

const ConfigurationTabContent = styled.div`
    padding: 24px 24px 0 24px;
`;

const exportTabPanel = css`
    border-left: ${style_border_default};
`;

const previewTabPanel = css`
    border-left: ${style_border_default};
`;

const appearanceTabPanel = css`
    border-left: ${style_border_default};
`;

const setupTabPanel = css`
    border-left: ${style_border_default};
`;

const ContentWrapper = styled.div`
    display: flex;
`;

const tabWrapper = css`
    flex: 1;
`;

const modalSubHeader = css`
    display: flex;
    flex-direction: row;
    padding-left: 24px;
    border-bottom: ${style_border_default};
    border-left: ${style_border_default};
`;

const tabItem = css`
    font-size: 10px;
    cursor: pointer;
    width: 80px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const tabItemIcon = css`
    padding-bottom: 4px;
`

const tabItemTitleDisabled = css`
    color: ${color_shades_darkest};
`;

const tabItemTitleSelected = css`
    color: ${color_colors_ocean};
    background-color: ${color_variants_ocean_light_opaque};
    img {
        filter: invert(49%) sepia(18%) saturate(1878%) hue-rotate(159deg) brightness(85%) contrast(99%);
    }
`;

export default CustomAssistantConfigurations;