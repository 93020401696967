import React from 'react';
import { css } from '@emotion/css';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import { color_shades_dark, color_shades_light } from '../../../constants/colors';
const incompleteIcon = require('../../../content/images/templates-icons/incomplete-white.svg');
const readyIcon = require('../../../content/images/templates-icons/templates-sync-white.svg');
const completeIcon = require('../../../content/images/templates-icons/complete-white.svg');
interface TemplateStatusProps {
    status: Status
}

type Status = "synced" | "incomplete" | "complete" | "not-enabled" // note: we will likely need more status states in the future

const getStatusIcon = (status: Status) => {
    if (status == "incomplete" || status == "not-enabled") return incompleteIcon;
    if (status == "complete") return readyIcon;
    return completeIcon;
}
const getStatusLabel = (status: Status) => {
    switch (status) {
        case "synced": return "In Use";
        case "complete": return "Ready";
        case "incomplete": return "Incomplete";
        case "not-enabled": return "Not Enabled";
    }
}
const AppliedTemplateStatusIndicator: React.SFC<TemplateStatusProps> = (props) => {
    return (
        <div className={"status-indicator " + containerStyle}>
            <div>
                <p className="label">Template Status:</p>
                <p className="status">{getStatusLabel(props.status)}</p>
            </div>
            <img src={getStatusIcon(props.status)} />
        </div>
    );

}
const containerStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: 32px;
    img {
        margin-left: 12px;
        border-right: 1px solid ${color_shades_dark};
        border-left: 1px solid ${color_shades_dark};
        padding: 0 12px;
        height: 24px;
    }
    .label {
        font-family: Muli;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: ${color_shades_light};
        cursor: pointer;
        text-align: right;
    }
    .status {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: white;
        cursor: pointer;
        
    }   
`
export default AppliedTemplateStatusIndicator;