import { GET_CONFIGURATION_URL } from '../urls';
import fetch from 'cross-fetch';

export const getConfiguration = (serverRootUrl: string, configurationId: string, appId: string, appKey: string) => {
    const endpoint = GET_CONFIGURATION_URL(serverRootUrl, configurationId, appId, appKey);
    return (
        fetch(endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
    )
};