import React from 'react';
import { Subscribe } from 'unstated';
import HelpMessageContainer from '../../state/containers/HelpMessageContainer';
import EditHelpMessageForm from './components/EditHelpMessageForm';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import GenericEditContentItemPage from '../formScenes/GenericEditContentItemPage';
import IResult from '../../models/result/IResult';
import HelpMessageModel from '../../models/features/api/Help/HelpMessageModel';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { HelpMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import queryString from 'query-string';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';

interface ApplicationRouteProps {
    applicationFeatureId: string
    helpMessageId: string
}
interface EditHelpMessageProps extends RouteComponentProps<ApplicationRouteProps> { }

class EditHelpMessage extends React.Component<EditHelpMessageProps> {
    render() {
        return (
            <Subscribe key={this.props.match.params.helpMessageId + this.props.match.params.applicationFeatureId} to={[
                HelpMessageContainer,
                ApplicationContainer,
                TitleStateContainer,
                RepromptContainer,
                FollowUpContainer,
                MediaResponseStateContainer,
                FeatureContainer,
                ApplicationEnvironmentsContainer,
                ApplicationBackupVersionsContainer]}>
                {(helpContainer: HelpMessageContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer,
                    environmentsContainer: ApplicationEnvironmentsContainer,
                    backupsContainer: ApplicationBackupVersionsContainer) => {
                    return (
                        <GenericEditContentItemPage stateContainer={helpContainer}
                            environmentsContainer={environmentsContainer}
                            backupsContainer={backupsContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="Edit Help Message"
                            contentItemId={this.props.match.params.helpMessageId}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={HelpMessagesFeatureTypeId}
                            formRender={(contentItem: HelpMessageModel,
                                application,
                                isTemplateOwned,
                                assistantApplication,
                                onShowPublishModal,
                                onSubmit: (model: HelpMessageModel, onSuccess) => Promise<IResult<HelpMessageModel>>,
                                toggleSync: (id, shouldNotSync) => Promise<IResult<HelpMessageModel>>,
                                onAccessTypeChange) => (
                                <EditHelpMessageForm
                                    location={this.props.location}
                                    application={application}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    helpMessage={contentItem}
                                    stateContainer={helpContainer}
                                    appContainer={appContainer}
                                    followUpContainer={followUpContainer}
                                    repromptContainer={repromptContainer}
                                    responseStateContainer={responseContainer}
                                    featureContainer={featureContainer}
                                    assistantApplication={assistantApplication}
                                    onShowPublishModal={onShowPublishModal}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onSubmit={onSubmit}
                                    onToggleSync={toggleSync}
                                    previousContentId={queryString?.parse(this.props.location.search)?.previousContentId as string}
                                    history={this.props.history}
                                    isSaving={helpContainer.state.isSaving}
                                    isTemplateOwned={isTemplateOwned} />
                            )} />
                    );
                }
                }
            </Subscribe >
        );
    }
}

export default EditHelpMessage;