import {Container} from 'unstated';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import * as voicifyApi from '../../api';

type MenuState = {
    isDrawerOpen: boolean
    applications: ApplicationModel[]
    isLoading: boolean
}

export default class MenuStateContainer extends Container<MenuState> {
    state = {
        isDrawerOpen: localStorage.getItem("menu") != "false",
        isLoading: false,
        applications: []
    }

    loadApplicationsAndFeatures(organizationId: string) {
        this.setLoading(true);
        voicifyApi.getApplicationsWithFeaturesForOrg(organizationId).then(result => {
            if(result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    applications: result.data
                })
            }
            else {
                this.setLoading(false);
            }
        });
    }

    toggleDrawer() {
        var isDrawerOpen = this.state.isDrawerOpen;
        localStorage.setItem("menu", (!isDrawerOpen).toString())
        this.setState({
            ...this.state,
            isDrawerOpen: !isDrawerOpen
        })
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading
        })
    }
}