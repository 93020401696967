import React, { useState, useEffect } from "react";
import { css } from '@emotion/css';
import { color_colors_growth, color_shades_dark, color_text_default, color_text_light, silver_four } from "../../../constants/colors";
import LanguageModel from "../../../models/features/api/LanguageModel";
import ToggleIndicator from "../../../components/general/ToggleIndicator";
import Button from "../../../components/general/Button";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import UserApplicationPreferencesContainer from "../../../state/containers/UserApplicationPreferencesContainer";
import { SimulatorMessage } from "./SimulatorInteraction";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import useWindowSize from "../../../hooks/UseWindowSize";
import styled from "@emotion/styled";

const greenPowerIcon = require("../../../content/images/simulator/green-power.svg");
const removeThumbtackIcon = require("../../../content/images/simulator/remove-thumbtack.svg");
const helpIconBlack = require('../../../content/images/help-black.svg');
const fallbackIconBlack = require('../../../content/images/fallback-black.svg');
const welcomeIconBlack = require('../../../content/images/welcome-black.svg');
const exitIconBlack = require('../../../content/images/exit-black.svg');

interface SimulatorPinnedMessagesPanelProps {
    applicationId: string,
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer,
    languages: LanguageModel[]
    selectedLanguage?: LanguageModel,
    requests?: SimulatorMessage[],
    handleSendMessage: (text: string) => void
    handleSendWelcome: () => void
    handleStartNewSession: () => void
};

const SimulatorPinnedMessagesPanel: React.FC<SimulatorPinnedMessagesPanelProps> = (props) => {
    const [defaultMessagesExpanded, setDefaultMessagesExpanded] = useState(true);

    const windowSize = useWindowSize();

    const toggleDefaultMessagesExpanded = () => {
        setDefaultMessagesExpanded(!defaultMessagesExpanded)
    }

    useEffect(() => {
        props.userApplicationPreferencesContainer.loadUserApplicationPreferences(props.applicationId);
    }, [props.applicationId])

    const pinnedMessages = props.userApplicationPreferencesContainer.state.currentUserApplicationPreferences?.simulatorPinnedMessages ?? [];
    return (
        <div className={containerStyle}>
            <PinnedMessagesHeadingWrapper>
                <div className="title-container">
                    <div className="title">Pinned Messages</div>
                    <div className="subtitle">Saved messages that you can quickly add to the conversation</div>
                </div>
                <div className={defaultPinned}>
                    <div onClick={toggleDefaultMessagesExpanded} className="defaults-expander">
                        {defaultMessagesExpanded
                            ? <ToggleIndicator direction="up" />
                            : <ToggleIndicator direction="down" />}
                        Defaults
                    </div>
                    <span className={`${fill} line`}></span>
                </div>
                {defaultMessagesExpanded &&
                    <div className={defaultButtons}>
                        <Button icon={greenPowerIcon} themes={["white-pill"]} className={greenText} text="Restart session" onClick={() => props.handleStartNewSession()} />
                        <div className={generalResponses}>
                            <Button icon={welcomeIconBlack} themes={["white-pill"]} disabled={props.requests.length > 0} className={generalResponseButton} text="Welcome" onClick={() => props.handleSendWelcome()} />
                            <Button icon={helpIconBlack} themes={["white-pill"]} className={generalResponseButton} text="Help" onClick={() => props.handleSendMessage("Help")} />
                            <Button icon={exitIconBlack} themes={["white-pill"]} className={generalResponseButton} text="Exit" onClick={() => props.handleSendMessage("Exit")} />
                            <Button icon={fallbackIconBlack} themes={["white-pill"]} className={generalResponseButton} text="System Fallback" onClick={() => props.handleSendMessage("[Unhandled Response]")} />
                        </div>
                    </div>
                }
                {defaultMessagesExpanded && <HorizontalSeparator />}
            </PinnedMessagesHeadingWrapper>
            <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - 353}>
                <Phrases>
                    {pinnedMessages.map((pm, idx) =>
                        <Phrase key={idx}>
                            <PhraseChip onClick={() => props.handleSendMessage(pm.message)}>
                                {pm.message}
                            </PhraseChip>
                            <div className="remove-thumbtack" onClick={() => props.userApplicationPreferencesContainer.deleteSimulatorPinnedMessage(props.applicationId, pm.id)}>
                                <img src={removeThumbtackIcon} />
                            </div>
                        </Phrase>
                    )}
                </Phrases>
            </CustomScrollbars>
        </div>
    )
}

const PinnedMessagesHeadingWrapper = styled.div`
    padding: 0 24px;
`;

const Phrases = styled.div`
    padding: 0 24px;
`;

const greenText = css`
    color: ${color_colors_growth};
    margin-left: 0px;
`;

const generalResponseButton = css`
    margin-right: 8px;
    margin-left: 0px;
    margin-top:0px;
`;

const defaultButtons = css`
    margin-top: -15px;
`;

const generalResponses = css`
    display: flex;
    display: block;
`;

const Phrase = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 8 0;
    cursor: pointer;
    .remove-thumbtack {
        height: 56px;
        width: 24px;
        margin-left: 8px;
        img {
            width: 100%;
            margin-top: 16px;
        }
    }
    &:first-of-type {
        margin-top: 24px;
    }
    &:last-of-type {
        margin-bottom: 24px;
    }
`;

const PhraseChip = styled.div`
    background: white;
    border: 1px solid ${silver_four};
    border-radius: 8px;
    width: 100%;
    padding: 16px 24px;    
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
`;

const defaultPinned = css`
    display: flex;
    flex-direction: row;
    .defaults-expander{
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        padding-right: 5px;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        cursor: pointer;
        text-align:left;
        flex-direction: row;
        display:flex;
        padding-bottom: 15px;
        div {
            margin-right: 10px;
            margin-top: 6px;
        }
    }
`;

const fill = css`
    flex: 1;
    &.line {
        margin-left: 5px;
        border-bottom: solid 1px ${color_shades_dark};        
        margin-bottom: 24px;
    }
`;

const containerStyle = css`
    padding-top: 32px;
    max-width: 432px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    .title-container {
        .title {
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 18px;
            color: ${color_text_default}
        }
        .subtitle {
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 12px;
            color: ${color_text_light}
        }
    }
`;

export default SimulatorPinnedMessagesPanel