import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import UserContainer from "../../../state/containers/UserContainer";
import MediaContainer from "../../../state/containers/MediaContainer";
import MediaResponseStateContainer from "../../../state/containers/MediaResponseContainer";
import SsmlContainer from "../../../state/containers/SsmlContainer";
import React, { useEffect, useRef, useState } from "react";
import SimulatorInteraction from "./SimulatorInteraction";
import SimulatorInputPanel from "./SimulatorInputPanel";
import Loader from "../../../components/general/Loader";
import SimulatorOutputPanel from "./SimulatorOutputPanel";
import { Dictionary } from "lodash";
import IGenericContentContainer from "../../../state/definitions/IGenericContentContainer";
import ContentItemModel from "../../../models/features/api/ContentItemModel";
import PageError from "../../../components/general/PageError";
import PanelContainer from "../../../components/structure/Panels/PanelContainer";
import UserApplicationPreferencesContainer from "../../../state/containers/UserApplicationPreferencesContainer";
import ApplicationModel from "../../../models/applications/api/ApplicationModel";
import SimulatorContainer from '../../../hooks/SimulatorContainer';

interface SimulatorProps {
    appContainer: ApplicationContainer,
    userContainer: UserContainer,
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer,
    mediaContainer: MediaContainer,
    mediaResponseContainer: MediaResponseStateContainer,
    ssmlContainer: SsmlContainer,
    applicationId: string,
    contentContainers: Dictionary<IGenericContentContainer<ContentItemModel, any, any, any>>
}

export type SimulatorEnvironment = "live" | "draft";

const Simulator = (props: SimulatorProps) => {

    const messageBarInput = useRef(null as HTMLInputElement);

    const application = props.appContainer.state.currentApp;

    const simulatorContainer = SimulatorContainer.useContainer();

    useEffect(() => {
        const selectedLanguage = application?.languages?.length > 0 ? (application.languages.find(l => l.id == application.defaultLanguageId) ?? application?.languages[0]) : null

        simulatorContainer.handleLanguageSelected(selectedLanguage);

        const asyncLoad = async () => {
            await props.appContainer.loadCurrentAppById(props.applicationId);
            let devices = props.mediaResponseContainer.state.deviceTargets;
            if (!(devices?.length > 0)) {
                const result = await props.mediaResponseContainer.getDeviceTargets();
                devices = result.data;
            }
            const alexaSpeaker = devices.find(d => d.assistant == "Alexa" && d.supportsRichAudio && !d.supportsForegroundImages);
            simulatorContainer.handleDeviceSelected(alexaSpeaker);
        }
        asyncLoad();
    }, [props.applicationId]);

    const handleSendMessage = async (text: string) => {
        await sendMessage(text, application);
    };

    const handleSendWelcome = async () => {
        const text = `Open ${application.invocationPhrase}`;
        await sendMessage(text, application);
    };

    const sendMessage = async (text: string, application: ApplicationModel) => {
        simulatorContainer.addMessage(text);

        await simulatorContainer.sendCustomAssistantRequest(
            application,
            text,
            props.userContainer.state.currentUser.id,
            simulatorContainer.sessionId,
            simulatorContainer.selectedLanguage,
            props.contentContainers);

        messageBarInput?.current.focus();
    };

    if (!(props.mediaResponseContainer.state.deviceTargets?.length > 0))
        return (
            <Loader />
        )

    return (
        <>
            <PanelContainer>
                <SimulatorInputPanel
                    appContainer={props.appContainer}
                    handleSendWelcome={handleSendWelcome}
                    userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                    applicationId={application.id}
                    languages={application.languages}
                    requests={simulatorContainer.requests}
                    selectedLanguage={simulatorContainer.selectedLanguage}
                    handleSendMessage={handleSendMessage}
                    handleLanguageSelected={(language) => simulatorContainer.handleLanguageSelected(language)}
                    handleStartNewSession={simulatorContainer.startNewSession}
                />

                <SimulatorInteraction
                    applicationId={application.id}
                    handleSendWelcome={handleSendWelcome}
                    responseContainer={props.mediaResponseContainer}
                    userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                    ssmlContainer={props.ssmlContainer}
                    requests={simulatorContainer.requests}
                    responses={simulatorContainer.responses}
                    selectedLanguage={simulatorContainer.selectedLanguage}
                    selectedDevice={simulatorContainer.deviceTarget}
                    isWaitingForResponse={simulatorContainer.isWaitingForResponse}
                    handleSendMessage={handleSendMessage}
                    handleDeviceSelected={(dev) => simulatorContainer.handleDeviceSelected(dev)}
                    simulatorMessageBarInputRef={messageBarInput}
                    handleSetEnvironment={(env) => simulatorContainer.setEnvironment(env)}
                    useDraftContent={simulatorContainer.useDraftContent}
                />

                <SimulatorOutputPanel
                    conversationHistory={simulatorContainer.getConversationHistory()}
                    contentItems={simulatorContainer.contentItemsFromHistory}
                />
            </PanelContainer>
            <PageError errors={[
                    ...simulatorContainer.errors ?? [],
                    ...props.appContainer?.state?.errors ?? [],
                    ...props.userContainer?.state?.errors ?? [],
                    ...props.userApplicationPreferencesContainer?.state?.errors ?? [],
                    ...props.mediaContainer?.state?.errors ?? [],
                    ...props.mediaResponseContainer?.state?.errors ?? [],
                    ...props.ssmlContainer?.state?.errors ?? [] 
                ]} onClear={() => {
                    simulatorContainer.clearErrors();
                    props.appContainer.clearErrors();
                    props.userContainer.clearErrors();
                    props.userApplicationPreferencesContainer.clearErrors();
                    props.mediaContainer.clearErrors();
                    props.mediaResponseContainer.clearErrors();
                    props.ssmlContainer.clearErrors(); 
                }} />
        </>
    )
};

export default Simulator;