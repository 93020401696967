import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import React from "react";
import { css } from '@emotion/css';
import { color_text_default, color_text_light, color_shades_dark } from "../../../constants/colors";
import LanguageModel from "../../../models/features/api/LanguageModel";
import SimulatorLanguageSelector from "./SimulatorLanguageSelector";
const flagIcon = require("../../../content/images/flags/Default.svg")

interface SimulatorUserProfileProps {
    applicationId: string,
    appContainer: ApplicationContainer,
    languages: LanguageModel[]
    selectedLanguage?: LanguageModel,
    handleLanguageSelected: (selectedLanguage: LanguageModel) => void
}

const SimulatorUserProfile: React.FC<SimulatorUserProfileProps> = ({ languages, selectedLanguage, handleLanguageSelected }) => (
    <div className={containerStyle}>
        <div className="title-container">
            <div className="title">User Profile</div>
            <div className="subtitle">Configure the settings of the end-user you’d like to speak as in the simulator</div>
        </div>
        <div className="language-container">
            <div className="title">
                <img src={flagIcon}></img>
                <span>Language / Region</span>
            </div>
            <SimulatorLanguageSelector languages={languages} selectedLanguage={selectedLanguage} handleLanguageSelected={handleLanguageSelected} />
        </div>
    </div>
);

const containerStyle = css`
    padding: 24px;
    padding-top: 32px;
    max-width: 432px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    .title-container {
        .title {
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 18px;
            color: ${color_text_default}
        }
        .subtitle {
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 12px;
            color: ${color_text_light}
        }
    }
    .language-container {
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 8px;
        max-width: 368px;
        height: 128px;
        padding: 24px;
        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            color: ${color_text_default}
        }
    }
`;

export default SimulatorUserProfile