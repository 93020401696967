import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaRectangleWrapper from './AlexaRectangleWrapper';

const screenStyle = css`
    color: white;
    .body-text, .title-text {
        font-family: 'Roboto', sans-serif;
        font-size: 80px;
        padding-top: 80px;
        padding-left: 160px;
        color: white;    
        overflow-wrap: break-word;
        z-index: 2;
        position: relative;
    }
    .body-text {
        overflow-y: hidden;
        text-align: center;
        font-size: 40px;
        margin-top: 30px;
        padding: 0;
    }
    .body-container {
        display: flex;
        z-index: 2;
        position: relative;
        align-items: baseline;
        margin-top: 150px;
        justify-content: center;
    }
    .hint-text {
        font-family: 'Noto Serif', serif;
        font-size: 60px;
        color: white;
        font-style: italic;
        z-index: 2;
        padding-left: 160px;
        position: relative;
        margin-bottom: 20px;
        margin-top: auto;
    }
    .foreground-image {
        width: 400px;
        height: 400px;
        z-index: 2;
        img {
            height: 100%;
            width: auto;
        }
        &.empty {
            background: #CCCCCC;
        }
    }
    .list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 160px;
        max-width: 440px;
    }
    .background-image {
        width: 1920px;
        height: 1080px;
        position: absolute;
        z-index: 1;
    }
`
class AlexaListTemplate2Preview extends ScreenPreviewComponent {
    getHintText() {
        if (this.state.hints && this.state.hints.length > 0) {
            const hint = this.state.hints[0];
            return `Try "Alexa, ${hint.content}"`;
        }
        return '';
    }
    renderAdditionalItems() {
        const items = [];
        for(var i = 0; i < this.props.response.additionalListItems; i++) {
            items.push(i);
        }
        return items.map((_, i) => (
            <div key={i} className="list-item">
                <div className="foreground-image empty"></div>
                <p className="body-text">
                    <span>{i+2}. Additional Item</span>
                </p>
            </div>
        ))
    }
    render() {
        return (
            <AlexaRectangleWrapper className={screenStyle}>
                {this.state.backgroundImageUrl ? <img className="background-image" src={this.state.backgroundImageUrl} /> : null}
                {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                <div className="body-container">
                    <div className="list-item">
                        {this.state.foregroundImageUrl
                            ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                            : <div className="foreground-image empty"></div>}
                        <p className="body-text">
                            <span>1. {this.state.displayText}</span>
                        </p>
                    </div>
                    {this.props.response.additionalListItems ? this.renderAdditionalItems() : null}
                </div>
                {this.state.hints ? <p className="hint-text">{this.getHintText()}</p> : null}
            </AlexaRectangleWrapper>
        )
    }
}

export default AlexaListTemplate2Preview;