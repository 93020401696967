import React from 'react';
import { css } from '@emotion/css';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import MediaContainer from '../../state/containers/MediaContainer';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import UserVariationsBubble from './UserVariationsBubble';
import SsmlContainer from '../../state/containers/SsmlContainer';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';
import DeviceTargetSelector from './DeviceTargetSelector';
import DeviceScreenVariationsBubble from './DeviceScreenVariationsBubble';
import ConversationBranchSwitcher from '../structure/ConversationFlow/ConversationBranchSwitcher';
import _ from 'lodash';
import LanguageModel from '../../models/features/api/LanguageModel';

interface ScreenConversationFlowViewerProps {
    applicationId: string
    mediaContainer: MediaContainer
    responseContainer: MediaResponseStateContainer
    ssmlContainer: SsmlContainer
    conversationItems: ConversationItem[]
    targets: DeviceTargetModel[]
    endSession: boolean
    emptyLabel?: string
    languages?: LanguageModel[]
}

interface ScreenConversationFlowViewerState {
    deviceTarget: DeviceTargetModel
}


const containerStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

`

class ScreenConversationFlowViewer extends React.Component<ScreenConversationFlowViewerProps, ScreenConversationFlowViewerState> {
    componentWillMount() {
        this.setState({
            deviceTarget: this.getDefaultDevice()
        })
    }
    getDefaultDevice() {
        if(this.props.targets) {
            const alexaMedium = this.props.targets.find(t => t.assistant == "Alexa" && t.displayName.includes("Medium"));
            return alexaMedium ? alexaMedium : this.props.targets[0];
        }
        return null;
    }
    handleTargetChange(target: DeviceTargetModel) {
        this.setState({
            ...this.state,
            deviceTarget: target
        })
    }
    renderBlock(conversationItem: ConversationItem, index: number) {
        return (
            <div key={index}>
                <UserVariationsBubble title={conversationItem.requestTitle} variations={conversationItem.requests} />
                <DeviceScreenVariationsBubble
                    title={conversationItem.responseTitle}
                    variations={conversationItem.responses}
                    reprompt={conversationItem.reprompt}
                    followUp={conversationItem.followUp}
                    ssmlContainer={this.props.ssmlContainer}
                    deviceTarget={this.state.deviceTarget} 
                    languages={this.props.languages}/>
                {conversationItem.children && conversationItem.children.length > 0 ? this.renderBranch(conversationItem.children) : null}
            </div>
        )
    }
    renderBranch(conversationItems: ConversationItem[]) {
        if (conversationItems && conversationItems.length > 1) {
            return (
                <ConversationBranchSwitcher position={0}>
                    {conversationItems.map((c, i) => (this.renderBlock(c, i)))}
                </ConversationBranchSwitcher>
            )
        }
        return conversationItems.map((c, i) => (this.renderBlock(c, i)))
    }
    render() {
        return (
            <div>
                <DeviceTargetSelector targets={_.sortBy(this.props.targets, o => o.displayName)} selectedTarget={this.state.deviceTarget} onSelectionChange={this.handleTargetChange.bind(this)} />
                <div className={containerStyle}>
                    {this.renderBranch(this.props.conversationItems)}
                    {this.props.emptyLabel ? <p className="empty-label-container">{this.props.emptyLabel}</p> : null}
                </div>
            </div>
        )
    }
}

export default ScreenConversationFlowViewer;