import React from 'react';
import {css} from '@emotion/css';
import { cool_grey } from '../../../constants/colors';
const imageIcon = require('../../../content/images/picture_icon.svg');
const audioIcon = require('../../../content/images/audio_icon.svg');
const videoIcon = require('../../../content/images/video_icon.svg');

const labelStyle = css`
display: flex;
color: ${cool_grey};
font-family: Muli;
font-size: 12px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: 0.5px;
img {
    height: 14px;
    margin-right: 2px;
}
> span + * {
    margin-left: 12px;
}
margin: 12px 0;
`

const AllowedMediaLabel: React.SFC = (props) => (
    <div className={labelStyle}>
        <span>File formats supported:  </span>
        <img src={imageIcon}/>
        <span>.png, .jpg</span>

        <img src={audioIcon}/>
        <span>.mp3</span>

        <img src={videoIcon}/>
        <span>.mp4</span>
    </div>
)

export default AllowedMediaLabel;