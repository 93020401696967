import React from 'react';
import {css} from '@emotion/css';
import {Subscribe} from 'unstated';
import {RouteComponentProps} from 'react-router';
import FullScreenLayout from '../../../shared/scenes/fullScreenLayout';
import ApplicationContainer from '../../../shared/state/containers/ApplicationContainer';
import OrganizationContainer from '../../../shared/state/containers/OrganizationContainer';
import HorizontalContainer from '../../../shared/components/structure/HorizontalContainer';
import ModalTitle from '../../../shared/components/general/ModalTitle';
import Button from '../../../shared/components/general/Button';
import HorizontalSeparator from '../../../shared/components/structure/HorizontalSeparator';
import PageError from '../../../shared/components/general/PageError';
import CreateApplicationForm from './components/CreateApplicationForm';
import { silver_two } from '../../../shared/constants/colors';
const closeIcon = require('../../../shared/content/images/close_icon.svg');

const CreateApplication: React.FC<RouteComponentProps> = (props) => {
    
    const handleClose = () => {
        props.history.push("/v");
    };

    const handleFinish = (applicationId: string) => { 
        props.history.push(`/v/apps/${applicationId}/modules/discover`);
        location.reload();
    };

    return (
        <FullScreenLayout>
            <Subscribe to={[ApplicationContainer, OrganizationContainer]}>
                {(appContainer: ApplicationContainer, orgContainer: OrganizationContainer) => {
                    return (
                        <div className={containerStyle}>
                            <HorizontalContainer>
                                <ModalTitle padded>Create a New App</ModalTitle>  
                                <div className={rightSide}>
                                    <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={handleClose.bind(this)} />                                        
                                </div>                                 
                            </HorizontalContainer>
                            <HorizontalSeparator />
                            <CreateApplicationForm 
                                organizationId={orgContainer.state.currentOrganization.id} 
                                appContainer={appContainer} 
                                onCancel={handleClose.bind(this)} 
                                onCreate={handleFinish.bind(this)}/>
                            <PageError errors={appContainer.state.createErrors} />
                        </div>
                    );
                }}
            </Subscribe>
        </FullScreenLayout>
    )

}

const rightSide = css`
    margin: 16px;
    margin-left: auto;
    padding: 0px 16px;
    padding-right: 0px;
    border-left: 1px solid ${silver_two};
`;

const containerStyle = css`
    height: 100vh;
`;

export default CreateApplication;