import React from 'react';
import {css} from '@emotion/css';
import { color_shades_dark } from '../../../constants/colors';

const containerStyle = css`
    border-bottom: 1px solid ${color_shades_dark};
`

const ContentItemSection = (props) => (
    <div className={containerStyle}>
        {props.children}
    </div>
);

export default ContentItemSection