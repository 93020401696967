import { css } from '@emotion/css';
import React, { useState } from "react";
import KeyModalLayout from '../../../components/structure/KeyModalLayout';
import ModalLayout from "../../../components/structure/ModalLayout";
import ApiKeyResult from "../../../models/auth/api/ApiKeyResult";
import ApiKeyContainer from "../../../state/containers/ApiKeyContainer";
import NewApiKeyForm from "./NewApiKeyForm";

interface GenerateApiKeyModalProps {
    onClose: () => void,
    apiKeyContainer: ApiKeyContainer,
    organizationId: string
}

interface GenerateApiKeyModalState {
    keyResult?: ApiKeyResult
}

const GenerateApiKeyModal: React.FC<GenerateApiKeyModalProps & GenerateApiKeyModalState> = (props) => {
    const [keyResult, setKeyResult] = useState(null)

    const handleFinishForm = (result: ApiKeyResult) => {
        setKeyResult(result);
    }

    const isLoading = props.apiKeyContainer.state.isLoading;

    return (
        <ModalLayout className={modalStyle} isVisible={true} onClose={props.onClose}>
            {
                (keyResult == null) ?
                    <NewApiKeyForm
                        {...props}
                        onFinish={handleFinishForm} />
                    :
                    <KeyModalLayout
                        modalTitle="Generate API Key"
                        handleClose={props.onClose}
                        token={keyResult.key}
                        isLoading={isLoading}
                    />
            }
        </ModalLayout>
    )
}

const modalStyle = css`
    width: 60vw;
    height: 50vh;
`;

export default GenerateApiKeyModal;