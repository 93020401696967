import React, { useState } from 'react';
import styled from '@emotion/styled';
import SmallModalLayout from '../../../components/structure/SmallModalLayout';
import ResponseFlagRuleModel, { ResponseFlagRuleType } from '../../../models/features/api/Flags/ResponseFlagRuleModel';
import ApplicationConversationFlagModel from '../../../models/features/api/Flags/ApplicationConversationFlagModel';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import { css } from '@emotion/css';
import SelectField from '../../../components/forms/SelectField';
import Button from '../../../components/general/Button';
import ModalTitle from '../../../components/general/ModalTitle';
import FooterBar from '../../../components/structure/FooterBar';
import { style_border_default } from '../../../constants/stylesValues';
import RoundedSelectionRow from '../../../components/general/RoundedSelectionRow';
import { color_text_light } from '../../../constants/colors';
import TextField from '../../../components/forms/TextField';
import CheckboxField from '../../../components/forms/CheckboxField';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import PageError from '../../../components/general/PageError';
import _ from 'lodash';
const closeIcon = require('../../../content/images/close_icon.svg');
const userFlagsIcon = require('../../../content/images/content-item-icons/user-flags.svg');
const addFlagIcon = require('../../../content/images/content-item-icons/add-flag.svg');
const removeFlagIcon = require('../../../content/images/content-item-icons/remove-flag.svg');
const addFlagBlueIcon = require('../../../content/images/content-item-icons/add-flag-blue.svg');
const removeFlagBlueIcon = require('../../../content/images/content-item-icons/remove-flag-blue.svg');
const sessionFlagIcon = require('../../../content/images/content-item-icons/session-flag.svg');
interface FlagRuleCreatorProps {
    onFinish: (flagRule: ResponseFlagRuleModel) => void
    onCancel: () => void
    applicationId: string
    flags: ApplicationConversationFlagModel[]
}
const FlagRuleCreator: React.FC<FlagRuleCreatorProps> = ({
    onFinish,
    onCancel,
    applicationId,
    flags
}) => {
    const [ruleType, setRuleType] = useState("Add" as ResponseFlagRuleType)
    const [flagId, setFlagId] = useState("new");
    const [flagName, setFlagName] = useState("");
    const [isSessionFlag, setIsSessionFlag] = useState(false);
    const [error, setError] = useState(null as string);
    const mapToFlagRule = () => {
        // if add, then map all the values properly
        if (ruleType == "Remove") {
            return {
                ruleType,
                flagScope: isSessionFlag ? "Session" : "UserProfile",
                applicationConversationFlagId: flagId,
                applicationConversationFlag: flags.find(f => f.id == flagId)
            } as ResponseFlagRuleModel
        } else {
            return {
                ruleType,
                flagScope: isSessionFlag ? "Session" : "UserProfile",
                applicationConversationFlagId: flagId != "new" ? flagId : null,
                applicationConversationFlag: flagId != "new" ? flags.find(f => f.id == flagId) : {
                    applicationId: applicationId,
                    name: flagName
                }
            } as ResponseFlagRuleModel
        }

    }
    const handleFinish = () => {
        // validate
        if (flagId == "new" && flagName.length < 1 || flagId.length < 1) {
            setError("You must provide a flag name.")
            return;
        }
        const flagRule = mapToFlagRule();
        onFinish(flagRule);
    }
    return (
        <SmallModalLayout className={overflowVisibleStyle} isVisible onClose={onCancel}>
            <FlagRuleCreatorBody>
                <HorizontalContainer className={titleContainer}>
                    <img src={userFlagsIcon} />
                    <ModalTitle>Add/Remove a Flag</ModalTitle>
                    <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={onCancel} />
                </HorizontalContainer>
                <SelectionContainer>
                    <RoundedSelectionRow
                        options={[
                            { name: "Add User Flag", icon: addFlagIcon, selectedIcon: addFlagBlueIcon },
                            { name: "Remove User Flag", icon: removeFlagIcon, selectedIcon: removeFlagBlueIcon },
                        ]}
                        onClick={(num) => setRuleType(num == 1 ? "Remove" : "Add")}
                        selectedFilter={ruleType == "Remove" ? 1 : 0} />
                </SelectionContainer>
                {ruleType == "Add" && <FlagRuleFormFields>
                    <p>Add a flag to any user that hits this response:</p>
                    <SelectField name="flags" label="Select a User Flag" value={flagId}
                        options={[{ label: '- Create a new Flag -', value: 'new' }, ..._.uniqBy(flags ?? [], f => f.name)?.map(f => ({ label: f.name, value: f.id }))]}
                        onChange={(e: any) => setFlagId(e.value)}
                        onBlur={() => { }}
                        className={valueSelectStyle}
                        useFormTheme />
                    {flagId == "new" && <TextField className={valueTextStyle} placeholder="Flag name..." name="flagValue" label="Name of User Flag" value={flagName} onBlur={() => { }} onChange={(e) => setFlagName(e.target.value)} />}
                    <CheckboxField checked={isSessionFlag} onChange={(e) => setIsSessionFlag(e.target.checked)} icon={sessionFlagIcon} label="Flag expires when session is over" />
                </FlagRuleFormFields>}
                {ruleType == "Remove" && <FlagRuleFormFields>
                    <p>Remove a flag from any user that hits this response:</p>
                    <SelectField name="flags" label="Select a User Flag" value={flagId}
                        options={_.uniqBy(flags ?? [], f => f.name)?.map(f => ({ label: f.name, value: f.id }))}
                        placeholder="Select one of your flags to remove"
                        onChange={(e: any) => setFlagId(e.value)}
                        onBlur={() => { }}
                        className={valueSelectStyle}
                        useFormTheme />
                        <CheckboxField checked={isSessionFlag} onChange={(e) => setIsSessionFlag(e.target.checked)} icon={sessionFlagIcon} label="Flag expires when session is over" />
                </FlagRuleFormFields>}
                <FooterBar className={footerStyle}>
                    <Button themes={["primary", "start-tight"]} text="Apply" type="button" disabled={(flagId == "new" && flagName == null)} onClick={() => handleFinish()} />
                    <Button themes={["white", "end-tight"]} text="Cancel" type="button" onClick={onCancel} />
                </FooterBar>
                <PageError errors={error ? [error] : []} onClear={() => setError(null)} />
            </FlagRuleCreatorBody>
        </SmallModalLayout>
    )
};
const FlagRuleFormFields = styled.div(`
    padding: 24px;
    flex: 1;
    margin-bottom: 80px;
    > p {
        color: ${color_text_light};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
    }
    .check-label {
        font-weight: normal;
    }
`)
const SelectionContainer = styled.div(`
    border-bottom: ${style_border_default};
    margin: 0 24px;
    padding: 24px 0;
`)
const FlagRuleCreatorBody = styled.div(`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 580px;
    overflow: visible;
`)
const titleContainer = css`
    background: white;
    padding: 0 32px;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: ${style_border_default};
    img {
        margin-right: 16px;
    }
`
const footerStyle = css`
    position: fixed;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0 32px;
`;


const valueSelectStyle = css`    
    .Select-menu-outer {
        z-index: 111;
        overflow: hidden;
    }
`;

const valueTextStyle = css`    
    .text-field-suggestions {
        z-index: 111;
    }
`;
const overflowVisibleStyle = css`
    overflow: visible;
    min-height: 624px;
`;

export default FlagRuleCreator;