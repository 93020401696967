import React from 'react';
import { css } from '@emotion/css';
import CurrentTemplateSelector from '../../applicationTemplateMenuSelector/components/CurrentTemplateSelector';
import SlideInSlideOutComponent from '../../../components/structure/SlideInSlideOutComponent';
import { text_blue, color_shades_dark } from '../../../constants/colors';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import TemplateConfigurationTemplateMenu from './TemplateConfigurationTemplateMenu';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
interface TemplateConfigurationMenuSelectorProps {
    currentTemplateId: string
    templateConfigContainer: TemplateConfigurationContainer
    templateConfiguration: TemplateConfigurationModel
    history: any
}
interface TemplateConfigurationMenuSelectorState {
    isExpanded: boolean
}
class TemplateConfigurationMenuSelector extends React.Component<TemplateConfigurationMenuSelectorProps, TemplateConfigurationMenuSelectorState> {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }
    
    toggle() {
        this.setState({
            ...this.state,
            isExpanded: !this.state.isExpanded
        })
    }
    render() {
        return (
            <div className={`${containerStyle} ${this.state.isExpanded ? 'expanded' : ''}`}>
                <CurrentTemplateSelector
                    onToggle={this.toggle.bind(this)}
                    isMinified={true}
                    isOpen={this.state.isExpanded}
                    templateConfiguration={this.props.templateConfiguration} />
                <SlideInSlideOutComponent showChildren={this.state.isExpanded}
                    styleName="contentTreeDropdownSlide"
                    direction="down"
                    slideType="size">
                    <div className="fixed-tree-container">
                        <TemplateConfigurationTemplateMenu
                            currentTemplateConfigId={this.props.currentTemplateId}
                            templateConfigContainer={this.props.templateConfigContainer} />
                    </div>
                </SlideInSlideOutComponent>
            </div>
        )
    }
}

const containerStyle = css`
    height: 80px;
    padding-top: 16px;
    &.expanded {        
        background: ${text_blue};
    }
    .fixed-tree-container {
        margin-top: 80px;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        position: fixed;
        max-height: calc(100% - 80px);
        width:288px;
        z-index: 3;
        background: white;
        border-right: 1px solid ${color_shades_dark};
    }
`

export default TemplateConfigurationMenuSelector;