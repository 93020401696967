import React from 'react';
import { Subscribe, Container } from 'unstated';
import { QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId,  SimpleChoiceFeatureTypeId, NumberRangeFeatureTypeId, HelpMessagesFeatureTypeId, FallbackFeatureTypeId, CustomRequestsFeatureTypeId, WelcomeMessagesFeatureTypeId, ExitMessagesFeatureTypeId } from '../../../constants/featureTypeIds';
import QuestionAnswerContainer from '../../../state/containers/QuestionAnswerContainer';
import LatestMessageContainer from '../../../state/containers/LatestMessageContainer';
import SimpleChoiceContainer from '../../../state/containers/SimpleChoiceContainer';
import NumberRangeContainer from '../../../state/containers/NumberRangeContainer';
import HelpMessageContainer from '../../../state/containers/HelpMessageContainer';
import FallbackMessageContainer from '../../../state/containers/FallbackMessageContainer';
import CustomRequestContainer from '../../../state/containers/CustomRequestContainer';
import WelcomeMessageContainer from '../../../state/containers/WelcomeMessageContainer';
import ExitMessageContainer from '../../../state/containers/ExitMessageContainer';
import IConversationItemContainer from '../../../state/definitions/IConversationItemContainer';

interface SubscribeToContentItemProps {
    featureTypeId: string
    children(container: IConversationItemContainer & Container<any>): React.ReactNode;
}


const getContainer = (featureTypeId: string) => {
    switch (featureTypeId) {
        case QuestionAnswerFeatureTypeId: return QuestionAnswerContainer;
        case LatestMessagesFeatureTypeId: return LatestMessageContainer;
        case SimpleChoiceFeatureTypeId: return SimpleChoiceContainer;
        case NumberRangeFeatureTypeId: return NumberRangeContainer;
        case HelpMessagesFeatureTypeId: return HelpMessageContainer;
        case FallbackFeatureTypeId: return FallbackMessageContainer;
        case CustomRequestsFeatureTypeId: return CustomRequestContainer;
        case WelcomeMessagesFeatureTypeId: return WelcomeMessageContainer;
        case ExitMessagesFeatureTypeId: return ExitMessageContainer;
    }
}

const SubscribeToContentItem: React.FC<SubscribeToContentItemProps> = (props) => (
    <Subscribe to={[getContainer(props.featureTypeId)]}>
        {props.children}
    </Subscribe>
)

export default SubscribeToContentItem;