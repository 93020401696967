import { Container } from 'unstated';
import _ from 'lodash';
import WebhookFolderModel from '../../models/webhooks/api/WebhookFolderModel';
import WebhookModel from '../../models/webhooks/api/WebhookModel';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import { TreeItem } from '../../components/structure/NestedTree/NestedTree';
import BulkWebhookRequest from '../../models/features/api/BulkWebhookRequest';
import { Children } from 'react';
const folderIcon = require('../../content/images/webhooks/webhook-folder-dark.svg');
const webhookIcon = require('../../content/images/webhooks/webhook-dark.svg');


type WebhookFolderState = {
    isLoading: boolean
    webhookFolders: WebhookFolderModel[]
    errors: string[]
    currentApplicationId: string
    loadingFolderIds: string[]
    expandedIds: string[]
    tree: TreeItem
}

export default class WebhookFolderContainer extends Container<WebhookFolderState> {
    state = {
        isLoading: false,
        webhookFolders: [] as WebhookFolderModel[],
        errors: [],
        currentApplicationId: null,
        loadingFolderIds: [] as string[],
        expandedIds: [] as string[],
        tree: {} as TreeItem
    }

    async buildTree(appId: string, webhooks: WebhookModel[]): Promise<IResult<TreeItem>> {
        try {
            if (appId != this.state.currentApplicationId)
                await this.loadWebhookFolders(appId);

            const root: TreeItem = {
                id: appId,
                isHighlighted: false,
                isExpanded: true,
                isLoadingChildren: false,
                icon: folderIcon,
                expandedIcon: folderIcon,
                title: "root",
                canExpand: true,
                linkTo: ``,
                children: [
                    ...(this.state.webhookFolders?.filter(wf => wf.parentId == null)?.sort((a, b) => a.priority > b.priority ? 1 : -1) ?? []).map(f => this.mapFolderToTreeItem(f, webhooks)),
                    ...(webhooks?.filter(w => w.webhookFolderId == null) ?? []).map(w => this.mapWebhookToTreeItem(w))
                ]
            }

            this.setState({
                ...this.state,
                tree: root
            })

            return {
                resultType: "Ok",
                data: root,
                errors: null
            }
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    getAllAncestors(folderId: string): WebhookFolderModel[] {
        const folder = this.findFolderById(folderId);
        if (!folder) return [];
        const output = [];
        output.push(folder);
        var parentId = folder.parentId;
        while (parentId) {
            const parent = this.findFolderById(parentId);
            output.push(parent);
            parentId = parent?.parentId;
        }
        return output;
    }

    findFolderById(folderId: string) {
        return this.state.webhookFolders.find(f => f.id == folderId);
    }

    setExpandedIds(ids: string[]) {
        this.setState({
            ...this.state,
            expandedIds: ids
        });
    }

    mapFolderToTreeItem(folder: WebhookFolderModel, webhooks: WebhookModel[]): TreeItem {
        return {
            id: folder?.id,
            isHighlighted: false,
            isExpanded: this.state.expandedIds?.some(id => folder?.id == id),
            isLoadingChildren: this.state.loadingFolderIds?.some(id => folder?.id == id),
            icon: folderIcon,
            expandedIcon: folderIcon,
            title: folder?.name,
            canExpand: true,
            type: "feature",
            linkTo: ``,
            children: [
                ...(this.state.webhookFolders?.filter(wf => wf.parentId == folder.id)?.sort((a, b) => a.priority > b.priority ? 1 : -1) ?? []).map(f => this.mapFolderToTreeItem(f, webhooks)),
                ...(webhooks?.filter(w => w.webhookFolderId == folder.id) ?? []).map(w => this.mapWebhookToTreeItem(w))
            ]
        }
    }

    mapWebhookToTreeItem(webhook: WebhookModel): TreeItem {
        return {
            id: webhook.id,
            isHighlighted: false,
            isExpanded: false,
            isLoadingChildren: false,
            icon: webhookIcon,
            title: webhook.title,
            canExpand: false,
            linkTo: ``,
            children: []
        }
    }

    moveFolderUp(applicationFeatureId: string) {
        const folder = this.findFolderById(applicationFeatureId);

        // sort locally
        var familyFeatures = _.orderBy(this.state.webhookFolders.filter(af => af.parentId == folder.parentId && af.applicationId == folder.applicationId), af => af.priority, 'asc').map(af => af.id);
        var originalIndex = familyFeatures.indexOf(folder.id);
        if (originalIndex <= 0) return;
        familyFeatures = this.moveFolderInParent(familyFeatures, originalIndex, originalIndex - 1);

        this.setFolderOrder(folder.applicationId, folder.parentId, familyFeatures);
    }

    moveFolderDown(applicationFeatureId: string) {
        const feature = this.findFolderById(applicationFeatureId);

        // sort locally
        var familyFeatures = _.orderBy(this.state.webhookFolders.filter(af => af.parentId == feature.parentId && af.applicationId == feature.applicationId), af => af.priority, 'asc').map(af => af.id);
        var originalIndex = familyFeatures.indexOf(feature.id);
        if (originalIndex >= familyFeatures.length) return;
        familyFeatures = this.moveFolderInParent(familyFeatures, originalIndex, originalIndex + 1);

        this.setFolderOrder(feature.applicationId, feature.parentId, familyFeatures);
    }

    private moveFolderInParent(features: string[], originalPosition: number, newPosition: number) {
        if (newPosition >= features.length) {
            var k = newPosition - features.length + 1;
            while (k--) {
                features.push(undefined);
            }
        }
        features.splice(newPosition, 0, features.splice(originalPosition, 1)[0]);
        return features;
    };
    
    setFolderOrder(applicationId: string, parentFolderId: string, sortedAppFeatureIds: string[]) {
        // set local state for perf, then call api
        const allfolders = this.state.webhookFolders;
        sortedAppFeatureIds.forEach((id, index) => {
            const feature = allfolders.find(af => af.id == id);
            feature.priority = index;
        });

        this.setState({
            ...this.state,
            webhookFolders: allfolders
        })

        // sort in api
        voicifyApi.setWebhookFolderOrder(applicationId, parentFolderId, sortedAppFeatureIds).then(result => {
            if (result.resultType != "Ok")
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
        }).catch(e => {
            this.setState({
                ...this.state,
                errors: [e.toString()]
            })
        });
    }

    
    async bulkDelete(request: BulkWebhookRequest): Promise<IResult<string>> {
        this.setState({
            ...this.state,
            isLoading: true,
        });
        var result = await voicifyApi.bulkDeleteWebhooksAndFolders(request);
        if (result.resultType == "Ok") {
            this.setLoading(false);
        }
        else
        this.setState({
            ...this.state,
            isLoading: false,
            errors: [...result.errors]
        });
        return result;
    }

    
    async moveToFolder(targetFolderId: string, request: BulkWebhookRequest): Promise<IResult<string>> {
        if(!targetFolderId)
            targetFolderId = "";
        this.setState({
            ...this.state,
            isLoading: true,
        });
        var result = await voicifyApi.moveWebhooksAndFoldersToFolder(targetFolderId, request);
        if (result.resultType == "Ok") {
            this.setLoading(false);
        }
        else
        this.setState({
            ...this.state,
            isLoading: false,
            errors: [...result.errors]
        });
        return result;
    }

    
    async copyToFolder(targetFolderId: string, request: BulkWebhookRequest): Promise<IResult<string>> {
        if(!targetFolderId)
            targetFolderId = "";
        this.setState({
            ...this.state,
            isLoading: true,
        });
        var result = await voicifyApi.copyWebhooksAndFoldersToFolder(targetFolderId, request);
        if (result.resultType == "Ok") {
            this.setLoading(false);
        }
        else {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [...result.errors]
            });
        }
        return result;
    }

    async getFolderView(appId: string, folderId?: string, webhooks?: WebhookModel[]): Promise<IResult<WebhookFolderModel>> {
        try {
            if (appId != this.state.currentApplicationId)
                await this.loadWebhookFolders(appId);
            var folder = this.state.webhookFolders?.filter(wf => wf.id == folderId)[0] ?? {};
            folder.children = this.state.webhookFolders?.filter(wf => wf.parentId == folderId)?.sort((a, b) => a.priority > b.priority ? 1 : -1) ?? [];
            folder.children.forEach(c => {
                c.totalWebhookCount = this.getWebhookCountInFolder(c, webhooks);
            });
            folder.webhooks = webhooks?.filter(w => w.webhookFolderId == folderId) ?? [];
            return {
                resultType: "Ok",
                data: folder,
                errors: null
            }
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    getWebhookCountInFolder(folder: WebhookFolderModel, webhookPool: WebhookModel[]) : number
    {
        var count = 0
        var childWebhooks = webhookPool?.filter(w => w.webhookFolderId == folder.id) ?? [];
        count = count + childWebhooks.length;
        var childFolders = this.state.webhookFolders?.filter(wf => wf.parentId == folder.id) ?? [];
        childFolders.forEach(f => {
            count = count + this.getWebhookCountInFolder(f, webhookPool);
        });
        return count;
    }

    async deleteFolder(id: string): Promise<IResult<WebhookFolderModel>> {
        try {                
            this.setLoading(true);
            const result = await voicifyApi.deleteWebhookFolder(id);
            if (result.resultType == "Ok") {
                const idx = this.state.webhookFolders.findIndex(wf => wf.id == id)
                const newFolders = [...this.state.webhookFolders];
                newFolders.splice(idx, 1);
                this.setState({
                    ...this.state,
                    isLoading: false,
                    webhookFolders: newFolders
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: true,
                    errors: result.errors
                })
            }
            return result
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }
    async updateFolder(model: WebhookFolderModel): Promise<IResult<WebhookFolderModel>> {
        try {                
            const result = await voicifyApi.updateWebhookFolder(model.id, model);
            if (result.resultType == "Ok") {
                const idx = this.state.webhookFolders.findIndex(wf => wf.id == model.id)
                const newFolders = [...this.state.webhookFolders];
                newFolders[idx] = model;
                this.setState({
                    ...this.state,
                    isLoading: false,
                    webhookFolders: newFolders
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: true,
                    errors: result.errors
                })
            }

        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    async createFolder(appId: string, name: string, parentId: string): Promise<IResult<WebhookFolderModel>> {
        try {
            if (appId != this.state.currentApplicationId)
                await this.loadWebhookFolders(appId);

            if(!parentId)
                parentId = null; 
                
            var orderedWebhookFolders = this.state.webhookFolders?.filter(wf => wf.parentId == parentId)?.sort((a, b) => a.priority > b.priority ? 1 : -1) ?? [];
            var lowestPriority = (orderedWebhookFolders == null || orderedWebhookFolders?.length == 0) ? 1 : orderedWebhookFolders[0].priority;
            const model: WebhookFolderModel = {
                name: name,
                priority: lowestPriority - 1,
                applicationId: appId,
                parentId: parentId
            };
            const result = await voicifyApi.createWebhookFolder(model);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    webhookFolders: [result.data, ...this.state.webhookFolders]
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;

        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    async loadWebhookFolders(appId: string): Promise<IResult<WebhookFolderModel[]>> {
        this.setLoading(true);
        try {
            const result = await voicifyApi.getWebhookFoldersForApplication(appId);
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    currentApplicationId: appId,
                    webhookFolders: result.data ?? []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: true,
                    errors: result.errors
                })
            }
            return result;
        } catch {
            this.setLoading(false);
        }
    }

    setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }

}