import FullScreenLayout from "../../fullScreenLayout";
import ScrollView from "../../../components/structure/ScrollView";
import PageContainer from "../../../components/structure/PageContainer";
import React from "react";
import HorizontalContainer from "../../../components/structure/HorizontalContainer";
import Button from "../../../components/general/Button";
import ModalTitle from "../../../components/general/ModalTitle";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import IntegrationListing from "../../../models/integrations/IntegrationListing";
import IntegrationsListingsContainer from "../../../state/containers/IntegrationsListingsContainer";
import WebhookContainer from "../../../state/containers/WebhookContainer";
import ExternalIntegrationForm from "../../formScenes/ExternalIntegrationForm";
const closeIcon = require('../../../content/images/close_icon.svg');


type IntegrationSetupProps = {
    organizationId: string,
    integrationContainer: IntegrationsListingsContainer,
    webhookContainer: WebhookContainer
    handleClose: (hasChanges: boolean) => void
    integration: IntegrationListing
}

type IntegrationSetupState = {
    integrationSelected: IntegrationListing,
    hasChanges: boolean
}

class IntegrationSetup extends React.Component<IntegrationSetupProps, IntegrationSetupState>{
    state = { integrationSelected: null } as IntegrationSetupState

    componentDidMount() {

        const { integration } = this.props;
        if (!this.props.integrationContainer.isInternalIntegration(integration)) {
            this.props.integrationContainer.setupExternalIntegration(this.props.organizationId, integration)
                .then(() => {
                    if (!this.props.integrationContainer.state.errors || this.props.integrationContainer.state.errors.length == 0) {
                        var oAuthUrl = this.props.integrationContainer.state.integrationConfig.oAuthUrl;
                        if (oAuthUrl) {
                            window.open(oAuthUrl, "_self");
                        } else {

                            var additionalPropertiesConfiguration = this.props.integrationContainer.state.integrationConfig.additionalPropertiesConfiguration == null ? [] : this.props.integrationContainer.state.integrationConfig.additionalPropertiesConfiguration;
                            var setupSections = this.props.integrationContainer.state.integrationConfig.setupSections == null ? [] : this.props.integrationContainer.state.integrationConfig.setupSections;
                            if (
                                (additionalPropertiesConfiguration != null && Object.keys(additionalPropertiesConfiguration).length > 0) ||
                                (setupSections != null && setupSections.length > 0)) {
                                this.setState({
                                    ...this.state,
                                    integrationSelected: integration,
                                })
                            }
                            else {
                                this.props.integrationContainer.sendConfigurationInformation(this.props.organizationId, integration, {})
                                this.props.webhookContainer.getWebhooksForOrganization(this.props.organizationId)
                                    .then(() => {
                                        this.handleFinished()
                                    })
                            }
                        }
                    }
                })
        } else {
            this.setState({
                ...this.state,
                integrationSelected: integration
            })
        }
    }
    handleClose() {
        this.props.integrationContainer.clearErrors()
        this.props.handleClose(this.state.hasChanges)
    }

    handleFinished(hasChanges?: boolean) {
        this.setState({ integrationSelected: null })
        this.props.integrationContainer.clearErrors()
        this.props.handleClose(hasChanges || false);
    }

    handleChange() {
        this.setState({ hasChanges: true });
    }

    render() {
        return (
            <FullScreenLayout>
                <ScrollView>
                    <PageContainer fill withFooter>
                        <HorizontalContainer>
                            <ModalTitle padded>Add a New Integration:</ModalTitle>
                            <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={this.handleClose.bind(this)} />
                        </HorizontalContainer>
                        <HorizontalSeparator />
                        {this.props.integrationContainer.state.integrationConfig && 
                        <ExternalIntegrationForm integrationContainer={this.props.integrationContainer}
                            organizationId={this.props.organizationId}
                            integration={this.state.integrationSelected}
                            webhookContainer={this.props.webhookContainer}
                            handleClose={this.handleFinished.bind(this)}
                            additionalPropertiesConfiguration={this.props.integrationContainer.state.integrationConfig.additionalPropertiesConfiguration}
                            setupSections={this.props.integrationContainer.state.integrationConfig.setupSections} />
                        }
                    </PageContainer>
                </ScrollView>
            </FullScreenLayout>
        )
    }
}

export default IntegrationSetup