import React from 'react';
import { css } from '@emotion/css';
import ApplicationFeatureModel from '../../../models/features/api/ApplicationFeatureModel';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import { Link } from 'react-router-dom';
import { silver_four, color_shades_darkest, color_shades_dark, color_shades_darker, color_shades_lighter, color_text_default } from '../../../constants/colors';
import LargeOverflowMenu from '../../../components/general/LargeOverflowMenu';
import CheckIndicator from '../../../components/forms/CheckIndicator';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from './DeleteConfirmation';
import WebhookCountTag from '../../../components/general/WebhookCountTag';

const folderIcon = require('../../../content/images/content-explorer/folder.svg');
const deleteIcon = require('../../../content/images/bin.svg');
const deleteRedIcon = require('../../../content/images/bin-red.svg');
const editIcon = require('../../../content/images/pencil-light.svg');
const upIcon = require('../../../content/images/navigation-up.svg');
const downIcon = require('../../../content/images/navigation-down.svg');
const webhookIcon = require('../../../content/images/webhooks/webhook-dark.svg');
const onHoverIcon = require('../../../content/images/moveable-icon.svg');

interface FeatureItemProps {
    feature: ApplicationFeatureModel
    childFeatures: ApplicationFeatureModel[]
    contentItems: GenericContentItemModel[]
    featureContainer: FeatureContainer
    withNesting: boolean
    applicationId: string
    onSelectionChange: (applicationFeature: ApplicationFeatureModel, isSelected: boolean) => void
    isSelected: boolean
    selectable: boolean
    isSingleSelect?: boolean
    disabled?: boolean
    onClick: () => void
    onManageWebhooks?: (applicationFeatureId: string) => void
    index?: number
    className?: string
    webhookInstanceCount?: number
}

interface FeatureItemState {
    isExpanded: boolean
    isEditing: boolean
    newName: string
    isDeleteConfirmationOpen: boolean
}

class FeatureItem extends React.Component<FeatureItemProps, FeatureItemState> {
    inputElement: HTMLInputElement
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            isEditing: false,
            isDeleteConfirmationOpen: false,
            newName: this.props.feature?.name ?? this.props.feature?.feature?.name ?? "Untitled"
        }
    }
    handleToggleExpand() {
        if (!this.props.withNesting) return;
        this.setState({
            ...this.state,
            isExpanded: !this.state.isExpanded
        });
    }
    handleFinishRename() {
        if (this.state.newName) {
            this.props.featureContainer.updateApplicationFeature(this.props.feature?.id, {
                name: this.state.newName
            }).then(result => {
                if (result.resultType == "Ok") {
                    this.setState({
                        ...this.state,
                        isEditing: false
                    })
                }
            })
        } else {
            this.setState({
                ...this.state,
                isEditing: false
            })
        }
    }
    handleNameChange(e) {
        this.setState({
            ...this.state,
            newName: e.target.value
        });
    }
    handleOptionSelected(optionName: string) {
        if (optionName == 'Rename') {
            this.setState({
                ...this.state,
                isEditing: true
            }, () => this.inputElement?.focus())
        }
        if (optionName == 'Move Up') {
            this.props.featureContainer.moveFeatureUp(this.props.feature?.id)
        }
        if (optionName == 'Move Down') {
            this.props.featureContainer.moveFeatureDown(this.props.feature?.id)
        }
        if (optionName == 'Delete') {
            this.setState({
                ...this.state,
                isDeleteConfirmationOpen: true
            })
        }
        if (optionName == "Webhooks") {
            this.props.onManageWebhooks(this.props.feature?.id);
        }
    }
    handleDeleteConfirm() {
        this.props.featureContainer.deleteApplicationFeature(this.props.feature.id).then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isDeleteConfirmationOpen: false
                })
            }
        });
    }
    handleCloseModals() {
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: false
        })
    }
    handleEnterBlur(e) {
        if (e.charCode == 13) {
            e.target.blur();
        }
    }

    getOptions() {
        const options = [
            { label: 'Rename', icon: editIcon, hoverIcon: editIcon },
            { label: 'Delete', icon: deleteIcon, hoverIcon: deleteRedIcon, isDestructive: true }];

        if (this.props.onManageWebhooks) options.push({ label: 'Webhooks', icon: webhookIcon, hoverIcon: webhookIcon });

        options.push({ label: 'Move Up', icon: upIcon, hoverIcon: upIcon });
        options.push({ label: 'Move Down', icon: downIcon, hoverIcon: downIcon });


        return options;
    }

    render() {
        const contentItemCount = this.props.featureContainer.getConversationCountForFeature(this.props.feature.id);
        const showWebhookCount = (this.props.onManageWebhooks && (this.props.webhookInstanceCount ?? 0) > 0);
        return (
            <div className={`${containerStyle} ${this.props.disabled ? 'disabled' : ''} ${this.props.className ?? ''}`}>
                <div className={`feature-item ${this.props.index == 0 ? 'top' : ''}`} onClick={this.handleToggleExpand.bind(this)}>
                    <img className='feature-item-hover-icon' src={onHoverIcon} />
                    {this.props.withNesting && <div className="triangle-container">
                        <div className={this.state.isExpanded ? "triangle-down" : "triangle-right"} />
                    </div>}
                    {this.props.selectable &&
                        <CheckIndicator checkType={this.props.isSingleSelect ? 'radio' : 'check'}
                            checked={this.props.isSelected}
                            onClick={this.props.disabled ? () => { } : () => this.props.onSelectionChange(this.props.feature, !this.props.isSelected)}
                            disabled={this.props.disabled}
                            className="ac-folder-check-box" />}

                    {this.state.isEditing ? <div className="feature-link">
                        <img className="folder-icon" src={folderIcon} />
                        <input className="feature-title"
                            placeholder="New Folder"
                            value={this.state.newName}
                            ref={r => this.inputElement = r}
                            onChange={this.handleNameChange.bind(this)}
                            onBlur={this.handleFinishRename.bind(this)}
                            onKeyPress={(e) => this.handleEnterBlur(e)}
                            onFocus={(e) => e.target.select()} />
                    </div> : (this.props.onClick ? <div className="feature-link" onClick={this.props.disabled ? null : this.props.onClick}>
                        <img className="folder-icon" src={folderIcon} />
                        <h4 className="feature-title ac-folder-title">{this.props.feature.name ?? this.props.feature?.feature?.name ?? "Untitled"}</h4>
                    </div> :
                        <Link className="feature-link" to={`/v/apps/${this.props.applicationId}/content/${this.props.feature.id}`}>
                            <img className="folder-icon" src={folderIcon} />
                            <h4 className="feature-title ac-folder-title">{this.props.feature.name ?? this.props.feature?.feature?.name ?? "Untitled"}</h4>
                        </Link>
                        )}
                    {showWebhookCount && 
                        <WebhookCountTag className="webhook-count" onClick={() => this.props.onManageWebhooks(this.props.feature.id)} count={this.props.webhookInstanceCount} /> }
                    
                    <div className={`count-container ${showWebhookCount ? 'no-margin-left' : ''} ac-folder-content-count`}>
                        <h5>{contentItemCount ?? '-'}</h5>
                        <p>CONVERSATIONS</p>
                    </div>
                    <LargeOverflowMenu isActive={false} onOptionSelected={this.handleOptionSelected.bind(this)} options={this.getOptions()} className="ac-folder-overflow-menu"/>
                </div>
                {this.state.isDeleteConfirmationOpen &&
                    <ConfirmationDialog title={`Delete ${this.props.feature?.name ?? "Untitled"}`} deleteText="Yes, Delete"
                        isLoading={this.props.featureContainer.state.isDeleting}
                        onClose={this.handleCloseModals.bind(this)}
                        onConfirm={this.handleDeleteConfirm.bind(this)}>
                        <DeleteConfirmation applicationFeatureCount={1} contentItemCount={contentItemCount} />
                    </ConfirmationDialog>
                }
            </div>
        )
    }
}

const containerStyle = css`
    &.dragging {
        .feature-item {
            border: 1px solid ${color_shades_dark};
        }
        .feature-item-hover-icon {
            visibility: visible;
        }
    }

    &.disabled {
        .feature-item {
            background: ${color_shades_lighter};
            cursor: not-allowed;
            .feature-title {
                background: ${color_shades_lighter};
            }
        }
    }
    .feature-item {
        height: 80px;
        background: #FFFFFF;
        border-bottom: 1px solid ${color_shades_dark};
        &.top {
            border-top: 1px solid ${color_shades_dark};
        }
        box-sizing: border-box;
        display: flex;
        align-items: center;
        /* 
        right padding should always be 32px
        feature-item-hover-icon takes 14px, so left padding should be 32 - 14 */
        padding: 0 32px 0 18px;
        .webhook-count {
            margin-left: auto;
            margin-right: 16px;
        }
        .feature-link {    
            cursor: pointer;
            display: flex;
            align-items: center;
            color: ${color_shades_darkest};
        }
        .folder-icon {
            width: 56px;
            height: 56px;
        }
        .feature-title {
            background: white;
            margin-left: -32px;
            margin-top: 18px;            
            font-family: Muli;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 12px;
            border: none;
            color: ${color_text_default};            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 60vw;
        }

        .add-new-button-container {
            position: relative;
        }

        .count-container {
            text-align: right;
            margin-right: 16px;
            margin-left: auto;
            border-right: 1px solid ${silver_four};
            padding: 0 12px;
            &.no-margin-left {
                margin-left: 0px;
            }
            h5 {                
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: ${color_shades_darker};
            }
        }

        .add-button {
            margin: 0 24px;
        }
    }

    .feature-item-hover-icon {
        width: 8px;
        height: 24px;
        margin-right: 6px;
        visibility: hidden;
    }

    .feature-item:hover .feature-item-hover-icon {
        visibility: visible;
    }

    .child-container {
        display: flex;
        align-items: stretch;
        .spacer {
            width: 32px;
            background: #E9EAEE;
            border: 1px solid ${color_shades_dark};
            box-sizing: border-box;
        }
        .child-content-container {
            flex: 1;
        }
        .child-content-items-container {
            padding: 32px;            
            background: ${color_shades_lighter};
        }

        .content-item {
            height: 64px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid ${color_shades_dark};
            box-sizing: border-box;
            border-radius: 4px;

            .content-item-icon {
                width: 24px;
                height: 24px;
                margin-right: 16px;
            }

            .feature-type-title {
                font-family: Muli;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: ${color_shades_darker};
            }
            .content-title {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: ${color_shades_darkest};
            }
        }
    }
    .triangle-down {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid ${color_shades_darkest};
    }
    .triangle-right {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid ${color_shades_darkest};
    }

    .triangle-container {
        width: 3px;
        height: 3px;
        margin-right: 8px;
    }

    .check {
        margin-top: 4px;
    }
`

export default FeatureItem;