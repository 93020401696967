import React from 'react';
import styled from "@emotion/styled";
import TemplateCategoryModel from '../../../models/templating/api/TemplateCategoryModel';
import TemplateIndustryModel from '../../../models/templating/api/TemplateIndustryModel';
import { TemplateType } from '../../../models/templating/api/TemplateConfigurationModel';
import ModuleMenuGroup from './ModuleMenuGroup';
import { style_border_default } from '../../../constants/stylesValues';
import { color_text_link, color_variants_ocean_light_1 } from '../../../constants/colors';
const modulesIcon = require('../../../content/images/templates-icons/modules-icon-small.svg');
const templatesIcon = require('../../../content/images/templates-icons/templates-icon-small.svg');
const integrationsIcon = require('../../../content/images/tab-icons/puzzle-dark.svg');
interface ModulesMenuProps {
    categories: TemplateCategoryModel[]
    industries: TemplateIndustryModel[]
    onTypeChange: (templateType?: TemplateType) => void
    onCategoryChange: (categoryId: string) => void
    onIndustryChange: (industryId: string) => void
    selectedType?: TemplateType
    selectedCategoryId?: string
    selectedIndustryId?: string
}

const DiscoverModulesMenu: React.FC<ModulesMenuProps> = ({
    categories,
    industries,
    onTypeChange,
    onCategoryChange,
    onIndustryChange,
    selectedType,
    selectedCategoryId,
    selectedIndustryId
}) => {
    return (<MenuBody>
        <ModuleTypeSection>
            <ModuleTypeHeader>Type:</ModuleTypeHeader>
            <ul>
                <ModuleTypeItem className={!selectedType ? 'selected' : ''} onClick={() => onTypeChange(null)}>
                    <img src={modulesIcon} />
                    <p>View All</p>
                </ModuleTypeItem>
                <ModuleTypeItem className={selectedType == "ContentTemplate" ? 'selected' : ''} onClick={() => onTypeChange("ContentTemplate")}>
                    <img src={templatesIcon} />
                    <p>Templates</p>
                </ModuleTypeItem>
                <ModuleTypeItem className={selectedType == "Integration" ? 'selected' : ''} onClick={() => onTypeChange("Integration")}>
                    <img src={integrationsIcon} />
                    <p>Integrations</p>
                </ModuleTypeItem>
            </ul>
        </ModuleTypeSection>
        <ModuleMenuGroup title="Category" allLabel="- All Categories -" onItemSelect={onCategoryChange} selectedItemId={selectedCategoryId} items={categories?.map(c => ({ id: c.id, label: c.name }))} />
        <ModuleMenuGroup title="Industry" allLabel="- All Industry -" onItemSelect={onIndustryChange} selectedItemId={selectedIndustryId} items={industries?.map(c => ({ id: c.id, label: c.name }))} />
    </MenuBody>)
};

const MenuBody = styled.div(``)
const ModuleTypeSection = styled.div(`
    border-bottom: ${style_border_default};
    padding: 16px 8px;
`);
const ModuleTypeHeader = styled.p(`
    margin-left: 16px;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
`)
const ModuleTypeItem = styled.li(`
    padding-left: 16px;
    flex: 1;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    img {
        margin-right: 12px;
        width: 20px;
    }
    &.selected {
        background: ${color_variants_ocean_light_1};
        border-radius: 4px;
        color: ${color_text_link};
    }
`);
export default DiscoverModulesMenu;