import React from 'react';
import { css } from '@emotion/css';
import ContentCard from '../structure/ContentCard';
import HorizontalContainer from '../structure/HorizontalContainer';
import MediaIndicator from '../general/MediaIndicator';
import VerticalSeparator from '../structure/VerticalSeparator';
import Button from '../general/Button';
import FormFieldsContainer from '../structure/FormFieldsContainer';
import { color_text_light } from '../../constants/colors';
const mediaIcon = require('../../content/images/content-tree-icons/content-level/media-attachments.svg');
interface MediaSelectionProps {
    onClick: () => void
    hasAudio: boolean
    hasImage: boolean
    hasVideo: boolean
    hasBackground: boolean
    anchor?: string
    disabled?: boolean
}

const isEmpty = (props: MediaSelectionProps) => {
    if (props.hasVideo || props.hasAudio || props.hasBackground || props.hasImage) return false;
    return true;
}

const MediaSelectionCard: React.FC<MediaSelectionProps> = (props) => (
    <ContentCard id={props.anchor ?? "media"} title="Attached Media" icon={mediaIcon} rightRender={() => <Button themes={["primary-small", "end-tight"]} className="ac-add-media-button" type="button" text="Add/Edit Media" onClick={props.onClick} disabled={props.disabled} />}>
        <FormFieldsContainer className={containerStyle}>
            {isEmpty(props) ?
                <p className={emptyLabelStyle}>No items attached</p> :
                <MediaIndicator hasBackground={props.hasBackground} hasAudio={props.hasAudio} hasImage={props.hasImage} hasVideo={props.hasVideo} />
            }
        </FormFieldsContainer>
    </ContentCard>
)

const containerStyle = css`
    margin-bottom: 16px;
`
const emptyLabelStyle = css`
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    color: ${color_text_light};
`

export default MediaSelectionCard;