import React from 'react';
import styled from '@emotion/styled';
import { style_border_default } from '../../../constants/stylesValues';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { color_shades_darkest } from '../../../constants/colors';
const backupIcon = require('../../../content/images/app-history-icons/backup.svg');
interface ApplicationHistoryToolbarProps {
    isExpanded: boolean;
    isLoading?: boolean
    onToggle: () => void;
    onSaveBackup?: () => void;
}

const ApplicationHistoryToolbar: React.FC<ApplicationHistoryToolbarProps> = ({ isExpanded, isLoading, onToggle, onSaveBackup }) => {
    return (
        <Container>
            <ToggleButton type="button" onClick={onToggle} className={`${onSaveBackup == null ? 'no-backup' : ''} ac-explorer-history-button`}>
                <p>History</p>
                <ToggleIndicator direction={isExpanded ? 'up' : 'down'} />
            </ToggleButton>
            
            {onSaveBackup != null && <>
                {isLoading ? <SecondaryLoader /> : <TooltipWrapper text="Save a backup" place="bottom">
                    <SaveBackupButton onClick={onSaveBackup} disabled={isLoading}>
                        <p>Backup</p>
                        <img src={backupIcon} />
                    </SaveBackupButton>
                </TooltipWrapper>
                }
            </>}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: ${style_border_default};
    padding: 0 24px;
    p{
        color: ${color_shades_darkest};
        font-size: 14px;
        line-height: 20px;
    }
`

const ToggleButton = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    padding: 0;
    border-right: ${style_border_default};
    margin-right: 16px;
    &.no-backup {
        margin-right: 0;
        border-right: none;
    }
    p {
        margin-right: 4px;
    }
`

const SaveBackupButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    p{
        margin-right: 8px;
    }
`
export default ApplicationHistoryToolbar;
