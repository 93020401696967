import React from 'react';
import { css } from '@emotion/css';

const containerStyle = css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    >div {
        width: 45%;
        flex: 1;
    }
    > * + * {
        margin-left: 18px;
    }

`

const HorizontalFormFieldsContainer: React.SFC = (props) => (
    <div className={containerStyle}>
        {props.children}
    </div>
);

export default HorizontalFormFieldsContainer;