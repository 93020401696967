import React from "react";
import { WebhookInstanceManagerProps, WebhookInstanceScope } from "..";
import { CustomRequestsFeatureTypeId, ExitMessagesFeatureTypeId, FallbackFeatureTypeId, HelpMessagesFeatureTypeId, LatestMessagesFeatureTypeId, NumberRangeFeatureTypeId, QuestionAnswerFeatureTypeId, SimpleChoiceFeatureTypeId, WelcomeMessagesFeatureTypeId } from "../../../constants/featureTypeIds";
import IResult from "../../../models/result/IResult";
import BulkWebhookInstancesUpdateRequest from "../../../models/webhooks/api/BulkWebhookInstancesUpdateRequest";
import WebhookInstanceRequest from "../../../models/webhooks/api/WebhookInstanceRequest";
import WebhookInstanceModel from "../../../models/webhooks/WebhookInstanceModel";
import CustomRequestContainer from "../../../state/containers/CustomRequestContainer";
import ExitMessageContainer from "../../../state/containers/ExitMessageContainer";
import FallbackMessageContainer from "../../../state/containers/FallbackMessageContainer";
import FeatureWebhookContainer from "../../../state/containers/FeatureWebhookContainer";
import HelpMessageContainer from "../../../state/containers/HelpMessageContainer";
import LatestMessageContainer from "../../../state/containers/LatestMessageContainer";
import NumberRangeContainer from "../../../state/containers/NumberRangeContainer";
import OrganizationContainer from "../../../state/containers/OrganizationContainer";
import QuestionAnswerContainer from "../../../state/containers/QuestionAnswerContainer";
import SimpleChoiceContainer from "../../../state/containers/SimpleChoiceContainer";
import WebhookContainer from "../../../state/containers/WebhookContainer";
import WelcomeMessageContainer from "../../../state/containers/WelcomeMessageContainer";
import WebhookInstanceForm from "./WebhookInstanceForm";




interface WebhookInstanceFormParentProps extends WebhookInstanceManagerProps {
    webhookContainer: WebhookContainer,
    featureWebhookContainer: FeatureWebhookContainer,
    questionAnswerContainer: QuestionAnswerContainer,
    customRequestContainer: CustomRequestContainer,
    exitMessageContainer: ExitMessageContainer,
    fallbackMessageContainer: FallbackMessageContainer,
    helpMessageContainer: HelpMessageContainer,
    latestMessageContainer: LatestMessageContainer,
    numberRangeContainer: NumberRangeContainer,
    simpleChoiceContainer: SimpleChoiceContainer,
    welcomeMessageContainer: WelcomeMessageContainer,
    organizationContainer: OrganizationContainer,
}

interface WebhookInstanceFormParentState {
    existingWebhookInstances?: WebhookInstanceModel[],
    formIsLoaded: boolean
}

class WebhookInstanceFormParent extends React.Component<WebhookInstanceFormParentProps, WebhookInstanceFormParentState> {

    constructor(props) {
        super(props);
        this.state = {
            existingWebhookInstances: null,
            formIsLoaded: false
        }
    }

    async componentDidUpdate(prevProps: WebhookInstanceFormParentProps) {
        if (!prevProps.show && this.props.show)
            this.load();
    }

    async load() {
        if (this.props.shouldNotLoad != true) {
            var result = await this.getExistingWebhookInstances();
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    existingWebhookInstances: result.data ?? [],
                    formIsLoaded: true,
                });
            }
        }
        if (this.props.stagedUpdate) {
            this.setState({
                ...this.state,
                existingWebhookInstances: null,
                formIsLoaded: true
            });
        }
        else {
            this.setState({
                ...this.state,
                existingWebhookInstances: [],
                formIsLoaded: true
            });
        }
    }

    async handleSubmitForm(updateRequest: BulkWebhookInstancesUpdateRequest) {
        if (!this.props.manualSave) {
            const result = await this.bulkUpdate(this.props.webhookContainerId, updateRequest);
            if (result.resultType == "Ok") {
                this.props.onFinish(result.data);
                this.close();
            }
        }
        else {
            this.props.manualSave(updateRequest);
            this.close();
        }
    }

    close() {
        this.setState({
            ...this.state,
            formIsLoaded: false,
            existingWebhookInstances: null
        });
        this.props.onClose();
    }

    async getExistingWebhookInstances(): Promise<IResult<WebhookInstanceModel[]>> {
        if (this.props.webhookInstanceScope == "Application")
            return await this.props.webhookContainer.getApplicationWebhookInstances(this.props.webhookContainerId);
        if (this.props.webhookInstanceScope == "ApplicationFeature")
            return await this.props.featureWebhookContainer.getApplicationFeatureWebhooks(this.props.webhookContainerId);
        switch (this.props.featureTypeId) {
            case WelcomeMessagesFeatureTypeId: return this.props.welcomeMessageContainer.getContentWebhooks(this.props.webhookContainerId);
            case QuestionAnswerFeatureTypeId: return this.props.questionAnswerContainer.getContentWebhooks(this.props.webhookContainerId);
            case ExitMessagesFeatureTypeId: return this.props.exitMessageContainer.getContentWebhooks(this.props.webhookContainerId);
            case LatestMessagesFeatureTypeId: return this.props.latestMessageContainer.getContentWebhooks(this.props.webhookContainerId);
            case HelpMessagesFeatureTypeId: return this.props.helpMessageContainer.getContentWebhooks(this.props.webhookContainerId);
            case FallbackFeatureTypeId: return this.props.fallbackMessageContainer.getContentWebhooks(this.props.webhookContainerId);
            case NumberRangeFeatureTypeId: return this.props.numberRangeContainer.getContentWebhooks(this.props.webhookContainerId);
            case SimpleChoiceFeatureTypeId: return this.props.simpleChoiceContainer.getContentWebhooks(this.props.webhookContainerId);
            case CustomRequestsFeatureTypeId: return this.props.customRequestContainer.getContentWebhooks(this.props.webhookContainerId);
        }
    }

    bulkUpdate(webhookContainerId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> {
        if (this.props.webhookInstanceScope == "Application")
            return this.props.webhookContainer.bulkUpdateApplicationWebhookInstances(webhookContainerId, updateRequest);
        if (this.props.webhookInstanceScope == "ApplicationFeature")
            return this.props.featureWebhookContainer.bulkUpdateFeatureWebhookInstances(webhookContainerId, updateRequest);
        switch (this.props.featureTypeId) {
            case WelcomeMessagesFeatureTypeId: return this.props.welcomeMessageContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case QuestionAnswerFeatureTypeId: return this.props.questionAnswerContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case ExitMessagesFeatureTypeId: return this.props.exitMessageContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case LatestMessagesFeatureTypeId: return this.props.latestMessageContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case HelpMessagesFeatureTypeId: return this.props.helpMessageContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case FallbackFeatureTypeId: return this.props.fallbackMessageContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case NumberRangeFeatureTypeId: return this.props.numberRangeContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case SimpleChoiceFeatureTypeId: return this.props.simpleChoiceContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
            case CustomRequestsFeatureTypeId: return this.props.customRequestContainer.bulkUpdateContentItemWebhookInstances(webhookContainerId, updateRequest);
        }
    }

    getIsLoading(): boolean {
        if (this.props.webhookInstanceScope == "Application")
            return this.props.webhookContainer.state.isUpdating;
        if (this.props.webhookInstanceScope == "ApplicationFeature")
            return this.props.featureWebhookContainer.state.isUpdating;
        switch (this.props.featureTypeId) {
            case WelcomeMessagesFeatureTypeId: return this.props.welcomeMessageContainer.state.isUpdatingWebhooks;
            case QuestionAnswerFeatureTypeId: return this.props.questionAnswerContainer.state.isUpdatingWebhooks;
            case ExitMessagesFeatureTypeId: return this.props.exitMessageContainer.state.isUpdatingWebhooks;
            case LatestMessagesFeatureTypeId: return this.props.latestMessageContainer.state.isUpdatingWebhooks;
            case HelpMessagesFeatureTypeId: return this.props.helpMessageContainer.state.isUpdatingWebhooks;
            case FallbackFeatureTypeId: return this.props.fallbackMessageContainer.state.isUpdatingWebhooks;
            case NumberRangeFeatureTypeId: return this.props.numberRangeContainer.state.isUpdatingWebhooks;
            case SimpleChoiceFeatureTypeId: return this.props.simpleChoiceContainer.state.isUpdatingWebhooks;
            case CustomRequestsFeatureTypeId: return this.props.customRequestContainer.state.isUpdatingWebhooks;
        }
    }


    render() {

        if (!this.props.show)
            return null;

        return (
            <WebhookInstanceForm
                existingWebhookInstances={this.state.existingWebhookInstances}
                existingWebhookChainingType={this.props.existingWebhookChainingType ?? "NoChaining"}
                stagedUpdate={this.props.stagedUpdate}
                applicationId={this.props.applicationId}
                webhookInstanceScope={this.props.webhookInstanceScope}
                webhookContainer={this.props.webhookContainer}
                onClose={this.close.bind(this)}
                organizationId={this.props.organizationContainer.state.currentOrganization?.id}
                isLoading={this.getIsLoading() || !this.state.formIsLoaded}
                onFinish={this.handleSubmitForm.bind(this)} />
        );
    }
}

export default WebhookInstanceFormParent;