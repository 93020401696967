import React from 'react';
import { Subscribe } from 'unstated';
import ExitMessageContentForm from './components/ExitMessageContentForm';
import { RouteComponentProps } from 'react-router-dom';
import ExitMessageContainer from '../../state/containers/ExitMessageContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ExitMessageModel from '../../models/features/api/Exit/ExitMessageModel';
import IResult from '../../models/result/IResult';
import GenericCreateContentItemPage from '../formScenes/GenericCreateContentItemPage';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { ExitMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface CreateExitMessageProps extends RouteComponentProps<ApplicationRouteProps> { }

class CreateExitMessage extends React.Component<CreateExitMessageProps> {
    render() {
        return (
            <Subscribe to={[ExitMessageContainer, ApplicationContainer, RepromptContainer, FollowUpContainer, TitleStateContainer, MediaResponseStateContainer, FeatureContainer]}>
                {(exitContainer: ExitMessageContainer,
                    appContainer: ApplicationContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    titleContainer: TitleStateContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer) => {
                    return (
                        <GenericCreateContentItemPage stateContainer={exitContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="New Exit Message"
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={ExitMessagesFeatureTypeId}
                            formRender={(application, assistantApplication, onPublish, onSubmit: (model: ExitMessageModel) => Promise<IResult<ExitMessageModel>>, onAccessTypeChange) => (

                                <ExitMessageContentForm
                                    appContainer={appContainer}
                                    followUpContainer={followUpContainer}
                                    repromptContainer={repromptContainer}
                                    location={this.props.location}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    application={application}
                                    stateContainer={exitContainer}
                                    assistantApplication={assistantApplication}
                                    responseStateContainer={responseContainer}
                                    featureContainer={featureContainer}
                                    history={this.props.history}
                                    isSaving={exitContainer.state.isSaving}
                                    onSubmit={onSubmit}
                                    onShowPublishModal={onPublish}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onToggleNavigation={() => { }}
                                    isTemplateOwned={false}
                                    onToggleSync={null}  />
                            )} />

                    );
                }}
            </Subscribe>
        );
    }
}

export default CreateExitMessage;