import styled from '@emotion/styled';
import React, { useState } from 'react';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import ModalTitle from '../../../components/general/ModalTitle';
import PageError from '../../../components/general/PageError';
import FooterBar from '../../../components/structure/FooterBar';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import ModalLayout from '../../../components/structure/ModalLayout';
import PageContainer from '../../../components/structure/PageContainer';
import { color_gradients_green_shift, color_shades_darkest, color_text_default } from '../../../constants/colors';
import LanguageModel from '../../../models/features/api/LanguageModel';
import EditableInteractionModel from '../../../models/interactionModel/EditableInteractionModel';
import TextAreaField from '../../../components/forms/TextAreaField';
import RadioGroup from '../../../components/forms/RadioGroup';
import { style_border_default } from '../../../constants/stylesValues';
import { css } from '@emotion/css';

const closeIcon = require('../../../content/images/close_icon.svg');

export const enabledOptions = [
    { label: "LLM Intent Matching Enabled", value: true },
    { label: "LLM Intent Matching Disabled", value: false }
];

interface ManageIntentPromptModalProps {
    handleClose: () => void;
    handleSubmit: (enabled: boolean, prompt: string) => any;
    isLoading: boolean;
    languages?: LanguageModel[];
    prompt: string;
    enabled: boolean;

}

const ManageIntentPromptModal: React.FC<ManageIntentPromptModalProps> = (props) => {
    const [prompt, setPrompt] = useState(props.prompt);
    const [enabled, setEnabled] = useState(props.enabled);
    const [errorMessages, setErrorMessages] = useState([]);

    const handlePromptChange = value => {
        setErrorMessages([]);
        setPrompt(value);
    };
    
    return (
        <NewIntentModal 
            isVisible={true} 
            onClose={() => props.handleClose()} >        
            <PageContainer fill withFooter>
                <HorizontalContainer>
                    <ModalTitle padded>Manage Intent Matching Prompt</ModalTitle>
                    <Button themes={['end-tight', 'icon']} 
                        icon={closeIcon} 
                        onClick={() => props.handleClose()} />
                </HorizontalContainer>
                <HorizontalSeparator />
                <HorizontalContainer >
                    <div className="main-body">
                        <CustomRadioGroup
                            checkContainerStyle={checkContainerStyle}
                            selectedOptionStyle={selectedOptionStyle}
                            onChange={(e) => setEnabled(e.value as boolean)}
                            value={enabled}
                            options={enabledOptions}
                        />
                        {
                            enabled && <>
                                <p>{"The variable {INTENTS} will be replaced by a new line separated list of relevant intents in this format: d: description. a:INTENT_NAME"}</p>
                                <p>{"The variable {LAST_ASSISTANT_SPEECH} will be replaced by the last thing the assistant said. You can optionally include it if you want to prompt with more context."}</p>
                                <CustomTextArea
                                    required={true}
                                    name={`prompt`}
                                    value={prompt}
                                    maxRows={20}
                                    disabled={props.isLoading || !enabled}
                                    placeholder="Edit intent matching prompt here"
                                    onChange={(e) => handlePromptChange(e.target.value)}
                                    onBlur={() => { }} />                            
                            </>
                        }
                    </div>
                    <PageError errors={[
                        ...errorMessages ?? []
                        ]} onClear={() => {
                            setErrorMessages([]);
                        }} />
                </HorizontalContainer>
                <CustomFooter>
                    <SubmitButton
                        type="button" 
                        themes={['primary']} 
                        text="Save" 
                        onClick={() => props.handleSubmit(enabled, prompt)} 
                        disabled={props.isLoading || prompt.length == 0 } 
                        loading={props.isLoading} />
                    <Button
                        type="button"
                        themes={['secondary']}
                        text="Cancel"
                        onClick={() => props.handleClose()}
                        disabled={props.isLoading}
                        loading={props.isLoading} />
                </CustomFooter>
            </PageContainer>
        </NewIntentModal>
    );

};

export default ManageIntentPromptModal;

const CustomTextArea = styled(TextAreaField)`
    margin-top: -16px;
`;
const NewIntentModal = styled(ModalLayout)`
    color: ${color_text_default};
    width: 70%;
    height: 70%;
    min-height: 750px;
    min-width: 600px;

    .main-body {
        width: 100%;
        padding: 32px;
        padding-top: 8px;
    }
`;

const CustomFooter = styled(FooterBar)`
    z-index: auto;
`;

const SubmitButton = styled(Button)`
    margin-right: auto;
`;

const CustomRadioGroup = styled(RadioGroup)`
    margin: 0 0 26px 0;
`;

const checkContainerStyle = css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: ${style_border_default};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const selectedOptionStyle = css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: ${color_gradients_green_shift};
`;