import React from 'react';
import { css } from '@emotion/css';
import ContainerDimensions from 'react-container-dimensions';
import { cool_grey, silver_two } from '../../../constants/colors';

export const cortanaBodyStyle = css`
    position: relative;
    width: 100%;
    padding-top: 133.33%; /* 16:9 Aspect Ratio - portrait */
    margin: 16px 0;
    .screen-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        background: gray;
        width: calc(100% - 16px);
        height: 100%;
    }
    .screen {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-49%, -49%);
        transform-origin: center center;
        height: 1440px;
        width: 1080px;
        background: #111111;
        display: flex;
        flex-direction: column;

        .media-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 42px;
            .scrub-bar {
                width: 900px;
                margin: 20px 160px;
                height: 8px;
                background: ${silver_two};
            }
            .scrub-handle {
                width: 32px;
                height: 32px;
                border: solid 8px ${silver_two};
                margin-top: -48px;
                margin-left: 90px;
                background: white;
                margin-right: auto;
                border-radius: 50%;
            }
            .time-container {
                display: flex;
                flex: 1;
                font-size: 60px;
                width: 900px;
                font-weight: bold;
                .end {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
            .play-icon {
                height: 80px;
                width: 80px;
                filter: invert(1);
            }
        }

        .card {
            margin: 40px;
            padding: 32px;
            background: #252525;
            border: 5px solid #aaaaaa;

            .body-text, .title-text {
                padding-top: 12px;
                padding-left: 12px;
            }
        }

        .body-text, .title-text {
            font-family: 'Roboto', sans-serif;
            
            padding-top: 80px;
            padding-left: 80px;
            color: #FFFFFF;    
            overflow-wrap: break-word;
            z-index: 2;
            position: relative;
        }
        .detail-text {
            color: ${cool_grey};
            font-family: 'Roboto', sans-serif;
            overflow-wrap: break-word;
            z-index: 2;
            position: relative;
            padding-left: 80px;            
            font-size: 42px;
        }
        .title-text {
            font-size: 80px;
        }
        .foreground-image {
            z-index: 2;
            height: auto;
            display: flex;
            width: 900px;
            height: 506px;
            justify-content: center;
            align-items: center;
            img {
                height: 100%;
                width: auto;
            }
        }
        .body-text {
            overflow-y: hidden;
            flex: 1;
            font-size: 42px;
        }
        .body-container {
            z-index: 2;
            position: relative;
        }
    }
`
interface CortanaWrapperProps {
    className?: string
}
const CortanaScreenScreenWrapper: React.SFC<CortanaWrapperProps> = (props) =>
    (
        <div className={cortanaBodyStyle}>
            <div className='screen-wrapper'>
                <ContainerDimensions>
                    {({ width, height }) =>
                        <div className={`screen ${props.className}`} style={{ transform: `translate(-50%, -50%) scale(${Math.min(width / 1080, height / 1440)})` }}>
                            {props.children}
                        </div>
                    }
                </ContainerDimensions>
            </div>
        </div >
    )

export default CortanaScreenScreenWrapper;