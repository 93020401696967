import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { color_shades_dark, color_shades_darkest, color_variants_blue_2, color_variants_green_2, color_variants_red_2, color_variants_shadow_10 } from "../../../constants/colors";
import NlpModelTrainingContainer from "../../../hooks/NlpModelTrainingContainer";
import AssistantApplicationModel from "../../../models/applications/api/AssistantApplicationModel";

const closeIcon = require('../../../content/images/close_icon.svg');
const redIcon = require('../../../content/images/nlp-icons/training-indicator-red.svg');
const greenIcon = require('../../../content/images/nlp-icons/training-indicator-green.svg');
const loadingGif = require('../../../content/images/nlp-icons/brainimation.gif');


const NlpModelTrainingIndicator: React.FC<{ application: AssistantApplicationModel }> = (props) => {
    
    const indicatorHook = NlpModelTrainingContainer.useContainer();

    useEffect(() => {
        indicatorHook.setNewApplication(props.application);
    }, [props.application?.id])

    if (indicatorHook.indicator?.type === "success") {
        return <IndicatorWrapper onClick={indicatorHook.clear} >
                <GreenIndicator>
                    <TrainingIcon src={greenIcon} />
                <IndicatorText>{indicatorHook.indicator.message}</IndicatorText>
                <CloseIcon src={closeIcon} />
                </GreenIndicator>            
            </IndicatorWrapper>
    }
    
    if (indicatorHook.indicator?.type === "building") {
        return <IndicatorWrapper onClick={indicatorHook.clear} >
            <BlueIndicator>
                <TrainingGif src={loadingGif} />
                <IndicatorText>{indicatorHook.indicator.message}</IndicatorText>
                <CloseIcon src={closeIcon} />
            </BlueIndicator>
        </IndicatorWrapper>
    }

    if (indicatorHook.indicator?.type === "error") {
        return <IndicatorWrapper onClick={indicatorHook.clear} >
            <RedIndicator>
                <TrainingIcon src={redIcon} />
                <IndicatorText>{indicatorHook.indicator.message}</IndicatorText>
                <CloseIcon src={closeIcon} />
            </RedIndicator>
        </IndicatorWrapper>
    }

    return <></>
}

export default NlpModelTrainingIndicator;

const IndicatorText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${color_shades_darkest};
    margin: auto auto auto 16px;
`;

const TrainingIcon = styled.img`
    width: 88px;
    height: 80px;
    margin: auto 0px auto 16px;
`;

const TrainingGif = styled.img`
    width: 96px;
    height: 92px;
    margin: auto 0px auto 16px;
`;

const CloseIcon = styled.img`
    margin: auto 32px auto auto;
    width: 12px;
    height: 12px;
`;

const IndicatorWrapper = styled.div`
    position: fixed;
    bottom: 32px;
    right: 32px;
    width: 528px;
    height: 104px;
    border-radius: 8px;
    border: 1px solid ${color_shades_dark};
    box-shadow: 0px 4px 20px ${color_variants_shadow_10};
    z-index: 1100;
    background: white;
    box-sizing: border-box;
    cursor: pointer;
`;

const Indicator = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    border-radius: 8px;
    width: 100%;
`;

const GreenIndicator = styled(Indicator)`
    background: ${color_variants_green_2};
`;

const RedIndicator = styled(Indicator)`
    background: ${color_variants_red_2};
`;

const BlueIndicator = styled(Indicator)`
    background: ${color_variants_blue_2};
`;