import React from 'react';
import ContentLanguageMultiSelector from '../../components/forms/ContentLanguageMultiSelector';
import TextField from '../../components/forms/TextField';
import TitleTextField from '../../components/forms/TitleTextField';
import Loader from '../../components/general/Loader';
import ContentCard from '../../components/structure/ContentCard';
import ContentCollapsingSection from '../../components/structure/ContentCollapsingSection';
import FormFieldsContainer from '../../components/structure/FormFieldsContainer';
import { TreeItem } from '../../components/structure/NestedTree/NestedTree';
import PageContainer from '../../components/structure/PageContainer';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import { CustomRequestsFeatureTypeId } from '../../constants/featureTypeIds';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import { getFeatureTypeIcon } from '../../models/extensions';
import CustomRequestModel from '../../models/features/api/CustomRequests/CustomRequestModel';
import ConversationItemFormProps from '../../models/features/ConversationItemFormProps';
import IResult from '../../models/result/IResult';
import CustomRequestContainer from '../../state/containers/CustomRequestContainer';
import ContentPagePanel from '../contentPage';
import ContentSectionTitle from '../contentPage/components/ContentSectionTitle';
import PanelTitle from '../contentPage/components/PanelTitle';
import ConversationFlow from '../conversationFlow';
import FollowUpWithRepromptForm, { FollowUpWithRepromptFormData, FollowUpWithRepromptFormProps } from '../followUpForm';
import RelatedContentMenu from '../relatedContentMenu';
import PageError from '../../components/general/PageError';
import ConversationItemActionButtonToolbar from './components/ConversationItemActionButtonToolbar';
import TemplateLockedBanner from '../../components/general/TemplateLockedBanner';
import ConversationItemFieldSet from '../../components/forms/ConversationItemFieldSet';
import ContentInfoOptions from '../../components/structure/ContentInfoOptions';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';
import CustomRequestResponseModel from '../../models/features/api/CustomRequests/CustomRequestResponseModel';
import ResponsesManager from '../responsesManager';
import { ContentItemPageState } from './BaseConversationItemForm';
import ResponseGroup from '../../models/features/ResponseGroup';
import { DirtyFormPrompt } from './components/DirtyFormPrompt';
import CustomRequestInputOptions from './components/CustomRequestInputOptions';
import { SensitiveConversationChange } from '../../models/features/api/ContentItemModel';
import SensitiveDataOptions from '../contentPage/components/SensitiveDataOptions';

const userIcon = require('../../content/images/user.svg');
const contentInfoIcon = require('../../content/images/content-tree-icons/content-level/task-list-pin.svg');
const inputGroupIcon = require('../../content/images/content-tree-icons/content-level/input-stack.svg');
const inputIcon = require('../../content/images/content-tree-icons/content-level/input-single.svg');

export interface CustomRequestContentFormData extends FollowUpWithRepromptFormData {
    title: string
    requestTypes?: string
    requestNames?: string
    platformFilter?: string
    responses: CustomRequestResponseModel[]
    webhookChainingType?: WebhookChainingType,
}
export interface CustomRequestContentFormProps extends FollowUpWithRepromptFormProps<CustomRequestModel>, ConversationItemFormProps<CustomRequestModel> {
    stateContainer: CustomRequestContainer
    application: ApplicationModel
    customRequest?: CustomRequestModel
}

class CustomRequestForm extends FollowUpWithRepromptForm<CustomRequestModel, CustomRequestContentFormProps, CustomRequestContentFormData, ContentItemPageState> {
    handleAsyncSubmit(onSuccess: (result: IResult<CustomRequestModel>) => void) {
        if (this.props.onSubmit && this.manualValidate()) {
            return this.props.onSubmit(this.props.stateContainer.mapFormDataToModel(this.props.customRequest, this.props.values), onSuccess);
        }
    }
    handleDiscardChanges() {
        this.handleFinish();
    }
    handleDelete() {
        const confirmed = confirm("Are you sure you want to delete this?");
        if (confirmed) {
            this.props.stateContainer.delete(this.props.customRequest.id).then(result => {
                this.handleFinish();
            });
        }
    }
    handleCopy() {
        this.handleAsyncSubmit(() => {
            this.props.stateContainer.copy(this.props.customRequest.id).then(r2 => {
                if (r2.resultType == "Ok") {
                    this.props.history.push(`/v/${r2.data.applicationFeatureId}/customrequests/${r2.data.id}`);
                }
            });
        });
    }

    getMenuTreeRoots() {
        var roots: TreeItem[] = [this.getContentInfoTreeItem(), {
            id: 'input-details',
            title: 'Input Requirements',
            isExpanded: this.isItemExpanded('input-details'),
            canExpand: true,
            icon: userIcon,
            linkTo: "#input",
            children: [{
                id: 'input-options',
                title: 'Intents and Events',
                canExpand: true,
                isExpanded: this.isItemExpanded('input-options'),
                icon: inputGroupIcon,
                linkTo: "#inputOptions",
                children: [{
                    id: 'requestTypes',
                    title: 'Request Types',
                    canExpand: false,
                    icon: inputIcon,
                    linkTo: "#requestTypes"
                }, {
                    id: 'requestNames',
                    title: 'Request Names',
                    canExpand: false,
                    icon: inputIcon,
                    linkTo: "#requestNames"
                }, {
                    id: 'platforms',
                    title: 'Platforms',
                    canExpand: false,
                    icon: inputIcon,
                    linkTo: "#platforms"
                }]
            }]
        },
        ...this.getResponsesTreeItems(this.props.values.responses)
        ]

        return roots;
    }
    handleResponsesChange(responseGroups: ResponseGroup[]) {
        this.props.setFieldValue("responses", this.getResponsesFromGroups(responseGroups));
    }
    getContentItem() {
        return this.props.customRequest;
    }
    handleContentItemOptionSelected(option: string, event: any) {
        event.stopPropagation();
        if (option == "Manage Webhooks") {
            this.showWebhookInstanceManager();
        }
        else if (option == "Duplicate") {
            this.handleCopy();
        }
        else if (option == "Delete") {
            this.handleDelete();
        }
    }
    handleUpdateInputValues(requstNames: string, requestTypes: string, platformFilter: string) {
        this.props.setFieldValue("requestNames", requstNames);
        this.props.setFieldValue("requestTypes", requestTypes);
        this.props.setFieldValue("platformFilter", platformFilter);
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.stateContainer.state.isSaving;
        const isDisabled = isLoading || this.props.isTemplateOwned;
        const responseGroups = this.getResponseGroups(this.props.values.responses);
        if (this.props.responseStateContainer.state.isLoading || !this.props.application) {
            return <Loader />
        }
        return (
            <>
                <form onSubmit={props.handleSubmit}>
                    <DirtyFormPrompt allowPrompt={props.status?.submitting != true}/>
                    <ContentPagePanel
                        relatedContentRender={() => (<RelatedContentMenu
                            parentContentId={this.props.previousContentId}
                            conversationItem={{ ...this.props.customRequest, featureTypeId: CustomRequestsFeatureTypeId }}
                            history={this.props.history}
                            featureTypeId={CustomRequestsFeatureTypeId}
                            followUp={responseGroups?.[0]?.firstResponse?.followUp}
                            contentId={this.props.customRequest?.id}
                            onClose={this.props.onToggleNavigation}
                            onSave={this.handleAsyncSubmit.bind(this)} />)
                        }
                        treeRender={this.renderTree.bind(this)}
                        formPanelRender={() => (
                            <PageContainer fill>
                                <ConversationItemActionButtonToolbar
                                    errors={Object.values(props.errors)}
                                    validateForm={props.validateForm}
                                    publishDisabled={this.props.assistantApplication == null}
                                    saveDisabled={ Object.keys(props.errors).length > 0}
                                    contentItem={props.customRequest}
                                    isLoading={isLoading}
                                    handleDiscardChanges={this.handleDiscardChanges.bind(this)}
                                    handlePublishing={this.handleSaveAndPublish.bind(this)}
                                    submitForm={props.submitForm}
                                />
                                {props.customRequest?.createdFromId && <TemplateLockedBanner isLoading={props.stateContainer.state.isTogglingSync} isLinked={props.isTemplateOwned} onRelink={this.handleToggleRelinkModal.bind(this)} onUnlink={() => this.props.onToggleSync(this.props.customRequest.id, true)} />}

                                <ConversationItemFieldSet disabled={isDisabled} withBanner={props.customRequest?.createdFromId != null}>
                                    <ScrollablePanelBody className="scrollable-body">
                                        <PanelTitle title="Content Editing"
                                            detail="Here’s where you can fill in the info about this content and determine the course of the conversation by filling in the relevant details.">
                                        </PanelTitle>
                                        <ContentCollapsingSection title="Content Info" id="contentInfo" icon={contentInfoIcon} titleRightRowComponent={
                                            <ContentInfoOptions webhookCount={this.props.stateContainer.state.webhookUpdates?.webhookInstances?.length ?? this.props.stateContainer.state.attachedWebhooks?.length ?? 0}
                                                options={this.getOptions(this.props.customRequest != null)}
                                                optionSelected={this.handleContentItemOptionSelected.bind(this)}
                                                manageWebhooks={this.showWebhookInstanceManager.bind(this)}
                                                showSensitiveConversation={this.state.showSensitiveConversation}
                                                toggleSensitiveConversation={() => { this.toggleSensitiveConversation(); }} />
                                        }>
                                            {this.state.showSensitiveConversation &&
                                                <SensitiveDataOptions
                                                    selectedOption={props.values.sensitiveConversationChange ?? "None"}
                                                    onChange={(value) => { props.setFieldValue("sensitiveConversationChange", value) }}
                                                />
                                            }
                                            <ContentSectionTitle title="Custom Request Conversation" icon={getFeatureTypeIcon(CustomRequestsFeatureTypeId, true)} />
                                            <TitleTextField id="contentTitle" disabled={isDisabled} name="title" placeholder="For display only" value={props.values.title} label="Title" onChange={props.handleChange} onBlur={props.handleBlur} />
                                            <ContentLanguageMultiSelector disabled={isDisabled} id="contentLanguage" label="Language / Region" languages={props.application.languages} selectedLanguageIds={props.values.languages.map(l => l.id)} onChange={this.handleLanguagesChange.bind(this)} />
                                            {this.renderOptions(false, isDisabled, false, true)}
                                        </ContentCollapsingSection>
                                        <CustomRequestInputOptions
                                            isDisabled={isDisabled}
                                            updateValues={this.handleUpdateInputValues.bind(this)}
                                            languages={this.props.values.languages}
                                            requestNames={this.props.values.requestNames}
                                            requestTypes={this.props.values.requestTypes}
                                            platformFilter={this.props.values.platformFilter}
                                            onBlur={this.props.handleBlur}
                                            setFieldValue={this.props.setFieldValue}
                                            onChange={this.props.handleChange}
                                            application={this.props.application}/>                               
                                        <ResponsesManager applicationId={this.props.application.id}
                                            applicationFeatureId={this.props.applicationFeatureId}
                                            languages={this.props.values.languages}
                                            responseGroups={responseGroups}
                                            getConditionsKey={(c) => this.getConditionsKey(c)}
                                            isDisabled={isDisabled}
                                            onChange={this.handleResponsesChange.bind(this)}
                                            onBlur={this.props.handleBlur} />
                                    </ScrollablePanelBody>
                                </ConversationItemFieldSet>
                                <PageError errors={this.props?.stateContainer?.state?.errors ?? []} onClear={this.handleClearError.bind(this)} />
                            </PageContainer>
                        )}
                        flowPanelRender={() => (
                            <ConversationFlow
                                applicationId={this.props.application.id}
                                key={this.props.values.responseContainer ? this.props.values.responseContainer.id : ''}
                                endSession={!this.props.values.responses?.some(r => r.followUp != null)}
                                languages={this.props.values.languages}
                                conversationItems={[{
                                    requests: [
                                        { content: `[Custom Request]`, highlights: [], id: '1' },
                                    ],
                                    requestTitle: "User Question",
                                    children: this.getPreviewResponses(props.values.responses)
                                }]} />

                        )}>
                    </ContentPagePanel>
                </form>
                {this.renderRelinkSyncWarning()}
                {this.renderWebhookInstanceManager(this.props.application.id, this.props.customRequest?.id, CustomRequestsFeatureTypeId, this.props.values?.webhookChainingType)}
            </>
        )
    }
}
export default CustomRequestForm;