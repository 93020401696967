import React from 'react';
import { css } from '@emotion/css';
import ConversationItemSlot from '../../../models/features/ConversationItemSlot';
import { dark_grey_blue, cool_grey } from '../../../constants/colors';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import VerticalSeparator from '../../../components/structure/VerticalSeparator';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { getFeatureTypeIcon } from '../../../models/extensions';
import ConversationItemWrapper from './ConversationItemWrapper';
const deleteIcon = require('../../../content/images/trash.svg');


interface ConversationItemCardProps {
    slot: ConversationItemSlot
    canDelete?: boolean
    onAddItem?: () => void
    onDelete?: () => void
}

const containerStyle = css`
    background: white;
    border-radius: 10px;
    border: solid 1px #c7cfd2;
    padding: 20px;
    margin: 16px 24px;

    .title-container {
        display: flex;
        align-items: center;

        .title {
            font-family: Muli;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${dark_grey_blue};
            margin-bottom: 20px;
            flex: 1;
        }

        .delete {
            cursor: pointer;
            margin-right: 0;
            margin-left: auto;
        }
    }

    .detail {
        font-family: Muli;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: ${cool_grey};
        margin-top: 20px;
        margin-left: 0;
    }
}
`
const ConversationItemCard: React.SFC<ConversationItemCardProps> = (props) => (
    <div className={containerStyle}>
        <div className="title-container">
            <h5 className="title">{props.slot.title}</h5>
            {props.canDelete ? <img src={deleteIcon} className="delete" onClick={props.onDelete} /> : null}
        </div>
        <HorizontalSeparator />
        <p className="detail">{props.slot.description}</p>
        <ConversationItemWrapper slot={props.slot} onClick={props.onAddItem} />
    </div>
);

export default ConversationItemCard;