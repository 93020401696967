import React from 'react';
import { ArrowRendererProps, MenuRendererProps } from 'react-select';
import { color_colors_ocean, color_shades_dark, color_shades_darker, color_shades_darkest, color_variants_ocean_xlight } from '../../constants/colors';
import styled from '@emotion/styled';
import ToggleIndicator from '../general/ToggleIndicator';
import DropDownSelectField from '../forms/DropDownSelectField';

const doorUnlocked = require('../../content/images/open-entry-door-unlocked.svg');
const targetedLock = require('../../content/images/targeted-entry-lock.svg');
const doorLocked = require('../../content/images/no-entry-door-locked.svg');
const doorUnlockedSelected = require('../../content/images/open-entry-door-unlocked-selected.svg');
const targetedLockSelected = require('../../content/images/targeted-entry-lock-selected.svg');
const doorLockedSelected = require('../../content/images/no-entry-door-locked-selected.svg');

type optionValueType = "open" | "targeted" | "closed" | "alwaysOpen";

interface ConversationAccessSelectorProps {
    onChange?: (e: React.ChangeEvent<any>) => void
    value?: string
    neverOpenAccess?: boolean
    neverTargetedAccess?: boolean
    disabled?: boolean
    alwaysOpenAvailable?: boolean
}

const options = [
    {
        value: "open" as optionValueType,
        label: "Open", image: doorUnlocked,
        description: "Anyone can trigger this conversation item at any point during a session",
        selectedImage: doorUnlockedSelected
    }, {
        value: "targeted" as optionValueType,
        label: "Targeted Access",
        image: targetedLock,
        description: "Only accessible via a targeted follow-up from another conversation item",
        selectedImage: targetedLockSelected
    },
    {
        value: "closed" as optionValueType,
        label: "No Access",
        image: doorLocked,
        description: "This conversation item exists within Voicify but is not meant to be hit by users",
        selectedImage: doorLockedSelected
    }]

const alwaysOpenOption = {
    value: "alwaysOpen" as optionValueType,
    label: "Always Open", image: doorUnlocked,
    description: "Always available even when context is limted but maintains prior conversation context",
    selectedImage: doorUnlockedSelected
};

const formatValueLabel = ({ label, image }) => (
    <CustomSelectChosenOption>
        <img src={image}></img>
        <p>{label}</p>
    </CustomSelectChosenOption>
);
const getMenuOptions = (alwaysTargeted: boolean, NeverTargeted: boolean, alwaysOpen: boolean) => {
    let returnList = [];
    if(alwaysTargeted){
        returnList = options.filter(option => option.value == "targeted" || option.value == "closed")
    }
    else if(NeverTargeted){
        returnList = options.filter(option => option.value == "open" || option.value == "closed")
    }
    else{
        returnList = options
    }
    if (alwaysOpen && returnList.findIndex(option => option.value === "alwaysOpen") == -1) {
        returnList.push(alwaysOpenOption);
    }
    return returnList;
}
const ConversationAccessSelector = (props: ConversationAccessSelectorProps) => {
    const menuPropOptions = getMenuOptions(props.neverOpenAccess, props.neverTargetedAccess, props.alwaysOpenAvailable);
    return (<ConversationAccessSelectorWrapper>
        <p className="user-entry">Conversation Access</p>
        <CustomDropDownSelect
            className="ac-access"
            clearable={false}
            searchable={false}
            valueRenderer={formatValueLabel}
            options={options}
            onChange={props.onChange}
            disabled={props.disabled}
            value={props.value}
            key="user-entry-selector"
            arrowRenderer={(p: ArrowRendererProps) => <Arrow direction={p.isOpen ? 'up' : 'down'} />}
            menuRenderer={(menuProps: MenuRendererProps) => {                
                return (<MenuOptionsWrapper>
                    <Triangle />
                    {menuPropOptions.map((option, index) =>
                        <SelectOption key={index} className="user-entry-option" onClick={() => menuProps.selectValue(option)}>
                            <CustomSelectOptionLabel>
                                {option.image ? <ConversationAccessIcon src={menuProps.valueArray[0]?.value == option.value ? option.selectedImage : option.image}></ConversationAccessIcon> : null}
                                <div>
                                    <p className={menuProps.valueArray[0]?.value == option.value ? "option-selected" : ""}>{option.label}</p>
                                    <p className={menuProps.valueArray[0]?.value == option.value ? "option-description-selected" : "option-description"}>{option.description}</p>
                                </div>
                            </CustomSelectOptionLabel>
                            {index != menuProps.options.length - 1 && <Divider />}
                        </SelectOption>
                    )}
                </MenuOptionsWrapper>
                )
            }}
        />
    </ConversationAccessSelectorWrapper>
    )
}

const Arrow = styled(ToggleIndicator)`
    height: 8px;
    width: 8px;
`;

const ConversationAccessIcon = styled.img`
    height: 24px;
    width: 24px;
    margin-top: 4px;
`
const SelectOption = styled.div`
    &.user-entry-option:hover{
            background-color: ${color_variants_ocean_xlight};
            cursor: pointer;
        }
`
const MenuOptionsWrapper = styled.div`
    margin-top: 8px;
`;

const CustomSelectChosenOption = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    p {
        color: ${color_shades_darkest};
        margin-left: 10px;   
    }
    img {
        height: 24px;
        width: 24px;
        margin-top: 4px;
    }
`;

const Divider = styled.div`
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${color_shades_dark};
    padding: 0;
`;

const CustomSelectOptionLabel = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    div{
        line-height: normal;
        margin-left:10px; 
        color: ${color_shades_darkest};
    }
    .option-selected{
        color: ${color_colors_ocean};
    }
    .option-description{
        font-size: 14px;
        color: ${color_shades_darker}; 
    }
    .option-description-selected{
        font-size: 14px;
        color: ${color_colors_ocean}; 
    }
`;

const Triangle = styled.div`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    border-top: solid 1px ${color_shades_dark};
    border-left: solid 1px ${color_shades_dark};
    background: white;
    margin-top: -7px;
    right: 15px;
    position: absolute;
`;

const ConversationAccessSelectorWrapper = styled.div`
    margin-top: 24px;
    .user-entry{
        margin-left: 16px;
        font-size: 14px;
        color: ${color_shades_darkest};
    }
`
const CustomDropDownSelect = styled(DropDownSelectField)`
    margin-top: 4px;
    .Select-multi-value-wrapper{
        height: 48px;
    }
    .Select-menu-outer{
        border-top-color: ${color_shades_dark};
    }
    .Select-value{
        margin-top: 8px;
    }
    .Select-control {
        border-radius: 4px;
    }
    &.is-open  {
        .Select-control {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-color: ${color_shades_dark};
        }
    }
`
export default ConversationAccessSelector