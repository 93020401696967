import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue_10 } from '../../constants/colors';

const cardStyle = css`
    background: white;
    box-shadow: 0 2px 4px 0 ${dark_grey_blue_10};
    border: solid 1px #cbd0d2;
    border-radius: 8px;
    height: auto;
`

interface PageCardProps {
    className?: string
}

const PageCard: React.SFC<PageCardProps> = (props) => (
    <div className={`${props.className ?? ''} ${cardStyle}`}>
        {props.children}
    </div>
)

export default PageCard;