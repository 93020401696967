import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import ModalHeader from '../../../components/general/ModalHeader';
import PageError from '../../../components/general/PageError';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import FooterBar from '../../../components/structure/FooterBar';
import SmallModalLayout from '../../../components/structure/SmallModalLayout';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';

interface SaveBackupProps {
    backupsContainer: ApplicationBackupVersionsContainer
    applicationId: string
    onClose: () => void
}
interface SaveBackupFormData {
    name: string
}
const InnerForm: React.FC<SaveBackupProps & FormikProps<SaveBackupFormData>> = (props) => (
    <SmallModalLayout isVisible onClose={props.onClose} className={customModalInner}>
        <ModalForm onSubmit={props.handleSubmit}>
            <ModalHeader title="Save Backup" onClose={props.onClose} />
            <div className="modal-body">
                <p>Save a copy of your App in its current state. You can then use this version as a reference for later revisions or to publish to your live instance.</p>
                <TextField name="name" placeholder="Saved" label="Backup Name" onBlur={props.handleBlur} onChange={props.handleChange} />
            </div>
            <FooterBar>
                <Button type="submit" themes={['primary', 'start']} text="Save" disabled={props.isSubmitting} loading={props.isSubmitting} />
                <Button type="button" themes={['secondary', 'end']} text="Cancel" onClick={() => props.onClose()} disabled={props.isSubmitting} loading={props.isSubmitting} />
            </FooterBar>
        </ModalForm>
        <PageError errors={props.backupsContainer.state.errors ?? []} onClear={() => props.backupsContainer.clearErrors()} />
    </SmallModalLayout>
)

const SaveApplicationBackupModal = withFormik<SaveBackupProps, SaveBackupFormData>({
    mapPropsToValues: props => ({ name: '' }),
    validationSchema: yup.object().shape({
        name: yup.string().required("You must enter a name for the backup")
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        var backupResult = await props.backupsContainer.queueCreateFullBackup(props.applicationId, { name: values.name, description: '' });
        if(backupResult?.resultType == "Ok") {
            // We do not need to do await here since its going to wait for the backup in the container in the background
            props.backupsContainer.waitForBackup(backupResult.data.id);
            setSubmitting(false);
            props.onClose();
            return;
        }
        setSubmitting(false);
    }
})(InnerForm);

const customModalInner = css`
    height: 416px;
    min-height: 416px;
`
const ModalForm = styled.form`
    min-height: 416px;
    height: 416px;
    padding-bottom:64px;
    .modal-body{
        padding: 0 32px;
        margin-bottom: 180px;
        height: 100%;
        flex: 1;
        >h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        >p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin: 24px 0;
        }
    }
`

export default SaveApplicationBackupModal;