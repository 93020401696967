import { css } from "@emotion/css";
import React from "react";
import { useState } from "react";
import Button from "../../../components/general/Button";
import WarningConfirmation from "../../../components/general/WarningConfirmation";
import ConfirmationDialog from "../../../components/structure/ConfirmationDialog";
import { color_shades_dark, color_text_default, color_colors_ocean, color_variants_ocean_light_2, color_colors_decline } from "../../../constants/colors";
import EditableIntent from "../../../models/interactionModel/EditableIntent";
import EditableSlot from "../../../models/interactionModel/EditableSlot";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import { maxSlotNameDisplaySize } from "./SlotsEditor";
import { FormikProps } from "formik";
import { SlotsEditorFormData } from "./SlotsEditorForm";
import ErrorLabel from "../../../components/general/ErrorLabel";
import styled from "@emotion/styled";

const deleteIcon = require('../../../content/images/trash.svg');
const addIcon = require('../../../content/images/add-blue.svg');
const redNoCircle = require('../../../content/images/red-no-circle.svg');
const yellowUpdate = require('../../../content/images/yellow-update.svg');
const alertIcon = require('../../../content/images/alert-circle.svg');
const requiredSlotIcon = require('../../../content/images/nlp-icons/required-slot.svg');
const requiredGroupIcon = require('../../../content/images/nlp-icons/required-group.svg');
const disallowedSlotIcon = require('../../../content/images/nlp-icons/disallowed-slot.svg');

interface SlotItemProps {
    toggleItemActivationByIndex: (index: number) => void;
    applicationId: string;
    nlpEntities: EditableNlpEntity[];
    intent: EditableIntent;
    slot: EditableSlot;
    isActive: boolean;
    formPrefix: string;
    formIndex: number;
}

const SlotItem: React.FC<FormikProps<SlotsEditorFormData> & SlotItemProps> = (props) => {
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

    const isActive: boolean = props.isActive;
    const slotName: string = props.slot?.name;
    const slotDisplayName: string = "{ } " + (slotName?.trim().length > maxSlotNameDisplaySize
        ? (slotName.trim().substring(0, maxSlotNameDisplaySize) + "...")
        : slotName);

    const isDeleted: boolean = props.slot?.isDeleted;
    const isModified: boolean = props.slot?.isModified;
    const cardPrefix = `${props.formPrefix}.slots.${props.formIndex}`;
    const isModifiedField = `${cardPrefix}.isModified`;
    const isDeletedField = `${cardPrefix}.isDeleted`;

    const hasUnsavedChanges: boolean = props.slot?.isDeleted || props.slot?.isAdded || props.slot?.isModified;
    const disableFields: boolean = isDeleted || props.isSubmitting || props.isValidating;
    const hasErrors = props.errors?.[props.formPrefix]?.["slots"]?.[props.formIndex];

    const handleItemToggleActivation = () => {
        props.toggleItemActivationByIndex(props.formIndex);
    };

    const toggleConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
    };

    const onConfirmDelete = () => {
        props.setFieldValue(isDeletedField, true);
        props.setFieldValue(isModifiedField, true);
        // Reset any validation errors on deleted item, otherwise the submit will not work
        props.validateForm();
        toggleConfirmDeleteModal();
    };

    const renderItemName = () => {
        return (
            <div className={itemTitle} key={slotName}>
                <Button
                    themes={["black-small"]}
                    text={slotDisplayName}
                    type="button" />
            </div>
        )
    };

    const getSlotDetailsIcon = () => {
        switch (true) {
            case props.slot?.disallowed:
                return (
                    <Button themes={['icon', 'end-tight']}
                        icon={disallowedSlotIcon}
                    />
                )
            case props.slot?.required:
                return (
                    <Button themes={['icon', 'end-tight']}
                        icon={requiredSlotIcon}
                    />
                )
            case props.slot?.requiredSlotGroupIds?.length > 0:
                return (
                    <RequiredSlotGroupWrapper>
                        <SlotGroupText>{props.slot.requiredSlotGroupIds[0]}</SlotGroupText>
                        <Button themes={['icon', 'end-tight']}
                            icon={requiredGroupIcon}
                        />
                    </RequiredSlotGroupWrapper>
                )
        }
    };

    const renderItemActions = () => {
        return (
            <div className={itemActions} >
                {getSlotDetailsIcon()}
                {hasErrors &&
                    <ErrorLabel image={alertIcon} text={"Errors Present"} />
                }
                {hasUnsavedChanges &&
                    <Button themes={['icon', 'end-tight']}
                        icon={isDeleted ? redNoCircle : (isModified ? yellowUpdate : addIcon)} />
                }
                {!disableFields &&
                    <Button themes={['icon', 'end-tight']}
                        icon={deleteIcon}
                        type="button"
                        disabled={disableFields}
                        onClick={toggleConfirmDeleteModal} />
                }
            </div>
        )
    };

    const renderSlotDisplayItem = () => {

        return (
            <div className={`${itemContainer} ${isActive ? 'active' : ''}`} >
                <div className={`${itemHeader} ${isActive ? 'active' : ''}`}
                    key={`menuItem-${slotName}`}
                    onClick={() => handleItemToggleActivation()}
                >
                    {renderItemName()}
                    {renderItemActions()}
                </div>
                {isConfirmDeleteModalOpen &&
                    <ConfirmationDialog title={`Confirm Delete`}
                        deleteText="Yes, Delete"
                        isLoading={false}
                        onClose={toggleConfirmDeleteModal}
                        onConfirm={onConfirmDelete}>
                        <WarningConfirmation
                            text={`Are you sure you want to delete the slot "${slotName}"? 
                        When you choose "Apply Changes" all utterances that are using this slot will become invalid.`} />
                    </ConfirmationDialog>
                }
            </div>
        )
    };

    return (
        renderSlotDisplayItem()
    );

};

export default SlotItem;

const SlotGroupText = styled.div`
    color: ${color_colors_decline};
    font-size: 8px;
`;

const RequiredSlotGroupWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const itemContainer = css`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    padding: 0 16px 0 16px;
    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
        min-height: 56px;
        height: auto;
    }
`;

const itemHeader = css`
    height: 56px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const itemTitle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    color: ${color_text_default};
    position:relative;
`;

const itemActions = css`
    display: flex;
`;
