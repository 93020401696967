import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaRectangleWrapper from './AlexaRectangleWrapper';

const screenStyle = css`
    color: white;
    .title-text {
        font-family: 'Roboto', sans-serif;
        font-size: 80px;
        padding-top: 80px;
        padding-left: 80px;
        padding-right: 160px;
        color: white;    
        overflow-wrap: break-word;
        z-index: 2;
        position: relative;
    }
    
    .body-container {
        display: flex;
        z-index: 2;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .hint-text {
        font-family: 'Noto Serif', serif;
        font-size: 60px;
        color: white;
        font-style: italic;
        z-index: 2;
        padding-left: 160px;
        position: relative;
    }
    .foreground-image {
        z-index: 2;
        height: 800px;
        width: auto;
        padding-left: 80px;
        padding-right: 80px;
        img {
            width: auto;
            height: 100%;
        }
    }
    .background-image {
        width: 1920px;
        height: 1080px;
        position: absolute;
        z-index: 1;
    }
`
class AlexaBody7TemplatePreview extends ScreenPreviewComponent {
    getHintText() {
        if (this.state.hints && this.state.hints.length > 0) {
            const hint = this.state.hints[0];
            return `Try "Alexa, ${hint.content}"`;
        }
        return '';
    }
    render() {
        return (
            <AlexaRectangleWrapper className={screenStyle}>
                {this.state.backgroundImageUrl ? <img className="background-image" src={this.state.backgroundImageUrl} /> : null}
                {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                <div className="body-container">
                    {this.state.foregroundImageUrl
                        ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                        : null}
                </div>
                {this.state.hints ? <p className="hint-text">{this.getHintText()}</p> : null}
            </AlexaRectangleWrapper>
        )
    }
}

export default AlexaBody7TemplatePreview;