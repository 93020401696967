import React from 'react';
import { css } from '@emotion/css';
import { cool_grey, dark_grey_blue_10, dark_grey_blue, color_shades_dark } from '../../../constants/colors';
import { FeatureSelectListItem } from './FeatureSelectListItem';
import FeatureModel from '../../../models/features/api/FeatureModel';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import Scrollbars from 'react-custom-scrollbars';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
const folderIcon = require('../../../content/images/content-explorer/folder-blue.svg');
const importIcon = require('../../../content/images/content-explorer/import.svg');

interface NewFeaturePickerProps {
    featureContainer: FeatureContainer
    onSelected: (feature: NewContentSelected) => void
    onClose: () => void
    position: PickerPosition
    featureTypeIdsFilter?: string[]
    isFiltered?: boolean
    contentOnly?: boolean
    featuresOnly?: boolean
    allowImport?: boolean
}

type PickerPosition = "left" | "right"
export type NewContentType = "folder" | "contentItem" | "import";
export interface NewContentSelected {
    feature?: FeatureModel,
    type: NewContentType
}

class NewFeatureOrContentPicker extends React.Component<NewFeaturePickerProps> {
    documentCloser = this.handleDocumentClick.bind(this)
    componentWillMount() {
        this.setState({
            isCollapsed: true
        })
        document.addEventListener("click", this.documentCloser, true);
        this.props.featureContainer.getSystemFeatures();
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.documentCloser, true);
    }
    handleDocumentClick(e: any) {
        if (e.target.closest(".selector-wrapper") || e.target.closest(".selection-container")) return;
        this.props.onClose();
    }
    handleSelectionChange(feature: FeatureModel) {
        this.props.onSelected({
            feature: feature,
            type: "contentItem"
        });
    }
    handleNewFolder() {
        this.props.onSelected({
            type: "folder"
        })
    }
    handleImport() {
        this.props.onSelected({
            type: "import"
        })
    }
    render() {
        const features = this.props.featureTypeIdsFilter && this.props.isFiltered && this.props.featureTypeIdsFilter.length > 0
            ? this.props.featureContainer.state.systemFeatures.filter(s => this.props.featureTypeIdsFilter.some(id => id == s.featureType.id))
            : this.props.featureContainer.state.systemFeatures;
        return (
            <div className={containerStyle + ' ' + (this.props.position == 'left' ? leftStyle : rightStyle)}>
                <div className="selector-wrapper">
                    <div className={triangleStyle}></div>
                    <CustomScrollbars autoHide autoHeight autoHeightMax={600} className="inner-wrapper">
                        {!this.props.contentOnly &&
                            <div className={`${listItemStyle} ac-new-folder`} onClick={() => this.handleNewFolder()}>
                                <img src={folderIcon} />
                                <p>New Folder</p>
                            </div>
                        }
                        {!this.props.featuresOnly && <>
                            {this.props.featureContainer.state.isLoadingSystemFeatures ? <div><SecondaryLoader /></div> :
                                features.map((target, i) => (
                                    <FeatureSelectListItem
                                        key={i}
                                        top={this.props.contentOnly && i == 0}
                                        bottom={this.props.contentOnly && i == features.length - 1}
                                        feature={target}
                                        onSelected={this.handleSelectionChange.bind(this)} />
                                ))
                            }
                        </>}
                        {this.props.allowImport &&
                            <div className={`import ${listItemStyle}`} onClick={() => this.handleImport()}>
                                <img src={importIcon} />
                                <p>Import Content</p>
                            </div>
                        }
                    </CustomScrollbars>
                </div>
            </div>
        )
    }
}

const listItemStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    background: white;
    &.import {
        border-top: 1px solid ${color_shades_dark};
    }
    img {
        width: 32px;
        height: 32px;
        margin: 0 16px;
    }
    p {
        color: ${dark_grey_blue};
        font-size: 16px;
    }
`

const containerStyle = css`
    position: absolute;
   
    .selector-wrapper {
        width: 340px;
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        background: white;
        margin-bottom: 120px;
        margin-top: -12px;
        box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
        border-radius: 10px;
        z-index: 100;
        flex: 1;
        position: absolute;
        .inner-wrapper {
            overflow-y: auto;
            max-height: 600px;
            border: 1px solid #cccccc;
            border-radius: 10px;
        }
    }

    .field-label {
        font-size: 14px;
        color: ${cool_grey};
        background: white;
        padding-left: 32px;
        padding-top: 16px;
    }
`


const triangleStyle = css`
    z-index: 1;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -14px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: white;
    margin-right: 16px;
    margin-left: auto;
`
const rightStyle = css`
    right: 374px;
    top: 82px;
`
const leftStyle = css`
    top: 42px;
    left: 32px;
    .${triangleStyle} {
        margin-left: 16px;
        margin-right: auto;
    }
`
export default NewFeatureOrContentPicker