import React from 'react';
import { css } from '@emotion/css';
import MediaItemModel from '../../models/media/MediaItemModel';
import ReactTable, { Column, SubComponentFunction, ExpandedRows } from 'react-table';
import SortAndPageControl from './SortAndPageControl';
import { Option } from 'react-select'
import ExpandableGrid from './ExpandableGrid';
import NumberIncrementSelector from '../forms/NumberIncrementSelector';

// component used in building forms with consistent components for look and function
interface SortedPaginatedTableProps {
    items: MediaItemModel[]
    columns: Column[]
    sortOptions: Option[]
    page: number
    sortValue: string
    updateSortValue: (i: Option<string>) => void
    updatePageValue: (i: boolean) => void
    itemRender?: (obj : any, column: number) => JSX.Element 
    expandRender?: (obj : any, index: number, column: number) => JSX.Element 
    renderTopToolbar?: () => JSX.Element 
    updateViewValue?: () => void
    view?: "grid" | "table"
    showView?: boolean
    className?: string
    subComponent?: SubComponentFunction
    expandedRows: ExpandedRows
    freezeWhenExpanded: boolean
    reset?: boolean
}

const tableStyles = css`
    border-radius: 10px;
    border: solid 1px #cccccc;
    overflow: hidden;
    .rt-table {
        background: white;
        width: 100%;
        overflow: hidden;
        .rt-thead, .rt-thead -header {
            min-height: 52px;
            box-shadow: none;
            border-bottom: solid 1px #cccccc;
            cursor: default;
            .rt-th {
                border: none;
                cursor: default;
                disabled;
                box-shadow: none;
                .rt-resizable-header-content {
                    cursor: default;
                    div {
                        padding:0px;
                        cursor: default;
                        padding-top:12px;
                    }
                }
            }
        }
        .rt-tbody {
            min-height: 64px;
            overflow: hidden;
            .rt-tr-group {
                min-height: 52px;
                width: 100%;
                border-bottom: solid 1px #cccccc;
                .rt-tr {
                    background: transparent;
                    width: 100%;
                    min-height: 52px;
                    border: none;
                    overflow: visible;
                }
            }
        }
        div.rt-td {
            background: transparent;
            align-self: center;
            overflow: visible;
            border-right: none;
            &.rt-expandable {
                display: none;
            }
        }
    }
    .rt-noData {
        padding-top: 80px;
        background: transparent;
    }
`;
class SortedPaginatedTable extends React.Component<SortedPaginatedTableProps> {     
render() {        
        return (
            <div className={this.props.className}>  
                <SortAndPageControl 
                    updatePageValue={this.props.updatePageValue} 
                    updateSortValue={this.props.updateSortValue}
                    showView={this.props.showView}
                    view={this.props.view}
                    updateViewValue={this.props.updateViewValue}
                    sortValue={this.props.sortValue}
                    page={this.props.page < 10 ? "0" + this.props.page : "" + this.props.page}
                    sortOptions={this.props.sortOptions} 
                    />
                {this.props.renderTopToolbar()}
                {(this.props.view.localeCompare("table") === 0)
                 ?
                    <ReactTable
                        className={tableStyles}
                        data={this.props.items} 
                        sortable={false}
                        resizable={false}
                        expanded={this.props.expandedRows}
                        columns={this.props.columns} 
                        minRows={1}
                        showPagination={false}
                        SubComponent={this.props.subComponent}
                        freezeWhenExpanded={this.props.freezeWhenExpanded}
                    />
                    :
                    <ExpandableGrid 
                        itemRender={this.props.itemRender}
                        expandRender={this.props.expandRender}
                        data={this.props.items}
                        enableExpand={true}
                        reset={this.props.reset}
                        columnCount={2}
                    />
                }                
            </div>
        );
    }
}

export default SortedPaginatedTable;