import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { FormikErrors } from "formik";
import React from "react";
import { useState } from "react";
import Button from "../../../components/general/Button";
import ErrorLabel from "../../../components/general/ErrorLabel";
import WarningConfirmation from "../../../components/general/WarningConfirmation";
import ConfirmationDialog from "../../../components/structure/ConfirmationDialog";
import { color_shades_dark, color_text_default, color_colors_ocean, color_variants_ocean_light_2 } from "../../../constants/colors";
import LanguageModel from "../../../models/features/api/LanguageModel";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import { InteractionModelFormData, maxNlpEntityNameSize } from "./InteractionModelForm";
import TooltipWrapper from '../../../components/general/TooltipWrapper';

const deleteIcon = require('../../../content/images/trash.svg');
const addIcon = require('../../../content/images/add-blue.svg');
const redNoCircle = require('../../../content/images/red-no-circle.svg');
const yellowUpdate = require('../../../content/images/yellow-update.svg');
const duplicateIcon = require('../../../content/images/response-duplicate.svg');
const alertIcon = require('../../../content/images/alert-circle.svg');
const sensitiveEntity = require('../../../content/images/data-sensitivity/computer-lock.svg');
const noTrackingEntity = require('../../../content/images/data-sensitivity/computer-notracking.svg');

interface ApplicationNlpEntityItemProps {
    toggleItemActivationByIndex: (index: number) => void;
    handleCopyNlpEntity: (index: number) => void;
    applicationId: string;
    languages?: LanguageModel[];
    nlpEntity: EditableNlpEntity;
    isActive: boolean;
    formPrefix: string;
    formIndex: number;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    handleSubmit;
    dirty: boolean;
    isValid: boolean;
    status?: any;
    errors?: FormikErrors<InteractionModelFormData>;
}

const ApplicationNlpEntityItem: React.FC<ApplicationNlpEntityItemProps> = (props) => {
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

    const isActive: boolean = props.isActive;
    const nlpEntityName: string = props.nlpEntity.name?.trim().length > maxNlpEntityNameSize
        ? (props.nlpEntity.name.trim().substring(0, maxNlpEntityNameSize) + "...")
        : props.nlpEntity.name;

    const hasUnsavedChanges: boolean = props.nlpEntity.isDeleted || props.nlpEntity.isAdded || props.nlpEntity.isModified;

    const isDeleted: boolean = props.nlpEntity.isDeleted;
    const isModified: boolean = props.nlpEntity.isModified;
    const isCopyable: boolean = props.nlpEntity.isCopyable;
    const cardPrefix = `${props.formPrefix}.${props.formIndex}`;
    const isModifiedField = `${cardPrefix}.isModified`;
    const isDeletedField = `${cardPrefix}.isDeleted`;

    const hasErrors = props.errors?.[props.formPrefix]?.[props.formIndex];

    const handleItemToggleActivation = (index: number) => {
        props.toggleItemActivationByIndex(index);
    };

    const toggleConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
    };

    const onConfirmDelete = () => {
        props.setFieldValue(isDeletedField, !isDeleted);
        props.setFieldValue(isModifiedField, true);
        toggleConfirmDeleteModal();
    };

    const handleCopyNlpEntity = (event) => {
        event.stopPropagation();
        props.handleCopyNlpEntity(props.formIndex);
    };

    const renderNlpEntityName = () => {
        return (
            <div className={itemBody} key={props.formIndex}>
                <Button
                    themes={["white-small"]}
                    text={nlpEntityName}
                    type="button" />
                {props.nlpEntity.dataSensitivity === "Sensitive" &&
                    <TooltipWrapper place="right" text="Entity not tracked in analytics and will be encrypted for webhooks"><SensitivityIcon src={sensitiveEntity} /></TooltipWrapper>
                }
                {props.nlpEntity.dataSensitivity === "NoTracking" &&
                    <TooltipWrapper place="right" text="Entity information will not be tracked in this application's analytics"><SensitivityIcon src={noTrackingEntity} /></TooltipWrapper>
                }
            </div>
        )
    };

    const renderItemActions = () => {
        return (
            <div className={itemActions} >
                {hasErrors &&
                    <ErrorLabel image={alertIcon} text={"Errors Present"} />
                }
                {hasUnsavedChanges &&
                    <Button themes={['icon', 'end-tight']}
                        icon={isDeleted ? redNoCircle : (isModified ? yellowUpdate : addIcon)} />
                }
                {!isDeleted && isCopyable &&
                    <Button themes={['icon', 'end-tight']}
                        icon={duplicateIcon}
                        type="button"
                        onClick={(event) => handleCopyNlpEntity(event)} />
                }
                {!isDeleted &&
                    <Button themes={['icon', 'end-tight']}
                        icon={deleteIcon}
                        type="button"
                        onClick={toggleConfirmDeleteModal} />
                }
            </div>
        )
    };

    const renderNlpEntityDisplayItem = () => {
        return (
            <>
                <div className={`${itemContainer} ${isActive ? 'active' : ''}`}
                    key={`menuItem-${props.formIndex}`}
                    onClick={() => handleItemToggleActivation(props.formIndex)} >
                    {renderNlpEntityName()}
                    {renderItemActions()}
                </div>
                {isConfirmDeleteModalOpen &&
                    <ConfirmationDialog title={`Confirm Delete`}
                        deleteText="Yes, Delete"
                        isLoading={false}
                        onClose={toggleConfirmDeleteModal}
                        onConfirm={onConfirmDelete}>
                        <WarningConfirmation
                            text={`Are you sure you want to delete the entity "${nlpEntityName}"? 
                        All slots that are using this entity will become invalid.`} />
                    </ConfirmationDialog>
                }
            </>
        )
    };

    return (
        renderNlpEntityDisplayItem()
    );

};

export default ApplicationNlpEntityItem;

const itemContainer = css`

    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    
    cursor: pointer;

    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
    }
    
`;

const itemBody = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${color_text_default};
    font-size: 18px;
    position:relative;
`;

const itemActions = css`
    display: flex;
`;

const SensitivityIcon = styled.img`

`;

