import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import ModalHeader from '../../../components/general/ModalHeader';
import PageError from '../../../components/general/PageError';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import FooterBar from '../../../components/structure/FooterBar';
import SmallModalLayout from '../../../components/structure/SmallModalLayout';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';

interface SaveBackupProps {
    backupsContainer: ApplicationBackupVersionsContainer
    environmentsContainer: ApplicationEnvironmentsContainer
    applicationId: string
    publishId: string
    onClose: () => void
}
const PublishDetailsModal: React.FC<SaveBackupProps> = (props) => (
    <SmallModalLayout isVisible onClose={props.onClose} className={customModalInner}>
        <ModalBody>
            <ModalHeader title="Publish Details" onClose={props.onClose} />
            <div className="modal-body">
                <p>Review Publishing Details</p>

            </div>
            <FooterBar>
                <Button type="button" themes={['primary', 'start']} text="Close" onClick={() => props.onClose()} />
            </FooterBar>
        </ModalBody>
        <PageError errors={props.backupsContainer.state.errors ?? []} onClear={() => props.backupsContainer.clearErrors()} />
    </SmallModalLayout>
)


const customModalInner = css`
    height: 416px;
    min-height: 416px;
`
const ModalBody = styled.div`
    min-height: 416px;
    height: 416px;
    padding-bottom:64px;
    .modal-body{
        padding: 0 32px;
        margin-bottom: 180px;
        height: 100%;
        flex: 1;
        >h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        >p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin: 24px 0;
        }
    }
`

export default PublishDetailsModal;