import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { FormikProps, withFormik } from "formik";
import * as yup from 'yup';
import ApplicationContainer from '../../../../shared/state/containers/ApplicationContainer';
import HorizontalContainer from '../../../../shared/components/structure/HorizontalContainer';
import FormFieldsContainer from '../../../../shared/components/structure/FormFieldsContainer';
import TextField from '../../../../shared/components/forms/TextField';
import PageContainer from '../../../../shared/components/structure/PageContainer';
import ImageUploadContainer from '../../../../shared/components/structure/ImageUploadContainer';
import AvatarFileUploadField from '../../../../shared/components/forms/AvatarFileUploadField';
import Button from '../../../../shared/components/general/Button';
import HorizontalSeparator from '../../../../shared/components/structure/HorizontalSeparator';
import PageCard from '../../../../shared/components/structure/PageCard';
import { color_variants_main_bg, color_shades_darker } from '../../../../shared/constants/colors';
import CustomScrollbars from '../../../../shared/components/structure/CustomScrollbars';
import LanguageSingleSelector from '../../../../shared/scenes/applicationSettings/components/languages/LanguageSingleSelector';
import LanguageContainer from '../../../hooks/LanguageContainer';
import CreateApplicationWithTemplatesModel from '../../../models/applications/CreateApplicationWithTemplatesModel';

interface CreateApplicationFormProps {
    organizationId: string
    appContainer: ApplicationContainer
    onCreate?: (applicationId: string) => void
    onCancel?: () => void
}

interface CreateApplicationFormData {
    name?: string
    invocationName?: string
    imageFile: File
    defaultLanguageId: string
}

const InnerForm: React.FC<FormikProps<CreateApplicationFormData> & CreateApplicationFormProps> = (props) => {
    const [imageUrl, setImageUrl] = useState("");

    const languagesContainer = LanguageContainer.useContainer();

    useEffect(() => {
        const getLanguages = async () => {
            await languagesContainer.loadLanguages();
        }
        if (languagesContainer.languages.length == 0) {
            getLanguages();
        }
        const enUs = languagesContainer.languages.find(l => l.shortCode == "en-US");
        props.setFieldValue("defaultLanguageId", enUs?.id);
    }, [])

    const handleImageSelected = (file: File) => {
        readDataUrl(file);
        props.setFieldValue("imageFile", file);
    };

    const handleLanguageChange = (selectedLanguageIds: string[]) => {
        props.setFieldValue("defaultLanguageId", selectedLanguageIds[0]);
    };

    const readDataUrl = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageUrl(reader.result.toString())
        }
        reader.readAsDataURL(file);
    };

    const isLoading = props.appContainer.state.isCreatingApplication || props.appContainer.state.isLoadingApps;

    return (
        <form onSubmit={props.handleSubmit} className={formStyle}>
            <HorizontalContainer className={whiteBackgroundStyle}>
                <Button className={rightSide} themes={['secondary-small']} text="Cancel" onClick={props.onCancel.bind(this)} loading={props.appContainer.state.isCreatingApplication} disabled={props.appContainer.state.isCreatingApplication} />
                <Button themes={['primary-small']} className="ac-create-app-finish" text="Create App" type="submit" loading={props.appContainer.state.isCreatingApplication} disabled={props.appContainer.state.isCreatingApplication} />
            </HorizontalContainer>
            <HorizontalSeparator />
            <PageContainer>
                <CustomScrollbars className={scrollBarsStyle}>
                    <div className={formSpacingStyle}>
                        <h3>App Info</h3>
                        <p>Here you can configure the public-facing aspects of your application.</p>
                        <PageCard className={formCardStyle}>
                            <h4>App Basics</h4>
                            <HorizontalSeparator />
                            <FormFieldsContainer className={formFieldsContainerStyle}>
                                <LanguageSingleSelector
                                    label="Language / Region"
                                    className={`${languageSelectStyle} ac-create-app-lang`}
                                    languages={languagesContainer.languages}
                                    selectedLanguageIds={[props.values.defaultLanguageId]}
                                    onChange={handleLanguageChange.bind(this)} />
                                <TextField name="name"
                                    className="ac-create-app-name"
                                    disabled={isLoading}
                                    required
                                    value={props.values.name}
                                    label="App Name"
                                    placeholder="Give your application a name"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur} />
                                <TextField name="invocationName"
                                    className="ac-create-app-invocation"
                                    disabled={isLoading}
                                    tooltip="Invocation Name is the phrase people will use to call up your application on their device"
                                    required
                                    value={props.values.invocationName}
                                    label="Invocation Name"
                                    placeholder="The name used to start your app"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur} />
                            </FormFieldsContainer>
                        </PageCard>
                        <PageCard className={`${formCardStyle} ${imageUploadStyle}`}>
                            <h4>App Avatar</h4>
                            <HorizontalSeparator />
                            <ImageUploadContainer className="previewContainer ac-create-app-image">
                                <AvatarFileUploadField accept=".png,.jpg,.jpeg" onChange={handleImageSelected.bind(this)} />
                            </ImageUploadContainer>
                            <h3>This image will represent your app in Voicify</h3>
                            <p>Upload an image in .jpg or .png format</p>
                        </PageCard>
                        <div className={createAppButtonContainerStyle}>
                            <Button text="Create App" themes={["primary"]} type="submit" loading={props.appContainer.state.isCreatingApplication} disabled={props.appContainer.state.isCreatingApplication} />
                        </div>
                    </div>
                </CustomScrollbars>
            </PageContainer>
        </form>
    )
}

const CreateApplicationForm = withFormik<CreateApplicationFormProps, CreateApplicationFormData>({
    mapPropsToValues: () => ({
        name: '',
        invocationName: '',
        defaultLanguageId: '',
        imageFile: null
    }),
    validationSchema: yup.object().shape({
        name: yup.string().max(50).required("You must enter a name for your application"),
        invocationName: yup.string().required("You must enter an invocation name for your application").matches(/^([^0-9\!\&\*\(\)\{\}\%\$\#\@\!\?\;\:\-\_\+\=\/\<\>\~]*)$/, "Invocation name cannot contain digits or special characters"),
    }),
    handleSubmit: async (values, { props }) => {
        const appInfo: CreateApplicationWithTemplatesModel = {
            name: values.name,
            invocationPhrase: values.invocationName,
            imageFile: values.imageFile,
            templates: [],
            defaultLanguageId: values.defaultLanguageId,
            withSamples: false
        };
        
        const result = await props.appContainer.createApplication(props.organizationId, appInfo);
        if (result.resultType == "Ok") {
            props.onCreate(result.data.id);
        }
    }
})(InnerForm);

const rightSide = css`
    margin-left: auto;
`;

const createAppButtonContainerStyle = css`
    text-align: center;   
    margin-top: 24px;
`;

const formSpacingStyle = css`
    width: 50vw;
    padding-bottom: 120px;
    margin-left: auto;
    margin-right: auto;
`;

const imageUploadStyle = css`
    h3 {
        margin-bottom: 0px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
    p {
        margin-bottom: 0px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: ${color_shades_darker};
    }
    .previewContainer {
        margin-left: auto;
        margin-right: auto;
    }
`;

const languageSelectStyle = css`
    position: relative;
    margin-bottom: 16px;
    .field .selection {
        height: 60px;
        border-radius: 5px; 
        padding-left: 24px;
    }
    .field-wrapper {
        label {
            position: absolute;
            left: 14px;
            top: -27px;
            font-family: Muli;
            font-size: 14px;
            font-weight: 600;
        }
    }
`;

const formCardStyle = css`
    width: 50vw;
    padding: 32px;
    margin-top: 24px;
`;

const formStyle = css`
    background: ${color_variants_main_bg};
    height: calc(100% - 120px);
`

const scrollBarsStyle = css`
    height: calc(100% - 152px);
`;

const formFieldsContainerStyle = css`
    margin-top: 56px;
`;

const whiteBackgroundStyle = css`
    background: white;
`;

export default CreateApplicationForm;
