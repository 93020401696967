import React from 'react';
import {css} from '@emotion/css';

// layout component used for creating split views (aka 50%/50%)

const layoutStyle = css`
    margin: 32px 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    >div {
        width: 45%;
        flex: 1;
    }
    > * + * {
        margin-left: 50px;
    }
`

const SplitContainer: React.SFC = (props) => (
<div className={layoutStyle}>
        {props.children}
    </div>
);

export default SplitContainer;