import React, { Ref, useState, useEffect } from 'react';
import { css } from '@emotion/css';
import LanguageModel from '../../../models/features/api/LanguageModel';
import { color_shades_dark, color_text_light, color_shades_lighter, color_shades_light, color_text_default } from '../../../constants/colors';
import Button from '../../../components/general/Button';
import { style_border_default } from '../../../constants/stylesValues';

const welcomeIconBlack = require('../../../content/images/welcome-black.svg');
const sendIcon = require("../../../content/images/simulator/send.svg");
const micIcon = require('../../../content/images/simulator/microphone.svg');

interface MessageBarProps {
    onSubmit: (text: string, language: LanguageModel) => void
    simulatorMessageBarInputRef: Ref<HTMLInputElement>
    selectedLanguage?: LanguageModel,
    isDisabled?: boolean
    showSendWelcomeButton?: boolean
    handleSendWelcome: () => void
};

const SimulatorMessageBar: React.FC<MessageBarProps> = (props) => {
    const [text, setText] = useState('');
    const [isMicOpen, setIsMicOpen] = useState(false);
    let recognition = null;
    
    if (window['SpeechRecognition'] || window["webkitSpeechRecognition"]) {
        const SpeechRecognition = window['SpeechRecognition'] || window["webkitSpeechRecognition"];
        recognition = new SpeechRecognition();
        recognition.lang = props.selectedLanguage?.shortCode ?? "en-US";
        // This runs when the speech recognition service starts
        recognition.onstart = function () {
            // start recognition
            setIsMicOpen(true);
        };
        recognition.onerror = () => {
            setIsMicOpen(false);
        };
        recognition.onspeechend = function () {
            // when user is done speaking
            recognition.stop();
            setIsMicOpen(false);
        };
        // This runs when the speech recognition service returns result
        recognition.onresult = function (event) {
            const transcript = event.results[0][0].transcript;
            setText(transcript);
            if (transcript?.length > 0) {
                submit();
            }
        };
    }

    useEffect(() => {
        if (recognition && props.selectedLanguage?.shortCode != props.selectedLanguage?.shortCode) {
            recognition.lang = props.selectedLanguage?.shortCode ?? "en-US";
        };
    }, [props.selectedLanguage]);

    const handleStartListening = () => {
        recognition.start();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submit();
    };

    const submit = () => {
        if (text) {
            props.onSubmit(text, props.selectedLanguage);
            setText('');
        }
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    return (
        <div className={containerStyle}>
            {props.showSendWelcomeButton &&
                <Button
                    className={welcomeButton}
                    icon={welcomeIconBlack}
                    themes={["white-pill"]}
                    disabled={!props.showSendWelcomeButton}
                    text="Welcome"
                    onClick={() => props.handleSendWelcome()} />
            }
            <form onSubmit={handleSubmit}>
                <div className={`message-bar-input ${props.isDisabled ? "disabled" : ""} ac-simulator-message-bar`}>
                    {recognition && <button type="button" className="mic-button" onClick={handleStartListening}>
                        <img src={micIcon} />
                        <p>{isMicOpen ? "Listening" : "Click to Speak"}</p>
                    </button>}
                    <input ref={props.simulatorMessageBarInputRef} disabled={props.isDisabled} name="text" placeholder="Type Something Here" type="text" value={text} onChange={handleTextChange} />
                    <button className="submit-button" type="submit"><img src={sendIcon} /></button>
                </div>
            </form>
        </div>
    );
};

const welcomeButton = css`
    position: absolute;
    top: -56px;
    left: 0px;
`;

const containerStyle = css`
    background: ${color_shades_lighter};
    height: 72px;
    padding: 15px;
    border-top: 0;
    position: relative;
    margin-bottom: 16px;

    form {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .message-bar-input {
        flex: 1;
        height: 72px;
        border-radius: 12px;
        border: 1px solid ${color_shades_dark};
        background: white;
        font-family: Muli;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        padding-left: 16px;
        box-shadow: 0px 4px 10px rgba(50, 70, 97, 0.1);
        display: flex;
        justify-content: space-between;
        align-items:center;
        input {
            width: 100%;
            background: transparent;
            border: none;
            font-size: 16px;
            color: ${color_text_default};
            ::placeholder {
                font-weight: normal;
                font-style: italic;
                font-size: 16px;
                letter-spacing: normal;
                color: ${color_text_light};
            }
        }

        .mic-button {
            padding: 0;
            margin: 0;
            background: none;
            border: none;
            border-right: ${style_border_default};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 104px;
            height: 100%;
            overflow: hidden;
            padding-right: 16px;
            margin-right: 16px;
            cursor: pointer;
            p {
                font-size: 10px;
                line-height: 16px;
                color: ${color_text_default};
            }
        }

        &.disabled {
            background: ${color_shades_light};
            color: ${color_text_light};
        }
    }

    .submit-button {
        border: none;
        background-color: transparent;
        background-repeat: no-repeat;
        margin-right: 16px;
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
    }
`;

export default SimulatorMessageBar;