import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import TextAreaField from "../../../components/forms/TextAreaField";
import TextField from "../../../components/forms/TextField";
import Button from "../../../components/general/Button";
import ModalHeader from "../../../components/general/ModalHeader";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import FooterBar from "../../../components/structure/FooterBar";
import ModalLayout from "../../../components/structure/ModalLayout";
import { color_text_default, text_blue } from "../../../constants/colors";
import useWindowSize from "../../../hooks/UseWindowSize";

const deleteIcon = require('../../../content/images/trash.svg');

interface EntityMetadataModalProps {
    metadata: {[key: string]: string},
    handleClose: () => void,
    handleUpdate: (metadata: {[key: string]: string}) => void

}

const EntityMetadataModal: React.FC<EntityMetadataModalProps> = (props) => {
    const [metadata, setMetadata] = useState([] as {key: string, value: string}[]);
    const windowSize = useWindowSize();
    useEffect(() => {
        let meta = [];
        for (let key in props.metadata) {
            meta.push({ key, value: convertValueToString(props.metadata[key]) });
        }
        setMetadata(meta);
    }, [])
    const mapMetadata = () => {
        let meta = {};
        for (let kv of metadata) {
            if (isJsonString(kv.value)) {
                meta[kv.key] = JSON.parse(kv.value);
            } else {
                meta[kv.key] = kv.value;
            }
        }
        return meta;   
    }
    const updateKey = (idx: number, e: any) => {
        let newMetadata = [...metadata];
        newMetadata[idx].key = e.target.value;
        setMetadata(newMetadata);
    }   
    const updateValue = (idx: number, e: any) => {
        let newMetadata = [...metadata];
        newMetadata[idx].value = e.target.value;
        setMetadata(newMetadata);
    }
    const removeMetadata = (idx: number) => {
        let newMetadata = [...metadata];
        newMetadata.splice(idx, 1);
        setMetadata(newMetadata);
    }
    const convertValueToString = (val: any): string => {
        if (typeof val === 'string')
            return val;
        else 
            return JSON.stringify(val);
    }
    const isJsonString = (str: string) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    return (
        <MetadataModal isVisible={true}>
            <ModalHeader title={"Entity Metadata"} onClose={() => props.handleClose()} />
            <MetadataHolder>
                <CustomScrollbars autoHide autoHeight autoHeightMin={(windowSize.windowSize.innerHeight * .6) - 184} autoHeightMax={(windowSize.windowSize.innerHeight * .6) - 184}>
                    <Button
                        themes={["flat"]}
                        text="+ Add Metadata"
                        type="button"
                        className={addProperty}
                        onClick={() => {
                            setMetadata([...metadata, { key: "", value: "" }]);
                        }}
                    />
                    <>
                        {
                            !!metadata.length ? metadata.map((m, idx) => {
                                return (
                                    <FieldContainer key={idx}>
                                        <TextField label={"Key"}
                                            onChange={(e) => updateKey(idx, e)}
                                            value={m.key}
                                            type="text"
                                            useForm={false}
                                            className={leftField}
                                        />
                                        <TextField
                                            label={"Value"}
                                            onChange={(e) => updateValue(idx, e)}
                                            value={convertValueToString(m.value)}
                                            type="text"
                                            useForm={false}
                                            className={rightField}
                                        />
                                        <RemoveIcon themes={['icon', 'end-tight']}
                                            icon={deleteIcon}
                                            type="button"
                                            onClick={() => removeMetadata(idx)} /> 
                                    </FieldContainer>
                                )
                            }) : null
                        }                    
                    </>
                </CustomScrollbars>
            </MetadataHolder>
            <FooterBar>
                <UpdateButton
                    type="button"
                    themes={["primary"]}
                    text="Update"
                    onClick={() => {
                        props.handleUpdate(mapMetadata());
                        props.handleClose();
                    }}
                />
                <Button
                    type="button"
                    themes={["secondary"]}
                    text="Cancel"
                    onClick={() => props.handleClose()}
                />
            </FooterBar>
        </MetadataModal>
    );

};

const MetadataHolder = styled.div`
    margin: 0px 32px 32px 32px;
`;

const MetadataModal = styled(ModalLayout)`
    color: ${color_text_default};
    width: 40%;
    height: 60%;
    min-height: 60%;
    min-width: 40%;
`;

const UpdateButton = styled(Button)`
    margin-right: auto;
`;

const addProperty = css`
    color: ${text_blue}
    margin-bottom: 16px;
    box-shadow: none;
    margin-left: 0px;
    border: 0;
    padding-left: 0px;
`;

const leftField = css`
    margin-right: 16px;
`;

const rightField = css`
    margin-left: 16px;
    margin-right: 16px;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const RemoveIcon = styled(Button)`
    margin-top: 37px;
    margin-left: 8px;
`;

export default EntityMetadataModal;
