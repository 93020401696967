import IntegrationListing from "../../models/integrations/IntegrationListing";
import { Container } from "unstated";
import * as voicifyApi from "../../api";
import Axios from "axios";
import IntegrationConfiguration from "../../models/integrations/IntegrationFormField";
import CreateIntegrationListingRequest from "../../models/integrations/api/CreateIntegrationListingRequest";
import * as _ from 'lodash'
import IntegrationSetupRequest from "../../models/integrations/setup/IntegrationSetupRequest";

type IntegrationsListingsState = {
    integrationsListings: IntegrationListing[],
    integrationConfig: IntegrationConfiguration,
    isLoading: boolean,
    isSaving: boolean,
    errors: string[]
}


const customWebhook = {
    name: "Custom Webhook",
    id: "custom",
    description: "Create a custom integration from a webhook",
    provider: "Voicify",
    internalIntegration: true,
} as IntegrationListing

const customIntegration = {
    name: "Add an Integration",
    id: "integration",
    description: "Add a custom integration listing",
    provider: "Custom",
    internalIntegration: true,
} as IntegrationListing

export default class IntegrationsListingsContainer extends Container<IntegrationsListingsState> {
    state = {
        integrationsListings: [] as IntegrationListing[],
    } as IntegrationsListingsState

    setLoading(isLoading: boolean) {
        return this.setState({
            ...this.state,
            isLoading: isLoading
        })
    }

    setSaving(isSaving: boolean) {
        return this.setState({
            ...this.state,
            isSaving: isSaving
        })
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: null
        })
    }

    getListings(organizationId: string) {
        return this.setLoading(true).then(() => {
            var promise = voicifyApi.getIntegrationsListings(organizationId)
                .then(res => {
                    if (res.resultType == "Ok") {
                        var listings = res.data;
                        var builtin = listings.filter(l => l.organizationId == null);
                        var custom = listings.filter(l => l.organizationId != null);
                        listings = builtin.concat(custom)
                        return this.setState({
                            ...this.state,
                            integrationsListings: listings,
                            isLoading: false
                        })
                    } else {
                        return this.setState({
                            ...this.state,
                            errors: res.errors,
                            isLoading: false
                        })
                    }
                })
                .catch(err => {
                    return this.setState({
                        ...this.state,
                        errors: err,
                        isLoading: false
                    })
                });
            return promise;
        });

    }

    isInternalIntegration(integration: IntegrationListing) {
        return integration.internalIntegration == true;
    }

    sendConfigurationInformation(organizationId: string, integration: IntegrationListing, parameters: { [name: string]: string }) {
        this.clearErrors();
        return this.setSaving(true).then(() => {
            const setupRequest: IntegrationSetupRequest = {
                additionalProperties: parameters,
                apiUserId: this.state.integrationConfig.apiUserId,
            }
            var promise = voicifyApi.sendIntegrationConfiguration(organizationId, integration.id, setupRequest)
                .then(res => {
                    if (res.resultType == "Ok") {
                        return this.setState({
                            ...this.state,
                            integrationConfig: {
                                additionalPropertiesConfiguration: {},
                                oAuthUrl: "",
                                setupSections: [],
                                apiUserId: "",
                            },
                            isSaving: false
                        })
                    } else {
                        return this.setState({
                            ...this.state,
                            errors: res.errors,
                            integrationConfig: {
                                additionalPropertiesConfiguration: {},
                                oAuthUrl: "",
                                setupSections: [],
                                apiUserId: "",
                            },
                            isSaving: false
                        })
                    }
                })
                .catch(err => {
                    return this.setState({
                        ...this.state,
                        isSaving: false,
                        errors: err
                    })
                })
            return promise;
        })
    }

    addCustomIntegrationListing(organizationId: string, request: CreateIntegrationListingRequest) {
        return this.setSaving(true).then(() => {
            var promise = voicifyApi.addIntegrationListing(organizationId, request)
                .then(res => {
                    if (res.resultType != "Ok") {
                        return this.setState({
                            ...this.state,
                            errors: res.errors,
                            isSaving: false
                        })
                    } else {
                        return this.setState({
                            ...this.state,
                            isSaving: false,
                            errors: null
                        })
                    }
                })
                .catch(err => {
                    return this.setState({
                        ...this.state,
                        errors: [err],
                        isSaving: false
                    })
                })
            return promise;
        });
    }

    removeIntegrationsListing(integrationsListingId: string) {
        return this.setSaving(true).then(() => {
            var promise = voicifyApi.removeIntegrationListing(integrationsListingId)
                .then(res => {
                    if (res.resultType != "Ok") {
                        return this.setState({
                            ...this.state,
                            errors: res.errors,
                            isSaving: false
                        })
                    } else {
                        _.remove(this.state.integrationsListings, i => i.id == integrationsListingId)
                        return this.setState({
                            ...this.state,
                            integrationsListings: this.state.integrationsListings,
                            isSaving: false,
                            errors: null
                        })
                    }
                })
                .catch(err => {
                    return this.setState({
                        ...this.state,
                        isSaving: false,
                        errors: [err]
                    })
                })
            return promise;
        });
    }

    setupExternalIntegration(organizationId: string, integration: IntegrationListing) {
        this.clearErrors();
        return this.setSaving(true).then(() => {
            var promise = voicifyApi.getIntegrationSetup(organizationId, integration.id)
                .then(res => {
                    if (res.resultType != "Ok") {
                        this.setState({
                            ...this.state,
                            integrationConfig: null,
                            isSaving: false,
                            errors: res.errors
                        })
                    }
                    else {
                        this.setState({
                            ...this.state,
                            integrationConfig: res.data,
                            isSaving: false
                        })
                    }
                    return res;
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        isSaving: false,
                        errors: ["There was an issue contacting the remote integration, please try again later"]
                    })
                    return err;
                })

            return promise;
        });
    }
}