import { css } from '@emotion/css';
import React from 'react';
import CheckIndicator from '../forms/CheckIndicator';
import Button from './Button';
import { color_defaults_white, color_shades_dark, color_text_default, color_text_light, color_text_link, silver_four } from '../../constants/colors';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import { GroupSelectionType } from '../../scenes/featureContent/components/FeatureToolbar';
import TooltipWrapper from './TooltipWrapper';
import ConditionalTooltipWrapper from './ConditionalTooltipWrapper';
const moveIcon = require("../../content/images/content-explorer/move.svg");
const copyIcon = require("../../content/images/content-explorer/copy.svg");
const deleteIcon = require("../../content/images/content-explorer/delete.svg");
const exportIcon = require("../../content/images/content-explorer/export.svg");
const publishIcon = require("../../content/images/app-history-icons/full-publish.svg");

interface SelectionOptionsToolbarProps {
    application: ApplicationModel
    applicationFeatureId: string
    selectedFeaturesIds: string[]
    selectedContentIds: string[]
    onUnselectAll: () => void
    onSelectAll: () => void
    icon: string
    groupSelectionState: GroupSelectionType
    onExport: () => void
    onMove: () => void
    onCopy: () => void
    onDelete: () => void
    onClear: () => void
    onPublish: () => void
    partialPublishDisabled?: boolean
    isMoving: boolean
    isCopying: boolean
    isDeleting: boolean
    isExporting: boolean
    isPublishing: boolean
    history: any
    isSelectingWebhooks?: boolean
    isAppAdmin?: boolean
}

class SelectionOptionsToolbar extends React.Component<SelectionOptionsToolbarProps> {
    constructor(props) {
        super(props)
    }
    getSelectionLabel() {
        var label = "";
        if (this.props.selectedFeaturesIds.length > 0) {
            label += `${this.props.selectedFeaturesIds.length} Folder${this.props.selectedFeaturesIds.length > 1 ? 's' : ''}`
        }
        if (this.props.selectedContentIds.length > 0) {
            if (this.props.selectedFeaturesIds.length > 0)
                label += ', ';
            label += `${this.props.selectedContentIds.length} ${this.props.isSelectingWebhooks == true ? "Webhook" : "Conversation"}${this.props.selectedContentIds.length > 1 ? 's' : ''}`;
        }

        return label;
    }
    render() {
        return (
            <div className={containerStyle}>
                <div className="tool-bar-container">
                    <div className="group-select-container">
                        <CheckIndicator onClick={() => this.props.groupSelectionState == 'all' ? this.props.onUnselectAll() : this.props.onSelectAll()} partial={this.props.groupSelectionState == 'partial'} checked={this.props.groupSelectionState == 'all'} />
                        <img src={this.props.icon} />
                    </div>
                    <div className="selection-details-container">
                        <div className="selection-header">
                            <span>Selected - </span>
                            <button type="button" onClick={this.props.onClear}>Unselect All</button>
                        </div>
                        <div className="selection-description">
                            <p>{this.getSelectionLabel()}</p>
                        </div>
                    </div>
                    <div className="selection-operations-container">
                        {this.props.onPublish != null && 
                            <ConditionalTooltipWrapper 
                                showTooltip={!this.props.isAppAdmin || this.props.partialPublishDisabled} 
                                place="bottom"
                                text={!this.props.isAppAdmin ? 
                                    'Only App Admins can publish content' 
                                    : 
                                    'Please run a full publish of your <br /> app before starting any partial <br /> publishes to avoid errors'}>  
                                <div> 
                                    <Button 
                                        disabled={this.props.partialPublishDisabled || !this.props.isAppAdmin} 
                                        themes={['secondary-small']} 
                                        className="ac-publish-all" 
                                        text="Publish" 
                                        onClick={this.props.onPublish} 
                                        type="button" 
                                        rightIcon={publishIcon}/>
                                </div>
                            </ConditionalTooltipWrapper>
                        }<Button disabled={this.props.isExporting} loading={this.props.isExporting} themes={['white-small']} text="Export" onClick={this.props.onExport} icon={exportIcon} />
                        <Button disabled={this.props.isMoving} loading={this.props.isMoving} themes={['white-small']} text="Move to..." onClick={this.props.onMove} icon={moveIcon} />
                        <Button disabled={this.props.isCopying} loading={this.props.isCopying} themes={['white-small']} text="Copy to..." onClick={this.props.onCopy} icon={copyIcon} />
                        <Button disabled={this.props.isDeleting} loading={this.props.isDeleting} themes={['white-small']} text="Delete" onClick={this.props.onDelete} icon={deleteIcon} />
                    </div>
                </div>
            </div>
        )
    }
}

const containerStyle = css`    
    background: ${color_defaults_white};
    .tool-bar-container {
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 56px;
        border-bottom: 1px solid ${color_shades_dark};
        
        .group-select-container {
            display: flex;
            align-items: center;
            padding: 0 12px 0 8px;
            margin-right: 12px;
            border-right: 1px solid ${color_shades_dark};
        }
        
        .selection-details-container {
            .selection-header {
                display: flex;
                align-items: center;                
                font-size: 12px;
                line-height: 16px;

                span {
                    color: ${color_text_light};
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                }
                button {
                    color: ${color_text_link};
                    border: none;
                    background: none;
                    cursor: pointer;
                }
            }
            .selection-description {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: ${color_text_default};
            }
        }
    
        .selection-operations-container {
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-left: auto;
        }
    }
    
  
`;

export default SelectionOptionsToolbar;

