import React, { useEffect, useState } from 'react';
import BackButton from '../../components/general/BackButton';
import { TabPanel } from 'react-tabs';
import PageContainer from '../../components/structure/PageContainer';
import HeaderTitleView from '../../components/structure/HeaderTitleView';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import ContentAnalytics from '../contentAnalytics';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import StatusSwitch from '../../components/general/StatusSwitch';
import HeaderStatusContainer from '../../components/general/HeaderStatusContainer';
import NavigationBarFormPage from './NavigationBarFormPage';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import Loader from '../../components/general/Loader';
import IGenericContentContainer from '../../state/definitions/IGenericContentContainer';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import IContentWebhookContainer from '../../state/definitions/IContentWebhookContainer';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import IResult from '../../models/result/IResult';
import HeaderSeparator from '../../components/structure/Panels/HeaderSeparator';
import FullPageTabbedPanel from '../../components/structure/Panels/FullPageTabbedPanel';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import IncompleteIndicatorInverse from '../../components/general/IncompleteIndicatorInverse';
import ApplicationContentMenuSelector from '../applicationContentMenuSelector';
import { BreadcrumbItem } from '../../components/general/Breadcrumb';
import FeatureContainer from '../../state/containers/FeatureContainer';
import PageError from '../../components/general/PageError';
import SyncedIndicatorInverse from '../../components/general/SyncedIndicatorInverse';
import { getEditUrl } from '../../models/extensions';
import { Subscribe } from 'unstated';
import ApplicationAnalyticsFacetContainer from '../../state/containers/ApplicationAnalyticsFacetContainer';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationEnvironmentModel from '../../models/applications/api/environments/ApplicationEnvironmentModel';
import PartialPublishForm from './PartialPublishForm';
import ContentItemListStatus from '../../components/general/ContentItemListStatus';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import AssistantApplicationModel from '../../models/applications/api/AssistantApplicationModel';
import { getContentItemAccessType } from '../../models/extensions/contentItems';
import ConversationAccessType from '../../models/features/ConversationAccessType';
const contentEditIcon = require('../../content/images/tab-icons/content-edit.svg');
const analyticsIcon = require('../../content/images/tab-icons/analytics.svg');
const noAccessImage = require('../../content/images/conversation-access/no-access-inverted.svg');
const targetedAccessImage = require('../../content/images/conversation-access/targeted-access-inverted.svg');

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface GenericEditContentItemProps<TContentItem extends ContentItemModel> extends RouteComponentProps<ApplicationRouteProps> {
    contentItemId: string
    stateContainer: IGenericContentContainer<TContentItem, any, any, any> & IContentWebhookContainer
    environmentsContainer: ApplicationEnvironmentsContainer
    backupsContainer: ApplicationBackupVersionsContainer
    titleContainer: TitleStateContainer
    applicationContainer: ApplicationContainer
    featureContainer: FeatureContainer
    formRender: (contentItem: TContentItem,
        application: ApplicationModel,
        isTemplateOnwed: boolean,
        assistantApplication: AssistantApplicationModel,
        onShowPublishModal: () => void,
        onSubmit: (model: TContentItem, onSuccess) => Promise<IResult<TContentItem>>,
        toggleSync: (id: string, shouldNonSuccessotSync: boolean) => Promise<IResult<TContentItem>>,
        onAccessTypeChange: (accessType: ConversationAccessType) => void) => JSX.Element
    defaultTitle: string
    featureTypeId: string
}

const analyticsTabName = "analytics";
const integrationsTabName = "integrations";

const GenericEditContentItemPage = <TContentItem extends ContentItemModel>(props: GenericEditContentItemProps<TContentItem>) => {
    const [contentItem, setContentItem] = useState(null as TContentItem);
    const [environment, setEnvironment] = useState(null as ApplicationEnvironmentModel)
    const [lastPublish, setLastPublish] = useState(null as GenericContentItemPublishModel)
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [isUnPublishing, setIsUnPublishing] = useState(false);
    const [accessType, setAccessType] = useState(null as ConversationAccessType);

    useEffect(() => {
        const asyncLoad = async () => {
            props.stateContainer.getContentWebhooks(props.contentItemId);
            const contentResult = await props.stateContainer.findFullById(props.contentItemId);
            props.applicationContainer.state.currentApp ?? await props.applicationContainer.loadCurrentAppById(contentResult.data.applicationId);
            await props.environmentsContainer.loadEnvironments(props.applicationContainer.state.currentApp.id);
            const primaryEnvironment = props.environmentsContainer.findPrimaryEnvironment(props.applicationContainer.state.currentApp.id)
            const publishHistoryResult = await props.stateContainer.loadPublishHistory(props.contentItemId, primaryEnvironment.id, 0, 1);
            setContentItem(contentResult.data);
            setEnvironment(primaryEnvironment);
            setLastPublish(publishHistoryResult.data[0]);
            setAccessType(getContentItemAccessType(contentResult.data));
            props?.location?.search?.includes("publishing=true") && handleShowPublishModal();
        }
        asyncLoad();
    }, [props.contentItemId]);

    const handleSubmit = async (model: TContentItem, onSuccess?: (result: IResult<TContentItem>) => void) => {
        const result = await props.stateContainer.updateFromModel(model);
        if (result.resultType == "Ok") {
            if (onSuccess) onSuccess(result);
            // Full update was successful
            if (props.stateContainer.state.hasWebhookUpdate) {
                await props.stateContainer.bulkUpdateContentItemWebhookInstances(result.data.id, props.stateContainer.state.webhookUpdates);
            }
            // Update edited content state, do not redirect
            props.featureContainer?.addOrUpdateContentItem(result.data, result.data.responses[0], props.stateContainer.featureTypeId);
            setContentItem(result.data);
        }
        return result;
    }

    const handleShowPublishModal = async () => {
        setShowPublishModal(true);
    }

    const getBreadCrumbItems = () => {
        var ancestors = props.featureContainer.getAllAncestors(props.match.params.applicationFeatureId);

        var crumbs: BreadcrumbItem[] = ancestors?.reverse()?.map(af => ({
            id: af?.id,
            title: af.parentId ? (af?.name ?? af.feature?.name ?? af?.feature?.featureType?.name ?? 'Untitled') : 'Home',
            link: `/v/apps/${props.applicationContainer.state.currentApp?.id}/content/${af?.id}`
        }))

        return crumbs;
    }
    const handleClose = () => {
        props.history.push(`/v/apps/${props.applicationContainer.state.currentApp.id}/content/${props.match.params.applicationFeatureId}`);
    }
    const handleToggleSync = (id: string, shouldNotSync: boolean) => {
        const promise = props.stateContainer.toggleSync(id, shouldNotSync);
        promise.then(result => {
            if (result.resultType == "Ok") {
                props.stateContainer.findFullById(props.contentItemId).then(result => {
                    setContentItem(result?.data);
                });
            }
        });

        return promise;
    }

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = getEditUrl(props.contentItemId, props.match.params.applicationFeatureId, props.featureTypeId);

        if (index == 1) {
            props.history.push(urlStart + analyticsTabName)
        }
        else if (index == 2) {
            props.history.push(urlStart + integrationsTabName)
        }
        else {
            props.history.push(urlStart)
        }
    }
    const getSelectedIndex = () => {
        if (location.href.indexOf(analyticsTabName) > -1) {
            return 1;
        }
        if (location.href.indexOf(integrationsTabName) > -1) {
            return 2;
        }

        return 0;
    }
    const handlePartialPublishClose = async () => {
        setIsUnPublishing(false);
        setShowPublishModal(false);
        const publishHistoryResult = await props.stateContainer.loadPublishHistory(props.contentItemId, environment.id, 0, 1);
        setLastPublish(publishHistoryResult.data[0]);
    }

    if (contentItem == null || props.applicationContainer.state.currentApp == null) {
        return <Loader />
    }

    const isLoading = props.stateContainer.state.isLoading
        || props.applicationContainer.state.isLoadingCurrentApp;

    const isTemplateOwned = contentItem?.createdFromId && !contentItem?.shouldNotSync;

    const renderStatus = (contentItem) => {
        return <ContentItemListStatus
            dropDownDisabled={props.applicationContainer.state.assistantApplication == null}
            isListView={false}
            loaded={true}
            contentItem={contentItem}
            lastPublish={lastPublish}
            handlePublish={() => {
                // TODO: need to submit the form here? This may be a UX challenge as well
                // when you are on analytics page, you can still say "save and publish"
                // because status is higher than the tab, but there is no form to reference for the "save"
                // unless you are on the edit page. We need to consult with Jason here as well
                setShowPublishModal(true);
            }}
            handleUnpublish={() => {
                setIsUnPublishing(true);
                setShowPublishModal(true);
            }} />
    }
    return (
        <>
            <FullPageTabbedPanel tabs={[{
                title: "Content Edit",
                icon: contentEditIcon,
                selectedIcon: contentEditIcon,
                className: "ac-content-edit-tab"
            }, {
                title: "Analytics",
                icon: analyticsIcon,
                selectedIcon: analyticsIcon,
                className: "ac-content-analytics-tab"
            }]} onTabSelected={handleTabSelection.bind(this)} selectedIndex={getSelectedIndex()}
                leftRender={() => <>
                    <BackButton onClick={handleClose.bind(this)} />
                    <ApplicationContentMenuSelector application={props.applicationContainer.state.currentApp} featureTypeId={props.featureTypeId} history={props.history} />
                </>}
                titleRender={() => <HeaderTitleView
                    titleImage={(contentItem.isLive && contentItem.requiresParent) ? targetedAccessImage : !contentItem?.isLive ? noAccessImage : null} 
                    text={contentItem?.title ?? props.defaultTitle}
                    breadcrumb={getBreadCrumbItems()}
                    app={props.titleContainer.state.application} />}
                rightRender={() => !isLoading ? <HeaderStatusContainer>
                    {renderStatus(contentItem)}
                </HeaderStatusContainer> : null}>
                <TabPanel>
                    {!isLoading
                        ? props.formRender(
                            contentItem,
                            props.applicationContainer.state.currentApp,
                            isTemplateOwned,
                            props.applicationContainer.state.assistantApplication,
                            handleShowPublishModal,
                            handleSubmit,
                            handleToggleSync,
                            setAccessType) : <Loader />}

                </TabPanel>
                <TabPanel>
                    {!isLoading ?
                        <Subscribe to={[ApplicationAnalyticsFacetContainer]}>
                            {(facetContainer: ApplicationAnalyticsFacetContainer) => (
                                <ContentAnalytics contentItem={contentItem} facetContainer={facetContainer} history={props.history} applicationId={contentItem.applicationId} contentId={contentItem.id} featureTypeId={props.featureTypeId} applicationFeatureId={props.match.params.applicationFeatureId} />
                            )}
                        </Subscribe>
                        : <Loader />}
                </TabPanel>
            </FullPageTabbedPanel>
            <PageError errors={[...props.stateContainer?.state?.errors ?? [],
            ...props.environmentsContainer?.state?.errors ?? [],
            ...props.applicationContainer?.state?.errors ?? [],
            ...props.backupsContainer?.state?.errors ?? [],
            ...props.featureContainer?.state?.errors ?? []]}
                onClear={() => {
                    props.stateContainer.clearErrors();
                    props.environmentsContainer.clearErrors();
                    props.applicationContainer.clearErrors();
                    props.backupsContainer.clearErrors();
                    props.featureContainer.clearErrors();
                }} />
            {showPublishModal && <PartialPublishForm
                contentItems={[{ contentId: props.contentItemId, featureTypeId: props.featureTypeId }]}
                defaultContentItemName={contentItem.title}
                applicationId={contentItem.applicationId}
                isUnpublishing={isUnPublishing}
                applicationFeatureId={props.match.params.applicationFeatureId}
                history={props.history}
                showClosedAccessWarning={accessType === "closed"}
                onClose={handlePartialPublishClose.bind(this)}
            />}
        </>
    );
}

export default GenericEditContentItemPage;