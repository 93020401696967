import React from 'react';
import { css } from '@emotion/css';
import { cool_grey, pale_grey, silver_three } from '../../../constants/colors';
const endIcon = require('../../../content/images/finish.svg');
const followUpIcon = require('../../../content/images/followup.svg');
interface ConversationFinishProps {
    icon?: string
    label?: string
}


const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 32px 0;

    span {
        font-family: Muli;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${cool_grey};
    }
`
const containerStyle = css`
    display: flex;
    align-items: center;

    .icon-container {
        width: 50px;
        height: 50px;
        box-shadow: 0 2px 4px 0 rgba(48, 69, 98, 0.1);
        background-color: #ffffff;
        margin: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid ${silver_three};
    }

    .line {
        background: ${cool_grey};
        width: 50px;
        height: 1px;
    }
`;
const ConversationFinish: React.SFC<ConversationFinishProps> = (props) => (
    <div className={wrapperStyle}>
        <div className={containerStyle}>
            <div className="line"></div>
            <div className="icon-container">
                <img src={props.icon} />
            </div>
            <div className="line"></div>
        </div>
        <span>{props.label}</span>
    </div>
)

export const SessionEndConversationFinish = () => (
    <ConversationFinish icon={endIcon} label="Session Ends" />
)

export const FollowUpConversationFinish = () => (
    <ConversationFinish icon={followUpIcon} label="Awaits further user input" />
)

export default ConversationFinish