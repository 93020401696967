import React from 'react';
import styled from '@emotion/styled';
import { style_border_default } from '../../../constants/stylesValues';

const StaticMenuPanel = styled.div(`
    width: 288px;
    max-width: 288px;
    border-right: ${style_border_default};
    background: white;
`);

export default StaticMenuPanel