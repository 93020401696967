import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import GoogleMobileScreenWrapper from './GoogleMobileScreenWrapper';

const screenStyle = css`
    .list-item { 
        border-bottom: 1px solid gray;
        display: flex;
        align-items: center;
        height: 300px;
        .foreground-image {
            height: 260px;
            width: auto;
            margin-right: 0;
            margin-left: auto;
            img {
                height: 100%;
                width: auto;
            }
        }
    }
`
class GoogleMobileListPreview extends ScreenPreviewComponent {
    renderAdditionalItems() {
        const items = [];
        for (var i = 0; i < this.props.response.additionalListItems; i++) {
            items.push(i);
        }
        return items.map((_, i) => (
            <div key={i} className="list-item">
                <p className="body-text">
                    <span>{i + 2}. Additional Item</span>
                </p>
                <div className="foreground-image empty"></div>
            </div>
        ))
    }
    render() {
        return (
            <GoogleMobileScreenWrapper className={screenStyle}>
                <div className="body-container">
                    {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                    <div className="body-container">
                        <div className="list-item">
                            <p className="body-text">
                                <span>1. {this.state.displayText}</span>
                            </p>
                            {this.state.foregroundImageUrl
                                ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                                : <div className="foreground-image empty"></div>}
                        </div>
                        {this.props.response.additionalListItems ? this.renderAdditionalItems() : null}
                    </div>
                </div>
                <div className="chip-container">
                    {this.state.hints ? this.state.hints
                        .filter(h => h.content.length <= 20)
                        .map((h, i) => <span key={i} className="hint-chip">{h.content}</span>)
                        : null}
                </div>
            </GoogleMobileScreenWrapper>
        )
    }
}

export default GoogleMobileListPreview;