import { css } from '@emotion/css';
import React from "react";
import { color_shades_darkest, color_shades_lighter } from "../../constants/colors";
const webhookIcon = require('../../content/images/webhooks/webhook-dark.svg');

interface WebhookCountTagProps {
    count: number
    className?: string
    onClick: () => void
}

const WebhookCountTag: React.FC<WebhookCountTagProps> = (props) => (    
    <div onClick={props.onClick ?? null} className={`${webhookCountStyle} ${props.className ?? ''}`}>
        <img src={webhookIcon}/>
        <div>{props.count}</div>
    </div>
)

const webhookCountStyle = css`
    display: flex;
    width: 45px;
    height: 24px;
    border-radius: 16px;
    padding: 2px 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    background: ${color_shades_lighter};
    color: ${color_shades_darkest};
    cursor: pointer;
    img {
        margin: 2px 4px 0px 0px;
        height: 16px;
        width: 16px;
    }
`;
export default WebhookCountTag;