import React from 'react';
import styled from '@emotion/styled';
import { color_colors_growth, color_shades_lighter, color_text_default, color_text_light } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import IntegrationListing from '../../../models/integrations/IntegrationListing';

const defaultImage = require('../../../content/images/icon.svg');
const integrationIcon = require('../../../content/images/templates-icons/integration-icon-light.svg');
const notEnabledIcon = require('../../../content/images/templates-icons/not-enabled.svg');
const sunsetIcon = require('../../../content/images/templates-icons/sunset.svg');

interface LegacyIntegrationCardProps {
    integration: IntegrationListing
    applicationId: string
    onSelect: (integration: IntegrationListing) => void
}

const LegacyIntegrationCard: React.FC<LegacyIntegrationCardProps> = ({
    integration,
    onSelect
}) => {

    return (
        <CardBody onClick={() => onSelect(integration)}>
            <CardHeader>
                <LegacyHeader>
                    <p>Legacy</p>
                    <img src={sunsetIcon}/>
                </LegacyHeader>
            </CardHeader>
            <CardImageWrapper>
                <img src={integration.iconUrl ?? defaultImage} />
            </CardImageWrapper>
            <InnerCardBody>
                <p className="version-label">{' '}</p>
                <p className="template-title">{integration?.name}</p>
                <p className="template-byline">
                    <img src={integrationIcon} />
                    <span>{`Legacy Integration - by ${integration.provider}`}</span>
                </p>
                <p className="template-description">{integration.description}</p>
            </InnerCardBody>
            <CardFooter>
               
                <p className="template-status">
                    <span>{"Not Enabled"}</span>
                    <img src={notEnabledIcon} />
                </p>
            </CardFooter>
        </CardBody>)
}

const CardBody = styled.div(`
    border: ${style_border_default};
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    width: 340px;
    height: 376px;
    position: relative;
    margin-left: 32px;
    margin-top: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: ${color_text_default};
`);

const CardHeader = styled.div(`
    background: ${color_shades_lighter};
    height: 72px;
    border-bottom: ${style_border_default};
    display: flex;
`);

const LegacyHeader = styled.div(`
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-left: auto;
    p {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 14px;
        line-height: 16px;
        margin-right: 8px;
    }
`)

const CardImageWrapper = styled.div(`
    position: absolute;
    width: 96px;
    height: 96px;
    margin-left: 24px;
    margin-top: 24px;
    background: white;
    border: ${style_border_default};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
        max-height: 100%;
        max-width: 100%;
    }
`);

const InnerCardBody = styled.div(`
    display: flex;
    flex-direction: column;
    margin: 24px;
    color: ${color_text_default};
    flex: 1;
    .version-label {
        margin-right: 0;
        margin-left: auto;
        text-align: right;        
        font-size: 14px;
        line-height: 20px;
        height: 20px;
    }
    .template-title {
        margin-top: 16px;
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
    }
    .template-byline {
        display: flex;
        align-items: center;
        color: ${color_text_light};
        font-size: 14px;
        line-height: 20px;
        img {
            margin-right: 8px;
        }
    }
    .template-description {
        margin-top: 24px;
    }
`)

const CardFooter = styled.div(`
    height: 72px;
    border-top: ${style_border_default};
    margin: 0 24px;
    display: flex;
    align-items: center;
    .template-price {
        .price-label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: ${color_text_light};
            display: block;
        }
        .price-value {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            display: block;
        }
    }
    .template-status {
        margin-right: 0;
        margin-left: auto;
        font-size: 14px;
        line-height: 20px;
        font-style: italic;
        color: ${color_text_light};
        display: flex;
        align-items: center;
        &.enabled {
            color: ${color_colors_growth};
        }
        img {
            width: 24px;
            margin-left: 8px;
        }
    }
`)
export default LegacyIntegrationCard