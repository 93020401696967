import React from 'react';
import { css } from '@emotion/css';
import LanguageModel from '../../../../models/features/api/LanguageModel';
import LanguageCollectionAvatar from '../../../../components/general/LanguageCollectionAvatar';
import { getLanguageNameForLanguage, getRegionyNameForLanguage, getLanguageName, getFlagIconForLanguage } from '../../../../models/extensions/languages';
import _ from 'lodash';
import GroupedMultiSelector, { ItemGroup } from '../../../../components/forms/GroupedMultiSelector';

interface LanguageSingleSelectorProps {
    languages: LanguageModel[]
    selectedLanguageIds: string[]
    onChange: (selectedLanguageIds: string[]) => void
    label?: string
    className?: string
    groupToggle?: string
    labelStyle?: string
    optionStyle?: string
}
interface LanguageSingleSelectorState {
    isExpanded: boolean
}

class LanguageSingleSelector extends React.Component<LanguageSingleSelectorProps, LanguageSingleSelectorState> {
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: false,
        }
    }

    handleChange(selectedIds: string[]) {
        this.props.onChange(selectedIds);
    }

    getLanguageGroups(): ItemGroup<LanguageModel>[] {
        var dict = _.groupBy(this.props.languages, l => l.languageGroupCode)
        var groups = Object.keys(dict).map(key => ({
            key: key,
            label: `${key.toUpperCase()} - ${getLanguageName(key)}`,
            subItems: dict[key].map(lang => ({
                data: lang,
                value: lang.id,
                icon: getFlagIconForLanguage(lang),
                label: getRegionyNameForLanguage(lang),
                groupKey: key,
                isSelected: this.props.selectedLanguageIds.some(id => id == lang.id)
            }))
        }));
        groups = _.sortBy(groups, group => group.key);
        return groups;
    }

    render() {
        const selectedLangs = this.props.languages.filter(l => this.props.selectedLanguageIds.some(id => id == l.id));
        return (
            <GroupedMultiSelector 
                label={this.props.label ?? "Languages:"}
                groupToggle={this.props.groupToggle ? this.props.groupToggle : groupToggleStyle}
                groups={this.getLanguageGroups()}
                onChange={this.handleChange.bind(this)}
                selectedValues={this.props.selectedLanguageIds}
                withGroupSelection={false}
                restrictGroups={true}
                className={this.props.className}
                subItemCheckType={"radio"}
                position={"bottom"}
                emptyText="You must add at least one language to your application first."
                innerSelectionRender={
                    () => <>
                        <LanguageCollectionAvatar languages={selectedLangs} limit={3} />
                        <p className={this.props.labelStyle ? this.props.labelStyle : labelStyle}>{selectedLangs.length > 0 ? selectedLangs.map(l => `${getLanguageNameForLanguage(l)} (${getRegionyNameForLanguage(l)})`).join(', ') : 'Select a language'}</p>
                    </>
                } />
        )
    }
}

const groupToggleStyle = css`
    margin-left: auto;
    margin-right: 8px;
`

const labelStyle = css`
    padding: 0 8px;
`

export default LanguageSingleSelector;