import React, { useEffect } from 'react';
import { TabPanel } from 'react-tabs';
import OrganizationApplications from '../../organizationApplications';
import OrganizationMembers from '../../organizationMembers';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import TitleStateContainer from '../../../state/containers/TitleStateContainer';
import OrganizationSettings from '../../organizationSettings';
import FullPageTabbedPanel from '../../../components/structure/Panels/FullPageTabbedPanel';
import Logo from '../../dashboardHeader/components/Logo';
import DashboardHeader from '../../dashboardHeader';
import ProfileMenu from '../../profileMenu';
import ApplicationSelectorMenu from '../../applicationSelectorMenu';
import HeaderSeparator from '../../../components/structure/Panels/HeaderSeparator';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import OrganizationTemplates from '../../organizationTemplates';
import OrganizationAnalytics from '../../organizationAnalytics';

const appsIcon = require('../../../content/images/tab-icons/apps.svg');
const membersIcon = require('../../../content/images/tab-icons/members.svg');
const settingsIcon = require('../../../content/images/tab-icons/settings.svg');
const builderIcon = require('../../../content/images/tab-icons/lego-block.svg');
const analyticsIcon = require('../../../content/images/tab-icons/analytics.svg');

interface OrganizationDashboardProps {
    orgsContainer: OrganizationContainer
    appContainer: ApplicationContainer
    titleContainer: TitleStateContainer
    history: any
}
const membersTabName = "members";
const settingsTabName = "orgSettings";
const templatesTabName = "templates";
const modulesTabName = "modules";
const analyticsTabName = "orgAnalytics";

const OrganizationDashboardContent: React.FC<OrganizationDashboardProps> = (props) => {
    useEffect(() => {
        updateTitle();
    }, [location.href]);

    const updateTitle = () => {
        props.titleContainer.setTitleView(props.orgsContainer.state.currentOrganization.name, null, null);
    };

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = "/v";
        const tabPaths = [
            "",                     // index 0: default ("/v")
            `/${membersTabName}`,    // index 1: "/v/members"
            `/${modulesTabName}`,    // index 2: "/v/modules"
            `/${analyticsTabName}`,  // index 3: "/v/orgAnalytics"
            `/${settingsTabName}`    // index 4: "/v/orgSettings"
        ];
        const newPath = urlStart + (tabPaths[index] || "");
        props.history.push(newPath);
    };

    const getSelectedIndex = () => {
        if (location.href.indexOf(membersTabName) > -1) {
            return 1;
        }
        if (location.href.indexOf(templatesTabName) > -1 || location.href.indexOf(modulesTabName) > -1) {
            return 2;
        }
        if (location.href.indexOf(analyticsTabName) > -1) {
            return 3;
        }
        if (location.href.indexOf(settingsTabName) > -1) {
            return 4;
        }

        return 0;
    };

    return (
        <FullPageTabbedPanel tabs={[{
            title: "Apps",
            icon: appsIcon,
            selectedIcon: appsIcon,
            className: "ac-org-apps-tab"
        }, {
            title: "Members",
            icon: membersIcon,
            selectedIcon: membersIcon,
            className: "ac-org-members-tab"
        }, {
            title: 'Module Builder',
            icon: builderIcon,
            selectedIcon: builderIcon,
            className: "ac-org-builder-tab"
        },
        {
            title: "Analytics",
            icon: analyticsIcon,
            selectedIcon: analyticsIcon,
            className: "ac-org-settings-tab"
        },
        {
            title: "Settings",
            icon: settingsIcon,
            selectedIcon: settingsIcon,
            className: "ac-org-settings-tab"
        },
        ]}
            onTabSelected={handleTabSelection.bind(this)} selectedIndex={getSelectedIndex()}
            leftRender={() => <>
                <Logo isFull isInverse />
                <ApplicationSelectorMenu organizationId={props.orgsContainer.state.currentOrganization?.id} application={null} appContainer={props.appContainer} />
            </>}
            titleRender={() => <DashboardHeader />}
            rightRender={() => <>
                <HeaderSeparator />
                <ProfileMenu />
            </>}>
            <TabPanel>
                <ScrollablePanelBody>
                    <OrganizationApplications />
                </ScrollablePanelBody>
            </TabPanel>
            <TabPanel>
                <ScrollablePanelBody>
                    <OrganizationMembers history={props.history} />
                </ScrollablePanelBody>
            </TabPanel>
            <TabPanel>
                <OrganizationTemplates history={props.history} />
            </TabPanel>
            <TabPanel>
                <OrganizationAnalytics history={props.history} />
            </TabPanel>
            <TabPanel>
                <OrganizationSettings history={props.history} />
            </TabPanel>
        </FullPageTabbedPanel>
    );

}

export default OrganizationDashboardContent;
