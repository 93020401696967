import React from 'react';
import { css } from '@emotion/css';
import Tooltip from 'rc-tooltip';
import { ocean_blue, dark_grey_blue, dark_grey_blue_10, color_shades_dark } from '../../constants/colors';
import OverflowMenu, { OverflowOption } from './overflowMenu/OverflowMenu';

interface LargeOverflowMenuProps {
    isActive?: boolean
    onOptionSelected?: (optionName: string, event?: any) => void
    options: OverflowOption[]
    icon?: string
    hoverIcon?: string
    className?: string
}
interface LargeOverflowMenuState {
    isActive: boolean
}


const containerStyle = css`
    position: relative;
`
class LargeOverflowMenu extends React.Component<LargeOverflowMenuProps, LargeOverflowMenuState> {
    componentWillMount() {
        this.setState({
            ...this.state,
            isActive: false
        })
    }

    componentDidUpdate(prevProps: LargeOverflowMenuProps) {
        if (prevProps.isActive != this.props.isActive) {
            this.setState({
                ...this.state,
                isActive: this.props.isActive
            });
        }
    }

    handleClick(e: MouseEvent) {
        e.stopPropagation();
        this.setState({
            ...this.state,
            isActive: !this.state.isActive
        })
    }

    handleMenuClose() {
        this.setState({
            ...this.state,
            isActive: false
        })
    }

    render() {
        return (
            <div className={`${containerStyle} ${this.props.className ?? ''}`}>
                <Tooltip mouseEnterDelay={.5} overlayClassName={tooltipContentStyle} trigger={['hover']} placement="top" overlay={<span>Show options</span>}>
                    {this.props.icon ? <img className="menu-icon" src={this.props.icon} onClick={this.handleClick.bind(this)}/>
                        : <div className={`${overflowButtonStyle} ${this.state.isActive ? 'active' : ''}`} onClick={this.handleClick.bind(this)}>
                            <div className="circle"></div>
                            <div className="circle"></div>
                            <div className="circle"></div>
                        </div>
                    }
                </Tooltip>
                {this.state.isActive &&
                    <OverflowMenu className={overflowMenuStyle} onClose={this.handleMenuClose.bind(this)} onOptionSelected={(l, e) => this.props.onOptionSelected(l, e)} options={this.props.options}>
                        {this.props.children}
                    </OverflowMenu>}
            </div>
        )
    }
}

const overflowMenuStyle = css`
    .triangle {
        margin-right: 13px;
    }
`;
const overflowButtonStyle = css`
    border: 1px solid ${color_shades_dark};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    .circle {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        border: solid 1px ${color_shades_dark};
        margin: 2px;
    }

    &:hover, &.active { 
        box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
        border: solid 1px #007dbb;
        .circle {
            border: solid 1px #007dbb;
        }
    }

    &.active {
        background-color: rgba(0, 125, 187, 0.1);
    }
`;


const tooltipContentStyle = css`
    .rc-tooltip-inner{
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0.6px;
        z-index: 120;
        color: white;
        background-color: #304562;
        box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
        padding: 8px;
        min-height: 0;
    }
`
export default LargeOverflowMenu;