import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import MenuItemModel from '../../../models/nlp/menu/MenuItemModel';
import { color_shades_dark, color_shades_darkest } from '../../../constants/colors';
import { MenuItemOptionGroup } from '../../../models/nlp/menu/MenuItemOptionGroup';
import { MenuItemSizeGroup } from '../../../models/nlp/menu/MenuItemSizeGroup';

export interface VoicifyMenuEditorProps {
    menuItem?: MenuItemModel;
    group?: MenuItemOptionGroup | MenuItemSizeGroup;
    handleChange: (value: any) => void;
};

const VoicifyMenuEditor = (props: VoicifyMenuEditorProps) => {

    const jsonEditorRef = useRef(null);

    useEffect(() => {
        if (jsonEditorRef.current !== null) {
            jsonEditorRef.current.set(props.menuItem ? props.menuItem : props.group);
        }
    }, [props.menuItem, props.group])

    const setRef = instance => {
        if (instance) {
            jsonEditorRef.current = instance.jsonEditor;
        } else {
            jsonEditorRef.current = null;
        }
    };

    return (
        <MenuItemEditorWrapper>
            <CustomEditor>
                <Editor
                    ref={setRef}
                    value={props.menuItem ? props.menuItem : props.group}
                    onChange={value => props.handleChange(value)}
                    enableTransform={false}
                    enableSort={false}
                    mode="tree"
                    allowedModes={['tree', 'code']}
                    sortObjectKeys={true}
                />
            </CustomEditor>
        </MenuItemEditorWrapper>
    );

};

export default VoicifyMenuEditor;

const MenuItemEditorWrapper = styled.div`
    .field-wrapper {
        label {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    .field-container {
        height: 44px;
    }
    label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
`;

const CustomEditor = styled.div`
    font-family: Muli;
    *.jsoneditor-mode-tree {
        border: 1px solid ${color_shades_dark} ;
        font-family: Muli;
    }
    div.jsoneditor-menu {
        background-color: ${color_shades_darkest};
        border-bottom: none;
    }
    ul.jsoneditor-menu {
        background-color: white;
    }
    *.jsoneditor-navigation-bar {
        font-family: Muli;
    }
    .div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor td, div.jsoneditor th, div.jsoneditor textarea, pre.jsoneditor-preview, .jsoneditor-schema-error, .jsoneditor-popover {
        font-family: Muli;
    }
    div.jsoneditor-tree div.jsoneditor-color {
        display: none;
    }
`;

