import React from 'react';
import {Subscribe} from 'unstated';
import RepromptContainer from '../../state/containers/RepromptContainer';
import RepromptModel from '../../models/features/api/Reprompt/RepromptModel';
import ModalLayout from '../../components/structure/ModalLayout';
import ScrollView from '../../components/structure/ScrollView';
import FullScreenLayout from '../fullScreenLayout';
import PageContainer from '../../components/structure/PageContainer';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import ModalTitle from '../../components/general/ModalTitle';
import Button from '../../components/general/Button';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import TabPanelBody from '../../components/structure/TabPanelBody';
import RepromptForm from './components/RepromptForm';
import SmallModalLayout from '../../components/structure/SmallModalLayout';
const closeIcon = require('../../content/images/close_icon.svg');

interface RepromptManagerProps {
    applicationId: string
    reprompt?: RepromptModel
    onClose?: () => void 
    onFinish?: (reprompt: RepromptModel) => void
}

interface RepromptManagerState {
    hasChanges: boolean
}

class RepromptManager extends React.Component<RepromptManagerProps, RepromptManagerState> {
    componentWillMount() {
        this.setState({
            hasChanges: false
        });
    } 
    handleClose() {
        if(this.state.hasChanges) {
            var confirmClose = confirm("You have unsaved changes. Are you sure you want to close?");
            if(!confirmClose)
                return;
        }

        if(this.props.onClose)
            this.props.onClose();
    }
    handleFinish(reprompt: RepromptModel) {
        this.setState({
            hasChanges: false
        });

        if(this.props.onFinish) {
            this.props.onFinish(reprompt);
        }
    }
    handleChange() {
        this.setState({
            hasChanges: true
        });
    }
    render() {
        return (
            <SmallModalLayout onClose={this.handleClose.bind(this)} isVisible={true}>
            <Subscribe to={[RepromptContainer]}>
                {(repromptContainer: RepromptContainer) => {
                    return (
                        <FullScreenLayout>
                            <ScrollView>
                                <PageContainer>
                                    <HorizontalContainer>
                                        <ModalTitle>Reprompt:</ModalTitle>
                                        <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={this.handleClose.bind(this)} />
                                    </HorizontalContainer>
                                    <HorizontalSeparator />
                                    <TabPanelBody>
                                        <RepromptForm reprompt={this.props.reprompt}
                                          applicationId={this.props.applicationId}
                                          repromptContainer={repromptContainer}
                                          onFinish={this.handleFinish.bind(this)}
                                          onChange={this.handleChange.bind(this)}
                                          onClose={this.handleClose.bind(this)} />
                                    </TabPanelBody>
                                </PageContainer>
                            </ScrollView>
                        </FullScreenLayout>
                    );
                }}
            </Subscribe>
        </SmallModalLayout>
        )
    }
}

export default RepromptManager;