import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import FeatureContainer from '../../state/containers/FeatureContainer';
import NumberRangeContainer from '../../state/containers/NumberRangeContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import EditNumberRangeForm from './components/EditNumberRangeForm';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NumberRangeModel from '../../models/features/api/NumberRanges/NumberRangeModel';
import IResult from '../../models/result/IResult';
import GenericEditContentItemPage from '../formScenes/GenericEditContentItemPage';
import { NumberRangeFeatureTypeId } from '../../constants/featureTypeIds';
import queryString from 'query-string';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';

interface ApplicationRouteProps {
    applicationFeatureId: string
    numberRangeId: string
}
interface EditNumberRangeProps extends RouteComponentProps<ApplicationRouteProps> { }

class EditNumberRange extends React.Component<EditNumberRangeProps> {
    render() {
        return (
            <Subscribe key={this.props.match.params.numberRangeId + this.props.match.params.applicationFeatureId} to={[
                NumberRangeContainer,
                ApplicationContainer,
                TitleStateContainer,
                RepromptContainer,
                FollowUpContainer,
                MediaResponseStateContainer,
                FeatureContainer,
                ApplicationEnvironmentsContainer,
                ApplicationBackupVersionsContainer]}>
                {(numberRangeContainer: NumberRangeContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer,
                    environmentsContainer: ApplicationEnvironmentsContainer,
                    backupsContainer: ApplicationBackupVersionsContainer) => {
                    return (
                        <GenericEditContentItemPage stateContainer={numberRangeContainer}
                            environmentsContainer={environmentsContainer}
                            backupsContainer={backupsContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="Edit Number Range"
                            contentItemId={this.props.match.params.numberRangeId}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={NumberRangeFeatureTypeId}
                            formRender={(contentItem: NumberRangeModel,
                                application,
                                isTemplateOwned,
                                assistantApplication,
                                onShowPublishModal,
                                onSubmit: (model: NumberRangeModel, onSuccess) => Promise<IResult<NumberRangeModel>>,
                                toggleSync: (id, shouldNotSync) => Promise<IResult<NumberRangeModel>>,
                                onAccessTypeChange) => (
                                <EditNumberRangeForm
                                    location={this.props.location}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    application={application}
                                    numberRange={contentItem}
                                    stateContainer={numberRangeContainer}
                                    repromptContainer={repromptContainer}
                                    followUpContainer={followUpContainer}
                                    featureContainer={featureContainer}
                                    appContainer={appContainer}
                                    responseStateContainer={responseContainer}
                                    assistantApplication={assistantApplication}
                                    onShowPublishModal={onShowPublishModal}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onSubmit={onSubmit}
                                    onToggleSync={toggleSync}
                                    previousContentId={queryString?.parse(this.props.location.search)?.previousContentId as string}
                                    history={this.props.history}
                                    isSaving={numberRangeContainer.state.isSaving}
                                    isTemplateOwned={isTemplateOwned} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default EditNumberRange;