export interface TextToSpeechRequest {
    applicationId: string
    applicationSecret: string
    ssmlRequest: {
        ssml: string
        locale: string
        voice: string
    }
}

export interface TextToSpeechResponse {
    rootElementType: "string",
    url: "string" 
}

export default interface VoicifyTextToSpeechProvider {
    initialize: (locale: string) => void
    speakSsml: (ssml: string) => void
    stop: () => void
    addFinishListener: (callback: () => void) => void
    clearHandlers: () => void;
}

export interface VoicifyTextToSpeechSettings {
    appId: string
    appKey: string
    locale: string
    textToSpeechVoice: string
    textToSpeechProvider: string
    serverRootUrl: string
}