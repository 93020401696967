import React, { useEffect, useState } from "react";
import { css } from '@emotion/css';
import OrganizationContainer from "../../../state/containers/OrganizationContainer";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import ApplicationInfoSettings from "./ApplicationInfoSettings";
import InteractionModel from "../../interactionModel";
import { TabPanel } from "react-tabs";
import TabPanelBody from "../../../components/structure/TabPanelBody";
import { color_shades_dark, color_shades_darkest } from "../../../constants/colors";
import LanguageSettings from "./languages/LanguageSettings";
import TabContainerPanel from "../../../components/structure/Panels/TabContainerPanel";
import styled from "@emotion/styled";
import DeploymentsTab from "./DeploymentsTab";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import MemberContainer from "../../../state/containers/MemberContainer";
import ApplicationMembersContainer from "../../../state/containers/ApplicationMembersContainer";
import UserContainer from "../../../state/containers/UserContainer";
import Loader from "../../../components/general/Loader";
import DropDownSelectField from "../../../components/forms/DropDownSelectField";
import ApplicationEnvironmentsContainer, { ApplicationEnvironmentsOption } from "../../../state/containers/ApplicationEnvironmentsContainer";
import ApplicationEnvironmentModel from "../../../models/applications/api/environments/ApplicationEnvironmentModel";
import InteractionModelSettingsHeader from "./InteractionModelSettingsHeader";
import MediaContainer from "../../../state/containers/MediaContainer";

const interactionModelIcon = require('../../../content/images/application-settings/Interaction-Model-Dark.svg');
const interactionModelBlueIcon = require('../../../content/images/application-settings/Interaction-Model-blue.svg');
const rocketIcon = require('../../../content/images/application-settings/Rocket-dark.svg');
const rocketBlueIcon = require('../../../content/images/application-settings/Rocket-blue.svg');
const appInfoIcon = require('../../../content/images/application-settings/User-Badge-Dark.svg');
const appInfoBlueIcon = require('../../../content/images/application-settings/User-Badge-blue.svg');
const liveAppIcon = require('../../../content/images/live-app-icon.svg');
const testAppIcon = require('../../../content/images/test-app-icon.svg');

interface ApplicationsSettingsTabProps {
    history: any;
    applicationId: string;
    environmentId: string;
    appContainer: ApplicationContainer;
    orgContainer: OrganizationContainer;
    memberContainer: MemberContainer;
    applicationMembersContainer: ApplicationMembersContainer;
    userContainer: UserContainer;
    environmentsContainer: ApplicationEnvironmentsContainer;
    mediaContainer: MediaContainer;
}

//We are leaving interactionmodel here such that the old route still works.
//Moving forward we will be using nlp
const interactionModelTabName = "interactionmodel";
const nlpModelTabName = "nlp";
const deploymentTabName = "deployments";
const appInfo = "appInfo";

const ApplicationSettingsTab = (props: ApplicationsSettingsTabProps) => {

    // Deafult environment is used in App Info tab, Live(Production) for most but Draft for AL
    const [defaultEnvironmentOption, setDefaultEnvironmentOption] = useState<ApplicationEnvironmentsOption>(null);
    // If App has both Live(Production) and Draft environments then we show a selection drop down in Deployment Tab
    const [selectedEnvironmentOption, setSelectedEnvironmentOption] = useState<ApplicationEnvironmentsOption>(null);
    const [showEnvironmentOptions, setShowEnvironmentOptions] = useState(false);

    useEffect(() => {
        getDefaultEnvironment();
    }, [props.applicationId]);

    const getDefaultEnvironment = async () => {
        const envsResult = await props.environmentsContainer.loadEnvironments(props.applicationId);

        const appEnvs: ApplicationEnvironmentModel[] = envsResult.data;
        let selectedEnv: ApplicationEnvironmentModel = null;
        if (props.environmentId) {
            selectedEnv = appEnvs?.find(e => e.id == props.environmentId);
        }
        const liveEnv: ApplicationEnvironmentModel = props.environmentsContainer.findLiveEnvironment(props.applicationId);
        const draftEnv: ApplicationEnvironmentModel = props.environmentsContainer.findDraftEnvironment(props.applicationId);

        // if we have a environment id from the url lets use that
        if (selectedEnv) {
            if (selectedEnv.isDraft) {
                setSelectedEnvironmentOption("draft");
                setDefaultEnvironmentOption("draft");
            } else {
                setSelectedEnvironmentOption("live");
                setDefaultEnvironmentOption("live");
            }
        }
        // If app has Live env then environmentOption: "live" is a default
        // All new apps get both envs and all migrated ones do as well.
        else if (liveEnv) {
            setSelectedEnvironmentOption("live");
            setDefaultEnvironmentOption("live");
            if (getSelectedIndex() == 2)
                props.history.push(`/v/apps/${props.applicationId}/appSettings/${deploymentTabName}/${liveEnv.id}`);
        }
        // If some strange app only has one env and it is a Draft env then "draft" option is a default
        else if (appEnvs?.length === 1 && draftEnv) {
            setSelectedEnvironmentOption("draft");
            setDefaultEnvironmentOption("draft");
            if (getSelectedIndex() == 2)
                props.history.push(`/v/apps/${props.applicationId}/appSettings/${deploymentTabName}/${draftEnv.id}`);
        }

        if (liveEnv && draftEnv) {
            setShowEnvironmentOptions(true);
        }
    };

    function handleTabSelection(index: number, last: number) {
        if (index == last) return;
        const urlStart = `/v/apps/${props.applicationId}/appSettings/`;

        if (index === 0) {
            props.history.push(urlStart + nlpModelTabName)
        }
        if (index === 1) {
            props.history.push(urlStart + appInfo)
        }
        if (index === 2) {
            props.history.push(urlStart + deploymentTabName)
        }
    }

    function getSelectedIndex() {
        if (location.href.indexOf(appInfo) > -1) {
            return 1;
        }
        if (location.href.indexOf(deploymentTabName) > -1) {
            return 2;
        }
        return 0;
    }

    function handleSelectedEnvironmentOptionChange(option: ApplicationEnvironmentsOption) {
        setSelectedEnvironmentOption(option)
        const env = props.environmentsContainer.findEnvironmentByOption(props.applicationId, option);
        if (env) {
            props.history.push(`/v/apps/${props.applicationId}/appSettings/${deploymentTabName}/${env.id}`);
        }
    }

    const renderEnvironmentSelection = () => {
        return <SelectionContainer>
            <DropDownSelectField
                className={customSelect}
                key={"environment-selector"}
                onChange={(e: any) => handleSelectedEnvironmentOptionChange(e.value)}
                value={selectedEnvironmentOption}
                clearable={false}
                searchable={false}
                valueRenderer={formatValueLabel}
                optionImageStyle={optionImageStyle}
                options={[
                    { value: "live", label: "Live App", image: liveAppIcon },
                    { value: "draft", label: "Test App", image: testAppIcon }
                ]}
            />
        </SelectionContainer>
    }

    function renderHeaderContent() {
        const index = getSelectedIndex();
        if (index == 2) {
            return <AdditionalHeader>
                {showEnvironmentOptions ? renderEnvironmentSelection() : null}
                <span className="header-title">Deployment Platform</span>
            </AdditionalHeader>
        }
        if (index == 1) {
            return <AdditionalHeader>
                <span className="header-title">Application Identity</span>
            </AdditionalHeader>
        }
        else {
            return <InteractionModelSettingsHeader
                applicationId={props.applicationId}
                history={props.history}
                appContainer={props.appContainer}
            />
        }
    };

    const formatValueLabel = ({ label, image }) => (
        <CustomSelectChosenOption>
            <img className={optionImageStyle} src={image} />
            <p>{label}</p>
        </CustomSelectChosenOption>
    );

    if (!props.appContainer.state.currentApp)
        return <Loader />;

    return (
        <TabContainerPanel tabs={[
            { title: 'NLP Model', icon: interactionModelIcon, selectedIcon: interactionModelBlueIcon },
            { title: 'App Info', icon: appInfoIcon, selectedIcon: appInfoBlueIcon },
            { title: 'Deployment', icon: rocketIcon, selectedIcon: rocketBlueIcon, className: "ac-deploy-tab" }
        ]}
            onTabSelected={(index, last) => handleTabSelection(index, last)}
            selectedIndex={getSelectedIndex()}
            additionalHeaderContent={renderHeaderContent}>
            <TabPanel>
                <TabPanelBody className={langaugeTabStyle}>
                    <LanguageSettings
                        appContainer={props.appContainer}
                        applicationId={props.applicationId}
                        clearSelection={false} />
                    <InteractionModel
                        appContainer={props.appContainer}
                        applicationId={props.applicationId}
                        allAppLanguages={props.appContainer.state.currentApp.languages} />
                </TabPanelBody>
            </TabPanel>
            <TabPanel>
                <TabPanelBody className={langaugeTabStyle}>
                    <CustomScrollbars autoHeight={true} autoHeightMax="calc(100vh - 140px)">
                        <LanguageSettings
                            appContainer={props.appContainer}
                            applicationId={props.applicationId}
                            clearSelection={true} />
                        <ApplicationInfoSettings
                            appContainer={props.appContainer}
                            applicationId={props.applicationId}
                            environmentOption={defaultEnvironmentOption}
                            environmentsContainer={props.environmentsContainer} />
                    </CustomScrollbars>
                </TabPanelBody>
            </TabPanel>
            <TabPanel>
                <DeploymentsTab
                    {...props}
                    mediaContainer={props.mediaContainer}
                    environmentOption={selectedEnvironmentOption}
                    environmentsContainer={props.environmentsContainer} />
            </TabPanel>
        </TabContainerPanel>
    );
}

const langaugeTabStyle = css`
    padding: 0;
`
const AdditionalHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 100vw; // auto margin just refuses to work here
    padding-left: 32px;
    margin-left: 7px;
    white-space: nowrap;
    border-left: 1px solid ${color_shades_dark};
    height: 100%;
    padding-top: 0;
    .header-title {
        padding: 16px 16px 0 0;
    }

`;
const SelectionContainer = styled.div`
    padding: 10px 16px 10px 0;
`;

const optionImageStyle = css`
    height: 8px;
    width: 8px;
    margin: auto 0px;
`;

const customSelect = css`
    height: 28px;
    width: 140px;
`;

const CustomSelectChosenOption = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    p {
        color: ${color_shades_darkest};
        margin-left:10px;   
    }
`;

export default ApplicationSettingsTab;
