import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { TabPanel } from 'react-tabs';
import TitleStateContainer from '../../../state/containers/TitleStateContainer';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import ContentTreeStateContainer from '../../../state/containers/ContentTreeStateContainer';
import ImportExportContainer from '../../../state/containers/ImportExportContainer';
import FullPageTabbedPanel from '../../../components/structure/Panels/FullPageTabbedPanel';
import Logo from '../../dashboardHeader/components/Logo';
import HeaderSeparator from '../../../components/structure/Panels/HeaderSeparator';
import ApplicationSelectorMenu from '../../applicationSelectorMenu';
import DashboardHeader from '../../dashboardHeader';
import ProfileMenu from '../../profileMenu';
import FeatureContentManager from '../../featureContentManager';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import ApplicationAnalytics from '../../applicationAnalytics';
import ApplicationMembers from '../../applicationMembers';
import ApplicationSettings from '../../applicationSettings';
import ApplicationSimulator from '../../simulator';
import ApplicationAppliedTemplates from '../../applicationAppliedTemplates';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationAnalyticsFacetContainer from '../../../state/containers/ApplicationAnalyticsFacetContainer';
import WebhookManager from '../../webhookManager';
import WebhookContainer from '../../../state/containers/WebhookContainer';
import WebhookFolderContainer from '../../../state/containers/WebhookFolderContainer';
import ApplicationModules from '../../applicationModules';
import IntegrationsListingsContainer from '../../../state/containers/IntegrationsListingsContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import LoaderWithHeader from '../../../components/general/LoaderWithHeader';
import PageError from '../../../components/general/PageError';
import ApplicationMembersContainer from '../../../state/containers/ApplicationMembersContainer';
import UserContainer from '../../../state/containers/UserContainer';
import MemberContainer from '../../../state/containers/MemberContainer';
import NlpModelTrainingIndicator from './NlpModelTrainingIndicator';

const contentIcon = require('../../../content/images/tab-icons/content.svg');
const analyticsIcon = require('../../../content/images/tab-icons/analytics.svg');
const participantsIcon = require('../../../content/images/tab-icons/participants.svg');
const webhooksIcon = require('../../../content/images/webhooks/webhook-white.svg');
const settingsIcon = require('../../../content/images/tab-icons/settings.svg');
const simulatorIcon = require('../../../content/images/tab-icons/simulator.svg');
const modulesIcon = require('../../../content/images/tab-icons/modules.svg');

interface ApplicationDashboardContentProps {
    titleContainer: TitleStateContainer
    appContainer: ApplicationContainer
    orgContainer: OrganizationContainer
    environmentId: string
    featureContainer: FeatureContainer
    treeContainer: ContentTreeStateContainer
    importExportContainer: ImportExportContainer
    webhookContainer: WebhookContainer
    webhookFolderContainer: WebhookFolderContainer
    templateConfigurationContainer: TemplateConfigurationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    facetContainer: ApplicationAnalyticsFacetContainer
    integrationsContainer: IntegrationsListingsContainer
    environmentsContainer: ApplicationEnvironmentsContainer
    backupsContainer: ApplicationBackupVersionsContainer
    applicationMembersContainer: ApplicationMembersContainer
    orgMemberContainer: MemberContainer
    userContainer: UserContainer
    applicationId: string
    history: any
    currentFeatureId?: string
    currentWebhookFolderId?: string
    currentWebhookId?: string
    sessionId?: string
}
const analyticsTabName = "analytics";
const participantsTabName = "participants";
const settingsTabName = "appSettings";
const simulatorTabName = "simulator";
const webhooksTabName = "webhooks";
const modulesTabName = "modules";
const templatesSubTabName = "templates";

const ApplicationDashboardContent: React.FC<ApplicationDashboardContentProps> = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [isAppAdmin, setIsAppAdmin] = useState(false);

    useEffect(() => {
        if(props.applicationMembersContainer.state.members == null || props.applicationMembersContainer.state.members.length == 0) {
            props.applicationMembersContainer.getMembersForApp(props.applicationId);
        }
    }, [])

    useEffect(() => {
        const asyncLoad = async () => {
            let appId = props.applicationId;
            if(!appId) {
                const featureResult = await props.featureContainer.getApplicationFeature(props.currentFeatureId);
                appId = featureResult.data.applicationId;
            }
            const appResult = await props.appContainer.loadCurrentAppById(appId);
            props.titleContainer.setTitleView(appResult?.data?.name, [{
                id: props.orgContainer.state.currentOrganization.id,
                title: props.orgContainer.state.currentOrganization.name,
                link: "/v"
            }], appResult?.data);
            props.featureContainer.getFeaturesForApp(appId);
            props.webhookContainer.getWebhookAttachmentsForApplication(appId);
            props.featureContainer.getConversationCounts(appId);
            props.appliedAppTemplateContainer.loadAppliedTemplates(appId);
            props.environmentsContainer.loadEnvironments(appId);
            await setLoaded(true);
        }
        asyncLoad();
    }, [props.applicationId]);

    useEffect(() => {
        if(!props.applicationId
            || props.applicationMembersContainer.state.isLoading
            || props.userContainer.state?.currentUser?.id == null) 
            return;

        else {            
            props.orgMemberContainer.checkIsOrgAdmin(props.orgContainer.state.currentOrganization.id, props.userContainer.state.currentUser.id);
            const isAdmin = props.applicationMembersContainer.isUserAppAdmin(props.userContainer.state.currentUser.id, props.applicationId);
            setIsAppAdmin(isAdmin || props.orgMemberContainer.state.isOrgAdmin);
        }
    }, [props.userContainer.state.currentUser?.id, 
        props.applicationId, 
        props.applicationMembersContainer.state.members,
        props.orgMemberContainer.state.isOrgAdmin])

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = "/v/apps/" + props.applicationId;

        switch (index) {
            case 0: props.history.push(urlStart);
                break;
            case 1: props.history.push(`${urlStart}/${analyticsTabName}`);
                break;
            case 2: props.history.push(`${urlStart}/${participantsTabName}`);
                break;
            case 3: props.history.push(`${urlStart}/${webhooksTabName}`);
                break;
            case 4: props.history.push(`${urlStart}/${modulesTabName}`);
                break;
            case 5: props.history.push(`${urlStart}/${simulatorTabName}`);
                break;
            case 6: props.history.push(`${urlStart}/${settingsTabName}`);
                break;
        }
    }
    const getSelectedIndex = () => {
        if (location.href.indexOf(analyticsTabName) > -1) {
            return 1;
        }
        if (location.href.indexOf(participantsTabName) > -1) {
            return 2;
        }
        if (location.href.indexOf(webhooksTabName) > -1) {
            return 3;
        }
        if (location.href.indexOf(modulesTabName) > -1) {
            return 4;
        }
        if (location.href.indexOf(simulatorTabName) > -1) {
            return 5;
        }
        if (location.href.indexOf(settingsTabName) > -1) {
            return 6;
        }

        return 0;
    }
    const app = props.appContainer.state.currentApp;
    if (!loaded)
        return <LoaderWithHeader />
    
    return (
        <FullPageTabbedPanel key={app?.id ?? 'app'} tabs={[{
            title: "Content",
            icon: contentIcon,
            selectedIcon: contentIcon,
            className: "ac-app-content-tab"
        }, {
            title: "Analytics",
            icon: analyticsIcon,
            selectedIcon: analyticsIcon,
            className: "ac-app-analytics-tab"
        }, {
            title: "Participants",
            icon: participantsIcon,
            selectedIcon: participantsIcon,
            className: "ac-app-participants-tab"
        }, {
            title: "Webhooks",
            icon: webhooksIcon,
            selectedIcon: webhooksIcon,
            className: "ac-app-webhooks-tab"
        }, {
            title: "Modules",
            icon: modulesIcon,
            selectedIcon: modulesIcon,
            className: "ac-app-modules-tab ac-app-modules-tab"
        }, {
            title: "Simulator",
            icon: simulatorIcon,
            selectedIcon: simulatorIcon,
            className: "ac-app-simulator-tab"
        }, {
            title: "Settings",
            icon: settingsIcon,
            selectedIcon: settingsIcon,
            className: "ac-app-settings-tab"
        }]}
            onTabSelected={handleTabSelection.bind(this)} selectedIndex={getSelectedIndex()}
            leftRender={() => <>
                <Logo isFull isInverse />
                <ApplicationSelectorMenu application={app} appContainer={props.appContainer} 
                    organizationId={props.orgContainer.state.currentOrganization?.id}/>
            </>}
            titleRender={() => <DashboardHeader />}
            rightRender={() => <>
                <HeaderSeparator />
                <ProfileMenu />
            </>}>
            <TabPanel>
                {location.href.indexOf(templatesSubTabName) > -1
                    ? <ApplicationAppliedTemplates
                        key={props.currentFeatureId}
                        appContainer={props.appContainer}
                        appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                        applicationId={props.applicationId}
                        templateConfigurationContainer={props.templateConfigurationContainer}
                        history={props.history}
                        hasFullMenu />
                    : <FeatureContentManager
                        key={props.currentFeatureId}
                        appContainer={props.appContainer}
                        featureContainer={props.featureContainer}
                        webhookContainer={props.webhookContainer}
                        importExportContainer={props.importExportContainer}
                        treeContainer={props.treeContainer}
                        environmentsContainer={props.environmentsContainer}
                        backupsContainer={props.backupsContainer}
                        currentFeatureId={props.currentFeatureId ?? props.featureContainer.getRootFeature(props.applicationId)?.id}
                        applicationId={props.applicationId}
                        history={props.history}
                        isAppAdmin={isAppAdmin}
                        withNesting={false} />
                }
            </TabPanel>
            <TabPanel>
                <ApplicationAnalytics application={app} facetContainer={props.facetContainer} history={props.history} />
            </TabPanel>
            <TabPanel>
                <ScrollablePanelBody>
                    <ApplicationMembers applicationId={props.applicationId} history={props.history} />
                </ScrollablePanelBody>
            </TabPanel>
            <TabPanel>
                <WebhookManager
                    key={props.currentWebhookFolderId}
                    webhookContainer={props.webhookContainer}
                    webhookFolderContainer={props.webhookFolderContainer}
                    importExportContainer={props.importExportContainer}
                    appContainer={props.appContainer}
                    treeContainer={props.treeContainer}
                    currentFolderId={props.currentWebhookFolderId}
                    currentWebhookId={props.currentWebhookId}
                    applicationId={props.applicationId}
                    organizationId={props.orgContainer.state.currentOrganization?.id}
                    history={props.history}
                    withNesting={false} />
            </TabPanel>
            <TabPanel>
                <ApplicationModules appContainer={props.appContainer}
                    templateConfigurationContainer={props.templateConfigurationContainer}
                    appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                    integrationsContainer={props.integrationsContainer}
                    orgContainer={props.orgContainer}
                    applicationId={props.applicationId}
                    history={props.history} />
            </TabPanel>
            <TabPanel>
                <ApplicationSimulator key={props.applicationId} applicationId={props.applicationId} history={props.history} />
            </TabPanel>
            <TabPanel>
                <ApplicationSettings environmentId={props.environmentId} key={props.applicationId} applicationId={props.applicationId} history={props.history} />
            </TabPanel>
            <PageError errors={[
                ...props.backupsContainer?.state?.errors ?? [], 
                ...props.environmentsContainer?.state?.errors ?? [],
                ...props.featureContainer?.state?.errors ?? [],
                ...props.appContainer?.state?.errors ?? [],
                ...props.orgContainer?.state?.errors ?? [],
                ...props.webhookContainer?.state?.errors ?? [],
                ...props.appliedAppTemplateContainer?.state?.errors ?? []
                ]} 
                onClear={() => { 
                    props.backupsContainer.clearErrors(); 
                    props.environmentsContainer.clearErrors();  
                    props.featureContainer.clearErrors(); 
                    props.appContainer.clearErrors();                  
                    props.orgContainer.clearErrors();
                    props.webhookContainer.clearErrors();
                    props.appliedAppTemplateContainer.clearErrors();
                }} />
            <NlpModelTrainingIndicator application={props.appContainer.state.assistantApplication} />
        </FullPageTabbedPanel>
    )
}

export default ApplicationDashboardContent;