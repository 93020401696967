import * as yup from 'yup';
import { withFormik } from "formik";
import { CustomAssistantConfigurationsFormData } from "../../../../models/customAssistant/CustomAssistantConfigurationsFormData";
import CustomAssistantConfigurations from "./CustomAssistantConfigurations";
import { CustomAssistantConfiguration } from '../../../../models/customAssistant/CustomAssistantConfiguration';
import ApplicationContainer from '../../../../state/containers/ApplicationContainer';
import MediaContainer from '../../../../state/containers/MediaContainer';
import { VoicifyAssistantSettings } from '@voicify/voicify-browser-assistant-sdk';
import { MobileAssistantUIProps } from '../../../../models/customAssistant/CustomAssistantCodeExportTypes';

export interface CustomAssistantConfigurationsProps {
    closeCustomConfigForm: () => void
    copyText: (text: string) => void
    appContainer: ApplicationContainer
    applicationId: string
    appKey: string
    appName: string
    configName?: string
    appAvatar?: string
    customAssistantConfigurations: CustomAssistantConfiguration[]
    configId?: string
    configuration?: { [key: string]: any }
    isCreateConfigurationsOpen?: boolean
    isEditConfigurationsOpen?: boolean
    mediaContainer: MediaContainer
    theme?: string,
    handleSubmitForm: (config: CustomAssistantConfiguration) => void
    browserAssistantSettings: VoicifyAssistantSettings
    kotlinAssistantSettings: MobileAssistantUIProps
    swiftAssistantSettings: MobileAssistantUIProps
    isLoading: boolean
}

export interface PlatformConfigurations {
    javaScript: CustomAssistantConfiguration
    swift: CustomAssistantConfiguration
    kotlin: CustomAssistantConfiguration
}

const CustomAssistantConfigurationsForm = withFormik<CustomAssistantConfigurationsProps, CustomAssistantConfigurationsFormData>({
    mapPropsToValues: (props) => ({
        // Assistant Settings
        serverRootUrl: props.configuration.serverRootUrl ?? 'https://assistant.voicify.com',
        appId: props.configuration.applicationId ?? props.applicationId,
        appKey: props.configuration.appKey ?? props.appKey,
        channel: props.configuration.channel ?? props.appName,
        device: props.configuration.device ?? "Custom Assistant SDK",
        sessionTimeout: props.configuration.sessionTimeout ?? 3600000,
        locale: props.configuration.locale ?? props.appContainer.state.currentApp.languages[0].shortCode,
        defaultLanguageId: props.appContainer.state.currentApp.languages[0].id,
        name: props.configName,
        displayName: props.configuration.displayName,
        avatarUrl: props.configuration.avatarUrl,
        uiType: props.configuration.uiType ?? 'minimal',
        showConversationHistory: props.configuration.showConversationHistory ?? true,
        autoRunConversation: props.configuration.autoRunConversation ?? true,
        initializeWithWelcomeMessage: props.configuration.initializeWithWelcomeMessage ?? true,
        useVoiceInput: props.configuration.useVoiceInput ?? true,
        useOutputSpeech: props.configuration.useOutputSpeech ?? true,
        textToSpeechProvider: props.configuration.textToSpeechProvider ?? 'Google',
        textToSpeechVoice: props.configuration.textToSpeechVoice,
        useDraftContent: props.configuration.useDraftContent ?? false,
        activeInput: props.configuration.activeInput ?? 'textbox',
        noTracking: props.configuration.noTracking ?? true,
        themeStyle: props.configuration.theme ? props.configuration.theme : props.theme ? props.theme : 'lightStyle1',
        primaryColor: props.configuration.primaryColor && props.configuration.primaryColor,
        font: props.configuration.font && props.configuration.font,
        // Styles
        // Assistant Styles
        overallBackgroundColor: props.configuration.styles?.assistant?.backgroundColor,
        // Header Styles
        headerBackgroundColor: props.configuration.styles?.header?.backgroundColor,
        headerAssistantName: props.configuration.styles?.header?.assistantName,
        headerAssistantNameTextColor: props.configuration.styles?.header?.assistantNameTextColor,
        headerAssistantImage: props.configuration.styles?.header?.assistantImage,
        headerFontFamily: props.configuration.styles?.header?.fontFamily,
        headerCloseAssistantColor: props.configuration.styles?.header?.closeAssistantColor,
        headerMinimizeAssistantColor: props.configuration.styles?.header?.minimizeAssistantColor,
        headerMinimizeIcon: props.configuration.styles?.header?.minimizeIcon,
        headerCloseAssistantButtonImage: props.configuration.styles?.header?.closeAssistantButtonImage,
        // Body Styles
        bodyBackgroundColor: props.configuration.styles?.body?.backgroundColor,
        bodyMessageSentTextColor: props.configuration.styles?.body?.messageSentTextColor,
        bodyMessageSentBackgroundColor: props.configuration.styles?.body?.messageSentBackgroundColor,
        bodyMessageReceivedTextColor: props.configuration.styles?.body?.messageReceivedTextColor,
        bodyMessageReceivedBackgroundColor: props.configuration.styles?.body?.messageReceivedBackgroundColor,
        bodyMessageSentFontFamily: props.configuration.styles?.body?.messageSentFontFamily,
        bodyMessageReceivedFontFamily: props.configuration.styles?.body?.messageReceivedFontFamily,
        bodyHintsTextColor: props.configuration.styles?.body?.hintsTextColor,
        bodyHintsBackgroundColor: props.configuration.styles?.body?.hintsBackgroundColor,
        bodyHintsFontFamily: props.configuration.styles?.body?.hintsFontFamily,
        bodyAssistantImage: props.configuration.styles?.body?.assistantImage,
        // Toolbar Styles
        toolbarBackgroundColor: props.configuration.styles?.toolbar?.backgroundColor,
        toolbarSpeakActiveTitleColor: props.configuration.styles?.toolbar?.speakActiveTitleColor,
        toolbarSpeakInactiveTitleColor: props.configuration.styles?.toolbar?.speakInactiveTitleColor,
        toolbarTypeActiveTitleColor: props.configuration.styles?.toolbar?.typeActiveTitleColor,
        toolbarTypeInactiveTitleColor: props.configuration.styles?.toolbar?.typeInactiveTitleColor,
        toolbarPartialSpeechResultTextColor: props.configuration.styles?.toolbar?.partialSpeechResultTextColor,
        toolbarFullSpeechResultTextColor: props.configuration.styles?.toolbar?.fullSpeechResultTextColor,
        toolbarSpeechResultBoxBackgroundColor: props.configuration.styles?.toolbar?.speechResultBoxBackgroundColor,
        toolbarTextInputTextColor: props.configuration.styles?.toolbar?.textInputTextColor,
        toolbarHelpTextFontColor: props.configuration.styles?.toolbar?.helpTextFontColor,
        toolbarPartialSpeechResultFontFamily: props.configuration.styles?.toolbar?.partialSpeechResultFontFamily,
        toolbarAssistantStateFontFamily: props.configuration.styles?.toolbar?.assistantStateFontFamily,
        toolbarHelpTextFontFamily: props.configuration.styles?.toolbar?.helpTextFontFamily,
        toolbarSpeakFontFamily: props.configuration.styles?.toolbar?.speakFontFamily,
        toolbarTypeFontFamily: props.configuration.styles?.toolbar?.typeFontFamily,
        toolbarTextboxFontFamily: props.configuration.styles?.toolbar?.textboxFontFamily,
        toolbarEqualizerColor: props.configuration.styles?.toolbar?.equalizerColor,
        toolbarEqualizerColorOne: props.configuration.styles?.toolbar?.equalizerColorOne,
        toolbarEqualizerColorTwo: props.configuration.styles?.toolbar?.equalizerColorTwo,
        toolbarMuteFontColor: props.configuration.styles?.toolbar?.muteFontColor,
        toolbarMuteFontFamily: props.configuration.styles?.toolbar?.muteFontFamily,
        toolbarMicActiveColor: props.configuration.styles?.toolbar?.micActiveColor,
        toolbarMicActiveImage: props.configuration.styles?.toolbar?.micActiveImage ?? "https://voicify-prod-files.s3.amazonaws.com/sdk-images/micThreeActive.svg",
        toolbarSendActiveImage: props.configuration.styles?.toolbar?.sendActiveImage ?? "https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendTwoActive.svg",
        toolbarSendActiveColor: props.configuration.styles?.toolbar?.sendActiveColor,
        toolbarSendInactiveColor: props.configuration.styles?.toolbar?.sendInactiveColor,
        toolbarMuteImage: props.configuration.styles?.toolbar?.muteImage,
        toolbarAssistantStateFontColor: props.configuration.styles?.toolbar?.assistantStateFontColor,
        toolbarAssistantStateTextColor: props.configuration.styles?.toolbar?.assistantStateTextColor,
        toolbarTextInputLineColor: props.configuration.styles?.toolbar?.textInputLineColor,
        toolbarTextInputActiveLineColor: props.configuration.styles?.toolbar?.textInputActiveLineColor,
        toolbarPlaceholder: props.configuration.styles?.toolbar?.placeholder,
        // Start Styles
        startAssistantImage: props.configuration.styles?.start?.assistantImage,
        startButtonFontFamily: props.configuration.styles?.start?.buttonFontFamily,
        startButtonText: props.configuration.styles?.start?.buttonText,
        startButtonFontSize: props.configuration.styles?.start?.buttonFontSize,
    }),
    validationSchema: yup.object().shape({
        name: yup.string().max(50).required("You must enter a name"),
        primaryColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/, "Enter a valid hex color code"),
        buttonFontSize: yup.number().min(0, "Must be a number more than 0"),
        overallBackgroundColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/, "Enter a valid hex color code"),
        headerBackgroundColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/, "Enter a valid hex color code"),
        headerAssistantNameTextColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/, "Enter a valid hex color code"),
        bodyBackgroundColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/, "Enter a valid hex color code"),
        toolbarBackgroundColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/, "Enter a valid hex color code"),
        toolbarFontColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/, "Enter a valid hex color code"),
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);

        const platformConfigurations: PlatformConfigurations = {
            javaScript: {
                ...props.browserAssistantSettings,
                applicationId: props.browserAssistantSettings?.appId,
                applicationSecret: props.browserAssistantSettings?.appKey,
                name: values.name,
                avatarUrl: values.avatarUrl,
                displayName: values.displayName,
                textToSpeechVoice: props.browserAssistantSettings?.textToSpeechVoice,
                theme: values.themeStyle,
                showConversationHistory: values.showConversationHistory,
                styles: {
                    assistant: {
                        ...props.browserAssistantSettings?.styles?.assistant
                    },
                    header: {
                        ...props.browserAssistantSettings?.styles?.header
                    },
                    body: {
                        ...props.browserAssistantSettings?.styles?.body
                    },
                    toolbar: {
                        ...props.browserAssistantSettings?.styles?.toolbar
                    },
                    start: {
                        ...props.browserAssistantSettings?.styles?.start
                    }
                },
            },
            kotlin: {
                ...props.kotlinAssistantSettings?.assistantSettings,
                applicationId: props.kotlinAssistantSettings?.assistantSettings?.appId,
                applicationSecret: props.kotlinAssistantSettings?.assistantSettings?.appKey,
                activeInput: props.kotlinAssistantSettings?.assistantSettings?.initializeWithText ? "textbox" : "none",
                name: values.name,
                styles: {
                    assistant: {
                        ...props.kotlinAssistantSettings?.assistantSettings
                    },
                    header: {
                        ...props.kotlinAssistantSettings?.headerProps
                    },
                    body: {
                        ...props.kotlinAssistantSettings?.bodyProps
                    },
                    toolbar: {
                        ...props.kotlinAssistantSettings?.toolbarProps
                    },
                }
            },
            swift: {
                ...props.swiftAssistantSettings?.assistantSettings,
                serverRootUrl: props.swiftAssistantSettings?.assistantSettings?.serverRootUrl,
                applicationId: props.swiftAssistantSettings?.assistantSettings?.appId,
                applicationSecret: props.swiftAssistantSettings?.assistantSettings?.appKey,
                activeInput: props.swiftAssistantSettings?.assistantSettings?.initializeWithText ? "textbox" : "none",
                name: values.name,
                styles: {
                    assistant: {
                        ...props.swiftAssistantSettings?.assistantSettings
                    },
                    header: {
                        ...props.swiftAssistantSettings?.headerProps
                    },
                    body: {
                        ...props.swiftAssistantSettings?.bodyProps
                    },
                    toolbar: {
                        ...props.swiftAssistantSettings?.toolbarProps
                    }
                }
            }
        };

        const customAssistantConfiguration: CustomAssistantConfiguration = {
            id: props.configId,
            serverRootUrl: values.serverRootUrl,
            applicationId: values.appId,
            applicationSecret: values.appKey,
            locale: values.locale,
            channel: values.channel,
            device: values.device,
            autoRunConversation: values.autoRunConversation,
            initializeWithWelcomeMessage: values.initializeWithWelcomeMessage,
            textToSpeechProvider: values.textToSpeechProvider,
            useVoiceInput: values.useVoiceInput,
            useOutputSpeech: values.useOutputSpeech,
            useDraftContent: values.useDraftContent,
            noTracking: values.noTracking,
            sessionTimeout: values.sessionTimeout,
            uiType: values.uiType,
            activeInput: values.activeInput,
            name: values.name,
            avatarUrl: values.avatarUrl,
            displayName: values.displayName,
            textToSpeechVoice: values.textToSpeechVoice,
            theme: values.themeStyle,
            primaryColor: values.primaryColor,
            font: values.font,
            showConversationHistory: values.showConversationHistory,
            platformConfigurations: platformConfigurations,
            styles: {
                assistant: {
                    backgroundColor: values.overallBackgroundColor
                },
                header: {
                    fontFamily: values.headerFontFamily,
                    assistantNameTextColor: values.headerAssistantNameTextColor,
                    assistantImage: values.headerAssistantImage,
                    assistantName: values.headerAssistantName,
                    backgroundColor: values.headerBackgroundColor,
                    closeAssistantColor: values.headerCloseAssistantColor,
                    minimizeAssistantColor: values.headerMinimizeAssistantColor,
                    closeAssistantButtonImage: values.headerCloseAssistantButtonImage,
                    minimizeIcon: values.headerMinimizeIcon
                },
                body: {
                    assistantImage: values.bodyAssistantImage,
                    backgroundColor: values.bodyBackgroundColor,
                    borderBottomColor: values.overallBorderColor,
                    borderTopColor: values.overallBorderColor,
                    messageSentBackgroundColor: values.bodyMessageSentBackgroundColor,
                    messageSentFontFamily: values.bodyMessageSentFontFamily,
                    messageReceivedFontFamily: values.bodyMessageReceivedFontFamily,
                    hintsFontFamily: values.bodyHintsFontFamily,
                    messageSentTextColor: values.bodyMessageSentTextColor,
                    messageReceivedTextColor: values.bodyMessageReceivedTextColor,
                    messageReceivedBackgroundColor: values.bodyMessageReceivedBackgroundColor,
                    hintsTextColor: values.bodyHintsTextColor,
                    hintsBackgroundColor: values.bodyHintsBackgroundColor,
                },
                toolbar: {
                    assistantStateTextColor: values.toolbarAssistantStateTextColor,
                    assistantStateFontFamily: values.toolbarAssistantStateFontFamily,
                    helpTextFontFamily: values.toolbarHelpTextFontFamily,
                    muteFontFamily: values.toolbarMuteFontFamily,
                    partialSpeechResultFontFamily: values.toolbarPartialSpeechResultFontFamily,
                    speakFontFamily: values.toolbarSpeakFontFamily,
                    typeFontFamily: values.toolbarTypeFontFamily,
                    textboxFontFamily: values.toolbarTextboxFontFamily,
                    speechResultBoxBackgroundColor: values.toolbarSpeechResultBoxBackgroundColor,
                    micActiveHighlightColor: values.toolbarMicActiveHighlightColor,
                    speakActiveTitleColor: values.toolbarSpeakActiveTitleColor,
                    typeActiveTitleColor: values.toolbarTypeActiveTitleColor,
                    backgroundColor: values.toolbarBackgroundColor,
                    micActiveImage: values.toolbarMicActiveImage,
                    micInactiveImage: values.toolbarMicActiveImage,
                    sendActiveImage: values.toolbarSendActiveImage,
                    sendInactiveImage: values.toolbarSendActiveImage,
                    assistantStateFontColor: values.toolbarAssistantStateFontColor,
                    helpTextFontColor: values.toolbarHelpTextFontColor,
                    speakInactiveTitleColor: values.toolbarSpeakInactiveTitleColor,
                    typeInactiveTitleColor: values.toolbarTypeInactiveTitleColor,
                    muteFontColor: values.toolbarMuteFontColor,
                    placeholder: values.toolbarPlaceholder,
                    textInputLineColor: values.toolbarTextInputLineColor,
                    textInputActiveLineColor: values.toolbarTextInputActiveLineColor,
                    equalizerColor: values.toolbarEqualizerColor,
                    equalizerColorOne: values.toolbarEqualizerColorOne,
                    equalizerColorTwo: values.toolbarEqualizerColorTwo,
                    partialSpeechResultTextColor: values.toolbarPartialSpeechResultTextColor,
                    fullSpeechResultTextColor: values.toolbarFullSpeechResultTextColor,
                    textInputTextColor: values.toolbarTextInputTextColor,
                    sendActiveColor: values.toolbarSendActiveColor,
                    sendInactiveColor: values.toolbarSendInactiveColor,
                    muteImage: values.toolbarMuteImage,
                    micActiveColor: values.toolbarMicActiveColor
                },
                start: {
                    assistantImage: values.startAssistantImage,
                    buttonBackgroundColor: values.startButtonBackgroundColor,
                    buttonText: values.startButtonText,
                    buttonFontFamily: values.startButtonFontFamily,
                    buttonFontSize: values.startButtonFontSize,
                }
            },
        };
        props.handleSubmitForm(customAssistantConfiguration);
        setSubmitting(false)
        props.closeCustomConfigForm()
    }
})(CustomAssistantConfigurations);

export default CustomAssistantConfigurationsForm;