import React from 'react';
import { css } from '@emotion/css';
import PanelContainer from '../../components/structure/Panels/PanelContainer';
import CollapsingPanel from '../../components/structure/Panels/CollapsingPanel';
import CollapsedMenuPanel from '../../components/structure/CollapsedMenuPanel';
import TabContainerPanel from '../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import { color_shades_dark, color_variants_main_bg } from '../../constants/colors';
import ExpandedMenuPanel from '../../components/structure/ExpandedMenuPanel';
import PanelTitle from './components/PanelTitle';
import TabPanelBody from '../../components/structure/TabPanelBody';
import { Subscribe } from 'unstated';
const previewIcon = require('../../content/images/tab-icons/preview.svg');
const previewBlueIcon = require('../../content/images/tab-icons/preview-blue.svg');
const treeIcon = require('../../content/images/tab-icons/conv-tree.svg');
const treeIconBlue = require('../../content/images/tab-icons/conv-tree-blue.svg');
const connectionsIcon = require('../../content/images/tab-icons/connections.svg');
const connectionsIconBlue = require('../../content/images/tab-icons/connections-blue.svg');
interface ContentPagePanelProps {
    treeRender: () => JSX.Element
    relatedContentRender: () => JSX.Element
    formPanelRender: () => JSX.Element
    flowPanelRender: () => JSX.Element
    // note: when we add more areas for all content pages like related content tab, we can add the render here
}
interface ContentPagePanelState {
    isLeftExpanded: boolean
    selectedMenuIndex: number
    selectedRightIndex: number
}

class ContentPagePanel extends React.Component<ContentPagePanelProps, ContentPagePanelState> {
    constructor(props) {
        super(props);
        this.state = {
            isLeftExpanded: localStorage.getItem("contentMenu") == "true",
            selectedMenuIndex: 0,
            selectedRightIndex: 0
        }
    }
    handleToggleExpandLeft(e, expanded: boolean) {
        this.setState({
            ...this.state,
            isLeftExpanded: expanded
        });
        localStorage.setItem("contentMenu", expanded?.toString());
        e?.stopPropagation();
    }
    setContentMenuIndex(index: number) {
        this.setState({
            ...this.state,
            selectedMenuIndex: index
        });
    }
    setRightMenuIndex(index: number) {
        this.setState({
            ...this.state,
            selectedRightIndex: index
        });
    }
    renderMenu() {
        return <TabContainerPanel tabs={[
            {
                title: 'Conversation',
                icon: treeIcon,
                selectedIcon: treeIconBlue,
                className: 'ac-content-menu-tab'
            }, {
                title: 'Connections',
                icon: connectionsIcon,
                selectedIcon: connectionsIconBlue,
                className: 'ac-connections-menu-tab'
            }
        ]}
            onTabSelected={(newIndex) => { this.setContentMenuIndex(newIndex) }}
            selectedIndex={this.state.selectedMenuIndex}>
            <TabPanel>
                <ScrollablePanelBody className={panelBodyStyle}>
                    {this.props.treeRender()}
                </ScrollablePanelBody>
            </TabPanel>
            <TabPanel>
                <ScrollablePanelBody className={panelBodyStyle}>
                    {this.props.relatedContentRender()}
                </ScrollablePanelBody>
            </TabPanel>
        </TabContainerPanel>
    }
    render() {
        const props = this.props;
        return (

            <PanelContainer className={containerStyle}>
                <div className={`${menuStyle} ${(this.state.isLeftExpanded ? "expanded" : "collapsed")} ac-content-item-menu`}>
                    <div className={"fixed-wrapper"}>
                        <CollapsingPanel isCollapsed={!this.state.isLeftExpanded}
                            collapsedView={<CollapsedMenuPanel
                                onExpand={(e) => this.handleToggleExpandLeft(e, true)}
                                expandedViewRender={this.renderMenu.bind(this)} />}
                            expandedView={<ExpandedMenuPanel
                                onCollapse={(e) => this.handleToggleExpandLeft(e, false)}
                                expandedViewRender={this.renderMenu.bind(this)} />} />
                    </div>
                </div>
                <PanelContainer className={formBodyStyle}>
                    {props.formPanelRender()}
                </PanelContainer>
                <TabContainerPanel tabs={[
                    {
                        title: 'Preview',
                        icon: previewIcon,
                        selectedIcon: previewBlueIcon,
                        className: "ac-content-preview-tab"
                    }
                ]}
                    onTabSelected={(newIndex) => { this.setRightMenuIndex(newIndex) }}
                    selectedIndex={this.state.selectedRightIndex}>
                    <TabPanel>
                        <ScrollablePanelBody className={flowBodyStyle}>
                            <PanelTitle title="Conversation Preview" detail="Here’s a preview of how the conversation between the user and device could go with the content you filled in." />
                            {props.flowPanelRender()}
                        </ScrollablePanelBody>
                    </TabPanel>
                </TabContainerPanel>
            </PanelContainer>

        );
    }
}

const containerStyle = css`
    background: ${color_variants_main_bg};
`

const formBodyStyle = css`
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    .scrollable-body {       
        padding-bottom: 56px;
    }
`

const flowBodyStyle = css`
    padding-top: 0;
    min-height: 100%;
    padding-bottom: 56px;
`

const panelBodyStyle = css`
    min-height: 100%;
    padding-top: 0;
`

const menuStyle = css`
z-index: 2;
bottom: 0;
position: relative;

&.collapsed {
    width: 80px;
    .fixed-wrapper {
        width: 80px;
    }
}
&.expanded {
    width: 288px; 
    .fixed-wrapper {
        width: 288px;
    }
}

.fixed-wrapper {
    position: fixed;
    bottom: 0;
    height: calc(100vh - 80px);
    background: white;
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
}
`

export default ContentPagePanel;