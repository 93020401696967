import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';
const icon = require('../../content/images/incomplete-content.svg');

const IncompleteIndicatorInverse: React.SFC = (props) => (
    <div className={"incomplete-indicator " + containerStyle}>
        <span>Incomplete</span>
        <img src={icon}/>
    </div>
);

const containerStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    img {
        margin-left: 8px;
    }
    span {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: ${dark_grey_blue};
    }    
`

export default IncompleteIndicatorInverse;