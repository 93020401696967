import React from 'react';
import { css } from '@emotion/css';
import CollapsingPanel from '../../../components/structure/Panels/CollapsingPanel';
import { color_shades_dark } from '../../../constants/colors';
import ExpandedWebhookSidePanel from './ExpandedWebhookSidePanel';
import { TreeItem } from '../../../components/structure/NestedTree/NestedTree';
import CollapsedWebhookSidePanel from './CollapsedWebhookSidePanel';
import WebhookFolderContainer from '../../../state/containers/WebhookFolderContainer';
import WebhookContainer from '../../../state/containers/WebhookContainer';

interface WebhooksSidePanelProps {
    history: any
    isOpen: boolean
    toggleView: () => void
    webhookFolderContainer: WebhookFolderContainer
    webhookContainer: WebhookContainer
    applicationId: string
}

class WebhooksSidePanel extends React.Component<WebhooksSidePanelProps> {
    onToggleCollapse(e) {
        e.stopPropagation();
        this.props.toggleView();
    }
    render() {
        return (
            <div className={containerStyle + (this.props.isOpen ? " expanded" : " collapsed")}>
                <div className={"fixed-wrapper"}>
                    <CollapsingPanel isCollapsed={!this.props.isOpen}
                        collapsedView={
                            <CollapsedWebhookSidePanel history={this.props.history}
                                webhookFolderContainer={this.props.webhookFolderContainer}
                                onExpand={this.onToggleCollapse.bind(this)}
                                webhookContainer={this.props.webhookContainer}
                                applicationId={this.props.applicationId}/>
                        }
                        expandedView={
                            <ExpandedWebhookSidePanel history={this.props.history}
                                webhookFolderContainer={this.props.webhookFolderContainer}
                                onCollapse={this.onToggleCollapse.bind(this)}
                                webhookContainer={this.props.webhookContainer}
                                showCollapseButton
                                applicationId={this.props.applicationId}/>
                        } 
                    />
                </div>
            </div>
        )
    }
}

const containerStyle = css`
    z-index: 2;
    bottom: 0;
    position: relative;

    &.collapsed {
        width: 80px;
        .fixed-wrapper {
            width: 80px;
        }
    }
    &.expanded {
        width: 288px; 
        .fixed-wrapper {
            width: 288px;
        }
    }

    .fixed-wrapper {
        position: fixed;
        top: 80px;
        bottom: 0;
        height: calc(100vh - 80px);
        background: white;
        border-right: 1px solid ${color_shades_dark};
        box-sizing: border-box;
    }
`;

export default WebhooksSidePanel;