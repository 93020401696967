import { css } from '@emotion/css';
import React from "react";
import ConversationHistory from "../../../models/sessionAttributes/ConversationHistory";
import SimulatorConversationHistoryItem from "./SimulatorConversationHistoryItem";
import ContentItemModel from "../../../models/features/api/ContentItemModel";
import SimulatorHistoryContentItem from '../../../models/features/api/SimulatorHistoryContentItem';

interface SimulatorConversationHistoryProps {
    conversationHistory: ConversationHistory
    contentItems: SimulatorHistoryContentItem[]
}

const SimulatorConversationHistory: React.SFC<SimulatorConversationHistoryProps> = props => 
(
    <div className={containerStyle}>
        {props.contentItems?.map((c, i) => {
            return <SimulatorConversationHistoryItem key={i} turn={props.conversationHistory?.TurnHistory?.find(h => h.ContentId === c.id)} content={c} />
        })}
    </div>
)

const containerStyle = css`
    display:flex;
    padding: 24px;
    padding-bottom: 56px;
    flex-direction: column;
`;

export default SimulatorConversationHistory;