import React from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import TemplateSelector from './TemplateSelector';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
interface NavigationDrawerProps {
    currentTemplateConfigId?: string
    templateConfigContainer: TemplateConfigurationContainer
}
class TemplateConfigurationTemplateMenu extends React.Component<NavigationDrawerProps> {

    render() {
        return (
          <div className={containerStyle}>
              <TemplateSelector 
                templates={this.props.templateConfigContainer.state.organizationTemplates} 
                activeTemplate={this.props.templateConfigContainer.state.organizationTemplates.find(at => at.id == this.props.currentTemplateConfigId)}
                />
          </div>
        )
    }
}

const containerStyle = css`

`

export default TemplateConfigurationTemplateMenu;