import React, { useState } from 'react';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import { Link } from 'react-router-dom';
import ApplicationListItem from './ApplicationListItem';
import { color_colors_ocean } from '../../../constants/colors';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styled from '@emotion/styled';

const appsIcon = require('../../../content/images/content-tree-icons/app-level/all-apps.svg');
const arrowIcon = require('../../../content/images/content-tree-icons/app-level/long-arrow-right.svg');
const addIcon = require('../../../content/images/content-tree-icons/app-level/add-circle-white.svg');

interface ApplicationSelectorProps {
    application: ApplicationModel
    appContainer: ApplicationContainer
    organizationId: string
}

const ApplicationSelector: React.FC<ApplicationSelectorProps> = (props) => {
    const [loadingApps, setLoadingApps] = useState(false);
    const [hasMoreApps, setHasMoreApps] = useState(true);
    const [page, setPage] = useState(1);
    const [apps, setApps] = useState([] as ApplicationModel[])

    const loadMoreApps = async () => {
        setLoadingApps(true);
        var result = await props.appContainer.searchForApplications(props.organizationId, page, 30, "NameAscending", "");
        setPage(page + 1);
        if (result.resultType == "Ok") {
            setApps([...apps, ...result.data.applications]);
            if (result.data.applications.length > 29)
                setHasMoreApps(true);
            else
                setHasMoreApps(false);
        }
        else {
            setHasMoreApps(false);
        }
        setLoadingApps(false);
    };

    const [scrollingRef] = useInfiniteScroll({
        loading: loadingApps,
        hasNextPage: hasMoreApps,
        onLoadMore: loadMoreApps,
        // `rootMargin` is passed to `IntersectionObserver`.
        // We can use it to trigger 'onLoadMore' when the sentry comes near to become
        // visible, instead of becoming fully visible on the screen.
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <ApplicationSelectorWrapper>
            <Link to="/v" className="all-apps-section">
                <img className="start" src={appsIcon} />
                <p>All Apps</p>
                <img className="end" src={arrowIcon} />
            </Link>
            <CustomScrollbars autoHide className="apps-container">
                {apps?.map(a => (
                    <ApplicationListItem key={a.id} application={a} isSelected={props.application?.id == a.id} />
                ))}
                <div ref={scrollingRef} />
            </CustomScrollbars>
            <Link to="/v/create-app" className="add-app-section">
                <img className="start" src={addIcon} />
                <p>Add a New App</p>
            </Link>
        </ApplicationSelectorWrapper>
    )
}

const ApplicationSelectorWrapper = styled.div`
    background: linear-gradient(0deg, ${color_colors_ocean} 0%, #36C4F2 100%, #2BA5D8 100%);
    margin-top: 80px;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    max-height: calc(100% - 80px);
    width:288px;
    z-index: 3;from {
        height: 0px;
    }
    to {
        height:calc(100% - 48px)
    }
    .start {
        margin-right: 16px;
        width: 24px;
        height: 24px;
        margin-left: 4px;
    }
    .all-apps-section {
        display: flex;
        align-items: center;
        border-bottom: 1px solid white;
        height: 64px;
        padding: 0 24px;
        .end {
            margin-right: 0;
            margin-left: auto;
        }
        a, p {
            font-family: Muli;
            font-style: italic;
            font-size: 14px;
            line-height: 20px;
            color: white;
        }
    }
    .apps-container {
        flex: 1;
    }
    .add-app-section {
        margin-bottom: 0;
        margin-top: auto;
        display: flex;
        align-items: center;
        margin: 0 24px;
        height: 64px;
        border-top: 1px solid white;
        a, p {
            font-family: Muli;
            font-style: italic;
            font-size: 14px;
            line-height: 20px;
            color: white;
        }
    }
`;

export default ApplicationSelector;