import React from 'react';
import { css } from '@emotion/css';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import SelectField from '../../../components/forms/SelectField';
import TextField from '../../../components/forms/TextField';
import CheckboxField from '../../../components/forms/CheckboxField';
import FieldError from '../../../components/forms/FieldError';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import FollowUpModel from '../../../models/features/api/FollowUp/FollowUpModel';
import FollowUpContainer from '../../../state/containers/FollowUpContainer';
import DetailLabel from '../../../components/general/DetailLabel';
import { dark_grey_blue, cool_grey } from '../../../constants/colors';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';
import Button from '../../../components/general/Button';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import FollowUpManager from '../../followUpManager';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import SsmlParseService from '../../../services/ssmlParseService';
const followUpIcon = require('../../../content/images/compass.svg');

interface ManageImplicitFollowUpFormProps {
    applicationId: string
    followUp?: FollowUpModel
    followUpContainer: FollowUpContainer
    onChange?: (followUp: FollowUpModel) => void
    onManage?: () => void
}
class ManageImplicitFollowUpForm extends React.Component<ManageImplicitFollowUpFormProps> {

    componentWillMount() {
        this.props.followUpContainer.getFollowUpForApp(this.props.applicationId);
    }
    handleSelectionChange(e) {
        const finalValue = e?.value;
        var followUp: FollowUpModel = null;
        if (finalValue == "new") {
            followUp = {
                content: '',
                followUpHints: []
            }
        }
        else if (finalValue && finalValue.length > 0) {
            followUp = this.props.followUpContainer.state.followUps.find(f => f.id == finalValue)
        }
        if (this.props.onChange) this.props.onChange(followUp);
    }
    getSelectedFollowUp() {
        if (this.props.followUp) {
            if (this.props.followUp.name) {
                return this.props.followUp.id;
            }
            else {
                return 'new';
            }
        }
        return '';
    }
    handleFollowUpTextChange(ssml: string) {
        const followUp = this.props.followUp;
        followUp.content = ssml;
        this.props.onChange(followUp);
    }

    handleDuplicate(followUp: FollowUpModel) {
        this.props.onChange({
            content: followUp.content,
            followUpHints: followUp.followUpHints.map(f => ({
                content: f.content
            }))
        })
    }
    render() {
        if (this.props.followUpContainer.state.isLoading) {
            return <SecondaryLoader />
        }
        var ssmlParser = new SsmlParseService();
        return (
            <div className={containerStyle}>
                <p className="detail">Select an existing follow-up configuration or create a new, custom one</p>
                <FormFieldsContainer>
                    <SelectField options={[{ value: 'new', label: '- Custom / New -' }, ...this.props.followUpContainer.state.followUps.filter(f => f.name != undefined).map(f => ({ value: f.id, label: f.name }))]}
                        value={this.getSelectedFollowUp()}
                        onBlur={() => { }}
                        onChange={this.handleSelectionChange.bind(this)}
                        useFormTheme={true}
                        label="Select a follow-up:"
                        placeholder="- None -"
                        clearValueText="- None -"
                        clearable={false}
                        name="existingFollowUp" />
                    {this.props.followUp ?
                        <>
                            <p className="header sub-header"><i>Follow-Up:</i> {this.props.followUp.name ? this.props.followUp.name : "Untitled"}</p>

                            <HorizontalSeparator />
                            <div className="inner-wrapper" id="guidance">
                                <img className="inner-wrapper-img" src={followUpIcon} />
                                <div>
                                    <p className="header">Guidance</p>
                                    <p className="detail">Prompt the user with a question or statement and guide them to the next conversation</p>

                                    {this.props.followUp.name ?
                                        <div>
                                            <p className="label">Follow-up Language</p>
                                            <p className="preview"><i>{ssmlParser.stripTags(this.props.followUp.content)}</i></p>
                                        </div>
                                        : <SsmlField placeholder="What should the device say?"
                                            value={this.props.followUp ? this.props.followUp.content : ''}
                                            label="Follow-up Language:"
                                            applicationId={this.props.applicationId}
                                            onChange={this.handleFollowUpTextChange.bind(this)}
                                            onBlur={() => { }}
                                            className="follow-up-field"
                                            name="followUpContent" />}

                                    <FieldError name="followUp" />



                                </div>
                            </div>
                            {this.props.children}
                            <HorizontalSeparator />
                            {this.getSelectedFollowUp() == "new" ?
                                <Button themes={["hollow", "flat"]} text="Save as..." type="button" onClick={this.props.onManage} />
                                : <HorizontalContainer className="fill">
                                    <Button themes={["hollow", "flat"]} text="Edit" type="button" disabled={!this.props.followUp} onClick={this.props.onManage} />
                                    <div style={{ marginLeft: 'auto', marginRight: 0 }}>
                                        <Button themes={["hollow", "flat"]} text="Duplicate" type="button" disabled={!this.props.followUp} onClick={this.handleDuplicate.bind(this, this.props.followUp)} />
                                    </div>
                                </HorizontalContainer>
                            }
                        </>
                        : null}

                </FormFieldsContainer>
            </div>
        )
    }
}
const containerStyle = css`
.detail {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: ${cool_grey};
    margin-right: 16px;
} .header {
    font-family: Muli;
    font-size: 18px;
    color: ${dark_grey_blue};
}
.sub-header {
    margin-top: 16px;
    margin-bottom: 16px;
}
.inner-wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
    .inner-wrapper-img {
        margin-right: 16px;
    }

    >div {
        flex: 1;
    }

    .check-container {
        display: flex;
        align-items: center;
        margin: 24px 0;
    }

    .label {
        margin: 16px 0;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
    }
    .preview {
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
    }
}
.follow-up-field {
    margin-top: 16px;
}
`
export default ManageImplicitFollowUpForm;