import React from 'react';
import TextField from '../../components/forms/TextField';
import ContentCard from '../../components/structure/ContentCard';
import PageContainer from '../../components/structure/PageContainer';
import FormFieldsContainer from '../../components/structure/FormFieldsContainer';
import LatestMessageContainer from '../../state/containers/LatestMessageContainer';
import HorizontalFormFieldsContainer from '../../components/structure/HorizontalFormFieldsContainer';
import LatestMessageModel from '../../models/features/api/LatestMessages/LatestMessageModel';
import moment from 'moment';
import TitleTextField from '../../components/forms/TitleTextField';
import FollowUpWithRepromptForm, { FollowUpWithRepromptFormData, FollowUpWithRepromptFormProps } from '../followUpForm';
import ConversationFlow from '../conversationFlow';
import ConversationItemFormProps from '../../models/features/ConversationItemFormProps';
import Loader from '../../components/general/Loader';
import IResult from '../../models/result/IResult';
import { LatestMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import ContentLanguageMultiSelector from '../../components/forms/ContentLanguageMultiSelector';
import ContentPagePanel from '../contentPage';
import RelatedContentMenu from '../relatedContentMenu';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import PanelTitle from '../contentPage/components/PanelTitle';
import ContentCollapsingSection from '../../components/structure/ContentCollapsingSection';
import ContentSectionTitle from '../contentPage/components/ContentSectionTitle';
import { getFeatureTypeIcon } from '../../models/extensions';
import { TreeItem } from '../../components/structure/NestedTree/NestedTree';
import PageError from '../../components/general/PageError';
import ConversationItemActionButtonToolbar from './components/ConversationItemActionButtonToolbar';
import TemplateLockedBanner from '../../components/general/TemplateLockedBanner';
import ConversationItemFieldSet from '../../components/forms/ConversationItemFieldSet';
import ContentInfoOptions from '../../components/structure/ContentInfoOptions';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';
import LatestMessageResponseModel from '../../models/features/api/LatestMessages/LatestMessageResponseModel';
import { ContentItemPageState } from './BaseConversationItemForm';
import ResponseGroup from '../../models/features/ResponseGroup';
import ResponsesManager from '../responsesManager';
import { DirtyFormPrompt } from './components/DirtyFormPrompt';
import SensitiveDataOptions from '../contentPage/components/SensitiveDataOptions';

const userIcon = require('../../content/images/content-tree-icons/content-level/end-user-avatar.svg');
const contentInfoIcon = require('../../content/images/content-tree-icons/content-level/task-list-pin.svg');
const inputGroupIcon = require('../../content/images/content-tree-icons/content-level/input-stack.svg');
const inputIcon = require('../../content/images/content-tree-icons/content-level/input-single.svg');

export interface LatestMessageContentFormData extends FollowUpWithRepromptFormData {
    title: string
    category: string
    startDate: string
    endDate: string
    responses: LatestMessageResponseModel[]
    webhookChainingType?: WebhookChainingType
}
export interface LatestMessageContentFormProps extends FollowUpWithRepromptFormProps<LatestMessageModel>, ConversationItemFormProps<LatestMessageModel> {
    stateContainer: LatestMessageContainer
    latestMessage?: LatestMessageModel
}

class LatestMessageForm extends FollowUpWithRepromptForm<LatestMessageModel, LatestMessageContentFormProps, LatestMessageContentFormData, ContentItemPageState> {
    handleAsyncSubmit(onSuccess: (result: IResult<LatestMessageModel>) => void) {
        if (this.manualValidate())
            return this.props.onSubmit(this.props.stateContainer.mapFormDataToModel(this.props.latestMessage, this.props.values), onSuccess);
    }
    handleDiscardChanges() {
        this.handleFinish();
    }
    getPlaceholderCategoryName() {
        return this.props.values.category === '' ? "{Category}" : this.props.values.category;
    }
    handleDelete() {
        const confirmed = confirm("Are you sure you want to delete this?");
        if (confirmed) {
            this.props.stateContainer.delete(this.props.latestMessage.id).then(() => {
                this.handleFinish();
            });
        }
    }

    handleCopy() {
        this.handleAsyncSubmit(() => {
            this.props.stateContainer.copy(this.props.latestMessage.id).then(r2 => {
                if (r2.resultType == "Ok") {
                    this.props.history.push(`/v/${r2.data.applicationFeatureId}/latestmessages/${r2.data.id}`);
                }
            });
        });
    }

    getMenuTreeRoots() {
        var roots: TreeItem[] = [this.getContentInfoTreeItem(), {
            id: 'user-says',
            title: 'User-Says',
            isExpanded: this.isItemExpanded('user-says'),
            canExpand: true,
            icon: userIcon,
            linkTo: "#userSays",
            children: [{
                id: 'phrases',
                title: 'Input Options',
                canExpand: true,
                isExpanded: this.isItemExpanded('phrases'),
                icon: inputGroupIcon,
                linkTo: "#phrases",
                children: [{
                    id: 'category',
                    title: 'Category',
                    canExpand: false,
                    icon: inputIcon,
                    linkTo: "#category"
                }, {
                    id: 'startEnd',
                    title: 'Start/End Date',
                    canExpand: false,
                    icon: inputIcon,
                    linkTo: "#startEnd"
                },]
            }]
        },
        ...this.getResponsesTreeItems(this.props.values.responses)]

        return roots;
    }
    getContentItem() {
        return this.props.latestMessage;
    }
    handleContentItemOptionSelected(option: string, event: any) {
        event.stopPropagation();
        if (option == "Manage Webhooks") {
            this.showWebhookInstanceManager();
        }
        else if (option == "Duplicate") {
            this.handleCopy();
        }
        else if (option == "Delete") {
            this.handleDelete();
        }
    }
    handleResponsesChange(responseGroups: ResponseGroup[]) {
        this.props.setFieldValue("responses", this.getResponsesFromGroups(responseGroups));
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.stateContainer.state.isSaving;
        const isDisabled = isLoading || this.props.isTemplateOwned;
        const responseGroups = this.getResponseGroups(this.props.values.responses);
        if (this.props.responseStateContainer.state.isLoading || !this.props.application) {
            return <Loader />
        }
        return (
            <>
                <form onSubmit={props.handleSubmit}>
                    <DirtyFormPrompt allowPrompt={props.status?.submitting != true}/>
                    <ContentPagePanel
                        relatedContentRender={() => (<RelatedContentMenu
                            parentContentId={this.props.previousContentId}
                            conversationItem={{ ...this.props.latestMessage, featureTypeId: LatestMessagesFeatureTypeId }}
                            history={this.props.history}
                            featureTypeId={LatestMessagesFeatureTypeId}
                            followUp={responseGroups?.[0]?.firstResponse?.followUp}
                            contentId={this.props.latestMessage?.id}
                            onClose={this.props.onToggleNavigation}
                            onSave={this.handleAsyncSubmit.bind(this)} />)
                        }
                        treeRender={this.renderTree.bind(this)}
                        formPanelRender={() => (
                            <PageContainer fill>
                                <ConversationItemActionButtonToolbar
                                    errors={Object.values(props.errors)}
                                    validateForm={props.validateForm}
                                    publishDisabled={this.props.assistantApplication == null}
                                    saveDisabled={ Object.keys(props.errors).length > 0}
                                    contentItem={props.latestMessage}
                                    isLoading={isLoading}
                                    handleDiscardChanges={this.handleDiscardChanges.bind(this)}
                                    handlePublishing={this.handleSaveAndPublish.bind(this)}
                                    submitForm={props.submitForm}
                                />
                                {props.latestMessage?.createdFromId && <TemplateLockedBanner isLoading={props.stateContainer.state.isTogglingSync} isLinked={props.isTemplateOwned} onRelink={this.handleToggleRelinkModal.bind(this)} onUnlink={() => this.props.onToggleSync(this.props.latestMessage.id, true)} />}

                                <ConversationItemFieldSet disabled={isDisabled} withBanner={props.latestMessage?.createdFromId != null}>
                                    <ScrollablePanelBody className="scrollable-body">
                                        <PanelTitle title="Content Editing"
                                            detail="Here’s where you can fill in the info about this content and determine the course of the conversation by filling in the relevant details.">
                                        </PanelTitle>
                                        <ContentCollapsingSection title="Content Info" id="contentInfo" icon={contentInfoIcon} titleRightRowComponent={
                                            <ContentInfoOptions webhookCount={this.props.stateContainer.state.webhookUpdates?.webhookInstances?.length ?? this.props.stateContainer.state.attachedWebhooks?.length ?? 0}
                                                options={this.getOptions(this.props.latestMessage != null)}
                                                optionSelected={this.handleContentItemOptionSelected.bind(this)}
                                                manageWebhooks={this.showWebhookInstanceManager.bind(this)}
                                                showSensitiveConversation={this.state.showSensitiveConversation}
                                                toggleSensitiveConversation={() => { this.toggleSensitiveConversation(); }} />
                                        }>
                                            {this.state.showSensitiveConversation &&
                                                <SensitiveDataOptions
                                                    selectedOption={props.values.sensitiveConversationChange ?? "None"}
                                                    onChange={(value) => { props.setFieldValue("sensitiveConversationChange", value) }}
                                                />
                                            }
                                            <ContentSectionTitle title="Latest Message Conversation" icon={getFeatureTypeIcon(LatestMessagesFeatureTypeId, true)} />
                                            <TitleTextField id="contentTitle" disabled={isDisabled} name="title" placeholder="For display only" value={props.values.title} label="Title" onChange={props.handleChange} onBlur={props.handleBlur} />
                                            <ContentLanguageMultiSelector id="contentLanguage" disabled={isDisabled} label="Language / Region" languages={props.application.languages} selectedLanguageIds={props.values.languages.map(l => l.id)} onChange={this.handleLanguagesChange.bind(this)} />
                                            {this.renderOptions(false, isDisabled)}
                                        </ContentCollapsingSection>
                                        <ContentCollapsingSection title="User Says" icon={userIcon} id="userSays">
                                            <ContentCard title="Phrases" icon={inputGroupIcon} id="phrases">
                                                <FormFieldsContainer>
                                                    <TextField id="category" disabled={isDisabled} required name="category" value={props.values.category} label="Category" placeholder="Enter the category this message belongs to" onChange={props.handleChange} onBlur={props.handleBlur} />
                                                    <HorizontalFormFieldsContainer>
                                                        <TextField id="startEnd" disabled={isDisabled} required name="startDate" type="date" value={props.values.startDate} label="Effective Start Date" onChange={props.handleChange} onBlur={props.handleBlur} />
                                                        <TextField disabled={isDisabled} required name="endDate" type="date" value={props.values.endDate} label="Effect End Date" onChange={props.handleChange} onBlur={props.handleBlur} />
                                                    </HorizontalFormFieldsContainer>
                                                </FormFieldsContainer>
                                            </ContentCard>

                                        </ContentCollapsingSection>
                                       
                                        <ResponsesManager applicationId={this.props.application.id}
                                            applicationFeatureId={this.props.applicationFeatureId}
                                            languages={this.props.values.languages}
                                            responseGroups={responseGroups}
                                            getConditionsKey={(c) => this.getConditionsKey(c)}
                                            isDisabled={isDisabled}
                                            onChange={this.handleResponsesChange.bind(this)}
                                            onBlur={this.props.handleBlur} />
                                    </ScrollablePanelBody>
                                </ConversationItemFieldSet>
                                <PageError errors={this.props?.stateContainer?.state?.errors ?? []} onClear={this.handleClearError.bind(this)} />
                            </PageContainer>
                        )}
                        flowPanelRender={() => (
                            <ConversationFlow
                                applicationId={this.props.application.id}
                                key={this.props.values.responseContainer ? this.props.values.responseContainer.id : ''}
                                endSession={!this.props.values.responses?.some(r => r.followUp != null)}
                                languages={this.props.values.languages}
                                conversationItems={[{
                                    requests: [
                                        { content: `What's new with ${this.getPlaceholderCategoryName()}?`, highlights: [this.getPlaceholderCategoryName()], id: '1' },
                                        { content: `What's the latest from ${this.getPlaceholderCategoryName()}?`, highlights: [this.getPlaceholderCategoryName()], id: '2' },
                                        { content: `What's the daily message from ${this.getPlaceholderCategoryName()}?`, highlights: [this.getPlaceholderCategoryName()], id: '3' },
                                        { content: `What was the message from ${this.getPlaceholderCategoryName()} on ${moment(props.values.startDate).format('MMMM Do')}?`, highlights: [this.getPlaceholderCategoryName(), moment(props.values.startDate).format('MMMM Do')], id: '4' },
                                    ],
                                    requestTitle: "User Question",
                                    children: this.getPreviewResponses(props.values.responses)
                                }]} />

                        )}>
                    </ContentPagePanel>
                </form>
                {this.renderRelinkSyncWarning()}
                {this.renderWebhookInstanceManager(this.props.application.id, this.props.latestMessage?.id, LatestMessagesFeatureTypeId, this.props.values?.webhookChainingType)}
            </>
        )
    }
}
export default LatestMessageForm;