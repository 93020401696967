import { withFormik } from "formik";
import * as yup from 'yup';
import HelpMessageForm, { EditHelpMessageFormProps, EditHelpMessageFormData } from '../../formScenes/HelpMessageForm';

const EditHelpMessageForm = withFormik<EditHelpMessageFormProps, EditHelpMessageFormData>({
    mapPropsToValues: props => ({
        title: props.helpMessage.title,
        requiresParent: props.helpMessage.requiresParent,
        responses: props.helpMessage.responses.length > 0 ? props.helpMessage.responses : [{ content: '' }],
        languages: props.helpMessage.languages,
        isLive: props.helpMessage.isLive,
        webhookChainingType: props.helpMessage.webhookChainingType,
        sensitiveConversationChange: props.helpMessage.sensitiveConversationChange ?? "None",
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter an response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.helpMessage, values));
    }
})(HelpMessageForm);

export default EditHelpMessageForm;