import React from 'react';
import SortedTable from '../../../components/structure/SortedTable';
import ApiKeyContainer from '../../../state/containers/ApiKeyContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import _ from 'lodash';
import TableHeader from '../../../components/general/TableHeader';
import ApiKeyModel from '../../../models/auth/api/ApiKeyModel';
import TableCell from '../../../components/general/TableCell';
import moment from 'moment';
import { css } from '@emotion/css';
import { color_colors_decline, color_colors_gold, color_colors_gold_background, color_shades_darkest, silver_two } from '../../../constants/colors';
import UserAvatar from '../../../components/general/UserAvatar';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import MemberContainer from '../../../state/containers/MemberContainer';
import styled from '@emotion/styled';
import Loader from '../../../components/general/Loader';
const deleteIcon = require('../../../content/images/content-explorer/delete.svg');
const alertCircle = require('../../../content/images/alert-circle.svg');
const noApiKeys = require('../../../content/images/organization-settings/no-api-keys.svg');
const infoCircle = require('../../../content/images/info-circle-yellow.svg');
const noPermissionApiKeys = require('../../../content/images/organization-settings/no-permission-api-keys.svg');

interface ApiKeySettingsProps {
    apiKeyContainer: ApiKeyContainer,
    orgContainer: OrganizationContainer,
    isOrgAdmin: boolean
}

interface ApiKeySettingsState {
    deleteApiKey: ApiKeyModel,
}

class ApiKeySettings extends React.Component<ApiKeySettingsProps, ApiKeySettingsState> {
    constructor(props) {
        super(props);
        this.state = {
            deleteApiKey: null,
        }
    }
    async componentDidMount() {
        await this.props.apiKeyContainer.loadOrganizationApiKeys(this.props.orgContainer.state.currentOrganization.id);

    }
    async showDeleteModal(apiKeyModel: ApiKeyModel) {
        await this.setState({
            ...this.state,
            deleteApiKey: apiKeyModel
        });
    }
    async finishDeleteModal() {
        await this.props.apiKeyContainer.deleteApiKey(this.state.deleteApiKey.id);
        await this.props.apiKeyContainer.loadOrganizationApiKeys(this.props.orgContainer.state.currentOrganization.id);
        this.setState({
            ...this.state,
            deleteApiKey: null
        });
    }
    async closeDeleteModal() {
        await this.setState({
            ...this.state,
            deleteApiKey: null
        });
    }
    render() {
        const apiKeys = _.orderBy(this.props.apiKeyContainer.state.apiKeys, a => a.expirationDate, "asc");
        const columns = [
            {
                id: "name",
                Header: <TableHeader text="NAME" />,
                accessor: (m: ApiKeyModel) => m,
                Cell: row => (
                    <TableCell className={apiKeyCellStyle}>
                        <span>
                            {row.value.name}
                        </span>
                    </TableCell>
                )
            },
            {
                id: "expiration",
                Header: <TableHeader text='EXPIRES' />,
                accessor: (m: ApiKeyModel) => m,
                Cell: row => (
                    <TableCell className={`${expirationDateStyle} ${(row.value.expirationDate != null && moment().isAfter(row.value.expirationDate)) ? "expired" : ''}`}>
                        <HorizontalContainer>
                            {
                                row.value.expirationDate == null ?
                                    "Never" :
                                    moment(row.value.expirationDate).format("MM/DD/YYYY")
                            }
                            {
                                (row.value.expirationDate != null && moment().isAfter(row.value.expirationDate)) && <img src={alertCircle} />
                            }

                        </HorizontalContainer>
                    </TableCell>
                )
            },
            {
                id: "created-by",
                Header: <TableHeader text='CREATED BY' />,
                accessor: (m: ApiKeyModel) => m,
                Cell: row => (
                    <TableCell className={apiKeyCellStyle}>
                        {row.value.createdByUserModel?.lastName &&
                            <HorizontalContainer>
                                <UserAvatar noBackground lastName={row.value.createdByUserModel.lastName} firstName={row.value.createdByUserModel.firstName} src={row.value.createdByUserModel.imageUrl} size={32} />
                                <div className={createdByUserName}>{`${row.value.createdByUserModel.firstName} ${row.value.createdByUserModel.lastName}`}</div>
                            </HorizontalContainer>
                        }
                    </TableCell>
                )
            },
            {
                id: "delete",
                Header: <TableHeader text='' />,
                accessor: (m: ApiKeyModel) => m,
                width: 50,
                Cell: row => (
                    <TableCell className={apiKeyCellStyle}>
                        <img onClick={() => this.showDeleteModal(row.value)} className={deleteStyle} src={deleteIcon} />
                    </TableCell>
                )
            }
        ]
        return (
            <div className={containerStyle}>
                <CustomScrollbars autoHide>
                    <div className="api-key-header border-header">
                        <MainHeader>Manage your API Keys</MainHeader>
                        <SubHeader>API Keys can be used to access Voicify's API's to extend apps in this organization using the integrations framework.</SubHeader>
                        <SubHeader>For more information on our integrations framework and how to use these keys check out our knowledge center <a target="_blank" href="https://support.voicify.com/knowledge/how-do-i-create-api-keys">here.</a></SubHeader>
                        <div className={warningCss}>
                            <img src={infoCircle} />
                            <SubHeader>Keys generated here will have access to your organization. <b>Keep them secure.</b></SubHeader>
                        </div>
                    </div>
                    {
                        this.props.isOrgAdmin ?
                            (apiKeys && apiKeys.length > 0) ?
                                <div className={tableContainer}>
                                    <SortedTable noDataText="No API Keys generated yet" className={tableStyle} columns={columns} data={apiKeys} />
                                </div>
                                :
                                <>
                                    <img className={fullWidthImage} src={noApiKeys} />
                                    <NoKeysText>No API Keys have been generated yet</NoKeysText>
                                </>
                            :
                            <>
                                <img className={fullWidthImage} src={noPermissionApiKeys} />
                                <NoKeysText>Only Organization Administrators have access to API Keys</NoKeysText>
                            </>
                    }
                    {this.state.deleteApiKey &&
                        <ConfirmationDialog title="Delete" deleteText="Yes, Delete"
                            isLoading={this.props.apiKeyContainer.state.isLoading}
                            onClose={this.closeDeleteModal.bind(this)}
                            onConfirm={this.finishDeleteModal.bind(this)}>
                            <DeleteConfirmation
                                itemLabel={`the ${this.state.deleteApiKey.name} API Key? This will immediately revoke its access to Voicify API's.`} />

                        </ConfirmationDialog>
                    }

                </CustomScrollbars>
            </div>
        )
    }
}

const expirationDateStyle = css`
    &.expired {
        color: ${color_colors_decline};
    }
    img {
        margin-left: 8px;
        margin-top: -2px;
    }
`;

const NoKeysText = styled.p(`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${color_shades_darkest};
`);

const tableContainer = css`
    margin-bottom: 250px;
`;

const fullWidthImage = css`
    width: 100%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
`;

const warningCss = css`
    width: 100%;
    border: 1px solid ${color_colors_gold};
    background: ${color_colors_gold_background};
    display: flex;
    border-radius: 8px;
    padding: 16px 24px;
    margin-top: 16px;
    img {
        margin-right: 12px;
    }
`;

const containerStyle = css`
    font-style: normal;
    font-weight: normal;
    .api-key-header {
        padding: 32px;
    }
    .border-header {
        border-bottom: solid 1px ${silver_two};
    }
`;

const MainHeader = styled.h2(`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
`);

const SubHeader = styled.h3(`
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
`);

const apiKeyCellStyle = css`
    overflow: hidden;
`;

const createdByUserName = css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
`;

const deleteStyle = css`
    cursor: pointer;
`;

const tableStyle = css`
    border: none;
    .rt-noData {
        margin-top: 30px;
    }
`;

export default ApiKeySettings;