import React, { FormEventHandler, FormEvent } from 'react';
import {Form} from 'formik';
import {css} from '@emotion/css';

const containerStyle = css`
    padding-bottom: 90px;
`
const FormContainer: React.SFC = (props) => (
    <div className={containerStyle}>
        {props.children}
    </div>
);

export default FormContainer;