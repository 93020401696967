import React from 'react';
import moment from 'moment';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import FeatureFilters from '../../../models/features/FeatureFilters';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import ApplicationFeatureModel from '../../../models/features/api/ApplicationFeatureModel';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import { css } from '@emotion/css';
import { BreadcrumbItem } from '../../../components/general/Breadcrumb';
import DisablingFilters from '../../../models/features/DisablingFilters';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import ModalLayout from '../../../components/structure/ModalLayout';
import WebhookFolderContainer from '../../../state/containers/WebhookFolderContainer';
import WebhookFolderModel from '../../../models/webhooks/api/WebhookFolderModel';
import FullScreenLayout from '../../fullScreenLayout';
import PageContainer from '../../../components/structure/PageContainer';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import ModalTitle from '../../../components/general/ModalTitle';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import WebhookToolbar from './WebhookToolbar';
import WebhookList from './WebhookList';
const closeIcon = require('../../../content/images/close_icon.svg');

interface WebhookFolderSelectorProps {
    withNesting: boolean
    applicationId: string
    appContainer: ApplicationContainer
    webhookFolderContainer: WebhookFolderContainer
    initialFolderId?: string
    disabledFolderIds?: string[]
    onFinish?: (selectedIds:string[]) => void
    onClose?: () => void
    title: string
}

interface WebhookFolderSelectorState {
    selectedFolderIds: string[]
    folderHistory: WebhookFolderModel[]
    currentFolderId?: string
    isAddingNewFolder: boolean
    selectionText?: string
}

class WebhookFolderSelector extends React.Component<WebhookFolderSelectorProps, WebhookFolderSelectorState> {
    constructor(props) {
        super(props);
        this.state = {
            currentFolderId: props.initialFolderId,
            isAddingNewFolder: false,
            selectedFolderIds: [], // default select the root when selecting a folder
            folderHistory: [],
            selectionText: '',
        }
    }
    componentDidMount() {
        this.setState({
            ...this.state,
            selectedFolderIds: [this.props.initialFolderId]
        });
    }
    handleStartAddNewFolder() {
        this.setState({
            ...this.state,
            isAddingNewFolder: true
        })
    }
    handleAddNewFeature(text: string, parentFolderId: string) {
        this.props.webhookFolderContainer.createFolder(this.props.applicationId, text, parentFolderId).then(result => {
            if (result.resultType == "Ok")
                this.setState({
                    ...this.state,
                    isAddingNewFolder: false
                })
        });
    }
    getBreadCrumbItems() {
        var ancestors = this.props.webhookFolderContainer.getAllAncestors(this.state.currentFolderId);

        var crumbs: BreadcrumbItem[] = ancestors?.reverse()?.map(f => ({
            id: f?.id,
            title: f?.name ?? 'Untitled',
            link: `#`,
            highlighted: this.state.selectedFolderIds[0] == f?.id
        }))
        const isHomeHighlighted = this.state.selectedFolderIds[0] == null;
        crumbs = [{id: null, title: "Home", link: `#`, highlighted: isHomeHighlighted}, ...crumbs];

        return crumbs;
    }
    getFolders(parentFolderId: string) {
        return this.props.webhookFolderContainer.state.webhookFolders.filter(wf => wf.parentId == parentFolderId);
    }
    handleFolderSelectionChange(applicationFeature: ApplicationFeatureModel, isSelected: boolean) {
        var selectedIds = this.state.selectedFolderIds;
        if (isSelected) {
            selectedIds = [applicationFeature?.id]
        }
        this.setState({
            ...this.state,
            selectedFolderIds: selectedIds
        })
    }
    handleClearSelections() {
        this.setState({
            ...this.state,
            selectedFolderIds: []
        })
    }

    handleFolderClick(folder: WebhookFolderModel) {
        const history = this.state.folderHistory;
        history.push(folder);
        this.setState({
            ...this.state,
            currentFolderId: folder?.id,
            folderHistory: history,
            selectedFolderIds: [folder.id]
        })
    }

    handleFinishSelection() {
        this.props.onFinish(this.state.selectedFolderIds);
    }
    handleBreadcrumbClick(item: BreadcrumbItem) {
        this.setState({
            ...this.state,
            currentFolderId: item.id
        })
    }
    render() {
        const folder = this.props.webhookFolderContainer.findFolderById(this.state.currentFolderId);
        const application = this.props.appContainer.state.currentApp;
        return (
            <ModalLayout isVisible={true} onClose={this.props.onClose}>
                <FullScreenLayout className={containerStyle}>
                    <PageContainer fill>
                        <HorizontalContainer className="header-wrapper">
                            <ModalTitle>{this.props.title}</ModalTitle>
                            <Button className="close-icon" themes={['end-tight', 'icon']} icon={closeIcon} onClick={this.props.onClose} />
                        </HorizontalContainer>
                        <HorizontalSeparator />
                        <div className={containerStyle}>
                            <WebhookToolbar
                                showImport={false}
                                showNewWebhook={false}
                                onImport={null}
                                showNewFolder={false}
                                onAddNewWebhook={null}
                                onAddNewFolder={this.handleStartAddNewFolder.bind(this)}
                                groupSelectionState={'none'}
                                onBreadCrumbItemClick={this.handleBreadcrumbClick.bind(this)}
                                breadCrumbs={this.getBreadCrumbItems()}
                                history={null}/>
                            <CustomScrollbars autoHide className="scrollable-container">
                                <WebhookList 
                                    webhookFolderContainer={this.props.webhookFolderContainer}
                                    currentFolderId={this.state.currentFolderId}
                                    folders={this.getFolders(this.state.currentFolderId)}
                                    onWebhookClick={null}
                                    webhooks={[]}
                                    applicationId={this.props.applicationId}
                                    selectedWebhookIds={[]}
                                    handleCreateNewFolder={null}
                                    disabledFolderIds={this.props.disabledFolderIds}
                                    selectedFolderIds={this.state.selectedFolderIds}
                                    onFolderClick={this.handleFolderClick.bind(this)}
                                    onWebhookSelectionChange={null}
                                    onFolderSelectionChange={this.handleFolderSelectionChange.bind(this)}
                                    showContentItems={false}
                                    selectableContentItems={false}
                                    selectableFeatures
                                    isSingleSelect
                                    showWebhookCount={false}
                                    withNesting={false}
                                    isAddingNewFolder={this.state.isAddingNewFolder}
                                    droppableId={"webhooks_modal"}
                                />
                            </CustomScrollbars>
                            <FooterBar small>
                                <Button themes={["primary"]} text="Select" type="button"
                                    loading={this.props.webhookFolderContainer.state.isLoading}
                                    disabled={this.props.webhookFolderContainer.state.isLoading || this.state.selectedFolderIds.length < 1}
                                    onClick={this.handleFinishSelection.bind(this)} />
                            </FooterBar>
                        </div>
                    </PageContainer>
                </FullScreenLayout>
            </ModalLayout>            

        )
    }
}

const containerStyle = css`
    height: 100%;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;

    h2 {
        margin-left: 32px;
    }

    .close-icon {
        margin-right: 32px;
        margin-top: 28px;
    }
`

export default WebhookFolderSelector