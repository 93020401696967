import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark } from '../../../constants/colors';
import TextField from '../../../components/forms/TextField';
import TemplateFormFieldOptionModel from '../../../models/templating/api/TemplateFormFieldOptionModel';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
import Button from '../../../components/general/Button';
const deleteIcon = require('../../../content/images/menu-item-icons/bin.svg');
const upIcon = require('../../../content/images/menu-item-icons/navigation-up-circle.svg');
const downIcon = require('../../../content/images/menu-item-icons/navigation-down-circle.svg');

interface OptionsFieldEditorProps {
    option: TemplateFormFieldOptionModel
    title: string
    onChange: (option: TemplateFormFieldOptionModel) => void
    onDelete: (option: TemplateFormFieldOptionModel) => void
    onMoveUp: (option: TemplateFormFieldOptionModel) => void
    onMoveDown: (option: TemplateFormFieldOptionModel) => void
}
interface OptionsFieldEditorState {
    isDeleteConfirmationOpen: boolean
}

class FieldOptionEditor extends React.Component<OptionsFieldEditorProps, OptionsFieldEditorState> {
    constructor(props) {
        super(props);
        this.state = {
            isDeleteConfirmationOpen: false
        }
    }
    handleToggleDelete() {
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: !this.state.isDeleteConfirmationOpen
        })
    }
    handleConfirmDelete() {
        this.props.onDelete(this.props.option);
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: false
        })
    }

    handleOptionChange(fieldName: keyof TemplateFormFieldOptionModel, value) {
        const option = this.props.option;
        (option[fieldName] as any) = value;
        this.props.onChange(option);
    }
    render() {
        const { option, title } = this.props;
        return (
            <div className={containerStyle}>
                <div className="option-title-container">
                    <p>{title}</p>
                    <div className="option-title-buttons">
                        <Button type="button" themes={['icon']} icon={downIcon} onClick={() => this.props.onMoveDown(option)} />
                        <Button type="button" themes={['icon']} icon={upIcon} onClick={() => this.props.onMoveUp(option)} />
                        <Button type="button" themes={['icon']} icon={deleteIcon} onClick={this.handleToggleDelete.bind(this)} />
                    </div>
                </div>
                <TextField name="option.label" value={option.label ?? ''} placeholder="Enter a value for display" label="Option Label" onChange={e => this.handleOptionChange("label", e.target.value)} />
                <TextField name="option.value" value={option.value ?? ''} placeholder="Enter a value for the variable" label="Option value" onChange={e => this.handleOptionChange("value", e.target.value)} />

                {this.state.isDeleteConfirmationOpen &&
                    <ConfirmationDialog title={`Delete Option`}
                        deleteText="Yes, Delete"
                        isLoading={false}
                        onClose={this.handleToggleDelete.bind(this)}
                        onConfirm={this.handleConfirmDelete.bind(this)}>
                        <DeleteConfirmation itemLabel={option?.label ?? "Option?"} />
                    </ConfirmationDialog>}
            </div>
        );
    }
}


const containerStyle = css`
    border-top: 1px solid ${color_shades_dark};
    padding-top: 24px;
    margin-top: 48px;

    .option-title-container {
        display: flex;
        align-items: center;
        margin-top: -36px;
        margin-bottom: 24px;
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            background: white;
            margin-left: 16px;
            padding: 0 8px;
        }
        .option-title-buttons {
            margin-right: 16px;
            margin-left: auto;
            display: flex;
            align-items: center;
            background: white;

            button {
                margin: 0 4px;
            }
        }
    }
   
`;

export default FieldOptionEditor;