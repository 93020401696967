import React from 'react';
import styled from '@emotion/styled';
import ResponseFlagRuleModel from '../../../models/features/api/Flags/ResponseFlagRuleModel';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import Button from '../../../components/general/Button';
import { css } from '@emotion/css';
import { color_shades_dark, color_shades_darkest, color_text_light } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import _ from 'lodash';
import EffectModel from '../../../models/features/api/Effects/EffectModel';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { reorder } from '../../../models/extensions';
import { getEffectLabel } from './EffectManager';
const deleteIcon = require('../../../content/images/condition-icons/delete.svg');
const moveableIcon = require('../../../content/images/moveable-icon.svg');
const effectIcon = require('../../../content/images/content-item-icons/effect.svg');

interface ResponseEffectsListProps {
    effects: EffectModel[]
    onAddEffect: () => void
    onRemoveEffect: (effect: EffectModel) => void
    onClickEffect: (effect: EffectModel) => void
    updateEffects: (effects: EffectModel[]) => void
}

const ResponseEffectsList: React.FC<ResponseEffectsListProps> = ({
    effects,
    onAddEffect,
    onRemoveEffect,
    updateEffects,
    onClickEffect
}) => {
    const renderEffects = _.orderBy(effects, ['priority']);
    const handleDragEnd = (result: DropResult, provider: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const orderedEffects = _.sortBy(effects ?? [], s => s.priority)
        const newEffects = reorder(orderedEffects, result.source.index, result.destination.index)
        newEffects.forEach((e, i) => {
            e.priority = i;
        })
        updateEffects(newEffects);
    }
    return (
        <>
            <DetailLabel>Here you can add or modify effects triggered by this response.</DetailLabel>
            
            {
                renderEffects?.length > 0
                    ? <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId={"sections"} type="TEMPLATE_SECTION">
                            {
                                (provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {_.sortBy(effects ?? [], s => s.priority)?.map((e, i) => (
                                            <Draggable draggableId={`${e.name}${e.id}`} index={i} key={`${e.name}${e.id}`}>
                                                {(provided, snapshot) => (
                                                    <EffectListItem
                                                        ref={provided.innerRef}
                                                        onClick={() => onClickEffect(e)}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <DraggableIcon src={moveableIcon} />
                                                        <Priority><span>{(e.priority ?? 0) + 1}</span></Priority>
                                                        <img src={effectIcon} />
                                                        <p>{getEffectLabel(e?.name)}</p>
                                                        <button className="remove-button" type="button" onClick={(effect) => {effect.stopPropagation(); onRemoveEffect(e); }}>                                                            
                                                            <img src={deleteIcon} />
                                                        </button>
                                                    </EffectListItem>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>
                    </DragDropContext>
                    : <NoEffectsContainer><p>No effects configured</p></NoEffectsContainer>
            }
            <HorizontalSeparator />
            <Button themes={['secondary']} className={addButtonStyle} type="button" onClick={() => onAddEffect()} text="Add / Remove an Effect" />
        </>
    )
}

const DetailLabel = styled.p(`
    margin: 16px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color_text_light};
`)
const EffectListItem = styled.li(`
    height: 52px;
    border: ${style_border_default};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 8px;
    background: white;
    img {
        max-height: 24px;
        max-width: 24px;
        &.session-icon {
            width: 20px;
            height: 20px;
        }
    }
    p {
        margin: 0 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
    }
    .remove-button {
        border: none;
        background: none;
        box-shadow: none;
        padding: 0;
        margin-right: 0;
        margin-left: auto;
    cursor: pointer;
    }
`)
const NoEffectsContainer = styled.div(`
    background: white;
    border: ${style_border_default};
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 24px;
    p {
        color: ${color_text_light};
        font-style: italic;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
`)
const addButtonStyle = css`
    margin: 24px 0;
    width: 100%;
    cursor: pointer;
`
const DraggableIcon = styled.img`    
    cursor: grab;
    margin-right: 8px;
    height: 24px;
    width: 10px;
`;
const Priority = styled.div`
    border: 1px solid ${color_shades_darkest};
    color: ${color_shades_darkest};
    border-radius: 50%;
    margin-right: 12px;
    height: 24px;
    width: 24px;
    span {
        padding-left: 7px;
    }
`;
export default ResponseEffectsList;