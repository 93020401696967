import { css } from '@emotion/css';
import React from 'react';
import { cool_grey, dark_grey_blue, dark_grey_blue_20, dark_sky_blue, highlight_blue } from '../../constants/colors';
import ConversationMediaResponse from '../../models/conversationFlow/ConversationMediaResponse';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import LanguageModel from '../../models/features/api/LanguageModel';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';
import RepromptModel from '../../models/features/api/Reprompt/RepromptModel';
import SsmlCacheItem from '../../models/ssml/SsmlCacheItem';
import SsmlContainer from '../../state/containers/SsmlContainer';
import PageError from '../general/PageError';
import PositionIndicator from '../structure/ConversationFlow/PositionIndicator';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import AlexaAudioPlayerPreview from './AlexaRectangleScreens/AlexaAudioPlayerPreview';
import AlexaBody1TemplatePreview from './AlexaRectangleScreens/AlexaBodyTemplate1Preview';
import AlexaBody2TemplatePreview from './AlexaRectangleScreens/AlexaBodyTemplate2Preview';
import AlexaBody3TemplatePreview from './AlexaRectangleScreens/AlexaBodyTemplate3Preview';
import AlexaBody6TemplatePreview from './AlexaRectangleScreens/AlexaBodyTemplate6Preview';
import AlexaBody7TemplatePreview from './AlexaRectangleScreens/AlexaBodyTemplate7Preview';
import AlexaListTemplate2Preview from './AlexaRectangleScreens/AlexaListTemplate2Preview';
import AlexaVideoPreview from './AlexaRectangleScreens/AlexaVideoPreview';
import AlexaSmallAudioPlayerPreview from './AlexaRoundScreens/AlexaSmallAudioPlayerPreview';
import AlexaSmallBody1TemplatePreview from './AlexaRoundScreens/AlexaSmallBodyTemplate1Preview';
import AlexaSmallBody2TemplatePreview from './AlexaRoundScreens/AlexaSmallBodyTemplate2Preview';
import AlexaSmallBody3TemplatePreview from './AlexaRoundScreens/AlexaSmallBodyTemplate3Preview';
import AlexaSmallBody6TemplatePreview from './AlexaRoundScreens/AlexaSmallBodyTemplate6Preview';
import AlexaSmallBody7TemplatePreview from './AlexaRoundScreens/AlexaSmallBodyTemplate7Preview';
import AlexaSmallListTemplate2Preview from './AlexaRoundScreens/AlexaSmallListTemplate2Preview';
import AlexaSmallVideoPreview from './AlexaRoundScreens/AlexaSmallVideoPreview';
import AlexaShortAudioPlayerPreview from './AlexaShortRectangleScreens/AlexaShortAudioPlayerPreview';
import AlexaShortBody1TemplatePreview from './AlexaShortRectangleScreens/AlexaShortBodyTemplate1Preview';
import AlexaShortBody2TemplatePreview from './AlexaShortRectangleScreens/AlexaShortBodyTemplate2Preview';
import AlexaShortBody3TemplatePreview from './AlexaShortRectangleScreens/AlexaShortBodyTemplate3Preview';
import AlexaShortBody6TemplatePreview from './AlexaShortRectangleScreens/AlexaShortBodyTemplate6Preview';
import AlexaShortBody7TemplatePreview from './AlexaShortRectangleScreens/AlexaShortBodyTemplate7Preview';
import AlexaShortListTemplate2Preview from './AlexaShortRectangleScreens/AlexaShortListTemplate2Preview';
import AlexaShortVideoPreview from './AlexaShortRectangleScreens/AlexaShortVideoPreview';
import CortanaScreenListPreview from './CortanaScreens/CortanaScreenListPreview';
import CortanaScreenMediaResponsePreview from './CortanaScreens/CortanaScreenMediaResponsePreview';
import CortanaScreenSimpleCardPreview from './CortanaScreens/CortanaScreenSimpleCardPreview';
import CortanaScreenStandardCardPreview from './CortanaScreens/CortanaScreenStandardCardPreview';
import GoogleMobileListPreview from './GoogleMobileScreens/GoogleMobileListPreview';
import GoogleMobileMediaResponsePreview from './GoogleMobileScreens/GoogleMobileMediaResponsePreview';
import GoogleMobileSimpleCardPreview from './GoogleMobileScreens/GoogleMobileSimpleCardPreview';
import GoogleMobileStandardCardPreview from './GoogleMobileScreens/GoogleMobileStandardCardPreview';
import GoogleListPreview from './GoogleSmartScreens/GoogleListPreview';
import GoogleMediaResponsePreview from './GoogleSmartScreens/GoogleMediaResponsePreview';
import GoogleSimpleCardPreview from './GoogleSmartScreens/GoogleSimpleCardPreview';
import GoogleStandardCardPreview from './GoogleSmartScreens/GoogleStandardCardPreview';
import MultiFileAudioPlayer from './MultiFileAudioPlayer';
import DOMPurify from 'dompurify';

const deviceIcon = require('../../content/images/device.svg');

const containerStyle = css`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
    }
`
const bubbleStyle = css`
    margin: 30px 0 0 115px;
    border: solid 1px ${dark_sky_blue};
    box-shadow: -1px 4px 10px 0 ${dark_grey_blue_20};
    background: white;
    border-radius: 10px 0 10px 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media only screen and (min-width: 1240px) {
        max-width: 60%;
        margin-right: 0;
        margin-left: auto;
    }
    .title {
        font-family: Muli;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 24px;
    }

    .open-quote {
        margin-left: 0;
        margin-right: auto;
        margin-bottom: 16px;
        margin-top: 16px;
    }

    .close-quote {
        margin-right: 0;
        margin-left: auto;
        margin-bottom: 16px;
        margin-top: 0;
    }

    .placeholder {
        text-align: center;
        font-style: italic;
        color: ${cool_grey};
    }
    .carousel-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 16px;
        span {
            margin-bottom: 8px;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            text-align: center;
            color: ${cool_grey};
        }
    }
`;

const inputStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    margin-top: 12px;
    span {
        background: ${highlight_blue};
    }
`;

const unsupportedDevice = css`
    padding: 16px;
`

interface DeviceVariationsBubbleProps {
    variations: ConversationMediaResponse[]
    title?: string
    detail?: string
    followUp?: FollowUpModel
    reprompt?: RepromptModel
    ssmlContainer: SsmlContainer
    deviceTarget: DeviceTargetModel
    languages?: LanguageModel[]
}
interface DeviceVariationsBubbleState {
    position: number
    audioResponses: SsmlCacheItem[]
}


class DeviceScreenVariationsBubble extends React.Component<DeviceVariationsBubbleProps, DeviceVariationsBubbleState> {

    componentWillMount() {
        this.setState({
            position: 0,
            audioResponses: []
        });
    }

    getSsmlContent(conversationResponse: ConversationMediaResponse) {
        var customResponse = conversationResponse.mediaResponseContainer
            ? conversationResponse.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTarget.id && r.readoutOverride != null)
            : null;
        if (customResponse) return customResponse.readoutOverride;

        return conversationResponse.content;
    }

    buildLabel(item: ConversationMediaResponse) {
        // strip tags from ssml first
        const ssml = this.getVariationSsml(item, false);
        const stripTagRegex = /<\/?[^>]+(>|$)/g
        var displayText = ssml;
        displayText = displayText.replace(stripTagRegex, "");
        item.highlights.forEach(highlight => {
            displayText = displayText.replace(highlight.replace(stripTagRegex, ""), `<span>${highlight}</span>`);
        });

        return (
            <p className={inputStyle} key={item.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayText) }}>
            </p>
        )
    }

    private getVariationSsml(item: ConversationMediaResponse, includeFollowUp: boolean): string {
        var ssml = item.content;
        var customResponse = item.mediaResponseContainer
            ? item.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTarget.id && r.readoutOverride != null)
            : null;
        if (customResponse) ssml = customResponse.readoutOverride

        if (includeFollowUp && this.props.followUp) {
            ssml += `<break time=".5s"/> ${this.props.followUp.content}`;
        }

        return ssml;
    }

    loadSsmlAudio(item: ConversationMediaResponse) {
        const innerSsml = this.getVariationSsml(item, true);
        var ssml = innerSsml.startsWith('<speak>') ? innerSsml : `<speak>${innerSsml}</speak>`;
        var locale = (this.props.languages == null || this.props.languages.length < 1) ? "" : this.props.languages[0].shortCode;

        if (this.state.audioResponses.some(a => a.ssml == ssml && a.assistant == this.props.deviceTarget.assistant))
            return;
        var cachedAudio = this.props.ssmlContainer.getCachedSsmlSpeech(ssml, this.props.deviceTarget.assistant, locale);
        if (cachedAudio) {
            var responses = this.state.audioResponses;
            responses.push({ ssml: ssml, assistant: this.props.deviceTarget.assistant, outputAudioFiles: cachedAudio, locale: locale });
            this.setState({
                ...this.state,
                audioResponses: responses
            })
        }
        else {
            this.props.ssmlContainer.getSpeechOutput(ssml, this.props.deviceTarget.assistant, locale).then(result => {
                if (result.resultType == "Ok") {
                    var responses = this.state.audioResponses;
                    responses.push({ ssml: ssml, assistant: this.props.deviceTarget.assistant, outputAudioFiles: result.data, locale: locale });
                    this.setState({
                        ...this.state,
                        audioResponses: responses
                    })
                }
            })
        }
    }

    handlePositionChange(position: number) {
        this.setState({
            ...this.state,
            position
        })
    }

    getAudioSrc(variation: ConversationMediaResponse): string[] {
        // check for audio player
        var customResponse = variation.mediaResponseContainer
            ? variation.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTarget.id)
            : null;
        if (customResponse != null && customResponse.audioItem) {
            return [customResponse.audioItem.url];
        }
        else if (variation.audioItem) {
            return [variation.audioItem.url]
        }
        var innerSsml = this.getVariationSsml(variation, true);
        var ssml = innerSsml.startsWith('<speak>') ? innerSsml : `<speak>${innerSsml}</speak>`;
        var audioSrc = this.state.audioResponses.find(a => a.ssml == ssml && a.assistant == this.props.deviceTarget.assistant);
        if (!audioSrc || !audioSrc.outputAudioFiles) return null;
        return audioSrc.outputAudioFiles.map(s => s.url);
    }

    stripTags(ssml: string) {
        const stripTagRegex = /<\/?[^>]+(>|$)/g
        var displayText = ssml;
        displayText = displayText.replace(stripTagRegex, "");
        return displayText;
    }
    hasAudio(): boolean {
        const variation = this.props.variations[this.state.position];
        if (!variation) return false;
        const customResponse = variation.mediaResponseContainer
            ? variation.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTarget.id && r.readoutOverride != null)
            : null;
        if (customResponse != null && customResponse.videoItem) {
            return false;
        }
        if (!customResponse && variation.videoItem && this.props.deviceTarget.assistant == "Alexa") {
            return false;
        }

        return true;
    }
    renderDisplayPreview() {
        if (this.props.variations.length == 0) return null;
        const response = this.props.variations[this.state.position];
        const mediaResponse = response.mediaResponseContainer
            ? response.mediaResponseContainer.responses.find(r => r.deviceTargetId == this.props.deviceTarget.id)
            : null;

        // temp: need to fill in the others with a switch
        if (this.props.deviceTarget.assistant == "Alexa" && !this.props.deviceTarget.displayName.includes("Small")) {
            if (mediaResponse) {
                switch (mediaResponse.responseTemplateType.nativeName) {
                    case "BodyTemplate1":
                        return <AlexaBody1TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate2":
                        return <AlexaBody2TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate3":
                        return <AlexaBody3TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate6":
                        return <AlexaBody6TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate7":
                        return <AlexaBody7TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "AudioPlayer":
                        return <AlexaAudioPlayerPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "Video":
                        return <AlexaVideoPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "ListTemplate2":
                        return <AlexaListTemplate2Preview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                }
            }
            else {
                if (response.isList) {
                    return <AlexaListTemplate2Preview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.videoItem) {
                    return <AlexaVideoPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.audioItem) {
                    return <AlexaAudioPlayerPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.imageItem) {
                    return <AlexaBody2TemplatePreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else {
                    return <AlexaBody1TemplatePreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
            }
        }
        else if (this.props.deviceTarget.assistant == "Alexa" && this.props.deviceTarget.displayName.includes("Small") && this.props.deviceTarget.displayName.includes("Round")) {
            if (mediaResponse) {
                switch (mediaResponse.responseTemplateType.nativeName) {
                    case "BodyTemplate1":
                        return <AlexaSmallBody1TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate2":
                        return <AlexaSmallBody2TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate3":
                        return <AlexaSmallBody3TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate6":
                        return <AlexaSmallBody6TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate7":
                        return <AlexaSmallBody7TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "AudioPlayer":
                        return <AlexaSmallAudioPlayerPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "Video":
                        return <AlexaSmallVideoPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "ListTemplate2":
                        return <AlexaSmallListTemplate2Preview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />

                }
            }
            else {
                if (response.isList) {
                    return <AlexaSmallListTemplate2Preview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.videoItem) {
                    return <AlexaSmallVideoPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.audioItem) {
                    return <AlexaSmallAudioPlayerPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.imageItem) {
                    return <AlexaSmallBody2TemplatePreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else {
                    return <AlexaSmallBody1TemplatePreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
            }
        }
        if (this.props.deviceTarget.assistant == "Alexa" && this.props.deviceTarget.displayName.includes("Small")) {
            if (mediaResponse) {
                switch (mediaResponse.responseTemplateType.nativeName) {
                    case "BodyTemplate1":
                        return <AlexaShortBody1TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate2":
                        return <AlexaShortBody2TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate3":
                        return <AlexaShortBody3TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate6":
                        return <AlexaShortBody6TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate7":
                        return <AlexaShortBody7TemplatePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "AudioPlayer":
                        return <AlexaShortAudioPlayerPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "Video":
                        return <AlexaShortVideoPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "ListTemplate2":
                        return <AlexaShortListTemplate2Preview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                }
            }
            else {
                if (response.isList) {
                    return <AlexaShortListTemplate2Preview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.videoItem) {
                    return <AlexaShortVideoPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.audioItem) {
                    return <AlexaShortAudioPlayerPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.imageItem) {
                    return <AlexaShortBody2TemplatePreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else {
                    return <AlexaShortBody1TemplatePreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
            }
        }
        else if (this.props.deviceTarget.assistant == "GoogleAssistant") {
            if (this.props.deviceTarget.displayName.includes("Screen")) {
                if (mediaResponse) {
                    switch (mediaResponse.responseTemplateType.nativeName) {
                        case "BodyTemplate1":
                        case "SimpleCard":
                            return <GoogleSimpleCardPreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                        case "BodyTemplate2":
                        case "StandardCard":
                            return <GoogleStandardCardPreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                        case "MediaResponse":
                            return <GoogleMediaResponsePreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                        case "List":
                            return <GoogleListPreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                    }
                }
                else {
                    if (response.isList) {
                        return <GoogleListPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                    else if (response.audioItem) {
                        return <GoogleMediaResponsePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                    else if (response.imageItem) {
                        return <GoogleStandardCardPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                    else {
                        return <GoogleSimpleCardPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                }
            }

            else if (this.props.deviceTarget.displayName.includes("Mobile")) {
                if (mediaResponse) {
                    switch (mediaResponse.responseTemplateType.nativeName) {
                        case "BodyTemplate1":
                        case "SimpleCard":
                            return <GoogleMobileSimpleCardPreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                        case "BodyTemplate2":
                        case "StandardCard":
                            return <GoogleMobileStandardCardPreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                        case "MediaResponse":
                            return <GoogleMobileMediaResponsePreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                        case "List":
                            return <GoogleMobileListPreview
                                followUp={this.props.followUp}
                                response={response}
                                mediaResponse={mediaResponse} />
                    }
                }
                else {
                    if (response.isList) {
                        return <GoogleMobileListPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                    else if (response.audioItem) {
                        return <GoogleMobileMediaResponsePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                    else if (response.imageItem) {
                        return <GoogleMobileStandardCardPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                    else {
                        return <GoogleMobileSimpleCardPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    }
                }
            }
        }

        else if (this.props.deviceTarget.assistant == "Cortana") {
            if (mediaResponse) {
                switch (mediaResponse.responseTemplateType.nativeName) {
                    case "BodyTemplate1":
                    case "SimpleCard":
                    case "Card":
                        return <CortanaScreenSimpleCardPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "BodyTemplate2":
                    case "StandardCard":
                    case "Hero":
                        return <CortanaScreenStandardCardPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "MediaResponse":
                    case "AudioCard":
                        return <CortanaScreenMediaResponsePreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                    case "List":
                        return <CortanaScreenListPreview
                            followUp={this.props.followUp}
                            response={response}
                            mediaResponse={mediaResponse} />
                }
            }
            else {
                if (response.isList) {
                    return <CortanaScreenListPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.audioItem) {
                    return <CortanaScreenMediaResponsePreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else if (response.imageItem) {
                    return <CortanaScreenStandardCardPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
                else {
                    return <CortanaScreenSimpleCardPreview
                        followUp={this.props.followUp}
                        response={response}
                        mediaResponse={mediaResponse} />
                }
            }
        }
        return <p className={unsupportedDevice}>Unsupported Device Preview</p>;
    }
    render() {
        const { title, variations } = this.props;
        if (!variations) return null;
        return (
            <div className={containerStyle}>
                <div className={`${bubbleStyle} ac-output-message-preview`}>
                    {title ? <><span className="title">{title}</span>
                        <HorizontalSeparator /></> : null}
                    {this.renderDisplayPreview()}
                    {this.hasAudio() ?
                        <MultiFileAudioPlayer
                            src={this.getAudioSrc(variations[this.state.position])}
                            isLoading={this.props.ssmlContainer.state.isLoading}
                            onLoad={() => this.loadSsmlAudio(variations[this.state.position])} />
                        : null}
                    {
                        variations.length > 1 ?
                            <>
                                <HorizontalSeparator />
                                <div className="carousel-container">
                                    <span>{`VARIATION: ${this.state.position + 1} of ${variations.length}`}</span>
                                    <PositionIndicator count={variations.length} position={this.state.position} onChange={this.handlePositionChange.bind(this)} />
                                </div>
                            </>
                            : null
                    }
                </div>

                <img src={deviceIcon} />
                <PageError errors={this.props.ssmlContainer.state.errors} />
            </div>
        );
    }
}

export default DeviceScreenVariationsBubble;
