import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import GoogleScreenWrapper from './GoogleScreenWrapper';
import { cool_grey, silver_two } from '../../../constants/colors';
const playIcon = require('../../../content/images/baseline-play_arrow-24px.svg')
const screenStyle = css`
    color: white;
    .body-text, .title-text {
        font-family: 'Roboto', sans-serif;
        font-size: 80px;
        padding-top: 80px;
        padding-left: 160px;
        color: #111111;    
        overflow-wrap: break-word;
        z-index: 2;
        position: relative;
    }
    .body-text {
        overflow-y: hidden;
        flex: 1;
    }
    .detail-text {
        color: ${cool_grey};
        font-family: 'Roboto', sans-serif;
        overflow-wrap: break-word;
        z-index: 2;
        position: relative;
        padding-left: 80px;            
        font-size: 42px;
    }
    .media-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 42px;
        margin-bottom: 32px;
        .scrub-bar {
            width: 1820px;
            margin: 20px 160px;
            height: 8px;
            background: ${silver_two};
        }
        .scrub-handle {
            width: 32px;
            height: 32px;
            border: solid 8px ${silver_two};
            margin-top: -48px;
            margin-left: 90px;
            background: white;
            margin-right: auto;
            border-radius: 50%;
        }
        .time-container {
            display: flex;
            flex: 1;
            font-size: 60px;
            width: 1820px;
            font-weight: bold;
            .end {
                margin-left: auto;
                margin-right: 0;
            }
        }
        .play-icon {
            height: 80px;
            width: 80px;
        }
    }

    .body-container {
        display: flex;
        z-index: 2;
        align-items: center;
        height: 68%;
    }
    .foreground-image {
        width: 400px;
        height: 400px;
        z-index: 2;
        height: auto;
        padding-right: 160px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .chip-container {
        margin-left: 60px;
        margin-bottom: 20px;
    }
    .hint-chip {
        border: 1px solid gray;
        height: 80px;
        border-radius: 80px;
        margin-right: 40px;
        font-family: 'Roboto', sans-serif;
        font-size: 62px;
        color: #111111;    
        z-index: 2;
        padding: 32px;
    }
`
class GoogleMediaResponsePreview extends ScreenPreviewComponent {

    render() {
        return (
            <GoogleScreenWrapper className={screenStyle}>
                <div className="body-container">
                    <p className="body-text">
                        <span>{this.state.displayTitle}</span>
                        <br />
                        <span>{this.state.displayText}</span>
                    </p>
                    {this.state.foregroundImageUrl
                        ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                        : null}
                </div>
                <div className="media-container">
                    <div className="scrub-bar"></div>
                    <div className="scrub-handle"></div>
                    <div className="time-container">
                        <p>--.--</p>
                        <p className="end">--.--</p>
                    </div>
                    <img className="play-icon" src={playIcon}/>
                </div>
                <div className="chip-container">
                    {this.state.hints ? this.state.hints
                            .filter(h => h.content.length <= 20)
                            .map((h, i) => <span key={i} className="hint-chip">{h.content}</span>) 
                        : null}
                </div>
            </GoogleScreenWrapper>
        )
    }
}

export default GoogleMediaResponsePreview;