import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaSmallWrapper from './AlexaSmallWrapper';

const screenStyle = css`
    color: white;
    .body-container {
        margin: auto 180px 180px 180px;
    }
`
class AlexaSmallBody6TemplatePreview extends ScreenPreviewComponent {

    render() {
        return (
            <AlexaSmallWrapper className={screenStyle}>
                {this.state.backgroundImageUrl ? <img className="background-image" src={this.state.backgroundImageUrl} /> : null}
                <div className="body-container">
                    <p className="body-text">
                        <span>{this.state.displayText}</span>
                    </p>
                </div>
            </AlexaSmallWrapper>
        )
    }
}

export default AlexaSmallBody6TemplatePreview;