import React from 'react';
import {css} from '@emotion/css';
import { color_shades_dark } from '../../../constants/colors';
import Button from '../../../components/general/Button';
import TemplateFieldType from '../../../models/templating/TemplateFieldType';
import { getFieldTypeIcon } from '../../../models/extensions/fieldTypeIcons';
const addIcon = require('../../../content/images/add-left.svg');
interface TemplateFieldOptionProps {
    fieldType: TemplateFieldType
    onAdd: (fieldType: string) => void
    name: string
    description: string
}

const TemplateFieldOption: React.SFC<TemplateFieldOptionProps> = (props) => (
    <div className={containerStyle}>
        <div className="card-header-section">
            <img src={getFieldTypeIcon(props.fieldType)}/>
            <p>{props.name}</p>
        </div>
        <div className="card-body-section">
            <p>{props.description}</p>
            <Button type="button" themes={['secondary-small']} text="Add to Template" icon={addIcon} onClick={() => props.onAdd(props.fieldType)}/> 
        </div>
    </div>
);

const containerStyle = css`
    margin: 24px 0px;
    background: white;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;

    .card-header-section {
        display: flex;
        align-items: center;
        padding: 32px;
        border-bottom: 1px solid ${color_shades_dark};
        img {
            width: 36px;
            height: auto;
        }
        p {
            margin-left:16px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
    }
    .card-body-section {
        padding: 32px;

        p {
            margin-bottom: 32px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
        button {
            margin: 0;
        }
    }
`;

export default TemplateFieldOption