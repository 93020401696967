import React from 'react';
import { css } from '@emotion/css';
import FeatureFilters from '../../../models/features/FeatureFilters';
import { color_text_link, color_defaults_white, color_shades_dark } from '../../../constants/colors';
import Button from '../../../components/general/Button';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import CheckIndicator from '../../../components/forms/CheckIndicator';
import Breadcrumb, { BreadcrumbItem } from '../../../components/general/Breadcrumb';
import NewFolderOrWebhookPicker, { SelectedOption } from './NewFolderOrWebhookPicker';
const addIcon = require("../../../content/images/content-explorer/add-white.svg");

interface WebhookToolbarProps {
    icon?: string
    onAddNewFolder: () => void
    onAddNewWebhook: () => void
    onImport: () => void
    onBreadCrumbItemClick?: (item: BreadcrumbItem) => void
    breadCrumbs: BreadcrumbItem[]
    onSelectAll?: () => void
    groupSelectionState: GroupSelectionType
    highlightedBreadcrumbId?: string
    history: any
    showImport: boolean
    showNewWebhook: boolean
    showNewFolder: boolean
}

interface WebhookToolbarState {
    isAddNewExpanded: boolean
}

export type GroupSelectionType = 'all' | 'partial' | 'none'

class WebhookToolbar extends React.Component<WebhookToolbarProps, WebhookToolbarState> {
    searchInput: HTMLInputElement;

    constructor(props) {
        super(props)
        this.state = {
            isAddNewExpanded: false
        }
    }

    handleToggleAddNew() {
        this.setState({
            ...this.state,
            isAddNewExpanded: !this.state.isAddNewExpanded
        })
    }
    handleNewSelected(option: SelectedOption) {
        if(option == "folder")
            this.props.onAddNewFolder();
        if(option == "webhook")
            this.props.onAddNewWebhook();
        if(option == "import")
            this.props.onImport();
    }
    render() {
        return (
            <div className={containerStyle}>
                <div className="tool-bar-container">
                    {this.props.onSelectAll && <div className="group-select-container">
                        <CheckIndicator onClick={() => this.props.onSelectAll()} partial={this.props.groupSelectionState == 'partial'} checked={this.props.groupSelectionState == 'all'} />
                        <img src={this.props.icon} />
                    </div>}
                    <Breadcrumb items={this.props.breadCrumbs} onItemClick={this.props.onBreadCrumbItemClick} />
                    {(this.props.showImport || this.props.showNewFolder || this.props.showNewWebhook) &&
                        <div className="add-button">
                            <Button themes={['primary-small']} text="Add New" onClick={this.handleToggleAddNew.bind(this)} type="button" rightIcon={addIcon} />
                            {this.state.isAddNewExpanded &&
                                <NewFolderOrWebhookPicker
                                    position={"right"}
                                    onClose={this.handleToggleAddNew.bind(this)}
                                    onSelected={this.handleNewSelected.bind(this)} 
                                    showImport={this.props.showImport}
                                    showNewWebhook={this.props.showNewWebhook}
                                    showNewFolder={this.props.showNewFolder}
                            />}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export const getFilterCount = (filters: FeatureFilters) => {
    var count = 0;
    if (filters.textFilter && filters.textFilter.length > 0) count++;
    if (filters.daysFilter) count++;
    if (filters.featureTypeIdsFilter && filters.featureTypeIdsFilter.length > 0) count++;
    if (filters.languageIdsFilter && filters.languageIdsFilter.length > 0) count++;
    if (filters.propertiesFilter) {
        var hasPropertyFilters = Object.keys(filters.propertiesFilter).map(key => filters.propertiesFilter[key]).filter(val => val === true).length > 0
        if (hasPropertyFilters)
            count++;
    }
    return count;
}

const containerStyle = css`

    background: ${color_defaults_white};
    .tool-bar-container {
        border-bottom: 1px solid ${color_shades_dark};
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 56px;
    }
    .group-select-container {
        display: flex;
        align-items: center;
        padding: 0 12px 0 8px;
        margin-right: 12px;
        border-right: 1px solid ${color_shades_dark};
    }

    button {
        margin: 0;

        &.expanded {
            background: linear-gradient(0deg, rgba(30, 126, 185, 0.07), rgba(30, 126, 185, 0.07)), #FFFFFF;
            border: 1px solid ${color_text_link};
            color: ${color_text_link};
        }

        &.search-button {
            margin: 0 16px;
            padding: 0 16px;
            .right-img {
                margin: 0;
            }
        }

        .filter-right {
            margin-left: 8px;
            width: 20px;
            img {
                width: 16px;
                height: 16px;
            }
        }

        .filter-count {
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${color_text_link};
            width: 20px;
            height: 20px;
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
            }
        }
    }

    .filter-button {
        margin-right: 24px;
        margin-left: auto;

        &.applied-filter button {
            border: 1px solid ${color_text_link};
            color: ${color_text_link};
        }
    }

    .add-button {
        margin-right: 16px;
        margin-left: auto;
        position: relative;
    }
`;

export default WebhookToolbar;

