import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import FeatureContainer from '../../state/containers/FeatureContainer';
import CustomRequestContainer from '../../state/containers/CustomRequestContainer';
import UpdateCustomRequestContentForm from './components/UpdateCustomRequestContentForm';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import GenericEditContentItemPage from '../formScenes/GenericEditContentItemPage';
import CustomRequestModel from '../../models/features/api/CustomRequests/CustomRequestModel';
import IResult from '../../models/result/IResult';
import { CustomRequestsFeatureTypeId } from '../../constants/featureTypeIds';
import queryString from 'query-string';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';

interface ApplicationRouteProps {
    applicationFeatureId: string
    customRequestId: string
}
interface EditCustomRequestProps extends RouteComponentProps<ApplicationRouteProps> { }
class EditCustomRequest extends React.Component<EditCustomRequestProps> {
    render() {
        return (
            <Subscribe key={this.props.match.params.customRequestId + this.props.match.params.applicationFeatureId} to={[
                CustomRequestContainer,
                ApplicationContainer,
                TitleStateContainer,
                RepromptContainer,
                FollowUpContainer,
                MediaResponseStateContainer,
                FeatureContainer,
                ApplicationEnvironmentsContainer,
                ApplicationBackupVersionsContainer]}>
                {(customRequestContainer: CustomRequestContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer,
                    environmentsContainer: ApplicationEnvironmentsContainer,
                    backupsContainer: ApplicationBackupVersionsContainer) => {
                    return (
                        <GenericEditContentItemPage stateContainer={customRequestContainer}
                            environmentsContainer={environmentsContainer}
                            backupsContainer={backupsContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="Edit Custom Request"
                            contentItemId={this.props.match.params.customRequestId}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={CustomRequestsFeatureTypeId}
                            formRender={(contentItem: CustomRequestModel,
                                application,
                                isTemplateOwned: boolean,
                                assistantApplication,
                                onShowPublishModal,
                                onSubmit: (model: CustomRequestModel, onSuccess) => Promise<IResult<CustomRequestModel>>,
                                toggleSync: (id, shouldNotSync) => Promise<IResult<CustomRequestModel>>,
                                onAccessTypeChange) => (
                                <UpdateCustomRequestContentForm
                                    location={this.props.location}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    application={application}
                                    customRequest={contentItem}
                                    stateContainer={customRequestContainer}
                                    repromptContainer={repromptContainer}
                                    followUpContainer={followUpContainer}
                                    appContainer={appContainer}
                                    responseStateContainer={responseContainer}
                                    featureContainer={featureContainer}
                                    assistantApplication={assistantApplication}
                                    onShowPublishModal={onShowPublishModal}
                                    onSubmit={onSubmit}
                                    onToggleSync={toggleSync}
                                    onAccessTypeChange={onAccessTypeChange}
                                    previousContentId={queryString?.parse(this.props.location.search)?.previousContentId as string}
                                    history={this.props.history}
                                    isSaving={customRequestContainer.state.isSaving}
                                    isTemplateOwned={isTemplateOwned} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default EditCustomRequest;