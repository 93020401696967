import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import ConversationItemSelector from './ConversationItemSelector';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { ocean_blue, cool_grey } from '../../../constants/colors';
import NavigationSelection from './NavigationSelection';
import ContentItemSection from './ContentItemSection';
const leadIcon = require('../../../content/images/content-tree-icons/content-level/lead-in.svg')
interface ParentConversationItemSelectorProps {
    onSelect: (conversationItem: GenericContentItemModel) => void
    conversationItems?: GenericContentItemModel[]
    isLoading: boolean
    isSaving: boolean
    currentConversationItem?: GenericContentItemModel
}
interface ParentConversationItemSelectorState {
    isOpen?: boolean
}

class ParentConversationItemSelector extends React.Component<ParentConversationItemSelectorProps, ParentConversationItemSelectorState> {
    state = {
        isOpen: true
    }

    handleToggle() {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        })
    }

    render() {
        return (
            <div className={`parent-selector ${containerStyle} ${this.state.isOpen ? 'open' : ''}`}>
                {this.props.isLoading ? <div className="loader-container"><SecondaryLoader /></div> :
                    <NavigationSelection isOpen={this.state.isOpen} title="Preceeded by" icon={leadIcon} count={this.props.conversationItems ? this.props.conversationItems.length : 0} onClick={this.handleToggle.bind(this)} />}
                {this.state.isOpen && !this.props.isLoading ?
                    <ContentItemSection>
                        <ConversationItemSelector
                            conversationItems={this.props.conversationItems}
                            isSaving={this.props.isSaving}
                            onSelect={this.props.onSelect} />
                    </ContentItemSection>
                    : null}
            </div>
        )
    }
};

const containerStyle = css`
    .spinner > div {
        background: ${cool_grey};
    }
    .loader-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin: 8px;
    }

`


export default ParentConversationItemSelector;