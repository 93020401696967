import React, { FocusEvent } from 'react';
import { css } from '@emotion/css';
import _ from 'lodash';
import GroupedMultiSelector, { ItemGroup, OptionItem, SelectorPosition } from '../../../components/forms/GroupedMultiSelector';
import { color_shades_light } from '../../../constants/colors';
import EditableNlpEntity from '../../../models/nlpEntity/EditableNlpEntity';
import FieldError from '../../../components/forms/FieldError';
import styled from '@emotion/styled';
const addIcon = require('../../../content/images/add-circle-dark.svg');

interface NlpEntitySelectorProps {
    handleChange: (values: string[]) => any;
    selected: EditableNlpEntity;
    selectedDisplayText: string;
    label?: string;
    position?: SelectorPosition;
    id?: string;
    nlpEntities: EditableNlpEntity[];
    disabled?: boolean;
    fieldName: string;
    className?: string;
}

const NlpEntitySelector: React.FC<NlpEntitySelectorProps> = (props) => {

    const selectedDisplayName: string = props.selected?.name;

    const getGroups = (): ItemGroup<EditableNlpEntity>[] => {
        const groups: ItemGroup<EditableNlpEntity>[] = [];

        const customGroup: ItemGroup<EditableNlpEntity> = {
            key: '__custom',
            label: 'Custom',
            subItems: [],
            hideRadio: true
        }

        const preBuiltGroup: ItemGroup<EditableNlpEntity> = {
            key: '__preBuilt',
            label: 'Pre-built',
            subItems: [],
            hideRadio: true
        }

        props.nlpEntities?.forEach(entity => {
            const deleted: string = entity.isDeleted ? "Deleted - " : "";
            const label: string = `${deleted} ${entity.name} (Type: ${entity.type})`;
            const optionItem: OptionItem<EditableNlpEntity> = {
                label: label,
                value: entity.name,
                isSelected: entity.name == selectedDisplayName,
                data: entity,
                groupKey: '',
                hideCheckbox: true
            }
            if (entity.prebuiltEntityId) {
                optionItem.groupKey = "preBuilt";
                preBuiltGroup.subItems.push(optionItem);
            } else {
                optionItem.groupKey = "custom";
                customGroup.subItems.push(optionItem);
            }
        });

        if (customGroup.subItems.length) {
            groups.push(customGroup);
        }
        if (preBuiltGroup.subItems.length) {
            groups.push(preBuiltGroup);
        }

        return groups;
    }
    return (
        <>
            <NlpEntitySelectorStyled
                className={props.className}
                groupToggle={groupToggleStyle}
                label={props.label}
                groups={getGroups()}
                onChange={(values) => props.handleChange(values)}
                selectedValues={[selectedDisplayName]}
                withGroupSelection={false}
                treatGroupAsValue={false}
                subItemCheckType={"radio"}
                restrictGroups={true}
                position={props.position ?? 'bottom'}
                emptyText="Select"
                disabled={props.disabled}
                innerSelectionRender={() =>
                    <div>{props.selectedDisplayText}</div>
                } />
            <FieldError name={props.fieldName} ignoreTouched={true} />
        </>
    )

}

const groupToggleStyle = css`
    margin-left: auto;
    margin-right: 0;
    height: 8px;
    width: 8px;
`;

const NlpEntitySelectorStyled = styled(GroupedMultiSelector)`
    margin-left: auto;
    margin-top: 16px;
    margin-right: 0;

    &.bordered {
        border-right: 1px solid #ccc;
    }
    &.disabled {
        .field .selection{
            background: ${color_shades_light};
            cursor: not-allowed;
        }
    }
    .sub-item {
        height: 44px;
    }
    .item-group {
        height: 44px;
    }

    .field {
        .selection {
            border-radius: 8px;
            .empty-text {
                text-align: right;
                margin-left: auto;
                margin-right: 12px;
                width: 100%;
            }
        }
    }

    .inner-wrapper {
        max-height: 300px !important;
    }
    .field-wrapper {
        display: block;
        .selection-section {
            border-radius: 8px;
            margin-top:8;
        }
        label { 
            text-align: left;
            margin: 0 16px;
        }
    }

    
`;


export default NlpEntitySelector;