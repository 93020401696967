import React from 'react';
import { css } from '@emotion/css';
import ApplicationFeatureModel from '../../../models/features/api/ApplicationFeatureModel';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import { Link } from 'react-router-dom';
import { silver_four, color_shades_darkest, color_shades_dark, color_shades_darker, color_shades_lighter, color_text_default } from '../../../constants/colors';
import LargeOverflowMenu from '../../../components/general/LargeOverflowMenu';
import CheckIndicator from '../../../components/forms/CheckIndicator';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import WebhookFolderModel from '../../../models/webhooks/api/WebhookFolderModel';
import WebhookFolderContainer from '../../../state/containers/WebhookFolderContainer';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
const folderIcon = require('../../../content/images/webhooks/webhook-folder-grey.svg');
const deleteIcon = require('../../../content/images/bin.svg');
const deleteRedIcon = require('../../../content/images/bin-red.svg');
const editIcon = require('../../../content/images/pencil-light.svg');
const upIcon = require('../../../content/images/navigation-up.svg');
const downIcon = require('../../../content/images/navigation-down.svg');
interface WebhookFolderItemProps {
    folder: WebhookFolderModel
    webhookFolderContainer: WebhookFolderContainer
    applicationId: string
    onSelectionChange: (folder: WebhookFolderModel, isSelected: boolean) => void
    isSelected: boolean
    selectable: boolean
    isSingleSelect?: boolean
    disabled?: boolean
    onClick: (folder: WebhookFolderModel) => void
    index?: number
    className?: string
    showWebhookCount: boolean
    reloadData: (appId: string) => void
}

interface WebhookFolderItemState {
    isEditing: boolean
    newName: string
    isDeleteConfirmationOpen: boolean
}

class WebhookFolderItem extends React.Component<WebhookFolderItemProps, WebhookFolderItemState> {
    inputElement: HTMLInputElement
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isDeleteConfirmationOpen: false,
            newName: this.props.folder?.name ?? this.props.folder?.name ?? "Untitled"
        }
    }
    handleFinishRename() {
        if (this.state.newName) {
            const folder = { ...this.props.folder };
            folder.name = this.state.newName;
            this.props.webhookFolderContainer.updateFolder(folder).then(result => {
                if (result.resultType == "Ok") {
                    this.setState({
                        ...this.state,
                        isEditing: false
                    })
                }
            })
        } else {
            this.setState({
                ...this.state,
                isEditing: false
            })
        }
    }
    handleNameChange(e) {
        this.setState({
            ...this.state,
            newName: e.target.value
        });
    }
    handleOptionSelected(optionName: string) {
        if (optionName == 'Rename') {
            this.setState({
                ...this.state,
                isEditing: true
            }, () => this.inputElement?.focus())
        }
        if (optionName == 'Move Up') {
            this.props.webhookFolderContainer.moveFolderUp(this.props.folder?.id)
        }
        if (optionName == 'Move Down') {
            this.props.webhookFolderContainer.moveFolderDown(this.props.folder?.id)
        }
        if (optionName == 'Delete') {
            this.setState({
                ...this.state,
                isDeleteConfirmationOpen: true
            })
        }
    }
    async handleDeleteConfirm() {
        const result = await this.props.webhookFolderContainer.deleteFolder(this.props.folder.id)
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isDeleteConfirmationOpen: false
                })
                this.props.reloadData(this.props.applicationId)
            }
            
    }
    handleCloseModals() {
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: false
        })
    }
    handleEnterBlur(e) {
        if (e.charCode == 13) {
            e.target.blur();
        }
    }

    getOptions() {
        const options = [
            { label: 'Rename', icon: editIcon, hoverIcon: editIcon },
            { label: 'Delete', icon: deleteIcon, hoverIcon: deleteRedIcon, isDestructive: true }];

        options.push({ label: 'Move Up', icon: upIcon, hoverIcon: upIcon });
        options.push({ label: 'Move Down', icon: downIcon, hoverIcon: downIcon });


        return options;
    }

    render() {
        return (
            <div className={`${containerStyle} ${this.props.disabled ? 'disabled' : ''} ${this.props.className ?? ''}`}>
                <div className={`feature-item ${this.props.index == 0 ? 'top' : ''} ${this.props.selectable ? '' : 'not-selectable'}`}>
                    {this.props.selectable &&
                        <CheckIndicator checkType={this.props.isSingleSelect ? 'radio' : 'check'}
                            checked={this.props.isSelected}
                            onClick={this.props.disabled ? () => { } : () => this.props.onSelectionChange(this.props.folder, !this.props.isSelected)}
                            disabled={this.props.disabled} />}

                    {this.state.isEditing ? 
                    <div className="feature-link">
                        <img className="folder-icon" src={folderIcon} />
                        <input className="feature-title"
                            placeholder="New Folder"
                            value={this.state.newName}
                            ref={r => this.inputElement = r}
                            onChange={this.handleNameChange.bind(this)}
                            onBlur={this.handleFinishRename.bind(this)}
                            onKeyPress={(e) => this.handleEnterBlur(e)}
                            onFocus={(e) => e.target.select()} />
                    </div> : 
                    (this.props.onClick ? 
                        <div className="feature-link" onClick={this.props.disabled ? null : () => this.props.onClick(this.props.folder)}>
                            <img className="folder-icon" src={folderIcon} />
                            <h4 className="feature-title">{this.props.folder.name ?? "Untitled"}</h4>
                        </div> 
                        :
                        <Link className="feature-link" to={`/v/apps/${this.props.applicationId}/webhooks/${this.props.folder.id}`}>
                            <img className="folder-icon" src={folderIcon} />
                            <h4 className="feature-title">{this.props.folder.name ?? "Untitled"}</h4>
                        </Link>
                        )}
                    {this.props.showWebhookCount &&
                        <div className="webhook-count">
                            <div className="count">{this.props.folder.totalWebhookCount}</div>
                            <div className="descriptor">{`WEBHOOK${this.props.folder.totalWebhookCount == 1 ? '' : 's'}`}</div>
                        </div>
                    }
                    <LargeOverflowMenu className={`overflow-menu-folder ${this.props.showWebhookCount ? "" : "margin-left"}`} isActive={false} onOptionSelected={this.handleOptionSelected.bind(this)} options={this.getOptions()}/>
                </div>

                {this.state.isDeleteConfirmationOpen &&
                    <ConfirmationDialog title={`Delete ${this.props.folder?.name ?? "Untitled"}`} deleteText="Yes, Delete"
                        isLoading={this.props.webhookFolderContainer.state.isLoading}
                        onClose={this.handleCloseModals.bind(this)}
                        onConfirm={this.handleDeleteConfirm.bind(this)}>
                        <DeleteConfirmation applicationFeatureCount={1} />
                    </ConfirmationDialog>}
            </div>
        )
    }
}

const containerStyle = css`
    .webhook-count {
        padding-right: 24px;
        border-right: solid 1px ${color_shades_dark};
        margin-left: auto;
        text-align: right;
        font-style: normal;
        .count {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: ${color_shades_darkest};
        }
        .descriptor {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${color_shades_darker};
        }
    }
    &.dragging {
        .feature-item {
            border: 1px solid ${color_shades_dark};
        }
    }
    &.disabled {
        .feature-item {
            background: ${color_shades_lighter};
            cursor: not-allowed;
            .feature-title {
                background: ${color_shades_lighter};
            }
        }
    }
    .overflow-menu-folder {
        margin-left:24px;
        margin-right: 32px;
        &.margin-left {
            margin-left: auto;
        }
    }
    .feature-item {
        height: 80px;
        background: #FFFFFF;
        border-bottom: 1px solid ${color_shades_dark};
        &.top {
            border-top: 1px solid ${color_shades_dark};
        }
        &.not-selectable {
            padding-left: 69px;
        }
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 32px;
        .feature-link {    
            cursor: pointer;
            display: flex;
            align-items: center;
            color: ${color_shades_darkest};
        }
        .folder-icon {
            width: 56px;
            height: 56px;
        }
        .feature-title {
            background: white;
            margin-left: 16px;
            margin-top: 18px;            
            font-family: Muli;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 12px;
            border: none;
            color: ${color_text_default};            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 60vw;
        }

        .add-new-button-container {
            position: relative;
        }

        .count-container {
            text-align: right;
            margin-right: 16px;
            margin-left: auto;
            border-right: 1px solid ${silver_four};
            padding: 0 12px;
            h5 {                
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: ${color_shades_darker};
            }
        }

        .add-button {
            margin: 0 24px;
        }
    }
    .child-container {
        display: flex;
        align-items: stretch;
        .spacer {
            width: 32px;
            background: #E9EAEE;
            border: 1px solid ${color_shades_dark};
            box-sizing: border-box;
        }
        .child-content-container {
            flex: 1;
        }
        .child-content-items-container {
            padding: 32px;            
            background: ${color_shades_lighter};
        }

        .content-item {
            height: 64px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid ${color_shades_dark};
            box-sizing: border-box;
            border-radius: 4px;

            .content-item-icon {
                width: 24px;
                height: 24px;
                margin-right: 16px;
            }

            .feature-type-title {
                font-family: Muli;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: ${color_shades_darker};
            }
            .content-title {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: ${color_shades_darkest};
            }
        }
    }
    .triangle-down {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid ${color_shades_darkest};
    }
    .triangle-right {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid ${color_shades_darkest};
    }

    .triangle-container {
        width: 3px;
        height: 3px;
        margin-right: 8px;
    }

    .check {
        margin-top: 4px;
    }
`

export default WebhookFolderItem;