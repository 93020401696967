import React from 'react';
import { css } from '@emotion/css';
import ToggleIndicator from '../../../../components/general/ToggleIndicator';
import { dark_grey_blue_10, color_text_light, color_text_link, color_shades_dark } from '../../../../constants/colors';

interface FilterSelectorProps {
    icon: string
    placeholder: string
    label: string
    selectedText: string
    onClear: () => void
}
interface FilterSelectorState {
    isExpanded: boolean
}

class FilterSelector extends React.Component<FilterSelectorProps, FilterSelectorState> {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    } documentCloser = this.handleDocumentClick.bind(this)
    componentWillMount() {
        document.addEventListener("click", this.documentCloser, true);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.documentCloser, true);
    }
    handleDocumentClick(e: any) {
        if (e.target.closest(".filter-options") || e.target.closest(".filter-field")) return;
        this.setState({
            ...this.state,
            isExpanded: false
        })
    }
    handleToggle() {
        this.setState({
            ...this.state,
            isExpanded: !this.state.isExpanded
        })
    }
    render() {
        return (<div className={containerStyle}>
            <div className="label-container">
                <label>{this.props.label}</label>
                {this.props.selectedText && <button type="button" onClick={this.props.onClear}>Clear</button>}
            </div>
            <div className="filter-field" onClick={this.handleToggle.bind(this)}>
                <img src={this.props.icon} />
                {this.props.selectedText ? <p className="selection-value">{this.props.selectedText}</p> : <p className="placeholder">{this.props.placeholder}</p>}
                <ToggleIndicator direction={this.state.isExpanded ? 'up' : 'down'} />
            </div>
            {this.state.isExpanded &&
                <div className="filter-options">
                    <div className={triangleStyle}></div>
                    <div className="inner-options">
                        {this.props.children}
                    </div>
                </div>}
        </div>)
    }

}

const containerStyle = css`
    position: relative;
    min-width: 248px;

    .label-container {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        label {
            font-size: 12px;
            margin-left: 16px;
        }

        button {
            color: ${color_text_link};
            border: none;
            background: none;
            margin-left: auto;
            margin-right: 16px;            
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            cursor: pointer;
        }
    }

    .filter-field {
        background: white;
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 4px;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;

        .placeholder {
            font-style: italic;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #8F97A1;
        }

        .toggle-indicator {
            margin-right: 0;
            margin-left: auto;
        }
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        img {
            margin-right: 12px;
        }
    }

    .filter-options {
        position: absolute;
        margin-top: 12px;
        width: 100%;
        

        .inner-options {
            position: relative;
            z-index: 1;
            overflow-x: hidden;
            border: 1px solid ${color_shades_dark};
            border-radius: 8px;
            width: 100%;
            box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
            background: white;
        }
    }

`
const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: white;
    margin-right: 16px;
    margin-left: auto;
    z-index: 2;
`

export default FilterSelector;