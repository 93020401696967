import React from 'react';
import { css } from '@emotion/css';
import MenuStateContainer from '../../../state/containers/MenuStateContainer';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import CollapsingPanel from '../../../components/structure/Panels/CollapsingPanel';
import ApplicationContentMenu from './ApplicationContentMenu';
import CollapsedApplicationContentMenu from './CollapsedApplicationContentMenu';
import ContentTreeStateContainer from '../../../state/containers/ContentTreeStateContainer';
import { color_shades_dark } from '../../../constants/colors';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import { breakpoint_small } from '../../../constants/breakpoints';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
interface NavigationDrawerProps {
    history: any
    application: ApplicationModel
    menuContainer: MenuStateContainer;
    appContainer: ApplicationContainer;
    orgContainer: OrganizationContainer;
    featureContainer: FeatureContainer;
    treeContainer: ContentTreeStateContainer;
    backupsContainer: ApplicationBackupVersionsContainer
}
const ApplicationMenu: React.FC<NavigationDrawerProps> = (props) => {
    const onToggleCollapse = (e) => {
        e.stopPropagation();
        props.menuContainer.toggleDrawer();
    }
    return (
        <div className={`${containerStyle} ${(props.menuContainer.state.isDrawerOpen ? " expanded" : " collapsed")} ac-content-left-menu`}>
            <div className={"fixed-wrapper"}>
                <CollapsingPanel isCollapsed={!props.menuContainer.state.isDrawerOpen}
                    collapsedView={
                        <CollapsedApplicationContentMenu history={props.history}
                            application={props.application}
                            appContainer={props.appContainer}
                            featureContainer={props.featureContainer}
                            treeContainer={props.treeContainer}
                            backupsContainer={props.backupsContainer}
                            onExpand={onToggleCollapse.bind(this)} />
                    }
                    expandedView={
                        <ApplicationContentMenu history={props.history}
                            application={props.application}
                            appContainer={props.appContainer}
                            featureContainer={props.featureContainer}
                            treeContainer={props.treeContainer}
                            backupsContainer={props.backupsContainer}
                            onCollapse={onToggleCollapse} />
                    } />
            </div>
        </div>
    )
}


const containerStyle = css`
    z-index: 2;
    bottom: 0;
    position: relative;

    &.collapsed {
        width: 80px;
        .fixed-wrapper {
            width: 80px;
        }
    }
    &.expanded {
        width: 288px; 
        .fixed-wrapper {
            width: 288px;
        }
    }

    .fixed-wrapper {
        position: fixed;
        top: 80px;
        bottom: 0;
        height: calc(100vh - 80px);
        background: white;
        border-right: 1px solid ${color_shades_dark};
        box-sizing: border-box;
    }
    ${breakpoint_small} {
        display: none;
    }
`;

export default ApplicationMenu;