import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import ConversationItemSelector from './ConversationItemSelector';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { ocean_blue, cool_grey } from '../../../constants/colors';
import NavigationSelection from './NavigationSelection';
import GroupedConversationItem from '../../../models/features/GroupedConversationItem';
import GroupedConversationItemSelector from './GroupedConversationItemSelector';
import ContentItemSection from './ContentItemSection';
const relatedIcon = require('../../../content/images/content-tree-icons/content-level/related.svg');
interface SiblingConversationItemSelectorProps {
    onSelect: (conversationItem: GenericContentItemModel) => void
    conversationItems?: GroupedConversationItem[]
    isLoading: boolean
    isSaving: boolean
    currentConversationItem?: GenericContentItemModel
}
interface SiblingConversationItemSelectorState {
    isOpen?: boolean
}

class SiblingConversationItemSelector extends React.Component<SiblingConversationItemSelectorProps, SiblingConversationItemSelectorState> {
    state = {
        isOpen: true
    }

    handleToggle() {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        })
    }
    handleClose() {
        this.setState({
            ...this.state,
            isOpen: false
        })
    }

    getCount() {
        return this.props.conversationItems && this.props.conversationItems.length > 0 ?
            this.props.conversationItems.map(c => c.children.length).reduce((p, c) => (p + c))
            : 0;
    }

    render() {
        return (
            <div className={`sibling-selector ${containerStyle} ${this.state.isOpen ? 'open' : ''}`}>
                {this.props.isLoading ? <div className="loader-container"><SecondaryLoader /></div> :
                    <NavigationSelection isOpen={this.state.isOpen} title="Related" icon={relatedIcon} count={this.props.conversationItems ? this.props.conversationItems.length : 0} onClick={this.handleToggle.bind(this)} />}

                {this.state.isOpen && !this.props.isLoading ?
                    <ContentItemSection>
                        <GroupedConversationItemSelector
                            title="Related Conversations"
                            description="Conversations at the same level"
                            conversationItems={this.props.conversationItems}
                            onSelect={this.props.onSelect}
                            onClose={this.handleClose.bind(this)}
                            isSaving={this.props.isSaving} />
                    </ContentItemSection>
                    : null}
            </div>
        )
    }
};

const containerStyle = css`
    .spinner > div {
        background: ${cool_grey};
    }
    .loader-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin: 8px;
    }
`

export default SiblingConversationItemSelector;