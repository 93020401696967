import type { RequestContext } from "assistant/VoicifyAssistant";
import { addItemToAfterResponseData, extractDataValue, sleep } from "../Shared";


export const performCloseAssistantEffect = async (data: any, requestContext: RequestContext) => {
    var fireAfterMilliseconds = extractDataValue(data, "fireAfterMilliseconds", 0);
    const element = document.getElementById(requestContext.assistantElementRootId);
    if (element) {
        const button = element?.shadowRoot?.getElementById("voicify-assistant-close-button-id");
        if (button) {
            await sleep(fireAfterMilliseconds);
            button.click();
        }
    }
}
export const handleCloseAssistantEffect = async (data: { [key: string]: any }, requestContext: RequestContext, afterResponseEffect: boolean = false): Promise<boolean> => {
    
    var fireBefore = extractDataValue(data, "fireBeforeTextToSpeech", true);
    if (fireBefore || afterResponseEffect) {
        await performCloseAssistantEffect(data, requestContext);
        return true;
    } else {
        addItemToAfterResponseData("closeAssistant", requestContext, data);
        return false;
    }
}