import React from 'react';
import { css } from '@emotion/css';
import { Subscribe } from 'unstated';
import ModalLayout from '../../components/structure/ModalLayout';
import FullScreenLayout from '../fullScreenLayout';
import ScrollView from '../../components/structure/ScrollView';
import PageContainer from '../../components/structure/PageContainer';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import ModalTitle from '../../components/general/ModalTitle';
import Button from '../../components/general/Button';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { RouteComponentProps } from 'react-router';
import PageError from '../../components/general/PageError';
import MemberContainer from '../../state/containers/MemberContainer';
import AddApplicationMemberForm from './components/AddApplicationMemberForm';
import ApplicationMembersContainer from '../../state/containers/ApplicationMembersContainer';
import { breakpoint_small } from '../../constants/breakpoints';
import ModalHeader from '../../components/general/ModalHeader';

const closeIcon = require('../../content/images/close_icon.svg');

interface AddApplicationMemberProps {
    applicationId: string
}

interface AddApplicationMemberState {
    hasChanges: boolean
}

class AddApplicationMember extends React.Component<RouteComponentProps<AddApplicationMemberProps>, AddApplicationMemberState> {
    componentWillMount() {
        this.setState({
            hasChanges: false
        })
    }
    handleClose() {
        if (this.state.hasChanges) {
            var confirmClose = confirm("You have unsaved changes. Are you sure you want to close?");
            if (!confirmClose)
                return;
        }

        this.props.history.goBack();
    }
    handleFinish() {
        this.props.history.goBack();
    }
    handleChange() {
        this.setState({ hasChanges: true });
    }
    render() {
        return (

            <ModalLayout
                isVisible={true}
                onClose={this.handleClose.bind(this)}
                className={modalStyle}
            >
                <Subscribe to={[MemberContainer, OrganizationContainer, ApplicationMembersContainer]}>
                    {(memberContainer: MemberContainer, orgContainer: OrganizationContainer, appMemberContainer: ApplicationMembersContainer) => {
                        return (
                            <FullScreenLayout>
                                <ScrollView>
                                    <PageContainer fill withFooter>
                                        <HorizontalContainer>
                                            <ModalHeader title="Add a New Member" onClose={this.handleClose.bind(this)} /> 
                                        </HorizontalContainer>
                                        <HorizontalSeparator />
                                        <AddApplicationMemberForm
                                            applicationId={this.props.match.params.applicationId}
                                            appMemberContainer={appMemberContainer}
                                            organizationContainer={orgContainer}
                                            memberContainer={memberContainer}
                                            onFinish={this.handleFinish.bind(this)}
                                            onClose={this.handleClose.bind(this)}
                                            onChange={this.handleChange.bind(this)} />
                                    </PageContainer>
                                </ScrollView>
                                <PageError errors={memberContainer.state.errors} />
                            </FullScreenLayout>
                        );
                    }}
                </Subscribe>
            </ModalLayout>
        )
    }
};

const modalStyle = css`
`;

export default AddApplicationMember;