import React from 'react';
import { css } from '@emotion/css';
import ToggleIndicator from '../general/ToggleIndicator';
import { cool_grey, dark_grey_blue_10 } from '../../constants/colors';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';
import { DeviceTargetListItem } from './DeviceTargetListItem';
import CustomScrollbars from '../structure/CustomScrollbars';

interface DeviceTargetSelectorProps {
    targets: DeviceTargetModel[]
    onSelectionChange: (target: DeviceTargetModel) => void
    selectedTarget: DeviceTargetModel
    isSmall?: boolean
}
interface DeviceTargetSelectorState {
    isCollapsed: boolean
}

class DeviceTargetSelector extends React.Component<DeviceTargetSelectorProps, DeviceTargetSelectorState> {
    documentCloser = this.handleDocumentClick.bind(this)
    componentWillMount() {
        this.setState({
            isCollapsed: true
        })
        document.addEventListener("click", this.documentCloser, true);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.documentCloser, true);
    }
    handleDocumentClick(e: any) {
        if (e.target.closest(".selector-wrapper") || e.target.closest(".selection-container")) return;
        this.setState({
            ...this.state,
            isCollapsed: true
        })
    }
    handleToggle() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        })
    }
    handleSelectionChange(target: DeviceTargetModel) {
        this.handleToggle();
        this.props.onSelectionChange(target);
    }
    render() {
        return (
            <div className={containerStyle + " small " + "ac-simulator-device-picker ac-content-preview-device-picker"}>
                {this.props.isSmall ? null : <p className="field-label">Preview-Type:</p>}
                <div className="selection-container">
                    <DeviceTargetListItem deviceTarget={this.props.selectedTarget} viewOnly isSelected={false} onSelected={this.handleToggle.bind(this)} />
                    <div className="selection-toggle" onClick={this.handleToggle.bind(this)}>
                        <ToggleIndicator direction={this.state.isCollapsed ? "down" : "up"} />
                    </div>
                </div>
                {this.state.isCollapsed ? null :
                    <div className="selector-wrapper">
                        {this.props.isSmall ? null : <div className={triangleStyle}></div>}
                        <CustomScrollbars autoHide className={`inner-wrapper${this.props.targets?.length === 1 ? " is-small" : ""}`}>
                            {this.props.targets.map((target, i) => (
                                <DeviceTargetListItem key={i} top={i == 0} bottom={i == this.props.targets.length - 1} deviceTarget={target} onSelected={this.handleSelectionChange.bind(this)} isSelected={target.id == this.props.selectedTarget.id} />
                            ))}
                        </CustomScrollbars>
                    </div>
                }
            </div>
        )
    }
}

const containerStyle = css`
    position: relative;
    width: 100%;
    .selection-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border-bottom: solid 1px ${cool_grey};
        cursor: pointer;
        background: white;
        .selection-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
        }
        .selection-toggle.small {
            border:0;
        }
        overflow: hidden;
    }

    &.small {
        .selection-container {
            height:52px;
            width:100%;
            align-items:center;
            justify-content: space-between;
            border: 0;
            .selection-toggle {
                border: 0;
            }
        }
        .selector-wrapper {
            margin:0;
            .inner-wrapper {
                min-height: 300px;
                max-height: 493px;
                &.is-small {
                    min-height: 82px;
                }
            }
        }
    }

    .selector-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        background: white;
        margin-bottom: 120px;
        margin-top: 12px;
        box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
        border-radius: 10px;
        z-index: 3;
        flex: 1;
        width: 100%;
        .inner-wrapper {
            overflow: hidden;
            max-height: 600px;
        }
    }

    .field-label {
        font-size: 14px;
        color: ${cool_grey};
        background: white;
        padding-left: 32px;
        padding-top: 16px;
    }
`

const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: transparent;
    margin-right: 16px;
    margin-left: auto;
`

export default DeviceTargetSelector