import React from 'react';
import MediaResponseModel from '../../models/features/api/MediaResponses/MediaResponseModel';
import { css } from '@emotion/css';
import FollowUpHintModel from '../../models/features/api/FollowUp/FollowUpHintModel';
import ConversationMediaResponse from '../../models/conversationFlow/ConversationMediaResponse';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';

interface ScreenPreviewComponentProps {
    mediaResponse?: MediaResponseModel
    response?: ConversationMediaResponse
    followUp?: FollowUpModel
}
interface ScreenPreviewComponentState {
    foregroundImageUrl?: string
    backgroundImageUrl?: string
    audioUrl?: string
    videoUrl?: string
    displayText?: string
    displayTitle?: string
    followUpText?: string
    hints?: FollowUpHintModel[]
    ssml?: string
}


abstract class ScreenPreviewComponent extends React.Component<ScreenPreviewComponentProps, ScreenPreviewComponentState> {
    componentWillMount() {
        this.configureFields();
    }
    configureFields() {
        this.setState({
            ...this.state,
            displayText: this.getDisplayText(),
            displayTitle: this.getDisplayTitle(),
            foregroundImageUrl: this.getForegroundImageUrl(),
            backgroundImageUrl: this.getBackgroundImageUrl(),
            audioUrl: this.getAudioUrl(),
            videoUrl: this.getVideoUrl(),
            hints: this.getHints(),
            followUpText: this.getFollowUpText(),
            ssml: this.getSsmlDisplay()
        })
    }
    componentDidUpdate(prevProps: ScreenPreviewComponentProps) {
        if (prevProps != this.props) {
            this.configureFields();
        }
    }
    stripTags(ssml: string) {
        const stripTagRegex = /<\/?[^>]+(>|$)/g
        var displayText = ssml;
        displayText = displayText.replace(stripTagRegex, "");
        return displayText;
    }
    getSsmlDisplay(): string {
        if (this.props.mediaResponse && this.props.mediaResponse.readoutOverride != null)
            return this.stripTags(this.props.mediaResponse.readoutOverride);
        if (this.props.response.content)
            return this.stripTags(this.props.response.content);
    }
    getDisplayText(): string {
        if (this.props.mediaResponse && this.props.mediaResponse.displayText != null)
            return this.props.mediaResponse.displayText.replace('\n', "<br/>");
        if (this.props.response.displayTextOverride)
            return this.props.response.displayTextOverride.replace('\n', "<br/>");
        if (this.props.response.content) {
            return this.stripTags(this.props.response.content).replace('\r', '<br/>').replace('\n', "<br/>");

        }

        return '';
    }
    getForegroundImageUrl(): string {
        if (this.props.mediaResponse && this.props.mediaResponse.foregroundImageItem != null)
            return this.props.mediaResponse.foregroundImageItem.url;
        if (this.props.response.imageItem)
            return this.props.response.imageItem.url;
        return null
    }
    getDisplayTitle() {
        if (this.props.mediaResponse && this.props.mediaResponse.displayTitle != null)
            return this.props.mediaResponse.displayTitle;
        if (this.props.response.audioItem)
            return this.props.response.audioItem.name;
        if (this.props.response.displayTitleOverride)
            return this.props.response.displayTitleOverride;
        return null;
    }
    getBackgroundImageUrl() {
        if (this.props.mediaResponse && this.props.mediaResponse.backgroundImageItem)
            return this.props.mediaResponse.backgroundImageItem.url;
        if (this.props.response.backgroundImageItem)
            return this.props.response.backgroundImageItem.url;
        return null;
    }
    getVideoUrl() {
        if (this.props.mediaResponse && this.props.mediaResponse.videoItem)
            return this.props.mediaResponse.videoItem.url;
        if (this.props.response.videoItem)
            return this.props.response.videoItem.url;
        return null;

    }
    getAudioUrl() {
        if (this.props.mediaResponse && this.props.mediaResponse.audioItem)
            return this.props.mediaResponse.audioItem.url;
        if (this.props.response.audioItem)
            return this.props.response.audioItem.url;
        return null;
    }
    getHints() {
        if (!this.props.followUp) return null;
        return this.props.followUp.followUpHints;
    }
    getFollowUpText() {
        if (this.props.followUp && !this.props.response.displayTextOverride) return this.stripTags(this.props.followUp.content);
        return null;
    }
}

export default ScreenPreviewComponent