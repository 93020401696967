import React from 'react';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import CortanaScreenWrapper from './CortanaScreenWrapper';
import DOMPurify from 'dompurify';

class CortanaScreenStandardCardPreview extends ScreenPreviewComponent {

    render() {
        return (
            <CortanaScreenWrapper className=''>
                <div className="body-container">

                    <div className="card">
                        {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                        {this.state.foregroundImageUrl
                            ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                            : null}
                        <p className="body-text">
                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.displayText) }}></span>

                            {this.state.followUpText ?
                                <>
                                    <br /><br />
                                    <span className="followup-text">{this.state.followUpText}</span>
                                </>
                                : null}
                        </p>
                    </div>
                </div>
            </CortanaScreenWrapper>
        )
    }
}

export default CortanaScreenStandardCardPreview;