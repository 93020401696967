import React from 'react';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import ApplicationTrackingContainer from '../../../state/containers/ApplicationTrackingContainer';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { css } from '@emotion/css';
import ContentNode from '../../../components/analytics/tracking/ContentNode';
import { ConversationTrackingTreeBaseNode, ConversationTrackingTreeContentNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import SessionStartNode from '../../../components/analytics/tracking/SessionStartNode';
import SessionEndNode from '../../../components/analytics/tracking/SessionEndNode';
import { color_colors_ocean, color_shades_lighter, color_text_light } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import DownTreePaths from '../../../components/analytics/tracking/DownTreePaths';
import SlimGenericContentItemModel from '../../../models/features/api/SlimGenericContentItemModel';
import NodeOffset from '../../../models/analytics/NodeOffset';
import TreeRowPager from '../../../components/analytics/TreeRowPager';
import _ from 'lodash';
import ConversationScript from '../../conversationScript';
import ContentTrackingContainer from '../../../state/containers/ContentTrackingContainer';
import FullContentNode from '../../../components/analytics/tracking/FullContentNode';
import UpTreePaths from '../../../components/analytics/tracking/UpTreePaths';
import ContentConversationTree from '../../../models/analytics/ContentConversationTree';
import AnalyticsTrackTree from '../../../models/analytics/AnalyticsTrackTree';
import ContentItemModel from '../../../models/features/api/ContentItemModel';
import AnalyticsEmptyView from '../../../components/analytics/AnalyticsEmptyView';

interface ContentTrackingPageProps {
    applicationId: string
    contentId: string
    contentItem: ContentItemModel
    featureTypeId: string
    trackingContainer: ContentTrackingContainer
    filter: AnalyticsRequestFilter
    history?: any
}
interface ContentTrackingPageState {
    selectedNodes: ConversationTrackingTreeBaseNode[]
    positionOffsets: NodeOffset[]
}


class ContentTrackingPage extends React.Component<ContentTrackingPageProps, ContentTrackingPageState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedNodes: [],
            positionOffsets: []
        }
    }
    componentDidMount() {
        this.reloadData();
    }
    componentDidUpdate(previousProps: ContentTrackingPageProps) {
        if (JSON.stringify(previousProps.filter) != JSON.stringify(this.props.filter)) {
            this.reloadData();
        }
    }
    async reloadData() {
        const result = await this.props.trackingContainer.loadTree(this.props.applicationId, this.props.contentId, this.props.filter);
        const tree = this.props.trackingContainer.findTree(this.props.contentId, this.props.filter);
        let selectedNodes = []
        if (result?.resultType == "Ok") {
            const contentTree = this.props.trackingContainer.convertToContentTree(tree);
            selectedNodes[1] = contentTree.contentNode;
            await this.props.trackingContainer.loadNodeDetails(this.props.applicationId, this.props.contentId, this.props.filter, [...contentTree.parentNodes, contentTree.contentNode, ...contentTree.childNodes]);
        }
        if (tree?.data?.childNodes?.length > 3) {
            // check to see if we should set an offset
            // if we have 4 or more, then set the default to far left
            let levelOffset = this.state.positionOffsets.find(l => l.level == 0);
            const index = this.state.positionOffsets.indexOf(levelOffset);
            if (levelOffset) {
                levelOffset.offset = -(tree.data.childNodes.length / 2) + 2;
                this.state.positionOffsets[index] = levelOffset
            } else {
                levelOffset = { level: 0, offset: -(tree.data.childNodes.length / 2) + 2 }
                this.state.positionOffsets.push(levelOffset)
            }
        }
        this.setState({
            ...this.state,
            selectedNodes,
            positionOffsets: this.state.positionOffsets
        })
    }
    handleContentNodeClick(node: ConversationTrackingTreeContentNode, level: number) {
        let { selectedNodes, positionOffsets } = this.state;
        if ((selectedNodes[level] as any)?.contentId == node.contentId) {
            selectedNodes = selectedNodes.slice(0, level);
        }
        else {
            selectedNodes[level] = node;
            if (node.childNodes?.length > 3) {
                // check to see if we should set an offset
                // if we have 4 or more, then set the default to far left
                let levelOffset = positionOffsets.find(l => l.level == level + 1);
                const index = positionOffsets.indexOf(levelOffset);
                if (levelOffset) {
                    levelOffset.offset = -(node.childNodes.length / 2) + 2;
                    positionOffsets[index] = levelOffset
                } else {
                    levelOffset = { level: level + 1, offset: -(node.childNodes.length / 2) + 2 }
                    positionOffsets.push(levelOffset)
                }

            }
        }
        this.setState({
            ...this.state,
            selectedNodes,
            positionOffsets
        });
    }
    handleEndNodeClick(level: number, node: ConversationTrackingTreeBaseNode) {
        var selectedNodes = this.state.selectedNodes;
        if (selectedNodes[level]?.conversationTrackingNodeType == 'SessionEnd') {
            selectedNodes = selectedNodes.slice(0, level);
        }
        else {
            selectedNodes[level] = node;
        }
        this.setState({
            ...this.state,
            selectedNodes
        })
    }
    handleStartNodeClick(level: number, node: ConversationTrackingTreeBaseNode) {
        var selectedNodes = this.state.selectedNodes;
        if (selectedNodes[level]?.conversationTrackingNodeType == 'SessionStart') {
            selectedNodes = selectedNodes.slice(0, level);
        }
        else {
            selectedNodes[level] = node;
        }
        this.setState({
            ...this.state,
            selectedNodes
        })
    }
    handlePage(level: number, increment: boolean, nodeLength: number) {

        const positionOffsets = this.state.positionOffsets;
        let levelOffset = positionOffsets.find(l => l.level == level);
        const index = positionOffsets.indexOf(levelOffset);
        if (!levelOffset)
            levelOffset = { level, offset: 0 };
        else {
            // limit it so the last node ends in the middle
            if (increment && levelOffset.offset > (nodeLength / 2 - 1))
                return;
            if (!increment && levelOffset.offset < -(nodeLength / 2 - 1))
                return;
        }

        if (increment)
            levelOffset.offset++;
        else
            levelOffset.offset--;

        if (index > -1)
            positionOffsets[index] = levelOffset;
        else
            positionOffsets.push(levelOffset);


        this.setState({
            ...this.state,
            positionOffsets
        })
    }
    getChildNodes(contentTree: ContentConversationTree, totalTree: AnalyticsTrackTree) {
        const selectedParent = this.state.selectedNodes[0];
        if (!selectedParent) {
            return contentTree.childNodes;
        }

        const parentFlow = totalTree.data.childNodes.find(n => n.conversationTrackingNodeType == selectedParent.conversationTrackingNodeType && (n as any).contentId == (selectedParent as any).contentId);
        return parentFlow.childNodes[0].childNodes
    }
    renderRow(nodes: ConversationTrackingTreeBaseNode[], conversationItems: SlimGenericContentItemModel[], level: number, label: string) {
        if (!nodes || nodes.length == 0)
            return null;

        const selectedNodes = this.state.selectedNodes;
        const selectedNode = nodes.find(n => (n.conversationTrackingNodeType == "Content" && (selectedNodes[level] as ConversationTrackingTreeContentNode)?.contentId == (n as ConversationTrackingTreeContentNode).contentId)
            || (n.conversationTrackingNodeType == "SessionEnd" && selectedNodes[level]?.conversationTrackingNodeType == "SessionEnd"))
        const totalSessions = nodes.map(n => n.sessionIds?.length ?? 0).reduce((a, b) => (a + b));
        const nodeOffset = this.state.positionOffsets.find(o => o.level == level)?.offset ?? 0;

        const treeRowOffset = css`
            margin-right: ${nodeOffset * 144 * 2}px;
        `
        return (
            <>
                <div className="tree-row-wrapper">
                    <p className="section-label">{label}</p>
                    <div className="row-separator">
                        <div className="row-separator-body" />
                    </div>
                    <div className="pager">
                        <div className="pager-body">
                            <TreeRowPager
                                onRight={() => this.handlePage(level, true, nodes.length)}
                                onLeft={() => this.handlePage(level, false, nodes.length)}
                                canMoveLeft={nodeOffset > -(nodes.length / 2 - 1)}
                                canMoveRight={nodeOffset <= (nodes.length / 2 - 1)} />
                            <p className="pager-label">{selectedNode ? `${nodes.indexOf(selectedNode) + 1} of ` : '-- of '} {nodes.length.toString()} items</p>
                        </div>
                    </div>
                </div>
                <div className={`tree-row ${treeRowOffset}`}>
                    {nodes?.map((n, i) => {
                        if (n.conversationTrackingNodeType == "Content") {
                            const contentNode = n as ConversationTrackingTreeContentNode;
                            const selected = (selectedNodes[level] as ConversationTrackingTreeContentNode)?.contentId == contentNode.contentId;
                            return <ContentNode id={contentNode.contentId + level}
                                key={i}
                                nodeData={contentNode}
                                percentage={n.sessionIds.length / totalSessions}
                                conversationItem={conversationItems.find(c => c.id == contentNode.contentId)}
                                selected={selected}
                                childSelected={selectedNodes[level + 1] != null}
                                active={selected || selectedNodes[level] == null}
                                isStart={level == 0}
                                isEnd={level == 2}
                                isLoadingDetails={this.props.trackingContainer.state.isLoadingDetails}
                                onClick={() => this.handleContentNodeClick(contentNode, level)} />
                        }
                        if (n.conversationTrackingNodeType == "SessionEnd") {
                            return <SessionEndNode key={i}
                                selected={selectedNodes[level]?.conversationTrackingNodeType == 'SessionEnd'}
                                percentage={n.sessionIds.length / totalSessions}
                                active={selectedNodes[level]?.conversationTrackingNodeType == 'SessionEnd' || selectedNodes[level] == null}
                                onClick={() => this.handleEndNodeClick(level, n)} />
                        }
                        if (n.conversationTrackingNodeType == "SessionStart") {
                            return <SessionStartNode key={i}
                                selected={selectedNodes[level]?.conversationTrackingNodeType == 'SessionStart'}
                                percentage={n.sessionIds.length / totalSessions}
                                active={selectedNodes[level]?.conversationTrackingNodeType == 'SessionStart' || selectedNodes[level] == null}
                                onClick={() => this.handleStartNodeClick(level, n)} />
                        }
                    })}
                </div>
            </>
        )
    }
    render() {
        const { trackingContainer } = this.props;
        const tree = trackingContainer.findTree(this.props.contentId, this.props.filter);
        if (trackingContainer.state.isLoading || !tree)
            return (<div className={loaderContainer}><SecondaryLoader /></div>)

        const contentTree = trackingContainer.convertToContentTree(tree);
        const filteredChildNodes = this.getChildNodes(contentTree, tree);
        const childItems = _.sortBy(filteredChildNodes, n => (n.sessionIds?.length ?? 0)).reverse();
        const parentItems = _.sortBy(contentTree.parentNodes, n => (n.sessionIds?.length ?? 0)).reverse();
        const selectedChild = filteredChildNodes.find(c => c.conversationTrackingNodeType == this.state.selectedNodes[2]?.conversationTrackingNodeType && (c as any).contentId == (this.state.selectedNodes[2] as any)?.contentId)
        const selectedParent = contentTree.parentNodes.find(c => c.conversationTrackingNodeType == this.state.selectedNodes[0]?.conversationTrackingNodeType && (c as any).contentId == (this.state.selectedNodes[0] as any)?.contentId)
        return (<div className={wrapperStyle}>
            <CustomScrollbars>
                {parentItems?.length > 0 ?
                    <div className="tree-container">
                        {this.renderRow(parentItems, tree.conversationItems, 0, "Preceeded by")}
                        <UpTreePaths topIndex={parentItems.indexOf(selectedParent) > -1 ? parentItems.indexOf(selectedParent) : 0}
                            topLength={contentTree.parentNodes.length}
                            activeIndex={parentItems.indexOf(selectedParent)}
                            topNodes={parentItems}
                            nodeOffset={0}
                            topNodeOffset={this.state.positionOffsets.find(o => o.level == 0)?.offset ?? 0} />
                        <div className="tree-row-wrapper">
                            <p className="section-label">Current Conversation</p>
                            <div className="row-separator">
                                <div className="row-separator-body" />
                            </div>
                            <div className={`tree-row`}>
                                <FullContentNode id={contentTree.contentNode?.contentId ?? 'content'}
                                    nodeData={contentTree.contentNode}
                                    conversationItem={this.props.contentItem}
                                    selected={selectedParent != null}
                                    childSelected={this.state.selectedNodes[2] != null}
                                    active
                                    isLoadingDetails={this.props.trackingContainer.state.isLoadingDetails}
                                    featureTypeId={this.props.featureTypeId} />
                            </div>
                        </div>
                        <DownTreePaths prevIndex={0}
                            prevLength={1}
                            activeIndex={childItems.indexOf(selectedChild)}
                            nodes={childItems}
                            nodeOffset={this.state.positionOffsets.find(o => o.level == 2)?.offset ?? 0} prevNodeOffset={0} />
                        {this.renderRow(childItems, tree.conversationItems, 2, "Followed by")}
                    </div>
                    : <AnalyticsEmptyView />}
            </CustomScrollbars>
            <div className="conversation-preview-container">
                <CustomScrollbars>
                    <ConversationScript nodes={this.state.selectedNodes ?? []} applicationId={this.props.applicationId} hasStart={this.state.selectedNodes[0]?.conversationTrackingNodeType == "SessionStart"} />
                </CustomScrollbars>
            </div>
        </div>)
    }
}
const loaderContainer = css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 64px 0;
    .spinner > div {
        background: ${color_colors_ocean};
    }

`
const wrapperStyle = css`
    display: flex;
    height: 100%;
    background: white;
    .tree-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 128px 0;
        overflow-x: hidden;
    }
    .tree-row-wrapper {
        width: 100%;
        position: relative;
        .section-label {   
            position: absolute;
            font-size: 18px;
            line-height: 24px;
            color: ${color_text_light};
            background: white;
            margin: 0 32px;
            top: -108px;
        }
    }
    .tree-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .row-separator {
        position: relative;
        width: 100%;
        .row-separator-body {
            height: 1px;
            bottom: 80px;
            left: 0;
            right: 0;
            position: absolute;
            border-top: ${style_border_default};
        }
    }
    .pager {
        position: relative;
        width: 100%;
        z-index: 6;
       
        .pager-body {
            bottom: 52px;
            right: 32;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .pager-label {
                color: ${color_text_light};
                font-size: 10px;
                line-height: 16px;
                text-align: right;
                background: white;
            }
        }
    }

    .conversation-preview-container {
        background: ${color_shades_lighter};
        min-width: 576px;
        height: 100%;
        border-left: ${style_border_default};
    }
   
`

export default ContentTrackingPage;