import React from 'react';
import {css} from '@emotion/css';
import HorizontalSeparator from './HorizontalSeparator';
import ToggleIndicator from '../general/ToggleIndicator';

interface ToggleableSectionProps {
    isCollapsed?: boolean
    hideTitle?: string
    showTitle?: string
    className?: string
    toggleType?: "triangle" | "chevron"
}

interface ToggleableSectionState {
    isCollapsed?: boolean
}

const containerStyle = css`
    .hidden {
        display: none;
    }

    .title-section {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 24px 8px;

        span {
            width: auto;
            margin: 0 8px;
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3885a6;
        }

        .toggle-indicator {
            border-color: #3885a6;
            margin: 2px;
        }

        .line {
            flex: 1;
        }
    }
`
class ToggleableSection extends React.Component<ToggleableSectionProps, ToggleableSectionState> {
    componentWillMount() {
        this.setState({
            isCollapsed: this.props.isCollapsed
        })
    }

    componentDidUpdate(previousProps: ToggleableSectionProps) {
        if(previousProps.isCollapsed != this.props.isCollapsed) {
            this.setState({
                isCollapsed: this.props.isCollapsed
            })
        }
    }

    handleToggle() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        })
    }

    render() {
        return (
            <div className={`${containerStyle} ${this.props.className ?? ''}`}>
                <div className="title-section" onClick={this.handleToggle.bind(this)}>
                    <ToggleIndicator direction={this.state.isCollapsed ? 'up' : 'down'} type={this.props.toggleType}/>
                    <span>{this.state.isCollapsed ? this.props.showTitle : this.props.hideTitle}</span>
                    <div className="line">
                        <HorizontalSeparator />
                    </div>
                </div>
                <div className={this.state.isCollapsed ? 'hidden': ''}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default ToggleableSection;