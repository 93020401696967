import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, primary_gradient, ocean_blue, faded_blue_2, faded_blue_3 } from '../../constants/colors';

interface ProgressBarProps {
    percent: number
}


const outerStyle = css`
    height: 56px;
    width: 100%;
    background: transparent;
`

const innerStyle = css`
    height: 56px;
    background: ${faded_blue_3};
`
const wrapperStyle = css`
width: 100%;
position: absolute;
`
const HeaderProgressBar: React.SFC<ProgressBarProps> = (props) => (
    <div className={wrapperStyle}>
        {props.percent === 0 ? null :
            <div className={outerStyle}>
                <div className={innerStyle} style={{ width: `${props.percent}%` }}></div>
            </div>
        }
    </div>
)

export default HeaderProgressBar;