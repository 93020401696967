import React from 'react';
import { css } from '@emotion/css';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import SelectField from '../../../components/forms/SelectField';
import CheckboxField from '../../../components/forms/CheckboxField';
import FieldError from '../../../components/forms/FieldError';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import RepromptModel from '../../../models/features/api/Reprompt/RepromptModel';
import RepromptContainer from '../../../state/containers/RepromptContainer';
import { dark_grey_blue, cool_grey } from '../../../constants/colors';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';
import Button from '../../../components/general/Button';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import PageError from '../../../components/general/PageError';
import ContentCard from '../../../components/structure/ContentCard';
const repromptCardIcon = require('../../../content/images/content-tree-icons/content-level/reprompt.svg');
const checkIcon = require('../../../content/images/check-circle.svg');
interface ManageRepromptFormProps {
    applicationId: string
    reprompt?: RepromptModel
    repromptContainer: RepromptContainer
    groupIndex?: number
    onChange?: (reprompt: RepromptModel) => void
    onManage?: () => void
}
class ManageRepromptForm extends React.Component<ManageRepromptFormProps> {
    componentDidMount() {
        // TODO: investigate the many calls to this and optimize
        this.props.repromptContainer.getRepromptForApp(this.props.applicationId);
    }
    handleSelectionChange(e) {
        const finalValue = e === null ? null : e.value;
        var reprompt: RepromptModel = null;
        if (finalValue == "new") {
            reprompt = {
                content: '',
                includeFollowUp: false
            }
        }
        else if (finalValue) {
            reprompt = this.props.repromptContainer.state.reprompt.find(f => f.id == finalValue)
        }
        if (this.props.onChange) this.props.onChange(reprompt);
    }
    getSelectedReprompt() {
        if (this.props.reprompt) {
            if (this.props.reprompt.name) {
                return this.props.reprompt.id;
            }
            else {
                return 'new';
            }
        }
        return '';
    }
    handleRepromptTextChange(ssml: string) {
        const reprompt = this.props.reprompt;
        reprompt.content = ssml;
        this.props.onChange(reprompt);
    }
    handleIncludeFollowUpChange(e) {
        const reprompt = this.props.reprompt;
        reprompt.includeFollowUp = e.target.checked;
        this.props.onChange(reprompt);
    }

    handleDuplicate(reprompt: RepromptModel) {
        this.props.onChange({
            content: reprompt.content,
            includeFollowUp: reprompt.includeFollowUp
        })
    }
    render() {
        if (this.props.repromptContainer.state.isLoading) {
            return <SecondaryLoader />
        }
        return (
            <div className={containerStyle}>
                <ContentCard title="Reprompt" icon={repromptCardIcon}>
                    <div className="inner-wrapper" id={`g${this.props.groupIndex}reprompt`}>
                        <div>
                            <p className="detail">If the user doesn’t say anything after a period of time (several seconds)</p>
                            <FormFieldsContainer>
                                <SelectField
                                    options={[
                                        { value: null, label: '- None -' },
                                        { value: 'new', label: '- Custom / New -' },
                                        ...this.props.repromptContainer.state.reprompt.filter(f => f.name != undefined)
                                            .map(f => ({ value: f.id, label: f.name }))
                                    ]}
                                    value={this.getSelectedReprompt()}
                                    onBlur={() => { }}
                                    onChange={this.handleSelectionChange.bind(this)}
                                    useFormTheme={true}
                                    label="Select a reprompt:"
                                    placeholder="- None -"
                                    clearValueText="- None -"
                                    clearable={true}
                                    name="existingReprompt" />
                                {this.props.reprompt ?
                                    <>
                                        <HorizontalSeparator />
                                        <p className="header sub-header"><i>Reprompt:</i> {this.props.reprompt.name ? this.props.reprompt.name : "Untitled"}</p>
                                        {this.props.reprompt.name ?
                                            <div>
                                                <p className="label">Reprompt Language</p>
                                                <p className="preview"><i>{this.props.reprompt.content}</i></p>
                                            </div>
                                            : <SsmlField placeholder="What should the device say?"
                                                value={this.props.reprompt ? this.props.reprompt.content : ''}
                                                label="Reprompt Language:"
                                                applicationId={this.props.applicationId}
                                                onChange={this.handleRepromptTextChange.bind(this)}
                                                onBlur={() => { }}
                                                name="repromptContent" />}
                                        {this.props.reprompt.name ?
                                            <div className="check-container">
                                                <img src={checkIcon} />
                                                <p>Repeat follow-up</p>
                                            </div>
                                            : <CheckboxField
                                                name="includeFollowUp"
                                                checked={this.props.reprompt ? this.props.reprompt.includeFollowUp : false}
                                                onChange={this.handleIncludeFollowUpChange.bind(this)}
                                                label="Then repeat previous Follow-up" />
                                        }
                                        <FieldError name="reprompt" />
                                        <HorizontalSeparator />
                                        {this.getSelectedReprompt() == "new" ?
                                            <Button themes={["hollow", "flat"]} text="Save as..." type="button" onClick={this.props.onManage} />
                                            : <HorizontalContainer className="fill">
                                                <Button themes={["hollow", "flat"]} text="Edit" type="button" disabled={!this.props.reprompt} onClick={this.props.onManage} />
                                                <div style={{ marginLeft: 'auto', marginRight: 0 }}>
                                                    <Button themes={["hollow", "flat"]} text="Duplicate" type="button" disabled={!this.props.reprompt} onClick={this.handleDuplicate.bind(this, this.props.reprompt)} />
                                                </div>
                                            </HorizontalContainer>
                                        }
                                    </>
                                    : null}
                            </FormFieldsContainer>
                        </div>
                    </div>
                    <PageError errors={this.props.repromptContainer.state.errors} />
                </ContentCard>
            </div>
        )
    }
}

const containerStyle = css`
.inner-wrapper {
    display: flex;
    align-items: flex-start;
    img {
        margin-right: 16px;
    }
    .header {
        font-family: Muli;
        font-size: 18px;
        color: ${dark_grey_blue};
    }
    .sub-header {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .detail {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: ${cool_grey};
        margin-right: 16px;
    }

    >div {
        flex: 1;
    }

    .check-container {
        display: flex;
        align-items: center;
        margin: 24px 0;
    }

    .label {
        margin: 16px 0;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
    }
    .preview {
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
    }
}
`

export default ManageRepromptForm;