import React from 'react';
import ApiKeyContainer from '../../../state/containers/ApiKeyContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import _ from 'lodash';
import UserContainer from '../../../state/containers/UserContainer';
import DeploymentAccountsContainer from '../../../state/containers/DeploymentAccountsContainer';
import TabContainerPanel from '../../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import OrganizationInfoSettings from './OrganizationInfoSettings';
import ApiKeySettings from './ApiKeySettings';
import GenerateApiKeyModal from './GenerateApiKeyModal';
import OrganizationDeploymentAccounts from './OrganizationDeploymentAccounts';
import Button from '../../../components/general/Button';
import MemberContainer from '../../../state/containers/MemberContainer';
import { css } from '@emotion/css';

const apiKeyBlue = require('../../../content/images/organization-settings/api-key-blue.svg');
const apiKeyDark = require('../../../content/images/organization-settings/api-key-dark.svg');
const credentialsDark = require('../../../content/images/organization-settings/credentials-dark.svg');
const credentialsBlue = require('../../../content/images/organization-settings/credentials-blue.svg');
const basicInfoBlue = require('../../../content/images/organization-settings/basic-info-blue.svg');
const basicInfoDark = require('../../../content/images/organization-settings/basic-info-dark.svg');
const addIcon = require("../../../content/images/content-explorer/add-white.svg");

interface OrgnaizationSettingsProps {
    orgContainer: OrganizationContainer,
    userContainer: UserContainer,
    deploymentContainer: DeploymentAccountsContainer,
    apiKeyContainer: ApiKeyContainer,
    memberContainer: MemberContainer
    history: any
}
interface OrgnaizationSettingsState {
    isNewApiKeyModal: boolean
}
class OrganizationSettingsTabs extends React.Component<OrgnaizationSettingsProps, OrgnaizationSettingsState> {
    constructor(props) {
        super(props);
        this.state = {
            isNewApiKeyModal: false
        }
    }
    async componentDidMount() {
        if (!this.props.userContainer.state.currentUser) {
            await this.props.userContainer.getUser()
        }
        await this.props.memberContainer.checkIsOrgAdmin(this.props.orgContainer.state.currentOrganization?.id, this.props.userContainer.state.currentUser?.id);
    }
    getSelectedIndex() {
        if (location.href.indexOf("apiKeys") > -1) {
            return 1;
        }
        if (location.href.indexOf("credentials") > -1) {
            return 2;
        }
        return 0;
    }

    handleTabSelection(index: number, last: number) {
        if (index == last) return;
        const urlStart = "/v/orgSettings/";

        if (index == 0) {
            this.props.history.push(urlStart + window.location.search)
        }
        if (index == 1) {
            this.props.history.push(urlStart + "apiKeys" + window.location.search)
        }
        if (index == 2) {
            this.props.history.push(urlStart + "credentials" + window.location.search)
        }
    }

    renderAddNewApiKeyButton() {
        if (this.getSelectedIndex() == 1 && this.props.memberContainer.state.isOrgAdmin) {
            return (
                <Button
                    themes={['primary-small']}
                    text="Add New"
                    onClick={() => this.toggleNewApiKeyModal()}
                    type="button"
                    rightIcon={addIcon} />
            );
        }
        return null;
    }

    toggleNewApiKeyModal() {
        this.setState({
            ...this.state,
            isNewApiKeyModal: !this.state.isNewApiKeyModal
        });
    }
    render() {
        return (
            <TabContainerPanel
                selectedIndex={this.getSelectedIndex()}
                onTabSelected={this.handleTabSelection.bind(this)}
                tabs={[{
                    title: 'Basic Info',
                    icon: basicInfoDark,
                    className: "ac-org-settings-basic-info",
                    selectedIcon: basicInfoBlue
                }, {
                    title: 'Api Keys',
                    icon: apiKeyDark,
                    className: "ac-org-settings-api-keys",
                    selectedIcon: apiKeyBlue
                }, {
                    title: 'Credentials',
                    icon: credentialsDark,
                    className: "ac-org-settings-credentials",
                    selectedIcon: credentialsBlue
                }]}
                additionalHeaderContent={this.renderAddNewApiKeyButton.bind(this)}>
                <TabPanel>
                    <ScrollablePanelBody className={paddedBody}>
                        <OrganizationInfoSettings orgContainer={this.props.orgContainer} />
                    </ScrollablePanelBody>
                </TabPanel>
                <TabPanel>
                    <ApiKeySettings isOrgAdmin={this.props.memberContainer.state.isOrgAdmin} orgContainer={this.props.orgContainer} apiKeyContainer={this.props.apiKeyContainer} />
                    {this.state.isNewApiKeyModal &&
                        <GenerateApiKeyModal apiKeyContainer={this.props.apiKeyContainer} onClose={this.toggleNewApiKeyModal.bind(this)} organizationId={this.props.orgContainer.state.currentOrganization?.id} />
                    }
                </TabPanel>
                <TabPanel>
                    <ScrollablePanelBody className={paddedBody}>
                        <OrganizationDeploymentAccounts organizationId={this.props.orgContainer.state.currentOrganization.id} deploymentAccountsContainer={this.props.deploymentContainer} />
                    </ScrollablePanelBody>
                </TabPanel>
            </TabContainerPanel>
        )
    }
}

const paddedBody = css`
padding: 32px;
`
export default OrganizationSettingsTabs;