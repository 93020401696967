import styled from "@emotion/styled";
import React from "react";
import DropDownSelectField from "../../../components/forms/DropDownSelectField";
import HorizontalOptionsSelector from "../../../components/forms/HorizontalOptionsSelector";
import ContentCard from "../../../components/structure/ContentCard";
import FormFieldsContainer from "../../../components/structure/FormFieldsContainer";
import { color_colors_ocean, color_shades_dark, color_shades_darker, color_shades_darkest, color_variants_ocean_xlight } from "../../../constants/colors";
import { HighlightSection } from "../../formScenes/BaseConversationItemForm";
import { SensitiveConversationChange } from '../../../models/features/api/ContentItemModel';
import { ArrowRendererProps, MenuRendererProps } from "react-select";
import ToggleIndicator from "../../../components/general/ToggleIndicator";
import TooltipWrapper from "../../../components/general/TooltipWrapper";
import Tooltip from "rc-tooltip";


const computerLockDark = require('../../../content/images/data-sensitivity/computer-lock-large.svg');
const noChangeDark = require('../../../content/images/data-sensitivity/computer-no-change-dark.svg');
const computerLockSideDark = require('../../../content/images/data-sensitivity/computer-lock.svg');
const computerLockSideBlue = require('../../../content/images/data-sensitivity/computer-lock-blue.svg');
const computerWarningDark = require('../../../content/images/data-sensitivity/computer-warning-dark.svg');
const computerWarningBlue = require('../../../content/images/data-sensitivity/computer-warning-blue.svg');
const helpIcon = require('../../../content/images/question-mark.svg');
const noChangeBlue = require('../../../content/images/data-sensitivity/computer-no-change-blue.svg');

interface SensitiveDataOptionsProps {
    selectedOption: SensitiveConversationChange,
    onChange: (value: SensitiveConversationChange) => void
}

const options = [
    { selectedImage: noChangeBlue, value: "None" as SensitiveConversationChange, label: "No change", image: noChangeDark, description: "No change to secure conversation state (default)." },
    { value: "StartSensitiveConversation" as SensitiveConversationChange, label: "Start Secure Conversation", image: computerLockSideDark, description: "Marks session as containing potentially sensitive data.", selectedImage: computerLockSideBlue },
    { value: "EndSensitiveConversation" as SensitiveConversationChange, label: "End Secure Conversation", image: computerWarningDark, description: "Ends session's secure conversation state.", selectedImage: computerWarningBlue }]


const SensitiveDataOptions: React.FC<SensitiveDataOptionsProps> = (props: SensitiveDataOptionsProps) => {
    const formatValueLabel = ({ label, image }) => (
        <CustomSelectChosenOption>
            <img src={image}></img>
            <p>{label}</p>
        </CustomSelectChosenOption>
    );
    return (
        <HighlightSection>
            <ContentCard title="Secure Conversation" icon={computerLockDark}>
                <FormFieldsContainer>
                        <Description>
                            Manage if this item represents the start or end of a secure conversation.
                            <TooltipWrapper
                                place="right"
                                text="If this content item is hit you can mark the user session<br />as a secure conversation. Conversations marked as sensitive<br />track no user input in your analytics and send all user input<br />related data to your webhooks encrypted with your webhook's<br />encrypted key. This continues until the user reaches a content<br />item that ends the secure conversation.">
                                    <HelpIcon src={helpIcon} />
                            </TooltipWrapper>
                        </Description>
                    <CustomDropDownSelect
                        className="ac-access"
                        clearable={false}
                        searchable={false}
                        valueRenderer={formatValueLabel}
                        options={options}
                        onChange={(value) => { props.onChange((value as any).value)}}
                        disabled={false}
                        value={props.selectedOption}
                        key="user-entry-selector"
                        arrowRenderer={(p: ArrowRendererProps) => <Arrow direction={p.isOpen ? 'up' : 'down'} />}
                        menuRenderer={(menuProps: MenuRendererProps) => {
                            return (<MenuOptionsWrapper>
                                <Triangle />
                                {options.map((option, index) =>
                                    <SelectOption key={index} className="user-entry-option" onClick={() => menuProps.selectValue(option)}>
                                        <CustomSelectOptionLabel>
                                            {option.image ? <ConversationAccessIcon src={menuProps.valueArray[0].value == option.value ? option.selectedImage : option.image}></ConversationAccessIcon> : null}
                                            <div>
                                                <p className={menuProps.valueArray[0].value == option.value ? "option-selected" : ""}>{option.label}</p>
                                                <p className={menuProps.valueArray[0].value == option.value ? "option-description-selected" : "option-description"}>{option.description}</p>
                                            </div>
                                        </CustomSelectOptionLabel>
                                        {index != menuProps.options.length - 1 && <Divider />}
                                    </SelectOption>
                                )}
                            </MenuOptionsWrapper>
                            )
                        }}
                    />
                </FormFieldsContainer>
            </ContentCard>
        </HighlightSection>
    );
};
const Description = styled.p`
    margin-bottom: 32px;
    display: flex;
`
const Arrow = styled(ToggleIndicator)`
    height: 8px;
    width: 8px;
`;
const MenuOptionsWrapper = styled.div`
    margin-top: 8px;
`
const SelectOption = styled.div`
    &.user-entry-option:hover{
            background-color: ${color_variants_ocean_xlight};
            cursor: pointer;
        }
`


const CustomDropDownSelect = styled(DropDownSelectField)`
    margin-top: 4px;
    margin-bottom: 16px;
    .Select-multi-value-wrapper{
        height: 48px;
    }
    .Select-menu-outer{
        border-top-color: ${color_shades_dark};
    }
    .Select-value{
        margin-top: 8px;
    }
    .Select-control {
        border-radius: 4px;
    }
    &.is-open  {
        .Select-control {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-color: ${color_shades_dark};
        }
    }
`

const CustomSelectChosenOption = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    p {
        color: ${color_shades_darkest};
        margin-left: 10px;   
    }
    img {
        height: 24px;
        width: 24px;
        margin-top: 4px;
    }
`;
const Triangle = styled.div`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    border-top: solid 1px ${color_shades_dark};
    border-left: solid 1px ${color_shades_dark};
    background: white;
    margin-top: -7px;
    right: 15px;
    position: absolute;
`;

const ConversationAccessIcon = styled.img`
    height: 24px;
    width: 24px;
    margin-top: 4px;
`

const Divider = styled.div`
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${color_shades_dark};
    padding: 0;
`;

const CustomSelectOptionLabel = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    div{
        line-height: normal;
        margin-left:10px; 
        color: ${color_shades_darkest};
    }
    .option-selected{
        color: ${color_colors_ocean};
    }
    .option-description{
        font-size: 14px;
        color: ${color_shades_darker}; 
    }
    .option-description-selected{
        font-size: 14px;
        color: ${color_colors_ocean}; 
    }
`;

const HelpIcon = styled.img`
    margin-left: 8px;
`;


export default SensitiveDataOptions;
