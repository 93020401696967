import initializeVoicifyAssistant from './ui/AssistantUI';

// export all the thing we want publicly exposed
export * from './models/customAssistantApiModels';
export * from './assistant/VoicifyAssistant';
export * from './models/speechToTextModels';
export * from './models/textToSpeechModels';
export * from './ui/AssistantUI';


if(window)
    window.initializeVoicifyAssistant = initializeVoicifyAssistant;
