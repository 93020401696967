import React from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { color_shades_darkest } from "../../../../constants/colors";
import { CopyButton } from "../SharedStyledComponents";
import Loader from "../../../../components/general/Loader";
import StyledDropdown from "./StyledDropdown";
import { CodeExportLanguage, codeExportLanguageOptions } from "../../../../models/customAssistant/CustomAssistantCodeExportTypes";
import CodeExportDescrption from "./CodeExportDescription";
import CodeBlock from "./CodeBlock";
import { javascriptCodeExportDescription, javascriptDocumentationInformation, javascriptDocumentationLink, javascriptDocumentationLinkName, kotlinDescription, kotlinDocumentationInformation, kotlinDocumentationLink, kotlinDocumentationLinkName, kotlinInstallationInstructions, kotlinInstallationTitle, kotlinPermissionsCode, kotlinPermissionsTitle, swiftDescription, swiftDocumentationInformation, swiftDocumentationLink, swiftDocumentationLinkName, swiftInstallationInstructions, swiftInstallationTitle, swiftPermissionsCode, swiftPermissionsTitle } from "../../../../constants/CustomAssistantConfigurationGuide";
import { CodeExportType } from "./CustomAssisantExportTypeDropdown";

interface ExportTabPanelProps {
    copyText?: (text: string) => void
    assistantSettingsExport?: string
    codeExportLanguage?: CodeExportLanguage
    handleCodeExportLanguageChange?: (optionValue: string, value: CodeExportLanguage) => void
    exportType?: CodeExportType
    handleExportTypeChange?: (optionValue: string, value: CodeExportType) => void
    isLoading: boolean
    hasPlatformConfigurations?: boolean
}

const ExportTabPanel: React.FC<ExportTabPanelProps> = ({ copyText, assistantSettingsExport, codeExportLanguage, handleCodeExportLanguageChange, exportType, handleExportTypeChange, isLoading, hasPlatformConfigurations }) => {

    const renderDescription = () => {
        switch (codeExportLanguage) {
            case "JavaScript":
                return (
                    <CodeExportDescrption
                        description={javascriptCodeExportDescription}
                        documentationInformation={javascriptDocumentationInformation}
                        documentationLink={javascriptDocumentationLink}
                        documentationLinkName={javascriptDocumentationLinkName}
                        exportType={exportType}
                        handleExportTypeChange={handleExportTypeChange}
                        hasPlatformConfigurations={hasPlatformConfigurations}
                    />
                )
            case "Swift":
                return (
                    <CodeExportDescrption
                        description={swiftDescription}
                        installationTitle={swiftInstallationTitle}
                        installationCode={swiftInstallationInstructions}
                        documentationInformation={swiftDocumentationInformation}
                        documentationLink={swiftDocumentationLink}
                        documentationLinkName={swiftDocumentationLinkName}
                        permissionsTitle={swiftPermissionsTitle}
                        permissionsCode={swiftPermissionsCode}
                        exportType={exportType}
                        handleExportTypeChange={handleExportTypeChange}
                        hasPlatformConfigurations={hasPlatformConfigurations}
                    />
                )
            case "Kotlin":
                return (
                    <CodeExportDescrption
                        description={kotlinDescription}
                        installationTitle={kotlinInstallationTitle}
                        installationCode={kotlinInstallationInstructions}
                        documentationInformation={kotlinDocumentationInformation}
                        documentationLink={kotlinDocumentationLink}
                        documentationLinkName={kotlinDocumentationLinkName}
                        permissionsTitle={kotlinPermissionsTitle}
                        permissionsCode={kotlinPermissionsCode}
                        exportType={exportType}
                        handleExportTypeChange={handleExportTypeChange}
                        hasPlatformConfigurations={hasPlatformConfigurations}
                    />
                )
        }
    }
    return (
        <ConfigurationTabContent>
            <HeaderWrapper>
                <SubHeader>Export content</SubHeader>
                <StyledDropdown
                    handleOnChange={handleCodeExportLanguageChange}
                    options={codeExportLanguageOptions}
                    size="medium"
                    value={codeExportLanguage}
                />
            </HeaderWrapper>
            {renderDescription()}
            <CopyButton className={copyButton} themes={["secondary-small"]} text="Copy Code Snippet" type="button" onClick={() => copyText(assistantSettingsExport)} />
            {isLoading ?
                <Loader />
                :
                <CodeBlock code={assistantSettingsExport} />
            }
        </ConfigurationTabContent>
    )

};

const copyButton = css`
    margin-right: 16px;
    margin-left: 0;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const SubHeader = styled.h2`
    flex: 1;
    font-size: 18px;
    color: ${color_shades_darkest};
    font-weight: 400;
`;

const ConfigurationTabContent = styled.div`
    padding: 24px 24px 0 24px;
`;

export default ExportTabPanel;