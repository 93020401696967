import React from 'react';
import FollowUpContainer from '../../../state/containers/FollowUpContainer';
import ParentContentContainer from '../../../state/containers/ParentContentContainer';
import ParentConversationItemSelector from './ParentConversationItemSelector';
import { mapContentContainerToGenericContentItemModels } from '../../../models/extensions/followUps';
import SiblingConversationItemSelector from './SiblingConversationItemSelector';
import ChildConversationItemSelector from './ChildConversationItemSelector';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import IResult from '../../../models/result/IResult';
import { getEditUrl } from '../../../models/extensions';
import GroupedConversationItem from '../../../models/features/GroupedConversationItem';
import FollowUpModel from '../../../models/features/api/FollowUp/FollowUpModel';

interface NavigationMenuProps {
    featureTypeId: string
    contentId: string
    followUp?: FollowUpModel
    followUpContainer: FollowUpContainer
    parentContentContainer: ParentContentContainer
    onClose?: () => void
    onSave?: () => Promise<IResult<any>>
    history: any
    conversationItem?: GenericContentItemModel
    parentContentId?: string
}
interface NavigationMenuState {
    parentItems: GenericContentItemModel[],
    siblingItems: GroupedConversationItem[],
    isSaving: boolean
}
class NavigationMenu extends React.Component<NavigationMenuProps, NavigationMenuState> {
    state = {
        parentItems: null as GenericContentItemModel[],
        siblingItems: [] as GroupedConversationItem[],
        isSaving: false
    }
    componentDidMount() {
        this.loadParents();
    }
    componentDidUpdate(prevProps: NavigationMenuProps) {
        if (prevProps.parentContentId != this.props.parentContentId) {
            this.setState({
                ...this.state,
                isSaving: false
            })
        }
    }
    private loadParents() {
        this.props.parentContentContainer.getParentContentItems(this.props.featureTypeId, this.props.contentId)
            .then(result => {
                if (result.resultType == "Ok") {
                    this.setState({
                        ...this.state,
                        parentItems: mapContentContainerToGenericContentItemModels(result.data)
                    }, this.loadSiblings);
                }
            })
    }
    private loadSiblings() {
        if (this.state.parentItems && this.state.parentItems.length > 0) {
            this.state.parentItems.forEach(c => {
                if (c.followUpId) {
                    this.props.followUpContainer.findById(c.followUpId)
                        .then(result => {
                            if (result.resultType == "Ok") {
                                const siblingItems = this.state.siblingItems;
                                siblingItems.push({
                                    parent: c,
                                    children: mapContentContainerToGenericContentItemModels(result.data.childContentContainer).filter(c => c.id != this.props.contentId)
                                });
                                this.setState({
                                    ...this.state,
                                    siblingItems: siblingItems
                                })
                            }
                        })
                }
            });
        }
        
    }
   
    private navigateToItem(conversationItem: GenericContentItemModel) {
        this.props.history.push(`${getEditUrl(conversationItem.id, conversationItem.applicationFeatureId, conversationItem.featureTypeId)}?previousContentId=${this.props.contentId}`);
    }
    handleItemSelected(conversationItem: GenericContentItemModel) {
        this.setState({
            ...this.state,
            isSaving: true
        })
        if (this.props.onSave) {
            const promise = this.props.onSave();
            if (!promise) {
                this.setState({
                    ...this.state,
                    isSaving: false
                })
            }
            else {
                promise.then(result => {
                    if (result?.resultType == "Ok") {
                        this.setState({
                            ...this.state,
                            isSaving: false
                        })
                        this.navigateToItem(conversationItem);
                    }
                    else {
                        this.setState({
                            ...this.state,
                            isSaving: false
                        })
                    }
                }).catch(() => {
                    this.setState({
                        ...this.state,
                        isSaving: false
                    })
                })
            }
        }
        else {
            this.navigateToItem(conversationItem);
        }
    }

    getContextualParent() {
        if (this.state.parentItems && this.props.parentContentId) {
            return this.state.parentItems.find(p => p.id == this.props.parentContentId);
        }
        return null;
    }

    render() {
        return (<>
            <ParentConversationItemSelector
                currentConversationItem={this.getContextualParent()}
                conversationItems={this.state.parentItems}
                onSelect={this.handleItemSelected.bind(this)}
                isLoading={this.props.parentContentContainer.state.isLoading}
                isSaving={this.state.isSaving} />
            <SiblingConversationItemSelector
                currentConversationItem={this.props.conversationItem}
                conversationItems={this.state.siblingItems}
                onSelect={this.handleItemSelected.bind(this)}
                isLoading={this.props.followUpContainer.state.isLoadingOne}
                isSaving={this.state.isSaving} />
            <ChildConversationItemSelector
                conversationItems={this.props.followUp?.childContentContainer?.contentItems}
                contextualHelp={this.props.followUp?.helpMessage}
                contextualFallback={this.props.followUp?.fallbackMessage}
                isLoading={this.props.followUpContainer.state.isLoadingOne}
                onSelect={this.handleItemSelected.bind(this)}
                isSaving={this.state.isSaving} />
        </>)
    }
}
export default NavigationMenu

