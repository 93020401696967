import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Subscribe } from 'unstated';
import TextField from '../../components/forms/TextField';
import Button from '../../components/general/Button';
import ModalHeader from '../../components/general/ModalHeader';
import PageError from '../../components/general/PageError';
import WarningLabel from '../../components/general/WarningLabel';
import FooterBar from '../../components/structure/FooterBar';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import SmallModalLayout from '../../components/structure/SmallModalLayout';
import { color_colors_gold, color_shades_dark, color_shades_darker, color_text_default, color_variants_gold_light } from '../../constants/colors';
import ContentItemReferenceModel from '../../models/applications/api/backups/ContentItemReferenceModel';
import { getFeatureContentUrl } from '../../models/extensions';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import FeatureContainer from '../../state/containers/FeatureContainer';
const darkPage = require('../../content/images/dark-page.svg');
const darkFolderFile = require('../../content/images/dark-folder-file.svg');
const yellowInfoCircle = require('../../content/images/info-circle-yellow.svg');
const doorLocked = require('../../content/images/no-entry-door-locked.svg');
const arrowRight = require('../../content/images/arrow-right.svg')
const doorUnlocked = require('../../content/images/open-entry-door-unlocked.svg');

interface InnerContentItemPublishFormProps extends PartialPublishFormProps {
    backupsContainer: ApplicationBackupVersionsContainer
    environmentsContainer: ApplicationEnvironmentsContainer
    featureContainer: FeatureContainer
    environmentId: string
}
const InnerPartialPublishForm: React.FC<InnerContentItemPublishFormProps> = (props) => {
    const [name, setName] = useState(props.isUnpublishing ? "Unpublished" : "Published");
    const [publishingContentItems, setPublishingContentItems] = useState([] as ContentItemReferenceModel[]);
    const [loading, setLoading] = useState(true);
    const [selectedContentCount, setSelectedContentCount] = useState(0);
    const [contentInFoldersCount, setContentInFoldersCount] = useState(0);
    const [warningIsVisible, setWarningIsVisible] = useState(true);
    const rootAction = props.isUnpublishing ? "Unpublish" : "Publish";

    useEffect(() => {
        const initialLoad = async () => {
            const hasFolders = !(props.applicationFeatureIds === undefined || props.applicationFeatureIds.length == 0);
            if (props.defaultContentItemName && props.contentItems?.length == 1 && !hasFolders) {
                setName(`${rootAction}ing ${props.defaultContentItemName}`);
                setPublishingContentItems(props.contentItems);
                setSelectedContentCount(1);
            }
            else {
                let toPublish = [...props.contentItems];
                setSelectedContentCount(toPublish.length);
                if (hasFolders) {
                    let folders = [] as string[];
                    const loadChildFolders = async (folderId: string) => {
                        const childFolders = await props.featureContainer.getFeaturesForParent(folderId);
                        if (!(childFolders === undefined || childFolders.length == 0)) {
                            childFolders.forEach((f) => loadChildFolders(f.id));
                        }
                        folders = [...folders, folderId]
                    }
                    for(let i = 0; i < props.applicationFeatureIds.length; i++)
                    {
                        await loadChildFolders(props.applicationFeatureIds[i]);
                    }
                    let contentInFolders = [] as ContentItemReferenceModel[];
                    for (let i = 0; i < folders.length; i++) {
                        const folderId = folders[i];
                        const content = await props.featureContainer.getContentItemsForAppFeature(folderId);
                        contentInFolders = [...contentInFolders, ...content.data.map((c => {
                            return {
                                contentId: c.id,
                                featureTypeId: c.featureTypeId
                            };
                        }))];
                    }
                    setContentInFoldersCount(contentInFolders.length);
                    toPublish = [...toPublish, ...contentInFolders];
                }
                let defaultName = `${rootAction}ed 1 content item`;
                if (toPublish.length > 1) {
                    defaultName = `${rootAction}ed ${toPublish.length} content items`;
                }

                setName(defaultName);
                setPublishingContentItems(toPublish);
            }

            await setLoading(false);
        };

        initialLoad();
    }, [props.isUnpublishing])

    const handleSubmit = async () => {
        setLoading(true);

        if (props.isUnpublishing) {
            await props.environmentsContainer.unpublishContentItems(props.environmentId, { 
                name: name,
                contentItems: publishingContentItems
            });
            setLoading(false);
            props.onClose();
            return;
        } else {
            const backupAndPublishResult = await props.environmentsContainer.queuePartialBackupAndPublish(props.applicationId, 
                props.environmentId, 
                { 
                    name: name ?? `${rootAction}ed`, 
                    description: '', 
                    contentItems: publishingContentItems 
                }
            );
            
            if (backupAndPublishResult?.resultType == "Ok") { 
                await props.environmentsContainer.addPublish(backupAndPublishResult.data.publish);
                props.backupsContainer.waitForBackup(backupAndPublishResult.data.backup.id);
                props.environmentsContainer.waitForPublish(backupAndPublishResult.data.publish.id);
                props.history.push(getFeatureContentUrl(props.applicationId, props.applicationFeatureId));
                setLoading(false);
                props.onClose();
                return;
            }
        }

        // Error occured
        setLoading(false);
    }
    const handlePublishModalWarningClose = () => {
        setWarningIsVisible(false);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        if (loading) return;

        if (e.key === "Enter") {
            handleSubmit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <PartialPublishModal isVisible onClose={props.onClose} >
            <ModalForm tabIndex={0} onKeyDown={handleKeyDown}>
                <ModalHeader title={rootAction} onClose={props.onClose} />
                <div className="modal-body">
                    <PublishConfirmationHeader>Kick off a Partial {rootAction}?</PublishConfirmationHeader>
                    <p className="margin-bottom">This will {rootAction.toLocaleLowerCase()} all changes found in the selected content item(s). Any content items that share components like follow-ups or reprompts will not be {rootAction.toLocaleLowerCase()}ed.</p>
                    {(props.showClosedAccessWarning && warningIsVisible && !props.isUnpublishing) && 
                    <WarningLabel onClose={handlePublishModalWarningClose.bind(this)} closable={true} image={yellowInfoCircle} text='You are publishing an item set to "No Access" and it will not be hit by users' />}
                    <TextField autoFocus useForm={false} value={name} name="name" placeholder="Enter name here" label="Name"
                        onChange={e => setName(e.target.value)} readOnly={loading} />
                    <HorizontalSeparator />
                    <PublishConfirmationHeader className="summary">Summary</PublishConfirmationHeader>
                    <p className="margin-bottom">{`${rootAction}ing your selected content`}</p>
                    {selectedContentCount > 0 &&
                        <SummaryRow>
                            <img src={darkPage} />
                            <p>{`${selectedContentCount} Conversation item${selectedContentCount == 1 ? '' : 's'}`}</p>
                        </SummaryRow>
                    }
                    {contentInFoldersCount > 0 &&
                        <SummaryRow>
                            <img src={darkFolderFile} />
                            <p>{`${contentInFoldersCount} Conversation item${contentInFoldersCount == 1 ? '' : 's'} in selected folders`}</p>
                        </SummaryRow>
                    }
                </div>
                <FooterBar>
                    <Button type="button" themes={['primary', 'start']} text={rootAction} onClick={handleSubmit} disabled={loading} loading={loading} />
                    <Button type="button" themes={['secondary', 'end']} text="Cancel" onClick={() => props.onClose()} disabled={loading} loading={loading} />
                </FooterBar>
                <PageError errors={[
                    ...props.backupsContainer?.state?.errors ?? [],
                    ...props.environmentsContainer?.state?.errors ?? []
                ]} onClear={() => {
                    props.backupsContainer.clearErrors();
                    props.environmentsContainer.clearErrors();
                }} />
            </ModalForm>
        </PartialPublishModal>
    );
}

interface PartialPublishFormProps {
    applicationId: string
    defaultContentItemName?: string
    applicationFeatureIds?: string[]
    contentItems?: ContentItemReferenceModel[]
    applicationFeatureId: string
    history: any
    showClosedAccessWarning?: boolean
    onClose: () => void
    isUnpublishing?: boolean
}
const PartialPublishForm: React.FC<PartialPublishFormProps> = (props) => (
    <Subscribe to={[ApplicationBackupVersionsContainer, ApplicationEnvironmentsContainer, FeatureContainer]}>
        {(backupsContainer: ApplicationBackupVersionsContainer, environmentsContainer: ApplicationEnvironmentsContainer, featureContainer: FeatureContainer) =>
        (<InnerPartialPublishForm {...props}
            featureContainer={featureContainer}
            environmentId={environmentsContainer.findPrimaryEnvironment(props.applicationId)?.id}
            backupsContainer={backupsContainer}
            environmentsContainer={environmentsContainer} />)}
    </Subscribe>
)

const SummaryRow = styled.div`
    display: flex;
    margin: 8px 0px 16px 0px;
    p {
        margin-bottom: 0px;
    }
    img {
        margin-right: 8px;
    }
`;

const PublishConfirmationHeader = styled.h5`
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${color_text_default};
    margin-top: 24px;
    margin-bottom: 16px;
    &.summary {
        margin: 16px 0 8px 0;
    }
`
const PartialPublishModal = styled(SmallModalLayout)`
    max-width: 615px;
`

const ModalForm = styled.div`
    min-height: 600px;
    height: auto;
    .modal-body{
        padding: 0 32px;
        margin-bottom: 150px;
        height: 100%;
        flex: 1;
        h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
        .margin-bottom {
            margin: 0 0 24px 0;
        }
    }
`
export default PartialPublishForm;