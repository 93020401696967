import { Container } from 'unstated'

interface ContentTreeState {
    expandedIds: string[]
    selectedId: string
}

class ContentTreeStateContainer extends Container<ContentTreeState> {
    state = {
        expandedIds: [] as string[],
        selectedId: null
    }

    setExpandedIds(ids: string[]) {
        this.setState({
            ...this.state,
            expandedIds: ids
        })
    }

    setSelectedId(id: string) {
        this.setState({
            ...this.state,
            selectedId: id
        })
    }

    addExpandedId(id: string) {
        if (this.state.expandedIds.some(i => i == id)) return;
        const expandedIds = this.state.expandedIds;
        expandedIds.push(id);
        this.setState({
            ...this.state,
            expandedIds
        })
    }
}

export default ContentTreeStateContainer;