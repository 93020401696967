import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import GoogleMobileScreenWrapper from './GoogleMobileScreenWrapper';
const playIcon = require('../../../content/images/baseline-play_arrow-24px.svg')
class GoogleMobileMediaResponsePreview extends ScreenPreviewComponent {

    render() {
        return (
            <GoogleMobileScreenWrapper className=''>
                <div className="body-container">
                    <p className="body-text">
                        <span>{this.state.displayText}</span>

                        {this.state.followUpText ?
                            <>
                                <br /><br />
                                <span className="followup-text">{this.state.followUpText}</span>
                            </>
                            : null}
                    </p>
                    {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                    <p className="detail-text">
                        <span>{this.state.displayText}</span>
                    </p>
                    {this.state.foregroundImageUrl
                        ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                        : null}
                </div>
                <div className="media-container">
                    <div className="scrub-bar"></div>
                    <div className="scrub-handle"></div>
                    <div className="time-container">
                        <p>--.--</p>
                        <p className="end">--.--</p>
                    </div>
                    <img className="play-icon" src={playIcon}/>
                </div>
                <div className="chip-container">
                    {this.state.hints ? this.state.hints
                        .filter(h => h.content.length <= 20)
                        .map((h, i) => <span key={i} className="hint-chip">{h.content}</span>)
                        : null}
                </div>
            </GoogleMobileScreenWrapper>
        )
    }
}

export default GoogleMobileMediaResponsePreview;