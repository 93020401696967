import React from 'react';
import { css } from '@emotion/css';
import { color_text_default, color_text_light } from '../../../constants/colors';

const emptyImage = require('../../../content/images/content-explorer/empty.svg');

const EmptyView = (props: { text?: string, subtext?: string }) => (
    <div className={containerStyle}>
        <img src={emptyImage} />
        <h4>{props.text ?? "It's quiet in here!"}</h4>
        <p>{props.subtext ?? "Try adding some new content"}</p>
    </div>
)

const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        font-size: 24px;
        line-height: 32px;
        margin-top: 32px;
        color: ${color_text_default};
        text-align: center;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: ${color_text_light};
    }
`

export default EmptyView;