import React, { FocusEvent } from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, silver_two, faded_red, cool_grey, color_text_default, color_shades_darkest, color_text_light, color_shades_light } from '../../constants/colors';
import { Field } from 'formik';
import FieldError from './FieldError';
import Button from '../general/Button';
import IconTooltip from '../general/IconTooltip';
import VerticalSeparator from '../structure/VerticalSeparator';
import TooltipWrapper from '../general/TooltipWrapper';
const deleteIcon = require('../../content/images/trash.svg');

// component used in building forms with consistent components for look and function
interface TitleTextFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: FocusEvent<any>) => void
    onDelete?: () => void
    label?: string
    id?: string
    canDelete?: boolean
    required?: boolean
    tooltip?: string
}

class TitleTextField extends React.Component<TitleTextFieldProps> {
    render() {
        const props = {
            ...this.props
        };

        return (
            <div className={containerStyle}>
                <div className={labelContainer}>
                    {props.canDelete ? <Button additionalClasses={deleteIconStyle} type="button" onClick={props.onDelete} themes={["icon"]} icon={deleteIcon} /> : null}
                    {props.tooltip !== undefined ? <IconTooltip text={props.tooltip} className={tooltipStyle} place="top" /> : null}
                    {props.required ? <TooltipWrapper text="Required field"><span className={requiredLabelStyle}>*</span></TooltipWrapper> : null}
                </div>
                <label htmlFor={props.name} className={labelStyle}>{props.label}</label>
                <div className={fieldContainer}>
                    <Field id={props.id}
                        className={textFieldStyles}
                        type={props.type}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        disabled={props.disabled} />
                </div>
                <FieldError name={props.name} />
            </div>
        );
    }
}
const textFieldStyles = css`
    color: ${color_shades_darkest};
    text-align: left;
    background: transparent;
    border: none;
    height: 60px;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    padding: 0 24px;
    font-family: Muli;
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        letter-spacing: normal;
        color: ${cool_grey};
    }
    &:disabled {
        background: ${color_shades_light};
    }
`;
const containerStyle = css`
margin: 24px 0;

`
const labelStyle = css` 
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: ${color_text_default};
    margin: 0 0 0 24px;
`;

const deleteIconStyle = css`
    height: 14px;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 0;
    img {
        height: 14px;
    }
`


const tooltipStyle = css`

    margin: 0;
    margin-top: 24px;
    margin-bottom: 0;
`

const labelContainer = css`
display: flex;
align-items: center;
`

const fieldContainer = css`
display: flex;
align-items: center;
height: 64px;
width: 100%;
border-radius: 5px;
border: solid 1px ${silver_two}; 
background: white;
overflow: hidden;
margin-top: 8px;
`
const requiredLabelStyle = css`
    margin-right: 0;
    margin-left: auto;
    margin-top: 14px;
    margin-bottom: -20px;
    font-family: Muli;
    font-size: 24px;
    color: ${faded_red};
`
export default TitleTextField;