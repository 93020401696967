import { InjectedFormikProps, withFormik } from "formik";
import _ from 'lodash';
import React from 'react';
import { TabPanel } from 'react-tabs';
import * as yup from 'yup';
import MultiFileSelector from '../../../components/forms/MultiFileSelector';
import Button from '../../../components/general/Button';
import Loader from '../../../components/general/Loader';
import PageTitle from '../../../components/general/PageTitle';
import FooterBar from '../../../components/structure/FooterBar';
import FormContainer from '../../../components/structure/FormContainer';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import ImageInnerTabs from '../../../components/structure/ImageInnerTabs';
import PageContainer from '../../../components/structure/PageContainer';
import SplitContainer from '../../../components/structure/SplitContainer';
import MediaResponseContainerModel from '../../../models/features/api/MediaResponses/MediaResponseContainerModel';
import UpdateBulkMediaResponseContainerRequest from '../../../models/features/api/MediaResponses/UpdateBulkMediaResponseContainerRequest';
import MediaItemModel from '../../../models/media/MediaItemModel';
import StagedMediaItem from '../../../models/media/StagedMediaItem';
import MediaContainer from '../../../state/containers/MediaContainer';
import MediaResponseStateContainer from '../../../state/containers/MediaResponseContainer';
import AllowedMediaLabel from './AllowedMediaLabel';
import { AttachedMediaContainer } from './AttachedMediaContainer';
import AudioResponseBuilder from './AudioResponseBuilder';
import ChatResponseBuilder from './ChatResponseBuilder';
import FileStageCard from './FileStageCard';
import { ResponseDetailContainer } from './ResponseDetailContainer';
import ScreenResponseBuilder from './ScreenResponseBuilder';
import DefaultMedia from "../../../models/features/api/DefaultMedia";
import { css } from '@emotion/css';
import TabbedLayout from "../../../components/structure/TabbedLayout";
import TabPanelBody from "../../../components/structure/TabPanelBody";
import MediaLibraryTabbedLayout from "../../../components/structure/MediaLibraryTabbedLayout";
import TextField from "../../../components/forms/TextField";
import MediaLibraryTab from "./MediaLibraryTab";
import PageError from "../../../components/general/PageError";
import { GigabytesSize } from "../../../constants/UploadFileSize";
const outputIcon = require('../../../content/images/device.svg');
const speakerIcon = require('../../../content/images/speaker.svg');
const speakerSelectedIcon = require('../../../content/images/speaker-blue.svg');
const screenIcon = require('../../../content/images/screen.svg');
const screenSelectedIcon = require('../../../content/images/screen-blue.svg');
const chatIcon = require('../../../content/images/chat-icon.svg');
const chatSelectedIcon = require('../../../content/images/chat-icon-blue.svg');

export interface MediaManagerFormData {
    imageItem?: MediaItemModel
    backgroundImageItem?: MediaItemModel
    audioItem?: MediaItemModel
    videoItem?: MediaItemModel
    displayTitleOverride?: string
    displayTextOverride?: string
    container?: UpdateBulkMediaResponseContainerRequest
}
export interface MediaManagerData extends MediaManagerFormData {
    fullContainer: MediaResponseContainerModel
}

interface MediaManagerFormProps {
    mediaContainer: MediaContainer
    stateContainer: MediaResponseStateContainer
    applicationId: string
    onFinish: (data: MediaManagerData) => void
    onClose?: () => void
    onChange?: () => void
    imageItem?: MediaItemModel
    audioItem?: MediaItemModel
    videoItem?: MediaItemModel
    backgroundImageItem?: MediaItemModel
    displayTitleOverride?: string
    displayTextOverride?: string
    mediaResponseContainerId?: string
    container?: MediaResponseContainerModel
}

interface MediaManagerFormState {
    pendingFiles: StagedMediaItem[]
    mediaItems: MediaItemModel[]
}
class InnerForm extends React.Component<InjectedFormikProps<MediaManagerFormProps, MediaManagerFormData>, MediaManagerFormState> {
    componentWillMount() {
        this.setState({
            pendingFiles: [] as StagedMediaItem[],
            mediaItems: [] as MediaItemModel[],
        })

        this.loadContainer();

    }
    componentDidUpdate(prevProps: MediaManagerFormProps) {
        if (this.props.mediaResponseContainerId != prevProps.mediaResponseContainerId) {
            this.loadContainer()
        }

    }

    private loadCompatMediaItems() {
        const mediaItems = this.state.mediaItems;

        if (this.props.imageItem) {
            if (!mediaItems.some(m => m.id == this.props.imageItem.id))
                mediaItems.push(this.props.imageItem);
        }
        if (this.props.backgroundImageItem) {
            if (!mediaItems.some(m => m.id == this.props.backgroundImageItem.id))
                mediaItems.push(this.props.backgroundImageItem);
        }
        if (this.props.audioItem) {
            if (!mediaItems.some(m => m.id == this.props.audioItem.id))
                mediaItems.push(this.props.audioItem);
        }
        if (this.props.videoItem) {
            if (!mediaItems.some(m => m.id == this.props.videoItem.id))
                mediaItems.push(this.props.videoItem);
        }

        this.setState({
            ...this.state,
            mediaItems: mediaItems
        })
    }
    private async loadContainer() {
        await this.props.mediaContainer.getMediaItemsForApp(this.props.applicationId);
        this.props.stateContainer.getDeviceTargets().then(() =>
            this.loadCompatMediaItems());

        if (this.props.container && this.props.container.responses.length > 0) {

            if (this.props.container.additionalMediaItems != null && this.props.container.additionalMediaItems.length > 0) {
                this.setState({
                    ...this.state,
                    mediaItems: this.props.container.additionalMediaItems
                })
            }
            this.props.setFieldValue("container", {
                ...this.props.container,
                additionalMediaItemIds: this.props.container.additionalMediaItems.map(m => m.id),
            })
        }
        else if (this.props.mediaResponseContainerId) {
            await this.props.stateContainer.getMediaResponseContainer(this.props.mediaResponseContainerId).then(containerResult => {
                if (containerResult.resultType == "Ok") {
                    if (containerResult.data.additionalMediaItems != null && containerResult.data.additionalMediaItems.length > 0) {
                        this.setState({
                            ...this.state,
                            mediaItems: containerResult.data.additionalMediaItems
                        })
                    }
                    this.props.setFieldValue("container", {
                        ...containerResult.data,
                        additionalMediaItemIds: [],
                    })
                }
            });
        }
        else {
            // create initial
            this.props.setFieldValue("container", {
                additionalMediaItemIds: [],
                responses: []
            }
            );
        }
    }
    handleDiscardChanges() {
        this.props.onClose();
    }

    handleStagedFileUpdate(file: StagedMediaItem, name: string, caption: string) {
        var files = this.state.pendingFiles;
        const existingFile = files.find(f => f.file == file.file);
        if (existingFile) {
            existingFile.name = name;
            existingFile.caption = caption;
            files[files.findIndex(f => f.file == file.file)] = existingFile
            this.setState({
                ...this.state,
                pendingFiles: files
            })
        }
    }

    handleUploadAll() {
        var filesToUpload = this.state.pendingFiles.filter(f => !f.isUploading)
        filesToUpload.forEach(file => this.handleStagedFileUpload(file));
        this.props.mediaContainer.getMediaItemsForApp(this.props.applicationId);
    }

    handleSingleFileUpload(file: StagedMediaItem) {
        this.handleStagedFileUpload(file);
        this.props.mediaContainer.getMediaItemsForApp(this.props.applicationId);
    }

    handleStagedFileUpload(file: StagedMediaItem) {
        var files = this.state.pendingFiles;
        const existingFile = files.find(f => f.file == file.file);
        if (existingFile) {
            existingFile.isUploading = true;
            files[files.findIndex(f => f.file == file.file)] = existingFile
            this.setState({
                ...this.state,
                pendingFiles: files
            })
        }
        this.props.mediaContainer.uploadMediaItem(this.props.applicationId,
            file.name,
            file.file,
            file.caption,
            (number) => this.handleProgressUpdate(file, number)).then(result => {
                if (result.resultType == "Ok") {
                    this.handleRemoveStagedFile(file);
                    var mediaItems = this.state.mediaItems;
                    mediaItems.push(result.data);
                    this.setState({
                        ...this.state,
                        mediaItems: mediaItems
                    });

                    this.updateContainerMediaItems();
                }
            });
    }

    attachMediaItem(item: MediaItemModel) {
        var newMediaItems = [...this.state.mediaItems, item];
        if (this.state.mediaItems.findIndex(i => i.id === item.id) < 0) {
            this.setState({
                ...this.state,
                mediaItems: newMediaItems
            });
            this.updateContainerMediaItems(newMediaItems);
        }
    }

    updateContainerMediaItems(mediaItems: MediaItemModel[] = []) {
        const container = this.props.values.container;
        if (mediaItems == null || mediaItems.length == 0)
            mediaItems = this.state.mediaItems;
        container.additionalMediaItemIds = mediaItems.map(m => m.id);

        this.onContainerChange(container);
    }

    handleProgressUpdate(file: StagedMediaItem, progress: number) {
        var files = this.state.pendingFiles;
        const existingFile = files.find(f => f.file == file.file);
        if (existingFile) {
            existingFile.progress = progress;
            files[files.findIndex(f => f.file == file.file)] = existingFile
            this.setState({
                ...this.state,
                pendingFiles: files
            })
        }
    }

    handleRemoveStagedFile(file: StagedMediaItem) {
        const files = this.state.pendingFiles
        _.remove(files, f => f.file == file.file);

        this.setState({
            ...this.state,
            pendingFiles: files
        })
    }

    handleFilesSelected(files: File[]) {
        var currentFiles = this.state.pendingFiles;
        currentFiles.push(...files.map(file => ({
            name: file.name,
            file: file,
            caption: '',
            isUploading: false,
            progress: 0,
        })));
        this.setState({
            ...this.state,
            pendingFiles: currentFiles
        });
    }

    handleMediaItemRemoved(mediaItem: MediaItemModel) {
        const mediaItems = this.state.mediaItems
        _.remove(mediaItems, f => f.id == mediaItem.id);
        // remove from fields
        if (mediaItem.id == this.props.values.audioItem?.id) {
            this.props.setFieldValue("audioItem", null);
        }
        if (mediaItem.id == this.props.values.videoItem?.id) {
            this.props.setFieldValue("videoItem", null);
        }
        if (mediaItem.id == this.props.values.imageItem?.id) {
            this.props.setFieldValue("imageItem", null);
        }
        if (mediaItem.id == this.props.values.backgroundImageItem?.id) {
            this.props.setFieldValue("backgroundImageItem", null);
        }
        this.setState({
            ...this.state,
            mediaItems: mediaItems
        })

        this.updateContainerMediaItems();
    }
    handleScreenDefaultsChange(values: DefaultMedia) {
        this.props.setFieldValue("audioItem", values.audioItem);
        this.props.setFieldValue("videoItem", values.videoItem);
        this.props.setFieldValue("imageItem", values.imageItem);
        this.props.setFieldValue("backgroundImageItem", values.backgroundImageItem);
        this.props.setFieldValue("displayTextOverride", values.displayTextOverride);
        this.props.setFieldValue("displayTitleOverride", values.displayTitleOverride);
    }
    handleAudioDefaultsChange(values: DefaultMedia) {
        this.props.setFieldValue("audioItem", values.audioItem);
    }
    onContainerChange(container: UpdateBulkMediaResponseContainerRequest) {
        this.props.setFieldValue("container", container);
        this.props.onChange();
    }
    getCustomMediaIds(): string[] {
        const customResponses = this.props.values.container.responses;
        var imageIds = customResponses.filter(r => r.foregroundImageItemId).map(r => r.foregroundImageItemId);
        var backgroundIds = customResponses.filter(r => r.backgroundImageItemId).map(r => r.backgroundImageItemId);
        var audioIds = customResponses.filter(r => r.audioItemId).map(r => r.audioItemId);
        const videoIds = customResponses.filter(r => r.videoItemId).map(r => r.videoItemId);

        return [...imageIds, ...backgroundIds, ...audioIds, ...videoIds];
    }
    getAttachedMediaItems(): MediaItemModel[] {
        const returnItems = [];
        const mediaItems = this.props.mediaContainer.state.mediaItems;
        this.state.mediaItems.forEach(item => {
            const index = mediaItems.findIndex(i => i.id === item.id);
            if (index > -1)
                returnItems.push(mediaItems[index]);
            else
                returnItems.push(item);
        });
        return returnItems;
    }
    render() {
        const props = { ...this.props };
        if (props.stateContainer.state.isLoading || !props.values.container || props.stateContainer.state.deviceTargets.length == 0) {
            return <Loader />
        }
        return (
            <FormContainer>
                <SplitContainer>
                    <PageContainer fill>
                        <PageTitle text="Files:" isSubtitle />
                        <MediaLibraryTabbedLayout tabs={["Upload", "Library"]}>
                            <TabPanel>
                                <TabPanelBody>
                                    <div>
                                        <MultiFileSelector
                                            onSelected={this.handleFilesSelected.bind(this)}
                                            accept=".png,.mp3,.mp4,.jpeg,.jpg" />
                                    </div>
                                    <AllowedMediaLabel />
                                    {this.state.pendingFiles.length > 0 ?
                                        <div>
                                            <HorizontalContainer className="center">
                                                <PageTitle text="Pending:" isSubtitle tight />
                                                <Button text="Upload &amp; Attach All" type="button" themes={["primary-small", "end-tight"]} onClick={this.handleUploadAll.bind(this)} />
                                            </HorizontalContainer>

                                            <HorizontalSeparator />
                                            {this.state.pendingFiles.map((file, i) => (
                                                <FileStageCard
                                                    file={file}
                                                    id={`file-card-${i}`}
                                                    key={i}
                                                    isUploading={file.isUploading}
                                                    progress={file.progress}
                                                    onRemove={this.handleRemoveStagedFile.bind(this)}
                                                    onUpload={this.handleSingleFileUpload.bind(this)}
                                                    onChange={this.handleStagedFileUpdate.bind(this)} />
                                            ))}
                                        </div>
                                        : null}
                                </TabPanelBody>
                            </TabPanel>
                            <TabPanel>
                                <TabPanelBody>
                                    <MediaLibraryTab onAttachMediaItem={this.attachMediaItem.bind(this)} {...this.props} />
                                </TabPanelBody>
                            </TabPanel>
                        </MediaLibraryTabbedLayout>
                    </PageContainer>
                    <PageContainer fill>
                        <PageTitle text="Experience Builder:" isSubtitle />
                        <AttachedMediaContainer mediaItems={this.getAttachedMediaItems()} customUsedItemIds={this.getCustomMediaIds()} onItemRemoved={this.handleMediaItemRemoved.bind(this)} />
                        <ResponseDetailContainer>
                            <ImageInnerTabs tabs={[
                                { title: 'Screen', icon: screenIcon, selectedIcon: screenSelectedIcon },
                                { title: 'Audio', icon: speakerIcon, selectedIcon: speakerSelectedIcon },
                                { title: 'Chat', icon: chatIcon, selectedIcon: chatSelectedIcon },]}>
                                <TabPanel>
                                    <ScreenResponseBuilder deviceType="Screen"
                                        applicationId={this.props.applicationId}
                                        onChange={this.onContainerChange.bind(this)}
                                        container={props.values.container}
                                        targets={this.props.stateContainer.state.deviceTargets.filter(d => d.supportsForegroundImages)}
                                        mediaItems={this.getAttachedMediaItems()}
                                        audioItem={this.props.values.audioItem}
                                        videoItem={this.props.values.videoItem}
                                        imageItem={this.props.values.imageItem}
                                        backgroundImageItem={this.props.values.backgroundImageItem}
                                        displayTextOverride={this.props.values.displayTextOverride}
                                        displayTitleOverride={this.props.values.displayTitleOverride}
                                        onDefaultsChange={this.handleScreenDefaultsChange.bind(this)} />

                                </TabPanel>
                                <TabPanel>
                                    <AudioResponseBuilder deviceType="Audio"
                                        applicationId={this.props.applicationId}
                                        targets={this.props.stateContainer.state.deviceTargets.filter(d => d.supportsSsml && !d.supportsDisplayText)}
                                        onChange={this.onContainerChange.bind(this)}
                                        container={props.values.container}
                                        mediaItems={this.getAttachedMediaItems()}
                                        audioItem={this.props.values.audioItem}
                                        onDefaultsChange={this.handleAudioDefaultsChange.bind(this)} />
                                </TabPanel>

                                <TabPanel>
                                    <ChatResponseBuilder deviceType="Chat"
                                        applicationId={this.props.applicationId}
                                        targets={this.props.stateContainer.state.deviceTargets.filter(d => !d.supportsSsml && d.supportsDisplayText)}
                                        onChange={this.onContainerChange.bind(this)}
                                        container={props.values.container}
                                        displayTextOverride={this.props.values.displayTextOverride}
                                        mediaItems={this.getAttachedMediaItems()} />

                                </TabPanel>
                            </ImageInnerTabs>
                        </ResponseDetailContainer>
                    </PageContainer>
                </SplitContainer>
                <PageError errors={props.mediaContainer.state.errors} />
                <FooterBar className={footerStyle}>
                    <Button themes={["primary"]} text="Save" type="button" onClick={() => props.handleSubmit(null)} disabled={this.props.stateContainer.state.isSaving} loading={this.props.stateContainer.state.isSaving} />
                    <Button themes={["white", "end"]} text="Discard" type="button" onClick={this.handleDiscardChanges.bind(this)} disabled={this.props.stateContainer.state.isSaving} loading={this.props.stateContainer.state.isSaving} />
                </FooterBar>
            </FormContainer>
        );
    }
}
// note: some info on handling two submit buttons: https://github.com/jaredpalmer/formik/issues/214
// save creates the item and save and publish saves it and toggles the is live call
const MediaManagerForm = withFormik<MediaManagerFormProps, MediaManagerFormData>({
    mapPropsToValues: props => ({
        imageItem: props.imageItem,
        backgroundImageItem: props.backgroundImageItem,
        audioItem: props.audioItem,
        videoItem: props.videoItem,
        displayTextOverride: props.displayTextOverride,
        displayTitleOverride: props.displayTitleOverride
        // note: container mapped at component did mount due to lazy load
    }),
    validationSchema: yup.object().shape({
    }),
    handleSubmit: (values, { props }) => {

        const output = { ...values } as MediaManagerData
        // create or update
        if (props.mediaResponseContainerId) {
            props.stateContainer.updateResponseContainer(props.mediaResponseContainerId, values.container).then(result => {
                if (result.resultType == "Ok") {
                    output.fullContainer = result.data
                    props.onFinish(output);
                }
            });

        }
        else {
            props.stateContainer.createResponseContainer({
                ...values.container,
                applicationId: props.applicationId
            }).then(result => {
                if (result.resultType == "Ok") {
                    output.fullContainer = result.data
                    props.onFinish(output);
                }
            });
        }
    }
})(InnerForm);

const footerStyle = css`
    position: fixed;
`;
export default MediaManagerForm;