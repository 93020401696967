import React from 'react';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import ToggleableSection from '../../components/structure/ToggleableSection';
import ConversationAccessSelector from '../../components/structure/ConversationAccessSelector';
import { getAccessType } from '../../models/extensions/contentItems';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import ConversationAccessType from '../../models/features/ConversationAccessType';
import ConversationItemFormData from '../../models/features/ConversationItemFormData';
import ConversationItemFormProps from '../../models/features/ConversationItemFormProps';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import BaseConversationItemForm, { ContentItemPageState } from '../formScenes/BaseConversationItemForm';
import RequiresParentOption from './components/RequiresParentOption';


export interface FollowUpWithRepromptFormData extends ConversationItemFormData {
    requiresParent: boolean,
    alwaysOpen?: boolean
}
export interface FollowUpWithRepromptFormProps<TContentItem extends ContentItemModel> extends ConversationItemFormProps<TContentItem> {
    appContainer: ApplicationContainer
    followUpContainer: FollowUpContainer
    repromptContainer: RepromptContainer
    applicationFeatureId: string
    isSaving?: boolean
    onAccessTypeChange: (accessType: ConversationAccessType) => void
}

abstract class FollowUpWithRepromptForm<TContentItem extends ContentItemModel, TProps extends FollowUpWithRepromptFormProps<TContentItem>, TFormData extends FollowUpWithRepromptFormData, TState extends ContentItemPageState>
    extends BaseConversationItemForm<TContentItem, TProps, TFormData, TState> {

    componentWillMount() {
        this.props.followUpContainer.clearErrors();
        this.props.repromptContainer.clearErrors();
        this.setState({
            ...this.state,
            isRelinkOpen: false
        })
    }

    renderOptions(alwaysTargeted?: boolean, isDisabled?: boolean, neverTargeted?: boolean, alwaysOpenAvailable: boolean = false) {
        return (
            <ConversationAccessSelector disabled={isDisabled}
                neverTargetedAccess={neverTargeted}
                neverOpenAccess={alwaysTargeted}
                alwaysOpenAvailable={alwaysOpenAvailable}
                value={getAccessType(this.props.values.isLive, this.props.values.requiresParent, alwaysOpenAvailable ? this.props.values.alwaysOpen : false)}
                onChange={(v) => this.handleAccessTypeChange(v, alwaysOpenAvailable)} />
        )
    }
    handleAccessTypeChange(value, alwaysOpenAvailable: boolean) {
        if (value.value == "open") {
            this.props.setFieldValue("isLive", true);
            this.props.setFieldValue("requiresParent", false);
            if (alwaysOpenAvailable) {
                this.props.setFieldValue("alwaysOpen", false);                
            }
        }
        else if (value.value == "targeted") {
            this.props.setFieldValue("isLive", true);
            this.props.setFieldValue("requiresParent", true);
            if (alwaysOpenAvailable) {
                this.props.setFieldValue("alwaysOpen", false);
            }
        }
        else if (value.value == "alwaysOpen") {
            this.props.setFieldValue("isLive", true);
            this.props.setFieldValue("requiresParent", false);
            this.props.setFieldValue("alwaysOpen", true);
        }
        else {
            this.props.setFieldValue("isLive", false);
            this.props.setFieldValue("requiresParent", false);
            if (alwaysOpenAvailable) {
                this.props.setFieldValue("alwaysOpen", false);
            }
        }
        this.props.onAccessTypeChange(value.value);
    }
}
export default FollowUpWithRepromptForm;