import { withFormik } from "formik";
import * as yup from 'yup';
import QuestionAnswerForm, { QuestionAnswerContentFormData, QuestionAnswerContentFormProps } from '../../formScenes/QuestionAnswerForm';
import _ from "lodash";
import QuestionModel from "../../../models/features/api/QuestionAnswers/QuestionModel";

const EditQuestionAnswerContentForm = withFormik<QuestionAnswerContentFormProps, QuestionAnswerContentFormData>({
    mapPropsToValues: props => {
        const questions = props.questionAnswer.questionSet;
        let firstQuestion: QuestionModel = {};
        if (questions && questions.length > 0) {
            firstQuestion = questions[0];
        }
        const values: QuestionAnswerContentFormData = {
            title: props.questionAnswer.title,
            isLive: props.questionAnswer.isLive,
            webhookChainingType: props.questionAnswer.webhookChainingType,
            questions: props.questionAnswer.questionSet.length > 0 ? props.questionAnswer.questionSet.map(q => (
                {
                    questionId: q.id,
                    content: q.content,
                }
            )) : [{ content: '' }],
            responses: props.questionAnswer.responses.length > 0 ? _.cloneDeep(props.questionAnswer.responses) : [{ content: '' }],
            requiresParent: props.questionAnswer.requiresParent,
            languages: props.questionAnswer.languages,
            minimumConfidenceScore: firstQuestion?.minimumConfidenceScore,
            requiredPhrases: firstQuestion?.requiredPhrases ?? [],
            sensitiveConversationChange: props.questionAnswer.sensitiveConversationChange ?? "None",
        };
        return values;
    },
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        questions: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a question variation")
            })
        ).required("You must enter at least 1 question variation").min(1, "You must enter at least 1 question variation"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter an response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
        minimumConfidenceScore: yup.number()
            .typeError('Please provide a number between 0 and 1.')
            .min(0, 'Please provide a number between 0 and 1.')
            .max(1, 'Please provide a number between 0 and 1.')
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.questionAnswer, values));
    }
})(QuestionAnswerForm);

export default EditQuestionAnswerContentForm;