import React from 'react';
import { withFormik } from "formik";
import * as yup from 'yup';
import moment from 'moment';
import LatestMessageForm, { LatestMessageContentFormProps, LatestMessageContentFormData } from '../../formScenes/LatestMessageForm';

const LatestMessageContentForm = withFormik<LatestMessageContentFormProps, LatestMessageContentFormData>({
    mapPropsToValues: props => ({
        title: 'Untitled Latest Message',
        category: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        responses: [{ content: '' }],
        followUpSelection: 'end',
        repromptSelection: 'end',
        entryType: 'open',
        sensitiveConversationChange: "None",
        requiresParent: false,
        isLive: true,
        languages: props.application?.languages.find(l => l.id == props.application?.defaultLanguageId) ? [props.application?.languages.find(l => l.id == props.application?.defaultLanguageId)] : [],
        webhookChainingType: "NoChaining"
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
        category: yup.string().required("You must enter a category"),
        startDate: yup.date().required("You must enter a start date"),
        endDate: yup.date().required("You must enter an end date")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus, setFieldValue }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel({
            applicationFeatureId: props.applicationFeatureId,
            applicationId: props.application?.id,
            responses: []
        }, values));
    }
})(LatestMessageForm);

export default LatestMessageContentForm;