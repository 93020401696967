import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import GoogleScreenWrapper from './GoogleScreenWrapper';

const screenStyle = css`
    color: white;
    .body-text, .title-text {
        font-family: 'Roboto', sans-serif;
        
        padding-top: 80px;
        padding-left: 80px;
        color: #111111;    
        overflow-wrap: break-word;
        z-index: 2;
        position: relative;
    }
    .title-text {
        font-size: 80px;
    }
    .body-text {
        overflow-y: hidden;
        flex: 1;
        font-size: 42px;
    }
    .body-container {
        display: flex;
        z-index: 2;
        flex-direction: column;
        position: relative;
    }
    .chip-container {
        margin-left: 60px;
        margin-bottom: 20px;
    }
    .list-item { 
        border-bottom: 1px solid gray;
        display: flex;
        align-items: center;
        height: 300px;
        .foreground-image {
            height: 260px;
            width: auto;
            margin-right: 0;
            margin-left: auto;
            img {
                height: 100%;
                width: auto;
            }
        }
    }
    .hint-chip {
        border: 1px solid gray;
        height: 80px;
        border-radius: 80px;
        margin-right: 40px;
        font-family: 'Roboto', sans-serif;
        font-size: 62px;
        color: #111111;    
        z-index: 2;
        padding: 32px;
    }
`
class GoogleListPreview extends ScreenPreviewComponent {
    renderAdditionalItems() {
        const items = [];
        for (var i = 0; i < this.props.response.additionalListItems; i++) {
            items.push(i);
        }
        return items.map((_, i) => (
            <div key={i} className="list-item">
                <p className="body-text">
                    <span>{i+2}. Additional Item</span>
                </p>
                <div className="foreground-image empty"></div>
            </div>
        ))
    }

    render() {
        return (
            <GoogleScreenWrapper className={screenStyle}>
                {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}
                <div className="body-container">
                    <div className="list-item">
                        <p className="body-text">
                            <span>1. {this.state.displayText}</span>
                        </p>
                        {this.state.foregroundImageUrl
                            ? <div className="foreground-image"><img src={this.state.foregroundImageUrl} /></div>
                            : <div className="foreground-image empty"></div>}
                    </div>
                    {this.props.response.additionalListItems ? this.renderAdditionalItems() : null}
                </div>
                <div className="chip-container">
                    {this.state.hints ? this.state.hints
                        .filter(h => h.content.length <= 20)
                        .map((h, i) => <span key={i} className="hint-chip">{h.content}</span>)
                        : null}
                </div>
            </GoogleScreenWrapper>
        )
    }
}

export default GoogleListPreview;