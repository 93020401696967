import React from 'react';
import { css } from '@emotion/css';
import TemplateFormFieldModel from '../../../models/templating/api/TemplateFormFieldModel';
import { color_shades_dark, color_text_light } from '../../../constants/colors';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import TextField from '../../../components/forms/TextField';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import Switch from '../../../components/forms/Switch';
import MarkdownEditorModal from './MarkdownEditorModal';
import LargeOverflowMenu from '../../../components/general/LargeOverflowMenu';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import { OverflowOption } from '../../../components/general/overflowMenu/OverflowMenu';
import { DraggableProvided } from 'react-beautiful-dnd';
import TemplateFieldType from '../../../models/templating/TemplateFieldType';
import { getFieldTypeIcon } from '../../../models/extensions/fieldTypeIcons';

const menuIcon = require('../../../content/images/menu-item-icons/circle-menu.svg');
const deleteIcon = require('../../../content/images/menu-item-icons/bin.svg');
const upIcon = require('../../../content/images/menu-item-icons/navigation-up.svg');
const downIcon = require('../../../content/images/menu-item-icons/navigation-down.svg');
const moveIcon = require('../../../content/images/menu-item-icons/navigation-right-circle.svg');

interface TextFieldEditorProps {
    field: TemplateFormFieldModel
    sections: TemplateFormSectionModel[]
    draggable: DraggableProvided
    onChange: (field: TemplateFormFieldModel) => void
    onDelete: (field: TemplateFormFieldModel) => void
    onMoveUp: (field: TemplateFormFieldModel) => void
    onMoveDown: (field: TemplateFormFieldModel) => void
    onMoveToSection: (field: TemplateFormFieldModel, sectionId: string) => void
}

interface TextFieldEditorState {
    isCollapsed: boolean
    isMarkdownOpen: boolean
    isDeleteConfirmationOpen: boolean
}

class TextFieldEditor extends React.Component<TextFieldEditorProps, TextFieldEditorState> {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false,
            isMarkdownOpen: false,
            isDeleteConfirmationOpen: false
        }
    }
    handleToggleCollapse() {
        this.setState({
            ...this.state,
            isCollapsed: !this.state.isCollapsed
        })
    }
    handleToggleMarkdown() {
        this.setState({
            ...this.state,
            isMarkdownOpen: !this.state.isMarkdownOpen
        })
    }
    handleToggleDelete() {
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: !this.state.isDeleteConfirmationOpen
        })
    }
    handleMarkdownChange = (markdown: string) => {
        this.handleFieldChange("instructionsMarkdown", markdown);
        this.setState({
            ...this.state,
            isMarkdownOpen: false
        })
    }
    handleFieldChange(fieldName: keyof TemplateFormFieldModel, value) {
        const field = this.props.field;
        (field[fieldName] as any) = value;
        this.props.onChange(field);
    }
    getOptions() {
        const options: OverflowOption[] = [
            { label: 'Delete', icon: deleteIcon, hoverIcon: deleteIcon, isDestructive: true },
            { label: 'Move Up', icon: upIcon, hoverIcon: upIcon },
            { label: 'Move Down', icon: downIcon, hoverIcon: downIcon },
            {
                label: 'Move to another page...',
                icon: moveIcon, hoverIcon: moveIcon,
                subOptions: this.props.sections.map(s => ({
                    label: s.title
                }))
            }
        ];
        return options;
    }
    handleOptionSelected(optionName: string) {
        if (optionName == 'Move Up') {
            this.props.onMoveUp(this.props.field);
        }
        if (optionName == 'Move Down') {
            this.props.onMoveDown(this.props.field);
        }
        if (optionName == 'Delete') {
            this.setState({
                ...this.state,
                isDeleteConfirmationOpen: true
            })
        }

        // find section by name and pass it off
        const section = this.props.sections.find(s => s.title == optionName);
        if (section) {
            this.props.onMoveToSection(this.props.field, section.id);
        }
    }
    render() {
        const { field, draggable } = this.props;
        return (
            <div className={containerStyle}>
                <div className="field-title-wrapper" {...draggable.dragHandleProps}>
                    <ToggleIndicator direction={this.state.isCollapsed ? 'right' : 'down'} onClick={this.handleToggleCollapse.bind(this)} type="triangle" className="toggle-indicator" />
                    <img src={getFieldTypeIcon(field.fieldType as TemplateFieldType)} onClick={this.handleToggleCollapse.bind(this)} />
                    <div className="title-text-container" onClick={this.handleToggleCollapse.bind(this)}>
                        <p className="field-type-label">{field.fieldType}</p>
                        <p className="field-title">{field.title}</p>
                    </div>
                    <div className="field-button">
                        <LargeOverflowMenu
                            isActive={false}
                            onOptionSelected={this.handleOptionSelected.bind(this)}
                            options={this.getOptions()}
                            icon={menuIcon}
                        />
                    </div>
                </div>
                {!this.state.isCollapsed && <div className="field-body-wrapper">
                    <TextField name="field.associatedVariable" value={field.associatedVariable ?? ''} placeholder="{Variable}" label="Associated Variable" onChange={e => this.handleFieldChange("associatedVariable", e.target.value)} />
                    <HorizontalSeparator />
                    <Switch className="switch-container" checked={field.isRequired} onChange={(checked) => this.handleFieldChange("isRequired", checked)} label="Required Field" />
                    <TextField name="field.title" value={field.title ?? ''} placeholder="Title your field" label="Display Title" onChange={e => this.handleFieldChange("title", e.target.value)} />
                    <TextField name="field.label" value={field.label ?? ''} placeholder="Label for your text field" label="Text Field Label" onChange={e => this.handleFieldChange("label", e.target.value)} />
                    <TextField name="field.description" value={field.tip ?? ''} placeholder="Describe your field" label="Description of Field" onChange={e => this.handleFieldChange("tip", e.target.value)} />
                    <TextField name="field.placeholder" value={field.placeholder ?? ''} placeholder="Provide a placeholder" label="Placeholder Content (Optional)" onChange={e => this.handleFieldChange("placeholder", e.target.value)} />
                    <TextField name="field.defaultValue" value={field.defaultValue ?? ''} placeholder="Use this if you want to have the field auto-fill with a value" label="Default Value (Optional)" onChange={e => this.handleFieldChange("defaultValue", e.target.value)} />

                </div>}
                {this.state.isMarkdownOpen && <MarkdownEditorModal contextTitle={field.title ?? "Field Instructions"} markdown={this.props.field.instructionsMarkdown ?? ''} onSave={this.handleMarkdownChange.bind(this)} onClose={this.handleToggleMarkdown.bind(this)} />}

                {this.state.isDeleteConfirmationOpen &&
                    <ConfirmationDialog title={`Delete ${field?.title ?? "Field"}`}
                        deleteText="Yes, Delete"
                        isLoading={false}
                        onClose={this.handleToggleDelete.bind(this)}
                        onConfirm={() => this.props.onDelete(field)}>
                        <DeleteConfirmation itemLabel={`${field?.title ?? "Field"}?`} />
                    </ConfirmationDialog>}
            </div>
        );
    }
}
const containerStyle = css`
    border: 1px solid ${color_shades_dark};
    background: white;
    border-radius: 8px;
    margin-bottom: 32px;
    .field-title-wrapper {
        display: flex;
        align-items: center;
        margin: 16px 0;
        cursor: pointer;
        >.toggle-indicator {
            margin: 16px;
        }
        >img {
            width: 24px;
            height: auto;
            margin-right: 12px;
        }
        .title-text-container {
            flex: 1;
        }
        .field-type-label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: ${color_text_light};
        }
        .field-title {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        .field-button {
            margin-right: 32px;
            margin-left: auto;
        }
    }
    .field-body-wrapper {
        margin: 0 32px;
        padding: 24px 0;
        border-top: 1px solid ${color_shades_dark};
        >.switch-container {
            margin: 32px 0;
        }
    }
`;

export default TextFieldEditor;