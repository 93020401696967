import React from 'react';
import {css} from '@emotion/css';
import { color_shades_dark, color_text_light } from '../../../constants/colors';

const NoResultsView = () => (
    <div className={containerStyle}>
        <p>No matching results</p>
    </div>
);

const containerStyle = css`
    height: 64px;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin: 0 32px;
    padding: 0 32px;
    p {
        font-style: italic;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: ${color_text_light};
    }
`;

export default NoResultsView;