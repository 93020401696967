import { hexToCSSFilter } from 'hex-to-css-filter';
import { useState } from "react";
import { getKotlinHexValue } from '../../../models/customAssistant/CustomAssistantCodeExportTypes';

const GRADIENT_DIRECTION: string = '215.1deg';
const GRADIENT_COLOR_ONE_PERCENTAGE: string = '20.69%';
const GRADIENT_COLOR_TWO_PERCENTAGE: string = '95.73%';

export interface Gradient {
    javaScript: string;
    swift: string;
    kotlin: string;
}

function useCustomAssistantConfigurationColors() {
    const [iconFilter, setIconFilter] = useState<string>('');
    const [colorShade, setColorShade] = useState<string>('');
    const [highlight, setHighlight] = useState<string>('');
    const [gradient, setGradient] = useState<Gradient>({ javaScript: '', swift: '', kotlin: '' });

    const createGradient = (primaryColor: any) => {
        let newGradient = { javaScript: '', swift: '', kotlin: '' };
        if (isValidHexCode(primaryColor) && colorShade) {
            newGradient.javaScript = `linear-gradient(${GRADIENT_DIRECTION}, ${colorShade} ${GRADIENT_COLOR_ONE_PERCENTAGE}, ${primaryColor} ${GRADIENT_COLOR_TWO_PERCENTAGE})`;
            newGradient.swift = `${colorShade},${primaryColor}`;
            newGradient.kotlin = `${getKotlinHexValue(colorShade, true)},${getKotlinHexValue(primaryColor, true)}`;
            setGradient(newGradient);
            return newGradient;
        }
    };

    const createIconFilter = (primaryColor: string) => {
        if (isValidHexCode(primaryColor)) {
            const iconFilterColor = hexToCSSFilter(primaryColor);
            setIconFilter(iconFilterColor.filter);
        } else {
            setIconFilter('');
        }
    };

    const createHighlight = (hex: string, alpha: number) => {
        let highlight = `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, "")}`;
        setHighlight(highlight);
        return highlight;
    };

    const createColorShade = (colorCode: string, amount: number) => {
        if (colorShade) {
            setColorShade('');
        };

        if (colorCode) {
            let usePound = false;
            if (colorCode[0] == "#") {
                colorCode = colorCode.slice(1);
                usePound = true;
            };
            const num = parseInt(colorCode, 16);
            let r = (num >> 16) + amount;
            if (r > 255) {
                r = 255;
            } else if (r < 0) {
                r = 0;
            }
            let b = ((num >> 8) & 0x00FF) + amount;
            if (b > 255) {
                b = 255;
            } else if (b < 0) {
                b = 0;
            }
            let g = (num & 0x0000FF) + amount;
            if (g > 255) {
                g = 255;
            } else if (g < 0) {
                g = 0;
            }
            return setColorShade((usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16));
        }
    };

    const isValidHexCode = (str: string) => {
        if (str[0] != '#' || str.indexOf('#', 1) != -1)
            return false;
        if (!(str.length == 4 || str.length == 7))
            return false;
        for (let i = 1; i < str.length; i++)
            if (!((str[i].charCodeAt(0) <= '0'.charCodeAt(0) && str[i].charCodeAt(0) <= 9)
                || (str[i].charCodeAt(0) >= 'a'.charCodeAt(0) && str[i].charCodeAt(0) <= 'f'.charCodeAt(0))
                || (str[i].charCodeAt(0) >= 'A'.charCodeAt(0) || str[i].charCodeAt(0) <= 'F'.charCodeAt(0))))
                return false;
        return true;
    }

    const clearColorStates = () => {
        setGradient({ javaScript: '', swift: '', kotlin: '' });
        setIconFilter('');
        setColorShade('');
        setHighlight('');
    };

    return {
        iconFilter,
        colorShade,
        highlight,
        gradient,
        useCustomAssistantConfigurationColors,
        createGradient,
        createIconFilter,
        createHighlight,
        createColorShade,
        clearColorStates,
        isValidHexCode,
    }
}

export default useCustomAssistantConfigurationColors;