import type { RequestContext } from "assistant/VoicifyAssistant";
import { addItemToAfterResponseData, extractDataValue, findElement, sleep } from "../Shared";


export const performClickEffect = async (data: any) => {
    const fireAfterMilliseconds = extractDataValue(data, "fireAfterMilliseconds", 0);
    const elementId = extractDataValue(data, "elementId", null);
    const elementName = extractDataValue(data, "elementName", null);
    const querySelector = extractDataValue(data, "querySelector", null);
    if (elementId || elementName || querySelector) {
        const element = findElement(elementId, elementName, querySelector);
        if (element) {
            await sleep(fireAfterMilliseconds);
            if (element)
                element.click();             
        }  
    }
}
export const handleClickEffect = async (data: { [key: string]: any }, requestContext: RequestContext, afterResponseEffect: boolean = false): Promise<boolean> => {
    var fireBefore = extractDataValue(data, "fireBeforeTextToSpeech", true);
    if (fireBefore || afterResponseEffect) {
        await performClickEffect(data);
        return true;
    } else {
        addItemToAfterResponseData("clickTap", requestContext, data);
        return false;
    }
}