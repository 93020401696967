import React, { useState } from 'react';
import TextField from '../../../components/forms/TextField';
import { css } from '@emotion/css';
import { blue_white, color_defaults_black, color_shades_dark, cool_grey, dark_grey_blue } from '../../../constants/colors';
import FollowUpHintModel from '../../../models/features/api/FollowUp/FollowUpHintModel';
import FollowUpModel from '../../../models/features/api/FollowUp/FollowUpModel';
const downArrowIcon = require('../../../content/images/down_arrow.svg');
const upArrowIcon = require('../../../content/images/up_arrow.svg');
const trashIcon = require('../../../content/images/trash.svg');
const moveableIcon = require('../../../content/images/moveable-icon.svg');
const disabledUpArrowIcon = require('../../../content/images/disabled_up_arrow.svg')
const disabledDownArrowIcon = require('../../../content/images/disabled_down_arrow.svg')

interface HintFieldProps {
    index: number
    isLast: boolean
    hint: FollowUpHintModel
    followUp: FollowUpModel
    handleMoveDown?: (idx) => void
    handleMoveUp?: (idx) => void
    handleHintDelete?: (idx) => void
    handleHintChange?: (e, idx) => void
}

const HintField: React.FC<HintFieldProps> = ({
    index,
    isLast,
    hint,
    followUp,
    handleMoveDown,
    handleMoveUp,
    handleHintDelete,
    handleHintChange
}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div key={index}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)} >
            <div className={hintNavigation}>
                {isHovered ? <img className="moveable-icon" src={moveableIcon} /> : <div className="spacer-bar left" />}
                <p>Hint {index + 1}</p>
                <div className="spacer-bar right" />
                <img className="hints-field-image right-margin" onClick={() => handleMoveDown(index)} src= {isLast ? disabledDownArrowIcon : downArrowIcon} />
                <img className="hints-field-image right-margin" onClick={() => handleMoveUp(index)} src={index == 0 ? disabledUpArrowIcon : upArrowIcon} />
                <img className="hints-field-image" onClick={() => handleHintDelete(index)} src={trashIcon} />
            </div>
            <TextField
                key={index}
                name={`followUpHints.${index}.content`}
                placeholder="Something the user can say"
                onChange={(e) => handleHintChange(e, index)}
                onBlur={() => { }}
                value={hint.content}
                disabled={followUp.name != null} />
        </div>
    )
}


const hintNavigation = css`
    display: flex;     
    p{
        white-space: nowrap;        
    }
    .hints-field-image {
        cursor: pointer;
        &.right-margin{
            margin-right:14px; 
        }            
    }
    .moveable-icon{
        cursor: grab;
        margin-right: 10px;
    }
    .spacer-bar {
        width: 100%;
        height: 1px;
        background: ${color_shades_dark};
        margin-top: 10px;
        &.left {
            margin-right: 8px;
            width: 11px;    
        }
        &.right{
            margin-left: 8px;
            margin-right: 8px;
        }
    }
`
export default HintField;