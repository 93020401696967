import React from 'react';
import { css } from '@emotion/css';
import { ImageTabItem } from './ImageInnerTabs';
import { Tab } from 'react-tabs';
import TabIndicator from './TabIndicator';
import { silver_two, dark_sky_blue, ocean_blue } from '../../constants/colors';

interface ImageTabProps {
    tab: ImageTabItem
    index: number
    className?: string
}
function ImageTab(props) {
    return (
        <Tab key={props.index} selectedClassName={activeTabStyle} className={`${tabStyle} ${props.className ? props.className : ''}`}>
            <div className="tab-wrapper">
                <div className="tab-content">
                    <img className="tab-icon" src={props.tab.icon} />
                    <img className="selected-tab-icon" src={props.tab.selectedIcon} />
                    <span>{props.tab.title}</span>
                </div>
            </div>
            <TabIndicator className="indicator" color={indicatorGradient} />
        </Tab>
    )
}
const indicatorGradient = `linear-gradient(267deg, ${dark_sky_blue}, ${ocean_blue});`;

const tabStyle = css`
color: black;
cursor: pointer;
flex-grow: 1;
align-items: center;
position: relative;
.indicator {    
    position: absolute;
    left: 50%;
    bottom: -2px;
    z-index: 4;
    margin-left: -25px;
}
&.bordered {
    .tab-content {
        border-right: 1px solid ${silver_two};
    }
}
.tab-wrapper {
    height: 115px;
    display: flex;
    align-items: center;
}
.tab-content {
    min-width: 80px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65px;
    span {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0.5px;
    }

}
.tab-indicator {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
}

.selected-tab-icon {
    display: none;
}

img {
    margin-bottom: 16px;
}
`;

const activeTabStyle = css`
color: ${ocean_blue};
span {
    color: ${ocean_blue};
}
>.tab-indicator {
    display: block;
}
.tab-icon {
    display: none;
}
.selected-tab-icon {
    display: block;
}
`;

export default ImageTab;