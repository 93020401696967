{
    "id": "",
    "serverRootUrl": "",
    "applicationId": "",
    "applicationSecret": "",
    "locale": "en-US",
    "channel": "",
    "device": "Custom Assistant SDK",
    "autoRunConversation": false,
    "initializeWithWelcomeMessage": true,
    "textToSpeechProvider": "Google",
    "useVoiceInput": true,
    "useOutputSpeech": true,
    "useDraftContent": false,
    "noTracking": true,
    "sessionTimeout": 3600000,
    "uiType": "minimal",
    "activeInput": "textbox",
    "name": "",
    "theme": "lightStyle1",
    "showConversationHistory": "true",
    "platformConfigurations": {
        "javaScript": {
            "serverRootUrl": "",
            "appId": "",
            "appKey": "",
            "locale": "en-US",
            "channel": "My App",
            "device": "My device",
            "autoRunConversation": true,
            "initializeWithWelcomeMessage": true,
            "textToSpeechProvider": "Google",
            "useVoiceInput": true,
            "useOutputSpeech": true,
            "useDraftContent": false,
            "noTracking": true,
            "sessionTimeout": 3600000,
            "environmentId": null,
            "uiType": "minimal",
            "openByDefault": false,
            "activeInput": "textbox",
            "showConversationHistory": "true",
            "styles": {
                "assistant": {
                    "zIndex": "1"
                },
                "header": {
                    "backgroundColor": "#FFFFFF",
                    "assistantName": "Custom Assistant",
                    "assistantNameTextColor": "#324661",
                    "fontFamily": "sans-serif",
                    "closeAssistantColor": "invert(74%) sepia(3%) saturate(4%) hue-rotate(13deg) brightness(91%) contrast(90%);",
                    "minimizeAssistantColor": "invert(74%) sepia(3%) saturate(4%) hue-rotate(13deg) brightness(91%) contrast(90%);"
                },
                "body": {
                    "backgroundColor": "#F4F4F6",
                    "messageSentBackgroundColor": "#808080",
                    "messageReceivedTextColor": "#324661",
                    "messageReceivedBackgroundColor": "#0000000d",
                    "messageSentFontFamily": "sans-serif",
                    "messageReceivedFontFamily": "sans-serif",
                    "hintsFontFamily": "sans-serif"
                },
                "toolbar": {
                    "backgroundColor": "#FFFFFF",
                    "speechResultBoxBackgroundColor": "#808080",
                    "partialSpeechResultFontFamily": "sans-serif",
                    "assistantStateFontFamily": "sans-serif",
                    "helpTextFontFamily": "sans-serif",
                    "speakFontFamily": "sans-serif",
                    "typeFontFamily": "sans-serif",
                    "textboxFontFamily": "sans-serif",
                    "textInputActiveLineColor": "#AAAAAA",
                    "textInputLineColor": "#AAAAAA",
                    "textboxActiveHighlightColor": "#80808033",
                    "equalizerColor": "linear-gradient(180deg, #808080 0%, #AAAAAA 100%)",
                    "muteImageColor": "invert(74%) sepia(3%) saturate(4%) hue-rotate(13deg) brightness(91%) contrast(90%);",
                    "muteImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/mute.svg",
                    "muteFontFamily": "sans-serif",
                    "micActiveColor": "invert(54%) sepia(0%) saturate(1%) hue-rotate(282deg) brightness(92%) contrast(86%);",
                    "sendActiveColor": "invert(54%) sepia(0%) saturate(1%) hue-rotate(282deg) brightness(92%) contrast(86%);",
                    "sendInactiveColor": "invert(54%) sepia(0%) saturate(1%) hue-rotate(282deg) brightness(92%) contrast(86%);",
                    "assistantStateTextColor": "#324661"
                },
                "start": {
                    "assistantImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/assistant.svg",
                    "buttonBackgroundColor": "#808080",
                    "buttonFontFamily": "sans-serif"
                }
            },
            "applicationId": "",
            "applicationSecret": "",
            "name": "",
            "theme": "lightStyle1"
        },
        "kotlin": {
            "serverRootUrl": "",
            "appId": "",
            "appKey": "",
            "locale": "en-US",
            "channel": "My App",
            "device": "My device",
            "textToSpeechVoice": "",
            "autoRunConversation": true,
            "initializeWithWelcomeMessage": true,
            "textToSpeechProvider": "Google",
            "useVoiceInput": true,
            "useOutputSpeech": true,
            "useDraftContent": false,
            "noTracking": true,
            "initializeWithText": false,
            "applicationId": "",
            "applicationSecret": "",
            "activeInput": "textbox",
            "name": "",
            "showConversationHistory": "true",
            "uiType": "minimal",
            "styles": {
                "assistant": {
                    "serverRootUrl": "",
                    "appId": "",
                    "appKey": "",
                    "locale": "en-US",
                    "channel": "My App",
                    "device": "My device",
                    "textToSpeechVoice": "",
                    "autoRunConversation": true,
                    "initializeWithWelcomeMessage": true,
                    "textToSpeechProvider": "Google",
                    "useVoiceInput": true,
                    "useOutputSpeech": true,
                    "useDraftContent": false,
                    "noTracking": true,
                    "initializeWithText": false
                },
                "header": {
                    "backgroundColor": "#FFFFFF",
                    "assistantName": "Custom Assistant",
                    "assistantNameTextColor": "#324661",
                    "fontFamily": "sans-serif",
                    "closeAssistantColor": "#AAAAAA",
                    "closeAssistantButtonImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/close-2.png"
                },
                "body": {
                    "backgroundColor": "#F4F4F6",
                    "messageSentBackgroundColor": "#808080",
                    "messageReceivedTextColor": "#324661",
                    "messageReceivedBackgroundColor": "#0d000000",
                    "messageSentFontFamily": "sans-serif",
                    "messageReceivedFontFamily": "sans-serif",
                    "hintsFontFamily": "sans-serif"
                },
                "toolbar": {
                    "backgroundColor": "#FFFFFF",
                    "speechResultBoxBackgroundColor": "#808080",
                    "partialSpeechResultFontFamily": "sans-serif",
                    "assistantStateFontFamily": "sans-serif",
                    "helpTextFontFamily": "sans-serif",
                    "speakFontFamily": "sans-serif",
                    "typeFontFamily": "sans-serif",
                    "textboxFontFamily": "sans-serif",
                    "textInputActiveLineColor": "#AAAAAA",
                    "textInputLineColor": "#AAAAAA",
                    "textboxActiveHighlightColor": "#33808080",
                    "equalizerColor": "#808080,#AAAAAA",
                    "micActiveColor": "#808080",
                    "sendActiveColor": "#808080",
                    "sendInactiveColor": "#808080",
                    "micActiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/mic-image-3.png",
                    "micInactiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/mic-image-3.png",
                    "sendActiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/send-image-3.png",
                    "sendInactiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/send-image-3.png"
                }
            }
        },
        "swift": {
            "serverRootUrl": "",
            "appId": "",
            "appKey": "",
            "locale": "en-US",
            "channel": "My App",
            "device": "My device",
            "textToSpeechVoice": "",
            "autoRunConversation": true,
            "initializeWithWelcomeMessage": true,
            "textToSpeechProvider": "Google",
            "useVoiceInput": true,
            "useOutputSpeech": true,
            "useDraftContent": false,
            "noTracking": true,
            "initializeWithText": false,
            "applicationId": "",
            "applicationSecret": "",
            "activeInput": "textbox",
            "name": "",
            "showConversationHistory": "true",
            "uiType": "minimal",
            "styles": {
                "assistant": {
                    "serverRootUrl": "",
                    "appId": "",
                    "appKey": "",
                    "locale": "en-US",
                    "channel": "My App",
                    "device": "My device",
                    "textToSpeechVoice": "",
                    "autoRunConversation": true,
                    "initializeWithWelcomeMessage": true,
                    "textToSpeechProvider": "Google",
                    "useVoiceInput": true,
                    "useOutputSpeech": true,
                    "useDraftContent": false,
                    "noTracking": true,
                    "initializeWithText": false
                },
                "header": {
                    "backgroundColor": "#FFFFFF",
                    "assistantName": "Custom Assistant",
                    "assistantNameTextColor": "#324661",
                    "fontFamily": "sans-serif",
                    "closeAssistantColor": "#AAAAAA",
                    "closeAssistantButtonImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/close-2.png"
                },
                "body": {
                    "backgroundColor": "#F4F4F6",
                    "messageSentBackgroundColor": "#808080",
                    "messageReceivedTextColor": "#324661",
                    "messageReceivedBackgroundColor": "#0000000d",
                    "messageSentFontFamily": "sans-serif",
                    "messageReceivedFontFamily": "sans-serif",
                    "hintsFontFamily": "sans-serif"
                },
                "toolbar": {
                    "backgroundColor": "#FFFFFF",
                    "speechResultBoxBackgroundColor": "#808080",
                    "partialSpeechResultFontFamily": "sans-serif",
                    "assistantStateFontFamily": "sans-serif",
                    "helpTextFontFamily": "sans-serif",
                    "speakFontFamily": "sans-serif",
                    "typeFontFamily": "sans-serif",
                    "textboxFontFamily": "sans-serif",
                    "textInputActiveLineColor": "#AAAAAA",
                    "textInputLineColor": "#AAAAAA",
                    "textboxActiveHighlightColor": "#80808033",
                    "equalizerColor": "#808080,#AAAAAA",
                    "micActiveColor": "#808080",
                    "sendActiveColor": "#808080",
                    "sendInactiveColor": "#808080",
                    "micActiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/mic-image-3.png",
                    "micInactiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/mic-image-3.png",
                    "sendActiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/send-image-3.png",
                    "sendInactiveImage": "https://voicify-prod-files.s3.amazonaws.com/sdk-images/send-image-3.png"
                }
            }
        }
    },
    "styles": {
        "assistant": {},
        "header": {},
        "body": {},
        "toolbar": {},
        "start": {}
    }
}