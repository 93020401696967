import type { AfterResponseEffect, RequestContext } from "assistant/VoicifyAssistant";
import { VOICIFY_AFTER_EFFECT_DATA, VOICIFY_AFTER_RESPONSE_DATA, VOICIFY_EFFECT_DATA } from "../keys/localStorageKeys";


export const getLocalStorageAfterEffectData = (): RequestContext[] => {
    var str = localStorage.getItem(VOICIFY_AFTER_EFFECT_DATA);
    if (str) {
        var arr = JSON.parse(str) as RequestContext[];
        return arr;
    }
    return [];
};

export const setLocalStorageAfterEffectData = (contexts: RequestContext[]) => {
    const effectData = JSON.stringify(contexts);
    localStorage.setItem(VOICIFY_AFTER_EFFECT_DATA, effectData);
};

export const addAfterEffectContext = (context: RequestContext) => {
    const arr = getLocalStorageAfterEffectData();
    const newArr = [...arr, context];
    setLocalStorageAfterEffectData(newArr);
}

export const getLocalStorageEffectData = () => {
    return localStorage.getItem(VOICIFY_EFFECT_DATA)
};

export const setLocalStorageEffectData = (data: { [key: string]: any; }) => {
    const effectData = JSON.stringify(data);
    localStorage.setItem(VOICIFY_EFFECT_DATA, effectData);
};


export const getLocalStorageAfterResponseData = (): AfterResponseEffect[] =>{
    var str = localStorage.getItem(VOICIFY_AFTER_RESPONSE_DATA);
    if (str) {
        var arr = JSON.parse(str) as AfterResponseEffect[];
        return arr;
    }
    return [];
};

export const setLocalStorageAfterResponseData = (afterResponseEffects: AfterResponseEffect[]) => {
    const effectData = JSON.stringify(afterResponseEffects);
    localStorage.setItem(VOICIFY_AFTER_RESPONSE_DATA, effectData);
};

export const extractDataValue = (data: { [key: string]: any }, propertyName: string, defaultValue: any) => {
    if (data.hasOwnProperty(propertyName)) {
        return data[propertyName];
    }
    else {
        return defaultValue;
    }
}

export const addItemToAfterResponseData = (effectName: string, inRequestContext: RequestContext, inData: { [key: string]: any }) => {
    var afterResponseEffects = getLocalStorageAfterResponseData();
    afterResponseEffects.push({
        data: inData,
        effect: effectName,
        requestContext: inRequestContext
    });
    setLocalStorageAfterResponseData(afterResponseEffects);
}

export const findElement = (elementId: string, elementName: string, querySelector: string): HTMLElement | null => {
    let element: HTMLElement | null = null;
    if (elementId) {
        element = document.getElementById(elementId);
    }
    if (elementName && !element) {
        const elements = document.getElementsByName(elementId);
        if (elements?.length)
            element = elements[0];
    }
    if (querySelector && !element) {
        element = document.querySelector(querySelector);
    }

    return element;
}

export const sleep = async (ms: number) => {
    await new Promise(r => setTimeout(r, ms));
}