import styled from "@emotion/styled";
import { color_gradients_silver_shift, color_shades_dark, color_text_default } from "../../../constants/colors";


export const SmallTag = styled.div`
    position: relative;
    white-space: nowrap;
    margin: 0px 16px 16px 16px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    text-align: center;
    padding: 6px 24px 0px 24px;
    font-size: 14px;
    font-weight: 600;
    background: ${color_gradients_silver_shift};
    color: ${color_text_default};
    border: solid 1px ${color_shades_dark};
    border-radius: 16px;
    height: 32px;
    display: inline-block;
`;

export const SmallTagWithMargin = styled(SmallTag)`
    margin-top: 16px;
`;