import React from 'react';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import styled from '@emotion/styled';
import { color_colors_ocean } from '../../../constants/colors';
import ModuleCard from './ModuleCard';
import ModuleListItem from './ModuleListItem';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import EmptyView from './EmptyView';

interface PrivateTemplatesProps {
    templateConfigurationContainer: TemplateConfigurationContainer
    applicationId: string
    privateTemplates: TemplateConfigurationModel[]
}

const PrivateTemplates: React.FC<PrivateTemplatesProps> = ({
    templateConfigurationContainer,
    applicationId,
    privateTemplates
}) => {

    return (
        <InnerPageContainer>
            <PageHeader>Private Modules</PageHeader>
            <PageDetail>These are the private modules you've been given access to from other organizations</PageDetail>
            {templateConfigurationContainer.state.isLoading
                && <LoaderContainer>
                    <SecondaryLoader />
                </LoaderContainer>
            }
            {templateConfigurationContainer.state.defaultViewType == "table"
                ? <ListContainer>
                    {privateTemplates?.map(t => (
                        <li key={t.id}><ModuleListItem templateConfiguration={t} key={t.id} applicationId={applicationId} /></li>
                    ))}
                </ListContainer>
                : <CardContainer>
                    {privateTemplates?.map(t => (
                        <li key={t.id}><ModuleCard templateConfiguration={t} key={t.id} applicationId={applicationId} /></li>
                    ))}
                </CardContainer>
            }
            {privateTemplates?.length < 1 && !templateConfigurationContainer.state.isLoading &&
                <EmptyView title="It's quiet in here!"
                    description="This is where you'll see the modules you've been given access to. Module builders can share their private modules with you and you can use them from here. Try exploring available modules to add!"
                    actionLink={`/v/apps/${applicationId}/modules/discover`}
                    actionText="Explore Modules" />}
        </InnerPageContainer>
    )
};

const InnerPageContainer = styled.div(`
    padding-bottom: 128px;
`);

const CardContainer = styled.ul(`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
`);

const ListContainer = styled.ul(`
    list-style-type: none;
`);

const LoaderContainer = styled.div(`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
    .spinner > div {
        background-color: ${color_colors_ocean};
    }
`);

const PageHeader = styled.h3(`
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 32px 0 24px 32px;
`);

const PageDetail = styled.p(`
    margin: auto 32px;
`);

export default PrivateTemplates;