import React from 'react';
import { css } from '@emotion/css';
import { ocean_blue, cool_grey, silver, dark_grey_blue } from '../../../constants/colors';
import { getFeatureIcon } from '../../../models/extensions';
import FeatureModel from '../../../models/features/api/FeatureModel';

interface FeatureSelectListItemProps {
    feature: FeatureModel
    onSelected: (feature: FeatureModel) => void
    viewOnly?: boolean
    top?: boolean
    bottom?: boolean
}
const containerStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    background: white;
    img {
        width: 32px;
        height: 32px;
        margin: 0 16px;
    }
    p {
        color: ${dark_grey_blue};
        font-size: 16px;
    }
`

export const FeatureSelectListItem: React.SFC<FeatureSelectListItemProps> = (props) => {
    if (props.feature) {
        return (
            <div className={`${containerStyle} ${props.viewOnly ? '' : 'selectable'} 
                     ${props.top ? 'top' : ''} ${props.bottom ? 'bottom' : ''}
                    ac-new-content-item ac-new-content-item-${props.feature?.featureType?.id}`} onClick={() => props.onSelected(props.feature)}>
                <img className="template-icon" src={getFeatureIcon(props.feature)} />
                <p className="template-title">{props.feature?.featureType?.name ?? props.feature?.name}</p>
            </div>)
    }
    return null;
}

