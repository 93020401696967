import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import Select, { Option, ValueComponentProps } from 'react-select';
import { dark_grey_blue, cool_grey } from '../../constants/colors';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ApplicationAvatar from '../general/ApplicationAvatar';
import ApplicationContainer from '../../state/containers/ApplicationContainer';

// component used in building forms with consistent components for look and function
interface AppSelectFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: any) => void
    label?: string
    topLabel?: string
    id?: string
    appContainer: ApplicationContainer
    useFormTheme?: boolean
    hidden?: boolean
    icon?: string
    organizationId: string
    clearable?: boolean
}

export interface AppOption extends Option {
    app: ApplicationModel
}

interface IconOptionProps {
    className?: string,
    isDisabled?: boolean,
    isFocused?: boolean,
    isSelected?: boolean,
    onFocus?: (option: any, event: any) => void,
    onSelect?: (option: any, event: any) => void,
    option: AppOption
}

class IconOptionComponent extends React.Component<IconOptionProps> {

    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }
    handleMouseEnter(event) {
        this.props.onFocus(this.props.option, event);
    }
    handleMouseMove(event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }
    render() {
        return (
            <div className={this.props.className}
                onMouseDown={this.handleMouseDown.bind(this)}
                onMouseEnter={this.handleMouseEnter.bind(this)}
                onMouseMove={this.handleMouseMove.bind(this)}
                title={this.props.option.label}>
                <ApplicationAvatar app={this.props.option.app} size={32} className="app-avatar" />
                <span className="Select-value-label">
                    {this.props.children}
                </span>
            </div>
        );
    }
}

class IconValue extends React.Component<ValueComponentProps<string>> {
    render() {
        return (
            <div className="Select-value" title={this.props.value.title}>
                <ApplicationAvatar app={this.props.value.app} size={32} className="app-avatar" />
                <span className="Select-value-label">
                    {this.props.children}
                </span>

            </div>
        );
    }
}
const selectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    flex: 1;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    .Select-control {
        border-radius: 12px;
        min-height: 64px;
    }
    .Select-placeholder, .Select-value{
        padding: 0 24px;
        padding-left: 24px;
        font-family: Muli;
        font-size: 14px;
        display: flex;
        align-items: center;
        .app-avatar {
            margin-left: 14px;
        }
    }
    .Select-value-label {
        margin-left: 16px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        overflow: hidden;
    }
    .Select-option, Select-noresults {
        height: 64px;
        padding: 0 24px;
        font-family: Muli;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    .Select-input {
        margin: 16px;
        font-family: Muli, sans-serif;
    }
`

const containerStyle = css`
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    flex: 1;
    > * + * {
        margin-top: 8px;
    }

    label {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: ${dark_grey_blue};
        margin-left: 16px;
    }

    .detail-text {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.7px;
        text-align: right;
        color: ${cool_grey};
        margin-right: 8px;
        margin-left: auto;
    }

    .detail-icon {
        margin-right: 32px;
    }
`

const AppSelectField = (props: AppSelectFieldProps) => {
    const [apps, setApps] = useState([] as ApplicationModel[])
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const initialLoad = async () => {
            const appsResult = await props.appContainer.searchForApplications(props.organizationId, 1, 20, "CreatedDateDescending");
            setApps(appsResult.data.applications);
        }
        initialLoad();
    }, []);
    const handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        props.onChange(value);
    };
    const handleInputChange = async value => {
        if (value.length > 3 && !isLoading) {
            await setIsLoading(true);
            const appsResult = await props.appContainer.searchForOrgApplications(props.organizationId, 1, 20, "CreatedDateDescending", value);
            await setApps(appsResult.data.applications);
            await setIsLoading(false);
        }
    }
    const handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        props.onBlur(true);
    };

    return (

        <div className={containerStyle}>
            {props.label !== undefined ? <label>{props.label}</label> : null}
            <Select id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={handleChange.bind(this)}
                onBlur={handleBlur.bind(this)}
                onInputChange={handleInputChange.bind(this)}
                disabled={props.disabled}
                options={apps.map(a => ({
                    app: a,
                    label: a.name,
                    value: a.id
                }))}
                valueComponent={IconValue}
                clearable={props.clearable}
                optionComponent={IconOptionComponent}
                className={selectStyle} />
        </div>
    );

}

export default AppSelectField;