import React from 'react';
import {css} from '@emotion/css';

interface ConversationItemFieldSetProps {
    disabled?: boolean
    withBanner?: boolean
}

const ConversationItemFieldSet: React.FC<ConversationItemFieldSetProps> = (props) => (
    <fieldset disabled={props.disabled} className={`${containerStyle} ${props.withBanner ? withBannerStyle : ''}`}>
        {props.children}
    </fieldset>
);
const withBannerStyle = css`
    padding-bottom: 72px;
`
const containerStyle = css`
    height: 100%;
`;

export default ConversationItemFieldSet;