import React from 'react';
import { css } from '@emotion/css';
import ConversationRequest from '../../models/conversationFlow/ConversationRequest';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import PositionIndicator from '../structure/ConversationFlow/PositionIndicator';
import { ocean_blue, dark_grey_blue, highlight_green, dark_grey_blue_20, greeny_blue, cool_grey, dark_sky_blue, highlight_blue, pastel_red } from '../../constants/colors';
import TooltipWrapper from '../general/TooltipWrapper';
const deviceIcon = require('../../content/images/device.svg');
const infoIcon = require('../../content/images/info_icon.svg');


const containerStyle = css`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
    }
`
const bubbleStyle = css`
    margin: 30px 0 0 115px;
    border: solid 1px ${pastel_red};
    box-shadow: -1px 4px 10px 0 ${dark_grey_blue_20};
    background: white;
    border-radius: 10px 0 10px 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media only screen and (min-width: 1240px) {
        max-width: 20%;
        margin-right: 0;
        margin-left: auto;
    }
    .placeholder {
        text-align: center;
        font-style: italic;
        color: ${cool_grey};
    }
    .content-container {
        margin:auto;
        display: flex;
        flex-direction: row;
        img {
            margin-left: 8px;
            width: 20px;
            height: 20px;
            color: ${cool_grey};
        }
    }

`;

const UnsupportedResponseBubble: React.SFC = props =>
(
    <div className={containerStyle}>
        <div className={bubbleStyle}>
            <div className="content-container">
                <p className="placeholder">Unsupported Response</p>
                <TooltipWrapper text="The simulator does not support responses intended for native platforms"><img src={infoIcon} /></TooltipWrapper>                
            </div>
        </div>
        <img src={deviceIcon} />
    </div>
)

export default UnsupportedResponseBubble;
