import React from 'react';
import MediaContainer from '../../../state/containers/MediaContainer';
import MediaResponseStateContainer from '../../../state/containers/MediaResponseContainer';
import { css } from '@emotion/css';
import SearchField from '../../../components/forms/SearchField';
import RoundedSelectionRow from '../../../components/general/RoundedSelectionRow';
import SortedPaginatedTable from '../../../components/general/SortedPaginatedTable';
import TableCell from '../../../components/general/TableCell';
import MediaItemModel from '../../../models/media/MediaItemModel';
import TableHeader from '../../../components/general/TableHeader';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { ocean_blue } from '../../../constants/colors';
import { Option } from 'react-select'
import ExpandedMediaItem from './ExpandedMediaItem';
import ReactTable, { ExpandedRows, RowInfo } from 'react-table';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import RemoveConfirmation from '../../../components/general/RemoveConfirmation';
import MediaGridPreview from './MediaGridPreview';
import IResult from '../../../models/result/IResult';
import MediaItemLinkedContent from '../../../models/media/MediaItemLinkedContent';
import { Link } from 'react-router-dom';
import { getEditUrl, getFeatureTypeIcon } from '../../../models/extensions';
import SimpleItemDisplayInfo from '../../../models/media/SimpleItemDisplayInfo';
import moment from 'moment';
import NumberIncrementSelector from '../../../components/forms/NumberIncrementSelector';
const audioIconBlack = require('../../../content/images/audio_icon_black.svg');
const imageIconBlack = require('../../../content/images/image_icon_black.svg');
const videoIconBlack = require('../../../content/images/video_icon_black.svg');
const audioIconBlue = require('../../../content/images/audio_icon_blue.svg');
const imageIconBlue = require('../../../content/images/image_icon_blue.svg');
const videoIconBlue = require('../../../content/images/video_icon_blue.svg');
const attachmentIcon = require('../../../content/images/attachment.svg');
const restoreIcon = require('../../../content/images/undo.svg');
interface MediaLibraryTabProps {
    mediaContainer: MediaContainer
    stateContainer: MediaResponseStateContainer
    applicationId: string
    onAttachMediaItem: (item: MediaItemModel) => void
}

interface MediaLibraryState {
    selectedMediaFilter: number
    page: number
    pageLimit: number
    recordsPerPage: number
    searchValue: string
    sortMethod: string
    expandedRows: ExpandedRows
    freezeWhenExpanded: boolean
    isDeleteLoading: boolean
    isDeleteModuleVisible: boolean
    deletingItem: MediaItemModel
    deletingItemLinkedContent: MediaItemLinkedContent
    displayedItems: MediaItemModel[]
    view: "grid" | "table"
    reset: boolean
    showingDisabledItems: boolean
}

const defaultState: MediaLibraryState = {
    selectedMediaFilter: 0,
    page: 1,
    pageLimit: 1,
    recordsPerPage: 20,
    isDeleteLoading: false,
    searchValue: '',
    deletingItem: {},
    deletingItemLinkedContent: {},
    freezeWhenExpanded: true,
    sortMethod: "LastUpdated",
    expandedRows: {},
    reset: false,
    displayedItems: [],
    view: "table",
    showingDisabledItems: false,
    isDeleteModuleVisible: false
}

class MediaLibraryTab extends React.Component<MediaLibraryTabProps, MediaLibraryState> {
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);

        // Set the state directly. Use props if necessary.
        this.state = {
            ...defaultState
        }
    }
    componentWillMount() {
        this.resetDisplayedItems();
    }
    updateMediaItem(item: MediaItemModel, tableIndex: number): void {
        this.props.mediaContainer.updateMediaItem(item);
    }
    handleSearch(searchEvent) {
        this.updateSearchValue(searchEvent.target.value);
    }
    toggleView() {
        this.setState({
            ...this.state,
            view: (this.state.view.localeCompare("grid") === 0) ? "table" : "grid",
        })
    }
    resetDisplayedItems() {
        this.setState({
            ...this.state,
            freezeWhenExpanded: false,
            expandedRows: {},
        }, this.setDisplayedItemsFromState);
    }
    setDisplayedItemsFromState() {
        this.setDisplayedItems(this.state.selectedMediaFilter, this.state.searchValue, this.state.sortMethod, this.state.page);
    }
    setDisplayedItems(
        selectedFilter: number,
        searchValue: string,
        sortMethod: string,
        selectedPage: number,
    ): void {
        this.props.mediaContainer.getMediaItemsForApp(this.props.applicationId).then(result => {
            var items = result.data;
            // 1. Filter Disabled
            items = this.filterDisabledMediaItems(items, this.state.showingDisabledItems);
            // 2. Filter Type
            items = this.filterMediaItems(items, selectedFilter);
            // 3. Search
            items = this.searchMediaItems(items, searchValue);
            // 4. Set new page and page limit based off of number of available items.
            const newPage = this.getNewPageLimit(items, selectedPage);
            // 5. Sort
            items = this.sortMediaItems(items, sortMethod);
            // 6. Get the Right page  
            items = this.pageMediaItems(items, newPage);
            this.setState({
                ...this.state,
                displayedItems: items,
                freezeWhenExpanded: true,
                reset: false,
            });
        })
    }
    freezeRows(): void {
        this.setState({
            ...this.state,
            freezeWhenExpanded: true,
        })
    }
    getNewPageLimit(items: MediaItemModel[], newPage: number): number {
        let newLimit = Math.ceil(items.length / this.state.recordsPerPage);
        newLimit = newLimit === 0 ? 1 : newLimit;
        if (newPage > newLimit)
            newPage = newLimit;
        if (this.state.pageLimit != newLimit)
            this.setState({
                ...this.state,
                pageLimit: newLimit,
                page: newPage
            });
        return newPage;
    }
    sortMediaItems(items: MediaItemModel[], sortMethod: string): MediaItemModel[] {
        let sortFunction = (item1: MediaItemModel, item2: MediaItemModel): number => { return 1; };
        switch (sortMethod) {
            case "OldestUpdated":
                sortFunction = (item1: MediaItemModel, item2: MediaItemModel): number => {
                    return new Date(item1.modifiedDate).getTime() - new Date(item2.modifiedDate).getTime();
                };
                break;
            case "NameA2Z":
                sortFunction = (item1: MediaItemModel, item2: MediaItemModel): number => {
                    return item1.name.localeCompare(item2.name);
                };
                break;
            case "NameZ2A":
                sortFunction = (item1: MediaItemModel, item2: MediaItemModel): number => {
                    return -1 * item1.name.localeCompare(item2.name);
                };
                break;
            default: //also LatestUpdated            
                sortFunction = (item1: MediaItemModel, item2: MediaItemModel): number => {
                    return new Date(item2.modifiedDate).getTime() - new Date(item1.modifiedDate).getTime();
                };
                break;
        }
        return items.sort(sortFunction);
    }
    filterMediaItems(items: MediaItemModel[], selectedFilter: number): MediaItemModel[] {
        switch (selectedFilter) {
            case 1:
                return items.filter(i => i.mediaType === "ImageFile");
            case 2:
                return items.filter(i => i.mediaType === "AudioFile");
            case 3:
                return items.filter(i => i.mediaType === "VideoFile");
            default: // return everything
                return items;
        }
    }
    filterDisabledMediaItems(items: MediaItemModel[], disabled: boolean): MediaItemModel[] {
        return items.filter(i => i.isDisabled == disabled);
    }
    pageMediaItems(items: MediaItemModel[], selectedPage: number): MediaItemModel[] {
        if (items == null)
            return [];
        const pageStart = (selectedPage - 1) * this.state.recordsPerPage;
        return items.slice(pageStart, pageStart + this.state.recordsPerPage);
    }
    searchMediaItems(items: MediaItemModel[], searchValue: string): MediaItemModel[] {
        const lowerSearch = searchValue.toLowerCase();
        const returnItems = items.filter(i => i.name.toLowerCase().includes(lowerSearch));
        return returnItems;
    }
    updateMediaLibraryFilter(optionId: number) {
        this.setState({
            ...this.state,
            selectedMediaFilter: optionId,
            freezeWhenExpanded: false,
            view: (optionId === 1 ? "grid" : this.state.view),
        }, this.resetExpansionGridView);
        this.setDisplayedItems(optionId, this.state.searchValue, this.state.sortMethod, this.state.page)
    }
    updatePageNumber(increment: boolean) {
        let newPage = this.state.page + (increment ? 1 : -1);
        if (newPage > this.state.pageLimit)
            newPage = this.state.pageLimit;
        else if (newPage < 1)
            newPage = 1
        this.setState({
            ...this.state,
            page: newPage,
            freezeWhenExpanded: false,
        }, this.resetExpansionGridView);
        this.setDisplayedItems(this.state.selectedMediaFilter, this.state.searchValue, this.state.sortMethod, newPage);
    }
    updateSortMethod(method: Option<string>) {
        this.setState({
            ...this.state,
            sortMethod: method.value,
            freezeWhenExpanded: false,
        }, this.resetExpansionGridView);
        this.setDisplayedItems(this.state.selectedMediaFilter, this.state.searchValue, method.value, this.state.page)
    }
    replaceMediaItem(item: MediaItemModel, file: File, index: number): Promise<IResult<MediaItemModel>> {
        const promise = this.props.mediaContainer.replaceMediaItem(this.props.applicationId, item, file);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const expandedRows = { ...this.state.expandedRows };
                expandedRows[index] = true;
                this.setState({
                    ...this.state,
                    displayedItems: [
                        ...this.state.displayedItems.slice(0, index),
                        {
                            ...this.state.displayedItems[index],
                            name: file.name,
                            url: URL.createObjectURL(file)
                        },
                        ...this.state.displayedItems.slice(index + 1, this.state.displayedItems.length)
                    ],
                    freezeWhenExpanded: false,
                    expandedRows: expandedRows,
                }, this.freezeRows);
            }
        });
        return promise;
    }
    deleteMediaItem() {
        this.setState({
            ...this.state,
            freezeWhenExpanded: false,
            isDeleteLoading: true,
        }, this.completeDelete)
    }
    completeDelete() {
        this.props.mediaContainer.deleteMediaItem(this.state.deletingItem).then(result => {
            if (result.resultType == "Ok")
                this.closeDeleteModal(this.resetDisplayedItems);
            else
                this.setState({
                    ...this.state,
                    isDeleteLoading: false,
                })
        });
    }
    attemptDelete(item: MediaItemModel, linkedContent: MediaItemLinkedContent) {
        this.setState({
            ...this.state,
            isDeleteModuleVisible: true,
            deletingItem: item,
            deletingItemLinkedContent: linkedContent
        })
    }
    closeDeleteModal(callback: () => void) {
        this.setState({
            ...this.state,
            isDeleteModuleVisible: false,
            isDeleteLoading: false,
            deletingItem: {},
            reset: true,
            freezeWhenExpanded: true,
        }, callback)
    }
    restoreMediaItem(item: MediaItemModel): void {
        const newItem = {
            ...item,
            isDisabled: false
        }
        this.props.mediaContainer.updateMediaItem(newItem).then(result => {
            if (result.resultType == "Ok") {
                this.resetDisplayedItems();
            }
        });
    }
    updateSearchValue(searchValue: string) {
        this.setState({
            ...this.state,
            searchValue: searchValue,
            freezeWhenExpanded: false,
        }, this.resetExpansionGridView);
        this.setDisplayedItems(this.state.selectedMediaFilter, searchValue, this.state.sortMethod, this.state.page)
    }
    renderPreviewItem(item: MediaItemModel, column: number): JSX.Element {
        return <MediaGridPreview
            attachMediaItem={(item) => { return (item.isDisabled ? this.restoreMediaItem(item) : this.props.onAttachMediaItem(item)) }}
            item={item}
            column={column} />
    }
    renderTopToolbar(): JSX.Element {
        return this.state.showingDisabledItems ?
            <div className={disabledTopToolbar}>
                <div className={`viewWarningText`}>NOW VIEWING ARCHIVED ITEMS</div>
                <span />
                <div className={`toggleDisabled`} onClick={this.toggleDisabledView.bind(this)}>Back to Normal View</div>
            </div>
            :
            null;
    }
    resetExpansionGridView(index?: number): void {
        this.setState({
            ...this.state,
            reset: true,
            expandedRows: {},
        }, this.resetExpansionGridViewCallback);
    }
    resetExpansionGridViewCallback(): void {
        this.setState({
            ...this.state,
            reset: false,
        });
    }
    generateLinkedContentLinks(linkedContent: MediaItemLinkedContent): SimpleItemDisplayInfo[] {
        const returnArray: SimpleItemDisplayInfo[] = [];
        if (linkedContent.contentItems != null)
            linkedContent.contentItems.forEach(item => {
                returnArray.push({
                    name: item.name,
                    url: getEditUrl(item.contentItemId, item.applicationFeatureId, item.featureTypeId),
                    icon: getFeatureTypeIcon(item.featureTypeId, true)
                });
            });
        return returnArray;
    }
    toggleRow(index: number) {
        const expandedRows = { ...this.state.expandedRows };
        expandedRows[index] = !(expandedRows[index] === true);
        this.setState({
            ...this.state,
            expandedRows: expandedRows
        })
    }
    expandRow(index: number) {
        const expandedRows = { ...this.state.expandedRows };
        expandedRows[index] = true;
        this.setState({
            ...this.state,
            expandedRows: expandedRows
        })
    }
    collapseRow(index: number): void {
        const expandedRows = { ...this.state.expandedRows };
        expandedRows[index] = false;
        this.setState({
            ...this.state,
            expandedRows: expandedRows
        }, this.resetDisplayedItems);
    }
    toggleDisabledView() {
        this.state.showingDisabledItems ?
            this.setState({
                ...this.state,
                showingDisabledItems: false
            }, this.resetDisplayedItems)
            :
            this.setState({
                ...this.state,
                showingDisabledItems: true
            }, this.resetDisplayedItems)
            ;
    }
    render() {
        const columns = [
            {
                id: "chevronCol",
                width: 20,
                Header: <TableHeader text="" />,
                accessor: (item: MediaItemModel) => item,
                Cell: row => (
                    <div className={expanderContainer}>
                        {(this.state.expandedRows[row.index] === true)
                            ? <TableCell><ToggleIndicator onClick={() => this.collapseRow(row.index)} className={chevronStyle} direction="up" /></TableCell>
                            : <TableCell><ToggleIndicator onClick={() => this.expandRow(row.index)} className={chevronStyle} direction="down" /></TableCell>}
                    </div>
                )
            },
            {
                id: "typeCol",
                width: 50,
                Header: <TableHeader text="TYPE" />,
                accessor: (item: MediaItemModel) => item.mediaType,
                Cell: row => (
                    <TableCell key="cell1">
                        <img src={row.value === "AudioFile" ? audioIconBlack :
                            row.value === "VideoFile" ? videoIconBlack :
                                row.value === "ImageFile" ? imageIconBlack : imageIconBlack} />
                    </TableCell>
                )
            },
            {
                id: "filenameCol",
                Header: <TableHeader text="FILENAME" />,
                accessor: (item: MediaItemModel) => item.name,
                Cell: (row) => (
                    <TableCell key="cell2" className={`${textStyle} ${fileDescriptionStyle}`}>
                        <span onClick={() => this.toggleRow(row.index)}>{row.value ? row.value : "No Name"}</span>
                    </TableCell>
                )
            },
            {
                id: "udpatedCol",
                width: 105,
                Header: <TableHeader text="UPDATED" />,
                accessor: (item: MediaItemModel) => {
                    return moment(item.modifiedDate).format("MMM DD, YYYY");
                },
                Cell: row => (
                    <TableCell key="cell3" className={textStyle}>
                        {row.value ? row.value : "No Last Updated"}
                    </TableCell>
                )
            },
            {
                id: "attachCol",
                width: 90,
                Header: <TableHeader text="" />,
                accessor: (item: MediaItemModel) => item,
                Cell: row => (
                    <TableCell key="cell3" className={`${textStyle} attach ${attachCell}`} >
                        {this.state.showingDisabledItems ?
                            <div onClick={() => this.restoreMediaItem(row.value)}><span className={blueText}>Restore</span><img src={restoreIcon} /></div> :
                            <div onClick={() => this.props.onAttachMediaItem(row.value)}><span className={blueText}>Attach</span><img src={attachmentIcon} /></div>}
                    </TableCell>
                )
            },
        ]
        const linkedContentData = this.generateLinkedContentLinks(this.state.deletingItemLinkedContent);
        return (
            <div className={mediaLibraryTabStyle}>
                <SearchField
                    id="1"
                    name={"searchField"}
                    placeholder={"Search"}
                    disabled={false}
                    onChange={this.handleSearch.bind(this)} />
                <div className={textStyle}>Media-Type:</div>
                <RoundedSelectionRow
                    options={[
                        { name: "All Media" },
                        { icon: imageIconBlack, selectedIcon: imageIconBlue, name: "Images" },
                        { icon: audioIconBlack, selectedIcon: audioIconBlue, name: "Audio" },
                        { icon: videoIconBlack, selectedIcon: videoIconBlue, name: "Video" }
                    ]}
                    onClick={(num) => this.updateMediaLibraryFilter(num)}
                    selectedFilter={this.state.selectedMediaFilter}
                />
                <SortedPaginatedTable
                    columns={columns}
                    renderTopToolbar={this.renderTopToolbar.bind(this)}
                    items={this.state.displayedItems}
                    updateSortValue={(val) => this.updateSortMethod(val)}
                    updatePageValue={(b) => this.updatePageNumber(b)}
                    updateViewValue={this.toggleView.bind(this)}
                    view={this.state.view}
                    page={this.state.page}
                    showView={true}
                    reset={this.state.reset}
                    sortValue={this.state.sortMethod}
                    itemRender={this.renderPreviewItem.bind(this)}
                    freezeWhenExpanded={this.state.freezeWhenExpanded}
                    expandedRows={this.state.expandedRows}
                    subComponent={(row) =>
                        <ExpandedMediaItem
                            onUpdateMediaItem={(item, index) => this.updateMediaItem(item, index)}
                            key={row.index}
                            onReplaceMediaItem={this.replaceMediaItem.bind(this)}
                            mediaItem={row.original}
                            index={row.index}
                            onDeleteItem={this.attemptDelete.bind(this)}
                            onAttachMediaItem={this.props.onAttachMediaItem}
                            onRestoreMediaItem={this.restoreMediaItem.bind(this)}
                            onCollapse={this.collapseRow.bind(this)}
                            loadLinkedContent={(mediaItemId) => { return this.props.mediaContainer.getLinkedContent(mediaItemId) }}
                            generateLinkedContentLinks={this.generateLinkedContentLinks.bind(this)}
                        />}
                    expandRender={(media, index, column) =>
                        <ExpandedMediaItem
                            onUpdateMediaItem={(media, index) => this.updateMediaItem(media, index)}
                            onReplaceMediaItem={this.replaceMediaItem.bind(this)}
                            mediaItem={media}
                            onRestoreMediaItem={this.restoreMediaItem.bind(this)}
                            column={column}
                            index={index * 2 + column} //we need to figure out the actual index in the displayed items arry.
                            onDeleteItem={this.attemptDelete.bind(this)}
                            onAttachMediaItem={this.props.onAttachMediaItem}
                            loadLinkedContent={(mediaItemId) => { return this.props.mediaContainer.getLinkedContent(mediaItemId) }}
                            generateLinkedContentLinks={this.generateLinkedContentLinks.bind(this)}
                            onCollapse={this.resetExpansionGridView.bind(this)} />
                    }
                    sortOptions={[
                        { value: 'LastUpdated', label: 'Last Updated - Newest' },
                        { value: 'OldestUpdated', label: 'Last Updated - Oldest' },
                        { value: 'NameA2Z', label: 'Alphabetical - A-Z' },
                        { value: 'NameZ2A', label: 'Alphabetical - Z-A' },
                    ]} />
                {this.state.isDeleteModuleVisible ?
                    <ConfirmationDialog
                        title="Delete"
                        isLoading={this.state.isDeleteLoading}
                        deleteText="Yes, Delete"
                        onClose={() => this.closeDeleteModal(() => { })}
                        disabled={(this.state.deletingItem.isDisabled === true && this.state.deletingItemLinkedContent.contentItems.length > 0)}
                        onConfirm={() => this.deleteMediaItem()}>
                        <RemoveConfirmation customText={(this.state.deletingItem.isDisabled ?
                            (linkedContentData.length > 0 ?
                                <div className={deleteStyle}>
                                    You are not currently able to permanently delete
                                    <b> {this.state.deletingItem.name}</b>. You must first clear it from all linked content.
                                    {linkedContentData.length > 0 ? ` It is currently linked to ${linkedContentData.length} content items.` : ""}
                                </div>
                                :
                                <div className={deleteStyle}>
                                    Are you sure you want to permenantly delete<b> {this.state.deletingItem.name}</b>?
                                </div>
                            )
                            :
                            <div className={deleteStyle}>
                                Are you sure you want to delete
                                <b> {this.state.deletingItem.name}</b>
                                ? {linkedContentData.length > 0 ? `It is currently linked to ${linkedContentData.length} content items.` : ""}
                            </div>
                        )
                        } />
                    </ConfirmationDialog> : null}
                <div className={bottomRow}>
                    <div className={bottomToggle} onClick={this.toggleDisabledView.bind(this)}>{this.state.showingDisabledItems ? "Back to Normal View" : "Show Archived Items"}</div>
                    <NumberIncrementSelector
                        selectedNumber={this.state.page < 10 ? "0" + this.state.page : "" + this.state.page}
                        updateValue={(b) => this.updatePageNumber(b)}
                        showNumber={true}
                    />
                </div>
            </div>
        )
    }
}

const bottomRow = css`
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    .page-container {
        margin-left: auto;
        margin-right: 0px;
    }
`;

const bottomToggle = css`
    cursor: pointer;
    color: ${ocean_blue};
    font-size: 14px;
    font-family: Muli;
    padding-top: 10px;
`;

const disabledTopToolbar = css`
    display: flex;
    flex-direction: row;
    font-family: Muli;
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center; 
    .toggleDisabled {        
        color: ${ocean_blue};
        cursor: pointer;
    }
    span {
        flex-grow: 1;
    }
`;

const deleteStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;  
`;

const attachCell = css`    
    img {
        padding-top: 5px;
        padding-right: 10px;
    }
`;


const textStyle = css`    
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    cursor: pointer;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    .attach {
        padding-top: 5px;
    }
`;

const fileDescriptionStyle = css`
    white-space: normal;
    word-break: break-all;
`;

const blueText = css`
    padding-right: 10px;
    color: ${ocean_blue};
    top: -5px;
    position: relative;
`;

const mediaLibraryTabStyle = css`
    text-align: center;
`;

const chevronStyle = css`
    margin-left: 5px;
`;

const expanderContainer = css`
    cursor: pointer;
`;

export default MediaLibraryTab;