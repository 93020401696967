import React, { useState } from 'react';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import StaticMenuPanel from '../../../components/structure/Panels/StaticMenuPanel';
import BodyPanel from '../../../components/structure/Panels/BodyPanel';
import TemplateConfigurationModel, { TemplateType } from '../../../models/templating/api/TemplateConfigurationModel';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import OwnedModulesMenu, { OwnedModuleView } from './OwnedModulesMenu';
import AppliedTemplates from './AppliedTemplates';
import OwnedTemplates from './OwnedTemplates';
import PrivateTemplates from './PrivateTemplates';

interface DiscoverModuleProps {
    templateConfigurationContainer: TemplateConfigurationContainer
    appliedTemplatesContainer: AppliedApplicationTemplateContainer
    applicationId: string
    organizationTemplates: TemplateConfigurationModel[]
    privateTemplates: TemplateConfigurationModel[]
    appliedTemplates: AppliedApplicationTemplateModel[]
}

const OwnedModules: React.FC<DiscoverModuleProps> = ({
    templateConfigurationContainer,
    applicationId,
    organizationTemplates,
    privateTemplates,
    appliedTemplates
}) => {

    const [templateType, setTemplateType] = useState(null as TemplateType);
    const [viewType, setViewType] = useState("Applied" as OwnedModuleView);

    return (
        <PanelContainer>
            <StaticMenuPanel>
                <OwnedModulesMenu
                    onTypeChange={setTemplateType}
                    onViewChange={setViewType}
                    selectedView={viewType}
                    selectedType={templateType}
                    categories={templateConfigurationContainer.state.categories}
                    industries={templateConfigurationContainer.state.industries}
                />
            </StaticMenuPanel>
            <BodyPanel>
                <ScrollablePanelBody>
                    {viewType == "Applied" &&
                        <AppliedTemplates
                            templateConfigurationContainer={templateConfigurationContainer}
                            applicationId={applicationId}
                            appliedTemplates={appliedTemplates}
                        />
                    }
                    {viewType == "Owned" &&
                        <OwnedTemplates
                            templateConfigurationContainer={templateConfigurationContainer}
                            applicationId={applicationId}
                            organizationTemplates={organizationTemplates}
                        />
                    }
                    {viewType == "Access" &&
                        <PrivateTemplates
                            templateConfigurationContainer={templateConfigurationContainer}
                            applicationId={applicationId}
                            privateTemplates={privateTemplates}
                        />
                    }
                </ScrollablePanelBody>
            </BodyPanel>
        </PanelContainer>
    )
};

export default OwnedModules