import { GET_USER_PROFILE } from "../urls";
import fetch from 'cross-fetch';

export const getUserData = (baseUrl: string, userId: string, appId: string, appKey: string) => {
    return (
        fetch(GET_USER_PROFILE(baseUrl, userId, appId, appKey), {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
    )
};