import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import _ from 'lodash';
import ApplicationConversationFlagModel from '../../models/features/api/Flags/ApplicationConversationFlagModel';

type ApplicationFlagsState = {
    isLoading: boolean
    applicationFlags: ApplicationConversationFlagModel[]
    errors: string[]
}

export default class ApplicationFlagsContainer extends Container<ApplicationFlagsState> {
    // default state
    state = this.getInitialState()

    getInitialState(): ApplicationFlagsState {
        return {
            isLoading: false,
            applicationFlags: [] as ApplicationConversationFlagModel[],
            errors: []
        }
    }
    async loadApplicationFlags(applicationId: string) {
        await this.setLoading(true);
        try {
            const result = await voicifyApi.getApplicationConversationFlags(applicationId);
            if(result?.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    errors: [],
                    isLoading: false,
                    applicationFlags: result.data
                })
            } else {
                await this.setState({
                    ...this.state,
                    isLoading:false,
                    errors: result.errors
                })
            }
            return result;
        } catch (e) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString() ?? "Unexpected error"]
            })
        }

    }

    private async setLoading(isLoading: boolean) {
        await this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}