import React, { useState } from "react";
import EditableIntent from "../../../models/interactionModel/EditableIntent";
import Button from "../../../components/general/Button";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import EditableSlot from "../../../models/interactionModel/EditableSlot";
import { DirtyFormPrompt } from "../../formScenes/components/DirtyFormPrompt";
import TextField from "../../../components/forms/TextField";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import Switch from '../../../components/forms/Switch';
import NlpEntitySelector from "./NlpEntitySelector";
import { maxSlotNameDisplaySize } from "./SlotsEditor";
import { color_colors_ocean, color_shades_darkest } from "../../../constants/colors";
import StyledDropdown from "../../applicationSettings/components/customAssistantConfigurations/StyledDropdown";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import useWindowSize from "../../../hooks/UseWindowSize";

const deleteAvatar = require("../../../content/images/custom-assistant-deployment/delete.svg");
const requiredSlotIcon = require('../../../content/images/nlp-icons/required-slot.svg');
const requiredGroupIcon = require('../../../content/images/nlp-icons/required-group.svg');
const disallowedSlotIcon = require('../../../content/images/nlp-icons/disallowed-slot.svg');

interface SlotCardProps {
    applicationId: string;
    nlpEntities: EditableNlpEntity[];
    intent: EditableIntent;
    slot: EditableSlot;
    formPrefix: string;
    formIndex: number;
    validateField;
    validateForm;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    handleSubmit;
    dirty: boolean;
    isValid: boolean;
    status?: any;
    allSlotGroups: string[]
};

const SlotCard: React.FC<SlotCardProps> = (props) => {
    const windowSize = useWindowSize();

    const slotName: string = props.slot?.name;
    const slotDisplayName: string = "{ } " + (slotName?.trim().length > maxSlotNameDisplaySize
        ? (slotName.trim().substring(0, maxSlotNameDisplaySize) + "...")
        : slotName);

    const cardPrefix = `${props.formPrefix}.slots.${props.formIndex}`;
    const nameField: string = `${cardPrefix}.name`;
    const requiredField: string = `${cardPrefix}.required`;
    const disallowedField: string = `${cardPrefix}.disallowed`;
    const requiredSlotGroupField: string = `${cardPrefix}.requiredSlotGroupIds`;
    const isModifiedField: string = `${cardPrefix}.isModified`;
    const nlpEntityNameField: string = `${cardPrefix}.entityName`;

    const selectedEntityName: string = props.slot?.entityName;
    const selectedEntityId: string = props.slot?.entityId;
    const entityNamesArray = props.nlpEntities?.map(e => e.name);

    const slotRequired = props.slot?.required;
    const slotDisallowed = props.slot?.disallowed;
    const slotHasRequiredSlotGroups = props.slot?.requiredSlotGroupIds?.length > 0;

    const selectedEntity: EditableNlpEntity = props.nlpEntities
        ?.find(e => (selectedEntityId ? e.id == selectedEntityId : e.name == selectedEntityName));

    const handleFieldChange = (fieldName, value) => {
        props.setFieldValue(fieldName, value);
        props.setFieldValue(isModifiedField, true);
    };

    const handleAddRequiredSlotGroup = () => {
        const newGroups = [...(props.slot.requiredSlotGroupIds || [])];
        if (props.allSlotGroups?.length) {
            newGroups.push(props.allSlotGroups[0]);
        } else {
            newGroups.push("Group 1");
        }
        handleFieldChange(requiredSlotGroupField, newGroups);
        //scroll to the bottom of the container
    }

    const getNextGroupName = () => {
        let i = 1;
        while (props.allSlotGroups?.includes(`Group ${i}`)) {
            i++;
        }
        return `Group ${i}`;
    }

    const handleAddGroupOption = (groupIndex: number) => {
        const newGroups = [...(props.slot.requiredSlotGroupIds || [])];
        let newGroupName = getNextGroupName();
        newGroups[groupIndex] = newGroupName;
        handleFieldChange(requiredSlotGroupField, newGroups);
    }

    const deleteGroupId = (groupIndex: number) => {
        const newGroups = [...(props.slot.requiredSlotGroupIds || [])];
        newGroups.splice(groupIndex, 1);
        handleFieldChange(requiredSlotGroupField, newGroups);
    }

    const handleEntitySelectorClick = (values: string[]) => {
        if (values?.length) {
            const selectedOption = values[0];

            if (selectedOption === "__custom") {
                // do nothing, clicking on group
            } else if (selectedOption === "__preBuilt") {
                // do nothing, clicking on group
            } else if (entityNamesArray.includes(selectedOption)) {

                if (selectedOption === selectedEntityName) return;

                const optionEntity: EditableNlpEntity = props.nlpEntities?.find(e => e.name == selectedOption);
                if (optionEntity == null) return;
                handleFieldChange(nlpEntityNameField, optionEntity?.name);

            }
        }
    };

    const handleRequiredSlotGroupClick = (value: any, groupIndex: number) => {
        if (value === "New Group") {
            handleAddGroupOption(groupIndex);
        } else {
            const newGroups = [...(props.slot.requiredSlotGroupIds || [])];
            newGroups[groupIndex] = value;
            handleFieldChange(requiredSlotGroupField, newGroups);
        }
    };

    const handleSlotNameKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
        event.stopPropagation();
        if (event.key === "Escape") {
        }
        else if (event.key === "Enter") {
            props.validateForm();
        }
    };

    return (
        <SlotCardWrapper>
            <CardHeader>
                <div>Properties for:</div>
                <CustomButton
                    themes={["black-small"]}
                    text={slotDisplayName}
                    type="button"
                />
            </CardHeader>
            <DirtyFormPrompt allowPrompt={props.status?.submitting != true} />
            <HorizontalSeparator />
            <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - 250}>
                <CardContentWrapper>
                    <SectionHeaderWrapper>
                        <SectionHeaderText>DETAILS</SectionHeaderText>
                        <HorizontalSeparator />
                    </SectionHeaderWrapper>
                    <TextField
                        className={textFieldStyle}
                        useForm={true}
                        name={nameField}
                        disabled={false}
                        required
                        placeholder="Slot Name"
                        value={props.slot?.name}
                        label="Name"
                        onChange={e => handleFieldChange(nameField, e?.target?.value)}
                        onBlur={props.handleBlur}
                        autoFocus={true}
                        onKeyPress={handleSlotNameKeyUp}
                    />
                    <NlpEntitySelector
                        fieldName={nlpEntityNameField}
                        label='Entity'
                        selectedDisplayText={selectedEntityName || "Select an entity"}
                        handleChange={handleEntitySelectorClick}
                        selected={selectedEntity}
                        nlpEntities={props.nlpEntities}
                        className={nlpEntitySelectorStyle}
                    />
                    <div>
                        <SectionHeaderWrapper>
                            <SectionHeaderText>PERMISSION</SectionHeaderText>
                            <HorizontalSeparator />
                        </SectionHeaderWrapper>
                        <Switch
                            icon={disallowedSlotIcon}
                            disabled={slotRequired}
                            name={disallowedField}
                            detail="If checked, this intent will not be hit if this slot is extracted."
                            checked={props.slot?.disallowed}
                            onChange={(checked) => handleFieldChange(disallowedField, checked)}
                            label={props.slot?.disallowed ? "Disallowed" : "Disallow"}
                            className={switchStyle}
                        />
                    </div>
                    <SectionHeaderWrapper>
                        <SectionHeaderText>REQUIREMENTS</SectionHeaderText>
                        <HorizontalSeparator />
                    </SectionHeaderWrapper>
                    <div>
                        <Switch
                            icon={requiredSlotIcon}
                            disabled={slotDisallowed}
                            detail="If checked, this slot will be required in all of this intent's utterances."
                            className={switchStyle}
                            name={requiredField}
                            checked={props.slot?.required}
                            onChange={(checked) => handleFieldChange(requiredField, checked)}
                            label={props.slot?.required ? "Is Required" : "Make Required"}
                        />
                    </div>
                    {(!slotRequired && !slotDisallowed && slotHasRequiredSlotGroups) &&
                        props.slot.requiredSlotGroupIds.map((groupId, index) => {

                            const availableOptions = props.allSlotGroups.map((groupId) => {
                                return {
                                    label: groupId,
                                    value: groupId
                                }
                            });

                            availableOptions.push({
                                value: "New Group",
                                label: "New Group"
                            });

                            return (
                                <>
                                    <LabelWrapper>
                                        <Label>Required Slot Group</Label>
                                        <LabelIcon src={requiredGroupIcon} />
                                    </LabelWrapper>
                                    <GroupDropdownContainer>
                                        <LabelAndDropdownContainer>
                                            <CustomDropdown
                                                handleOnChange={(fieldName, value) => handleRequiredSlotGroupClick(value, index)}
                                                fieldName={`requiredSlotGroup-${index}`}
                                                options={availableOptions}
                                                size="medium"
                                                searchable={true}
                                                value={props.slot.requiredSlotGroupIds[index]}
                                            />
                                        </LabelAndDropdownContainer>
                                        <DeleteButton src={deleteAvatar} onClick={() => deleteGroupId(index)} />
                                    </GroupDropdownContainer>
                                </>
                            )
                        })
                    }
                    {
                        !slotRequired && !slotDisallowed &&
                        <>
                            <AddAnIntentText onClick={handleAddRequiredSlotGroup}>
                                + Add to a required slot group
                            </AddAnIntentText>
                        </>
                    }
                </CardContentWrapper>
            </CustomScrollbars>
        </SlotCardWrapper>
    );

};

export default SlotCard;

const SectionHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 32px 0 24px 0;
`;

const SectionHeaderText = styled.h2`
    margin-right: 8px;
    font-size: 16px;
    color: ${color_shades_darkest};
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
`;

const CardContentWrapper = styled.div`
    margin: 16px 32px 16px 32px;
`;

const nlpEntitySelectorStyle = css`
    min-width: inherit;
    .field-wrapper {
        label {
            font-family: Muli;
            font-size: 14px;
        }
    }
`;

const CustomButton = styled(Button)`
    margin-left: 8px; 
`;

const textFieldStyle = css`
    margin: 16px 0;
    .field-container {
        height: 44px;
    }
`;

const switchStyle = css`
    margin-top: 16px;
    padding: 0;
`;
const GroupDropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const LabelAndDropdownContainer = styled.div`
    width: 100%;
`;

const DeleteButton = styled.img`
    cursor: pointer;
    margin-left: 16px;
    flex: 0;
`;

const SlotCardWrapper = styled.div``;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    margin: 0 32px;
`;

const ClickableText = styled.div`
    color: ${color_colors_ocean};
    font-size: 12px;
    cursor: pointer;
`;

const AddAnIntentText = styled(ClickableText)`
    font-size: 14px;
    height: 48px;
    margin-top: 24px;
`;

const CustomDropdown = styled(StyledDropdown)`
    margin-top: 4px;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 32px 0 8px 0;
    align-items: center;
`;

const Label = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin: 0 0 4px 4px;
`;

const LabelIcon = styled.img`
    margin-left: 4px;
`;