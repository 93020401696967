import React from 'react';
import { css } from '@emotion/css';
import Tooltip from 'rc-tooltip';
import OverflowMenu, { OverflowOption } from './OverflowMenu';
import { color_shades_darker, text_blue } from '../../../constants/colors';
import ToggleIndicator from '../ToggleIndicator';

interface TextOptionsMenuProps {
    isActive?: boolean
    onOptionSelected?: (optionName: string) => void
    options: OverflowOption[]
    className?: string
    optionClassName?: string
    menuClassName?: string
    toggleClassName?: string
    clickableText: string
    label?: string
}
interface TextOptionsMenuState {
    isActive: boolean
}

class TextOverflowMenu extends React.Component<TextOptionsMenuProps, TextOptionsMenuState> {
    componentWillMount() {
        this.setState({
            ...this.state,
            isActive: false
        })
    }

    componentDidUpdate(prevProps: TextOptionsMenuProps) {
        if (prevProps.isActive != this.props.isActive) {
            this.setState({
                ...this.state,
                isActive: this.props.isActive
            });
        }
    }

    handleClick(e) {
        e.stopPropagation();
        this.setState({
            ...this.state,
            isActive: !this.state.isActive
        })
    }

    handleMenuClose() {
        this.setState({
            ...this.state,
            isActive: false
        })
    }

    render() {
        return (
            <div className={`${containerCss} ${this.props.className}`}>         
                <div onClick={this.handleClick.bind(this)} className={expandTextStyle}>          
                    {this.props.label != null && <span onClick={this.handleClick.bind(this)} className={'label'}>{this.props.label}</span>}
                    <div>{this.props.clickableText}</div>
                    <ToggleIndicator
                        className={`${toggleStyle} ${this.props.toggleClassName ?? ''}`}
                        direction={this.state.isActive ? 'up' : 'down'} />
                </div>
                {this.state.isActive ?
                    <OverflowMenu
                        onClose={this.handleMenuClose.bind(this)}
                        onOptionSelected={this.props.onOptionSelected}
                        options={this.props.options}
                        optionClassName={`${optionCss} ${this.props.optionClassName ?? ''}`}
                        className={`${menuCss} ${this.props.menuClassName ?? ''}`}>
                        {this.props.children}
                    </OverflowMenu> 
                : null}
            </div>
        )
    }
}

const toggleStyle = css`
    margin: 4px 0px 0px 8px;
    border-left: 1px solid ${color_shades_darker};
    border-top: 1px solid ${color_shades_darker};
`;

const optionCss = css`    
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 150px;
`;

const menuCss = css`    
    margin-left: 70px;
    width: 150px;
    margin-top: 26px;
`;

const containerCss = css`
    display: flex;
    flex-direction: row;    
    border: 0px;
    position: relative;
`;

const expandTextStyle = css`
    cursor: pointer;
    color: ${color_shades_darker};
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    .label {
        margin-right: 8px;
    }
`;

export default TextOverflowMenu;