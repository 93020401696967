import { withFormik } from "formik";
import * as yup from 'yup';
import NumberRangeForm, { NumberRangeFormProps, NumberRangeFormData } from '../../formScenes/NumberRangeForm';

const getRangeType = (min?: number, max?: number) => {
    if (min != null && max != null && min == max) {
        return "equal";
    }
    else if (min != null && max != null) {
        return "between";
    }
    else if (min != null) {
        return "greater-than";
    }
    else if (max != null) {
        return "less-than";
    }
    return "equal";
}
const EditNumberRangeForm = withFormik<NumberRangeFormProps, NumberRangeFormData>({
    mapPropsToValues: (props: NumberRangeFormProps) => {
        var values: NumberRangeFormData = {
            title: props.numberRange.title,
            minimumValue: props.numberRange.minimumValue,
            maximumValue: props.numberRange.maximumValue,
            rangeType: getRangeType(props.numberRange.minimumValue, props.numberRange.maximumValue),
            responses: props.numberRange.responses.length > 0 ? props.numberRange.responses : [{ content: '' }],
            requiresParent: true,
            languages: props.numberRange.languages,
            isLive: props.numberRange.isLive,
            webhookChainingType: props.numberRange.webhookChainingType,
            sensitiveConversationChange: props.numberRange.sensitiveConversationChange ?? "None",
        };

        return values;
    },
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.numberRange, values));
    }
})(NumberRangeForm);

export default EditNumberRangeForm;