import { getTextToSpeech } from "../apis/fetches/getTextToSpeech";
import type { TextToSpeechRequest, TextToSpeechResponse, VoicifyTextToSpeechSettings } from "../models/textToSpeechModels";
import type VoicifyTextToSpeechProvider from "../models/textToSpeechModels";

type LinkedUrls = {
    src?: string
    next?: LinkedUrls
}

export class BrowserTextToSpeechProvider implements VoicifyTextToSpeechProvider {
    settings: VoicifyTextToSpeechSettings
    audioPlayer?: HTMLAudioElement
    speechEndHandlers?: (() => void)[] = []

    constructor(settings: VoicifyTextToSpeechSettings) {
        this.settings = settings;
    }

    initialize(locale: string) {
        this.settings.locale = locale;
    };

    createLinkedList(src: TextToSpeechResponse[]) {
        let top: LinkedUrls = {};
        for (let i = src.length - 1; i >= 0; i--) {
            if (i == src.length - 1) {
                top = {
                    src: src[i].url,
                    next: {}
                }
            }
            else {
                let temp = {
                    src: src[i].url,
                    next: top
                };
                top = temp;
            }
        }
        return top;
    };

    async playUrl(url: string | TextToSpeechResponse[]) {
        let linkedLists: LinkedUrls = {};
        if (Array.isArray(url)) {
            linkedLists = this.createLinkedList(url);
            if (linkedLists.src) {
                const sound = new Audio(linkedLists.src);
                this.audioPlayer = sound;
            };
            let currentPlayer = linkedLists;
            if (this.audioPlayer) {
                await this.audioPlayer.play();
                this.audioPlayer.onended = async () => {
                    if (currentPlayer?.next?.src && this.audioPlayer) {
                        this.audioPlayer.src = currentPlayer.next?.src;
                        await this.audioPlayer?.play();
                        currentPlayer = currentPlayer.next;
                    } else {
                        this.speechEndHandlers?.forEach(handle => handle());
                    }
                };
            };
        } else {
            if (url) {
                const sound = new Audio(url);
                this.audioPlayer = sound;
                this.audioPlayer.onended = () => {
                    this.speechEndHandlers?.forEach(handle => handle());
                };
                await this.audioPlayer.play();
            };
        };
    }

    async speakSsml(ssml: string) {
        const ssmlUrl = await this.ssmlToSpeech(ssml);
        if (ssmlUrl) {
            this.playUrl(ssmlUrl)
        };
    };

    stop() {
        if (this.audioPlayer) {
            this.audioPlayer.pause();
            this.audioPlayer.currentTime = 0;
        };
    };

    addFinishListener(callback: () => void) {
        this.speechEndHandlers?.push(callback);
    }

    clearHandlers() {
        this.speechEndHandlers = [];
    }

    async ssmlToSpeech(ssml: string) {
        try {
            const textToSpeechRequest = this.generateTTSRequest(ssml);
            const result = await getTextToSpeech(textToSpeechRequest, this.settings.serverRootUrl, this.settings.textToSpeechProvider);
            if (result.status == 200) {
                const response = await result.json() as TextToSpeechResponse[];
                return (response)
            }
            else {
                return null
            }
        }
        catch (e: any) {
            return null;
        };
    };

    generateTTSRequest(ssml: string): TextToSpeechRequest {
        const request: TextToSpeechRequest = {
            applicationId: this.settings.appId,
            applicationSecret: this.settings.appKey,
            ssmlRequest: {
                ssml: ssml,
                locale: this.settings.locale,
                voice: this.settings.textToSpeechVoice
            }
        };
        return (request);
    };

};

