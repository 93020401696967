import React from 'react';
import { css } from '@emotion/css';
import Select, { Option, ValueComponentProps } from 'react-select';
import { Field } from 'formik';
import FieldError from './FieldError';
import { dark_grey_blue, silver_two, dark_grey_blue_10, cool_grey } from '../../constants/colors';
import VerticalSeparator from '../structure/VerticalSeparator';

// component used in building forms with consistent components for look and function
interface SelectFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: any) => void
    label?: string
    topLabel?: string
    id?: string
    options: IconOption[]
    useFormTheme?: boolean
    hidden?: boolean
    icon?: string
    clearable?: boolean
}

export interface IconOption extends Option {
    icon: string
    rightIcon?: string
    rightText?: string
}
interface IconOptionProps {
    className?: string,
    isDisabled?: boolean,
    isFocused?: boolean,
    isSelected?: boolean,
    onFocus?: (option: any, event: any) => void,
    onSelect?: (option: any, event: any) => void,
    option: IconOption
}
class IconOptionComponent extends React.Component<IconOptionProps> {

    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }
    handleMouseEnter(event) {
        this.props.onFocus(this.props.option, event);
    }
    handleMouseMove(event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }
    render() {
        return (
            <div className={this.props.className}
                onMouseDown={this.handleMouseDown.bind(this)}
                onMouseEnter={this.handleMouseEnter.bind(this)}
                onMouseMove={this.handleMouseMove.bind(this)}
                title={this.props.option.label}>
                <img src={this.props.option.icon} />
                <span className="Select-value-label">
                    {this.props.children}
                </span>
                {this.props.option.rightText ?
                    <span className="detail-text">{this.props.option.rightText}</span>
                    : null}
                {this.props.option.rightIcon ?
                    <img src={this.props.option.rightIcon} className="detail-icon" />
                    : null}
            </div>
        );
    }
}

class IconValue extends React.Component<ValueComponentProps<string>> {
    render() {
        return (
            <div className="Select-value" title={this.props.value.title}>
                <img src={this.props.value.icon} />
                <span className="Select-value-label">
                    {this.props.children}
                </span>
                {this.props.value.rightText ?
                    <span className="detail-text">{this.props.value.rightText}</span>
                    : null}
                {this.props.value.rightIcon ?
                    <img src={this.props.value.rightIcon} className="detail-icon"/>
                    : null}
            </div>
        );
    }
}
const selectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    flex: 1;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    .Select-control {
        border-radius: 24px;
        min-height: 48px;
        box-shadow: -1px 4px 10px 0 ${dark_grey_blue_10};
    }
    .Select-placeholder, .Select-value{
        padding: 0 26px 0 8px;
        font-family: Muli;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .Select-value-label {
        margin-left: 16px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
    }
    .Select-option, Select-noresults {
        height: 48px;
        padding: 0 26px 0 8px;
        font-family: Muli;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
    }
`

const containerStyle = css`
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    flex: 1;
    > * + * {
        margin-top: 8px;
    }

    label {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: ${dark_grey_blue};
        margin-left: 16px;
    }

    .detail-text {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.7px;
        text-align: right;
        color: ${cool_grey};
        margin-right: 8px;
        margin-left: auto;
    }

    .detail-icon {
        margin-right: 32px;
    }
`

class IconSelectField extends React.Component<SelectFieldProps, {}> {
    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(true);
    };
    render() {
        const props = {
            ...this.props
        };

        return (

            <div className={containerStyle}>
                {this.props.label !== undefined ? <label>{this.props.label}</label> : null}
                <Select id={props.id}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={this.handleChange.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    disabled={props.disabled}
                    options={props.options}
                    valueComponent={IconValue}
                    clearable={props.clearable}
                    optionComponent={IconOptionComponent}
                    className={selectStyle} />
            </div>
        );
    }
}

export default IconSelectField;