import React from 'react';
import { css } from '@emotion/css';
import { blue_white, color_colors_ocean, color_shades_darkest, color_variants_main_bg } from '../../constants/colors';
import { style_border_default } from '../../constants/stylesValues';
import styled from '@emotion/styled';
import HorizontalContainer from './HorizontalContainer';
import ModalTitle from '../general/ModalTitle';
import Button from '../general/Button';
import CustomScrollbars from './CustomScrollbars';
import TextField from '../forms/TextField';
import FooterBar from './FooterBar';
import Loader from '../general/Loader';

const closeIcon = require('../../content/images/close_icon.svg');
const copyIcon = require('../../content/images/organization-settings/copy-blue.svg');

interface KeyModalLayoutProps {
    modalTitle: string;
    handleClose: () => void;
    token: string;
    isLoading: boolean;
};

const KeyModalLayout = (props: KeyModalLayoutProps) => {

    const handleCopyKey = (key: string) => {
        const textField = document.createElement('textarea')
        textField.innerText = key;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    };

    return (
        <div className={containerStyle}>
            <HorizontalContainer className={modalTitleContainer}>
                <TitleWrapper>
                    <ModalTitle>{props.modalTitle}</ModalTitle>
                </TitleWrapper>
                <CloseButtonWrapper>
                    <Button className={closeButton} themes={['start-tight', 'icon']} icon={closeIcon} onClick={props.handleClose} />
                </CloseButtonWrapper>
            </HorizontalContainer>
            <CustomScrollbars autoHide>
                <div className="main-body">
                    <p className="generated-warning">This key will only be generated once and shown while this screen is active.</p>
                    <TextField
                        readOnly
                        value={props.token || ""}
                        useForm={false}
                        onBlur={() => null}
                        label={`Key`} />
                    <div onClick={() => handleCopyKey(props.token)} className="copy-key">
                        <img src={copyIcon} />
                        <p>Copy key to clipboard</p>
                    </div>
                </div>
            </CustomScrollbars>
            <div className={bodyContainer}>
                <FooterBar className={footerStyle}>
                    <Button disabled={props.isLoading} themes={["primary", "wide"]} text="Close" type="button" onClick={props.handleClose} />
                </FooterBar>
            </div>
        </div>
    )
};

const modalTitleContainer = css`
    background: white;
    padding: 0 32px;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: ${style_border_default};
    img {
        margin-right: 16px;
    }
`;

const containerStyle = css`
    .main-body {
        padding: 32px;
        background: ${blue_white};
        height: 100%;
        .Select-option {
            z-index: 10;
        }
    }
    .generated-warning {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
        margin-bottom: 32px;
    }
    .copy-key {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        color: ${color_colors_ocean};
        margin-top: 40px;
        img {
            margin-right: 8px;
        }
    }`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const CloseButtonWrapper = styled.div`
    display: flex;
    padding-left: 28.5px;
    border-left: ${style_border_default};
`;

const bodyContainer = css`
    padding: 32px;
    background: ${color_variants_main_bg};
    flex: 1;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
`;

const footerStyle = css`
    position: fixed;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0 32px;
`;

const closeButton = css`
    margin-right: -8px;
    margin-left: 0;
`;

export default KeyModalLayout;