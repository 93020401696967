import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import FallbackMessageContainer from '../../state/containers/FallbackMessageContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import CreateFallbackMessageForm from './components/CreateFallbackMessageForm';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import FallbackMessageModel from '../../models/features/api/Fallback/FallbackMessageModel';
import IResult from '../../models/result/IResult';
import GenericCreateContentItemPage from '../formScenes/GenericCreateContentItemPage';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { FallbackFeatureTypeId } from '../../constants/featureTypeIds';

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface CreateFallbackMessageProps extends RouteComponentProps<ApplicationRouteProps> { }

class CreateFallbackMessage extends NavigationBarFormPage<CreateFallbackMessageProps, ConversationNavigationState> {
    render() {
        return (
            <Subscribe to={[
                FallbackMessageContainer,
                ApplicationContainer,
                TitleStateContainer,
                RepromptContainer,
                FollowUpContainer,
                MediaResponseStateContainer,
                FeatureContainer]}>
                {(fallbackContainer: FallbackMessageContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer) => {
                    return (
                        <GenericCreateContentItemPage stateContainer={fallbackContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="New Fallback Message"
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={FallbackFeatureTypeId}
                            formRender={(application, assistantApplication, onPublish, onSubmit: (model: FallbackMessageModel) => Promise<IResult<FallbackMessageModel>>,onAccessTypeChange) => (
                                <CreateFallbackMessageForm
                                    location={this.props.location}
                                    application={application}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    stateContainer={fallbackContainer}
                                    assistantApplication={assistantApplication}
                                    appContainer={appContainer}
                                    onShowPublishModal={onPublish}
                                    followUpContainer={followUpContainer}
                                    repromptContainer={repromptContainer}
                                    responseStateContainer={responseContainer}
                                    featureContainer={featureContainer}
                                    isSaving={fallbackContainer.state.isSaving}
                                    history={this.props.history}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onSubmit={onSubmit}
                                    onToggleNavigation={() => { }} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default CreateFallbackMessage;