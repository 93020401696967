import { css } from '@emotion/css';
import React from 'react';
import { Subscribe } from 'unstated';
import Button from '../../components/general/Button';
import ModalTitle from '../../components/general/ModalTitle';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import ModalLayout from '../../components/structure/ModalLayout';
import PageContainer from '../../components/structure/PageContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { blue_white } from '../../constants/colors';
import InnerContentExplorer from './components/InnerContentExplorer';
import FeatureFilters from '../../models/features/FeatureFilters';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import FullScreenLayout from '../fullScreenLayout';
import { InnerPage } from '../../state/containers/ConversationExplorerContainer';
const closeIcon = require('../../content/images/close_icon.svg');


interface ConversationItemExplorerProps {
    exploreType: ExploreType
    applicationId: string
    onSelect?: (contentItems: GenericContentItemModel[], featureIds: string[]) => void
    languageIdFilter?: string[] // used for hard filters - separate from adjustable filters
    propertyFilter?: any // used for hard filters - separate from adjustable filters
    featureTypeIdFilter?: string[] // used for hard filters - separate from adjustable filters
    onClose: () => void
    title?: string
    disabledFeatureIds: string[] // used for hard filters - separate from adjustable filters
    initialFilter?: FeatureFilters
    isLoading: boolean
    initialPage?: InnerPage
}

export type ExploreType = "feature-select" | "content-multi-select" | "content-select"

class ContentExplorer extends React.Component<ConversationItemExplorerProps> {
    handleClose() {
        this.props.onClose();
    }
    render() {
        return (
            <ModalLayout isVisible={true} onClose={this.handleClose.bind(this)}>
                <Subscribe to={[ApplicationContainer, FeatureContainer]}>
                    {(appContainer: ApplicationContainer, featureContainer: FeatureContainer) => (

                        <FullScreenLayout className={containerStyle}>
                            <PageContainer fill>
                                <HorizontalContainer className="header-wrapper">
                                    <ModalTitle>{this.props.title}</ModalTitle>
                                    <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={this.handleClose.bind(this)} />
                                </HorizontalContainer>
                                <HorizontalSeparator />
                                <InnerContentExplorer
                                    applicationId={this.props.applicationId}
                                    featureContainer={featureContainer}
                                    appContainer={appContainer}
                                    withNesting={false}
                                    initialApplicationFeatureId={this.props.initialPage?.applicationFeature?.id}
                                    exploreType={this.props.exploreType}
                                    onSelect={this.props.onSelect}
                                    isLoading={this.props.isLoading}
                                    initialFilter={this.props.initialFilter}
                                    disablingFilter={{
                                        featureTypeIdFilter: this.props.featureTypeIdFilter,
                                        languageIdFilter: this.props.languageIdFilter,
                                        propertyFilter: this.props.propertyFilter,
                                        disabledAppFeatureIds: this.props.disabledFeatureIds
                                    }}
                                />
                            </PageContainer>
                        </FullScreenLayout>
                    )}
                </Subscribe>
            </ModalLayout>

        )
    }
}
const containerStyle = css`
    background: ${blue_white};

    .header-wrapper {
        padding: 0 50px;
        background: white;
        align-items: center;
    }
`
export default ContentExplorer