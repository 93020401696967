import { css } from '@emotion/css';
import React from "react";
import VerticalSeparator from "../../../components/structure/VerticalSeparator";
import DeviceTargetModel from "../../../models/features/api/MediaResponses/DeviceTargetModel";
import { color_shades_dark, color_shades_darkest } from "../../../constants/colors";
import _ from "lodash";
import styled from '@emotion/styled';
import SimulatorDeviceSelector from './SimulatorDeviceSelector';
import { SimulatorEnvironment } from './Simulator';
import DropDownSelectField from '../../../components/forms/DropDownSelectField';

const liveAppIcon = require('../../../content/images/live-app-icon.svg');
const testAppIcon = require('../../../content/images/test-app-icon.svg');

interface SimulatorDevicePickerProps {
    onDeviceSelectionChange: (device: DeviceTargetModel) => void
    onSetEnvironment: (evnt: SimulatorEnvironment) => void
    selectedTarget?: DeviceTargetModel
    availableDevices: DeviceTargetModel[]
    useDraftContent: boolean
};

const formatValueLabel = ({ label, image }) => (
    <CustomSelectChosenOption>
        <img className={optionImageStyle} src={image}/>
        <p>{label}</p>
    </CustomSelectChosenOption>
);

export type InterfaceType = "screen" | "audio" | "chat";

const DeviceAndEnvironmentPicker = (props: SimulatorDevicePickerProps) => {
    return (
        <Container>
            <SelectionContainer className="ac-simulator-device-type">
                <DropDownSelectField
                    className={customSelect}
                    key={"environment-selector"}
                    onChange={(e: any) => props.onSetEnvironment(e.value)}
                    value={props.useDraftContent ? "draft" : "live"}
                    clearable={false}
                    searchable={false}
                    valueRenderer={formatValueLabel}
                    optionImageStyle={optionImageStyle}
                    options={[{ value: "live", label: "Live App", image: liveAppIcon }, { value: "draft", label: "Test App", image: testAppIcon }]}
                />
            </SelectionContainer>
            <Separator />
            <SimulatorDeviceSelector targets={props.availableDevices} isSmall selectedTarget={props.selectedTarget} onSelectionChange={props.onDeviceSelectionChange} />
        </Container>
    );
};

const optionImageStyle = css`
    height: 8px;
    width: 8px;
    margin: auto 0px;
`;

const customSelect = css`
    height: 28px;
    width: 140px;
    margin-bottom: 30px;
    margin-right: 16px;
`

const SelectionContainer = styled.div`
        display: flex;
        flex-direction: row;
        margin-left: 32px;
        margin-top: 20px;
`;

const Separator = styled(VerticalSeparator)`
    height: 32px;
`;

const Container = styled.div`
    height: 55px;
    display: flex;
    flex-direction:row;
    border-bottom: 1px solid ${color_shades_dark};
    justify-content: space-between;
    align-items: center;
    background: white;
    box-sizing: content-box;
`;

const CustomSelectChosenOption = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    p{
        color: ${color_shades_darkest};
        margin-left:10px;   
    }
`;

export default DeviceAndEnvironmentPicker;