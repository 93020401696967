import React from 'react';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import ApplicationContentTree from './ApplicationContentTree';
import ContentTreeStateContainer from '../../../state/containers/ContentTreeStateContainer';
import TabContainerPanel from '../../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
const contentTreeIcon = require("../../../content/images/tab-icons/content-tree.svg");
const contentTreeBlueIcon = require("../../../content/images/tab-icons/content-tree-blue.svg");

interface ApplicationContentMenuProps {
    application: ApplicationModel
    appContainer: ApplicationContainer
    featureContainer: FeatureContainer
    backupsContainer: ApplicationBackupVersionsContainer
    treeContainer: ContentTreeStateContainer
    history: any
}
const ApplicationContentMenuContent: React.FC<ApplicationContentMenuProps> = (props) => {
    const handleTabChange = (index: number) => {
        if (index == 0) {
            props.history.push(`/v/apps/${props.application.id}`)
        }
    }
    const getSelectedIndex = () => {
        return location.href.indexOf("templates") > -1 ? 1 : 0
    }
    return (
        <TabContainerPanel tabs={[{
            title: 'Explorer',
            icon: contentTreeIcon,
            selectedIcon: contentTreeBlueIcon
        },
        ]} onTabSelected={handleTabChange} selectedIndex={getSelectedIndex()}>
            <TabPanel>
                <div className="expanded-content-tree-container">
                    <ApplicationContentTree history={props.history}
                        key={props.application?.id}
                        application={props.application}
                        appContainer={props.appContainer}
                        featureContainer={props.featureContainer}
                        backupsContainer={props.backupsContainer}
                        treeContainer={props.treeContainer} />
                </div>
            </TabPanel>
        </TabContainerPanel>
    )
}

export default ApplicationContentMenuContent;