import { ADD_TEXT_TO_SPEECH } from "../urls";
import fetch from 'cross-fetch';
import type { TextToSpeechRequest } from "models/textToSpeechModels";

export const getTextToSpeech = (requestBody: TextToSpeechRequest, baseUrl: string, ttsProvider: string) => {
    return (
        fetch(ADD_TEXT_TO_SPEECH(baseUrl, ttsProvider), {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        })
    )
};