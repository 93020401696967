import React from 'react';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import ImportExportContainer from '../../state/containers/ImportExportContainer';
import ContentTreeStateContainer from '../../state/containers/ContentTreeStateContainer';
import FeatureFilters from '../../models/features/FeatureFilters';
import PanelContainer from '../../components/structure/Panels/PanelContainer';
import PageContainer from '../../components/structure/PageContainer';
import ConfirmationDialog from '../../components/structure/ConfirmationDialog';
import { BreadcrumbItem } from '../../components/general/Breadcrumb';
import Loader from '../../components/general/Loader';
import CustomScrollbars from '../../components/structure/CustomScrollbars';
import SelectionOptionsToolbar from '../../components/general/SelectionOptionsToolbar';
import { GroupSelectionType } from '../featureContent/components/FeatureToolbar';
import WebhookToolbar from './components/WebhookToolbar';
import DeleteConfirmation from '../featureContent/components/DeleteConfirmation';
import WebhookContainer from '../../state/containers/WebhookContainer';
import WebhookFolderContainer from '../../state/containers/WebhookFolderContainer';
import WebhookModel from '../../models/webhooks/api/WebhookModel';
import WebhookFolderModel from '../../models/webhooks/api/WebhookFolderModel';
import WebhooksSidePanel from './components/WebhooksSidePanel';
import WebhookList from './components/WebhookList';
import BulkWebhookRequest from '../../models/features/api/BulkWebhookRequest';
import WebhookFolderSelector from './components/WebhookFolderSelector';
import IResult from '../../models/result/IResult';
import WebhookForm from './components/WebhookForm';
import OrphanedWebhooksHeader from './components/OrphanedWebhooksHeader';
import OrphanedWebhooksModal from './components/OrphanedWebhooksModal';
import UpdateWebhookRequest from '../../models/webhooks/api/UpdateWebhookRequest';
import ImportFileDialog from '../../components/general/ImportFileDialog';
import WebhookSensitiveDataModel from '../../models/webhooks/api/WebhookSensitiveDataModel';
const homeIcon = require("../../content/images/content-explorer/home.svg");
const folderIcon = require("../../content/images/webhooks/webhook-folder-dark.svg");

interface WebhookManagerProps {
    withNesting: boolean
    applicationId: string
    webhookContainer: WebhookContainer
    webhookFolderContainer: WebhookFolderContainer
    importExportContainer: ImportExportContainer
    appContainer: ApplicationContainer
    treeContainer: ContentTreeStateContainer
    history: any
    currentFolderId: string
    organizationId: string
    currentWebhookId?: string
}

interface WebhookManagerState {
    filters: FeatureFilters
    currentFolder: WebhookFolderModel
    selectedFolderIds: string[]
    selectedWebhookIds: string[]
    isSelectorOpen: boolean
    isImporterOpen: boolean
    isWebhookUpdaterOpen: boolean
    isWebhookCreatorOpen: boolean
    isDeleteConfirmationOpen: boolean
    isAddingNewFolder: boolean
    isSidePanelOpen: boolean
    selectionOperation: SelectionOperation
    updatingWebhook: WebhookModel
    loaded: boolean
    orphanedWebhooks: WebhookModel[]
    isOrphanedWebhooksModalOpen: boolean
}

type SelectionOperation = "move" | "copy";

class WebhookManager extends React.Component<WebhookManagerProps, WebhookManagerState> {
    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            updatingWebhook: null,
            selectedWebhookIds: [],
            selectedFolderIds: [],
            isSelectorOpen: false,
            isDeleteConfirmationOpen: false,
            isAddingNewFolder: false,
            isImporterOpen: false,
            currentFolder: null,
            isSidePanelOpen: false,
            isWebhookUpdaterOpen: false,
            isWebhookCreatorOpen: false,
            selectionOperation: "move",
            loaded: false,
            orphanedWebhooks: [],
            isOrphanedWebhooksModalOpen: false
        }
    }
    async componentDidUpdate() {
        if (this.state.loaded) {
            let change = false;
            const newState = { ...this.state };
            if (this.props.currentFolderId != null && this.props.currentFolderId != (this.state.currentFolder?.id ?? null)) {
                const result = await this.props.webhookFolderContainer.getFolderView(this.props.applicationId, this.props.currentFolderId, this.props.webhookContainer.state.webhooks);
                newState.currentFolder = result.data;
                change = true;
            }
            if ((this.props.currentWebhookId != null) && (this.props.currentWebhookId != this.state.updatingWebhook?.id ?? "")) {
                var webhook = this.props.webhookContainer.state.webhooks.find(w => w.id == this.props.currentWebhookId);
                newState.updatingWebhook = webhook;
                newState.isWebhookUpdaterOpen = true;
                change = true;
            }
            if (change)
                this.setState(newState);
        }
    }
    componentDidMount() {
        this.props.webhookContainer.getWebhookTypes()
        const appId = this.props.applicationId;
        if (appId) {
            this.reloadData(appId);
        }
    }
    async reloadData(appId: string) {
        this.setState({
            ...this.state,
            loaded: false
        });
        var results = await Promise.all([
            this.props.webhookFolderContainer.loadWebhookFolders(appId),
            this.props.webhookContainer.getWebhooksForApplication(appId),
            this.props.webhookContainer.getWebhooksForOrganization(this.props.organizationId)
        ]);
        if (!results.some(r => r.resultType != "Ok")) {
            await this.loadCurrentFolder(appId, this.props.currentFolderId);
            this.props.webhookFolderContainer.buildTree(this.props.applicationId, this.props.webhookContainer.state.webhooks);
            let orphanedWebhooks = this.props.webhookContainer.state.organizationWebhooks ?? [];
            orphanedWebhooks = orphanedWebhooks.filter(w => !w?.applicationId);
            await this.setState({
                ...this.state,
                orphanedWebhooks: orphanedWebhooks,
                loaded: true
            });
        }
    }
    async loadCurrentFolder(appId: string, folderId: string) {
        const result = await this.props.webhookFolderContainer.getFolderView(appId, folderId, this.props.webhookContainer.state.webhooks);
        if (result.resultType == "Ok") {
            this.setState({
                ...this.state,
                currentFolder: result.data
            });
        }
    }
    handleStartAddNewFeature() {
        this.setState({
            ...this.state,
            isAddingNewFolder: true
        })
    }
    handleStartAddNewWebhook() {
        this.setState({
            ...this.state,
            isWebhookCreatorOpen: true,
            isWebhookUpdaterOpen: false,
            updatingWebhook: null
        })
    }
    handleStartUpdatingWebhook(webhook: WebhookModel) {
        this.props.history.push(`/v/apps/${this.props.applicationId}/webhooks/${this.props.currentFolderId ?? ""}?webhookId=${webhook.id}`);
    }
    async handleUpdateWebhook(model: WebhookModel) {
        model.applicationId = this.props.applicationId,
            await this.props.webhookContainer.updateFullWebhook(model);
        this.loadCurrentFolder(this.props.applicationId, this.props.currentFolderId);
        this.handleCloseModals();
    }
    async handleCreateWebhook(model: WebhookModel) {
        model.applicationId = this.props.applicationId,
            await this.props.webhookContainer.createFullWebhook(model);
        this.loadCurrentFolder(this.props.applicationId, this.props.currentFolderId);
        this.handleCloseModals();
    }

    async handleRemoveSensitiveData(webhookId: string) {
        await this.props.webhookContainer.removeSensitiveDataKey(webhookId);
    }

    async handleGenerateSensitiveData(webhookId: string) {
        await this.props.webhookContainer.generateSensitiveDataKey(webhookId);
    }

    toggleSidePanel() {
        this.setState({
            ...this.state,
            isSidePanelOpen: !this.state.isSidePanelOpen
        })
    }
    toggleImporter() {
        this.setState({
            ...this.state,
            isImporterOpen: !this.state.isImporterOpen
        });
    }
    handleFilterChange(filters: FeatureFilters) {
        this.setState({
            ...this.state,
            filters
        })
    }
    getBreadCrumbItems() {
        var ancestors = this.props.webhookFolderContainer.getAllAncestors(this.props.currentFolderId);

        var crumbs: BreadcrumbItem[] = ancestors?.reverse()?.map(f => ({
            id: f?.id,
            title: f?.name ?? 'Untitled',
            link: `/v/apps/${this.props.applicationId}/webhooks/${f.id}`,
        }))

        crumbs = [{ id: "home", title: "Home", link: `/v/apps/${this.props.applicationId}/webhooks/` }, ...crumbs];
        return crumbs;
    }
    toggleOrphanedWebhooksModal() {
        this.setState({
            ...this.state,
            isOrphanedWebhooksModalOpen: !this.state.isOrphanedWebhooksModalOpen
        });
    }
    async handleAddAllWebhooksToApp(ids: string[]) {
        const promises = [];
        ids.forEach(id => {
            const webhook = this.state.orphanedWebhooks.find(w => w.id == id);
            if(webhook) {
                const updateRequest: UpdateWebhookRequest = {
                    title: webhook.title,
                    description: webhook.description,
                    webhookTypeId: webhook.webhookType.id,
                    url: webhook.url,
                    accessToken: webhook.accessToken,
                    isDisabled: webhook.isDisabled,
                    isPublic: webhook.isPublic,
                    applicationId: this.props.applicationId
                };
                promises.push(this.props.webhookContainer.updateWebhook(id, updateRequest));
            }
        });
        const results = await Promise.all(promises);
        if (!results.some(r => r.resultType != "Ok")) {
            await this.reloadData(this.props.applicationId);
            await this.setState({
                ...this.state,
                isOrphanedWebhooksModalOpen: false
            });
        }
    }
    handleWebhookSelectionChange(webhook: WebhookModel, isSelected: boolean) {
        const selectedIds = this.state.selectedWebhookIds;

        if (isSelected) {
            selectedIds.push(webhook?.id);
        }
        else {
            selectedIds.splice(selectedIds.indexOf(webhook.id), 1);
        }

        this.setState({
            ...this.state,
            selectedWebhookIds: selectedIds
        })
    }
    onBreadcrumbClick(item: BreadcrumbItem) {
        this.props.history.push(item.link);
    }
    handleFolderSelectionChange(applicationFeature: WebhookFolderModel, isSelected: boolean) {
        const selectedIds = this.state.selectedFolderIds;

        if (isSelected) {
            selectedIds.push(applicationFeature?.id);
        }
        else {
            selectedIds.splice(selectedIds.indexOf(applicationFeature.id), 1);
        }

        this.setState({
            ...this.state,
            selectedFolderIds: selectedIds
        })
    }
    handleSelectAll() {
        this.setState({
            ...this.state,
            selectedWebhookIds: this.state.currentFolder.webhooks?.map(c => c.id) ?? [],
            selectedFolderIds: this.state.currentFolder.children?.map(c => c.id) ?? [],
        })
    }
    handleClearSelections() {
        this.setState({
            ...this.state,
            selectedWebhookIds: [],
            selectedFolderIds: []
        })
    }
    handleCopy() {
        this.setState({
            ...this.state,
            selectionOperation: "copy",
            isSelectorOpen: true
        })
    }
    handleMove() {
        this.setState({
            ...this.state,
            selectionOperation: "move",
            isSelectorOpen: true
        })
    }
    handleExport() {
        this.props.importExportContainer.exportWebhooksToFile(this.buildBulkWebhookRequest(), this.props.appContainer.state.currentApp);
    }
    handleDelete() {
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: true
        });
    }
    handleDeleteWebhook(webhook: WebhookModel) {
        this.setState({
            ...this.state,
            selectedFolderIds: [],
            selectedWebhookIds: [webhook.id],
            isDeleteConfirmationOpen: true
        });
    }
    handleImportComplete(file: File) {
        const importPromise = this.props.importExportContainer.importFileToWebhookFolder(this.props.currentFolderId, this.props.applicationId, file);
        importPromise.then(importResult => {
            if (importResult.resultType == "Ok") {
                this.reloadData(this.props.applicationId);
                this.handleCloseModals();
            }
        });
    }
    handleSelectionComplete(folderIds: string[]) {
        if (folderIds.length == 1) {
            var result: Promise<IResult<string>> = null;
            if (this.state.selectionOperation == "copy") {
                result = this.props.webhookFolderContainer.copyToFolder(folderIds[0], this.buildBulkWebhookRequest());
            }
            else if (this.state.selectionOperation == "move") {
                result = this.props.webhookFolderContainer.moveToFolder(folderIds[0], this.buildBulkWebhookRequest());
            }
            result.then(result => {
                if (result.resultType == "Ok") {
                    this.props.webhookContainer.getWebhooksForApplication(this.props.applicationId)
                    if (folderIds[0] == this.props.currentFolderId) {
                        this.reloadData(this.props.applicationId);
                        this.handleCloseModals();
                        this.handleClearSelections();
                    }
                    else {
                        this.handleCloseModals();
                        this.handleClearSelections();
                        this.props.history.push(`/v/apps/${this.props.applicationId}/webhooks/${folderIds[0]}`);
                    }
                }
            });
        }
        else {
            this.handleCloseModals();
        }
    }
    buildBulkWebhookRequest() {
        return {
            applicationId: this.props.applicationId,
            webhookFolderIds: this.state.selectedFolderIds,
            webhookIds: this.state.selectedWebhookIds
        } as BulkWebhookRequest;
    }
    handleDeleteConfirm() {
        this.props.webhookFolderContainer.bulkDelete(this.buildBulkWebhookRequest()).then(result => {
            if (result.resultType == "Ok") {
                this.reloadData(this.props.applicationId);
                this.handleCloseModals();
                this.handleClearSelections();
            }
        })
    }
    handleCloseModals() {
        this.props.history.push(`/v/apps/${this.props.applicationId}/webhooks/${this.props.currentFolderId ?? ""}`);
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: false,
            isSelectorOpen: false,
            isImporterOpen: false,
            isWebhookUpdaterOpen: false,
            isWebhookCreatorOpen: false,
            updatingWebhook: null
        });
    }
    async handleCreateNewFolder(text: string) {
        await this.props.webhookFolderContainer.createFolder(this.props.applicationId, text, this.props.currentFolderId);
        await this.loadCurrentFolder(this.props.applicationId, this.props.currentFolderId);
        this.setState({
            ...this.state,
            isAddingNewFolder: false
        });
    }
    render() {
        const currentFolder = this.state.currentFolder;
        if (!currentFolder)
            return <Loader />

        var selectionState: GroupSelectionType = 'none';
        if (currentFolder.webhooks?.length == this.state.selectedWebhookIds.length
            && currentFolder.children?.length == this.state.selectedFolderIds.length
            && (this.state.selectedWebhookIds.length > 0 || this.state.selectedFolderIds.length > 0))
            selectionState = 'all';
        else if (this.state.selectedWebhookIds.length > 0
            || this.state.selectedFolderIds.length > 0)
            selectionState = 'partial';

        const selectedFolderCount = this.state.selectedFolderIds.length;
        const selectedWebhookCount = this.state.selectedWebhookIds.length;
        var deleteConfirmation = `${selectedFolderCount} folder${selectedFolderCount == 1 ? '' : 's'} and ${selectedWebhookCount} webhook${selectedWebhookCount == 1 ? '' : 's'}? This will remove all deleted webhooks from your content as well.`;
        if (selectedWebhookCount == 1 && selectedFolderCount == 0)
            deleteConfirmation = `this webhook and all instances of it?`;
        return (
            <PanelContainer>
                <WebhooksSidePanel
                    history={this.props.history}
                    isOpen={this.state.isSidePanelOpen}
                    webhookFolderContainer={this.props.webhookFolderContainer}
                    toggleView={this.toggleSidePanel.bind(this)}
                    applicationId={this.props.applicationId}
                    webhookContainer={this.props.webhookContainer} />
                <PageContainer fill>
                    {this.state.selectedWebhookIds.length > 0 || this.state.selectedFolderIds.length > 0 ?
                        <SelectionOptionsToolbar applicationFeatureId={currentFolder?.id}
                            application={null}
                            onPublish={null}
                            isPublishing={false}
                            selectedContentIds={this.state.selectedWebhookIds}
                            selectedFeaturesIds={this.state.selectedFolderIds}
                            icon={this.props.currentFolderId ? folderIcon : homeIcon}
                            groupSelectionState={selectionState}
                            onClear={this.handleClearSelections.bind(this)}
                            onExport={this.handleExport.bind(this)}
                            isExporting={this.props.importExportContainer.state.isLoading}
                            isMoving={this.props.webhookFolderContainer.state.isLoading}
                            isCopying={this.props.webhookFolderContainer.state.isLoading}
                            isDeleting={this.props.webhookFolderContainer.state.isLoading}
                            onMove={this.handleMove.bind(this)}
                            onCopy={this.handleCopy.bind(this)}
                            onUnselectAll={this.handleClearSelections.bind(this)}
                            onSelectAll={this.handleSelectAll.bind(this)}
                            onDelete={this.handleDelete.bind(this)}
                            history={this.props.history}
                            isSelectingWebhooks={true} />
                        :
                        <WebhookToolbar
                            showNewWebhook
                            showImport
                            showNewFolder
                            icon={this.props.currentFolderId ? folderIcon : homeIcon}
                            onImport={this.toggleImporter.bind(this)}
                            onAddNewWebhook={this.handleStartAddNewWebhook.bind(this)}
                            onAddNewFolder={this.handleStartAddNewFeature.bind(this)}
                            onSelectAll={this.handleSelectAll.bind(this)}
                            groupSelectionState={selectionState}
                            onBreadCrumbItemClick={this.onBreadcrumbClick.bind(this)}
                            breadCrumbs={this.getBreadCrumbItems()}
                            history={this.props.history} />
                    }
                    {
                        (this.state.orphanedWebhooks && this.state.orphanedWebhooks.length > 0) &&
                        <OrphanedWebhooksHeader 
                            showOrphanedWebhooks={this.toggleOrphanedWebhooksModal.bind(this)}
                            count={this.state.orphanedWebhooks.length}
                        />
                    }
                    <CustomScrollbars autoHide>
                        <WebhookList
                            reloadData={this.reloadData.bind(this)}
                            webhookFolderContainer={this.props.webhookFolderContainer}
                            currentFolderId={this.props.currentFolderId}
                            folders={currentFolder.children}
                            webhooks={currentFolder.webhooks}
                            applicationId={this.props.applicationId}
                            selectedWebhookIds={this.state.selectedWebhookIds}
                            selectedFolderIds={this.state.selectedFolderIds}
                            onFolderClick={null}
                            onWebhookClick={this.handleStartUpdatingWebhook.bind(this)}
                            onWebhookSelectionChange={this.handleWebhookSelectionChange.bind(this)}
                            onFolderSelectionChange={this.handleFolderSelectionChange.bind(this)}
                            showContentItems
                            selectableContentItems
                            selectableFeatures
                            withNesting={false}
                            showWebhookCount
                            isAddingNewFolder={this.state.isAddingNewFolder}
                            droppableId={"webhooks_main"}
                            disabledFolderIds={[]}
                            handleCreateNewFolder={this.handleCreateNewFolder.bind(this)}
                        />
                    </CustomScrollbars>
                    {this.state.isSelectorOpen &&
                        <WebhookFolderSelector
                            applicationId={this.props.applicationId}
                            onClose={this.handleCloseModals.bind(this)}
                            title="Select a folder"
                            disabledFolderIds={this.state.selectionOperation == "copy" ? [] : this.state.selectedFolderIds}
                            onFinish={this.handleSelectionComplete.bind(this)}
                            withNesting={true}
                            appContainer={this.props.appContainer}
                            webhookFolderContainer={this.props.webhookFolderContainer}
                            initialFolderId={this.props.currentFolderId}
                        />
                    }
                    {(this.state.isWebhookUpdaterOpen || this.state.isWebhookCreatorOpen) &&
                        <WebhookForm
                            currentFolderId={this.props.currentFolderId}
                            onWebhookDelete={this.handleDeleteWebhook.bind(this)}
                            onClose={this.handleCloseModals.bind(this)}
                            editorType={this.state.isWebhookUpdaterOpen ? "update" : "create"}
                            webhookTypes={this.props.webhookContainer.state.webhookTypes}
                            isLoading={this.props.webhookContainer.state.isLoading}
                            finishCreate={this.handleCreateWebhook.bind(this)}
                            finishUpdate={this.handleUpdateWebhook.bind(this)}
                            webhookToUpdate={this.state.updatingWebhook}
                            removeSensitiveDataKey={this.handleRemoveSensitiveData.bind(this)}
                            generateSensitiveDataKey={this.handleGenerateSensitiveData.bind(this)}
                            webhookSensitiveData={this.props.webhookContainer.state.webhookSensitiveData}
                        />
                    }
                    {this.state.isOrphanedWebhooksModalOpen &&
                        <OrphanedWebhooksModal
                            webhooks={this.state.orphanedWebhooks}
                            handleAddToApp={this.handleAddAllWebhooksToApp.bind(this)}
                            handleClose={this.toggleOrphanedWebhooksModal.bind(this)}
                            handleRemove={this.handleDeleteWebhook.bind(this)}      
                            isLoading={this.props.webhookContainer.state.isLoading || this.props.webhookContainer.state.isUpdating}                      
                        /> 
                    }
                    {
                        this.state.isImporterOpen &&
                        <ImportFileDialog
                            completeImport={this.handleImportComplete.bind(this)}
                            onClose={this.handleCloseModals.bind(this)}
                            title={"Import webhooks into folder"}
                            acceptFileTypes={".zip, .vfy"}
                            acceptFileTypesDisplayText={"Supports .zip, .vfy"}
                            isLoading={this.props.importExportContainer.state.isLoading}
                            errors={this.props.importExportContainer.state.errors}
                        />
                    }
                    {this.state.isDeleteConfirmationOpen &&
                        <ConfirmationDialog title="Delete" deleteText="Yes, Delete"
                            isLoading={this.props.webhookFolderContainer.state.isLoading}
                            onClose={this.handleCloseModals.bind(this)}
                            onConfirm={this.handleDeleteConfirm.bind(this)}
                        >
                            <DeleteConfirmation
                                itemLabel={deleteConfirmation} />
                        </ConfirmationDialog>}
                </PageContainer>
            </PanelContainer>
        )
    }
}

export default WebhookManager