import React, { useState } from 'react';
import { css } from '@emotion/css';
import Dropzone from 'react-dropzone';
import Button from '../general/Button';
import { color_text_default, color_variants_shadow_10 } from '../../constants/colors';
import { BytesToMegabytesMultiplier, getFileTypeFromFileName, MaxImageBytesSize } from '../../constants/UploadFileSize';
import styled from '@emotion/styled';

const newImageIcon = require('../../content/images/new_image.svg');

interface FileUploadFieldProps {
    onChange?: (file: File) => void
    accept?: string
    imageUrl?: string
    addIcon?: string
    addImageText?: string
    previewStyle?: string
    defaultImageStyle?: string
    imageStyle?: string
    containerStyle?: string
    label?: string
    buttonStyle?: string
}

const AvatarFileUploadField: React.FC<FileUploadFieldProps> = (props) => {

    const [imageUrl, setImageUrl] = useState(props.imageUrl);
    
    let dropZone: Dropzone;

    const readDataUrl = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = e => {
            setImageUrl(reader.result.toString());
        };
        reader.readAsDataURL(file);
    };
    
    const handleFilePicked = (files: File[]) => {
        if (files.length > 0) {
            const fileType = getFileTypeFromFileName(files[0].name);

            if (fileType === "ImageFile" && files[0].size > MaxImageBytesSize) {
                const imageSizeAllowed: number = Math.round(MaxImageBytesSize * BytesToMegabytesMultiplier);
                alert(`[${files[0].name}] image is too large. Please select a smaller image file. The max file size allowed is ${imageSizeAllowed}MB`);
                return;
            }
            readDataUrl(files[0]);
            props.onChange(files[0]);
        }
        else {
            setImageUrl(undefined);
        }
    }

    return (
        <div className={props.containerStyle ? props.containerStyle : containerStyle}>
            <Label>{props.label ? props.label : null}</Label>
            <Dropzone className="dropZone" ref={(node) => { dropZone = node }} onDrop={handleFilePicked.bind(this)} accept={props.accept} multiple={false}>                                                
                <div className={props.previewStyle ? props.previewStyle : previewStyle}>
                    <img className={props.imageStyle ? imageUrl == null ? props.defaultImageStyle : props.imageStyle : imageUrl == null ? defaultImageStyle : imageStyle} src={imageUrl ?? newImageIcon} />
                </div>
                <div>
                    <Button rightIcon={props.addIcon} className={`${props.buttonStyle} ${buttonStyle}`} themes={['small-flat']} text={props.addImageText ? props.addImageText : `Add an Image`} type="button" />
                </div>
            </Dropzone>
        </div>
    )
}

const Label = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_text_default};
    margin-left: 16px;
    margin-bottom: 8px;
`;
const buttonStyle = css`
    span {
        line-height: 20px;
        font-size: 14px;
    }
`;
const imageStyle = css`
    width: 165px;
    height: 165px;
    left: 0px;
    top: 0px;
    position: absolute;
`;
const defaultImageStyle = css`
    width: 75px;
    height: 75px;
    left: 50px;
    top: 40px;
    position: absolute;
`;
const previewStyle = css`
    width: 165px;
    height: 165px;
    padding: 30px;
    background-color: #ffffff;
    border: solid 2px #c7cfd2;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 4px 10px ${color_variants_shadow_10};
`;
const containerStyle = css`
    display: flex;
    flex-direction: column;
    .dropZone {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: auto;
        border-radius: 20;
        border: none;
        justify-content: center;
        cursor: pointer;
        margin: 32px 0;
        margin-bottom: 0px;
    }
`;

export default AvatarFileUploadField;