import React from 'react';
import { css } from '@emotion/css';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import Loader from '../../../components/general/Loader';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import Button from '../../../components/general/Button';
import TemplateListItem from '../../applicationAppliedTemplates/components/TemplateListItem';
import OrganizationTemplateListItem from './OrganizationTemplateListItem';
import { template } from 'lodash';

interface OrganizationTemplatesListViewProps {
    orgContainer: OrganizationContainer
    templatesContainer: TemplateConfigurationContainer
    history: any
}

class OrganizationTemplatesListView extends React.Component<OrganizationTemplatesListViewProps> {
    componentDidMount() {
        if (this.props.templatesContainer.state.organizationTemplates.length == 0) {
            this.props.templatesContainer.getOrganizationTemplates(this.props.orgContainer.state.currentOrganization?.id);
        }
    }
    handleAddNew() {
        this.props.history.push('/v/modules/add')
    }
    render() {
        if (this.props.templatesContainer.state.isLoading)
            return <Loader />
        const counts = this.props.templatesContainer.state.appliedTemplateCounts;

        return (
            <>
                <PanelHeaderContainer className={panelHeaderStyle}>
                    <h2>Modules</h2>
                    <Button themes={['primary-small', 'end']} text="Create New Module" onClick={this.handleAddNew.bind(this)} />
                </PanelHeaderContainer>
                <ScrollablePanelBody className={panelBodyStyle}>
                    {this.props.templatesContainer.state.organizationTemplates?.map(t =>
                        <OrganizationTemplateListItem
                            key={t.id}
                            templateConfiguration={t}
                            activeInstanceCount={counts?.find(c => c.templateConfigurationId == t.id)?.appliedTemplateCount} />
                    )}
                </ScrollablePanelBody>
            </>
        )
    }
}

const panelHeaderStyle = css`
    padding: 0 32px;
    button {
        margin-right: 0;
        margin-left: auto;
    }
    h2 {
        font-size: 18px;
        line-height: 24px;
    }
`
const panelBodyStyle = css`
    padding: 48px 0;
    min-height: 100%;
`

export default OrganizationTemplatesListView;