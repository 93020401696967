import React from 'react';
import { css } from '@emotion/css';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import AlexaShortRectangleWrapper from './AlexaShortRectangleWrapper';

const screenStyle = css`
    video {
        width: 1920px;
        height: 960px;
    }
`
class AlexaShortVideoPreview extends ScreenPreviewComponent {
    render() {
        return (
            <AlexaShortRectangleWrapper className={screenStyle}>
                {this.state.videoUrl ?
                    <video controls>
                        <source src={this.state.videoUrl} type="video/mp4" />
                        Your browser does not support videos.
                    </video>
                : null}
            </AlexaShortRectangleWrapper>
        )
    }
}

export default AlexaShortVideoPreview;