import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import FeatureContainer from '../../state/containers/FeatureContainer';
import ImportExportContainer from '../../state/containers/ImportExportContainer';
import ContentTreeStateContainer from '../../state/containers/ContentTreeStateContainer';
import FeatureFilters from '../../models/features/FeatureFilters';
import { getFeatureTypeCreateUrl, getFeatureContentUrl } from '../../models/extensions';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import ApplicationFeatureModel from '../../models/features/api/ApplicationFeatureModel';
import BulkContentRequest from '../../models/features/api/BulkContentRequest';
import FeatureToolbar, { GroupSelectionType } from '../featureContent/components/FeatureToolbar';
import PanelContainer from '../../components/structure/Panels/PanelContainer';
import ApplicationContentMenuPanel from '../applicationContentMenuPanel';
import PageContainer from '../../components/structure/PageContainer';
import SelectionOptionsToolbar from '../../components/general/SelectionOptionsToolbar';
import FeatureContent from '../featureContent';
import ContentExplorer from '../contentExplorer';
import ConfirmationDialog from '../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../featureContent/components/DeleteConfirmation';
import { BreadcrumbItem } from '../../components/general/Breadcrumb';
import Loader from '../../components/general/Loader';
import CustomScrollbars from '../../components/structure/CustomScrollbars';
import WebhookInstanceManager from '../webhookInstanceManager';
import WebhookContainer from '../../state/containers/WebhookContainer';
import FullApplicationPublishModal from './components/FullApplicationPublishModal';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import PartialPublishForm from '../formScenes/PartialPublishForm';
import ContentItemReferenceModel from '../../models/applications/api/backups/ContentItemReferenceModel';
import { DATE_FORMAT } from '../../models/analytics/AnalyticsDateFormat';
import ApplicationMembersContainer from '../../state/containers/ApplicationMembersContainer';
import UserContainer from '../../state/containers/UserContainer';
import ImportFileDialog from '../../components/general/ImportFileDialog';

const homeIcon = require("../../content/images/content-explorer/home.svg");
const folderIcon = require("../../content/images/content-explorer/folder-dark.svg");

interface FeatureContentManagerProps {
    withNesting: boolean
    applicationId: string
    appContainer: ApplicationContainer
    featureContainer: FeatureContainer
    webhookContainer: WebhookContainer
    importExportContainer: ImportExportContainer
    treeContainer: ContentTreeStateContainer
    backupsContainer: ApplicationBackupVersionsContainer
    environmentsContainer: ApplicationEnvironmentsContainer
    isAppAdmin?: boolean
    currentFeatureId: string
    history: any
}

const FeatureContentManager = (props: FeatureContentManagerProps) => {
    const [filters, setFilters] = useState({} as FeatureFilters);
    const [loaded, setLoaded] = useState(false);
    const [selectedFeatureIds, setSelectedFeatureIds] = useState([] as string[]);
    const [selectedContentIds, setSelectedContentIds] = useState([] as string[]);
    const [isCopySelectorOpen, setIsCopySelectorOpen] = useState(false);
    const [isMoveSelectorOpen, setIsMoveSelectorOpen] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [isAddingNewFeature, setIsAddingNewFeature] = useState(false);
    const [isImporterOpen, setIsImporterOpen] = useState(false);
    const [isFilterExpanded, setIsFilterExpanded] = useState(false);
    const [isFullAppPublishOpen, setIsFullAppPublishOpen] = useState(false);
    const [publishingContentItems, setPublishingContentItems] = useState([] as ContentItemReferenceModel[]);
    const [isUnpublishing, setIsUnpublishing] = useState(false);
    const [publishingFolders, setPublishingFolders] = useState([] as string[]);
    const [editingFolderWithWebhooksId, setEditingFolderWithWebhooksId] = useState(null as string);
    const [editingContentItemWithWebhooks, setEditingContentItemWithWebhooks] = useState(null as GenericContentItemModel);

    useEffect(() => {
        loadDetails();
    }, [props.currentFeatureId]);

    useEffect(() => {
        if (publishingContentItems.length == 0 && loaded) {
            const environment = props.environmentsContainer.findLiveEnvironment(props.applicationId);
            if (props.currentFeatureId && environment?.id) {
                props.featureContainer.getApplicationFeatureContentPublishStatus(props.currentFeatureId, environment?.id);
            }
        }
    }, [props.applicationId,
        props.currentFeatureId,
        props.environmentsContainer.state.environments,
        props.environmentsContainer.state.pollingIds,
        publishingContentItems,
        loaded]);

    const loadDetails = async () => {
        if (props.currentFeatureId) {
            setLoaded(false);

            const promises = [];
            promises.push(props.featureContainer.getContentItemsForAppFeature(props.currentFeatureId));
            promises.push(loadEnvironments());
            await Promise.all(promises);
            props.treeContainer.addExpandedId(props.currentFeatureId);
            props.treeContainer.setSelectedId(props.currentFeatureId);

            setLoaded(true);
        }
    }

    const loadEnvironments = async () => {
        if (props.environmentsContainer.state.currentAppId != props.applicationId && props.applicationId) {
            await props.environmentsContainer.loadEnvironments(props.applicationId);
        }
    }

    const handleAddNewContent = (featureTypeId: string) => {
        let url = getFeatureTypeCreateUrl(featureTypeId, props.currentFeatureId)
        props.history.push(url);
    }

    const handleAddNewFeature = (text: string, applicationFeatureId: string) => {
        props.featureContainer.addApplicationFeature(props.applicationId, text, applicationFeatureId).then(result => {
            if (result.resultType == "Ok") {
                setIsAddingNewFeature(false);
            }
        });
    }

    const getFilteredFeatures = (applicationFeatureId: string) => {
        let features = props.featureContainer.getFeaturesForParent(applicationFeatureId);
        if (filters.textFilter) {
            features = features.filter(af => (af.name ?? af.feature.name)?.toLowerCase().indexOf(filters.textFilter.toLowerCase()) > -1)
        }
        return features;
    }

    const getFilteredContent = (applicationFeatureId: string) => {
        let content = props.featureContainer.getContentByFeatureId(applicationFeatureId);
        if (filters.textFilter) {
            content = content.filter(c => (c.title ?? "untitled")?.toLowerCase().indexOf(filters.textFilter.toLowerCase()) > -1)
        }
        if (filters.featureTypeIdsFilter && filters.featureTypeIdsFilter.length > 0) {
            content = content.filter(c => filters.featureTypeIdsFilter.some(id => id == c.featureTypeId))
        }
        if (filters.languageIdsFilter) {
            content = content.filter(c => filters.languageIdsFilter?.some(id => c.languages?.some(l => l.id == id)))
        }
        if (filters.daysFilter) {
            content = content.filter(c => moment().diff(moment(c.modifiedDate), 'days') <= filters.daysFilter);
        }
        if (filters.propertiesFilter) {
            const { hasImage, hasAudio, hasVideo, isPublished, isDraft, isUpdated, isIncomplete, isSyncedAndPublished, isSyncedDraft, isSyncedAndUpdated } = filters.propertiesFilter;
            if (hasImage)
                content = content.filter(c => c.hasForegroundImage || c.hasBackgroundImage);
            if (hasAudio)
                content = content.filter(c => c.hasAudio);
            if (hasVideo)
                content = content.filter(c => c.hasVideo);
            // these need to be grouped
            content = content.filter(c => {
                if ((!isPublished && !isDraft && !isUpdated && !isIncomplete && !isSyncedAndPublished && !isSyncedDraft && !isSyncedAndUpdated))
                    return true;
                const lastPublish = props.featureContainer.state.featureContentPublishStatus.find((ps) => ps.contentItemId === c.id)
                const modifiedDate = moment(c.modifiedDate, DATE_FORMAT);
                const latestPublish = lastPublish?.publishes?.length ? lastPublish.publishes[0] : null;
                let publishDate: moment.Moment;
                if (latestPublish?.applicationEnvironmentPublish?.createdDate) {
                    publishDate = moment(latestPublish.applicationEnvironmentPublish.createdDate, DATE_FORMAT);
                }
                if (isPublished)
                    return publishDate && publishDate.isAfter(modifiedDate)
                if (isDraft)
                    return !publishDate || latestPublish.publishEventType == "Unpublish"
                if (isUpdated)
                    return publishDate && publishDate.isBefore(modifiedDate)
                if (isIncomplete)
                    return !c.isComplete
                if (isSyncedAndPublished)
                    return (publishDate && publishDate.isAfter(modifiedDate)) && c.shouldNotSync && c.createdFromId
                if (isSyncedDraft)
                    return (!publishDate || latestPublish.publishEventType == "Unpublish") && c.shouldNotSync && c.createdFromId
                if (isSyncedAndUpdated)
                    return (publishDate && publishDate.isBefore(modifiedDate)) && c.shouldNotSync && c.createdFromId
            })
        }
        return content;
    }

    const getBreadCrumbItems = () => {
        let ancestors = props.featureContainer.getAllAncestors(props.currentFeatureId);

        let crumbs: BreadcrumbItem[] = ancestors?.reverse()?.map(af => ({
            id: af?.id,
            title: af?.name ?? af.feature?.name ?? af?.feature?.featureType?.name ?? 'Untitled',
            link: `/v/apps/${props.applicationId}/content/${af.id}`,
        }))

        return crumbs;
    }

    const handleContentItemSelectionChange = (contentItem: GenericContentItemModel, isSelected: boolean) => {
        const selectedIds = [...selectedContentIds];

        if (isSelected) {
            selectedIds.push(contentItem?.id);
        }
        else {
            selectedIds.splice(selectedIds.indexOf(contentItem.id), 1);
        }
        setSelectedContentIds(selectedIds);
    }

    const handleFeatureSelectionChange = (applicationFeature: ApplicationFeatureModel, isSelected: boolean) => {
        const selectedIds = [...selectedFeatureIds];

        if (isSelected) {
            selectedIds.push(applicationFeature?.id);
        }
        else {
            selectedIds.splice(selectedIds.indexOf(applicationFeature.id), 1);
        }
        setSelectedFeatureIds(selectedIds);
    }

    const handleSelectAll = () => {
        setSelectedFeatureIds(getFilteredFeatures(props.currentFeatureId)?.map(af => af.id) ?? []);
        setSelectedContentIds(getFilteredContent(props.currentFeatureId)?.map(c => c.id) ?? []);
    }

    const handleClearSelections = () => {
        setSelectedFeatureIds([]);
        setSelectedContentIds([]);
    }

    const handleExport = () => {
        const content = props.featureContainer.getContentByFeatureId(props.currentFeatureId ?? props.featureContainer.state.currentTopicFeatureId);
        const request: BulkContentRequest = {
            applicationId: props.applicationId,
            applicationFeatures: selectedFeatureIds?.map(id => ({ id })) ?? [],
            content: content?.filter(c => selectedContentIds.some(id => id == c.id))?.map(c => ({ id: c.id, featureTypeId: c.featureTypeId }))
        };
        props.importExportContainer.exportContentToFile(request, props.appContainer.state?.currentApp);
    }

    const handleCloseWebhookManager = () => {
        setEditingContentItemWithWebhooks(null);
        setEditingFolderWithWebhooksId(null);
    }

    const handleImportComplete = async (file: File) => {
        const importResult = await props.importExportContainer.importFileToAppFeature(props.currentFeatureId, file);
        if (importResult.resultType == "Ok") {
            const promise = props.featureContainer.refreshForAppFeature(props.applicationId, props.currentFeatureId);
            promise.then(results => {
                if (results[0].resultType == "Ok" && results[1].resultType == "Ok") {
                    handleCloseModals();
                    props.featureContainer.getConversationCounts(props.applicationId);
                }
            })
        }
    }

    const handleMoveToSelected = async (_, featureIds: string[]) => {
        if (featureIds.length > 0) {
            const content = props.featureContainer.getContentByFeatureId(props.currentFeatureId ?? props.featureContainer.state.currentTopicFeatureId);
            let result = await props.featureContainer.bulkMoveToAppFeature(featureIds[0], {
                applicationId: props.applicationId,
                applicationFeatures: selectedFeatureIds?.map(id => ({ id })) ?? [],
                content: content?.filter(c => selectedContentIds.some(id => id == c.id))?.map(c => ({ id: c.id, featureTypeId: c.featureTypeId }))
            })
            if (result.resultType == "Ok") {
                handleCloseModals();
                props.history.push(getFeatureContentUrl(props.applicationId, featureIds[0]))
            }

        }
        else {
            handleCloseModals();
        }
    }

    const handleCopyToSelected = async (_, featureIds: string[]) => {
        if (featureIds.length > 0) {
            const content = props.featureContainer.getContentByFeatureId(props.currentFeatureId ?? props.featureContainer.state.currentTopicFeatureId);
            const result = await props.featureContainer.bulkCopyToAppFeature(featureIds[0], {
                applicationId: props.applicationId,
                applicationFeatures: selectedFeatureIds?.map(id => ({ id })) ?? [],
                content: content?.filter(c => selectedContentIds.some(id => id == c.id))?.map(c => ({ id: c.id, featureTypeId: c.featureTypeId }))
            })
            if (result.resultType == "Ok") {
                handleCloseModals();
                props.history.push(getFeatureContentUrl(props.applicationId, featureIds[0]))
            }
        }
        else {
            handleCloseModals();
        }
    }

    const handleDeleteConfirm = async () => {
        const content = props.featureContainer.getContentByFeatureId(props.currentFeatureId ?? props.featureContainer.state.currentTopicFeatureId);

        const result = await props.featureContainer.bulkDeleteContent(props.currentFeatureId, {
            applicationId: props.applicationId,
            applicationFeatures: selectedFeatureIds?.map(id => ({ id })) ?? [],
            content: content?.filter(c => selectedContentIds.some(id => id == c.id))?.map(c => ({ id: c.id, featureTypeId: c.featureTypeId }))
        });
        if (result.resultType == "Ok") {
            handleCloseModals();
            handleClearSelections();
        }
    }

    const handleCloseModals = () => {
        setIsMoveSelectorOpen(false);
        setIsDeleteConfirmationOpen(false);
        setIsCopySelectorOpen(false);
        setIsImporterOpen(false);
        setPublishingContentItems([]);
        setPublishingFolders([]);
    }

    const finishManageWebhooks = () => {
        props.webhookContainer.getWebhookAttachmentsForApplication(props.applicationId);
        props.featureContainer.refreshForAppFeature(props.applicationId, props.currentFeatureId, true);
    }

    const handlePartialPublish = () => {
        // Publish selected content items, clear IsUnpublishing flag
        setIsUnpublishing(false);

        if (selectedContentIds.length > 0) {
            let content = props.featureContainer.getContentByFeatureId(props.currentFeatureId);
            content = content.filter(c => selectedContentIds.includes(c.id));
            if (content.length > 0) {
                const publishingContent: ContentItemReferenceModel[] = content.map((c) => {
                    return {
                        contentId: c.id,
                        featureTypeId: c.featureTypeId
                    }
                });
                setPublishingContentItems(publishingContent);
            }
        }
        setPublishingFolders(selectedFeatureIds);
    }

    const feature = props.featureContainer.findFeatureById(props.currentFeatureId ?? props.featureContainer.state.currentTopicFeatureId);
    const application = props.appContainer.state.currentApp;
    const childContent = getFilteredContent(feature?.id);
    const childFeatures = getFilteredFeatures(feature?.id);
    const nonFilteredContent = props.featureContainer.getContentByFeatureId(feature?.id);
    const isContentFiltered : boolean = nonFilteredContent?.length > childContent?.length;

    if (!application)
        return <Loader />

    let selectionState: GroupSelectionType = 'none';

    if (childContent.length == selectedContentIds.length
        && childFeatures.length == selectedFeatureIds.length
        && (selectedContentIds.length > 0 || selectedFeatureIds.length > 0))
        selectionState = 'all';
    else if (selectedContentIds.length > 0
        || selectedFeatureIds.length > 0)
        selectionState = 'partial';

    return (
        <PanelContainer>
            <ApplicationContentMenuPanel history={props.history} application={application} />
            <PageContainer fill>
                {selectedContentIds.length > 0 || selectedFeatureIds.length > 0 ?
                    <SelectionOptionsToolbar applicationFeatureId={feature?.id}
                        application={application}
                        partialPublishDisabled={props.appContainer.state.assistantApplication == null}
                        selectedContentIds={selectedContentIds}
                        selectedFeaturesIds={selectedFeatureIds}
                        icon={feature?.parentId ? folderIcon : homeIcon}
                        groupSelectionState={selectionState}
                        onClear={handleClearSelections.bind(this)}
                        onExport={handleExport.bind(this)}
                        isExporting={props.importExportContainer.state.isLoading}
                        isMoving={props.featureContainer.state.isMoving}
                        isCopying={props.featureContainer.state.isCopying}
                        isDeleting={props.featureContainer.state.isDeleting}
                        isPublishing={props.environmentsContainer.state.isLoading || props.backupsContainer.state.isCreating || props.backupsContainer.state.isLoading}
                        onMove={() => setIsMoveSelectorOpen(true)}
                        onCopy={() => setIsCopySelectorOpen(true)}
                        onPublish={() => handlePartialPublish()}
                        onUnselectAll={handleClearSelections.bind(this)}
                        onSelectAll={handleSelectAll.bind(this)}
                        isAppAdmin={props.isAppAdmin}
                        onDelete={() => setIsDeleteConfirmationOpen(true)}
                        history={props.history} /> :
                    <FeatureToolbar applicationFeatureId={feature?.id}
                        application={application}
                        icon={feature?.parentId ? folderIcon : homeIcon}
                        filters={filters}
                        onAddNewFeature={() => setIsAddingNewFeature(true)}
                        onImport={() => setIsImporterOpen(true)}
                        onAddNewContent={handleAddNewContent.bind(this)}
                        onSelectAll={handleSelectAll.bind(this)}
                        onFullPublish={() => setIsFullAppPublishOpen(!isFullAppPublishOpen)}
                        featureContainer={props.featureContainer}
                        groupSelectionState={selectionState}
                        onToggleFilters={() => setIsFilterExpanded(!isFilterExpanded)}
                        isFilterExpanded={isFilterExpanded}
                        onBreadCrumbItemClick={null}
                        breadCrumbs={getBreadCrumbItems()}
                        allowImport
                        isAppAdmin={props.isAppAdmin}
                        history={props.history} />
                }
                <CustomScrollbars autoHide>
                    <FeatureContent feature={feature}
                        showContentItemStatus={true}
                        handlePublish={async (ci) => { await setIsUnpublishing(false); await setPublishingContentItems([{ contentId: ci.id, featureTypeId: ci.featureTypeId }]) }}
                        handleUnpublish={async (ci) => { await setIsUnpublishing(true); await setPublishingContentItems([{ contentId: ci.id, featureTypeId: ci.featureTypeId }]); }}
                        contentPublishStatus={props.featureContainer.state.featureContentPublishStatus}
                        contentPublishStatusLoaded={props.featureContainer.state.loadedPublishHistoryFeatureId == props.currentFeatureId}
                        featureContainer={props.featureContainer}
                        webhookContainer={props.webhookContainer}
                        childFeatures={childFeatures}
                        contentItems={childContent}
                        handleAddNewFeature={handleAddNewFeature.bind(this)}
                        withNesting={props.withNesting}
                        applicationId={props.applicationId}
                        selectedContentItemIds={selectedContentIds}
                        selectedFeatureIds={selectedFeatureIds}
                        onContentItemSelectionChange={handleContentItemSelectionChange.bind(this)}
                        onFeatureSelectionChange={handleFeatureSelectionChange.bind(this)}
                        onManageWebhooksForAppFeature={(appFeatureId) => setEditingFolderWithWebhooksId(appFeatureId)}
                        onManageWebhooksForContentItem={(contentItemId) => setEditingContentItemWithWebhooks(contentItemId)}
                        onFilterChange={(filters) => setFilters(filters)}
                        application={application}
                        filters={filters}
                        isAddingNewFeature={isAddingNewFeature}
                        isFilterExpanded={isFilterExpanded}
                        navigatingContentItems
                        onStubAdded={() => { }}
                        droppableId="features_main"
                        selectableContentItems
                        selectableFeatures
                        statusDropDownDisabled={props.appContainer.state.assistantApplication == null}
                        showContentItems
                        isContentFiltered={isContentFiltered} />
                </CustomScrollbars>
                {isCopySelectorOpen && <ContentExplorer
                    applicationId={props.applicationId}
                    exploreType="feature-select"
                    onClose={handleCloseModals.bind(this)}
                    title="Select a folder to copy to"
                    disabledFeatureIds={selectedFeatureIds}
                    onSelect={handleCopyToSelected.bind(this)}
                    isLoading={props.featureContainer.state.isLoadingBulkOperation}
                />}
                {isMoveSelectorOpen && <ContentExplorer
                    applicationId={props.applicationId}
                    exploreType="feature-select"
                    onClose={handleCloseModals.bind(this)}
                    title="Select a folder to move to"
                    disabledFeatureIds={selectedFeatureIds}
                    onSelect={handleMoveToSelected.bind(this)}
                    isLoading={props.featureContainer.state.isLoadingBulkOperation}
                />}
                {
                    isImporterOpen && <ImportFileDialog
                        completeImport={handleImportComplete.bind(this)}
                        onClose={handleCloseModals.bind(this)}
                        title={"Import content into folder"}
                        acceptFileTypes={".zip, .vfy"}
                        acceptFileTypesDisplayText={"Supports .zip, .vfy"}
                        isLoading={props.importExportContainer.state.isLoading}
                        errors={props.importExportContainer.state.errors}
                    />
                }
                {isDeleteConfirmationOpen &&
                    <ConfirmationDialog title="Delete" deleteText="Yes, Delete"
                        isLoading={props.featureContainer.state.isLoadingBulkOperation}
                        onClose={handleCloseModals.bind(this)}
                        onConfirm={handleDeleteConfirm.bind(this)}>
                        <DeleteConfirmation applicationFeatureCount={selectedFeatureIds.length} contentItemCount={selectedContentIds.length} />
                    </ConfirmationDialog>}

                {isFullAppPublishOpen && <FullApplicationPublishModal
                    applicationId={props.applicationId}
                    primaryEnvironmentId={props.environmentsContainer.findPrimaryEnvironment(props.applicationId)?.id}
                    onClose={() => setIsFullAppPublishOpen(!isFullAppPublishOpen)}
                    environmentsContainer={props.environmentsContainer}
                    backupsContainer={props.backupsContainer} />}

                {(publishingContentItems.length > 0 || publishingFolders.length > 0) &&
                    <PartialPublishForm
                        contentItems={publishingContentItems}
                        applicationFeatureIds={publishingFolders}
                        applicationId={props.applicationId}
                        applicationFeatureId={props.currentFeatureId}
                        history={props.history}
                        onClose={() => handleCloseModals()}
                        isUnpublishing={isUnpublishing}
                    />
                }
                <WebhookInstanceManager
                    show={editingFolderWithWebhooksId != null || editingContentItemWithWebhooks != null}
                    featureTypeId={editingContentItemWithWebhooks?.featureTypeId}
                    existingWebhookChainingType={editingContentItemWithWebhooks?.webhookChainingType}
                    webhookContainerId={editingFolderWithWebhooksId ?? editingContentItemWithWebhooks?.id}
                    applicationId={props.applicationId}
                    webhookInstanceScope={editingFolderWithWebhooksId != null ? "ApplicationFeature" : "Content"}
                    onClose={handleCloseWebhookManager.bind(this)}
                    onFinish={finishManageWebhooks.bind(this)}
                />
            </PageContainer>
        </PanelContainer>
    )
}

export default FeatureContentManager