import React from 'react';
import { Subscribe } from 'unstated';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ImportExportContainer from '../../state/containers/ImportExportContainer';
import MemberContainer from '../../state/containers/MemberContainer';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import IGenericContentContainer from '../../state/definitions/IGenericContentContainer';
import PublishHistory from './components/PublishHistory';

interface PublishHistoryPanelProps {
    applicationId: string
    contentId?: string
    onClose: () => void
    contentItemContainer?: IGenericContentContainer<any, any, any, any>
}

const PublishHistoryPanel: React.FC<PublishHistoryPanelProps> = (props) => (
    <Subscribe to={[
        ApplicationBackupVersionsContainer, 
        MemberContainer, 
        ApplicationEnvironmentsContainer, 
        OrganizationContainer
        ]}>
        {(backupVersionsContainer: ApplicationBackupVersionsContainer, 
            memberContainer: MemberContainer, 
            environmentContainer: ApplicationEnvironmentsContainer, 
            orgContainer: OrganizationContainer) => (
            <PublishHistory 
                onClose={props.onClose} 
                contentId={props.contentId} 
                contentItemContainer={props.contentItemContainer}
                applicationId={props.applicationId} 
                organizationId={orgContainer.state.currentOrganization?.id} 
                backupsContainer={backupVersionsContainer} 
                memberContainer={memberContainer} 
                environmentsContainer={environmentContainer} />
        )}
    </Subscribe>)

export default PublishHistoryPanel;