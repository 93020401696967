
import React from 'react';
import styled from '@emotion/styled';
import { color_text_default } from '../../../constants/colors';

interface DeploymentStatusProps {
  platform: string,
  platformIcon?: string,
  statusIcon?: string,
  href: string
}

function DeploymentStatus(props: DeploymentStatusProps) {
  return (
    <StyleContainer href={props.href}>
      <PlatformIcon src={props.platformIcon} />
      <PlatformText>{props.platform}</PlatformText>
      <PlatformStatus src={props.statusIcon} />
    </StyleContainer>
  );
}

const StyleContainer = styled.a`
  color: ${color_text_default};
  display: flex;
  margin: 24px 24px 0px 24px;
`;

const PlatformIcon = styled.img`
  margin-right: 12px;
  width: 24px;
`;

const PlatformText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const PlatformStatus = styled.img`
  margin-left: auto;
`;

export default DeploymentStatus;