import React from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ActiveTemplateSelector from './ActiveTemplateSelector';

interface NavigationDrawerProps {
    history: any
    applicationId: string
    appContainer: ApplicationContainer
    currentAppliedTemplateId?: string
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
}

class AppliedAppTemplateMenu extends React.Component<NavigationDrawerProps> {

    render() {
        return (
            <div className={containerStyle}>
                <ActiveTemplateSelector
                    appliedTemplates={this.props.appliedAppTemplateContainer.getLoadedTemplatesForApp(this.props.applicationId)}
                    activeAppliedTemplate={this.props.appliedAppTemplateContainer.state.appliedTemplates.find(at => at.id == this.props.currentAppliedTemplateId)}
                />
            </div>
        )
    }
}

const containerStyle = css`

`

export default AppliedAppTemplateMenu;