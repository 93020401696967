import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';
import RoundedSelectField from '../forms/RoundedSelectField';
import { Option } from 'react-select';
import RoundedTwoOptionButton from '../forms/RoundedTwoOptionButton';
import NumberIncrementSelector from '../forms/NumberIncrementSelector';

// component used in building forms with consistent components for look and function
interface SortAndPageControlProps {
    updateSortValue: (i: Option<string>) => void
    updatePageValue: (i: boolean) => void
    updateViewValue?: () => void
    sortValue?: string
    showView?: boolean 
    view?: "grid" | "table"
    sortOptions: Option[]
    page: string
}   

const containerStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    .label {           
        font-family: Muli;
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: 0.5px;
        color: ${dark_grey_blue};
        &.selector {            
            padding-left: 12px;
        }
        &.view {            
            margin-left: -30px;
        }
        &.page {
            padding-right: 10;
        }
    }
    .sort-container {
        width: 50%;
        text-align: left;
    }
    .view-container {
        width: 25%;
        text-align: center;
    }
    .page-container {
        width: 25%;
        text-align: right;
        div {
            text-align: center;
        }
    }
`
class SortAndPageControl extends React.Component<SortAndPageControlProps> {        
handleSort = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.updateSortValue(value);
}; 
render() {        
        return (          
            <div className={containerStyle}>
                <RoundedSelectField 
                    options={this.props.sortOptions}
                    onChange={this.handleSort.bind(this)} 
                    value={this.props.sortValue}
                    label={"SORT BY"}
                    />
                {
                    this.props.showView ? 
                    <RoundedTwoOptionButton
                        updateViewValue={this.props.updateViewValue}
                        selectedOption={this.props.view.localeCompare("table") === 0}
                        label={"VIEW"}/> 
                    : null                    
                }    
                <NumberIncrementSelector 
                    selectedNumber={this.props.page}
                    updateValue={this.props.updatePageValue}
                    label={"PAGE"}
                    showNumber={true}
                />   
            </div>
        );
    }
}

export default SortAndPageControl;