import React from 'react';
import { css } from '@emotion/css';
import ResponseTemplateTypeModel from '../../../models/features/api/MediaResponses/ResponseTemplateTypeModel';
import UpdateMediaResponseRequest from '../../../models/features/api/MediaResponses/UpdateMediaResponseRequest';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { dark_grey_blue, greeny_blue, green_1, faded_red } from '../../../constants/colors';
import SelectField from '../../../components/forms/SelectField';
import { ResponseFieldCard } from './ResponseFieldCard';
import IconSelectField, { IconOption } from '../../../components/forms/IconSelectField';
import ResponseTemplateSelector from './ResponseTemplateSelector';
import UpdateBulkMediaResponseContainerRequest from '../../../models/features/api/MediaResponses/UpdateBulkMediaResponseContainerRequest';
import RadioGroup, { RadioOption } from '../../../components/forms/RadioGroup';
import MediaItemModel from '../../../models/media/MediaItemModel';
import MediaType from '../../../models/media/MediaTypes';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextAreaField from '../../../components/forms/TextAreaField';
import _ from 'lodash';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
import CompatabilityViewer from './CompatabilityViewer';
import Button from '../../../components/general/Button';
import PageError from '../../../components/general/PageError';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';
import ResponseBuilderContainer from './ResponseBuilderContainer';
const readoutIcon = require('../../../content/images/media-icons/Readout-Dark.svg');
const audioIcon = require('../../../content/images/media-icons/Audio-dark.svg')
const textIcon = require('../../../content/images/media-icons/Text-dark.svg');
const videoIcon = require('../../../content/images/media-icons/Video-dark.svg');
const noVideoIcon = require('../../../content/images/media-icons/Video-None-dark.svg');
const imageIcon = require('../../../content/images/media-icons/Image-dark.svg');
const noImageIcon = require('../../../content/images/media-icons/Image-None-dark.svg');
const resetIcon = require('../../../content/images/reset-blue.svg');
const customIcon = require('../../../content/images/hammer-wench.svg');

interface ResponseBuilderProps {
    displayTextOverride?: string
    targets: DeviceTargetModel[],
    onChange?: (container: UpdateBulkMediaResponseContainerRequest) => void
    container: UpdateBulkMediaResponseContainerRequest
    deviceType: string
    mediaItems: MediaItemModel[]
    applicationId: string
}
interface ResponseBuilderState {
    deviceTarget: DeviceTargetModel
    customResponse?: UpdateMediaResponseRequest
    validationErrors?: string[]
    successMessage?: string
    hasCustomChanges: boolean
}

class ChatResponseBuilder extends React.Component<ResponseBuilderProps, ResponseBuilderState> {
    componentWillMount() {
        this.setState({
            ...this.state,
            deviceTarget: this.props.targets[0],
            customResponse: this.getCurrentResponse(this.props.targets[0].id),
            validationErrors: null,
            successMessage: null,
            hasCustomChanges: false
        })
    }

    componentDidUpdate(prevProps: ResponseBuilderProps) {
        if (prevProps.deviceType != this.props.deviceType) {
            this.setState({
                ...this.state,
                deviceTarget: this.props.targets[0],
                customResponse: this.getCurrentResponse(this.props.targets[0].id)
            })
        }
        else if (this.props.container.responses.length != prevProps.container.responses.length) {
            this.setState({
                ...this.state,
                customResponse: this.getCurrentResponse(this.state.deviceTarget.id)
            })
        }
    }

    private getCurrentResponse(deviceTargetId?: string): UpdateMediaResponseRequest {
        if (!deviceTargetId) deviceTargetId = this.state.deviceTarget.id
        const existingResponse = this.props.container.responses.find(r => r.deviceTargetId == deviceTargetId);
        if (existingResponse) {
            return existingResponse;
        }
        else {
            return this.buildChatResponseFromDefault(deviceTargetId);
        }
    }

    private buildChatResponseFromDefault(deviceTargetId?: string): UpdateMediaResponseRequest {
        if (!deviceTargetId) deviceTargetId = this.state.deviceTarget.id
        const target = this.props.targets.find(t => t.id == deviceTargetId);
        if (!target) return null;

        const template = target.responseTemplates.find(t => t.usesDisplayText);
        if (!template) return;
        return {
            responseTemplateTypeId: template.id,
            applicationId: this.props.applicationId,
            deviceTargetId: deviceTargetId
        }
    }

    removeTargetResponse(deviceTargetId: string) {
        const container = this.props.container;
        _.remove(container.responses, r => r.deviceTargetId == deviceTargetId);

        this.props.onChange(container);
    }

    handleChatTextOptionChange(option: RadioOption) {
        // if option is not readout
        const existingResponse = this.state.customResponse;
        if (option.value != 'default') {
            if (existingResponse) {
                existingResponse.displayText = '';
                this.setState({
                    ...this.state,
                    customResponse: existingResponse,
                    hasCustomChanges: true
                });
            }
        }
        else {
            // if readout, remove response for targetvar existingResponse = this.state.customResponse;
            if (existingResponse) {
                existingResponse.displayText = null;
                this.setState({
                    ...this.state,
                    customResponse: existingResponse
                });
            }
            this.removeTargetResponse(this.state.deviceTarget.id);
        }
    }

    handleDisplayTextChange(e) {
        var existingResponse = this.state.customResponse;
        if (existingResponse) {
            existingResponse.displayText = e.target.value;
            this.setState({
                ...this.state,
                customResponse: existingResponse,
                hasCustomChanges: true
            });
        }
    }

    handleUpdateResponse() {
        const validationErrors = this.verifyCustomResponse();
        if (validationErrors) {
            this.setState({
                ...this.state,
                validationErrors: [validationErrors]
            });
            return;
        }

        const container = this.props.container;
        const existingResponse = container.responses.find(r => r.deviceTargetId == this.state.deviceTarget.id);
        if (existingResponse) {
            const index = container.responses.indexOf(existingResponse);
            container.responses[index] = this.state.customResponse;
        }
        else {
            container.responses.push(this.state.customResponse);
        }

        this.props.onChange(container);
        this.setState({
            ...this.state,
            validationErrors: null,
            successMessage: "Saved",
            hasCustomChanges: false
        });

        // hide save message after delay
        setTimeout(() => {
            this.setState({
                ...this.state,
                successMessage: null
            })
        }, 3000);
    }

    private verifyCustomResponse(): string {
        const response = this.state.customResponse;
        if (!response) return "Invalid Response";
        const template = this.state.deviceTarget.responseTemplates.find(t => t.id == this.state.customResponse.responseTemplateTypeId);
        if (!template) return "Invalid template";

        if (template.requiresVideo && !response.videoItemId) return "This template requires a video";
        if (template.requiresAudio && !response.audioItemId) return "This template requires an audio file";
        if (template.requiresForegroundImage && !response.foregroundImageItemId) return "This template requires a foreground image";
        if (template.requiresBackgroundImage && !response.backgroundImageItemId) return "This template requires a background image";
        return null;
    }

    render() {
        return (
            <ResponseBuilderContainer>
                <ResponseFieldCard title="Display Text">
                    <FormFieldsContainer>
                        <RadioGroup
                            options={[{ icon: textIcon, value: 'default', label: 'Same as Readout' },
                            { icon: textIcon, value: 'custom', label: 'Custom' }]}
                            onChange={this.handleChatTextOptionChange.bind(this)}
                            value={this.state.customResponse && this.state.customResponse.displayText != null ? 'custom' : 'default'} />
                        {this.state.customResponse && this.state.customResponse.displayText != null ?
                            <TextAreaField name="displayText" placeholder="What should the display text be?" value={this.state.customResponse.displayText} onChange={this.handleDisplayTextChange.bind(this)} />
                            : null}
                        {this.state.customResponse && this.state.customResponse.displayText != null ?
                            <div className="update-container">
                                <Button themes={['primary-small']} type="button" onClick={this.handleUpdateResponse.bind(this)} additionalClasses="update-button" text="Update" />
                                <p className="error-label">{this.state.validationErrors ? this.state.validationErrors.join(',') : null}</p>
                                <p className="success-label">{this.state.successMessage}</p>
                            </div> : null}
                    </FormFieldsContainer>
                </ResponseFieldCard>
            </ResponseBuilderContainer>
        )
    }
}

export default ChatResponseBuilder