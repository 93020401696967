import React from 'react';
import { css } from '@emotion/css';
import { cool_grey, dark_grey_blue } from '../../../constants/colors';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';

const cardStyle = css`
    background: white;
    border-radius: 10px;
    border: 1px solid ${cool_grey};
    padding: 16px 32px;
    margin: 16px 0;
    .card-title {
        font-family: Muli;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 16px;
    }
`

interface ResponseFieldCardProps {
    title: string
}

export const ResponseFieldCard: React.SFC<ResponseFieldCardProps> = (props) => (
    <div className={cardStyle}>
        <p className="card-title">{props.title}</p>
        <HorizontalSeparator />
        <div>
            {props.children}
        </div>
    </div>
)