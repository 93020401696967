import React from "react";
import { Subscribe } from 'unstated';
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import DeploymentAccountsContainer from "../../state/containers/DeploymentAccountsContainer";
import ApiKeyContainer from "../../state/containers/ApiKeyContainer";
import MemberContainer from "../../state/containers/MemberContainer";
import UserContainer from "../../state/containers/UserContainer";
import OrganizationSettingsTabs from "./components/OrganizationSettingsTabs";

interface OrganizationSettingsProps {
    history: any
}

const OrganizationSettings: React.FC<OrganizationSettingsProps> = (props) => (
    <Subscribe to={[OrganizationContainer, DeploymentAccountsContainer, ApiKeyContainer, MemberContainer, UserContainer]}>
        {(orgContainer : OrganizationContainer, deploymentContainer: DeploymentAccountsContainer, apiKeyContainer: ApiKeyContainer, memberContainer: MemberContainer, userContainer: UserContainer) => {
            return (
                <OrganizationSettingsTabs 
                    history={props.history} 
                    orgContainer={orgContainer} 
                    memberContainer={memberContainer}
                    deploymentContainer={deploymentContainer} 
                    apiKeyContainer={apiKeyContainer} 
                    userContainer={userContainer}/>
            );
        }}
    </Subscribe>
);

export default OrganizationSettings;