import React from 'react';
import { css } from '@emotion/css';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import { color_shades_light, color_shades_dark, color_text_light, color_text_default } from '../../../constants/colors';
import LanguageCollectionAvatar from '../../../components/general/LanguageCollectionAvatar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { defaultProps } from '@nivo/pie';
const templateUsageIcon = require('../../../content/images/templates-icons/template-usage.svg');
const moduleIcon = require('../../../content/images/templates-icons/standard/module-icon-blue.svg');
const templateIcon = require('../../../content/images/templates-icons/standard/template-stamp-blue.svg');
const integrationIcon = require('../../../content/images/templates-icons/standard/integration-icon-blue.svg');
interface TemplateListItemProps {
    templateConfiguration: TemplateConfigurationModel
    activeInstanceCount: number
}
const OrganizationTemplateListItem: React.FC<TemplateListItemProps> = ({
    templateConfiguration,
    activeInstanceCount
}) => {
    const getTypeLabel = () => {
        if(templateConfiguration?.templateType == "ContentTemplate") return "Template";
        if(templateConfiguration?.templateType == "Integration") return "Integration";
        return "Module"
    }
    const getTypeIcon = () => {
        if(templateConfiguration?.templateType == "ContentTemplate") return templateIcon;
        if(templateConfiguration?.templateType == "Integration") return integrationIcon;
        return moduleIcon

    }
    
    return (
        <div className={containerStyle}>
            <Link to={`/v/modules/edit/${templateConfiguration?.id}`} className="list-item-body">
                <div className="template-icon">
                    <img src={getTypeIcon()} />
                </div>
                <div className="template-title">
                    <p className="section-label">{getTypeLabel()}</p>
                    <h3>{templateConfiguration?.name}</h3>
                </div>
                <div className="template-modified">
                    <div>
                        <p className="section-label">Last modified</p>
                        <p className="modified-date">{moment(templateConfiguration.modifiedDate).format("MMM DD, YYYY")}</p>
                    </div>
                </div>
                <LanguageCollectionAvatar languages={templateConfiguration?.templateApplication?.languages ?? []} limit={3} />
                <div className="template-usage">
                    <div>
                        <p className="usage-count">{activeInstanceCount ?? "-"}</p>
                        <p className="usage-label">Active Instances</p>
                    </div>
                    <img src={templateUsageIcon} />
                </div>
            </Link>
        </div>
    );
}

const containerStyle = css`
    height: 80px;
    background: ${color_shades_light};
    width: 100%;
    padding-left: 80px;
    border-top: 1px solid ${color_shades_dark};
    &:last-child {
        border-bottom: 1px solid ${color_shades_dark};
    }
    .list-item-body {
        background: white;
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        color: ${color_text_default};
        .section-label {
            color: ${color_text_light};
            font-size: 12px;
            line-height: 16px;
        }

        .template-title {
            margin-left: 32px;
            margin-right: 16px;
            width: 40%;
            flex: 1;
        }
        .template-usage, .template-modified {
            margin-right: 32px;
            margin-left: auto;
            width: 15%;
        }
        .template-modified {

        }
        .template-usage {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .usage-count {
                text-align: right;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
            }
            .usage-label {
                text-align: right;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                font-size: 12px;
                line-height: 16px;
            }
            img {
                margin-left: 16px;
            }
        }
    }
    .template-icon {
        background: white;
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 8px;
        padding: 8px;
        width: 56px;
        height: 56px;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -28px;
        img {
            width: 32px;
            height: 32px;
            transform: rotate(-45deg);
        }
    }
`;

export default OrganizationTemplateListItem;