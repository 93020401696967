import React from 'react';
import { css } from '@emotion/css';
import FeatureFilters from '../../../models/features/FeatureFilters';
import { page_primary_background, shade_2_lighter, silver, silver_four, color_text_light, color_text_link, color_defaults_white, color_shades_darker, color_shades_dark } from '../../../constants/colors';
import Button from '../../../components/general/Button';
import NewFeatureOrContentPicker, { NewContentSelected } from './NewFeatureOrContentPicker';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import FeatureModel from '../../../models/features/api/FeatureModel';
import { getFeatureCreateUrl, getFeatureTypeCreateUrl } from '../../../models/extensions';
import ContentItemFilters from './filters/ContentItemFilters';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import Breadcrumb, { BreadcrumbItem } from '../../../components/general/Breadcrumb';
import CheckIndicator from '../../../components/forms/CheckIndicator';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import ConditionalTooltipWrapper from '../../../components/general/ConditionalTooltipWrapper';
const filterIcon = require("../../../content/images/content-explorer/filter.svg");
const filterBlueIcon = require("../../../content/images/content-explorer/filter-blue.svg");
const addIcon = require("../../../content/images/content-explorer/add-white.svg");
const publishIcon = require("../../../content/images/app-history-icons/full-publish.svg");
interface FeatureToolbarProps {
    application: ApplicationModel
    applicationFeatureId: string
    icon?: string
    filters?: FeatureFilters
    onAddNewFeature: () => void
    onAddNewContent: (featureTypeId: string) => void
    onImport: () => void
    onBreadCrumbItemClick?: (item: BreadcrumbItem) => void
    onToggleFilters: () => void
    onFullPublish?: () => void
    isFilterExpanded: boolean
    breadCrumbs: BreadcrumbItem[]
    onSelectAll?: () => void
    featureContainer: FeatureContainer
    groupSelectionState: GroupSelectionType
    disableCreateContent?: boolean
    highlightedBreadcrumbId?: string
    allowImport: boolean
    history: any
    isAppAdmin?: boolean
}

interface FeatureToolbarState {
    isAddNewExpanded: boolean
}

export type GroupSelectionType = 'all' | 'partial' | 'none'

class FeatureToolbar extends React.Component<FeatureToolbarProps, FeatureToolbarState> {
    searchInput: HTMLInputElement;

    constructor(props) {
        super(props)
        this.state = {
            isAddNewExpanded: false
        }
    }

    handleToggleAddNew() {
        this.setState({
            ...this.state,
            isAddNewExpanded: !this.state.isAddNewExpanded
        })
    }
    handleNewSelected(newContent: NewContentSelected) {
        if (newContent.type == "contentItem") {
            this.props.onAddNewContent(newContent.feature.featureType.id);
        }
        else if (newContent.type == "folder") {
            this.props.onAddNewFeature();
        }
        else if (newContent.type == "import") {
            this.props.onImport();
        }
        this.setState({
            ...this.state,
            isAddNewExpanded: !this.state.isAddNewExpanded
        })
    }
    renderFilterRight() {
        // if we have any active filters, show the number. Otherwise show the icon based on expanded state
        if (getFilterCount(this.props.filters) > 0) {
            return <div className="filter-right filter-count"><span>{getFilterCount(this.props.filters)}</span></div>
        }
        else if (this.props.isFilterExpanded)
            return <div className="filter-right"><img src={filterBlueIcon} /></div>
        else
            return <div className="filter-right"><img src={filterIcon} /></div>
    }
    render() {
        return (
            <div className={containerStyle}>
                <div className="tool-bar-container">
                    {this.props.onSelectAll && <div className="group-select-container">
                        <CheckIndicator onClick={() => this.props.onSelectAll()} partial={this.props.groupSelectionState == 'partial'} checked={this.props.groupSelectionState == 'all'} />
                        <img src={this.props.icon} />
                    </div>}
                    <Breadcrumb items={this.props.breadCrumbs} onItemClick={this.props.onBreadCrumbItemClick} />
                    <div className={`filter-button ${getFilterCount(this.props.filters) > 0 ? 'applied-filters' : ''}`}>
                        <Button themes={['white-icon']} text="" onClick={this.props.onToggleFilters} additionalClasses={this.props.isFilterExpanded ? 'expanded' : null} rightComponent={() => this.renderFilterRight()} />
                    </div>
                    {this.props.onFullPublish != null && 
                        <ConditionalTooltipWrapper showTooltip={!this.props.isAppAdmin} place="bottom" text='Only App Admins can publish content'>  
                            <div className="publish-button">
                                <Button disabled={!this.props.isAppAdmin} themes={['secondary-small']} className="ac-publish-all" text="Publish" onClick={() => this.props.onFullPublish()} type="button" rightIcon={publishIcon}/>
                            </div>
                        </ConditionalTooltipWrapper>
                    }
                    <div className="add-button">
                        <Button themes={['primary-small']} className="ac-new-content-button" text="Add New" onClick={this.handleToggleAddNew.bind(this)} type="button" rightIcon={addIcon} />
                        {this.state.isAddNewExpanded &&
                            <NewFeatureOrContentPicker
                                position={"right"}
                                allowImport={this.props.allowImport}
                                featuresOnly={this.props.disableCreateContent}
                                featureContainer={this.props.featureContainer}
                                onClose={this.handleToggleAddNew.bind(this)}
                                onSelected={this.handleNewSelected.bind(this)} />}
                    </div>
                </div>
            </div>
        )
    }
}

export const getFilterCount = (filters: FeatureFilters) => {
    var count = 0;
    if (filters.textFilter && filters.textFilter.length > 0) count++;
    if (filters.daysFilter) count++;
    if (filters.featureTypeIdsFilter && filters.featureTypeIdsFilter.length > 0) count++;
    if (filters.languageIdsFilter && filters.languageIdsFilter.length > 0) count++;
    if (filters.propertiesFilter) {
        var hasPropertyFilters = Object.keys(filters.propertiesFilter).map(key => filters.propertiesFilter[key]).filter(val => val === true).length > 0
        if (hasPropertyFilters)
            count++;
    }
    return count;
}

const containerStyle = css`

    background: ${color_defaults_white};
    .tool-bar-container {
        border-bottom: 1px solid ${color_shades_dark};
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 56px;
    }
    .group-select-container {
        display: flex;
        align-items: center;
        padding: 0 12px 0 8px;
        margin-right: 12px;
        border-right: 1px solid ${color_shades_dark};
    }

    button {
        margin: 0;

        &.expanded {
            background: linear-gradient(0deg, rgba(30, 126, 185, 0.07), rgba(30, 126, 185, 0.07)), #FFFFFF;
            border: 1px solid ${color_text_link};
            color: ${color_text_link};
        }

        &.search-button {
            margin: 0 16px;
            padding: 0 16px;
            .right-img {
                margin: 0;
            }
        }

        .filter-right {
            width: 20px;
            img {
                width: 16px;
                height: 16px;
            }
        }

        .filter-count {
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${color_text_link};
            width: 20px;
            height: 20px;
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
            }
        }
    }

    .filter-button {
        margin-right: 16px;
        margin-left: auto;

        &.applied-filter button {
            border: 1px solid ${color_text_link};
            color: ${color_text_link};
        }
    }

    .publish-button {
        margin-right: 16px;
    }

    .add-button {
        margin-right: 16px;
        margin-left: auto;
        position: relative;
    }
`;

export default FeatureToolbar;

