import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, cool_grey, dark_grey_blue, faded_red, pale_grey } from '../../constants/colors';
import FieldError from './FieldError';
const calendarIcon = require("../../content/images/analytics-icons/calendar.svg");

interface DateInputFieldProps {
    value: string,
    onChange?: (e) => void,
    onBlur?: (e) => void
    onFocus?: (e) => void
    name?: string
    disabled?: boolean
    label?: string
    className?: string
    labelIcon?: string
    autoFocus?: boolean
}

const DateInputField: React.SFC<DateInputFieldProps> = (props) => (
    <div className={`${containerStyle} ${props.className}`}>
        <div className={labelContainer}>
            {props.labelIcon && <img src={props.labelIcon} /> }
            {props.label !== undefined ? <label htmlFor={props.name} className={labelStyle}>{props.label}</label> : null}
        </div>
        <div className={fieldWrapper}>
            <div className={`${fieldContainer}  ${props.disabled ? 'disabled' : ''} field-container`}>   
                <img src={calendarIcon} />             
                <input 
                    className={textFieldStyles}
                    type="date"
                    name={props.name}
                    autoFocus={props.autoFocus}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    disabled={props.disabled} />
            </div>   
        </div>    
        <FieldError name={props.name} />
    </div>
);

const textFieldStyles = css`
    color: ${dark_grey_blue};
    font-family: Muli;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    border: none; 
    padding: 12px 16px;
    font-size: 14px;
    background: transparent;
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        letter-spacing: normal;
        color: ${cool_grey};
    }
`;
const containerStyle = css`
margin-bottom: 24px;
flex: 1;
&.tight {
    margin-bottom: 16px;
    label {
        margin: 8px 16px 0 16px;
    }
}
`
const labelStyle = css`
font-family: Muli;
font-size: 14px;
font-weight: 600;
text-align: left;
margin: 0 16px;
`;

const labelContainer = css`
display: flex;
align-items: center;
height: 22px;
`
const fieldContainer = css`

background: #FFFFFF;
    border: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    box-shadow: inset 0px 1px 2px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    >img {
        width: 20px;
        height: 20px;
    }
    
    input {
        border: none;
        background: transparent;
        box-shadow: none;
        margin-left: 12px;

        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        &::-webkit-calendar-picker-indicator,
        &::-webkit-inner-spin-button {
            display: none;
        }
    }
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px ${color_shades_dark}; 
    background: white;
    margin-top: 8px;
    img {
        margin: 16px;
    }
    &.disabled {
        background: ${pale_grey};
        input {
            background: ${pale_grey};
        }
    }
`
const separatorStyle = css`
    height: 30px;
`
const fieldWrapper = css`
display: flex;
flex-direction: row;
align-items: center;

`

export default DateInputField;