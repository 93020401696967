import React from 'react';
import { css } from '@emotion/css';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import { text_blue, color_shades_dark, color_shades_darker } from '../../../constants/colors';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
const moduleIcon = require('../../../content/images/templates-icons/standard/module-icon-blue.svg');
const templateIcon = require('../../../content/images/templates-icons/standard/template-stamp-blue.svg');
const integrationIcon = require('../../../content/images/templates-icons/standard/integration-icon-blue.svg');
interface CurrentContentSelectorProps {
    onToggle: () => void
    isMinified: boolean
    isOpen: boolean
    templateConfiguration: TemplateConfigurationModel
}


const CurrentTemplateSelector: React.FC<CurrentContentSelectorProps> = ({onToggle, isMinified, isOpen, templateConfiguration}) => {
    const getTypeIcon = () => {
        if (templateConfiguration?.templateType == "ContentTemplate") return templateIcon;
        if (templateConfiguration?.templateType == "Integration") return integrationIcon;
        return moduleIcon
    }
    return (
        <div className={`${containerStyle} ${isOpen ? 'open' : ''} ${isMinified ? 'minified' : ''}`} onClick={onToggle}>
            <div className="template-icon">
                <img src={getTypeIcon()} />
            </div>

            <ToggleIndicator direction={isOpen ? "up" : "down"} className="toggle-indicator" />
        </div>
    );
}
const containerStyle = css`
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 48px;
    padding: 0 8px 0 16px;
    border-left: 1px solid ${color_shades_darker};
    border-right: 1px solid ${color_shades_darker};
    .template-icon {
        background: white;
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 8px;
        padding: 8px;
        width: 48px;
        height: 48px;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        img {
            width: 32px;
            height: 32px;
            transform: rotate(-45deg);
        }
    }
    
    &.open {
        background: ${text_blue};
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
    }
   
    .toggle-indicator {
        border-color: white;
        margin-left: auto;
        margin-right: 2px;
        margin-left: 12px;
    }
`


export default CurrentTemplateSelector;