import React from 'react';
import { css } from '@emotion/css';
import FeatureFilters, { PropertiesFilter } from '../../../../models/features/FeatureFilters';
import FeatureTypeFilter from './FeatureTypeFilter';
import FeatureTypeModel from '../../../../models/features/FeatureTypeModel';
import ModifiedDateFilter from './ModifiedDateFilter';
import FilterWrapper from './FilterWrapper';
import MiscellaneousFilter from './MiscellaneousFilter';
import LanguageFilter from './LanguageFilter';
import LanguageModel from '../../../../models/features/api/LanguageModel';
import { color_shades_dark, color_shades_lighter, color_text_light, color_text_default, color_colors_ocean } from '../../../../constants/colors';

const searchIcon = require('../../../../content/images/search.svg');

interface FiltersProps {
    currentFilter: FeatureFilters
    onChange: (filter: FeatureFilters) => void
    featureTypes: FeatureTypeModel[]
    languages: LanguageModel[]
}

class ContentItemFilters extends React.Component<FiltersProps> {
    searchRef: HTMLInputElement
    componentDidMount() {
        this.searchRef?.focus();
    }

    handleLanguageFilterChange(languageIds: string[]) {
        this.props.onChange({
            ...this.props.currentFilter,
            languageIdsFilter: languageIds
        })
    }

    handleFeatureFilterChange(featureTypeIds: string[]) {
        this.props.onChange({
            ...this.props.currentFilter,
            featureTypeIdsFilter: featureTypeIds
        })
    }

    handleModifiedDateRangeChange(days?: number) {
        this.props.onChange({
            ...this.props.currentFilter,
            daysFilter: days
        })
    }

    handleMiscFilterChange(properties: PropertiesFilter) {
        this.props.onChange({
            ...this.props.currentFilter,
            propertiesFilter: properties
        })
    }
    
    handleSearchTextChange(e) {
        this.props.onChange({
            ...this.props.currentFilter,
            textFilter: e.target.value
        })
    }

    render() {
        return (<div className={containerStyle}>
            <div className="search-bar">
                <label>Filter by Title</label>
                <div className="input-wrapper">
                    <img src={searchIcon} />
                    <input ref={search => this.searchRef = search} value={this.props.currentFilter?.textFilter ?? ''} placeholder="Filter by Title" onChange={this.handleSearchTextChange.bind(this)} />
                </div>
            </div>
            <div className="filters-wrapper">
                <FilterWrapper>
                    <FeatureTypeFilter onChange={this.handleFeatureFilterChange.bind(this)}
                        featureTypeIds={this.props.currentFilter?.featureTypeIdsFilter}
                        featureTypes={this.props.featureTypes} />
                </FilterWrapper>
                <FilterWrapper>
                    <LanguageFilter onChange={this.handleLanguageFilterChange.bind(this)}
                        languageIds={this.props.currentFilter?.languageIdsFilter}
                        languages={this.props.languages} />
                </FilterWrapper>
                <FilterWrapper>
                    <ModifiedDateFilter onChange={this.handleModifiedDateRangeChange.bind(this)}
                        days={this.props.currentFilter?.daysFilter} />
                </FilterWrapper>
                <FilterWrapper>
                    <MiscellaneousFilter onChange={this.handleMiscFilterChange.bind(this)}
                        propertiesFilter={this.props.currentFilter?.propertiesFilter}
                    />
                </FilterWrapper>
            </div>
        </div>)
    }
}

const containerStyle = css`
    border-bottom: 1px solid ${color_shades_dark};
    background: ${color_shades_lighter};
    padding: 16px 16px 24px 16px;

    .search-bar {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        margin: 0 16px;
        border-bottom: 1px solid ${color_shades_dark};
        label {
            color: ${color_text_default};
            font-size: 16px;
            line-height: 24px;
        }
        .input-wrapper {
            background: white;            
            border-radius: 8px;
            border: 1px solid ${color_shades_dark};
            margin-left: 12px;
            flex: 1;
            display: flex;
            align-items: center;
            height: 48px;
            padding: 0 16px;

            img {
                margin-right: 12px;
            }
            &:focus-within {
                border: 1px solid ${color_colors_ocean};
            }
        }
        input {
            border: none;
            background: transparent;
            flex: 1;
            color: ${color_text_default};
            line-height: 20px;
            font-size: 14px;
            ::placeholder {
                font-style: italic;
                color: ${color_text_light};                
                font-weight: normal;
                line-height: 20px;
                font-size: 14px;
            }
        }
    }

    .filters-wrapper {
        display: flex;
        justify-content: stretch;
        max-width: 100%;
        flex-basis: 0;
    }
`

export default ContentItemFilters;