import React from 'react';
import {css} from '@emotion/css';
import { cool_grey, pale_grey } from '../../../constants/colors';


const containerStyle = css`
border: 1px solid ${cool_grey};
background: ${pale_grey};
`
export const ResponseDetailContainer: React.SFC = (props) => (
    <div className={containerStyle}>
        {props.children}
    </div>
)