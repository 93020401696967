import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { color_shades_dark, color_shades_darkest, color_variants_ocean_light_opaque, color_variants_shadow_10, ocean_blue } from '../../../constants/colors';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import _ from 'lodash';

const speakerIcon = require('../../../content/images/speaker.svg');
const screenIcon = require('../../../content/images/screen.svg');
const chatIcon = require('../../../content/images/chat-icon.svg');

interface SimulatorDeviceSelectorProps {
    targets: DeviceTargetModel[]
    onSelectionChange: (target: DeviceTargetModel) => void
    selectedTarget: DeviceTargetModel
    isSmall?: boolean
}

const SimulatorDeviceSelector = (props: SimulatorDeviceSelectorProps) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isScreenCollapsed, setIsScreenCollapsed] = useState(true);
    const [isChatCollapsed, setIsChatCollapsed] = useState(true);
    const [isSpeakerCollapsed, setIsSpeakerCollapsed] = useState(false);
    const clickingRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, false);
        return () => {
          document.removeEventListener("click", handleOutsideClick, false);
        };
      }, []);
    
    const handleSelectionChange = (target: DeviceTargetModel) => {
        setIsCollapsed(!isCollapsed);
        props.onSelectionChange(target);
    }

    const renderDeviceHeader = (icon: string, name: string, select: () => any, top: boolean, bottom: boolean, isHeaderCollapsed: boolean) => {        
        return ( 
            <DeviceOption className={getContainerClass(false, false, top, false, bottom)} key={name} onClick={() => select()}>
                <div className="template-icon" ><img className="header-image" src={icon} /></div>
                <p className="template-title">{name}</p>                
                <DeviceHeaderToggleIndicator direction={isHeaderCollapsed ? "down" : "up"} />
            </DeviceOption> 
        )
    }

    const renderDeviceOption = (device: DeviceTargetModel, isSelected: boolean, top: boolean, bottom: boolean, flatBottom: boolean = false) => {
        return (
            <DeviceOption key={device.id} className={getContainerClass(true, isSelected, top, bottom, flatBottom)} onClick={() => handleSelectionChange(device)}>
                <div className="template-icon device" ><img src={device.iconUrl} /></div>
                <p className="template-title">{device.displayName}</p>
            </DeviceOption>
        );
    }

    const handleOutsideClick = (e: any) => {        
        if (clickingRef.current && !clickingRef.current.contains(e.target)) {
            setIsCollapsed(true);
        }
    }

    const getContainerClass = (isDevice: boolean, isSelected: boolean, top: boolean, bottom: boolean, flatBottom: boolean = false) => {
        var className = 'selectable';
        if (isSelected) className += ' selected';
        if (top) className += ' top';
        if (bottom) className += ' bottom';
        if (isDevice) className += ' device';
        if(flatBottom) className += ' flat-bottom';
        return className
    }

    return (
        <div className={containerStyle + " small " + "ac-simulator-device-picker ac-content-preview-device-picker"} ref={clickingRef}>
            {props.isSmall ? null : <p className="field-label">Preview-Type:</p>}
            <div className="selection-container">
                {props.selectedTarget &&
                <TargetDevice key={props.selectedTarget.id} onClick={() => setIsCollapsed(!isCollapsed)}>
                    <img className="device-target-icon" src={props.selectedTarget.iconUrl} />
                    <p className="device-target-title">{props.selectedTarget.displayName}</p>
                </TargetDevice>}
                <div className="selection-toggle" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <ToggleIndicator className={TargetDeviceToggleIndicator} direction={isCollapsed ? "down" : "up"} />
                </div>
            </div>
            {!isCollapsed &&
                <OptionsWrapper className="selector-wrapper" >
                    <Triangle />
                    <CustomScrollbars autoHide className={`inner-wrapper ${props.targets?.length === 1 ? " is-small" : ""}`}>
                        {renderDeviceHeader(screenIcon, "Screen", () => setIsScreenCollapsed(!isScreenCollapsed), true, false, isScreenCollapsed)}
                        {!isScreenCollapsed && _.orderBy(props.targets.filter(t => t.supportsForegroundImages), [t => t.displayName.toLowerCase()]).map((target, i) => (
                            renderDeviceOption(target, target.id == props.selectedTarget.id, false, false)
                        ))}
                        {renderDeviceHeader(speakerIcon, "Audio", () => setIsSpeakerCollapsed(!isSpeakerCollapsed), false, false, isSpeakerCollapsed)}
                        {!isSpeakerCollapsed && _.orderBy(props.targets.filter(t => !t.supportsForegroundImages && t.supportsRichAudio), [t => t.displayName.toLowerCase()]).map((target, i) => (
                            renderDeviceOption(target, target.id == props.selectedTarget.id, false, false)
                        ))}
                        {renderDeviceHeader(chatIcon, "Chat", () => setIsChatCollapsed(!isChatCollapsed), false, isChatCollapsed, isChatCollapsed)}
                        {!isChatCollapsed && _.orderBy(props.targets.filter(t => !t.supportsForegroundImages && !t.supportsRichAudio), [t => t.displayName.toLowerCase()]).map((target, i) => (
                            renderDeviceOption(target, target.id == props.selectedTarget.id, false, false, !isChatCollapsed && isSpeakerCollapsed && isScreenCollapsed)
                        ))}
                    </CustomScrollbars>                    
                </OptionsWrapper>
            }
        </div>
    )
}
const TargetDevice = styled.div`
    background: white;
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    flex: 1;
    align-items: center;
    min-height: 80px;
    .device-target-icon {
        margin-right: 16px;
    }
    .device-target-title {
        font-size: 18px;
        color:${color_shades_darkest};
        margin-bottom: 2px;
    }
`
const TargetDeviceToggleIndicator = css`
    width: 8px;
    height: 8px;
`

const DeviceHeaderToggleIndicator = styled(ToggleIndicator)`
    margin-left: auto;
    margin-right: 32px;
    width: 8px;
    height: 8px;
`;

const Triangle = styled.div`
    transform: rotate(45deg);
    width: 14px;
    height: 14px;
    margin-top: -7px;
    margin-bottom: -7px;
    border-top: solid 1px ${color_shades_dark};
    border-left: solid 1px ${color_shades_dark};
    background: white;
    margin-right: 30px;
    margin-left: auto;
    z-index: 4;
`;

const DeviceOption = styled.div`
    background: white;
    display: flex;
    flex-direction: row;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    flex: 1;
    align-items: center;
    min-height: 64px;
    .header-image{
        width: 32px;
    }
    &.selectable {
        border: 1px solid ${color_shades_dark};
        border-bottom: 0px;        
        &:hover {
            background: ${color_variants_ocean_light_opaque};
        }
        &.top {
            border-radius: 10px 10px 0 0;
            border-top: 0px;
        }
        &.bottom {
            border-radius: 0 0 10px 10px;
        }
        &.flat-bottom {
            border-bottom: 1px solid ${color_shades_dark};
        }
    }
    .template-icon {
        margin-right: 12px;
        margin-left: 24px;
        &.device {
            margin-left: 48px;
        }
    }
    &.selected {
        background-color: rgba(0, 125, 187, 0.1);
        border: 1px solid ${ocean_blue};
    }
    &.device {
        border-left: 3px solid ${color_shades_dark};
    }

    .template-title {
        color:${color_shades_darkest};
        font-size: 18px;
        margin-bottom: 2px;
    }
`;

const OptionsWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 4px 4px 0 ${color_variants_shadow_10};
    border-radius: 12px;
    z-index: 3;
    flex: 1;
    width: 100%;
    left: 0px;
    top: 72px;
    .inner-wrapper {
        overflow: hidden;
        max-height: 600px;
        border-radius: 12px;
        border-top: solid 1px ${color_shades_dark};
        border-bottom: solid 1px ${color_shades_dark};
    }    
`;

const containerStyle = css`
    width: 100%;
    .selection-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border-top: solid 1px ${color_shades_dark};
        cursor: pointer;
        background: white;
        .selection-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 52px;
        }
        .selection-toggle.small {
            border:0;
        }
        overflow: hidden;
    }

    &.small {
        .selection-container {
            height:52px;
            width:100%;
            align-items:center;
            justify-content: space-between;
            border: 0;
            .selection-toggle {
                border: 0;
            }
        }
        .selector-wrapper {
            margin:0;
            .inner-wrapper {
                min-height: 300px;
                &.is-small {
                    min-height: 82px;
                }
            }
        }
    }

    .field-label {
        font-size: 14px;
        color: ${color_shades_dark};
        background: white;
        padding-left: 32px;
        padding-top: 16px;
    }
`

export default SimulatorDeviceSelector