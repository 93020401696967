import React from 'react';
import { css } from '@emotion/css';
import { color_shades_darkest, color_shades_dark, color_shades_darker, color_colors_royalty } from '../../../constants/colors';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import ApplicationAvatar from '../../../components/general/ApplicationAvatar';
import ApplicationCountsModel from '../../../models/applications/api/ApplicationCountsModel';
import ApplicationWithMembers from '../../../models/applications/api/ApplicationWithMembers';
const folderIcon = require('../../../content/images/grey-folder.svg');
const contentItemIcon = require('../../../content/images/grey-page.svg');
import { Link } from 'react-router-dom';
import IconCollectionItem from '../../../models/media/IconCollectionItem';
import IconCollectionAvatar from '../../../components/general/IconCollectionAvatar';
import SmallOverflowMenu from '../../../components/general/overflowMenu/SmallOverflowMenu';
const deleteIcon = require('../../../content/images/menu-item-icons/bin.svg');
const webhookIcon = require('../../../content/images/webhooks/webhook-dark.svg');
interface ApplicationTableItemProps {
    app: ApplicationWithMembers
    counts?: ApplicationCountsModel
    onOptionSelect?: (id: string, option: string) => void
}
const ApplicationTableItem: React.SFC<ApplicationTableItemProps> = (props) => (
    <div className={`${containerStyle} ac-app-list-item`}>
        <Link to={`/v/apps/${props.app.id}`} className={nameAndIcon}>
            <ApplicationAvatar app={props.app} size={42} />
            <div className={`${appNameStyle} ${props.app.allowsTemplating ? 'basis-app' : ''}`} >{`${props.app.allowsTemplating ? '⬥ ' : ''}${props.app.name}`}</div>
        </Link>
        <HorizontalContainer className="counts">
            <img className="folder" src={folderIcon} />
            <div>{props.counts == null ? '-' : props.counts.applicationFeatureCount}</div>
            <img className="content" src={contentItemIcon} />
            <div>{props.counts == null ? '-' : props.counts.conversationCount}</div>
        </HorizontalContainer>
        <IconCollectionAvatar
            className={"users"}
            items={props.app.members?.filter(a => a.organizationMember.isApiUser == false)?.map(a => {
                var collectionItem: IconCollectionItem;
                collectionItem = {
                    noImageText: `${a.organizationMember.firstName == null ? "N" : a.organizationMember.firstName[0]} ${a.organizationMember.lastName == null ? "A" : a.organizationMember.lastName[0]}`,
                    tooltipText: `${a.organizationMember.firstName == null ? "N" : a.organizationMember.firstName[0]} ${a.organizationMember.lastName == null ? "A" : a.organizationMember.lastName[0]}`,
                    imageUrl: a.organizationMember.imageUrl
                }
                return collectionItem;
            })}
            limit={4} />
        <SmallOverflowMenu
            className={`${overflowStyle} ac-app-list-item-overflow`}
            menuClassName={overflowMenuStyle}
            optionClassName={overflowOptionStyle}
            buttonClassName={overflowMenuButtonStyle}
            onOptionSelected={(option) => props.onOptionSelect(props.app.id, option)}
            options={[{ label: 'Webhooks', icon: webhookIcon, hoverIcon: webhookIcon }, { label: 'Delete', icon: deleteIcon, hoverIcon: deleteIcon, isDestructive: true }]} />
    </div>
);


const overflowMenuStyle = css`
    width: 120px;
    margin-left: 0px;
    right: -11px;
`;

const overflowOptionStyle = css`
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 120px;
`;


const overflowMenuButtonStyle = css`    
    border-radius: 4px;
    width: 24px;
    height: 24px;
    border-color: ${color_shades_darker};
    .circle {
        width: 4px;
        height: 4px;
        border-color: ${color_shades_darker};
    }
`;

const overflowStyle = css`
    position: absolute;
    right: 32px;
    top: 16px;
`;

const containerStyle = css`
    display: flex; 
    flex-direction: row;    
    position: relative;
    border-bottom: solid 1px ${color_shades_dark};
    padding: 8px 32px;
    .counts {
        width: 30%;
        line-height: 24px;
        margin: auto 8px;        
        color: ${color_shades_darker};
        img {
            margin-right: 4px;
        }
    }
    .users {
        width: 20%;
        margin: auto 0px;
    }
`;

const appNameStyle = css`
    margin-left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: ${color_shades_darkest};
    margin-top: auto;
    margin-bottom: auto;
    &.basis-app {
        color: ${color_colors_royalty};
    }
`;

const nameAndIcon = css`
    display: flex;
    flex-direction: row;
    width: 50%;      
`;

export default ApplicationTableItem;