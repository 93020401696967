import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import { cool_grey, pale_grey, ocean_blue, color_shades_dark, color_shades_lighter } from '../../../constants/colors';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import { getFeatureTypeIcon, getDefaultFeatureTypeName } from '../../../models/extensions';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
const navigationCircle = require('../../../content/images/content-tree-icons/content-level/navigation-circle-blue.svg');

interface ConversationItemSelectorProps {
    conversationItems?: GenericContentItemModel[]
    isSaving: boolean
    onSelect: (contentItem: GenericContentItemModel) => void
}

const ConversationItemSelector: React.SFC<ConversationItemSelectorProps> = (props) => (
    <div className={containerStyle}>
        {props.conversationItems && props.conversationItems.length > 0 ? props.conversationItems?.map((c, i) =>
            (<div key={i} className={"conversation-item"} onClick={() => props.onSelect(c)}>
                <TooltipWrapper text={getDefaultFeatureTypeName(c.featureTypeId)} place="top">
                    <img src={getFeatureTypeIcon(c.featureTypeId)} />
                </TooltipWrapper>
                <TooltipWrapper text={c.title} place="top">
                    <p className="content-title"><i>{c.titleAttribute}</i>{c.title}</p>
                </TooltipWrapper>
                <TooltipWrapper text="Click to save and go to this item" place="top">
                    <img className="end" src={navigationCircle} />
                </TooltipWrapper>
            </div>)
        ) : <p className="empty-label">No conversation items here</p>}
    </div>
);
const containerStyle = css`
  background: ${color_shades_lighter};
  padding: 16px;

    .empty-label {
        margin: 0;
        background: white;
        font-size: 12px;
        border: 1px solid ${color_shades_dark};
        padding: 16px;
        border-radius: 8px;
    }

    .conversation-item {
        border-bottom: 1px solid ${color_shades_dark};
        border-left: 1px solid ${color_shades_dark};
        border-right: 1px solid ${color_shades_dark};
        background: white;
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 48px;
        cursor: pointer;

        p {
            margin: 0 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        img {
            width: 24px;
            height: 24px;
        }
        .end {
            margin-right: 0;
            margin-left: auto;
        }

        :first-child {
            border-top: 1px solid ${color_shades_dark};
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
        }
        :last-child {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
  }
`
export default ConversationItemSelector