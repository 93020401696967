import React from 'react';
import { InjectedFormikProps, withFormik, Form, FieldArray, ArrayHelpers } from "formik";
import * as yup from 'yup';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import RepromptContainer from '../../../state/containers/RepromptContainer';
import RepromptModel from '../../../models/features/api/Reprompt/RepromptModel';
import DetailLabel from '../../../components/general/DetailLabel';
import CheckboxField from '../../../components/forms/CheckboxField';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';

interface RepromptFormProps {
    repromptContainer: RepromptContainer
    reprompt?: RepromptModel
    applicationId: string
    onFinish?: (reprompt: RepromptModel) => void
    onClose?: () => void
    onChange?: () => void
}
interface RepromptFormData {
    name?: string
    content?: string
    includeFollowUp: boolean
}
class InnerForm extends React.Component<InjectedFormikProps<RepromptFormProps, RepromptFormData>> {
    handleCheckboxToggle(e: React.ChangeEvent<any>) {
        this.props.setFieldValue('includeFollowUp', e.target.checked);
    }
    handleSsmlUpdate(value: string) {
        this.props.setFieldValue('content', value);
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.repromptContainer.state.isLoading || props.repromptContainer.state.isSaving;

        return (
            <>
                <DetailLabel text="If the user doesn’t respond to what the device says, you can re-prompt them for a response." />
                <FormFieldsContainer>
                    <TextField className="tight" name="name" required value={props.values.name} label="Name:" placeholder="What do you want to call this message?" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                    <SsmlField applicationId={this.props.applicationId} className="tight" name="content" required value={props.values.content} label="Reprompt language:" placeholder="What do you want the device to say?" onChange={this.handleSsmlUpdate.bind(this)} onBlur={props.handleBlur} disabled={isLoading} />
                    <CheckboxField name="includeFollowUp" checked={props.values.includeFollowUp} label="Then repeat previous Follow-up" onChange={this.handleCheckboxToggle.bind(this)} />
                </FormFieldsContainer>
                <FooterBar>
                    <Button loading={isLoading} disabled={isLoading} themes={["primary", "wide"]} text="Save" type="button" onClick={props.handleSubmit} />
                    <Button disabled={isLoading} themes={["white", "end"]} text="Cancel" type="button" onClick={props.onClose} />
                </FooterBar>
            </>
        )
    }
}

const RepromptForm = withFormik<RepromptFormProps, RepromptFormData>({
    mapPropsToValues: props => ({
        name: props.reprompt ? (props.reprompt.name ? props.reprompt.name : '') : '',
        content: props.reprompt ? props.reprompt.content : '',
        includeFollowUp: true
    }),
    validationSchema: yup.object().shape({
        name: yup.string().required("You must enter a name"),
        content: yup.string().required("You must enter your message")
    }),
    handleSubmit: (values, { props }) => {
        if (props.reprompt && props.reprompt.id) {
            // updating an existing one
            props.repromptContainer.updateReprompt(props.reprompt.id, {
                id: props.reprompt.id,
                name: values.name,
                content: values.content,
                includeFollowUp: values.includeFollowUp
            }).then(result => {
                if (result.resultType == "Ok") {
                    props.onFinish(result.data);
                }
            })
        }
        else {
            // creating a new one
            props.repromptContainer.addReprompt(props.applicationId, {
                name: values.name,
                content: values.content,
                includeFollowUp: values.includeFollowUp
            }).then(result => {
                if (result.resultType == "Ok") {
                    props.onFinish(result.data);
                }
            })
        }
    }
})(InnerForm);

export default RepromptForm;
