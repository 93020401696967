import React from 'react';
import { css } from '@emotion/css';
import ContainerDimensions from 'react-container-dimensions';
const alexaBackground = require('../../../content/images/show-background.jpg');

export const alexaBodyStyle = css`
    position: relative;
    width: 100%;
    padding-top: 100%; /* 16:9 Aspect Ratio */
    margin: 16px 0;
    border-radius: 50%;
    .screen-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        background: black;
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
    .screen {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-49%, -49%);
        transform-origin: center center;
        width: 1080px;
        height: 1080px;
        background: url('${alexaBackground}');
        background-size:     cover; 
        background-repeat:   no-repeat;
        background-position: center center; 
        border-radius: 50%;
        overflow: hidden;
        color: white;
        display: flex;
        flex-direction: column;

        .body-text, .title-text {
            font-family: 'Roboto', sans-serif;
            font-size: 80px;
            padding-top: 80px;
            padding-left: 20px;
            padding-right: 20px;
            color: white;    
            overflow-wrap: break-word;
            z-index: 2;
            position: relative;
            text-align: center;
        }
        .body-text {
            overflow-y: hidden;
            flex: 1;
        }
        .background-cover {
            width: 1080px;
            height: 1080px;
            position: absolute;
            z-index: 1;  
            background-color: black;
            opacity: .7;          
        }
        .body-container {
            display: flex;
            z-index: 2;
            position: relative;
        }
        .background-image {
            width: 1080px;
            height: 1080px;
            position: absolute;
            z-index: 0;
        }
        .foreground-image {
            width: 600px;
            z-index: 2;
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
`
interface AlexaWrapperProps {
    className?: string
}
const AlexaSmallWrapper: React.SFC<AlexaWrapperProps> = (props) =>
    (
        <div className={alexaBodyStyle}>
            <div className='screen-wrapper'>
                <ContainerDimensions>
                    {({ width, height }) =>
                        <div className={`screen ${props.className}`} style={{ transform: `translate(-50%, -50%) scale(${Math.min(width / 1080, height / 1080)})` }}>
                            {props.children}
                        </div>
                    }
                </ContainerDimensions>
            </div>
        </div >
    )

export default AlexaSmallWrapper;