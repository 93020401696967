import { Container } from 'unstated';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import * as voicifyApi from '../../api';
import WebhookModel from '../../models/webhooks/api/WebhookModel';
import WebhookTypeModel from '../../models/webhooks/api/WebhookTypeModel';
import NewWebhookRequest from '../../models/webhooks/api/NewWebhookRequest';
import UpdateWebhookRequest from '../../models/webhooks/api/UpdateWebhookRequest';
import ApplicationWebhookModel from '../../models/webhooks/api/ApplicationWebhookModel';
import _, { countBy } from 'lodash';
import WebhookParameterValue from '../../models/webhooks/api/WebhookParameterValue';
import IResult from '../../models/result/IResult';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookAttachmentModel from '../../models/webhooks/api/WebhookAttachmentModel';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import WebhookSensitiveDataModel from '../../models/webhooks/api/WebhookSensitiveDataModel';

type WebhookState = {
    isLoading: boolean
    isUpdating: boolean
    webhooks: WebhookModel[]
    organizationWebhooks: WebhookModel[],
    webhookTypes: WebhookTypeModel[]
    applicationWebhookInstances: ApplicationWebhookModel[]
    errors: string[]
    webhookAttachments: WebhookAttachmentModel[]
    webhookSensitiveData: WebhookSensitiveDataModel
}

export default class WebhookContainer extends Container<WebhookState> {
    state = {
        isLoading: false,
        isUpdating: false,
        webhooks: [] as WebhookModel[],
        organizationWebhooks: [] as WebhookModel[],
        webhookTypes: [] as WebhookTypeModel[],
        applicationWebhookInstances: [] as ApplicationWebhookModel[],
        webhookAttachments: [] as WebhookAttachmentModel[],
        errors: [],
        webhookSensitiveData: {} as WebhookSensitiveDataModel
    }

    async clearErrors() {
        await this.setState({ ...this.state, errors: [] });
    }

    async updateFullWebhook(model: WebhookModel): Promise<IResult<WebhookModel>> {
        try {
            await this.setState({
                ...this.state,
                isLoading: true,
            })
            const result = await voicifyApi.updateFullWebhook(model.id, model);
            if (result.resultType == "Ok") {
                const idx = this.state.webhooks.findIndex(wf => wf.id == model.id)
                const newWebhooks = [...this.state.webhooks];
                newWebhooks[idx] = model;
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    webhooks: newWebhooks
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }

        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    async createFullWebhook(model: WebhookModel): Promise<IResult<WebhookModel>> {
        try {
            await this.setState({
                ...this.state,
                isLoading: true,
            })
            const result = await voicifyApi.createFullWebhook(model);
            if (result.resultType == "Ok") {
                const newWebhooks = [...this.state.webhooks, result.data];
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    webhooks: newWebhooks
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }

        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    getWebhooksForOrganization(organizationId: string): Promise<IResult<WebhookModel[]>> {
        this.setLoading(true);
        const promise = voicifyApi.getWebhooksForOrg(organizationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    organizationWebhooks: result.data
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })
        return promise;
    }

    getWebhookAttachmentsForApplication(applicationId: string): Promise<IResult<WebhookAttachmentModel[]>> {
        const promise = voicifyApi.getWebhookAttachmentsForApplication(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    webhookAttachments: result.data
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error]
            })
        })
        return promise;
    }

    getAttachedWebhooksCount(webhookContainerId: string): number {
        let count = 0;
        (this.state.webhookAttachments ?? []).forEach(wa => {
            (wa.applicationFeatureIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.applicationIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.customRequestIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.questionAnswerIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.numberRangeIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.simpleChoiceIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.welcomeMessageIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.exitMessageIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.latestMessageIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
            (wa.helpMessageIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });

            (wa.fallbackMessageIds ?? []).forEach(id => {
                if (id == webhookContainerId) {
                    count++;
                }
            });
        });
        return count;
    }

    getWebhooksForApplication(applicationId: string): Promise<IResult<WebhookModel[]>> {
        this.setLoading(true);
        const promise = voicifyApi.getWebhooksForApp(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    webhooks: result.data
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })
        return promise;
    }

    async getApplicationWebhookInstances(applicationId: string): Promise<IResult<ApplicationWebhookModel[]>> {
        try {
            this.setLoading(true);
            const result = await voicifyApi.getApplicationWebhookInstances(applicationId);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    applicationWebhookInstances: result.data
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;
        }
        catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    addOrUpdateApplicationWebhook(id: string, webhook: WebhookModel, defaultParameters: { [id: string]: WebhookParameterValue }, userDefinedParameters: { [id: string]: WebhookParameterValue }) {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        let promise = webhook.instanceId == null ?
            voicifyApi.addApplicationWebhook(id, webhook.id, defaultParameters, userDefinedParameters)
            :
            voicifyApi.updateApplicationWebhook(id, webhook.instanceId, defaultParameters, userDefinedParameters);

        promise.then(result => {
            if (result.resultType == "Ok") {
                const webhooks = [...this.state.applicationWebhookInstances];
                const model = result.data as ApplicationWebhookModel;
                const existing = webhooks.find(w => w.id === model.id)
                if (existing == null)
                    webhooks.push(model);
                else {
                    const idx = webhooks.indexOf(existing);
                    webhooks.splice(idx, 1, model);
                }
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [],
                    applicationWebhookInstances: webhooks
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }

    async bulkUpdateApplicationWebhookInstances(applicationId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> {
        try {
            await this.setState({
                ...this.state,
                isUpdating: true
            })
            var result = await voicifyApi.bulkUpdateApplicationWebhookInstances(applicationId, updateRequest);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isUpdating: false,
                    applicationWebhookInstances: result.data,
                    errors: [],
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
            return result;
        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }


    createWebhook(organizationId: string, model: NewWebhookRequest) {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        const promise = voicifyApi.createWebhook(organizationId, model);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const webhooks = this.state.webhooks;
                webhooks.push(result.data);
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [],
                    webhooks: webhooks
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }
    removeApplicationWebhook(applicationWebhookId: string) {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        const promise = voicifyApi.removeApplicationWebhook(applicationWebhookId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var webhooks = this.state.applicationWebhookInstances;
                _.remove(webhooks, w => w.id == applicationWebhookId);
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [],
                    applicationWebhookInstances: webhooks
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }

    updateWebhook(webhookId: string, model: UpdateWebhookRequest) {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        const promise = voicifyApi.updateWebhook(webhookId, model);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const webhooks = this.state.webhooks;

                // find webhook
                const webhook = webhooks.find(w => w.id == webhookId);

                webhooks[webhooks.indexOf(webhook)] = result.data;
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [],
                    webhooks: webhooks
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }

    removeOrganizationWebhook(webhookId: string) {
        this.setState({
            ...this.state,
            isUpdating: true,
        });

        return voicifyApi.removeOrganizationWebhook(webhookId)
            .then(res => {
                if (res.resultType == "Ok") {
                    const webhooks = this.state.webhooks;
                    const webhook = webhooks.find(w => w ? w.id == webhookId : false);
                    delete webhooks[webhooks.indexOf(webhook)]
                    this.setState({
                        ...this.state,
                        isUpdating: false,
                        webhooks
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        isUpdating: false,
                        errors: res.errors
                    })
                }
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [err]
                })
            })
    }

    getWebhookTypes() {
        if (this.state.webhookTypes == null || this.state.webhookTypes.length == 0) {
            this.setLoading(true);
            const promise = voicifyApi.getWebhookTypes();
            promise.then(result => {
                if (result.resultType == "Ok") {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        errors: [],
                        webhookTypes: result.data
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        errors: result.errors
                    })
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [error]
                })
            })
            return promise;
        }
    }

    async generateSensitiveDataKey(webhookId: string) {
        try {
            await this.setState({
                ...this.state,
                isLoading: true,
            })
            const result = await voicifyApi.generateSensitiveDataKey(webhookId);
            if (result.resultType == "Ok") {
                const idx = this.state.webhooks.findIndex(wf => wf.id == webhookId)
                const webhook = this.state.webhooks[idx];
                webhook.hasSensitiveDataKey = true;
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    webhookSensitiveData: result.data
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }

        } catch (error) {
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }

    async removeSensitiveDataKey(webhookId: string) {
        this.setState({
            ...this.state,
            isUpdating: true,
        });

        try {
            const res = await voicifyApi.removeSensitiveDataKey(webhookId);
            if (res.resultType == "Ok") {
                const webhooks = this.state.webhooks;
                const webhook = webhooks.find(w => w ? w.id == webhookId : false);
                webhook.hasSensitiveDataKey = false;
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    webhooks
                });
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: res.errors
                });
            }
        } catch (err) {
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [err]
            });
        }
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading
        })
    }
}