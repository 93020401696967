import React from 'react';
import { css } from '@emotion/css';
import ApplicationFeatureModel from '../../models/features/api/ApplicationFeatureModel';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { DropResult, ResponderProvided, DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import NewFeatureItem from './components/NewFeatureItem';
import NewStubContentItem from './components/NewStubContentItem';
import FeatureItem from './components/FeatureItem';
import ContentListItem from './components/ContentListItem';
import { color_shades_dark, color_shades_light, color_shades_darkest, color_colors_growth, color_variants_main_bg } from '../../constants/colors';
import DisablingFilters from '../../models/features/DisablingFilters';
import SecondaryLoader from '../../components/general/SecondaryLoader';
import EmptyView from './components/EmptyView';
import ContentItemFilters from './components/filters/ContentItemFilters';
import FeatureFilters from '../../models/features/FeatureFilters';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import { getFilterCount } from './components/FeatureToolbar';
import NoResultsView from './components/NoResultsView';
import WebhookContainer from '../../state/containers/WebhookContainer';
import ContentItemPublishHistoryModel from '../../models/features/api/ContentItemPublishHistoryModel';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';
import { reorder } from '../../models/extensions';

interface FeatureItemProps {
    feature: ApplicationFeatureModel
    application: ApplicationModel
    childFeatures: ApplicationFeatureModel[]
    contentItems: GenericContentItemModel[]
    contentPublishStatus?: ContentItemPublishHistoryModel[]
    contentPublishStatusLoaded?: boolean
    showContentItemStatus: boolean,
    handleAddNewFeature: (text: string, featureId: string) => void
    handlePublish: (ci : GenericContentItemModel) => void
    handleUnpublish: (ci : GenericContentItemModel) => void
    featureContainer: FeatureContainer
    webhookContainer: WebhookContainer
    withNesting: boolean
    applicationId: string
    selectedFeatureIds: string[]
    selectedContentItemIds: string[]
    onFeatureClick?: (applicationFeature: ApplicationFeatureModel) => void
    onContentItemSelectionChange: (contentItem: GenericContentItemModel, isSelected: boolean) => void
    onFeatureSelectionChange: (applicationFeature: ApplicationFeatureModel, isSelected: boolean) => void
    onStubAdded: (contentItem: GenericContentItemModel) => void
    onManageWebhooksForAppFeature?: (applicationFeatureId: string) => void
    onManageWebhooksForContentItem?: (contentItem: GenericContentItemModel) => void
    onFilterChange: (filters: FeatureFilters) => void
    showContentItems: boolean
    selectableContentItems: boolean
    selectableFeatures: boolean
    isSingleSelect?: boolean
    navigatingContentItems: boolean
    isAddingNewFeature: boolean
    newStubFeatureTypeId?: string
    droppableId: string
    disablingFilters?: DisablingFilters
    isFilterExpanded: boolean
    filters: FeatureFilters
    statusDropDownDisabled?: boolean
    isContentFiltered?: boolean
}

const FeatureContent = (props: FeatureItemProps) => {

    const handleCreateNewFeature = (text: string) => {
        props.handleAddNewFeature(text, props.feature?.id)
    };

    const handleFoldersDragEnd = (result: DropResult, provider: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        };
        const features = _.orderBy(props.childFeatures, af => af.priority, 'asc');
        
        props.featureContainer.setFeatureOrder(
            props.applicationId, 
            props.feature?.id, 
            reorder(features, result.source.index, result.destination.index).map(r => r.id));
    };

    const handleContentItemsDragEnd = (result: DropResult, provider: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        };
        const items = _.orderBy(props.contentItems, item => {item.priority, item.createdDate}, 'asc');
        const itemsOrdered: GenericContentItemModel[] = reorder(items, result.source.index, result.destination.index);
        
        props.featureContainer.setFeatureContentItemsOrder(
            props.feature?.id, 
            itemsOrdered);
    };

    const getIsContentItemDisabled = (item: GenericContentItemModel) => {
        const filters = props.disablingFilters;
        if (filters?.featureTypeIdFilter && filters?.featureTypeIdFilter.length > 0) {
            if (!filters.featureTypeIdFilter.some(id => id == item.featureTypeId))
                return true;
        }
        if (filters?.languageIdFilter && filters?.languageIdFilter.length > 0) {
            if (!filters.languageIdFilter.some(id => item.languages.some(l => l.id == id)) && item.languages.length > 0)
                return true;
        }
        // properties filter?
        return false;
    };

    const renderEmpty = () => {
        if (getFilterCount(props.filters) == 0)
            return <EmptyView />

        return <NoResultsView />
    };

    return (
        <div className={containerStyle}>

            {props.isFilterExpanded &&
                <ContentItemFilters
                    featureTypes={props.featureContainer.state.systemFeatures.map(f => f.featureType)}
                    languages={props.application.languages}
                    currentFilter={props.filters}
                    onChange={props.onFilterChange} />}
            <div className="child-container">
                {props.withNesting && <div className="spacer">
                </div>}
                <div className="child-content-container">
                    {props.isAddingNewFeature && 
                    <NewFeatureItem isLoading={props.featureContainer.state.isAddingFeature} onFinish={handleCreateNewFeature.bind(this)} />}
                    {(props.featureContainer.state.isLoadingFeatures 
                    && !(props.featureContainer.state.loadingAppFeatureIds.some(s => s === props.feature?.id))) ? 
                        <SecondaryLoader className="feature-loader" /> :
                        <DragDropContext onDragEnd={handleFoldersDragEnd.bind(this)}>
                            <Droppable droppableId={props.droppableId} type="FEATURES_MAIN">
                                {
                                    (provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {_.orderBy(props.childFeatures, af => af.priority, 'asc')?.map((af, i) => (
                                                <Draggable draggableId={af.id} index={i} key={af.id}> 
                                                    {(provided, snapshot) => (
                                                        <div 
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <FeatureItem 
                                                                key={"feature_item_" + af.id} 
                                                                feature={af}
                                                                className={snapshot.isDragging ? 'dragging' : ''}
                                                                index={i}
                                                                selectable={props.selectableFeatures}
                                                                featureContainer={props.featureContainer}
                                                                childFeatures={props.featureContainer.getFeaturesForParent(af.id)}
                                                                contentItems={props.featureContainer.getContentByFeatureId(af.id)}
                                                                withNesting={props.withNesting}
                                                                applicationId={props.applicationId}
                                                                onSelectionChange={props.onFeatureSelectionChange}
                                                                onClick={props.onFeatureClick ? () => props.onFeatureClick(af) : null}
                                                                onManageWebhooks={props.onManageWebhooksForAppFeature}
                                                                isSelected={props.selectedFeatureIds?.some(id => id == af.id)}
                                                                disabled={props.disablingFilters?.disabledAppFeatureIds?.some(id => id == af.id)}
                                                                isSingleSelect={props.isSingleSelect}
                                                                webhookInstanceCount={props.webhookContainer ? props.webhookContainer.getAttachedWebhooksCount(af.id) : 0} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                    }
                    {(props.featureContainer.state.loadingAppFeatureIds.some(s => s === props.feature?.id)) ?
                        <div className="content-loader">
                            <SecondaryLoader />
                        </div> :
                        
                        <div className={`child-content-items-container ${props.childFeatures == null || props.childFeatures?.length === 0 ? "only-content" : ""}`}>
                            {props.newStubFeatureTypeId && 
                            <NewStubContentItem
                                onFinish={props.onStubAdded}
                                featureTypeId={props.newStubFeatureTypeId}
                                applicationFeatureId={props.feature?.id}
                                applicationId={props.applicationId}
                            />}

                            {props.showContentItems && 
                            <DragDropContext onDragEnd={handleContentItemsDragEnd.bind(this)}>
                                <Droppable droppableId={"content_items_list"} type="CONTENT_ITEMS_MAIN">
                                {
                                    (provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {_.orderBy(props.contentItems, item => {item.priority, item.createdDate}, 'asc')?.map((c, i) => 
                                            {
                                                var publishHistory = props.contentPublishStatus?.find(p => p.contentItemId == c.id)?.publishes;
                                                let lastPublish = null as GenericContentItemPublishModel;
                                                // Do not allow reordering of content items if they were filtered
                                                const className = props.isContentFiltered ? '' : 'draggable ';

                                                if (publishHistory && publishHistory.length > 0) {
                                                    lastPublish = publishHistory[0];
                                                }
                                                return (
                                                <Draggable draggableId={c.id} index={i} key={c.id} isDragDisabled={props.isContentFiltered}>
                                                    {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                    <ContentListItem 
                                                        selectable={props.selectableContentItems}
                                                        key={"content_item_" + c.id}
                                                        className={className + (snapshot.isDragging ? 'dragging' : '')}
                                                        index={i}
                                                        statusDropDownDisabled={props.statusDropDownDisabled}
                                                        contentItem={c}
                                                        handlePublish={() => props.handlePublish(c)}
                                                        handleUnpublish={() => props.handleUnpublish(c)}
                                                        lastPublish={lastPublish}
                                                        isPublishLoaded={props.contentPublishStatusLoaded}
                                                        showStatus={props.showContentItemStatus}
                                                        withLink={props.navigatingContentItems}
                                                        applicationFeature={props.feature}
                                                        featureContainer={props.featureContainer}
                                                        isSelected={props.selectedContentItemIds?.some(id => id == c.id)}
                                                        onSelectionChange={props.onContentItemSelectionChange}
                                                        onManageWebhooks={props.onManageWebhooksForContentItem}
                                                        disabled={getIsContentItemDisabled(c)}
                                                        isSingleSelect={props.isSingleSelect}
                                                        webhookInstanceCount={props.webhookContainer?.getAttachedWebhooksCount(c.id)} />
                                                    </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                    )
                                }
                                </Droppable>
                            </DragDropContext>}

                            {(props.contentItems.length == 0 && props.childFeatures.length == 0 && !props.featureContainer.state.isLoadingFeatures) &&
                                renderEmpty()}

                        </div>
                        
                    }
                </div>
            </div>
        </div>
    )    
}

const containerStyle = css`
    background: ${color_variants_main_bg};
    padding-bottom: 16px;
    .content-loader {
        margin: 32px;
        margin-top: 48px;
        .spinner > div {
            background: ${color_colors_growth};
        }
    }
    .feature-loader {
        margin-top: 16px;
    }
    .child-container {
        display: flex;
        align-items: stretch;
        .spacer {
            width: 32px;
            background: ${color_shades_light};
            border: 1px solid ${color_shades_dark};
            box-sizing: border-box;
        }
        .child-content-container {
            flex: 1;
            padding: 32px 0;
            .spinner > div {
                background: ${color_colors_growth};
            }
        }
        .child-content-items-container {
            padding: 32px 0;  
            margin-bottom: 64px;
            &.only-content {
                padding: 0px;
            }        
        }

    }
    .triangle-down {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid ${color_shades_darkest};
    }
    .triangle-right {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid ${color_shades_darkest};
    }

    .triangle-container {
        width: 3px;
        height: 3px;
        margin-right: 8px;
    }

    .check {
        margin-top: 4px;
    }
`

export default FeatureContent;