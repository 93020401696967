import { css } from '@emotion/css';
import React from 'react';
import { color_colors_gold, color_colors_gold_background, color_colors_ocean, color_shades_dark, color_shades_darkest } from '../../../constants/colors';
const infoImage = require('../../../content/images/info-circle-yellow.svg');

interface OrphanedWebhooksHeaderProps {
    count: number
    showOrphanedWebhooks: () => void
}

const OrphanedWebhooksHeader: React.FC<OrphanedWebhooksHeaderProps> = (props) => (
    <div className={containerStyle}>
        <img src ={infoImage} />
        <p className="prompt">{`You have ${props.count} orphaned webhooks. `}</p>
        <span />
        <p className="show-link"onClick={() => props.showOrphanedWebhooks()}>View Them</p>
    </div>
)

const containerStyle = css`
    display: flex;
    border-bottom: 1px solid ${color_colors_gold};
    background: ${color_colors_gold_background};
    padding: 18px 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color_shades_darkest};
    .show-link {
        cursor: pointer;
        color: ${color_colors_ocean};
        padding-left: 16px;
        border-left: 1px solid ${color_shades_dark};
    }
    img {
        margin-right: 12px;
    }
    span {
        margin-left: auto;
    }
`;


export default OrphanedWebhooksHeader;