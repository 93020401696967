import React from 'react';
import { css } from '@emotion/css';
import ModalLayout from '../../components/structure/ModalLayout';
import MediaItemModel from '../../models/media/MediaItemModel';
import MediaContainer from '../../state/containers/MediaContainer';
import FullScreenLayout from '../fullScreenLayout';
import ScrollView from '../../components/structure/ScrollView';
import TabbedLayout from '../../components/structure/TabbedLayout';
import TabPanelBody from '../../components/structure/TabPanelBody';
import PageContainer from '../../components/structure/PageContainer';
import DetailLabel from '../../components/general/DetailLabel';
import { TabPanel } from 'react-tabs';
import { Subscribe } from 'unstated';
import PageTitle from '../../components/general/PageTitle';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import MediaManagerForm, { MediaManagerFormData } from './components/MediaManagerForm';
import ModalTitle from '../../components/general/ModalTitle';
import Button from '../../components/general/Button';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import MediaResponseContainerModel from '../../models/features/api/MediaResponses/MediaResponseContainerModel';
import PageError from '../../components/general/PageError';
import CustomScrollbars from '../../components/structure/CustomScrollbars';
const closeIcon = require('../../content/images/close_icon.svg');

interface MediaResponseManagerProps {
    isVisible: boolean
    applicationId: string
    onItemUploaded?: (mediaItem: MediaItemModel) => void
    onItemRemoved?: (mediaItem: MediaItemModel) => void
    onClose?: () => void
    onFinish: (formData: MediaManagerFormData) => void
    imageItem?: MediaItemModel
    backgroundImageItem?: MediaItemModel
    audioItem?: MediaItemModel
    videoItem?: MediaItemModel
    displayTextOverride?: string
    displayTitleOverride?: string
    mediaResponseContainerId?: string
    container?: MediaResponseContainerModel
}

interface MediaResponseManagerState {
    hasChanges: boolean
}

class MediaResponseManager extends React.Component<MediaResponseManagerProps, MediaResponseManagerState> {
    componentWillMount() {
        this.setState({hasChanges: false})
    }
    handleChange() {
        this.setState({hasChanges: true});
    }
    handleClose() {
        if(this.state.hasChanges) {
            var confirmClose = confirm("You have unsaved changes. Are you sure you want to close?");
            if(!confirmClose)
                return;
        }

        if(this.props.onClose)
            this.props.onClose();
    }
    handleFinish(formData: MediaManagerFormData) {
        if(this.props.onFinish) {
            this.setState({
                hasChanges: false
            });
            this.props.onFinish(formData);
        }
    }
    render() {
        return (
            <ModalLayout isVisible={this.props.isVisible} onClose={this.handleClose.bind(this)}>
                <Subscribe to={[MediaContainer, MediaResponseStateContainer]}>
                    {(mediaContainer: MediaContainer, mediaResponseContainer: MediaResponseStateContainer) => {
                        return (
                            <FullScreenLayout>
                                <CustomScrollbars autoHide className={scrollBarContainer}>
                                    <PageContainer>
                                        <HorizontalContainer>
                                            <ModalTitle>Media-Rich Experience:</ModalTitle>
                                            <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={this.handleClose.bind(this)} />
                                        </HorizontalContainer>
                                        <HorizontalSeparator />
                                        <TabPanelBody>
                                            <MediaManagerForm 
                                                stateContainer={mediaResponseContainer}
                                                mediaContainer={mediaContainer} 
                                                applicationId={this.props.applicationId} 
                                                onClose={this.handleClose.bind(this)} 
                                                onFinish={this.handleFinish.bind(this)}
                                                onChange={this.handleChange.bind(this)}
                                                audioItem={this.props.audioItem}
                                                videoItem={this.props.videoItem}
                                                imageItem={this.props.imageItem}
                                                displayTextOverride={this.props.displayTextOverride}
                                                displayTitleOverride={this.props.displayTitleOverride}
                                                mediaResponseContainerId={this.props.mediaResponseContainerId}
                                                container={this.props.container}
                                                backgroundImageItem={this.props.backgroundImageItem} />
                                        </TabPanelBody>

                                    </PageContainer>
                                </CustomScrollbars>
                                <PageError errors={mediaResponseContainer.state.errors}/>
                            </FullScreenLayout>
                        );
                    }}
                </Subscribe>
            </ModalLayout>
        )
    }
}

const scrollBarContainer = css`
    height: 100%;
`;

export default MediaResponseManager;