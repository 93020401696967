import React from 'react';
import { Subscribe } from 'unstated';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import UserContainer from '../../state/containers/UserContainer';
import MediaContainer from '../../state/containers/MediaContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import SsmlContainer from '../../state/containers/SsmlContainer';
import Simulator from './components/Simulator';
import ExitMessageContainer from '../../state/containers/ExitMessageContainer';
import CustomRequestContainer from '../../state/containers/CustomRequestContainer';
import HelpMessageContainer from '../../state/containers/HelpMessageContainer';
import FallbackMessageContainer from '../../state/containers/FallbackMessageContainer';
import WelcomeMessageContainer from '../../state/containers/WelcomeMessageContainer';
import QuestionAnswerContainer from '../../state/containers/QuestionAnswerContainer';
import LatestMessageContainer from '../../state/containers/LatestMessageContainer';
import SimpleChoiceContainer from '../../state/containers/SimpleChoiceContainer';
import NumberRangeContainer from '../../state/containers/NumberRangeContainer';
import {
    WelcomeMessagesFeatureTypeId, ExitMessagesFeatureTypeId, HelpMessagesFeatureTypeId, QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, FallbackFeatureTypeId,
    SimpleChoiceFeatureTypeId, NumberRangeFeatureTypeId, CustomRequestsFeatureTypeId
} from '../../constants/featureTypeIds';
import UserApplicationPreferencesContainer from '../../state/containers/UserApplicationPreferencesContainer';

interface ApplicationMembersProps {
    history: any
    applicationId: string
}

const ApplicationSimulator: React.FC<ApplicationMembersProps> = ({ applicationId }) => (
    <Subscribe to={[ApplicationContainer, UserContainer, UserApplicationPreferencesContainer, MediaContainer, MediaResponseStateContainer,
        SsmlContainer, CustomRequestContainer, ExitMessageContainer, FallbackMessageContainer, HelpMessageContainer,
        LatestMessageContainer, SimpleChoiceContainer, NumberRangeContainer, QuestionAnswerContainer, WelcomeMessageContainer]}>
        {(
            appContainer: ApplicationContainer,
            userContainer: UserContainer,
            userApplicationPreferencesContainer: UserApplicationPreferencesContainer,
            mediaContainer: MediaContainer,
            mediaResponseContainer: MediaResponseStateContainer,
            ssmlContainer: SsmlContainer,
            customRequestContainer: CustomRequestContainer,
            exitContainer: ExitMessageContainer,
            fallbackContainer: FallbackMessageContainer,
            helpContainer: HelpMessageContainer,
            latestMessageContainer: LatestMessageContainer,
            simpleChoiceContainer: SimpleChoiceContainer,
            numberRangeContainer: NumberRangeContainer,
            questionAnswerContainer: QuestionAnswerContainer,
            welcomeContainer: WelcomeMessageContainer
        ) => {
            const contentContainers = {};
            contentContainers[CustomRequestsFeatureTypeId] = customRequestContainer;
            contentContainers[ExitMessagesFeatureTypeId] = exitContainer;
            contentContainers[FallbackFeatureTypeId] = fallbackContainer;
            contentContainers[HelpMessagesFeatureTypeId] = helpContainer;
            contentContainers[LatestMessagesFeatureTypeId] = latestMessageContainer;
            contentContainers[SimpleChoiceFeatureTypeId] = simpleChoiceContainer;
            contentContainers[NumberRangeFeatureTypeId] = numberRangeContainer;
            contentContainers[QuestionAnswerFeatureTypeId] = questionAnswerContainer;
            contentContainers[WelcomeMessagesFeatureTypeId] = welcomeContainer;

            return (
                <Simulator
                    appContainer={appContainer}
                    userContainer={userContainer}
                    userApplicationPreferencesContainer={userApplicationPreferencesContainer}
                    mediaContainer={mediaContainer}
                    mediaResponseContainer={mediaResponseContainer}
                    ssmlContainer={ssmlContainer}
                    applicationId={applicationId}
                    contentContainers={contentContainers}
                />
            );
        }}
    </Subscribe>
);

export default ApplicationSimulator;
