import React from 'react';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import GoogleMobileScreenWrapper from './GoogleMobileScreenWrapper';
import DOMPurify from 'dompurify';

class GoogleMobileSimpleCardPreview extends ScreenPreviewComponent {

    render() {
        return (
            <GoogleMobileScreenWrapper className=''>
                <div className="body-container">

                    {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}

                    <p className="body-text">
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.displayText) }}></span>

                        {this.state.followUpText ?
                            <>
                                <br /><br />
                                <span className="followup-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.followUpText) }}></span>
                            </>
                            : null}
                    </p>
                </div>
                <div className="chip-container">
                    {this.state.hints ? this.state.hints
                        .filter(h => h.content.length <= 20)
                        .map((h, i) => <span key={i} className="hint-chip">{h.content}</span>)
                        : null}
                </div>
            </GoogleMobileScreenWrapper>
        )
    }
}

export default GoogleMobileSimpleCardPreview;