import React from 'react';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import ModuleCard from './ModuleCard';
import styled from '@emotion/styled';
import { color_colors_ocean } from '../../../constants/colors';
import EmptyView from './EmptyView';
import ModuleListItem from './ModuleListItem';
import SecondaryLoader from '../../../components/general/SecondaryLoader';

interface OwnedTemplatesProps {
    templateConfigurationContainer: TemplateConfigurationContainer
    applicationId: string
    organizationTemplates: TemplateConfigurationModel[]
}

const OwnedTemplates: React.FC<OwnedTemplatesProps> = ({
    templateConfigurationContainer,
    applicationId,
    organizationTemplates
}) => {

    return (
        <InnerPageContainer>
            <PageHeader>Organization Modules</PageHeader>
            <PageDetail>These are the modules owned by this organization</PageDetail>
            {templateConfigurationContainer.state.isLoading
                && <LoaderContainer>
                    <SecondaryLoader />
                </LoaderContainer>
            }
            {templateConfigurationContainer.state.defaultViewType == "table"
                ? <ListContainer>
                    {organizationTemplates?.map(t => (
                        <li key={t.id}><ModuleListItem templateConfiguration={t} key={t.id} applicationId={applicationId} /></li>
                    ))}
                </ListContainer>
                : <CardContainer>
                    {organizationTemplates?.map(t => (
                        <li key={t.id}><ModuleCard templateConfiguration={t} key={t.id} applicationId={applicationId} /></li>
                    ))}
                </CardContainer>
            }
            {organizationTemplates?.length < 1 && !templateConfigurationContainer.state.isLoading &&
                <EmptyView title="It's quiet in here!"
                    description="This is where you'll see the modules you've created in this organization. Try creating a template app or integration module!"
                    actionLink={`/v/modules`}
                    actionText="Build Modules" />}
        </InnerPageContainer>
    )

};

const InnerPageContainer = styled.div(`
    padding-bottom: 128px;
`);

const CardContainer = styled.ul(`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
`);

const ListContainer = styled.ul(`
    list-style-type: none;
`);

const LoaderContainer = styled.div(`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
    .spinner > div {
        background-color: ${color_colors_ocean};
    }
`);

const PageHeader = styled.h3(`
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 32px 0 24px 32px;
`);

const PageDetail = styled.p(`
    margin: auto 32px;
`);

export default OwnedTemplates;