import React from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import CurrentContentSelector from './components/CurrentContentSelector';
import ApplicationContentTree from '../applicationContentMenuPanel/components/ApplicationContentTree';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { Subscribe } from 'unstated';
import ContentTreeStateContainer from '../../state/containers/ContentTreeStateContainer';
import { color_shades_dark, text_blue } from '../../constants/colors';
import SlideInSlideOutComponent from '../../components/structure/SlideInSlideOutComponent';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ApplicationBackupVersionsContainer from '../../state/containers/ApplicationBackupVersionsContainer';

interface ApplicationContentMenuSelectorProps {
    application: ApplicationModel
    featureTypeId: string
    history: any
}
interface ApplicationContentMenuSelectorState {
    isExpanded: boolean
}
class ApplicationContentMenuSelector extends React.Component<ApplicationContentMenuSelectorProps, ApplicationContentMenuSelectorState> {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }
    toggle() {
        this.setState({
            ...this.state,
            isExpanded: !this.state.isExpanded
        })
    }
    render() {
        return (
            <Subscribe to={[ApplicationContainer, ContentTreeStateContainer, FeatureContainer, ApplicationBackupVersionsContainer]}>
                {(appContainer: ApplicationContainer, treeContainer: ContentTreeStateContainer, featureContainer: FeatureContainer, backupsContainer: ApplicationBackupVersionsContainer) => (
                    <div className={`${containerStyle} ${this.state.isExpanded ? 'expanded' : ''}`}>
                        <CurrentContentSelector
                            onToggle={this.toggle.bind(this)}
                            featureTypeId={this.props.featureTypeId}
                            isMinified={true}
                            isOpen={this.state.isExpanded} />
                        <SlideInSlideOutComponent showChildren={this.state.isExpanded}
                            styleName="contentTreeDropdownSlide"
                            direction="down"
                            slideType="size">
                            <div className="fixed-tree-container">
                                <ApplicationContentTree
                                    backupsContainer={backupsContainer}
                                    appContainer={appContainer}
                                    featureContainer={featureContainer}
                                    treeContainer={treeContainer}
                                    application={this.props.application}
                                    history={this.props.history} />
                            </div>
                        </SlideInSlideOutComponent>
                    </div>
                )}
            </Subscribe>
        )
    }
}

const containerStyle = css`
    height: 80px;
    padding-top: 16px;
    &.expanded {        
        background: ${text_blue};
    }
    .fixed-tree-container {
        margin-top: 80px;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        position: fixed;
        max-height: calc(100% - 80px);
        width:288px;
        z-index: 3;
        background: white;
        border-right: 1px solid ${color_shades_dark};
    }
`

export default ApplicationContentMenuSelector;