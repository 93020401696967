import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import { cool_grey, pale_grey, ocean_blue, color_shades_dark, color_shades_lighter, color_shades_darkest } from '../../../constants/colors';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import { getFeatureTypeIcon, getDefaultFeatureTypeName } from '../../../models/extensions';
import GroupedConversationItem from '../../../models/features/GroupedConversationItem';
import ConversationItemSelector from './ConversationItemSelector';
const parentIcon = require('../../../content/images/parent-to-current.svg');

interface ConversationItemSelectorProps {
    onSelect: (conversationItem: GenericContentItemModel) => void
    onClose: () => void
    conversationItems?: GroupedConversationItem[]
    title: string
    description: string
    isSaving: boolean
}

interface ConversationItemSelectorState {
    expandedGroups: GenericContentItemModel[]
}
class GroupedConversationItemSelector extends React.Component<ConversationItemSelectorProps, ConversationItemSelectorState> {
    constructor(props) {
        super(props)
        this.state = {
            expandedGroups: this.props.conversationItems.map(g => g.parent)
        };
    }
    componentDidUpdate(prevProps: ConversationItemSelectorProps) {
        if(prevProps.conversationItems != this.props.conversationItems) {
            this.setState({
                ...this.state,
                expandedGroups: this.props.conversationItems.map(g => g.parent)
            })

        }
    }
    isGroupExpanded(parent: GenericContentItemModel) {
        return this.state.expandedGroups.some(c => c.id == parent.id);
    }
    handleToggleGroup(parent: GenericContentItemModel) {
        const expandedGroups = this.state.expandedGroups;
        if (this.isGroupExpanded(parent)) {
            expandedGroups.splice(expandedGroups.indexOf(parent), 1);
        }
        else {
            expandedGroups.push(parent);
        }

        this.setState({
            ...this.state,
            expandedGroups
        })

    }
    render() {
        const props = this.props;
        return (
            <div className={containerStyle}>
                {props.conversationItems && props.conversationItems.length > 0 ? props.conversationItems.filter(c => c.children.length > 0).map((g, gi) =>
                    (<div className='conversation-group' key={gi}>
                        <div className='group-header' onClick={this.handleToggleGroup.bind(this, g.parent)}>
                            <div className="triangle-container">
                                <div className={this.isGroupExpanded(g.parent) ? "triangle-down" : "triangle-right"} />
                            </div>
                            <p className="content-title">{g.parent.title}</p>
                        </div>
                        {this.isGroupExpanded(g.parent) &&
                            <div className="group-items">
                                <ConversationItemSelector conversationItems={g.children} onSelect={props.onSelect} isSaving={props.isSaving} />
                            </div>
                        }
                    </div>)
                ) : <p className="empty-label">No conversation items here</p>}
            </div>
        );
    }
}

const containerStyle = css`
    background: ${color_shades_lighter};
    padding: 16px 0;
    .triangle-container {
        width: 3px;
        height: 3px;
    }
    .triangle-down {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid ${color_shades_darkest};
    }
    .triangle-right {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid ${color_shades_darkest};
    }
    .empty-label {
        margin: 0 12px;
        background: white;
        font-size: 12px;
        border: 1px solid ${color_shades_dark};
        padding: 16px;
        border-radius: 8px;
    }
    .group-header {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 16px;
        >p {
            margin: 0 8px;
        }
    }
`

export default GroupedConversationItemSelector