import React, { useEffect, useState } from "react";
import RadioGroup, { RadioOption } from "../../../components/forms/RadioGroup";
import Button from "../../../components/general/Button";
import PageError from "../../../components/general/PageError";
import styled from '@emotion/styled';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import ApplicationFulfillmentEndpoints from '../../../models/applications/api/ApplicationFulfillmentEndpoints';
import DeploymentWorkflowStep from './DeploymentWorkflowStep';
import Loader from '../../../components/general/Loader';
import ModalLayout from "../../../components/structure/ModalLayout";
import PageContainer from "../../../components/structure/PageContainer";
import HorizontalContainer from "../../../components/structure/HorizontalContainer";
import ModalTitle from "../../../components/general/ModalTitle";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import FooterBar from "../../../components/structure/FooterBar";
import SelectField from "../../../components/forms/SelectField";
import { css } from '@emotion/css';
import TextField from "../../../components/forms/TextField";
import { AlternateButton, LoginImage, CardContainer, CopyButton, DeploymentsCard, DeploymentSelector, DeploymentStep, Endpoints, EndpointsHeader, Filler, HelperText, LabelAndButtonRow, LabeledText, LinkedAccount, LogoEndpointsHeader, MainButton, StepHeader, StyledLink, WorkflowStepContainer, PublishStatusRow, StepNumber, TopTriangleStyle, Logo } from "./SharedStyledComponents";
import SkillCertificationSummary from "../../../models/deployments/api/SkillCertificationSummary";
import { LinkedApplicationStatus } from "./DeploymentsTab";
import AmazonAuthUrlRequest from "../../../models/deployments/api/AmazonAuthUrlRequest";
import AmazonDeploymentContainer, { DeploymentSelectionType } from "../../../hooks/AmazonDeploymentContainer";
import DeploymentsContainer from "../../../hooks/DeploymentsContainer";

const amazonLogo = require("../../../content/images/platforms/amazon-alexa-logo.png");
const amazonButton = require('../../../content/images/platforms/amazon-button.png');
const linkOutIcon = require("../../../content/images/link-out-white.svg");
const closeIcon = require('../../../content/images/close_icon.svg');
const endpointButtonIconActive = require('../../../content/images/application-settings/deployments/endpoint-button-icon-active.svg')
const endpointButtonIconInactive = require('../../../content/images/application-settings/deployments/endpoint-button-icon-inactive.svg')

interface AmazonDeploymentProps {
    organizationId: string;
    applicationId: string;
    environmentId: string;
    appContainer: ApplicationContainer;
    endpoints: ApplicationFulfillmentEndpoints;
    applicationStatusIcon: string;
    applicationStatus: LinkedApplicationStatus;
    certificationSummary: SkillCertificationSummary;
    launchMessage: string;
}

const AmazonDeploymentCard = (props: AmazonDeploymentProps) => {

    const amazonContainer = AmazonDeploymentContainer.useContainer();
    const deploymentsContainer = DeploymentsContainer.useContainer();
    
    const [loaded, setLoaded] = useState(false);
    const [showEndpoints, setShowEndpoints] = useState(false);
    const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
    const [currentVendorId, setCurrentVendorId] = useState(null as string);
    const [vendorLoading, setVendorLoading] = useState(false);
    const [showVendorModal, setShowVendorModal] = useState(false);
    const [workflowStep, setWorkflowStep] = useState(1);

    useEffect(() => {
        const loadAsync = async () => {
            setLoaded(false);
            // load Amazon deployment stuff per environment
            await amazonContainer.loadEnvironmentDeploymentInformation(props.applicationId, props.environmentId, props.organizationId);
            setLoaded(true);
        };

        loadAsync();
        
        const cleanUp = () => {
            amazonContainer.reset();
        }
        // When exactly does React clean up an effect? 
        // React performs the cleanup when the component unmounts.  
        // However, effects run for every render and not just once. 
        // This is why React also cleans up effects from the previous render before running the effects next time. 
        return cleanUp;
    }, [props.applicationId, props.environmentId, props.organizationId]); 
    
    useEffect(() => {
        switch (amazonContainer.status) {
            case ("linkingAccount"):
                setWorkflowStep(1);
                break;
            case ("selectingVendor"):
                setWorkflowStep(1);
                break;
            case ("linkingSkill"):
                setWorkflowStep(2);
                break;
            case ("launch"):
                setWorkflowStep(3);
                break;
        }
    }, [amazonContainer.status]);


    useEffect(() => {
        if (amazonContainer.vendors?.length != 0) {
            if (amazonContainer.status == "selectingVendor") {
                if (amazonContainer.vendors?.length == 1) {
                    const vendorId = amazonContainer.vendors[0].id;
                    linkVendor(vendorId);
                }
                else {
                    setShowVendorModal(true)
                }
            }
        }
    }, [amazonContainer.status]); 

    const handleDisconnectSkill = async () => {
        await setLoaded(false);
        await amazonContainer.unlinkEnvironmentSkill(props.applicationId, props.environmentId);
        await amazonContainer.setLinkingStatus("linkingSkill");
        await amazonContainer.setAccountNewOrExisting("new");
        await deploymentsContainer.loadInteractionModelEnvironmentStatus(props.applicationId, props.environmentId);
        await deploymentsContainer.stopWaitingForAlexaDeployment();
        await setLoaded(true);
    }

    const handleDisconnectAccount = async () => {
        await setLoaded(false);
        await amazonContainer.unlinkEnvironmentUserAccount(props.applicationId, props.environmentId);
        await amazonContainer.unlinkEnvironmentVendor(props.applicationId, props.environmentId);
        await amazonContainer.setVendorId(null);
        await setCurrentVendorId(null);
        await amazonContainer.setLinkingStatus("linkingAccount");
        await amazonContainer.getEnvironmentDeploymentStatus(props.applicationId, props.environmentId);
        await deploymentsContainer.loadInteractionModelEnvironmentStatus(props.applicationId, props.environmentId);
        await deploymentsContainer.stopWaitingForAlexaDeployment();
        await setLoaded(true);
    }

    const handleChangeVendor = async () => {
        await setCurrentVendorId(null);
        await amazonContainer.setLinkingStatus("selectingVendor");
        await setShowVendorModal(true);
    }

    const handleSelectionChange = (option: RadioOption) => {
        amazonContainer.setAccountNewOrExisting(option.value as DeploymentSelectionType)
    }

    const handleAccountSelectionChange = (newValue) => {
        if (!newValue) return;

        amazonContainer.setAccountNewOrExisting("existing").then(() =>
            amazonContainer.setAccountToLink(newValue.value, newValue.label))
    }

    const isMainButtonDisabled = () => {
        if (workflowStep == 1 && !amazonContainer.tokenId)
            return true;
        if (amazonContainer.status == "selectingVendor")
            return true;
        if (workflowStep == 2 && amazonContainer.createNew == "existing" && !amazonContainer.linkedProjectId)
            return true;
        return false;
    }

    const renderMainButton = () => {
        if (workflowStep == 1) {
            if (amazonContainer.accountSelection == "new") {
                return <LoginImage className="ac-amazon-login-button" src={amazonButton} onClick={linkNewAccount} />
            }
        } else if (workflowStep == 3) {
            return <a href="https://developer.amazon.com/alexa/console/ask?#" target="_blank">
                <MainButton
                    className="ac-amazon-developer-console"
                    loading={primaryButtonLoading}
                    disabled={primaryButtonLoading}
                    themes={["primary", "wide"]}
                    text="Developer Console"
                    type="button"
                    rightIcon={linkOutIcon}
                    onClick={handleMainButton} />
            </a>
        }
        return <MainButton
            className="ac-amazon-link-button"
            loading={primaryButtonLoading}
            disabled={primaryButtonLoading || isMainButtonDisabled()}
            themes={["primary", "wide"]}
            text="Link"
            type="button"
            onClick={handleMainButton} />
    }

    const handleMainButton = async () => {
        setPrimaryButtonLoading(true);
        switch (amazonContainer.status) {
            case "linkingAccount": await handleAccountLink(); break;
            case "linkingSkill": await handleSkillLink(); break;
        }
        setPrimaryButtonLoading(false);
    }

    const handleAccountLink = async () => {
        switch (amazonContainer.accountSelection) {
            case "new": await linkNewAccount(); break;
            case "existing": await linkExistingAccount(); break;
        }
    }

    const linkExistingAccount = async () => {
        if (!amazonContainer.tokenId) return;
        //Clear skill linkedProjectId
        await amazonContainer.setDeployToApp("");

        const linkTokenResult = await amazonContainer.linkTokenToEnvironment(props.applicationId, props.environmentId, amazonContainer.tokenId);

        if (linkTokenResult && linkTokenResult.data) {
            const vendorsResult = await amazonContainer.getEnvironmentVendors(props.applicationId, props.environmentId);
            //Auto select a vendor if there is only one
            if (vendorsResult && vendorsResult.data) {
                await amazonContainer.setLinkingStatus("selectingVendor");
            }
        }
    };

    const linkVendor = async (vendorId: string) => {
        await setVendorLoading(true);
        await amazonContainer.linkEnvironmentVendor(props.applicationId, props.environmentId, vendorId);
        await amazonContainer.setLinkingStatus("linkingSkill");
        await amazonContainer.getSkills(amazonContainer.vendorId, amazonContainer.tokenId);
        await setVendorLoading(false);
        setShowVendorModal(false);
    }

    const handleSkillLink = async () => {
        if (!amazonContainer.linkedProjectId && amazonContainer.createNew !== "new") return;

        await amazonContainer.setLinkingStatus("launch")
        if (amazonContainer.createNew == "new")
            await amazonContainer.createAndDeployEnvironmentNewSkill(props.applicationId, props.environmentId);
        else
            await amazonContainer.linkEnvironment(props.applicationId, props.environmentId, amazonContainer.linkedProjectId);

        await deploymentsContainer.waitForEnvironmentDeployment(props.applicationId, props.environmentId, true, false, false);
    }

    const handleDeployToAppSelectionChange = async (option: RadioOption) => {
        //Set selected skill linkedProjectId
        await option && amazonContainer.setDeployToApp(option.value as string);
    }

    const handleDeployToAppTextFieldChange = async (fieldValue: string) => {
        //Set specified skill linkedProjectId
        await fieldValue && amazonContainer.setDeployToApp(fieldValue)
    }

    const handleCreateNewSkillSelectionChange = async (newValue) => {
        if (!newValue) return;
        //Clear skill linkedProjectId first
        await amazonContainer.setDeployToApp("");
        await amazonContainer.setCreateNewSkill(newValue.value);
    }

    const linkNewAccount = async () => {
        //openAuthWindow
        const request: AmazonAuthUrlRequest = { 
            applicationId: props.applicationId, 
            environmentId: props.environmentId };
        const urlResult = await amazonContainer.getAuthUrl(request);
        if (urlResult) window.open(urlResult.data, "_self")
    }

    const handleVendorSelectionChange = async (newValue) => {
        if (!newValue?.value) return;
        await amazonContainer.setVendorId(newValue.value);
    }

    const handleSelectVendor = async () => {
        const vendorId = amazonContainer.vendorId ?? amazonContainer?.vendors[0]?.id;
        await amazonContainer.unlinkEnvironmentVendor(props.applicationId, props.environmentId);
        await linkVendor(vendorId);
        await setCurrentVendorId(vendorId);
        await amazonContainer.setLinkingStatus("linkingSkill");
    }

    const handleCloseVendorModal = async () => {
        if (currentVendorId) {
            await amazonContainer.setVendorId(currentVendorId);
        }
        await setShowVendorModal(false);
    }

    const vendorName = amazonContainer.vendors?.length ?
        amazonContainer.vendors
            .filter(a => a.id == amazonContainer.vendorId).map(v => v.name)[0]
        : null;

    const appName = amazonContainer.availableApps?.length ?
        amazonContainer.availableApps
            .filter(a => a.id == amazonContainer.linkedProjectId).map(a => a.name)[0]
        : null;

    let appNameOrPlaceholderText = appName;

    if (!appNameOrPlaceholderText) {
        if (amazonContainer.linkedProjectId) {
            appNameOrPlaceholderText = amazonContainer.linkedProjectId;
        } else if (amazonContainer.loadingAvailableApps) {
            appNameOrPlaceholderText = "Loading ...";
        } else {
            appNameOrPlaceholderText = "[deleted]";
        }
    }
    
    const renderStep1 = () => {
        if (amazonContainer.status == "linkingAccount") {
            return <DeploymentStep>
                <StepHeader>
                    <StepNumber className="ac-amazon-step-1">1</StepNumber>
                    <div>Connect Account</div>
                </StepHeader>
                <RadioGroup options={[
                    { value: "new", label: "Connect a new account" },
                    { value: "existing", label: "Connect an existing account" }]}
                    onChange={handleSelectionChange}
                    value={amazonContainer.accountSelection || (amazonContainer.userName ? "existing" : "new")} />
                <DeploymentSelector
                    useFormTheme={true}
                    useForm={false}
                    options={amazonContainer.availableCredentials.map(c => ({ label: c.userName, value: c.id }))}
                    disabled={amazonContainer.accountSelection === "new"}
                    value={amazonContainer.tokenId} onBlur={() => { }}
                    onChange={handleAccountSelectionChange}
                    label="Account"
                    name="existingDeploymentAccount" />
            </DeploymentStep>

        }
        else if (amazonContainer.status == "selectingVendor") {
            return <LinkedAccount>
                <StepHeader>
                    <StepNumber>1</StepNumber>
                    <div>Connect Account</div>
                </StepHeader>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Account</span>
                        <p>{amazonContainer.userName}</p>
                    </LabeledText>
                    <Filler />
                    <AlternateButton themes={["secondary-small"]} text="Disconnect" onClick={handleDisconnectAccount} />
                </LabelAndButtonRow>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Vendor</span>
                        <p>{currentVendorId ? 
                        amazonContainer.vendors.filter(a => a.id == currentVendorId).map(v => v.name)[0] 
                        : "Select a vendor"}</p>
                    </LabeledText>
                    <Filler />
                    {amazonContainer.vendors.length > 1 && <AlternateButton themes={["secondary-small"]} text="Change" onClick={handleChangeVendor} />}
                </LabelAndButtonRow>
            </LinkedAccount>
        }
        else {
            return <LinkedAccount>
                <StepHeader>
                    <StepNumber>1</StepNumber>
                    <div>Connect Account</div>
                </StepHeader>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Account</span>
                        <p>{amazonContainer.userName}</p>
                    </LabeledText>
                    <Filler />
                    <AlternateButton themes={["secondary-small"]} text="Disconnect" onClick={handleDisconnectAccount} />
                </LabelAndButtonRow>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Vendor</span>
                        <p>{vendorName}</p>
                    </LabeledText>
                    <Filler />
                    {amazonContainer.vendors.length > 1 && <AlternateButton themes={["secondary-small"]} text="Change" onClick={handleChangeVendor} />}
                </LabelAndButtonRow>
            </LinkedAccount>
        }
    }

    const renderStep2 = () => {
        if (amazonContainer.status == "linkingSkill") {
            return <DeploymentStep>
                <StepHeader>
                    <StepNumber className="ac-amazon-step-2">2</StepNumber>
                    <div>Link Skill</div>
                </StepHeader>
                <RadioGroup options={[
                    { value: "new", label: "Create a new skill" },
                    { value: "existing", label: "Connect to an existing skill" }]}
                    onChange={handleCreateNewSkillSelectionChange.bind(this)}
                    value={amazonContainer.createNew || "new"} />
                {
                    // we stop loading if we get 100 alexa skills to avoid throttling.
                    // If they have less than 100 we let them choose otherwise we ask for a pasted skill ID.
                    (amazonContainer.availableApps && amazonContainer.availableApps.length < 100) ?
                        <DeploymentSelector
                            useFormTheme={true}
                            useForm={false}
                            options={amazonContainer.availableApps.map(a => ({ value: a.id, label: a.name }))}
                            disabled={amazonContainer.createNew == "new"}
                            value={amazonContainer.linkedProjectId} onBlur={() => { }}
                            onChange={handleDeployToAppSelectionChange.bind(this)}
                            name="deployToApp"
                            label="Skill"
                        />
                        :
                        <TextField
                            useForm={false}
                            label="Skill ID"
                            disabled={amazonContainer.createNew == "new"}
                            placeholder="Paste your skill ID here"
                            value={amazonContainer.linkedProjectId}
                            onChange={(e) => handleDeployToAppTextFieldChange(e.target.value)} />
                }
            </DeploymentStep>
        } else {
            return <DeploymentStep>
                <StepHeader>
                    <StepNumber>2</StepNumber>
                    <div>Link Skill</div>
                </StepHeader>
                <LabelAndButtonRow>
                    <LabeledText>
                        <span>Skill</span>
                        <p>{appNameOrPlaceholderText}</p>
                    </LabeledText>
                    <Filler />
                    <AlternateButton themes={["secondary-small"]} text="Disconnect" onClick={handleDisconnectSkill} />
                </LabelAndButtonRow>
            </DeploymentStep>
        }
    }

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    const getApplicationStatusText = () => {
        switch (props.applicationStatus) {
            case ("WAITING"):
                return "Please wait...";
            case ("FAILED_REVIEW"):
                return "Failed skill review";
            case ("DEPLOYMENT_ISSUE"):
                return "There was an problem deploying your application to Alexa";
            case ("PENDING_REVIEW"):
                return "Pending skill review and approval";
            case ("LIVE"):
                return "Live";
            case ("LIVE_PENDING_REVIEW"):
                return "Skill update pending review";
            case ("LIVE_FAILED_REVIEW"):
                return "Skill update failed review";
            case ("READY"):
                return "Ready";
            default:
                return "Unknown";
        }
    }

    const getAdditionalStatusText = () => {
        switch (props.applicationStatus) {
            case ("WAITING"):
                return "Your Voicify application is syncing with your Alexa skill";
            case ("FAILED_REVIEW"):
                return "Your skill failed review. Check your email for certification feedback from the Alexa team. ";
            case ("PENDING_REVIEW"):
                return "Your skill has been submitted to Amazon for review. Once this process is completed, your skill will either appear in the skill store or you’ll be notified about issues that need to be resolved.";
            case ("LIVE"):
                return "";
            case ("LIVE_PENDING_REVIEW"):
                return "Your skill is live but the updated version is under review.";
            case ("LIVE_FAILED_REVIEW"):
                return "Your skill is live but the updated version failed review review. Check your email for certification feedback from the Alexa team.";
            case ("READY"):
                return "Everything seems good to go! Submit your skill for review in the Alexa Console and, if it’s approved, it will be published to this channel.";
            default:
                return "";
        }
    }


    const renderStep3 = () => {
        const additionalStatusText = getAdditionalStatusText();
        return <DeploymentStep>
            <StepHeader>
                <StepNumber>3</StepNumber>
                <div>Launch</div>
            </StepHeader>
            <LabelAndButtonRow>
                <LabeledText>
                    <span>Application Status</span>
                    <PublishStatusRow>
                        <a href="https://developer.amazon.com/alexa/console/ask?#" target="_blank">
                            <img className="ac-amazon-status" src={props.applicationStatusIcon} />
                        </a>
                        <p>{getApplicationStatusText()}</p>
                    </PublishStatusRow>
                </LabeledText>
                <Filler />
                <StyledLink target="_blank" href="https://support.voicify.com/knowledge/getting-started-with-voicify-part-4-deploying-to-alexa-and-google">View our launch docs</StyledLink>
            </LabelAndButtonRow>
            {(props.launchMessage || additionalStatusText) &&
                <LabeledText>
                    <p>{props.launchMessage ?? additionalStatusText}</p>
                </LabeledText>
            }
        </DeploymentStep>
    }

    return (
        <CardContainer>
            <DeploymentsCard id="alexa-card">
                <LogoEndpointsHeader>
                    <Logo src={amazonLogo} />
                    <Button
                        themes={['secondary-small']}
                        disabled={props.endpoints?.alexaEndpoint == null}
                        text={showEndpoints ? "Hide Endpoints" : "Show Endpoints"}
                        onClick={() => setShowEndpoints(!showEndpoints)}
                        type="button"
                        rightIcon={showEndpoints ? endpointButtonIconActive : endpointButtonIconInactive}
                        className={showEndpoints ? `button-active` : null} />
                </LogoEndpointsHeader>
                {
                    showEndpoints &&
                    <>
                        <TopTriangleStyle />
                        <Endpoints>
                            <EndpointsHeader>Fulfillment Endpoints</EndpointsHeader>
                            <HorizontalContainer>
                                <TextField
                                    useForm={false}
                                    value={props.endpoints?.alexaEndpoint}
                                    onChange={null}
                                    readOnly
                                    label="Direct Fulfillment" />
                                <CopyButton themes={["secondary"]} text="Copy" type="button" 
                                    onClick={() => copyText(props.endpoints?.alexaEndpoint)} />
                            </HorizontalContainer>
                            <HorizontalContainer>
                                <TextField
                                    useForm={false}
                                    value={props.endpoints?.alexaProxyEndpoint}
                                    onChange={null}
                                    readOnly
                                    label="Custom Fulfillment" />
                                <CopyButton themes={["secondary"]} text="Copy" type="button" 
                                    onClick={() => copyText(props.endpoints?.alexaProxyEndpoint)} />
                            </HorizontalContainer>
                        </Endpoints>
                    </>
                }
                <HelperText>
                    <p>Let’s get you ready to deploy to Alexa! <br /> <br /></p>
                    <p>This process will get your account on Voicify synced with your Amazon Developer account and apply your settings to a specific Skill. </p>
                    <StyledLink target="_blank" href="https://support.voicify.com/en/knowledge/alexa-deployment">Read More </StyledLink>
                </HelperText>
                <WorkflowStepContainer>
                    <DeploymentWorkflowStep
                        name="Connect Account"
                        currentStep={workflowStep}
                        step={1} />
                    <DeploymentWorkflowStep
                        name="Link Skill"
                        currentStep={workflowStep}
                        step={2} />
                    <DeploymentWorkflowStep
                        name="Launch"
                        currentStep={workflowStep}
                        step={3} />
                    <DeploymentWorkflowStep
                        last
                        icon={props.applicationStatusIcon}
                        currentStep={workflowStep}
                        step={4} 
                        href="https://developer.amazon.com/alexa/console/ask?#"
                    />
                </WorkflowStepContainer>
                {!loaded && <Loader className={customLoaderStyle} />}
                {
                    loaded &&
                    <>
                        {(workflowStep >= 1 && renderStep1())}
                        {(workflowStep >= 2 && renderStep2())}
                        {(workflowStep >= 3 && renderStep3())}
                        {renderMainButton()}
                    </>
                }
            </DeploymentsCard>
            <PageError 
                errors={amazonContainer.errors} 
                onClear={amazonContainer.clearErrors} />
            <VendorModal isVisible={showVendorModal} >
                <HorizontalContainer>
                    <ModalTitle padded>Choose Vendor</ModalTitle>
                    <Button themes={['end-tight', 'icon']} icon={closeIcon} 
                        onClick={handleCloseVendorModal} />
                </HorizontalContainer>
                <HorizontalSeparator />
                <PageContainer>
                    <VendorSelector
                        options={amazonContainer.vendors.map(a => ({ value: a.id, label: a.name }))}
                        value={amazonContainer.vendorId ?? amazonContainer?.vendors[0]?.id}
                        onBlur={() => { }}
                        clearable={false}
                        onChange={handleVendorSelectionChange}
                        label="Vendor"
                        useFormTheme={true}
                        useForm={false}
                        name="vendorId" />
                </PageContainer>
                <FooterBar>
                    <MainButton 
                        loading={vendorLoading} 
                        disabled={vendorLoading} 
                        themes={["primary"]} 
                        text="Select" 
                        type="button" 
                        onClick={handleSelectVendor} />
                </FooterBar>
            </VendorModal>
        </CardContainer>
    );
}

const VendorModal = styled(ModalLayout)`
    width: 40%;
    height: 30%;
    min-height: 500px;
    min-width: 600px;
`;

const VendorSelector = styled(SelectField)`
    margin: 32px;
`;

const customLoaderStyle = css`
    position: relative;
    top: auto;
    margin-top: 30px;
    margin-bottom: 30px;    
`;

export default AmazonDeploymentCard;
