import React from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import MenuCollapseToggle from '../../../components/structure/MenuCollapseToggle';
import ContentTreeStateContainer from '../../../state/containers/ContentTreeStateContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationContentMenuContent from './ApplicationContentMenuContent';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';

interface ApplicationContentMenuProps {
    application: ApplicationModel
    appContainer: ApplicationContainer
    featureContainer: FeatureContainer
    backupsContainer: ApplicationBackupVersionsContainer
    treeContainer: ContentTreeStateContainer
    onCollapse: (e) => void
    history: any
}
const ApplicationContentMenu: React.FC<ApplicationContentMenuProps> = (props) => {
    return (
        <div className={containerStyle}>
            <MenuCollapseToggle isMinified={false} onToggle={props.onCollapse} />
            <ApplicationContentMenuContent 
                backupsContainer={props.backupsContainer}
                appContainer={props.appContainer}
                application={props.application}
                featureContainer={props.featureContainer}
                treeContainer={props.treeContainer}
                history={props.history} />

        </div>
    )
}
const containerStyle = css`
    .panel-header {
        max-width: 288px;
    }
    .header-container {
        position: relative;
        width: 288px;
        display: flex;
        align-items: center;
    }

`

export default ApplicationContentMenu;