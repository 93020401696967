import React from 'react';
import { css } from '@emotion/css';
import AppliedApplicationTemplateContainer from '../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import PanelContainer from '../../components/structure/Panels/PanelContainer';
import TabContainerPanel from '../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import CollapsingPanel from '../../components/structure/Panels/CollapsingPanel';
import CollapsedMenuPanel from '../../components/structure/CollapsedMenuPanel';
import ExpandedMenuPanel from '../../components/structure/ExpandedMenuPanel';
import { color_variants_main_bg, color_shades_dark, text_blue, color_shades_darkest } from '../../constants/colors';
import AppliedAppTemplateMenu from './components/AppliedAppTemplateMenu';
import { Link } from 'react-router-dom';
import TemplateConfigurationContainer from '../../state/containers/TemplateConfigurationContainer';
import TemplateListItem from './components/TemplateListItem';
import CustomScrollbars from '../../components/structure/CustomScrollbars';
import ApplicationContentMenuPanel from '../applicationContentMenuPanel';
const templatesIcon = require('../../content/images/tab-icons/templates.svg');
const templatesBlueIcon = require('../../content/images/tab-icons/templates-blue.svg');
const completeIcon = require('../../content/images/templates-icons/complete.svg');
const readyIcon = require('../../content/images/templates-icons/generate-content.svg');
const incompleteIcon = require('../../content/images/templates-icons/incomplete.svg');
const notEnabledIcon = require('../../content/images/templates-icons/not-enabled.svg');
interface AppliedAppTemplatesProps {
    applicationId: string
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    templateConfigurationContainer: TemplateConfigurationContainer
    hasFullMenu: boolean
    history: any
}
interface AppliedAppTemplatesState {
    isLeftExpanded: boolean
    selectedMenuIndex: number
}

class ApplicationAppliedTemplates extends React.Component<AppliedAppTemplatesProps, AppliedAppTemplatesState> {

    constructor(props: AppliedAppTemplatesProps) {
        super(props);

        this.state = {
            isLeftExpanded: localStorage.getItem("contentMenu") == "true",
            selectedMenuIndex: 0
        }
    }

    componentDidMount() {
        this.props.templateConfigurationContainer.getAvailableTemplates(this.props.appContainer.state.organizationId);
    }

    handleToggleExpandLeft(e, expanded: boolean) {
        this.setState({
            ...this.state,
            isLeftExpanded: expanded
        });
        localStorage.setItem("contentMenu", expanded?.toString());
        e?.stopPropagation();
    }
    setContentMenuIndex(index: number) {
        this.setState({
            ...this.state,
            selectedMenuIndex: index
        });
    }

    getStatusIcon(status) {
        if (status == "incomplete") return incompleteIcon;
        if (status == "complete") return readyIcon;
        return completeIcon;
    }
    getStatusLabel(status) {
        switch (status) {
            case "synced": return "In Use";
            case "complete": return "Ready";
            case "incomplete": return "Incomplete";
        }
    }
    renderMenu() {
        return <TabContainerPanel tabs={[
            {
                title: 'Templates',
                icon: templatesIcon,
                selectedIcon: templatesBlueIcon,
            }
        ]}
            onTabSelected={(newIndex) => { this.setContentMenuIndex(newIndex) }}
            selectedIndex={this.state.selectedMenuIndex}>
            <TabPanel>
                <ScrollablePanelBody className={panelBodyStyle}>
                    <AppliedAppTemplateMenu history={this.props.history}
                        applicationId={this.props.applicationId}
                        appContainer={this.props.appContainer}
                        appliedAppTemplateContainer={this.props.appliedAppTemplateContainer} />
                </ScrollablePanelBody>
            </TabPanel>
        </TabContainerPanel>
    }


    render() {
        const appliedTemplates = this.props.appliedAppTemplateContainer.getLoadedTemplatesForApp(this.props.applicationId);
        const availableTemplateConfigurations = this.props.templateConfigurationContainer.state.availableTemplates?.filter(tc => !appliedTemplates?.some(a => a.templateForm?.templateConfigurationId == tc.id));
        return (
            <PanelContainer className={containerStyle}>
                {this.props.hasFullMenu
                    ? <ApplicationContentMenuPanel history={this.props.history} application={this.props.appContainer.state.currentApp} />
                    : <div className={`${menuStyle} ${(this.state.isLeftExpanded ? "expanded" : "collapsed")}`}>
                        <div className={"fixed-wrapper"}>
                            <CollapsingPanel isCollapsed={!this.state.isLeftExpanded}
                                collapsedView={<CollapsedMenuPanel
                                    onExpand={(e) => this.handleToggleExpandLeft(e, true)}
                                    expandedViewRender={this.renderMenu.bind(this)} />}
                                expandedView={<ExpandedMenuPanel
                                    onCollapse={(e) => this.handleToggleExpandLeft(e, false)}
                                    expandedViewRender={this.renderMenu.bind(this)} />} />
                        </div>
                    </div>}
                <CustomScrollbars>
                    <div className={`templates-list ${this.state.isLeftExpanded ? "expanded-view" : ""}`}>
                        {appliedTemplates.length > 0 && <h3>Enabled Template</h3>}
                        {appliedTemplates?.map((at, idx) => (
                            <TemplateListItem
                                key={idx}
                                templateConfiguration={at.templateForm.templateConfiguration}
                                applied={true}
                                applicationId={this.props.applicationId}
                                icon={this.getStatusIcon(this.props.appliedAppTemplateContainer.getCurrentStatus(at))}
                                status={this.getStatusLabel(this.props.appliedAppTemplateContainer.getCurrentStatus(at))}
                                appliedAppTemplate={at} />
                        ))}
                        {availableTemplateConfigurations.length > 0 && <h3>All Available Templates</h3>}
                        {availableTemplateConfigurations?.map((tc, idx) => (
                            <TemplateListItem
                                key={idx}
                                templateConfiguration={tc}
                                applied={false}
                                icon={notEnabledIcon}
                                status="Not Enabled"
                                applicationId={this.props.applicationId} />
                        ))}
                    </div>
                </CustomScrollbars>
            </PanelContainer>
        );
    }
}

const containerStyle = css`
    width: 100%;
    background: ${color_variants_main_bg};
    .form-wrapper {
        flex: 1;
        min-width: 600px;
    }
    .empty-indicator {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .templates-list {
        padding: 32px;
        h3 {
            margin: 32px;
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: ${color_shades_darkest};
        }
    }
`

const panelBodyStyle = css`
    padding-top: 0;
    min-height: 100%;
`

const menuStyle = css`
    z-index: 2;
    bottom: 0;
    position: relative;

    &.collapsed {
        width: 80px;
        .fixed-wrapper {
            width: 80px;
        }
    }
    &.expanded {
        width: 288px; 
        .fixed-wrapper {
            width: 288px;
        }
    }

    .fixed-wrapper {
        position: fixed;
        bottom: 0;
        height: calc(100vh - 80px);
        background: white;
        border-right: 1px solid ${color_shades_dark};
        box-sizing: border-box;
    }
`

export default ApplicationAppliedTemplates;