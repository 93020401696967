import React from 'react';
import { css } from '@emotion/css';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import PositionIndicator from '../structure/ConversationFlow/PositionIndicator';
import { dark_grey_blue, dark_grey_blue_20, cool_grey, dark_sky_blue, highlight_blue } from '../../constants/colors';
import ConversationMediaResponse from '../../models/conversationFlow/ConversationMediaResponse';
import RepromptModel from '../../models/features/api/Reprompt/RepromptModel';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import SsmlContainer from '../../state/containers/SsmlContainer';
import MultiFileAudioPlayer from './MultiFileAudioPlayer';
import SsmlCacheItem from '../../models/ssml/SsmlCacheItem';
import PageError from '../general/PageError';
import AudioPlayerFileIndicator from './AudioPlayerFileIndicator';
import LanguageModel from '../../models/features/api/LanguageModel';
import DOMPurify from 'dompurify';

const openQuoteIcon = require('../../content/images/quote-open.svg');
const closeQuoteIcon = require('../../content/images/quote-close.svg');
const deviceIcon = require('../../content/images/device.svg');

interface DeviceVariationsBubbleProps {
    variations: ConversationMediaResponse[]
    title?: string
    detail?: string
    followUp?: FollowUpModel
    reprompt?: RepromptModel
    ssmlContainer: SsmlContainer
    assistant?: string
    deviceTargetId?: string
    languages?: LanguageModel[]
}
interface DeviceVariationsBubbleState {
    position: number
    audioResponses: SsmlCacheItem[]
}

class DeviceAudioVariationsBubble extends React.Component<DeviceVariationsBubbleProps, DeviceVariationsBubbleState> {

    componentWillMount() {
        this.setState({
            position: 0,
            audioResponses: []
        });
    }

    getSsmlContent(conversationResponse: ConversationMediaResponse) {
        var customResponse = conversationResponse.mediaResponseContainer
            ? conversationResponse.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTargetId && r.readoutOverride != null)
            : null;
        if (customResponse) return customResponse.readoutOverride;

        return conversationResponse.content;
    }

    buildLabel(item: ConversationMediaResponse) {
        // strip tags from ssml first
        const ssml = this.getVariationSsml(item, false);
        const stripTagRegex = /<\/?[^>]+(>|$)/g
        var displayText = ssml ?? "";
        displayText = displayText.replace(stripTagRegex, "");
        item.highlights.forEach(highlight => {
            displayText = displayText.replace(highlight.replace(stripTagRegex, ""), `<span>${highlight}</span>`);
        });

        return (
            <p className={inputStyle} key={item.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayText) }}>
            </p>
        )
    }

    private getVariationSsml(item: ConversationMediaResponse, includeFollowUp: boolean): string {
        var ssml = item.content;
        var customResponse = item.mediaResponseContainer
            ? item.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTargetId && r.readoutOverride != null)
            : null;
        if (customResponse) ssml = customResponse.readoutOverride

        if (includeFollowUp && this.props.followUp) {
            ssml += `<break time=".5s"/> ${this.props.followUp.content}`;
        }

        return ssml;
    }

    loadSsmlAudio(item: ConversationMediaResponse) {
        const innerSsml = this.getVariationSsml(item, true);
        var ssml = innerSsml.startsWith('<speak>') ? innerSsml : `<speak>${innerSsml}</speak>`;
        var locale = (this.props.languages == null || this.props.languages.length < 1) ? "" : this.props.languages[0].shortCode;

        if (this.state.audioResponses.some(a => a.ssml == ssml && a.assistant == this.props.assistant))
            return;
        var cachedAudio = this.props.ssmlContainer.getCachedSsmlSpeech(ssml, this.props.assistant, locale);
        if (cachedAudio) {
            var responses = this.state.audioResponses;
            responses.push({ ssml: ssml, assistant: this.props.assistant, outputAudioFiles: cachedAudio, locale: locale });
            this.setState({
                ...this.state,
                audioResponses: responses
            })
        }
        else {
            this.props.ssmlContainer.getSpeechOutput(ssml, this.props.assistant, locale).then(result => {
                if (result.resultType == "Ok") {
                    var responses = this.state.audioResponses;
                    responses.push({ ssml: ssml, assistant: this.props.assistant, outputAudioFiles: result.data, locale: locale });
                    this.setState({
                        ...this.state,
                        audioResponses: responses
                    })
                }
            })
        }
    }

    handlePositionChange(position: number) {
        this.setState({
            ...this.state,
            position
        })
    }

    getAudioItem(variation: ConversationMediaResponse) {
        var customResponse = variation.mediaResponseContainer
            ? variation.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTargetId)
            : null;
        if (customResponse != null && customResponse.audioItem) {
            return customResponse.audioItem;
        }
        else if (variation.audioItem) {
            return variation.audioItem;
        }

        return null;
    }

    hasAudioPlayer(variation: ConversationMediaResponse) {
        var item = this.getAudioItem(variation);
        if (item == null) return false;
        return true;
    }

    getAudioSrc(variation: ConversationMediaResponse): string[] {
        var customResponse = variation.mediaResponseContainer
            ? variation.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTargetId)
            : null;
        if (customResponse != null && customResponse.audioItem) {
            return [customResponse.audioItem.url];
        }
        else if (variation.audioItem) {
            return [variation.audioItem.url]
        }

        var innerSsml = this.getVariationSsml(variation, true);
        var ssml = innerSsml.startsWith('<speak>') ? innerSsml : `<speak>${innerSsml}</speak>`;
        var audioSrc = this.state.audioResponses.find(a => a.ssml == ssml && a.assistant == this.props.assistant);
        if (!audioSrc || !audioSrc.outputAudioFiles) return null;
        return audioSrc.outputAudioFiles.map(s => s.url);
    }

    stripTags(ssml: string) {
        const stripTagRegex = /<\/?[^>]+(>|$)/g
        var displayText = ssml;
        displayText = displayText.replace(stripTagRegex, "");
        return displayText;
    }
    render() {
        const { title, variations } = this.props;
        if (!variations) return null;
        const variation = variations[this.state.position];
        const hasAudioPlayer = this.hasAudioPlayer(variation);
        return (
            <div className={containerStyle}>
                <div className={`${bubbleStyle} ac-output-message-preview`}>
                    {title && <><span className="title">{title}</span>
                        <HorizontalSeparator /></>}
                    <div className="open-quote-container">
                        <img className="open-quote" src={openQuoteIcon} />
                        {hasAudioPlayer ? <span>(Overridden by Audio Response)</span> : null}
                    </div>
                    <div className={`content-container ${hasAudioPlayer ? 'removed' : ''}`}>
                        {variations.some(v => v.content != '') ? this.buildLabel(variation) : <p className="placeholder">Enter your response to see here</p>}
                        {this.props.followUp ?
                            <div className={followUpContainer}>
                                <HorizontalSeparator />
                                <span className={followUpDetail}>&amp; Then…</span>

                                <p className={inputStyle}><span>{this.stripTags(this.props.followUp.content)}</span></p>
                            </div> : null}

                    </div>
                    <img className="close-quote" src={closeQuoteIcon} />
                    {hasAudioPlayer ? <AudioPlayerFileIndicator title={this.getAudioItem(variation).name} /> : null}
                    <MultiFileAudioPlayer hasFile={hasAudioPlayer} src={this.getAudioSrc(variation)} isLoading={this.props.ssmlContainer.state.isLoading} onLoad={() => this.loadSsmlAudio(variation)} />
                    {
                        variations.length > 1 ?
                            <>
                                <HorizontalSeparator />
                                <div className="carousel-container">
                                    <span>{`VARIATION: ${this.state.position + 1} of ${variations.length}`}</span>
                                    <PositionIndicator count={variations.length} position={this.state.position} onChange={this.handlePositionChange.bind(this)} />
                                </div>
                            </>
                            : null
                    }
                </div>

                <img src={deviceIcon} />
                <PageError errors={this.props.ssmlContainer.state.errors} />
            </div>
        );
    }
}

const containerStyle = css`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
    }
`
const bubbleStyle = css`
    margin: 30px 0 0 115px;
    border: solid 1px ${dark_sky_blue};
    box-shadow: -1px 4px 10px 0 ${dark_grey_blue_20};
    background: white;
    border-radius: 10px 0 10px 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media only screen and (min-width: 1240px) {
        max-width: 60%;
        margin-right: 0;
        margin-left: auto;
    }
    .content-container {
        &.removed {
            text-decoration: line-through;
        }
    }
    .title {
        font-family: Muli;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 24px;
    }

    .open-quote-container {
        display: flex;
        align-items: center;
        span {
            font-size: 12px;
            color: ${cool_grey};
            letter-spacing: 0.5px;
            margin-left: 4px;
        }
    }
    .open-quote {
        margin-bottom: 16px;
        margin-top: 16px;
    }

    .close-quote {
        margin-right: 0;
        margin-left: auto;
        margin-bottom: 16px;
        margin-top: 0;
    }

    .placeholder {
        text-align: center;
        font-style: italic;
        color: ${cool_grey};
    }
    .carousel-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 16px;
        span {
            margin-bottom: 8px;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            text-align: center;
            color: ${cool_grey};
        }
    }
`;

const inputStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    margin-top: 12px;
    span {
        background: ${highlight_blue};
    }
`;

const followUpDetail = css`
    background: white;
    margin-top: -9px;
    margin-left: 8px;
    padding: 0 4px;
    font-family: Muli;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: ${cool_grey};
    position: absolute;
`
const followUpContainer = css`
    position: relative;
    margin: 48px 0 16px 0;
    p {
        margin-top: 48px;
    }
`


export default DeviceAudioVariationsBubble;
