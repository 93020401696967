import React from 'react';
import {css} from '@emotion/css';
const imageIcon = require('../../content/images/picture_icon.svg');
const backgroundIcon = require('../../content/images/background_icon.svg');
const audioIcon = require('../../content/images/audio_icon.svg');
const videoIcon = require('../../content/images/video_icon.svg');

interface MediaIndicatorProps {
    hasAudio: boolean
    hasImage: boolean
    hasVideo: boolean
    hasBackground: boolean
}

const indicatorStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
        width: 22px;
        height: 22px;
        margin: 0 6px;
    }
`
const MediaIndicator : React.SFC<MediaIndicatorProps> = (props) => (
    <div className={indicatorStyle}>
        { props.hasAudio ? <img src={audioIcon}/> : null }
        { props.hasImage ? <img src={imageIcon}/> : null }
        { props.hasVideo ? <img src={videoIcon}/> : null }
        { props.hasBackground ? <img src={backgroundIcon}/> : null }
    </div>
);

export default MediaIndicator;