import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, silver_two, cool_grey } from '../../constants/colors';
import RoundedSelectionOption from './RoundedSelectionOption';
import MediaTypeOptionModel from '../../models/media/MediaTypeOptionModel';

// component used in building forms with consistent components for look and function
interface RoundedSelectionRowProps {
    selectedFilter: number
    onClick: (i: number) => void
    id?: string
    options: MediaTypeOptionModel[],
    className?: string,
    optionClassName?: string
    optionTextClassName?: string
}

const RoundedSelectionRow: React.FC<RoundedSelectionRowProps> = props =>
(
    <div className={`${fieldWrapper} ${props.className ?? ''}`}>
        <div className={selectorContainer}>
            {props.options.map((filterOption, index) =>
                <RoundedSelectionOption
                    onClick={() => props.onClick(index)}
                    selected={index === props.selectedFilter}
                    selectedIcon={filterOption.selectedIcon}
                    name={filterOption.name}
                    icon={filterOption.icon}
                    key={index}
                    className={props.optionClassName}
                    optionNumber={index}
                    textStyle={props.optionTextClassName}
                />
            )}
        </div>
    </div>
);

const selectorContainer = css`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 50px;
    border: solid 1px ${silver_two}; 
    background: white;
    margin-top: 8px;
    margin-bottom 55px;
    box-sizing: border-box;
`

const fieldWrapper = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
`

export default RoundedSelectionRow;