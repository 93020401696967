import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import MediaItemModel from '../../models/media/MediaItemModel';
import { resolve } from 'url';
import MediaResponseContainerModel from '../../models/features/api/MediaResponses/MediaResponseContainerModel';
import NewBulkMediaResponseContainerRequest from '../../models/features/api/MediaResponses/NewBulkMediaResponseContainerRequest';
import UpdateBulkMediaResponseContainerRequest from '../../models/features/api/MediaResponses/UpdateBulkMediaResponseContainerRequest';
import ResponseTemplateTypeModel from '../../models/features/api/MediaResponses/ResponseTemplateTypeModel';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';

type MediaResponseState = {
    mediaResponseContainers: MediaResponseContainerModel[]
    responseTemplates: ResponseTemplateTypeModel[]
    isSaving: boolean
    isLoading: boolean
    errors: string[]
    applicationId: string
    deviceTargets: DeviceTargetModel[]
}

export default class MediaResponseStateContainer extends Container<MediaResponseState> {
    // default state
    state = {
        mediaResponseContainers: [] as MediaResponseContainerModel[],
        responseTemplates: [] as ResponseTemplateTypeModel[],
        isSaving: false,
        isLoading: false,
        errors: [],
        applicationId: '',
        deviceTargets: [] as DeviceTargetModel[]
    }

    getMediaResponseContainer(containerId: string): Promise<IResult<MediaResponseContainerModel>> {
        // if we already have container return it
        const existingContainer = this.state.mediaResponseContainers.find(c => c.id == containerId);
        if (existingContainer) {
            return new Promise<IResult<MediaResponseContainerModel>>((resolve) => {
                resolve({
                    data: existingContainer,
                    errors: [],
                    resultType: "Ok"
                });
            });
        }

        this.setLoading(true)

        var promise = voicifyApi.getMediaResponseContainerById(containerId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const containers = this.state.mediaResponseContainers;
                containers.push(result.data);
                this.setState({
                    ...this.state,
                    errors: [],
                    mediaResponseContainers: containers,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        });
        return promise;
    }


    getDeviceTargets(): Promise<IResult<DeviceTargetModel[]>> {
        // if we already have targets return them
        if (this.state.deviceTargets.length > 0) {
            return new Promise<IResult<DeviceTargetModel[]>>((resolve) => {
                resolve({
                    data: this.state.deviceTargets,
                    errors: [],
                    resultType: "Ok"
                });
            });
        }

        this.setLoading(true)

        var promise = voicifyApi.getAllDeviceTargets();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    deviceTargets: result.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        });
        return promise;
    }

    getResponseTemplates(): Promise<IResult<ResponseTemplateTypeModel[]>> {
        // if we already have templates return them
        if (this.state.responseTemplates.length > 0) {
            return new Promise<IResult<ResponseTemplateTypeModel[]>>((resolve) => {
                resolve({
                    data: this.state.responseTemplates,
                    errors: [],
                    resultType: "Ok"
                });
            });
        }

        this.setLoading(true)

        var promise = voicifyApi.getAllResponseTemplates();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    responseTemplates: result.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        });
        return promise;
    }

    createResponseContainer(model: NewBulkMediaResponseContainerRequest): Promise<IResult<MediaResponseContainerModel>> {
        this.setSaving(true);
        var promise = voicifyApi.createBulkMediaResponseContainer(model);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const containers = this.state.mediaResponseContainers;
                containers.push(result.data);
                this.setState({
                    ...this.state,
                    errors: [],
                    isSaving: false,
                    mediaResponseContainers: containers
                });
            }
            else {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [error]
            })
        })
        return promise;
    }

    updateResponseContainer(containerId: string, model: UpdateBulkMediaResponseContainerRequest): Promise<IResult<MediaResponseContainerModel>> {
        this.setSaving(true);
        var promise = voicifyApi.updateBulkMediaResponseContainer(containerId, model);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const containers = this.state.mediaResponseContainers;
                const existingContainer = containers.find(c => c.id == containerId);
                if (existingContainer == null) {
                    containers.push(result.data)
                }
                else {
                    containers[containers.findIndex(c => c.id == containerId)] = result.data;
                }
                this.setState({
                    ...this.state,
                    errors: [],
                    isSaving: false,
                    mediaResponseContainers: containers
                });
            }
            else {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [error]
            })
        })
        return promise;
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    };
    
    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading
        });
    }

    private setSaving(isSaving: boolean) {
        this.setState({
            ...this.state,
            isSaving
        });
    }
}