import React from 'react';
import ScreenPreviewComponent from '../ScreenPreviewComponent';
import CortanaScreenWrapper from './CortanaScreenWrapper';
import DOMPurify from 'dompurify';

class CortanaScreenSimpleCardPreview extends ScreenPreviewComponent {

    render() {
        return (
            <CortanaScreenWrapper className=''>
                <div className="body-container">

                    {this.state.displayTitle ? <p className="title-text">{this.state.displayTitle}</p> : null}

                    <p className="body-text">
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.displayText) }}></span>

                        {this.state.followUpText ?
                            <>
                                <br /><br />
                                <span className="followup-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.followUpText) }}></span>
                            </>
                            : null}
                    </p>
                </div>
            </CortanaScreenWrapper>
        )
    }
}

export default CortanaScreenSimpleCardPreview;