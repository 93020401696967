import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Subscribe } from 'unstated';
import AppliedApplicationTemplateContainer from '../../state/containers/AppliedApplicationTemplateContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import TemplateConfigurationContainer from '../../state/containers/TemplateConfigurationContainer';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import AppliedTemplateManagerContent from './components/AppliedTemplateManagerContent';

interface AppliedAppTemplatesManagerProps {
    applicationId: string
    appliedAppTemplateId?: string // for edits
    templateConfigurationId?: string  // for new
}

const ApplicationAppliedTemplateManager: React.FC<RouteComponentProps<AppliedAppTemplatesManagerProps>> = (props) => {

    return (
        <Subscribe to={[ApplicationContainer, AppliedApplicationTemplateContainer, OrganizationContainer, TemplateConfigurationContainer, ApplicationEnvironmentsContainer]}>
            {(appContainer: ApplicationContainer,
                appliedAppTemplateContainer: AppliedApplicationTemplateContainer,
                orgContainer: OrganizationContainer,
                templateConfigContainer: TemplateConfigurationContainer,
                applicationEnvironmentsContainer: ApplicationEnvironmentsContainer) => (
                <AppliedTemplateManagerContent
                    appContainer={appContainer}
                    appliedAppTemplateContainer={appliedAppTemplateContainer}
                    orgContainer={orgContainer}
                    templateConfigContainer={templateConfigContainer}
                    applicationId={props.match.params.applicationId}
                    appliedAppTemplateId={props.match.params.appliedAppTemplateId}
                    templateConfigurationId={props.match.params.templateConfigurationId}
                    history={props.history}
                    key={props.match.params.appliedAppTemplateId}
                    applicationEnvironmentsContainer={applicationEnvironmentsContainer}
                />
            )}
        </Subscribe>
    )
}

export default ApplicationAppliedTemplateManager;