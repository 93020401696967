import React from 'react';
import { css } from '@emotion/css';

// used to manage and manipulate the inner styles of the filters 
// against the grouped multi selector
const FilterWrapper: React.SFC = (props) => (
    <div className={wrapperStyle}>
        {props.children}
    </div>
)

const wrapperStyle = css`
    margin: 16px 16px 0 16px;
    flex: 1;
    max-width: 24%;
`;

export default FilterWrapper;