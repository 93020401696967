import { css } from "@emotion/css";
import { FormikErrors } from "formik";
import React from "react";
import { useState } from "react";
import Button from "../../../components/general/Button";
import { color_shades_dark, color_colors_ocean, color_variants_ocean_light_2 } from "../../../constants/colors";
import { InteractionModelFormData } from "./InteractionModelForm";
import MenuItemModel from "../../../models/nlp/menu/MenuItemModel";
import EditedMenuItemModel from "../../../models/nlp/menu/EditedMenuItemModel";
import ConfirmationDialog from "../../../components/structure/ConfirmationDialog";
import WarningConfirmation from "../../../components/general/WarningConfirmation";
import styled from "@emotion/styled";
import SearchField from "../../../components/forms/SearchField";

const deleteIcon = require('../../../content/images/trash.svg');
const duplicateIcon = require('../../../content/images/response-duplicate.svg');
const addIcon = require('../../../content/images/add-blue.svg');
const redNoCircle = require('../../../content/images/red-no-circle.svg');
const yellowUpdate = require('../../../content/images/yellow-update.svg');

interface VoicifyMenuItemsProps {
    setSelectedMenuItem: (selectedMenuItem: EditedMenuItemModel) => void;
    selectedMenuItem: EditedMenuItemModel;
    toggleItemActivation: (menuItem: EditedMenuItemModel, index: number) => void;
    applicationId: string;
    updatedMenuItems: EditedMenuItemModel[];
    setUpdatedMenuItems: (updatedMenuItems: EditedMenuItemModel[]) => void;
    dirty: boolean;
    isValid: boolean;
    status?: any;
    errors?: FormikErrors<InteractionModelFormData>;
    activeItemIndex?: number;
    setActiveItemIndex?: (activeItemIndex: number) => void;
    handleAddItemClick: (isCopy: boolean, menuItem: MenuItemModel, index: number) => void;
    isLoading: boolean;
}

const VoicifyMenuItems: React.FC<VoicifyMenuItemsProps> = (props) => {

    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const toggleConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
    };

    const selectMenuItemAndOpenModal = (menuItem: EditedMenuItemModel, index: number) => {
        props.toggleItemActivation(menuItem, index)
        toggleConfirmDeleteModal();
    }

    const onConfirmDelete = (clickedMenuItem: EditedMenuItemModel) => {

        const copyOfUpdatedMenuItems = [...props.updatedMenuItems];

        const updatedMenuItemIndex = copyOfUpdatedMenuItems.findIndex((menuItem) => {
            return (menuItem.menuItem.id === clickedMenuItem.menuItem.id) && (menuItem.menuItem.comboId === clickedMenuItem.menuItem.comboId);
        });

        copyOfUpdatedMenuItems[updatedMenuItemIndex] = { ...clickedMenuItem, isDeleted: true };

        // update local menu item state to show deleted menu item
        props.setUpdatedMenuItems(copyOfUpdatedMenuItems);

        // close modal and rerender menu item state
        toggleConfirmDeleteModal();
    };

    const handleCopyMenuItem = (e: React.MouseEvent<HTMLElement>, menuItem: EditedMenuItemModel) => {
        e.stopPropagation();
        const menuItemCopy = { ...menuItem };
        const updatedMenuItemIndex = props.updatedMenuItems.findIndex((updatedMenuItem => {
            return (updatedMenuItem.menuItem.id === menuItemCopy.menuItem.id) && (updatedMenuItem.menuItem.comboId === menuItemCopy.menuItem.comboId);
        }))
        props.handleAddItemClick(true, menuItemCopy.menuItem, updatedMenuItemIndex);
    };

    return (
        <>
            {
                isConfirmDeleteModalOpen &&
                <ConfirmationDialog
                    title={`Confirm Delete`}
                    deleteText="Yes, Delete"
                    isLoading={false}
                    onClose={toggleConfirmDeleteModal}
                    onConfirm={() => onConfirmDelete(props.selectedMenuItem)}
                >
                    <WarningConfirmation
                        text={`Are you sure you want to delete menu item "${props.selectedMenuItem.menuItem.originalName}"?`}
                    />
                </ConfirmationDialog>
            }
            {
                props.updatedMenuItems?.length > 0 &&
                <>
                    <SearchField
                        id="searchField1"
                        name={"searchField"}
                        placeholder={"Find a menu item"}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        boxType={"square"}
                        className={searchPadding}
                    />
                    {props.updatedMenuItems.filter(menuItem => menuItem?.menuItem?.originalName?.toLowerCase().includes(searchTerm.toLowerCase()))?.map((menuItem: EditedMenuItemModel, index: number) => {
                        return (
                            <MenuItemWrapper
                                className={index === props.activeItemIndex ? "active" : ""}
                                key={`${menuItem.menuItem.originalName} - ${index}`}
                                onClick={() => {
                                    props.toggleItemActivation(menuItem, index)
                                }}
                            >
                                <Button
                                    themes={["white-small"]}
                                    text={menuItem.menuItem.originalName}
                                    type="button"
                                />
                                <MenuItemActionButtons>
                                    {
                                        (menuItem.isAdded || menuItem.isDeleted || menuItem.isModified) &&
                                        <Button
                                            themes={['icon', 'end-tight']}
                                            icon={menuItem.isDeleted ? redNoCircle : (menuItem.isModified ? yellowUpdate : addIcon)}
                                        />
                                    }
                                    <Button themes={['icon', 'end-tight']}
                                        icon={duplicateIcon}
                                        type="button"
                                        onClick={(e) => handleCopyMenuItem(e, menuItem)}
                                    />
                                    <Button themes={['icon', 'end-tight']}
                                        icon={deleteIcon}
                                        type="button"
                                        onClick={() => selectMenuItemAndOpenModal(menuItem, index)}
                                    />
                                </MenuItemActionButtons>
                            </MenuItemWrapper>
                        )
                    })}
                </>
            }
        </>
    );

};

export default VoicifyMenuItems;

const MenuItemActionButtons = styled.div`
    display: flex;
`;

const MenuItemWrapper = styled.div`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
    }
`;

const searchPadding = css`
    padding-top: 16px;
`;

