import React from 'react';
import { css } from '@emotion/css';
import MediaItemModel from '../../../models/media/MediaItemModel';
import { dark_grey_blue, color_shades_darker } from '../../../constants/colors';
import moment from 'moment';
const audioIcon = require('../../../content/images/audio_icon_blue.svg');
const videoIcon = require('../../../content/images/video_icon_blue.svg');
const attachIcon = require('../../../content/images/attachment.svg');
const restoreIcon = require('../../../content/images/undo.svg');


interface MediaGridPreviewProps {
    item: MediaItemModel
    column: number
    attachMediaItem: (item: MediaItemModel) => void
}

class MediaGridPreview extends React.Component<MediaGridPreviewProps> {
    renderMediaPreview(item: MediaItemModel): JSX.Element {
        switch (item.mediaType) {
            case ("AudioFile"):
                return <img src={audioIcon} />
            case ("VideoFile"):
                return <img src={videoIcon} />
            case ("ImageFile"):
            default:
                return <img src={item.url} />
        }
    }
    render() {
        const item = this.props.item;
        const nameString = item.name.length > 25 ?
            item.name.substring(0, 13) + "..." + item.name.substring(item.name.length - 10, item.name.length)
            :
            item.name
            ;
        const dateString = moment(item.modifiedDate).format("MMM DD, YYYY");
        return (
            <div key={item.id} className={`${containerStyle} _${this.props.column}`}>
                <div onClick={(e) => {
                    this.props.attachMediaItem(item);
                    e.stopPropagation();
                }}
                    className={"attach"}>
                    <img src={item.isDisabled ? restoreIcon : attachIcon} />
                </div>
                <div className={"image"}>
                    <div className={"col"}>
                        {this.renderMediaPreview(item)}
                    </div>
                </div>
                <div className={"name text"}>{nameString}</div>
                <div className={"date text"}>{dateString}</div>
            </div>
        )
    }
}

const containerStyle = css`
    border-radius: 4px;
    border: solid 1px #cccccc;
    box-shadow: 0 2px 4px 0 rgba(50, 70, 97, 0.1);
    padding: 16px;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    margin: 16px;
    text-align: left;
    &._0 {
        margin-left: 0px;
    }
    &._1 {
        margin-right: 0px;
    }
    .name.text {
        color: ${dark_grey_blue};
    }
    .date.text {
        color: ${color_shades_darker};
        font-size: 12px;
    }
    .text {    
        padding-top: 5px;  
        text-align: left;    
        font-family: Muli;
        width: 100%;
        word-wrap: break-word;
    }
    .attach {
        z-index: 90;
        width: 35px;
        height: 35px;
        position: absolute;
        top: 25px;
        right: 25px;
        background: rgba(255,255,255,.7);
        border-radius: 50%;
        img {
            z-index: 100;
            padding-top: 5px;
            padding-left: 5px;
        }
    }
    .image {
        width: 100%;
        height: 0px;
        background: black;
        padding-bottom: 75%;
        position: relative;
        .col {    
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            justify-content: center;
            text-align: center;
            display: flex;
            flex-direction: column;
            img {
                max-height: 100%;
                max-width: 100%;
                min-height: 100px;
                object-fit: contain;
            }
        }
    }
`;

export default MediaGridPreview;