import TemplateFieldType from "../templating/TemplateFieldType";

const shortTextIcon = require('../../content/images/field-types/short-text-field.svg');
const numberTextIcon = require('../../content/images/field-types/number-field.svg');
const longTextIcon = require('../../content/images/field-types/long-text-field.svg');
const ssmlIcon = require('../../content/images/field-types/ssml-field.svg');
const selectIcon = require('../../content/images/field-types/select-field.svg');
const radioIcon = require('../../content/images/field-types/radio-field.svg');
const checkIcon = require('../../content/images/field-types/checkbox-field.svg');
const fileUploadIcon = require('../../content/images/field-types/common-file-upload.svg');

export const getFieldTypeIcon = (fieldType: TemplateFieldType) => {
    switch (fieldType) {
        case "ShortTextField": return shortTextIcon;
        case "NumberTextField": return numberTextIcon;
        case "DateTextField": return shortTextIcon;
        case "LongTextField": return longTextIcon;
        case "SSMLTextField": return ssmlIcon;
        case "RadioGroupField": return radioIcon;
        case "CheckboxGroupField": return checkIcon;
        case "SelectField":  return selectIcon;
        case "FileUploadField": return fileUploadIcon;
    }

    return null;
} 