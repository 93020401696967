import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import _ from 'lodash';
import SubscriptionModel from '../../models/organizations/api/SubscriptionModel';

type SubscriptionState = {
    isLoading: boolean,    
    subscription: SubscriptionModel,
}

export default class SubscriptionContainer extends Container<SubscriptionState> {
    // default state
    state = {
        isLoading: false,
        subscription: null as SubscriptionModel,
    }   

    async loadSubscription(organizationId: string) {
        try {
            this.setLoading(true);
            const subResult = await voicifyApi.getOrganizationSubscription(organizationId);
            if (subResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    subscription: subResult.data
                })
            }
            else {
                this.setLoading(false);
            }
        }
        catch (error) {
            this.setLoading(false);
        }
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}