import { VoicifyAssistant } from "../assistant/VoicifyAssistant";
import type { VoicifyAssistantSettings } from "../models/customAssistantApiModels";
import { BrowserSpeechToTextProvider } from "../assistant/BrowserSpeechToTextProvider";
import { BrowserTextToSpeechProvider } from "../assistant/BrowserTextToSpeechProvider";
import { createStyles, addZIndex } from "./createStyles";
import { getConfiguration } from "../apis/fetches/getConfiguration";

const defaultIcon = require('./content/voicifyAvatar.svg');
const defaultImage = require('./content/voicifyAvatar.svg');
const defaultCloseImage = require('./content/close.svg');
const defaultInactiveSendImage = require('./content/send.svg');
const defaultActiveSendImage = require('./content/sendActive.svg');
const defaultActiveMicImage = require('./content/micActive.svg');
const defaultKeyboardImage = require('./content/keyboard-wireless.svg');
const defaultInactiveMicImage = require('./content/mic.svg');
const defaultSlideFromRightStartIcon = require('./content/slideLeftStartIcon.svg');
const defaultSlideFromBottomStartIcon = require('./content/slideUpStartIcon.svg');
const defaultMinimizeIcon = require('./content/minimize.svg');
const defaultMuteImage = require('./content/mute.svg')
const marked = require('marked');

declare global {
    interface Window { voicifyAssistant: VoicifyAssistant; initializeVoicifyAssistant: (settings: VoicifyAssistantSettings, rootId: string) => void }
};

type AssistantStatus = 'open' | 'closed' | 'initial' | 'existing';

type MicStatus = 'on' | 'off';

const getCustomAssistantConfiguration = async (serverRootUrl: string, configurationId: string, appId: string, appKey: string): Promise<VoicifyAssistantSettings> => {
    try {
        const result = await getConfiguration(serverRootUrl, configurationId, appId, appKey);
        let formattedResult = result.json();
        return formattedResult;
    } catch (e: any) {
        console.log(e);
        return e;
    }
};

export const initializeVoicifyAssistant = async (settings: VoicifyAssistantSettings, rootId: string = "voicifyAssistantRoot") => {

    if (settings.configurationId) {
        const dynamicSettings = await getCustomAssistantConfiguration(settings.serverRootUrl, settings.configurationId, settings.appId, settings.appKey);
        // Merge static settings object with dynamic settings coming from the API
        settings = {
            ...dynamicSettings,
            ...settings
        };
        // Ensure entire styles object isn't overridden by individually spreading each nested styles object
        if (settings.styles) {
            settings.styles.assistant = {
                ...dynamicSettings.styles?.assistant,
                ...settings.styles.assistant
            };
            settings.styles.header = {
                ...dynamicSettings.styles?.header,
                ...settings.styles.header
            }
            settings.styles.body = {
                ...dynamicSettings.styles?.body,
                ...settings.styles.body
            }
            settings.styles.toolbar = {
                ...dynamicSettings.styles?.toolbar,
                ...settings.styles.toolbar
            }
            settings.styles.start = {
                ...dynamicSettings.styles?.start,
                ...settings.styles.start
            }
        }
        settings.device = "Custom Assistant Browser SDK";
        settings.channel = settings.name ?? "My App";
    }

    const sttProvider = new BrowserSpeechToTextProvider(settings.locale);
    const ttsProvider = new BrowserTextToSpeechProvider(settings);
    window.voicifyAssistant = new VoicifyAssistant(settings, ttsProvider, sttProvider, rootId);

    let assistantStatus: AssistantStatus = 'initial';

    let micStatus: MicStatus = 'off';

    let audioList: HTMLAudioElement[] = [];

    let videoList: HTMLVideoElement[] = [];

    let root = document.getElementById(rootId);

    const hasRoot = root != null;
    if (!root) {
        root = document.createElement("div");
        root.id = rootId;
        root.ariaRoleDescription = "complementary";
    };

    let shadowRoot = root.attachShadow({ mode: 'open' });

    let rootElement = document.createElement("div");

    shadowRoot.appendChild(rootElement);

    if (!hasRoot)
        document.body.appendChild(root);

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    const scrollToBottomOfChat = () => {
        const chatDiv = shadowRoot.getElementById("voicify-assistant-chat-history");
        chatDiv?.scroll({ top: chatDiv.scrollHeight, behavior: 'smooth' });
    };

    function sanitize(str: string) {
        return str.replace(/&<"/g, function (m) {
            if (m === "&") return "&amp;"
            if (m === "<") return "&lt;"
            return "&quot;"
        });
    };

    const pauseAudio = () => {
        audioList.forEach((audioItem) => {
            audioItem.pause();
        });

    };

    const pauseVideo = () => {
        videoList.forEach((videoItem) => {
            videoItem.pause();
        });

    };

    const minimalUI: boolean = settings.uiType === "minimal";

    const assistantContainer = document.createElement("div");
    const header = document.createElement("div");
    const headerLeftContainer = document.createElement("div");
    const headerRightContainer = document.createElement("div");
    const minimizeButton = document.createElement("button");
    minimizeButton.type = "button";
    minimizeButton.className = "voicify-assistant-minimize-button";
    const minimizeIcon = document.createElement("img");
    minimizeButton.append(minimizeIcon);
    minimizeIcon.alt = "minimize";
    minimizeIcon.src = settings.styles?.header?.minimizeIcon ?? defaultMinimizeIcon;
    const closeButton = document.createElement("button");
    const closeIcon = document.createElement("img");
    closeIcon.alt = "close";
    closeIcon.src = settings.styles?.header?.closeAssistantButtonImage ?? defaultCloseImage;
    const headerText = document.createElement("p");
    if (settings.styles?.header?.assistantName) {
        headerText.innerText = settings.styles.header.assistantName;
        rootId = settings.styles.header.assistantName;
        root.ariaLabel = settings.styles.header.assistantName;
    }
    const logo = document.createElement("img");
    logo.alt = "assistant";
    logo.src = settings.styles?.header?.assistantImage ?? defaultIcon;
    const chatHistory = document.createElement("div");
    const toolbar = document.createElement("div");
    const toolbarBottomRow = document.createElement("div");
    const minimalSpeakingIndicator = document.createElement("div");
    minimalSpeakingIndicator.className = "voicify-assistant-minimal-speaking-indicator";
    if (!minimalUI) {
        toolbarBottomRow.className = "voicify-assistant-toolbar-bottom";
    } else if (settings.showConversationHistory) {
        toolbarBottomRow.className = "voicify-assistant-toolbar-bottom-minimal-show-history";
    } else {
        toolbarBottomRow.className = "voicify-assistant-toolbar-bottom-minimal";
    }
    const toolbarLeftColumn = document.createElement("div");
    const toolbarRightColumn = document.createElement("div");
    const toolbarTopRow = document.createElement("div");
    const toolbarListeningRow = document.createElement("div");
    const toolbarTranscription = document.createElement("div");
    const toolbarTopRowBottomBorder = document.createElement("div");
    const toolbarHelp = document.createElement("div");
    toolbarHelp.innerText = settings.styles?.toolbar?.helpText ?? "How can I help?";
    const toolbarMuteContainer = document.createElement("div");
    const toolbarMuteText = document.createElement("div");
    toolbarMuteText.innerText = settings.styles?.toolbar?.muteText ?? "Stop Listening";
    const toolbarMuteIcon = document.createElement("img");
    toolbarMuteIcon.src = settings.styles?.toolbar?.muteImage ?? defaultMuteImage;
    toolbarMuteIcon.alt = "mute";
    const toolbarAnimation = document.createElement("div");
    const toolbarState = document.createElement("div");
    toolbarState.innerText = settings.styles?.toolbar?.assistantStateText ?? "Listening...";
    const toolbarHeadingType = document.createElement("p");
    toolbarHeadingType.innerText = "TYPE";
    const textboxContainer = document.createElement("div");
    const textbox = document.createElement("input");
    textbox.placeholder = settings.styles?.toolbar?.placeholder ?? "Type something here";
    const send = document.createElement("button");
    send.type = "button";
    const sendIcon = document.createElement("img");
    sendIcon.alt = "send";
    sendIcon.src = settings.styles?.toolbar?.sendInactiveImage ?? defaultInactiveSendImage;
    const toolbarHeadingSpeak = document.createElement("p");
    toolbarHeadingSpeak.innerText = "SPEAK";
    const micIcon = document.createElement("img");
    if (!minimalUI) {
        micIcon.className = "voicify-assistant-mic-inactive";
    } else {
        micIcon.className = "voicify-assistant-mic-inactive-minimal";
    }
    const microphone = document.createElement("button");
    microphone.type = "button";
    micIcon.src = settings.styles?.toolbar?.micInactiveImage ?? defaultInactiveMicImage;
    micIcon.alt = "record";
    const keyboardIcon = document.createElement("img");
    keyboardIcon.className = "voicify-assistant-keyboard-inactive";
    const keyboard = document.createElement("button");
    keyboard.type = "button";
    keyboardIcon.src = settings.styles?.toolbar?.keyboardInactiveImage ?? defaultKeyboardImage;
    keyboardIcon.alt = "type";
    const startButton = document.createElement("button");
    const startIcon = document.createElement("img");
    startIcon.alt = "start";
    startButton.type = "button";
    const minimalUIContainer = document.createElement("div");
    const minimalUIContainerLeft = document.createElement("div");
    const minimalUIContainerRight = document.createElement("div");
    minimalUIContainer.className = "voicify-assistant-minimal-ui-container";
    minimalUIContainerLeft.className = "voicify-assistant-minimal-ui-container-left";
    minimalUIContainerRight.className = "voicify-assistant-minimal-ui-container-right";

    const buildUserMessage = (message: string) => {
        const messageItem = document.createElement("p");
        if (!minimalUI) {
            messageItem.className = "voicify-assistant-chat-user-item";
        } else {
            messageItem.className = "voicify-assistant-chat-user-item-minimal";
        }
        messageItem.innerText = message;
        chatHistory.appendChild(messageItem);
        scrollToBottomOfChat();
    };

    const buildBotMessage = (message: string, hints?: string[], image?: string, audio?: string, video?: string) => {
        const messageItemContainer = document.createElement("div");
        messageItemContainer.className = "voicify-assistant-chat-bot-container";
        const messageItemLeftColumn = document.createElement("div");
        messageItemLeftColumn.className = "voicify-assistant-chat-bot-container-left";
        const messageItemRightColumn = document.createElement("div");
        if (!minimalUI) {
            messageItemRightColumn.className = "voicify-assistant-chat-bot-container-right";
            const botAvatar = document.createElement("img");
            botAvatar.alt = "assistant avatar";
            botAvatar.src = settings.styles?.body?.assistantImage ?? defaultIcon;
            botAvatar.className = "voicify-assistant-chat-bot-avatar";
            messageItemLeftColumn.appendChild(botAvatar);
        } else {
            messageItemRightColumn.className = "voicify-assistant-chat-bot-container-right-minimal";
        }
        const messageItem = document.createElement("p");
        if (!minimalUI) {
            messageItem.className = "voicify-assistant-chat-bot-item";
        } else {
            messageItem.className = "voicify-assistant-chat-bot-item-minimal";
        }
        if (message) {
            if (ttsProvider) {
                pauseAudio()
                pauseVideo();
            };
            messageItem.innerHTML = marked.parse(message);
            // Resize markdown images using Marked.js package: https://github.com/markedjs/marked/issues/339
            const renderer = new marked.Renderer();
            renderer.image = function (src: string, title: string, alt: string) {
                // The below RegEx enables image resizing directly in markdown in the following format:
                // ![alt](https://markdown-img.jpg "=250x250")
                const exec = /=\s*(\d*)\s*x\s*(\d*)\s*$/.exec(title)
                let res = '<img src="' + sanitize(src) + '" alt="' + sanitize(alt)
                if (exec && exec[1]) res += '" height="' + exec[1]
                if (exec && exec[2]) res += '" width="' + exec[2]
                return res + '">'
            }
            marked.setOptions({
                renderer: renderer
            })
            const aTagList = messageItem.querySelectorAll("a");
            aTagList.forEach(aTag => {
                aTag.setAttribute('target', '_blank');
            })
            messageItemRightColumn.appendChild(messageItem);
        };
        const hintContainer = document.createElement("div");
        if (hints && !minimalUI) {
            hintContainer.className = "voicify-assistant-hint-container";
            hints?.map((hint: string) => {
                const hintButton = document.createElement("button");
                hintButton.className = "voicify-assistant-hint-button";
                hintButton.innerText = hint;
                hintContainer.appendChild(hintButton)
                hintButton.onclick = () => {
                    window.voicifyAssistant.makeTextRequest(hintButton.innerText);
                }
            });
            messageItemRightColumn.appendChild(hintContainer);
        }
        if (!minimalUI) {
            messageItemContainer.appendChild(messageItemLeftColumn);
        }
        messageItemContainer.appendChild(messageItemRightColumn);
        chatHistory.appendChild(messageItemContainer);
        const foregroundImage = document.createElement("img");
        foregroundImage.alt = "assistant response";
        if (image && !minimalUI) {
            const handleForegroundImageError = () => {
                foregroundImage.src = defaultImage;
            };
            foregroundImage.className = "voicify-assistant-foreground-image";
            foregroundImage.src = image;
            foregroundImage.onerror = handleForegroundImageError;
            foregroundImage.onload = scrollToBottomOfChat;
            chatHistory.appendChild(foregroundImage);
        }
        if (audio) {
            const audioElement = document.createElement("audio");
            audioElement.controls = true;
            audioElement.src = audio;
            audioElement.autoplay = true;
            audioElement.className = "voicify-assistant-audio-element";
            audioElement.id = "voicify-assistant-audio-element-" + uuidv4();
            if (!audioList.includes(audioElement)) {
                audioList.push(audioElement);
            };
            messageItemContainer.appendChild(messageItemLeftColumn);
            messageItemRightColumn.appendChild(audioElement);
            messageItemContainer.appendChild(messageItemRightColumn);
            chatHistory.appendChild(messageItemContainer);
            audioElement.addEventListener('play', function (e) {
                pauseVideo();
                audioList.forEach((audioItem) => {
                    if (audioItem && (audioItem != e.target)) {
                        audioItem.pause();
                    };
                });
            }, true);
        };
        if (video && !minimalUI) {
            const videoElement = document.createElement("video");
            videoElement.controls = true;
            videoElement.innerHTML = "Your browser does not support the video tag."
            videoElement.src = video;
            videoElement.className = "voicify-assistant-video-element";
            videoElement.id = "voicify-assistant-video-element-" + uuidv4();
            if (!videoList.includes(videoElement)) {
                videoList.push(videoElement);
            };
            messageItemContainer.appendChild(messageItemLeftColumn);
            messageItemRightColumn.appendChild(videoElement);
            messageItemContainer.appendChild(messageItemRightColumn);
            chatHistory.appendChild(messageItemContainer);
            videoElement.addEventListener('play', function (e) {
                pauseAudio();
                videoList.forEach((videoItem) => {
                    if (videoItem && (videoItem != e.target)) {
                        videoItem.pause();
                    };
                });
            }, true);
        }
        scrollToBottomOfChat();
    };

    const buildChatHistory = () => {
        const localStorageChatHistory = window.voicifyAssistant.getChatHistoryItems();
        if (localStorageChatHistory.length >= 1 && window.voicifyAssistant.isExistingSession()) {
            localStorageChatHistory.forEach((chatItem) => {
                if (chatItem.type === "user" && (chatItem.content != undefined || null)) {
                    buildUserMessage(chatItem.content.text);
                };
                if (chatItem.type === "bot") {
                    buildBotMessage(chatItem.content.text, chatItem.content.hints, chatItem.content.image, chatItem.content.audio, chatItem.content.video);
                };
            });
        };
    };

    const styleChatContainer = () => {
        if (!minimalUI) {
            if (settings.initializeWithWelcomeMessage) {
                if (settings.uiType === "slideFromBottom") {
                    assistantContainer.className = "voicify-assistant-chat-container-slide-up"
                } else {
                    assistantContainer.className = "voicify-assistant-chat-container";
                }
            } else {
                assistantContainer.className = "voicify-assistant-chat-container-quick";
            };
        } else if (settings.showConversationHistory) {
            assistantContainer.className = "voicify-assistant-chat-container-minimal-show-history";
        } else {
            assistantContainer.className = "voicify-assistant-chat-container-minimal";
        }
    };

    const styleHeader = () => {
        closeButton.id = "voicify-assistant-close-button-id";
        closeButton.append(closeIcon);
        closeIcon.className = "voicify-assistant-close-icon";
        if (!minimalUI) {
            closeButton.className = "voicify-assistant-close-button";
            assistantContainer.appendChild(header);
            switch (settings.uiType) {
                case "bottomRightButton":
                    header.className = "voicify-assistant-header voicify-assistant-header-bottom-right-button";
                    headerRightContainer.append(minimizeButton);
                    break;
                case "slideFromRight":
                    header.className = "voicify-assistant-header voicify-assistant-header-slide-left";
                    headerRightContainer.append(minimizeButton);
                    break;
                case "slideFromBottom":
                    header.className = "voicify-assistant-header voicify-assistant-header-slide-up";
                    headerRightContainer.append(startButton);
                    break;
                default:
                    header.className = "voicify-assistant-header voicify-assistant-header-bottom-right-button";
                    headerRightContainer.append(minimizeButton);
                    break;
            }
            headerLeftContainer.className = "voicify-assistant-header-left";
            header.append(headerLeftContainer);
            headerRightContainer.className = "voicify-assistant-header-right";
            header.append(headerRightContainer);
            headerRightContainer.append(closeButton);
            logo.className = "voicify-assistant-logo";
            headerLeftContainer.appendChild(logo);
            headerLeftContainer.appendChild(headerText);
        } else {
            closeButton.className = "voicify-assistant-close-button-minimal";
            assistantContainer.appendChild(closeButton);
        }
    };

    const styleChatHistory = () => {
        if (settings.showConversationHistory) {
            assistantContainer.appendChild(chatHistory);
            if (!minimalUI) {
                if (settings.initializeWithWelcomeMessage) {
                    chatHistory.className = "voicify-assistant-chat-history";
                } else {
                    chatHistory.className = "voicify-assistant-chat-history-hidden";
                };
            } else {
                if (settings.initializeWithWelcomeMessage) {
                    chatHistory.className = "voicify-assistant-chat-history-minimal";
                } else {
                    chatHistory.className = "voicify-assistant-chat-history-minimal-hidden";
                }
            }
            chatHistory.id = "voicify-assistant-chat-history";
        }
    };

    const showSpeakingIndicator = () => {
        micIcon.src = settings.styles?.toolbar?.micActiveImage ?? defaultActiveMicImage;
        if (!minimalUI) {
            micIcon.className = "voicify-assistant-mic-active";
        } else {
            micIcon.className = "voicify-assistant-mic-active-minimal";
        }
        toolbarHeadingSpeak.className = "voicify-assistant-toolbar-speak-active";
        toolbarTopRow.className = "voicify-assistant-toolbar-top";
        if (minimalUI) {
            if (settings.showConversationHistory) {
                toolbar.appendChild(minimalSpeakingIndicator);
            } else {
                assistantContainer?.appendChild(minimalSpeakingIndicator);
                toolbar.className = "voicify-assistant-toolbar-minimal-speaking";
            }
        }
    }

    const hideSpeakingIndicator = () => {
        micIcon.src = settings.styles?.toolbar?.micInactiveImage ?? defaultInactiveMicImage;
        if (!minimalUI) {
            micIcon.className = "voicify-assistant-mic-inactive";
        } else {
            micIcon.className = "voicify-assistant-mic-inactive-minimal";
        }
        toolbarHeadingSpeak.className = "voicify-assistant-toolbar-speak-inactive";
        if (minimalUI) {
            if (settings.showConversationHistory && minimalSpeakingIndicator.parentElement === toolbar) {
                toolbar.removeChild(minimalSpeakingIndicator);
            } else if (!settings.showConversationHistory && minimalSpeakingIndicator.parentElement === assistantContainer) {
                assistantContainer?.removeChild(minimalSpeakingIndicator);
                toolbar.className = "voicify-assistant-toolbar-minimal";
            }
        }

    }

    const scrollToEndOfPartial = () => {
        toolbarTranscription.scrollLeft = toolbarTranscription.scrollWidth;
    };

    const displayTranscription = (speech: string) => {
        toolbarTranscription.innerText = speech;
        scrollToEndOfPartial();
    }

    const clearTranscription = () => {
        toolbarTranscription.innerText = "";
    }

    const toggleListening = () => {
        if (micStatus === "off") {
            micStatus = "on";
            showSpeakingIndicator();
            pauseAudio();
            if (ttsProvider) {
                ttsProvider.stop();
            }
            sttProvider?.startListening();
            sttProvider?.addPartialListener((partialSpeechResult) => {
                displayTranscription(partialSpeechResult);
            })
            sttProvider?.addFinishListener(() => {
                hideSpeakingIndicator();
                sttProvider?.stopListening();
            })
            sttProvider?.addEndListener(() => {
                micStatus = "off"
                hideSpeakingIndicator();
                toolbarTopRow.className = "voicify-assistant-toolbar-top-hidden";
            })
            sttProvider?.addNoMatchListener(() => {
                hideSpeakingIndicator();
                toolbarTopRow.className = "voicify-assistant-toolbar-top-hidden";
            })
        } else if (micStatus === "on") {
            micStatus = "off";
            sttProvider?.stopListening();
            sttProvider?.addEndListener(() => {
                hideSpeakingIndicator();
                toolbarTopRow.className = "voicify-assistant-toolbar-top-hidden";
            })
        };
    }

    const styleToolbar = () => {
        assistantContainer.appendChild(toolbar);

        const numberOfBars = 11;

        for (let i = 1; i <= numberOfBars; i++) {
            const bar = document.createElement("div");
            bar.className = `voicify-assistant-bar-${i} voicify-assistant-bar`;
            toolbarAnimation.appendChild(bar);
        }

        switch (settings.uiType) {
            case "bottomRightButton":
                toolbar.className = "voicify-assistant-toolbar voicify-assistant-toolbar-bottom-right-button";
                break;
            case "slideFromRight":
                toolbar.className = "voicify-assistant-toolbar voicify-assistant-toolbar-slide";
                break;
            case "slideFromBottom":
                toolbar.className = "voicify-assistant-toolbar voicify-assistant-toolbar-slide";
                break;
            case "minimal":
                if (settings.showConversationHistory) {
                    toolbar.className = "voicify-assistant-toolbar-minimal-show-history";
                } else {
                    toolbar.className = "voicify-assistant-toolbar-minimal";
                }
                break;
            default:
                toolbar.className = "voicify-assistant-toolbar voicify-assistant-toolbar-bottom-right-button";
                break;
        }

        toolbarLeftColumn.className = "voicify-assistant-toolbar-left-no-mic";
        if (!minimalUI) {
            toolbarRightColumn.className = "voicify-assistant-toolbar-right";
        } else {
            toolbarRightColumn.className = "voicify-assistant-toolbar-right-minimal";
        }
        toolbarTopRow.className = "voicify-assistant-toolbar-top-hidden";
        toolbarTopRowBottomBorder.className = "voicify-assistant-toolbar-top-bottom-border";
        toolbarListeningRow.className = "voicify-assistant-toolbar-listening-row";
        toolbarState.className = "voicify-assistant-toolbar-state";
        toolbarMuteContainer.className = "voicify-assistant-toolbar-mute-container";
        toolbarMuteText.className = "voicify-assistant-toolbar-mute-text";
        toolbarMuteIcon.className = "voicify-assistant-toolbar-mute-icon";
        if (!minimalUI) {
            toolbarTranscription.className = "voicify-assistant-toolbar-transcription";
        } else {
            toolbarTranscription.className = "voicify-assistant-toolbar-transcription-minimal";
        }
        toolbarAnimation.className = "voicify-assistant-toolbar-animation";
        toolbarHelp.className = "voicify-assistant-toolbar-help";

        toolbarMuteContainer.appendChild(toolbarMuteText);
        toolbarMuteContainer.appendChild(toolbarMuteIcon);
        toolbarListeningRow.appendChild(toolbarState);
        toolbarListeningRow.appendChild(toolbarMuteContainer);

        if (!minimalUI) {
            toolbarTopRow.appendChild(toolbarHelp);
            toolbarTopRow.appendChild(toolbarAnimation);
            toolbarTopRow.appendChild(toolbarListeningRow);
            toolbarTopRow.appendChild(toolbarTranscription);
            toolbarTopRow.appendChild(toolbarTopRowBottomBorder);
        }

        toolbarHeadingType.className = "voicify-assistant-toolbar-type-inactive";
        if (!minimalUI) {
            textbox.className = "voicify-assistant-textbox";
        } else {
            textbox.className = "voicify-assistant-textbox-minimal";
        }

        textbox.onfocus = () => {
            sttProvider?.stopListening();
            if (!minimalUI) {
                textboxContainer.className = "voicify-assistant-input-container-active";
                sendIcon.className = "voicify-assistant-send-icon";
                micIcon.className = "voicify-assistant-mic-inactive";
                textbox.className = "voicify-assistant-textbox-active";
            } else {
                textboxContainer.className = "voicify-assistant-input-container-active-minimal";
                sendIcon.className = "voicify-assistant-send-icon-minimal";
                micIcon.className = "voicify-assistant-mic-inactive-minimal";
                textbox.className = "voicify-assistant-textbox-active-minimal";
            }
            micIcon.src = settings.styles?.toolbar?.micInactiveImage ?? defaultInactiveMicImage;
            toolbarHeadingType.className = "voicify-assistant-toolbar-type-active";
            sendIcon.src = settings.styles?.toolbar?.sendActiveImage ?? defaultActiveSendImage;
            toolbarHeadingSpeak.className = "voicify-assistant-toolbar-speak-inactive";
            toolbarTopRow.className = "voicify-assistant-toolbar-top-hidden";

        };

        textbox.onblur = () => {
            micStatus = "off";
            if (!minimalUI) {
                textboxContainer.className = "voicify-assistant-input-container-inactive";
            } else {
                textboxContainer.className = "voicify-assistant-input-container-inactive-minimal";
            }
            if (!minimalUI) {
                sendIcon.className = "voicify-assistant-send-icon-inactive";
            } else {
                sendIcon.className = "voicify-assistant-send-icon-inactive-minimal";
            }
            toolbarHeadingType.className = "voicify-assistant-toolbar-type-inactive";
            sendIcon.src = settings.styles?.toolbar?.sendInactiveImage ?? defaultInactiveSendImage;
            if (!minimalUI) {
                textbox.className = "voicify-assistant-textbox";
            } else {
                textbox.className = "voicify-assistant-textbox-minimal";
            }
        };

        if (!minimalUI) {
            textboxContainer.className = "voicify-assistant-input-container-inactive";
        } else {
            textboxContainer.className = "voicify-assistant-input-container-inactive-minimal";
        }
        if (!minimalUI) {
            send.className = "voicify-assistant-send-button";
        } else {
            send.className = "voicify-assistant-send-button-minimal";
        }
        if (!minimalUI) {
            sendIcon.className = "voicify-assistant-send-icon-inactive";
        } else {
            sendIcon.className = "voicify-assistant-send-icon-inactive-minimal";
        }
        send.appendChild(sendIcon);
        textboxContainer.appendChild(textbox);
        textboxContainer.appendChild(send);

        if (!minimalUI) {
            toolbarRightColumn.appendChild(toolbarHeadingType);
            toolbarRightColumn.appendChild(textboxContainer);
        } else {
            toolbarRightColumn.appendChild(toolbarTranscription);
        }
        toolbarHeadingSpeak.className = "voicify-assistant-toolbar-speak-inactive";
        if (sttProvider?.isSpeechRecognitionAvailable() && settings.useVoiceInput) {
            if (!minimalUI) {
                toolbarLeftColumn.className = "voicify-assistant-toolbar-left";
                microphone.className = "voicify-assistant-microphone";
            } else {
                toolbarLeftColumn.className = "voicify-assistant-toolbar-left-minimal";
                microphone.className = "voicify-assistant-microphone-minimal";
            }
            microphone.appendChild(micIcon);
            microphone.onclick = () => {
                if (!minimalUI) {
                    toggleListening();
                }
                if (minimalUI) {
                    pauseAudio();
                    toolbarLeftColumn.removeChild(microphone);
                    toolbarLeftColumn.appendChild(keyboard);
                    toolbarRightColumn.removeChild(textboxContainer);
                    toolbarRightColumn.appendChild(toolbarTranscription);
                }
                if (minimalUI && settings.autoRunConversation && settings.useOutputSpeech) {
                    ttsProvider.stop();
                    sttProvider.startListening();
                    sttProvider?.addPartialListener((partialSpeechResult) => {
                        displayTranscription(partialSpeechResult);
                    });
                    sttProvider?.addStartListener(() => {
                        showSpeakingIndicator();
                    });
                    sttProvider?.addFinishListener(() => {
                        hideSpeakingIndicator();
                    });
                    ttsProvider.addFinishListener(() => {
                        sttProvider.startListening();
                    });
                    sttProvider.addEndListener(() => {
                        clearTranscription();
                    });
                }
            };
            keyboard.className = "voicify-assistant-keyboard";
            keyboard.appendChild(keyboardIcon);
            keyboard.onclick = () => {
                window.voicifyAssistant.clearSpeechTimeout();
                ttsProvider.clearHandlers();
                pauseAudio();
                sttProvider?.stopListening();
                sttProvider?.addEndListener(() => {
                    hideSpeakingIndicator();
                });
                toolbarLeftColumn.removeChild(keyboard);
                toolbarLeftColumn.appendChild(microphone);
                toolbarRightColumn.removeChild(toolbarTranscription);
                toolbarRightColumn.appendChild(textboxContainer);
                if (settings.activeInput === "textbox") {
                    textbox.focus();
                }
            }
            toolbarMuteContainer.onclick = () => {
                toggleListening();
            };
            if (minimalUI) {
                if (toolbarLeftColumn.hasChildNodes()) {
                    toolbarLeftColumn.replaceChildren(keyboard);
                } else {
                    toolbarLeftColumn.appendChild(keyboard);
                }
            } else {
                if (toolbarLeftColumn.hasChildNodes()) {
                    toolbarLeftColumn.replaceChildren(toolbarHeadingSpeak, microphone);
                } else {
                    toolbarLeftColumn.appendChild(toolbarHeadingSpeak);
                    toolbarLeftColumn.appendChild(microphone);
                }
            }
        }
    };

    const styleBottomRightButtonStartButton = () => {
        startButton.className = "voicify-assistant-start-button";
        startIcon.src = settings.styles?.start?.assistantImage ?? defaultIcon;
        startIcon.className = "voicify-assistant-start-icon";
        startButton.append(startIcon);
        if (rootElement) {
            rootElement.appendChild(startButton);
        };
    };

    const styleMinimalStartButton = () => {
        startButton.className = "voicify-assistant-start-button-minimal";
        startIcon.src = settings.styles?.start?.assistantImage ?? defaultIcon;
        startIcon.className = "voicify-assistant-start-icon";
        startButton.append(startIcon);
        if (rootElement) {
            rootElement.appendChild(minimalUIContainerLeft);
            rootElement.appendChild(minimalUIContainer);
            rootElement.appendChild(minimalUIContainerRight);
            minimalUIContainer.appendChild(startButton);
        };
    }

    const styleSlideFromRightStartButton = () => {

        const startText = document.createElement("div");
        startText.innerText = settings.styles?.start?.buttonText ?? "Assistant";
        startText.className = "voicify-assistant-start-text-slide-left";

        const startButtonIcon = document.createElement("img");
        startButtonIcon.src = settings.styles?.start?.slideFromRightStartButtonIcon ?? defaultSlideFromRightStartIcon;
        startButtonIcon.className = "voicify-assistant-start-icon-slide-left voicify-assistant-start-icon-slide-left";
        if (settings.initializeWithWelcomeMessage) {
            startButton.className = "voicify-assistant-start-button-slide-left";
        } else {
            startButton.className = "voicify-assistant-start-button-slide-left-quick";
        };
        startButton.append(startButtonIcon);
        startButton.append(startText);

        if (rootElement) {
            rootElement.appendChild(startButton);
        };

    };

    const styleSlideFromBottomStartButton = () => {
        startButton.className = "voicify-assistant-start-button-slide-up";
        startIcon.src = settings.styles?.start?.slideFromBottomStartIcon ?? defaultSlideFromBottomStartIcon;
        startIcon.className = "voicify-assistant-start-icon-slide-up";
        startButton.append(startIcon);
        headerRightContainer.removeChild(closeButton);
        if (rootElement) {
            rootElement.appendChild(header);
        };
    };

    const createbottomRightButtonUi = () => {
        if (rootElement) {
            rootElement.className += "voicify-assistant-container";
        };
        styleChatContainer();
        styleHeader();
        styleChatHistory();
        styleToolbar();
        styleBottomRightButtonStartButton();
    };

    const createslideFromRightUi = () => {
        if (rootElement) {
            rootElement.className += "voicify-assistant-container-slide-left";
        };
        styleChatContainer();
        styleHeader();
        styleChatHistory();
        styleToolbar();
        styleSlideFromRightStartButton();
    }

    const createslideFromBottomUi = () => {
        if (rootElement) {
            rootElement.className += "voicify-assistant-container-slide-up";
        };
        styleChatContainer();
        styleHeader();
        styleChatHistory();
        styleToolbar();
        styleSlideFromBottomStartButton();
    }

    const createMinimalUi = () => {
        if (rootElement && settings.showConversationHistory) {
            rootElement.className += "voicify-assistant-container-minimal-show-history";
        } else {
            rootElement.className += "voicify-assistant-container-minimal";
        }
        styleChatContainer();
        styleHeader();
        styleChatHistory();
        styleToolbar();
        styleMinimalStartButton();
    }

    const setUIType = () => {
        switch (settings.uiType) {
            case 'bottomRightButton':
                createbottomRightButtonUi();
                break;
            case 'slideFromRight':
                createslideFromRightUi();
                break;
            case 'slideFromBottom':
                createslideFromBottomUi();
                break;
            case 'minimal':
                createMinimalUi();
                break;
            default:
                createbottomRightButtonUi();
                break;
        };
    };

    textbox.onkeyup = (e) => {
        if (e.key.toLowerCase() == "enter") {
            send.click();
        };
    };

    send.onclick = () => {
        window.voicifyAssistant.makeTextRequest(textbox.value);
        textbox.value = '';
    };

    startButton.onclick = () => {
        handleAssistantClick();
        if (settings.activeInput === "textbox") {
            textbox.focus();
        };
    };

    const endAssistant = () => {
        ttsProvider?.stop();
        window.voicifyAssistant.endSession();
        while (chatHistory.lastElementChild) {
            chatHistory.removeChild(chatHistory.lastElementChild);
        }
        if (settings.uiType === "slideFromBottom") {
            headerRightContainer.removeChild(closeButton);
            startIcon.src = settings.styles?.start?.slideFromBottomStartIcon ?? defaultSlideFromBottomStartIcon;
        }
        if (minimalUI) {
            minimalUIContainer?.removeChild(assistantContainer);
            minimalUIContainer?.appendChild(startButton);
        } else {
            rootElement?.removeChild(assistantContainer);
        }
        assistantStatus = 'initial';
    };

    closeButton.onclick = () => {
        if (settings.uiType === "minimal") {
            ttsProvider.stop();
            sttProvider.stopListening();
            ttsProvider.clearHandlers();
            minimizeAssistant();
        } else {
            endAssistant();
        }
    };

    minimizeButton.onclick = () => {
        minimizeAssistant();
    }

    const openAssistant = () => {
        assistantStatus = 'open';
        switch (settings.uiType) {
            case "bottomRightButton":
                rootElement?.appendChild(assistantContainer);
                rootElement?.appendChild(startButton);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
            case "slideFromRight":
                rootElement?.appendChild(startButton);
                rootElement?.appendChild(assistantContainer);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
            case "slideFromBottom":
                startIcon.src = settings.styles?.header?.minimizeIcon ?? defaultMinimizeIcon;
                headerRightContainer.appendChild(closeButton);
                rootElement?.appendChild(assistantContainer);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
            case "minimal":
                rootElement?.appendChild(minimalUIContainerLeft);
                rootElement?.appendChild(minimalUIContainer);
                rootElement?.appendChild(minimalUIContainerRight);
                minimalUIContainer?.removeChild(startButton);
                minimalUIContainer?.appendChild(assistantContainer);
                if (toolbarLeftColumn.contains(microphone)) {
                    toolbarLeftColumn.removeChild(microphone);
                    toolbarLeftColumn.appendChild(keyboard);
                }
                if (toolbarRightColumn.contains(textboxContainer)) {
                    toolbarRightColumn.removeChild(textboxContainer);
                    toolbarRightColumn.appendChild(toolbarTranscription);
                }
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarBottomRow);
                break;
            default:
                rootElement?.appendChild(assistantContainer);
                rootElement?.appendChild(startButton);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
        }
    };

    const startAssistant = () => {
        assistantStatus = 'open';
        switch (settings.uiType) {
            case "bottomRightButton":
                rootElement?.removeChild(startButton);
                rootElement?.appendChild(assistantContainer);
                rootElement?.appendChild(startButton);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
            case "slideFromRight":
                rootElement?.removeChild(startButton);
                rootElement?.appendChild(startButton);
                rootElement?.appendChild(assistantContainer);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
            case "slideFromBottom":
                startIcon.src = settings.styles?.header?.minimizeIcon ?? defaultMinimizeIcon;
                headerRightContainer.appendChild(closeButton);
                rootElement?.appendChild(assistantContainer);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
            case "minimal":
                rootElement?.removeChild(minimalUIContainerLeft);
                rootElement?.removeChild(minimalUIContainer);
                rootElement?.removeChild(minimalUIContainerRight);
                rootElement?.appendChild(minimalUIContainerLeft);
                rootElement?.appendChild(minimalUIContainer);
                rootElement?.appendChild(minimalUIContainerRight);
                minimalUIContainer?.removeChild(startButton);
                minimalUIContainer?.appendChild(assistantContainer);
                if (toolbarLeftColumn.contains(microphone)) {
                    toolbarLeftColumn.removeChild(microphone);
                    toolbarLeftColumn.appendChild(keyboard);
                    toolbarRightColumn.removeChild(textboxContainer);
                    toolbarRightColumn.appendChild(toolbarTranscription);
                }
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarBottomRow);
                break;
            default:
                rootElement?.removeChild(startButton);
                rootElement?.appendChild(assistantContainer);
                rootElement?.appendChild(startButton);
                toolbarBottomRow.replaceChildren(toolbarLeftColumn, toolbarRightColumn);
                toolbar.appendChild(toolbarTopRow);
                toolbar.appendChild(toolbarBottomRow);
                break;
        }
    };

    const minimizeAssistant = () => {
        window.voicifyAssistant.clearSpeechTimeout();
        if (ttsProvider) {
            ttsProvider.stop();
        }
        if (settings.uiType === "slideFromBottom") {
            headerRightContainer.removeChild(closeButton);
            startIcon.src = settings.styles?.start?.slideFromBottomStartIcon ?? defaultSlideFromBottomStartIcon;
        }
        assistantStatus = 'closed';
        if (!minimalUI) {
            rootElement?.removeChild(assistantContainer);
        } else {
            hideSpeakingIndicator();
            minimalUIContainer?.removeChild(assistantContainer);
            minimalUIContainer?.appendChild(startButton);
        }
    };

    const handleSpeechTimeout = () => {
        minimizeAssistant();
    }

    const handleAssistantClick = () => {
        switch (assistantStatus) {
            case 'initial':
                startAssistant();
                if (settings.initializeWithWelcomeMessage === false && !minimalUI) {
                    assistantContainer.className = "voicify-assistant-chat-container-quick";
                    chatHistory.className = "voicify-assistant-chat-history-hidden";
                };
                window.voicifyAssistant.startNewSession();
                window.voicifyAssistant.initializeAndStart(handleSpeechTimeout);
                if (settings.autoRunConversation && settings.useOutputSpeech) {
                    sttProvider?.addPartialListener((partialSpeechResult) => {
                        displayTranscription(partialSpeechResult);
                    });
                    sttProvider?.addStartListener(() => {
                        showSpeakingIndicator();
                    });
                    sttProvider?.addFinishListener(() => {
                        hideSpeakingIndicator();
                    });
                    sttProvider.addEndListener(() => {
                        clearTranscription();
                    });
                    if (minimalUI) {
                        ttsProvider.addFinishListener(() => {
                            sttProvider.startListening();
                        });
                    }
                };
                scrollToBottomOfChat();
                break;
            case 'existing':
                openAssistant();
                scrollToBottomOfChat();
                break;
            case 'open':
                if (settings.initializeWithWelcomeMessage === false && !minimalUI) {
                    assistantContainer.className = "voicify-assistant-chat-container";
                    chatHistory.className = "voicify-assistant-chat-history";
                }
                if (minimalUI) {
                    assistantContainer.className = "voicify-assistant-chat-container-minimal";
                    chatHistory.className = "voicify-assistant-chat-history-minimal";
                }
                minimizeAssistant();
                scrollToBottomOfChat();
                break;
            case 'closed':
                startAssistant();
                if (settings.autoRunConversation && settings.useOutputSpeech) {
                    sttProvider?.startListening();
                    sttProvider?.addPartialListener((partialSpeechResult) => {
                        displayTranscription(partialSpeechResult);
                    });
                    sttProvider?.addStartListener(() => {
                        showSpeakingIndicator();
                    });
                    sttProvider?.addFinishListener(() => {
                        hideSpeakingIndicator();
                    });
                    sttProvider.addEndListener(() => {
                        clearTranscription();
                    });
                    if (minimalUI) {
                        ttsProvider.stop();
                        ttsProvider.addFinishListener(() => {
                            sttProvider.startListening();
                        });
                    }
                };
                scrollToBottomOfChat();
                break;
        };
    };

    const createAssistant = () => {
        setUIType();
        if (window.voicifyAssistant.isExistingSession()) {
            assistantStatus = 'existing';
            handleAssistantClick();
            window.voicifyAssistant.initializeExistingSession(handleSpeechTimeout);
        };
        buildChatHistory();
        window.voicifyAssistant.onRequestStarted(request => {
            if (!request.context.requiresLanguageUnderstanding && request.context.requestName == "VoicifyWelcome")
                return;
            if (!minimalUI) {
                if (settings.uiType === "slideFromBottom") {
                    assistantContainer.className = "voicify-assistant-chat-container-slide-up"
                } else {
                    assistantContainer.className = "voicify-assistant-chat-container";
                }
                chatHistory.className = "voicify-assistant-chat-history";
            } else if (settings.showConversationHistory) {
                assistantContainer.className = "voicify-assistant-chat-container-minimal-show-history";
                chatHistory.className = "voicify-assistant-chat-history-minimal";
            } else {
                assistantContainer.className = "voicify-assistant-chat-container-minimal";
                chatHistory.className = "voicify-assistant-chat-history-minimal";
            };
            buildUserMessage(request.context.originalInput?.trim())
            toolbarTopRow.className = "voicify-assistant-toolbar-top-hidden";
        });
        window.voicifyAssistant.onResponseReceived(response => {
            buildBotMessage((response.displayText ?? response.outputSpeech ?? '')?.trim(), response.hints, response.foregroundImage?.url, response.audioFile?.url, response.videoFile?.url);
        });
        addZIndex(settings.styles);
        if (settings.customStyles) {
            createStyles(shadowRoot, settings.styles, settings.customStyles);
        } else {
            createStyles(shadowRoot, settings.styles);
        };
        scrollToBottomOfChat();
    };

    createAssistant();

    if (settings.openByDefault) {
        handleAssistantClick();
    };
};

export default initializeVoicifyAssistant;