export interface VoicifySpeechToTextProvider {
    initialize: (locale: string) => void
    addStartListener: (callback: (error?: boolean) => void) => void
    addPartialListener: (callback: (partialResult: string) => void) => void
    addNoMatchListener: (callback: (error: string) => void) => void
    addFinishListener: (callback: (fullResult: string) => void) => void
    addEndListener: (callback: (error?: boolean) => void) => void
    addErrorListener: (callback: (error: string) => void) => void
    startListening: () => void
    stopListening: () => void
    clearHandlers: () => void
    isSpeechRecognitionAvailable: () => void
}

export interface VoicifySpeechRecognition {
    lang: string, 
    onresult: Function, 
    onerror: Function, 
    onspeechstart: Function, 
    onspeechend: Function,
    onnomatch: Function,
    abort: Function,
    start: Function,
    stop: Function,
    continuous: boolean,
    interimResults: boolean
}