import React from 'react';
import { Subscribe } from 'unstated';
import { RouteComponentProps } from 'react-router-dom';
import LatestMessageContainer from '../../state/containers/LatestMessageContainer';
import LatestMessageContentForm from './components/LatestMessageContentForm';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import RepromptContainer from '../../state/containers/RepromptContainer';
import FollowUpContainer from '../../state/containers/FollowUpContainer';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import ConversationNavigationState from '../../models/state/ConversationNavigationState';
import NavigationBarFormPage from '../formScenes/NavigationBarFormPage';
import LatestMessageModel from '../../models/features/api/LatestMessages/LatestMessageModel';
import IResult from '../../models/result/IResult';
import GenericCreateContentItemPage from '../formScenes/GenericCreateContentItemPage';
import FeatureContainer from '../../state/containers/FeatureContainer';
import { LatestMessagesFeatureTypeId } from '../../constants/featureTypeIds';

interface ApplicationRouteProps {
    applicationFeatureId: string
}
interface CreateLatestMessageProps extends RouteComponentProps<ApplicationRouteProps> { }

class CreateLatestMessage extends NavigationBarFormPage<CreateLatestMessageProps, ConversationNavigationState> {
    render() {
        return (
            <Subscribe to={[LatestMessageContainer, ApplicationContainer, TitleStateContainer, RepromptContainer, FollowUpContainer, MediaResponseStateContainer, FeatureContainer]}>
                {(latestMessageContainer: LatestMessageContainer,
                    appContainer: ApplicationContainer,
                    titleContainer: TitleStateContainer,
                    repromptContainer: RepromptContainer,
                    followUpContainer: FollowUpContainer,
                    responseStateContainer: MediaResponseStateContainer,
                    featureContainer: FeatureContainer) => {
                    return (
                        <GenericCreateContentItemPage stateContainer={latestMessageContainer}
                            titleContainer={titleContainer}
                            applicationContainer={appContainer}
                            defaultTitle="New Latest Message"
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            staticContext={this.props.staticContext}
                            featureContainer={featureContainer}
                            featureTypeId={LatestMessagesFeatureTypeId}
                            formRender={(application, assistantApplication, onPublish, onSubmit: (model: LatestMessageModel) => Promise<IResult<LatestMessageModel>>, onAccessTypeChange) => (
                                <LatestMessageContentForm
                                    location={this.props.location}
                                    applicationFeatureId={this.props.match.params.applicationFeatureId}
                                    application={application}
                                    assistantApplication={assistantApplication}
                                    stateContainer={latestMessageContainer}
                                    appContainer={appContainer}
                                    repromptContainer={repromptContainer}
                                    followUpContainer={followUpContainer}
                                    responseStateContainer={responseStateContainer}
                                    featureContainer={featureContainer}
                                    history={this.props.history}
                                    isSaving={latestMessageContainer.state.isSaving}
                                    onAccessTypeChange={onAccessTypeChange}
                                    onShowPublishModal={onPublish}
                                    onSubmit={onSubmit} />
                            )} />
                    );
                }}
            </Subscribe>
        );
    }
}

export default CreateLatestMessage;