import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemModel from '../../../models/features/api/GenericContentItemModel';
import ConversationItemSelector from './ConversationItemSelector';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { ocean_blue, cool_grey } from '../../../constants/colors';
import NavigationSelection from './NavigationSelection';
import ContentItemSection from './ContentItemSection';
import HelpMessageModel from '../../../models/features/api/Help/HelpMessageModel';
import FallbackMessageModel from '../../../models/features/api/Fallback/FallbackMessageModel';
import { FallbackFeatureTypeId, HelpMessagesFeatureTypeId } from '../../../constants/featureTypeIds';
const leadOutIcon = require('../../../content/images/content-tree-icons/content-level/lead-out.svg');

interface ChildConversationItemSelectorProps {
    onSelect: (conversationItem: GenericContentItemModel) => void
    conversationItems?: GenericContentItemModel[]
    contextualHelp?: HelpMessageModel
    contextualFallback?: FallbackMessageModel
    isLoading: boolean
    currentConversationItem?: GenericContentItemModel
    isSaving: boolean
}
interface ChildConversationItemSelectorState {
    isOpen?: boolean
}

class ChildConversationItemSelector extends React.Component<ChildConversationItemSelectorProps, ChildConversationItemSelectorState> {
    state = {
        isOpen: true
    }

    handleToggle() {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        })
    }
    handleClose() {
        this.setState({
            ...this.state,
            isOpen: false
        })
    }

    render() {
        var conversationItems = [...(this.props.conversationItems ?? [])];
        if(this.props.contextualFallback)
            conversationItems.push({
                ...this.props.contextualFallback,
                featureTypeId: FallbackFeatureTypeId
            })

        if(this.props.contextualHelp)
            conversationItems.push({
                ...this.props.contextualHelp,
                featureTypeId: HelpMessagesFeatureTypeId
            })

        return (
            <div className={`${containerStyle} ${this.state.isOpen ? 'open' : ''}`}>
                {this.props.isLoading ? <div className="loader-container"><SecondaryLoader /></div> :
                    <NavigationSelection isOpen={this.state.isOpen} title="Followed by" icon={leadOutIcon} count={this.props.conversationItems ? conversationItems.length : 0} onClick={this.handleToggle.bind(this)} />}
                {this.state.isOpen && !this.props.isLoading ?
                    <ContentItemSection>
                        <ConversationItemSelector
                            conversationItems={conversationItems}
                            onSelect={this.props.onSelect}
                            isSaving={this.props.isSaving} />
                    </ContentItemSection>
                    : null}
            </div>
        )
    }
};

const containerStyle = css`
    .spinner > div {
        background: ${cool_grey};
    }
    .loader-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin: 8px;
    }
`

export default ChildConversationItemSelector;